<template>
  <div>
    <div class="top-bar flex justify-end">
      <v-button
        v-if="isAdminMeasurements"
        @click="addUnit"
        icon="plus"
      >{{ $t('add_measurement_unit') }}</v-button>
    </div>
    <div>
      <!-- Tabla de Unidades de Medida -->
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInUp"
        leave-active-class="animated fadeOutDown"
      >
        <div class="panel">
          <div class="w-full flex flex-wrap" v-show="create!=false || place.reference_code">
            <div class="panel">
              <form @submit.prevent="changeUnits" class="w-full flex">
                <div class="flex-grow pr-3">
                  <label for="code" class="w-full">{{ $t('reference_code') }}</label>
                  <v-input
                    v-model="place.code"
                    title="code"
                    id="code"
                    name="code"
                    placeholder="place_placeholder"
                    class="w-full"
                    required
                  />
                </div>
                <div class="flex-grow pr-3">
                  <label for="name" class="w-full">{{ $t('name') }}</label>
                  <v-input
                    v-model="place.name"
                    title="name"
                    id="name"
                    name="name"
                    placeholder="name"
                    class="w-full"
                    required
                  />
                </div>

                <div class="flex-grow-0 pr-3 inline-flex self-end">
                  <v-button
                    native-type="submit"
                    :disabled="!place.code || !place.name"
                  >{{ $t('save') }}</v-button>
                </div>
              </form>
            </div>
          </div>
          <section>
            <b-table
              show-empty
              :data="data"
              :loading="isLoading"
              paginated
              backend-pagination
              :per-page="perPage"
              :total="total"
              @page-change="onPageChange"
              backend-sorting
              :default-sort="orderField"
              :default-sort-direction="orderDir"
              @sort="onSort"
              striped
              bordered
              icon-pack="fas"
            >
              <template v-slot:empty="">
                <h4>{{ $t('no_rows') }}</h4>
              </template>

              <template>
                <b-table-column field="reference_code" sortable >
                  <template v-slot:header="">
                    {{ $t('reference_code') }}
                  </template>
                  <template v-slot="props">
                    {{ props.row.code }}
                  </template>
                </b-table-column>

                <b-table-column field="name" >
                  <template v-slot:header="">
                    {{ $t('name') }}
                  </template>
                  <template v-slot="props">
                    {{ props.row.name }}
                  </template>
                </b-table-column>

                <b-table-column field="updated_at" sortable >
                  <template v-slot:header="">
                    {{ $t('date') }}
                  </template>
                  <template v-slot="props">
                    {{ new Date(props.row.updated_at).toLocaleDateString() + ' ' + new Date(props.row.updated_at).toLocaleTimeString() }}
                  </template>
                </b-table-column>

                <b-table-column field="actions" :visible="isAdminMeasurements" centered width="80">
                  <template v-slot:header="">
                    {{ $t('actions') }}
                  </template>
                  <template v-slot="props">
                    <div class="w-full h-full flex items-center justify-around">
                      <b-tooltip :label="$t('edit_measurement_unit')" position="is-left">
                        <div @click="editUnit(props.row)" class="cursor-pointer flex text-blue">
                          <b-icon pack="fas" icon="pencil-alt" />
                        </div>
                      </b-tooltip>

                      <b-tooltip
                        v-if="canDelete(props.row)"
                        :label="$t('measurement_unit_has_usage')"
                        position="is-left"
                      >
                        <div class="cursor-not-allowed flex text-grey">
                          <b-icon pack="fas" icon="trash-alt" />
                        </div>
                      </b-tooltip>
                      <b-tooltip
                        v-else
                        :label="$t('remove_measurement_unit')"
                        type="is-danger"
                        position="is-left"
                      >
                        <div @click="removeUnit(props.row)" class="cursor-pointer flex text-red">
                          <b-icon pack="fas" icon="trash-alt" />
                        </div>
                      </b-tooltip>
                    </div>
                  </template>
                </b-table-column>
              </template>
            </b-table>
          </section>
        </div>
      </transition>
    </div>
    <!-- Modal para PREVIEW -->
    <measurement-unit-modal ref="measurement_unit_modal" @reload-units="() => reload()" />
  </div>
</template>

<script>
import Form from "vform";
import MeasurementUnitModal from "./measurement-unit-modal";
import axios from "axios";

export default {
  name: "measurement-units-manual",

  components: {
    MeasurementUnitModal
  },

  data: () => ({
    data: [],
    datatable: null,
    place: {
      name: "",
      code: "",
      description: ""
    },
    formUnits: new Form({
      name: "",
      code: "",
      description: ""
    }),
    newPlace: "",
    create: false,
    draggable: false,
    isLoading: true,
    autocomplete: null,
    page: 1,
    perPage: 15,
    total: 0,
    orderField: "reference_code",
    orderDir: "asc"
  }),

  props: {
    companyId: { type: Number, required: true },
    isAdminMeasurements: { type: Boolean, default: false }
  },

  mounted() {
    this.getProjectUnits();
  },

  methods: {
    async addUnit() {
      this.reload();
      this.getProjectUnits();
      this.create = true;
    },

    async getProjectUnits() {
      this.isLoading = true;
      let params = {
        p: this.companyId,
        order_dir: this.orderDir,
        order_field: this.orderField
      };
      const { data } = await axios.get(
        "/api/v2/company/" + this.companyId + "/measurement-units",
        { params: params }
      );
      if (data && data.success) {
        this.data = data.units;
        this.total = data.units ? data.units.length : 0;
      } else {
        this.$notify.error("error_loading_measurement_units_data");
      }
      this.isLoading = false;
    },

    reload() {
      this.getProjectUnits();
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    onPageChange(page) {
      this.page = page;
      this.reload();
    },

    onSort(field, order) {
      this.orderField = field;
      this.orderDir = order;
      this.reload();
    },

    editUnit(unit) {
      this.$refs.measurement_unit_modal.show(unit);
    },

    async removeUnit(unit) {
      this.$dialog.confirm({
        title: this.$t("delete_measurement_unit"),
        message: this.$t("delete_measurement_unit_confirm_text", [unit.name]),
        confirmText: this.$t("delete_measurement_unit"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true;
          let url = "/api/v2/measurement-units/" + unit.id + "/delete";
          const { data } = await axios.post(url);

          if (data.success) {
            this.$toast.open({
              message: this.$t("measurement_unit_remove_success"),
              type: "is-success",
              position: "is-top-right"
            });
            await this.sleep(1000);
            this.reload();
          } else {
            this.isLoading = false;
            // Error
            console.log(data);
            this.$toast.open({
              message: data.error || this.$t("measurement_unit_remove_error"),
              type: "is-danger",
              position: "is-top-right"
            });
          }
        }
      });
    },

    async changeUnits() {
      this.isLoading = true;

      let params = {
        code: this.place.code,
        name: this.place.name,
        description: this.place.description
      };

      let url = "/api/v2/company/" + this.companyId + "/measurement-units";
      axios({
        method: "post",
        url: url,
        data: params
      })
        .then(response => {
          this.$toast.open({
            message: this.$t("measurement_unit_change_success"),
            type: "is-success",
            position: "is-top-right"
          });

          this.create = false;
          this.place.code = "";
          this.place.name = "";
          this.place.description = "";
          this.reload();
        })
        .catch(error => {
          //Se produjo un error
          this.isLoading = false;
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("measurement_unit_change_fail"),
            type: "is-danger",
            position: "is-top-right"
          });
        });
    },

    canDelete(data) {
      return (
        (data.activities && data.activities.length > 0) ||
        (data.issues && data.issues.length > 0)
      );
    }
  }
};
</script>
