<template>
<span
  class="uppercase text-white font-black tracking-wide text-center p-1 rounded"
  :class="[`bg-${colors[status]}`]"
  style="font-size:0.6rem"
>
  {{ $t(labels[status]) }}
</span>
</template>

<script>

export default {
  name: 'issue-status-label',

  props: {
    status: { type: Number, default: 0 },
  },

  data: () => ({
    labels: [
      'closed',
      'opened',
    ],
    colors: [
      'red',
      'green'
    ],
  }),

};
</script>
