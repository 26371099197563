<template>

  <div class="flex w-full flex-col items-center mb-4">

    <div class="flex w-full flex-col lg:flex-row items-center mb-4">
      <div class="w-full lg:w-1/3">
        <b-field :label="$t('search_by_type')">
            <b-select ref="input"
                    v-model="filters.type"
                    :placeholder="$t('select')"
                    icon="search"
                    icon-pack="fas"
                    @input="applyFilters()"
                    expanded
            >
            <option :value="null">{{$t('select')}}</option>
            <option v-for="option in typeList" :value="option.id" :key="'type_'+option.id">
                        {{ option.name }}
                    </option>
            </b-select>
        </b-field>
      </div>

      <div class="w-full lg:w-1/3 lg:ml-4">
        <b-field :label="$t('search_by_status')">
            <b-select ref="input"
                    v-model="filters.status"
                    :placeholder="$t('select')"
                    icon="search"
                    icon-pack="fas"
                    @input="applyFilters()"
                    expanded
            >
            <option :value="null">{{$t('select')}}</option>
            <option v-for="option in statusList" :value="option.id" :key="'status_'+option.id">
                        {{ option.name }}
                    </option>
            </b-select>
        </b-field>
      </div>

      <div class="w-full lg:w-1/3 lg:ml-4">
        <b-field :label="$t('search_by_discipline')">
            <b-select ref="input"
                    v-model="filters.discipline"
                    :placeholder="$t('select')"
                    icon="search"
                    icon-pack="fas"
                    @input="applyFilters()"
                    expanded
            >
            <option :value="null">{{$t('select')}}</option>
            <option v-for="option in disciplineList" :value="option.id" :key="'discipline_'+option.id">
                        {{ option.name }}
                    </option>
            </b-select>
        </b-field>
      </div>

    </div>

    <div class="flex w-full flex-col lg:flex-row items-center mb-4">
        <div class="w-full lg:w-1/3">
          <b-field :label="$t('search_by_name')">
              <b-input
              v-model="filters.name"
              :placeholder="$t('name')"
              type="search"
              icon="search"
              icon-pack="fas"
              @input="applyFilters()">
              </b-input>
          </b-field>
        </div>

        <div class="w-full lg:w-1/3 lg:ml-4">
          <b-field :label="$t('search_by_tag')">
              <b-input
              v-model="filters.tag"
              :placeholder="$t('tag')"
              type="search"
              icon="search"
              icon-pack="fas"
              @input="applyFilters()">
              </b-input>
          </b-field>
        </div>

        <div class="w-full lg:w-1/3 lg:ml-4">
          <b-field :label="$t('search_by_created_date')">
              <b-datepicker
              v-model="filters.created"
              :placeholder="$t('created_at')"
              :month-names="$t('months_names')"
              :day-names="$t('days_names')"
              :first-day-of-week="parseInt($t('first_day_of_week'))"
              :readonly="false"
              @input="applyFilters()"
              >
              </b-datepicker>
          </b-field>
        </div>
    </div>

  </div>

</template>

<script>
  export default {

    components: {

    },

    props: {
      typeList: { type: Array, required: true },
      statusList: { type: Array, required: true },
      disciplineList: { type: Array, required: true }
    },

    data: () => ({
      filterName: '',

      filters: {
        type: null,
        status: null,
        discipline: null,
        name: null,
        tag: null,
        created: null,
      },
    }),

    methods: {
      generateFilters() {

        let params = {};

        // Filtro por nombre
        if (this.filters.name.length >= 3) {
          params.name = this.filters.name
        }
        //Filtro por creador
        if (this.filters.type !== null) {
          params.type = this.filters.type
        }

        if (this.filters.status !== null) {
          params.status = this.filters.status
        }

        if (this.filters.discipline !== null) {
          params.discipline = this.filters.discipline
        }

        if (this.filters.tag !== null) {
          params.tag = this.filters.tag
        }

        if (this.filters.created !== null) {
          params.created = this.filters.created
        }

        return params

      },

      applyFilters() {
        let args = this.generateFilters();
        // this.$emit('foldersfilters-updated', args);
      },

      cleanFiltersClicked() {
        this.filters = {
            type: null,
            status: null,
            discipline: null,
            name: null,
            tag: null,
            created: null,
        }
        this.applyFilters()
      },

      checkInputTextFilter(filter) {
        if (filter.length >= 3 || filter.length == 0) {
          this.applyFilters()
        }
      },
    }
  };
</script>

<style scoped>
</style>
