<template>
  <div>
    <!-- Filtros -->
    <b-collapse class="card filters-collapse" :open.sync="showFilters">
      <div slot="trigger" class="card-header filters-trigger">
        <p class="card-header-title">
          {{ $t('filters') }}
        </p>
        <a class="card-header-icon">
          <b-icon pack="fa" v-show="! showFilters" icon="plus-circle"></b-icon>
          <b-icon pack="fa" v-show="showFilters" icon="minus-circle"></b-icon>
        </a>
      </div>

      <div class="panel" style="border-width: 0px">
        <div class="flex flex-col lg:flex-row items-center mb-4">

          <div class="w-full lg:w-1/3 pr-4">
            <b-field :label="$t('search_by_type')">
              <b-select
                v-model="filters.type"
                :placeholder="$t('technical_specification_type')"
                icon="search" icon-pack="fas"
                @input="applyFilters(1)"
                expanded
              >
                <option :value="null">{{ $t('all') }}</option>
                <option v-for="type in technicalSpecificationtypes"
                        :value="type.id" :key="'type_'+type.id"
                >
                  {{ type.code }} - {{ type.name }}
                </option>
              </b-select>
            </b-field>
          </div>

          <div class="w-full lg:w-1/3 pr-4">
            <b-field :label="$t('search_by_scope')">
              <b-select
                v-model="filters.scope"
                :placeholder="$t('scope')"
                icon="search" icon-pack="fas"
                @input="applyFilters(1)"
                expanded
              >
                <option :value="null">{{ $t('all') }}</option>
                <option v-for="scope in scopeList"
                        :value="scope" :key="'scope_'+scope"
                >
                  {{ scope }}
                </option>
              </b-select>
            </b-field>
          </div>

          <div class="w-full lg:w-1/3 pr-4">
            <b-field :label="$t('search_by_status')">
              <b-select
                v-model="filters.status"
                :placeholder="$t('status')"
                icon="search" icon-pack="fas"
                @input="applyFilters(1)"
                expanded
              >
                <option :value="null">{{ $t('all') }}</option>
                <option v-for="(status, index) in statusList"
                        :value="index" :key="'status_'+status"
                >
                  {{ status }}
                </option>
              </b-select>
            </b-field>
          </div>

        </div>
        <div class="flex flex-col lg:flex-row items-center mb-4">

          <div class="w-full lg:w-1/3 pr-4">
            <b-field :label="$t('search_by_current_group')">
              <b-select
                v-model="filters.group"
                :placeholder="$t('group')"
                icon="search" icon-pack="fas"
                @input="applyFilters(1)"
                expanded
              >
                <option :value="null">{{ $t('all') }}</option>
                <option v-for="group in groupList"
                        :value="group.code" :key="'group_'+group.code"
                >
                  {{ group.name }}
                </option>
              </b-select>
            </b-field>
          </div>

          <div class="w-full lg:w-1/3 pr-4">
            <b-field :label="$t('search_by_activity')">
              <b-select
                v-model="filters.activity_id"
                :placeholder="$t('activity')"
                icon="search" icon-pack="fas"
                @input="applyFilters(1)"
                expanded
              >
                <option :value="null">{{ $t('all') }}</option>
                <option v-for="activity in activities"
                        :value="activity.id" :key="'activity_'+activity.id"
                >
                  {{ activity.name }}
                </option>
              </b-select>
            </b-field>
          </div>

          <div class="w-full lg:w-1/3 pr-4" v-if="project.id == 528">
            <b-field :label="$t('search_by_code')">
              <b-select
                v-model="filters.code"
                :placeholder="$t('code')"
                icon="search" icon-pack="fas"
                @input="applyFilters(1)"
                expanded
              >
                <option :value="null">{{ $t('all') }}</option>
                <option v-for="code in codes"
                        :value="code.code" :key="'code_'+code.code"
                >
                  {{ code.name }}
                </option>
              </b-select>
            </b-field>
          </div>

        </div>
        <button class="button is-primary" @click="cleanFiltersClicked()">{{ $t('clean_filters') }}</button>
      </div>
    </b-collapse>

    <div class="text-sm">
      <div class="flex w-full items-center justify-end text-white">
        <div class="p-2 text-gray-900 bg-white rounded-lg shadow-lg- font-medium capitalize text-right">

          <span v-if="!isLoadingTable"
            class="px-1 mt-2  hover:text-gray-700 cursor-pointer
              lg:px-2 text-sm xl:rounded xl:py-2 xl:hover:bg-gray-200"
              @click="goToDashboard">
            <b-tooltip label="Ir a Dashboard" position="is-left">
              <i class="lg:w-8 fas fa-chart-line p-2 bg-gray-200 rounded-full"></i>
              <span class="mx-1 hidden xl:inline-">Ir a Dashboard</span>
            </b-tooltip>
          </span>

          <span v-if="!isLoadingTable"
            class="px-1 mt-2  hover:text-gray-700 cursor-pointer
              lg:px-2 text-sm xl:rounded xl:py-2 xl:hover:bg-gray-200"
              @click="showModalReport">
            <b-tooltip label="Generar informe" position="is-left">
              <i class="lg:w-8 fas fa-book p-2 bg-gray-200 rounded-full"></i>
              <span class="mx-1 hidden xl:inline-">Generar informe</span>
            </b-tooltip>
          </span>

          <project-report-technical-specifications-list v-if="!isLoadingTable"
            :list="listOrder"
            :onlyModal="true"
            :openWithButton="false"
            :show.sync="showModal"/>
        </div>
      </div>

      <div>
        <div v-if="total" class="text-right w-full float-right">
          <b>{{ $t('count_results') }}: {{ total }}</b>
        </div>
      </div>

      <b-table
        ref="table_techspecs"
        :data="list"
        :loading="isLoadingTable"
        icon-pack="fas"

        paginated
        backend-pagination
        :per-page="perPage"
        :current-page.sync="currentPage"
        :total="total"
        @page-change="onPageChange"

        backend-sorting
        :default-sort="orderField"
        :default-sort-direction="orderDir"
        @sort="onSort"

        class="text-xs border-blue-lighter"
        bordered
        narrowed
        striped
      >

        <template slot="empty">
          <div v-if="! isLoadingTable" class="m-4 text-lg text-dark-grey">
            <span>{{ $t('empty_technical_specifications_table_msg') }}</span>
          </div>
        </template>

        <template>

          <b-table-column field="order" :label="$t('order')" sortable cell-class="vertical-center" v-slot="techSpec">
            <div>
              {{ techSpec.row.order }}
            </div>
          </b-table-column>

          <b-table-column field="name" :label="$t('name')" sortable cell-class="vertical-center" v-slot="techSpec">
            <div @click="goToFlow(techSpec.row.flow_id)" class="cursor-pointer">
              {{ techSpec.row.name }}
            </div>
            <span class="text-blue-light font-bold cursor-pointer" @click="goToActivity(techSpec.row.activity_parent_id)" style="font-size: 11px" v-if="techSpec.row.activity_parent_id">{{techSpec.row.activity_parent_name}}</span>
          </b-table-column>

          <b-table-column field="code" :label="$t('code')" sortable cell-class="vertical-center" v-slot="techSpec">
            <div>
              {{ techSpec.row.code }}
            </div>
          </b-table-column>

          <b-table-column field="type" :label="$t('type')" sortable cell-class="vertical-center" v-slot="techSpec">
            <div>
              {{ techSpec.row.type }}
            </div>
          </b-table-column>

          <b-table-column field="scope" :label="$t('scope')" sortable cell-class="vertical-center" v-slot="techSpec">
            <div>
              {{ techSpec.row.scope }}
            </div>
          </b-table-column>

          <b-table-column field="status" :label="$t('status')" sortable cell-class="vertical-center has-text-centered" v-slot="techSpec">
            <div>
              <technical-specification-status-label :status="techSpec.row.status"/>
            </div>
          </b-table-column>

          <b-table-column field="group" :label="$t('group')" sortable cell-class="vertical-center has-text-centered" v-slot="techSpec">
            <div v-if="techSpec.row.status != 2" class="py-1">
              <span class="p-1 text-xs text-center text-white rounded-full bg-blue">
                {{ techSpec.row.group }}
              </span>
            </div>
            <div v-else></div>
          </b-table-column>

          <b-table-column field="date" :label="$t('date')" sortable cell-class="vertical-center" v-slot="techSpec">
            <div>
              {{ techSpec.row.date }}
            </div>
          </b-table-column>

          <b-table-column field="days" :label="$t('days')" sortable cell-class="vertical-center" v-slot="techSpec">
            <div>
              {{ techSpec.row.days }}
            </div>
          </b-table-column>

        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import {mapGetters} from 'vuex'
import moment from 'moment'
import TechnicalSpecificationStatusLabel from './technical-specification-status-label'
import ProjectReportTechnicalSpecificationsList from '../../../components/project-report-technical-specifications-list'

Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'technical_specifications_page_title',
  subtitle: 'technical_specifications_page_subtitle',
  menuOption: '2-5',

  components: {
    TechnicalSpecificationStatusLabel,
    ProjectReportTechnicalSpecificationsList
  },

  props: {
  },

  data: () => ({
    isLoadingTable: true,
    currentPage: 1,
    page: 1,
    last: 1,
    perPage: 25,
    total: 0,
    orderField: "order",
    orderDir: "desc",
    list: [],
    technicalSpecificationtypes: [],
    showFilters: false,
    paginationOrder: '',
    filters: {
        p: null,
        type: null,
        scope: null,
        status: null,
        group: null,
        activity_id: null,
        code: null
    },
    statusList: ['No presentada', 'Pendiente', 'Aprobada'],
    scopeList: ['AT', 'GTI'],
    groupList: [],
    activities: [],
    codes: [
      {code: "ET-FVDG", name: "PLANTA SOLAR FOTOVOLTAICA DEL DEPÓSITO DE GETAFE"},
      {code: "ET-FVDR", name: "PLANTA SOLAR FOTOVOLTAICA DEL DEPÓSITO DE RETAMARES"},
      {code: "ET-FVEAV", name: "PLANTA SOLAR FOTOVOLTAICA DE LA EDAR ARROYO VALENOSO"}
    ],
    listOrder: [],
    showModal: false
  }),

  computed: {
    ...mapGetters({
      user: 'auth/user',
      project: 'app/project',
    }),
  },

  mounted() {
    this.filters.p = this.project.id
    this.getTypes()
    this.getTechnicalSpecifications(1)
  },

  methods: {

    async getTechnicalSpecifications(isFirst = 0) {
        this.isLoadingTable = true
        let params = {
            orderField: this.orderField,
            orderDir: this.orderDir,
            page: this.page,
            perPage: this.perPage,
            current_groups_list: isFirst,
            ...this.filters
        }
        this.currentPage = this.page
        let {data} = await axios.get('/api/v2/technical-specifications/control-data', {params});

        if (data.success) {
            this.list = data.data
            if (isFirst) {
              this.groupList = data.currentGroupsList
              this.activities = data.activities
              // this.codes = data.codes
            }

            // Last and total
            this.last = data.lastPage
            this.total = data.total
        } else {
            this.$notify.error("error_loading_data");
        }
        this.isLoadingTable = false
    },

    async getTypes() {
        let {data} = await axios.get('/api/v2/technical-specification-types', {params: {p: this.project.id}});

        if (data.success) {
            this.technicalSpecificationtypes = data.data
        } else {
            this.$notify.error("error_loading_data");
        }
    },

    applyFilters(page) {
        this.page = page;
        this.getTechnicalSpecifications()
    },

    onSort(field, order) {
      this.orderField = field
      this.orderDir = order
      this.applyFilters(1)
    },

    cleanFiltersClicked() {
      this.filters.type = null
      this.filters.scope = null
      this.filters.status = null
      this.filters.group = null
      this.filters.activity_id = null
      this.filters.code = null
      this.applyFilters(1)
    },

    onPageChange(page) {
      this.page = page;
      this.getTechnicalSpecifications()
    },

    goToFlow(validation_id) {
        if (validation_id) {
            this.$router.push({'name': 'validation.home', params: {validation_id}})
        }
    },

    goToActivity(activity_id) {
      this.$router.push({'name': 'activity.home', params: {activity_id}})
    },

    goToDashboard() {
      this.$router.push({'name': 'project.technical-specifications.dashboard'})
    },

    showModalReport() {
      this.listOrder = this.$refs.table_techspecs.newData
      this.showModal = true;
    },
  },

};
</script>

<style scoped>

.vertical-center {
  vertical-align: inherit !important;
}

.filters-collapse {
  margin-top: 15px;
  margin-bottom: 15px;
}

.filters-trigger:hover {
  background-color: #f8fafc;
}

/* FIX TEMPORAL. UN PAQUETE (PROBABLEMENTE @BIMDATA/VIEWER) ESTA SOBREESCRIBIENDO LA CLASE .flex-col */
@media (min-width: 1024px) {
  .lg\:flex-row {
    flex-direction: row;
  }
}
</style>
