<template>
  <section>
    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="w-1/2 modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('edit') }}</p>
        </header>
        <section class="modal-card-body">
          <!-- Cuerpo del Modal-->
          <div class="flex flex-col">
            <div class="mt-1">
              <label class="w-full">{{ $t('select_type') }}</label>
              <b-select ref="input"
                v-model="item.type"
                :placeholder="$t('select_type')"
                class="w-full"
                required
                expanded
              >
                <option v-for="option in types" :value="option" :key="option">
                  {{ option }}
                </option>
              </b-select>
            </div>

            <div class="mt-1">
              <label class="w-full">{{ $t('code') }}</label>
              <v-input
                v-model="item.code"
                placeholder="code"
                class="w-full"
                required
              />
            </div>

            <div class="mt-1">
              <label class="w-full">{{ $t('value') }}</label>
              <v-input
                v-model="item.value"
                placeholder="value"
                class="w-full"
                required
              />
            </div>
          </div>
        </section>
        <div class="modal-card-foot">
          <button class="button" :disabled="isLoading" v-on:click="isModalActive = false"><i class="fas fa-times"></i>&nbsp; {{ $t('cancel') }}</button>
          <button class="button is-success" :disabled="isLoading || (!item.value || !item.code || !item.type)" v-on:click="update()"><i class="fas fa-check"></i>&nbsp; Actualizar</button>
        </div>
      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

    </b-modal>
  </section>
</template>

<script>

  import {mapGetters} from 'vuex'
  import axios from "axios";

  export default {

    components: {
    },

    props: {
      types: { type: Array, require: false }
    },

    data() {
      return {
        isModalActive: false,
        isLoading: false,
        item: {}
      }
    },

    watch: {
    },

    created(){
    },

    computed: {
    },

    methods: {
      show(item) {
        this.item = {
          id: item.id,
          type: item.type,
          code: item.code,
          value: item.value
        }
        this.isModalActive = true
      },

      hide() {
        this.item = {}
        this.isModalActive = false
      },

      async update() {
        let body = {
          type: this.item.type,
          code: this.item.code,
          value: this.item.value
        }
        this.isLoading = true;
        let {data} = await axios.post(`/api/v2/assignment-intervention/${this.item.id}`, body)
        if (data.success) {
          this.$notify.success('success_editing')
          this.$emit('reload')
          this.hide()
        } else {
          this.$notify.error('error_saving')
        }
        this.isLoading = false;
      }
    }
}
</script>

<style scoped lang="scss">
  .modal-card {
    width: 100%;
  }

  .modal-card-body {
    min-height: 180px;
    min-width: 360px;
  }

  .modal-card-foot {
    justify-content: flex-end !important;
  }
</style>
