<template>
  <b-table :data="importsList" :loading="isLoadingTable" icon-pack="fas" paginated backend-pagination
    :per-page="per_page" :total="total" @page-change="onPageChange" class="border-blue-lighter" bordered narrowed
    striped>

    <template slot="empty">
      <div v-if="!isLoadingTable" cell-class="m-4 text-lg text-dark-grey">
        <span>{{ $t('empty_archive_info') }}</span>
      </div>
    </template>

    <template>

      <b-table-column field="description" cell-class="w-16 vertical-center" sortable>
        <template v-slot:header="">
          {{ $t('description') }}
        </template>
        <template v-slot="imports">
          {{ imports.row.description }}
        </template>
      </b-table-column>

      <b-table-column field="created_at" cell-class="w-10 vertical-center" centered sortable>
        <template v-slot:header="">
          {{ $t('date_time') }}
        </template>
        <template v-slot="imports">
          {{ new Date(imports.row.created_at).toLocaleDateString() + ' ' + new
            Date(imports.row.created_at).toLocaleTimeString() }}
        </template>
      </b-table-column>

      <b-table-column field="creator" cell-class="w-4 vertical-center" centered>
        <template v-slot:header="">
          <i class="fa fa-user"></i>
        </template>
        <template v-slot="imports">
          <b-tooltip :label="imports.row.creator.basic.fullname">
            <img :src="imports.row.creator.basic.avatar" class="w-6 h-6 overflow-hidden rounded-full">
          </b-tooltip>
        </template>
      </b-table-column>

      <b-table-column field="row_count" cell-class="w-6 vertical-center" centered>
        <template v-slot:header="">
          {{ $t('row_count') }}
        </template>
        <template v-slot="imports">
          {{ imports.row.row_count }}
        </template>
      </b-table-column>

      <b-table-column v-if="overwriteField" field="with_update" cell-class="w-4 vertical-center" centered>
        <template v-slot:header="">
          {{ $t('with_update') }}
        </template>
        <template v-slot="imports">
          {{ imports.row.with_update ? $t('yes') : $t('no') }}
        </template>
      </b-table-column>

      <b-table-column field="file_url" :label="$t('file')" cell-class="w-6 vertical-center" centered>
        <template v-slot:header="">
          {{ $t('file') }}
        </template>
        <template v-slot="imports">

          <b-tooltip :label="$t('download_import_file')" position="is-top">
            <div class="cursor-pointer text-blue-light">
              <a class="button is-rounded is-link" @click="downloadImportFile(imports.row.file_url)">
                <span class="icon">
                  <i class="fas fa-file-excel"></i>
                </span>
              </a>
            </div>
          </b-tooltip>

        </template>
      </b-table-column>

    </template>

  </b-table>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: "entity-imports-archive",

  computed: {
    ...mapGetters({
      user: 'auth/user',
      project: 'app/project',
    }),
  },

  props: {
    entityType: { type: String, required: true },
    projectId: { type: Number, required: false, default: null },
    partnerId: { type: Number, required: false, default: null },
    companyId: { type: Number, required: false, default: null },
    overwriteField: { type: Boolean, default: false }
  },

  data: () => ({
    page: 1,
    per_page: 20,
    lastPage: 1,
    total: 1,
    isLoadingTable: true,
    importsList: [],
  }),

  created() {
    this.getImportsList()
  },

  methods: {

    getImportsList() {
      let params = {
        'page': this.page,
        'per_page': this.per_page,
        'entity_type': this.entityType
      }

      // Añadimos el proyecto si lo tenemos
      if (this.projectId != null) {
        params.project_id = this.projectId
      }

      // Añadimos el partner si lo tenemos
      if (this.partnerId != null) {
        params.partner_id = this.partnerId
      }

      // Añadimos la company si la tenemos
      if (this.companyId != null) {
        params.company_id = this.companyId
      }

      let self = this
      axios.get('/api/v2/imports', { params: params })
        .then(function (response) {
          if (response.data && response.data.success) {
            self.total = response.data.total
            self.lastPage = response.data.lastPage
            self.importsList = response.data.imports
            self.isLoadingTable = false
          }
        })
        .catch(error => {
          console.log(error)
          self.isLoadingTable = false
        });
    },

    onPageChange(page) {
      this.page = page
      this.getImportsList()
    },

    downloadImportFile(url) {
      var extension = ".xlsx"

      let link = document.createElement('a')
      link.href = url
      link.download = this.$t(this.entityType + '_archive') + extension
      document.body.appendChild(link);
      link.click()
      document.body.removeChild(link);
    },

    reloadImports() {
      this.page = 1
      this.isLoadingTable = true
      this.getImportsList()
    },
  }
}
</script>

<style scoped></style>
