<template>
  <div class="flex-col">
    <b-tabs type="is-boxed" v-model="mainActiveTab">

      <b-tab-item>
        <template slot="header">
          <b-icon pack="fas" icon="tasks"></b-icon>
          <span> {{ $t('information_by_group') }} </span>
        </template>
        <div>

          <div class="flex">
            <div class="flex w-7/8">
              <treeselect
                :options="options"
                :show-count="true"
                :flatten-search-results="true"
                :placeholder="$t('select_group_menu')"
                :normalizer="normalizer"
                @input="handleSelectGroup"
              />

              <b-tooltip :label="txtTooltipExport || this.$t('export')" position="is-left">
                <div class="rounded-full bg-blue w-8 h-8 flex ml-3 items-center justify-center text-grey-lightest cursor-pointer" @click="exportReport">
                  <b-icon icon="download" pack="fas" size="is-small" v-if="! isLoadingExport" />
                  <b-icon
                    pack="fas"
                    icon="sync-alt"
                    custom-class="fa-spin"
                    v-if="isLoadingExport"
                    >
                  </b-icon>
                </div>
              </b-tooltip>
            </div>

            <div class="flex w-1/8 pl-2">
              <excel-by-activity-groups  :openWithButton="false"/>
            </div>
          </div>

          <!-- Panel 2 -->
          <div class="w-full mt-4 panel">
            <div class="flex flex-row w-full text-blue font-bold">{{ $t('group_progress') }}</div>
            <div class="flex flex-row w-full"><column-chart :data="panel2" suffix="%" :min="0" :max="100"/></div>
          </div>

          <!-- Gráficas dinámicas por grupo -->
          <div class="w-full mt-8 panel" v-for="group in panel3" :key="'panel3_'+group.name">
            <!-- Panel 3 -->
            <div class="w-full mt-8">
              <div class="flex flex-row w-full text-blue font-bold">{{ $t('progress_of') }} {{group.name}}</div>
              <div class="flex flex-row w-full"><line-chart :suffix="'%'" :min="0" :max="100" :data="group.data" /></div>
            </div>
          </div>

          <div v-if="isLoading" class="text-center my-4">
            <scale-loader></scale-loader>
          </div>

        </div>


      </b-tab-item>

      <b-tab-item>
        <template slot="header">
          <b-icon pack="fas" icon="tasks"></b-icon>
          <span> {{ $t('project_information') }}</span>
        </template>
        <div>
          <!-- info box -->
          <div class="w-full mt-8">
            <div class="flex flex-row w-full text-blue font-bold">{{ $t('project_information') }}</div>
            <div v-if="panel1.info" class="flex flex-wrap justify-center w-100">
              <div v-for="(box, index) in panel1.info" :style="'height: 160px'" class="w-1/4 mb-2" :key="'panel1_'+index">
                <div class="border-4 border-blue text-blue rounded flex items-center h-full justify-center flex-col mx-2">
                  <b class="text-center">{{ box.name }}</b>
                  <b class="text-3xl">{{box.total}}</b>
                </div>
              </div>
            </div>
          </div>

            <!-- table users info -->
            <div class=" w-full mt-8">
              <div class="flex flex-row w-full text-blue font-bold">{{ $t('information_per_user') }}</div>
              <b-table
              icon-pack="fas"
              :data="panel1.users"
              :bordered="true"
              v-if="panel1.users"
              >
                <template>
                  <b-table-column field="avatar" cell-class="w-10">
                    <template v-slot:header=""><span class="text-xs">Avatar</span></template>
                    <template v-slot="props">
                      <div class="w-8 h-8 rounded-full">
                          <img class="w-8 h-8 rounded-full" :src="props.row.avatar"/>
                      </div>
                    </template>
                  </b-table-column>

                  <b-table-column field="fullname" cell-class="w-5/12">
                    <template v-slot:header=""><span class="text-xs">{{ $t('user') }}</span></template>
                    <template v-slot="props">{{ props.row.fullname }}</template>
                  </b-table-column>

                  <b-table-column field="comments">
                    <template v-slot:header=""><span class="text-xs">{{ $t('comments') }}</span></template>
                    <template v-slot="props">{{ props.row.comments }}</template>
                  </b-table-column>

                  <b-table-column field="images">
                    <template v-slot:header=""><span class="text-xs">{{ $t('images') }}</span></template>
                    <template v-slot="props">{{ props.row.images }}</template>
                  </b-table-column>

                  <b-table-column field="documents">
                    <template v-slot:header=""><span class="text-xs">{{ $t('documents') }}</span></template>
                    <template v-slot="props">{{ props.row.documents }}</template>
                  </b-table-column>

                  <b-table-column field="create_issues">
                    <template v-slot:header=""><span class="text-xs">{{ $t('issues_created') }}</span></template>
                    <template v-slot="props">{{ props.row.create_issues }}</template>
                  </b-table-column>

                  <b-table-column field="issues">
                    <template v-slot:header=""><span class="text-xs">{{ $t('issues_participant') }}</span></template>
                    <template v-slot="props">{{ props.row.issues }}</template>
                  </b-table-column>
                </template>

                <template #empty>
                    <div class="has-text-centered">{{ $t('no_results') }}</div>
                </template>
              </b-table>
            </div>
        </div>
      </b-tab-item>

    </b-tabs>
  </div>
  </template>

  <script>
  import Vue from 'vue'
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
  import VueChartkick from 'vue-chartkick'
  import Chart from 'chart.js'
  import moment from 'moment'
  import excelByActivityGroups from '·/components/reports/excel-by-activity-groups.vue'

  // import the component
  import Treeselect from '@riophae/vue-treeselect'
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'

  Vue.use(VueChartkick, {adapter: Chart})
  Vue.use(moment)

  export default {
    middleware: 'auth',
    title: 'project_home_page_title',
    subtitle: 'project_home_page_subtitle',
    menuOption: '2-1',

    components: {
      ScaleLoader,
      Treeselect,
      excelByActivityGroups,
    },

    data: () => ({
      filters: {
        location_id: 0,
        status_id: 1,
        tag_name: '',
        parent_group_id: null
      },
      isLoading: true,
      panel1: {
        info: [],
        users: []
      },
      panel2: [],
      panel3: [],
      mainActiveTab: 0,
      options: [],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          isDisabled: node.subgroups_num == 0,
          children: node.children,
        }
      },
      isLoadingExport: false,
      txtTooltipExport: null,
    }),

    computed: {
      ...mapGetters({
        project: 'app/project',
        user: 'auth/user',
      }),
    },

    mounted() {

      this.preparePanel1()
      if (this.project == null) {

        this.$router.push('/projects');

      } else {
        this.getActivityGroupsList()

        this.getPanel2();
        this.getPanel3();
        this.getPanel1();

        let pageInfo = {
          title: this.project.name,
          subtitle: this.$t('project_home_page_subtitle'),
          menuOption: '2-1',
        }

        this.$store.dispatch('app/updatePageInfo', {info: pageInfo})
      }
    },

    methods: {

      preparePanel1() {
        this.panel1.info = [
          {
            "name": this.$t('activity').toUpperCase(),
            "total": 0
          },
          {
            "name": this.$t('measurements_loaded').toUpperCase(), // "MEDICIONES CREADAS",
            "total": 0
          },
          {
            "name": this.$t('issues').toUpperCase(),
            "total": 0
          },
          {
            "name": this.$t('flows_created').toUpperCase(), // "FLUJOS CREADOS",
            "total": 0
          },
          {
            "name": this.$t('uploaded_images').toUpperCase(), // "IMÁGENES CARGADAS",
            "total": 0
          },
          {
            "name": this.$t('uploaded_documents').toUpperCase(), // "DOCUMENTOS CARGADOS",
            "total": 0
          },
          {
            "name": this.$t('comments').toUpperCase(),
            "total": 0
          }
        ]
      },

      handleSelectGroup(value, instanceId) {
        this.filters.parent_group_id = value ?? null

        this.isLoading = true
        this.getPanel2()
        this.panel3 = []
        this.getPanel3()
      },

      async getActivityGroupsList(args = []) {

        const {data} = await axios.get(`/api/v2/project/${this.project.id}/activity/groups`, {params: args});

        if (data && data.groups) {
          this.options = data.groups;
        } else {
          this.$notify.error('error_loading_activities')
        }
      },

      async getPanel1() {
        let self = this
        let url = '/api/v2/project/' + this.project.id + '/dashboard/photovoltaicpanel1';
        await axios.get(url, {params: this.filters})
          .then(response => {
            self.panel1 = response.data.data
          })
          .catch((error) => {
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });
      },

      async getPanel2() {
        let self = this
        let url = '/api/v2/project/' + this.project.id + '/dashboard/photovoltaicpanel2';
        await axios.get(url, {params: this.filters})
          .then(response => {
            self.panel2 = response.data.data
          })
          .catch((error) => {
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });
      },

      async getPanel3() {
        let self = this
        let url = '/api/v2/project/' + this.project.id + '/dashboard/photovoltaicpanel3';
        await axios.get(url, {params: this.filters})
          .then(response => {
            self.isLoading = false;
            self.panel3 = response.data.data
          })
          .catch((error) => {
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });
      },

      async exportReport() {

        if( !this.isLoadingExport ){
          this.txtTooltipExport = this.$t('generating');

          this.$notify.success('generating_report');
          this.isLoadingExport = true;
          let self = this
          let url = '/api/v2/project/' + this.project.id + '/dashboard/exportphotovoltaic';
          await axios.get(url, {params: this.filters})
            .then(response => {
              window.open(response.data.data);

              self.$notify.success('report_generated')
              self.isLoadingExport = false;
              self.txtTooltipExport = this.$t('export')
            })
            .catch((error) => {
              //Se produjo un error
              console.log("Error loading exportación")
              self.$notify.error(error.error_msg || 'error_retrieving_data')
              self.isLoadingExport = false
              self.txtTooltipExport = this.$t('export')
            });
        }
      },
    },

  };
  </script>
