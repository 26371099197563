<template>
  <form @submit.prevent="register" @keydown="form.onKeydown($event)">
  <l-form-input :form="form" field="name" placeholder="name" size="is-medium" />
  <l-form-input :form="form" field="surname" placeholder="surname" size="is-medium" />
  <l-form-input :form="form" field="email" placeholder="email" inputType="email" size="is-medium" />
  <l-form-input :form="form" field="password" placeholder="password" inputType="password" size="is-medium" />
  <b-field>
    <v-button full :loading="form.busy" native-type="submit">
      {{ $t('register') }}
    </v-button>
  </b-field>
  <div class="has-text-centered">
    <span class="has-text-grey-light">{{ $t('already_has_account') }}</span>
    <router-link :to="{ name: 'login' }" class="link-white has-text-weight-semibold">
      {{ $t('log_in') }}
    </router-link>
  </div>
  </form>
</template>

<script>
import Form from 'vform'

export default {
  middleware: 'guest',
  layout: 'login',
  title: 'register',

  data: () => ({
    form: new Form({
      name: '',
      surname: '',
      email: '',
      password: '',
    })
  }),

  methods: {
    async register () {
      // Register the user.
      const { data } = await this.form.post('/api/v2/register')

      // Log in the user.
      const { data: { token }} = await this.form.post('/api/v2/login')

      // Save the token.
      this.$store.dispatch('auth/saveToken', { token })

      // Update the user.
      await this.$store.dispatch('auth/updateUser', { user: data })

      // Redirect home.
      this.$router.push({ name: 'project.select' })
    }
  }
};
</script>
