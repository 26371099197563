<template>
  <div class="canvasContainer" ref="canvasContainer">

    <v-stage ref="stage" class="relative"
      :config="configKonva"
      @wheel="handleWheel"
      @contextmenu="handleContextMenu"
      @click="handleClick"

      >

      <v-layer ref="refBlocks" :config="{ id: 'layerBg', name: 'layerBg', visible: showAreas }" :listening="false">
        <v-line v-for="block in blocks" :key="block.name"
          :config="block"></v-line>

        <v-text v-for="txt in txtBlocks" :key="txt.name" :listening="false"
          :config="txt"></v-text>
      </v-layer>

      <v-layer ref="layer" :config="{ id: 'layerEntities', name: 'layerEntities' }">

        <v-group v-for="pic in pics"
          :config="pic.config"
          :key="'group_' +pic.id"
          @dragend="handleDragEnd"
        >
          <v-text v-for="(obj, i) in pic.labels" :key="'text_' + i + '_' +pic.id"
            :config="obj"/>

          <v-rect v-for="(entity, index) in pic.entities " :key="entity.type + '_' + index + '_' + pic.id"
            :config="entity"
          />
        </v-group>

        <!-- <v-transformer ref="transformer"
          :config="{ resizeEnabled: false }"></v-transformer> -->

      </v-layer>

      <!-- <v-layer ref="refVectors" :config="{ id: 'layerVectors', name: 'layerVectors' }">
      </v-layer> -->

    </v-stage>
  </div>
</template>

<script>
import axios from "axios"
import Vue from 'vue';
import VueKonva from 'vue-konva';
import {KonvaViewer} from '../utils/index'

import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import store from "~/store";
import { Text } from "konva/lib/shapes/Text";

Vue.use(VueKonva);

export default {
    name: "ViewerDesign",

    components: {
    },

    props: {
      pictograms: { type: Array, default() { return [] } },
      projectId: { type: Number, require: true },
      workLayer: { type: String, default: null },

      canActionsPictogram: { type: Object, default() { return {} } }, // acciones que se puede realizar sobre el pictograma por defecto ninguna
      showLayerCompleted: { type: Boolean, default: false }, // visualiza o oculta la capa de estructuras completadas
      designMode: { type: Boolean, default: false },
      pictogramType: { type: String, default: 'structures' }, // para identificar que tipo de pictogramas se debe visualizar
      isDrawing: { type: Boolean, default: false },
      selectedTool: { type: String, default: null },
      showAreas: { type: Boolean, default: false }
    },

    data() {
      return {
        isLoading: false,
        progress: null,
        progressText: null,
        curProgressPhase: null,
        error: null,

        configKonva: {
          width: 800,
          height: 800,
          draggable: false,
          opacity: 1,
          pixelRatio: 2
        },


        filterStatuses: [], // estados filtrados segun se este visualizando estructuras hincados o modulos
        modifiedEntities: [], // lista de objetos que se modificaron y se mandaran para actualizar el general
        selectedObjects: [], // contiene los objetos seleccionados sobre los cuales se hande realizar acciones o acción

        powerPlant: { // objeto que hace contiene los datos del power plant
          id: 0,
          name: null
        },

        stage: null, // variable que contiene el stage/contenedor de grafica
        pics: [],
        scenes: [],

        selectedImage: null,
        selectedShapeName: '',

        pointsShape: [], // almacena los puntos de la forma que se ba a graficar
        totalImageLoad: 0,
        savingData: false,
        allImagesUploaded: true,

        screenshots: [],

        blocks: [], // array de objetos de block vectors
        txtBlocks: [], // array de objetos de textos
      }
    },

    computed: {
    },

    watch: {
      async pictograms(newValue, oldvalue) {
        let delItem=[]

        // Buscamos pictogramas que posterior mente se eliminaran
        console.log('newValue: ', newValue);

        this.scenes.forEach( (item, index) => {
          let key = Object.keys(item)[0]
          let node = newValue.find( p => p.id == key);
          if (node) {
            delItem.push({pos: index, id: key})
          }
        } )

        // eliminamos los pictogramas
        delItem.forEach(el => {
          let node = this.stage.findOne('#GroupPictogram_' + el.id)
          if (node) {
            node.remove()

            this.scenes.splice(el.pos, 1)

            // eliminar de pic
            let indexPic = this.pics.findIndex(p => p.id == el.id)
            if (indexPic > -1)
              this.pics.splice(indexPic, 1)
          }


        })

        if (delItem.length)
          this.stage.draw()
          console.log('this.scenes current: ', this.scenes);

        // Insertemos los que no estan insertados
        // TODO: optimizar a futuro buscar una mejor forma
        for (const element of newValue) {
          if ( ! this.scenes.find( obj => { console.log('obj: ', Object.keys(obj)[0]); console.log('element.id: ', element.id); return Object.hasOwnProperty.call(obj, element.id) }  )) {
            await this.LoadPictogramsJson(element)
          }
        }

        this._PrepareObjects()
        this.Render('structures')

        this.stage.draw()
      },

      async pictogramType(newValue, oldValue) {
        this.$emit('epc-saving', { loading: true, text: 'Cargando ' + this.$t(newValue) + '...' })
        await this.$nextTick();
        this._CacheGroups(false)
        this.Render(newValue)
      },

      designMode(newValue, oldValue)  {
        // this._CacheGroups(newValue)
      }
    },

    methods: {

      _CacheGroups(apply_cache) {

        for (const pic of this.pics) {
          let g = this.stage.find('#GroupPictogram_'+pic.id)

          if (apply_cache)
            g[0].cache()
          else
            g[0].clearCache()
        }

      },

      async Load() {
        this.isLoading = true
        this.$emit('epc-isloading', true)
        this.error = null

        let url = `/api/v2/project/${this.projectId}/power-plant` // add url
        let params = {}
        let partsPark = []
        let self = this
        await axios.get(url, { params: params })
          .then(function(response) {
            if (response.data && response.data.power_plant) {
              self.powerPlant = response.data.power_plant;

              response.data.power_plant.parts.forEach(part => {
                partsPark.push(part.pictogram_id)
              });

              //TODO: Talves esta parte a la larga seria mejor un archivo json cargar
              if (response.data.power_plant.metadata != null) {
                response.data.power_plant.metadata.areas.texts.forEach(txt => {
                  txt.draggable = true
                  self.txtBlocks.push(txt);
                })

                response.data.power_plant.metadata.areas.blocks.forEach(block => {
                  block.draggable = true
                  self.blocks.push(block);
                })
              }
              self.LoadPictograms()
            }

            self.$emit('epc-loaded', partsPark);
          })
          .catch(function(error) {
            console.warn(error);
          })
          .finally(function () {
            self.$emit('epc-isloading', false)
            self.isLoading = false
            self.progressText = null
            self.progress = null
            self.curProgressPhase = null
          });
      },

      async LoadPictograms() {

        for (const part of this.powerPlant.parts) {
          await this.LoadPictogramsJson(part.pictogram)
        }
        // console.log('load pictogramas layer orders: ', this._GetLayerOrder())
        console.log('scene: ', this.scenes)
        this._PrepareObjects()
        this.Render('structures')

      },

      async LoadPictogramsJson(pic=null) {

        this.isLoading = true
        this.$emit('epc-isloading', true)
        this.error = null

        try {
          await this.konvaViewer.Load({ url: `/api/v2/pictogram/${pic.id}/json` })
          // this.konvaViewer.SetDataPictogram(this.pictogram.scene)
          let scene = this.konvaViewer.GetEntities(pic.layers)
          let obj = {}
          obj[pic.id] = scene
          this.scenes.push(obj)

          // this.$emit('epc-loaded');
        } catch (error) {
            console.warn(error)
            this.error = error.toString()
        } finally {
            this.$emit('epc-isloading', false)
            this.isLoading = false
            this.progressText = null
            this.progress = null
            this.curProgressPhase = null
        }
      },

      _PrepareObjects() {
        for (const scene of this.scenes) {
          Object.entries(scene).forEach(([key, value]) => {
            if ( ! this.pics.find(p => p.id == key) ) {
              this.pics.push({
                id: key,
                config: {
                  id: 'GroupPictogram_' + key,
                  name: 'groupPictogram',
                  draggable: false,
                  x: 0,
                  y: 0,
                  rotation: 0
                },
                labels: value.labels,
                entities: []
              })
            }
          });
        }
      },

      _GetMetadata(pictogram_id, type) {
        if ( ! this.powerPlant.parts )
          return  {
              x: 0,
              y: 0,
              rotation:0
            }

        let part = this.powerPlant.parts.find(x => x.pictogram_id == pictogram_id)

        if (part == null || part.metadata == null)
          return null

        if (part.metadata[type])
          return {
            x: part.metadata[type].x,
            y: part.metadata[type].y,
            rotation: part.metadata[type].rotation
          }

        if (part.metadata['default'])
          return {
              x: part.metadata['default'].x,
              y: part.metadata['default'].y,
              rotation: part.metadata['default'].rotation
            }
        else
          return {
            x: 0,
            y: 0,
            rotation:0
          }
      },

      async Render(type) {
        for (const pic of this.pics) {
          let obj = this.scenes.find( scene => { return Object.keys(scene)[0] == pic.id } )
          let metadata = this._GetMetadata(pic.id, type)
          if (metadata != null) {
            pic.config.x = metadata.x
            pic.config.y = metadata.y
            pic.config.rotation = metadata.rotation
          }

          // console.log( 'obj: ', obj[key].entities )
          pic.entities = obj[pic.id].entities.filter( entity => entity.type === type )
        }

        this.stage.draw()
        this.$emit('epc-saving', { loading: false, text: null })

        this.$nextTick(() => {
          this._CacheGroups(true)
        });
      },

      GetLayers() {
        return this.konvaViewer.GetLayers()
      },

      EnableDraggable(enable) {
        this.konvaViewer.EnableDraggable(enable)
      },

      async Save() {
        // await this.sleep(1000)
        this.$emit('epc-saving', { loading: true, text: 'Generando captura de ' + this.$t(this.pictogramType) })
        await this.$nextTick();

        this.screenshots = []
        let self = this

        self.savingData = true
        // this._CacheGroups(false)
        // await this.konvaViewer.FitToScreen()
        //   .then(async result => {

            let img = this.konvaViewer.Screenshot()

            img = await this.konvaViewer.CropImage(img);

            img = await this.konvaViewer.GetBase64Image(img, true)

            // console.log(img)
            this.screenshots.push({
              name: this.pictogramType + (this.showAreas ? '_with_area' : '') + '.png',
              src: this.Base64ToBlob(img.replace('data:image/png;base64,', ''), 'image/png')
            })

            this.screenshots.push({
              name: this.pictogramType + (this.showAreas ? '_with_area' : '') + '_a4.jpg',
              src: this.Base64ToBlob(img.replace('data:image/png;base64,', ''), 'image/jpg')
            })
          // })

        this.$emit('epc-saving', { loading: true, text: 'Guardando capturas' })

        let arrAwsResponse = []
        for (const item of this.screenshots) {
          const awsr = await this.submitResource(item)
          arrAwsResponse.push(awsr)
        }

        this.$emit('epc-saving', { loading: true, text: 'Guardando datos' })
        this._CacheGroups(true)

        // primero guardamos las entidades modificadas si todo va bien recien actualizamos datos meta del pictograma y registramos
        // progreso realizado por el usuario
        let response = await this._SaveDataPowerPlant(arrAwsResponse);

        // if (response) this.$emit('epc-reload')

        this.savingData = false
        this.$emit('epc-saving', { loading: false, text: null })
      },

      Base64ToBlob(base64String, contentType) {
        contentType = contentType || '';
        var sliceSize = 1024;
        var byteCharacters = atob(base64String);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);
          var byteNumbers = new Array(slice.length);

          for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          var byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
      },

      async submitResource(resource) {
        const blob = resource.src;
        blob.name = resource.name; // TODO: al nombre del recurso añadir marca de tiempo y name company o project who prefix
        return this.store(blob, {})
        .then(async result => {
          // successFul contiene toda la lista de archivos que se han subido
          // para nuestro caso solo consideramos siempre el primero por que enviamos de a uno
          if( result.successful.length > 0 ) {
            let response = result.successful[0].response

            let params = {
              // uuid: response.uuid,
              key: response.body.key,
              // bucket: response.bucket,
              name: blob.name,
              content_type: blob.type,
              // url: response.url
            }
            return params
            // form.append("aws_response", JSON.stringify(params));
          }

          // failed contiene todos los archivos que tubieron fallo al subir
          if (result.failed.length > 0) {
            console.error('Errors:')
            result.failed.forEach((file) => {
              console.error(file.error)
            })

            return null
          }
        }).catch((error) => {
          console.log('error: ', error)
          return null
        });
      },

      // CUSTOM VAPOR STORE METHOD
      async store(file, options = null) {
        // verificamos si existe algun archivo en la lista de carga de uppy
        // si existe lo eliminamos
        if( this.keyFileUploadCurrent ) {
          this.uppy.removeFile(this.keyFileUploadCurrent);
        }

        if (typeof options.progress === 'undefined') {
            options.progress = () => {};
        }

        this.keyFileUploadCurrent = this.uppy.addFile({
          name: file.name, // file name
          type: file.type, // file type
          data: file, // file blob
          // meta: {
          //   // optional, store the directory path of a file so Uppy can tell identical files in different directories apart.
          //   relativePath: webkitFileSystemEntry.relativePath,
          // },
          source: 'Local',
          isRemote: false,
        });

        // response.data.extension = file.name.split('.').pop()
        return this.uppy.upload()
      },

      async _SaveDataPowerPlant(awsResponseImages) {
        // 1. contabilizar primero total de entidades por typo y estado
        let dataPictograms = []

        let stage = this.stage

        this.$refs.layer.getNode().find('Group').forEach(group => {

          let imgData = {
            id: group.id().replace('GroupPictogram_', ''),
            metadata: { }
          }

          if (this.pictogramType === 'structures') {
            imgData.metadata['default'] = {
              x: group.x(),
              y: group.y(),
              // width: node.width(),
              // height: node.height(),
              rotation: group.rotation()
            }
          }

          imgData.metadata[this.pictogramType] = {
            x: group.x(),
            y: group.y(),
            // width: node.width(),
            // height: node.height(),
            rotation: group.rotation()
          }

          dataPictograms.push(imgData)
        });

        // 3. preparar para mandar al backend
        let form = new FormData();

        form.append('name', 'power plant project ' + this.projectId)
        form.append('pictograms', JSON.stringify(dataPictograms))
        form.append('aws_images', JSON.stringify(awsResponseImages))

        if (this.showAreas) {

          let areas = {
            blocks: [],
            texts: [],
          }

          this.blocks.forEach(block => {
            areas.blocks.push({
              id: block.id,
              name: block.name,
              points: block.points,
              fill: block.fill,
              opacity: block.opacity,
              stroke: block.stroke,
              strokeWidth: block.strokeWidth,
              x: block.x,
              y: block.y
            })
          })


          this.txtBlocks.forEach(txt => {
            areas.texts.push({
              id: txt.id,
              name: txt.name,
              fontSize: txt.fontSize,
              text: txt.text,
              x: txt.x,
              y: txt.y
            })
          })

          form.append('metadata', JSON.stringify({ areas: areas }))
        }

        let url = `/api/v2/project/${this.projectId}/power-plant` // add url

        if (this.powerPlant.id) {
          url = `/api/v2/power-plant/${this.powerPlant.id}` // edit url
        }

        // Enviamos el form
        return await axios.post(url, form)
          .then((response) => {
            let data = response.data

            if (data && data.success) {
              this.$notify.success('success_editing')
              console.log('%cEPC-TACKER: '+ '%c power plant guardado correctamente.', 'background: #5577BB; color: #fff', 'color: #000')

              return true;
            } else if(data && data.msg){
              this.$notify.error('no_access_permissions')
            } else {
              this.$notify.error('error_saving')
            }

            return false;
          })
          .catch(error => {
            // Manejar errores aquí
            if (error.response) {
              // La solicitud fue hecha y el servidor respondió con un estado de error
              this.$notify.error(error.response.data.message)
              // console.log('Error de respuesta:', error.response.status, error.response.data);
              console.error('%cEPC-TACKER: '+ '%c Error de respuesta:', 'background: #5577BB; color: #fff', 'color: #000', error.response.status, error.response.data)
            } else if (error.request) {
              // La solicitud se realizó pero no se recibió respuesta del servidor
              this.$notify.error('No se recibió respuesta del servidor (E500)')
              console.error('%cEPC-TACKER: '+ '%c No se recibió respuesta del servidor', 'background: #5577BB; color: #fff', 'color: #000')
            } else {
              // Ocurrió un error antes de la solicitud, como una configuración incorrecta de Axios
              this.$notify.error('Error de configuración')
              console.error('%cEPC-TACKER: '+ '%c Error de configuración:', 'background: #5577BB; color: #fff', 'color: #000', error.message)
            }

            return false;
          })
      },

      _OnProgress(phase, size, totalSize) {
          if (phase !== this.curProgressPhase) {
              switch(phase) {
              case "font":
                  this.progressText = "Fetching fonts..."
                  break
              case "fetch":
                  this.progressText = "Fetching file..."
                  break
              case "parse":
                  this.progressText = "Parsing file..."
                  break
              case "prepare":
                  this.progressText = "Preparing rendering data..."
                  break
              }
              this.curProgressPhase = phase
          }
          if (totalSize === null) {
              this.progress = -1
          } else {
              this.progress = size / totalSize
          }
      },

      writeMessage(message) {
        // console.log( 'mouse hover: ', message )
      },

      handleMouseOut(event) {
        // this.writeMessage('Mouseout triangle');
        let obj = event.target
        let indexObjectSelected = this._IndexObjetoSelected(obj)
        if (indexObjectSelected === -1){
          obj.fill(this._GetFillColor(obj))
          obj.opacity(0.7)
        } else {
          obj.opacity(0.6)
        }

        if ( this.workLayer == null ) {
          this.popupPosition.x = -999
          this.popupPosition.y = 0
        }
      },

      handleMouseMove(event) {
        // if(this.isDrawing && this.pointsShape.length > 0) {
        //   // determinamos la posicion donde aparecera el pupup
        //   const position = this.konvaViewer.GetPosition(this.popupXDistanceMin, this.popupYDistanceMin);
        //   let transform = this.$refs.stage.getNode().getAbsoluteTransform().copy();
        //   transform.invert();
        //   let pint = transform.point(position);

        //   let v = this.$refs.refVectors.getNode().findOne('#vector')
        //   if(v) {
        //     this.$refs.refVectors.getNode().findOne('#circleVector').remove()
        //     v.remove()
        //   }
        //   let mode = 'brush'

        //   let lastPoint = this.pointsShape[ this.pointsShape.length - 1 ]

        //   var vector = new Konva.Line({
        //     id: 'vector',
        //     points: [ lastPoint.x, lastPoint.y, pint.x, pint.y ],
        //     stroke: 'black',
        //     strokeWidth: 0.5,
        //     lineCap: 'round',
        //     lineJoin: 'round',
        //     globalCompositeOperation: mode === 'brush' ? 'source-over' : 'destination-out',
        //   });

        //   this.$refs.refVectors.getNode().add(vector);

        //   let self = this
        //   var circle = new Konva.Circle({
        //     id: 'circleVector',
        //     x: pint.x,
        //     y: pint.y,
        //     radius: 3,
        //     fill: 'white',
        //     stroke: 'black',
        //     strokeWidth: 1,
        //     draggable: true // Permitir arrastrar los puntos
        //   });

        //   self.$refs.refVectors.getNode().add(circle);

        //   this.$refs.refVectors.getNode().batchDraw();

        // }
      },

      handleStageMouseDown(event) {
        console.log('handleClick: ', event)
        // TODO: Descomentar luego de solucionar problema
        event.evt.preventDefault()
        const pos = this.$refs.stage.getNode().getPointerPosition();
        let transform = this.$refs.stage.getNode().getAbsoluteTransform().copy();
        transform.invert();
        let pint = transform.point(pos);

        let zoomLavel = this.konvaViewer.GetZoomLavel()

        if(this.isDrawing) {
          this.pointsShape.push({
            x: pint.x,
            y: pint.y
          });

          this._DrawPoints()
        }

        if (this.selectedTool === 'pencil') {
          this._InsertTextField(pos)
        }
      },

      _DrawPoints() {
        this.$refs.refVectors.getNode().removeChildren()
        // this.$refs.refVectors.findOne('#0').remove()
        if ( this.blocks.length > 0 ) {
          this.$refs.refVectors.getNode().draw()
        }
        let mode = 'brush'

        var line = new Konva.Line({
          // id: 0,
          // name: 'block_0',
          points: [],
          stroke: 'black',
          strokeWidth: 0.5,
          lineCap: 'round',
          lineJoin: 'round',
          // closed: true,
          // tension: 1,
          // fill: '#ececec',
          globalCompositeOperation: mode === 'brush' ? 'source-over' : 'destination-out',
        });

        this.pointsShape.forEach(function(point) {
          line.points(line.points().concat([point.x, point.y]))
        })

        // Cerrar el polígono irregular
        // if (this.pointsShape.length > 1) {
        //   line.points(line.points().concat([this.pointsShape[0].x, this.pointsShape[0].y]));
        // }

        let pointInitial = this.pointsShape[0]
        let pointCurrent = this.pointsShape[ this.pointsShape.length - 1 ];
        let distance = Math.sqrt(Math.pow(pointCurrent.x - pointInitial.x, 2) + Math.pow(pointCurrent.y - pointInitial.y, 2));

        // Si la distancia entre la posición actual y el punto inicial es pequeña, cerrar la línea
        if (distance < 10 && this.pointsShape.length > 1) {
          // puntosLinea.push(puntoInicial.x, puntoInicial.y);
          line.points(line.points().concat([this.pointsShape[0].x, this.pointsShape[0].y]));
          line.fill('rgba(236, 236, 236, 0.68)') // #ececec
          line.closed(true)

          this.blocks.push({
            id: this.blocks.length + 1,
            name: 'block_' + (this.blocks.length + 1),
            points: [...line.points()],
            fill: 'rgba(236, 236, 236, 0.68)',
            closed: true,
            stroke: 'black',
            strokeWidth: 0.3,
            lineCap: 'round',
            lineJoin: 'round',
            opacity: 0.7,
            draggable: true
          })

          this.pointsShape = []
          this.$refs.refVectors.getNode().removeChildren()
          // this.$refs.refVectors.getNode().batchDraw();

          // this.$refs.refBlocks.getNode().draw()

          this.$emit('epc-viewer-design-mode-drawing', false)
          // cerrarLinea();
          // return;
        }

        this.$refs.refVectors.getNode().add(line);

        let self = this
        // Dibujar los puntos seleccionados
        this.pointsShape.forEach(function(point) {
          var circle = new Konva.Circle({
            x: point.x,
            y: point.y,
            radius: 3,
            fill: 'white',
            stroke: 'black',
            strokeWidth: 1,
            draggable: true // Permitir arrastrar los puntos
          });

          self.$refs.refVectors.getNode().add(circle);
        });

        // Renderizar la capa
        // this.$refs.refVectors.getNode().draw();

        this.$refs.refVectors.getNode().batchDraw();
      },

      handleWheel(e) {
        // stop default scrolling
        // e.evt.preventDefault();
        // this.konvaViewer.ZoomStagePoiter(e, 1.2) //1.01

        // this._Screenshot();
      },

      _InsertTextField(position) {
        this.$refs.refVectors.getNode().removeChildren()

        let txt = new Text({
          text: 'Example text',
          draggable: true,
          x: position.x,
          y: position.y,
          fontSize: 16
        })

        this.$refs.refVectors.getNode().add(txt);

        let tr = new Konva.Transformer({
          node: txt,
          enabledAnchors: ['middle-left', 'middle-right'],
          // set minimum width of text
          boundBoxFunc: function (oldBox, newBox) {
            newBox.width = Math.max(30, newBox.width);
            return newBox;
          },
        });

        txt.on('transform', function () {
          // reset scale, so only with is changing by transformer
          txt.setAttrs({
            width: txt.width() * txt.scaleX(),
            scaleX: 1,
          });
        });

        this.$refs.refVectors.getNode().add(tr);

        let self = this
        txt.on('dblclick dbltap', () => {
          // hide text node and transformer:
          txt.hide();
          tr.hide();

          // create textarea over canvas with absolute position
          // first we need to find position for textarea
          // how to find it?

          // at first lets find position of text node relative to the stage:
          var textPosition = txt.absolutePosition();

          let stage = this.$refs.stage.getNode()
          // console.log('container stage: ', stage.container().getBoundingClientRect())
          // so position of textarea will be the sum of positions above:
          // var areaPosition = {
          //   x: stage.container().offsetLeft + textPosition.x,
          //   y: stage.container().offsetTop + textPosition.y,
          // };

          var areaPosition = {
            x: stage.container().getBoundingClientRect().left + textPosition.x,
            y: stage.container().getBoundingClientRect().top + textPosition.y,
          };

          // create textarea and style it
          var textarea = document.createElement('textarea');
          document.body.appendChild(textarea);

          // apply many styles to match text on canvas as close as possible
          // remember that text rendering on canvas and on the textarea can be different
          // and sometimes it is hard to make it 100% the same. But we will try...
          textarea.value = txt.text();
          textarea.style.position = 'absolute';
          textarea.style.top = areaPosition.y + 'px';
          textarea.style.left = areaPosition.x + 'px';
          textarea.style.width = txt.width() - txt.padding() * 2 + 'px';
          textarea.style.height = txt.height() - txt.padding() * 2 + 5 + 'px';
          textarea.style.fontSize = txt.fontSize() + 'px';
          textarea.style.border = 'none';
          textarea.style.padding = '0px';
          textarea.style.margin = '0px';
          textarea.style.overflow = 'hidden';
          textarea.style.background = 'none';
          textarea.style.outline = 'none';
          textarea.style.resize = 'none';
          textarea.style.lineHeight = txt.lineHeight();
          textarea.style.fontFamily = txt.fontFamily();
          textarea.style.transformOrigin = 'left top';
          textarea.style.textAlign = txt.align();
          textarea.style.color = txt.fill();

          let rotation = txt.rotation();
          var transform = '';
          if (rotation) {
            transform += 'rotateZ(' + rotation + 'deg)';
          }

          var px = 0;
          // also we need to slightly move textarea on firefox
          // because it jumps a bit
          var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
          if (isFirefox) {
            px += 2 + Math.round(txt.fontSize() / 20);
          }
          transform += 'translateY(-' + px + 'px)';

          textarea.style.transform = transform;

          // reset height
          textarea.style.height = 'auto';
          // after browsers resized it we can set actual value
          textarea.style.height = textarea.scrollHeight + 3 + 'px';

          textarea.focus();

          function removeTextarea() {
            textarea.parentNode.removeChild(textarea);
            window.removeEventListener('click', handleOutsideClick);
            txt.show();
            tr.show();
            tr.forceUpdate();
          }

          function setTextareaWidth(newWidth) {
            if (!newWidth) {
              // set width for placeholder
              newWidth = txt.placeholder.length * txt.fontSize();
            }
            // some extra fixes on different browsers
            var isSafari = /^((?!chrome|android).)*safari/i.test(
              navigator.userAgent
            );
            var isFirefox =
              navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            if (isSafari || isFirefox) {
              newWidth = Math.ceil(newWidth);
            }

            var isEdge =
              document.documentMode || /Edge/.test(navigator.userAgent);
            if (isEdge) {
              newWidth += 1;
            }
            textarea.style.width = newWidth + 'px';
          }

          textarea.addEventListener('keydown', function (e) {

            // var dispatchForCode = function(event, callback) {
            //   var code;

            //   if (event.key !== undefined) {
            //     code = event.key;
            //   } else if (event.keyIdentifier !== undefined) {
            //     code = event.keyIdentifier;
            //   } else if (event.keyCode !== undefined) {
            //     code = event.keyCode;
            //   }

            //   callback(code);
            // };

            // hide on enter
            // but don't hide on shift + enter
            if (e.keyCode === 13 && !e.shiftKey) {
              txt.text(textarea.value);
              removeTextarea();
            }
            // on esc do not set value back to node
            if (e.keyCode === 27) {
              removeTextarea();
            }
          });

          textarea.addEventListener('keydown', function (e) {
            let scale = txt.getAbsoluteScale().x;
            setTextareaWidth(txt.width() * scale);
            textarea.style.height = 'auto';
            textarea.style.height =
              textarea.scrollHeight + txt.fontSize() + 'px';
          });

          function handleOutsideClick(e) {
            if (e.target !== textarea) {
              txt.text(textarea.value);
              removeTextarea();

              self.txtBlocks.push({
                id: self.txtBlocks.length + 1,
                name: 'txtblock_' + (self.txtBlocks.length + 1),
                text: txt.text(),
                draggable: true,
                x: txt.x(),
                y: txt.y(),
                fontSize: txt.fontSize(),
                fontStyle: 'bold'
              })
              console.log( 'txtBlocks: ', self.txtBlocks )
              self.$refs.refVectors.getNode().removeChildren()
            }
          }
          setTimeout(() => {
            window.addEventListener('click', handleOutsideClick);
          });
        });

        this.$emit('epc-viewer-design-selected-tool', null)
      },

      handleContextMenu(e) {
        // prevent default behavior
        // console.log('handleContextMenu: ', e)
        // e.evt.preventDefault();
        // e.evt.stopPropagation()
        // const stage = this.$refs.stage.getNode();

        // if (e.target === stage) {
        //   // if we are on empty place of the stage we will do nothing
        //   return;
        // }
        // let currentShape = e.target;
        // console.log('handleContextMenu: ', currentShape)
        // show menu
        // menuNode.style.display = 'initial';
        // var containerRect = stage.container().getBoundingClientRect();
        // menuNode.style.top =
        //   containerRect.top + stage.getPointerPosition().y + 4 + 'px';
        // menuNode.style.left =
        //   containerRect.left + stage.getPointerPosition().x + 4 + 'px';
      },

      async _Screenshot() {
        return new Promise((resolve, reject) => {
          // let timeOut = null;
          // const l = ()=>{
          //     timeOut && window.clearTimeout(timeOut);
          //     try {
          //       console.log('screenshot')\
              this.$emit('epc-screenshot', this.konvaViewer.Screenshot());
              resolve()
          //       timeOut = window.setTimeout(l, 1e3)
          //     } catch (e) {
          //       console.log(e)
          //     }
          // };
          // l()
        })
      },

      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },

      ZoomIn() {
        this.konvaViewer.ZoomStage(1.2)
      },

      ZoomOut() {
        this.konvaViewer.ZoomStage(0.8)
      },

      FitToScreen() {

        return new Promise((resolve, reject) => {

          this.konvaViewer.FitToScreen()
            .then(result => {
              resolve();//this._Screenshot()
            })

        })
      },

      // Función para manejar el evento de redimensionamiento
      handleResize() {
        this.konvaViewer.Resize()
      },

      _AllowedAction(action) {
        if (Object.keys(this.canActionsPictogram).length === 0)
          return false;

        return this.canActionsPictogram[action] === 'undefined' ? false : this.canActionsPictogram[action]
      },

      _GetLayerOrder() {
        const stage = this.$refs.stage.getStage();
        return stage.getChildren().map(layer => layer.getAttr('id'));
      },







    handleDragover(e) {
      e.preventDefault(); // !important
    },

    handleClick(e) {
      if (! this.designMode)
        return

      const mousePos = this.stage.getPointerPosition();

      this.selectedGroupId = null;

      this.$refs.layer.getNode().find('Group').forEach(group => {
        const groupNode = group;
        const box = groupNode.getClientRect();

        if (this.isPointInRect(mousePos, box) && this.selectedGroupId == null) {
          this.selectedGroupId = group.id();
        }
      });

      if (this.selectedGroupId) {
        const selectedGroupNode = this.stage.findOne(`#${this.selectedGroupId}`);
        selectedGroupNode.draggable(false);
        selectedGroupNode.moveToTop();
        selectedGroupNode.moveTo( this.$refs.layer.getNode() )
        selectedGroupNode.startDrag()
      }
    },

    handleDragEnd(event) {
      const group = event.target;
      console.log(`Group ${group.getAttr('id')} moved to (${group.x()}, ${group.y()})`);
    },

    isPointInRect(point, rect) {
      return point.x >= rect.x && point.x <= rect.x + rect.width &&
              point.y >= rect.y && point.y <= rect.y + rect.height;
    },

    handleClickOld(e) {
      if (! this.designMode)
        return

      // const stage = this.$refs.stage.getNode();
      console.log(' e.target.getParent().className: ',  e.target.getParent().getType())
      console.log(' e.target.getParent().className: ',  e.target.getParent().width())
      console.log(' e.target.getParent().className: ',  e.target.getParent().height())

      // clicked on stage - clear selection
      // if (e.target === e.target.getStage()) {
      if (e.target === this.stage) {
        // this.selectedShapeName = '';
        this.updateTransformer();
        return;
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer =
        e.target.getParent().className === 'Transformer';
      if (clickedOnTransformer) {
        return;
      }

      const node = e.target.findAncestor('.groupPictogram', true);
      console.log('node: ', node)

      // var clickedGroup = e.target.findAncestor('Group');
      //descomentar esto luego
      // if (node) {
      //   // transformer.nodes([clickedGroup]);
      //   this.updateTransformer(node);
      //   // layer.draw();
      // }

      // find clicked rect by its name
      // const name = e.target.name();
      // console.log( name )
      // const rect = this.rectangles.find((r) => r.name === name);
      // if (rect) {
      //   this.selectedShapeName = name;
      // } else {
      //   this.selectedShapeName = '';
      // }
      // this.updateTransformer(node);
    },

    updateTransformer(node=null) {
      // here we need to manually attach or detach Transformer node
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const { selectedShapeName } = this;

      // const selectedNode = stage.findOne('.' + selectedShapeName);
      // // do nothing if selected node is already attached
      // if (selectedNode === transformerNode.node()) {
      //   return;
      // }

      if (node === transformerNode.node()) {
        return;
      }


      // if (selectedNode) {
      if (node) {
        // attach to another node
        // transformerNode.nodes([selectedNode]);
        transformerNode.nodes([node]);
      } else {
        // remove transformer
        transformerNode.nodes([]);
      }
    },

    handleTransformEnd(e) {
      // shape is transformed, let us save new attrs back to the node
      // find element in our state
    //   const rect = this.rectangles.find(
    //     (r) => r.name === this.selectedShapeName
    //   );
    //   // update the state
    //   rect.x = e.target.x();
    //   rect.y = e.target.y();
    //   rect.rotation = e.target.rotation();
    //   rect.scaleX = e.target.scaleX();
    //   rect.scaleY = e.target.scaleY();

    //   // change fill
    //   rect.fill = Konva.Util.getRandomColor();
    },

    handleDragStart() {
      this.isDragging = true;
    },
    handleDragEnd() {
      this.isDragging = false;
    },
    _GetImageConfig(obj) {
      let config = {
        neme: 'pic_' + obj.id,
        id: 'pic_' + obj.id
      }

      this.imageConfig.name = 'pic_' + obj.id
      this.imageConfig.id = 'pic_' + obj.id // asignamos igual para buscar por #id
      config.x = obj.x ? obj.x : this.imageConfig.x
      config.y = obj.y ? obj.y : this.imageConfig.y
      config.width = this.imageConfig.width
      config.height = this.imageConfig.height

      if (obj.rotation) {
        config.rotation = obj.rotation
      }
      return config
    },
  },

    created() {
      this.uppy = new Uppy({
        debug: process.env.NODE_ENV === 'development', // activamos debug para development
        autoProceed: false,
        allowMultipleUploads: false,
      })
      .use(AwsS3Multipart, {
        limit: 3,
        companionUrl: '/api/v2/',
        companionHeaders: {
          'Authorization': "Bearer " + store.getters["auth/token"]
        }
      })
      // .on('upload-success', (file, response) => { this.onUploadSuccess(file, response) } )
      .on('upload-success', (file, response) => { // callback deveulto cuando el file ha sido subido correctamente
        console.log('%cEPC-TACKER: '+ '%c file ' + file.data.name + ' subido correctamente', 'background: #5577BB; color: #fff', 'color: #000')
      })
      .on('upload-progress', (file, progesss) => { // callback devuelto con el progreso de subido del archivo
        this.uploadPercentage = parseInt(
          Math.round((progesss.bytesUploaded * 100) / progesss.bytesTotal)
        );
      })
      .on('upload-error', (file, error, response) => { // callback devuelto si ha ocurrido algun error en la subida
        console.error('%cEPC-TACKER: '+ '%c a ocurrido un error al subir el archivo ' + file.data.name, 'background: #5577BB; color: #fff', 'color: #000', error)
      })

    },

    mounted() {
      this.stage = this.$refs.stage.getStage()
      this.konvaViewer = new KonvaViewer(this.$refs.canvasContainer, this.stage)
      this.Load()

      window.addEventListener('resize', this.handleResize);

      this.$emit('epc-mounted')
    },

    destroyed() {
      this.konvaViewer.Destroy()
      this.konvaViewer = null
    },

    beforeDestroy() {
      // Elimina el observador del evento de redimensionamiento antes de destruir el componente
      window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>

.canvasContainer {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 100px;
    min-height: 100px;

    background-color: #fefefe;
    .progress {
        position: absolute;
        z-index: 20;
        width: 90%;
        margin: 20px 5%;

        .progressText {
            margin: 10px 20px;
            font-size: 14px;
            color: #262d33;
            text-align: center;
        }
    }

    .error {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 20;
        padding: 30px;

        img {
            width: 24px;
            height: 24px;
            vertical-align: middle;
            margin: 4px;
        }
    }
}

</style>
