<template>
  <div>
    <div>
      <p>Seleccione las validaciones y la acción a realizar</p>
    </div>

    <div class="flex flex-row space-x-4">

      <div class="panel">
        <div class="text-sm w-full">
          <b-table
            icon-pack="fas"
            :data="templates"
            :paginated="isPaginated"
            :per-page="perPage"
            :bordered="true"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :default-sort-direction="defaultSortDirection"
            class="text-xs border-blue-lighter"
            v-if="loaded"

            checkable
            :checked-rows.sync="checkedRows"
            @check="handleCheck"
            @check-all="handleCheckAll"
          >
            <template>
              <b-table-column field="code" sortable>
                <template v-slot:header="">{{ $t('code') }}</template>
                <template v-slot="props">{{ props.row.code }}</template>
              </b-table-column>

              <b-table-column field="name" sortable>
                <template v-slot:header="">{{ $t('name') }}</template>
                <template v-slot="props">{{ props.row.name }}</template>
              </b-table-column>
            </template>

            <template slot="bottom-left">
              <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
              <b>{{ $t("total") }}</b>: {{ this.templates.length }}
            </template>

            <template #empty>
              <div class="has-text-centered">{{ $t('no_results') }}</div>
            </template>

          </b-table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import entityList from '../utils/entity-list.vue';


export default {
  components: {
    entityList
  },

  props: {
    clearSelectItems: { type: Boolean, default: false }
  },


  data: () => ({
    templates: [],
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 20,
    loaded: false,

    checkedRows: [],

    boxFullWidth: false,
    iconRemoveHovered: null,
  }),

  computed: {
    ...mapGetters({
      company: 'app/company',
    }),
  },

  mounted () {
    this.getTemplates()
  },

  watch: {
    clearSelectItems(newVal, oldVal) {
      if( newVal )
        this.checkedRows = []
        this.checkedSubRows = []
    }
  },

  methods: {

    async getTemplates () {
      const { data } = await axios.get("/api/v2/validations/templateImportsList", {'params' : { company_id: this.company.id }});

      if (data.templates) {
        this.templates = data.templates;
      } else {
        // Error
        console.log('error validation: ', data);
        this.$notify.error(data.msg || 'error_retrieving_data')
      }
      this.loaded = true
    },

    handleCheck(checkedList, row) {
      this.dispatchSelected(checkedList)
    },

    handleCheckAll(checkedList) {
      this.dispatchSelected(checkedList)
    },

    dispatchSelected(checkedList) {
      this.$emit('selected-items', checkedList)
    },
  },

};
</script>
