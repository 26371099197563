<template>
    <div class="page-with-top-bar">
      <project-admin-quick-buttons hiddenOption="documents_folders"/>
        <section>
            <div class="mt-4">
                <b-tabs type="is-boxed" v-model="mainActiveTab">
                    <b-tab-item>
                        <template slot="header">
                            <b-icon pack="fas" icon="file-import"></b-icon>
                            <span> {{ $t('import_folders') }} </span>
                        </template>
                        <div>
                            <font class="text-xs">Las actividades proporcionadas en el excel y que no sean de este proyecto serán ignoradas.</font>
                            <import-entities
                                    :can-overwrite="true"
                                    :project-id="project_id"
                                    :with-history="false"
                                    entity-type="document-folders"
                            >
                              <template v-slot:descripcion>
                                <span class="text-orange-dark">{{$t('activity_import_warning')}}</span>
                              </template>
                            </import-entities>
                        </div>
                    </b-tab-item>

                    <!-- <b-tab-item> algun dia tendra un exportador
                        <template slot="header">
                            <b-icon pack="fas" icon="file-export"></b-icon>
                            <span> {{ $t('export_activities') }} </span>
                        </template>
                        <div>
                            <export-activities :project_id="project_id"></export-activities>
                        </div>
                    </b-tab-item> -->

                </b-tabs>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import importEntities from '../../components/import-entities'
import exportActivities from '../../components/export-activities'
import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons'

export default {
  components: {
    importEntities,
    exportActivities,
    ProjectAdminQuickButtons,
  },
  middleware: "auth",
  title: "import_folders",
  menuOption: "0-2",

  computed: {
    ...mapGetters({
      navData: 'app/navData',
    }),
  },

  data: () => ({
    project_id: null,
    mainActiveTab: 0
  }),

  created() {
    this.$options.subtitle = ['project_actions_page_subtitle', [this.navData.project_name.toUpperCase()]]
    this.project_id = this.navData.project_id
  },

  methods: {

  },
};

</script>
