<template>
  <div class="page-with-top-bar">
    <section>
      <div class="mt-4">
        <b-tabs type="is-toggle" v-model="currentActiveTab" expanded size="is-medium">
          <b-tab-item :disabled="sendProcessData">
            <template slot="header">
              <b-icon pack="fas" icon="tasks"></b-icon>
              <span> {{ $t('step') + ' 1' }} </span>
            </template>

            <list-validations ref="list_validations"
              :clearSelectItems="validations.length == 0"
              @selected-items="onSelectedItems"
            />
          </b-tab-item>

          <!-- Acción a realizar sobre la lista previamente seleccionada -->
          <b-tab-item :disabled="(currentActiveTab != 1 && !selectedAction) || sendProcessData">
            <template slot="header">
              <b-icon pack="fas" icon="bolt"></b-icon>
              <span>{{ $t('step') + ' 2' }}</span>
            </template>
            <div v-if="selectedAction">

              <label class="font-bold">Acción masiva: {{ selectedAction.title }}</label>
              <div v-if="selectedAction.name != 'remove_users'">
                <p>Seleccione el grupo para realizar la acción seleccionada.</p>
                <list-groups ref="list_groups"
                  :action="selectedAction"
                  :show-description="false"
                  @selected-linked-entities="(items) => { linkedEntities = items }"
                />
              </div>

              <div v-if="showListUsers()">
                <p>Seleccione los usuarios para realizar la acción seleccionada.</p>
                <list-users ref="list_users"
                  :action="selectedAction"
                  :with-collaborators="true"
                  :checkable="true"
                  :show-description="false"
                  @selected-linked-entities="(items) => { linkedUsers = items }"
                />
              </div>

            </div>
          </b-tab-item>

          <!-- Muestra resumen de la acción y opcion para procesar la acción o cancelar -->
          <b-tab-item :disabled="currentActiveTab != 2 || !canComplete()">
            <template slot="header">
              <b-icon pack="fas" icon="hourglass-end"></b-icon>
              <span>{{ $t('step') + ' 3' }}</span>
            </template>

            <WizardBulkComplete 
              :validations="validations"
              :linkedUsers="linkedUsers"
              :action="selectedAction"
              :linkedEntities="linkedEntities"
              v-if="canComplete()"
              ref="wizard_complete"
            />
          </b-tab-item>

        </b-tabs>

        <div class="sticky bottom-0 ml-4 mr-4">
          <div class="panel mt-0 mb-0">
            <BulkActionsButtons 
              title="Flujos seleccionados"
              :show="validations.length > 0 && currentActiveTab == 0"
              :clearSelect="!selectedAction"
              :set-actions="actions"
              @select-action="onSelectAction"
            />
            <WizardBulkButtons :step="currentActiveTab"
              :complete="canComplete()"
              :processingData="sendProcessData"
              @wizard-previus="prevTab"
              @wizard-next="nexTab"
            />
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
  import axios from "axios";
  import { mapGetters } from 'vuex'
  import ListValidations from "../../../components/bulk-actions/validations/validations-list.vue";
  import ListGroups from "../../../components/bulk-actions/validations/groups-list.vue";
  import ListUsers from '·/components/bulk-actions/users-list';
  import WizardBulkButtons from '·/components/bulk-actions/wizard-bulk-buttons';
  import WizardBulkComplete from '·/components/bulk-actions/validations/wizard-bulk-complete';
  import BulkActionsButtons from '·/components/bulk-actions/bulk-actions-buttons';

  export default {
    middleware: "auth",
    title: "bulk_actions_page_title",
    menuOption: "0-5",

    components: {
      ListValidations,
      ListGroups,
      ListUsers,
      WizardBulkButtons,
      BulkActionsButtons,
      WizardBulkComplete
    },

    computed: {
      ...mapGetters({
        company: 'app/company',
      }),
    },

    data: () => ({
      currentActiveTab: 0,
      validations: [],
      linkedEntities: [],
      linkedUsers: [],
      selectedAction: null,
      sendProcessData: false,
      project: null,
      actions: [
        {type: 10, name: 'assign_group', text: 'assign_group', title: 'asignación de grupo'},
        {type: 11, name: 'assign_group_users', text: 'assign_group_users', title: 'asignar usuarios a grupo'},
        {type: 12, name: 'remove_users', text: 'remove_users', title: 'eliminar usuarios'},
      ],
    }),

    watch: {
    },

    mounted() {
    },

    created() {
      // this.getProjectData()
    },

    methods: {

      async getProjectData() {
        let params = {};
        const {data} = await axios.get("/api/v2/project/" + this.projectId, {params: params});
        if (data) {
          this.project = data
          // this.$refs.model_tags.initTags(data.tags);
          this.$options.subtitle = ["project_actions_page_subtitle", [this.project.name.toUpperCase()]];

        } else {
          this.$notify.error("error_loading_project_data");
        }

        this.isLoading = false;
      },

      prevTab () {
        if (this.currentActiveTab === 0 ) return
        const newTabIndex = this.currentActiveTab - 1
        const oldTabIndex = this.currentActiveTab
        this.changeTab(newTabIndex)
      },

      nexTab() {
        if (this.currentActiveTab === 2) {
          this.completeWizard()
          return
        }

        if ( this.currentActiveTab == 0 && !this.validStep1() ) return
        if ( this.currentActiveTab == 1 && !this.validStep2() ) return

        const newTabIndex = this.currentActiveTab + 1
        const oldTabIndex = this.currentActiveTab
        this.changeTab(newTabIndex)
      },

      async completeWizard() {
        this.sendProcessData = true
        await this.$refs.wizard_complete.sendForm();
        this.sendProcessData = false

        // limpiamos datos seleccionados
        this.linkedEntities = []
        this.linkedUsers = []
        this.selectedAction = null
        this.validations = []
        this.changeTab(0)
      },

      async changeTab (newTabIndex) {
        this.currentActiveTab = newTabIndex

        await this.$nextTick()
      },

      validStep1() {
        if( this.selectedAction && this.validations.length > 0)
          return true

        // Mostramos el error
        this.$toast.open({
          message: 'Debe seleccionar al menos un flujo y una acción',
          type: "is-danger",
          position: "is-top-right",
        });

        return false
      },

      validStep2() {
        let errorMessage = null
        if(this.selectedAction.name == 'assign_group' && this.linkedEntities.length == 0) {
          errorMessage = 'Debe seleccionar al menos un grupo'
        }
        if(this.selectedAction.name == 'assign_group_users' && (this.linkedEntities.length == 0 || this.linkedUsers.length == 0)) {
          errorMessage = 'Debe seleccionar al menos un grupo y un usuario'
        }
        if(this.selectedAction.name == 'remove_users' && this.linkedUsers.length == 0) {
          errorMessage = 'Debe seleccionar al menos un usuario'
        }

        if (!errorMessage) {
          return true
        }

        // Mostramos el error
        this.$toast.open({
          'message': errorMessage,
          'position': 'is-top-right',
          'type': 'is-danger'
        })

        return false
      },

      showListUsers() {
        return this.selectedAction && (this.selectedAction.name === 'assign_group_users' || this.selectedAction.name === 'remove_users')
      },

      async onSelectAction(action) {
        this.selectedAction = action;
        this.linkedEntities = [];

        this.$nextTick(() => {
          if( this.selectedAction.name === 'assign_group_users' || this.selectedAction.name === 'remove_users')
            this.$refs.list_users.reload()
        })
      },

      onSelectedItems(items) {
        this.validations = items

        if( !items.length )
          this.selectedAction = null
      },

      canComplete() {
        if (!this.selectedAction) {
          return false
        }
        if(this.selectedAction.name == 'assign_group' && this.linkedEntities.length == 0) {
          return false
        }
        if(this.selectedAction.name == 'assign_group_users' && (this.linkedEntities.length == 0 || this.linkedUsers.length == 0)) {
          return false
        }
        if(this.selectedAction.name == 'remove_users' && this.linkedUsers.length == 0) {
          return false
        }
        return true
      }
    }
  };
</script>
