<template>
<div>

  <div class="flex flex-wrap">
    <v-button icon="arrow-left" @click="goToPage('validations')">{{ $t('validations') }}</v-button>
  </div>

  <div class="mt-4">
    
    <div>
      <div class="top-bar flex justify-end">
        <v-button
          @click="showCreate = !showCreate"
          icon="plus">
          Nueva Regla
        </v-button>
      </div>
      <div>
        <transition
          name="custom-classes-transition"
          enter-active-class="animated fadeInUp"
          leave-active-class="animated fadeOutDown">
          <div class="panel">

            <div class="w-full flex flex-wrap" v-show="showCreate">
              <div class="panel">
                <form @submit.prevent="create" class="w-full flex">
                  <div class="flex-grow pr-3">
                    <label for="code" class="w-full">{{ $t('code') }}</label>
                    <v-input
                      v-model="item.code"
                      title="code"
                      id="code"
                      name="code"
                      placeholder="code"
                      class="w-full"
                      required
                    />
                  </div>

                  <div class="flex-grow pr-3">
                    <label for="name" class="w-full">{{ $t('name') }}</label>
                    <v-input
                      v-model="item.name"
                      title="name"
                      id="name"
                      name="name"
                      placeholder="name"
                      class="w-full"
                      required
                    />
                  </div>

                  <div class="flex-grow pr-3">
                    <label for="description" class="w-full">{{ $t('description') }}</label>
                    <v-input
                      v-model="item.description"
                      title="description"
                      id="description"
                      name="description"
                      placeholder="description"
                      class="w-full"
                    />
                  </div>

                  <div class="flex-grow-0 pr-3 inline-flex self-end">
                    <v-button
                      native-type="submit"
                      :disabled="!item.name || !item.code"
                    >{{ $t('save') }}
                    </v-button>
                  </div>
                </form>
              </div>
            </div>

            <b-table
              show-empty
              :data="data"
              :loading="isLoading"
              paginated
              striped
              bordered
              icon-pack="fas"
            >
              <template v-slot:empty="">
                <h4>{{ $t('no_rows') }}</h4>
              </template>

              <template>
                <b-table-column field="code" >
                  <template v-slot:header="">
                    {{ $t('code') }}
                  </template>
                  <template v-slot="props">
                    <div v-if="editItem && editItem.id == props.row.id">
                      <v-input
                        v-model="editItem.code"
                        title="code"
                        id="code"
                        name="code"
                        :value="props.row.code"
                        placeholder="code"
                        class="w-full"
                      />
                    </div>
                    <div v-else>
                      {{ props.row.code }}
                    </div>
                  </template>
                </b-table-column>

                <b-table-column field="name" >
                  <template v-slot:header="">
                    {{ $t('name') }}
                  </template>
                  <template v-slot="props">
                    <div v-if="editItem && editItem.id == props.row.id">
                      <v-input
                        v-model="editItem.name"
                        title="name"
                        id="name"
                        name="name"
                        :value="props.row.name"
                        placeholder="name"
                        class="w-full"
                      />
                    </div>
                    <div v-else>
                      {{ props.row.name }}
                    </div>
                  </template>
                </b-table-column>

                <b-table-column field="description" >
                  <template v-slot:header="">
                    {{ $t('description') }}
                  </template>
                  <template v-slot="props">
                    <div v-if="editItem && editItem.id == props.row.id">
                      <v-input
                        v-model="editItem.description"
                        title="description"
                        id="description"
                        name="description"
                        :value="props.row.description"
                        placeholder="description"
                        class="w-full"
                      />
                    </div>
                    <div v-else>
                      {{ props.row.description }}
                    </div>
                  </template>
                </b-table-column>

                <b-table-column field="actions"  centered width="80">
                  <template v-slot:header="">
                    {{ $t('actions') }}
                  </template>
                  <template v-slot="props">

                    <div class="w-full h-full flex items-center justify-around">
                        <div v-if="editItem && editItem.id == props.row.id">
                          <b-tooltip :label="$t('save')" position="is-left">
                            <div @click="update()" class="cursor-pointer flex text-blue">
                              <b-icon pack="fas" icon="save" />
                            </div>
                          </b-tooltip>
                          <b-tooltip
                            :label="$t('cancel')"
                            type="is-danger"
                            position="is-left"
                          >
                            <div @click="() => { editItem = {} }" class="cursor-pointer flex text-red">
                              <b-icon pack="fas" icon="ban" />
                            </div>
                          </b-tooltip>
                        </div>

                        <div v-else>
                          <b-tooltip :label="$t('edit')" position="is-left">
                            <div @click="() => {editItem = props.row}" class="cursor-pointer flex text-blue">
                              <b-icon pack="fas" icon="pencil-alt"/>
                            </div>
                          </b-tooltip>
                          <b-tooltip
                            :label="$t('remove')"
                            type="is-danger"
                            position="is-left"
                          >
                            <div @click="remove(props.row)" class="cursor-pointer flex text-red">
                              <b-icon pack="fas" icon="trash-alt"/>
                            </div>
                          </b-tooltip>
                        </div>

                    </div>
                  </template>
                </b-table-column>
              </template>
            </b-table>

          </div>
        </transition>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from 'axios'

export default {
  name: 'project_rules',
  title: "project_rules",
  components: {
  },
  data: () => ({
    projectId: null,
    data: [],
    isLoading: true,
    showCreate: false,
    item: {
      project_id: null,
      code: "",
      name: "",
      description: ""
    },
    editItem: {}
  }),


  created() {

    this.projectId = this.$route.params.project_id || null

    if( this.projectId )
    {
      let projectName = this.navData.project_name
        ? this.navData.project_name.toUpperCase()
        : "";
      this.$options.subtitle = ["project_actions_page_subtitle", [projectName]];
    }

  },

  computed: {
    ...mapGetters({
      navData: "app/navData",
    }),
  },

  mounted() {
    this.getAll();
    this.isLoading = false;
  },

  methods: {

    async getAll() {
      let {data} = await axios.get('/api/v2/project-rules', {params: {p: this.projectId}});

      if (data.success) {
        this.data = data.data
      } else {
        this.$notify.error("error_loading_data");
      }
    },

    reload() {
      this.isLoading = true;
      this.getAll();
      this.isLoading = false;
    },

    async create() {
      this.item.project_id = this.projectId

      this.isLoading = true;
      let {data} = await axios.post('/api/v2/project-rule', this.item)
      if (data.success) {
        this.$notify.success('save_successful')
        this.getAll();
        this.item.code = null
        this.item.name = null
        this.item.description = null
      } else {
        this.$notify.error('error_saving')
      }
      this.isLoading = false;
    },

    async update() {
      let body = {
        code: this.editItem.code,
        name: this.editItem.name,
        description: this.editItem.description,
      }
      this.isLoading = true;
      let {data} = await axios.post(`/api/v2/project-rule/${this.editItem.id}`, body)
      if (data.success) {
        this.$notify.success('success_editing')
        this.getAll();
        this.editItem = {}
      } else {
        this.$notify.error('error_saving')
      }
      this.isLoading = false;
    },

    async remove(type) {
      
      this.$dialog.confirm({
        title: this.$t("delete"),
        message: this.$t("delete_confirm_text", ['la regla de proyecto ', type.name]),
        confirmText: this.$t("delete"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true;
          let url = `/api/v2/project-rule/${type.id}`;
          const { data } = await axios.delete(url);

          if (data.success) {
            this.$toast.open({
              message: this.$t("removed_successfully"),
              type: "is-success",
              position: "is-top-right"
            });

            this.getAll();
          } else {
            // Error
            this.$toast.open({
              message: this.$t("remove_error"),
              type: "is-danger",
              position: "is-top-right"
            });
          }
          this.isLoading = false;
        }
      });
    },

    goToPage(destiny) {

      switch (destiny) {

        case 'validations':
          this.$router.push({
            name: 'project.admin.validations', params: {
              project_id: this.projectId,
            }
          })
          break;
      }

    },

  },
};
</script>
<style scoped>
.ghost {
  opacity: 0.8;
}
</style>
