<template>
  <section>
    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <!-- <div class="mx-auto flex h-12 w-12 flex-shrink-0- items-center justify-center rounded-full bg-blue-lighter sm:mx-0 sm:h-10 sm:w-10">
              <b-icon class="h-6 w-6 text-blue-dark" icon="file-pen" pack="fas"></b-icon>
            </div> -->
            <div class="mt-3 text-center sm:mt-0 sm:text-left">
              <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">{{ $t('edit') + ' orden de trabajo' }}</h3>
              <!-- <p class="text-sm text-gray-500">Are you sure you want to deactivate your account? All of your data will be permanently removed. This action cannot be undone.</p> -->
            </div>
          </div>
          <div class="mt-2 space-y-4">
            <work-orders-form ref="refWorkOrderForm"
              :data="workOrder"
            />
          </div>
        </div>
        <div class="px-4 py-3 justify-center sm:flex sm:px-6">
          <button class="button is-success mr-2" :disabled="isLoading" v-on:click="save()"><i class="fas fa-floppy-disk"></i>&nbsp; Guardar</button>
          <button class="button mr-2" :disabled="isLoading" v-on:click="isModalActive = false"><i class="fas fa-times"></i>&nbsp; {{ $t('cancel') }}</button>
        </div>
      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

    </b-modal>
  </section>
</template>

<script>
  import axios from "axios";
  import WorkOrdersForm from './work-orders-form.vue';

  import Uppy from '@uppy/core'
  import AwsS3Multipart from '@uppy/aws-s3-multipart'
  import store from "~/store";

  export default {

    components: {
      WorkOrdersForm
    },

    props: {
      projectId: {type: Number, required: true},
    },

    data() {
      return {
        isModalActive: false,
        isLoading: false,
        workOrder: {

        },
        uppy: null, // instancia de la libreria para subir archivos
      }
    },

    watch: {
    },

    created(){
      this.uppy = new Uppy({
        debug: process.env.NODE_ENV === 'development', // activamos debug para development
        autoProceed: false,
        allowMultipleUploads: false,
      })
      .use(AwsS3Multipart, {
        limit: 3,
        companionUrl: '/api/v2/',
        companionHeaders: {
          'Authorization': "Bearer " + store.getters["auth/token"]
        }
      })
      // .on('upload-success', (file, response) => { this.onUploadSuccess(file, response) } )
      .on('upload-success', (file, response) => { // callback deveulto cuando el file ha sido subido correctamente
        console.log('%cEPC-TACKER: '+ '%c file ' + file.data.name + ' subido correctamente', 'background: #5577BB; color: #fff', 'color: #000')
      })
      .on('upload-progress', (file, progesss) => { // callback devuelto con el progreso de subido del archivo
        this.uploadPercentage = parseInt(
          Math.round((progesss.bytesUploaded * 100) / progesss.bytesTotal)
        );
      })
      .on('upload-error', (file, error, response) => { // callback devuelto si ha ocurrido algun error en la subida
        console.error('%cEPC-TACKER: '+ '%c a ocurrido un error al subir el archivo ' + file.data.name, 'background: #5577BB; color: #fff', 'color: #000', error)
      })
    },

    computed: {
    },

    methods: {
      show(workOrder) {
        this.workOrder = {
          id: workOrder.id,
          project_id: workOrder.project_id,
          code: workOrder.code,
          description: workOrder.description
        }

        if (workOrder.partner_id) {
          this.workOrder.partner_id = workOrder.partner_id
        }
        this.isModalActive = true
      },

      hide() {
        this.workOrder = {}
        this.isModalActive = false
      },

      async save() {

        let order = this.$refs.refWorkOrderForm.getDataForm()

        if ( !this.validateData(order) )
          return

        let document_id = null
        if ( order.file != null )
          document_id = await this.uploadFile(order.file)

        let body = {
          project_id: this.projectId,
          document_id: document_id,
          code: order.code,
          description: order.description
        }

        if (this.workOrder.partner_id)
          body.partner_id = this.workOrder.partner_id

        this.isLoading = true;

        let url = order.id ? `/api/v2/work-orders/${order.id}` : `/api/v2/work-orders`;
        let {data} = await axios.post(url, body)

        if (data.success) {
          this.$notify.success('success_editing')
          this.$emit('reload')
          this.hide()
        } else {
          this.$notify.error('error_saving')
        }
        this.isLoading = false;
      },

      async uploadFile(file) {
        if (file.length == 0) {
          return;
        }

        this.isLoading = true;
        if (file.size / 1024 / 1024 > 1024) {
          this.$notify.warning("document_exceeded_max_size", {
            name: file.name,
          });
        } else {
          let ok = await this.submitFile(file);
          if (ok) {
            this.$notify.success("upload-document_success", {
              files: file.name,
            });
            return ok
          } else {
            this.isLoading = false;
            this.$notify.error("upload-document_error");
            return false;
          }
        }

        // Todos los ficheros subidos
        this.isLoading = false;
        this.$notify.success("upload-document_success", {
          files: 1,
        });
        this.$emit("success");
        return true;
      },

      async submitFile(file) {

        this.uploadPercentage = 0;
        this.cancelRequest = axios.CancelToken.source();

        let form = new FormData();

        // Si el nombre personalizado después del trim tiene contenido usamos ese, y si no pasamos al del fichero
        form.append("original_filename", file.name);
        form.append("entity_type", "workorder");
        form.append("entity_id", this.id);

        form.append("project_id", this.projectId)

        return this.store(file, {})
        .then(async result => {
              // successFul contiene toda la lista de archivos que se han subido
              // para nuestro caso solo consideramos siempre el primero por que enviamos de a uno
              if( result.successful.length > 0 ) {
                let response = result.successful[0].response

                let params = {
                  // uuid: response.uuid,
                  key: response.body.key,
                  // bucket: response.bucket,
                  name: file.name,
                  content_type: file.type,
                  // url: response.url
                }

                form.append("aws_response", JSON.stringify(params));

                let resp = null
                resp = await axios.post("/api/v2/project/" + this.projectId + "/document", form, {
                  cancelToken: this.cancelRequest.token,
                });

                console.log('upload file order: ', resp)

                return resp && resp.data && resp.data.success ? resp.data.document.id : false
              }

              // failed contiene todos los archivos que tubieron fallo al subir
              if (result.failed.length > 0) {
                console.error('Errors:')
                result.failed.forEach((file) => {
                  console.error(file.error)
                })

                return false
              }
        }).catch((error) => {
          console.log('error: ', error)
          return false
        });

      },

      // CUSTOM VAPOR STORE METHOD
      async store(file, options = null) {
        // verificamos si existe algun archivo en la lista de carga de uppy
        // si existe lo eliminamos
        if( this.keyFileUploadCurrent ) {
          this.uppy.removeFile(this.keyFileUploadCurrent);
        }

        if (typeof options.progress === 'undefined') {
            options.progress = () => {};
        }

        this.keyFileUploadCurrent = this.uppy.addFile({
          name: file.name, // file name
          type: file.type, // file type
          data: file, // file blob
          // meta: {
          //   // optional, store the directory path of a file so Uppy can tell identical files in different directories apart.
          //   relativePath: webkitFileSystemEntry.relativePath,
          // },
          source: 'Local',
          isRemote: false,
        });

        // response.data.extension = file.name.split('.').pop()
        return this.uppy.upload()
      },

      validateData(data) {
        let valid = true
        if (data.code == null || data.code == '' ) {
          this.$notify.error("El campo código del pedido es obligatorio")
          valid = false
        }
        // else if (!data.file) {
        //   this.$notify.error("Debe subir el documento del pedido")
        //   valid = false
        // }

        return valid
      }
    }
}
</script>

<style scoped lang="scss">
  .modal-card-body {
    min-height: 180px;
    min-width: 340px;
  }

  .modal-card-foot {
    justify-content: flex-end !important;
  }
</style>
