<template>

    <!-- Área padre -->
    <div class="w-full md:w-1/2 pr-3" style="margin-top: 15px">
        <form @submit.prevent="confirmSubmitParentElementForm">
            <div class="w-full">
                <label class="font-semibold text-base text-black">{{ $t('new_parent_' + this.elementType) }}</label>
                <div class="flex">
                    <div class="relative flex items-center w-full">
                        <multiselect
                            v-model="newParentElement"
                            :options="parentElements"
                            :placeholder="$t('parent_' + elementType)"
                            :disabled="disabled"
                            label="name"
                            class="rounded-r-none"
                            searchable
                            allowEmpty
                            closeOnSelect
                            :showLabels="false"
                            :loading="loadingParentElementForm"
                            track-by="id"
                            @select="selectParentOption"
                            @remove="removeParentOption"
                        >
                            <!--<span slot="placeholder" class="text-base">-->
                            <!--{{ $t('parent_department') }}-->
                            <!--</span>-->
                        </multiselect>
                        <button
                                type="submit"
                                :disabled="disabledNewParentButton || disabled"
                                class="btn h-full rounded-l-none btn-blue"
                        >
                            <b-icon
                                    icon="check"
                                    pack="fa"
                                    size="is-small"
                                    class="mr-2"/>
                            <span>
                          {{ $t('modify') }}
                        </span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

</template>


<script>
  import axios from 'axios'
  import Form from 'vform'
  import Multiselect from 'vue-multiselect'

  export default {
    name: 'change-parent',

    components: {
      Multiselect
    },

    props: {
      node: { type: Object, default: null },
      elementType: { type: String, required: true },
      disabled: { type: Boolean, default: true}
    },

    data: () => ({
      formAddParentElement: new Form({
        parent_element_id: null
      }),
      parentElements: [],
      newParentElement: null,
      loadingParentElementForm: false,
      disabledNewParentButton: true
    }),

    watch: {
      node(value) {
        this.loadParentElementForm()
      }
    },

    methods: {

      confirmSubmitParentElementForm () {
        if (this.newParentElement !== null) {
          this.$dialog.confirm({
            title: this.$t('new_parent_' + this.elementType),
            message: this.$t('modify_orgranigram_confirmation'),
            confirmText: this.$t('modify_orgranigram'),
            cancelText: this.$t('cancel'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => this.submitParentElementForm()
          })
        }
      },

      // Modifica el organigrama asignando un nuevo padre a un elemento
      async submitParentElementForm () {
        if (! this.node.id && this.newParentElement !== null) {
          return;
        }

        this.formAddParentElement.parent_element_id = this.newParentElement.id

        const { data } = await this.formAddParentElement.post('/api/v2/'+this.elementType+'/' + this.node.id + '/update_parent')
        if (data && data.success) {
          this.$notify.success('success_organigram_update')
          this.$parent.reloadOrganigram()
        } else {
          this.$notify.error('error_organigram_update')
        }
      },

      selectParentOption(selectedOption, id) {
        this.disabledNewParentButton = false
      },

      removeParentOption(removedOption, id) {
        this.disabledNewParentButton = true
      },

      async loadParentElementForm() {

        this.parentElements = []
        this.newParentElement = null
        //this.disabledNewParentElement = false
        this.disabledNewParentButton = true

        if (this.node !== null) {
          this.loadingParentElementForm = true

          // Obtenemos los elementos que pueden ser posibles padres del elemento
          const {data} = await axios.get('/api/v2/'+this.elementType+'/' + this.node.id + '/list_parents')

          if (data && data.success) {
            this.parentElements = data[this.elementType+'s']
            //this.disabledNewParentElement = this.parentElements.length === 0
          }
        }

        this.loadingParentElementForm = false
      },
    },
  };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>