<template>
  <div>
    <b-modal :active.sync="isModalActive" has-modal-card :width="640" :can-cancel="false">

      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow">
          <!-- Modal header -->
          <div class="flex items-start justify-between p-3 border-b- rounded-t">
              <h3 class="text-xl font-semibold text-gray-900">
                {{$t('linked_activities')}}
              </h3>
          </div>

          <!-- Modal body -->
          <div class="p-3 space-y-6">
            <table class="w-full my-2">  
                <tr class="bg-blue-lightest">
                    <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark" >{{$t('activity')}}</th>
                    <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark">{{$t('progress')}}</th>
                    <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark">{{$t('type')}}</th>
                    <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark">{{$t('days')}}</th>
                </tr>
                <tr v-for="pre in prelations">
                    <td class="border border-blue-lighter p-2 cursor-pointer" @click="goToActivity(pre)">{{pre.name}}</td>
                    <td class="border border-blue-lighter p-2 has-text-centered">{{pre.progress}}%</td>
                    <td class="border border-blue-lighter p-2 has-text-centered">{{pre.pivot.link_type}}</td>
                    <td class="border border-blue-lighter p-2 has-text-centered">{{pre.pivot.days}}</td>
                </tr>
            </table>
          </div>

          <!-- Modal footer -->
          <div class="flex items-center p-3 space-x-2 border-t- border-gray-200 rounded-b">
            <b-button class="w-1/2" @click="goToPrelations">{{$t('go_to_links')}}</b-button>
            <b-button class="w-1/2" type="is-info" @click="close">{{$t('close')}}</b-button>
          </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "pre-activity-modal",

  components: {
  },

  props: {
    prelations: { type: Array, required: true },
    activityId: { type: Number, required: true}
  },

  data: () => ({
    isModalActive: false
  }),

  mounted() {
  },

  methods: {

    show() {
      this.isModalActive = true
    },

    close() {
      this.isModalActive = false
    },

    goToPrelations() {
    this.$router.push({ name: 'activity.prelation', params: {
                activity_id: this.activityId
            } })
    },

    goToActivity(activity) {
      if (activity.parent_id) {
        this.$router.push({'name': 'subactivity.home', params: {activity_id: activity.parent_id, subactivity_id: activity.id}})
      } else {
        this.$router.push({'name': 'activity.home', params: {activity_id: activity.id}})
      }
    },

  }
};
</script>

<style scoped>

</style>
