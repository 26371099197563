<template>
  <div>
    <!-- Filtros -->
    <div class="panel">

      <div class="flex items-center mb-2">
        <div class="w-1/2 mr-4">
          <b-field>
            <b-input
              v-model="filterCompanyName"
              :placeholder="$t('name')"
              type="search"
              icon="search"
              icon-pack="fas"
              @keyup.enter.native="getCompanies">
            </b-input>
          </b-field>
        </div>
      </div>

      <button class="button is-primary btn-blue" @click="cleanFiltersClicked">{{$t('clean_filters')}}</button>
    </div>

    <div class="w-full flex justify-between mt-4 below-top-bar">
        <div class="flex">
        </div>

        <!-- Botón añadir company -->
        <div class="flex float-right">
          <div class="rounded-sm mr-4 mb-4 mt-2">
            <v-button icon="plus" @click="createCompanyButtonClicked()">{{$t('add_company')}}</v-button>
          </div>
        </div>
    </div>

    <!-- Tabla de proyectos -->
    <div class="panel">

      <b-table
        :data="companies"
        :loading="isLoadingTable"
        icon-pack="fas"

        paginated
        backend-pagination
        :per-page="perPage"
        :total="total"
        @page-change="onPageChange"

        backend-sorting
        :default-sort="orderField"
        :default-sort-direction="orderDir"
        @sort="onSort"

        class="w-full"
        striped
        bordered
      >

        <template>

          <b-table-column field="name" cell-class="vertical-center" centered sortable>
            <template v-slot:header="">
              {{ $t('name') }}
            </template>
            <template v-slot="companies">
              {{ companies.row.name }}
            </template>
          </b-table-column>

          <b-table-column field="alias" cell-class="vertical-center" centered sortable>
            <template v-slot:header="">
              {{ $t('alias') }}
            </template>
            <template v-slot="companies">
              {{ companies.row.alias }}
            </template>
          </b-table-column>

          <b-table-column field="configs" cell-class="vertical-center" centered sortable>
            <template v-slot:header="">
              {{ $t('configurations') }}
            </template>
            <template v-slot="companies">
              {{ companies.row.configs }}
            </template>
          </b-table-column>

          <b-table-column field="actions" cell-class="vertical-center" centered sortable width="150">
            <template v-slot:header="">
              {{ $t('actions') }}
            </template>
            <template v-slot="companies">
              <div class="w-full h-full flex items-center justify-around">
                <b-tooltip :label="$t('edit_project')" position="is-left">
                  <div @click="editCompanyButtonClicked(companies.row)"
                      class="cursor-pointer flex text-blue">
                    <b-icon pack="fas" icon="pencil-alt"/>
                  </div>
                </b-tooltip>
              </div>
            </template>
          </b-table-column>

        </template>
      </b-table>

    </div>

    <div class="panel">
      <div class="mb-3">
        <div class="flex items-center bg-blue-light text-white text-sm font-bold px-4 py-3 rounded" role="alert">
          <svg class="fill-current w-8 h-8 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
          <p>Para realizar una asignación masiva introduzca el ID del proyecto y el campo a debe ser distinto de proyecto el campo ID no es necesario no se toma en cuenta (de momento solo para incidencias). <br> Para asignación simple deje el campo ID del proyecto vacio y complete los demas campos de manera obligatoria</p>
        </div>
      </div>

      <div class="flex items-center mb-4">

      <div class="w-1/4 mr-4">
        Id proyecto
        <b-input v-model="projectId" :placeholder="$t('id')"> </b-input>
      </div>

      <div class="w-1/4 mr-4">
        Asignar Usuario
        <b-input v-model="assignation.userId" :placeholder="$t('id')"> </b-input>
      </div>
      <div class="w-1/4 mr-4">
        a
        <b-select ref="input" v-model="assignation.model" :placeholder="$t('select')" expanded>
          <option value="a" >Actividad</option>
          <option value="i" >Incidencia</option>
          <option value="p" >Proyecto</option>
          <option value="d" >Departamento</option>
          <option value="r" >Area</option>
        </b-select>
      </div>
      <div class="w-1/4 mr-4">
        Id
        <b-input v-model="assignation.modelId" placeholder="ID">
        </b-input>
      </div>
      <div class="w-1/4 mr-4">
        Role
        <b-select ref="input" v-model="assignation.roleId" :placeholder="'Selecciona'" expanded>
          <option value="7" >Participante</option>
          <option value="6" >Colaborador</option>
          <option value="5" >Visualizador</option>
          <option value="3" >Project admin</option>
          <option value="8" >Department jefe</option>
          <option value="9" >Department user</option>
          <option value="2" >Area admin</option>
        </b-select>
      </div>

      </div>
      <div class="flex items-center mb-4">

      <div class="w-1/4 mr-4">
        <button class="button is-primary btn-blue" @click="manualAssign()">Asignar</button>
      </div>

      </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    middleware: 'auth',
    title: 'companies_page_title',
    subtitle: 'companies_page_subtitle',
    menuOption: '0-0',

    components: {},

    computed: {
      filters() {
        let filters = {
          'order_field': this.orderField,
          'order_dir': this.orderDir,
        }
        if (this.filterCompanyName != '') {
          filters['name'] = this.filterCompanyName
        }
        return filters
      }
    },

    data: () => ({
      isLoadingTable: true,
      companies: [],
      filterCompanyName: '',
      perPage: 20,
      orderField: 'name',
      orderDir: 'asc',
      page: 1,
      total: 0,

      assignation: {
        userId: '',
        model: '',
        modelId: 0,
        roleId: 0,
      },

      projectId: null // solo usado para asignacion masiva a actividades o insidencia sde un proyecto

    }),

    created() {
      this.getCompanies()
    },

    methods: {

      async getCompanies() {
        this.isLoadingTable = true
        this.companies = []

        const {data} = await axios.get('/api/v2/companies', {params: this.filters})
        if (data && data.success) {
          this.companies = data.companies
          this.total = data.companies.length
          this.isLoadingTable = false
        }
      },

      onSort(field, order) {
        this.orderField = field
        this.orderDir = order
        this.getCompanies()
      },

      onPageChange(page) {
        this.page = page
        this.getCompanies()
      },

      cleanFiltersClicked() {
        this.filterCompanyName = ''
        this.getCompanies()
      },

      // Botones de acción
      createCompanyButtonClicked() {
        this.$store.dispatch('app/updateNavData', {
          area_name: '',
        });
        this.$router.push({name: 'company.create'})
      },

      editCompanyButtonClicked(company) {
        this.$router.push({
          name: 'company.edit', params: {company_id: company.id}
        })
      },

      async manualAssign() {
        if( this.projectId ) {
          this.assignation.projectId = this.projectId
        }

        const {data} = await axios.post('/api/v2/user/manualassign', { params: this.assignation })
        if (data && data.success) {
          this.$notify.success('asignado')
        } else {
          this.$notify.error('error')
        }
      },
    },

  };
</script>
