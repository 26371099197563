
import axios from 'axios'
import * as types from '../mutation-types'

// state
export const state = {
  user: null,
  token: null,
}

// mutations
export const mutations = {
  [types.SAVE_TOKEN] (state, { token, remember }) {
    state.token = token
  },

  [types.FETCH_USER_SUCCESS] (state, { user }) {
    state.user = user
  },

  [types.FETCH_USER_FAILURE] (state) {
    state.token = null
  },

  [types.LOGOUT] (state) {
    state.user = null
    state.token = null
    window.location.href = '/login'
  },

  [types.UPDATE_USER] (state, { user }) {
    state.user = user
  }
}

var tokenCheckTimer = null
var tokenCheckTimerStartTime = null

// actions
export const actions = {
  async saveToken ({ commit, dispatch }, payload) {
    commit(types.SAVE_TOKEN, payload)

    // Ponemos en marcha un timeout para que redirija al login
    // si el usuario ha estado inactivo
    if (tokenCheckTimer == null) {
      // console.log('token check set', parseInt(window.config.sessionTimeout))
      tokenCheckTimer = window.setInterval(() => {
        // console.log('check timeout')
        let minutes = Math.abs((tokenCheckTimerStartTime - new Date()) / 1000 / 60)
        // console.log('minutes', minutes)
        if (minutes > parseInt(window.config.sessionTimeout)) {
          // console.log('token timeout!!!')
          dispatch('logout')
        }
      }, 1000)
    }

    // Se guarda el momento en que recibimos el nuevo token
    tokenCheckTimerStartTime = new Date()
    // console.log('token new time', tokenCheckTimerStartTime)
  },

  async fetchUser ({ commit }) {
    try {
      await axios.get('/api/v2/user')
        .then(function(response) {
          commit(types.FETCH_USER_SUCCESS, { user: response.data })
        })
    } catch (e) {
      commit(types.FETCH_USER_FAILURE)
    }
  },

  updateUser ({ commit }, payload) {
    commit(types.UPDATE_USER, payload)
  },

  async logout ({ commit, dispatch }) {
    try {
      if (tokenCheckTimer) {
        window.clearTimeout(tokenCheckTimer)
      }
      await axios.post('/api/v2/logout', { device_type: types.DEVICE_TYPE_WEB })
        .then(function(response) {
          commit(types.LOGOUT)
        })
      //dispatch('app/logout')
    } catch (e) { console.log(e) }

    //commit(types.LOGOUT)
  },
}

// getters
export const getters = {
  user: state => state.user,
  userBasicInfo: state => {
    if (state.user) {
      return {
        user_id: state.user.id,
        fullname: state.user.name + ' ' + state.user.surname,
        email: state.user.email,
        avatar: state.user.avatar
      }
    }
    return null
  },
  token: state => state.token,
  check: state => state.user !== null
}
