<template>
  <b-modal :active.sync="isModalActive" :width="520" scroll="keep">
    <div class="p-4 bg-white border rounded shadow-lg border-blue-light">

      <header class="w-full text-xl font-semibold uppercase text-blue-light">
        {{ $t("validate_progress") }}
      </header>

      <div class="w-full">
        <form @submit.prevent="submitForm" class="w-full">
          <div>

            <div class="flex flex-row items-center mt-4">
              <div class="w-3 h-3 mr-1 rounded-full bg-green"></div>
              <div class="font-semibold text-black text-lg">{{ data.name }}</div>
            </div>

            <div class="flex flex-row items-center justify-start mt-1" v-if="data.unit">
              <div class="p-2 text-lg font-semibold text-white rounded-full bg-blue ">{{ (data.executed_quantity == 0) ? '0' : data.executed_quantity.toFixed(2) }} {{ data.unit.name }} {{ $t('executed') }}</div>
              <div class="p-2 text-lg font-semibold text-white rounded-full bg-orange ">{{ (data.pending_validation_quantity == 0) ? '0' : data.pending_validation_quantity.toFixed(2) }} {{ data.unit.name }} {{ $t('for_validate') }}</div>
              <div class="ml-1 text-xs font-medium text-grey-dark">{{ ((form.total - data.executed_quantity) == 0) ? '0' : (form.total - data.executed_quantity).toFixed(2) }} {{ data.unit.name }} {{ $t('pendings') }}</div>
            </div>


            <div class="flex flex-row justify-between w-full mt-4">

              <div class="flex flex-col w-1/3">
              </div>

              <div class="flex flex-col w-1/3 mx-4 ">
              </div>

              <div class="flex flex-col w-1/3">
              </div>

            </div>


            <!-- Observación sobre la validacion -->
            <div class="w-full pb-3 mt-4" v-if="isResource()" >
                <div class="text-xs font-medium text-blue">{{ $t("observations") }}</div>
                <b-input
                  v-model="form.observation"
                  name="observation"
                  :placeholder="$t('observations')"
                  :disabled="false"
                  class="w-full"
                  maxlength="2000"
                  type="textarea"
                ></b-input>


              <div v-if="isLoading" class="my-4 text-center">
                <scale-loader />
              </div>

            </div>
          </div>

          <div class="inline-flex justify-end w-full pb-3">

            <button class="btn btn-blue mr-2" type="button" @click="submitStatus(3)" :disabled="isLoading">
              <b-icon pack="fas" icon="thumbs-up" size="is-small"/>&nbsp;
              {{ $t("validate") }}
            </button>

            <button class="btn btn-red" type="button" @click="submitStatus(4)" :disabled="isLoading">
              <b-icon pack="fas" icon="thumbs-down" size="is-small"/>&nbsp;
              {{ $t("reject") }}
            </button>

          </div>
        </form>
      </div>
    </div>
  </b-modal>
</template>

<script>
// import Vue from 'vue';
import axios from "axios";
import EventBus from "~/plugins/bus";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  name: "assignment-contribute-modal",

  components: {
    ScaleLoader,
  },

  props: {
    entityId: { type: Number, required: true },
    entityType: { type: String, required: true },
  },

  data: () => ({
    cancelRequest: null,
    uploadPercentage: 0,
    isModalActive: false,
    id: null,
    isLoading: false,
    data: {},
    form: {
      executed_quantity: 0, // cantidad a confirmar/rechazar
      observation: null,
      status: null, // estado 3 aceptar o 4 rechazar
      total: 0,
    },

  }),


  methods: {

    onAddClicked() {
      this.$refs.modal.show();
    },


    submitStatus(status) {
      this.form.status = status
      this.submitForm()
    },


    /**
     * Inserta una linea tipo "aceptadas/rechazadas las cantidades pendientes hasta ahora" (executed_quantity especifica cuanto acepta/rechaza)
     */
    async submitForm() {
      let data = {
        executed_quantity: this.data.pending_validation_quantity,
        observation: this.form.observation || null,
        status: this.form.status,
      };

      let url =
        "/api/v2/assignments/" + this.id + "/validate-resource";

      axios({
        method: "post",
        url: url,
        data: data,
      })
        .then((response) => {
          this.$toast.open({
            message: response.data.error || response.data.msg || this.$t("assignment_update_success"),
            type: response.data.error || response.data.msg ? "is-danger" : "is-success",
            position: "is-top-right",
          });

          this.$emit("reload", { id: this.id, entityId: this.entityId }); //pide a su componente padre que recargue
          EventBus.$emit("refreshActivityDocumentsNotifications", true);
        })
        .catch((error) => {
          //Se produjo un error
          this.isLoading = false;
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("assignment_update_error"),
            type: "is-danger",
            position: "is-top-right",
          });
        });

      this.hide();
      this.$emit("reload");
    },

    /**
     * Comprueba si los datos son de tipo recurso
     */
    isResource() {
      return this.data.type === "App\\Models\\AssignmentType\\Resource";
    },

    /**
     * Muestra este modal
     */
    show(assignment_id) {
      this.id = assignment_id;
      this.getAssignmentData();
      this.isModalActive = true;
    },

    /**
     * Toma los datos de esta medición de la API
     */
    getAssignmentData() {
      var self = this;
      const { data } = axios
        .get("/api/v2/assignment/" + this.id)
        .then((response) => {

          if (response.data && response.data.success) {
            self.data = response.data.assignment;
            self.form = {
              observation: null,
              total: self.data.total_quantity != null ? self.data.total_quantity : self.data.partial,
            };

          } else {
            this.$notify.error(response.data.msg || "assignment_loading_error");
          }
        });
    },


    /**
     * valores por defecto del formulario
     */
    defaultFormValues() {
      return {
        executed_quantity: 0,
        observation: null,
        status: null,
        total: 0,
      };
    },

    /**
     * Oculta este modal
     */
    hide() {
      this.id = null;
      this.data = [];
      this.isModalActive = false;
      this.form = this.defaultFormValues();
    },

  },
};
</script>
<style scoped>
/* Oculta los botones para aumentar los inputs numéricos */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
