import Vue from 'vue'

const requireComponent = require.context('./LaraBue', false, /.*\.vue$/)

// Registro de componentes globales
requireComponent.keys().forEach(file => {
  const Component = requireComponent(file).default

  if (Component.name) {
    Vue.component(Component.name, Component)
  }
})