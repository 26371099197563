<template>
<span class="block">
  <b-tooltip :label="originalDate" position="is-bottom">
    {{ timeAgo }}
  </b-tooltip>
</span>
</template>

<script>
import { formatDistance, format } from 'date-fns'
import {es} from 'date-fns/esm/locale'

const locales = {es}

export default {
  name: 'l-timeago',

  props: {
    since: { type: [String, Date], required: true },
    locale: { type: String, default: null },
    autoUpdate: { type: Number, default: 60 },
    includeSeconds: { type: Boolean, default: false },
  },

  data: () => ({
    timeAgo: null,
    updater: null,
  }),

  watch: {
    autoUpdate(newValue) {
      this.stopUpdater()
      if (newValue) {
        this.startUpdater()
      }
    }
  },

  computed: {
    originalDate() {
      let dateObject = new Date(this.since);
      return format(dateObject, 'dd/MM/yyyy HH:mm')
    },
  },

  mounted() {
    this.timeAgo = this.convert(this.since)
    this.startUpdater()
  },

  beforeDestroy() {
    this.stopUpdater()
  },

  methods: {
    convert(date) {
      let dateObject = new Date(date);
      return formatDistance(dateObject, new Date(), {
        addSuffix: true,
        locale: this.locale != 'en' ? locales[this.locale] : null,
        includeSeconds: this.includeSeconds
      })
    },
    startUpdater() {
      this.updater = setInterval(() => {
        this.timeAgo = this.convert(this.since)
      }, this.autoUpdate * 1000)
    },
    stopUpdater() {
      if (this.updater) {
        clearInterval(this.updater)
        this.updater = null
      }
    },
  },

};
</script>
