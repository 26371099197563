<template>
  <section>
    <div class="panel">
      <div class="w-full mb-4">
        <div class="flex w-full">
          <p class="content">
            <b>{{ $t('order_field') }}</b>
          </p>
        </div>
        <div class="flex w-full">
          <b-radio v-model="orderField" native-value="name">
            {{ $t('name') }}
          </b-radio>
          <b-radio v-model="orderField" native-value="code">
            {{ $t('code') }}
          </b-radio>
        </div>
      </div>
      <div class="w-full mb-4">
        <div class="flex w-full">
          <p class="content">
            <b>{{ $t('order_dir') }}</b>
          </p>
        </div>
        <div class="flex w-full">
          <b-radio v-model="orderDir" native-value="asc">
            {{ $t('ascending') }}
          </b-radio>
          <b-radio v-model="orderDir" native-value="desc">
            {{ $t('descending') }}
          </b-radio>
        </div>
      </div>
      <div class="w-full mb-4">
        <div class="flex w-full">
          <p class="content">
            <b>{{ $t('export_template') }}</b>
          </p>
        </div>
        <div class="flex w-full">
          <b-field class="mr-2">
            <b-select v-model="template" icon="file-export" icon-pack="fas">
              <option value="default">{{ $t('default_template') }}</option>
              <option value="simple">{{ $t('plain_template') }}</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="flex w-full">
        <v-button type="button" color="green" icon="file-export" @click="exportActivities">{{ $t('export') }}</v-button>
      </div>
    </div>
  </section>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'export-assignments',

    props: {
      project_id: {type: Number, default: 0},
    },

    data: () => ({
      orderField: 'name',
      orderDir: 'asc',
      template: 'default',
    }),

    methods: {
      exportActivities() {

        let params = {
          projectId: this.project_id,
          orderDir: this.orderDir,
          orderField: this.orderField,
          template: this.template,
        };

        var self = this;

        axios({
          method: 'get',
          url: '/api/v2/export/assignment',
          responseType: 'blob',
          params: params
        })
          .then(function (response) {
            self.$notify.success('success_export');
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = self.$t('assignments') + '.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(error => {
            self.$notify.error('error_export');
            console.log(error)
          })
      }
    },
  };

</script>
