<template>
    <div class="panel w-full">
      <form @submit.prevent="submitForm" class="w-full">
        <div>
          <div class="flex items-center justify-between w-full">
            <div class="font-semibold text-black">Nuevo pictograma</div>
            <div>
              <b-tooltip :label="$t('close')" position="is-left">
                <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
                  <b-icon pack="fas" icon="times" size="is-small" />
                </div>
              </b-tooltip>
            </div>
          </div>

          <div class="mt-3 mb-2">
            <p>Primero seleccione el archivo que contiene el diseño de las estructuras de la planta fotovoltaica, en formato <span class="font-bold">dxf</span></p>
          </div>

          <b-field class="file is-success">
            <b-upload
              v-model="file"
              class="file-label"
              :multiple="false"
              :accept="accept"
              @input="_OnFileSelected"
            >
              <span class="file-cta">
                <span class="mr-2 file-label">{{
                  $t("upload_documents")
                }}</span>
                <b-icon class="file-icon" icon="upload" pack="fas"></b-icon>
              </span>
            </b-upload>
          </b-field>

          <div class="mt-4">

            <div class="mt-4">
              <!-- <div class="mb-2">
                <div class="text-xs font-medium capitalize text-blue">{{ $t("code") }}</div>
                <div class="flex flex-row items-center">
                  <input
                    v-model="form.code"
                    title="code"
                    :required="true"
                    name="code"
                    type="text"
                    class="w-full h-8_ p-1 border rounded bg-grey-lighter border-grey-light"
                    @focus="$event.target.select()" />
                </div>
              </div> -->

              <div class="mb-2">
                <div class="text-xs font-medium capitalize text-blue">{{ $t("name") }}</div>
                <div class="flex flex-row items-center">
                  <input
                    v-model="form.name"
                    title="code"
                    :disabled="dxf == null || generatedDesign"
                    :required="true"
                    name="name"
                    type="text"
                    class="w-full h-8_ p-1 border rounded bg-grey-lighter border-grey-light"
                    @focus="$event.target.select()" />
                </div>
              </div>

            </div>

            <!-- campo total de estructuras, se calcula a partir del diseño contenido en el archivo dxf -->
            <div class="mb-2">
              <div class="text-xs font-medium capitalize text-blue">{{ $t("total_structures") }}</div>
              <div class="flex flex-row items-center">
                <input
                  v-model="form.total_structures"
                  title="units"
                  :disabled="true"
                  :required="true"
                  name="units"
                  step="1"
                  type="number"
                  class="w-16 h-8 p-1 border rounded bg-grey-lighter border-grey-light"
                  @focus="$event.target.select()" />
                <span class="ml-1 text-xs text-black">(Dato calculado desde el diseño)</span>
              </div>
            </div>

            <div>
              <b-field>
                <b-switch v-model="layeredDesign"
                  :rounded="true"
                  :outlined="false"
                  :size="'is-small'"
                  :type="'is-success'"
                  :disabled="dxf == null || generatedDesign"
                  :left-label='true'>Crear diseño por capas</b-switch>
              </b-field>
            </div>

            <div class="mt-4">

              <fields-design-form ref="frm_default"
                :disable-fields="dxf == null || generatedDesign"
              />

              <layers-design-list v-if="layeredDesign"
                ref="refDataCadLayersDesign"
                :layers="layersDesign"
                :disable-fields="dxf == null || generatedDesign"
              />
            </div>

          </div>

        </div>

        <div class="flex flex-col w-full pb-3 mt-5">
          <v-button-processing v-if="processingData" />

          <div v-else class="w-full flex flex-col lg:flex-row">
            <b-button v-if="! generatedDesign"
              :label="$t('generate_design')"
              type="is-info"
              icon-pack="fas"
              icon-left="table-cells"
              class="mr-2 w-full mb-2"

              @click="_GenerateDesign" />

            <b-button v-if="generatedDesign"
              :label="$t('edit_design')"
              type="is-info"
              icon-pack="fas"
              icon-left="pen-square"
              class="mr-2 w-full mb-2"
              @click="_EditDesign" />

            <b-button v-if="generatedDesign"
              ref="refBtnSave"
              :label="$t('save')"
              type="is-success"
              icon-pack="fas"
              icon-left="save"
              class="w-full"
              native-type="submit" />

          </div>
        </div>
      </form>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from "axios";
import Form from "vform";
import { mapGetters } from "vuex";
import uploadDocument from "../upload-document";
import EventBus from "~/plugins/bus";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import moment from 'moment';
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import store from "~/store";
import FieldsDesignForm from "./components/fields-design-form.vue"

import {DxfWorker} from "./viewer/src/DxfWorker"
import LayersDesignList from './components/layers-design-list.vue';

Vue.use(moment);
export default {
  name: "pictogram-add",

  components: {
    uploadDocument,
    ScaleLoader,
    FieldsDesignForm,
    LayersDesignList
},

  props: {
    entityId: { type: Number, required: true },
    entityType: { type: String, required: false, default: 'project' },
  },

  data: () => ({
    cancelRequest: null,
    uploadPercentage: 0,
    id: null,
    isLoading: false,
    data: {},
    unitsList: [],

    form: {
      id: 0,
      code: null,
      name: null,
      total_structures: null,
    },

    file: null,
    lastLine: {},
    dxf: null, // contiene el dxf parseado a json
    scene: null, // contiene los datos de la escena a graficar en konva

    accept: "", // on created()

    uppy: null, // instancia de la libreria para subir archivos
    keyFileUploadCurrent: null, // key del archivo que se esta subiendo actualmente
    processingData: false,

    // DXF
    dxfUrl: null,
    isLocalFile: false,
    generatedDesign: false,

    // Layers del diseño
    layersDesign: [], // contiene las capas del diseño
    layeredDesign: false, // bandera que indica si se añadira el diseño por capas de cad.
    totalEntitiesDesign: {}, // objeto que contiene el total de pilings modules y panels generados
    cadLayeredDesign: {}, // objeto que contiene los datos de diseño por capas
    imagesDesign: [] // contiene las capturas de los diseños de las capas controlados por nosotros, son usados en el dasboard
  }),

  created() {

    this.accept = ".dxf" // extensiones aceptadas solo formato dxf

    this.uppy = new Uppy({
        debug: process.env.NODE_ENV === 'development', // activamos debug para development
        autoProceed: false,
        allowMultipleUploads: false,
      })
      .use(AwsS3Multipart, {
        limit: 3,
        companionUrl: '/api/v2/',
        companionHeaders: {
          'Authorization': "Bearer " + store.getters["auth/token"]
        }
      })
      // .on('upload-success', (file, response) => { this.onUploadSuccess(file, response) } )
      .on('upload-success', (file, response) => { // callback deveulto cuando el file ha sido subido correctamente
        console.log('%cEPC-TACKER: '+ '%c file ' + file.data.name + ' subido correctamente', 'background: #5577BB; color: #fff', 'color: #000')
      })
      .on('upload-progress', (file, progesss) => { // callback devuelto con el progreso de subido del archivo
        this.uploadPercentage = parseInt(
          Math.round((progesss.bytesUploaded * 100) / progesss.bytesTotal)
        );
      })
      .on('upload-error', (file, error, response) => { // callback devuelto si ha ocurrido algun error en la subida
        console.error('%cEPC-TACKER: '+ '%c a ocurrido un error al subir el archivo ' + file.data.name, 'background: #5577BB; color: #fff', 'color: #000', error)
      })
  },

  computed: {
    ...mapGetters({
      project: "app/project",
      companies: "app/companies",
      user: "auth/user",
    }),
    fileSelected() {
      return this.file != null;
    },
    existsLastLine() {
      // consideramos que existe linea si alguno de los nuevos campos no es null (u, l, w, h no existian en viejo formato)
      return this.lastLine.units || this.lastLine.length || this.lastLine.width || this.lastLine.height
    }
  },

  methods: {
    SaveDesignWithImage(images) {
      this.imagesDesign = images
      // Simulamos el presionado del boton guardar
      this.$refs.refBtnSave.$el.click()
    },

    onAddClicked() {
      this.$refs.modal.show();
    },
    /**
     * Envía el formulario para editar y/o añadir una linea
     */
    async submitForm() {

      this.scene.entities = this._parserEntities();
      // console.log('this.scene: ', this.scene.entities)
      // return
      let aliasCompany = this._GetCompany('alias')

      if (aliasCompany) {
        this.processingData = true
        let document = null

        if ( this.fileSelected ) {
          document = await this.uploadFiles(); // subida del fichero dxf
        }

        if (document) {
          const aws_response = await this.submitScenePictogram(document.filename.split('.').slice(0, -1).join('.') + '.json') // subida de las imagenes
          if( aws_response ) {
            let arrAwsResponse = []

            for (let index = 0; index < this.imagesDesign.length; index++) {
              const item = this.imagesDesign[index];
              const awsr = await this.submitBlob(item)
              arrAwsResponse.push(awsr)
            }

            await this.submitDataPictogram(document, aws_response, arrAwsResponse)
          }
          // else
          // mostrar mensaje si fallo la subida TODO: tratar en caso de generase error por alguna razon conciderar los casos de eliminicion del archivo
          // subido previamente
        }

        await this.sleep(1000)
        this.processingData = false

        this.onCloseClicked();
        this.$emit("reload");
      } else {
        this.$toast.open({
          message: 'El proyecto no esta asignado a ninguna empresa.',
          type: "is-danger",
          position: "is-top-right",
        });
      }

    },

    _parserEntities() {
      let o = {}
      for (const entity of this._GetEntities2()) {
        o[entity.id] = entity
      }
      return o
    },

    /** Metodo devuelve la compania si attr=null, o algun valor de algun atributo de la compania si attr!=null
     * Valores retornados si la compania no existe retorna null, si el atributo no existe retorna undefined
    */
    _GetCompany(attr=null) {
      let company = this.companies.find((c) => { return c.projects.findIndex((p) => { return p.id == this.projectId }) !== 1 })

      if(!company) // si no existe la company mandamos null
        return null

      return attr ? company[attr] : company
    },

    *_GetEntities2() {
      for ( const entity of this.scene.entities) {
        yield entity
      }
    },

    async submitScenePictogram(name) {
      //antes mandamos a guardar el archivo json que contiene la scene
      // const arr = new Uint8Array(JSON.stringify(this.scene)); // no se necesita realizar este paso
      // let worker = new DxfWorker(null)
      // const entities = await worker.FlattenScene(this.scene)
      // await worker.Destroy()
      // worker = null

      // this.scene.entities = entities

      const blob = new Blob([JSON.stringify(this.scene)], { type: 'application/json' });
      blob.name = name
      return this.store(blob, {})
        .then(async result => {
          // successFul contiene toda la lista de archivos que se han subido
          // para nuestro caso solo consideramos siempre el primero por que enviamos de a uno
          if( result.successful.length > 0 ) {
            let response = result.successful[0].response

            let params = {
              // uuid: response.uuid,
              key: response.body.key,
              // bucket: response.bucket,
              name: blob.name,
              content_type: blob.type,
              // url: response.url
            }
            return params
            // form.append("aws_response", JSON.stringify(params));
          }

          // failed contiene todos los archivos que tubieron fallo al subir
          if (result.failed.length > 0) {
            console.error('Errors:')
            result.failed.forEach((file) => {
              console.error(file.error)
            })

            return null
          }
        }).catch((error) => {
          console.log('error: ', error)
          return null
        });
    },

    async submitBlob(resources) {

      const blob = resources.src;
      blob.name = resources.name; // TODO: al nombre del recurso añadir marca de tiempo y name company o project who prefix
      return this.store(blob, {})
        .then(async result => {
          // successFul contiene toda la lista de archivos que se han subido
          // para nuestro caso solo consideramos siempre el primero por que enviamos de a uno
          if( result.successful.length > 0 ) {
            let response = result.successful[0].response

            let params = {
              // uuid: response.uuid,
              key: response.body.key,
              // bucket: response.bucket,
              name: blob.name,
              content_type: blob.type,
              // url: response.url
            }
            return params
            // form.append("aws_response", JSON.stringify(params));
          }

          // failed contiene todos los archivos que tubieron fallo al subir
          if (result.failed.length > 0) {
            console.error('Errors:')
            result.failed.forEach((file) => {
              console.error(file.error)
            })

            return null
          }
        }).catch((error) => {
          console.log('error: ', error)
          return null
        });
    },

    async submitDataPictogram(document, aws_response, aws_images) {
      console.log('aws_response: ', aws_response)
      const form =  new FormData()
      form.append('document_id', document.id)
      form.append('code', this.form.code)
      form.append('name', this.form.name)

      // this._GetLayers().forEach((item) => {
      //   form.append('layers[]', JSON.stringify(item))
      // })
      form.append('layers', JSON.stringify(this._GetLayers()))
      form.append('cad_layers', JSON.stringify(this.cadLayeredDesign))
      form.append('aws_images', JSON.stringify(aws_images))
      // dxf_parser => este contiene el parser del dxf a json original no lo usamos por lo que no mandamos de momento
      // si se envia considerar en mandar directo al buket o mandar con parametro form multipart
      // dxf_parser: this.dxf,
      // scene => sucede lo mismo que con el anterior para optimizar el envio, este es usado por konva para visualizacion de las
      // form.append('scene', JSON.stringify(this.scene)) // ya no lo enviamos optimizado guardando directo en el bucket
      form.append('aws_response', JSON.stringify(aws_response))

      let url = "/api/v2/project/" + this.entityId + "/pictogram";

      this.processingData = true

      await axios.post(url, form)
      .then((response) => {
        if( response.data.success ) {

          this.$toast.open({
            message: this.$t("assignment_update_success"),
            type: "is-success",
            position: "is-top-right",
          });

          this.id = response.data.pictogram.id

        } else {

          this.$toast.open({
            message: response.data.error,
            type: "is-danger",
            position: "is-top-right",
          });

        }

        // this.$emit("reload", { id: this.id, entityId: this.entityId }); //pide a su componente padre que recargue
      })
      .catch((error) => {
        //Se produjo un error
        this.isLoading = false;
        if (error.response && error.response.status === 422) {
          //extraemos el primer error de validación
          error.error_msg =
            error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ][0];
        }

        // Mostramos el error
        this.$toast.open({
          message: error.error_msg || this.$t("assignment_update_error"),
          type: "is-danger",
          position: "is-top-right",
        });
      });
    },

    async uploadFiles() {
      if (this.file == null) {
        return;
      }

      this.isLoading = true;
      let files = [ this.file ];
      let respuesta = null

      for (var i = files.length - 1; i >= 0; i--) {
        if (files[i].size / 1024 / 1024 > 1024) {
          this.$notify.warning("document_exceeded_max_size", {
            name: files[i].name,
          });
        } else {
          let ok = await this.submitFile(files[i]);
          if (ok) {
            // this.files.splice(i, 1);
            this.$notify.success("upload-document_success", {
              files: files[i].name,
            });

            return ok
          } else {
            this.isLoading = false;
            this.$notify.error("upload-document_error");
            return false;
          }
        }
      }
    },

    async submitFile(file) {

      this.uploadPercentage = 0;
      this.cancelRequest = axios.CancelToken.source();


      let form = new FormData();

      let customName = "";
      if (file.custom_name) customName = file.custom_name.trim();

      // Si el nombre personalizado después del trim tiene contenido usamos ese, y si no pasamos al del fichero
      form.append(
        "original_filename",
        customName != "" ? customName + this.getFileExtension(file) : file.name
      );
      form.append("entity_type", "pictogram");
      form.append("entity_id", this.id);

      form.append("project_id", this.entityId);

      return this.store(file, {})
      .then(async result => {
            // successFul contiene toda la lista de archivos que se han subido
            // para nuestro caso solo consideramos siempre el primero por que enviamos de a uno
            if( result.successful.length > 0 ) {
              let response = result.successful[0].response

              let params = {
                // uuid: response.uuid,
                key: response.body.key,
                // bucket: response.bucket,
                name: file.name,
                content_type: file.type,
                // url: response.url
              }

              form.append("aws_response", JSON.stringify(params));

              // let resp = null
              let { data } = await axios.post("/api/v2/" + this.entityType + "/" + this.entityId + "/document", form, {
                cancelToken: this.cancelRequest.token,
              });

              return data && data.success ? data.document : false
            }

            // failed contiene todos los archivos que tubieron fallo al subir
            if (result.failed.length > 0) {
              console.error('Errors:')
              result.failed.forEach((file) => {
                console.error(file.error)
              })

              return false
            }
      }).catch((error) => {
        console.log('error: ', error)
        return false
      });

    },

    // CUSTOM VAPOR STORE METHOD
    async store(file, options = null) {
      // verificamos si existe algun archivo en la lista de carga de uppy
      // si existe lo eliminamos
      if( this.keyFileUploadCurrent ) {
        this.uppy.removeFile(this.keyFileUploadCurrent);
      }

      if (typeof options.progress === 'undefined') {
          options.progress = () => {};
      }

      this.keyFileUploadCurrent = this.uppy.addFile({
        name: file.name, // file name
        type: file.type, // file type
        data: file, // file blob
        // meta: {
        //   // optional, store the directory path of a file so Uppy can tell identical files in different directories apart.
        //   relativePath: webkitFileSystemEntry.relativePath,
        // },
        source: 'Local',
        isRemote: false,
      });

      // response.data.extension = file.name.split('.').pop()
      return this.uppy.upload()
    },
    /**
     * Confirma y borra la medición abierta en el modal
     */
    async deleteAssignment() {
      let self = this;

      this.$dialog.confirm({
        title: this.$t("delete_measurement"),
        message: this.$t("delete_measurement_confirm_text", [this.data.name]),
        confirmText: this.$t("delete_measurement"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true;
          let url = "/api/v2/assignment/" + self.id + "/delete";
          const { data } = await axios.post(url);

          if (data.success) {
            self.data = data;

            this.$toast.open({
              message: this.$t("measurement_remove_success"),
              type: "is-success",
              position: "is-top-right",
            });
            self.$emit("reload"); //pide a su componente padre que recargue
            self.hide();
          } else {
            this.isLoading = false;
            // Error
            this.$toast.open({
              message: this.$t("measurement_remove_error"),
              type: "is-danger",
              position: "is-top-right",
            });
          }
        },
      });
    },

    removeFile(index) {
      this.files.splice(index, 1);
    },

    /**
     * Comprueba si el usuario puede administrar la asignación
     */
    isAdmin() {
      return this.data.user_id == this.user.id;
    },

    /**
     * Muestra este modal
     */
    show(assignment_id) {
      this.id = assignment_id;
      this.getProjectUnits();
      this.getAssignmentData();
      this.calculation = {
        units: null,
        length: null,
        width: null,
        height: null,
      }
    },

    _OnFileSelected(file) {
      if (!file) {
          this._OnFileCleared()
          return
      }

      if (this.dxfUrl && this.isLocalFile) {
          URL.revokeObjectURL(this.dxfUrl)
      }

      this.isLocalFile = true
      this.inputFile = file
      this.dxfUrl = URL.createObjectURL(file)

      this._LoadParserDxf(this.dxfUrl)

    },

    _OnFileCleared() {
      if (this.inputFile) {
          this.inputFile = null
          URL.revokeObjectURL(this.dxfUrl)
          this.dxfUrl = null
          this.$q.notify({
              type: "info",
              message: "File cleared"
          })
      }
    },

    async _LoadParserDxf(url) {
      let worker = new DxfWorker(null)
      // const {scene, dxf} = await worker.Load(url, fonts, this.options, progressCbk)
      const { dxf } = await worker._ConvertDxfToJson(url, 'utf-8', null)
      await worker.Destroy()
      worker = null
      this.dxf = dxf
      this.form.total_structures = dxf.entities.filter(item => item.type === 'POLYLINE' || item.type === 'LWPOLYLINE').length
      this.layersDesign = []
      Object.values(dxf.tables.layer.layers).forEach(value => {
        this.layersDesign.push(value);
      })
    },

    async _GenerateDesign() {

      let defaultDesign = this.$refs.frm_default.getDataForm()

      if ( defaultDesign != null ) {
        let cadLayersDesign = {}

        if ( this.layeredDesign ) {
          cadLayersDesign = this.$refs.refDataCadLayersDesign.getDataDesign()
        }

        this.cadLayeredDesign = Object.assign({}, {default: defaultDesign}, cadLayersDesign)

        this.processingData = true
        let worker = new DxfWorker(null)

        this.totalEntitiesDesign = await worker.CreateEntities(this.dxf, this._GetLayers(), this.cadLayeredDesign)

        this.scene = await worker.CreateObject(this.dxf)
        await worker.Destroy()
        worker = null

        await this.sleep(2000)
        this.processingData = false
        this.generatedDesign = true

        // TODO: enviaremos a abrir el modal y pasar datos para ser visualizados para que desde el viewer pueda ser guardado
        // por que se necesita tomar un acaptura al inicio para poder ser luego cargado en el apartado de parque fotovoltaico
        this.$emit('epc-preview', { preview: true, scene: this.scene, layers: this._GetLayers().filter((el) => { return el.create_entities }) })
      }
    },

    async _EditDesign() {
      this.generatedDesign = false
      await this._LoadParserDxf(this.dxfUrl)
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    /**
     * _GetLayers retorna los layers que hacen referencia a los layer manejador por nosotros, no a los que estan definidos en el
     * DXF de momento lo harcodeamos, porque son las que por defecto deben crearse y generarse, pero en el futoro hay que retocar
     * esta parte para adicionar otros dinamicamente
     * Propiedad create_entities indica si se van a crear las entidades al momento de generar el parser del DXF, ademas puede usarse
     * para identificar si se inserta en la tabla pictogram_layers (Si es muy ambiguo crear la propiedad adecuada y ajustar)
     * Propiedad in_design indica que viene en el diseño y no hay la necesidad de crear las entidades
     * Propiedad icon es el icono que representara el layer por defecto seria null
     */
    _GetLayers() {
      return [
        {
          code: 'pilings',
          name: 'Hincados',
          icon: 'band-aid',
          order: 0,
          quantity: 0,
          // total_entities: this.form.total_pilings * this.form.total_structures,
          total_entities: this.totalEntitiesDesign.hasOwnProperty('pilings') ? this.totalEntitiesDesign['pilings'] : 0,
          create_entities: true,
          in_design: false
        },
        {
          code: 'structures',
          name: 'Estructuras',
          icon: 'stream',
          order: 1,
          quantity: this.form.total_structures,
          total_entities: this.form.total_structures,
          create_entities: true,
          in_design: true
        },
        {
          code: 'modules',
          name: 'Modulos',
          icon: 'solar-panel',
          order: 2,
          quantity: 0,
          // total_entities: this.form.total_modules * this.form.total_structures,
          total_entities: this.totalEntitiesDesign.hasOwnProperty('modules') ? this.totalEntitiesDesign['modules'] : 0,
          create_entities: true,
          in_design: false
        },
        {
          code: 'panels',
          name: 'Paneles',
          icon: null,
          order: 3,
          quantity: 0,
          // total_entities: this.form.total_panels * this.form.total_modules * this.form.total_structures,
          total_entities: this.totalEntitiesDesign.hasOwnProperty('panels') ? this.totalEntitiesDesign['panels'] : 0,
          create_entities: false,
          in_design: false
        }
      ]
    },

    onCloseClicked() {
      this.dxf = null;

      this.$emit('epc-close', true)
    },

    _RemoveExtension(filename) {
      return (
        filename.substring(0, filename.lastIndexOf('.')) || filename
      );
    }
  },
};
</script>
<style scoped>
/* Oculta los botones para aumentar los inputs numéricos */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.epc-label {
 display: initial !important;
 color: unset !important;
}
</style>
