<template>
  <div class="gantt-elastic__header" :style="{ ...style['header'] }">
    <div
      class="gantt-elastic__header-title"
      :style="{ ...style['header-title'] }"
    >
      <div
        class="gantt-elastic__header-title--text"
        :style="{ ...style['header-title--text'] }"
        v-if="!opts.title.html"
      >
        {{ opts.title.label }}
      </div>
      <div
        class="gantt-elastic__header-title--html"
        :style="{ ...style['header-title--html'] }"
        v-if="opts.title.html"
        v-html="opts.title.label"
      ></div>
    </div>
    <div
      class="gantt-elastic__header-options flex flex-col lg:flex-row"
      :style="{ ...style['header-options'] }"
    >
      <button
        id="btn-fullscreen"
        class="gantt-elastic__header-btn-fullscreen lg:mr-7"
        :style="{ ...style['header-btn-fullscreen'] }"
        @click.prevent="toggleFullscreen"
      >
        <i class="fas fa-expand"></i> {{ $t('fullscreen') }}
      </button>
      <button
        class="gantt-elastic__header-btn-recenter lg:mr-7"
        :style="{ ...style['header-btn-recenter'] }"
        @click.prevent="recenterPosition"
      >
        {{ opts.locale.Now }}
      </button>
      <label
        class="gantt-elastic__header-label"
        :style="{ ...style['header-label'] }"
      >
        {{ $t('time_scale') }}
        <b-select v-model="step" size="is-small" rounded icon="filter" @input="stepChanged" class="inline-block mr-2">
          <!-- <option value="day">{{ $t('days') }}</option> -->
          <option value="week">{{ $t('weeks') }}</option>
          <option value="month">{{ $t('months') }}</option>
          <option value="year">{{ $t('years') }}</option>
        </b-select>
      </label>

      <label
        class="gantt-elastic__header-label"
        :style="{ ...style['header-label'] }"
      >
        {{ opts.locale["X-Scale"] }}
        <div
          class="gantt-elastic__header-slider-wrapper"
          :style="{ ...style['header-slider-wrapper'] }"
        >
          <vue-slider
            class="gantt-elastic__header-slider"
            tooltip="none"
            :style="{ ...style['header-slider'] }"
            :process-style="{ ...style['header-slider--process'] }"
            :slider-style="{ ...style['header-slider--slider'] }"
            v-model="scale"
            :max="24"
            :min="2"
            width="100px"
          ></vue-slider>
        </div>
      </label>
      <label
        class="gantt-elastic__header-label"
        :style="{ ...style['header-label'] }"
      >
        {{ opts.locale["Y-Scale"] }}
        <div
          class="gantt-elastic__header-slider-wrapper"
          :style="{ ...style['header-slider-wrapper'] }"
        >
          <vue-slider
            class="gantt-elastic__header-slider"
            tooltip="none"
            :style="{ ...style['header-slider'] }"
            :process-style="{ ...style['header-slider--process'] }"
            :slider-style="{ ...style['header-slider--slider'] }"
            v-model="height"
            :max="100"
            :min="7"
            width="100px"
          ></vue-slider>
        </div>
      </label>
      <label
        class="gantt-elastic__header-label"
        :style="{ ...style['header-label'] }"
      >
        <!--
        {{ opts.locale["Before/After"] }}
        <div
          class="gantt-elastic__header-slider-wrapper"
          :style="{ ...style['header-slider-wrapper'] }"
        >
          <vue-slider
            class="gantt-elastic__header-slider"
            tooltip="none"
            :style="{ ...style['header-slider'] }"
            :process-style="{ ...style['header-slider--process'] }"
            :slider-style="{ ...style['header-slider--slider'] }"
            v-model="scope"
            :max="31"
            :min="0"
            width="100px"
          ></vue-slider>
        </div>
        -->
      </label>
      <!--
      <label
        class="gantt-elastic__header-label"
        :style="{ ...style['header-label'] }"
      >
        {{ opts.locale["Task list width"] }}
        <div
          class="gantt-elastic__header-slider-wrapper"
          :style="{ ...style['header-slider-wrapper'] }"
        >
          <vue-slider
            class="gantt-elastic__header-slider"
            tooltip="none"
            :style="{ ...style['header-slider'] }"
            :process-style="{ ...style['header-slider--process'] }"
            :slider-style="{ ...style['header-slider--slider'] }"
            v-model="divider"
            :max="100"
            :min="0"
            width="100px"
          ></vue-slider>
        </div>
      </label>
      -->
      <label
        class="gantt-elastic__header-task-list-switch--wrapper"
        :style="{ ...style['header-task-list-switch--label'] }"
      >
        <switches
          class="gantt-elastic__header-task-list-switch"
          :style="{ ...style['header-task-list-switch'] }"
          v-model="root.state.options.taskList.display"
        ></switches>
        {{ opts.locale["Display task list"] }}
      </label>
    </div>
  </div>
</template>

<script>
  import vueSlider from "vue-slider-component";
  import "vue-slider-component/theme/default.css";
  import Switches from "vue-switches";

  const defaultStyle = {
    header: {
      margin: "0px auto",
      background: "#f3f5f747",
      padding: "10px",
      overflow: "hidden",
      clear: "both",
      display: "flex",
      "justify-content": "space-between",
      "flex-direction": "column"
    },
    // "header-title": {float: "left"},
    "header-title": {display: "flex"},
    // "header-options": {float: "right"},
    // "header-options": {display: "flex", "flex-direction": "column"},
    "header-title--text": {
      "font-size": "20px",
      "vertical-align": "middle",
      "font-weight": "400",
      "line-height": "35px",
      "padding-left": "22px",
      "letter-spacing": "1px"
    },
    "header-title--html": {
      "font-size": "20px",
      "vertical-align": "middle",
      "font-weight": "400",
      "line-height": "35px",
      "padding-left": "22px",
      "letter-spacing": "1px"
    },
    "header-btn-fullscreen": {
      background: "#57b",
      border: "white",
      outline: "none",
      cursor: "pointer",
      color: "white",
      "border-radius": "3px",
      // "margin-right": "27px",
      "font-size": "16px",
      padding: "8px 12px"
    },
    "header-btn-recenter": {
      background: "#95A5A6",
      border: "none",
      outline: "none",
      cursor: "pointer",
      color: "white",
      "border-radius": "3px",
      // "margin-right": "27px",
      "font-size": "16px",
      padding: "8px 12px"
    },
    "header-slider": {
      "box-sizing": "content-box"
    },
    "header-slider-wrapper": {
      display: "inline-block",
      "vertical-align": "middle"
    },
    "header-slider--slider": {"box-sizing": "content-box"},
    "header-slider--process": {"box-sizing": "content-box"},
    "header-task-list-switch--label": {"box-sizing": "content-box"},
    "header-task-list-switch": {
      margin: "0px 15px",
      "vertical-align": "middle"
    },
    "header-label": {}
  };
  const defaultOptions = {
    title: {
      label: "gantt-elastic",
      html: false
    },
    locale: {
      Now: "Now",
      "X-Scale": "Zoom-X",
      "Y-Scale": "Zoom-Y",
      "Task list width": "Task list",
      "Before/After": "Expand",
      "Display task list": "Show task list"
    }
  };
  export default {
    name: "activity-gantt-header",
    components: {
      vueSlider,
      Switches
    },
    props: ["options", "dynamicStyle"],
    inject: ["root"],
    data() {
      return {
        step: "month",
        scaleTimeoutId: null,
        firstScale: false,
        localScale: 0,
        localHeight: 0,
        localBefore: 0,
        localPercent: 0,
        sliderOptions: {
          xScale: {
            value: 0
          },
        wheight: window.innerHeight,
        },
        style: {},
        opts: {}
      };
    },
    created() {
      this.localScale = this.root.state.options.times.timeZoom;
      this.localHeight = this.root.state.options.row.height;
      this.localBefore = this.root.state.options.scope.before;
      this.localPercent = this.root.state.options.taskList.percent;
      this.sliderOptions.xScale.value = this.root.state.options.times.timeZoom;
      this.style = this.root.mergeDeep({}, defaultStyle, this.dynamicStyle);
      this.opts = this.root.mergeDeep({}, defaultOptions, this.options);
    },
    methods: {
      // Comprobamos si esta en pantalla completa, segun el navegador
      getFullscreenElement(){
        return document.fullscreenElement
            || document.webkitFullscreenElement
            || document.mozFullscreenElement
            || document.msFullscreenElement
      },
      toggleFullscreen() {
        // Si está en pantalla completa, salimos
        if (this.getFullscreenElement()){
          document.exitFullscreen();
          document.getElementsByClassName('gantt-elastic')[0].style.background = "white";
          document.getElementById("btn-fullscreen").innerHTML = '<i class="fas fa-expand"></i> '+this.$t('fullscreen');

          // Esperamos a que salga de la pantalla completa, para tomar la altura
          // sin barra de navegacion, si no, coge toda la pantalla
          setTimeout(()=>{
            this.root.state.options.maxHeight = window.innerHeight - 381;
          }, 1000);
        } else {
          this.root.state.options.maxHeight = screen.height;
          document.getElementsByClassName('gantt-elastic')[0].requestFullscreen();
          document.getElementsByClassName('gantt-elastic')[0].style.background = "#e3e3e3"; // un color algo más oscuro, blanco en toda la pantalla es molesto
          document.getElementById("btn-fullscreen").innerHTML = '<i class="fas fa-compress"></i> '+this.$t('exit');
        }
      },
      stepChanged() {
        let timeScale = 2000;

        switch (this.step) {
          case "day":
            timeScale = 2000;
            break;
          case "week":
            timeScale = 8000;
            break;
          case "month":
            timeScale = 16000;
            break;
          case "year":
            timeScale = 120000;
            break;

        }
        this.root.state.options.times.timeScale = 60 * timeScale;
        this.root.state.options.times.stepDuration = this.step;

        this.root.$emit('times-timeZoom-change', this.root.state.options.times.timeZoom);
      },
      getImage() {
        this.root.getImage("image/png").then(imgB64 => {
          const link = document.createElement("a");
          link.href = imgB64;
          link.download = "gantt-elastic.png";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      },
      recenterPosition() {
        this.root.$emit("recenterPosition");
      },
      setScale(value) {
        if (this.scaleTimeoutId !== null) {
          clearTimeout(this.scaleTimeoutId);
          this.scaleTimeoutId = null;
        }
        // debouncing
        if (this.firstScale) {
          this.scaleTimeoutId = setTimeout(() => {
            this.root.$emit("times-timeZoom-change", value);
            this.scaleTimeoutId = null;
          }, 50);
        } else {
          this.root.$emit("times-timeZoom-change", value);
          this.firstScale = true;
        }
      }
    },
    computed: {
      /**
       * If there is a component slot specified for header
       * @returns {bool}
       */
      beforeOptionsIsComponent() {
        const headerSlot = this.options.slots.header;
        if (
          typeof headerSlot.beforeOptions === "object" &&
          !Array.isArray(headerSlot.beforeOptions)
        ) {
          return true;
        }
        return false;
      },
      /**
       * If there is a slot with beforeOptions html content
       * @returns {bool}
       */
      beforeOptionsIsHtml() {
        if (typeof this.options.slots.header.beforeOptions === "string") {
          return true;
        }
        return false;
      },
      scale: {
        get() {
          return this.localScale;
        },
        set(value) {
          this.localScale = Number(value);
          this.setScale(this.localScale);
        }
      },
      height: {
        get() {
          return this.localHeight;
        },
        set(value) {
          this.localHeight = Number(value);
          this.root.$emit("row-height-change", Number(value));
        }
      },
      scope: {
        get() {
          return this.localBefore;
        },
        set(value) {
          this.localBefore = Number(value);
          this.root.$emit("scope-change", Number(value));
        }
      },
      divider: {
        get() {
          return this.localPercent;
        },
        set(value) {
          this.localPercent = Number(value);
          this.root.$emit("taskList-width-change", Number(value));
        }
      }
    }
  };
</script>
