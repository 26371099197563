<template>
  <div class="page-with-top-bar">
    <section>
      <div class="flex items-center justify-end w-full mt-2">
        <v-button v-if="this.company" icon="tasks" @click="goToPage('bulk-actions')" class="mr-4">{{ $t('bulk_actions') }}</v-button>
      </div>
      <!-- colocamos if this.company si es distinto de null se mostrara sino redirecciona para seleccionar company -->
      <div class="mt-4" v-if="this.company">
        <b-tabs type="is-boxed" v-model="mainActiveTab">

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="project-diagram"></b-icon>
              <span> {{ $t('validations') }} </span>
            </template>
            <div>
              <validations-manual
                ref="validations_manual"
              />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="user-friends"></b-icon>
              <span> {{ $t('groups') }} </span>
            </template>
            <div>
              <validations-groups
                ref="validations_groups"
              />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-import"></b-icon>
              <span> {{ $t('import_validations') }} </span>
            </template>
            <div>
              <import-entities
                :can-overwrite="true"
                :company-id="parseInt(this.company.id)"
                entity-type="validations"
              />
            </div>
          </b-tab-item>

        </b-tabs>
      </div>
    </section>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '~/store'
import ImportEntities from "·/components/import-entities";
import ValidationsManual from '·/pages/admin/validations/validationsManual'
import ValidationsGroups from '·/pages/admin/validations/validationsGroups'

export default {
  middleware: 'auth',
  title: 'validations_admin_page_title',
  subtitle: 'validations_admin_page_subtitle',
  menuOption: '0-5',
  components: {
    ImportEntities,
    ValidationsManual,
    ValidationsGroups,
  },

  data: () => ({
    mainActiveTab: 0,
  }),

  computed: {
    ...mapGetters({
      company: 'app/company',
    }),
  },

  mounted() {
    // Redireccionamos si no ha seleccionado la company, esto no es del todo correcto ya que se esta trabajando
    // en el area de administración lo cual se deberīa cambiar esta logica a futuro para que la logica del area de
    // administración este separada la de trabajo
    if (!this.company) {
      this.$notify.info('Debe seleccionar una empresa')
      this.$router.push({ name: "company.select" });
    }
  },

  methods : {
    goToPage(destiny) {

      switch (destiny) {
        case 'bulk-actions':
            this.$router.push({ name: 'admin.validations.bulk-actions'})
            break;
      }

    }
  }

};
</script>
