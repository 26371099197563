<template>
  <div class="">
    <div v-if="isLoading" class="my-4 text-center">
      <scale-loader></scale-loader>
    </div>

    <ModelPreview
      v-for="model in models" :key="'bim' + model.id"
      :modelBim="model"
      @change-view-model=" (e) => { $emit('change-model-view', e) } "
    ></ModelPreview>

  </div>
</template>

<script type="module">
import axios from 'axios';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import ModelPreview from './model-preview.vue'

export default {
  middleware: 'auth',

  components: {
    ScaleLoader,
    ModelPreview
  },

  props: {
    projectId: { type: Number, required: true }
  },

  data() {
    return {
      models: [],
      isLoading: false,
    }
  },

  mounted() {
    this.getModels()
  },

  computed: {

  },

  beforeCreate() {
  },

  created() {

  },

  mounted() {
    // this.getModels() // obtiene los modelos almacenados en el bucket de autodesk
    this.getModelsBim() // optiene desde al base de datos nuestra
  },


  methods: {
    getModels() {
      this.isLoading = true
      let url = '/api/v2/autodesk/forge/getModels';
      let params = {
        project_id: this.projectId
      }
      axios.get(url, { params: params })
        .then(response => {
          this.isLoading = false
          if( response.data.success ) {
            this.models = response.data.models.items
          }
        })
        .catch(error => {
          this.isLoading = false
          console.log('error get models: ', error)
        })
    },

    getModelsBim() {
      this.isLoading = true
      let url = '/api/v2/project/'+ this.projectId +'/document-bims';
      let params = { }
      axios.get(url, { params: params })
        .then(response => {
          this.isLoading = false
          if( response.data.success ) {
            this.$emit('firts-urn-model-bim', response.data.documents[0].urn) // no funcionan correcmente!
            this.models = response.data.documents
          }
        })
        .catch(error => {
          this.isLoading = false
          console.log('error get models: ', error)
        })
    },

    reload() {
      this.getModels()
    }
  },
};
</script>
