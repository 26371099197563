<template>
  <div>
    <div class="top-bar flex justify-end">
      <v-button
        v-if="isAdmin"
        @click="addItem"
        icon="plus">
        {{ $t('add_status') }}
      </v-button>
    </div>
    <div>
      <!-- Tabla de localizaciones -->
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInUp"
        leave-active-class="animated fadeOutDown">
        <div class="panel">

          <div class="w-full flex flex-wrap" v-show="create">
            <div class="panel">
              <form @submit.prevent="createNewItem" class="w-full flex">
                <div class="flex-grow pr-3">
                  <label for="code" class="w-full">{{ $t('code') }}</label>
                  <v-input
                    v-model="item.code"
                    title="code"
                    id="code"
                    name="code"
                    placeholder="code"
                    class="w-full"
                    required
                  />
                </div>

                <div class="flex-grow pr-3">
                  <label for="name" class="w-full">{{ $t('name') }}</label>
                  <v-input
                    v-model="item.name"
                    title="name"
                    id="name"
                    name="name"
                    placeholder="name"
                    class="w-full"
                    required
                  />
                </div>

                <div class="flex-grow-0 pr-3 inline-flex self-end">
                  <v-button
                    native-type="submit"
                    :disabled="!item.name && !item.code"
                  >{{ $t('save') }}
                  </v-button>
                </div>
              </form>
            </div>
          </div>

          <b-table
            show-empty
            :data="data"
            :loading="isLoading"
            paginated
            striped
            bordered
            icon-pack="fas"
          >
            <template v-slot:empty="">
              <h4>{{ $t('no_rows') }}</h4>
            </template>

            <template>
              <b-table-column field="code" >
                <template v-slot:header="">
                  {{ $t('code') }}
                </template>
                <template v-slot="props">
                  {{ props.row.code }}
                </template>
              </b-table-column>

              <b-table-column field="name" >
                <template v-slot:header="">
                  {{ $t('name') }}
                </template>
                <template v-slot="props">
                  {{ props.row.name }}
                </template>
              </b-table-column>

              <b-table-column field="updated_at" sortable >
                <template v-slot:header="">
                  {{ $t('date') }}
                </template>
                <template v-slot="props">
                  {{ new Date(props.row.updated_at).toLocaleDateString() + ' ' + new
                  Date(props.row.updated_at).toLocaleTimeString() }}
                </template>
              </b-table-column>

              <b-table-column field="actions" :visible="isAdmin()" centered width="80">
                <template v-slot:header="">
                  {{ $t('actions') }}
                </template>
                <template v-slot="props">

                  <div class="w-full h-full flex items-center justify-around">
                    <b-tooltip :label="$t('move_down')" position="is-left">
                      <div @click="moveItem(props.row,'down')" class="cursor-pointer flex text-blue">
                        <b-icon pack="fas" icon="arrow-circle-down"/>
                      </div>
                    </b-tooltip>

                    <b-tooltip :label="$t('move_up')" position="is-left">
                      <div @click="moveItem(props.row,'up')" class="cursor-pointer flex text-blue">
                        <b-icon pack="fas" icon="arrow-circle-up"/>
                      </div>
                    </b-tooltip>

                    <b-tooltip :label="$t('edit_status')" position="is-left">
                      <div @click="editItem(props.row)" class="cursor-pointer flex text-blue">
                        <b-icon pack="fas" icon="pencil-alt"/>
                      </div>
                    </b-tooltip>

                    <b-tooltip
                      v-if="!canDelete(props.row)"
                      :label="$t('status_has_usage')"
                      position="is-left"
                    >
                      <div class="cursor-not-allowed flex text-grey">
                        <b-icon pack="fas" icon="trash-alt"/>
                      </div>
                    </b-tooltip>
                    <b-tooltip
                      v-else
                      :label="$t('remove_status')"
                      type="is-danger"
                      position="is-left"
                    >
                      <div @click="removeItem(props.row)" class="cursor-pointer flex text-red">
                        <b-icon pack="fas" icon="trash-alt"/>
                      </div>
                    </b-tooltip>
                  </div>
                </template>
              </b-table-column>
            </template>
          </b-table>

        </div>
      </transition>
    </div>
    <!-- Modal para PREVIEW -->
    <status-edit-modal
      ref="location_modal"
      :entity-type="entityType"
      @reload="() => reload()"
    />
  </div>
</template>

<script>
  import Form from 'vform'
  import StatusEditModal from './status-edit-modal'
  import axios from 'axios'

  export default {
    name: 'project-statuses-manual',

    components: {
      StatusEditModal
    },

    data: () => ({
      data: [],
      isLoading: true,
      create: false,
      item: {
        code: "",
        name: ""
      }
    }),

    props: {
      projectId: {type: Number, required: true},
      entityType: {type: String, default: 'project'}
    },

    mounted() {
      this.getProjectStatuses();
    },

    methods: {
      /**
       * Recupera la lista de status disponible en este proyecto
       * @returns {Promise<void>}
       */
      async getProjectStatuses() {
        this.isLoading = true;
        const {data} = await axios.get('/api/v2/project/' + this.projectId + '/statuses', {params: { entity_type: this.entityType }})
        if (data && data.success) {
          this.data = data.statuses;
          this.total = data.status ? data.status.length : 0
        } else {
          this.$notify.error('error_loading_status_data')
        }
        this.isLoading = false
      },

      /**
       * Recarga los datos
       */
      reload() {
        this.getProjectStatuses();
      },

      /**
       * Abre modal para editar
       * @param item
       */
      editItem(item) {
        this.$refs.location_modal.show(item)
      },

      /**
       * Mueve un lugar hacia arriba en el ordenamiento
       */
      moveItem(item, direction) {
        if (direction != "up" && direction != "down")
          return; //solo obedecemos a up o down

        this.isLoading = true;
        axios({
          method: "post",
          url: "/api/v2/status/" + item.id + "/" + direction,
          data: this.item
        })
          .then(response => {
            this.$toast.open({
              message: this.$t("status_change_success"),
              type: "is-success",
              position: "is-top-right"
            });
            this.create = false;
            this.item.name = "";
            this.reload();
          })
          .catch(error => {
            //Se produjo un error y lo mostramos
            this.$toast.open({
              message: error.error_msg || this.$t("status_change_fail"),
              type: "is-danger",
              position: "is-top-right"
            });
          });
        this.isLoading = false;
      },

      /**
       * Expande el panel para insertar
       * @param item
       */
      addItem(item) {
        this.reload();
        this.create = true;
      },

      /**
       * Comprueba si tiene permisos de administrador en este componente
       * @returns {boolean}
       */
      isAdmin() {
        return true;
      },

      /**
       * Envía los datos al endpoint de inserción
       */
      createNewItem() {
        this.isLoading = true;
        let url = "/api/v2/project/" + this.projectId + "/statuses";
        this.item.entity_type = this.entityType

        axios({
          method: "post",
          url: url,
          data: this.item
        })
          .then(response => {
            this.$toast.open({
              message: this.$t("status_add_success"),
              type: "is-success",
              position: "is-top-right"
            });
            this.create = false;
            this.item.code = "";
            this.item.name = "";
            this.reload();
          })
          .catch(error => {
            //Se produjo un error
            this.isLoading = false;
            if (error.response && error.response.status === 422) {
              //extraemos el primer error de validación
              error.error_msg =
                error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                  ][0];
            }
            // Mostramos el error
            this.$toast.open({
              message: error.error_msg || this.$t("status_add_fail"),
              type: "is-danger",
              position: "is-top-right"
            });
          });
      },

      /**
       * Comprueba si hay permisos para borrar
       * @param item
       * @returns {boolean}
       */
      canDelete(item) {
        return true;
      },

      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },

      /**
       * Llamada a la API para borrar
       * @param item
       * @returns {Promise<void>}
       */
      async removeItem(item) {

        this.$dialog.confirm({
          title: this.$t('remove_status'),
          message: this.$t('remove_status_confirm_text', [item.name]),
          confirmText: this.$t('remove_status'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {

            this.isLoading = true
            let url = '/api/v2/status/' + item.id + '/delete'
            const {data} = await axios.post(url)

            if (data.success) {
              this.$toast.open({
                message: this.$t('status_remove_success'),
                type: 'is-success',
                position: 'is-top-right'
              })
              await this.sleep(1000)
              this.reload()
            } else {
              this.isLoading = false
              // Error
              this.$toast.open({
                message: data.error || this.$t('status_remove_error'),
                type: 'is-danger',
                position: 'is-top-right'
              })
            }
          }
        })
      }
    }
  };
</script>
