<template>
  <a :href="url" class="submenu-item">
    <!-- <b-icon icon="circle" class="submenu-item-icon" custom-size="xs" /> -->
    <span>
      <slot />
    </span>
  </a>
</template>

<script>
export default {
  props: {
    url: { type: String, default: null }
  }
};
</script>
