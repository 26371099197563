<template>
  <div class="page-with-top-bar">

    <div class="top-bar flex justify-end">
      <div class="flex items-center text-white">
        <!-- Importar usuarios
        <v-button-icon-round
                @click="$router.push({name: 'admin.groups.import'})"
                icon="file-import"
                tooltip="import_groups"
                tooltip-position="left"
                class="mr-1"
        />
        -->
      </div>
    </div>

    <div class="w-full flex justify-between mt-4 below-top-bar">
      <div class="flex">

      </div>

      <div class="flex">
          <router-link
            to="/admin/group/"
            class="btn btn-blue rounded"><b-icon icon="plus" pack="fas"/>
            {{ $t('add_group') }}
          </router-link>

      </div>
    </div>
    <div class="panel">
          <div v-if="!loaded" class="text-center my-4">
      <scale-loader></scale-loader>
    </div>
    <div v-else>
    <div class="w-full flex flex-wrap mt-4">
    <section class="w-full">
      <b-table
      :data="groups"
      icon-pack="fas"
      :paginated="isPaginated"
      :per-page="perPage"
      :bordered="true"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :default-sort-direction="defaultSortDirection"
      v-if="loaded">
      <template>

        <b-table-column field="name" cell-class="vertical-center" centered sortable>
          <template v-slot:header="">{{ $t('name') }}</template>
          <template v-slot="props">{{ props.row.name }}</template>
        </b-table-column>

        <b-table-column field="description" cell-class="vertical-center" centered sortable>
          <template v-slot:header="">{{ $t('description') }}</template>
          <template v-slot="props">{{ props.row.description }}</template>
        </b-table-column>

        <b-table-column field="projects" cell-class="vertical-center" centered sortable>
          <template v-slot:header="">{{ $t('projects') }}</template>
          <template v-slot="props">{{ props.row.projects_count }}</template>
        </b-table-column>


        <b-table-column field="actions" cell-class="vertical-center" centered sortable>
          <template v-slot:header="">
            {{ $t('actions') }}
          </template>
          <template v-slot="props">
            <div class="w-full h-full flex items-center justify-around">
                <router-link :to="'/admin/group/' + props.row.id" class="flex pr-0">
                  <b-tooltip :label="$t('edit_group')" type="is-primary" position="is-left">
                    <div class="cursor-pointer flex text-blue">
                      <b-icon icon="pencil-alt" pack="fas" />
                    </div>
                  </b-tooltip>
              </router-link>

              <b-tooltip :label="$t('remove_group')" position="is-left" type="is-danger">
                  <div @click="deleteGroupButtonClicked(props.row.id, props.row.name)"
                      class="cursor-pointer flex text-red">
                    <b-icon pack="fas" icon="trash-alt"/>
                  </div>
                </b-tooltip>
            </div>
          </template>
      </b-table-column>
    </template>
    <template slot="bottom-left">
        <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
        <b>{{ $t("total_groups") }}</b>: {{ this.groups.length }}
    </template>

    <template #empty>
        <div class="has-text-centered">{{ $t('no_results') }}</div>
    </template>

  </b-table>
</section>
    </div>
</div>
</div>
</div>
</template>

<script>
import axios from 'axios'
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  middleware: 'auth',
  title: 'groups_page_title',
  subtitle: 'groups_page_subtitle',
  menuOption: '0-1',
  components: {
    ScaleLoader
  },

  data: () => ({
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 20,
    loaded: false,
    users: null,
    showArchive: 0
  }),

  mounted () {
    this.getGroups()
  },

  methods: {

    async getGroups () {
      const { data } = await axios.get("/api/v2/user/groups", {'params' : { withProjects : 0 }});

      if (data.groupsWithAccess) {
        this.groups = data.groupsWithAccess;
        this.loaded = true
      }
    },

    deleteGroupButtonClicked(groupId, groupName) {

        let self = this;
        this.$dialog.confirm({
          title: this.$t('delete_group'),
          message: this.$t('delete_group_confirm_text', [groupName]),
          confirmText: this.$t('delete_group'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            let url = '/api/v2/group/' + groupId + '/delete'
            const {data} = await axios.post(url)
            if (data && data.success) {
              await this.$store.dispatch("app/setGroup", { });
              this.loaded = false
              this.$notify.success('success_deleting_group')
              self.getGroups()
            } else {
              this.$notify.error(data.error)
            }
          }
        })
    },

  },


};
</script>
