<template>
  <div class="w-full">
    <div class="p-2 text-gray-900 bg-white rounded-lg shadow-lg- font-medium capitalize text-right"
      :class="{ 'flex flex-row overflow-y-auto': isClassic, 'wrap-menu': !isClassic }">
      <!-- <span class="px-2 mr-2 border-r border-gray-800">
        <img src="https://www.freepnglogos.com/uploads/spotify-logo-png/file-spotify-logo-png-4.png" alt="alt placeholder" class="w-8 h-8 -mt-1 inline mx-auto">
      </span> -->

      <button-circle v-if="!isClassic"
        v-for="(b, index) in _buttons" :key="'qbtn_'+index"
        :text-label="b.text"
        :icon="b.icon"
        :active-tooltip="b.tooltip"
        :handle-on-click="b.handleOnClick"
      ></button-circle>

      <button-classic v-if="isClassic"
        v-for="(b, index) in _buttons" :key="'qbtn_'+index"
        :text-label="b.text"
        :show-text="b.showText"
        :icon="b.icon"
        :active-tooltip="b.tooltip"
        :handle-on-click="b.handleOnClick"
      ></button-classic>



      <slot></slot>

      <!-- <span class="w-10 relative float-right mr-3 cursor-pointer hover:text-gray-700">
        <i class="w-8 fas fa-user p-2 bg-gray-200 rounded-full">
        </i>
        <span class="absolute right-0 top-0 -mt-1 -mr-1 text-xs bg-yellow-500 text-black font-medium px-2 rounded-full">3</span>
      </span> -->
    </div>
  </div>
</template>

<script>

import ButtonCircle from '../buttons-menu/button-circle.vue';
import ButtonClassic from '../buttons-menu/button-classic.vue'

  export default {
    name: 'quick-button-menu',

    components: {
      ButtonCircle,
      ButtonClassic
    },

    computed: {
      _buttons() {
        return this.buttons.filter((el) => { return el.visible } )
      }
    },


    props: {
      buttons: { type: Array, default() { return [] } },
      isClassic: { type: Boolean, default: false }
    },

    data: () => ({

    }),

    watch: {},

    mounted() {
    },

    methods: {

    },

  };
</script>

<style scoped>
.wrap-menu .b-tooltip:not(:last-child) {
  margin-right: 3px;
}
</style>
