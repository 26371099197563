<template>
  <b-modal :active.sync="isModalActive" :width="640" scroll="keep">
    <div class="border border-blue-light rounded shadow bg-white" v-if="location">
      <header class="w-full bg-grey-lightest px-4 text-xl font-thin uppercase text-blue-light">
        {{ $t('edit_location') }}
      </header>
      <div class="w-full px-8 pb-8 pt-6 bg-grey-lightest">
        <form @submit.prevent="updateLocation" class="w-full">
        <div class="border-grey-light border-b border-dashed mb-3">

          <div class="w-full inline-flex pb-3">
            <label form="reference_code" class="md:w-1/3">{{ $t('reference_code')}}</label>
            <v-input
                    v-model="formLocation.reference_code"
                    title="reference_code"
                    required="true"
                    name="reference_code"
                    placeholder="reference_code"
                    class="md:w-2/3"
                    maxlength="100"
            />
          </div>
          <div class="w-full inline-flex pb-3">
            <div class="w-full md:w-1/2 inline-flex">
              <label form="latitude" class="md:w-1/4">{{ $t('latitude')}}</label>
              <v-input
                      v-model="formLocation.latitude"
                      title="latitude"
                      type="number"
                      step="any"
                      required="true"
                      name="latitude"
                      placeholder="latitude"
                      class="md:w-3/4"
              />
            </div>

            <div class="w-full md:w-1/2 inline-flex pl-4">
              <label form="longitude" class="md:w-1/4">{{ $t('longitude')}}</label>
              <v-input
                      v-model="formLocation.longitude"
                      title="longitude"
                      type="number"
                      step="any"
                      required="true"
                      name="longitude"
                      placeholder="longitude"
                      class="md:w-3/4"
              />
            </div>
          </div>

          <div class="w-full inline-flex justify-end pb-3">
            <button
                    class="btn btn-blue"
                    nativeType="submit"
            >
              {{$t('edit')}}
            </button>
          </div>

        </div>
        </form>

        <div class="content">
          {{ $t('status') + ': '}}<strong>{{ location.state }}</strong><br>
          {{ $t('description') + ': '}}<strong>{{ location.description }}</strong><br>
          {{ $t('comments') + ': '}}<strong>{{ location.comments }}</strong><br>
          {{ $t('last_update') + ': '}}
            <strong>
              {{ new Date(location.updated_at).toLocaleDateString() + ' ' + new Date(location.updated_at).toLocaleTimeString() }}
            </strong>
        </div>

      </div>
    </div>

  </b-modal>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Form from 'vform'

export default {
  name: "location-modal",

  components: {

  },

  data: () => ({
    isModalActive: false,
    location: null,
    formLocation: new Form ({
      reference_code: '',
      latitude: '',
      longitude: '',
    }),
  }),

  methods: {

    async updateLocation () {

      const { data } = await this.formLocation.post('/api/v2/location/' + this.location.id)

      if (data.success) {

        this.$toast.open({
          message: this.$t('location_change_success'),
          type: 'is-success',
          position: 'is-top-right'
        })

        this.hide()
        this.$emit("reload-locations");

      } else {

        this.isLoading = false
        let msg = data.error_msg ? data.error_msg : this.$t('location_change_fail')

        // Error
        this.$toast.open({
          message: msg,
          type: 'is-danger',
          position: 'is-top-right'
        })
      }
    },

    show(location) {
      this.location = location;
      this.formLocation.reference_code = location.reference_code
      this.formLocation.latitude = location.latitude
      this.formLocation.longitude = location.longitude
      this.isModalActive = true;
    },

    hide() {
      this.location = null;
      this.isModalActive = false;
      this.formLocation.reference_code = ''
      this.formLocation.latitude = ''
      this.formLocation.longitude = ''
    },

  }
};
</script>
<style scoped>
  /* Oculta los botones para aumentar los inputs numéricos */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }

</style>
