<template>
  <div>
    <div>
      <label class="font-bold">Acción masiva: {{ action.title }}</label>
      <p v-if="action.name == 'assign_group'">Seleccione el grupo al que se vincularán las actividades seleccionadas</p>
      <p class="text-sm"> <b-icon pack="fas" icon="info-circle" type="is-info" size="is-small" /><i>Solo se asignará el grupo a las actividades padres</i></p>
    </div>

    <div class="panel">

      <!-- <label class="font-bold">Acción masiva: {{ action.title }}</label> -->


      <div  class="flex" v-if="action.name === 'change_data'">
        <!-- <div class="w-1/4 pr-4">
          <l-form-double-date
            :form="form"
            startField="scheduled_start_date"
            endField="scheduled_end_date"
            label="scheduled_date"
            :disabled="!this.createNew ? true : !this.isCreator"
          />
        </div> -->
        <div class="w-1/4 pr-4">
          <l-form-double-date
            :form="form"
            startField="current_start_date"
            endField="current_end_date"
            label="estimated_date"
          />
        </div>
        <!-- <div class="w-1/4 pr-4">
          <l-form-double-date
            :form="form"
            startField="real_start_date"
            endField="real_end_date"
            label="real_date"
            :disabled="false"
          />
        </div>

        <div class="w-1/4">
          <div class="w-1/2">
            <b-field :label="$t('expected_end_date')">
              <b-input
                :value="calculateExpectedEndDate"
                :disabled="true"
              />
            </b-field>
          </div>
        </div> -->
      </div>

      <div class="w-full items-center" v-if="action.name == 'link_tags'">
        <span class="label">{{ $t('activity_tags') }}</span>
        <model-tags
          :model-id="0"
          model-type="activity"
          :allow-edit="false"
          :add-from-list="true"
          :initialParentTags="optionTags"
          :parent-model-id="0"
          parent-model-type="project"
          ref="model_tags"
          @epc-tags-changed="handleChangedTags"
        />
      </div>

      <div class="w-full items-center" v-if="action.name == 'assign_group'">
        <b-field :label="$t('groups')">
          <treeselect
            v-model="selectedGroup" :multiple="false"
            :options="groups"
            @select="handleSelectedGroup"
          />
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import Form from 'vform'
import moment from 'moment'
import ModelTags from "../../components/model-tags";

// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

Vue.use(moment)

export default {
  name: 'wizard-bulk-complete',

  components: {
    ModelTags,
    Treeselect
  },

  props: {
    projectId: { type: Number, default: 0 },
    action: { type: Object, default: null },
    optionTags: {type: Array, function () { return [] }},
  },

  data: () => ({
    form: new Form({
      scheduled_start_date: null,
      scheduled_end_date: null,
      current_start_date: null,
      current_end_date: null,
      real_start_date: null,
      real_end_date: null,
      expected_end_date: null,
      tags: '',
      show_on_devices: 1,
      in_planning: false,
      weight: null,
    }),

    groups: [],
    selectedGroup: null,
  }),

  computed: {
  },

  mounted() {
    this.getGroups()
  },

  created() {

  },

  watch: {
    form: {
      handler(val, oldVal) {
        if( this.action.name === 'change_data' )
          this.prepareData()
      },
      deep: true
    }
  },

  methods: {
    async getGroups() {
      let params = {
        template: 'select'
      };

      const { data } = await axios.get(
        "/api/v2/project/" + this.projectId + "/activity/groups",
        { params: params }
      );

      if (data && data.success) {
        this.groups = data.groups;
      } else {
        this.$notify.error("error_loading_extra_fields_data");
      }
    },


    prepareData() {
      let data = {
        current_start_date: moment(this.form.current_start_date).format('YYYY-MM-DD'),
        current_end_date: moment(this.form.current_end_date).format('YYYY-MM-DD'),
      }

      this.dispatchData([data])
    },

    dispatchData(data) {
      this.$emit('selected-linked-entities', data)
    },

    handleChangedTags(values) {
      let tags = values.map(function(tag, index) {
          return { id: index, text: tag.text}
      })

      this.dispatchData(tags)
    },

    handleSelectedGroup(node, instanceId) {
      let data = {
        group_id: node.id,
        group_name: node.label,
      }
      this.dispatchData([data])
    }
  }
}

</script>
