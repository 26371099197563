<template>
    <div>
      <div class="flex items-center justify-between  mb-4">
        <v-button icon="arrow-left" @click="goToPage('edit-activity')">{{ $t('activity') }}</v-button>
        <div class="flex items-center space-x-2">
          <v-button icon="file-export" color="green" @click="exportPipes">{{ $t('export') }}</v-button>
          <v-button icon="plus" color="green" @click="addDeletePipe = true">{{ $t('add_pipe') }}</v-button>
        </div>
      </div>
    
      <!-- Formulario Agregar Tuberia -->
      <div class="border border-blue-lighter rounded bg-blue-lightest p-2" v-if="addDeletePipe">
        <div class="flex items-center mb-4">
          <span class="flex-grow text-xl font-thin uppercase text-blue-light">{{ $t('add_pipe') }}</span>
          <v-button-icon-round
            icon="times"
            tooltip="close"
            tooltip-position="left"
            color="red"
            @click="addDeletePipe=false; pipeForm={}"
          />
        </div>
        <div class="w-full">
          <div class="grid grid-cols-3 gap-3">
            <div class="pr-4">
              <label>{{ $t('name') }}</label>
              <b-input
              @input="handleInputSearch"
              v-model="pipeName"
              type="text" 
              :clearable="clearable"
              expanded
              ></b-input>
            </div>
            <div class="pr-4">
              <label>{{ $t('element') }}</label>
              <b-select
                v-model="pipeForm.filterElement"
                @input="getDismissedPipes"
                :placeholder="$t('select')" expanded>
                  <option value="">{{ $t('select') }}</option>
                  <option v-for="option in ElementsList" :value="option" :key="'type_'+option">
                    {{ option}}
                  </option>
              </b-select>
            </div>
    
            <div class="pr-4">
              <label>{{ $t('element_type') }}</label>
              <b-select
                v-model="pipeForm.filterElementType"
                @input="getDismissedPipes"
                :placeholder="$t('select')" expanded>
                  <option value="">{{ $t('select') }}</option>
                  <option v-for="option in ElementTypesList" :value="option" :key="'type_'+option">
                    {{ option}}
                  </option>
              </b-select>
            </div>
    
            <div class="pr-4">
              <label>{{ $t('material') }}</label>
              <b-select
                v-model="pipeForm.filterMaterial"
                @input="getDismissedPipes"
                :placeholder="$t('select')" expanded>
                  <option value="">{{ $t('select') }}</option>
                  <option v-for="option in MaterialsList" :value="option" :key="'type_'+option">
                    {{ option}}
                  </option>
              </b-select>
            </div>
    
            <div class="pr-4">
              <label>{{ $t('diameter') }}</label>
              <b-select
                v-model="pipeForm.filterDiameter"
                @input="getDismissedPipes"
                :placeholder="$t('select')" expanded>
                  <option value="">{{ $t('select') }}</option>
                  <option v-for="option in DiameterList" :value="option" :key="'type_'+option">
                    {{ option}}
                  </option>
              </b-select>
            </div>
            <!-- btable results-->
            
          </div>
          <div class="panel">
            <div v-if="isLoadingDismissedPipes" class="my-4 text-center">
              <scale-loader></scale-loader>
            </div>
            <div v-else>
              <div class="flex flex-wrap w-full mt-4">
                <section class="w-full mb-6">
                  <b-table
                    icon-pack="fas"
                    :data="dismissed_pipes_data"
                    :paginated="isPaginatedDismissedPipes"
                    :per-page="perPageDismissedPipes"
                    :bordered="true"
                    checkable
                    sticky-checkbox
                    :checked-rows.sync="filasSeleccionadas"
                    :current-page.sync="currentPageDismissedPipes"
                    :pagination-simple="isPaginationSimpleDismissedPipes"
                    :default-sort-direction="defaultSortDirectionDismissedPipes"
                    v-if="!isLoading">
                    <template>
                      <b-table-column field="name" sortable>
                        <template v-slot:header="">{{ $t('name') }}</template>
                        <template v-slot="props">{{ props.row.name }}</template>
                      </b-table-column>
                      <b-table-column field="element" sortable>
                        <template v-slot:header="">{{ $t('element') }}</template>
                        <template v-slot="props">{{ props.row.element }}</template>
                      </b-table-column>
                      <b-table-column field="element_type" sortable>
                        <template v-slot:header="">{{ $t('element_type') }}</template>
                        <template v-slot="props">{{ props.row.element_type }}</template>
                      </b-table-column>
                      <b-table-column field="material" sortable>
                        <template v-slot:header="">{{ $t('material') }}</template>
                        <template v-slot="props">{{ props.row.material }}</template>
                      </b-table-column>
                      <b-table-column field="diameter" sortable>
                        <template v-slot:header="">{{ $t('diameter') }}</template>
                        <template v-slot="props">{{ props.row.diameter }}</template>
                      </b-table-column>
                      <!-- colum input -->
                      <b-table-column field="units" sortable>
                        <template v-slot:header="">{{ $t('units') }}</template>
                        <template v-slot="props">
                          <b-input v-model="props.row.units" type="text" expanded></b-input>
                        </template>
                      </b-table-column>
                    </template>
                    <template slot="bottom-left">
                        <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                        <b>{{ $t("total") }}</b>: {{ this.dismissed_pipes_data.length }}
                    </template>
                    <template #empty>
                        <div class="has-text-centered">{{ $t('no_results') }}</div>
                    </template>
                  </b-table>
                </section>
              </div>
            </div>
          </div>

          <div class="flex justify-center items-center">
            <button class="btn btn-green btn-lg p-4" type="button" @click="addPipe()">
              <i class="fas fa-angles-down mr-2"></i> {{ $t('add_pipe') }}
            </button>
          </div>
        </div>
    
      </div>
    
      <div class="panel">
        <div v-if="isLoading" class="my-4 text-center">
          <scale-loader></scale-loader>
        </div>
        <div v-else>
          <div class="flex flex-wrap w-full mt-4">
            <section class="w-full mb-6">
              <div class="flex items-center mb-4">
                <span class="flex-grow text-xl font-thin uppercase text-blue-light">{{ $t('deleted_pipes') }}</span>
              </div>
              <b-table
                icon-pack="fas"
                :data="activity_dismissed_pipes_data"
                :paginated="isPaginated"
                :per-page="perPage"
                :bordered="true"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :default-sort-direction="defaultSortDirection"
                v-if="!isLoading">
                <template>
                  
                  <b-table-column field="element" sortable>
                    <template v-slot:header="">{{ $t('element') }}</template>
                    <template v-slot="props">{{ props.row.pipe.element }}</template>
                  </b-table-column>
    
                  <b-table-column field="element_type" sortable>
                    <template v-slot:header="">{{ $t('element_type') }}</template>
                    <template v-slot="props">{{ props.row.pipe.element_type }}</template>
                  </b-table-column>
    
                  <b-table-column field="material" sortable>
                    <template v-slot:header="">{{ $t('material') }}</template>
                    <template v-slot="props">{{ props.row.pipe.material }}</template>
                  </b-table-column>
    
                  <b-table-column field="diameter" sortable>
                    <template v-slot:header="">{{ $t('diameter') }}</template>
                    <template v-slot="props">{{ props.row.pipe.diameter }}</template>
                  </b-table-column>
    
                  <b-table-column field="units" sortable>
                    <template v-slot:header="">{{ $t('units') }}</template>
                    <template v-slot="props">{{ props.row.units }}</template>
                  </b-table-column>
    
                  <b-table-column field="actions" width=100 sortable>
                    <template v-slot:header="">{{ $t('actions') }}</template>
                    <template v-slot="props">
                      <div class="flex items-center justify-around w-full h-full">
                        <b-tooltip :label="$t('delete')" position="is-left" type="is-danger">
                            <div @click="confirmDelete(props.row)"
                                class="flex cursor-pointer text-red">
                              <b-icon pack="fas" icon="trash-alt"/>
                            </div>
                        </b-tooltip>
                      </div>
                    </template>
                  </b-table-column>
                </template>
    
                <template slot="bottom-left">
                    <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                    <b>{{ $t("total") }}</b>: {{ this.activity_dismissed_pipes_data.length }}
                </template>
    
                <template #empty>
                    <div class="has-text-centered">{{ $t('no_results') }}</div>
                </template>
    
              </b-table>
            </section>
          </div>
        </div>
      </div>
    
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
    import Form from 'vform'

    // import moment from 'moment'
    
    // Vue.use(moment)
    
    export default {
      middleware: 'auth',
      title: 'activity_edit_page_title',
      subtitle: 'activity_edit_page_subtitle',
      menuOption: '2-2',
    
      components: {
        ScaleLoader,
      },
    
      data: () => ({
        isLoading: true,
        page: 1,
        perPage: 10,
        total: 0,
        orderField: "reference_code",
        orderDir: "asc",
        currentPage: 1,
        isPaginationSimple: false,
        defaultSortDirection: 'asc',
        isPaginated: true,
        
        // talbe dismissed pipes
        isLoadingDismissedPipes: true,
        pageDismissedPipes: 1,
        perPageDismissedPipes: 5,
        totalDismissedPipes: 0,
        orderFieldDismissedPipes: "reference_code",
        orderDirDismissedPipes: "asc",
        currentPageDismissedPipes: 1,
        isPaginationSimpleDismissedPipes: false,
        defaultSortDirectionDismissedPipes: 'asc',
        isPaginatedDismissedPipes: true,

        activity: {},

        dismissed_pipes_data: [],
        activity_dismissed_pipes_data: [],

        activityId: null,
        subactivityId: null,
        isSubactivity: false,
        pipeForm: new Form({
          // TODO
          filterElement: '',
          filterElementType: '',
          filterMaterial: '',
          filterDiameter: '',
        }),
    
        addDeletePipe: false,
        // filer values
        ElementsList: [],
        ElementTypesList: [],
        MaterialsList: [],
        DiameterList: [],
        
        filasSeleccionadas: [],
        //autocomplete
        pipeName: '',
        keepFirst: true,
        openOnFocus: true,
        clearable: true,

      }),
    
    
      created() {
        this.debouncedSearch = this.debounce(this.getDismissedPipes, 750)
        this.activityId = this.$route.params.activity_id
        this.subactivityId = this.$route.params.subactivity_id || null
        this.isSubactivity = this.$route.name === 'subactivity.dismissed-pipes.edit' // falso, no existe
      },
    
      mounted() {
        this.getDismissedPipes()
        this.getActivityDismissedPipes()
      },

      computed: {

        filteredPipeNamesObj() {
          let self = this
          return this.dismissed_pipes_data.filter(option => {
            return (
                option.name
                  .toString()
                  .toLowerCase()
                  .indexOf(self.pipeName.toLowerCase()) >= 0
            )
          })
        },
      },

      methods: {
        async getDismissedPipes() {
          let id = this.isSubactivity ? this.subactivityId : this.activityId
          let params = {
            filterName: this.pipeName,
            filterElement: this.pipeForm.filterElement,
            filterElementType: this.pipeForm.filterElementType,
            filterMaterial: this.pipeForm.filterMaterial,
            filterDiameter: this.pipeForm.filterDiameter,
          }
          const { data } = await axios.get('/api/v2/activity/' + id + '/dismissed-pipes', { params: params})

          if (data && data.success) {
            this.dismissed_pipes_data = data.data.pipes
            this.ElementsList = data.data.elements
            this.ElementTypesList = data.data.elementTypes
            this.MaterialsList = data.data.materials
            this.DiameterList = data.data.diameters
            
          } else {
            this.$notify.error('error_loading_activity_data')
          }
    
          this.isLoadingDismissedPipes = false;
        },

        

        async getActivityDismissedPipes() {
          let id = this.isSubactivity ? this.subactivityId : this.activityId
          const { data } = await axios.get('/api/v2/activity/' + id + '/activity-dismissed-pipes')
          if (data && data.success) {
            this.activity_dismissed_pipes_data = data.activityDismissedPipes
          } else {
            this.$notify.error('error_loading_activity_data')
          }
    
          this.isLoading = false;
        },

        setOptions(option) {
          if (option) {
            this.pipeForm.pipe_id = option.id
            this.pipeForm.element = option.element
            this.pipeForm.element_type = option.element_type
            this.pipeForm.material = option.material
            this.pipeForm.diameter = option.diameter
          } else {
            this.pipeForm.pipe_id = ''
            this.pipeForm.element = ''
            this.pipeForm.element_type = ''
            this.pipeForm.material = ''
            this.pipeForm.diameter = ''
          }
        },

        // export pipes 
        async exportPipes() {
          this.$notify.success('starting_download_document')
          const { data } = await axios.get('/api/v2/report/dismissed-pipes/' + this.activityId)
          
          if (data && data.success) {
            this.$notify.success('download_folder_progress')
            window.open(data.url)

          } else if (data && data.msg) {
            this.$notify.error(data.msg)
          } else {
            this.$notify.error('download_folder_error')
          }
        },
        handleInputSearch() {
          if (this.pipeName.length > 1){
            this.debouncedSearch()
          } else {
            this.dismissed_pipes_data = []
          }
        },
        // funcion que espera a que se deje de escribir para enviar la peticion
        debounce(func, wait) {
          let timeout;
          return function (...args) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => {
              func.apply(context, args);
            }, wait);
          };
        },
        // funcion para convertir un objeto en una lista de objetos para usar en un select
        objToList(data) {  
          return Object.keys(data)  
            .filter(key => Number.isInteger(parseInt(key)))
            .map(key => ({ name: data[key], value: parseInt(key) }));  
        },

        async addPipe() {
          let id = this.isSubactivity ? this.subactivityId : this.activityId
          let url = '/api/v2/activity/' + id + '/activity-dismissed-pipe' 
    
          try{
            
            // verificamos si units existe en filasSeleccionadas
            if (this.filasSeleccionadas.length === 0) {
              this.$notify.error('none_selected')
              return
            }

            // Verificamos si units está vacío en alguna fila
            if (this.filasSeleccionadas.some(item => !item.units)) {
              this.$notify.error('units_required');
              return;
            }
            
            await Promise.all(this.filasSeleccionadas.map(async item => {
              const pipeData = {
                pipe_id: item.id,
                units: item.units,
              }
              const { data } = await axios.post(url, pipeData)
              if (data && data.success) {
                this.$notify.success('success_creating_dismissed_pipe')
                this.pipeForm = {
                  filterElement: '',
                  filterElementType: '',
                  filterMaterial: '',
                  filterDiameter: '',
                }
                this.addDeletePipe = false;
                // this.getDismissedPipes()
                this.getActivityDismissedPipes()
                this.pipeName = ''
              } else if(data && data.msg){
                this.$notify.error('no_access_permissions')
                this.$router.push({ name: 'project.home', params: {} })
              } else {
                this.$notify.error('error_saving')
              }
            }))

            // quitamos las filas seleccionadas
            this.filasSeleccionadas = []
            this.getDismissedPipes()
          }
          catch(error){
            //Se produjo un error
            this.isLoading = false;
            if (error.response && error.response.status === 422) {
              //extraemos el primer error de validación
              error.error_msg =
                error.response.data.errors[Object.keys(error.response.data.errors)[0]][0];
            }
    
            // Mostramos el error
            this.$toast.open({
              message: error.error_msg || this.$t("assignment_update_error"),
              type: "is-danger",
              position: "is-top-right"
            });
          }
        },
    
        getEntityId() {
          return this.isSubactivity ? parseInt(this.subactivityId) : parseInt(this.activityId)
        },
    
        goToPage(destiny = 'extrafields') {
          switch (destiny) {
            case 'edit-activity':
                if (this.isSubactivity) {
                  this.$router.push({ name: 'subactivity.edit', params: {
                    subactivity_id: this.subactivityId, activity_id: this.activityId
                  } })
                } else {
                  this.$router.push({ name: 'activity.edit', params: {
                    activity_id: this.activityId
                  } })
                }
                break;
    
            case 'dismissed-pipes':
                break;
          }
    
        },
        
        confirmDelete(item) {
          this.$buefy.dialog.confirm({
            title: this.$t('deleting_pipe'),
            message: this.$t('delete_pipe_confirm_text'),
            confirmText: this.$t('delete'),
            cancelText: this.$t('cancel'),
            type: 'is-danger',
            
            onConfirm: () => this.removePipe(item)
          })
        },

        async removePipe(item){
          let url = '/api/v2/activity/' + this.activityId + '/activity-dismissed-pipe/' + item.id
          const { data } = await axios.delete(url)
          if (data && data.success) {
            this.$notify.success('removed_successfully')
            // this.getDismissedPipes()
            this.getActivityDismissedPipes()
          } else if(data && data.msg){
            this.$notify.error('no_access_permissions')
          } else {
            this.$notify.error('remove_error')
          }
        },
      },
    };
    </script>
    