<template>
  <div>
    <div class="w-full flex justify-between below-top-bar" v-if="showPanel1">
      <div class="flex flex-grow space-x-4">
        <div class="w-3/4 p-5 bg-white border border-gray-200 rounded-lg shadow">
          <h5 class="mb-2 text-2xl font-semibold tracking-tight text-blue-light">
            <b-icon pack="fas" icon="shopping-cart"></b-icon> <span>{{ $t('inputs') }} ({{ $t('purchases') }})</span>
          </h5>
          <p class="mb-3 font-normal text-5xl text-gray-500 text-center"> {{ showQuantity(info.entries) }}</p>
          <p class="mb-3 font-normal text-3xl text-gray-500 text-right">{{ showCurrency(info.entries_price) }}</p>
        </div>

        <div class="w-3/4 p-5 bg-white border border-gray-200 rounded-lg shadow">
          <h5 class="mb-2 text-2xl font-semibold tracking-tight text-blue-light">
            <b-icon pack="fas" icon="shopping-cart" class="fa-flip-horizontal"></b-icon> <span>{{ $t('outputs') }}</span>
          </h5>
          <p class="mb-3 font-normal text-5xl text-gray-500 text-center">{{ showQuantity(info.outs) }}</p>
          <p class="mb-3 font-normal text-3xl text-gray-500 text-right">{{ showCurrency(info.outs_price) }}</p>
        </div>

        <div class="w-3/4 p-5 bg-white border border-gray-200 rounded-lg shadow">
          <h5 class="mb-2 text-2xl font-semibold tracking-tight text-blue-light">
            <b-icon pack="fas" icon="cubes"></b-icon> <span>{{ $t('stock') }}</span>
          </h5>
          <p class="mb-3 font-normal text-5xl text-gray-500 text-center">{{ showQuantity(info.stock) }}</p>
          <p class="mb-3 font-normal text-3xl text-gray-500 text-right">{{ showCurrency(info.stock_price) }}</p>
        </div>
      </div>
    </div>
    <div class="w-full flex justify-between below-top-bar mt-4" v-if="showPanel2">
      <div class="flex flex-grow space-x-1">
        <div class="w-3/12 p-1 text-white border border-gray-200 rounded-lg shadow flex justify-end items-center"
          style="background-color: rgb(85, 119, 187);">
          <p class="font-normal text-right"> Cantidad de productos</p>
        </div>

        <div class="w-3/12 p-1 bg-white border border-gray-200 rounded-lg shadow flex justify-center items-center">
          <p class="font-normal text-gray-500 text-center text-3xl"> {{ showQuantity(info.stock_products) }}</p>
        </div>

        <div class="w-3/12 p-1 text-white border border-gray-200 rounded-lg shadow flex justify-end items-center"
          style="background-color: rgb(85, 119, 187);">
          <p class="font-normal text-right">Duracion de inventario (días)</p>
        </div>

        <div class="w-3/12 p-1 bg-white border border-gray-200 rounded-lg shadow flex justify-center items-center">
          <p class="font-normal text-gray-500 text-center text-3xl"> {{ showQuantity(info.inventory_duration) }}</p>
        </div>

        <div class="w-3/12 p-1 text-white border border-gray-200 rounded-lg shadow flex justify-end items-center"
          style="background-color: rgb(85, 119, 187);">
          <p class="font-normal text-right">Stock Máximo</p>
        </div>

        <div class="w-3/12 p-1 bg-white border border-gray-200 rounded-lg shadow flex justify-center items-center">
          <p class="font-normal text-gray-500 text-center text-3xl"> {{ showQuantity(info.stock_max) }}</p>
        </div>

        <div class="w-3/12 p-1 text-white border border-gray-200 rounded-lg shadow flex justify-end items-center"
          style="background-color: rgb(85, 119, 187);">
          <p class="font-normal text-right">Stock Minimo</p>
        </div>

        <div class="w-3/12 p-1 bg-white border border-gray-200 rounded-lg shadow flex justify-center items-center">
          <p class="font-normal text-gray-500 text-center text-3xl"> {{ showQuantity(info.stock_min) }}</p>
        </div>

        <div class="w-3/12 p-1 text-white border border-gray-200 rounded-lg shadow flex justify-end items-center"
          style="background-color: rgb(85, 119, 187);">
          <p class="font-normal text-right">Stock de seguridad</p>
        </div>

        <div class="w-3/12 p-1 bg-white border border-gray-200 rounded-lg shadow flex justify-center items-center">
          <p class="font-normal text-gray-500 text-center text-3xl"> {{ showQuantity(info.security_stock) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from 'axios'

import { parseQuantity, formatCurrency } from './helpers.js'

export default {

  components: {

  },

  props: {
    projectId: { type: Number, require: true },
  },

  computed: {
    filters() {
      let filters = {
      }

      return filters
    }
  },

  data: function () {
    return {
      isLoadingTable: true,
      info: {
        'entries': 0,
        'entries_price': 0,
        'outs': 0,
        'outs_price': 0,
        'stock': 0,
        'stock_price': 0,

        'stock_products': 0,
        'inventory_duration': 0,
        'stock_max': 0,
        'stock_min': 0,
        'security_stock': 0,
      },

      showPanelHeader: false,
      showPanel1: true,
      showPanel2: false,
    }
  },

  created() {
    this.getInfoDashboard()
  },

  methods: {
    async getInfoDashboard(filters = null) {
      this.isLoadingTable = true
      this.products = []

      if (filters == null)
        filters = this.filters

      const { data } = await axios.get(`/api/v2/project/${this.projectId}/store-inventories/dashboard`, { params: filters })
      if (data && data.success) {
        this.info = data;
        this.isLoadingTable = false
      }
    },

    showQuantity(quantity) {
      return parseQuantity(quantity);
    },

    showCurrency(mount) {
      return formatCurrency(mount);
    },

    reload(arg = {}) {

      let filters = { ...arg, ...this.filters }
      console.log('filters: ', filters)
      // filters.filter_provider_id
      // filters.filter_activity_id
      // filters.filter_purpose_id
      // filters.filter_date_start
      // filters.filter_date_end

      this.showPanel2 = filters.filter_product_id

      this.getInfoDashboard(filters);
    },
  },

};
</script>
