<template>
  <div>
    <!-- <div class="mr-2 rounded-sm" @click="isModalActive = true" v-if="canAddFromProject">
      <v-button icon="file-import" class="mr-4 is-toggle-rounded"></v-button>
    </div> -->
    <b-tooltip :label="$t('add_document_project')" position="is-left" >
      <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-blue text-grey-lightest" 
        @click="isModalActive = true">
        <b-icon pack="fas" icon="file-import" size="is-small" />
      </div>
    </b-tooltip>
    <b-modal :active.sync="isModalActive" has-modal-card @after-enter="getDocumentsSelectables">
      <div class="w-1/2 modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('project_documents') }}</p>
        </header>
        <section class="modal-card-body">
          <!-- Cuerpo del Modal-->
          <div class="flex flex-col">

            Seleccione el/los documento/s que serán copiados en esta actividad:

            <section class="w-full">
              <b-table
                icon-pack="fas"
                :data="documents"
                :paginated="true"
                :per-page="20"
                :bordered="true"
                :current-page.sync="currentPage"
                :pagination-simple="false"
                :default-sort-direction="'asc'"
                v-if="!isLoading"
                checkable
                checkbox-position="left"
                :checkbox-type="'is-success'"
                :checked-rows.sync="checkedRows">
                <template>

                  <b-table-column field="thumbnail" cell-class="w-6 vertical-center" sortable>
                    <template v-slot:header="">{{ $t('thumbnail') }}</template>
                    <template v-slot="props">
                      <div class="flex flex-wrap justify-center">
                        <img
                          :src="props.row.file.thumbnail"
                          class="p-1 bg-white border rounded"
                          :alt="props.row.name"
                          :onerror="'this.src=\''+asset('public/thumb.jpeg')+'\''"
                        />
                      </div>
                    </template>
                  </b-table-column>

                  <b-table-column field="name" cell-class="vertical-center" sortable>
                    <template v-slot:header="">{{ $t('name') }}</template>
                    <template v-slot="props"><b>{{ props.row.project_document_code }}</b><br>
                      {{ props.row.name }}
                    </template>
                  </b-table-column>

                </template>

                <template slot="bottom-left">
                    <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                    <b>{{ $t("total") }}</b>: {{ this.documents.length }}
                </template>

                <template #empty>
                    <div class="has-text-centered">{{ $t('no_results') }}</div>
                </template>

              </b-table>
            </section>



          </div>
        </section>
        <div class="modal-card-foot">
          <button class="button is-success" :disabled="isLoading || checkedRows.length==0" v-on:click="copyDocument">Añadir documento</button>
        </div>
      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

    </b-modal>
  </div>
</template>

<script>

  import axios from "axios";
  import EventBus from '~/plugins/bus'

  export default {

    props: {
      entityId: { type: Number, required: false },
      entityType: { type: String, required: false }
    },

    data() {
      return {
        currentPage: 1,
        documents: [],
        checkedRows: [],
        isModalActive: false,
        isLoading: false
      }
    },

    methods: {

    async copyDocument() {

      this.isLoading = true;

      // Parametros post
      let params = {
          source_document: null,
      }

      let url = '/api/v2/activity/' + this.entityId + '/clone-document';

      // para cada documento seleccionado, llamamos a clonar. Dentro tiene await, hay que usar for .. of
      for (const document of this.checkedRows) {

        params.source_document = document.document_id
        const {data} = await axios.post( url, params);

        if (data.success) {
          this.$notify.success('Añadido ' + document.name)
        } else {
          this.$notify.error('Error ' + document.name + ' ' + (data.msg || ''))
        }

      }

      // Actualizacion inmediata al usuario local que ejecuta accion
      EventBus.$emit("refreshActivityDocumentsNotifications");

      // reset de lineas seleccionadas
      this.checkedRows = []

      this.isLoading = false;
      this.isModalActive = false;
    },


    // Al abrir modal, carga la lista documentos de proyecto asignados a esta actividad como plantilla base para duplicar un doc
    getDocumentsSelectables() {

      this.checkedRows = []
      if (this.entityId && this.entityType.toLowerCase().indexOf('activity') != -1) {

        this.isLoading = true
        let self = this
        axios.get("/api/v2/activity/" + this.entityId + "/selectable-documents")
          .then(function(response) {
            self.documents = response.data.documents
            self.isLoading = false
          })
          .catch((error) => {
            console.log('Error loading selectable-documents', error)
            self.isLoading = false
          })
      }

    }
  }
}
</script>

<style scoped lang="scss">
  .modal-card {
    width: 100%;
  }

  .modal-card-body {
    min-height: 180px;
    min-width: 360px;
  }

  .modal-card-foot {
    justify-content: flex-end !important;
  }

  .vue-treeselect--open{
    color: #4a4a4a;
  }

</style>
