<template></template>
<script>
import Vue from "vue";
import moment from 'moment';
import 'moment-timezone';
import EventBus from "~/plugins/bus";

Vue.use(moment);

export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    marker: {
      type: Object,
      required: true,
      icon: String,
    },
    props: {
      method: { type: Function },
    },
  },

  mounted() {
    // Si la exactitud del marcador es menor a 50 metros, se pinta.
    if (this.marker.properties.coords.accuracy < 50) {
      /** CIRCLE **/
      // Círculo para representar la exactitud del punto
      let circle = new google.maps.Circle({
        // Color y borde
        strokeWeight: 0,
        fillColor: "#385da5",
        fillOpacity: 0.1,

        // Lo asignamos al mapa
        map: this.map,

        // Etiqueta con el nombre del usuario
        label: {
          text: this.marker.properties.user.fullname,
          color: "black",
          fontSize: "12px",
        },

        // Centramos el círculo con el marcador
        center: {
          lat: this.marker.properties.coords.latitude,
          lng: this.marker.properties.coords.longitude,
        },

        // Radio del circulo dependiendo de la exactitud
        radius: this.marker.properties.coords.accuracy,
      });

      /** INFOWINDOW **/
      // Pintamos la ventana de información
      let contentString =
        '<div id="content">' +
        '<div id="siteNotice">' +
        "</div>" +
        "<table>" +
        "<tbody>" +
        "<tr>" +
        '<td style="background-color: #f0f0f0; padding: 5px;">Fecha</td>' +
        "<td style='padding: 5px;'><b>" +
        moment
          .unix(this.marker.properties.timestamp / 1000)
          .tz("Europe/Madrid")
          .format("DD/MM/YYYY HH:mm:ss") +
        "</b></td>" +
        "</tr>" +
        "<tr>" +
        '<td style="background-color: #f0f0f0; padding: 5px;">Latitud</td>' +
        "<td style='padding: 5px;'><b>" +
        this.marker.properties.coords.latitude +
        "</b></td>" +
        "</tr>" +
        "<tr>" +
        '<td style="background-color: #f0f0f0; padding: 5px;">Longitud</td>' +
        "<td style='padding: 5px;'><b>" +
        this.marker.properties.coords.longitude +
        "</b></td>" +
        "</tr>" +
        "<tr>" +
        '<td style="background-color: #f0f0f0; padding: 5px;">Precisión</td>' +
        "<td style='padding: 5px;'><b>" +
        this.marker.properties.coords.accuracy.toFixed(2) +
        " m</b></td>" +
        "</tr>" +
        "<tr>" +
        '<td style="background-color: #f0f0f0; padding: 5px;">Altitud</td>' +
        "<td style='padding: 5px;'><b>" +
        this.marker.properties.coords.altitude.toFixed(2) +
        " m</b></td>" +
        "</tr>" +
        "<tr>" +
        '<td style="background-color: #f0f0f0; padding: 5px;">Orientaci&oacute;n</td>' +
        "<td style='padding: 5px;'><b>" +
        this.marker.properties.coords.heading.toFixed(0) +
        "º</b></td>" +
        "</tr>" +
        "<tr>" +
        '<td style="background-color: #f0f0f0; padding: 5px;">Velocidad</td>' +
        "<td style='padding: 5px;'><b>" +
        (this.marker.properties.coords.speed * 3.6).toFixed(2) +
        " km/h </b></td>" +
        "</tr>" +
        "</tbody>" +
        "</table>" +
        "</div>" +
        "</div>";

      let payload = new this.google.maps.InfoWindow({
        content: contentString,
      });

      /** MARKER **/
      // Pintamos el marcador
      let marker = new this.google.maps.Marker({
        // Posicion
        position: {
          lat: this.marker.properties.coords.latitude,
          lng: this.marker.properties.coords.longitude,
        },

        // Mapa
        map: this.map,

        // Infowindow
        infowindow: payload,

        // Label que se mostrará junto al avatar
        label: {
          text: this.marker.properties.user.fullname,
          color: "black",
          fontSize: "12px",
        },

        // Avatar del usuario
        icon: {
          size: new this.google.maps.Size(32, 32),
          scaledSize: new this.google.maps.Size(32, 32),
          url: this.marker.properties.user.avatar,
          labelOrigin: new google.maps.Point(16, 42),
        },
      });

      // Click event
      this.google.maps.event.addListener(marker, "click", function() {
        this.infowindow.open(this.map, this);
      });

      EventBus.$on('remove-marker', function(e){
        marker.setMap(null);
        circle.setMap(null);
      });

      let self = this;

      EventBus.$on('setMarker', function(marker){


        /** CIRCLE **/
      // Círculo para representar la exactitud del punto
      let circle = new google.maps.Circle({
        // Color y borde
        strokeWeight: 0,
        fillColor: "#ffff00",
        fillOpacity: 0.1,

        // Lo asignamos al mapa
        map: self.map,

        // Etiqueta con el nombre del usuario
        label: {
          text: marker.user.fullname,
          color: "black",
          fontSize: "12px",
        },

        // Centramos el círculo con el marcador
        center: {
          lat: marker.coords.latitude,
          lng: marker.coords.longitude,
        },

        // Radio del circulo dependiendo de la exactitud
        radius: marker.coords.accuracy,
      });

      /** INFOWINDOW **/
      // Pintamos la ventana de información
      let contentString =
        '<div id="content">' +
        '<div id="siteNotice">' +
        "</div>" +
        "<table>" +
        "<tbody>" +
        "<tr>" +
        '<td style="background-color: #f0f0f0; padding: 5px;">Fecha</td>' +
        "<td style='padding: 5px;'><b>" +
        moment
          .unix(marker.timestamp / 1000)
          .tz("Europe/Madrid")
          .format("DD/MM/YYYY HH:mm:ss") +
        "</b></td>" +
        "</tr>" +
        "<tr>" +
        '<td style="background-color: #f0f0f0; padding: 5px;">Latitud</td>' +
        "<td style='padding: 5px;'><b>" +
        marker.coords.latitude +
        "</b></td>" +
        "</tr>" +
        "<tr>" +
        '<td style="background-color: #f0f0f0; padding: 5px;">Longitud</td>' +
        "<td style='padding: 5px;'><b>" +
        marker.coords.longitude +
        "</b></td>" +
        "</tr>" +
        "<tr>" +
        '<td style="background-color: #f0f0f0; padding: 5px;">Precisión</td>' +
        "<td style='padding: 5px;'><b>" +
        marker.coords.accuracy.toFixed(2) +
        " m</b></td>" +
        "</tr>" +
        "<tr>" +
        '<td style="background-color: #f0f0f0; padding: 5px;">Altitud</td>' +
        "<td style='padding: 5px;'><b>" +
        marker.coords.altitude.toFixed(2) +
        " m</b></td>" +
        "</tr>" +
        "<tr>" +
        '<td style="background-color: #f0f0f0; padding: 5px;">Orientaci&oacute;n</td>' +
        "<td style='padding: 5px;'><b>" +
        marker.coords.heading.toFixed(0) +
        "º</b></td>" +
        "</tr>" +
        "<tr>" +
        '<td style="background-color: #f0f0f0; padding: 5px;">Velocidad</td>' +
        "<td style='padding: 5px;'><b>" +
        (marker.coords.speed * 3.6).toFixed(2) +
        " km/h </b></td>" +
        "</tr>" +
        "</tbody>" +
        "</table>" +
        "</div>" +
        "</div>";

      let payload = new self.google.maps.InfoWindow({
        content: contentString,
      });

      /** MARKER **/
      // Pintamos el marcador
      let exposureMarker = new self.google.maps.Marker({
        // Posicion
        position: {
          lat: marker.coords.latitude,
          lng: marker.coords.longitude,
        },

        // Mapa
        map: self.map,

        // Infowindow
        infowindow: payload,

        // Label que se mostrará junto al avatar
        label: {
          text: marker.user.fullname,
          color: "black",
          fontSize: "12px",
        },

        // Avatar del usuario
        icon: {
          size: new self.google.maps.Size(32, 32),
          scaledSize: new self.google.maps.Size(32, 32),
          url: marker.user.avatar,
          labelOrigin: new google.maps.Point(16, 42),
        },
      });

      // Click event
     self.google.maps.event.addListener(exposureMarker, "click", function() {
        this.infowindow.open(self.map, this);
      });


      });
    }
  },
};
</script>
