<template>
  <b-dropdown
      :mobile-modal="false"
      position="is-top-left"
      aria-role="list"
  >
      <template #trigger>
        <button type="button"
          class="h-9 w-9 rounded-full hover:bg-gray-200 hove:text-white">
          <b-icon icon="ellipsis-v" pack="fas"></b-icon>
        </button>
      </template>

      <!--Editar-->
      <b-dropdown-item v-for="( item, index ) in _options"
        :key="'menu_item_' + index"
        aria-role="listitem"
        :disabled="item.disabled || false"
        @click="item.handleOnClick()">
        <b-icon pack="fas" :icon="item.icon" />
        <span>{{ item.text }}</span>
       <div v-html="item.html"></div>
      </b-dropdown-item>
  </b-dropdown>
</template>

<script>

  export default {
    name: 'button-circle-dropdown',

    computed: {

    },

    components: {},

    props: {
      options: { type: Array, default() { return [] } },
    },

    data: () => ({

    }),

    computed: {
      _options() {
        return this.options.filter( (el) => { return el.visible })
      }
    },

    mounted() {
    },

    methods: {
    },

  };
</script>
