<template>

<b-tabs type="is-toggle-rounded" size="is-small" position="is-centered" v-model="activeTab">
  <b-tab-item>
    <template slot="header">
      <b-icon pack="fas" icon="file"></b-icon>
      <span>General</span>
    </template>

    <div class="flex-col">

      <!-- Panel 1 -->
      <div class="w-full mt-8">
        <div class="flex flex-row w-full text-blue font-bold">Avance General</div>
        <div class="flex flex-row w-full"><column-chart :min="0" :max="100" :suffix="'%'" :data="panel1" /></div>
      </div>

      <!-- Panel 2 -->
      <div class="w-full mt-8" v-if="filters.status_id == 1 && filters.location_id == 0">
        <div class="flex flex-row w-full text-blue font-bold">% de Avance proyecto</div>
        <div class="flex flex-row w-full"><line-chart :suffix="'%'" :data="panel2" /></div>
      </div>

      <!-- Panel 3 -->
      <div class="w-full mt-8" v-if="filters.status_id == 1 && filters.location_id == 0">
        <div class="flex flex-row w-full text-blue font-bold">% de Avance Obra civil</div>
        <div class="flex flex-row w-full"><line-chart :suffix="'%'" :data="panel3" /></div>
      </div>

      <!-- Panel 4 -->
      <div class="w-full mt-8" v-if="filters.status_id != 0">
        <div class="flex flex-row w-full text-blue font-bold">% de Avance Montaje Mecanico</div>
        <div class="flex flex-row w-full"><line-chart :suffix="'%'" :data="panel4"/></div>
      </div>

      <!-- Panel 5 -->
      <div class="w-full mt-8" v-if="filters.status_id != 0">
        <div class="flex flex-row w-full text-blue font-bold">% de Avance Instalación eléctrica</div>
        <div class="flex flex-row w-full"><line-chart  :max="100" :data="panel5"/></div>
      </div>
    </div>
  </b-tab-item>


  <b-tab-item>
    <template slot="header">
      <b-icon pack="fas" icon="calendar"></b-icon>
      <span>Ratio / personal</span>
    </template>

    <dashboard-ignis-staff v-if="activeTab === 1" />

  </b-tab-item>
</b-tabs>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import VueChartkick from 'vue-chartkick'
import Chart from 'chart.js'
import moment from 'moment'
import DashboardIgnisStaff from './dashboard-ignis-staff.vue'

Vue.use(VueChartkick, {adapter: Chart})
Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'project_home_page_title',
  subtitle: 'project_home_page_subtitle',
  menuOption: '2-1',

  components: {
    ScaleLoader,
    DashboardIgnisStaff,
  },

  data: () => ({
    activeTab: 0,
    filters: {
      location_id: 0,
      status_id: 1,
      tag_name: '',
    },
    panel1: null,
    panel2: null,
    panel3: null,
    panel4: null,
    panel5: null,
  }),

  computed: {
    ...mapGetters({
      project: 'app/project',
      user: 'auth/user',
    }),
  },

  mounted() {

    if (this.project == null) {

      this.$router.push('/projects');

    } else {

      this.getPanelsInfo();

      let pageInfo = {
        title: this.project.name,
        subtitle: this.$t('project_home_page_subtitle'),
        menuOption: '2-1',
      }

      this.$store.dispatch('app/updatePageInfo', {info: pageInfo})
    }
  },

  methods: {

    async getPanelsInfo() {
      this.getIgnisPanel1();
      this.getIgnisPanel3();
      this.getIgnisPanel4();
      this.getIgnisPanel5();
      this.getIgnisPanel2();
    },

    async getIgnisPanel1() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/ignispanel1';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel1 = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel1")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async getIgnisPanel2() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/ignispanel2';
      this.filters.tag_name = 'OBRA CIVIL, Montaje mecánico, INSTALACIÓN ELÉCTRICA';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel2 = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel2")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async getIgnisPanel3() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/ignispanel3';
      this.filters.tag_name = 'OBRA CIVIL';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel3 = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel3")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async getIgnisPanel4() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/ignispanel3';
      this.filters.tag_name = 'Montaje mecánico';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel4 = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel4")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async getIgnisPanel5() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/ignispanel3';
      this.filters.tag_name = 'INSTALACIÓN ELÉCTRICA';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel5 = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel5")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    }
  },

};
</script>

<style scoped>
.column-scroll {
  width: 50%;
  height: calc(100vh - 150px);
  overflow-x: hidden;
  overflow-y: auto;
}
.column-scroll:not(:last-child) {
  margin-right: 0.5rem;
}
.column-scroll:last-child {
  margin-left: 0.5rem;
}
</style>
