import axios from "axios"
import Konva from 'konva'; // es necesario si se quiere crear usando la api nativa de konva

/** Level in "message" events. */
const MessageLevel = Object.freeze({
    INFO: "info",
    WARN: "warn",
    ERROR: "error"
})

export class KonvaViewer {

    /**
     * @param options Some options can be overridden if specified. See DxfViewer.DefaultOptions.
     */
    constructor(domContainer, stage, options = null) {
      this.options = Object.create(KonvaViewer.DefaultOptions)
      if (options) {
          Object.assign(this.options, options)
      }
      options = this.options

      this.domContainer = domContainer
      this.stage = stage

      this.layerEntities = this.stage.getLayers().find( (ly) => { return ly.attrs.id == 'layerEntities' } ).getLayer()
      this.canvaEntities = this.layerEntities ? this.layerEntities.getNativeCanvasElement() : null

      // if (options.autoResize) {
        this.canvasWidth = domContainer.clientWidth
        this.canvasHeight = domContainer.clientHeight
        domContainer.style.position = "relative"
      // } else {
      //     this.canvasWidth = options.canvasWidth
      //     this.canvasHeight = options.canvasHeight
      //     this.resizeObserver = null
      // }

      this._SetSize(this.canvasWidth, this.canvasHeight)

      // domContainer.style.display = "block"
      // if (options.autoResize) {
      //     this.canvas.style.position = "absolute"
      //     this.resizeObserver = new ResizeObserver(entries => this._OnResize(entries[0]))
      //     this.resizeObserver.observe(domContainer)
      // }

      this.entities = []
      this.labels = []
      this.bounds = null
      this.origin = null
      this.layers = []
      this.zoomLevel = 1
    }

    GetLabels() {
        return this.labels
    }

    GetZoomLavel() {
      return this.zoomLevel
    }

    _SetSize(width, height) {
      this.stage.width(width)
      this.stage.height(height)
      this.stage.draw()
    }

    SetSize(width, height) {
      this._EnsureRenderer()

      const hScale = width / this.canvasWidth
      const vScale = height / this.canvasHeight

      const cam = this.camera
      const centerX = (cam.left + cam.right) / 2
      const centerY = (cam.bottom + cam.top) / 2
      const camWidth = cam.right - cam.left
      const camHeight = cam.top - cam.bottom
      cam.left = centerX - hScale * camWidth / 2
      cam.right = centerX + hScale * camWidth / 2
      cam.bottom = centerY - vScale * camHeight / 2
      cam.top = centerY + vScale * camHeight / 2
      cam.updateProjectionMatrix()

      this.canvasWidth = width
      this.canvasHeight = height
      this.renderer.setSize(width, height)

      this._Emit("resized", {width, height})
      this._Emit("viewChanged")
      this.Render()
    }

    /** Load DXF into the viewer. Old content is discarded, state is reset.
     * @param url {string} DXF file URL.
     * @param fonts {?string[]} List of font URLs. Files should have typeface.js format. Fonts are
     *  used in the specified order, each one is checked until necessary glyph is found. Text is not
     *  rendered if fonts are not specified.
     * @param progressCbk {?Function} (phase, processedSize, totalSize)
     *  Possible phase values:
     *  * "font"
     *  * "fetch"
     *  * "parse"
     *  * "prepare"
     * @param workerFactory {?Function} Factory for worker creation. The worker script should
     *  invoke DxfViewer.SetupWorker() function.
     */
    async Load({url, progressCbk = null, workerFactory = null}) {

      const {data} = await axios.get(url).catch(error => {
        console.error('Error al obtener el archivo remoto:', error);
      });;

      // TODO: optimizar esta logica
      if( data && data.success ) {
        await fetch(data.data + '?' + Date.now())  // anadir timestamp para evitar cache
        .then(response => {
          if (!response.ok) {
            throw new Error('Error de red: ' + response.status);
          }
          // return response;
          return response.json();
        })
        .then(design => {
          // Manipular los datos JSON aquí
          // let design = JSON.parse(response.data.data)
          this.entities = design.entities
          this.labels = design.texts
          this.bounds = design.bounds
          this.origin = design.origin
          this.layers = design.layers
        })
        .catch(error => {
          console.error('Ocurrió un error al obtener el archivo JSON:', error);
        });

        console.log('%cEPC-TACKER: '+ '%c KonvaViewer.js datos cargados correctamente', 'background: #5577BB; color: #fff', 'color: #000')
      } else {
        console.error('%cEPC-TACKER: '+ '%c KonvaViewer.js datos no cargados', 'background: #5577BB; color: #fff', 'color: #000')
      }
    }

    SetDataPictogram(design) {
      this.entities = design.entities
      this.labels = design.texts
      this.bounds = design.bounds
      this.origin = design.origin
      this.layers = design.layers
    }

    SetDataEvacuation(design) {

      this.entities = design.entities
      // this.labels = design.texts
      // this.bounds = design.bounds
      // this.origin = design.origin
      // this.layers = design.layers
    }

    async LoadOld({url, progressCbk = null, workerFactory = null}) {

      await axios.get(url)
        .then(response => {

          if( response.data.success ) {

            // let design = JSON.parse(response.data.data)
            // this.entities = design.entities
            // this.labels = design.texts
            // this.bounds = design.bounds
            // this.origin = design.origin
            // this.layers = design.layers
            // this.$emit('epc-loaded')
            console.log('%cEPC-TACKER: '+ '%c KonvaViewer.js datos cargados correctamente', 'background: #5577BB; color: #fff', 'color: #000')
          }
        })
        .catch(error => {
          console.error('Error al obtener el archivo remoto:', error);
        });


      // for (const layer of scene.layers) {
      //     this.layers.set(layer.name, new Layer(layer.name, layer.displayName, layer.color))
      // }

    }

    /** @return {Iterable<{name:String, color:number}>} List of layer names. */
    GetLayers() {
        const result = []
        for (const lyr of this.layers.values()) {
            result.push({
                name: lyr.name,
                displayName: lyr.displayName,
                color: this._TransformColor(lyr.color)
            })
        }
        return result
    }

    ShowLayer(name, show) {
        this._EnsureRenderer()
        const layer = this.layers.get(name)
        if (!layer) {
            return
        }
        for (const obj of layer.objects) {
            obj.visible = show
        }
        this.Render()
    }

    /** Reset the viewer state. */
    Clear() {
        // this._EnsureRenderer()
        // if (this.worker) {
        //     this.worker.Destroy(true)
        //     this.worker = null
        // }
        // if (this.controls) {
        //     this.controls.dispose()
        //     this.controls = null
        // }
        // this.scene.clear()
        // for (const layer of this.layers.values()) {
        //     layer.Dispose()
        // }
        // this.layers.clear()
        // this.blocks.clear()
        // this.materials.each(e => e.material.dispose())
        // this.materials.clear()
        // this.SetView({x: 0, y: 0}, 2)
        // this._Emit("cleared")
        // this.Render()
    }

    /** Free all resources. The viewer object should not be used after this method was called. */
    Destroy() {
        // if (!this.HasRenderer()) {
        //     return
        // }
        // if (this.resizeObserver) {
        //     this.resizeObserver.disconnect()
        // }
        this.Clear()
        // this._Emit("destroyed")
        // for (const m of this.simplePointMaterial) {
        //     m.dispose()
        // }
        // for (const m of this.simpleColorMaterial) {
        //     m.dispose()
        // }
        // this.simplePointMaterial = null
        // this.simpleColorMaterial = null
        // this.renderer.dispose()
        // this.renderer = null
        this.stage.destroy()
        this.stage = null
    }

    SetView(center, width) {
        const aspect = this.canvasWidth / this.canvasHeight
        const height = width / aspect
        const cam = this.camera
        cam.left = -width / 2
        cam.right = width / 2
        cam.top = height / 2
        cam.bottom = -height / 2
        cam.zoom = 1
        cam.position.set(center.x, center.y, 1)
        cam.rotation.set(0, 0, 0)
        cam.updateMatrix()
        cam.updateProjectionMatrix()
        this._Emit("viewChanged")
    }

    EnableDraggable(enable) {
      this.stage.draggable(enable)
    }

    /** Set view to fit the specified bounds. */
    FitView(minX, maxX, minY, maxY, padding = 0.1) {
        const aspect = this.canvasWidth / this.canvasHeight
        let width = maxX - minX
        const height = maxY - minY
        const center = {x: minX + width / 2, y: minY + height / 2}

        if (height * aspect > width) {
            width = height * aspect
        }

        if (width <= Number.MIN_VALUE * 2) {
            width = 1
        }

        this.SetView(center, width * (1 + padding))
    }

    FitViewKonvaNew(padding = 0.1) {
      const container = this.domContainer;

      // Calcula el tamaño del contenedor
      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;

      const stageWidth = this.stage.width()
      const stageHeight = this.stage.height()

      // Calcula la escala para ajustar el Stage al contenedor
      const scaleX = containerWidth / stageWidth;
      const scaleY = containerHeight / stageHeight;
      const scale = Math.min(scaleX, scaleY);

      // Escala el Stage y actualiza su tamaño
      this.stage.width(stageWidth * scale);
      this.stage.height(stageHeight * scale);

      // Centra el Stage en el contenedor
      this.stage.scale({ x: scale, y: scale });
      this.stage.offset({
        x: (stageWidth - stageWidth * scale) / 2,
        y: (stageHeight - stageHeight * scale) / 2,
      });

      // Dibujar los elementos del Stage actualizados
      this.stage.draw();
    }

    FitViewKonva(padding = 0.1) {

      // now we need to fit stage into parent container
      var containerWidth = this.domContainer.offsetWidth;
      var containerHeight = this.domContainer.offsetHeight;

      // but we also make the full scene visible
      // so we need to scale all objects on canvas
      var scaleX = containerWidth / this.stage.width();
      var scaleY = containerHeight / this.stage.height();
      // let scaleX = this.canvaEntities.width / this.stage.width();
      // let scaleY = this.canvaEntities.height / this.stage.height();
      const scale = Math.min(scaleX, scaleY);

      this.stage.width(this.stage.width() * scale);
      this.stage.height(this.stage.height() * scale);

      // this.stage.width(this.stage.width() * scaleX);
      // this.stage.height(this.stage.height() * scaleY);


      this.stage.scale({ x: scaleX, y: scaleY });

      let center = this._FitViewKonva(this.stage.width(), this.stage.height())
      let offset = this._GetOffset()
      // this.stage.position({ x: Math.abs(center.x + offset.x), y: Math.abs(center.y - offset.y) })
      this.stage.position({ x: Math.abs(center.x + offset.x), y: Math.abs(center.y) })
      // this.stage.position({ x: center.x, y: center.y })
      this.stage.draw()


      // this.ZoomStage(0.8) // ultimo recurso harcodeado
    }

    _FitViewKonva(canvasWidth, canvasHeight, padding = 0.1) {
      let minX = this.bounds.minX - this.origin.x
      let maxX = this.bounds.maxX - this.origin.x
      let minY = this.bounds.minY - this.origin.y
      let maxY = this.bounds.maxY - this.origin.y

      const aspect = canvasWidth / canvasHeight
      let width = maxX - minX
      const height = maxY - minY
      const center = {x: minX + width / 2, y: minY + height / 2}

      if (height * aspect > width) {
          width = height * aspect
      }

      if (width <= Number.MIN_VALUE * 2) {
          width = 1
      }

      return center
      // this.SetView(center, width * (1 + padding))
    }

    GetScale() {
      let canvasWidth = this.stage.width()
      let canvasHeight = this.stage.height()

      return {
        x: Math.round(canvasWidth / ((this.bounds.maxX  - this.origin.x) - (this.bounds.minX  - this.origin.x))),
        y: Math.round(canvasHeight / ((this.bounds.maxY  - this.origin.y) - (this.bounds.minY  - this.origin.y)))
      }
    }

    SetScaleStage() {
      let scale = this.GetScale()
      this.stage.scaleX (scale.x)
      this.stage.scaleY (scale.y)
    }

    /** @return {Vector2} Scene origin in global drawing coordinates. */
    GetOrigin() {
        return this.origin
    }

    // Este metodo debe recibir los layer que son controlados por nosotros para determinar el color del estado
    GetEntities(layers, typeRegister) {
      console.log( 'type register: ', typeRegister )
      let labels = []
      let entities = [];
      let backgrounds = []
      let completed = []

      let offset = this._GetOffset();

      Object.keys(this.entities).forEach((key, index) => {
        const element = this.entities[key]
        if(element) {
          if (element.type === 'structures') {

            let structureVertical = element.width < element.height

            labels.push({
              id: 'text_' + element.id,
              text: this._GetNameEntity(element, index),
              x: Math.abs( element.x + offset.x ),
              y: Math.abs( element.y - offset.y ) + (structureVertical ? element.height : 0),
              width: structureVertical ? element.height : element.width,
              height: structureVertical ? element.width : element.height,
              fontSize: 2,
              // fill: element.status == null ? '#ffffff' : '#000000',
              align: 'center',
              verticalAlign: 'middle',
              rotation: structureVertical ? -90 : 0
            })

            backgrounds.push({
              id: 'bk_' + element.id,
              x: Math.abs( element.x + offset.x ),
              y: Math.abs( element.y - offset.y ),
              width: element.width,
              height: element.height,
              fill: index % 2 === 0 ? '#f7f3f3' : null,
            })

            completed.push({
              id: 'sc_' + element.id,
              x: Math.abs( element.x + offset.x ),
              y: Math.abs( element.y - offset.y ),
              width: element.width,
              height: element.height,
              opacity: 0.7,
              // fill: this._GetColoStatusEntityOnlyComplete(element, layers),
              stroke: this._GetColoStatusEntityOnlyComplete(element, layers), // '#f7f3f3',
              strokeWidth: 0.8,
              // shadowColor: this._GetColorLayer(element.layer),
              // shadowBlur: 1,
              // shadowOffsetX: 1,
              // shadowOffsetY: 1
            })
          }

          entities.push({
            id: element.id.toString(),
            name: this._GetNameEntity(element, index),
            x: parseFloat(Math.abs( element.x + offset.x )),
            y: parseFloat(Math.abs( element.y - offset.y )),
            width: element.width,
            height: element.height,
            fill: (typeRegister === 'issue') ? '#666666' : this._GetColorStatusOfLayout(element, layers), // relleno  aletorio Konva.Util.getRandomColor()
            stroke: (typeRegister === 'issue') ? '#666666' : this._GetColorLayer(element.layer),
            strokeWidth: 0.1,
            // shadowBlur: 10,
            opacity: element.status == null ? 1 : 0.7, // 0..1
            visible: true,

            type: element.type,
            layerName: element.layer,
            // status: element.status,
            // TODO: Esta parte lo colocamos de esta manera por que solo guardaremos el id del estado, luego corregir y eliminar comentario
            previou_status: element.so, // estado anterior
            status: element.status ? (typeof element.status === 'object' ? element.status.id : element.status ) :  null,
            // listening: () => {  },
            listening: false,
          })
        }
      });

      return { labels, entities, backgrounds, completed }
    }

    GetLineaEvacuation(typeLine) {
      let labels = []
      let sections = [];
      let nodes = [];
      let backgrounds = []
      let completed = []

      // let offset = this._GetOffset();
      Object.keys(this.entities).forEach((key, index) => {
        const element = this.entities[key]
        if(element) {
          if (element.type === 'sections') {

            sections.push({
              id: element.id.toString(),
              name: element.name,
              x: parseFloat(Math.abs( element.x )),
              y: parseFloat(Math.abs( element.y )),
              width: element.width,
              height: element.height,
              fill: '#ffffff', // relleno  aletorio Konva.Util.getRandomColor()
              stroke: typeLine === 'buried_evacuation_line' ? null : '#000000',
              strokeWidth: typeLine === 'buried_evacuation_line' ? null : 2,
              // shadowBlur: 10,
              opacity: element.status == null ? 1 : 0.7, // 0..1
              visible: true,

              type: element.type,
              layerName: element.layer,
              // status: element.status,
              // TODO: Esta parte lo colocamos de esta manera por que solo guardaremos el id del estado, luego corregir y eliminar comentario
              previou_status: element.so, // estado anterior
              status: element.status ? (typeof element.status === 'object' ? element.status.id : element.status ) :  null,
              // listening: () => {  },  2111, 4572 , l 32047
            })

          } else if (element.type === 'border_line') {
            backgrounds.push({
              id: element.id.toString(),
              name: element.name,
              points: element.points,
              stroke: '#000000',
              strokeWidth: 2,
              // opacity: element.status == null ? 1 : 0.7, // 0..1
              visible: true,

              type: element.type,
              layerName: element.layer,
              previou_status: element.so, // estado anterior
              status: null,
            })
          } else {

            nodes.push({
              id: element.id.toString(),
              name: element.name,
              x: parseFloat(Math.abs( element.x )),
              y: parseFloat(Math.abs( element.y )),
              radius: element.radius,
              fill: '#ffffff', // relleno  aletorio Konva.Util.getRandomColor()
              stroke: '#000000',
              strokeWidth: 2,
              // shadowBlur: 10,
              opacity: element.status == null ? 1 : 0.7, // 0..1
              visible: true,

              type: element.type,
              layerName: element.layer,
              // status: element.status,
              // TODO: Esta parte lo colocamos de esta manera por que solo guardaremos el id del estado, luego corregir y eliminar comentario
              previou_status: element.so, // estado anterior
              status: element.status ? (typeof element.status === 'object' ? element.status.id : element.status ) :  null,
              // listening: () => {  },  2111, 4572 , l 32047
            })

            labels.push({
              id: 'text_' + element.id,
              text: element.name,
              x: Math.abs( element.x - element.radius),
              y: Math.abs( element.y + element.radius + 10),
              // width: structureVertical ? element.height : element.width,
              // height: structureVertical ? element.width : element.height,
              fontSize: 16,
              // fill: element.status == null ? '#ffffff' : '#000000',
              stroke: '#000000',
              strokeWidth: 1,
              align: 'center',
              verticalAlign: 'middle',
              rotation: 0
            })
          }


        }
      });

      return { labels, sections, nodes, backgrounds, completed }
    }

    _GetNameEntity(entity, index) {
      if ( entity.name != null )
        return entity.name

      return 'E.' + entity.type.charAt(0).toUpperCase() + '.' + (index + 1)

    }

    _GetColoStatusEntityOnlyComplete(entity, layers) {
      if( entity.status != null ) {
        let lyr = layers.find( item => { return item.code === entity.type })
        if (lyr) {
          let idStatus = typeof entity.status === 'object' ? entity.status.id : entity.status;

          let status = lyr.statuses.find(item => { return item.id == idStatus && item.is_completion == 1})
          if (status)
            return status.color
        }
      }
     return null
   }

    _GetColorStatusOfLayout(entity, layers) {
       if( entity.status != null ) {
         let lyr = layers.find( item => { return item.code === entity.type })
         if (lyr) {
           let idStatus = typeof entity.status === 'object' ? entity.status.id : entity.status;

           let status = lyr.statuses.find(item => { return item.id == idStatus })
           if (status)
             return status.color
         }
       }
      return null

      // if ( typeof entity.status === 'object' )
      //   return entity.status.color
      // else {
      //   let lyr = layers.find( item => { return item.code === entity.type })
      //   if (lyr) {
      //     let status = lyr.statuses.find(item => { return item.id == entity.status })
      //     if (status) return status.color
      //   }
      //   return null
      // }
    }

    // para zoom stage
    ZoomStage(scaleBy) {
      const oldScale = this.stage.scaleX();

      const pos = {
        x: this.stage.width() / 2,
        y: this.stage.height() / 2,
      };

      const mousePointTo = {
        x: pos.x / oldScale - this.stage.x() / oldScale,
        y: pos.y / oldScale - this.stage.y() / oldScale,
      };

      const newScale = Math.max(0.05, oldScale * scaleBy);

      this.zoomLevel = newScale

      const newPos = {
        x: -(mousePointTo.x - pos.x / newScale) * newScale,
        y: -(mousePointTo.y - pos.y / newScale) * newScale,
      };

      this.stage.scale({ x: newScale, y: newScale });
      this.stage.position(newPos);
    }

    // zoom usado por evento Wheel
    ZoomStagePoiter(e, scaleBy) {
      var oldScale = this.stage.scaleX();
      var pointer = this.stage.getPointerPosition();

      var mousePointTo = {
        x: (pointer.x - this.stage.x()) / oldScale,
        y: (pointer.y - this.stage.y()) / oldScale,
      };

      // how to scale? Zoom in? Or zoom out?
      let direction = e.evt.deltaY > 0 ? 1 : -1;

      // when we zoom on trackpad, e.evt.ctrlKey is true
      // in that case lets revert direction
      if (e.evt.ctrlKey) {
        direction = -direction;
      }

      var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      this.stage.scale({ x: newScale, y: newScale });

      var newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      this.stage.position(newPos);
    }

    Screenshot() {
      const bbox = this.CalculateBoundingBox();

      return this.stage.toDataURL({
        // mimeType: 'image/jpeg',
        quality: 1,
        pixelRatio: 15, // 25
        // width: 1280,
        // height: 1280
        x: bbox.x,
        y: bbox.y,
        width: bbox.width,
        height: bbox.height,
      })
    }

    CalculateBoundingBox() {
      // const layer = this.stage.findOne('#layerEntities').getLayer();
      let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
      this.layerEntities.getChildren().forEach( node => {
          const box = node.getClientRect();
          minX = Math.min(minX, box.x);
          minY = Math.min(minY, box.y);
          maxX = Math.max(maxX, box.x + box.width);
          maxY = Math.max(maxY, box.y + box.height);
      });

      return { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
    }

    CropImageOptimize(screenshot) {
      return new Promise((resolve, reject) => {
        const image = new Image();

        image.onload = () => {
          const { width, height } = image;
          const blockSize = 500; // Tamaño del bloque para el procesamiento por partes

          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Redimensionar la imagen si es necesario
          const scaleFactor = Math.min(1, 1000 / Math.max(width, height));
          canvas.width = width * scaleFactor;
          canvas.height = height * scaleFactor;
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          // Procesar por partes
          const cropArea = { left: Infinity, top: Infinity, right: -Infinity, bottom: -Infinity };

          function processBlock(x, y) {
            const blockWidth = Math.min(blockSize, canvas.width - x);
            const blockHeight = Math.min(blockSize, canvas.height - y);
            const blockData = ctx.getImageData(x, y, blockWidth, blockHeight).data;

            for (let offsetY = 0; offsetY < blockHeight; offsetY++) {
              for (let offsetX = 0; offsetX < blockWidth; offsetX++) {
                const alpha = blockData[(offsetY * blockWidth + offsetX) * 4 + 3];
                if (alpha > 0) {
                  cropArea.left = Math.min(cropArea.left, x + offsetX);
                  cropArea.top = Math.min(cropArea.top, y + offsetY);
                  cropArea.right = Math.max(cropArea.right, x + offsetX);
                  cropArea.bottom = Math.max(cropArea.bottom, y + offsetY);
                }
              }
            }
          }

          for (let y = 0; y < canvas.height; y += blockSize) {
            for (let x = 0; x < canvas.width; x += blockSize) {
              processBlock(x, y);
            }
          }

          // Calcular las dimensiones del área útil
          const widthU = cropArea.right - cropArea.left + 1;
          const heightU = cropArea.bottom - cropArea.top + 1;

          // Crear un nuevo lienzo para recortar la imagen
          const recortadoCanvas = document.createElement('canvas');
          recortadoCanvas.width = widthU;
          recortadoCanvas.height = heightU;
          const recortadoCtx = recortadoCanvas.getContext('2d');

          // Recortar la imagen y dibujarla en el nuevo lienzo
          recortadoCtx.drawImage(canvas, cropArea.left, cropArea.top, widthU, heightU, 0, 0, widthU, heightU);

          // Devolver la imagen recortada
          resolve(recortadoCanvas.toDataURL());
        };

        image.onerror = (e) => {
          console.error('Error al cargar la imagen para recorte:', e);
          reject();
        };

        image.src = screenshot;
      });
    }

    CropImage(screenshot) {
      const image = document.createElement("img")

      return new Promise((resolve, reject) => {

        image.src = screenshot

        image.addEventListener("load", ()=>{
          // Crear un lienzo temporal
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');

          // Establecer el tamaño del lienzo igual al tamaño de la imagen
          canvas.width = image.width;
          canvas.height = image.height;

          // Dibujar la imagen en el lienzo
          ctx.drawImage(image, 0, 0);

          // Obtener los datos de píxeles de la imagen
          var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          var data = imageData.data;

          // Encontrar los límites de la imagen
          var top = canvas.height;
          var bottom = 0;
          var left = canvas.width;
          var right = 0;

          for (var y = 0; y < canvas.height; y++) {
            for (var x = 0; x < canvas.width; x++) {
              var alpha = data[(y * canvas.width + x) * 4 + 3];
              if (alpha > 0) {
                top = Math.min(top, y);
                bottom = Math.max(bottom, y);
                left = Math.min(left, x);
                right = Math.max(right, x);
              }
            }
          }

          // Calcular las dimensiones del área útil
          var width = right - left + 1;
          var height = bottom - top + 1;

          // Crear un nuevo lienzo para recortar la imagen
          var recortadoCanvas = document.createElement('canvas');
          var recortadoCtx = recortadoCanvas.getContext('2d');

          // Establecer el tamaño del nuevo lienzo igual al área útil
          recortadoCanvas.width = width;
          recortadoCanvas.height = height;

          // Recortar la imagen y dibujarla en el nuevo lienzo
          recortadoCtx.drawImage(canvas, left, top, width, height, 0, 0, width, height);

          // Devolver la imagen recortada
          resolve(recortadoCanvas.toDataURL());

        })


        image.addEventListener("error", (e)=>{
          console.error('%cEPC-TACKER: '+ '%c KonvaViewer.js error al cargar imagen para recorte', 'background: #5577BB; color: #fff', 'color: #000')
          reject()
        })
      })
    }

    convertirPNGaWebP(pngURL, calidad) {
      // Crear un nuevo elemento de imagen
      var img = new Image();
      img.onload = function() {
          // Crear un elemento canvas
          var canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;

          // Dibujar la imagen en el canvas
          var ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          // Obtener la representación de la imagen como URL de datos de tipo imagen/webp
          var webpURL = canvas.toDataURL('image/webp', calidad);

          // Imprimir la URL de la imagen WebP
          console.log('URL de imagen WebP:', webpURL);
      };
      // Establecer la URL de la imagen PNG
      img.src = pngURL;
  }

    GetBase64Image(screenshot, withCompress=false) {

      const img = document.createElement("img");
      return new Promise((resolve, reject)=>{
        img.src = screenshot,
        img.addEventListener("load", ()=>{
            let widthImg = img.width
            let heightImg = img.height

            if (withCompress) {
              // TODO: esta compresion la realizamos para ser usada en dompdf
              let limitWidth = 2480
              let limitHeight = 3508

              if (widthImg > limitWidth || heightImg > limitHeight) {
                let originalProportion =  widthImg / heightImg

                // Redimensionar según el ancho máximo y ajustar el alto
                if (widthImg > limitWidth) {
                  widthImg = limitWidth
                  heightImg = widthImg / originalProportion
                }

                // Si el alto supera el límite, redimensionar según el alto máximo y ajustar el ancho
                if (heightImg > limitHeight) {
                  heightImg = limitHeight;
                  widthImg = heightImg * originalProportion;
                }

                //TODO: este codico comentado usar si se para limites lo comentamos por que a qui ya tenemos limites fijos
                // if (nuevoAncho / nuevoAlto > proporcionOriginal) {
                //   nuevoAncho = nuevoAlto * proporcionOriginal;
                // } else {
                //   nuevoAlto = nuevoAncho / proporcionOriginal;
                // }
              }

            }

            const canva = document.createElement("canvas")
            canva.width = widthImg,
            canva.height = heightImg;
            canva.getContext("2d").drawImage(img, 0, 0, widthImg, heightImg);

            if(withCompress)
              resolve(canva.toDataURL("image/png"), 0.9) // 0.9 nivel de compresion va de 0..1
            else
              resolve(canva.toDataURL("image/png"))
        })

        img.addEventListener("error", ()=>{
            reject()
        })
      })

    }

    FitToScreen() {
      return new Promise((resolve, reject) => {
        // get layers
        const nodesLayer = this.stage.findOne('#layerEntities')
        const layerSize = nodesLayer.getClientRect({
          relativeTo: this.stage,
        })

        // calculate sizes
        const stageSize = this.stage.getSize()
        const scaleX = stageSize.width / layerSize.width
        const scaleY = stageSize.height / layerSize.height
        const scaleValue = Math.min(scaleX, scaleY) * 0.9

        // move canvas
        // this.MoveStage(this.stage, { x: 0, y: 20 }, { x: scaleValue, y: scaleValue })
        this.MoveStage(this.stage, { x: 10, y: 40 }, { x: scaleValue, y: scaleValue })
          // .then(r => resolve() )
        resolve()
      })
    }

    MoveStage(stage,  location,  scale) {
      const { x, y } = location
      // const tween = new Konva.Tween({
      //   duration: 0.35,
      //   easing: Konva.Easings.EaseInOut,
      //   node: stage,
      //   onFinish: () => {
      //     fnCallback()
      //     tween.destroy
      //   },
      //   scaleX: (scale && scale.x) || 1,
      //   scaleY: (scale && scale.y) || 1,
      //   x,
      //   y,
      // })

      // tween.play()
      stage.x(x)
      stage.y(y)
      stage.scaleX((scale && scale.x) || 1)
      stage.scaleY((scale && scale.y) || 1)
      stage.batchDraw()
    }

    // Metodo que permite obtener la posicion que debe mostrarse un elemento respetando los limites del
    // contenedor
    GetPosition(discanceXMin, distanceYMin) {
      const mousePos = this.stage.getPointerPosition();

      let x = mousePos.x //- 190;
      let y = mousePos.y //- 40;

      var contenedorWidth = this.stage.width();
      var contenedorHeight = this.stage.height();

      if ( mousePos.x <= discanceXMin ) {
        // console.log('El punto está cerca del límite izquierdo del contenedor.');
      } else {
        // console.log('El punto no está cerca del límite izquierdo del contenedor.');
      }

      if (mousePos.x >= contenedorWidth - discanceXMin) {
        x = mousePos.x - discanceXMin
        // console.log('El punto está cerca del límite derecho del contenedor.');
      } else {
        // console.log('El punto no está cerca del límite derecho del contenedor.');
      }

      if (mousePos.y <= distanceYMin) {
        // console.log('El punto está cerca del límite superior del contenedor.');
      } else {
        // console.log('El punto no está cerca del límite superior del contenedor.');
      }

      if (mousePos.y >= contenedorHeight - distanceYMin) {
        y = mousePos.y - distanceYMin
        // console.log('El punto está cerca del límite inferior del contenedor.');
      } else {
        // console.log('El punto no está cerca del límite inferior del contenedor.');
      }

      return { x, y }
    }

    Resize() {
      this.stage.width(this.domContainer.offsetWidth)
      this.stage.height(this.domContainer.offsetHeight)

      this.stage.scale({
        x: this.domContainer.offsetWidth / this.stage.width(),
        y: this.domContainer.offsetWidth / this.stage.height()
      });

      // Vuelve a dibujar la capa de Konva
      // this.$refs.refLayerText.getNode().draw();
      // this.$refs.layer.getNode().draw();
      this.stage.draw()
      // this.FitViewKonva()
      this.FitToScreen()
    }

    _GetOffset() {
      return {
        x: Math.abs( this.bounds.minX  - this.origin.x ), // desplazameinto en x
        y: Math.abs( this.bounds.maxY  - this.origin.y ) // desplazamaiento en y
      }
    }

    _OnPointerEvent(e) {
        const canvasRect = e.target.getBoundingClientRect()
        const canvasCoord = {x: e.clientX - canvasRect.left, y: e.clientY - canvasRect.top}
        this._Emit(e.type, {
            domEvent: e,
            canvasCoord,
            position: this._CanvasToSceneCoord(canvasCoord.x, canvasCoord.y)
        })
    }

    _OnResize(entry) {
        this.SetSize(Math.floor(entry.contentRect.width), Math.floor(entry.contentRect.height))
    }

    _LoadBatch(scene, batch) {

        const objects = new Batch(this, scene, batch).CreateObjects()

        const layer = this.layers.get(batch.key.layerName)

        for (const obj of objects) {
            this.scene.add(obj)
            if (layer) {
                layer.PushObject(obj)
            }
        }
    }

    _GetColorLayer(nameLayer) {
      let layer = this.layers.find((l) => l.name === nameLayer)
      return this._GetCssColor(layer.color)
    }

    _GetCssColor(value) {
        let s = value.toString(16)
        while (s.length < 6) {
            s = "0" + s
        }
        return "#" + s
    }

    /** Ensure the color is contrast enough with current background color.
     * @param color {number} RGB value.
     * @return {number} RGB value to use for rendering.
     */
    _TransformColor(color) {
        if (!this.options.colorCorrection && !this.options.blackWhiteInversion) {
            return color
        }
        /* Just black and white inversion. */
        const bkgLum = Luminance(this.clearColor)
        if (color === 0xffffff && bkgLum >= 0.8) {
            return 0
        }
        if (color === 0 && bkgLum <= 0.2) {
            return 0xffffff
        }
        if (!this.options.colorCorrection) {
            return color
        }
        const fgLum = Luminance(color)
        const MIN_TARGET_RATIO = 1.5
        const contrast = ContrastRatio(color, this.clearColor)
        const diff = contrast >= 1 ? contrast : 1 / contrast
        if (diff < MIN_TARGET_RATIO) {
            let targetLum
            if (bkgLum > 0.5) {
                targetLum = bkgLum / 2
            } else {
                targetLum = bkgLum * 2
            }
            if (targetLum > fgLum) {
                color = Lighten(color, targetLum / fgLum)
            } else {
                color = Darken(color, fgLum / targetLum)
            }
        }
        return color
    }

    _Emit(eventName, data = null) {
      this.canvas.dispatchEvent(new CustomEvent(EVENT_NAME_PREFIX + eventName, { detail: data }))
    }
}

KonvaViewer.MessageLevel = MessageLevel

KonvaViewer.DefaultOptions = {
    canvasWidth: 400,
    canvasHeight: 300,
    /** Automatically resize canvas when the container is resized. This options utilizes
     *  ResizeObserver API which is still not fully standardized. The specified canvas size is
     *  ignored if the option is enabled.
     */
    autoResize: false,
    /** Frame buffer clear color alpha value. */
    clearAlpha: 1.0,
    /** Use alpha channel in a framebuffer. */
    canvasAlpha: false,
    /** Assume premultiplied alpha in a framebuffer. */
    canvasPremultipliedAlpha: true,
    /** Use antialiasing. May degrade performance on poor hardware. */
    antialias: true,
    /** Correct entities colors to ensure that they are always visible with the current background
     * color.
     */
    colorCorrection: false,
    /** Simpler version of colorCorrection - just invert pure white or black entities if they are
     * invisible on current background color.
     */
    blackWhiteInversion: true,
    /** Size in pixels for rasterized points (dot mark). */
    pointSize: 2,
    /** Retain the simple object representing the parsed DXF - will consume a lot of additional
     * memory.
     */
    retainParsedDxf: false,
    /** Whether to preserve the buffers until manually cleared or overwritten. */
    preserveDrawingBuffer: false,
    /** Encoding to use for decoding DXF file text content. DXF files newer than DXF R2004 (AC1018)
     * use UTF-8 encoding. Older files use some code page which is specified in $DWGCODEPAGE header
     * variable. Currently parser is implemented in such a way that encoding must be specified
     * before the content is parsed so there is no chance to use this variable dynamically. This may
     * be a subject for future changes. The specified value should be suitable for passing as
     * `TextDecoder` constructor `label` parameter.
     */
    fileEncoding: "utf-8"
}

class Layer {
    constructor(name, displayName, color) {
        this.name = name
        this.displayName = displayName
        this.color = color
        this.objects = []
    }

    PushObject(obj) {
        this.objects.push(obj)
    }

    Dispose() {
        for (const obj of this.objects) {
            obj.geometry.dispose()
        }
        this.objects = null
    }
}

// De aquí para abajo es para tratamiento del codigo de color

/** Transform sRGB color component to linear color space. */
function LinearColor(c) {
    return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)
}

/** Transform linear color component to sRGB color space. */
function SRgbColor(c) {
    return c < 0.003 ? c * 12.92 : Math.pow(c, 1 / 2.4) * 1.055 - 0.055
}

/** Get relative luminance value for a color.
 * https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
 * @param color {number} RGB color value.
 * @return {number} Luminance value in range [0; 1].
 */
function Luminance(color) {
    const r = LinearColor(((color & 0xff0000) >>> 16) / 255)
    const g = LinearColor(((color & 0xff00) >>> 8) / 255)
    const b = LinearColor((color & 0xff) / 255)

    return r * 0.2126 + g * 0.7152 + b * 0.0722
}

/**
 * Get contrast ratio for a color pair.
 * https://www.w3.org/TR/2008/REC-WCAG20-20081211/#contrast-ratiodef
 * @param c1
 * @param c2
 * @return {number} Contrast ratio between the colors. Greater than one if the first color color is
 *  brighter than the second one.
 */
function ContrastRatio(c1, c2) {
    return (Luminance(c1) + 0.05) / (Luminance(c2) + 0.05)
}

function HlsToRgb({h, l, s}) {
    let r, g, b
    if (s === 0) {
        /* Achromatic */
        r = g = b = l
    } else {
        function hue2rgb(p, q, t) {
            if (t < 0) {
                t += 1
            }
            if (t > 1) {
                t -= 1
            }
            if (t < 1/6) {
                return p + (q - p) * 6 * t
            }
            if (t < 1/2) {
                return q
            }
            if (t < 2/3) {
                return p + (q - p) * (2/3 - t) * 6
            }
            return p
        }

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s
        const p = 2 * l - q
        r = hue2rgb(p, q, h + 1/3)
        g = hue2rgb(p, q, h)
        b = hue2rgb(p, q, h - 1/3)
    }

    return (Math.min(Math.floor(SRgbColor(r) * 256), 255) << 16) |
           (Math.min(Math.floor(SRgbColor(g) * 256), 255) << 8) |
            Math.min(Math.floor(SRgbColor(b) * 256), 255)
}

function RgbToHls(color) {
    const r = LinearColor(((color & 0xff0000) >>> 16) / 255)
    const g = LinearColor(((color & 0xff00) >>> 8) / 255)
    const b = LinearColor((color & 0xff) / 255)

    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h, s
    const l = (max + min) / 2

    if (max === min) {
        /* Achromatic */
        h = s = 0
    } else {
        const d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
        case r:
            h = (g - b) / d + (g < b ? 6 : 0)
            break;
        case g:
            h = (b - r) / d + 2
            break
        case b:
            h = (r - g) / d + 4
            break
        }
        h /= 6
    }

    return {h, l, s}
}

function Lighten(color, factor) {
    const hls = RgbToHls(color)
    hls.l *= factor
    if (hls.l > 1) {
        hls.l = 1
    }
    return HlsToRgb(hls)
}

function Darken(color, factor) {
    const hls = RgbToHls(color)
    hls.l /= factor
    return HlsToRgb(hls)
}
