<template>
  <b-modal :active.sync="isModalActive" :width="1028" scroll="keep" :can-cancel="canCancel" @close="closeModal">

    <div class="border border-blue-light rounded shadow bg-white p-4">
      <form @submit.prevent="saveInventory" class="w-full">
        <header class="font-thin uppercase text-blue-light modal-card-head_ mb-4"
        >Añadir / editar inventario</header>

        <div class="modal-card-body_ relative">
            <div class="border-grey-light border-b border-dashed mb-3">

              <div class="w-full mb-3">
                <b-field :label="$t('material')">
                  <multiselect v-model="product" :options="products"
                    :custom-label="nameWithCode"
                    :placeholder="$t('select')"
                    label="name"
                    track-by="name"
                    :show-labels="false"
                    :maxHeight="200"/>
                </b-field>
              </div>

              <div class="w-full mb-3">
                <b-field :label="$t('provider')">
                  <multiselect v-model="provider" :options="providers"
                    :custom-label="nameWithCode"
                    :placeholder="$t('select')"
                    label="name"
                    track-by="name"
                    :show-labels="false"
                    :maxHeight="200"/>
                </b-field>
              </div>

              <div class="w-full flex flex-row mt-4 mb-3">
                <!-- <div class="w-1/5 mr-4">
                  <b-field :label="$t('unit_price')">
                    <v-input
                      v-model="form.unit_price"
                      title="unit_price"
                      required="true"
                      name="unit_price"
                      placeholder="unit_price"
                      maxlength="100"
                    />
                  </b-field>
                </div> -->

                <div class="w-1/5 mr-4">
                  <b-field :label="$t('stock')">
                    <v-input
                      v-model="form.stock"
                      title="stock"
                      required="true"
                      name="stock"
                      placeholder="stock"
                      maxlength="100"
                    />
                  </b-field>
                </div>

                <div class="w-1/5 ml-4">
                  <b-field :label="$t('stock_min')">
                    <v-input
                      v-model="form.stock_min"
                      title="stock_min"
                      required="true"
                      name="stock_min"
                      placeholder="stock_min"
                      maxlength="100"
                    />
                  </b-field>
                </div>
              </div>

              <b-loading :is-full-page="false" v-model="processingData" :can-cancel="false"></b-loading>
            </div>
        </div>
        <footer class="modal-card-foot_">
          <div class="w-full inline-flex justify-end">
            <!-- <v-button color="grey" icon="undo-alt" @click="isModalActive = false;">{{ $t('cancel') }}</v-button> -->
            <!-- <button class="button" size="is-small" type="button" @click="isModalActive = false; /*$emit('update:show', !show)*/">{{ $t('cancel') }}</button> -->
            <button class="btn btn-blue" nativeType="submit" v-if="!processingData">{{$t('save')}}</button>
            <v-button-processing v-if="processingData" />
          </div>
        </footer>
      </form>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Form from "vform";
import Multiselect from 'vue-multiselect'

export default {
  name: "material-modal",

  components: {
    Multiselect
  },

  props: {
    projectId: { type: Number, required: true }
  },

  data: () => ({
    isModalActive: false,
    processingData: false,
    canCancel: ['escape', 'x', 'outside'],
    data: null,
    form: {
      id: null,
      product_id: null,
      provider_id: null,
      // unit_price: "0.00",
      stock: "0",
      stock_min: "0",
    },
    options: [
      { value: 0, text: 'Sin categoría' }
    ],

    product: null,
    products: [],
    provider: null,
    providers: []
  }),

  computed: {
      ...mapGetters({
        company: "app/company",
      }),
  },

  created() {
    this.getProductsProject()
    this.getProvidersProject()
  },

  methods: {
    nameWithCode ({ name, code }) {
      return `${name} — [${code}]`
    },

    async getProductsProject() {
      this.products = []

      const {data} = await axios.get('/api/v2/project/'+this.projectId+'/store-products')
      if (data && data.success) {
        this.products = data.products
      } else {
        this.$notify.error('error_retreiving_measures')

      }
    },

    async getProvidersProject() {
      this.providers = []

      const {data} = await axios.get('/api/v2/project/'+this.projectId+'/providers')
      if (data && data.success) {
        this.providers = data.providers
      } else {
        this.$notify.error('error_retreiving_measures')

      }
    },

    async saveInventory() {
      let url = "/api/v2/project/"+this.projectId+"/store-inventory";
      if( this.form.id)
        url = "/api/v2/store-inventory/" + this.form.id;

      this.form.product_id = this.product.id
      this.form.provider_id = this.provider.id

      this.canCancel = [];
      this.processingData = true
      axios({
        method: "post",
        url: url,
        data: this.form
      })
        .then(response => {
          this.$toast.open({
            message: this.$t("save_successful"),
            type: "is-success",
            position: "is-top-right"
          });

          this.processingData = false
          this.canCancel = ['escape', 'x', 'outside']

          this.hide();
          this.$emit("reload-inventories");
        })
        .catch(error => {
          //Se produjo un error
          this.isLoading = false;
          this.processingData = false
          this.canCancel = ['escape', 'x', 'outside']
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("save_error"),
            type: "is-danger",
            position: "is-top-right"
          });
        });
    },

    show(data) {
      this.data = data;
      this.form.id = data.id;
      this.form.product_id = data.product_id;
      this.form.provider_id = data.provider_id;
      // this.form.unit_price = data.unit_price.toString(),
      this.form.stock = data.stock.toString();
      this.form.stock_min = data.stock_min.toString();

      this.product = null
      this.provider = null

      if( this.form.product_id ) {
          this.product = this.products.find((item) => item.id == this.form.product_id);
      }

      if( this.form.provider_id ) {
          this.provider = this.providers.find((item) => item.id == this.form.provider_id);
      }

      this.isModalActive = true;
    },

    hide() {
      this.data = null;
      this.product = null
      this.provider = null

      this.isModalActive = false;
      this.form.id = null;
      this.form.product_id = null;
      this.form.provider_id = null;
      // this.form.unit_price = "0.00",
      this.form.stock = "0";
      this.form.stock_min = "0";
    },

    closeModal() {
      this.$emit('update:show', !this.show)
    }
  }
};
</script>
<style scoped>
/* Oculta los botones para aumentar los inputs numéricos */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
