<template>
  <!-- modal de filtro de gestor documental -->
  <div>

    <div v-if="withButton"
      class="mr-2 rounded-sm" @click="show()">
      <v-button icon="project-diagram" class="mr-4"><span class="hidden sm:inline">{{ $t('filters') }}</span></v-button>
    </div>

    <b-modal :active.sync="isModalActive" has-modal-card >

      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow">
          <!-- Modal header -->
          <div class="flex items-start justify-between p-3 border-b- rounded-t">
              <h3 class="text-xl font-semibold text-gray-900">
                {{ $t('filters') }}
              </h3>
              <!-- <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                  <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                  </svg>
                  <span class="sr-only">Close modal</span>
              </button> -->
          </div>

          <!-- Modal body -->
          <div class="p-3 space-y-6">
            <folders-filters-fields ref="refFiltersFields"
              :locations-list="locationsList"/>
          </div>

          <!-- Modal footer -->
          <div class="flex items-center p-3 space-x-2 border-t- border-gray-200 rounded-b">
            <b-button @click="clearFilter()">{{ $t('clean_filters') }}</b-button>
            <b-button type="is-info"
              @click="applyFilter()">Aplicar filtros</b-button>
          </div>
      </div>

      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
    </b-modal>

  </div>
</template>

<script>
import axios from "axios";
import FoldersFiltersFields from "./components/folders-filters-fields.vue";
export default {
  name: "folders-filters-modal",

  components: {
    FoldersFiltersFields
  },

  props: {
    withButton: { type: Boolean, default: true },
    locationsList: { type: Array, default() { return [] } }
  },

  data: () => ({
    isModalActive: false,
    isLoading: false,
    filters: {}
  }),

  mounted() {
  },

  methods: {
    createFolder() {

    },

    show() {
      this.isModalActive = true
    },

    applyFilter() {
      this.filters = this.$refs.refFiltersFields.generateFilters()

      this.$emit('epc-apply-filters', this.filters);

      this.isModalActive = false
    },

    clearFilter() {
      this.$refs.refFiltersFields.cleanFilters()
      this.filters = this.$refs.refFiltersFields.generateFilters()

      this.$emit('epc-apply-filters', this.filters);

      this.isModalActive = false
    },

    getFilters() {
      return this.filters
    }
  }
};
</script>

<style scoped>

</style>
