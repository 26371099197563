<template>
  <div class="">
    <!-- Tabla de Drawings -->
    <div class="panel">
      <div v-if="isLoading" class="my-4 text-center">
        <scale-loader></scale-loader>
      </div>
      <div v-else>
        <div class="flex flex-wrap w-full mt-4">
        <section class="w-full">
          <b-table v-if="!isLoading"
            icon-pack="fas"
            :data="models"
            :bordered="true"
          >
            <template>
              <!-- <b-table-column field="thumbnail" cell-class="w-6 vertical-center" sortable>
                <template v-slot:header="">{{ $t('thumbnail') }}</template>
                <template v-slot="props">6200 render validation
                  <div class="flex flex-wrap justify-center">
                    <img
                      :src="props.row.objectKey"
                      class="p-1 bg-white border rounded"
                      :alt="props.row.description"
                      onerror="this.src='https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/thumb.jpeg'"
                    />
                  </div>
                </template>
              </b-table-column> -->

              <b-table-column field="code" cell-class="vertical-center" sortable>
                <template v-slot:header="">{{ $t('code') }}</template>
                <template v-slot="props">{{ props.row.code }}</template>
              </b-table-column>

              <b-table-column field="name" cell-class="vertical-center" sortable>
                <template v-slot:header="">{{ $t('name') }} / {{ $t('description') }}</template>
                <template v-slot="props">{{ props.row.original_filename }} <br> <span class="text-light"> {{ props.row.description }}</span></template>
              </b-table-column>

              <!-- <b-table-column field="file" cell-class="w-6 vertical-center" centered v-if="withActions">
                <template v-slot:header="">{{ $t('file') }}</template>
                <template v-slot="props">
                  <b-tooltip :label="$t('download_document')" position="is-top">
                    <div class="cursor-pointer text-blue-light">
                        <a class="button is-rounded is-link" @click="downloadDoc(props.row.document_id)">
                            <span class="icon">
                              <i class="fas fa-file"></i>
                            </span>
                        </a>
                    </div>
                  </b-tooltip>
                </template>
              </b-table-column> -->

              <b-table-column field="actions" cell-class="vertical-center" width=100>
                <template v-slot:header="">{{ $t('actions') }}</template>
                <template v-slot="props">
                  <div class="flex items-center justify-around w-full h-full">

                    <b-tooltip :label="'editar'" position="is-right" v-if="props.row.mime_type === 'application/pdf'">
                      <div class="w-8 h-8 overflow-hidden" @click="openEditor(props.row)" >
                        <b-icon pack="fas" icon="pen" />
                      </div>
                    </b-tooltip>

                    <b-tooltip :label="$t('delete')" position="is-left" type="is-danger">
                        <div @click="removeDesign(props.row)"
                            class="flex cursor-pointer text-red">
                          <b-icon pack="fas" icon="trash-alt"/>
                        </div>
                    </b-tooltip>

                    <b-tooltip :label="$t('view_document')" position="is-left" v-if="props.row.mime_type === 'application/pdf'">
                        <!-- <div @click="onRowClicked(props.row)"
                            class="flex cursor-pointer text-sky-300">
                          <b-icon pack="fas" icon="eye"/>
                        </div> -->
                        <div @click="onShowViewerPdfClicked(props.row)"
                            class="flex cursor-pointer text-sky-300">
                          <b-icon pack="fas" icon="eye"/>
                        </div>
                    </b-tooltip>

                    <b-tooltip :label="$t('view_assignments')" position="is-left" v-if="props.row.mime_type === 'application/pdf'">
                        <div @click="assignedActivities(props.row)"
                            class="flex cursor-pointer text-sky-300">
                          <b-icon pack="fas" icon="link"/>
                        </div>
                    </b-tooltip>

                  </div>
                </template>
              </b-table-column>
            </template>

            <template slot="bottom-left">
                <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                <b>{{ $t("total") }}</b>: {{ this.models.length }}
            </template>

            <template #empty>
                <div class="has-text-centered">{{ $t('no_results') }}</div>
            </template>

          </b-table>
        </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="module">
import axios from 'axios';
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'


export default {
  middleware: 'auth',

  components: {
    ScaleLoader
  },

  props: {
    projectId: { type: Number, required: true },
  },

  data() {
    return {
      models: [],
      isLoading: false,
    }
  },

  computed: {

  },

  beforeCreate() {
  },

  created() {

  },

  mounted() {
    // this.getModels() // obtiene los modelos almacenados en el bucket de autodesk
    this.getModelsBim() // optiene desde al base de datos nuestra
  },


  methods: {
    getModels() { // trae los modelos desde autodesk no usado de momento
      this.isLoading = true
      let url = '/api/v2/autodesk/forge/getModels';
      let params = {
        project_id: this.projectId
      }
      axios.get(url, { params: params })
        .then(response => {
          this.isLoading = false
          if( response.data.success ) {
            this.models = response.data.models.items
          }
        })
        .catch(error => {
          this.isLoading = false
          console.log('error get models: ', error)
        })
    },

    getModelsBim() {
      this.isLoading = true
      let url = '/api/v2/project/'+ this.projectId +'/document-bims';
      let params = { }
      axios.get(url, { params: params })
        .then(response => {
          this.isLoading = false
          if( response.data.success ) {
            this.models = response.data.documents
          }
        })
        .catch(error => {
          this.isLoading = false
          console.log('error get models: ', error)
        })
    },

    async removeDesign(model) {
      // this.isLoading = true
      // let url = '/api/v2/model-bim/' + model.id + '/delete';
      // let params = { }
      // axios.post(url)
      //   .then(response => {
      //     this.isLoading = false
      //     if( response.data.success ) {
      //       console.log( 'remove modelos: ', response )
      //       this.models = response.data.documents
      //     }
      //   })
      //   .catch(error => {
      //     this.isLoading = false
      //     console.log('error get models: ', error)
      //   })

      this.$dialog.confirm({
        title: this.$t("delete"),
        message: this.$t("delete_generic", [model.description]),
        confirmText: this.$t("delete"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true;
          let url = "/api/v2/model-bim/" + model.id + "/delete";
          const { data } = await axios.post(url);

          if (data.success) {
            this.$toast.open({
              message: this.$t("success_deleting_generic"),
              type: "is-success",
              position: "is-top-right"
            });
            await this.sleep(1000);
            this.reload();
          } else {
            this.isLoading = false;
            // Error
            this.$toast.open({
              message: data.error || this.$t("error_saving"),
              type: "is-danger",
              position: "is-top-right"
            });
          }
        }
      });
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    reload() {
      // this.getModels()
      this.getModelsBim()
    }
  },
};
</script>
