<template>
<div>

  <span class="text-xs blue-lightest">
    <router-link :to="'/admin/validations/'"
              >{{ $t('validations') }}
    </router-link>
    <span class="mx-3">&gt;</span>
    <span class="font-bold">{{ form.name }}</span>
  </span>


  <div class="panel">

    <div class="flex">
      <div class="w-1/2 pr-4">
        <l-form-input ref="templateCode"
          :form="form"
          field="code"
          label="code"
        />
      </div>
      <div class="w-1/2 pr-4">
        <label for="flow_type" class="label">{{ $t('select_type') }}</label>
        <b-select ref="input"
          v-model="form.flow_type"
          :placeholder="$t('select_type')"
          id="flow_type"
          name="flow_type"
          class="w-full"
          required
          expanded
        >
          <option v-for="(type, index) in templateTypes" :value="index" :key="index">
            {{ type }}
          </option>
        </b-select>
      </div>
      <div class="w-full pr-4">
        <l-form-input ref="templateName"
          :form="form"
          field="name"
          label="name"
        />
      </div>
    </div>


    <!-- Descripción -->
    <div class="flex items-center mt-8">
      <l-form-input
        :form="form"
        field="description"
        label="description"
        input-type="textarea"
        :maxlength="2000"
        class="w-full"
      />
    </div>


    <!-- documentos heredables entre tareas -->
    <div class="flex items-center mt-8">
      <b-tooltip :label="'Los documentos de una tarea se heredan a la siguiente tarea una vez finalizada'" position="is-top">
        <b-icon icon="info" pack="fas" size="is-small" class="ml-1 float-right"/>
      </b-tooltip>
      <v-switch
        :label="'Documentos heredables'"
        :color="form.inherit_docs? 'green' : 'red'"
        :value="form.inherit_docs"
        v-model="form.inherit_docs"
        v-on:checked="form.inherit_docs = !form.inherit_docs"
      />

      <label class="text-red text-xs">* Actualizar este campo, afectará tambien a los flujos ya asignados</label>
    </div>


    <!-- Tareas -->
    <div class="mt-8">
      <label><b>{{ $t('tasks') }}</b></label>

      <!-- recuadro de tarea -->
      <div class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-blue-lightest mb-1"
        v-for="(task, taskKey) in form.tasks" :key="task.id">

        <!-- div linea tarea padre -->
        <div>
          <!-- delete -->
          <b-tooltip :label="$t('remove')" position="is-left" type="is-danger">
              <div @click="unlinkTask(task.id, task.code + ' ' +task.name)" class="flex cursor-pointer text-red">
                <b-icon pack="fas" icon="trash-alt"/>
              </div>
          </b-tooltip>
          <!-- save -->
          <b-tooltip v-if="tasksInEdition.includes(task.id)" :label="$t('save')" position="is-left">
              <div @click="sendTaskData(task.id, taskKey)" class="flex cursor-pointer text-blue">
                <b-icon pack="fas" icon="download"/>
              </div>
          </b-tooltip>
          <!-- edit -->
          <b-tooltip v-else :label="$t('edit')" position="is-left">
              <div @click="editTask(task.id)" class="flex cursor-pointer text-blue">
                <b-icon pack="fas" icon="pencil-alt"/>
              </div>
          </b-tooltip>

          <span v-if="tasksInEdition.includes(task.id)"><input v-model="form.tasks[taskKey].order_column" type="text" class="mr-2"></span>
          <span v-else class="ml-2 italic">({{ task.order_column }})</span>

          <span v-if="tasksInEdition.includes(task.id)"><input v-model="form.tasks[taskKey].code" type="text" class="mr-2"></span>
          <span v-else class="ml-2 font-bold">{{ task.code }}</span>

          <span v-if="tasksInEdition.includes(task.id)"><input v-model="form.tasks[taskKey].name" type="text" class="mr-2"></span>
          <span v-else class="ml-2">{{ task.name }}</span>

          <span v-if="tasksInEdition.includes(task.id)"><input v-model="form.tasks[taskKey].description" type="text" class="mr-2" :placeholder="$t('description')"></span>
          <span v-else class="ml-2">{{ task.description }}</span>
        </div>

        <!-- div linea tarea hija -->
        <div class="bg-gray-100" v-for="subTask in task.children" :key="subTask.id">

          <b-icon pack="fas" icon="chevron-right"/>
          <!-- delete -->
          <b-tooltip :label="$t('remove')" position="is-left" type="is-danger">
              <div @click="unlinkTask(subTask.id, subTask.code + ' ' +subTask.name)" class="flex cursor-pointer text-red">
                <b-icon pack="fas" icon="trash-alt"/>
              </div>
          </b-tooltip>
          <!-- edit -->
          <b-tooltip :label="$t('edit')" position="is-left">
              <div @click="editTask(subTask.id)" class="flex cursor-pointer text-blue">
                <b-icon pack="fas" icon="pencil-alt"/>
              </div>
          </b-tooltip>
          <i>({{ subTask.order_column }})</i>
          <span class="ml-2 font-bold">{{ subTask.code }}</span>
          <span class="ml-2">{{ subTask.name }}</span>
          <span class="ml-2">{{ task.description }}</span>

        </div>

      </div>

      <!-- recuadro añadir tarea -->
      <div class="w-full border border-blue-lighter rounded flex items-center p-2 bg-blue-lightest mb-1">

        <b-tooltip :label="$t('add_task')" position="is-left">
            <button class="btn btn-green items-center mr-2"  type="button" @click="linkTask()">
              <i class="fas fa-plus"></i>
            </button>
        </b-tooltip>

        <b-tooltip :label="$t('order')" position="is-left">
          <input v-model="newTask.order_column" type="text" :placeholder="$t('order')" class="input mr-2 w-15" style="width:40px">
        </b-tooltip>

        <b-tooltip :label="$t('code')" position="is-left">
          <input v-model="newTask.code" type="text" :placeholder="$t('code')" class="input mr-2" style="width:60px">
        </b-tooltip>

        <b-tooltip :label="$t('name')" position="is-left">
          <input v-model="newTask.name" type="text" :placeholder="$t('name')" class="input mr-2">
        </b-tooltip>

        <b-tooltip :label="$t('description')" position="is-left">
          <input v-model="newTask.description" type="text" :placeholder="$t('description')" class="input mr-2">
        </b-tooltip>

        <b-tooltip label="Grupo inicial" position="is-left">
          <b-select v-model="newTask.manager_group_id" :placeholder="$t('select')" class="mr-2">
            <option v-for="group in templateGroups" :value="group.id" :key="'group_'+group.id">
              {{ group.code }}
            </option>
          </b-select>
        </b-tooltip>

        <b-tooltip label="Tarea padre" position="is-left">
          <b-select v-model="newTask.parent_id" :placeholder="$t('select')" class="mr-2">
            <option :value="0" selected>{{ $t('no_parent') }}</option>
            <option v-for="(task) in form.tasks" :value="task.id" :key="'parent_select_'+task.id">
              {{ task.code }} {{ task.name }}
            </option>
          </b-select>
        </b-tooltip>

        <b-tooltip label="Tareas hijas concurrentes" position="is-left" v-show="!newTask.parent_id">
          <v-switch
            :label="'Concurrentes'"
            :color="newTask.is_concurrent? 'green' : 'red'"
            :value="newTask.is_concurrent"
            v-model="newTask.is_concurrent"
            v-on:checked="newTask.is_concurrent = !newTask.is_concurrent"
          />
        </b-tooltip>

      </div>

    </div>


    <!-- Grupos -->
    <div class="mt-8">
      <label><b>{{ $t('groups') }}</b></label>
    </div>
    <div class="grid grid-cols-4 gap-4">

      <!-- recuadro grupo -->
      <span class="border border-blue-lighter rounded items-center p-2 bg-blue-lightest m-1"
        v-for="(group) in form.groups" :key="group.id">

        <div style="float:right" @click="unlinkGroup(group.id, group.code)">
          <b-tooltip :label="$t('delete')" position="is-left">
            <b-icon pack="fas" icon="times-circle" />
          </b-tooltip>
        </div>

        <div class="text-2xl text-bold mb-4">{{ group.code }}</div>

        <userlist
          :ref="'group_' + group.id + '_participants'"
          title="users_participants"
          icon="users"
          api-url="/api/v2/validations/templategroup"
          :parent-entity-id="parseInt(group.id)"
          :users-permission-type="['participate']"
          :allow-create-users="false"
          @user-added="participantAddedInGroup($event)"
          :local-mode="false"
          :allow-company-users22="true"
          :superior-hierarchical-users="[]"
          :box-full-width="true"
          :validation-users="true"
          class="mr-3"
          roleName="participant" />
      </span>

      <!-- recuadro añadir grupo -->
      <span class="border border-blue-lighter rounded items-center p-2 bg-blue-lightest m-1"
        style="text-align: center">

        <span class="font-bold text-blue">{{ $t('add_group') }}</span>

        <b-select v-model="groupToAdd" :placeholder="$t('select')" expanded class="mt-2">
          <option v-for="group in templateGroups" :value="group" :key="'group_'+group.id">
            {{ group.code }}
          </option>
        </b-select>

        <button class="btn btn-green items-center mt-2" style="margin:auto" type="button" @click="linkGroup()">
          <i class="fas fa-plus mr-1"></i> {{ $t('add_group') }}
        </button>

      </span>

    </div>
    <div>
      <label class="text-red text-xs">* Añadir/eliminar nuevos grupos a la plantilla, o modificar los participantes del grupo, afectará tambien
        a los flujos ya asignados
      </label>
    </div>

  </div>

  <form @submit.prevent="sendTemplateData" @keydown="form.onKeydown($event)"
    class="w-full flex items-center justify-end mb-8">
    <v-button :disabled="disableSaveButton" type="submit" icon="thumbs-up" icon-pack="far" class="mr-4">{{ $t('save') }}</v-button>
    <v-button color="grey" icon="undo-alt" @click="$router.go(-1)">{{ $t('cancel') }}</v-button>
  </form>

</div>
</template>

<script>
import axios from 'axios'
import Form from 'vform'
import { mapGetters } from 'vuex'
import Userlist from '~/components/UserBox/UserList'

export default {
  middleware: 'auth',
  title: 'validations_edit_page_title',
  subtitle: 'validations_edit_page_subtitle',
  menuOption: '0-5',

  components: {
    Userlist,
  },

  data: () => ({
    templateId: null,
    form: new Form({
      code: '',
      name: '',
      description: '',
      flow_type: 0,
      tasks: [],
      groups: [],
      inherit_docs: false,
    }),
    newTask: new Form({
      code: '',
      name: '',
      description: '',
      order_column: 0,
      manager_group_id: 0,
      parent_id: 0,
      is_concurrent: false,
    }),
    tasksInEdition: [], // tareas que estan en modo edicion. Editor inline en desuso (ahora redirige a su pantalla)
    disableSaveButton: false,
    templateGroups: [], // plantillas de grupos posibles a añadir al template
    groupToAdd: {}, // el grupo elegido para añadir
    templateTypes: ['Estándar', 'Especificaciones Técnicas', 'Incidencias'],
  }),

  created() {

    this.templateId = this.$route.params.validation_id

  },

  computed: {
    ...mapGetters({
      company: 'app/company',
    }),
  },

  mounted() {
    this.getGroups()
    this.getTemplateData()
    this.$refs.templateCode.setFocus()
  },

  methods: {

    // Listado de todas las plantillas de grupo (para selector al añadir)
    async getGroups () {
      const { data } = await axios.get("/api/v2/validations/templategroups", {'params' : { company_id: this.company.id }});

      if (data.groups) {
        this.templateGroups = data.groups;
      } else {
        // Error
        this.$notify.error('error_retrieving_data')

      }
    },

    async getTemplateData() {
      const { data } = await axios.get('/api/v2/validations/template/' + this.templateId)
      if (data && data.success) {

        this.form.code = data.data.code
        this.form.name = data.data.name
        this.form.description = data.data.description
        this.form.flow_type = data.data.flow_type
        this.form.tasks = data.data.tasks
        this.form.groups = data.data.groups
        this.form.inherit_docs = data.data.inherit_docs

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')
        this.$router.push({ name: 'project.home', params: {} })

      } else {
        this.$notify.error('error_retrieving_data')
      }
    },


    async sendTemplateData() {
      var url = '/api/v2/validations/template/' + this.templateId;
      this.disableButton();

      this.form.inherit_docs = this.form.inherit_docs ? 1 : 0 // laravel espera 1 o 0

      // Enviamos el form
      const { data } = await this.form.post(url)
      if (data && data.success) {

        this.$notify.success('success_editing')
        this.$router.push({ name: 'admin.validations' })

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')
        this.$router.push({ name: 'project.home', params: {} })

      } else {
        this.$notify.error('error_saving')
      }
    },


    // redirige a pantalla edicion
    editTask(taskId) {
      // this.tasksInEdition.push(taskId) edicion inline en desuso
      this.$router.push({
        name: 'validation-template-task.edit', params: { validation_id: this.templateId, task_id: taskId }
      })
    },


    // guarda cambios de la tarea y la saca del modo edicion
    async sendTaskData(taskId, taskKey) {

      var url = '/api/v2/validations/template/task/' + taskId;

      // Enviamos el form
      const { data } = await axios.post(url, this.form.tasks[taskKey])
      if (data && data.success) {

        // la sacamos del modo edicion
        this.tasksInEdition.splice(this.tasksInEdition.indexOf(taskId),1)

        this.$notify.success('success_editing')

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')

      } else {
        this.$notify.error('error_saving')
      }
    },


    participantAddedInGroup(user) {
      // console.log('usuario añadido', user)
    },


    // crea nueva tarea en esta plantilla
    async linkTask() {
      if (this.newTask.code == '' || this.newTask.name == '' || this.newTask.managerGroupId == 0) {
        this.$notify.error('Debe introducir al menos: Codigo, Nombre y Grupo inicial')
        return
      }

      const { data } = await axios.post("/api/v2/validations/template/task", {
        code: this.newTask.code,
        name: this.newTask.name,
        description: this.newTask.description,
        order_column: this.newTask.order_column,
        manager_group_id: this.newTask.manager_group_id,
        template_id: this.templateId,
        parent_id: this.newTask.parent_id == 0 ? null : this.newTask.parent_id,
        is_concurrent: this.newTask.is_concurrent, // la tarea padre define si las subtareas son concurrentes (no tienen orden secuencial)
      })

      if (data && data.success) {

        // reload
        this.getTemplateData()
        this.newTask = new Form({
          code: '',
          name: '',
          description: '',
          order_column: 0,
          manager_group_id: 0,
          parent_id: 0,
          is_concurrent: false,
        }),
        this.$notify.success('success_editing')

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')

      } else {
        this.$notify.error('error_saving')
      }
    },


    // elimina la task de esta plantilla
    async unlinkTask(taskId, taskName) {

      let self = this;
      this.$dialog.confirm({
        title: this.$t('delete'),
        message: this.$t('delete_generic', [taskName]),
        confirmText: this.$t('delete'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {

          const { data } = await axios.post("/api/v2/validations/template/task/" + taskId + "/delete", {})
          if (data && data.success) {

            // reload
            this.getTemplateData()
            this.$notify.success('success_deleting_generic')

          } else if(data && data.msg){

            this.$notify.error('no_access_permissions')

          } else {
            this.$notify.error('error_saving')
          }
        }
      })
    },


    // vincula el grupo seleccionado con la plantilla
    async linkGroup() {
      if (!this.groupToAdd.id) {
        this.$notify.error('Debe seleccionar un grupo de la lista')
        return
      }

      // console.log("añadir el grupo ", this.groupToAdd)
      const { data } = await axios.post("/api/v2/validations/template/group", {
        company_id: this.company.id,
        template_id: this.templateId,
        group_code: this.groupToAdd.code,
      })
      if (data && data.success) {

        // reload
        this.getTemplateData()
        this.groupToAdd = {}
        this.$notify.success('success_editing')

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')

      } else {
        this.$notify.error('error_saving')
      }
    },


    // desvincula el grupo de esta plantilla
    async unlinkGroup(groupId, groupName) {

      let self = this;
      this.$dialog.confirm({
        title: this.$t('delete'),
        message: this.$t('delete_generic', [groupName]),
        confirmText: this.$t('delete'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {

          const { data } = await axios.post("/api/v2/validations/template/group/" + groupId + "/delete", { })
          if (data && data.success) {
            // reload
            this.getTemplateData()

            this.$notify.success('success_deleting_generic')

          } else if(data && data.msg){

            this.$notify.error('no_access_permissions')

          } else {
            let msg = 'error_saving'
            if( data && data.error )
              msg = data.error

            this.$notify.error(msg)
          }
        }
      })
    },


    disableButton() {
      this.disableSaveButton = true
      let self = this
      setTimeout(function() {
        self.disableSaveButton = false
      }, 2000);
    }

  },
};
</script>
