<template>
  <div class="page-with-top-bar">
    <section>
      <div class="mt-4">
        <b-tabs type="is-boxed" v-model="mainActiveTab">
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="store-alt"></b-icon>
              <span> {{ $t('storehouse') }} </span>
            </template>
            <div>
              <manage-inventory :projectId="project_id" />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="shopping-cart"></b-icon>
              <span> {{ $t('entrances') }} </span>
            </template>
            <div>
              <manage-purchases :projectId="project_id" />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="shopping-cart" class="fa-flip-horizontal"></b-icon>
              <span> {{ $t('outs') }} </span>
            </template>
            <div>
              <manage-outs :projectId="project_id" :canImport="false" />
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import importEntities from '../../components/import-entities'
import exportActivities from '../../components/export-activities'
import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons'
import ManageInventory from '../../components/storehouse/manage-inventory.vue'
import ManageMaterials from '../../components/storehouse/manage-materials.vue'
import ManageProviders from '../../components/storehouse/manage-providers.vue'
import ManageExtrafields from '../../components/storehouse/manage-extrafields.vue'
import ManagePurchases from '../../components/storehouse/manage-purchases.vue'
import ManageOuts from '../../components/storehouse/manage-outs.vue'

export default {
  components: {
    importEntities,
    exportActivities,
    ProjectAdminQuickButtons,
    ManageInventory,
    ManageMaterials,
    ManageProviders,
    ManageExtrafields,
    ManagePurchases,
    ManageOuts,
  },
  middleware: "auth",
  title: "storehouse_page_title",
  subtitle: 'storehouse_page_subtitle',
  menuOption: "2-10",

  computed: {
    ...mapGetters({
      navData: 'app/navData',
      project: 'app/project',
    }),
  },

  data: () => ({
    project_id: null,
    mainActiveTab: 0
  }),

  created() {
    // this.$options.subtitle = ['project_actions_page_subtitle', [this.navData.project_name.toUpperCase()]]
    this.project_id = this.project.id
  },

  methods: {

  },
};

</script>
