<template>
  <div class="w-full">
    <div v-if="layers.length > 0">
      <div class="mt-3 mb-3">
        <div class="flex items-center bg-blue-light text-white text-sm font-bold px-4 py-3 rounded" role="alert">
          <svg class="fill-current w-8 h-8 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
          <p>Realize los cambios necesarios en la estructura de las capas y pulse boton validar.</p>
        </div>
      </div>

      <b-collapse v-for="(layer, index) of layers"
        :ref="'collapse_lyr_' + index"
        class="card mb-1"
        :class="{'border': isOpen == index, 'border-blue': isOpen == index, 'rounded': isOpen == index}"
        animation="slide"
        :key="'tab_lyr_' + index"
        :open="isOpen == index"
        @open="isOpen = index"
        :aria-id="'contentIdForA11y5-' + index">
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            :aria-controls="'contentIdForA11y5-' + index"
            :style="_getStyle(props.open, layer)"
            :aria-expanded="props.open">
            <p class="card-header-title" :style="{ color: props.open ? '#ffffff !important' : 'hsl(0, 0%, 21%)'}">
              <button class="inline-block w-9 h-9 bg-gray-200 rounded-full mr-1"
                :style="{ backgroundColor: _GetCssColor(layer.color) }"
                type="button">
                <b-icon pack="fas" :icon="'layer-group'"></b-icon>
              </button>  {{ layer.name }}
            </p>
            <a class="card-header-icon">
              <b-icon
                  :icon="props.open ? 'angle-down' : 'angle-up'" pack="fas">
              </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content-">
            <fields-design-form :ref="'frm_lys_' + index"
              :disable-fields="disableFields"
            />
          </div>
        </div>
        <footer class="card-footer">
          <b-button
            :label="$t('validate')"
            type="is-info"
            icon-pack="fas"
            icon-left="check"
            expanded
            @click="_HandleValidate( layer, index)"/>

          <!-- <a class="card-footer-item p-2" @click="_HandleOpenModal( { type: layer.code, status: null } )">
            <b-icon pack="fas" icon="plus-circle"></b-icon> {{ $t('add') }}
          </a> -->
          <!-- <a class="card-footer-item" @click="_OnSave"> <b-icon pack="fas" icon="save"></b-icon> {{ $t('save') }}</a> -->
          <!-- <a class="card-footer-item">Delete</a> -->
        </footer>
      </b-collapse>
    </div>
    <div v-else class="flex flex-row bg-grey-light border hover:border-blue rounded">
      <p class="text-center w-full">El diseño no contiene capas</p>
    </div>
  </div>
</template>

<script>
import FieldsDesignForm from "./fields-design-form.vue"

export default {
  name: "layers-design-list",

  components: {
    FieldsDesignForm
  },

  props: {
    layers: { type: Array, required: true },
    disableFields: { type: Boolean, default: false },
  },

  data: () => ({

    dataDesign: {}, // contendrá la cantidad de hincados, modulos y paneles por capas a ser diseñado
    isOpen: -1 // para menejar que panel esta colapsado o activo
  }),

  created() {
  },

  computed: {
  },

  methods: {

    getDataDesign() {
      return this.dataDesign
    },

    _getStyle(open, lyr) {
      let color = 'initial'
      if( this.dataDesign.hasOwnProperty(lyr.name.replace(/\s/g, '_')) ) {
        color = '#C5E1A5'
      }

      return { backgroundColor: open ? 'rgb(85, 119, 187)' : color}
    },

    _HandleValidate(lyr, refindex) {
      let dataFrm = this.$refs['frm_lys_' + refindex][0].getDataForm()

      if (dataFrm != null) {
        this.dataDesign[ lyr.name.replace(/\s/g, '_') ] = dataFrm
      }

      this.$refs['collapse_lyr_' + refindex][0].toggle()
    },

    _GetCssColor(value) {
      let s = value.toString(16)
      while (s.length < 6) {
          s = "0" + s
      }

      if (s=="ffffff") // TODO: cuando es blanco tratamos tratar aquí de momento mandamos gris bajo al 10% si es necesario o mostrar un borde y dejar fondo blanco
        s = 'f2f2f2'
      return "#" + s
    }
  },
};
</script>
