<template>
<div>
  <b-collapse class="card filters-collapse" :open.sync="showFilters">
    <div slot="trigger" class="card-header filters-trigger">
      <p class="card-header-title">
        {{ $t('filters') }}
      </p>
      <a class="card-header-icon">
        <b-icon pack="fa" v-show="! showFilters" icon="plus-circle"/>
        <b-icon pack="fa" v-show="showFilters" icon="minus-circle"/>
      </a>
    </div>
    <div class="panel" style="border-width: 0">
      <div class="flex flex-col lg:flex-row items-center mb-4">
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_name')">
            <b-input
              v-model="filterName"
              :placeholder="$t('activity_name')"
              type="search"
              icon="search"
              icon-pack="fas"
              @input="checkInputTextFilter(filterName)">
            </b-input>
          </b-field>
        </div>
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_creator')">
            <b-input
              v-model="filterCreator"
              :placeholder="$t('creator')"
              type="search"
              icon="search"
              icon-pack="fas"
              @input="checkInputTextFilter(filterCreator)">
            </b-input>
          </b-field>
        </div>
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_manager')">
            <b-input
              v-model="filterManager"
              :placeholder="$t('manager')"
              type="search"
              icon="search"
              icon-pack="fas"
              @input="emptyParticipantAndApplyFilters">
            </b-input>
          </b-field>
        </div>
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_participant')">
            <b-input
              v-model="filterParticipant"
              :placeholder="$t('participant')"
              type="search"
              icon="search"
              icon-pack="fas"
              @input="emptyManagerAndApplyFilters">
            </b-input>
          </b-field>
        </div>
      </div>
      <div class="flex flex-col lg:flex-row items-center mb-4">
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('scheduled_start_date')">
            <b-datepicker
              v-model="filterDateScheduledStart"
              :placeholder="$t('scheduled_start_date')"
              :month-names="$t('months_names')"
              :day-names="$t('days_names')"
              :first-day-of-week="parseInt($t('first_day_of_week'))"
              :readonly="false"
              @input="applyFilters(1)"
            >
            </b-datepicker>
          </b-field>
        </div>
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('scheduled_end_date')">
            <b-datepicker
              v-model="filterDateScheduledEnd"
              :placeholder="$t('scheduled_end_date')"
              :month-names="$t('months_names')"
              :day-names="$t('days_names')"
              :first-day-of-week="parseInt($t('first_day_of_week'))"
              :readonly="false"
              @input="applyFilters(1)"
            >
            </b-datepicker>
          </b-field>
        </div>
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('current_start_date')">
            <b-datepicker
              v-model="filterDateCurrentStart"
              :placeholder="$t('current_start_date')"
              :month-names="$t('months_names')"
              :day-names="$t('days_names')"
              :first-day-of-week="parseInt($t('first_day_of_week'))"
              :readonly="false"
              @input="applyFilters(1)"
            >
            </b-datepicker>
          </b-field>
        </div>
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('current_end_date')">
            <b-datepicker
              v-model="filterDateCurrentEnd"
              :placeholder="$t('current_end_date')"
              :month-names="$t('months_names')"
              :day-names="$t('days_names')"
              :first-day-of-week="parseInt($t('first_day_of_week'))"
              :readonly="false"
              @input="applyFilters(1)"
            >
            </b-datepicker>
          </b-field>
        </div>
      </div>
      <div class="flex flex-col lg:flex-row items-center mb-4">
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('select_location')">
            <b-select ref="input"
                      v-model="filterLocationId"
                      :placeholder="$t('select_location')"
                      icon="map-marker-alt"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      expanded
            >
              <option v-for="option in locationsList"
                      :value="option['id']" :key="'location_'+option['id']"
              >
                {{ option['name'] }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_department')">
            <b-select ref="input"
                      v-model="filterDepartmentId"
                      :placeholder="$t('department')"
                      icon="search"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      expanded
            >
              <option v-for="option in departmentsList"
                      :value="option['id']"
                      :key="option['id']"
              >
                {{ option['name'] }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_status')">
            <b-select ref="input"
                      v-model="filterStatus"
                      :placeholder="$t('activity_status')"
                      icon="search"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      expanded
            >
              <option value="100">{{$t('opened')}}</option>
              <option value="101">{{$t('all')}}</option>
              <option value="0">{{$t('activity_status_assigned')}}</option>
              <option value="1">{{$t('activity_status_running')}}</option>
              <option value="2">{{$t('activity_status_paused')}}</option>
              <option value="3">{{$t('activity_status_issue')}}</option>
              <option value="4">{{$t('activity_status_done')}}</option>
              <option value="5">{{$t('activity_status_reopened')}}</option>
              <option value="6">{{$t('activity_status_closed')}}</option>
            </b-select>
          </b-field>
        </div>

        <div class="w-full lg:w-1/4 mr-4" v-if="hasStatusList()">
          <b-field :label="$t('search_by_custom_status')">
            <b-select ref="input"
                      v-model="filterStatusId"
                      :placeholder="$t('custom_status')"
                      icon="traffic-light"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      expanded
            >

              <option :value="null">{{$t('all')}}</option>
              <option v-for="option in statusList"
                      :value="option['id']" :key="'status_'+option['id']"
              >
                {{ option['name'] }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>

      <div class="flex flex-col lg:flex-row items-center mb-4">
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_time_limit')">
            <b-select ref="input"
                      v-model="filterTimeLimit"
                      :placeholder="$t('activities_terms')"
                      icon="search"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      expanded
            >
              <option value="status_ok">{{$t('activity_time_status_ok')}}</option>
              <option value="status_warning">{{$t('activity_time_status_warning')}}</option>
              <option value="status_danger">{{$t('activity_time_status_danger')}}</option>
            </b-select>
          </b-field>
        </div>

        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('groups')">
            <treeselect
              v-model="group" :multiple="false"
              :options="groups"
              @input="applyFilters(1)"
            />
          </b-field>
        </div>

        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_type')">
            <b-select ref="input"
                      v-model="filterActivityType"
                      :placeholder="$t('activity_types')"
                      icon="search"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      expanded
            >
              <option v-for="option in typesList"
                      :value="option['id']"
                      :key="option['id']"
              >
                {{ option['name'] }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_favorite')">
            <b-select ref="input"
                      v-model="filterFavorite"
                      icon="star"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      expanded
            >
              <option value="">{{ $t('all') }}</option>
              <option value="1">{{ $t('favorites') }}</option>
              <option value="0">{{ $t('no_favorites') }}</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="flex flex-col lg:flex-row items-center mb-4">
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('filter_by_critical_path')">
            <b-select ref="input"
                      v-model="filterCriticalPath"
                      icon="project-diagram"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      expanded
            >
              <option value="">{{ $t('all') }}</option>
              <option value="1">{{ $t('critical_path') }}</option>
              <option value="0">{{ $t('critical_path_out') }}</option>
            </b-select>
          </b-field>
        </div>
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('filter_by_in_planning')">
            <b-select ref="input"
                      v-model="filterInPlanning"
                      @input="applyFilters(1)"
                      expanded
            >
              <option value="">{{ $t('all') }}</option>
              <option value="1">{{ $t('activities_in_planning') }}</option>
              <option value="0">{{ $t('activities_out_planning') }}</option>
            </b-select>
          </b-field>
        </div>
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('filter_by_planning_code')">
            <b-input
              v-model="filterPlanningCode"
              :placeholder="$t('planning_code')"
              type="search"
              icon="search"
              icon-pack="fas"
              @input="checkInputTextFilter(filterPlanningCode)">
            </b-input>
          </b-field>
        </div>
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_assignment_drawings')">
            <b-select ref="input"
                      v-model="filterDocumentId"
                      :placeholder="documentsList.length ? $t('drawings') : $t('there_are_no_drawings')"
                      icon="search"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      expanded
                      :disabled="documentsList.length == 0"
            >
              <option v-for="option in documentsList"
                      :value="option['id']"
                      :key="option['id']"
              >
                {{ option['code'] + (option['description'] == null ? '' : ' - ' + option['description']) }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="w-full mb-4">
        <filter-tags
          @filterList="filterByTags($event)"
          ref="filterTags"
        />
      </div>
      <button class="button is-primary btn-blue" @click="cleanFiltersClicked()">{{$t('clean_filters')}}</button>
    </div>
  </b-collapse>
  <span v-if="! showFilters && ! blankFilters" class="mx-2 font-bold cursor-pointer text-red" @click="cleanFiltersClicked()">
    {{$t('clean_filters')}} <b-icon icon="trash-alt" pack="fas" size="is-small" class="text-red"/>
  </span>
</div>
</template>

<script>
  import FilterTags from "·/components/filter-tags";
  import moment from "moment";
  import axios from "axios";
  import {mapGetters} from "vuex";
  import EventBus from '~/plugins/bus'

  // import the component
  import Treeselect from '@riophae/vue-treeselect'
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'

  export default {
    name: 'activity-filters',

    computed: {
      // ...mapGetters({
      //   project: 'app/project',
      // }),

      // true si todos los filtros estan vacios o con su valor por defecto
      blankFilters() {
        return this.filterName == ''
          && this.filterCreator == ''
          && this.filterManager == ''
          && this.filterParticipant == ''
          && this.filterTimeLimit == ''
          && this.filterLocationId == 0
          && this.filterDepartmentId == 0
          && this.filterDocumentId == 0
          && this.filterStatus == 100
          && this.filterStatusId == null
          && this.filterDateScheduledStart == null
          && this.filterDateScheduledEnd == null
          && this.filterDateCurrentStart == null
          && this.filterDateCurrentEnd == null
          && this.filterSubactivities == false
          && this.filterFavorite == ''
          && this.filterCriticalPath == ''
          && this.filterInPlanning == ''
          && this.filterPlanningCode == ''
          && this.group == null
          && this.tags.length == 0
          && this.filterActivityType == 0
        },
    },

    components: {
      FilterTags,
      Treeselect
    },

    props: {
      projectId: { type: Number, require:true }
    },

    data: () => ({
      showFilters: false,
      filterName: '',
      filterCreator: '',
      filterManager: '',
      filterParticipant: '',
      filterTimeLimit: '',
      filterLocationId: 0,
      filterDepartmentId: 0,
      filterDocumentId: 0, // Drawings
      filterStatus: 100,
      filterStatusId: null,
      filterDateScheduledStart: null,
      filterDateScheduledEnd: null,
      filterDateCurrentStart: null,
      filterDateCurrentEnd: null,
      filterSubactivities: false,
      filterFavorite: '',
      filterCriticalPath: '',
      filterInPlanning: '',
      filterPlanningCode: '',
      locationsList: [],
      departmentsList: [],
      documentsList: [],
      openedSubactivitiesId: 0,
      subactivities: [],
      statusList: [],
      tags: [],
      groups: [], // grupos de actividades
      group: null, // grupo de actividad seleccionada
      filterActivityType: 0,
      typesList: [], // listado tipos de actividad
    }),

    watch: {},

    mounted() {
      this.getStatusList();
      this.getLocationsList();
      this.getDepartmentsList();
      this.getDocumentsList();
      this.getGroupsList();
      this.getTypesList();
      this.getSavedFilters();
    },

    methods: {
      generateFilters() {
        let params = {p: this.projectId, s_num: 1};

        // Filtro por nombre
        if (this.filterName.length >= 3) {
          params.n = this.filterName
        } else if (this.filterName.length == 0) {
          params.n = null
        }
        //Filtro por creador
        if (this.filterCreator.length >= 3) {
          params.creator = this.filterCreator
        } else if (this.filterCreator.length == 0) {
          params.creator = null
        }
        //Filtro por responsable
        if (this.filterManager.length >= 3) {
          params.manager = this.filterManager
        } else if (this.filterManager.length == 0) {
          params.manager = null
        }
        //Filtro por participante
        if (this.filterParticipant.length >= 3) {
          params.participant = this.filterParticipant
        } else if (this.filterParticipant.length == 0) {
          params.participant = null
        }
        //Filtro por emplazamiento
        if (this.filterLocationId > 0) {
          params.location_id = this.filterLocationId
        }
        //Filtro por departamento
        if (this.filterDepartmentId > 0) {
          params.department_id = this.filterDepartmentId
        }
        //Filtro por documento (plano)
        if (this.filterDocumentId > 0) {
          params.document_id = this.filterDocumentId
        }
        //Filtro por estado
        if (this.filterStatus !== null) {
          params.status = this.filterStatus
        }
        //Filtro por plazo
        if (this.filterTimeLimit) {
          params.time_limit = this.filterTimeLimit
        }
        //Filtro por fecha programada inicio
        if (this.filterDateScheduledStart !== null) {
          params.filter_date_scheduled_start = moment(this.filterDateScheduledStart).format('YYYY-MM-DD')
        }
        //Filtro por fecha programada fin
        if (this.filterDateScheduledEnd !== null) {
          params.filter_date_scheduled_end = moment(this.filterDateScheduledEnd).format('YYYY-MM-DD')
        }
        //Filtro por fecha actual inicio
        if (this.filterDateCurrentStart !== null) {
          params.filter_date_current_start = moment(this.filterDateCurrentStart).format('YYYY-MM-DD')
        }
        //Filtro por fecha actual fin
        if (this.filterDateCurrentEnd !== null) {
          params.filter_date_current_end = moment(this.filterDateCurrentEnd).format('YYYY-MM-DD')
        }
        //Filtro por destacados
        if (this.filterFavorite && this.filterFavorite !== '') {
          params.filter_favorite = this.filterFavorite
        }
        //Filtro por camino crítico
        if (this.filterCriticalPath && this.filterCriticalPath !== '') {
          params.filter_critical_path = this.filterCriticalPath
        }
        if (this.filterInPlanning && this.filterInPlanning !== '') {
          params.filter_in_planning = this.filterInPlanning
        }
        //Filtro por código de planificación
        if (this.filterPlanningCode.length >= 3) {
          params.filter_planning_code = this.filterPlanningCode
        } else if (this.filterPlanningCode.length == 0) {
          params.filter_planning_code = null
        }

        // Filtro estado personalizado
        if (this.filterStatusId) {
          params.filter_status_id = this.filterStatusId
        }

        // Filtro grupo de actividades
        if (this.group) {
          params.group_id = this.group
        }

        // Solo tomar subactividades
        if (this.filterSubactivities) {
          params.filter_subactivities = 1
        }

        //Añadir el orden y número de elementos por página
        if (this.orderField) {
          params.order_field = this.orderField;
          params.order_dir = this.orderDir;
          params.per_page = this.perPage;
        }

        // Añadir siempre el filtro de parent_access para no añadir las subactividades dos veces
        params.parent_access = 1;

        // Añadir tags
        if (this.tags.length > 0) {
          params.tags = this.tags
        }

        //Filtro por tipo de actividad
        if (this.filterActivityType > 0) {
          params.activity_type_id = this.filterActivityType
        }

        // Guarda filtros en localstore para posteriores visitas
        this.$store.dispatch('searchFilters/setActivitiesFilters', params);

        return params
      },

      async getLocationsList() {
        let url = '/api/v2/locations?p=' + this.projectId;
        const {data} = await axios.get(url);
        if (data && data.success) {
          this.locationsList = data.locations
        } else {
          this.$notify.error('error_loading_locations')
        }
      },

      async getDepartmentsList() {
        let url = '/api/v2/project/' + this.projectId + '/departments';
        const {data} = await axios.get(url);
        if (data && data.success) {
          this.departmentsList = data.departments

        } else {
          this.$notify.error('error_loading_departments')
        }
      },

      /**
       * Carga los estados personalizados
       * @returns {Promise<void>}
       */
      async getStatusList() {
        let data = await axios.get('/api/v2/project/' + this.projectId + '/statuses', {params: this.params});

        if (data.data && data.data.success) {
          this.statusList = data.data.statuses;
          EventBus.$emit('statusListReceived', this.statusList);
        }
      },

      async getDocumentsList() {
        let params = {
          text: '',
          with: 'drawings'
        };

        const { data } = await axios.get(
          "/api/v2/project/" + this.projectId + "/documents",
          { params: params }
        );

        if (data && data.success) {
          this.documentsList = data.documents;
        } else {
          this.$notify.error("error_loading_extra_fields_data");
        }
      },

      async getGroupsList() {
        let params = {
          template: 'select'
        };

        const { data } = await axios.get(
          "/api/v2/project/" + this.projectId + "/activity/groups",
          { params: params }
        );

        if (data && data.success) {
          this.groups = data.groups;
        } else {
          this.$notify.error("error_loading_extra_fields_data");
        }
      },

      async getTypesList() {
        let url = '/api/v2/activity-types?p=' + this.projectId;
        const {data} = await axios.get(url);
        if (data && data.success) {
          this.typesList = data.data
        } else {
          this.$notify.error('error_loading_data')
        }
      },

      emptyManagerAndApplyFilters() {
        this.filterManager = "";
        this.checkInputTextFilter(this.filterParticipant)
      },

      emptyParticipantAndApplyFilters() {
        this.filterParticipant = "";
        this.checkInputTextFilter(this.filterManager)
      },

      applyFilters(page) {
        let args = this.generateFilters();
        this.$emit('filters-updated', args);
      },

      cleanFiltersClicked() {
        this.filterName = '';
        this.filterCreator = '';
        this.filterManager = '';
        this.filterParticipant = '';
        this.filterTimeLimit = '';
        this.filterLocationId = 0;
        this.filterDocumentId = 0;
        this.filterDepartmentId = 0;
        this.filterStatus = 100;
        this.filterStatusId = null;
        this.filterDateScheduledStart = null;
        this.filterDateScheduledEnd = null;
        this.filterDateCurrentStart = null;
        this.filterDateCurrentEnd = null;
        this.filterFavorite = '';
        this.filterCriticalPath = '';
        this.filterInPlanning = '';
        this.filterPlanningCode = '';
        this.page = 1; //TODO no deberíamos paginar a esta altura
        this.$refs.filterTags.resetTags();
        this.tags = [];
        this.group = null;
        this.filterActivityType = 0;
        this.$store.dispatch('searchFilters/resetActivitiesFilters');
        this.isLoadingTable = true;
        this.applyFilters(1); //TODO no deberíamos paginar a esta altura
      },

      checkInputTextFilter(filter) {
        if (filter.length >= 3 || filter.length == 0) {
          this.applyFilters(1)
        }
      },

      checkFilterSubactivities() {
        this.filterSubactivities = !this.filterSubactivities;
        this.isLoadingTable = true;
        this.applyFilters(1);
      },

      // Carga las tags recibidas y aplica el filtro
      filterByTags(tags) {
        this.isLoadingTable = true;
        this.tags = tags;
        this.applyFilters(1);
      },

      hasStatusList() {
        return this.statusList.length > 0;
      },

      getSavedFilters() {

        // Traer de local storage los posibles valores preguardados de filtros
        if (this.$store.getters["searchFilters/activities"]) {
          this.filterName = this.$store.getters["searchFilters/activities"].n || ''
          this.filterCreator = this.$store.getters["searchFilters/activities"].creator || ''
          this.filterManager = this.$store.getters["searchFilters/activities"].manager || ''
          this.filterParticipant = this.$store.getters["searchFilters/activities"].participant || ''
          this.filterLocationId = this.$store.getters["searchFilters/activities"].location_id || 0
          this.filterDepartmentId = this.$store.getters["searchFilters/activities"].department_id || 0
          this.filterDocumentId = this.$store.getters["searchFilters/activities"].document_id || 0
          this.filterStatus = this.$store.getters["searchFilters/activities"].status || 100
          this.filterStatusId = this.$store.getters["searchFilters/activities"].filter_status_id || null
          this.filterTimeLimit = this.$store.getters["searchFilters/activities"].time_limit || ''
          this.filterDateScheduledStart = this.$store.getters["searchFilters/activities"].filter_date_scheduled_start || null
          this.filterDateScheduledEnd = this.$store.getters["searchFilters/activities"].filter_date_scheduled_end || null
          this.filterDateCurrentStart = this.$store.getters["searchFilters/activities"].filter_date_current_start || null
          this.filterDateCurrentEnd = this.$store.getters["searchFilters/activities"].filter_date_current_end || null
          this.filterFavorite = this.$store.getters["searchFilters/activities"].filter_favorite || ''
          this.filterCriticalPath = this.$store.getters["searchFilters/activities"].filter_critical_path || ''
          this.filterInPlanning = this.$store.getters["searchFilters/activities"].filter_in_planning || ''
          this.filterPlanningCode = this.$store.getters["searchFilters/activities"].filter_planning_code || ''
          this.filterSubactivities = this.$store.getters["searchFilters/activities"].filter_subactivities || false
          this.filterActivityType = this.$store.getters["searchFilters/activities"].activity_type_id || 0
          this.tags = this.$store.getters["searchFilters/activities"].tags || []
          if (this.tags.length > 0) {
            // procesamiento para que aparezcan seleccionados los tags guardados
            let preSelectedTags = []
            this.tags.forEach(function(tagName) {
              preSelectedTags.push({text: tagName}) // el componente requiere un simple objeto con atributo 'txt' para mostrarlo
            })
            this.$refs.filterTags.syncTags(preSelectedTags);
          }

        }
      },

    },

  };
</script>

<style scoped>
  .filters-collapse {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .filters-trigger:hover {
    background-color: #f8fafc;
  }

  /* FIX TEMPORAL. UN PAQUETE (PROBABLEMENTE @BIMDATA/VIEWER) ESTA SOBREESCRIBIENDO LA CLASE .flex-col */
  @media (min-width: 1024px) {
    .lg\:flex-row {
      flex-direction: row;
    }
  }
</style>
