<template>
<div>
  <div v-if="false" class="flex flex-row justify-between text-sm uppercase align-middle text-blue-light">
    <div>
      <b-button
        @click="showModalAssignmentCertifiedExecution"
        @reload="() => reload()"
        v-if="canManageCertifiers"
        type="is-small is-primary"
        class="font-semibold uppercase">{{ $t("upload_certification") }}
      </b-button>
    </div>

    <div>
      <b-button
        @click="onClickUnlockMeasurementsButton"
        @reload="() => reload()"
        v-if="canManageCertifiers"
        type="is-small is-primary"
        class="font-semibold uppercase">
          <span v-if="assignmentsLocked">{{ $t("unlock_measurements") }}</span>
          <span v-else>{{ $t("lock_measurements") }}</span>
      </b-button>
    </div>

    <b-field>
      <b-tag v-if="assignmentsLocked" type="is-success">
          <span>{{ $t('blocked') }}</span>
      </b-tag>
      <b-tag v-else type="is-warning">
          <span>{{ $t('unlocked') }}</span>
      </b-tag>
    </b-field>
  </div>

  <div v-if="isLoading" class="my-4 mt-3 text-center">
    <scale-loader />
  </div>

  <div v-else class="block mt-3 text-sm uppercase  text-blue-light">
    <span v-if="pictogram" class="font-semibold basis-9">{{ _totalPictograms }}</span>
    <span>{{ !pictogram ? $t("no_certifiers_found") : ($t("pictogram") + ' ' + $t("linked")) }}</span>
    <button class="w-9 h-9 rounded-full bg-gray-200 float-right"
      type="button"
      @click="handleWorkMonitoring">
      <b-icon icon="solar-panel" pack="fas"></b-icon>
    </button>
  </div>

  <div class="my-4" v-if="pictogram">
    <pictograms-table
      :list-layers='_listLayers'
      :pictogram="pictogram"
      :activityId="activityId"
      :subactivityId="subactivityId"
      @epc-open-modal="handleOpenViewerPictogram"
    />
  </div>

  <konva-preview :ref="'konva_preview_modal'"
    :can-actions-pictogram="canActionsPictogram"
    @epc-reload="() => { $emit('epc-reload') }" />
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import PictogramsTable from './components/pictogram-table.vue'
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import KonvaPreview from './viewer-pixi/konva-preview-modal.vue';

export default {
  name: "pictogram-table-activity",

  components: {
    ScaleLoader,
    PictogramsTable,
    KonvaPreview
  },

  props: {
    pictogram: { type: Object, require: true },
    canManageCertifiers: { type: Boolean, default: false },
    assignmentsLocked: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    activityId: { type: Number, default: null },
    subactivityId: { type: Number, default: null }
  },

  data() {
    return {
      selected: undefined,
      selectedHistory: undefined,
      selectedSubHistory: undefined,

      canActionsPictogram: {
        'save': true,
        'select_entity': true
      }
    }
  },

  computed: {
    ...mapGetters({
      locale: "lang/locale",
      project: 'app/project',
    }),

    _listLayers() {
      if (!this.pictogram) return []

      // Primero añadimos propiedad children inicializada con array vacio, tambien sacamos avance general para cada layer desde
      // la propiedad this.pictogram.metadata
      // TODO: Talvez sea necesario clonar layers para no alterar el original
      this.pictogram.layers.forEach( (layer) => {

        if (this.pictogram.metadata['total_progress'] && this.pictogram.metadata['total_progress'][layer.code])
          layer.progress = this.pictogram.metadata['total_progress'][layer.code]
        else
          layer.progress = 0


        if (this.pictogram.metadata['statuses_progress'])
          layer.statuses_progress = this.pictogram.metadata['statuses_progress'][layer.code]
        else
          layer.statuses_progress = { "-1": this.pictogram.metadata['total_' + layer.code] }

        layer.total_execute = this.pictogram.metadata['total_' + layer.code]
        layer.children = []
      })

      // ordenamos la lista por order_column
      let listOrder = this.pictogram.layers.sort( function(a, b) {
        if (a.order_column < b.order_column) return -1;
        if (a.order_column > b.order_column) return 1;
        return 0;
      });

      // let tree = this.list_to_tree(listOrder);
      // // let listOnlyParent = listOrder.filter(function(item) { return item.code.indexOf('.') === -1 })  // solo optenemos los padres
      // // // debemos añadir los children a los padres si esque tienen para esto nos valemos del codigo
      // // listOnlyParent.forEach(function( item, index) {
      // //   item.subchapters = []
      // // })
      return listOrder//this.pictogram.layers
    },

    _totalPictograms() {
      return this.pictogram ? 1 : 0
    }
  },

  watch: {
  },
  created() {

  },
  mounted() {

  },

  methods: {
    displayDetails(rowId){
      this.selected = (this.selected == rowId) ? undefined : rowId;
    },

    displayHistory(rowId){

      this.selectedHistory = (this.selectedHistory == rowId) ? undefined : rowId;
    },

    displaySubHistory(rowId){

      this.selectedSubHistory = (this.selectedSubHistory == rowId) ? undefined : rowId;
    },

    calculateCertifierValues(item, type='pending'){

      if (item.lines) {
        if (type == 'pending')
          return ((item.total_quantity - item.executed_quantity) == 0)
          ? (item.total_quantity - item.executed_quantity).toFixed()
          : (item.total_quantity - item.executed_quantity).toFixed(2)

        return (item.executed_quantity == null || item.executed_quantity == 0) ? 0 : (item.executed_quantity * 1).toFixed(2)

      }

      return type == 'pending' ? item.total_units : 0;

    },

    onClickUnlockMeasurementsButton() {
      this.$emit('unlock-measurements');
    },

    showModalAssignmentCertifiedExecution() {
      this.$emit('show-execution-modal');
    },

    list_to_tree(list) {
      var map = {}, node, roots = [], i;

      for (i = 0; i < list.length; i += 1) {
        map[list[i].sortCode] = i; // initialize the map
        list[i].children = []; // initialize the children


      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        // obtenemos el codigo del padre
        let levels = node.sortCode.split('.');
        let totalLevels = levels.length
        // if (node.parentId !== "0") {
        if (totalLevels - 1 !== 0) { // si es cero es primer nivel
          levels.pop() // eliminamos el ulimo level
          let mapLevel = map[levels.join('.')];
          if( typeof mapLevel == 'undefined' ) {
            // si no existe el nivel antenemos al primer nivel de parentesto un cero
            levels[0] = "0" + levels[0];
            mapLevel = map[levels.join('.')];
          }

          list[mapLevel].children.push(node);
        } else {
          roots.push(node);
        }
      }
      return roots;
    },

    handleOpenViewerPictogram(e) {
      this.canActionsPictogram.save = true
      this.canActionsPictogram.select_entity = true
      this.$refs.konva_preview_modal.show(this.pictogram, e.code)
    },

    handleWorkMonitoring() {
      this.canActionsPictogram.save = false
      this.canActionsPictogram.select_entity = false
      this.$refs.konva_preview_modal.show(this.pictogram)
    }
  },
};
</script>

<style lang="scss" scoped>
table, tr {
  border: 1px solid #ADC2EA;
}
</style>
