<template>
  <div>
    <div class="w-full flex justify-end">
      <div class="flex">
        <v-button @click="create = !create;
          materialCreate.id = 0;
          materialCreate.name = '';
          materialCreate.qty = '';
          materialCreate.measure_id = '';
          materialCreate.description = '';
          materialCreate.code = '';"
          class="btn btn-blue rounded" icon="plus">{{ $t('add_material') }}
      </v-button>
      </div>
    </div>
    <div class="panel" v-if="create || materialCreate.id">
      <div>

        <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('create_material') }}</h2>
        <form @submit.prevent="createMaterial">
        <div class="w-full justify inline-flex">
          <b-field :label="$t('name')" class="md:w-1/5 mr-4">
            <b-input v-model="materialCreate.name" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('code')" class="md:w-1/5 mr-4">
            <b-input v-model="materialCreate.code" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('description')" class="md:w-1/5 mr-4">
            <b-input v-model="materialCreate.description" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('measure')" class="md:w-1/5 mr-4">
            <b-select :placeholder="$t('select_measure')" v-model="materialCreate.measure_id" required>
              <option value="number" v-for="measure in measures" :value="measure.id">{{ measure.name }}</option>
            </b-select>
          </b-field>
          <b-field :label="$t('qty')" class="md:w-1/5 mr-4">
            <b-input v-model="materialCreate.qty" class="w-full"></b-input>
          </b-field>
          <b-field label="  " class="md:w-1/5 mr-4 mt-4">
            <v-button class="btn btn-primary" nativeType="submit" icon="save" :disabled="!materialCreate.name || !materialCreate.code || !materialCreate.measure_id">{{ $t('save') }}</v-button>
          </b-field>
        </div>
      </form>
      </div>
    </div>
    <div class="panel">
      <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('material_list') }}</h2>
      <div class="panel">
        <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('filter') }}</h2>
        <form @submit.prevent="filterMaterial">
          <div class="w-full justify inline-flex">
            <b-field :label="$t('name')" class="md:w-1/5 mr-4">
              <b-input v-model="materialFilter.name" class="w-full"></b-input>
            </b-field>
            <b-field :label="$t('code')" class="md:w-1/5 mr-4">
              <b-input v-model="materialFilter.code" class="w-full"></b-input>
            </b-field>
            <b-field :label="$t('measure')" class="md:w-1/5 mr-4">
              <b-select :placeholder="$t('select_measure')" v-model="materialFilter.measure_id" required>
              <option value="number" v-for="measure in measures" :value="measure.id">{{ measure.name }}</option>
            </b-select>
            </b-field>
            <b-field :label="$t('description')" class="md:w-1/5 mr-4">
              <b-input v-model="materialFilter.description" class="w-full"></b-input>
            </b-field>
            <b-field :label="$t('qty')" class="md:w-1/5 mr-4">
              <b-input v-model="materialFilter.qty" class="w-full"></b-input>
            </b-field>
            <b-field label="  " class="md:w-1/5 mr-4 mt-4">
              <v-button class="btn btn-primary" nativeType="submit"><b-icon pack="fas" icon="search"/></v-button>
            </b-field>
          </div>
        </form>
      </div>

      <div class="w-full flex flex-wrap mt-4">
        <section class="w-full">
          <b-table
          :data="materials"
          :paginated="isPaginated"
          :per-page="perPage"
          :bordered="true"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :default-sort-direction="defaultSortDirection"
          v-if="loaded">
          <template slot-scope="props">
            <b-table-column field="name" :label="$t('name')" sortable width=100>
              {{ props.row.name }}
            </b-table-column>

            <b-table-column field="code" :label="$t('code')" sortable >
              {{ props.row.code }}
            </b-table-column>

            <b-table-column field="description" :label="$t('description')" sortable >
              {{ props.row.description }}
            </b-table-column>

            <b-table-column field="measure" :label="$t('measure')" v-if="props.row.measure != null" sortable>
              {{ props.row.measure.name }}
            </b-table-column>

            <b-table-column field="qty" :label="$t('qty')" sortable width=100>
              {{ props.row.qty }}
            </b-table-column>

            <b-table-column field="actions" :label="$t('actions')" sortable width=100 >
              <div class="justify inline flex">
                <button @click="getMaterial(props.row.id)"><b-icon pack="fas" icon="pencil-alt" type="is-primary" /></button>
              </div>
            </b-table-column>
          </template>
        </b-table>
      </section>
    </div>
  </div>
</div>
</template>

<script>
import Form from 'vform'
import axios from 'axios'

export default {
  middleware: 'auth',
  title: 'users_page_title',
  subtitle: 'users_page_subtitle',
  menuOption: '0-3',

  data: () => ({
    project_id: 0,
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 5,
    materials: [],
    measures: [],
    loaded: false,
    projects: null,
    create: false,

    materialFilter: new Form ({
      name: '',
      code: '',
      measure_id: '',
    }),
    materialCreate: new Form ({
      id: 0,
      name: '',
      qty: 0,
      description: '',
      code: '',
      measure_id: '',
    }),
  }),

  mounted () {
    this.project_id = parseInt(this.$route.params.project_id)
    this.getMeasures()
    this.getMaterials()
  },

  methods: {

    async filterMaterial () {
      if (! this.materialFilter) {
        return;
      }
      console.log(this.materialFilter)
      const url = '/api/v2/report/material/' + this.project_id + '/filter'
      const { data } = await this.materialFilter.post(url)
      if (data.data && data.success) {
        this.materials = data.data
      }
    },

    async createMaterial () {
      if (! this.materialCreate) {
        return;
      }
      var url
      if(this.materialCreate.id){
        url = '/api/v2/report/material/' + this.materialCreate.id
      }else{
        url = '/api/v2/report/material/' + this.project_id + '/add'
      }

      const { data } = await this.materialCreate.post(url)
      if (data.data && data.success) {
        this.materials = data.data
        this.materialCreate.name = ''
        this.materialCreate.code = ''
        this.materialCreate.qty = ''
        this.materialCreate.measure_id = ''
        this.materialCreate.description = ''
        if(this.materialCreate.id){
          this.getMaterials()
          // Hago esto pq extiende de la clase padre y no me retorna la lista completa de nuevo.
        }
        this.materialCreate.id = 0
        this.success('material_save')

      }else{
        this.error('error_creating_material')

      }
    },

    async getMaterials(){
      const url = '/api/v2/report/material/' + this.project_id + '/list'
      const { data } = await axios.get(url)
      if (data.data && data.success) {
        this.materials = data.data
        this.loaded = true
      } else {
        this.error('error_retreiving_materials')

      }
    },
    async getMeasures(){
      const url = '/api/v2/report/measure/' + this.project_id + '/list'
      const { data } = await axios.get(url)
      if (data.data && data.success) {
        this.measures = data.data
        console.log(this.measures,"measures")
      } else {
        this.error('error_retreiving_materials')

      }
    },

    async getMaterial(id){
      const url = '/api/v2/report/material/' + id
      const { data } = await axios.get(url)
      if (data) {
        //Object.assign(this.materialCreate,data.data)
        this.materialCreate.id = id
        this.materialCreate.name = data.name
        this.materialCreate.code = data.code
        this.materialCreate.measure_id = data.measure_id
        this.materialCreate.qty = data.qty
        this.materialCreate.description = data.description

      } else {
        this.error('error_retreiving_material')
      }
    },

    /*
     ALERTAS
     */
     success(message){
      this.$toast.open({
        message: message,
        type: 'is-success',
        position: 'is-top-right'
      })
    },
    error(message){
      this.$toast.open({
        message: message,
        type: 'is-danger',
        position: 'is-top-right'
      })
    }
  },
  watch:{

  }

};
</script>
