<template>
  <div>
    <templates-list-project />
  </div>
</template>

<script>
  import TemplatesListProject from '../../components/templates/project/template-projects-list.vue'
  export default {
    middleware: 'auth',
    title: 'project_templates_page_title',
    subtitle: 'project_templates_page_subtitle',
    menuOption: '0-6',

    components: {
      TemplatesListProject
    },

    computed: {
    },

    data: () => ({
    }),

    created() {
    },

    methods: {

    },

  };
</script>
