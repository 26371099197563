<template>
  <b-modal :active.sync="isModalActive" :width="1028" scroll="keep" :can-cancel="canCancel" @close="closeModal">

    <div class="border border-blue-light rounded shadow bg-white p-4">
      <form @submit.prevent="savePurchase" class="w-full">
        <header class="font-thin uppercase text-blue-light modal-card-head_ mb-4"
        >Añadir / editar material</header>

        <div class="modal-card-body_ relative">
            <div class="border-grey-light border-b border-dashed mb-3">

              <div class="w-full flex flex-row mb-3">
                <div class="w-2/5 mr-4">
                  <b-field :label="$t('material')">
                    <b-select  :placeholder="$t('select')" expanded>
                      <option v-for="material in products" :value="material.id" :key="'material_'+material.id">
                        {{ material.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div>

                <div class="w-2/5 mr-4">
                  <b-field :label="$t('quantity')">
                    <v-input
                      v-model="form.qty"
                      title="quantity"
                      required="true"
                      name="quantity"
                      placeholder="quantity"
                      maxlength="100"
                    />
                  </b-field>
                </div>

                <div class="w-2/5 mr-4">
                  <b-field :label="$t('price')">
                    <v-input
                      v-model="form.price"
                      title="price"
                      required="true"
                      name="price"
                      placeholder="price"
                      maxlength="100"
                    />
                  </b-field>
                </div>

                <!-- <div class="w-2/5 ml-4">
                  <b-field :label="$t('provider')">
                    <b-select v-model="form.provider_id" :placeholder="$t('select')" expanded>
                      <option v-for="provider in providers" :value="provider.id" :key="'provider_'+provider.id">
                        {{ provider.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div> -->
              </div>

              <div class="w-full flex flex-row mb-3">
                <div class="w-1/2 mr-4">
                  <b-field :label="$t('delivery_note')">
                    <v-input
                      v-model="form.delivery_note"
                      title="delivery_note"
                      required="true"
                      name="delivery_note"
                      placeholder="delivery_note"
                      maxlength="100"
                    />
                  </b-field>
                </div>
              </div>

              <div class="w-full flex flex-row mb-3">
                <div class="w-full">
                  <b-field :label="$t('description')">
                    <b-input
                      v-model="form.description"
                      title="description"
                      required="true"
                      name="description"
                      :placeholder="$t('description')"
                      maxlength="250"
                      type="textarea"
                    />
                  </b-field>
                </div>
              </div>

              <hr class="m-0">


              <b-loading :is-full-page="false" v-model="processingData" :can-cancel="false"></b-loading>
            </div>
        </div>
        <footer class="modal-card-foot_">
          <div class="w-full inline-flex justify-end">
            <!-- <v-button color="grey" icon="undo-alt" @click="isModalActive = false;">{{ $t('cancel') }}</v-button> -->
            <!-- <button class="button" size="is-small" type="button" @click="isModalActive = false; /*$emit('update:show', !show)*/">{{ $t('cancel') }}</button> -->
            <button class="btn btn-blue" nativeType="submit" v-if="!processingData">{{$t('save')}}</button>
            <v-button-processing v-if="processingData" />
          </div>
        </footer>
      </form>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Form from "vform";

export default {
  name: "material-purchase",

  components: {},

  props: {
    projectId: { type: Number, required: true }
  },

  data: () => ({
    isModalActive: false,
    processingData: false,
    canCancel: ['escape', 'x', 'outside'],
    data: null,
    form: {
      id: null,
      unit_id: null,
      provider_id: null,
      code: "",
      presto_code: "",
      name: "",
      unit_price: "0.00",
      purpose: "",
      delivery_note: "",
      description: "",
      stock: "0",
      stock_min: "0"
    },
    products: [],

    units: [],
    providers: []
  }),

  computed: {
      ...mapGetters({
        company: "app/company",
      }),
  },

  created() {
    this.getProducts()
  },

  methods: {


    async getProducts() {
      this.products = []

      const {data} = await axios.get("/api/v2/project/"+this.projectId+"/store-products")
      if (data && data.success) {
        this.products = data.products
      } else {
        this.$notify.error('error_retreiving_measures')
      }
    },

    async savePurchase() {
      let url = "/api/v2/project/"+this.projectId+"/store-purchase";

      if( this.form.id > 0 )
        url = "/api/v2/store-purchase/" + this.form.id;

      this.canCancel = [];
      this.processingData = true
      axios({
        method: "post",
        url: url,
        data: this.form
      })
        .then(response => {
          this.$toast.open({
            message: this.$t("save_successful"),
            type: "is-success",
            position: "is-top-right"
          });

          this.processingData = false
          this.canCancel = ['escape', 'x', 'outside']

          this.hide();
          this.$emit("reload-materials");
        })
        .catch(error => {
          //Se produjo un error
          this.isLoading = false;
          this.processingData = false
          this.canCancel = ['escape', 'x', 'outside']
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("save_error"),
            type: "is-danger",
            position: "is-top-right"
          });
        });
    },

    show(data) {
      this.data = data;
      this.form.id = data.id;
      this.form.material_id = data.material_id;
      // this.form.date_purchase = data.date_purchase;
      this.form.qty = data.qty.toString();
      this.form.price = data.price.toString();
      this.form.delivery_note = data.delivery_note;
      this.isModalActive = true;
    },

    hide() {
      this.data = null;
      this.isModalActive = false;
      this.form.id = null;
      this.form.material_id = null;

      this.form.qty = "0";
      this.form.delivery_note = "";
      this.form.price = 0.00;
    },

    closeModal() {
      this.$emit('update:show', !this.show)
    }
  }
};
</script>
<style scoped>
/* Oculta los botones para aumentar los inputs numéricos */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
