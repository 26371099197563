<template>
  <div class="top-bar flex justify-end">
    <div class="flex items-center text-white">
      <!-- Volver -->
      <!-- <b-button
        @click="$router.go(-1)"
        color="grey"
        size="is-small"
        icon-left="arrow-left"
        icon-pack="fas"
        class="mr-8"
      >
        {{ $t('back') }}
      </b-button> -->

      <!-- Editar Proyecto -->
      <v-button-icon-round
        @click="push({name: 'project-template.edit'})"
        icon="pencil-alt"
        tooltip="edit_project"
        tooltip-position="bottom"
        class="mr-1"
        :color="activeDisplay('edit')"
      />

      <!-- Configurar Status Kanban -->
      <v-button-icon-round
        @click="push({name: 'project-template.manage-statuses'})"
        icon="traffic-light"
        tooltip="manage_statuses"
        tooltip-position="bottom"
        class="mr-1"
        :color="activeDisplay('manage-statuses')"
      />

      <!-- Issue Types -->
      <v-button-icon-round
        @click="push({name: 'project-template.admin.issue-types'})"
        icon="exclamation-triangle"
        tooltip="issues"
        tooltip-position="bottom"
        class="mr-1"
        :color="activeDisplay('issues')"
      />

      <!-- Activity Groups -->
      <v-button-icon-round
        @click="push({name: 'project-template.admin.activity-groups'})"
        icon="layer-group"
        tooltip="activity_groups"
        tooltip-position="bottom"
        class="mr-1"
        :color="activeDisplay('activity-groups')"
      />

      <!-- Chapters -->
      <v-button-icon-round
        @click="push({name: 'project-template.admin.chapters'})"
        icon="stream"
        tooltip="chapters"
        tooltip-position="bottom"
        class="mr-1"
        :color="activeDisplay('chapters')"
      />

      <!-- Importar/Exportar -->
      <v-button-icon-round
        @click="push({name:'project-template.import-export-activities'})"
        icon="file-excel"
        :tooltip="'import_export_activities'"
        tooltip-position="left"
        class="mr-1"
        :color="activeDisplay('import_export_activities')"
      />

      <!-- Mediciones -->
      <v-button-icon-round
        @click="push({name: 'project-template.admin.measurements'})"
        icon="tasks"
        tooltip="measurements"
        tooltip-position="bottom"
        class="mr-1"
        :color="activeDisplay('assignments')"
      />

      <!-- Siguiente -->
      <!-- <b-button
        @click="$router.go(-1)"
        color="grey"
        size="is-small"
        icon-right="arrow-right"
        icon-pack="fas"
        class="ml-8"
      >
        {{ $t('next') }}
      </b-button> -->
    </div>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: 'project-template-admin-quick-buttons',

    computed: {
      ...mapGetters({
        navData: "app/navData",
      })
    },

    components: {},

    props: {hiddenOption: {type: String, required: true}},

    data: () => ({}),

    watch: {},

    mounted() {
    },

    methods: {
      activeDisplay(option) {
        return option !== this.hiddenOption ? 'grey' : 'blue';
      },

      push(args) {
        this.$store.dispatch('app/updateNavData', {
          project_id: this.navData.project_id,
          project_name: this.navData.project_name
        });

        args.project_id = this.navData.project_id;
        args.project_name = this.navData.project_name;
        this.$router.push(args);
      },

    },

  };
</script>
