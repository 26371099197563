<template>
  <div>
    <div>
      <!-- Tabla de Asignaciones-->
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInUp"
        leave-active-class="animated fadeOutDown"
      >
        <div class="panel">
          <section>
            <b-table
              show-empty
              :data="data"
              :loading="isLoading"

              paginated
              backend-pagination
              :per-page="perPage"
              :total="total"
              @page-change="onPageChange"

              backend-sorting
              :default-sort="orderField"
              :default-sort-direction="orderDir"
              @sort="onSort"

              detailed
              detail-key="id"
              :has-detailed-visible="displaySubactivitiesOrAssignments"
              :opened-detailed="[this.openedSubactivitiesId]"
              @details-open="openDetails"
              @details-close="(row, index) => this.openedSubactivitiesId = 0"

              striped
              bordered
              icon-pack="fas"
              :row-class="getRowClass"
            >
              <template>
                <b-table-column field="code" :label="$t('code')" sortable v-slot="props">{{ props.row.code }}</b-table-column>

                <b-table-column field="name" :label="$t('name')" v-slot="props">{{ props.row.name}}</b-table-column>

                <b-table-column field="tags" :label="$t('tags')" v-slot="props">{{ jointags(props.row.tagged) }}</b-table-column>

                <b-table-column field="weight" :label="$t('weight')" v-slot="props">{{ props.row.weight }}</b-table-column>

                <b-table-column
                  field="actions"
                  :label="$t('actions')"
                  centered
                  width="80" v-slot="props"
                  :visible="hideActions"
                >
                  <div class="w-full h-full flex items-center justify-around">

                    <b-tooltip :label="$t('edit')" position="is-left" v-if="!loadMeasurements">
                      <div @click="editEntity(props.row)" class="cursor-pointer flex text-blue">
                        <b-icon pack="fas" icon="pencil-alt" />
                      </div>
                    </b-tooltip>

                    <b-tooltip :label="$t('delete')" type="is-danger" position="is-left" v-if="!loadMeasurements">
                      <div @click="removeEntity(props.row)" class="cursor-pointer flex text-red">
                        <b-icon pack="fas" icon="trash-alt" />
                      </div>
                    </b-tooltip>

                    <b-tooltip :label="$t('add_subactivity')" position="is-left" v-if="!loadMeasurements">
                      <div @click="addSubEntity(props.row)" class="cursor-pointer flex text-blue">
                        <b-icon pack="fas" icon="plus-circle" />
                      </div>
                    </b-tooltip>

                    <b-tooltip :label="$t('add_measurement_title')" position="is-left" v-if="loadMeasurements">
                      <div @click="addMeasurement(props.row)" class="cursor-pointer flex text-blue">
                        <b-icon pack="fas" icon="plus-circle" />
                      </div>
                    </b-tooltip>

                  </div>

                </b-table-column>
              </template>

              <template slot="detail" slot-scope="activities">

                <!--Mediciones -->
                <div class="mb-1" v-if="loadMeasurements">
                  <div>
                    <h4>
                      <b>{{ $t('measurements') }}</b>
                    </h4>
                  </div>

                  <template-list-measurements
                    :list="activities.row.assignments"
                    :entityId="activities.row.id"
                    :hideActions="hideActions"
                    @reload="reloadActivities"
                    @epc-edit-measurement="editMeasurement"
                  />
                </div>

                <!-- Subactividades -->
                <div v-if="displaySubactivities(activities.row)">
                  <div>
                    <h4>
                      <b>{{ activities.row.subactivities_num }} {{
                          activities.row.subactivities_num > 1 ?
                            $t('subactivities') : $t('subactivity')
                        }}</b>
                    </h4>
                  </div>

                  <template>
                    <b-table
                      ref="subactivities"
                      :data="getSubactivities(activities.row)"
                      default-sort="notifications"
                      default-sort-direction="desc"
                      bordered
                      class="text-xs border-blue-lighter bg-grey-lighter subactivities"
                      narrowed

                      detailed
                      detail-key="id"
                      icon-pack="fas"
                      :has-detailed-visible="displaySubactivitiesOrAssignments"

                    >

                      <template>
                        <b-table-column
                          field="code"
                          :label="$t('code')"
                          sortable v-slot="props">{{ props.row.code }}</b-table-column>

                        <b-table-column field="name" cell-class="vertical-center" sortable>
                          <template v-slot:header="">
                            {{ $t('name') }}
                          </template>
                          <template v-slot="subactivities_info">
                            {{ subactivities_info.row.name }}
                          </template>
                        </b-table-column>

                        <b-table-column field="tags" :label="$t('tags')" v-slot="props">{{ jointags(props.row.tagged) }}</b-table-column>

                        <b-table-column field="weight" :label="$t('weight')" v-slot="props">{{ props.row.weight }}</b-table-column>

                        <b-table-column
                          field="actions"
                          :label="$t('actions')"
                          centered
                          width="80" v-slot="props"
                          :visible="hideActions"
                        >
                          <div class="w-full h-full flex items-center justify-around">

                            <b-tooltip :label="$t('edit')" position="is-left">
                              <div @click="editEntity(props.row)" class="cursor-pointer flex text-blue" v-if="!loadMeasurements">
                                <b-icon pack="fas" icon="pencil-alt" />
                              </div>
                            </b-tooltip>

                            <b-tooltip :label="$t('delete')" type="is-danger" position="is-left" v-if="!loadMeasurements">
                              <div @click="removeEntity(props.row)" class="cursor-pointer flex text-red">
                                <b-icon pack="fas" icon="trash-alt" />
                              </div>
                            </b-tooltip>

                            <b-tooltip :label="$t('add_measurement_title')" position="is-left" v-if="loadMeasurements">
                              <div @click="addMeasurement(props.row)" class="cursor-pointer flex text-blue">
                                <b-icon pack="fas" icon="plus-circle" />
                              </div>
                            </b-tooltip>
                          </div>
                        </b-table-column>

                      </template>

                      <template slot="detail" slot-scope="child">
                      <!--Mediciones -->
                      <div class="mb-1" v-if="loadMeasurements">
                        <div>
                          <h4>
                            <b>{{ $t('measurements') }}</b>
                          </h4>
                        </div>

                        <template-list-measurements
                          :list="child.row.assignments" :entityId="child.row.id"
                          :entityBlockedAssignments="child.row.blocked_assignments == 1"
                          :hideActions="hideActions"
                          @reload="reloadSubActivities"
                          @epc-edit-measurement="editMeasurement"

                        />
                      </div>
                    </template>
                    <template #empty>
                      <div class="has-text-centered">{{ $t('no_results') }}</div>
                    </template>
                    </b-table>
                  </template>
                </div>
              </template>

              <template #empty>
                <div class="has-text-centered">{{ $t('no_results') }}</div>
              </template>
            </b-table>
          </section>

        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import TemplateListMeasurements from './template-list-measurements.vue';

export default {
  name: "template-list-activities",

  components: {
    TemplateListMeasurements
  },

  props: {
    projectId: { type: Number, required: true },
    hideActions: { type: Boolean, default: true },
    loadMeasurements: { type: Boolean, default: false },
  },

  data: () => ({
    data: [],
    isLoading: true,
    page: 1,
    perPage: 15,
    total: 0,
    orderField: "reference_code",
    orderDir: "asc",

    showSubs: false,
    openedSubactivitiesId: 0,
    subactivities: [],
  }),

  watch: {

  },

  mounted() {
    this.getProjectActivities();
  },

  methods: {
    async getProjectActivities() {
      this.isLoading = true;
      let params = {
        p: this.projectId,
        order_dir: this.orderDir,
        order_field: this.orderField
      };
      const { data } = await axios.get(
        "/api/v2/activity/templates",
        { params: params }
      );
      if (data && data.success) {
        this.data = data.activities;
        this.total = data.activities ? data.activities.length : 0;
      } else {
        this.$notify.error("error_loading_activities");
      }
      this.isLoading = false;
    },

    reload() {
      this.getProjectActivities();
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    onPageChange(page) {
      this.page = page;
      this.reload();
    },

    onSort(field, order) {
      this.orderField = field;
      this.orderDir = order;
      this.reload();
    },

    editEntity(entity) {
      this.$emit('epc-edit-entity', entity);
    },

    async removeEntity(activity) {
      this.$dialog.confirm({
        title: this.$t("delete_activity"),
        message: this.$t("delete_activity_confirm_text", [activity.name]),
        confirmText: this.$t("delete_activity"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true;
          let url = "/api/v2/activity/templates/" + activity.id + "/delete";
          const { data } = await axios.post(url);

          if (data.success) {
            this.$toast.open({
              message: this.$t("removed_successfully"),
              type: "is-success",
              position: "is-top-right"
            });

            // TODO: Cuando se trabaja de manera concurrente no es eficiente, mejor  usar reaload
            let index = this.data.findIndex( (item) => { return item.id == activity.id } );
            if( index > -1 )
              this.data.splice(index, 1);
            this.isLoading = false;

            // await this.sleep(1000);
            // this.reload(); //Descomentar si se quiere volver a recargar todo haciendo llamada desde el servidor
          } else {
            this.isLoading = false;
            // Error
            this.$toast.open({
              message: this.$t("remove_error"),
              type: "is-danger",
              position: "is-top-right"
            });
          }
        }
      });
    },

    addSubEntity(entity) {
      this.$emit('epc-add-sub-entity', entity);
    },

    addMeasurement(entity) {
      this.$emit('epc-add-measurement', entity);
    },

    jointags(tags) {
      return tags.map( (tag) => { return tag.tag_name } ).join(',')
    },

    showSubactivities(activityId) {
      this.showSubs = (!this.showSubs);
    },

    displaySubactivities(row) {
      return !row.isSubactivity && row.subactivities_num > 0
    },

    displayAssignments(row) {
      return (row.assignments && row.assignments.length > 0);
    },

    displaySubactivitiesOrAssignments(row) {
      return !!(this.displayAssignments(row) || this.displaySubactivities(row));
    },

    openDetails(row, index) {
      if (typeof this.subactivities[row.id] === 'undefined') {
        this.isLoadingTable = true;
        let self = this;
        let url = '/api/v2/activity/templates/' + row.id + '/subactivities';
        let params = {with_assignments: 1}; // TODO:  quitar si no hace falta
        axios.get(url, {params: params}).then(function (response) {
          if (response.data && response.data.success) {
            self.subactivities[row.id] = response.data.subactivities
          }
          self.isLoadingTable = false;
          self.openedSubactivitiesId = row.id
        })
      } else {
        self.isLoadingTable = false;
        self.openedSubactivitiesId = row.id
      }
    },

    getSubactivities(row) {
      return this.subactivities[row.id]
    },

    getRowClass(row, index) {
      let cssClass = '';
      // if (row.parent_access && !this.filterSubactivities) {
      //   cssClass = 'is-hidden'
      // } else if (row.subactivities_notifications) {
      //   cssClass = cssClass + ' sub-notifications'
      // }

      return cssClass
    },

    reloadActivities() {
      this.reload()
    },

    reloadSubActivities() {
      this.reload()
    },

    editMeasurement(measurement) {
      console.log('se emitira edit measure of list activitires')
      this.$emit('epc-edit-measurement', measurement)
    }
  }
};
</script>

<style lang="scss">
.disabled-subactivity {
  background: #F8FAFC !important;
  color: #B8C2CA !important;
}
</style>
