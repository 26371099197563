<template>
  <div class="page-with-top-bar">
    <project-admin-quick-buttons hiddenOption="locations"/>
    <section>
      <div class="mt-4">
        <b-tabs type="is-boxed" v-model="mainActiveTab">
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="map-marker-alt"></b-icon>
              <span> {{ $t('locations_list') }} </span>
            </template>
            <div>
              <project-locations-manual
                ref="project_locations_manual"
                :project-id="projectId"
                :is-admin-locations="true"
              />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-import"></b-icon>
              <span> {{ $t('import_locations') }} </span>
            </template>
            <div>
              <import-entities
                :can-overwrite="true"
                :project-id="projectId"
                entity-type="locations"
              />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-export"></b-icon>
              <span> {{ $t('export_locations') }} </span>
            </template>
            <div>
              <export-locations
                :project-id="projectId"
              />
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import ProjectLocationsManual from '../../components/project-locations-manual'
  import ExportLocations from '../../components/export-locations'
  import ImportEntities from "../../components/import-entities";
  import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons'

  export default {
    middleware: 'auth',
    title: 'locations_page_title',
    menuOption: '0-2',

    components: {
      ImportEntities,
      ProjectLocationsManual,
      ExportLocations,
      ProjectAdminQuickButtons
    },

    computed: {
      ...mapGetters({
        navData: "app/navData",
      }),
      projectId() {
        return parseInt(this.navData.project_id)
      },
    },

    data: () => ({
      mainActiveTab: 0
    }),

    watch: {
      mainActiveTab(value) {
        if (value == 0) {
          this.$refs.project_locations_manual.reload()
        }
      }
    },

    created() {
      let projectName = this.navData.project_name
        ? this.navData.project_name.toUpperCase()
        : "";
      this.$options.subtitle = ["project_actions_page_subtitle", [projectName]];
    },

    methods: {}
  };
</script>
