<template>
  <div class="flex flex-col w-full">
    <div class="flex items-center justify-between w-full">
      <div class="font-semibold text-black">{{ _GetTitle() }}</div>
      <div>
        <b-tooltip :label="$t('close')" position="is-left" :active="! processingData">
          <div class="flex items-center justify-center w-8 h-8 rounded-full text-grey-lightest"
            :class="{ 'cursor-pointer bg-red': !processingData, 'bg-gray-300': processingData }"
            @click="_HandleCloseClicked">
            <b-icon pack="fas" icon="times" size="is-small" />
          </div>
        </b-tooltip>
      </div>
    </div>

    <div v-if="'aerial_evacuation_line' === typeLine" class="mt-4">
      <!-- Cantidad de tramos de la linea de evacuación aérea -->
      <div class="mb-2">
        <div class="text-xs font-medium capitalize text-blue">Cantidad de tramos</div>
        <div class="flex flex-row items-center">
          <b-field>
            <b-numberinput v-model="totalSections"
              title="total_sections"
              controls-position="compact"
              icon-pack="fas"
              :required="true"
              name="sections"
              step="1"
              min="1"
              @focus="$event.target.select()"
            >
          </b-numberinput>
        </b-field>
        </div>
      </div>

      <div class="mt-4">
        <div class="w-full">
          <div class="text-xs font-medium capitalize text-blue">Prefijo de cada nodo</div>
          <!-- <label form="node_prefix" class="">Prefijo de cada nodo</label> -->
          <b-field>
            <v-input
              v-model="nodePrefix"
              title="node_prefix"
              type="text"
              :required="true"
              name="node_prefix"
              placeholder="Prefijo"
            />
          </b-field>
        </div>
      </div>

      <div class="mt-4">
        <div class="w-full pb-3">
          <div class="text-xs font-medium capitalize text-blue">Inicio de la numeración del nodo</div>
          <div class="flex flex-row items-center">
            <b-field>
              <b-numberinput v-model="startNodeNumberingAt"
                title="start_node"
                controls-position="compact"
                icon-pack="fas"
                :required="true"
                name="start_node"
                step="1"
                min="1"
                @focus="$event.target.select()"
              >
              </b-numberinput>
            </b-field>
          </div>
        </div>
      </div>
    </div>

    <!-- sino es linea de evacuacion aerea es enterrada -->
    <div v-else class="mt-4">

      <!-- Longitud de la linea de evacuación enterrada -->
      <div class="mb-2">
        <div class="text-xs font-medium capitalize text-blue">Longitud de la linea de evacuación</div>
        <div class="flex flex-row items-center">
          <b-field>
            <b-numberinput v-model="longitudLine"
              title="total_sections"
              controls-position="compact"
              icon-pack="fas"
              :required="true"
              name="sections"
              step="1"
              min="1"
              @focus="$event.target.select()"
            >
          </b-numberinput>
        </b-field>
        </div>
      </div>

      <div class="mb-2">
        <div class="text-xs font-medium capitalize text-blue">Unidad de medida</div>
        <div class="flex flex-row items-center">
          <b-field>
            <b-select v-model="unit"
              placeholder="Elija una unidad de medida"
              expanded
              :required="true">
                <option value="m">Metro</option>
                <option value="km">Kilometros</option>
            </b-select>
          </b-field>
        </div>
      </div>

      <div class="mb-2">
        <div class="text-xs font-medium capitalize text-blue">Total de segmentos</div>
        <div class="flex flex-row items-center">
          <b-field>
            <b-numberinput v-model="totalSections"
              title="total_sections"
              controls-position="compact"
              icon-pack="fas"
              :required="true"
              name="sections"
              step="1"
              min="1"
              max="150"
              @focus="$event.target.select()"
            >
          </b-numberinput>
        </b-field>
        </div>
      </div>

      <div class="mb-2">
        <div class="w-full">
          <div class="text-xs font-medium capitalize text-blue">Origen de la linea de evacuación</div>
          <!-- <label form="node_prefix" class="">Prefijo de cada nodo</label> -->
          <b-field>
            <v-input
              v-model="nameNodeStart"
              title="node_start"
              type="text"
              :required="true"
              name="node_start"
              placeholder="Nombre origen"
            />
          </b-field>
        </div>
      </div>

      <div class="mb-2">
        <div class="w-full">
          <div class="text-xs font-medium capitalize text-blue">Destino de la linea de evacuación</div>
          <!-- <label form="node_prefix" class="">Prefijo de cada nodo</label> -->
          <b-field>
            <v-input
              v-model="nameNodeEnd"
              title="node_end"
              type="text"
              :required="true"
              name="node_end"
              placeholder="Nombre destino"
            />
          </b-field>
        </div>
      </div>

    </div>

    <div class="flex flex-col w-full mt-4">
      <v-button-processing v-if="processingData" />

      <div v-else class="w-full flex flex-col lg:flex-row">
        <b-button :label="'Generar linea de evacuación'"
          type="is-info"
          icon-pack="fas"
          icon-left="table-cells"
          class="w-full"

          @click="_HandleClickGenerateDesign" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },

  props: {
    typeLine: { type: String, require: true }
  },

  data () {
    return {
      processingData: false, // bandera para indicar que se esta procesando algo

      totalSections: null,
      nodePrefix: null,
      startNodeNumberingAt: null,

      // para la linea de evacuacion enterrada
      longitudLine: null,
      unit: null,
      nameNodeStart: null,
      nameNodeEnd: null

    }
  },

  mounted() {
  },

  methods: {
    async _HandleClickGenerateDesign() {
      let pictogram = {}
      let dataLineBuried = null

      if( this.typeLine === 'aerial_evacuation_line') { // tratamos la linea de evacuación aérea
        // TODO: poner un validador de campos

        pictogram = this._GenerateAirEvacuationLine()

      } else { // tratamos linea de evacuación enterrada (buried)
        pictogram = this._GenerateBuriedEvacuationLine()
        dataLineBuried = {
          line_length: this.longitudLine,
          unit: this.unit
        }
      }

      this.$emit('epc-evacuation-line-preview', { scene: pictogram, layers: this._GetLayers(), dataLineBuried })
    },

    _HandleCloseClicked() {
      if (!this.processingData)
        this.$emit('epc-pictogram-data-close')
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    _GenerateAirEvacuationLine() {
      let pictogram = {
        entities: []
      }

      let posX = 20
      let posY = 50
      let initWidth = 120
      let initHeight = 20

      for (let index = 0; index < this.totalSections; index++) {
        posX += (index > 0) ? (initWidth - 2 + (20 * 2)) : 0

        pictogram.entities.push({
          id: index + 1,
          name: 'section ' + (index + 1),
          x: posX,
          y: posY,
          width: initWidth,
          height: initHeight,
          layer: 'Sections',
          type: 'sections',
          status: null,
          so: null,
          parent: null,
          user: null
        })
      }

      posX = 20
      posY = 60

      for (let index = 0; index < this.totalSections - 1; index++) {
        posX += initWidth - (index > 0 ? 2 : 1) + (20 * (index > 0 ? 2 : 1))

        pictogram.entities.push({
          id: pictogram.entities.length + index + 1,
          name: this.nodePrefix + ' ' + (this.startNodeNumberingAt + index),
          x: posX,
          y: posY,
          radius: 20,
          layer: 'Nodes',
          type: 'nodes',
          status: null,
          so: null,
          parent: null,
          user: null
        })
      }

      return pictogram
    },

    _GenerateBuriedEvacuationLine() {
      let pictogram = {
        entities: []
      }

      let posX = 59
      let posY = 50
      const lineWidth = 1500
      const lineHeight = 20

      pictogram.entities.push({
        id: 1,
        name: this.nameNodeStart,
        x: 40,
        y: 60,
        radius: 20,
        layer: 'Nodes',
        type: 'nodes',
        status: null,
        so: null,
        parent: null,
        user: null
      })

      pictogram.entities.push({
        id: 2,
        name: this.nameNodeEnd,
        x: 59 + lineWidth + 20,
        y: 60,
        radius: 20,
        layer: 'Nodes',
        type: 'nodes',
        status: null,
        so: null,
        parent: null,
        user: null
      })


      let segmentWidth = lineWidth / this.totalSections

      for (let index = 0; index < this.totalSections; index++) {

        posX += (index > 0 ? segmentWidth : 0)

        pictogram.entities.push({
          id: index + 3,
          name: 'section ' + (index + 1),
          x: posX,
          y: posY,
          width: segmentWidth,
          height: lineHeight,
          layer: 'Sections',
          type: 'sections',
          status: null,
          so: null,
          parent: null,
          user: null
        })
      }

      posX = 59

      pictogram.entities.push({
        id: pictogram.entities.length + 1,
        name: 'border',
        points: [ posX, posY, posX + 1 + lineWidth, posY, posX + 1 + lineWidth, posY + 20, posX, posY + 20 ],
        width: lineWidth,
        height: lineHeight,
        layer: 'no layer',
        type: 'border_line',
        status: null,
        so: null,
        parent: null,
        user: null
      })


      return pictogram
    },

    _GetTitle() {
      return 'Nueva ' + this.$t(this.typeLine)
    },

    /**
     * _GetLayers retorna los layers que hacen referencia a los layer manejador por nosotros, no a los que estan definidos en el
     * DXF de momento lo harcodeamos, porque son las que por defecto deben crearse y generarse, pero en el futoro hay que retocar
     * esta parte para adicionar otros dinamicamente
     * Propiedad create_entities indica si se van a crear las entidades al momento de generar el parser del DXF, ademas puede usarse
     * para identificar si se inserta en la tabla pictogram_layers (Si es muy ambiguo crear la propiedad adecuada y ajustar)
     * Propiedad in_design indica que viene en el diseño y no hay la necesidad de crear las entidades
     * Propiedad icon es el icono que representara el layer por defecto seria null
     */
     _GetLayers() {
      let lyrs = [
        {
          code: 'sections',
          name: 'Tramos',
          icon: 'window-minimize',
          order: 0,
          quantity: this.totalSections,
          total_entities: this.totalSections,
          create_entities: true,
          in_design: false
        }
      ]

      if (this.typeLine === 'aerial_evacuation_line') {
        lyrs.push({
          code: 'nodes',
          name: 'Nodos',
          icon: 'circle',
          order: 1,
          quantity: this.totalSections - 1,
          total_entities: this.totalSections - 1,
          create_entities: true,
          in_design: false
        })
      }

      return lyrs
    },

    // Realizamos un aplastado para guardar en un array cuyo indice es el id de de la entidad para facilitar
    // en el servidor la busqueda
    _FlattenEntities() {
      let o = {}
      for (const entity of this._GetEntities2()) {
        o[entity.id] = entity
      }
      return o
    },

    *_GetEntities2() {
      for ( const entity of this.data.scene.entities) {
        yield entity
      }
    },
  }
}
</script>
