<template>
  <div class="w-full">
    <!-- Candidad de ejes de la estructura, es introducido manualmente -->
    <div class="mb-2">
      <div class="text-xs font-medium capitalize text-blue">Cantidad de ejes de la estructura</div>
      <div class="flex flex-row items-center">
        <b-field class="mb-0 w-1/2"
          :type="{ 'is-danger' : errors.axles } "
          :message="{ 'La cantidad es invalida' : errors.axles  }">
          <b-numberinput
            v-model="form.total_axles"
            controls-position="compact"
            :disabled="disableFields"
            size="is-small"
            min="1"
            max="3"
            icon-pack="fas"
            @input="(value) => { errors.axles = value < 1 && value > 3 }" />
        </b-field>

        <span class="ml-1 text-xs text-black">Eje(s) por estructura</span>
      </div>
    </div>

    <!-- campo total de paneles por estructura, es introducido manualmente -->
    <div class="mb-2">
      <div class="text-xs font-medium capitalize text-blue">{{ $t("total_pilings") }}</div>
      <div class="flex flex-row items-center">
        <b-field class="mb-0 w-1/2"
          :type="{ 'is-danger' : errors.pilings } "
          :message="{ 'La cantidad es invalida' : errors.pilings  }">
          <b-numberinput
            v-model="form.total_pilings"
            controls-position="compact"
            :disabled="disableFields"
            size="is-small"
            icon-pack="fas"
            @input="(value) => { errors.pilings = value <= 0 }" />
        </b-field>

        <span class="ml-1 text-xs text-black">(Hincados (pilotes) por estructura)</span>
      </div>
    </div>

    <!-- campo total de hincados (pilotes), es introducido manualmente -->
    <div class="mb-2">
      <div class="text-xs font-medium capitalize text-blue">{{ $t("total_modules") }}</div>

      <div class="flex flex-row items-center">
        <b-field class="mb-0 w-1/2"
          :type="{ 'is-danger' : errors.modules } "
          :message="{ 'La cantidad es invalida' : errors.modules  }">
          <b-numberinput
            v-model="form.total_modules"
            controls-position="compact"
            :disabled="disableFields"
            size="is-small"
            icon-pack="fas"
            @input="(value) => { errors.modules = value <= 0 }" />
        </b-field>
        <span class="ml-1 text-xs text-black">(Modulos por estructura)</span>
      </div>
    </div>

    <!-- campo total de paneles, es introducido manualmente -->
    <div class="mb-2">
      <div class="text-xs font-medium capitalize text-blue">{{ $t("total_panels") }}</div>

      <div class="flex flex-row items-center">
        <b-field class="mb-0 w-1/2"
          :type="{ 'is-danger' : errors.panels } "
          :message="{ 'La cantidad es invalida' : errors.panels  }">
          <b-numberinput
            v-model="form.total_panels"
            controls-position="compact"
            :disabled="disableFields"
            size="is-small"
            icon-pack="fas"
            @input="(value) => { errors.panels = value <= 0 }" />
        </b-field>
        <span class="ml-1 text-xs text-black">(Paneles por modulo)</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "fields-design-form",

  components: {
  },

  props: {
    disableFields: { type: Boolean, default: false }
  },

  data: () => ({
    form: {
      total_axles: 1, // por defecto es de un eje la estructura
      total_pilings: null,
      total_modules: null,
      total_panels: null,
    },

    errors: {
      axles: false,
      pilings: false,
      modules: false,
      panels: false,
    },
  }),

  created() {
  },

  computed: {
  },

  methods: {
    getDataForm() {
      if (this._ValidateForm()) {
        return this.form
      }

      return null
    },

    _ValidateForm() {
      this.errors.axles = this.form.total_axles < 1 || this.form.total_axles > 3
      this.errors.pilings = this.form.total_pilings == null || this.form.total_pilings <= 0
      this.errors.modules = this.form.total_modules == null || this.form.total_modules <= 0
      this.errors.panels = this.form.total_panels == null || this.form.total_panels <= 0

      if ( ! this.errors.axles && ! this.errors.pilings && ! this.errors.modules && ! this.errors.panels ) {
        return true
      }

      this.$toast.open({
        message: 'campos marcados obligatorios',
        type: "is-danger",
        position: "is-top-right",
      });

      return false;
    },

  },
};
</script>
