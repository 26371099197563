<template>
<div>
  <div class="flex flex-row justify-between text-sm uppercase align-middle text-blue-light">
    <div>
      <b-button
        @click="showModalAssignmentCertifiedExecution"
        @reload="() => reload()"
        v-if="canManageCertifiers"
        type="is-small is-primary"
        class="font-semibold uppercase">{{ $t("upload_certification") }}
      </b-button>
    </div>

    <div>
      <b-button
        @click="onClickUnlockMeasurementsButton"
        @reload="() => reload()"
        v-if="canManageCertifiers"
        type="is-small is-primary"
        class="font-semibold uppercase">
          <span v-if="assignmentsLocked">{{ $t("unlock_measurements") }}</span>
          <span v-else>{{ $t("lock_measurements") }}</span>
      </b-button>
    </div>

    <b-field>
      <b-tag v-if="assignmentsLocked" type="is-success">
          <span>{{ $t('blocked') }}</span>
      </b-tag>
      <b-tag v-else type="is-warning">
          <span>{{ $t('unlocked') }}</span>
      </b-tag>
    </b-field>
  </div>

  <div v-if="isLoading" class="my-4 mt-3 text-center">
    <scale-loader />
  </div>

  <div v-else class="mt-3 text-sm uppercase align-middle text-blue-light">
    <span v-if="chaptersCount" class="font-semibold">{{ chaptersCount }}</span> {{ !chaptersCount ? $t("no_certifiers_found") : $t("certifiers_under_execution") }}
  </div>

  <div class="flex items-center my-4" v-if="_listChapters.length">
    <chapters-table
      :list-chapters='_listChapters'
    />
  </div>

</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChaptersTable from './components/chapters-table'
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  name: "chapter-table-activity",

  components: {
    ChaptersTable,
    ScaleLoader
  },

  props: {
    listChapters: { type: Array, require: true },
    canManageCertifiers: { type: Boolean, default: false },
    assignmentsLocked: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    chaptersCount: { type: Number, default: 0 },
  },

  data() {
    return {
      selected: undefined,
      selectedHistory: undefined,
      selectedSubHistory: undefined,
    }
  },

  computed: {
    ...mapGetters({
      locale: "lang/locale",
      project: 'app/project',
    }),
    _listChapters() {
      // primero añadimos un atributo por el cual ordenar (sortCode), para no alterar la estructura ni el formato
      this.listChapters.forEach((element) => {
        let codes = element.code.split('.')
        if( parseInt(codes[0]) < 10 )
          element.sortCode = '0' + codes.join('.')
        else
          element.sortCode = codes.join('.')

        // Si un capitulo tiene children, pueden ser mediciones certifiers que cuelgan dentro.
        // A esas posibles mediciones hijas añadimos el codigo del padre + '.01' para que cuelguen del padre
        element.children = element.children.map(function(elto){
          elto.sortCode = element.sortCode + '.01';
          return elto;
        })

        // Aplanar. Si hay hijos, sacar cada elemento hijo para colgarlo fuera del raiz 'listChapters'
        // y sea procesado por el 'list_to_tree' que lo metera bajo el padre gracias al sortCode anterior
        // **** Desde que cambiamos a cargar por niveles, no entra en este IF, porque devolvemos children vacio. Pero si se devuelven funcionará todo ***
        if (element.children.length) {
          let hasCertifiers = false

          element.children.forEach( (childElement) => {
            this.listChapters.push(childElement);
            if (childElement.type == 'certifier') {
              hasCertifiers = true;
            }
          })

          // eliminamos este atributo ya innecesario, ya cuelgan fuera
          delete element['children']

          // indicamos que los hijos de este nodo son certificadoras, para cuando se pinte el recuadro, ponga como titulo 'Certificadoras'
          element.hasCertifiers = hasCertifiers;
        }

      });

      // ordenamos la lista por sortcode
      let listOrder = this.listChapters.sort( function(a, b) {
        if (a.sortCode < b.sortCode) return -1;
        if (a.sortCode > b.sortCode) return 1;
        return 0;
      });

      let tree = this.list_to_tree(listOrder);
      // let listOnlyParent = listOrder.filter(function(item) { return item.code.indexOf('.') === -1 })  // solo optenemos los padres
      // // debemos añadir los children a los padres si esque tienen para esto nos valemos del codigo
      // listOnlyParent.forEach(function( item, index) {
      //   item.subchapters = []
      // })

      return tree
    }
  },

  watch: {
  },
  created() {

  },
  mounted() {

  },

  methods: {
    displayDetails(rowId){

      this.selected = (this.selected == rowId) ? undefined : rowId;
    },

    displayHistory(rowId){

      this.selectedHistory = (this.selectedHistory == rowId) ? undefined : rowId;
    },

    displaySubHistory(rowId){

      this.selectedSubHistory = (this.selectedSubHistory == rowId) ? undefined : rowId;
    },

    calculateCertifierValues(item, type='pending'){

      if (item.lines) {
        if (type == 'pending')
          return ((item.total_quantity - item.executed_quantity) == 0)
          ? (item.total_quantity - item.executed_quantity).toFixed()
          : (item.total_quantity - item.executed_quantity).toFixed(2)

        return (item.executed_quantity == null || item.executed_quantity == 0) ? 0 : (item.executed_quantity * 1).toFixed(2)

      }

      return type == 'pending' ? item.total_units : 0;

    },

    onClickUnlockMeasurementsButton() {
      this.$emit('unlock-measurements');
    },

    showModalAssignmentCertifiedExecution() {
      this.$emit('show-execution-modal');
    },

    list_to_tree(list) {
      var map = {}, node, roots = [], i;

      for (i = 0; i < list.length; i += 1) {
        map[list[i].sortCode] = i; // initialize the map
        list[i].children = []; // initialize the children


      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        // obtenemos el codigo del padre
        let levels = node.sortCode.split('.');
        let totalLevels = levels.length
        // if (node.parentId !== "0") {
        if (totalLevels - 1 !== 0) { // si es cero es primer nivel
          levels.pop() // eliminamos el ulimo level
          let mapLevel = map[levels.join('.')];
          if( typeof mapLevel == 'undefined' ) {
            // si no existe el nivel antenemos al primer nivel de parentesto un cero
            levels[0] = "0" + levels[0];
            mapLevel = map[levels.join('.')];
          }

          list[mapLevel].children.push(node);
        } else {
          roots.push(node);
        }
      }
      return roots;
    },
  },
};
</script>

<style lang="scss" scoped>
table, tr {
  border: 1px solid #ADC2EA;
}
</style>
