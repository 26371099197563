<template>
  <div class="panel">
    <div class="w-full flex items-center justify-between mb-8">
      <div class=" uppercase text-blue-light font-thin text-lg">
        {{ $t('materials') }}
      </div>
      <v-button-icon-round
        icon="plus"
        tooltip="add_material"
        tooltip-position="left"
        color="blue"
        @click="addIssueButtonClicked(null)"
      />

      <register-out
        ref="register_out_modal"
        :projectId="projectId"
        :entityId="entityId"
        :entity-type="entityType"
        @reload-list="handleSuccess"
      />
    </div>

    <!-- Tabla de productos -->
    <div>

      <store-outs ref="outs_list"
        :projectId="projectId"
        :entityId="entityId"
        :entityType="entityType"
        :withPanelFilter="false"
        :withClassWrapperTable="false"
        :propColumns="configColumns"
      />

    </div>

  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import axios from 'axios'
  import StoreOuts from './outs.vue'
  import RegisterOut from './out-entity-modal.vue'

  export default {

    components: {
      StoreOuts,
      RegisterOut
    },

    props: {
      projectId: {type: Number, require: true },
      entityId: {type: Number, require: true },
      entityType: {type: String, require: true },
    },

    computed: {
      ...mapGetters({
        company: "app/company",
      }),
    },

    data: function() {
      return {
        isLoadingTable: true,
        outs: [],
        perPage: 20,
        orderField: 'name',
        orderDir: 'asc',
        page: 1,
        total: 0,

        configColumns: {
          code: { title: 'code', display: true },
          material: { title: 'material', display: true },
          provider: { title: 'provider', display: true },
          date_out: { title: 'date_out', display: true },
          qty: { title: 'quantity', display: true },
          activity: { title: 'activity', display: false },
          user: { title: 'user', display: false },
          actions: { title: 'actions', display: false },
        }
      }
    },

    created() {
    },

    methods: {
      handleSuccess() { // recibe la entidad editada
        this.$refs.outs_list.reload()
      },

      addIssueButtonClicked(entity) {
        let storeout = {
          id: 0,
          product_id: null,
          provider_id: null,
          entity_id: null,
          date_out: null, //new Date(),
          qty: 0,
          price: 0.00,
          delivery_note: "",
        };

        if(entity != null)
        {
          storeout.id = entity.id
          storeout.product_id = entity.inventory.product_id
          storeout.provider_id = entity.inventory.provider_id
          storeout.entity_id = entity.storeable_id
          storeout.date_out = entity.date_out
          storeout.qty = entity.qty
          storeout.price = entity.price
          storeout.delivery_note = entity.delivery_note
        }

        this.$refs.register_out_modal.show(storeout);
      },
    },

  };
</script>
