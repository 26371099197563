<template>
  <div class="page-with-top-bar">

    <div class="top-bar flex justify-end">
      <div class="flex items-center text-white">
        <!-- Importar usuarios -->
        <v-button-icon-round
                @click="$router.push({name: 'admin.users.import'})"
                icon="file-import"
                tooltip="import_users"
                tooltip-position="left"
                class="mr-1"
        />
      </div>
    </div>

    <div class="w-full flex justify-between mt-4 below-top-bar">
      <div class="flex">

      </div>
      <div class="flex">
        <button @click="collaboratorPage = !collaboratorPage" class="btn btn-blue rounded-full" :disabled="!collaboratorPage">
          <b-icon icon="users" pack="fas"/>
        </button>
        <button @click="collaboratorPage = !collaboratorPage" class="btn btn-blue rounded-full" :disabled="collaboratorPage">
          <b-icon icon="address-book" pack="fas"/>
        </button>
      </div>
      <div class="flex">
        <template v-if="!collaboratorPage">
          <router-link
            to="/admin/user/"
            class="btn btn-blue rounded"><b-icon icon="user" pack="fas"/>
            {{ $t('add_user') }}
          </router-link>
        </template>
        <template v-else>
          <router-link
            to="/admin/collaborator/"
            class="btn btn-blue rounded"><b-icon icon="address-book" pack="fas"/>
            {{ $t('add_collaborator') }}
          </router-link>
        </template>
      </div>
    </div>
    <div class="panel">

      <form @submit.prevent="usersFilter">
        <div class="w-full justify flex">
          <b-field class="w-1/5 mt-8 mr-4">
            <b-select v-model="userFilter.active" rounded icon="sort" @input="usersFilter">
              <option value="all">{{ $t('all') }}</option>
              <option value="active">{{ $t('active_users') }}</option>
              <option value="inactive">{{ $t('inactive_users') }}</option>
            </b-select>
          </b-field>

          <b-field :label="$t('search_by_name')" class="w-1/5 mr-4">
            <b-input v-model="userFilter.name"></b-input>
          </b-field>
          <b-field :label="$t('seacrh_by_surname')" class="w-1/5 mr-4">
            <b-input v-model="userFilter.surname"></b-input>
          </b-field>
          <b-field :label="$t('search_by_email')" class="w-1/5 mr-4">
            <b-input v-model="userFilter.email"></b-input>
          </b-field>
          <b-field :label="$t('search_by_area')" class="w-1/5 mr-4">
            <b-select placeholder="select_area" v-model="userFilter.area" expanded>
              <option
              v-for="option in areas"
              :value="option.id"
              :key="option.id">
              {{ option.name }}
            </option>
          </b-select>
        </b-field>

      <b-field label="" class="w-1/5 mt-4">
        <v-button class="btn btn-primary" nativeType="submit"><b-icon icon="search" pack="fas"/></v-button>
      </b-field>
    </div>
  </form>
  <div class="w-full flex flex-wrap mt-4">
    <section class="w-full">
      <b-table
      icon-pack="fas"
      :data="users"
      :paginated="isPaginated"
      :per-page="perPage"
      :bordered="true"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :default-sort-direction="defaultSortDirection"
      v-if="loaded">
      <template>
        <b-table-column field="avatar" label="Avatar" width="40" v-slot="props">
          <figure class="media-left">
            <p class="image is-50x50">
              <img :src="props.row.avatar">
            </p>
          </figure>
        </b-table-column>

        <b-table-column field="name" :label="$t('name')" sortable v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="surname" :label="$t('surname')" sortable v-slot="props">
          {{ props.row.surname }}
        </b-table-column>

        <b-table-column field="email" :label="$t('email')" sortable v-slot="props">
          {{ props.row.email }}
        </b-table-column>

        <b-table-column field="phone" :label="$t('phone')" sortable v-slot="props">
          {{ props.row.phone }}
        </b-table-column>

        <b-table-column field="area" :label="$t('area')" sortable v-slot="props">
          <template v-if="props.row.area">
            {{ props.row.area.name }}
          </template>
        </b-table-column>

        <b-table-column :label="$t('active')" width=100 v-slot="props">
          <div class="w-full h-full flex items-center justify-around">
            <b-icon v-show="props.row.active" pack="fas" class="flex text-green" icon="check"></b-icon>
            <b-icon v-show="! props.row.active" pack="fas" class="flex text-red" icon="times"></b-icon>
          </div>
        </b-table-column>

      <b-table-column field="actions" :label="$t('actions')" width=100 sortable v-slot="props">
          <div class="w-full h-full flex items-center justify-around">

          <template v-if="!collaboratorPage">
              <router-link :to="'/admin/user/' + props.row.id" class="flex pr-0">
                <b-tooltip :label="$t('edit_user')" type="is-primary" position="is-left">
                  <div class="cursor-pointer flex text-blue">
                    <b-icon icon="pencil-alt" pack="fas" />
                  </div>
                </b-tooltip>
            </router-link>
          </template>
          <template v-if="collaboratorPage">
              <router-link :to="'/admin/collaborator/' + props.row.id" class="flex pr-0">
                <b-tooltip :label="$t('edit_user')" type="is-primary" position="is-left">
                  <div class="cursor-pointer flex text-blue">
                    <b-icon icon="pencil-alt" pack="fas"/>
                  </div>
                </b-tooltip>
            </router-link>
          </template>
        </div>
      </b-table-column>
    </template>
    <template slot="bottom-left">
      <div v-show="userFilter.active === 'all'">
        <b-icon pack="fas" class="flex text-blue" icon="user"></b-icon>
        <b>{{ $t("total_users") }}</b>: {{ this.users.length }}
      </div>
      <div v-show="userFilter.active === 'active'">
        <b-icon pack="fas" class="flex text-green" icon="check"></b-icon>
        <b>{{ $t("total_active_users") }}</b>: {{ this.users.length }}
      </div>
      <div v-show="userFilter.active === 'inactive'">
        <b-icon pack="fas" class="flex text-red" icon="times"></b-icon>
        <b>{{ $t("total_inactive_users") }}</b>: {{ this.users.length }}
      </div>
    </template>
    <template slot="empty">No results</template>
  </b-table>
</section>
</div>
</div>
</div>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import api from '~/api'

export default {
  middleware: 'auth',
  title: 'users_page_title',
  subtitle: 'users_page_subtitle',
  menuOption: '0-3',

  data: () => ({
    projectID: 0,
    ready: false,
    showInactive: false,
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 20,
    users: [],
    loaded: false,
    areas: null,
    projects: null,
    collaboratorPage: false,
    activeUsersFilter: 'active',
    userFilter: new Form ({
      name: '',
      surname: '',
      email: '',
      area: 0,
      project:0,
      collaborator: false,
      active: 'active',
    }),
  }),

  mounted () {
    let self = this
    this.projectID = parseInt(this.$route.params.project_id)
    api.fetchUserAreas().then(data => { self.areas = data.areas })
    this.usersFilter()
  },

  methods: {

    async usersFilter () {
      if (! this.userFilter) {
        return;
      }
      const { data } = await this.userFilter.post('/api/v2/users/filter')
      if (data.data && data.success) {
        this.users = []
        this.users = data.data
        this.loaded = true
      }
    },

    async getProjects(){
      const url = '/api/v2/project/' + this.area + '/areas'
      const { data } = await axios.get(url)
      if (data.data) {
        this.projects = data.data
      }
    },
    emptyFilter(){
      this.userFilter.name = ''
      this.userFilter.email = ''
      this.userFilter.area = 0
      this.userFilter.project = 0
    }
  },
  watch:{
    collaboratorPage: function(){
      this.emptyFilter()
      if(this.collaboratorPage){
        let pageInfo = {
          title: this.$t('collaborators_page_title'),
          subtitle: this.$t('collaborators_page_subtitle'),
        }
        this.$store.dispatch('app/updatePageInfo', { info: pageInfo })
        this.userFilter.collaborator = true
        this.usersFilter()
      }else{
        let pageInfo = {
          title: this.$t('users_page_title'),
          subtitle: this.$t('users_page_subtitle'),
        }
        this.$store.dispatch('app/updatePageInfo', { info: pageInfo })
        this.userFilter.collaborator = false
        this.usersFilter()
      }
    },
  }

};
</script>
