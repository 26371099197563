<template>
  <b-modal :active.sync="isModalActive" :width="640" scroll="keep">
    <div class="border border-blue-light rounded shadow bg-white" v-if="status">
      <header class="w-full bg-grey-lightest px-4 text-xl font-thin uppercase text-blue-light">
        {{ $t('edit_status') }}
      </header>
      <div class="w-full px-8 pb-8 pt-6 bg-grey-lightest">
        <form @submit.prevent="updateStatus" class="w-full">
          <div class="border-grey-light border-b border-dashed mb-3">

            <div class="w-full inline-flex pb-3">
              <div class="w-full md:w-1/2 inline-flex">
                <label form="code" class="md:w-1/4">{{ $t('code')}}</label>
                <v-input
                  v-model="form.code"
                  title="code"
                  type="text"
                  required="true"
                  name="code"
                  placeholder="code"
                  class="md:w-3/4"
                />
              </div>
            </div>

            <div class="w-full inline-flex pb-3">
              <div class="w-full md:w-1/2 inline-flex">
                <label form="name" class="md:w-1/4">{{ $t('name')}}</label>
                <v-input
                  v-model="form.name"
                  title="name"
                  type="text"
                  required="true"
                  name="name"
                  placeholder="name"
                  class="md:w-3/4"
                />
              </div>
            </div>

            <div class="w-full inline-flex justify-end pb-3">
              <button
                class="btn btn-blue"
                nativeType="submit"
              >
                {{$t('edit')}}
              </button>
            </div>

          </div>
        </form>

        <div class="content">
          {{ $t('last_update') + ': '}}
          <strong>
            {{ new Date(status.updated_at).toLocaleDateString() + ' ' + new
            Date(status.updated_at).toLocaleTimeString() }}
          </strong>
        </div>

      </div>
    </div>

  </b-modal>
</template>

<script>
  import Form from 'vform'

  export default {
    name: "status-edit-modal",

    components: {},

    props: {
      entityType: { type: String, required: true }
    },

    data: () => ({
      isModalActive: false,
      status: null,
      form: new Form({
        code: '',
        name: '',
      }),
    }),

    methods: {

      /**
       * Actualiza el estado llamando a la API
       */
      async updateStatus() {

        this.form.entity_type = this.entityType
        const {data} = await this.form.post('/api/v2/status/' + this.status.id);

        if (data.success) {

          this.$toast.open({
            message: this.$t('status_change_success'),
            type: 'is-success',
            position: 'is-top-right'
          });

          this.hide();
          this.$emit("reload");

        } else {

          this.isLoading = false;
          let msg = data.error_msg ? data.error_msg : this.$t('status_change_fail');

          // Error
          this.$toast.open({
            message: msg,
            type: 'is-danger',
            position: 'is-top-right'
          })
        }
      },

      /**
       * Muestra el modal
       * @param status
       */
      show(status) {
        this.status = status;
        this.form.code = status.code;
        this.form.name = status.name;
        this.isModalActive = true;
      },

      /**
       * Oculta el modal
       */
      hide() {
        this.status = null;
        this.isModalActive = false;
        this.form.name = '';
      },

    }
  };
</script>
<style scoped>
  /* Oculta los botones para aumentar los inputs numéricos */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance: textfield; /* Firefox */
  }

</style>
