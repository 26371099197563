<template>
  <div>
    <div class="top-bar flex justify-end">
      <v-button
        @click="showCreate = !showCreate"
        icon="plus">
        Nueva Especificación Técnica
      </v-button>
    </div>
    <div>
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInUp"
        leave-active-class="animated fadeOutDown">
        <div class="panel">

          <div class="w-full flex flex-wrap" v-show="showCreate">
            <div class="panel">
              <form @submit.prevent="create" class="w-full flex">
                <div class="w-full">
                  <div class="flex">
                    <div class="w-1/2 pr-3">
                      <label for="spec_type" class="w-full">{{ $t('select_type') }}</label>
                      <b-select ref="input"
                        v-model="item.spec_type_id"
                        :placeholder="$t('select_type')"
                        id="spec_type"
                        name="spec_type"
                        class="w-full"
                        required
                        expanded
                      >
                        <option v-for="option in spectTypeList" :value="option['id']" :key="option['id']">
                          {{ option['name'] }}
                        </option>
                      </b-select>
                    </div>

                    <div class="w-1/2 pr-3">
                      <label for="scope" class="w-full">{{ $t('select_scope') }}</label>
                      <b-select ref="input"
                        v-model="item.scope"
                        :placeholder="$t('select_scope')"
                        id="scope"
                        name="scope"
                        class="w-full"
                        required
                        expanded
                      >
                        <option v-for="(option, index) in scopeList" :value="option" :key="index">
                          {{ option }}
                        </option>
                      </b-select>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="w-1/3 pr-3">
                      <label for="code" class="w-full">{{ $t('code') }}</label>
                      <v-input
                        v-model="item.code"
                        title="code"
                        id="code"
                        name="code"
                        placeholder="code"
                        class="w-full"
                        required
                      />
                    </div>

                    <div class="w-1/3 pr-3">
                      <label for="name" class="w-full">{{ $t('name') }}</label>
                      <v-input
                        v-model="item.name"
                        title="name"
                        id="name"
                        name="name"
                        placeholder="name"
                        class="w-full"
                        required
                      />
                    </div>

                    <div class="w-1/3 pr-3">
                      <label for="order_column" class="w-full">{{ $t('order') }}</label>
                      <v-input
                        v-model="item.order_column"
                        title="order_column"
                        id="order_column"
                        name="order_column"
                        placeholder="order"
                        class="w-full"
                        required
                        type="number"
                      />
                    </div>
                  </div>
                </div>


                <div class="self-end">
                  <v-button
                    native-type="submit"
                    :disabled="!item.name || !item.code || !item.spec_type_id"
                  >{{ $t('save') }}
                  </v-button>
                </div>
              </form>
            </div>
          </div>

          <b-table
            show-empty
            :data="data"
            :loading="isLoading"
            paginated
            striped
            bordered
            icon-pack="fas"
          >
            <template v-slot:empty="">
              <h4>{{ $t('no_rows') }}</h4>
            </template>

            <template>
              <b-table-column field="code" >
                <template v-slot:header="">
                  {{ $t('code') }}
                </template>
                <template v-slot="props">
                  {{ props.row.code }}
                </template>
              </b-table-column>

              <b-table-column field="name" >
                <template v-slot:header="">
                  {{ $t('name') }}
                </template>
                <template v-slot="props">
                  {{ props.row.name }}
                </template>
              </b-table-column>

              <b-table-column field="scope" >
                <template v-slot:header="">
                  {{ $t('scope') }}
                </template>
                <template v-slot="props">
                  {{ props.row.scope }}
                </template>
              </b-table-column>

              <b-table-column field="specification_type" >
                <template v-slot:header="">
                  {{ $t('specification_type') }}
                </template>
                <template v-slot="props">
                  {{ props.row.specification_type.name }}
                </template>
              </b-table-column>

              <b-table-column field="order_column" >
                <template v-slot:header="">
                  {{ $t('order') }}
                </template>
                <template v-slot="props">
                  {{ props.row.order_column }}
                </template>
              </b-table-column>

              <b-table-column field="actions"  centered width="80">
                <template v-slot:header="">
                  {{ $t('actions') }}
                </template>
                <template v-slot="props">

                  <div class="w-full h-full flex items-center justify-around">
                      <div>
                        <b-tooltip :label="$t('edit')" position="is-left">
                          <div @click="edit(props.row)" class="cursor-pointer flex text-blue">
                            <b-icon pack="fas" icon="pencil-alt"/>
                          </div>
                        </b-tooltip>
                        <b-tooltip
                          :label="$t('remove')"
                          type="is-danger"
                          position="is-left"
                        >
                          <div @click="remove(props.row)" class="cursor-pointer flex text-red">
                            <b-icon pack="fas" icon="trash-alt"/>
                          </div>
                        </b-tooltip>
                      </div>

                  </div>
                </template>
              </b-table-column>
            </template>
          </b-table>

        </div>
      </transition>

      
      <technical-specification-edit-modal
        ref="technical_specification_edit_modal"
        :specification-types="spectTypeList"
        :scope-list="scopeList"
        @reload="() => reload()"
      />
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import TechnicalSpecificationEditModal from './technical-specification-edit-modal'

  export default {
    name: 'technical-specification',

    components: {
      TechnicalSpecificationEditModal
    },

    data: () => ({
      data: [],
      spectTypeList: [],
      scopeList: ['AT', 'GTI'],
      isLoading: true,
      showCreate: false,
      item: {
        spec_type_id: null,
        scope: 'AT',
        code: "",
        name: "",
        order_column: 0
      },
    }),

    props: {
      projectId: {type: Number, required: true},
      entityType: {type: String, default: 'project'}
    },

    mounted() {
      this.getData();
      this.getTypes();
      this.isLoading = false;
    },

    methods: {
      async getTypes() {
        let {data} = await axios.get('/api/v2/technical-specification-types', {params: {p: this.projectId}});

        if (data.success) {
          this.spectTypeList = data.data
        } else {
          this.$notify.error("error_loading_data");
        }
      },

      async getData() {
        let {data} = await axios.get('/api/v2/technical-specifications', {params: {p: this.projectId}});

        if (data.success) {
          this.data = data.data
        } else {
          this.$notify.error("error_loading_data");
        }
      },

      reload() {
        this.isLoading = true;
        this.getData();
        this.getTypes();
        this.isLoading = false;
      },

      async create() {
        this.item.project_id = this.projectId

        this.isLoading = true;
        let {data} = await axios.post('/api/v2/technical-specification', this.item)
        if (data.success) {
          this.$notify.success('save_successful')
          this.getData();
          this.item.code = null
          this.item.name = null
          this.item.order_column = 0
        } else {
          this.$notify.error('error_saving')
        }
        this.isLoading = false;
      },

      async edit(technicalSpecification) {
        this.$refs.technical_specification_edit_modal.show(technicalSpecification)
      },

      async remove(technicalSpecification) {
        if (technicalSpecification.flows_count) {
          this.$toast.open({
            message: 'No se puede eliminar porque tiene flujos asociados',
            type: "is-danger",
            position: "is-top-right"
          });
          return
        }
        
        this.$dialog.confirm({
          title: this.$t("delete"),
          message: this.$t("delete_confirm_text", ['la especificación técnica', technicalSpecification.name]),
          confirmText: this.$t("delete"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            this.isLoading = true;
            let url = `/api/v2/technical-specification/${technicalSpecification.id}`;
            const { data } = await axios.delete(url);

            if (data.success) {
              this.$toast.open({
                message: this.$t("removed_successfully"),
                type: "is-success",
                position: "is-top-right"
              });

              this.getData();
            } else {
              // Error
              this.$toast.open({
                message: this.$t("remove_error"),
                type: "is-danger",
                position: "is-top-right"
              });
            }
            this.isLoading = false;
          }
        });
      }
    }
  };
</script>
