<template>
<transition-group
  name="custom-classes-transition"
  :enter-active-class="show ? 'animated zoomIn' : null"
  :leave-active-class="show ? 'animated zoomOut' : null"
  tag="ul"
  class="list-reset"
  style="min-height:3rem">
  <li
    v-for="(user, index) in usersList"
    :key="user.user_id"
    class="float-left pb-2"
    :class="{
      'w-full': boxFullWidth,
      'w-full lg:w-1/2': !boxFullWidth,
      'lg:pr-1': !boxFullWidth && (index % 2 === 0),
      'lg:pl-1': !boxFullWidth && (index % 2 !== 0),
      }">
    <div
      :class="[
        (authUser.id === user.user_id) ? 'bg-blue-lightest' : 'bg-grey-lightest',
        isSuperiorHierarchyUser(user.user_id) ? 'sup-user' : ''
      ]"
      class="w-full border border-blue-lighter rounded flex items-center px-2"
      style="height:38px;line-height:33px">
      <img
        :src="user.avatar"
        class="rounded-full overflow-hidden"
        style="width:28px;height:28px">
      <span class="flex-grow text-xs font-semibold text-blue-light truncate px-2">
        {{ user.fullname }}
      </span>

      <user-replacement
        v-show="! disabled && canReplace"
        :userListIndex="index"
        @replaceButtonClicked="onClickReplaceButton($event)"
      />

      <button v-show="showRemoveButton(index) && ! isSuperiorHierarchyUser(user.user_id)"
        @click="onClickRemoveButton(index)"
        @mouseover="iconRemoveHovered = index"
        @mouseleave="iconRemoveHovered = null"
        class="flex items-center cursor-pointer">
        <b-icon v-show="!isHoverRemoveIcon(index)"
          pack="far"
          icon="trash-alt"
          class="text-grey float w-full h-full"
          size="is-small"/>
        <b-icon v-show="isHoverRemoveIcon(index)"
          pack="fas"
          icon="trash-alt"
          class="text-grey float w-full h-full"
          size="is-small"/>
      </button>
    </div>
  </li>
</transition-group>
</template>

<script>
import { mapGetters } from 'vuex'
import UserReplacement from './UserReplacement.vue'

export default {
  props: {
    usersList: { type: Array, default: [] },
    superiorHierarchicalUsers: { type: Array, default: []},
    disabled: { type: Boolean, default: false },
    allowZeroUsers: { type: Boolean, default: false },
    isManager: { type: Boolean, default: false },
    show: { type: Boolean, default: false },
    canReplace: { type: Boolean, default: false},
    boxFullWidth: { type: Boolean, default: false, required: false},
  },

  components: {
    UserReplacement
  },

  data: () => ({
    iconRemoveHovered: null
  }),

  computed: {
    ...mapGetters({
      authUser: 'auth/user',
    }),
  },

  methods: {
    showRemoveButton(index) {
      if (this.disabled || ('cannot_delete' in this.usersList[index])) {
        return false
      }
      if (!this.allowZeroUsers && (this.usersList.length == 1) && !this.isManager) {
        return false
      }
      return true
    },
    isHoverRemoveIcon(index) {
      return this.iconRemoveHovered === index
    },
    onClickRemoveButton(index) {
      this.$emit('removeButtonClicked', index)
    },
    onClickReplaceButton(index) {
      this.$parent.$emit('replaceButtonClicked', index)
    },
    isSuperiorHierarchyUser(userId) {
      return this.superiorHierarchicalUsers.length > 0 && this.superiorHierarchicalUsers.includes(userId)
    }
  },
};
</script>

<style scoped>
  .sup-user {
    background-color: #E6E6E6;
  }
</style>
