<template>
  <div>
    <div v-if="isLoading" class="text-center my-4">
      <scale-loader></scale-loader>
    </div>
    <div v-else>
      <div class="panel" v-if="ready">

        <label v-if="creatingNew">Añadir maquinaria en <b>{{ partner.name }}</b></label>
        <label v-else>Editar maquinaria de <b>{{ partner.name }}</b></label>

        <form @submit.prevent="updateMachinery">


          <div class="flex items-center mb-4">
            <div class="w-1/4 mr-4">
              <label for="name">{{ $t('name')}}</label>
              <v-input
                title="name"
                id="name"
                v-model="formChangeMachinery.name"
                name="name"
                :placeholder="$t('name')"
              />
            </div>

            <div class="w-1/4 mr-4">
              <label for="number_plate">{{ $t('number_plate')}}</label>
              <v-input
                title="number_plate"
                id="number_plate"
                v-model="formChangeMachinery.number_plate"
                name="number_plate"
                :placeholder="$t('number_plate')"
              />
            </div>
          </div>

          <div class="flex items-center mb-4">
            <div class="w-1/4 mr-4">
              <label for="code">{{ $t('code')}}</label>
              <v-input
                title="code"
                id="code"
                v-model="formChangeMachinery.code"
                name="code"
                :placeholder="$t('code')"
              />
            </div>

            <div class="w-1/4 mr-4">
              <label for="owner">{{ $t('owner')}}</label>
              <v-input
                title="owner"
                id="owner"
                v-model="formChangeMachinery.owner"
                name="owner"
                :placeholder="$t('owner')"
              />
            </div>
          </div>

          <div class="flex items-center mb-4">

            <div class="w-1/4 mr-4">
              <label for="type">{{ $t('type')}}</label>
              <v-select
                title="type"
                select-name="type_name"
                select-id="type_id"
                :selectedValue="parseInt(formChangeMachinery.type)"
                :data="machineryType"
                v-on:updateValue="updateType"/>
            </div>

            <div class="w-1/4 mr-4">
              <label for="status">{{ $t('status')}}</label>
              <v-select
                title="status"
                select-name="status_name"
                select-id="status_id"
                :selectedValue="parseInt(formChangeMachinery.status)"
                :data="machineryStatus"
                v-on:updateValue="updateStatus"/>
            </div>

          </div>

          <div class="flex items-center mb-4">

            <div class="flex flex-row mt-4">
                <button class="btn btn-blue" nativeType="submit"
                :disabled="formChangeMachinery.name && formChangeMachinery.type >= 0 && formChangeMachinery.status >= 0 ? false : true">
                  {{$t('save')}}
                </button>

                <a @click="$router.go(-1)" class="btn btn-light ml-2">
                  {{$t('cancel')}}
                </a>
            </div>

          </div>

        </form>
      </div>

      <div class="flex items-center mb-4">


      </div>

    </div>
  </div>
</template>

<script>
  import Form from 'vform'
  import axios from 'axios'
  import store from '~/store'
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

  export default {
    middleware: 'auth',
    // title: 'userform_page_title',
    // subtitle: 'userform_page_subtitle',
    // menuOption: '0-2',

    components: {
      ScaleLoader
    },

    data: () => ({
      formChangeMachinery: new Form({
        code: null,
        name: '',
        number_plate: '',
        type: '',
        status: '',
        partner_id: 0,
        owner: null,
      }),
      partner: {},
      ready: false,
      partnerId: 0,
      machineryId: 0,
      creatingNew: false,
      isLoading: false,
      machineryType: [{id: 0, name: 'Type1'}, {id: 1, name: 'Type2'}, {id: 2, name: 'Type3'}],
      machineryStatus: [{id: 0, name: 'machinery_status_available'}, {id: 1, name: 'machinery_status_notavailable'}]
    }),

    mounted() {

      // Capturamos los IDs de la url (/admin/partner/:id/machinery/:machineryId?) id y machineryId
      this.partnerId = store.state.route.params.id;
      this.machineryId = store.state.route.params.machineryId;
      this.creatingNew = store.state.route.params.machineryId ? false: true; // creando o editando?
      this.formChangeMachinery.partner_id = this.partnerId;

      this.machineryType.unshift({id: -1, name: this.$t('select')})
      this.machineryStatus.unshift({id: -1, name: this.$t('select')})

      this.getPartner()

      if (this.machineryId) { //editar
        this.machineryId = parseInt(this.machineryId)
        this.getMachinery()
      } else { // crear nuevo
        this.ready = true
      }
    },

    created(){
    },

    methods: {


      updateType(value) {
        this.formChangeMachinery.type = parseInt(value)
      },

      updateStatus(value) {
        this.formChangeMachinery.status = parseInt(value)
      },

      getPartner() {
        let self = this;
        axios.get('/api/v2/partner/' + self.partnerId)
          .then(function (response) {
            //  Object.assign(self.partner, response.data.data); bug, no va bien
            self.partner = Object.assign({}, response.data.data);
          })
          .catch(function (error) {
            self.$notify.error('error_retrieving_data')
          });
      },

      getMachinery() {
        let self = this;

        axios.get('/api/v2/partner/' + this.partnerId + '/machinery/' + this.machineryId)
          .then(function (response) {

            self.formChangeMachinery = Object.assign({}, response.data.data);
            self.ready = true
          })
          .catch(function (error) {
            self.$notify.error('error_retrieving_data')
          });
      },

      async updateMachinery() {

        let formData = new FormData();

        formData.append('code', this.formChangeMachinery.code);
        formData.append('name', this.formChangeMachinery.name);
        formData.append('number_plate', this.formChangeMachinery.number_plate);
        formData.append('type', this.formChangeMachinery.type);
        formData.append('status', this.formChangeMachinery.status);
        formData.append('partner_id', this.formChangeMachinery.partner_id);
        formData.append('owner', this.formChangeMachinery.owner);

        var url = this.machineryId
          ? '/api/v2/partner/' + this.partnerId + '/machinery/' + this.machineryId
          : '/api/v2/partner/' + this.partnerId + '/machinery/'

        let self = this
        this.isLoading = true

        await axios.post(url, formData)
          .then(function (response) {
            self.isLoading = false

            if (response.data.success == true) {

              self.$notify.success('machinery_updated')
              self.$router.push({
                name: 'partner.machineries'
              })
            }
          })
          .catch(error => {
            self.isLoading = false
            self.$notify.error('error_updating_machinery')
          });

      },

    },
  };
</script>
