<template>
  <div class="panel">
    <div class="flex items-center justify-between w-full mb-5">
      <div class="flex flex-col">
        <div><label><b>{{ extrafield && extrafield.id ? 'Editar' : 'Crear' }} {{ $t('extrafield') }} </b></label></div>
        <div></div>
      </div>
      <div>
        <b-tooltip :label="$t('close')" position="is-left">
          <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
            <b-icon pack="fas" icon="times" size="is-small" />
          </div>
        </b-tooltip>
      </div>
    </div>

    <!-- <form @submit.prevent="saveExtraField" class="w-full"> -->
      <!-- <header class="font-thin uppercase text-blue-light modal-card--head"
      >Añadir / editar campo adicional</header> -->

      <div class="modal-card--body ">
          <div class="border-grey-light border-b border-dashed mb-3">
            <div class="w-full inline-flex pb-3">
              <label form="reference_code" class="md:w-1/3">{{ $t('reference_code')}}</label>
              <v-input
                v-model="form.code"
                title="reference_code"
                required="true"
                name="code"
                placeholder="reference_code"
                class="md:w-2/3"
                maxlength="100"
              />
            </div>

            <div class="w-full inline-flex pb-3">
              <label form="name" class="md:w-1/3">{{ $t('name')}}</label>
              <v-input
                v-model="form.name"
                title="name"
                required="true"
                name="name"
                placeholder="name"
                class="md:w-2/3"
                maxlength="100"
              />
            </div>

            <div class="w-full inline-flex pb-3">
              <label form="type" class="md:w-1/3">{{ $t('type')}}</label>
              <b-select v-model="form.field_type" :placeholder="$t('select')" class="md:w-2/3" expanded>
                  <option v-for="option in options" :value="option.value" :key="'type_'+option.value">
                    {{ option.text }}
                  </option>
              </b-select>
            </div>

            <div class="w-full inline-flex">
              <label form="order_column" class="md:w-1/3">{{ $t('order')}}</label>
              <b-input type="number"
                min="0"
                max="100"
                v-model="form.order_column"
                class="md:w-2/3">
              </b-input>
            </div>
          </div>

      </div>

      <footer class="modal-card--foot">
        <!-- <div class="w-full inline-flex justify-end">
          <button class="btn btn-blue" nativeType="submit">{{$t('save')}}</button>
        </div> -->

        <div class="flex items-center justify-end w-full">
          <form @submit.prevent="saveExtraField" @keydown="form.onKeydown($event)" v-if="!processingData"
            class="w-full flex items-center justify-end mb-2 mt-2">
            <v-button :disabled="disableSaveButton" type="submit" icon="thumbs-up" icon-pack="far" class="mr-4">{{ $t('save') }}</v-button>
            <v-button color="grey" icon="undo-alt" @click="onCloseClicked">{{ $t('cancel') }}</v-button>
          </form>

          <v-button-processing v-if="processingData" />
        </div>

      </footer>
    <!-- </form> -->
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";

export default {
  props: {
    projectId: { type: Number, required: true },
    extrafield: { type: Object, default: null },
    apiEntity: { type: String, default: 'extrafield' } // Api Entity hace referencia a la api de la entidad relacionada con project
  },

  data() {
    return {
      processingData: false,
      disableSaveButton: false,

      data: this.extrafield || null,

      form: new Form({
        id: this.extrafield ? this.extrafield.id : null,
        code: this.extrafield ? this.extrafield.code : '',
        name: this.extrafield ? this.extrafield.name : '',
        field_type: this.extrafield ? this.extrafield.field_type : '',
        order_column: this.extrafield ? this.extrafield.order_column : ''
      }),

      options: [
        { value: 0, text: 'Select' },
        { value: 1, text: 'Input' },
        { value: 2, text: 'Calendar' },
        { value: 3, text: 'Checkbox' },
      ],
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
    async saveExtraField() {
      this.processingData = true

      let url = "/api/v2/project/"+this.projectId+"/" + this.apiEntity;

      if( this.form.id )
        url = `/api/v2/${this.apiEntity}/` + this.form.id;

      axios({
        method: "post",
        url: url,
        data: this.form
      })
        .then(response => {
          this.$toast.open({
            message: this.$t("save_successful"),
            type: "is-success",
            position: "is-top-right"
          });

          this.hide();
          this.processingData = false
          this.$emit('epc-success-creating', this.entity)
        })
        .catch(error => {
          //Se produjo un error
          this.processingData = false
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("save_error"),
            type: "is-danger",
            position: "is-top-right"
          });
        });
    },

    show(data) {
      this.data = data;
      this.form.code = data.code;
      this.form.name = data.name;
      this.form.field_type = data.field_type;
      this.form.order_column = data.order_column;
      this.isModalActive = true;
    },

    hide() {
      this.data = null;
      this.isModalActive = false;
      this.form.code = "";
      this.form.name = "";
      this.field_type = "";
      this.order_column = "";
    },

    onCloseClicked() {
      this.extrafield = null;

      this.$emit('epc-close', true)
    },
  },
};
</script>

<style scoped>
/* Oculta los botones para aumentar los inputs numéricos */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
