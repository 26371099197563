<template>
  <div>
    <div class="panel">
      <div v-if="loadingAssigned" class="my-4 text-center">
        <scale-loader></scale-loader>
      </div>

      <div v-if="extraFieldId && !loadingAssigned">
        <div class="flex items-center justify-between w-full mb-5">
          <div><label><b><span v-if="extraFieldId && form.name"> {{ $t('extra_field') + ' - ' + form.name }}</span></b></label></div>
          <div>
            <b-tooltip :label="$t('close')" position="is-left">
              <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
                <b-icon pack="fas" icon="times" size="is-small" />
              </div>
            </b-tooltip>
          </div>
        </div>

        <b-tabs type="is-boxed" v-model="linkActiveTab">
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-excel"></b-icon>
              <span> {{ $t('linked_activities') }} </span>
            </template>

            <!-- recuadro de valores -->
            <div
              v-for="(value) in form.assigned_activities" :key="value.id">
              <div v-if="value.fieldable" class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-blue-lightest mb-1">
                <!-- delete -->
                <b-tooltip :label="$t('unlink')" position="is-left" type="is-danger">
                    <div @click="unassignExtrafields(value.extrafield_id, value.id, form.name, value.fieldable.name, 'actitivty')"
                      class="flex cursor-pointer text-red">
                      <b-icon pack="fas" icon="trash-alt"/>
                    </div>
                </b-tooltip>
                <!-- save -->
                <span class="ml-2">{{ value.fieldable.name }}</span>
              </div>
            </div>
            <div v-if="form.assigned_activities.length == 0" class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-gray-200 mb-1 has-text-centered">{{ $t('no_results') }}</div>

            <div class="flex items-center justify-end w-full mt-5" v-if="form.assigned_activities.length > 0">
              <button v-if="!processingData"
                type="button"
                class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-orange hover:bg-orange-light transition ease-in-out duration-150"
                @click="unassignExtrafields(form.id, -1, form.name, 'all', 'activity')"
                >
                <i class="fas fa-trash-alt mr-3"></i>
                {{ $t('unlink_activities') }}
              </button>

              <button v-if="processingData" type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-orange hover:bg-orange-light transition ease-in-out duration-150 cursor-not-allowed" disabled="">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                {{ $t('unlink_activities') }}
              </button>
            </div>

          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="traffic-light"></b-icon>
              <span>{{ $t('linked_statuses') }}</span>
            </template>

            <div class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-blue-lightest mb-1"
              v-for="(value) in form.statuses" :key="value.id">
              <div>
                <!-- delete -->
                <b-tooltip :label="$t('unlink')" position="is-left" type="is-danger">
                    <div @click="unassignExtrafields(value.pivot.extrafield_id, value.id, form.name, value.name, 'status')" class="flex cursor-pointer text-red">
                      <b-icon pack="fas" icon="trash-alt"/>
                    </div>
                </b-tooltip>
                <!-- save -->
                <span class="ml-2">{{ value.name }}</span>
              </div>
            </div>
            <div v-if="form.statuses.length == 0" class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-gray-200 mb-1 has-text-centered">{{ $t('no_results') }}</div>

            <div class="flex items-center justify-end w-full mt-5" v-if="form.statuses.length > 0">
              <button v-if="!processingData"
                type="button"
                class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-orange hover:bg-orange-light transition ease-in-out duration-150"
                @click="unassignExtrafields(form.id, -1, form.name, 'all', 'status')"
                >
                <i class="fas fa-trash-alt mr-3"></i>
                {{ $t('unlink_statuses') }}
              </button>

              <button v-if="processingData" type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-orange hover:bg-orange-light transition ease-in-out duration-150 cursor-not-allowed" disabled="">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                {{ $t('unlink_statuses') }}
              </button>
            </div>
          </b-tab-item>
        </b-tabs>




      </div>

    </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import Multiselect from 'vue-multiselect'
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  components: {
    ScaleLoader,
    Multiselect,
  },

  props: {
    extrafields: { type: Array, required: true },
    apiEntity: { type: String, default: 'extrafield' }
  },

  data() {
    return {
      disableSaveButton: false,
      processingData: false,
      loaded: false,
      disabled: false,

      statuses: [],
      statusesSelected: [],
    }
  },

  computed: {
  },

  watch: {
  },
  created() {
    this.getProducts()
  },
  mounted() {

  },

  methods: {
    async getProducts() {
    },


    onCloseClicked() {
      this.value = null;
      this.$emit('epc-close', true)
    },

    async assignExtrafieldsActivity() {
      if (!this.activity.id) {
        this.$notify.error(this.$t('empty_field_required', ['DSO']))
        return
      }

      if( this.checkedRows.length == 0){
        this.$notify.error(this.$t('error_select_extrafield'))
        return
      }

      if (!this.activity.id) {
        this.$notify.error(this.$t('empty_field_required', ['DSO']))
        return
      }

      this.processingData = true;
      let fields = [];
      this.checkedRows.forEach(field => {
        fields.push(field.id);
      });

      let self = this;

      await axios.post("/api/v2/extrafields/assign", {
        entityId: this.activity.id,
        entity: this.activity.type,
        fields: fields
      })
      .then(response => {
        let data = response.data
        this.processingData = false;

        if (data && data.success) {
          // reload
          self.activity = {};
          self.checkedRows = [];

          self.$notify.success('success_editing')

        } else if(data && data.msg) {
          self.$notify.error('no_access_permissions')
        } else {
          self.$notify.error('error_saving')
        }
      })
      .catch(error => {
          self.processingData = false;
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
                ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("assignment_insert_error"),
            type: "is-danger",
            position: "is-top-right"
          });
      });
    },

    async unassignExtrafields(fieldId, valueId, fieldName, valueName, entity='activity') {
      let params = { };
      params.entity = entity;
      params.assignment = valueId;
      valueName = valueName === 'all' ? 'todas las asignaciones' : valueName;
      let self = this;
      this.$dialog.confirm({
        title: this.$t('unassign'),
        message: this.$t('unassign_question_extrafield', [fieldName, valueName]),
        confirmText: this.$t('unassign'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {

          const { data } = await axios.post("/api/v2/extrafields/" + fieldId + "/unassign", params)
          if (data && data.success) {

            // reload
            this.readExtrafield( { with: 'assigned' } )
            this.$notify.success('success_deleting_generic')

          } else if(data && data.msg){

            this.$notify.error('no_access_permissions')

          } else {
            this.$notify.error('error_saving')
          }
        }
      })
    },
  },
};
</script>

