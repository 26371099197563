<template>
    <!-- boton crear carpeta dentro de carpeta actual -->
    <div v-if="canManageFolders" class="hover:bg-gray-300 p-2 rounded-tl-lg cursor-pointer">

      <div v-if="openInModal">
        <span @click="show()"> <span class="hidden lg:inline">
          {{ $t(textHeader) }} <i v-if="showNameCurrentFolder">{{ selectedFolder.name || $t('root') }}</i></span> <b-icon pack="fas" icon="folder-plus" />
        </span>

        <b-modal :active.sync="isModalActive" has-modal-card >
          <div class="modal-card" style="width: auto">

            <div class="modal-card-head">
              <p class="modal-card-title">{{ $t(textHeader) }}</p>
            </div>

            <div class="modal-card-body">
              <!-- <b-field :label="$t('code')">
                <input v-model="newFolder.code" type="text" :placeholder="$t('code')" class="input mr-2"  required="true">
              </b-field> -->
              <b-field :label="$t('name')">
                <input v-model="newFolder.name" type="text" :placeholder="$t('name')" class="input mr-2"  required="true">
              </b-field>


            </div>

            <div class="modal-card-foot">
              <button class="btn btn-green items-center mr-2"
                type="button"
                :disabled="newFolder.name ? false : true"
                @click="createFolder()">
                {{ $t('create') }}
              </button>
              <!-- <button class="button is-success" :disabled="selectedFolder==null" v-on:click="moveDocument">{{ $t('move') }}</button> -->
            </div>

          </div>

          <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
        </b-modal>
      </div>

      <div v-else>
        <span @click="showCreationForm = !showCreationForm"> <span class="hidden lg:inline">
          {{ $t(textHeader) }} <i v-if="showNameCurrentFolder">{{ selectedFolder.name || $t('root') }}</i></span> <b-icon pack="fas" icon="folder-plus" />
        </span>

        <span v-if="showCreationForm" class="inline-flex ml-2">
          <input v-model="newFolder.name" type="text" :placeholder="$t('name')" class="input mr-2"  required="true">
          <!-- <input v-model="newFolder.code" type="text" :placeholder="$t('code')" class="input mr-2"  required="true"> -->
          <button class="btn btn-green items-center mr-2" type="button" :disabled="newFolder.name ? false : true" @click="createFolder()">
            <i class="fas fa-plus"></i>
          </button>
        </span>
      </div>

    </div>
</template>

<script>
import axios from "axios";

export default {
  name: "entity-document-folder-create",

  components: {
  },

  props: {
    selectedFolder: { type: Object, required: true },
    canManageFolders: { type: Boolean, default: false },
    parentId: { type: Number, required: true },
    entityType: { type: String, required: true },
    showNameCurrentFolder: { type: Boolean, default: true },
    textHeader: { type: String, required: false, default: 'create_folder_in' },
    roundedBorder: {type: String, required: false, default: '' },
    openInModal: { type: Boolean, default: false }
  },

  data: () => ({
    newFolder: {
      code: '',
      name: '',
    },
    showCreationForm: false,
    isModalActive: false,
    isLoading: false
  }),

  mounted() {
  },

  methods: {

    reloadList() {
      this.$emit('reload-documents-list')
    },

    async createFolder() {
      let self = this;
      if (this.entityType != 'activity') {
        this.$notify.error("Option only for activities")
        return
      }

      this.isLoading = true
      const { data } = await axios.post("/api/v2/" + this.entityType + "/" + this.parentId + "/document-folder", {
        code: this.newFolder.code || null,
        name: this.newFolder.name,
        parent_id: this.selectedFolder.id || null,
      })

      if (data && data.success) {

        self.reloadList()
        this.newFolder = {
          code: '',
          name: '',
        },
        this.showCreationForm = false
        this.$notify.success('success_editing')

      } else if(data && data.msg){

        this.$notify.error(data.msg || 'no_access_permissions')

      } else {
        this.$notify.error('error_saving')
      }

      this.isLoading = false

    },

    show() {
        this.isModalActive = true
    }
  }
};
</script>

<style scoped>
@media (min-width: 1024px) {
  .lg\:inline{
    display: inline;
  }
}
</style>
