<template>
  <div>
    <div class="panel">
      <div v-if="isLoading" class="my-4 text-center">
        <scale-loader></scale-loader>
      </div>
      <div v-else>
        <documents-table ref="tbldocuments"
          :documents="documents"
          @epc-show-viewer="_handleShowViewer"/>
      </div>
    </div>

    <!-- Modal solo visualiza -->
    <viewer-pdf v-if="typeDocument === 'documents'"
      ref="modalViewerPdf"
      :fileName="fileName"></viewer-pdf>

    <!-- Modal para EDITAR PDF -->
    <entity-document-editor v-if="typeDocument === 'drawings'"
        :ref="'modalViewerEditorPdf'"
        :entity-id="project.id"
        :entity-type="'project'"
        :enableMeasurement="false"
        :enableBtnSave="false"
        :nameEditorRef="'ViewerOdf'"
        @reload-documents-list="() => $emit('reload-documents-list')"
    />
  </div>
  </template>

  <script>
  import {mapGetters} from "vuex";
  import axios from 'axios'
  import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
  import ViewerPdf from "../../components/viewer-pdf.vue";
  import entityDocumentEditor from "../../components/entity-document-editor.vue"
  import DocumentsTable from "./documents-table.vue";
  import EventBus from "~/plugins/bus";

  export default {

    components: {
      ScaleLoader,
      DocumentsTable,
      ViewerPdf,
      entityDocumentEditor
    },

    props: {
      typeDocument: { type: String, required: true }
    },

    data: () => ({
      isLoading: true,
      page: 1,
      perPage: 10,
      total: 0,
      orderField: "reference_code",
      orderDir: "asc",
      currentPage: 1,
      isPaginationSimple: false,
      defaultSortDirection: 'asc',
      isPaginationSimple: false,
      isPaginated: true,

      documents: [],

      fileName: 'demo.pdf',
    }),

    computed: {
      ...mapGetters({
        user: "auth/user",
        project: 'app/project',
      }),
    },

    created() {
      let self = this;
      // Si recibimos el evento refresh, refrescamos el componente
      EventBus.$on("refreshProjectDocumentsNotifications", function(e) {
        if( e.executor_user_id && e.executor_user_id != self.user.id ) {
          self.getProjectDocuments();
        }
      });
    },

    mounted() {
      this.getProjectDocuments()
    },

    beforeDestroy(){
      EventBus.$off("refreshProjectDocumentsNotifications");
    },

    methods: {

      async getProjectDocuments() {
        this.isLoading = true;
        let params = {
          text: '',
          with: this.typeDocument
        };

        const { data } = await axios.get(
          "/api/v2/project/" + this.project.id + "/documents",
          { params: params }
        );

        if (data && data.success) {
          this.documents = data.documents;
          this.total = data.documents ? data.documents.length : 0;
        } else {
          this.$notify.error("error_loading_documents");
        }
        this.isLoading = false;
      },

      _handleShowViewer(document) {
        this.fileName = document.file.raw

        if (this.typeDocument === 'documents') {
          // this.fileName = document.file.raw
          this.$refs.modalViewerPdf.show();
          this.$refs.modalViewerPdf.getPdf();
        } else {
          this.$refs.modalViewerEditorPdf.show(document);
        }
      },
    },
  };
  </script>
