<template>
  <span
    class="p-1 font-black tracking-wide text-center text-white uppercase rounded"
    :class="[`bg-${labels[status]}`]"
    :style="'font-size:' + fontSize + 'rem'"
  >
    {{ $t(labels[status]) }}
  </span>
</template>

<script>

export default {
  name: 'technical-specification-status-label',

  props: {
    status: { type: Number, default: 0 },
    fontSize: {type: String, default: "0.6"}
  },

  data: () => ({
    labels: [
      'technical_specification_status_not_presented',
      'technical_specification_status_pending',
      'technical_specification_status_approved',
    ],
  }),

};
</script>
<style scoped>
  .bg-technical_specification_status_not_presented {
    background-color: #3490dc;
  }
  .bg-technical_specification_status_pending {
    background-color: #F6993F;
  }
  .bg-technical_specification_status_approved {
    background-color: #38C172;
  }
</style>
