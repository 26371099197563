<template>
  <div>
    <div class="panel p-2 relative">
      <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>

      <div class="flex items-center justify-between w-full mb-5">
        <div class="flex flex-col">
          <div><label><b>Total de pictogramas a vincular: {{ this.pictogramsToLink.length }}</b></label></div>

          <!-- <div><b>Actividad vinculada al pictograma</b></div>
          <div><label>{{ group.name }}</label></div> -->
        </div>
        <div>
          <b-tooltip :label="$t('close')" position="is-left">
            <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
              <b-icon pack="fas" icon="times" size="is-small" />
            </div>
          </b-tooltip>
        </div>
      </div>

      <ActivitySelector ref="activity_selector"
        :projectId="projectId"
        :icon-button="'link'"
        :title-button="'link'"
        :action-button="linkPictograms"
        @epc-selected-entities="(e) => { selectedEntities = e }"
      />
    </div>
  </div>
  </template>

  <script>
  import axios from "axios";
  import ActivitySelector from "./components/activity-selector.vue";


  export default {
    name: "pictogram-link",

    components: {
      ActivitySelector
    },

    props: {
      projectId: { type: Number, required: true },
      pictogramsToLink: { type: Array, required: true },
    },

    data() {
      return {
        disableSaveButton: false,
        processingData: false,
        disabled: false,
        isLoading: false,

        selectedEntities: [] // entidades a ser vinculada
      }
    },

    computed: {

    },

    watch: {
    },

    mounted() {
    },

    methods: {
      onCloseClicked() {
        this.$emit('epc-close', true)
      },

      // TODO: revisar la logica de este metodo el parametro que resive no esta bien seria activity o subactividad segun se seleccione
      async linkPictograms(model) {

        if( this.checkedRows.length == 0){
          this.$notify.error(this.$t('error_select_document'))
          return
        }

        this.processingData = true;
        let fields = [];
        this.checkedRows.forEach(field => {
          fields.push(field.document_id);
        });

        let self = this;

        await axios.post("/api/v2/pictogram/assign", {
          entityId: this[model].id,
          entityType: this[model].type,
          fields: fields
        })
        .then(response => {
          let data = response.data
          this.processingData = false;

          if (data && data.success) {
            // reload
            self[model] = {};
            self.checkedRows = [];

            self.$notify.success('success_editing')

          } else if(data && data.msg) {
            self.$notify.error('no_access_permissions')
          } else {
            self.$notify.error('error_saving')
          }
        })
        .catch(error => {
            self.processingData = false;
            if (error.response && error.response.status === 422) {
              //extraemos el primer error de validación
              error.error_msg =
                error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                  ][0];
            }

            // Mostramos el error
            this.$toast.open({
              message: error.error_msg || this.$t("assignment_insert_error"),
              type: "is-danger",
              position: "is-top-right"
            });
        });
      },
    },
  };
  </script>

