  <template>
    <div>

      <div class="top-bar flex justify-end">
        <!-- <v-button
        @click="() => { showFormImportEntities = true }"
        icon="file-import"
        class="mr-4"
        :disabled="showFormData"
      >{{ $t('import_inventory') }}</v-button>

      <v-button
        @click="openInventaryModal(null)"
        icon="shopping-bag"
        :disabled="showFormData"
      >{{ $t('add_inventory') }}</v-button>

      <inventory-edit
        ref="inventory_modal"
        :projectId="projectId"
        @reload-inventories="handleSuccess"
      /> -->
      </div>

      <div>
        <inventory-filters ref="filtersInventoryRef" :projectId="projectId" :products="products" :providers="providers"
          @filters-updated="filtersUpdate" />
      </div>

      <inventory-dashboard ref="dashboardInventoryRef" :projectId="projectId" />

      <div class="flex">
        <div :class="['w-full', 'mr-2']">
          <inventories ref="inventories_list" :projectId="projectId" @epc-edit="handleEditEntity" />
        </div>

        <!-- <transition name="fade">
        <div class="w-5/12 ml-2" v-if="showFormImportEntities">
          <inventory-import
            :projectId="projectId"
            :entity-type="'store-inventories'"
            @epc-close="(event) => { showFormImportEntities = false }"
            @epc-success-import="() => { showFormImportEntities = false ; $refs.inventories_list.reload() }"
          />
        </div>

      </transition> -->
      </div>
    </div>
  </template>

<script>
import Inventories from './inventories.vue';
import InventoryEdit from './inventory-modal.vue';
import PurchaseEdit from './purchase-edit.vue';
import InventoryImport from './inventory-import.vue';
import InventoryFilters from './storehouse-filters.vue'
import InventoryDashboard from './inventory-dashboard.vue'


export default {
  name: "manage-invnetory",

  components: {
    Inventories,
    InventoryEdit,
    PurchaseEdit,
    InventoryImport,
    InventoryFilters,
    InventoryDashboard
  },

  data: () => ({
    material: null, // objeto que se crea
    showFormData: false,
    showFormImportEntities: false,
    showFormLink: false,
  }),

  props: {
    projectId: { type: Number, required: true },
    products: { type: Array, default: () => ([]) },
    providers: { type: Array, default: () => ([]) },
  },

  created() {
  },

  mounted() {
  },

  methods: {

    // handleOnCloseEdit(event) {
    //   this.showFormData = !event
    // },

    handleSuccess() { // recibe la entidad editada
      this.$refs.inventories_list.reload()
    },

    handleEditEntity(entity) {
      this.openInventaryModal(entity)
    },

    // Botones de acción
    openInventaryModal(inventory) {
      if (inventory == null) {
        inventory = {
          id: 0,
          product_id: null,
          provider_id: null,
          unit_price: 0,
          stock: 0,
          stock_min: 0,
        };
      }
      this.$refs.inventory_modal.show(inventory);
    },

    filtersUpdate() {
      // this.isLoadingTable = true;
      this.applyFilters(1);
    },

    applyFilters(page) {
      let generatedFilters = this.customizeFilters();
      generatedFilters.page = 1;
      let currentPage = generatedFilters.page;

      this.$refs.inventories_list.reload(generatedFilters)
      this.$refs.dashboardInventoryRef.reload(generatedFilters)
    },

    customizeFilters() {
      let filters = this.$refs.filtersInventoryRef.generateFilters();

      // filters.with_assignments = 1;
      // filters.order_field = this.orderField;
      // filters.order_dir = this.orderDir;
      // filters.per_page = this.perPage;

      //filters.s = 1; //con subactividades. Como se piden de todas formas al desplegar, se quitan de aqui para aligerar carga

      return filters;
    },
  }

};
</script>

<style scoped></style>
