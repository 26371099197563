<template>
<div>
  <div class="flex items-center justify-between w-full">

    <div class="flex-col w-1/2">
      <!-- Título -->
      <div class="text-lg font-thin uppercase text-blue-light">{{ $t('attached_documents') }}</div>
    </div>

    <div class="flex-col w-1/2">
      <div class="flex-row">

        <!-- Botón añadir -->
        <b-tooltip :label="$t('add_document')" position="is-left" class="float-right">
          <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-blue text-grey-lightest" @click="onAddClicked">
            <b-icon pack="fas" icon="plus" size="is-small" />
          </div>
        </b-tooltip>
        <!-- Modal -->
        <upload-document
          ref="modal_attach"
          :upload-endpoint="'/api/v2/' + entityType + '/' + entityId + '/document'"
          :allow-multiple-files="true"
          @success="successfullyUploaded"
          :entityId="entityId"
          :entityType="entityType"
          :is-attached="1"
          :validable-parent-code="validableParentCode"
        />

      </div>
    </div>

  </div>

  <div v-if="isLoading" class="mt-4 text-center">
        <div class="mt-4 text-center"><scale-loader></scale-loader></div>
  </div>

  <div v-else>
    <div v-if="! isLoading" class="flex flex-wrap mt-4">

      <div class="w-full">
        <document-item-list
            v-for="(doc, index) in list"
            :key="doc.document_id"
            :document="doc"
            :parent-id="entityId"
            :can-delete="true"
            :entity-type="entityType"
            :bg-color="index % 2 ? 'bg-white' : 'bg-grey-lighter'"
            :showVersions="showVersions"
            :isAttached="true"
            @reload-documents-list="() => loadDocumentsList(entityId, entityType)"
        />
      </div>
    </div>

    <div v-if="this.total > this.perPage" class="mt-4">
      <b-pagination
          :total="total"
          :current.sync="page"
          size="is-small"
          iconPack="fas"
          order="is-centered"
          :per-page="perPage"
          @change="onPageChange">
      </b-pagination>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import uploadDocument from "./upload-document";
import documentItemList from "./document-item-list";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import Buefy from 'buefy'

export default {
  name: "entity-attached-documents",

  components: {
    uploadDocument,
    documentItemList,
    ScaleLoader,
    Buefy
  },

  props: {
    entityId: { type: Number, required: true },
    entityType: { type: String, required: true },
    validableParentCode: { type: String, required: false, default: '' }, // para formato codificacion documentos "lean2" y "lean3" que van precedidos por codigo actividad padre
  },

  data: () => ({
    list: [],
    isLoading: true,
    orderField: 'date',
    page: 1,
    perPage: 15,
    total: 0,
    showVersions: true
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
      project: 'app/project'
    }),
  },

  watch: {
    entityId(id) {
      if (id > 0) {
        this.loadDocumentsList(id, this.entityType);
      }
    }
  },

  created() {
    this.loadDocumentsList(this.entityId, this.entityType);
  },
  methods: {
    async loadDocumentsList(entityId, entityType) {

      this.isLoading = true
      let self = this

      let params = {
        per_page: this.perPage,
        page: this.page,
        order_field: this.orderField,
        attached: 1
      }

      await axios.get("/api/v2/"+ entityType +"/" + entityId + "/documents",
        { params: params })
        .then(function(response) {

          if (response.data && response.data.documents) {
            self.list = response.data.documents;
            self.total = response.data.total
          }
        })
        .catch(function(error) {
          console.log(error);
        })
      self.isLoading = false;
    },

    successfullyUploaded()
    {
      this.loadDocumentsList(this.entityId, this.entityType);
    },

    onPageChange(value) {
      this.page = value
      this.loadDocumentsList(this.entityId, this.entityType)
    },

    onAddClicked() {
      this.$refs.modal_attach.show();
    }
  }
};
</script>
