<template>
<div>
  <b-collapse class="card filters-collapse" :open.sync="showFilters">
    <div slot="trigger" class="card-header filters-trigger">
      <p class="card-header-title">
        {{ $t('filters') }}
      </p>
      <a class="card-header-icon">
        <b-icon pack="fa" v-show="! showFilters" icon="plus-circle"/>
        <b-icon pack="fa" v-show="showFilters" icon="minus-circle"/>
      </a>
    </div>
    <div class="panel" style="border-width: 0">
      <div class="flex flex-col lg:flex-row items-center mb-4">

        <!-- por nombre -->
        <div class="w-full lg:w-1/6 mr-4">
          <b-field :label="$t('search_by_name')">
            <b-input
              v-model="filterValidableName"
              :placeholder="$t('name')"
              type="search"
              icon="search"
              icon-pack="fas"
              @input="checkInputTextFilter(filterValidableName)">
            </b-input>
          </b-field>
        </div>

        <!-- por flujo -->
        <div class="w-full lg:w-1/6 mr-4">
          <b-field :label="$t('search_by_validation_flow')">
            <b-select ref="input"
                      v-model="filterTemplate"
                      :placeholder="$t('select')"
                      icon="search"
                      icon-pack="fas"
                      @input="filterCurrentTask = null; applyFilters(1);"
                      expanded
            >
              <option :value="null">{{$t('select')}}</option>
              <option v-for="flowOption in templatesList" :value="flowOption.id" :key="'flow_'+flowOption.id">
                        {{ flowOption.name }}
                      </option>
            </b-select>
          </b-field>
        </div>

        <!-- currentTasks a partir del flujo de validacion y solo se muestra si se selecciona un filterTemplate-->
        <div class="w-full lg:w-1/6 mr-4">
          <b-field :label="$t('search_by_current_task')">
            <b-select ref="input"
                      v-model="filterCurrentTask"
                      :placeholder="filterTemplate ? $t('select') : $t('select_v_flow')"
                      icon="search"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      :disabled="!filterTemplate"
                      expanded
            >
              <option :value="null">{{$t('select')}}</option>
              <option v-for="taskOption in currentTasksList" :value="taskOption.code" :key="'flow_'+taskOption.id">
                {{ taskOption.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <!-- por estado -->
        <div class="w-full lg:w-1/6 mr-4">
          <b-field :label="$t('search_by_status')">
            <b-select ref="input"
                      v-model="filterStatus"
                      :placeholder="$t('status')"
                      icon="search"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      expanded
            >
              <option :value="null">{{$t('validation_status_all')}}</option>
              <option value="99">{{$t('uncompleted')}}</option>
              <option value="0">{{$t('validation_status_pending')}}</option>
              <option value="1">{{$t('validation_status_pending_validation')}}</option>
              <option value="4">{{$t('validation_status_pending_answer')}}</option>
              <option value="2">{{$t('validation_status_completed')}}</option>
              <option value="3">{{$t('validation_status_rejected')}}</option>
              <option value="5">{{$t('validation_status_reopened')}}</option>
              <option value="6">{{$t('validation_status_closed')}}</option>
            </b-select>
          </b-field>
        </div>

        <!-- por grupo actual a validar -->
        <div class="w-full lg:w-1/6 mr-4">
          <b-field :label="$t('search_by_current_group')">
            <b-select ref="input"
                      v-model="filterCurrentGroup"
                      :placeholder="$t('select')"
                      icon="search"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      expanded
            >
              <option :value="null">{{$t('select')}}</option>
              <option v-for="groupOption in currentGroupsList" :value="groupOption.code" :key="'flow_'+groupOption.code">
                        {{ groupOption.code }} - {{ groupOption.name }}
                      </option>
            </b-select>
          </b-field>
        </div>

        <!-- por tipo -->
        <div class="w-full lg:w-1/6 mr-4">
          <b-field :label="$t('search_by_type')">
            <b-select ref="input"
                      v-model="filterType"
                      :placeholder="$t('select')"
                      icon="search"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      expanded
            >
              <option :value="null">{{$t('select')}}</option>
              <option v-for="(type, index) in templateTypes" :value="index" :key="'flow_type_'+index">
                {{ type }}
              </option>
            </b-select>
          </b-field>
        </div>

      </div>

      <div class="flex items-center mb-4">
        <button class="button is-primary btn-blue" @click="cleanFiltersClicked()">{{$t('clean_filters')}}</button>
      </div>
    </div>
  </b-collapse>
  <span v-if="! showFilters && ! blankFilters" class="mx-2 font-bold cursor-pointer text-red" @click="cleanFiltersClicked()">
    {{$t('clean_filters')}} <b-icon icon="trash-alt" pack="fas" size="is-small" class="text-red"/>
  </span>
</div>

</template>

<script>

  export default {
    name: 'validation-filters',

    computed: {
      blankFilters() {
        return this.filterValidableName == ''
          && this.filterTemplate == null
          && this.filterStatus == null
          && this.filterCurrentGroup == null
          && this.filterCurrentTask == null
      }
    },

    components: {},

    props: {
      templatesList: { type: Array, required: true },
      currentGroupsList: { type: Array, required: true },
      currentTasksList: { type: Array, required: true },
    },

    data: () => ({
      showFilters: false,
      filterValidableName: '',
      filterTemplate: null,
      filterCurrentTask: null,
      filterStatus: 99, // por defecto 'no completadas' para acortar los enormes listados
      filterCurrentGroup: null,
      filterType: null,
      templateTypes: ['Estándar', 'Especificaciones Técnicas', 'Incidencias'],
    }),

    watch: {},

    created() {
        this.debouncedSearch = this.debounce(this.applyFilters, 750)
    },

    mounted() {

      // Traer de local storage los posibles valores preguardados de filtros
      if (this.$store.getters["searchFilters/validations"]) {
        this.filterValidableName = this.$store.getters["searchFilters/validations"].validable_name || ''
        this.filterTemplate = this.$store.getters["searchFilters/validations"].template_id || null
        this.filterCurrentTask = this.$store.getters["searchFilters/validations"].current_task || null
        this.filterStatus = this.$store.getters["searchFilters/validations"].status || 99
        this.filterCurrentGroup = this.$store.getters["searchFilters/validations"].current_group || null
        this.filterType = this.$store.getters["searchFilters/validations"].type || null
      }
    },

    methods: {
      generateFilters() {

        let params = {};

        // Filtro por nombre
        if (this.filterValidableName.length > 0) {
          params.validable_name = this.filterValidableName
        } else if (this.filterValidableName.length == 0) {
          params.validable_name = null
        }

        //Filtro por creador
        // if (this.filterTemplate !== null) { // pasamos tambien null para que sea reseteado en la store de searchFilters. Parametros a null no se enviarán en la peticion GET
        params.template_id = this.filterTemplate
        // }

        //Filtro por tarea actual a partir del flujo de validacion filterTemplate
        params.current_task_code = this.filterCurrentTask

        //Filtro por estado
        params.status = this.filterStatus
        //Filtro por grupo actual
        params.current_group = this.filterCurrentGroup
        params.type = this.filterType

        // Guarda filtros en localstore para posteriores visitas
        this.$store.dispatch('searchFilters/setValidationsFilters', params);

        //Añadir el orden y número de elementos por página
        if (this.orderField) {
          params.order_field = this.orderField;
          params.order_dir = this.orderDir;
          params.per_page = this.perPage;
        }

        return params
      },

      applyFilters(page) {
        let args = this.generateFilters();
        this.$emit('filters-updated', args);
      },

      cleanFiltersClicked() {
        this.filterValidableName = '';
        this.filterTemplate = null;
        this.filterCurrentTask = null;
        this.filterStatus = null;
        this.filterCurrentGroup = null;
        this.filterType = null;
        this.showValidationCompleted = null;
        this.$store.dispatch('searchFilters/resetValidationsFilters');
        this.page = 1; //TODO no deberíamos paginar a esta altura
        this.tags = [];
        this.isLoadingTable = true;
        this.applyFilters(1); //TODO no deberíamos paginar a esta altura
      },

      checkInputTextFilter(filter) {
        // if (filter.length >= 3 || filter.length == 0) {
          // this.applyFilters(1)
        // }
        this.debouncedSearch();
      },

      // funcion que espera a que se deje de escribir para enviar la peticion
      debounce(func, wait) {
        let timeout;
        return function (...args) {
          const context = this;
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            func.apply(context, args);
          }, wait);
        };
      },

    },

  };
</script>

<style scoped>
  .filters-collapse {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .filters-trigger:hover {
    background-color: #f8fafc;
  }

  /* FIX TEMPORAL. UN PAQUETE (PROBABLEMENTE @BIMDATA/VIEWER) ESTA SOBREESCRIBIENDO LA CLASE .flex-col */
  @media (min-width: 1024px) {
    .lg\:flex-row {
      flex-direction: row;
    }
  }
</style>
