<template>
  <div class="relative" :class="{ 'h-20' : isLoading }">
    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>

    <div v-if="!isLoading">
      <div v-for="document in documents" :key="'document_' + document.document_id"
        class="file-preview-frame krajee-default  kv-preview-thumb w-full"
        :class="{ 'bg-blue-light': document.document_id == selectedDocumentId, 'border-blue-light': document.document_id == selectedDocumentId}"
        :title="document.name">
        <div class="kv-file-content w-full">
          <object class="kv-preview-data file-preview-pdf"
            :title="document.name" :data="document.file.thumbnail"
            type="application/pdf"
            style="width: 100%; height: 160px; position: relative;">
            <div class="file-preview-other">
              <span class="file-other-icon"><i class="bi-file-earmark-fill"></i></span>
            </div>
          </object>
        </div>
        <div class="file-thumbnail-footer">
          <div class="file-footer-caption"
            :title="document.name">
              <div class="file-caption-info" :class="{ 'text-white': document.document_id == selectedDocumentId }">{{ document.name }}</div>
              <div class="file-size-info" :class="{ 'text-white': document.document_id == selectedDocumentId }">
                <span>({{ getReadableFileSizeString(document.size) }} KB)</span>
              </div>
          </div>
          <div class="file-upload-indicator" title="Not uploaded yet"><i class="bi-plus-lg text-warning"></i></div>
          <div class="file-actions">
            <div class="file-footer-buttons">
              <b-button type="is-info"
                icon-pack="fas"
                title="Selecionar"
                icon-right="clipboard-check"
                @click="handleSelectClick(document)" />
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from "axios";
import moment from 'moment';

Vue.use(moment);
export default {
  name: "epc-documents-preview",

  components: {
  },

  props: {
    entityId: { type: Number, required: true },
    entityType: { type: String, required: true },

    selectedDocument: { type: Object, default: null}
    // assignmentId: { type: Number, required: true },
  },

  data() {
    return {
      isLoading: false,
      documents: [],
      _selectedDocument: this.selectedDocument,
      selectedDocumentId: this.selectedDocument ? this.selectedDocument.id : 0
    }
  },

  created() {


  },

  mounted() {
    this.getDocumentsSelectables()
  },

  computed: {

  },

  methods: {
    getDocumentsSelectables() {

      this.checkedRows = []
      if (this.entityId && this.entityType.toLowerCase().indexOf('activity') != -1) {

        this.isLoading = true
        let self = this
        axios.get("/api/v2/activity/" + this.entityId + "/selectable-documents?filter=drawings")
          .then(function(response) {
            self.documents = response.data.documents
            self.isLoading = false
          })
          .catch((error) => {
            self.isLoading = false
          })
      }
    },

    getReadableFileSizeString(fileSizeInBytes) {
      var i = -1;
      var byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
      do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
      } while (fileSizeInBytes > 1024);
      return Math.max(fileSizeInBytes, 0.01).toFixed(2) + byteUnits[i];
    },

    handleSelectClick(document) {
      if( document.project_document_scale == null ) {
        this.$notify.info('El plano no tiene una escala definida contacte con su supervisor.')
        // return
      }

      this._selectedDocument = document
      this.selectedDocumentId = document.document_id

      this.$emit('update:selectedDocument', document)
    },
  },
};
</script>

<style scoped>

.file-preview-frame {
  /* margin: 8px; */
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 6px;
  float: left;
  text-align: center;
}

.file-preview-frame:not(.file-preview-error):hover {
    border: 1px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}

.file-preview-frame .kv-file-content {
    /* width: 213px; */
    height: 160px;
}

.file-preview-other-frame, .file-preview-object, .kv-file-content, .kv-zoom-body {
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-preview-frame .file-thumbnail-footer {
    height: 70px;
    position: relative;
}

.file-footer-caption {
    display: block;
    text-align: center;
    padding-top: 4px;
    font-size: 11px;
    color: #999;
    margin-bottom: 30px;
}

.file-caption-info, .file-size-info {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
    height: 15px;
    margin: auto;
}

.file-drag-handle, .file-upload-indicator {
    float: left;
    margin-top: 10px;
    width: 16px;
    height: 16px;
}

.file-error-message pre, .file-error-message ul, .krajee-default .file-actions, .krajee-default .file-other-error {
    text-align: left;
}

.krajee-default .file-footer-buttons {
    float: right;
}

</style>
