<template>
  <div class="panel">
    <div class="w-full uppercase text-blue-light font-thin text-lg">
      {{ $t('progress') }}
      <span class="font-semibold">{{ currentPercentage }}%</span>
    </div>
    <div
      v-if="isLoading"
      class="w-full my-8 text-center text-green font-semibold"
      style="font-size:6rem"
    >
      <scale-loader></scale-loader>
    </div>
    <div v-else class="w-full my-8 text-center text-green font-semibold" style="font-size:6rem">

      <template v-if="chartType == 'childrenProgress'">
        <pie-chart :min="0" :max="100" suffix="%" :data="updatedDataset" :title="$t('progress_chart_title_1')" />
        <column-chart :min="0" :max="100" suffix="%" :data="dataset2" :title="$t('progress_chart_title_2')" />
      </template>
      <template v-else>
        <!-- activityProgress -->
        <area-chart :min="0" :max="100" suffix="%" :data="updatedDataset" />
      </template>
    </div>
    <template v-if="chartType == 'activityProgress'">
      <label>{{ $t('change_scale')}}</label>
      <v-select
        title="step_title"
        select-name="step_name"
        select-id="step_id"
        :selectedValue="0"
        :data="step"
        v-on:updateValue="makeSteps"/>
    </template>
  </div>
</template>

<script>
import Vue from "vue";
import EventBus from "~/plugins/bus";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import VueChartkick from "vue-chartkick";
import Chart from "chart.js";
import moment from "moment";
import axios from "axios";
Vue.use(VueChartkick, { adapter: Chart });
Vue.use(moment);

export default {
  name: "activity-progress",

  components: {
    ScaleLoader
  },
  props: {
    isLoading: { type: Boolean, required: true },
    percentage: { type: Number, required: true },
    activityId: { type: Number, required: true }
  },

  data: () => ({
    currentPercentage: 0,
    variable: "",
    originalDataset: null,
    updatedDataset: null,
    dataset2: null,
    chartType: 'activityProgress',
    step: [{id: 0, name: 'complete'}, {id: 1, name: 'days'}, {id: 2, name: 'weeks'}, {id: 3, name: 'months'}, {id: 4, name: 'years'}, {id: 5, name: 'only_positives'}]
  }),

  created() {
    // Referenciamos
    var self = this;
    this.currentPercentage = this.percentage;
    // Pedimos el progreso
    this.getActivityProgress();

    // Si recibimos el evento refresh, refrescamos el componente
    EventBus.$on("refreshProgress", function() {
      self.getActivityProgress();
    });
  },

  // beforeDestroy(){  // comentado, si no no refresca grafico cuando es llamado en activity/home.vue assignmetsReloadEvent
  // 	EventBus.$off("refreshProgress")
  // },

  methods: {
    async getActivityProgress() {
      const { data } = await axios.get(
        "/api/v2/activity/" + this.activityId + "/progress"
      );
      if (data && data.success) {
        this.originalDataset = data.data;
        this.updatedDataset = data.data;
        this.dataset2 = data.data2;
        if (data.chartType) this.chartType = data.chartType;
        let oldPercentage = this.currentPercentage;
        this.currentPercentage = data.percentage;

        //Si el porcentaje ha cambiado y ha subido o bajado de 100, alertamos
        if (
          this.currentPercentage != oldPercentage &&
          (oldPercentage == 100 || this.currentPercentage == 100)
        )
          this.$emit("reload");
      }
    },
    // Metodo para poder visualizar la gráfica de progreso
    // filtrada por días, semanas, meses, años o solo valores positivos
    makeSteps(step){
      const complete = "0", days = "1", weeks = "2", months = "3", years = "4", positives = "5";
      let newdataset = [];
      let contador = 0;
      let actualDay, actualWeek, actualMonth, actualYear;

      switch (step) {
        case complete:
          this.getActivityProgress();
          break;

        case days:
          // Obtengo la primera semana del dataset para empezar a comparar
          actualDay = moment(Object.keys(this.originalDataset)[0]).dayOfYear().toString();

          Object.entries(this.originalDataset).forEach(fecha => {
            // si estoy en el mismo dia
            if(actualDay == moment(fecha[0]).dayOfYear().toString()){
              newdataset[contador] = fecha;
            } else {
              actualDay = moment(fecha[0]).dayOfYear().toString();
              contador++;
              newdataset[contador] = fecha;
            }
          });

        case weeks:
          // Obtengo la primera semana del dataset para empezar a comparar
          actualWeek = moment(Object.keys(this.originalDataset)[0]).week().toString();

          Object.entries(this.originalDataset).forEach(fecha => {
            // si estoy en la misma semana
            if(actualWeek == moment(fecha[0]).week().toString()){
              newdataset[contador] = fecha;
            } else {
              actualWeek = moment(fecha[0]).week().toString();
              contador++;
              newdataset[contador] = fecha;
            }
          });

          break;
        case months:
          // Obtengo el primer mes del dataset para empezar a comparar
          actualMonth = moment(Object.keys(this.originalDataset)[0]).month().toString();

          Object.entries(this.originalDataset).forEach(fecha => {
            // si estoy en la misma semana
            if(actualMonth == moment(fecha[0]).month().toString()){
              newdataset[contador] = fecha;
            } else {
              actualMonth = moment(fecha[0]).month().toString();
              contador++;
              newdataset[contador] = fecha;
            }
          });

          break;
        case years:
          // Obtengo el primer año del dataset para empezar a comparar
          actualYear = moment(Object.keys(this.originalDataset)[0]).year().toString();

          Object.entries(this.originalDataset).forEach(fecha => {
            // si estoy en la misma semana
            if(actualYear == moment(fecha[0]).year().toString()){
              newdataset[contador] = fecha;
            } else {
              actualYear = moment(fecha[0]).year().toString();
              contador++;
              newdataset[contador] = fecha;
            }
          });

          break;

        case positives:

          // No muestra el progreso negativo
          Object.entries(this.originalDataset).forEach(fecha => {

            if (contador == 0) {

              newdataset.push(fecha);// fecha[0]: fecha, fecha[1]: progreso

            } else {

              let previousValue = parseInt(newdataset[newdataset.length-1][1]);
              if (parseInt(fecha[1]) >= previousValue) {
                newdataset.push(fecha);
              }

            }
            contador++;
          });

          break;
      }
      // Los nuevos datos filtrados pasan al dataset de la gráfica
      this.updatedDataset = newdataset;

    }
  }
};
</script>
