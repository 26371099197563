<template>
  <div>
    <div class="w-full flex justify-end">
      <div class="flex">
        <v-button @click="create = !create;
        measureCreate.id = 0;
        measureCreate.name = '';
        measureCreate.description = '';
        measureCreate.type= 'number'"
        class="btn btn-blue rounded" icon="plus">{{ $t('add_measure') }}
      </v-button>
    </div>
  </div>
  <div class="panel" v-if="create || measureCreate.id">
    <div>
      <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('create_measure') }}</h2>
      <form @submit.prevent="createmeasure">
        <div class="w-full justify inline-flex">
          <b-field :label="$t('name')" class="md:w-1/5 mr-4">
            <b-input v-model="measureCreate.name" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('description')" class="md:w-1/5 mr-4">
            <b-input v-model="measureCreate.description" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('UGM')" class="md:w-1/5 mr-4">
            <b-select :placeholder="$t('select_type')" v-model="measureCreate.type" required>
              <option value="number">{{ $t('number') }}</option>
              <option value="date">{{ $t('date') }}</option>
              <option value="hours">{{ $t('hours') }}</option>
            </b-select>
          </b-field>
          <b-field label="  " class="md:w-1/5 mr-4 mt-4">
            <v-button class="btn btn-primary" nativeType="submit" icon="save" :disabled="!measureCreate.name">{{ $t('save') }}</v-button>
          </b-field>
        </div>
      </form>
    </div>
  </div>
  <div class="panel">
    <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('measure_list') }}</h2>
    <div class="panel">
      <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('filter') }}</h2>
      <form @submit.prevent="filtermeasure">
        <div class="w-full justify inline-flex">
          <b-field :label="$t('name')" class="md:w-1/5 mr-4">
            <b-input v-model="measureFilter.name" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('type')" class="md:w-1/5 mr-4">
            <b-select :placeholder="$t('select_type')" v-model="measureFilter.type" required>
              <option value="number">{{ $t('number') }}</option>
              <option value="date">{{ $t('date') }}</option>
              <option value="hours">{{ $t('hours') }}</option>
            </b-select>
          </b-field>
          <b-field label="  " class="md:w-1/5 mr-4 mt-4">
            <v-button class="btn btn-primary" nativeType="submit"><b-icon pack="fas" icon="search"/></v-button>
          </b-field>
        </div>
      </form>
    </div>
    <div class="w-full flex flex-wrap mt-4">
      <section class="w-full">
        <b-table
        :data="measures"
        :paginated="isPaginated"
        :per-page="perPage"
        :bordered="true"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :default-sort-direction="defaultSortDirection"
        v-if="loaded">
        <template slot-scope="props">
          <b-table-column field="name" :label="$t('name')" sortable width=100>
            {{ props.row.name }}
          </b-table-column>

          <b-table-column field="description" :label="$t('description')" sortable>
            {{ props.row.description }}
          </b-table-column>

          <b-table-column field="type" :label="$t('type')" sortable width=200>
            {{ props.row.type }}
          </b-table-column>

          <b-table-column field="actions" :label="$t('actions')" sortable width=100 >
            <div class="justify inline flex">
              <button @click="getmeasure(props.row.id)"><b-icon pack="fas" icon="pencil-alt" type="is-primary" /></button>
            </div>
          </b-table-column>
        </template>
      </b-table>
    </section>
  </div>
</div>
</div>
</template>

<script>
import Form from 'vform'
import axios from 'axios'

export default {
  middleware: 'auth',
  title: 'users_page_title',
  subtitle: 'users_page_subtitle',
  menuOption: '0-3',

  data: () => ({
    project_id: 0,
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 5,
    measures: [],
    loaded: false,
    projects: null,
    create: false,

    measureFilter: new Form ({
      name: '',
      type: 'number',
    }),
    measureCreate: new Form ({
      id: 0,
      name: '',
      description: '',
      type: 'number',
    }),
  }),

  mounted () {
    this.project_id = parseInt(this.$route.params.project_id)
    this.getmeasures()
  },

  methods: {

    async filtermeasure () {
      if (! this.measureFilter) {
        return;
      }
      console.log(this.measureFilter)
      const url = '/api/v2/report/measure/' + this.project_id + '/filter'
      const { data } = await this.measureFilter.post(url)
      if (data.data && data.success) {
        this.measures = data.data
      }
    },

    async createmeasure () {
      if (! this.measureCreate) {
        return;
      }
      var url
      if(this.measureCreate.id){
        url = '/api/v2/report/measure/' + this.measureCreate.id
      }else{
        url = '/api/v2/report/measure/' + this.project_id + '/add'
      }
      const { data } = await this.measureCreate.post(url)
      if (data.data && data.success) {
        this.measures = data.data
        this.measureCreate.name = ''
        this.measureCreate.description = ''
        this.measureCreate.type = 'number'
        if(this.measureCreate.id){
          this.getmeasures()
          // Hago esto pq extiende de la clase padre y no me retorna la lista completa de nuevo.
        }
        this.measureCreate.id = 0
        this.success('measure_save')

      }else{
        this.error('error_creating_measure')

      }
    },

    async getmeasures(){
      const url = '/api/v2/report/measure/' + this.project_id + '/list'
      const { data } = await axios.get(url)
      if (data.data && data.success) {
        this.measures = data.data
        this.loaded = true
      } else {
        this.error('error_retreiving_measures')

      }
    },

    async getmeasure(id){
      const url = '/api/v2/report/measure/' + id
      const { data } = await axios.get(url)
      if (data) {
        //Object.assign(this.measureCreate,data.data)
        this.measureCreate.id = id
        this.measureCreate.name = data.name
        this.measureCreate.description = data.description
        this.measureCreate.type = data.type

      } else {
        this.error('error_retreiving_measure')
      }
    },

    /*
     ALERTAS
     */
     success(message){
      this.$toast.open({
        message: message,
        type: 'is-success',
        position: 'is-top-right'
      })
    },
    error(message){
      this.$toast.open({
        message: message,
        type: 'is-danger',
        position: 'is-top-right'
      })
    }
  },
  watch:{

  }

};
</script>
