<template>
<div
  :class="{
    [`bg-${color}`]: true,
    [`border-${color}-dark`]: true,
  }"
  class="border rounded shadow overflow-hidden">
  <!-- Título -->
  <div
    :class="{ [`bg-${color}-light`]: true }"
    class="w-full p-2 text-center text-grey-lightest uppercase font-medium  truncate">
    {{ $t(title) }}
  </div>
  <!-- Cuerpo -->
  <div class="relative w-full inset-0">
    <div class="absolute inset-0 pl-1 flex items-center">
      <b-icon :icon="iconName" size="is-large" class="w-16 h-16" :class="{ [`text-${color}-light`]: true }" />
    </div>
    <div class="relative inset-0 text-right block p-1">
      <div class="w-full text-4xl text-grey-lightest font-bold">{{ quantity }}</div>
      <div class="w-full text-sm truncate" :class="{ [`text-${color}-lighter`]: true }">{{ $t(subtitle) }}</div>
    </div>
  </div>
  <!-- Pie -->
  <div
    :class="{ [`bg-${color}-dark`]: true, [`hover:bg-${color}-darker`]: true }"
    class="w-full text-center text-grey-lightest font-bold py-2 flex items-center justify-center cursor-pointer"
    @click="onClick"
  >
    {{ $t('access') }}
    <b-icon icon="arrow-right" size="is-small" class="ml-2" />
  </div>
</div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, default: '' },
    quantity: { type: String, default: '0' },
    iconName: { type: String, required: true },
    color: { type: String, default: 'grey' },
    buttonTitle: { type: String, default: 'access' },
    route: { type: Object, default: null },
  },

  methods: {
    onClick() {
      if (this.route) {
        this.$router.push(this.route)
      }
    },
  },

};
</script>
