<template>
  <div>
    <ViewerPageKonva v-if="pictogram"
      :canActionsPictogram="canActionsPictogram"
      :pictogram="pictogram"
      :work-layer="workLayer"
      :activityId="activityId"
      :subactivityId="subactivityId"
      @epc-reload="() => { $emit('epc-reload') }"
      @epc-screenshot="(e) => { $emit('epc-screenshot', e) }"></ViewerPageKonva>
  </div>
</template>

<script>

import axios from 'axios'
import ViewerPageKonva from '../../components/pictograms/viewer-pixi/ViewerPageKonva.vue'


export default {
  middleware: "auth",
  title: "pictogram",

  components: {
    ViewerPageKonva
  },

  created () {

  },

  mounted() {
    this.workLayer = this.$route.params.wl // wl = worklayer
    this.readPictogram(this.$route.params.pictogramId);

    this.activityId = this.$route.params.activity ?? null;
    this.subactivityId = this.$route.params.subactivity ?? null

    if(this.activityId != null)
      this.activityId = parseInt(this.activityId)

    if(this.subactivityId != null)
      this.subactivityId = parseInt(this.subactivityId)


  },

  props: {

  },

  // data: () => ({
  data() {
    return {
      pictogram: null,
      workLayer: null, // // capa donde  trabajará el usuario, solo permite trabajar en esa capa si null trabaja en todas
      activityId: null,
      subactivityId: null,
      canActionsPictogram: {
        'save': true,
        'select_entity': true
      }
    }
  },

  methods: {

    async readPictogram(pictogramId) {
      // Submit the form.
      let data = null

      await axios.get('/api/v2/pictogram/' + pictogramId)
        .then(response => (data = response.data))
        .catch(error => console.log(error));

      //const { data } = await this.form.post('api/v2/login')

      if (data && data.success) {
        this.pictogram = data.pictogram
      }
    }

  }
};
</script>
