<template>
<div>
  <div class="panel p-2 relative">
    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>

    <div class="flex items-center justify-between w-full mb-5">
      <div class="flex flex-col">
        <div><b>Actividades vinculadas al grupo</b></div>
        <div><label><b>Grupo: </b>{{ group.name }}</label></div>
      </div>
      <div>
        <b-tooltip :label="$t('close')" position="is-left">
          <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
            <b-icon pack="fas" icon="times" size="is-small" />
          </div>
        </b-tooltip>
      </div>
    </div>

    <div><label><b>{{ $t('activities') }}</b></label></div>
    <div class="w-full inline-flex pb-3">
      <multiselect
        v-model="activitiesSelected"
        :options="activities"
        :placeholder="$t('activities')"
        :disabled="disabled"
        :multiple="true"
        label="name"
        class="rounded-r-none"
        searchable
        allowEmpty
        :closeOnSelect="false"
        :showLabels="false"
        :loading="processingData"
        track-by="id"
      >
          <!--<span slot="placeholder" class="text-base">-->
          <!--{{ $t('parent_department') }}-->
          <!--</span>-->
          <!-- <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template> -->
          <!-- <template slot="singleLabel" slot-scope="props">
            <img class="option__image" :src="props.option.img" alt="No Man’s Sky">
            <span class="option__desc"><span class="option__title">{{ props.option.title }}</span></span>
          </template> -->
          <!-- <template slot="option" slot-scope="props">
            <div class="option__desc"><span class="option__title">{{ props.option.plannig_code }}</span><span class="option__small">{{ props.option.name }}</span></div>
          </template> -->
      </multiselect>

    </div>

    <div class="flex items-center justify-end w-full">
      <v-button v-if="!processingData"
        @click="linkGroup()"
        icon="plus"
      >{{ $t('link_group') }}</v-button>

      <v-button-processing v-if="processingData" />
    </div>
  </div>
</div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import Multiselect from 'vue-multiselect'


export default {
  name: "activity-group-link",

  components: {
    Multiselect
  },

  props: {
    projectId: { type: Number, required: true },
    group: { type: Object, default: null },
    entityType: { type: String, default: 'project' },
  },

  data() {
    return {
      disableSaveButton: false,
      processingData: false,
      disabled: false,
      isLoading: false,
      activities: [],
      activitiesSelected: [],
    }
  },

  computed: {

  },

  watch: {
  },

  mounted() {
    // TODO: refactorizar hacer que solo llame a un method
    if( this.entityType === 'project-template' )
      this.getActivityTemplates();
    else
      this.getActivities();
  },

  methods: {

    async getActivities(){
      this.isLoading = true
      const { data } = await axios.get("/api/v2/activities", {'params' : { p : this.projectId, light: 1, group_id: this.group.id, add_without_groups: 1, per_page: 999}});
      this.activities = data.activities;
      // this.activities = data.activities.filter((activity) => { return activity.group_id == null });
      this.activitiesSelected = data.activities.filter((activity) => { return activity.group_id == this.group.id });

      this.isLoading = false
    },

    async getActivityTemplates(){
      this.isLoading = true
      const { data } = await axios.get("/api/v2/activity/templates", {'params' : { p : this.projectId, light: 1, group_id: this.group.id, add_without_groups: 1, per_page: 999}});
      this.activities = data.activities;
      // this.activities = data.activities.filter((activity) => { return activity.group_id == null });
      this.activitiesSelected = data.activities.filter((activity) => { return activity.group_id == this.group.id });

      this.isLoading = false
    },


    onCloseClicked() {
      this.$emit('epc-close', true)
    },

    handleEditEntity(entity) {

    },

    async linkGroup() {
      if (!this.activitiesSelected) {
        this.$notify.error(this.$t('empty_field_required', ['DSO']))
        return
      }

      this.processingData = true;

      let activities = [];
      this.activitiesSelected.forEach(activity => {
        activities.push(activity.id);
      });

      let self = this;

      await axios.post(`/api/v2/activity/groups/${this.group.id}/link`, {
        activities: activities,
        entity_type: this.entityType,
      })
      .then(response => {
        let data = response.data
        this.processingData = false;

        if (data && data.success) {
          // reload

          self.$notify.success('success_editing')

        } else if(data && data.msg) {
          self.$notify.error('no_access_permissions')
        } else {
          self.$notify.error('error_saving')
        }
      })
      .catch(error => {
          self.processingData = false;
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
                ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("assignment_insert_error"),
            type: "is-danger",
            position: "is-top-right"
          });
      });
    }
  },
};
</script>

