<template>
  <li>
    <div class="detail"></div>
    <div class="detail detail-main">
      <fieldset class="relative mt-3">
        <legend class="absolute -top-3">
          <span class="text-xs px-3 text-white rounded-full" style="background-color:#5577BB">{{ $t('chapter') + ' ' + node.code }}</span>
        </legend>
        <div>
          <subchapters
            :listChapters="node.children"
            :showingCertifiers="node.hasCertifiers || showingCertifiers"
          />
        </div>
      </fieldset>
    </div>
  </li>
</template>

<script>

export default {
  components: {
  },

  props: {
    node: { type: Object, require: true },
    showingCertifiers: { type: Boolean, required: false, default: false }, // para titulo de la tabla 'capitulo' o 'certificadoras'
  },

  data () {
    return {

    }
  },

  beforeCreate() {
    this.$options.components.subchapters = require('./chapters-table').default
  },
}
</script>
