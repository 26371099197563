<template>
  <div class="flex-col">
    <!-- <manage-photovoltaic-park v-if="project"
      ref="refManagePhotovoltaicPark"
      :projectId="project.id"
      :can-actions-pictogram="canActionsPictogram"
    ></manage-photovoltaic-park> -->

    <progress-photovoltaic-park v-if="project"
      ref="refProgressPhotovoltaicPark"
      :projectId="project.id"
      :can-actions-pictogram="canActionsPictogram"
    ></progress-photovoltaic-park>


  </div>
  </template>

  <script>
  import Vue from 'vue'
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
  import ManagePhotovoltaicPark from '../../components/pictograms/manage-photovoltaic-park.vue'
  import ProgressPhotovoltaicPark from '../../components/pictograms/progress-photovoltaic-park.vue'

  export default {
    middleware: 'auth',
    title: 'project_home_page_title',
    subtitle: 'project_home_page_subtitle',
    menuOption: '2-1',

    components: {
      ScaleLoader,
      ManagePhotovoltaicPark,
      ProgressPhotovoltaicPark
    },

    data: () => ({
      canActionsPictogram: {
        'mode_dashboard': true,
        'show_header': false,
        'save': false,
        'select_entity': false
      }
    }),

    computed: {
      ...mapGetters({
        project: 'app/project',
        user: 'auth/user',
      }),
    },

    mounted() {

    },

    methods: {

    },

  };
  </script>

  <style scoped>

  </style>
