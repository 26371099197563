<template>
    <div class="panel">
      <!-- Selector de actividades -->
      <div><label><b>{{ $t('select_dso') }}</b></label></div>
      <div class="w-full inline-flex pb-3">
        <b-select ref="input3"
          v-model="activity"
          :placeholder="$t('select_dso')"
          icon="clipboard-list"
          icon-pack="fas"
          expanded
        >
          <optgroup label="DSOs">
            <option v-for="(option, indexActivities) in activities"
            v-bind:value="{ id: option['id'], name: option['name'], type: 'activity', folders: option['folders'] }"
            :key="'activity_'+ indexActivities">
              <span v-if="option['is_subactivity']">&nbsp;&nbsp;</span>
              {{ option['name'] }}
            </option>
          </optgroup>
        </b-select>
      </div>

      <!-- Selector de sub actividades -->
      <div><label><b>{{ $t('select_subactivity') + ( requiredSubActivity ? '' : (' (' + $t('optional') + ')')) }}</b></label></div>
      <div class="w-full pb-3">
        <b-select ref="input6"
          v-model="subactivity"
          :placeholder="$t('select_subactivity')"
          icon="clipboard-list"
          icon-pack="fas"
          expanded
        >
          <option v-for="(option, indexsubactivities) in subactivities"
          v-bind:value="{ id: option['id'], name: option['name'], type: 'activity'}"
          :key="'subactivity_'+ indexsubactivities">
            {{ option['name'] }}
          </option>
        </b-select>
      </div>
      <div class="flex items-center justify-end w-full">
        <v-button v-if="!processingData"
          @click="_onClickButton()"
          icon="link"
        >{{ $t(titleButton) }}</v-button>

        <v-button-processing v-if="processingData" />
      </div>
    </div>
  </template>

  <script>
  import axios from "axios";
  import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";


  export default {
    name: "linked-entities",

    components: {
      ScaleLoader
    },

    props: {
      projectId: { type: Number, required: true },
      activityIdSelected: { type: Number, required: false, default: 0 }, // identificador de la actividad seleccionada por defecto
      titleButton: { type: String, required: false, default: 'select' }, // titulo del boton
      iconButton: { type: String, required: false, false: 'save' }, // icon del botton
      requiredSubActivity: { type: Boolean, default: false }, // para indicar que el campo subactivity es obligatorio por defecto lo es el campo activity
      actionButton: { type: Function, required: true } // para indicar que el campo subactivity es obligatorio por defecto lo es el campo activity
    },

    data() {
      return {
        disableSaveButton: false,
        processingData: false,
        loadingAssigned: false,
        linkedEntities: [],

        // propiedades para manejar las vinculaciones
        activity: {},
        activities: [],
        subactivity: {},
        subactivities: [],
        errorFields: {}
      }
    },

    computed: {

    },

    watch: {
      // Cargar subactividades, cuando seleccione actividad
      // evento @click de b-select no funciona en chrome, detectamos el cambio por watch
      activity: function() {
        if (this.activity) {
          this.getSubactivities(this.activity.id);
        }
      },
    },

    mounted() {
      this.getActivities()

      this.errorFields = {
        activity: 'DSO',
        subactivity: this.$t('subactivity'),
      };
    },

    methods: {
      async getActivities(){
        const { data } = await axios.get("/api/v2/activities", {'params' : { p : this.projectId, with_folders: 1, per_page: 999}});

        this.activities = data.activities;

        // para activar la actividad seleccionada
        if (self.activityIdSelected > 0) {
          let self = this;
          this.activities.forEach(item => {
            if( self.activityIdSelected == item.id ){
              self.activity = { id: item.id, name: item.name, type: 'activity', folders: item.folders }
            }
          });
        }
      },

      async getSubactivities(activityId) {
        this.subactivity = {}
        const { data } = await axios.get('/api/v2/activity/' + activityId + '/subactivities', {params : {}});
        this.subactivities = data.subactivities || [];
      },

      processData(inprocess) {
        this.processingData = inprocess
      },

      _onClickButton() {
        // Solo comprobamos que se a seleccionado una actividad, sub actividad es opcional de momento

        let model = 'activity'
        if (this.requiredSubActivity) model = 'subactivity'
        // if (this.requiredSubActivity && Object.keys(this.subactivity).length > 0) model = 'subactivity'
        if (!this[model].id) {
          this.$notify.error(this.$t('empty_field_required', [this.errorFields[model]]))
          return
        }
        let selectedEntities = []
        selectedEntities.push( this.subactivity.id ? this.subactivity.id : this.activity.id )
        this.$emit('epc-selected-entities', selectedEntities)

        this.actionButton()
      }
    },
  };
</script>



