<template>
    <b-modal :active.sync="isModalActive" :width="1028" scroll="keep" :can-cancel="canCancel" @close="closeModal">
        <div class="border border-blue-light rounded shadow bg-white p-4">
            <form @submit.prevent="saveChanges" class="w-full">
                <header class="font-thin uppercase text-blue-light modal-card-head_ mb-4">Añadir / editar importacion
                </header>
                <div class="modal-card-body_ relative">
                    <div class="border-grey-light border-b border-dashed mb-3">
                        <div class="w-full flex flex-wrap">
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('group_code') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.group_code">
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('planning_code') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.planning_code">
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('new_planning_code') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.new_planning_code">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full flex flex-wrap">
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('parent_activity_planning_code') }}</label>
                                    <div class="control">
                                        <input class="input" type="text"
                                            v-model="formData.parent_activity_planning_code">
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('name') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.name">
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('in_planning') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.in_planning">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full flex flex-wrap">
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('critical_path') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.critical_path">
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('scheduled_start_date') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.scheduled_start_date">
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('scheduled_end_date') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.scheduled_end_date">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full flex flex-wrap">
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('current_start_date') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.current_start_date">
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('current_end_date') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.current_end_date">
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('progress') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.progress">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full flex flex-wrap">
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('weight') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.weight">
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('email_manager') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.email_manager">
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('location_reference_code') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.location_reference_code">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full flex flex-wrap">
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('participants') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.participants">
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('collaborators') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.collaborators">
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('tags') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.tags">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full flex flex-wrap">
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('status') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.status">
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('show_on_devices') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.show_on_devices">
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('description') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.description">
                                    </div>
                                </div>
                            </div>
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('document_codes') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.document_codes">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full flex flex-wrap">
                            <div class="w-1/3 px-4 mb-3">
                                <div class="field">
                                    <label class="label">{{ $t('flow_codes') }}</label>
                                    <div class="control">
                                        <input class="input" type="text" v-model="formData.flow_codes">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <b-loading :is-full-page="false" v-model="processingData" :can-cancel="false"></b-loading>
                    </div>
                </div>
                <footer class="modal-card-foot_">
                    <div class="w-full inline-flex justify-end">
                        <button class="btn btn-blue" nativeType="submit" v-if="!processingData">{{
                            $t('save')
                            }}</button>
                        <v-button-processing v-if="processingData" />
                    </div>
                </footer>
            </form>
        </div>
    </b-modal>
</template>

<script>
export default {
    props: {
        rowData: {
            type: [],
        }
    },
    data() {
        return {
            isModalActive: false,
            canCancel: ['escape', 'x', 'outside'],
            processingData: false,
            formData: {},
        }
    },
    methods: {
        show(rowData) {
            this.formData = { ...rowData };
            this.isModalActive = true;
        },
        closeModal() {
            this.isModalActive = false;
            this.$emit('update:show', !this.show)
        },
        saveChanges() {
            this.$emit('saved', this.formData);
            this.closeModal();
        }

    },
}
</script>

<style lang="scss" scoped></style>