<template>
  <div :style="nodeMargin">
    <div
        @click="toggleChildren(node)"
        :class="node.is_current ? 'flex items-center cursor-pointer mt-1 bg-blue-lightest':'flex items-center cursor-pointer mt-1'"
    >
      <b-icon 
        :icon="showChildren ? 'folder-open': 'folder'" 
        pack="fas" 
        size="is-small" 
        :class="node.is_current ? 'text-blue':'text-yellow'" 
        style="margin-right: 8px"
      >
      </b-icon>
      {{ node.name }}
    </div>
    <div v-if="hasChildren" v-show="showChildren">
      <FolderTree
        v-for="(child, index) in node.children"
        :key="child.name + child.id"
        :indx="[...indx, index]"
        :node="child"
        :spacing="spacing + 15"
      />
    </div>
  </div>
</template>

<script>
import EventBus from "~/plugins/bus";

export default {
  name: 'FolderTree',
  props: {
    node: {
      type: Object,
      required: true
    },
    spacing: {
      type: Number,
      default: 0
    },
    indx: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showChildren: false
    }
  },
  computed: {
    nodeMargin() {
      return {
        'margin-left': `${this.spacing}px`
      }
    },
    hasChildren() {
      const { children } = this.node
      return children && children.length > 0
    },
  },
  methods: {
    toggleChildren(folder) {
      if (!this.showChildren) {
        this.openFolder(this.indx, folder);
      }
      this.showChildren = !this.showChildren
    },

    openFolder(index, folder) {
      EventBus.$emit('openFolder', {index, folder});
    }
  }
}
</script>