<template>
  <div class="w-full mt-8">
    <div class="flex flex-row w-full text-blue font-bold">{{ $t(dataset.name) }}</div>
    <div class="flex flex-row w-full text-blue font-bold">Total: {{ dataset.total }}</div>
    <div class="flex flex-row w-full text-blue font-bold">Ejecutados: {{ dataset.executed }}</div>
    <div class="flex flex-row w-full"><column-chart :min="0" :max="100" :suffix="'%'" :data="dataset.data" /></div>
  </div>
</template>

<script>

  export default {
    name: "ViewerChartsetPictogram",
    components: {
    },

    props: {
      dataset: { type: Object, default: { } }
    },

    watch: {
    },

    data() {
      return {
        panel1: null,
      }
    },

    methods: {

    }
  }

</script>

<style scoped>

</style>
