<template>
<b-field
  :label="label ? $t(label) : null"
  :type="form.errors.has(field) ? 'is-danger' : ''"
  :message="form.errors.has(field) ? form.errors.get(field) : null">
  <b-input ref="input"
    v-model="form[field]"
    :type="inputType"
    :disabled="disabled"
    :placeholder="$t(_placeholder)"
    :autocomplete="autocomplete"
    :password-reveal="passwordReveal"
    :size="size"
    :icon="icon"
    icon-pack="fas"
    :maxlength="maxlength"
  />
</b-field>
</template>

<script>
export default {
  name: 'l-form-input',

  props: {
    // The vform object
    form: { type: Object, required: true },
    // The field name
    field: { type: String, required: true },
    // Key for the label text
    label: { type: String, default: null },
    // Input autocomplete
    autocomplete: { type: String, default: null,
      validator: value => {
        return ['on', 'off'].includes(value)
      }
    },
    // Key for the input placeholder text
    placeholder: { type: String, default: null },
    // Input html type (text, email, password...)
    inputType: { type: String, default: 'text' },
    // Disabled input
    disabled: { type: Boolean, default: false },
    // Password reveal
    passwordReveal: { type: Boolean, default: null },
    // Input size class
    size: { type: String, default: null },
    // Input icon name
    icon: { type: String, default: null },
    // Text area max length
    maxlength: { type: Number, default: null },
  },

  computed: {
    _placeholder() {
      return this.placeholder ? this.placeholder : this.label
    }
  },

  methods: {
    setFocus() {
      this.$refs.input.focus()
    }
  },

};
</script>
