import axios from "axios"

export class DataPictogram {

    /**
     * @param options Some options can be overridden if specified. See DxfViewer.DefaultOptions.
     */
    constructor() {
      this.scene = null
      this.entities = []
      this.labels = []
      this.bounds = null
      this.origin = null
      this.layers = []
    }

    GetLabels() {
        return this.labels
    }

    /** Load DXF into the viewer. Old content is discarded, state is reset.
     * @param url {string} DXF file URL.
     * @param fonts {?string[]} List of font URLs. Files should have typeface.js format. Fonts are
     *  used in the specified order, each one is checked until necessary glyph is found. Text is not
     *  rendered if fonts are not specified.
     * @param progressCbk {?Function} (phase, processedSize, totalSize)
     *  Possible phase values:
     *  * "font"
     *  * "fetch"
     *  * "parse"
     *  * "prepare"
     * @param workerFactory {?Function} Factory for worker creation. The worker script should
     *  invoke DxfViewer.SetupWorker() function.
     */
    async Load({url, progressCbk = null, workerFactory = null}) {

      const {data} = await axios.get(url).catch(error => {
        console.error('Error al obtener el archivo remoto:', error);
      });;

      // TODO: optimizar esta logica
      if( data && data.success ) {
        await fetch(data.data + '?' + Date.now())  // anadir timestamp para evitar cache
        .then(response => {
          if (!response.ok) {
            throw new Error('Error de red: ' + response.status);
          }
          // return response;
          return response.json();
        })
        .then(design => {
          // Manipular los datos JSON aquí
          // let design = JSON.parse(response.data.data)
          this.scene = design

          // TODO esto debe ser eliminado en el futuro, antes analziar si es necesario
          this.entities = design.entities
          this.labels = design.texts
          this.bounds = design.bounds
          this.origin = design.origin
          this.layers = design.layers
        })
        .catch(error => {
          console.error('Ocurrió un error al obtener el archivo JSON:', error);
        });


        console.log('%cEPC-TACKER: '+ '%c KonvaViewer.js datos cargados correctamente', 'background: #5577BB; color: #fff', 'color: #000')
      } else {
        console.error('%cEPC-TACKER: '+ '%c KonvaViewer.js datos no cargados', 'background: #5577BB; color: #fff', 'color: #000')
      }
    }

    SetDataPictogram(design) {
      this.scene = design

      // TODO esto debe ser eliminado en el futuro, antes analziar si es necesario
      this.entities = design.entities
      this.labels = design.texts
      this.bounds = design.bounds
      this.origin = design.origin
      this.layers = design.layers
    }

    GetScene() {
      return this.scene
    }

    /** @return {Iterable<{name:String, color:number}>} List of layer names. */
    GetLayers() {
        const result = []
        for (const lyr of this.layers.values()) {
            result.push({
                name: lyr.name,
                displayName: lyr.displayName,
                color: this._TransformColor(lyr.color)
            })
        }
        return result
    }

    ShowLayer(name, show) {
        this._EnsureRenderer()
        const layer = this.layers.get(name)
        if (!layer) {
            return
        }
        for (const obj of layer.objects) {
            obj.visible = show
        }
        this.Render()
    }

    /** Reset the viewer state. */
    Clear() {
        // this._EnsureRenderer()
        // if (this.worker) {
        //     this.worker.Destroy(true)
        //     this.worker = null
        // }
        // if (this.controls) {
        //     this.controls.dispose()
        //     this.controls = null
        // }
        // this.scene.clear()
        // for (const layer of this.layers.values()) {
        //     layer.Dispose()
        // }
        // this.layers.clear()
        // this.blocks.clear()
        // this.materials.each(e => e.material.dispose())
        // this.materials.clear()
        // this.SetView({x: 0, y: 0}, 2)
        // this._Emit("cleared")
        // this.Render()
    }

    /** Free all resources. The viewer object should not be used after this method was called. */
    Destroy() {
        // if (!this.HasRenderer()) {
        //     return
        // }
        // if (this.resizeObserver) {
        //     this.resizeObserver.disconnect()
        // }
        this.Clear()
        // this._Emit("destroyed")
        // for (const m of this.simplePointMaterial) {
        //     m.dispose()
        // }
        // for (const m of this.simpleColorMaterial) {
        //     m.dispose()
        // }
        // this.simplePointMaterial = null
        // this.simpleColorMaterial = null
        // this.renderer.dispose()
        // this.renderer = null
    }

    /** @return {Vector2} Scene origin in global drawing coordinates. */
    GetOrigin() {
        return this.origin
    }

    // Este metodo debe recibir los layer que son controlados por nosotros para determinar el color del estado
    GetEntities(layers) {
      let labels = []
      let entities = [];
      let backgrounds = []
      let completed = []

      let offset = this._GetOffset();

      Object.keys(this.entities).forEach((key, index) => {
        const element = this.entities[key]
        if(element) {
          if (element.type === 'structures') {

            let structureVertical = element.width < element.height

            labels.push({
              id: 'text_' + element.id,
              text: this._GetNameEntity(element, index),
              x: Math.abs( element.x + offset.x ),
              y: Math.abs( element.y - offset.y ) + (structureVertical ? element.height : 0),
              width: structureVertical ? element.height : element.width,
              height: structureVertical ? element.width : element.height,
              fontSize: 2,
              // fill: element.status == null ? '#ffffff' : '#000000',
              align: 'center',
              verticalAlign: 'middle',
              rotation: structureVertical ? -90 : 0
            })

            backgrounds.push({
              id: 'bk_' + element.id,
              x: Math.abs( element.x + offset.x ),
              y: Math.abs( element.y - offset.y ),
              width: element.width,
              height: element.height,
              fill: index % 2 === 0 ? '#f7f3f3' : null,
            })

            completed.push({
              id: 'sc_' + element.id,
              x: Math.abs( element.x + offset.x ),
              y: Math.abs( element.y - offset.y ),
              width: element.width,
              height: element.height,
              opacity: 0.7,
              // fill: this._GetColoStatusEntityOnlyComplete(element, layers),
              stroke: this._GetColoStatusEntityOnlyComplete(element, layers), // '#f7f3f3',
              strokeWidth: 0.8,
              // shadowColor: this._GetColorLayer(element.layer),
              // shadowBlur: 1,
              // shadowOffsetX: 1,
              // shadowOffsetY: 1
            })
          }

          entities.push({
            id: element.id.toString(),
            name: this._GetNameEntity(element, index),
            x: parseFloat(Math.abs( element.x + offset.x )),
            y: parseFloat(Math.abs( element.y - offset.y )),
            width: element.width,
            height: element.height,
            fill: this._GetColorStatusOfLayout(element, layers), // relleno  aletorio Konva.Util.getRandomColor()
            stroke: this._GetColorLayer(element.layer),
            strokeWidth: 0.1,
            // shadowBlur: 10,
            opacity: element.status == null ? 1 : 0.7, // 0..1
            visible: true,

            type: element.type,
            layerName: element.layer,
            // status: element.status,
            // TODO: Esta parte lo colocamos de esta manera por que solo guardaremos el id del estado, luego corregir y eliminar comentario
            previou_status: element.so, // estado anterior
            status: element.status ? (typeof element.status === 'object' ? element.status.id : element.status ) :  null,
            // listening: () => {  },  2111, 4572 , l 32047
          })
        }
      });

      return { labels, entities, backgrounds, completed }
    }

    _GetOffset() {
      return {
        x: Math.abs( this.bounds.minX  - this.origin.x ), // desplazameinto en x
        y: Math.abs( this.bounds.maxY  - this.origin.y ) // desplazamaiento en y
      }
    }

    _GetNameEntity(entity, index) {
      if ( entity.name != null )
        return entity.name

      return 'E.' + entity.type.charAt(0).toUpperCase() + '.' + (index + 1)

    }

    _GetColoStatusEntityOnlyComplete(entity, layers) {
      if( entity.status != null ) {
        let lyr = layers.find( item => { return item.code === entity.type })
        if (lyr) {
          let idStatus = typeof entity.status === 'object' ? entity.status.id : entity.status;

          let status = lyr.statuses.find(item => { return item.id == idStatus && item.is_completion == 1})
          if (status)
            return status.color
        }
      }
     return null
   }

    _GetColorStatusOfLayout(entity, layers) {
       if( entity.status != null ) {
         let lyr = layers.find( item => { return item.code === entity.type })
         if (lyr) {
           let idStatus = typeof entity.status === 'object' ? entity.status.id : entity.status;

           let status = lyr.statuses.find(item => { return item.id == idStatus })
           if (status)
             return status.color
         }
       }
      return null

      // if ( typeof entity.status === 'object' )
      //   return entity.status.color
      // else {
      //   let lyr = layers.find( item => { return item.code === entity.type })
      //   if (lyr) {
      //     let status = lyr.statuses.find(item => { return item.id == entity.status })
      //     if (status) return status.color
      //   }
      //   return null
      // }
    }

    _GetColorLayer(nameLayer) {
      let layer = this.layers.find((l) => l.name === nameLayer)
      return this._GetCssColor(layer.color)
    }

    _GetCssColor(value) {
        let s = value.toString(16)
        while (s.length < 6) {
            s = "0" + s
        }
        return "#" + s
    }

    /** Ensure the color is contrast enough with current background color.
     * @param color {number} RGB value.
     * @return {number} RGB value to use for rendering.
     */
    _TransformColor(color) {
        if (!this.options.colorCorrection && !this.options.blackWhiteInversion) {
            return color
        }
        /* Just black and white inversion. */
        const bkgLum = Luminance(this.clearColor)
        if (color === 0xffffff && bkgLum >= 0.8) {
            return 0
        }
        if (color === 0 && bkgLum <= 0.2) {
            return 0xffffff
        }
        if (!this.options.colorCorrection) {
            return color
        }
        const fgLum = Luminance(color)
        const MIN_TARGET_RATIO = 1.5
        const contrast = ContrastRatio(color, this.clearColor)
        const diff = contrast >= 1 ? contrast : 1 / contrast
        if (diff < MIN_TARGET_RATIO) {
            let targetLum
            if (bkgLum > 0.5) {
                targetLum = bkgLum / 2
            } else {
                targetLum = bkgLum * 2
            }
            if (targetLum > fgLum) {
                color = Lighten(color, targetLum / fgLum)
            } else {
                color = Darken(color, fgLum / targetLum)
            }
        }
        return color
    }
}

class Layer {
    constructor(name, displayName, color) {
        this.name = name
        this.displayName = displayName
        this.color = color
        this.objects = []
    }

    PushObject(obj) {
        this.objects.push(obj)
    }

    Dispose() {
        for (const obj of this.objects) {
            obj.geometry.dispose()
        }
        this.objects = null
    }
}

// De aquí para abajo es para tratamiento del codigo de color

/** Transform sRGB color component to linear color space. */
function LinearColor(c) {
    return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)
}

/** Transform linear color component to sRGB color space. */
function SRgbColor(c) {
    return c < 0.003 ? c * 12.92 : Math.pow(c, 1 / 2.4) * 1.055 - 0.055
}

/** Get relative luminance value for a color.
 * https://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
 * @param color {number} RGB color value.
 * @return {number} Luminance value in range [0; 1].
 */
function Luminance(color) {
    const r = LinearColor(((color & 0xff0000) >>> 16) / 255)
    const g = LinearColor(((color & 0xff00) >>> 8) / 255)
    const b = LinearColor((color & 0xff) / 255)

    return r * 0.2126 + g * 0.7152 + b * 0.0722
}

/**
 * Get contrast ratio for a color pair.
 * https://www.w3.org/TR/2008/REC-WCAG20-20081211/#contrast-ratiodef
 * @param c1
 * @param c2
 * @return {number} Contrast ratio between the colors. Greater than one if the first color color is
 *  brighter than the second one.
 */
function ContrastRatio(c1, c2) {
    return (Luminance(c1) + 0.05) / (Luminance(c2) + 0.05)
}

function HlsToRgb({h, l, s}) {
    let r, g, b
    if (s === 0) {
        /* Achromatic */
        r = g = b = l
    } else {
        function hue2rgb(p, q, t) {
            if (t < 0) {
                t += 1
            }
            if (t > 1) {
                t -= 1
            }
            if (t < 1/6) {
                return p + (q - p) * 6 * t
            }
            if (t < 1/2) {
                return q
            }
            if (t < 2/3) {
                return p + (q - p) * (2/3 - t) * 6
            }
            return p
        }

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s
        const p = 2 * l - q
        r = hue2rgb(p, q, h + 1/3)
        g = hue2rgb(p, q, h)
        b = hue2rgb(p, q, h - 1/3)
    }

    return (Math.min(Math.floor(SRgbColor(r) * 256), 255) << 16) |
           (Math.min(Math.floor(SRgbColor(g) * 256), 255) << 8) |
            Math.min(Math.floor(SRgbColor(b) * 256), 255)
}

function RgbToHls(color) {
    const r = LinearColor(((color & 0xff0000) >>> 16) / 255)
    const g = LinearColor(((color & 0xff00) >>> 8) / 255)
    const b = LinearColor((color & 0xff) / 255)

    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h, s
    const l = (max + min) / 2

    if (max === min) {
        /* Achromatic */
        h = s = 0
    } else {
        const d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
        case r:
            h = (g - b) / d + (g < b ? 6 : 0)
            break;
        case g:
            h = (b - r) / d + 2
            break
        case b:
            h = (r - g) / d + 4
            break
        }
        h /= 6
    }

    return {h, l, s}
}

function Lighten(color, factor) {
    const hls = RgbToHls(color)
    hls.l *= factor
    if (hls.l > 1) {
        hls.l = 1
    }
    return HlsToRgb(hls)
}

function Darken(color, factor) {
    const hls = RgbToHls(color)
    hls.l /= factor
    return HlsToRgb(hls)
}
