<template>
  <div class="relative pb-1 bg-grey-lightest pl-2 pr-2 m-1 rounded-sm">
    <div class="flex flex-row pt-2 pb-2">
      <div>
        <div>
          <b-icon icon="copy" pack="fas"></b-icon>
        </div>
      </div>
      <div class="flex flex-1 overflow-hidden text-ellipsis items-center text-sm">
        <div>
          <p class="flex-1" style="min-width:25px">
            {{ data.description }}
          </p>
          <!-- <p><strong>Estado: </strong> {{ data.description_status }}</p>
          <p><strong>Fecha: </strong> {{ formatDate(data.created_at) }}</p> -->
          <p>{{ data.description_status }}</p>
          <p>
            <l-timeago
                :since="data.created_at"
                :locale="locale"
                class="my-1 text-xs font-semibold text-blue-light"
              />
          </p>
        </div>
        <span class="flex grow shrink-0 basis-auto" style="max-width: 194px">
          <b-icon v-if="data.status == 0" pack="fas" icon="mug-hot" size="is-medium"> </b-icon>
          <b-icon v-if="data.status == 1" pack="fas" icon="spinner" custom-class="animate-spin" size="is-medium"> </b-icon>
          <b-icon v-if="data.status == 2" pack="fas" icon="check" size="is-medium" type="is-success"> </b-icon>
          <b-icon v-if="data.status == 4" pack="fas" icon="times-circle" size="is-medium" type="is-danger"> </b-icon>
        </span>
      </div>
    </div>
    <div class="">
      <b-progress
        :type="'is-primary'"
        :size="'is-small'"
        :max="100000"
        :value="data.progress * 1000"
        :show-value="true"
        :format="'percent'"
        :precision="2"
        :keep-trailing-zeroes="true"
        :locale="'es-ES'"></b-progress>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  components: {
  },

  props: {
    data: { type: Object, required: true }
  },

  computed: mapGetters({
    locale: "lang/locale",
    // pageInfo: 'app/pageInfo'
  }),

  mounted() {
  },

  methods: {
    formatDate( datetime ) {
      return moment(new Date(datetime) , 'YYYY-MM-DD HH:mm:ss')//.format("DD/MM/YYYY"),
    }
  }

};
</script>
