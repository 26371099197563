<template>
  <b-modal :active.sync="isModalActive" :width="640" scroll="keep">
    <div class="flex flex-col p-4 bg-white border rounded shadow border-blue-light">

      <div class="flex flex-col w-full">
        <div class="font-semibold uppercase text-blue">EDITAR RECURSO</div>
        <div class="text-blue">Datos sobre el recurso</div>
      </div>

      <form @submit.prevent="submitForm">
      <div class="flex flex-col w-full mt-4">
        <div class="flex flex-col">
            <div class="text-xs font-medium text-blue">{{ $t("code") }}</div>
            <div class="flex flex-row w-full">
              <input
                v-model="form.code"
                title="code"
                required="true"
                name="code"
                class="w-full h-8 p-1 text-xs border rounded bg-grey-lighter border-grey-light"
              />
            </div>

        </div>

        <div class="flex flex-col">
            <div class="text-xs font-medium text-blue">{{ $t("name") }}</div>
            <div class="flex flex-row w-full">
              <input
                v-model="form.name"
                title="name"
                required="true"
                name="name"
                class="w-full h-8 p-1 text-xs border rounded bg-grey-lighter border-grey-light"
              />
            </div>

        </div>
        <div class="flex flex-row mt-4">

            <div class="flex flex-col w-1/3">
              <div class="text-xs font-medium text-blue">{{ $t('measurement_unit')}}</div>
              <div class="flex flex-row w-full">
                <select
                  v-model="form.measure_unit_id"
                  :required="this.isResource()"
                  :disabled="this.isMeasurementUnitEditable()"
                  class="w-full h-8 p-1 text-xs border rounded bg-grey-lighter border-grey-light"
                >
                <option
                  v-for="option in unitsList"
                  :value="option.id"
                  :key="option.id"
                >{{ option.name }}
                </option>
              </select>
              </div>
            </div>

            <div class="flex flex-col w-1/3 mx-4">
              <div class="text-xs font-medium capitalize text-blue">{{ $t("units") }}</div>
              <div class="flex flex-row w-full">
                <input
                  v-model="form.total_units"
                  title="name"
                  required="true"
                  name="name"
                  :disabled="(hasChildren || hasLines) ? false : false"
                  class="w-full h-8 p-1 text-xs border rounded bg-grey-lighter border-grey-light"
                />
              </div>
            </div>

            <div class="flex flex-col w-1/3 mr-2">
              <div class="text-xs font-medium text-blue">{{ $t("unit_price") }}</div>
              <div class="flex flex-row w-full">
                <input
                  v-model="form.unit_price"
                  title="name"
                  required="true"
                  :disabled="data.parent_assignment_id > 0"
                  name="name"
                  class="w-full h-8 p-1 text-xs border rounded bg-grey-lighter border-grey-light"
                />
              </div>
            </div>

            <div class="flex flex-col w-1/3 ml-2">
              <div class="text-xs font-medium text-blue">{{ $t("unit_cost") }}</div>
              <div class="flex flex-row w-full">
                <input
                  v-model="form.unit_cost"
                  title="name"
                  :disabled="data.parent_assignment_id > 0"
                  name="name"
                  class="w-full h-8 p-1 text-xs border rounded bg-grey-lighter border-grey-light"
                />
              </div>
            </div>

        </div>
        <div class="flex flex-row mt-4" v-if="!hasLines && !hasChildren">

            <div class="flex flex-col w-1/3">
              <div class="text-xs font-medium capitalize text-blue">{{ $t("length") }}</div>
              <div class="flex flex-row w-full">

                <input
                  v-model="form.length"
                  title="length"
                  :disabled="data.completed"
                  :required="hasLines"
                  name="length"
                  step=".01"
                  type="number"
                  class="w-full h-8 p-1 text-xs border rounded bg-grey-lighter border-grey-light"
                />

              </div>
            </div>

            <div class="flex flex-col w-1/3 mx-4">
              <div class="text-xs font-medium capitalize text-blue">{{ $t("width") }}</div>
              <div class="flex flex-row w-full">
                <input
                  v-model="form.width"
                  title="width"
                  :disabled="data.completed"
                  :required="hasLines"
                  name="width"
                  step=".01"
                  type="number"
                  class="w-full h-8 p-1 text-xs border rounded bg-grey-lighter border-grey-light"
                />
              </div>
            </div>

            <div class="flex flex-col w-1/3">
              <div class="text-xs font-medium capitalize text-blue">{{ $t("height") }}</div>
              <div class="flex flex-row w-full">
                <input
                  v-model="form.height"
                  title="height"
                  :disabled="data.completed"
                  name="height"
                  step=".01"
                  type="number"
                  class="w-full h-8 p-1 text-xs border rounded bg-grey-lighter border-grey-light"
                />
              </div>
            </div>

        </div>

        <div class="flex flex-row mt-4">
            <div class="w-1/3 flex flex-col">
              <div class="text-xs text-blue font-medium">Vincular orden de pedido</div>
              <div class="flex flex-row w-full">
                <select
                  v-model="form.work_order_id"
                  class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
                >
                  <option
                    v-for="option in orders"
                    :value="option.id"
                    :key="'order_'+option.id"
                  >{{ option.code }}
                  </option>
                </select>
              </div>
            </div>

            <div class="w-1/3 flex flex-col mx-4">
              <div class="text-xs text-blue font-medium capitalize">{{ $t("position") }}</div>
              <div class="flex flex-row w-full">

                <input
                  v-model="form.position"
                  title="position"
                  name="position"
                  class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
                />

              </div>
            </div>
          </div>
      </div>

      <div class="flex flex-row items-center justify-start w-full mt-6">
        <button class="btn btn-blue" type="submit">{{$t('save')}}</button>
        <span class="ml-2 text-xs cursor-pointer text-red"
              v-if="isAdmin()"
              @click.prevent="deleteAssignment">{{$t('delete')}}</span>
      </div>
      </form>

    </div>
  </b-modal>
</template>

<script>
  import axios from "axios";
  import {mapGetters} from "vuex";

  export default {
    name: "assignment-update-modal",

    components: {},

    props: {
      entityId: {type: Number, required: true},
      entityType: {type: String, required: true},
      canManage: {type: Boolean, default: false}
    },

    data: () => ({
      isModalActive: false,
      id: null,
      data: {},
      unitsList: [],
      orders: [],
      form: {},
      hasChildren: false,
      hasLines: false,
    }),

    computed: {
      ...mapGetters({
        project: "app/project",
        company: "app/company",
        user: "auth/user"
      })
    },

    methods: {
      /**
       * Envía el formulario para editar y/o añadir una linea
       */
      async submitForm() {
        let data = {
          code: this.form.code,
          name: this.form.name,
          measure_unit_id: this.form.measure_unit_id,
          weight: this.form.weight,
          unit_price: this.form.unit_price,
          unit_cost: this.form.unit_cost,
          total_units: this.form.total_units,
          description: this.form.description,
          width: this.form.width,
          height: this.form.height,
          length: this.form.length,
          work_order_id: this.form.work_order_id,
          position: this.form.position
        };

        let url =
          "/api/v2/assignments/" +
          this.entityType +
          "/" +
          this.entityId +
          "/" +
          this.id;
        axios({
          method: "post",
          url: url,
          data: data
        })
          .then(response => {

            if (response.data && response.data.success) {

              this.$toast.open({
                message: this.$t("assignment_update_success"),
                type: "is-success",
                position: "is-top-right"
              });
  
              this.$emit("reload", {id: this.id, entityId: this.entityId}); //pide a su componente padre que recargue

            } else {

              this.$toast.open({
                message: response.data.msg || this.$t("assignment_update_error"),
                type: "is-danger",
                position: "is-top-right"
              });
            }

            this.hide();
          })
          .catch(error => {
            //Se produjo un error
            this.isLoading = false;
            if (error.response && error.response.status === 422) {
              //extraemos el primer error de validación
              error.error_msg =
                error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                  ][0];
            }

            // Mostramos el error
            this.$toast.open({
              message: error.error_msg || this.$t("assignment_update_error"),
              type: "is-danger",
              position: "is-top-right"
            });
          });
      },

      /**
       * Confirma y borra la medición abierta en el modal
       */
      async deleteAssignment() {
        let self = this;

        this.$dialog.confirm({
          title: this.$t("delete_measurement"),
          message: this.$t("delete_measurement_confirm_text", [this.data.name]),
          confirmText: this.$t("delete_measurement"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            this.isLoading = true;
            let url = "/api/v2/assignment/" + self.id + "/delete";
            const {data} = await axios.post(url);

            if (data.success) {
              this.$toast.open({
                message: this.$t("measurement_remove_success"),
                type: "is-success",
                position: "is-top-right"
              });
              self.$emit("reload"); //pide a su componente padre que recargue
              self.hide();
            } else {
              this.isLoading = false;
              // Error
              this.$toast.open({
                message: this.$t("measurement_remove_error"),
                type: "is-danger",
                position: "is-top-right"
              });
            }
          }
        });
      },

      /**
       * Pide la lista de unidades para el proyecto actual
       */
      getProjectUnits() {
        const {data} = axios
          // .get("/api/v2/project/" + this.project.id + "/measurement-units") // eliminar si todo va bien
          .get("/api/v2/company/" + this.company.id + "/measurement-units")
          .then(response => {
            if (response.data && response.data.success) {
              this.unitsList = response.data.units;
            } else {
              this.$notify.error("error_loading_measurement_units_data");
            }
          });
      },

      async getWorkOrders() {
        let {data} = await axios.get('/api/v2/work-orders', {params: {
          project_id: this.project.id
        }});

        if (data.success) {
          this.orders = data.data
        } else {
          this.$notify.error("error_loading_data");
        }
      },

      /**
       * Comprueba si el usuario puede administrar la asignación
       */
      isAdmin() {
        return this.data.user_id == (this.user && this.user.id) || this.canManage;
      },

      /**
       * Comprueba si los datos son de tipo recurso
       */
      isResource() {
        return this.data.type == 'App\\Models\\AssignmentType\\Resource';
      },

      /**
       * Comprueba si los datos son de tipo recurso
       */
      isMeasurementUnitEditable() {

        if(this.data.hasOwnProperty('children_lines')){

          if(this.data.children_lines.length > 0){
            return true;
          }else{
            return false;
          }

        }

        if(this.data.hasOwnProperty('lines')){

          if(this.data.lines.length > 0){
            return true;
          }else{
            return false;
          }

        }
      },

      /**
       * Comprueba si los datos son de tipo tarea
       */
      isTask() {
        return this.data.type === 'App\\Models\\AssignmentType\\Task';
      },


      /**
       * Muestra este modal
       */
      show(assignment) {
        this.id = assignment.id;
        this.getProjectUnits();
        this.getWorkOrders();
        this.getAssignmentData();
        this.isModalActive = true;
        this.form.measure_unit_id = assignment.unit.id;

      },

      /**
       * Toma los datos de esta medición de la API
       */
      getAssignmentData() {
        var self = this;
        const {data} = axios
          .get("/api/v2/assignment/" + this.id)
          .then(response => {
            if (response.data && response.data.success) {
              // console.log(response);
              self.data = response.data.assignment;
              self.hasChildren = (self.data.children && self.data.children.length > 0)
              self.hasLines = (self.data.lines && self.data.lines.length > 0)
              self.form = {
                code: self.data.code,
                name: self.data.name,
                measure_unit_id: self.data.measure_unit_id || null,
                weight: self.data.weight == null ? null : String(self.data.weight),
                unit_price: self.data.unit_price,
                unit_cost: self.data.unit_cost,
                total_quantity: self.data.total_quantity == null ? null : String(self.data.total_quantity),
                total: self.data.total == null ? null : String(self.data.total),
                total_units: self.data.total_units == null ? null : String(self.data.total_units),
                description: self.data.description,
                width: self.data.width,
                height: self.data.height,
                length: self.data.length,
                work_order_id: self.data.work_order_id,
                position: self.data.position

              };
            } else {
              this.$notify.error("assignment_loading_error");
            }
          });
      },

      getUnitNameByID(id) {
        return this.unitsList.find(x => x.id === id)
      },

      /**
       * valores por defecto del formulario
       */
      defaultFormValues() {
        return {
          name: "",
          weight: "",
          unit_price: "",
          unit_cost: null,
          measure_unit_id: null,
          total_quantity: null,
          description: "",
          width: "",
          height: "",
          length: ""
        };
      },

      /**
       * Oculta este modal
       */
      hide() {
        this.id = null;
        this.data = [];
        this.isModalActive = false;
        this.form = this.defaultFormValues();
      }
    }
  };
</script>
<style scoped>
  /* Oculta los botones para aumentar los inputs numéricos */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
</style>
