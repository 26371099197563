<template>
<div>
  <project-documents
    ref="project_drawings"
    :type-document="'documents'"
  ></project-documents>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import ProjectDocuments from "../../components/documents/project-documents.vue"

export default {
  middleware: 'auth',
  title: 'project_documents_page_title',
  subtitle: 'project_documents_page_subtitle',
  menuOption: '2-7',

  components: {
    ProjectDocuments
  },

  data: () => ({

  }),

  mounted() {

  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      project: 'app/project',
      mobile: 'device/mobile'
    }),

    stickyHeader() { return this.mobile.on_mobile}, // para fiar el header de la tabla cuando se esta mostrando en movil
  },

  created() {

  },

  beforeDestroy(){
  },

  methods: {

  },
};
</script>

<style scoped>
  .panel::v-deep .b-table .table-wrapper.has-sticky-header tr:first-child th,
  .panel::v-deep .b-table .table th.is-sticky {
    background: hsl(220, 43%, 53%);
  }
</style>
