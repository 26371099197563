<template>
  <li @click="$emit('click')">
    <router-link :to="{name : url}" >
      <b-icon :icon="icon" pack="fas" class="menu-item-icon" />
      <span>
        <slot />
      </span>
      <b-icon v-if="hasSubmenu" pack="fas" icon="angle-right" class="menu-chevron"/>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    url: { type: String, default: null },
    icon: { type: String, required: true },
    hasSubmenu: { type: Boolean, default: false },
  },
};
</script>
