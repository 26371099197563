<template>
  <div>

    <!-- titulo carpeta seleccionada -->
    <div class="flex items-center rounded-t-lg bg-gray-200 p-2 font-bold">
      <div class="w-3/4">
        <span v-if="selectedFolder.name" @click="openFolder(selectedFolder.parent || {id: null})" class="cursor-pointer">
          <b-tooltip :label="$t('back_to') + ' ' + (selectedFolder.parent ? selectedFolder.parent.name : $t('root'))" position="is-right">
            <b-icon pack="fas" icon="angle-left" />
          </b-tooltip>
        </span>
        {{ selectedFolder.name || $t('root') }}
        <span class="text-xs text-blue-light font-bold ml-2">({{ documents.length }} {{$t('documents')}})</span>
      </div>
      <span class="cursor-pointer w-1/4 text-xs text-right" v-if="canManageFolders" @click="selectedFolder.id ? downloadFolder(selectedFolder.id): downloadFolderActivity(parentId)">
        {{ $t('download_folder') }} <b-icon pack="fas" icon="download" />
      </span>
    </div>


    <!-- listado carpetas -->
    <div v-for="folder, index in folders" :key="'f_' + folder.id" :set="numFiles = documents.length">

      <div class="w-full flex items-center " :class="[ (index + numFiles) % 2 ? 'bg-white' : 'bg-grey-lighter']">

        <div class="text-yellow cursor-pointer" @click="openFolder(folder)">
          <b-icon pack="fas" :icon="folder.icon || 'folder'" size="is-large" />
        </div>

        <div class="w-10/12 ml-2 flex items-center cursor-pointer" @click="openFolder(folder)">
            {{ folder.name }}
            <span class="text-xs text-blue-light font-bold ml-2">({{ folder.documents_count }} docs)</span>
        </div>

        <div class="w-2/12 text-right mr-2">
          <b-dropdown
            :mobile-modal="false"
            position="is-top-left"
            aria-role="list"
          >
            <template #trigger>
              <span class="p-2 w[36px] h[36px] rounded-full menu-hover cursor-pointer">
                <b-icon icon="ellipsis-v" pack="fas"></b-icon>
              </span>
            </template>

            <b-dropdown-item v-if="canManageFolders && folder.documents_count == 0 && folder.children == null && folder.id != -1"
              aria-role="listitem"
              @click="deleteFolder(folder.id, folder.name)">
              <b-icon pack="fas" icon="trash-alt" />
              <span>{{ $t('remove') }}</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="canManageFolders && (folder.documents_count != 0 || folder.children != null) && folder.id != -1"
              aria-role="listitem"
              @click="downloadFolder(folder.id)">
              <b-icon pack="fas" icon="download" />
              <span>{{ $t('download_folder') }}</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="canManageFolders && folder.id != -1"
              aria-role="listitem"
              @click="moveFolder(folder.id)">
              <b-icon pack="fas" icon="exchange-alt" />
              <span>{{ $t('move_folder') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="w-1/3 ml-2 flex items-center" v-if="false">

            <div v-if="canManageFolders && folder.documents_count == 0 && folder.children == null && folder.id != -1"
              @click="deleteFolder(folder.id, folder.name)">
              <b-tooltip :label="$t('remove')" position="is-top">
                <b-icon pack="fas" icon="trash-alt" />
              </b-tooltip>
            </div>
            <div v-if="canManageFolders && (folder.documents_count != 0 || folder.children != null) && folder.id != -1"
              @click="downloadFolder(folder.id)">
              <b-tooltip :label="$t('download_folder')" position="is-top">
                <b-icon pack="fas" icon="download" />
              </b-tooltip>
            </div>
            <div v-if="canManageFolders && folder.id != -1"
              @click="moveFolder(folder.id)">
              <b-tooltip :label="$t('move_folder')" position="is-top">
                <b-icon pack="fas" icon="exchange-alt" />
              </b-tooltip>
            </div>
        </div>

      </div>

    </div>


    <!-- listado ficheros -->
    <div v-for="document, index in documents" :key="'d_' + document.document_id">

      <div class="w-full flex items-center" :class="[ index % 2 ? 'bg-white' : 'bg-grey-lighter']">

        <div>
          <div @click="showPreview(document, index)" class="cursor-pointer">
            <b-icon pack="far" icon="file" size="is-large" />
          </div>
        </div>

        <div class="w-7/12 ml-2" style="font-size: 11px">

          <div class="w-full">
            <span class="font-bold"> {{ document.name }}</span>
            <span class="italic" v-if="document.desc_name"><br>{{ document.desc_name }}</span>
            <span class="text-blue-light font-bold cursor-pointer" style="font-size: 10px" v-if="document.related_validation_task"@click="goWithTheFlow(document.related_validation_task.validation_id)"><br>{{document.related_validation_task.validation_name}}</span>
            <span class="text-blue-light font-bold cursor-pointer" style="font-size: 10px" v-else-if="document.related_validation" @click="goWithTheFlow(document.related_validation.validation_id)"><br>{{document.related_validation.validation_name}}</span>
          </div>

        </div>

        <div class="w-3/12 ml-2" style="font-size: 11px">
          <validation-status-label v-if="document.related_validation_task" :status="document.related_validation_task.flow_status" class="w-full" style="vertical-align: middle"/>
          <validation-status-label v-else-if="document.related_validation" :status="document.related_validation.flow_status" class="w-full" style="vertical-align: middle"/>
        </div>


        <div class="w-2/12 text-right mr-2">
          <b-dropdown
              :mobile-modal="false"
              position="is-top-left"
              aria-role="list"
          >
            <template #trigger>
              <span class="p-2 w[36px] h[36px] rounded-full menu-hover cursor-pointer">
                <b-icon icon="ellipsis-v" pack="fas"></b-icon>
              </span>
            </template>

            <!-- EDITAR -->
            <b-dropdown-item v-if="showPdfEditor && document.related_validation==null && document.related_validation_task==null"
              aria-role="listitem"
              @click="openEditor(document)">
              <b-icon pack="fas" icon="pen" />
              <span>{{ $t('edit') }}</span>
            </b-dropdown-item>

            <!-- Download document -->
            <b-dropdown-item
              aria-role="listitem"
              @click="downloadDoc(document)">
              <b-icon pack="fas" icon="download" />
              <span>{{ $t(documentDownloadTooltip('download_document', document)) }}</span>
            </b-dropdown-item>

            <!-- Subir vesion -->
            <b-dropdown-item v-if="showVersions && entityType != 'validation' && document.related_validation==null && document.related_validation_task==null"
              aria-role="listitem"
              @click="openUploadModal(document.document_id)"
              :disabled="!isOk(document)">
              <b-icon pack="fas" icon="upload" />
              <span>{{ $t(documentDownloadTooltip('upload_version', document)) }}</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="showVersions"
              aria-role="listitem"
              @click="(document.versions.length <= 0 || !isOk(document)) ? null : openVersionsModal(document.document_id)">
              <b-icon pack="fas" icon="history" />
              <span>{{ $t((document.versions.length <= 0 || !isOk(document)) ? 'document_without_versions':'display_versions') }}</span>
              <span class="dot" v-if="document.versions.length > 0">
                v{{ document.versions.length + 1 }}
              </span>
            </b-dropdown-item>

          </b-dropdown>
        </div>

        <div class="w-4/12 ml-2" style="font-size: 11px" v-if="false">
          <div class="flex w-full mb-2">
            <b-tooltip multilined position="is-top">
                <b-icon pack="fas" icon="info" />
                <template v-slot:content>
                  <b>Creación: </b>{{document.created_at}}
                  <br>
                  <b>Última modificación: </b>{{document.updated_at}}
              </template>
            </b-tooltip>
            <b-tooltip :label="'editar'" position="is-top" v-if="showPdfEditor && document.related_validation==null">
              <div class="w-8 h-8 overflow-hidden" @click="openEditor(document)">
                <b-icon pack="fas" icon="pen" />
              </div>
            </b-tooltip>
            <b-tooltip :label="document.user.fullname + ' (' + document.created_at + ')'" position="is-top" multilined>
              <div class="w-8 h-8 overflow-hidden">
                <b-icon pack="fas" icon="user" />
              </div>
            </b-tooltip>
            <b-tooltip :label="$t(documentDownloadTooltip('download_document', document))" position="is-top">
              <div class="w-8 h-8 overflow-hidden" @click="downloadDoc(document)">
                <b-icon pack="fas" icon="download" />
              </div>
            </b-tooltip>
            <b-tooltip :label="$t(documentDownloadTooltip('upload_version', document))" position="is-top">
              <div v-if="showVersions && entityType != 'validation' && document.related_validation==null"
                class="w-8 h-8 overflow-hidden" @click="openUploadModal(document.document_id)" :disabled="!isOk(document)">
                <b-icon pack="fas" icon="upload" />
              </div>
            </b-tooltip>
            <b-tooltip
              :label="$t((document.versions.length <= 0 || !isOk(document)) ? 'document_without_versions':'display_versions')"
              :type="$t((document.versions.length <= 0 || !isOk(document)) ? 'is-danger':'is-primary')"
              position="is-top">
              <div v-if="showVersions"
                class="w-8 h-8 overflow-hidden" @click="(document.versions.length <= 0 || !isOk(document)) ? null : openVersionsModal(document.document_id)">
                <b-icon pack="fas" icon="history" />
              </div>
            </b-tooltip>
            <span class="dot" v-if="document.versions.length > 0">
              v{{ document.versions.length + 1 }}
            </span>
          </div>
          <!-- Tags -->
          <!-- <div class="w-full" v-if="document.tags.length > 0">
            <visualize-tags
                :tags="document.tags"
                :bg-color="bgColor"
            />
          </div> -->
        </div>

        <!-- Modal para subir versión -->
        <upload-document
                :ref="'upload_modal_' + document.document_id"
                :upload-endpoint="'/api/v2/document/' + document.document_id + '/add_version/' + entityType + '/' + parentId"
                :entity-id="parentId"
                :entity-type="entityType"
                :notify-users="true"
                @success="reloadList"
                :validable-parent-code="validableParentCode"
        />

        <!-- Modal para las versiones -->
        <document-versions
                ref="versions_modal"
                :document="document"
                :entityId="parentId"
                :entityType="entityType"
                @reload-documents-list="reloadList"
        />
      </div>
    </div>



    <!-- mensaje carpeta vacia -->
    <div v-if="documents.length == 0 && folders.length == 0" class="p-4 text-red">
      {{ $t('empty_folder') }} <b-icon pack="fas" icon="ban" />
    </div>

    <!-- Pie. boton crear carpeta dentro de carpeta actual -->
    <div :class="['flex', 'items-center', 'bg-gray-200', 'p-2', 'font-bold', 'rounded-b-lg']">
      <entity-document-folder-create
        :selectedFolder="selectedFolder"
        :canManageFolders="canManageFolders || isProjectParticipant"
        :parentId="parentId"
        :entityType="entityType"
        roundedBorder="rounded-b-lg"
        @reload-documents-list="reloadList"
        style="background: #3a73; color: #285; border-radius: 0 !important; display: flex; align-items: center"
        onmouseover="this.style.background='#3a73'"
        onmouseout="this.style.background='#3a72'"
      />
    </div>

    <!-- Modal para PREVIEW -->
    <entity-document-preview
        :entity-id="parentId"
        :entity-type="entityType"
        :document-id="documentId"
        ref="previewModal"
        :can-delete="canDeleteDocument"
        :showVersions="showVersions"
        @reload-documents-list="() => $emit('reload-documents-list')"
        @update-modal-document="updateModalDocument"
        :can-update-modal-document="documents.length > 1"
    />

    <!-- Modal para EDITAR PDF -->
    <entity-document-editor
        :entity-id="parentId"
        :entity-type="entityType"
        ref="editorModal"
        @reload-documents-list="() => $emit('reload-documents-list')"
    />

    <!-- Modal mover carpeta -->
    <b-modal v-model="showModalSelectFolder" size="lg" scroll="keep">
      <div class="flex flex-col border border-blue-light rounded shadow bg-white p-4">
        <select-folders
        :folderId="moveFolderId"
        :parentId="parentId"
        @close="reloadList()" />
      </div>
    </b-modal>

    <div class="pb-3 bg-white">
    </div>
  </div>
</template>

<script>
import EventBus from "~/plugins/bus";
import {mapGetters} from "vuex";
import axios from "axios";
import entityDocumentPreview from "./entity-document-preview";
import VisualizeTags from "·/components/visualize-tags";
import uploadDocument from "·/components/upload-document";
import documentVersions from "·/components/document-versions";
import entityDocumentFolderCreate from "·/components/entity-document-folder-create";
import entityDocumentEditor from "·/components/entity-document-editor";
import selectFolders from "../pages/project/activities/select-folders";
import validationStatusLabel from '·/components/validations/validation-status-label';
import validationTaskStatusLabel from '·/components/validations/validation-task-status-label';


export default {
  name: "entity-document-folder",

  components: {
    entityDocumentPreview,
    entityDocumentFolderCreate,
    VisualizeTags,
    uploadDocument,
    documentVersions,
    entityDocumentEditor,
    selectFolders,
    validationStatusLabel,
    validationTaskStatusLabel,
  },

  props: {
    documents: { type: Array, required: true },
    folders: { type: Array, required: true },
    selectedFolder: { type: Object, required: true },
    user: { type: Object, required: true },
    canManageFolders: { type: Boolean, default: false },
    isProjectManager: { type: Boolean, default: false },
    isProjectParticipant: { type: Boolean, default: false },
    projectId: { type: Number, required: true },
    parentId: { type: Number, required: true },
    entityType: { type: String, required: true },
    bgColor: { type: String, default: 'bg-white' },
    showVersions: { type: Boolean, default: true },
    validableParentCode: { type: String, required: false, default: '' }, // para formato codificacion documentos "lean2" y "lean3" que van precedidos por codigo actividad padre
  },

  data: () => ({
    backgroundColor: null,
    showPdfEditor: false,
    showModalSelectFolder: false,
    moveFolderId: null,
    canDeleteDocument: false,
    documentId: null
  }),

  computed: {
    ...mapGetters({
      company: 'app/company',
    }),
  },

  mounted() {
    // Check module pdfEditor availability for this company
    if (this.company.configs
      && this.company.configs.modules
      && this.company.configs.modules.includes('pdfEditor')) {
        this.showPdfEditor = true;
    } else {
        this.showPdfEditor = true; // always true for now...
    }
  },

  methods: {

    openFolder(folder) {
      this.$emit('openFolder', folder)
    },

    showPreview(document, index) {
      this.canDeleteDocument = this.user.id == document.user.user_id || this.isProjectManager
      this.documentId = document.document_id
      this.$refs.previewModal.show(document);
      if (document.exif) {
        EventBus.$emit("load-locations", document.exif);
      }
    },

    async downloadDoc(document) {
        this.$notify.success("starting_download_document");
        let url = "/api/v2/document/" + document.document_id;

        const {data} = await axios.get(url);

        if (data) {

          window.open(data.data);

          // const link = document.createElement("a");
          // link.href = window.URL.href(data.data);
          // link.setAttribute("download", this.document.name);
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);

        } else {
          this.$notify.error("error_download_document");
        }
      },


    openUploadModal(docId) {
      // this.$refs.upload_modal[index].show();
      this.$refs['upload_modal_' + docId][0].show();
    },


    reloadList() {
      this.showModalSelectFolder = false
      this.$emit('reload-documents-list')
    },


    openVersionsModal(docId) {
      let pos = this.$refs.versions_modal.findIndex(value => { return value._props.document.document_id == docId})
      this.$refs.versions_modal[pos].show();
    },


    openEditor(document) {
      this.$refs.editorModal.show(document)
    },


    isOk(document){
      return document.status == 1;
    },


    documentDownloadTooltip(tooltip, document) {
      if(this.isOk(document))
        return tooltip;

      return 'document_still_processing';
    },

    // elimina la task de esta plantilla
    async deleteFolder(folderId, folderName) {

      let self = this;
      this.$dialog.confirm({
        title: this.$t('delete'),
        message: this.$t('delete_generic', [folderName]),
        confirmText: this.$t('delete'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {

          const { data } = await axios.post("/api/v2/" + this.entityType + "/" + this.parentId + "/document-folder/" + folderId + "/delete", {})
          if (data && data.success) {

            // reload
            this.reloadList()
            this.$notify.success('success_deleting_generic')

          } else if(data && data.msg){
            this.$notify.error(data.msg)

          } else {
            this.$notify.error('error_saving')
          }
        }
      })
    },
    async downloadFolder(folderId) {

      let self = this;
      const { data } = await axios.get("/api/v2/document-folder/" + folderId + "/download", {})
      if (data && data.success) {
        this.$notify.success('download_folder_progress')

      } else if(data && data.msg){
        this.$notify.error(data.msg)

      } else {
        this.$notify.error('download_folder_error')
      }
    },
    async downloadFolderActivity(activityId) {
      let self = this;
      const { data } = await axios.get("/api/v2/activity/" + activityId + "/document-folder/download", {})
      if (data && data.success) {
        this.$notify.success('download_folder_progress')

      } else if(data && data.msg){
        this.$notify.error(data.msg)

      } else {
        this.$notify.error('download_folder_error')
      }
    },
    moveFolder(folderId) {
      this.moveFolderId = folderId
      this.showModalSelectFolder = true
    },

    updateModalDocument(event) {
      let newIndex = this.documents.findIndex(d => d.document_id == event.document_id) + event.direction
      if (newIndex == -1) {
        newIndex = this.documents.length - 1
      }
      if (newIndex >= this.documents.length) {
        newIndex = 0
      }
      let newDocument = this.documents[newIndex]
      this.showPreview(newDocument, newIndex)
    },

    // Go to validationflow home
    goWithTheFlow(validationId) {
        this.$router.push({name: 'validation.home', params: {validation_id: validationId}})
    },
  }
};
</script>

<style scoped>
.menu-hover:hover {
  background-color: #e3e3e3;
}
</style>
