<template>
  <section>
    <div v-if="isLoading" class="my-4 text-center">
      <scale-loader></scale-loader>
    </div>
    <div v-else>
      <div v-if="!this.groups || this.groups.length == 0" class="mt-16">
        <p class="text-3xl text-center text-grey">
          <b-icon pack="fas" icon="ban" size="is-large"/>
          {{ $t('no_groups_to_select') }}
        </p>
      </div>
      <template v-else>
        <div>
          <div class="relative flex flex-col z-0 sm:flex-row">
            <div class="flex w-full flex-row-reverse sm:order-2">
              <div class="sm:mt-2 sm:mr-2 rounded-sm">
                <b-tooltip :label="$t('display_map')" position="is-left">
                  <v-button icon="map-marked-alt" class="" @click="selectGroupMap"></v-button>
                </b-tooltip>
              </div>
            </div>
            <div class="flex w-full flex-col sm:flex-row sm:order-1">
              <div class="w-full mt-2 sm:ml-2 sm:mr-4">
                <b-input
                  v-model="search"
                  :placeholder="$t('search_group')"
                  type="search"
                  icon="search"
                  icon-pack="fas">
                </b-input>
              </div>
              <div class="w-full sm:w-3/4">
                <v-switch
                  v-model="showArchive"
                  ref="show_archive"
                  :label="$t('include_archive')"
                  :color="showArchive ? 'green' : 'red'"
                  v-on:checked="(value) => showArchive = value"
                  class="sm:mt-3 sm:ml-3"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-col w-full mt-3 sm:mt-6">
            <div class="flex flex-wrap justify-between-">
              <!-- Grupo -->
              <div
                v-for="group in filteredGroups()"
                :key="group.id"
                @click="selectGroup(group)"
                class="flex-initial mb-8 bg-blue-lightest border rounded shadow cursor-pointer border-blue-lighter hover:bg-grey-lightest epc-card"
              >
                <div>
                  <div class="w-full p-4">
                    <div class="w-full rounded-sm h-48 bg-gray-400 bg-cover"
                      :style="{ backgroundImage: 'url('+group.image+')'}">
                        <!-- <img class="inline align-middle" style="max-height: 200px;"
                        :src="group.image" /> -->
                    </div>
                    <div class="mt-1">
                      <div><span class="font-bold uppercase">{{ group.name }}</span></div>
                      <div><span class="text-sm">{{ group.description }}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!this.projects || this.projects.length > 0"
              class="flex flex-col flex-wrap content-start w-full">

              <div class="mb-4 ml-2">
                <h1 class="p-0 m-0 text-xl font-bold uppercase font-raleway text-blue">{{ $t('projects_without_group_title')}} </h1>
                <p class="m-0 text-sm font-bold font-raleway text-grey-dark">{{ $t('projects_without_group_subtitle')}}</p>
              </div>

              <div class="w-full flex flex-wrap justify-between-">
                <!-- Proyecto -->
                <div
                  v-for="project in filteredProjects()"
                  :key="project.id"
                  :class="{ 'bg-grey-lighter': !project.can_participate, 'badge-orange': project.notifications }"
                  :data-badge="project.notifications"
                  class="ww-1/4 mx-2- mb-8 border rounded-sm shadow cursor-pointer border-blue-lighter hover:bg-grey-lightest badge epc-card"
                  style="min-height:495px;"

                >
                  <!-- Nombre del proyecto -->
                  <div
                    :class="{ 'border-grey-light': !project.can_participate,
                          'border-grey-lighter': project.can_participate }"
                    class="flex flex-col items-center justify-center w-full p-2 text-center uppercase border-b bg-blue-lightest h-36"
                  >
                    <span class="text-lg font-semibold" @click="selectProject(project)">
                      <!-- Icono de visualizador -->
                      <b-icon
                        pack="fas"
                        v-show="project.can_view"
                        icon="eye"
                        class="float-left text-blue"/>
                      <!-- Icono de colaborador -->
                      <b-icon
                        pack="fas"
                        v-show="project.can_collaborate"
                        icon="address-book"
                        class="float-left text-blue"/>
                      {{ project.name }}
                    </span>
                    <!-- <div> ocultado area por no usarse el organigrama
                      <b-icon pack="fas" icon="globe-africa" size="is-small"></b-icon>
                      <span class="text-sm">{{ project.area_name }}</span>
                    </div> -->
                  </div>
                  <div>
                    <img :src="project.image" class="object-fill w-full h-64" v-if="project.image" @click="selectProject(project)">
                  </div>
                  <!-- Área del proyecto -->

                  <!-- Presupuesto -->
                  <div class="w-full pt-2 text-sm text-center">
                    <span class="font-bold">{{ $t('budget') }}:</span>
                    <template v-if="project.budget">
                      {{ formatPrice(project.budget.toFixed(2)) }} {{ project.currency }}
                    </template>
                    <template v-else>N/A</template>
                  </div>
                  <!-- Fecha Entrega -->
                  <div class="flex items-center justify-center w-full p-2 text-sm text-grey">
                    {{ $t('delivery_date') }}:
                    {{ (new Date(project.current_final_date)).toLocaleDateString() }}
                  </div>
                  <div class="flex items-center justify-center w-full pb-4 text-sm text-grey">
                    <b-dropdown v-show="project.can_participate">
                      <button class="button is-small" type="button" slot="trigger">
                        <template
                          v-if="!project.user_notifications.project_notifications || project.user_notifications.project_notifications === 'all'"
                        >
                          <b-icon pack="far" icon="bell" class="float-left h-full text-grey"></b-icon>
                          <span class="text-grey">{{ $t('all_notifications') }}</span>
                        </template>
                        <template v-else>
                          <b-icon pack="far" icon="bell" class="float-left h-full text-grey"></b-icon>
                          <span class="text-grey">{{ $t('mentions_favorites') }}</span>
                        </template>
                        <b-icon pack="fas" class="text-grey" icon="angle-down"></b-icon>
                      </button>

                      <b-dropdown-item @click="setProjectNotifications(project, true)" value="all">
                        <div class="media">
                          <b-icon class="media-left" icon="globe-africa"></b-icon>
                          <div class="media-content">
                            <h3>{{ $t('all_notifications') }}</h3>
                            <small>{{ $t('all_notifications_text') }}</small>
                          </div>
                        </div>
                      </b-dropdown-item>

                      <b-dropdown-item
                        @click="setProjectNotifications(project, false)"
                        value="mentions_favorites"
                      >
                        <div class="media">
                          <b-icon class="media-left" icon="user-check"></b-icon>
                          <div class="media-content">
                            <h3>{{ $t('mentions_favorites') }}</h3>
                            <small>{{ $t('mentions_favorites_text') }}</small>
                          </div>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </template>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from "axios";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  middleware: "auth",
  title: "select_group_page_title",
  subtitle: "select_group_page_subtitle",
  menuOption: "2-0",
  components: {
    ScaleLoader
  },

  data: () => ({
    groups: null,
    projects: null,
    isLoading: true,
    search: '',
    showArchive: 0,

  }),

  watch: {
    showArchive(value) {
      this.isLoading = true
      this.getGroups()
    }
  },

  computed: {
    ...mapGetters({
      company: 'app/company',
    }),
  },

  created() {
    // solo cargara los grupos si se ha seleccionado una compania, caso contrario
    // en el mounted mostramos mensaje y redireccionamos al apartado correspondiente
    if (this.company)
      this.getGroups();
  },

  mounted() {
    if (!this.company) {
      this.$notify.info('Debe seleccionar una empresa')
      this.$router.push({ name: "company.select" });
    }
  },

  methods: {

    async getGroups() {
      const { data } = await axios.get("/api/v2/user/groups", {
        'params' : { showArchive: this.showArchive, withProjects: 1, companyId: this.company.id }
      });

      if (data.groupsWithAccess && data.groupsWithAccess.length > 0) {
        this.groups = data.groupsWithAccess;

        if(data.projectsWithoutGroups){
          this.projects = data.projectsWithoutGroups;
        }

        this.isLoading = false;
      }else{
        this.$router.push({ name: "project.select" });
      }
    },

    async selectGroup(group) {
      await this.$store.dispatch("app/setGroup", { group });
      this.$router.push({ name: "project.select" });
    },

    filteredGroups() {
      return this.groups.filter(group => {
        return group.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },

    async selectGroupMap() {
      await this.$router.push({ name: "group.select.map" });
    },

    async selectProject(project) {
      await this.$store.dispatch("app/setProject", { project });
      let tags = project.tags
      await this.$store.dispatch("app/setTags", { tags });
      this.$router.push({ name: "project.home" });
    },

    async setProjectNotifications(project, value) {
      // params
      let params = {
        key: "project_notifications",
        value: value ? "all" : "mentions_favorite",
        project: project.id
      };

      // endpoint
      const { data } = await axios.post(
        "/api/v2/project/user/notifications",
        params
      );

      if (data.success) {
        // show success msg
        this.getProjects();
        this.$notify.success("success_change_user_project_notifications");
      } else {
        // show error msg
        this.$notify.error("error_change_user_project_notifications");
      }
    },

    filteredProjects() {
      return this.projects.filter(project => {
        return project.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },

    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }


  }
};
</script>

<style scoped>
.basis-1\/2 {
  flex-basis: 50%;
}

.epc-card{
  box-sizing: border-box;
  margin: 1rem .25em;
  flex-basis: calc(100% - 0.5rem);
}

/* @media only screen and (min-width: 768px) and (max-width: 1024px){ */
@media only screen and (min-width: 480px) {
  .epc-card {
    flex-basis: calc(50% - 0.5rem);
  }

}

@media only screen and (min-width: 768px) {
  .epc-card {
    flex-basis: calc(33% - 0.5rem);
  }

}

@media only screen and (min-width: 1200px) {
  .epc-card {
    flex-basis: calc(25% - 0.5rem);
  }
}

/* @media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3)
  and (orientation: portrait) {

} */
</style>
