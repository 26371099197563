<template>
<div>
<div class="grid grid-cols-2 gap-4">
  <!-- Attributes -->
  <div>
    <div>
      <!-- Title -->
      <div class="mb-5"><label><b>{{ $t('attributes') }}</b></label></div>
      <!-- Formulario añadir nuevo elemento -->
      <div class="border border-blue-lighter rounded bg-blue-lightest p-2">

        <div class="flex w-full">

          <div class="w-1/8 pr-4">
            <label>{{ $t('code') }}</label>
            <input v-model="newForm.code" type="text" name="" class="input" ref="codeField">
          </div>

          <div class="w-1/8 pr-4">
            <label>{{ $t('name') }}</label>
            <input v-model="newForm.name" type="text" name="" class="input">
          </div>

          <div class="w-1/8 pr-4">
            <label>&nbsp;</label>
            <button v-if="newForm.element_id" class="btn btn-green" type="button" @click="saveAttribute()">
              <i class="fas fa-download mr-1"></i> {{ $t('save') }}
            </button>
            <button v-else class="btn btn-green" type="button" @click="saveAttribute()">
              <i class="fas fa-plus mr-1"></i> {{ $t('add_attribute') }}
            </button>
          </div>

        </div>

      </div>

      <!-- Tabla de Atributos -->
      <div class="panel">
        <div v-if="isLoading" class="my-4 text-center">
          <scale-loader></scale-loader>
        </div>
        <div v-else>
          <div class="flex flex-wrap w-full mt-4">
          <section class="w-full">
            <b-table

            icon-pack="fas"
            :data="elements"
            :paginated="isPaginated"
            :per-page="perPage"
            :bordered="true"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :default-sort-direction="defaultSortDirection"
            v-if="!isLoading">
            <template>
              <b-table-column field="code" sortable>
                <template v-slot:header="">{{ $t('code') }}</template>
                <template v-slot="props">{{ props.row.code }}</template>
              </b-table-column>

              <b-table-column field="name" sortable>
                <template v-slot:header="">{{ $t('name') }}</template>
                <template v-slot="props">{{ props.row.name }}</template>
              </b-table-column>

              <b-table-column field="actions" width=100 sortable>
                <template v-slot:header="">{{ $t('actions') }}</template>
                <template v-slot="props">
                  <div class="flex items-center justify-around w-full h-full">
                    <b-tooltip :label="$t('edit')" position="is-left">
                        <div @click="editElement(props.row)"
                            class="flex cursor-pointer text-blue">
                          <b-icon pack="fas" icon="pencil-alt"/>
                        </div>
                    </b-tooltip>
                    <b-tooltip :label="$t('delete')" position="is-left" type="is-danger">
                        <div @click="removeElement(props.row)"
                            class="flex cursor-pointer text-red">
                          <b-icon pack="fas" icon="trash-alt"/>
                        </div>
                    </b-tooltip>
                    <b-tooltip :label="$t('view_values')" position="is-left">
                        <div @click="onRowClicked(props.row)"
                            class="flex cursor-pointer text-sky-300">
                          <b-icon pack="fas" icon="eye"/>
                        </div>
                    </b-tooltip>
                  </div>
                </template>
              </b-table-column>
            </template>
            <template slot="bottom-left">
                <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                <b>{{ $t("total") }}</b>: {{ this.elements.length }}
            </template>

            <template #empty>
                <div class="has-text-centered">{{ $t('no_results') }}</div>
            </template>

            </b-table>
          </section>
          </div>
        </div>
      </div>

    </div>

  </div>

  <!-- Values -->
  <div>
    <!-- Elements Attribute Value -->
    <div>
      <div class="mb-5"><label><b>{{ $t('values') }} <span v-if="elementId && form.name"> - {{ $t('attribute') + ' ' + form.name }}</span></b></label></div>

      <div v-if="!elementId && !loaded">
        <p class="text-center font-medium text-3xl text-gray-300">Elija un attributo, para administrar sus valores</p>
      </div>

      <div v-if="loaded" class="my-4 text-center">
        <scale-loader></scale-loader>
      </div>

      <div v-if="elementId && !loaded">
        <!-- recuadro de valores -->
        <div class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-blue-lightest mb-1"
          v-for="(value, taskKey) in form.values" :key="value.id">

          <div>
            <!-- delete -->
            <b-tooltip :label="$t('remove')" position="is-left" type="is-danger">
                <div @click="deleteValue(value.id, value.name)" class="flex cursor-pointer text-red">
                  <b-icon pack="fas" icon="trash-alt"/>
                </div>
            </b-tooltip>
            <!-- save -->
            <b-tooltip v-if="valuesInEdition.includes(value.id)" :label="$t('save')" position="is-left">
                <div @click="sendValueData(value.id, taskKey)" class="flex cursor-pointer text-blue">
                  <b-icon pack="fas" icon="download"/>
                </div>
            </b-tooltip>
            <!-- edit -->
            <b-tooltip v-else :label="$t('edit')" position="is-left">
                <div @click="editValue(value.id)" class="flex cursor-pointer text-blue">
                  <b-icon pack="fas" icon="pencil-alt"/>
                </div>
            </b-tooltip>

            <span v-if="valuesInEdition.includes(value.id)"><input v-model="form.values[taskKey].name" type="text" class="mr-2"></span>
            <span v-else class="ml-2">{{ value.name }}</span>
          </div>
        </div>
        <div v-if="form.values.length == 0" class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-gray-200 mb-1 has-text-centered">{{ $t('no_results') }}</div>
        <!-- recuadro añadir values -->
        <div class="w-full border border-blue-lighter rounded flex items-center p-2 bg-blue-lightest mb-1">

          <b-tooltip :label="$t('add_generic')" position="is-left">
              <button class="btn btn-green items-center mr-2"  type="button" @click="saveValue()">
                <i class="fas fa-plus"></i>
              </button>
          </b-tooltip>

          <b-tooltip :label="$t('name')" position="is-left">
            <input v-model="newValue.name" type="text" :placeholder="$t('name')" class="input mr-2">
          </b-tooltip>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="grid grid-cols-2 gap-4">
  <!-- Attributes Elements-->
  <div>
    <!-- Title -->
    <div class="mb-5"><label><b>{{ $t('elements') }}</b></label></div>
    <!-- Formulario para filtro de elementos -->
    <div class="border border-blue-lighter rounded bg-blue-lightest p-2">
      <div class="flex w-full">
        <div class="w-1/8 pr-4">
          <label>{{ $t('code') }}</label>
          <input v-model="searchForm.code" type="text" name="" class="input" @input="handleInputSearch()">
        </div>
        <div class="w-1/8 pr-4">
          <label>{{ $t('name') }}</label>
          <input v-model="searchForm.name" type="text" name="" class="input" @input="handleInputSearch()">
        </div>
        <div class="w-1/8 pr-4">
          <label>&nbsp;</label>
          <button class="btn btn-green" type="button" @click="handleInputSearch()">
            <i class="fas fa-search mr-1"></i> {{ $t('search') }}
          </button>
        </div>
      </div>
    </div>
    <!-- Tabla de Elementos -->
    <div class="panel">
      <div v-if="isLoadingElements" class="my-4 text-center">
        <scale-loader></scale-loader>
      </div>
      <div v-else>
        <div class="flex flex-wrap w-full mt-4">
          <section class="w-full">
            <b-table
              icon-pack="fas"
              :data="elementsElements"
              :paginated="isPaginatedElements"
              :per-page="perPageElements"
              :bordered="true"
              :current-page.sync="currentPageElements"
              :pagination-simple="isPaginationSimpleElements"
              :default-sort-direction="defaultSortDirectionElements"
              v-if="!isLoadingElements">
              <template>
                  <div style="float: right;">
                      <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                      <b>{{ $t("total") }}</b>: {{ this.elementsElements.length }}
                  </div>
                  <div style="clear: both;"/>
              </template>
              <template>
                <b-table-column field="code" sortable>
                  <template v-slot:header="">{{ $t('code') }}</template>
                  <template v-slot="props">{{ props.row.code }}</template>
                </b-table-column>
                <b-table-column field="name" sortable>
                  <template v-slot:header="">{{ $t('name') }}</template>
                  <template v-slot="props">{{ props.row.name }}</template>
                </b-table-column>
              </template>

              <template #empty>
                <div class="has-text-centered">{{ $t('no_results') }}</div>
              </template>
            </b-table>
          </section>
        </div>
      </div>
    </div>
  </div>
  <!-- Values Elements -->
  <div>

  </div>
</div>
</div>
</template>

<script>
import Form from "vform";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
// import MeasurementUnitModal from "./measurement-unit-modal";
import axios from "axios";

export default {
  name: "project-elements-manual",

  components: {
    // MeasurementUnitModal,
    ScaleLoader,
  },

  props: {
    projectId: { type: Number, required: true },
    // isAdminMeasurements: { type: Boolean, default: false }
  },

  data: () => ({
    // data: [],
    newForm: new Form({
      name: "",
      code: ""
    }),

    // form elements
    searchForm: new Form({
      code: "",
      name: "",
    }),

    isLoading: true,
    page: 1,
    perPage: 10,
    total: 0,
    orderField: "reference_code",
    orderDir: "asc",

    currentPage: 1,
    isPaginationSimple: false,
    elements: [],
    defaultSortDirection: 'asc',
    isPaginationSimple: false,
    isPaginated: true,

    // variables for elements
    isLoadingElements: true,
    pageElements: 1,
    perPageElements: 25,
    totalElements: 0,
    orderFieldElements: "reference_code",
    orderDirElements: "asc",

    currentPageElements: 1,
    isPaginationSimpleElements: false,
    elementsElements: [],
    defaultSortDirectionElements: 'asc',
    isPaginatedElements: true,

    // values
    elementId: null,
    form: new Form({
      code: '',
      name: '',
      values: [],
    }),

    newValue: new Form({
      name: '',
    }),

    valuesInEdition: [], // valor que estan en modo edicion. Editor inline
    disableSaveButton: false,
    loaded: false,
  }),

  mounted() {
    this.getProjectElementAttributes();
    this.getProjectAssignmentElements();
  },

  created() {
      this.debouncedSearch = this.debounce(this.getProjectAssignmentElements, 750);
  },

  methods: {
    async getProjectElementAttributes() {
      this.isLoading = true;
      let params = {
        p: this.projectId,
        order_dir: this.orderDir,
        order_field: this.orderField
      };
      const { data } = await axios.get(
        "/api/v2/project/" + this.projectId + "/element-attibutes",
        { params: params }
      );

      if (data && data.success) {
        this.elements = data.elements;
        this.total = data.elements ? data.elements.length : 0;
      } else {
        this.$notify.error("error_loading_measurement_units_data");
      }
      this.isLoading = false;
    },

    async getProjectAssignmentElements() {
      let params = {
        name: this.searchForm.name,
        code: this.searchForm.code,
      };
      const { data } = await axios.get(
        "/api/v2/project/" + this.projectId + "/assignment-elements",
        { params: params }
      );
      if (data && data.success) {
        this.elementsElements = data.elements;
        this.totalElements = data.elements ? data.elements.length : 0;
      } else {
        this.$notify.error("error_loading_assignment_elements_data");
      }
      this.isLoadingElements = false;
    },

    handleInputSearch() {
      if (this.searchForm.code.length > 0 || this.searchForm.name.length > 0) {
        this.debouncedSearch();
      } else if (this.searchForm.code.length == 0 && this.searchForm.name.length == 0) {
        this.getProjectAssignmentElements();
      }

    },

    // funcion que espera a que se deje de escribir para enviar la peticion
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },


    reload() {
      this.getProjectElementAttributes();
      this.getProjectAssignmentElements();
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    onPageChange(page) {
      this.page = page;
      this.reload();
    },

    onSort(field, order) {
      this.orderField = field;
      this.orderDir = order;
      this.reload();
    },

    editElement(attribute) {

      this.newForm = attribute
      this.newForm.element_id = attribute.id
      this.$refs.codeField.focus()

    },

    async removeElement(element) {
      this.$dialog.confirm({
        title: this.$t("delete"),
        message: this.$t("delete_generic", [element.name]),
        confirmText: this.$t("delete"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          this.elementId = null

          this.isLoading = true;
          let url = "/api/v2/element-attibutes/" + element.id + "/delete";
          const { data } = await axios.post(url);

          if (data.success) {
            this.$toast.open({
              message: this.$t("success_deleting_generic"),
              type: "is-success",
              position: "is-top-right"
            });
            await this.sleep(1000);
            this.reload();
          } else {
            this.isLoading = false;
            // Error
            this.$toast.open({
              message: data.error || this.$t("error_saving"),
              type: "is-danger",
              position: "is-top-right"
            });
          }
        }
      });
    },

    // canDelete(data) {
    //   return (
    //     (data.activities && data.activities.length > 0) ||
    //     (data.issues && data.issues.length > 0)
    //   );
    // },

    async saveAttribute() {
      if (this.newForm.code == '' || this.newForm.name == '') {
        this.$notify.error(this.$t('required_empty_fields', ['codigo y nombre', 'atributo']))
        return
      }

      this.elementId = null
      let url = "/api/v2/project/"+ this.projectId +"/element-attibutes" // add url

      if (this.newForm.element_id) {
        url = "/api/v2/element-attibutes/" + this.newForm.element_id // edit url
      }

      // let postData = Object.assign( { company_id: this.company.id }, this.newForm) // le fusiona el company_id al form
      let postData = this.newForm

      // Enviamos el form
      const { data } = await axios.post(url, postData)

      if (data && data.success) {

        this.$notify.success('success_editing')
        this.getProjectElementAttributes()
        this.newForm = { code: '', name: '' }

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')
        this.$router.push({ name: 'project.home', params: {} })

      } else {
        this.$notify.error('error_saving')
      }
    },

    async readAttribute() {

      if(this.elementId){
        this.loaded = true;
        let params = { };
        const { data } = await axios.get(
          "/api/v2/element-attibutes/" + this.elementId,
          { params: params }
        );

        if (data && data.success) {
          this.form = data.element;
        } else {
          this.$notify.error("error_loading_measurement_units_data");
        }
        this.loaded = false;
      }

    },

    onRowClicked(element, access = true) {
      if (access) {
        this.elementId = element.id;

        this.readAttribute();
      }
    },

    // value
    // redirige a pantalla edicion
    editValue(valueId) {
      this.valuesInEdition.push(valueId) // edicion inline
    },

    // guarda cambios de la tarea y la saca del modo edicion
    async sendValueData(valueId, taskKey) {

      var url = '/api/v2/element-attibute-values/' + valueId;

      // Enviamos el form
      const { data } = await axios.post(url, this.form.values[taskKey])
      if (data && data.success) {

        // la sacamos del modo edicion
        this.valuesInEdition.splice(this.valuesInEdition.indexOf(valueId),1)

        this.$notify.success('success_editing')

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')

      } else {
        this.$notify.error('error_saving')
      }
    },

    // crea nueva tarea en esta plantilla
    async saveValue() {
      if (this.newValue.name == '') {
        this.$notify.error(this.$t('empty_field_required', ['nombre']))
        return
      }

      const { data } = await axios.post("/api/v2/element-attibutes/" + this.elementId + "/value", {
        name: this.newValue.name,
        element_id: this.elementId
      })

      if (data && data.success) {
        // reload
        this.readAttribute()
        this.newTask = new Form({
          name: '',
        }),

        this.$notify.success('success_editing')

      } else if(data && data.msg) {
        this.$notify.error('no_access_permissions')
      } else {
        this.$notify.error('error_saving')
      }
    },

    // elimina el valor
    async deleteValue(valueId, valueName) {

      let self = this;
      this.$dialog.confirm({
        title: this.$t('delete'),
        message: this.$t('delete_generic', [valueName]),
        confirmText: this.$t('delete'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {

          const { data } = await axios.post("/api/v2/element-attibute-values/" + valueId + "/delete", {})
          if (data && data.success) {

            // reload
            this.readAttribute()
            this.$notify.success('success_deleting_generic')

          } else if(data && data.msg){

            this.$notify.error('no_access_permissions')

          } else {
            this.$notify.error('error_saving')
          }
        }
      })
    },
  }
};
</script>
