<template>
  <div class="relative w-full table text-center">
    <b-icon :icon="icon" class="w-6 text-blue" />
    <label for="id" class="text-sm text-grey-darker mb-4">{{ $t(label) }}</label>
    <div class="flex justify-center mt-4">
      <v-radio
        v-model="radioValue"
        :name="name"
        :checked="radioValue"
        :value="true"
        class="mr-2">
        {{ $t('yes') }}
      </v-radio>
      <v-radio
        v-model="radioValue"
        :name="name"
        :checked="!radioValue"
        :value="false"
        class="ml-2">
        {{ $t('no') }}
      </v-radio>
    </div>
  </div>
</template>

<script>
export default {
  name: 'v-radio-boolean',

  props: {
    label: { type: String, default: '' },
    id: { type: String, default: null },
    name: { type: String, default: null },
    icon: { type: String, default: null },
    value: { type: Boolean, required: true },
  },

  data: () => ({
    radioValue: false,
  }),

  watch: {
    value (newValue) {
      // console.log('cambio', newValue)
      this.radioValue = newValue
    },
    radioValue (newValue) {
      if (newValue !== undefined) {
        console.log('emit', newValue)
        this.$emit('input', newValue)
      }
    }
  },

  mounted () {
    this.radioValue = this.value
  },

};
</script>
