<template>
  <section>
    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="w-1/2 modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('edit') }}</p>
        </header>
        <section class="modal-card-body">
          <!-- Cuerpo del Modal-->
          <div class="flex flex-col">
            <div class="mt-1">
              <label class="w-full">{{ $t('select_type') }}</label>
              <b-select ref="input"
                v-model="technicalSpecification.spec_type_id"
                :placeholder="$t('select_type')"
                class="w-full"
                required
                expanded
              >
                <option v-for="option in specificationTypes" :value="option['id']" :key="option['id']">
                  {{ option['name'] }}
                </option>
              </b-select>
            </div>

            <div class="mt-1">
              <label class="w-full">{{ $t('select_scope') }}</label>
              <b-select ref="input"
                v-model="technicalSpecification.scope"
                :placeholder="$t('select_scope')"
                class="w-full"
                required
                expanded
              >
                <option v-for="(option, index) in scopeList" :value="option" :key="index">
                  {{ option }}
                </option>
              </b-select>
            </div>

            <div class="mt-1">
              <label class="w-full">{{ $t('code') }}</label>
              <v-input
                v-model="technicalSpecification.code"
                placeholder="code"
                class="w-full"
                required
              />
            </div>

            <div class="mt-1">
              <label class="w-full">{{ $t('name') }}</label>
              <v-input
                v-model="technicalSpecification.name"
                placeholder="name"
                class="w-full"
                required
              />
            </div>

            <div class="mt-1">
              <label class="w-full">{{ $t('order') }}</label>
              <v-input
                v-model="technicalSpecification.order_column"
                placeholder="order"
                class="w-full"
                required
                type="number"
              />
            </div>
          </div>
        </section>
        <div class="modal-card-foot">
          <button class="button" :disabled="isLoading" v-on:click="isModalActive = false"><i class="fas fa-times"></i>&nbsp; {{ $t('cancel') }}</button>
          <button class="button is-success" :disabled="isLoading || (!technicalSpecification.name || !technicalSpecification.code || !technicalSpecification.spec_type_id)" v-on:click="update()"><i class="fas fa-check"></i>&nbsp; Actualizar</button>
        </div>
      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

    </b-modal>
  </section>
</template>

<script>

  import {mapGetters} from 'vuex'
  import axios from "axios";

  export default {

    components: {
    },

    props: {
      specificationTypes: { type: Array, require: true },
      scopeList: { type: Array, require: true }
    },

    data() {
      return {
        isModalActive: false,
        isLoading: false,
        technicalSpecification: {}
      }
    },

    watch: {
    },

    created(){
    },

    computed: {
    },

    methods: {
      show(technicalSpecification) {
        this.technicalSpecification = {
          id: technicalSpecification.id,
          spec_type_id: technicalSpecification.spec_type_id,
          scope: technicalSpecification.scope,
          code: technicalSpecification.code,
          name: technicalSpecification.name,
          order_column: technicalSpecification.order_column
        }
        this.isModalActive = true
      },

      hide() {
        this.technicalSpecification = {}
        this.isModalActive = false
      },

      async update() {
        let body = {
          spec_type_id: this.technicalSpecification.spec_type_id,
          scope: this.technicalSpecification.scope,
          code: this.technicalSpecification.code,
          name: this.technicalSpecification.name,
          order_column: this.technicalSpecification.order_column
        }
        this.isLoading = true;
        let {data} = await axios.post(`/api/v2/technical-specification/${this.technicalSpecification.id}`, body)
        if (data.success) {
          this.$notify.success('success_editing')
          this.$emit('reload')
          this.hide()
        } else {
          this.$notify.error('error_saving')
        }
        this.isLoading = false;
      }
    }
}
</script>

<style scoped lang="scss">
  .modal-card {
    width: 100%;
  }

  .modal-card-body {
    min-height: 180px;
    min-width: 360px;
  }

  .modal-card-foot {
    justify-content: flex-end !important;
  }
</style>
