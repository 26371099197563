<template>
  <div class="page-with-top-bar">
    <project-template-admin-quick-buttons hiddenOption="issues"/>
    <section>
      <div class="mt-4">
        <b-tabs type="is-boxed" v-model="mainActiveTab">
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file"></b-icon>
              <span> {{ $t('issue_types') }} </span>
            </template>

            <project-issue-c-t-d-o
              ref="project_issue_types"
              :projectId="projectId"
              :entity_type="'project_templates'"
              option='types'
            ></project-issue-c-t-d-o>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import Form from "vform";
  import ProjectIssueCTDO from "../../../components/project-issue-c-t-d-o";
  import ProjectTemplateAdminQuickButtons from '../../../components/project-template-admin-quick-buttons.vue'
  import EventBus from '~/plugins/bus';

  export default {
    middleware: "auth",
    title: "issues_page_title",
    menuOption: "0-6",

    components: {
      ProjectTemplateAdminQuickButtons,
      ProjectIssueCTDO,
    },

    computed: {
      ...mapGetters({
        // user: "auth/user",
        project: "template/project",
      }),
      projectId() {
        return parseInt(this.$route.params.template_id);
      }
    },

    data: () => ({
      isLoading: false,
      form: new Form({
        projectId: null,
        partnerId: null,
        companyId: null,
        excelFile: null,
        description: "",
        overwrite: false,
        assignmentType: null,
        activityCode: null,
        actionType: "",
      }),

      entityType: 'project',
      entityId: null,

      errors: [],
      mainActiveTab: 0,
      addDrawings: false,
    }),

    watch: {
      mainActiveTab(value) {
        if (value == 0) {
          // no aplicable
          //this.$refs.project_assignments_manual.reload();
        }
      }
    },

    created() {
      let projectName = this.project.name
        ? this.project.name.toUpperCase()
        : "";
      this.$options.subtitle = ["project_actions_page_subtitle", [projectName]];

      this.entityId = this.projectId;
    },

    mounted() {
      let self = this;
      // Si recibimos el evento refresh, refrescamos el componente
      EventBus.$on("refreshProjectDocumentsNotifications", function(e) {
        if( e.executor_user_id && e.executor_user_id != self.user.id ) {
          self.refreshProjectDocuments();
        } else {
        }
      });
    },

    beforeDestroy(){
      EventBus.$off("refreshProjectDocumentsNotifications");
    },

    methods: {

      onAddClicked(modal='documents') {
        this.addDrawings = modal == 'drawings'
        this.$refs.modal.show();
      },

      successfullyUploaded() {

        if( this.addDrawings )
          this.$refs.project_drawings_manual.reload();
        else
          this.$refs.project_documents_manual.reload();

        // let entity = this.entityType;
        // entity = entity.charAt(0).toUpperCase() + entity.slice(1);

        //Evento : "refreshActivityDocumentsNotifications"
        // EventBus.$emit('refresh'+entity+'DocumentsNotifications', true);
      },

      refreshProjectDocuments() {
        this.$refs.project_drawings_manual.reload();
        this.$refs.project_documents_manual.reload();
      },
    }
  };
</script>
