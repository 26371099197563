<template>
  <div
    :ref="'konva_popover'"
    :style="{
      top: position.y + 2 + 'px',
      left: position.x + 2 + 'px',
      borderRadius: '3px',
      boxShadow: '0 0 5px grey',
      backgroundColor: '#fff',
      minWidth: '210px'
    }"

    class="absolute z-10 max-w-sm rounded overflow-hidden shadow-lg"
  >
    <div class="card-header font-semibold px-3 py-1 mb-0 border-b border-solid border-slate-100 uppercase">
        {{ $t(type) }} : {{ dataTooltip.attrs ? dataTooltip.attrs.name : '' }}
      </div>
    <div class="card-body px-3 py-2">
      <label for="weight" class="label">
        {{ $t('status') }}
      </label>
      <b-select v-model="selectedStatus"
        placeholder="Elija estado"
        icon="traffic-light"
        icon-pack="fas"
        expanded
        @input="handleChangeStatus">
        <option :value="null" key="status_null">Sin estado</option>
        <option v-for="option in statuses"
          :value="option"
          :key="'status_' + option.id">
          {{ option.name }}
        </option>
      </b-select>
    </div>
  </div>
</template>

<script>


/** Events: all DxfViewer supported events (see DxfViewer.Subscribe()), prefixed with "dxf-". */
export default {
    name: "KonvaViewerPopover",

    props: {
        position: { type: Object, required: true },
        dataTooltip: { type: Object, default() { return { } }},
        statuses: { type: Array, default() { return [] } },
    },

    data() {
        return {
          selectedStatus: null,
        }
    },

    computed: {
      type: function() {
        return this.dataTooltip.attrs ? this.dataTooltip.attrs.type : 'structure'
      },
    },

    watch: {
      dataTooltip(nuevoValor, valorAnterior) {
        this.selectedStatus = nuevoValor.status == null ? null : nuevoValor.status
      }
    },

    created() {

    },

    updated() {
    },

    mounted() {
      // window.addEventListener("click", this.onClick);
    },

    methods: {
      // onClick(e) {
      //   if (!this.isClickedInside(e, this.$refs.konva_popup)) {
      //     this.$emit('epc-close');
      //   }
      // },

      isClickedInside(e, element) {
        let node = e.target;
        while (node) {
          if (node === element) {
            return true;
          }
          node = node.parentNode;
        }
        return false;
      },

      handleChangeStatus(value) {

        this.dataTooltip.fill( value?.color ?? null )
        // let statusOld = JSON.parse(JSON.stringify(this.dataTooltip.status)) //clo

        this.dataTooltip.status = value

        // colocar aqui se se quiere emitir alguna llamada a la api

        this.$emit('epc-update-list', this.dataTooltip)
        this.$emit('epc-close')
      }
    },

    destroyed() {
      // window.removeEventListener("click", this.onClick);
    },
}
</script>

<style scoped>


</style>
