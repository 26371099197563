<template>
  <transition-group
    name="custom-classes-transition"
    :enter-active-class="show ? 'animated zoomIn' : null"
    :leave-active-class="show ? 'animated zoomOut' : null"
    tag="ul"
    class="list-reset"
    style="min-height:3rem">
    <li
      v-for="(entity, index) in entities"
      :key="entity[entityKey]"
      class="float-left pb-2"
      :class="{
        'w-full': boxFullWidth,
        'w-1/2': !boxFullWidth,
        'pr-1': !boxFullWidth && (index % 2 === 0),
        'pl-1': !boxFullWidth && (index % 2 !== 0),
        }">
      <div
        :class="[ 'bg-blue-lightest' ]"
        class="w-full border border-blue-lighter rounded flex items-center px-2"
        style="height:38px;line-height:33px">
        <img v-if="withImage"
          :src="entity.avatar"
          class="rounded-full overflow-hidden"
          style="width:28px;height:28px">
        <span class="flex-grow text-xs font-semibold text-blue-light truncate px-2">
          {{ getNameEntity(entity) }}
        </span>

        <button v-show="showRemoveButton"
          @click="onClickRemoveButton(index)"
          @mouseover="iconRemoveHovered = index"
          @mouseleave="iconRemoveHovered = null"
          class="flex items-center cursor-pointer">
          <b-icon v-show="!isHoverRemoveIcon(index)"
            pack="far"
            icon="trash-alt"
            class="text-grey float w-full h-full"
            size="is-small"/>
          <b-icon v-show="isHoverRemoveIcon(index)"
            pack="fas"
            icon="trash-alt"
            class="text-grey float w-full h-full"
            size="is-small"/>
        </button>
      </div>
    </li>
  </transition-group>
</template>

<script>

export default {
  components: {
  },

  props: {
    entities: { type: Array, default: [] },
    entityKey: { type: String, default: null, require: true },
    disabled: { type: Boolean, default: false },
    show: { type: Boolean, default: false },
    boxFullWidth: { type: Boolean, default: false, required: false},
    showRemoveButton: {type: Boolean, default: true },
    withImage: {type: Boolean, default: false },
    componentName: {type: Array, default: [], require: true },
  },

  data: () => ({

  }),

  computed: {
  },

  watch: {

  },

  created() {

  },

  mounted() {

  },

  methods: {
    getNameEntity(entity) {
      let name = '';
      this.componentName.forEach(function(val) {
          if(name != '')
            name += ' ';

          name += entity[val]
      });
      return name;
    },
    // Al pulsar el botón para quitar un usuario
    onClickRemoveButton(index) {
      // Mostrar un mensaje de advertencia cuando el roleName está relacionado con los departamentos
        // this.$dialog.confirm({
        //   title: this.$t('remove_dept_user_title'),
        //   message: this.$t('remove_dept_user_confirmation'),
        //   confirmText: this.$t('remove'),
        //   cancelText: this.$t('cancel'),
        //   type: 'is-danger',
        //   hasIcon: true,
        //   onConfirm: () => this.removeUserDispatch(index)
        // })
        this.removeUserDispatch(index)
    },

    removeUserDispatch(index) {
      this.$emit('removeButtonClicked', index)
      this.removeUser(index)
    },

    // Reemplazamos a un usuario de la lista
    async removeUser(index) {
      this.__removeUserFromList(index)
    },

    // Quita un usuario de la lista local
    __removeUserFromList(index) {
      this.entities.splice(index, 1)
    },


    isHoverRemoveIcon(index) {
      return this.iconRemoveHovered === index
    },

    onClickRemoveButton(index) {
      this.$emit('removeButtonClicked', index)
    },
  },

};
</script>
