<template>
<div>
    {{ $t('descriptive_name') }}
    <input v-model="desc_name" :placeholder="''" class="input" />

    <div class="flex flex-row w-full pb-3 mt-4">

    <div class="flex flex-col w-1/5 pb-3 mt-4">
        {{ $t('year') }}<br>
        <b-select v-model="myForm.tyear" :placeholder="$t('select')" expanded>
            <option v-for="i in tyearValues" :value="i.value" :key="'tyear_'+i.value">
                {{ i.name }}
            </option>
        </b-select>
    </div>

    <div class="flex flex-col w-2/5 pb-3 mt-4">
        {{$t('form_type')}}<br>
        <b-select v-model="myForm.t0" :placeholder="$t('select')" expanded
            @input="getSelectOptions(1, myForm.t0.id)">
            <option v-for="option in options.t0" :value="option" :key="'t0_'+option.id">
                {{ option.description }}
            </option>
        </b-select>
    </div>

    <div class="flex flex-col w-2/5 pb-3 mt-4">
        {{$t('contract_number')}}<br>
        <b-select v-model="myForm.tncontrato" :placeholder="$t('select')" expanded>
            <option v-for="i in tncontratoValues" :value="i" :key="'tncontrato_'+i">
                {{ 'DSO' + i }}
            </option>
        </b-select>
    </div>
    </div>

    T1
    <b-select v-model="myForm.t1" :placeholder="$t('select')" expanded
        @input="getSelectOptions(2, myForm.t1.id)">
        <option v-for="option in options.t1" :value="option" :key="'t1_'+option.id">
            {{ option.description }}
        </option>
    </b-select>

    T2
    <b-select v-model="myForm.t2" :placeholder="$t('select')" expanded
        @input="getSelectOptions(3, myForm.t2.id)">
        <option v-for="option in options.t2" :value="option" :key="'t2_'+option.id">
            {{ option.description }}
        </option>
    </b-select>

    T3
    <b-select v-model="myForm.t3" :placeholder="$t('select')" expanded>
        <option v-for="option in options.t3" :value="option" :key="'t3_'+option.id">
            {{ option.description }}
        </option>
    </b-select>

    {{$t('free_field')}}
    <input v-model="myForm.tlibre" :placeholder="''" class="input" />

    {{$t('document_number')}}
    <input v-model="myForm.tndoc" :placeholder="''" class="input" />

    <div class="flex w-full">
        <div class="w-1/2 pb-3 mt-4" v-if="!form.isLastGroup">
            Version
            <b-input v-model="myForm.tversion" type="number" min="0" max="99"></b-input>
        </div>
        <!-- switch ultimo grupo validador, forzado version definitiva doc -->
        <div class="w-1/2 pb-3 mt-4" v-if="form.isLastGroup">
            <b-tooltip :label="'Como ultimo validador, la version es definitiva'" position="is-right" :multilined="false">
            <v-switch
            :disabled="form.isLastGroup"
            :label="$t('final_version')"
            :color="myForm.tversionbool ? 'green' : 'red'"
            v-model="myForm.tversionbool"
            style="margin-left:20px; margin-bottom:25px "
            />
            </b-tooltip>
        </div>
        <!-- switch resto de grupos, version definitiva opcional -->
        <div class="w-1/2 pb-3 mt-4" v-else>
            <v-switch
            :disabled="myForm.isLastGroup"
            :label="$t('final_version')"
            :color="myForm.tversionbool ? 'green' : 'red'"
            v-model="myForm.tversionbool"
            v-on:checked="toggleVersion()"
            style="margin-left:20px; margin-bottom:25px "
            />
        </div>
    </div>

    AAMMDD
    <input v-model="myForm.tdate" :placeholder="''" class="input" />

    <div class="cursor-default tag is-info" type="button"
        @click="generateName">
        {{$t('generate_filename')}} <b-icon pack="fas" icon="file-alt" />
    </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from 'moment'
import axios from 'axios';

export default {
  name: "codification-lean",

  components: {
  },


  props: {
    form: {type: Object, required: true},
    prefixCode: { type: String, required: false, default: '' }, // se usa formato 'lean2' si recibe un string, que se pondrá al comienzo del codigo del documento,  (viene del planning_code de la actividad padre). Si es vacio pues se usará formato 'lean' normal
  },


  data: () => ({
    tyearValues: [],
    tncontratoValues: [],
    desc_name: '',

    options: {
      t0: [],
      t1: [],
      t2: [],
      t3: [],
    },

    myForm: {
        tyear: moment().format('YY'),
        t0: { id: null, code: ''},
        tncontrato: '',
        t1: { id: null, code: ''},
        t2: { id: null, code: ''},
        t3: { id: null, code: ''},
        tlibre: '',
        tndoc: '',
        tversion: '0',
        tversionbool: false, // variable auxiliar no util
        tversionprev: '', // variable auxiliar con numero version justo antes de marcar definitivo
        tdate: moment().format('YYMMDD'),
    }

  }),


  created() {
    this.tyearValues = this.getYearValues()
    this.tncontratoValues = [...Array(251).keys()] // [ 0, 1, 2, 3, 4... 250]
    this.tncontratoValues.shift() // saca el 0

    this.getSelectOptions(0, 0)

    if (this.form.isLastGroup) {
        this.myForm.tversionbool = true // el ultimo grupo validador, todo documento que suba ya es version "definitivo"
        this.myForm.tversion='DEF'
    }

  },


  computed: {

    ...mapGetters({
        project: 'app/project',
    }),

    // codigo del fichero generado
    codedName() {

        // si no ha puesto T0 es que no va codificado
        if (this.myForm.t0.code == '')
            return '';

        let name;

        if (this.prefixCode != '') {
            // variantes "lean2" y "lean3".
            if (this.project.codification_form == 'lean2') { // El codigo documento empieza por el codigo de la actividad padre (recibido desde el componente padre)
                name = this.prefixCode
            } else if (this.project.codification_form == 'lean3') { // Empieza por el codigo de la actividad padre hasta el primer guion bajo '_'
                name = this.prefixCode.split('_')[0]
            }
        } else {
            // variante "lean" normal. El codigo documento empieza por año+t0+dso
            name = this.myForm.tyear.toString() + this.myForm.t0.code
            if (this.myForm.tncontrato != '') {
                name += '.' + this.myForm.tncontrato.toString().padStart(3, '0')
            }
        }

        if (this.myForm.t1 && this.myForm.t1.code != '')
            name += '_' + this.myForm.t1.code
        if (this.myForm.t2 && this.myForm.t2.code != '')
            name += '_' + this.myForm.t2.code
        if (this.myForm.t3 && this.myForm.t3.code != '')
            name += '_' + this.myForm.t3.code
        if (this.myForm.tlibre != '')
            name += '_' + this.myForm.tlibre
        if (this.myForm.tndoc != '')
            name += '_' + this.myForm.tndoc
        if (this.myForm.tversion != '') {
            if (this.myForm.tversion != 'DEF') {
            name += '_V' + this.myForm.tversion.padStart(2, '0') // _V02_
            } else {
            name += '_' + this.myForm.tversion // _DEF_
            }
        }
        if (this.myForm.tdate != '')
            name += '_' + this.myForm.tdate

        // sin extension, se le añade en el componente que lo llame

        return name
    },
  },


  methods: {

    getYearValues() {
        // rellenar tyearValues con año actual +2 y -2
        let options = [];

        [-4, -3, -2, -1, 0, 1, 2].forEach(function(index) {
            let year = moment().add(index, 'year')
            options.push({value: year.format('YY'), name: year.format('YYYY')})
        })

        return options
    },


    // carga desde Api las opciones del select del nivel n (t1, t2..)
    async getSelectOptions(level, parent_id) {

        this.resetSelects(level)

        await axios.get("/api/v2/documentcodification?project_id=" + this.project.id + "&parent_id=" + parent_id)
            .then((response) => {
            this.options[`t${level}`] = response.data.data
            })
            .catch((error) => {
            this.$notify.error("error obteniendo codigos fichero");
            });
    },


    // resetea los siguientes selects
    resetSelects(n) {
        while(n < 4) {
            this.myForm[`t${n}`]['code'] = '';
            n++
        }
    },


    // aplica el codigo de nombre generado al campo custom_name
    generateName() {

        // emit al componente padre validationtask-completion-modal con lo generado
        this.$emit('generatedCodename', {
            desc_name: this.desc_name || '',
            custom_name: this.codedName,
            coded_name: this.codedName ? 1 : 0,
        })

    },


    toggleVersion() {

        // el ultimo grupo siempre es version definitiva
        if (this.form.isLastGroup)
            return;

        this.myForm.tversionbool = !this.myForm.tversionbool
        if (this.myForm.tversionbool) {
            this.myForm.tversionprev = this.myForm.tversion // guarda el valor anterior de version
        }
        this.myForm.tversionbool ? this.myForm.tversion='DEF' : this.myForm.tversion=this.myForm.tversionprev
    },


  }
}
</script>