<template>
<div>

  <span class="text-xs blue-lightest">
    <router-link :to="'/admin/validations/'"
      >{{ $t('validations') }}
    </router-link>
    <span class="mx-3">&gt;</span>
    <router-link :to="'/admin/validation-template/'+ templateId +'/edit'"
      class="font-bold"> {{ form.templateName }}
    </router-link>
    <span class="mx-3">&gt;</span>
    {{ $t('tasks') }}
    <span class="mx-3">&gt;</span>
    <span class="font-bold">{{ form.name }}</span>
  </span> 


  <div class="panel">

    <div class="flex">
      <div class="w-1/4 pr-4">
        <l-form-input ref="taskCode"
          :form="form"
          field="code"
          label="code"
        />
      </div>
      <div class="w-2/4 pr-4">
        <l-form-input ref="taskName"
          :form="form"
          field="name"
          label="name"
        />
      </div>
      <div class="w-1/8 pr-4">
        <l-form-input ref="taskOrder"
          :form="form"
          field="order_column"
          label="order"
        />
      </div>
      <div class="w-1/8 pr-4">
        <b-tooltip label="Tareas hijas concurrentes" position="is-left" v-show="form.parent_id===null">
          <v-switch
            :label="'Concurrentes'"
            :color="form.is_concurrent? 'green' : 'red'"
            :value="form.is_concurrent"
            v-model="form.is_concurrent"
            v-on:checked="form.is_concurrent = !form.is_concurrent"
            style="margin-left:20px; margin-bottom:25px "
          />
        </b-tooltip>
      </div>
    </div>


    <!-- Descripción -->
    <div class="flex items-center mt-8">
      <l-form-input
        :form="form"
        field="description"
        label="description"
        input-type="textarea"
        :maxlength="2000"
        class="w-full"
      />
    </div>


    <!-- Grupos -->
    <div class="mt-8">
      <label><b>{{ $t('groups') }}</b></label>
    </div>
    <div class="grid grid-cols-4 gap-4">

      <!-- recuadro grupo -->
      <span class="border border-blue-lighter rounded items-center p-2 bg-blue-lightest m-1" 
        v-for="(group) in form.groups" :key="group.id">

        <div style="float:right" @click="unlinkGroup(group.id, group.group.code)">
          <b-tooltip :label="$t('unlink')" position="is-left" type="is-danger">
            <b-icon pack="fas" icon="times-circle" />
          </b-tooltip>
        </div>

        <div class="text-2xl text-bold mb-4">{{ group.group.code }}</div>

        <div class="flex">

          <div class="flex w-1/2">{{ $t('order') }}:</div>
          <div class="flex w-1/2">
            <input v-model="group.order_column" type="text" style="width:40px;">
            <div @click="editGroup(group)">
              <b-tooltip :label="$t('save')" position="is-right">
                <b-icon pack="fas" icon="save" class="ml-1 text-green"/>
              </b-tooltip>
            </div>
          </div>

        </div>

        <ul>
          <li
            v-for="(user) in groupUsers(group.group.code)"
            :key="user.user_id"
            class="float-left py-2 w-full">
            <div
              class="bg-gray-lightest w-full border border-blue-lighter rounded flex items-center px-2"
              style="height:38px;line-height:33px">
              <img :src="user.basic.avatar"
                class="rounded-full overflow-hidden"
                style="width:28px;height:28px">
              <span class="flex-grow text-xs font-semibold text-blue-light truncate px-2">
                {{ user.basic.fullname }}
              </span>
            </div>
          </li>
        </ul>
       
      </span> 

      <!-- recuadro añadir grupo -->
      <span v-if="unassignedTemplateGroups.length" class="border border-blue-lighter rounded items-center p-2 bg-blue-lightest m-1"
        style="text-align: center">

        <span class="font-bold text-blue">{{ $t('add_group') }}</span>

        <b-select v-model="groupToAdd" :placeholder="$t('select')" expanded class="my-2">
          <option v-for="group in unassignedTemplateGroups" :value="group" :key="'group_'+group.id">
            {{ group.code }}
          </option>
        </b-select>

        {{ $t('order') }}:
        <input v-model="groupToAddOrder" type="text" style="width:40px;">

        <button class="btn btn-green items-center mt-2" style="margin:auto" type="button" @click="linkGroup()">
          <i class="fas fa-plus mr-1"></i> {{ $t('add_group') }}
        </button>

      </span>

    </div>

    <div>
      <label class="text-red text-xs">* Añadir/eliminar grupos a la tarea NO afecta a las tareas de flujos ya asignados, solo a esta plantilla</label>
    </div>

  </div>

  <form @submit.prevent="sendTaskData" @keydown="form.onKeydown($event)"
    class="w-full flex items-center justify-end mb-8">
    <v-button :disabled="disableSaveButton" type="submit" icon="thumbs-up" icon-pack="far" class="mr-4">{{ $t('save') }}</v-button>
    <v-button color="grey" icon="undo-alt" @click="$router.go(-1)">{{ $t('cancel') }}</v-button>
  </form>

</div>
</template>

<script>
import axios from 'axios'
import Form from 'vform'
import { mapGetters } from 'vuex'

export default {
  middleware: 'auth',
  title: 'validations_edit_page_title',
  subtitle: 'validations_edit_page_subtitle',
  menuOption: '0-5',

  components: {
  },

  data: () => ({
    templateId: null,
    templateName: '', // solo para el breadcrumb
    taskId: null,
    templateFlow: {}, // objecto template padre de esta tarea
    form: new Form({
      code: '',
      name: '',
      description: '',
      order_column: 0,
      groups: [],
      is_concurrent: false,
    }),
    disableSaveButton: false,
    unassignedTemplateGroups: [], // plantillas de grupos posibles a añadir al template
    groupToAdd: {}, // el grupo elegido para añadir
    groupToAddOrder: 0, // el orden en el que irá el nuevo grupo
  }),

  created() {

    this.templateId = this.$route.params.validation_id
    this.taskId = this.$route.params.task_id

  },

  computed: {
    ...mapGetters({
      company: 'app/company',
    }),
  },

  mounted() {
    this.getTaskData()
    this.getTemplateData()
    this.$refs.taskCode.setFocus()
  },

  methods: {

    async getTaskData() {
 
      const { data } = await axios.get('/api/v2/validations/template/task/' + this.taskId)

      if (data && data.success) {

        this.form.code = data.data.code
        this.form.name = data.data.name
        this.form.templateName = data.data.flow.name
        this.form.description = data.data.description
        this.form.order_column = data.data.order_column
        this.form.groups = data.data.groups
        this.form.is_concurrent = data.data.is_concurrent
        this.form.parent_id = data.data.parent_id

        // recalcula los grupos que quedan por añadir. Solo si ya hemos cargado los datos de la plantilla padre
        if (this.templateFlow.id) {
          this.unassignedTemplateGroups = this.groupDiff(this.templateFlow.groups, this.form.groups)
        }
        this.groupToAdd = {}
        this.groupToAddOrder = this.getMaxOrder(this.form.groups) + 1

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')
        this.$router.push({ name: 'project.home', params: {} })

      } else {
        this.$notify.error('error_retrieving_data')
      }
    },


    async getTemplateData() {
      const { data } = await axios.get('/api/v2/validations/template/' + this.templateId + '?withUsers=1')
      if (data && data.success) {

        this.templateFlow = data.data
        
        // a los grupos de la plantilla le restamos los de la tarea y esos son los que podrá añadir
        this.unassignedTemplateGroups = this.groupDiff(this.templateFlow.groups, this.form.groups)

        this.groupToAddOrder = this.getMaxOrder(this.form.groups) + 1

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')
        this.$router.push({ name: 'project.home', params: {} })

      } else {
        this.$notify.error('error_retrieving_data')
      }
    },


    async sendTaskData() {
      var url = '/api/v2/validations/template/task/' + this.taskId;
      this.disableButton();

      // Enviamos el form
      const { data } = await this.form.post(url)
      if (data && data.success) {

        this.$notify.success('success_editing')
        this.$router.push({ name: 'validation-template.edit', params: { validation_id: this.templateId } })

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')
        this.$router.push({ name: 'project.home', params: {} })

      } else {
        this.$notify.error('error_saving')
      }
    },


    // vincula el grupo seleccionado con la tarea
    async linkGroup() {
      if (!this.groupToAdd.id || isNaN(this.groupToAddOrder)) {
        this.$notify.error('Debe seleccionar un grupo de la lista y establecer su orden')
        return
      }

      const { data } = await axios.post("/api/v2/validations/templatetaskgroup", {
        company_id: this.company.id,
        task_id: this.taskId,
        group_code: this.groupToAdd.code,
        // status: this.groupToAdd.code, se mete en backend default 0
        order_column: this.groupToAddOrder,
      })
      if (data && data.success) {

        // reload
        this.getTaskData()
        
        this.$notify.success('success_editing')

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')

      } else {
        this.$notify.error('error_saving')
      }
    },


    // desvincula el grupo de esta tarea
    async unlinkGroup(groupId, groupName) {

      let self = this;
      this.$dialog.confirm({
        title: this.$t('delete'),
        message: this.$t('delete_generic', [groupName]),
        confirmText: this.$t('delete'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {

          const { data } = await axios.post("/api/v2/validations/templatetaskgroup/" + groupId + "/delete", { })
          if (data && data.success) {

            // reload
            this.getTaskData()
            this.$notify.success('success_deleting_generic')

          } else if(data && data.msg){

            this.$notify.error('no_access_permissions')

          } else {
            this.$notify.error('error_saving')
          }
        }
      })
    },


    // edita el grupo (orden y poco mas)
    async editGroup(group) {

      if (isNaN(parseInt(group.order_column))) {
        this.$notify.error('Debe establecer un orden numerico para el grupo ' + group.group.code)
        return
      }

      let url = '/api/v2/validations/templatetaskgroup/' + group.id;

      const { data } = await axios.post(url, { order_column: group.order_column })
      if (data && data.success) {

        // reload
        this.getTaskData()
        this.$notify.success('success_editing')

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')

      } else {
        this.$notify.error('error_saving')
      }
    },


    // devuelve el contenido de usuarios del grupo pasado (usuarios estan en grupo de la plantilla del flujo no en el grupo de la tarea)
    groupUsers(code) {

      if (!this.templateFlow.groups)
        return []

      let group = this.templateFlow.groups.filter(group => group.code == code)

      return group[0].users || []
    },


    // a los grupos de la plantilla le restamos los de la tarea y esos son los que podrá añadir
    // array diff, devuelve los templateGroups que no estan en taskGroup
    groupDiff(templateGroups, taskGroups) {

      return templateGroups.filter(
              templateGroup => !taskGroups.some(taskGroup => templateGroup.code == taskGroup.group.code)
            )

    },


    // obtiene el maximo orden de los grupos de la tarea
    getMaxOrder(groups) {

      let maxGroup = groups.reduce( function(maxValue, currentGroup) {
          return { order_column: Math.max(maxValue.order_column, currentGroup.order_column) }
        }
      )
      return maxGroup.order_column

    },


    disableButton() {
      this.disableSaveButton = true
      let self = this
      setTimeout(function() {
        self.disableSaveButton = false
      }, 2000);
    }

  },
};
</script>
