<template>
  <button
    :type="nativeType"
    :disabled="disabled"
    @click="click"
    class="btn"
    :class="{
      [`btn-${color}`]: true,
      'w-full': full,
      'btn-loading': loading,
      [sizeClass]: true,
    }"
  >
    <b-icon v-if="icon"
      :icon="icon"
      :pack="iconPack"
      :class="{ 'mr-1': iconSize === 'is-small', 'mr-2': iconSize !== 'is-small' }"
      :size="iconSize"
    />
    <span>
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  name: 'v-button',

  props: {
    color: { type: String, default: 'blue' },
    nativeType: { type: String, default: 'button' },
    icon: { type: String, default: null },
    iconPack: { type: String, default: 'fas' },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    size: { type: String, default: null },
    full: { type: Boolean, default: false }
  },

  computed: {
    sizeClass() {
      var size = 'px-4 rounded '
      if (this.icon) {
        size += 'py-2'
      } else {
        size += 'py-3'
      }
      if (this.size === 'large') {
        size = 'p-4 rounded'
      } else if (this.size === 'small') {
        size = 'px-2 py-1 text-xs rounded-sm'
      }
      return size
    },
    iconSize() {
      if (this.size) {
        return 'is-' + this.size
      }
      return null
    }
  },

  methods: {
    click (event) {
      this.$emit('click', event)
    }
  },
};
</script>
