<template>
<div>
  <div class="panel p-2">
    <div class="flex items-center justify-between w-full mb-5">
      <div><label><b>{{ measurement && measurement.id ? 'Editar' : 'Crear' }} medición: </b></label></div>
      <div>
        <b-tooltip :label="$t('close')" position="is-left">
          <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
            <b-icon pack="fas" icon="times" size="is-small" />
          </div>
        </b-tooltip>
      </div>
    </div>

    <div class="w-full pb-3">
      <div class="mt-4">
        <l-form-input
          v-model="form.code"
          ref="code"
          :form="form"
          field="code"
          label="code"
        />
      </div>

      <div class="mt-4">
        <l-form-input ref="name"
          :form="form"
          field="name"
          label="name"
        />
      </div>

      <div class="mt-4">
        <label for="measurement_units" class="label">
          {{ $t('unit') }}
        </label>
        <l-form-select
          :form="form"
          field="unit_id"
          :options="optionsUnits"
          placeholder="select_unit"
          full
          optionDisplayField="name"
        />
      </div>

      <div class="mt-4">
        <label for="weight" class="label">
          {{ $t('weight') }}
        </label>
        <l-form-input
          v-model="form.weight"
          ref="weight"
          :form="form"
          field="weight"
        />
      </div>

    </div>

    <div class="flex items-center justify-end w-full">
      <form @submit.prevent="sendMeasurementData" @keydown="form.onKeydown($event)" v-if="!processingData"
        class="w-full flex items-center justify-end mb-2 mt-2">
        <v-button :disabled="disableSaveButton" type="submit" icon="thumbs-up" icon-pack="far" class="mr-4">{{ $t('save') }}</v-button>
        <v-button color="grey" icon="undo-alt" @click="onCloseClicked">{{ $t('cancel') }}</v-button>
      </form>

      <v-button-processing v-if="processingData" />
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import Form from "vform";
import axios from "axios";
import ModelTags from "../../../components/model-tags";

export default {
  name: "template-measurement-edit",

  components: {
    ModelTags
  },

  data: () => ({
    activityId: null,
    subactivityId: null,
    disableSaveButton: false,
    processingData: false,

    form: new Form({  // data activity or subactivity of template project
      id: null,
      entity: 'activity',
      entity_id: null,
      parent_measurement_id: null,
      unit_id: null,
      code: '',
      name: '',
      weight: null,
    }),

  }),

  props: {
    entity: { type: String, required: true },
    entity_id: { type: Number, required: true },
    measurement: { type: Object, default: null },
    optionsUnits: { type: Array, default: function() { return [] } }
  },

  watch: {
  },

  computed: {
    ...mapGetters({
      storeProject: 'template/project',
    }),

  },

  mounted() {
    if( this.measurement ) {
      this.form.id = this.measurement.id
      this.form.parent_measurement_id = this.measurement.parent_measurement_id
      this.form.unit_id = this.measurement.unit_id
      this.form.entity = this.measurement.entity
      this.form.entity_id = this.measurement.entity_id
      this.form.code = this.measurement.code
      this.form.name = this.measurement.name
      this.form.weight = this.measurement.weight
    }
  },

  methods: {
    async getMeasurementData() {
      const { data } = await axios.get('/api/v2/measurement/templates/' + this.form.id, {params: { with_statuses: 1 }})
      if (data && data.success) {
        this.form.id = data.data.id
        this.form.parent_measurement_id = data.data.parent_measurement_id
        this.form.unit_id = data.data.unit_id
        this.form.code = data.data.code
        this.form.name = data.data.name
        this.form.weight = data.data.weight

      } else {
        this.$notify.error('error_loading_measurement_data')
      }
    },

    async sendMeasurementData() {
      this.processingData = true
      var url = '/api/v2/measurement/templates';
      let isCreate = ! this.form.id // si id es null o cero es crear la medicion

      // if( isCreate )
      //   this.form.project_id = this.projectId

      url += (! isCreate ? ('/' + this.form.id) : ('/' + this.entity + '/' + this.entity_id) ) // si id de actividad nos es null editamos
      // Enviamos el form
      const { data } = await this.form.post(url).catch((error) => { this.processingData = false })

      if (data && data.success) {

        if (this.form.parent_id) {
          this.$notify.success(isCreate ? 'success_creating_subactivity' : 'success_editing_subactivity')
        } else {
          this.$notify.success(isCreate ? 'success_creating_activity' : 'success_editing_activity')
        }

        if( data.warning )
          this.$notify.warning(data.warning)

        this.processingData = false
        this.$emit('epc-success-creating', this.entity)

      } else {
        this.processingData = false
        if( data && data.message )
          this.$notify.error(data.message)
        else
          this.$notify.error(this.form.parent_id ? 'error_creating_subactivity' : 'error_creating_activity')
      }
    },

    onCloseClicked() {
      this.measurement = null;

      this.$emit('epc-close', true)
    },

    handleEditEntity(entity) {

    }
  },
};
</script>

