<template>
  <b-modal :active.sync="isModalActive" scroll="clip">
    <div class="flex flex-row max-h-screen bg-white border rounded shadow overflow-y-none border-blue-light" v-if="document">

        <div class="flex w-3/5">
          <div v-if="canUpdateModalDocument"
            :style="{ 'background-color': backgroundColor }"
            class="gallery-btn text-white flex justify-center items-center"
            @click="updateModalDocument(-1)"
          >
            <b-icon pack="fas" icon="chevron-left"/>
          </div>
          <div v-if="image" class="flex items-center w-full" :style="{ 'background-color': backgroundColor }">
            <img :src="image" :alt="document.name" class="object-cover w-full h-full" @error="imgError">
          </div>
          <div v-else-if="video" class="flex items-center w-full" :style="{ 'background-color': backgroundColor }">
            <video :src="video" controls></video>
          </div>
          <div v-else
              :style="{ 'background-color': backgroundColor }"
              class="flex items-center w-full text-white text-2xl font-semibold justify-center"
            >
            {{ fileExtension() }}
          </div>
          <div v-if="canUpdateModalDocument"
            :style="{ 'background-color': backgroundColor }"
            class="gallery-btn text-white flex justify-center items-center"
            @click="updateModalDocument(1)"
            >
            <b-icon pack="fas" icon="chevron-right"/>
          </div>
        </div>
      <div class="w-2/5 p-4 overflow-y-scroll bg-grey-lighter">
        <div class="flex flex-col items-start mb-4">

          <div class="flex flex-row items-center">
            <div class="w-10 h-10 mr-2 overflow-hidden border rounded-full border-grey">
              <img :src="document.user.avatar" :alt="document.user.fullname">
            </div>
            <div class="flex flex-col">
              <div class="text-base text-blue">{{ document.user.fullname }}</div>
              <div class="text-sm text-grey">{{ document.user.email }}</div>
            </div>
          </div>

          <div class="flex flex-col mt-4">
            <div class="text-md"><strong>{{ document.name }}</strong><br></div>
            <div class="text-sm">{{ $t('uploaded_at') + ': '}}<strong>{{ document.updated_at }}</strong></div>
            <div class="text-sm">{{ $t('size') + ': '}}<strong>{{ getReadableFileSizeString(document.size) }}</strong><br></div>
            <div class="text-sm">{{ $t('type') + ': '}}<strong>{{ document.name.substr(document.name.lastIndexOf('.') + 1).toUpperCase()}}</strong><br></div>
            <div class="text-sm" v-if="document.exif && document.exif.original_datetime">{{ $t('taken_at') + ': '}}<strong>{{ document.exif.original_datetime }}</strong></div>
          </div>

          <div class="flex flex-row justify-start mt-4">
            <v-button-icon-round
              v-if="isPdf"
              @click="loadPDFInNewTab"
              icon="eye"
              :tooltip="documentDownloadTooltip('view_document')"
              :disabled="!isOk()"
              tooltip-position="top"
              class="mr-1"
            />
            <v-button-icon-round
              @click="downloadDoc"
              icon="download"
              :tooltip="documentDownloadTooltip('download_document')"
              :disabled="!isOk()"
              tooltip-position="top"
              class="mr-1"
            />
            <v-button-icon-round
              @click="deleteDoc"
              icon="trash-alt"
              color="red"
              tooltip="delete_document"
              tooltip-position="top"
              v-if="canDelete && !document.is_document_project"
            />
          </div>


          <!-- Geolocalización -->
          <b-collapse class="panel" :open.sync="isOpen" v-if="document.exif && (document.exif.lat && document.exif.lon)">
            <div slot="trigger" class="panel-heading">
              <strong>{{ $t('geolocation') }}</strong>
            </div>
            <div class="panel-block">
              <photo-map class="w-full" v-if="document.exif && (document.exif.lat && document.exif.lon)"
                        :datamaps="datamaps"/>
            </div>
          </b-collapse>

        </div>

        <b-field :label="$t('filename')">
          <b-input
            v-model="filename"
            :placeholder="$t('filename')"
            required
            icon-pack="fas"
          />
        </b-field>
        <div class="mb-2">

        </div>
        <!-- ETIQUETAS -->
        <div class="mb-2">
          <model-tags
            v-if="document"
            :model-id="document.document_id"
            :initial-tags="document.tags"
            model-type="document"
            :allow-edit="false"
            :add-from-list="true"
            :parent-model-id="project.id"
            parent-model-type="project"
            ref="modelTags"
            :disabled="!canDelete"
          />
        </div>
        <!-- Usuarios mencionados -->
        <mention-users
          ref="mention_users"
          v-if="entityId > 0"
          :entityType="entityType"
          :entityId="entityId"
          :document="document"
          :disabled="!canDelete"
        />

        <div style="margin-top: 10px">
          <!-- Botón Upload -->
          <button v-if="document !== null && canDelete"
                  class="mr-2 button is-primary"
                  @click="updateDocument"
                  :disabled="filename.trim() == ''"
          >
            <b-icon pack="fas" icon="save" size="is-small"/>
            <span>{{ $t('save') }}</span>
          </button>
        </div>



      </div>
    </div>
  </b-modal>
</template>

<script>
  import axios from "axios";
  import PhotoMap from "~/components/Map.vue";
  import EventBus from "~/plugins/bus";
  import MentionUsers from '·/components/mention-users'
  import ModelTags from "·/components/model-tags";
  import {mapGetters} from "vuex";
  import entityDocumentEditor from "·/components/entity-document-editor";

  export default {
    name: "entity-document-preview",

    components: {
      PhotoMap,
      MentionUsers,
      ModelTags,
      entityDocumentEditor
    },

    props: {
      entityId: {type: Number, required: true},
      entityType: {type: String, required: true},
      canDelete: {type: Boolean, default: false},
      showVersions: { type: Boolean, default: true },
      canUpdateModalDocument: { type: Boolean, default: false },
      isAttached: { type: Boolean, default: false },
    },

    data: () => ({
      filename: "",
      extension: "",
      backgroundColor: null,
      isModalActive: false,
      document: null,
      image: null,
      video: null,
      datamaps: {},
      isOpen: false,
      isPdf: true,
    }),

    computed: {
      ...mapGetters({
        project: 'app/project'
      }),
    },
    beforeDestroy(){
      EventBus.$off("load-locations");
    },
    methods: {
      imgError() {
        this.image = null;
        // this.colorBackground();
      },

      fileExtension() {
        let index = this.document.name.lastIndexOf(".");
        if (index) {
          let ext = this.document.name.substr(index + 1);
          if (ext) {
            return ext.toUpperCase();
          }
        }
        return "";
      },

      colorBackground() {
        var codes = [0, 0, 0];
        let ext = this.fileExtension();
        for (var i = 0; i < ext.length; i++) {
          codes[i] = ext.charCodeAt(i);
          if (i == 2) {
            break;
          }
        }
        var rgb = (codes[2] + 100) | ((codes[1] + 50) << 8) | (codes[0] << 16);
        this.backgroundColor = "#" + (0x1000000 + rgb).toString(16).slice(1);
        // console.log('aquí', hexColor)
      },

      loadPDFInNewTab() {
          window.open(this.document.file.raw + '?' + Date.now(), '_blank');
      },

      async loadPreviewImage() {

        this.image = this.document.file.big_thumbnail;

      },

      async loadVideoPlayer(){
       this.video = this.document.file.raw;
      },

      getReadableFileSizeString(fileSizeInBytes) {
        var i = -1;
        var byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
        do {
          fileSizeInBytes = fileSizeInBytes / 1024;
          i++;
        } while (fileSizeInBytes > 1024);
        return Math.max(fileSizeInBytes, 0.01).toFixed(2) + byteUnits[i];
      },
      async downloadDoc() {
        this.$notify.success("starting_download_document");
        let url = "/api/v2/document/" + this.document.document_id;

        const {data} = await axios.get(url);

        if (data) {

          window.open(data.data);

          // const link = document.createElement("a");
          // link.href = window.URL.href(data.data);
          // link.setAttribute("download", this.document.name);
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);

        } else {
          this.$notify.error("error_download_document");
        }
      },
      deleteDoc() {
        this.$dialog.confirm({
          title: this.$t("delete_document"),
          message: this.$t("delete_document_confirm_text"),
          confirmText: this.$t("delete_document"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            let url = "/api/v2/" + this.entityType + "/" + this.entityId + "/document/" + this.document.document_id;
            if (this.isAttached) {
              url += '?is_attached=1'
            }
            const {data} = await axios.post(url);
            if (data && data.success) {
              this.$notify.success("success_deleting_document");
              this.$emit("reload-documents-list");

              this.hide();
            } else {
              this.$notify.error("error_deleting_document");
            }
          }
        });
      },
      show(document) {
        // instanciamos
        var self = this;
        this.document = document;
        this.extension = this.getFileExtension(document);
        this.filename = document.name.replace(/\.[^/.]+$/, "");
        //Aqui

        if (this.document.type == 3) {
          this.loadVideoPlayer();
        } else {
          this.loadPreviewImage();
        }
        EventBus.$off("load-locations");
        EventBus.$on("load-locations", function (locations) {
          if (locations) {
            self.datamaps = {
              id: 1,
              latitude: locations.lat,
              longitude: locations.lon
            };
          }
        });

        this.colorBackground();
        this.isModalActive = true;
      },

      getFileExtension(file) {
        let re = /(?:\.([^.]+))?$/;

        return '.' + re.exec(file.name)[1];
      },

      hide() {
        this.document = null;
        this.isModalActive = false;
        this.filename = "";
        this.extension = "";
      },

      // Actualizamos las etiquetas y las menciones
      updateDocument() {
        this.$refs.mention_users.uploadMentions();
        this.$refs.modelTags.syncTagsToModel(this.document.document_id);
        this.updateDocumentName();

        this.$notify.success("success_editing_document");
        this.$emit("reload-documents-list");
        this.hide();

        // Refrescamos los componentes dependiente de notificaciones para actualizar la lista de documentos
        // if (this.entityType === 'activity') {
        //   console.log("desde updateDocument")
        //   EventBus.$emit('refreshActivityDocumentsNotifications', true)
        // } else {
        //   EventBus.$emit('refreshIssueDocumentsNotifications', true)
        // }
      },

      async updateDocumentName() {
        let url = "/api/v2/document/" + this.document.document_id;
        let data = await axios.post(url, {original_name: this.filename.trim() + this.extension});
      },

      isOk(){
        return this.document.status == 1;
      },

      documentDownloadTooltip(tooltip)
      {
        if(this.isOk())
          return tooltip;

        return 'document_still_processing';
      },

      updateModalDocument(direction) {
        this.$emit('update-modal-document', {document_id: this.document.document_id, direction})
      }

    }
  };
</script>
<style>
.gallery-btn {
  width: 5%;
  cursor: pointer;
}
.gallery-btn:hover {
  opacity: .9;
  transition: 0.3s;
}
</style>
