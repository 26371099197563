<template>
    <div>
        <notification-panel-header :notification="notification">
        </notification-panel-header>

        <div v-if="notification.document" class="flex items-start mb-2">
            <entity-document-thumbnail
                :key="notification.document.id"
                :document="notification.document"
                :parent-id="notification.data.activity_id"
                entity-type="activity"
                class="w-1/5"
            />
        </div>
        <div v-else class="flex items-start mb-2">
            <span>{{ $t('deleted_document') }}</span>
        </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import notificationPanelHeader from './notification-components/notification-panel-header'
  import entityDocumentThumbnail from './../entity-document-thumbnail'

  export default {
    name: "notification-DocumentUploaded",

    props: {
      notification: { type: Object, required: true }
    },

    components: {
      notificationPanelHeader,
      entityDocumentThumbnail
    },

    computed: {
      ...mapGetters({
        project: 'app/project',
        locale: 'lang/locale',
      }),
    },

    methods: {

    },
  }
</script>

<style scoped>

</style>