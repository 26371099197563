import * as types from '../mutation-types'

// state
export const state = {
  pageTitle: '',
  pageSubtitle: '',
  menuOption: '',
  project: {},
  group: {},
  company: {},
  companies: {},
  navData: {},
  tags: [],
  visualConfig: {},
  dashboardReport: {},
  importEntities: {},
}

// mutations
export const mutations = {

  [types.ADMIN_NAVIGATION_DATA] (state, navData) {
    state.navData = Object.assign({}, state.navData, navData)
  },

  [types.UPDATE_PAGE_INFO] (state, { info }) {
    state.pageTitle = info.title
    state.pageSubtitle = info.subtitle
    state.menuOption = info.menuOption
  },

  [types.PROJECT_SELECTED] (state, { project }) {
    state.project = project
  },

  [types.GROUP_SELECTED] (state, { group }) {
    state.group = group
  },

  [types.COMPANY_SELECTED] (state, { company }) {
    state.company = company
    state.company.isLoteCanal = (state.company.configs && state.company.configs.loteCanal) ? state.company.configs.loteCanal : 0 // mostrar cosas solo a empresas de lotes de canal YII
  },

  [types.SET_COMPANIES] (state, { companies }) {
    state.companies = companies
  },

  [types.TAGS_SELECTED] (state, { tags }) {
    state.tags = tags
  },

  [types.VISUAL_CONFIG] (state, { config }) {
    // check if is empty object
    if (Object.keys(config).length === 0 && config.constructor === Object) {
      state.visualConfig = {}
    } else {
      Object.assign(state.visualConfig, config)
    }
  },

  [types.DASHBOARD_REPORT] (state, { dashboardReport }) {
    state.dashboardReport = dashboardReport
  },

  [types.IMPORT_ENTITIES] (state, { importEntities }) {
    state.importEntities = importEntities
  },

  [types.LOGOUT] (state) {
    // console.log('app/logout')
     state.project = null
     state.group = null
     state.company = null
     state.companies = null
     state.visualConfig = {}
     state.dashboardReport = {}
     state.importEntities = {}
    // Cookies.remove('admin_nav_data')
    // Cookies.remove('project')
  },

}



// actions
export const actions = {

  updateNavData ({ commit}, payload) {
    commit(types.ADMIN_NAVIGATION_DATA, payload)
  },

  updatePageInfo ({ commit}, payload) {
    commit(types.UPDATE_PAGE_INFO, payload)
  },

  setCompany ({ commit }, payload) {
    commit(types.COMPANY_SELECTED, payload)
  },

  setCompanies ({ commit }, payload) {
    commit(types.SET_COMPANIES, payload)
  },

  setGroup ({ commit }, payload) {
    commit(types.GROUP_SELECTED, payload)
  },

  setProject ({ commit }, payload) {
    commit(types.PROJECT_SELECTED, payload)
  },

  setTags ({ commit }, payload) {
    commit(types.TAGS_SELECTED, payload)
  },

  setVisualConfig ({ commit }, payload) {
    commit(types.VISUAL_CONFIG, payload)
  },

  setDashboardReport ({ commit }, payload) {
    commit(types.DASHBOARD_REPORT, payload)
  },

  setImportEntities ({ commit }, payload) {
    commit(types.IMPORT_ENTITIES, payload)
  },

  logout({ commit }) {
    commit(types.LOGOUT)
  },

}

// getters
export const getters = {
  pageInfo: state => ({
    title: state.pageTitle,
    subtitle: state.pageSubtitle,
    menuOption: state.menuOption,
  }),
  project: state => state.project,
  group: state => state.group,
  company: state => state.company,
  companies: state => state.companies,
  tags: state => state.tags,
  navData: state => state.navData,
  visualConfig: state => state.visualConfig,
  dashboardReport: state => state.dashboardReport, 
  importEntities: state => state.importEntities,
}
