
export const state = {
    activities: {},
    validations: {},
}

export const mutations = {
    saveActivitiesFilters (state, filters) {
      state.activities = Object.assign({}, state.activities, filters)
    },

    saveValidationsFilters (state, filters) {
      state.validations = Object.assign({}, state.validations, filters)
    },

    clearActivitiesFilters (state) {
      state.activities = {}
    },

    clearValidationsFilters (state) {
      state.validations = {}
    },
}

export const actions = {
    setActivitiesFilters ({ commit }, payload) {
      commit('saveActivitiesFilters', payload)
    },

    setValidationsFilters ({ commit }, payload) {
      commit('saveValidationsFilters', payload)
    },

    resetActivitiesFilters ({ commit }) {
      commit('clearActivitiesFilters')
    },

    resetValidationsFilters ({ commit }) {
      commit('clearValidationsFilters')
    },
}

export const getters = {
    activities: state => state.activities,
    validations: state => state.validations,
}
