<template>
  <b-modal :active.sync="isModalActive" :width="640" scroll="keep">
    <div class="flex flex-col border border-blue-light rounded shadow bg-white p-4">

      <div class="flex flex-col w-full">
        <div class="uppercase font-semibold text-blue">{{ $t('add_task') }}</div>
        <div class="text-blue">Datos sobre la tarea</div>
      </div>

      <form @submit.prevent="submitForm">

        <div class="flex flex-col w-full mt-4">

          <div class="flex flex-row">

          <div class="flex flex-col w-2/4 mr-2">
            <div class="text-xs text-blue font-medium">{{ $t("code") }}</div>
            <div class="flex flex-row w-full">
              <input
                v-model="form.code"
                title="code"
                required="true"
                name="code"
                class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
              />
            </div>
          </div>

          <div class="flex flex-col w-2/4 mr-2">
            <div class="text-xs text-blue font-medium">{{ $t("weighting") }}</div>
            <div class="flex flex-row w-full">
              <input
                v-model="form.weight"
                title="weighting"
                required="true"
                name="weight"
                class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
              />
            </div>
          </div>



          </div>


        <div class="flex flex-col mt-2">

          <div class="flex flex-col w-full">
            <div class="text-xs text-blue font-medium">{{ $t("name") }}</div>
            <div class="flex flex-row w-full">
              <input
                v-model="form.name"
                title="name"
                required="true"
                name="name"
                class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
              />
            </div>
          </div>

          <div class="flex flex-col w-full mt-2">
            <div class="text-xs text-blue font-medium">{{ $t("description") }}</div>
            <div class="flex flex-row w-full">
              <textarea
                v-model="form.description"
                title="description"
                required="false"
                name="description"
                class="w-full h-16 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
              />
            </div>
          </div>



        </div>

      </div>

      <div class="flex flex-row justify-start w-full mt-6 items-center">
        <button class="btn btn-blue" type="submit">{{$t('save')}}</button>
      </div>
      </form>

    </div>
  </b-modal>
</template>

<script>
  import axios from "axios";
  import {mapGetters} from "vuex";

  export default {
    name: "task-modal",

    components: {},

    props: {
      entityId: {type: Number, required: true},
      entityType: {type: String, required: true},
    },

    data: () => ({
      id: null,
      isModalActive: false,
      isMeasurementUnitsDisabled: false,
      unitsList: [],
      form: {},
    }),

    computed: {
      ...mapGetters({
        project: "app/project",
        company: "app/company"
      })
    },

    methods: {
      /**
       * Envia el "formulario" con una nueva medición
       */
      async submitForm() {


        let url = "/api/v2/assignments/" + this.entityType + "/" + this.entityId;
        axios({
          method: "post",
          url: url,
          data: this.form
        })
          .then(response => {
            this.$toast.open({
              message: this.$t("assignment_insert_success"),
              type: "is-success",
              position: "is-top-right"
            });

            this.$emit("reload");
            this.hide();
          })
          .catch(error => {
            //Se produjo un error
            this.isLoading = false;
            if (error.response && error.response.status === 422) {
              //extraemos el primer error de validación
              error.error_msg =
                error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                  ][0];
            }

            // Mostramos el error
            this.$toast.open({
              message: error.error_msg || this.$t("assignment_insert_error"),
              type: "is-danger",
              position: "is-top-right"
            });
          });
      },


      /**
       * Pide la lista de unidades para el proyecto actual
       */
      getProjectUnits() {
        const {data} = axios
          // .get("/api/v2/project/" + this.project.id + "/measurement-units")
          .get("/api/v2/company/" + this.company.id + "/measurement-units")
          .then(response => {
            if (response.data && response.data.success) {
              this.unitsList = response.data.units;
            } else {
              this.$notify.error("error_loading_measurement_units_data");
            }
          });
      },

      /**
       * Abre este modal
       */
      show(task = null) {
        this.getProjectUnits();
        this.form = this.defaultFormValues();
        this.isModalActive = true;
      },

      /**
       * Valores por defecto pra el form
       */
      defaultFormValues() {
        return {
          code: "",
          name: "",
          weight: "",
          assignmentType: "task",
          measure_unit_id: null,
          parent_assignment_id: null,
          total_units: null,
          description: "",
        };
      },

      /**
       * Oculta este formulario
       */
      hide() {
        this.id = null;
        this.isModalActive = false;
        this.form = this.defaultFormValues();
      }
    }
  };
</script>
<style scoped>
  /* Oculta los botones para aumentar los inputs numéricos */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
</style>
