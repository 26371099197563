<template>
  <b-modal :active.sync="isModalActive" :width="640" scroll="keep">
    <div class="border border-blue-light rounded shadow bg-white">
      <form @submit.prevent="saveExtraField" class="w-full">
        <header class="font-thin uppercase text-blue-light modal-card-head"
        >Añadir / editar campo adicional</header>

        <div class="modal-card-body ">
            <div class="border-grey-light border-b border-dashed mb-3">
              <div class="w-full inline-flex pb-3">
                <label form="reference_code" class="md:w-1/3">{{ $t('reference_code')}}</label>
                <v-input
                  v-model="form.code"
                  title="reference_code"
                  required="true"
                  name="code"
                  placeholder="reference_code"
                  class="md:w-2/3"
                  maxlength="100"
                />
              </div>

              <div class="w-full inline-flex pb-3">
                <label form="name" class="md:w-1/3">{{ $t('name')}}</label>
                <v-input
                  v-model="form.name"
                  title="name"
                  required="true"
                  name="name"
                  placeholder="name"
                  class="md:w-2/3"
                  maxlength="100"
                />
              </div>

              <div class="w-full inline-flex pb-3">
                <label form="type" class="md:w-1/3">{{ $t('type')}}</label>
                <b-select v-model="form.field_type" :placeholder="$t('select')" class="md:w-2/3" expanded>
                    <option v-for="option in options" :value="option.value" :key="'type_'+option.value">
                      {{ option.text }}
                    </option>
                </b-select>
              </div>

              <div class="w-full inline-flex">
                <label form="order_column" class="md:w-1/3">{{ $t('order')}}</label>
                <b-input type="number"
                  min="0"
                  max="100"
                  v-model="form.order_column"
                  class="md:w-2/3">
                </b-input>
              </div>

              <div class="w-full inline-flex mt-2">
                <div class="md:w-1/3">
                  <b-tooltip :label="$t('automatic_field_description')" position="is-right">
                    <label form="order_column">{{ $t('automatic_field')}}</label>
                  </b-tooltip>
                </div>
                <v-switch
                  :label="' '"
                  :color="form.is_automatic ? 'green' : 'red'"
                  v-model="form.is_automatic"
                  v-on:checked="form.is_automatic = !form.is_automatic"
                  style="margin-left:20px; margin-bottom:25px "
                  />
              </div>
            </div>

        </div>
        <footer class="modal-card-foot">
          <div class="w-full inline-flex justify-end">
            <button class="btn btn-blue" nativeType="submit">{{$t('save')}}</button>
          </div>
        </footer>
      </form>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Form from "vform";

export default {
  name: "extra-field-modal",

  components: {},

  data: () => ({
    isModalActive: false,
    data: null,
    form: {
      code: "",
      name: "",
      field_type: "",
      order_column: "",
      is_automatic: false
    },
    options: [
      { value: 0, text: 'select' },
      { value: 1, text: 'input' },
      { value: 2, text: 'calendar' },
      { value: 3, text: 'checkbox' },
    ]
  }),

  methods: {
    async saveExtraField() {
      let url = "/api/v2/project/"+this.data.project_id+"/extrafields";

      if( this.data.id > 0 )
        url = "/api/v2/extrafields/" + this.data.id;

      axios({
        method: "post",
        url: url,
        data: this.form
      })
        .then(response => {
          this.$toast.open({
            message: this.$t("save_successful"),
            type: "is-success",
            position: "is-top-right"
          });

          this.hide();
          this.$emit("reload-extra-fields");
        })
        .catch(error => {
          //Se produjo un error
          this.isLoading = false;
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("save_error"),
            type: "is-danger",
            position: "is-top-right"
          });
        });
    },

    show(data) {
      this.data = data;
      this.form.code = data.code;
      this.form.name = data.name;
      this.form.field_type = data.field_type;
      this.form.order_column = data.order_column;
      this.form.is_automatic = data.is_automatic;
      this.isModalActive = true;
    },

    hide() {
      this.data = null;
      this.isModalActive = false;
      this.form.code = "";
      this.form.name = "";
      this.field_type = "";
      this.order_column = "";
      this.is_automatic = false;
    }
  }
};
</script>
<style scoped>
/* Oculta los botones para aumentar los inputs numéricos */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
