<template>
    <div>
        <!-- Table assignment by breakdown -->
        <div class="panel">
            <b-table :data="sortedBudgets" :loading="isLoadingTable" paginated backend-pagination :per-page="perPage"
                :total="total" @page-change="onPageChange" backend-sorting :default-sort="orderField"
                :default-sort-direction="orderDir" @sort="onFrontSort" class="w-full" striped bordered icon-pack="fas"
                detailed detail-key="assignment_id" :show-detail-icon="showDetailIcon" scrollable>
                <template>
                    <b-table-column field="assignment_code" :label="$t('code')" sortable v-slot="props" width="20%">
                        {{ props.row.assignment_code }}
                    </b-table-column>
                    <b-table-column field="assignment_name" :label="$t('chapter')" sortable v-slot="props">
                        {{ props.row.assignment_name }}
                    </b-table-column>
                    <b-table-column field="importe_vt" :label="$t('total_sale')" sortable v-slot="props">
                        {{ formatNumber(props.row.importe_vt) }}
                    </b-table-column>
                    <b-table-column field="importe_cpt" :label="$t('expected_total_cost')" sortable v-slot="props">
                        <!-- {{ calculateTotalAmountCapitulo(props.row) }} -->
                        {{ formatNumber(props.row.importe_cpt) }}
                    </b-table-column>
                </template>

                <template #detail="props">
                    <!-- Verificar si hay subcapítulos (nivel hijo) -->
                    <div
                        v-if="props.row.children.length > 0 && props.row.children.some(child => child.type === 'App\\Models\\AssignmentType\\CertifierHead')">
                        <div class="w-full h-full flex items-center justify-between mb-3">
                            <h3 class="font-bold">{{ $t('Sub-capitulo') }}</h3>
                        </div>
                        <!-- Mostrar subcapítulos -->
                        <b-table
                            :data="sortTableData(props.row.children.filter(child => child.type === 'App\\Models\\AssignmentType\\CertifierHead'))"
                            :loading="isLoadingTable" class="w-full" striped bordered icon-pack="fas" detailed
                            detail-key="assignment_id" :show-detail-icon="showDetailIcon" scrollable>
                            <template>
                                <b-table-column field="assignment_code" :label="$t('code')" sortable v-slot="props"
                                    width="20%">
                                    {{ props.row.assignment_code }}
                                </b-table-column>
                                <b-table-column field="assignment_name" :label="$t('chapter')" sortable v-slot="props">
                                    {{ props.row.assignment_name }}
                                </b-table-column>
                                <b-table-column field="importe_vt" :label="$t('total_sale')" sortable v-slot="props">
                                    {{ formatNumber(props.row.importe_vt) }}
                                </b-table-column>
                                <b-table-column field="importe_cpt" :label="$t('expected_total_cost')" sortable
                                    v-slot="props">
                                    {{ formatNumber(props.row.importe_cpt) }}
                                </b-table-column>
                            </template>
                            <template #detail="props">
                                <div v-if="props.row.children.length > 0">
                                    <div class="w-full h-full flex items-center justify-between mb-3">
                                        <h3 class="font-bold">{{ $t('assignments') }}</h3>
                                    </div>
                                    <!-- Mostrar las asignaciones de subcapítulos -->
                                    <b-table :data="sortTableData(props.row.children)" class="w-full" striped bordered
                                        icon-pack="fas" detailed detail-key="assignment_id"
                                        :show-detail-icon="showDetailIcon" scrollable>
                                        <!-- Renderización de las asignaciones -->
                                        <template>
                                            <b-table-column field="assignment_code" :label="$t('assignment_code')"
                                                sortable v-slot="data">
                                                {{ data.row.assignment_code }}
                                            </b-table-column>
                                            <b-table-column field="assignment_name" :label="$t('name')" sortable
                                                v-slot="data">
                                                <div class="text-left">
                                                    {{ data.row.assignment_name }}
                                                </div>
                                            </b-table-column>
                                            <b-table-column field="unit" :label="$t('unit')" sortable v-slot="data">
                                                {{ data.row.unit.name }}
                                            </b-table-column>
                                            <b-table-column field="assignment_total_units" :label="$t('assignment')"
                                                class="!text-right" sortable v-slot="data">
                                                {{ formatNumber(data.row.assignment_total_units) }}
                                            </b-table-column>
                                            <b-table-column field="assignment_unit_price" :label="$t('unit_price')"
                                                class="text-right" sortable v-slot="data">
                                                {{ data.row.total_amount == 0 ?
                                                    formatNumber(data.row.assignment_total_units
                                                        * data.row.assigment_unit_cost) :
                                                    formatNumber(data.row.total_amount)
                                                }}
                                            </b-table-column>
                                            <b-table-column field="total_amount" :label="$t('total_planned')"
                                                class="text-right" sortable v-slot="data">
                                                {{
                                                    formatNumber(data.row.total_importe)
                                                }}
                                            </b-table-column>
                                            <b-table-column :label="$t('actions')" field="actions" centered width="150"
                                                v-slot="data">
                                                <b-tooltip :label="$t('add') + ' ' + $t('breakdown')"
                                                    position="is-left">
                                                    <div @click="addButtonBreakdown(data.row)"
                                                        class="cursor-pointer flex">
                                                        <b-icon pack="fas" icon="plus-circle" />
                                                    </div>
                                                </b-tooltip>
                                            </b-table-column>
                                        </template>

                                        <!-- Si hay cost_budgets en las asignaciones -->
                                        <template #detail="data">
                                            <h3 class="font-bold">{{ $t('breakdown') }}s</h3>
                                            <b-table v-if="data.row.cost_budgets.length > 0"
                                                :data="sortTableData(data.row.cost_budgets)" :loading="isLoadingTable"
                                                backend-sorting class="w-full" striped bordered icon-pack="fas"
                                                scrollable>
                                                <b-table-column field="code" :label="$t('code')" sortable v-slot="data">
                                                    {{ data.row.code }}
                                                </b-table-column>
                                                <b-table-column field="name" :label="$t('breakdown_name')" sortable
                                                    v-slot="data">
                                                    {{ data.row.name }}
                                                </b-table-column>
                                                <b-table-column field="unit" :label="$t('UD')" sortable v-slot="data">
                                                    {{ data.row.unit === null ? '-' : data.row.unit.name }}
                                                </b-table-column>
                                                <b-table-column field="provider" :label="$t('provider')" sortable
                                                    v-slot="data">
                                                    {{ data.row.provider ? data.row.provider.name : 'N/A' }}
                                                </b-table-column>
                                                <b-table-column field="unit_price" :label="$t('unit_price')" sortable
                                                    v-slot="data" class="text-center">
                                                    {{ formatNumber(data.row.unit_price) }}
                                                </b-table-column>
                                                <b-table-column field="quantity" :label="$t('quantity')" sortable
                                                    v-slot="data" class="text-center">
                                                    {{ formatNumber(data.row.quantity) }}
                                                </b-table-column>
                                                <b-table-column field="coefficient" :label="$t('coefficient')" sortable
                                                    v-slot="data" class="text-center">
                                                    {{ formatNumber(data.row.coefficient) }}
                                                </b-table-column>
                                                <b-table-column field="total_amount" :label="$t('total_planned')"
                                                    sortable v-slot="data" class="text-center">
                                                    {{ formatNumber(data.row.total_amount) }}
                                                </b-table-column>
                                                <b-table-column :label="$t('actions')" field="actions" centered
                                                    width="150" v-slot="data">
                                                    <!-- <b-tooltip :label="$t('edit_breakdown')" position="is-left">
                                                        <div @click="editButtonClicked(data.row)"
                                                            class="cursor-pointer flex text-blue">
                                                            <b-icon pack="fas" icon="pencil-alt" />
                                                        </div>
                                                    </b-tooltip> -->
                                                    <b-tooltip :label="$t('delete') + ' ' + $t('breakdown')"
                                                        position="is-left" type="is-danger">
                                                        <div @click="removeResources(data.row)"
                                                            class="cursor-pointer flex text-red">
                                                            <b-icon pack="fas" icon="trash-alt" />
                                                        </div>
                                                    </b-tooltip>
                                                </b-table-column>
                                            </b-table>
                                            <div v-else class="text-center py-4">
                                                {{ $t('no_results') }}
                                            </div>
                                        </template>
                                    </b-table>
                                </div>
                            </template>
                        </b-table>
                    </div>

                    <!-- Si no hay subcapítulos, mostrar mediciones de tipo Resource directamente -->
                    <div
                        v-if="props.row.children.some(child => child.type === 'App\\Models\\AssignmentType\\Resource')">
                        <div class="w-full h-full flex items-center justify-between mb-3">
                            <h3 class="font-bold">{{ $t('assignments') }}</h3>
                        </div>
                        <!-- Mostrar las asignaciones de subcapítulos -->
                        <b-table
                            :data="sortTableData(props.row.children.filter(child => child.type === 'App\\Models\\AssignmentType\\Resource'))"
                            :loading="isLoadingTable" class="w-full" striped bordered icon-pack="fas" detailed
                            detail-key="assignment_id" :show-detail-icon="showDetailIcon" scrollable>
                            <!-- Renderización de las asignaciones -->
                            <template>
                                <b-table-column field="assignment_code" :label="$t('assignment_code')" sortable
                                    v-slot="data">
                                    {{ data.row.assignment_code }}
                                </b-table-column>
                                <b-table-column field="assignment_name" :label="$t('name')" sortable v-slot="data">
                                    <div class="text-left">
                                        {{ data.row.assignment_name }}
                                    </div>
                                </b-table-column>
                                <b-table-column field="unit" :label="$t('unit')" sortable v-slot="data">
                                    {{ data.row.unit.name }}
                                </b-table-column>
                                <b-table-column field="total_units" :label="$t('assignment')" class="!text-right"
                                    sortable v-slot="data">
                                    {{ formatNumber(data.row.assignment_total_units) }}
                                </b-table-column>
                                <b-table-column field="unit_price" :label="$t('unit_price')" class="text-right" sortable
                                    v-slot="data">
                                    {{
                                        data.row.total_amount == 0 ?
                                            formatNumber(data.row.importe_vt) : data.row.total_amount
                                    }}
                                </b-table-column>
                                <b-table-column field="total" :label="$t('total_planned')" class="text-right" sortable
                                    v-slot="data">
                                    {{
                                        formatNumber(data.row.importe_cpt)
                                    }}
                                </b-table-column>
                                <b-table-column :label="$t('actions')" field="actions" centered width="150"
                                    v-slot="data">
                                    <b-tooltip :label="$t('add') + ' ' + $t('breakdown')" position="is-left">
                                        <div @click="addButtonBreakdown(data.row)" class="cursor-pointer flex">
                                            <b-icon pack="fas" icon="plus-circle" />
                                        </div>
                                    </b-tooltip>
                                </b-table-column>
                            </template>

                            <!-- Si hay cost_budgets en las asignaciones -->
                            <template #detail="data">
                                <h3 class="font-bold">{{ $t('breakdown') }}s</h3>
                                <b-table v-if="data.row.cost_budgets.length > 0" :data="data.row.cost_budgets"
                                    :loading="isLoadingTable" backend-sorting class="w-full" striped bordered
                                    icon-pack="fas" scrollable>
                                    <b-table-column field="code" :label="$t('code')" sortable v-slot="data">
                                        {{ data.row.code }}
                                    </b-table-column>
                                    <b-table-column field="name" :label="$t('breakdown_name')" sortable v-slot="data">
                                        {{ data.row.name }}
                                    </b-table-column>
                                    <b-table-column field="unit" :label="$t('UD')" sortable v-slot="data">
                                        {{ data.row.unit == null ? '-' : data.row.unit.name }}
                                    </b-table-column>
                                    <b-table-column field="provider" :label="$t('provider')" sortable v-slot="data">
                                        {{ data.row.provider ? data.row.provider.name : 'N/A' }}
                                    </b-table-column>
                                    <b-table-column field="unit_price" :label="$t('unit_price')" sortable v-slot="data"
                                        class="text-center">
                                        {{ formatNumber(data.row.unit_price) }}
                                    </b-table-column>
                                    <b-table-column field="quantity" :label="$t('quantity')" sortable v-slot="data"
                                        class="text-center">
                                        {{ formatNumber(data.row.quantity) }}
                                    </b-table-column>
                                    <b-table-column field="coefficient" :label="$t('coefficient')" sortable
                                        v-slot="data" class="text-center">
                                        {{ formatNumber(data.row.coefficient) }}
                                    </b-table-column>
                                    <b-table-column field="total_amount" :label="$t('total_planned')" sortable
                                        v-slot="data" class="text-center">
                                        {{ formatNumber(data.row.total_amount) }}
                                    </b-table-column>
                                    <b-table-column :label="$t('actions')" field="actions" centered width="150"
                                        v-slot="data">
                                        <b-tooltip :label="$t('edit_breakdown')" position="is-left">
                                            <!-- <div @click="editButtonClicked(data.row)"
                                                class="cursor-pointer flex text-blue">
                                                <b-icon pack="fas" icon="pencil-alt" />
                                            </div> -->
                                        </b-tooltip>
                                        <b-tooltip :label="$t('delete') + ' ' + $t('breakdown')" position="is-left"
                                            type="is-danger">
                                            <div @click="removeResources(data.row)"
                                                class="cursor-pointer flex text-red">
                                                <b-icon pack="fas" icon="trash-alt" />
                                            </div>
                                        </b-tooltip>
                                    </b-table-column>
                                </b-table>
                                <div v-else class="text-center py-4">
                                    {{ $t('no_results') }}
                                </div>
                            </template>
                        </b-table>
                    </div>
                </template>

                <template #empty>
                    <div class="has-text-centered">{{ $t('no_results') }}</div>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: {
        projectId: { type: Number, require: true },
    },
    computed: {
        filters() {
            let filters = {
                'order_field': this.orderField,
                'order_dir': this.orderDir,
                'perPage': this.perPage,
                'page': this.page,
                'project_id': this.projectId
            }
            return filters
        },
        sortedBudgets() {
            // Aplicar la función de ordenamiento a los datos de la tabla principal
            return this.sortTableData(this.budgets);
        }
    },
    data: function () {
        return {
            isLoadingTable: true,
            budgets: [],
            perPage: 25,
            orderField: 'unit_price',
            orderDir: 'asc',
            page: 1,
            last: 1,
            total: 0,
            applyFilters: {},
            showDetailIcon: true,
        }
    },
    created() {
        this.getAssignmentBreakdown()
    },
    methods: {
        sortTableData(data) {

            if (!data || !data.length) return [];
            return [...data].sort((a, b) => {
                const fieldA = a[this.orderField];
                const fieldB = b[this.orderField];

                // Si no hay valores en el campo, prioriza los no vacíos
                if (fieldA == null) return 1;
                if (fieldB == null) return -1;

                let modifier = this.orderDir === 'asc' ? 1 : -1;
                return fieldA > fieldB ? modifier : fieldA < fieldB ? -modifier : 0;
            });
        },
        async getAssignmentBreakdown(filters = null) {
            this.isLoadingTable = true
            this.budgets = []
            const params = filters ? filters : this.filters;
            const { data } = await axios.get(`/api/v2/assignment-breakdown`, { params });
            if (data && data.success) {
                this.budgets = data.assignments;
                this.last = data.pagination.lastPage;
                this.total = data.pagination.total;
                this.isLoadingTable = false
            }
        },

        onSort(field, order) {
            this.companyId = this.company.id
            this.orderField = field
            this.orderDir = order
            this.getBudgets();
        },
        onFrontSort(field, order) {
            this.orderField = field;
            this.orderDir = order === 'asc' ? 'asc' : 'desc';
        },

        onPageChange(page) {
            this.page = page
            this.getBudgets();
        },

        reload(arg = {}) {
            let filters = { ...arg, ...this.filters }
            this.getAssignmentBreakdown(filters);
        },

        async editButtonClicked(entity) {
            this.$emit('epc-edit', entity)
        },

        async removeResources(assignmentBudget) {
            try {
                this.$dialog.confirm({
                    title: this.$t('delete_breakdown'),
                    message: this.$t('delete_activity_confirm_text', [assignmentBudget.name]),
                    confirmText: this.$t('delete') + ' ' + this.$t('breakdown'),
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => {
                        this.processingData = true;
                        await axios.delete(`/api/v2/assignment-breakdown/${assignmentBudget.id}`);
                        this.$buefy.toast.open({
                            message: this.$t("removed_successfully"),
                            type: 'is-success'
                        });
                        this.$emit('reload-list');
                    },
                    onCancel: () => {
                        this.processingData = false;
                    }
                });
            } catch (error) {
                this.$buefy.toast.open({
                    message: this.$t("remove_error") + (error.response.data.message),
                    type: 'is-danger'
                });
            } finally {
                this.processingData = false;
            }
        },

        archiveClass(value) {
            return value ? "cursor-pointer flex text-yellow-dark" : "cursor-pointer flex text-blue"
        },

        handleSelect(newRow) {
            this.$emit('update:template', newRow);
        },

        clearSelected() {
            this.rowSelected = null
        },
        formatNumber(value) {
            const number = parseFloat(value);
            if (isNaN(number)) return '0,00';
            let [integerPart, decimalPart] = number.toFixed(2).split('.');
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return `${integerPart},${decimalPart}`;
        },
        addButtonBreakdown(assignment) {
            this.$emit('add-by-assignment', assignment);
        },

    },
}
</script>
<style scoped>
.custom-class {
    background-color: red !important;
}
</style>