var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:'konva_popover',staticClass:"absolute z-10 max-w-sm rounded overflow-hidden shadow-lg",style:({
    top: _vm.position.y + 2 + 'px',
    left: _vm.position.x + 2 + 'px',
    borderRadius: '3px',
    boxShadow: '0 0 5px grey',
    backgroundColor: '#fff',
    minWidth: '210px'
  })},[_c('div',{staticClass:"card-header font-semibold px-3 py-1 mb-0 border-b border-solid border-slate-100 uppercase"},[_vm._v("\n      "+_vm._s(_vm.$t(_vm.type))+" : "+_vm._s(_vm.dataTooltip.attrs ? _vm.dataTooltip.attrs.name : '')+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"card-body px-3 py-2"},[_c('label',{staticClass:"label",attrs:{"for":"weight"}},[_vm._v("\n      "+_vm._s(_vm.$t('status'))+"\n    ")]),_vm._v(" "),_c('b-select',{attrs:{"placeholder":"Elija estado","icon":"traffic-light","icon-pack":"fas","expanded":""},on:{"input":_vm.handleChangeStatus},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}},[_c('option',{key:"status_null",domProps:{"value":null}},[_vm._v("Sin estado")]),_vm._v(" "),_vm._l((_vm.statuses),function(option){return _c('option',{key:'status_' + option.id,domProps:{"value":option}},[_vm._v("\n        "+_vm._s(option.name)+"\n      ")])})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }