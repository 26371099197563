<template>
    <div>
        <b-collapse class="card filters-collapse" :open.sync="showFilters">
            <div slot="trigger" class="card-header filters-trigger">
                <p class="card-header-title">
                    {{ $t('filters') }}
                </p>
                <a class="card-header-icon">
                    <b-icon pack="fa" v-show="!showFilters" icon="plus-circle" />
                    <b-icon pack="fa" v-show="showFilters" icon="minus-circle" />
                </a>
            </div>
            <div class="panel" style="border-width: 0">
                <div class="flex items-center mb-4">
                    <div class="w-1/4 mr-4">
                        <b-field :label="$t('search_by_invoice')">
                            <b-input v-model="filterInvoiceCode" :placeholder="$t('code')" type="search" icon="search"
                                icon-pack="fas" @input="checkInputTextFilter">
                            </b-input>
                        </b-field>
                    </div>
                    <div class="w-1/2 mr-4">
                        <b-field :label="$t('search_by_provider')" class="w-full lg:w-1/2 mr-4">
                            <b-select v-model="selectedProvider" :placeholder="$t('select_provider')" icon="store"
                                icon-pack="fas" expanded>
                                <option v-for="(provider) in providers" :value="provider.id" :key="provider.id">
                                    {{ provider.name }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                </div>

                <button class="button is-primary btn-blue" @click="cleanFiltersClicked()">{{ $t('clean_filters')
                    }}</button>
            </div>
        </b-collapse>
    </div>
</template>

<script>
export default {
    name: 'billings-filters',
    props: {
        providers: { type: Array, required: true }
    },
    data() {
        return {
            showFilters: false,
            filterInvoiceCode: null,
            filterProviderId: null,
            ajaxTimerProducts: null,
            selectedProvider: null,
            isLoadingProviders: false,
        };
    },

    methods: {
        generateFilters() {
            const params = {};
            if (this.filterInvoiceCode) {
                params.filter_invoice_code = this.filterInvoiceCode;
            }
            if (this.filterProviderId) {
                params.filter_provider_id = this.filterProviderId;
            }
            return params;
        },

        applyFilters() {
            const args = this.generateFilters();
            this.$emit('filters-updated', args);
        },

        cleanFiltersClicked() {
            this.filterInvoiceCode = null;
            this.filterProviderId = null;
            this.selectedProvider = null;
            this.applyFilters();
        },

        checkInputTextFilter() {
            clearTimeout(this.ajaxTimerProducts);
            this.ajaxTimerProducts = setTimeout(() => {
                this.applyFilters();
            }, 500);
        }
    },
    watch: {
        selectedProvider(newProvider) {
            this.filterProviderId = newProvider;
            this.applyFilters();
        },
        filterInvoiceCode() {
            this.checkInputTextFilter();
        }
    }
};
</script>

<style>
.filters-collapse {
    margin-top: 15px;
    margin-bottom: 15px;
}

.filters-trigger:hover {
    background-color: #f8fafc;
}
</style>
