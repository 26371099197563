<template>
  <div>
    <project-locations-manual
            :project-id="project.id"
            :is-admin-locations="false"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ProjectLocationsManual from '../../components/project-locations-manual'

  export default {
    middleware: 'auth',
    title: 'locations_page_title',
    subtitle: 'locations_page_subtitle',
    menuOption: '2-13',

    components: {
      ProjectLocationsManual
    },

    computed: {
      ...mapGetters({
        project: 'app/project',
      }),
    },

    data: () => ({

    }),

    methods: {

    }
  };
</script>
