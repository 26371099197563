<template>
    <b-modal :active.sync="showModal" :width="900" scroll="keep" :can-cancel="false">
        <div class="w-auto border border-blue-light rounded">
            <header class="w-full bg-grey-lightest px-4 py-2 text-xl font-thin uppercase text-blue-light">
                {{ $t('welcome_epc_tracker') }}
            </header>
            <section class="modal-card-body bg-grey-lightest text-center" style="min-height: 400px;">
                <youtube :video-id="videoId" player-width="640" :player-vars="{autoplay: 0}"></youtube>
            </section>
            <footer class="border-t p-4 flex items-center justify-end bg-grey-lighter">
                <div class="mr-2">
                    <b-checkbox v-model="displayNextOnboarding">
                        {{ $t('display_next_onboarding') }}
                    </b-checkbox>
                </div>
                <div>
                    <b-button type="is-link" @click="close">
                        {{ $t('continue') }}
                    </b-button>
                </div>
            </footer>
        </div>
    </b-modal>
</template>

<script>
  import Vue from 'vue'
  import axios from "axios";
  import { mapGetters } from 'vuex'
  import VueYouTubeEmbed from 'vue-youtube-embed'

  Vue.use(VueYouTubeEmbed)
  // Vue.use(VueYouTubeEmbed, { global: false })

  export default {
    name: "onboarding-video",

    props: {
      show: { type: Boolean, default: false },
    },

    data: () => ({
      videoId: '8AWPjDJ08Qw',
      displayNextOnboarding: true,
      isModalActive: true,
    }),

    computed: {
      ...mapGetters({
        user: 'auth/user',
      }),
      showModal() {
        return this.isModalActive && this.show
      }
    },

    methods: {
      async close() {
        // El usuario no quiere ver más el onboarding
        if (! this.displayNextOnboarding) {
          await axios.post('/api/v2/user/' + this.user.id + '/hide_onboarding');
        }
        this.isModalActive = false;
      },
    },

  }
</script>

<style>
    /* iframe {
        height: 360px!important;
    } */
</style>