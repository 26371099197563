<template>
  <div>
    <div class="google-map" ref="pmap"></div>
  </div>
</template>
<script>
export default {
  name: "photo-map",
  props: {
    datamaps: { type: Object, default: null },
    name: { type: String, default: "location" }
  },

  data: function() {
    return {
      map: null,
      markers: []
    };
  },
  mounted: function() {
    // Instanciamos
    var self = this;

    // Cargamos el mapa
    self.load();

    // Seteamos el marcador
    self.setMarker(self.datamaps);
  },
  methods: {
    load() {
      const element = this.$refs.pmap;
      const options = {
        disableDefaultUI: true,
        mapTypeId: "satellite",
        zoom: 19
      };
      this.map = new window.google.maps.Map(element, options);
    },

    setMarker(item) {
      // Marcador
      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(
          parseFloat(item.latitude),
          parseFloat(item.longitude)
        ),
        map: this.map,
        draggable: false,
        id: item.id
      });

      // Añadimos el marcador al map
      marker.setMap(this.map);

      // Centramos el mapa
      this.map.setCenter({
        lat: parseFloat(item.latitude),
        lng: parseFloat(item.longitude)
      });
    }
  }
};
</script>


<style scoped>
.google-map {
  width: 100%;
  height: 300px;
  margin: 0 auto;
  background: gray;
}
</style>

