<template>
  <b-tooltip :label="textLabel"
    :active="activeTooltip"
    :position="positionTooltip">
    <button type="button"
      class="h-9 w-9 rounded-full bg-gray-200 text-black"
      @click="handleClick">
      <b-icon v-if="icon" :icon="icon" pack="fas"></b-icon>
      <span class="mx-1 hidden xl:inline-">{{ $t(textLabel) }}</span>
      <!-- <span class="absolute right-0 top-0 -mt-2 -mr-1 text-xs bg-red-500 text-white font-medium px-2 shadow-lg rounded-full">3</span> -->
    </button>
  </b-tooltip>
</template>

<script>

  export default {
    name: 'quick-button-menu',

    computed: {

    },

    components: {},

    props: {
      textLabel: { type: String, required: true },
      icon: { type: String, default: null },
      activeTooltip: { type: Boolean, default: false },
      positionTooltip: { type: String, default: 'is-top' },
      handleOnClick: { type: Function, required: true }
    },

    data: () => ({

    }),

    watch: {},

    mounted() {
    },

    methods: {
      handleClick() {
        this.handleOnClick()
      }
    },

  };
</script>
