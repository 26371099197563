<template>
  <div>
    <div class="w-full h-12">
      <!-- can_validate el usuario tiene el rol de validate_users -->
      <!-- <template v-if="project.can_admin || project.can_validate"> -->
      <!-- De momento dejamos que todos los usuarios puedan ver el botón de crear flujos -->
  <div class="flex w-full items-center justify-end text-white">
    <div class="p-2 text-gray-900 bg-white font-medium capitalize text-right flex space-x-2">
      <!-- Asignar flujo a proyecto -->
      <add-validation-flow :openModalWith="'button-icon'"/>

      <!-- Reportar datos de Excel filtrados -->
      <v-button-icon-round
        icon="file-excel"
        pack="fa"
        tooltip="export"
        tooltip-position="bottom"
        @click="exportData"
        class="mr-1"
      />
    </div>
  </div>
</div>


    <!-- Filtros -->
    <validation-filters @filters-updated="filtersUpdated"
      :templatesList="templatesList"
      :currentGroupsList="currentGroupsList"
      :currentTasksList="currentTasksList"
      ref="validationFiltersRef"/>


    <div class="mt-2 text-sm panel">

      <!-- <b-field grouped group-multiline>
        <div v-for="(column, index) in columns"
             :key="index"
             class="control">
          <b-checkbox v-model="column.visible" class="text-xs">
            <span>{{ $t(column.title) }}</span>
          </b-checkbox>
        </div>
      </b-field> -->
      <div class="mb-3">
        <multiselect v-model="columnsSelected"
          :options="_columns"
          :multiple="true"
          :deselect-label="$t('multiselect_deselect_label')"
          :select-label="$t('multiselect_select_label')"
          :selected-label="$t('multiselect_selected_label')"
          :show-labels="true"
          :hide-selected="false"
          :limit-text="count => `y ${count} más`"
          label="title"
          track-by="title"
          :close-on-select="true" :clear-on-select="false" :preserve-search="true"
          :max-height="150"
          :allow-empty="true"
          :limit="limitShowColumnsSelect"
          @selected="_HandleSelectItem"
          @remove="_HandleRemoveItem">
        </multiselect>
      </div>

      <template>
        <!-- Contador de resultados -->
        <div class="flex w-full flex-col mb-3">
          <div class="w-full flex flex-row-reverse">
            <div class="">
              <b-select v-model="perPage" @input="filtersUpdated" size="is-small">
                <option value="10">{{ $t('per_page', {amount: 10}) }}</option>
                <option value="25">{{ $t('per_page', {amount: 25}) }}</option>
                <option value="50">{{ $t('per_page', {amount: 50}) }}</option>
              </b-select>
            </div>
          </div>
          <div v-if="total" class="w-full text-right">
            <b>{{ $t('count_results') }}: {{ total }}</b>
          </div>
        </div>

        <b-table
          :data="list"
          :loading="isLoadingTable"
          icon-pack="fas"

          paginated
          backend-pagination
          :per-page="perPage"
          :current-page.sync="currentPage"
          :total="total"
          @page-change="onPageChange"

          backend-sorting
          :default-sort="orderField"
          :default-sort-direction="orderDir"
          @sort="onSort"

          class="text-xs border-blue-lighter"
          bordered
          narrowed
          striped
          :row-class="getRowClass"

          :sticky-header="stickyHeader"
          :mobile-cards="false"
        >

          <template slot="empty">
            <div v-if="! isLoadingTable" class="m-4 text-lg text-dark-grey">
              <span>{{ $t('no_results') }}</span>
            </div>
          </template>

          <template>

            <b-table-column field="entity_type" :visible="columns['entity_type'].visible" :label="$t('type')"
                            sortable cell-class="vertical-center" v-slot="validations">
              <div class="cursor-pointer" @click="onRowClicked(validations.row.id)">
                {{ flowType(validations.row.validable_type) }}
              </div>
            </b-table-column>

            <b-table-column field="validable_name" :visible="columns['validable_name'].visible" :label="$t('name')"
                            sortable cell-class="vertical-center" v-slot="validations">
              <div class="cursor-pointer" @click="onRowClicked(validations.row.id)">
                <span style="font-size: 0.65rem;" class="font-bold" v-if="validations.row.validable.parent"><!-- actividad padre (en DSOs) -->
                  ({{ validations.row.validable.parent.name }})<br>
                </span>
                {{ validations.row.validable.name }}
                <span v-if="validations.row.description"
                 class="font-bold"
                >
                  <br>{{validations.row.description}}
                </span>
                <span class="text-blue font-bold" style="font-size: 9px" v-if="validations.row.technical_specification">
                  <br>ET: {{validations.row.technical_specification.name}}
                </span>
              </div>
            </b-table-column>

            <b-table-column field="code" :visible="columns['code'].visible" :label="$t('code')"
                            sortable cell-class="vertical-center" v-slot="validations">
              <div>
                {{ validations.row.template.code }}
              </div>
            </b-table-column>

            <b-table-column field="name" :visible="columns['name'].visible" :label="$t('validation_flow')" sortable
                            cell-class="vertical-center" v-slot="validations">
                <div class="cursor-pointer" @click="onRowClicked(validations.row.id)">
                    <p>{{ validations.row.template.name }}</p>
                    <p class="text-blue font-bold" style="font-size: 10px;">
                      {{ currentTask(validations.row.current_task) }}
                    </p>
                </div>
            </b-table-column>

            <b-table-column field="status" :label="$t('status')" cell-class="w-32 vertical-center"
                            :visible="columns['status'].visible" sortable centered v-slot="validations">
              <validation-status-label
                :status="validations.row.status"
                style="vertical-align: middle"/>
            </b-table-column>

            <b-table-column field="created_at" :visible="columns['created_at'].visible" :label="$t('created_at')" sortable
                            cell-class="vertical-center" v-slot="validations">
                {{validations.row.created}}
            </b-table-column>

            <b-table-column field="current_groups" :visible="columns['current_groups'].visible" :label="$t('current_groups')" sortable
                            cell-class="vertical-center" v-slot="validations">
                <span v-if="validations.row.status != 2 && validations.row.status != 6" class="flex flex-row items-center justify-start w-auto h-full">
                  <span v-for="group in validations.row.current_groups" :key="'group_'+group.id" class="w-4 h-4 p-3 mr-1 text-xs text-center text-white rounded-full btn btn-blue">
                      <b-tooltip multilined position="is-top">
                          {{ group.code }}
                          <template v-slot:content>
                            <b>{{group.name}}</b>
                            <br>
                            ({{ validations.row.current_task_manager_group.code }})
                            {{ currentTask(validations.row.current_task) }}
                        </template>
                      </b-tooltip>
                  </span>
                </span>
                <span v-else></span>
            </b-table-column>

            <b-table-column field="documents_count" :visible="columns['documents_count'].visible" cell-class="w-8 vertical-center" centered sortable>
              <template v-slot:header="">
                <i class="fas fa-file-alt"></i>
              </template>
              <template v-slot="validations">
                {{ validations.row.documents_count }}
              </template>
            </b-table-column>

            <b-table-column field="actions" :label="$t('actions')" :visible="columns['actions'].visible"
                    cell-class="w-12 vertical-center text-right" sortable centered>
              <template v-slot="validations">

                <b-tooltip :label="$t('download_documents')" position="is-left" v-if="validations.row.documents_count">
                  <div @click="downloadDocuments(validations.row.id)" class="">
                    <b-icon icon="download" pack="fas" size="is-small" class=""/>
                  </div>
                </b-tooltip>

                <b-tooltip v-if="project && project.can_admin" :label="$t('delete')" position="is-left" type="is-danger">
                  <div @click="deleteValidationFlow(validations.row.id, validations.row.template.name)" class="">
                    <b-icon icon="trash-alt" pack="fas" size="is-small" class="text-red"/>
                  </div>
                </b-tooltip>

              </template>
            </b-table-column>

            <b-table-column field="id" numeric cell-class="is-hidden" :visible="false" v-slot="validations">
              {{ validations.row.id }}
            </b-table-column>

          </template>

        </b-table>
      </template>
    </div>

  </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import {mapGetters} from 'vuex'
import Form from 'vform'
import validationStatusLabel from '·/components/validations/validation-status-label'
import moment from 'moment'
import Buefy from 'buefy'
import BIcon from "buefy/src/components/icon/Icon";
import EventBus from '~/plugins/bus'
import addValidationFlow from '·/components/add-validation-flow';
import validationFilters from '·/components/validations/validation-filters';
import Multiselect from 'vue-multiselect'

Vue.use(moment);
Vue.use(Buefy);

export default {
  middleware: 'auth',
  title: 'validations_page_title',
  subtitle: 'validations_page_subtitle',
  menuOption: '2-4',

  components: {
    BIcon,
    validationStatusLabel,
    addValidationFlow,
    validationFilters,
    Multiselect
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      project: 'app/project',
      company: 'app/company',
      mobile: 'device/mobile'
    }),

    _columns: function() {
      // return Object.values(this.columns)
      // Object.keys(myObject).map(key => myObject[key]);
      return Object.entries(this.columns).map(([key, value]) => ({ key, title: this.$t(value.title), visible: value.visible }));
    },
    stickyHeader() { return this.mobile.on_mobile}, // para fiar el header de la tabla cuando se esta mostrando en movil
    limitShowColumnsSelect() { // cantidad de columnas visibles seleccionadas a mostrar en el multiselect por defecto  8
      return this.mobile.on_mobile ? 3 : 8
    }
  },

  data: () => ({
    form: new Form({
      creator: 'Todos',
      fecha: '1 Enero 2018 - 31 Diciembre 2018',
    }),
    // paginationRows: 10,
    isLoadingTable: true,
    page: 1,
    last: 1,
    perPage: 25,
    currentPage: 1,
    total: 0,
    orderField: "validable_name",
    orderDir: "asc",
    list: [],
    templatesList: [], // lista de plantillas asignadas para usar en el filtro
    currentGroupsList: [], // lista de grupos que les toca validar, para el filtro
    currentTasksList: [], // lista de tareas actuales, de la validacion seleccionada
    columns: {
      'entity_type': {title: 'type', visible: true},
      'validable_name': {title: 'name', visible: true},
      'code': {title: 'code', visible: true},
      'name': {title: 'validation_flow', visible: true},
      'status': {title: 'status', visible: true},
      'created_at': {title: 'created_at', visible: true},
      'current_groups': {title: 'current_groups', visible: true},
      'documents_count': {title: 'documents_count', visible: true},
      'actions': {title: 'actions', visible: true},
    },
    columnsSelected: [],
  }),

  mounted() {
    this.columnsSelected = this._columns

    // recupera de la localstore la pagina que estamos (util al volver de una validacion al listado)
    this.page = this.$store.getters["searchFilters/validations"].page || 1
    this.last = this.$store.getters["searchFilters/validations"].last || 1

    this.applyFilters(this.page, true);
    let self = this;

    // Si recibimos el evento refresh, refrescamos el componente
    EventBus.$on('refreshValidations', function (e) {
      self.isLoading = true;
      self.applyFilters(self.page, true);
    });

  },

  created() {
  },

  beforeDestroy(){
  	EventBus.$off("refreshValidations")
  },

  methods: {

    async getValidationsList(args = [], isFirstTime = false) {

      const {data} = await axios.get('/api/v2/validations', {params: args});

      if (data) {
        // Last and total
        this.last = data.lastPage;
        this.total = data.total;
        // guarda en la localstore la ultima pagina
        this.$store.dispatch('searchFilters/setValidationsFilters', { last: this.last });
        this.currentTasksList = data.current_tasks ? data.current_tasks : []
        if (data.validations) {
          this.list = data.validations;
          // solo la primera vez, cargamos las plantillas que estan asignadas a un flujo (para el filtro)
          if (isFirstTime) {
            this.templatesList = this.getTemplatesList(data.templates)
            this.currentGroupsList = this.getCurrentGroups(data.currentGroupsList)
          }

        }
      } else {
        this.$notify.error(data.msg || 'error_loading_validations')
      }

      this.isLoadingTable = false
    },

    filtersUpdated() {
      this.isLoadingTable = true;
      this.applyFilters(1);
    },


    applyFilters(page, isFirstTime = false) {

      let args = {}

      // desde evento 'refreshValidations' a veces no tiene definido las refs, evito error asi aunque no filtre
      if (this.$refs.validationFiltersRef !== undefined) {
        args = this.$refs.validationFiltersRef.generateFilters();
      }

      args.p = this.project.id
      args.order_field = this.orderField;
      args.order_dir = this.orderDir;
      args.per_page = this.perPage;

      args.page = (page <= this.last) ? page : 1;
      // actualiza en localstore la pagina actual
      this.$store.dispatch('searchFilters/setValidationsFilters', { page: args.page });
      this.currentPage = args.page;

      if (isFirstTime) {
        args.template_list = 1
        args.current_groups_list = 1
      }

      this.getValidationsList(args, isFirstTime)
    },

    async exportData() {
      // usaremos la mis ruta que se usar para filtrar, pero con un parametro mas para exportar
      let args = {}
      if (this.$refs.validationFiltersRef !== undefined) {
        args = this.$refs.validationFiltersRef.generateFilters();
      }
      args.p = this.project.id
      args.export = 1
      args.order_field = this.orderField;
      args.order_dir = this.orderDir;
      args.per_page = this.perPage;

      const {data} = await axios.get('/api/v2/validations', {params: args});

      if (data && data.success) {
        // Download
        window.open(data.url);
        
      }
    },


    fetchData() {
      if (this.page <= this.last) {
        this.isLoadingTable = true;
        this.applyFilters(this.page);
      }
    },

    onPageChange(page) {
      // guarda en la localstore la pagina que estamos
      this.$store.dispatch('searchFilters/setValidationsFilters', { page: page });
      this.page = page;
      this.fetchData()
    },

    onSort(field, order) {
      this.orderField = field;
      this.orderDir = order;
      this.fetchData()
    },

    getRowClass(row) {
      let cssClass = '';
      // cssClass = 'is-hidden'
      // cssClass = cssClass + ' sub-notifications'

      return cssClass
    },

    currentTask(task) {
      return task ? task.name : '-'
    },

    flowType(type) {
      return type.includes('Project') ? 'Lote' :
           type.includes('Activity') ? 'DSO' :
           type.includes('Issue') ? 'Issue' :
           'DSO';
    },


    // Go to validationflow home
    onRowClicked(validationId) {
        this.$router.push({name: 'validation.home', params: {validation_id: validationId}})
    },

    deleteValidationFlow(validationId, validationName) {
      this.$dialog.confirm({
        title: this.$t('delete'),
        message: this.$t('delete_generic', [validationName]),
        confirmText: this.$t('delete'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          let url = '/api/v2/validation/' + validationId + '/delete'
          const {data} = await axios.post(url)
          if (data && data.success) {
            this.$notify.success('success_deleting_generic')
            this.applyFilters(this.page)
          } else {
            this.$notify.error(data.msg)
          }
        }
      })
    },

    async downloadDocuments(validationId) {

      // Generando zip
      this.$notify.success('generating');
      let self = this;

      await axios.get("/api/v2/validation/" + validationId + "/downloadDocuments?company_alias=" + this.company.alias,
        {})
        .then(function(response) {

          if (response.data) {
            // Download
            window.open(response.data.data);

            // Reporte generado
            self.$notify.success('file_ready_to_download')
          }
        })
        .catch(function(error) {
          console.log(error);
          self.$notify.error('error_retrieving_data')
        })
        .finally(function () {
        });
    },

    // tomamos todas las plantillas que estan asignadas (para pasar al filtro)
    getTemplatesList(list) {

      let templatesList = []
      let processedTemplates = [] // evitar duplicados

      list.forEach(function (flow) {
        if (! processedTemplates.includes(flow.id)) {
          processedTemplates.push(flow.id)
          templatesList.push({ id: flow.id, name: flow.name })
        }
      })

      return templatesList

    },

    // tomamos todos los grupos actuales de las tareas (para pasar al filtro)
    getCurrentGroups(list) {

      let groupsList = []
      let processedGroups = [] // evitar duplicados

      list.forEach(function (group) {

            if (! processedGroups.includes(group.code)) {
              processedGroups.push(group.code)
              groupsList.push({ code: group.code, name: group.name })
            }

      })

      return groupsList
    },



    _HandleSelectItem(selectedOption, id) {
      this.columns[selectedOption.key].visible = true
    },

    _HandleRemoveItem(removedOption, id) {
      if (this.columns[removedOption.key])
        this.columns[removedOption.key].visible = false
    }

  },

};
</script>

<style lang="scss" scoped>
.panel::v-deep .b-table .table-wrapper.has-sticky-header tr:first-child th {
  background: hsl(220, 43%, 53%);
}

.vertical-center {
  vertical-align: inherit !important;
}

.b-table .table-wrapper.has-sticky-header tr:first-child th {
  // background-color: $primary !important;
  min-height: 3rem;
}

</style>


