<template>
    <div>
      <!-- Filtros -->
      <b-collapse class="card filters-collapse" :open.sync="showFilters" v-if="!isLightVersion">
        <div slot="trigger" class="card-header filters-trigger">
          <p class="card-header-title">
            {{ $t('filters') }}
          </p>
          <a class="card-header-icon">
            <b-icon pack="fa" v-show="! showFilters" icon="plus-circle"></b-icon>
            <b-icon pack="fa" v-show="showFilters" icon="minus-circle"></b-icon>
          </a>
        </div>

        <div class="panel" style="border-width: 0px">
          <div class="flex items-center mb-4">

            <div class="w-1/4 mr-4">
              <b-field :label="$t('search_by_name')">
                <b-input
                  v-model="filterName"
                  :placeholder="$t('issue_name')"
                  type="search"
                  icon="search"
                  icon-pack="fas"
                  @input="applyFilters(1)">
                </b-input>
              </b-field>
            </div>

            <div class="w-1/4 mr-4">
              <b-field :label="$t('search_by_manager')">
                <b-input
                  v-model="filterManager"
                  :placeholder="$t('manager')"
                  type="search"
                  icon="search"
                  icon-pack="fas"
                  @input="emptyParticipantAndApplyFilters(1)">
                </b-input>
              </b-field>
            </div>

            <div class="w-1/4 mr-4">
              <b-field :label="$t('search_by_participant')">
                <b-input
                  v-model="filterParticipant"
                  :placeholder="$t('participant')"
                  type="search"
                  icon="search"
                  icon-pack="fas"
                  @input="emptyManagerAndApplyFilters(1)">
                </b-input>
              </b-field>
            </div>

            <div class="w-1/4 mr-4">
              <b-field :label="$t('search_by_status')">
                <b-select ref="input"
                          v-model="filterStatus"
                          :placeholder="$t('activity_status')"
                          icon="search" icon-pack="fas"
                          @input="applyFilters(1)"
                          expanded
                >
                  <option value="1">{{ $t('opened') }}</option>
                  <option value="0">{{ $t('closed') }}</option>
                  <option value="101">{{ $t('allf') }}</option>
                </b-select>
              </b-field>
            </div>

            <div class="w-1/4 mr-4">
              <b-field :label="$t('search_by_location')">
                <b-select
                  v-model="filterLocation"
                  :placeholder="$t('location')"
                  icon="map-marker-alt" icon-pack="fas"
                  @input="applyFilters(1)"
                  expanded
                >
                  <option :value="null">{{ $t('all') }}</option>
                  <option v-for="location in locationsList"
                          :value="location.ids" :key="'location_'+location.id"
                  >
                    {{ location.name }}
                  </option>
                </b-select>
              </b-field>
            </div>

          </div>

          <div class="flex items-center mb-4">

            <!-- clasificacion -->
            <div class="w-1/4 mr-4">
              <b-field :label="$t('issue_classification')">
                <b-select
                  v-model="filterClassIssue"
                  :placeholder="$t('issue_classification')"
                  icon="exclamation-triangle" icon-pack="fas"
                  @input="applyFilters(1)"
                  expanded
                >
                  <option :value="null">{{ $t('all') }}</option>
                  <option v-for="classification in issueClassesList"
                          :value="classification.ids" :key="'class_'+classification.id"
                  >
                    {{ classification.name }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <!-- tipo -->
            <div class="w-1/4 mr-4">
              <b-field :label="$t('issue_type')">
                <b-select
                  v-model="filterTypeIssue"
                  :placeholder="$t('issue_type')"
                  icon="exclamation-triangle" icon-pack="fas"
                  @input="applyFilters(1)"
                  expanded
                >
                  <option :value="null">{{ $t('all') }}</option>
                  <option v-for="type in issueTypesList"
                          :value="type.ids" :key="'type_'+type.id"
                  >
                    {{ type.name }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div class="w-1/4 mr-4">
              <b-field :label="$t('supply')">
                <b-select
                  :disabled="typeSelected == 'supply' ? false : true"
                  v-model="supplyTypeId"
                  :placeholder="$t('issue_type')"
                  icon="exclamation-triangle" icon-pack="fas"
                  @input="applyFilters(1)"
                  expanded
                >
                  <option :value="null">{{ $t('all') }}</option>
                  <option v-for="type in supplyTypesList"
                    :value="type.id" :key="'supply_'+type.id"
                  >
                    {{ type.name }}
                  </option>

                </b-select>
              </b-field>
            </div>

            <div class="w-1/4 mr-4">
              <b-field :label="$t('provider')">
                <b-select
                  v-model="filterProviderId"
                  :placeholder="$t('issue_type')"
                  icon="exclamation-triangle" icon-pack="fas"
                  @input="applyFilters(1)"
                  expanded
                >
                  <option :value="null">{{ $t('all') }}</option>
                  <option v-for="type in providersList"
                    :value="type.id" :key="'provider_'+type.id"
                  >
                    {{ type.name }}
                  </option>

                </b-select>
              </b-field>
            </div>


            <!-- disciplina -->
            <div class="w-1/4 mr-4">
              <b-field :label="$t('issue_disciplines')">
                <b-select
                  v-model="filterDisciplineIssue"
                  :placeholder="$t('issue_disciplines')"
                  icon="exclamation-triangle" icon-pack="fas"
                  @input="applyFilters(1)"
                  expanded
                >
                  <option :value="null">{{ $t('all') }}</option>
                  <option v-for="discipline in issueDisciplinesList"
                          :value="discipline.ids" :key="'discipline_'+discipline.id"
                  >
                    {{ discipline.name }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <!-- origenes -->
            <div class="w-1/4 mr-4">
              <b-field :label="$t('issue_origins')">
                <b-select
                  v-model="filterOriginIssue"
                  :placeholder="$t('issue_origins')"
                  icon="exclamation-triangle" icon-pack="fas"
                  @input="applyFilters(1)"
                  expanded
                >
                  <option :value="null">{{ $t('all') }}</option>
                  <option v-for="origin in issueOriginsList"
                          :value="origin.ids" :key="'origin_'+origin.id"
                  >
                    {{ origin.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>

          <div class="flex items-center mb-4">

            <div class="w-1/3 mr-4">
              <b-field :label="$t('created_date_start')">
                <b-datepicker
                  v-model="filterDateCreatedStart"
                  :placeholder="$t('created_date_start')"
                  :month-names="$t('months_names')"
                  :day-names="$t('days_names')"
                  :first-day-of-week="parseInt($t('first_day_of_week'))"
                  :readonly="false"
                  @input="applyFilters(1)"
                >
                </b-datepicker>
              </b-field>
            </div>

            <div class="w-1/3 mr-4">
              <b-field :label="$t('created_date_end')">
                <b-datepicker
                  v-model="filterDateCreatedEnd"
                  :placeholder="$t('created_date_end')"
                  :month-names="$t('months_names')"
                  :day-names="$t('days_names')"
                  :first-day-of-week="parseInt($t('first_day_of_week'))"
                  :readonly="false"
                  @input="applyFilters(1)"
                >
                </b-datepicker>
              </b-field>
            </div>

            <div class="w-1/3 mr-4">
              <b-field :label="$t('search_by_assignment_drawings')">
                <b-select
                  v-model="filterDocumentId"
                  :placeholder="drawings.length ? $t('drawings') : $t('there_are_no_drawings')"
                  icon="search" icon-pack="fas"
                  @input="applyFilters(1)"
                  expanded
                  :disabled="drawings.length == 0"
                >
                  <option :value="null">{{ $t('all') }}</option>
                  <option v-for="option in drawings"
                          :value="option.document_id" :key="'document_'+option.document_id"
                  >
                    {{ option.name  }}
                  </option>
                </b-select>
              </b-field>
            </div>

            <div class="w-1/3 mr-4">
              <b-field :label="$t('project')">
                <b-select
                  v-model="filterProjectId"
                  :placeholder="$t('project')"
                  icon="search" icon-pack="fas"
                  @input="applyFilters(1)"
                  expanded
                  :disabled="projectList.length == 0"
                >
                  <option :value="null">{{ $t('all') }}</option>
                  <option v-for="option in projectList"
                          :value="option.id" :key="'project_'+option.id"
                  >
                    {{ option.name  }}
                  </option>
                </b-select>
              </b-field>
            </div>

          </div>

          <div class="flex items-center mb-4">
            <div class="w-full mb-4">
              <filter-tags
                @filterList="filterByTags($event)"
                ref="filterTags"
              />
            </div>

          </div>

          <button class="button is-primary" @click="cleanFiltersClicked()">{{ $t('clean_filters') }}</button>
        </div>
      </b-collapse>
      <!--<div class="w-full h-8" v-if="(project.user_department.can_admin || user.collaborator)" > -->
        <div class="w-full h-8" >
        <div class="flex w-full items-center justify-end text-white">
          <div class="p-2 text-gray-900 bg-white rounded-lg shadow-lg- font-medium capitalize text-right">

            <span v-if="!isLoadingTable"
              class="px-1 mt-2  hover:text-gray-700 cursor-pointer
                lg:px-2 text-sm xl:rounded xl:py-2 xl:hover:bg-gray-200"
                @click="showModalReport">
              <b-tooltip :label="$t('issues_report')" position="is-left">
                <i class="lg:w-8 fas fa-book p-2 bg-gray-200 rounded-full"></i>
                <span class="mx-1 hidden xl:inline-">{{ $t('issues_report') }}</span>
              </b-tooltip>
            </span>

            <project-report-issues-list v-if="!isLoadingTable"
              :issuesList="listOrder"
              :onlyModal="true"
              :openWithButton="false"
              :isCorporate="true"
              :show.sync="showModal"/>
          </div>
        </div>
      </div>    
      <div class="panel text-sm" v-if="!isLightVersion">
        <b-tabs type="is-boxed" v-model="mainActiveTab">

            <!-- incidencias en mapa -->
            <b-tab-item>
              <template slot="header">
                <b-icon pack="fas" icon="map-location-dot"></b-icon>
                <span> {{ $t('map') }} </span>
              </template>
              <div>
                <input type="hidden" name="address_id" id="address_id" value="">

                <googlemaps ref="mapRef"
                    name="issues-location"
                    :datamaps="datamaps"
                    :draggable="draggable"
                    @updPlace="updPlace"
                    :newPlace="newPlace"
                    inputAutocomplete="address_id"/>
              </div>
            </b-tab-item>

            <!-- incidencias en planos -->
            <b-tab-item>
              <template slot="header">
                <b-icon pack="fas" icon="compass-drafting"></b-icon>
                <span>{{ $t('drawings') }}</span>
              </template>
              <div>
                Selecciona un plano para ver sus incidencias:<br>
                <div v-for="(drawing) in drawings" :key="drawing.id" v-on:click="loadDrawing(drawing)" class="text-blue text-sm rounded-xl inline-block my-2 py-0.5 px-2 border-2 border-blue bg-blue-200 mr-1 cursor-pointer">
                  <img :src="drawing.file.thumbnail" style="width:150px" >
                  {{ drawing.name }}
                </div>
                <div v-if="drawings.length==0" class="text-red">No hay ninguna incidencia creada sobre plano</div>

                <div style="overflow:auto;">
                  <div id="zoom">
                    <a v-on:click="loadDrawing(drawingDocument, parseFloat(scale)-0.2)" class="ml-1 button is-light is-small">
                      <b-icon pack="fa" class="float-left" size="is-small" icon="magnifying-glass-minus" ></b-icon>
                    </a>
                    <a v-on:click="loadDrawing(drawingDocument, parseFloat(scale)+0.2)" class="ml-1 button is-light is-small">
                      <b-icon pack="fa" class="float-left" size="is-small" icon="magnifying-glass-plus"></b-icon>
                    </a>
                  </div>
                  <canvas id="canvas" width="595"  style="width: auto;border: 1px solid gray; box-shadow: 2px 2px 4px gray;"></canvas> <!--height="720"-->
                </div>
              </div>
            </b-tab-item>

          </b-tabs>
      </div>

      <div class="flex flex-col text-sm">

        <div >
          <div v-if="total" class="text-right w-full float-right">
            <b>{{ $t('count_results') }}: {{ total }}</b>
          </div>
        </div>

        <b-table
          ref="table_issues"
          :data="list"
          :loading="isLoadingTable"
          :pagination-simple="false"

          paginated
          :per-page="perPage"
          :total="total"
          :current-page.sync="currentPage"

          :default-sort="[orderField,orderDir]"
          :default-sort-direction="orderDir"

          icon-pack="fas"
          class="border-blue-lighter text-xs"
          bordered
          narrowed
          striped

          :sticky-header="false"
          :mobile-cards="false"
          @sort="onSort"
        >

          <template slot="empty">
            <div v-if="! isLoadingTable" class="m-4 text-lg text-dark-grey">
              <span>{{ $t('empty_issues_table_msg') }}</span>
            </div>
          </template>

          <template>

            <b-table-column field="user" label="" cell-class="w-12 vertical-center" centered>
              <template v-slot:header="">
                <i class="fas fa-user"></i>
              </template>
              <template v-slot="issues">
                <b-tooltip
                  v-if="issues.row.manager && issues.row.manager.user_id"
                  :label="issues.row.manager.fullname"
                  position="is-right"
                >
                  <img :src="issues.row.manager.avatar" class="rounded-full overflow-hidden w-6 h-6">
                </b-tooltip>
              </template>
            </b-table-column>


            <b-table-column field="code" label="Id" sortable cell-class="vertical-center" v-slot="issues">
              <div>
                {{ issues.row.code }}
              </div>
            </b-table-column>

            <b-table-column field="project" :label="$t('project')" sortable cell-class="vertical-center" v-slot="issues">
              <div class="text-blue font-bold">
                {{ issues.row.project_name }}
              </div>
            </b-table-column>

            <b-table-column field="code2" :label="$t('codification')" sortable cell-class="vertical-center" v-slot="issues">
              <div class="text-blue font-bold">
                {{ issues.row.code2 }}
              </div>
            </b-table-column>

            <b-table-column field="name" :label="$t('issue')" sortable cell-class="vertical-center" v-slot="issues">
              <div class="cursor-pointer"
                   @click="onRowClicked(issues.row.activity_sub, issues.row.parent_id, issues.row.activity_id, issues.row.id)">
                {{ issues.row.name }}
              </div>
            </b-table-column>

            <b-table-column field="class_name" :label="$t('classification')" sortable cell-class="vertical-center" v-slot="issues">
              <div>
                {{ issues.row.class_name }}
              </div>
            </b-table-column>

            <b-table-column field="type_name" :label="$t('type')" sortable cell-class="vertical-center" v-slot="issues">
              <div>
                {{ issues.row.type_name }}
              </div>
            </b-table-column>

            <b-table-column field="activity_name" :label="$t('activity')" sortable cell-class="vertical-center" v-slot="issues">
              <div>
                {{ issues.row.activity_name }}
              </div>
            </b-table-column>

            <b-table-column field="activity_name" :label="$t('provider')" sortable cell-class="vertical-center" v-slot="issues">
              <div>
                {{ issues.row.supply_provider_name }}
              </div>
            </b-table-column>

            <b-table-column field="created_at" :label="$t('opened_date')" cell-class="w-24 vertical-center" sortable centered
              v-slot="issues">
              {{ issues.row.created_at }}
            </b-table-column>

            <b-table-column field="closed_at" :label="$t('closed_date')" cell-class="w-24 vertical-center" sortable centered
                            :visible="filterStatus != 1" v-slot="issues">
              {{ issues.row.closed_at }}
            </b-table-column>

            <b-table-column field="duration" :label="$t('duration')" cell-class="w-24 vertical-center" :custom-sort="sortElapsedTime" sortable centered
             v-slot="issues">
              {{ calculateElapsedTime(issues.row) }}
            </b-table-column>

            <b-table-column field="is_open" :label="$t('status')" cell-class="w-24 vertical-center" sortable centered v-slot="issues">
              <issue-status-label :status="issues.row.is_open" class="w-full vertical-center"/>
            </b-table-column>



          </template>
        </b-table>
      </div>
    </div>
  </template>

  <script>
  import Vue from "vue"
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import Form from 'vform'
  import issueStatusLabel from '·/components/issue-status-label'
  import projectReport from '·/components/project-report'
  import projectReportIssuesList from '·/components/project-report-issues-list'
  import moment from 'moment'
  import entityNotifications from '·/components/entity-notifications'
  import MugenScroll from 'vue-mugen-scroll'
  import EventBus from '~/plugins/bus'
  import Googlemaps from '~/components/GoogleMaps.vue'
  import FilterTags from "·/components/filter-tags";
  import PdfCanvas from "~/plugins/pdfCanvas";

  Vue.use(moment)

  export default {
    middleware: 'auth',
    title: 'issues_page_title',
    subtitle: 'issues_page_subtitle',
    menuOption: '2-0',

    components: {
      issueStatusLabel,
      entityNotifications,
      projectReport,
      projectReportIssuesList,
      MugenScroll,
      Googlemaps,
      FilterTags
    },

    props: {
      isLightVersion: { type: Boolean, default: false }, // version light sin mapa ni filtros, para integrar en sitios
    },

    data: () => ({
      mainActiveTab: 0,
      form: new Form({
        creator: 'Todos',
        fecha: '1 Enero 2018 - 31 Diciembre 2018',
      }),
      isLoadingTable: true,
      currentPage: 1,
      page: 1,
      last: 1,
      perPage: 25,
      total: 0,
      orderField: "code",
      orderDir: "asc",
      list: [], // listado incidencias segun los filtros
      listUnfiltered: [], // listado incidencias con filtros por defecto (rellenado en el listado inicial del mapa)
      listOrder: [], // lista ordenada por el table
      filterName: '',
      filterManager: '',
      filterParticipant: '',
      filterStatus: 1,
      filterLocation: null,
      filterClassIssue: null,
      filterTypeIssue: null,
      filterDisciplineIssue: null,
      filterOriginIssue: null,
      filterDateCreatedStart: null,
      filterDateCreatedEnd: null,
      filterDocumentId: null,
      filterProjectId: null,
      filterProviderId: null,
      locationsList: [],
      issueClassesList:[],
      issueTypesList:[],
      issueDisciplinesList:[],
      issueOriginsList:[],
      showFilters: false,
      tags: [],
      //mapa
      datamaps: [],
      draggable: false,
      newPlace: '',

      typeSelected: '',
      supplyTypeId: null,
      supplyTypesList: [],
      providersList: [],

      paginationOrder: '',

      projectList: [],

      showModal: false,
      drawings: [], // listado de planos donde se señaló alguna incidencia
      drawingDocument: null, // plano seleccionado para ver incidencias (objeto document normal)
      documentTask: null, // el futuro objeto que gestiona un document pdf con libreria pdfjs
      scale: 1, // scale of selected drawing (zoom)
    }),
    watch: {
      'filterTypeIssue': function (val) {
        // console.log('filterTypeIssue', val)
        // fifind issueTypesList
        
        this.filterProviderId = null
        this.supplyTypeId = null
        if (val == null) {
          this.supplyTypesList = []
          this.filterTypeIssue = null
          return
        }
        let type = this.issueTypesList.find( type => type.ids === val)
        this.typeSelected = type.type
        if (type.type == 'supply') {
          this.supplyTypesList = type.children
        } else {
          
        this.supplyTypesList = []
        this.supplyTypeId = null
        this.filterTypeIssue = null
        }
      },

    },

    computed: {
      ...mapGetters({
        user: 'auth/user',
        //project: 'app/project',
        company: 'app/company',
      }),
    },

    mounted() {

      //this.applyFilters(this.page)
      this.getLocations();
      this.getIssueCTDOList();
      this.getProviders();
      //llamada a mapa sin filtros, todas las incidencias abiertas
      this.getIssuesMap();
      let self = this

      // Si recibimos el evento refresh, refrescamos la lista
      EventBus.$on('refreshIssuesNotifications', function (e) {
        self.isLoadingTable = true
        self.applyFilters(self.page);
        self.getIssuesMap();
      });

    },

    beforeDestroy(){
      EventBus.$off("refreshIssuesNotifications");
    },

    methods: {

      async getIssuesList2(name, manager, participant, filterDateCreatedStart, filterDateCreatedEnd, filterStatus, filterLocation, filterTypeIssue, supplyTypeId, filterDocumentId, filterProviderId , map = false, tags = [], filterClassIssue = null, filterDisciplineIssue = null, filterOriginIssue = null, filterProjectId = null) {

        var self = this
        var params = {p: 0, s: 1}

        // Filtro por nombre
        if (name && name.length >= 3) {
            params.n = name
        }

        //Filtro por responsable
        if (manager && manager.length >= 3) {
            params.manager = manager
        }

        //Filtro por participante
        if (participant && participant.length >= 3) {
            params.participant = participant
        }

        //Filtro por estado
        if (filterStatus && filterStatus !== null) {
            params.status = filterStatus
        }

        //Filtro por emplazamiento
        if (filterLocation && filterLocation !== null) {
            params.location_all = filterLocation
        }

        // Fecha de creación inicio
        if (filterDateCreatedStart && filterDateCreatedStart !== null) {
            params.filter_date_created_start = filterDateCreatedStart
        }

        // Fecha de creacion final
        if (filterDateCreatedEnd && filterDateCreatedEnd !== null) {
            params.filter_date_created_end = filterDateCreatedEnd
        }

        //Filtro por tipo de incidencia
        if (filterTypeIssue && filterTypeIssue !== null) {
            params.type_issue_all = filterTypeIssue
        }

        //Filtro por clase de incidencia
        if (filterClassIssue && filterClassIssue !== null) {
            params.class_issue_all = filterClassIssue
        }

        //Filtro por disciplina de incidencia
        if (filterDisciplineIssue && filterDisciplineIssue !== null) {
            params.discipline_issue_all = filterDisciplineIssue
        }

        //Filtro por origen de incidencia
        if (filterOriginIssue && filterOriginIssue !== null) {
            params.origin_issue_all = filterOriginIssue
        }

        //Filtro por incidencias señaladas en este documento de plano
        if (filterDocumentId && filterDocumentId !== null) {
            params.document_id = filterDocumentId
        }

        if (supplyTypeId && supplyTypeId !== null) {
          params.supply_type_id = supplyTypeId
        } 
        //Filtro por proveedor
        if (filterProviderId && filterProviderId !== null) {
            params.supply_provider_id = filterProviderId
        }

        //Filtro por proyecto
        if (filterProjectId && filterProjectId !== null) {
            params.filter_project_id = filterProjectId
        }


        // Añadir el orden y número de elementos por página
        params.order_field = this.orderField
        params.order_dir = this.orderDir
        // TODO: optimizar a futuro la carga de incidencia
        // params.per_page = this.perPage // no enviamos para que nos traiga todas las incidencia

        // Si es para mostar en mapa
        if (map) {
            params.map = 1;
            params.per_page = 1000 // si supera los 1000 incidencia no enviar para que traiga todas
        }

        // Añadir tags
        if (tags.length > 0) {
            params.tags = tags
        }

        const project = await axios.get('/api/v2/admin/projects?page=' + this.page, {params: params})

        // console.log(project.data.projects)
        this.projectList = project.data.projects;
        
        this.getLocationsList(this.projectList);
        // params.project_ids = project.data.projects;

        // Axios
        const {data} = await axios.get('/api/v2/admin/issues?page=' + this.page, {params: params})

        // console.log(data.issues);

        if (data && data.issues) {
            if (map) {
            EventBus.$emit('load-locations', data.issues);

            self.listUnfiltered = data.issues
            self.drawings = data.issues
                .filter(issue => issue.drawing) // devuelve solo las issues con plano
                .map(issue => issue.drawing) // devuelve solo el objeto drawing
                .filter((value, index, self) => self.findIndex(el => el.document_id==value.document_id) === index) // unique. quita planos repetidos

            } else {
            // Last and total
            this.last = data.lastPage
            this.total = data.total

            self.list = data.issues

            }
        } else {
            this.$notify.error('error_loading_issues')
        }

        this.isLoadingTable = false
      },

      async getIssuesMap(name = '', manager = '', participant = '', filterDateCreatedStart = '', filterDateCreatedEnd = '', filterStatus = 1, map = true) {
        //Establece map a true para devolver coordenadas
        // this.getIssuesList(name, manager, participant, filterDateCreatedStart, filterDateCreatedEnd, filterStatus, null, null, null, null, map);
        this.getIssuesList2(name, manager, participant, filterDateCreatedStart, filterDateCreatedEnd, filterStatus, null, null, null, null, null, map);
      },

      async getLocationsList(projectList) {

        const locations = []

        // promise all
        const promises = projectList.map(async project => {
          const {data} = await axios.get('/api/v2/locations?all=1&p=' + project.id)
          if (data && data.success) {
            locations.push(...data.locations)
          }
        })

        await Promise.all(promises)

        const resultM = this.mergeByName(locations)
        this.locationsList = resultM
      },

      async getLocations(){
        const {data} = await axios.get('/api/v2/company/locations')
        if (data && data.success) {
          this.locationsList = this.mergeByName(data.locations)
        } else {
          this.$notify.error('error_loading_locations')
        }
      },
      setDefaultSupplyType() {
        const firstSupplyType = this.issueTypesList.find(type => type.type === 'supply');
        if (firstSupplyType) {
          this.filterTypeIssue = firstSupplyType.ids;
        } 
        this.applyFilters(1);
      },

      mergeByName(newArray) {
        const merged = []; // esto es para almacenar el nuevo array sin duplicados en base a name ademas agregamos un campo ids con los ids de los duplicados 
        if (!newArray) {
          return merged;
        } 
        
        newArray.forEach((item) => {
          const found = merged.find((element) => element.name === item.name);
          if (found) {
            found.ids.push(item.id);
          } else {
            merged.push({ ...item, ids: [item.id] });
          }
        });
        return merged;
      },

      async getProviders () {
        let url = '/api/v2/user/partners'
        const params = {company_id: this.company.id}
        const {data} = await axios.get(url, {params: params})
        if (data && data.success) {
          this.providersList = data.partners.filter(partner => partner.type == 3)
        } else {
          this.$notify.error('error_loading_providers')
        }
      },


      async getIssueCTDOList() {
        //const { data } = await axios.get('/api/v2/project/' + this.project.id + '/issue-classes-types-disciplines-origins')
        const { data } = await axios.get('/api/v2/company/' + this.company.id + '/issue-classes-types-disciplines-origins')
        if (data && data.success) {
          // console.log(data)
          this.issueClassesList = data.classes
          this.issueTypesList = data.types
          
          this.issueDisciplinesList = data.disciplines
          this.issueOriginsList = data.origins

          this.setDefaultSupplyType();
        } else {
          this.$notify.error('Error cargando clase / tipo / disciplinas ...')
        }
      },

      onRowClicked(isSub, parentId, activityId, issueId) {

        if (isSub) {

          // Nos lleva hacia la incidencia
          this.$router.push({
            name: 'issue.home-sub',
            params: {activity_id: parentId, subactivity_id: activityId, issue_id: issueId}
          })

        } else {

          // Nos lleva hacia la incidencia
          this.$router.push({name: 'issue.home', params: {activity_id: activityId, issue_id: issueId}})
        }

      },

      // Carga las tags recibidas y aplica el filtro
      filterByTags(tags) {
        this.isLoadingTable = true;
        this.tags = tags;
        this.applyFilters(1);
      },

      applyFilters(page) {

        var name, manager, participant = "";
        var filterStatus, filterLocation = null, filterTypeIssue = null, filterDocumentId = null;
        var filterClassIssue = null, filterDisciplineIssue = null, filterOriginIssue = null;
        var filterDateCreatedStart, filterDateCreatedEnd = "";
        var filterProviderId = null, supplyTypeId = null;
        var filterProjectId = null;
        // Comprobamos la página
        this.page = (page <= this.last) ? page : 1
        this.currentPage = page;

        if (this.filterName.length >= 3) {
          name = this.filterName
        }

        if (this.filterManager.length >= 3) {
          manager = this.filterManager
        }

        if (this.filterParticipant.length >= 3) {
          participant = this.filterParticipant
        }

        if (this.filterStatus !== null) {
          filterStatus = this.filterStatus
        }

        if (this.filterLocation !== null) {
          filterLocation = this.filterLocation
        }

        if (this.filterTypeIssue !== null) {
          filterTypeIssue = this.filterTypeIssue
        }

        if (this.supplyTypeId !== null) {
          supplyTypeId = this.supplyTypeId
        }

        if (this.filterClassIssue !== null) {
          filterClassIssue = this.filterClassIssue
        }

        if (this.filterDisciplineIssue !== null) {
          filterDisciplineIssue = this.filterDisciplineIssue
        }

        if (this.filterOriginIssue !== null) {
          filterOriginIssue = this.filterOriginIssue
        }

        if (this.filterDocumentId !== null) {
          filterDocumentId = this.filterDocumentId
        }

        if (this.filterProviderId !== null) {
          filterProviderId = this.filterProviderId
        }

        if (this.filterDateCreatedStart !== null) {
          filterDateCreatedStart = moment(this.filterDateCreatedStart).format('YYYY-MM-DD')
        }

        if (this.filterDateCreatedEnd !== null) {
          filterDateCreatedEnd = moment(this.filterDateCreatedEnd).format('YYYY-MM-DD')
        }

        if (this.filterProjectId !== null) {
          filterProjectId = this.filterProjectId
        }
        // this.getIssuesList(name, manager, participant, filterDateCreatedStart, filterDateCreatedEnd, filterStatus, filterLocation, filterTypeIssue, filterDocumentId, filterProviderId, false, this.tags, filterClassIssue,  filterDisciplineIssue, filterOriginIssue)
        this.getIssuesList2(name, manager, participant, filterDateCreatedStart, filterDateCreatedEnd, filterStatus, filterLocation, filterTypeIssue, supplyTypeId, filterDocumentId, filterProviderId, false, this.tags, filterClassIssue,  filterDisciplineIssue, filterOriginIssue, filterProjectId)
      },

      fetchData() {
        if (this.page <= this.last) {
          this.isLoadingTable = true
          this.applyFilters(this.page)
          this.isLoadingTable = false
        }
      },

      // onPageChange(page) {
      //   this.page = page
      //   this.fetchData()
      // },

      onSort(field, order) {
        this.orderField = field
        this.orderDir = order
        // this.fetchData()
      },

      emptyManagerAndApplyFilters() {
        this.filterManager = ""
        this.applyFilters()
      },

      emptyParticipantAndApplyFilters() {
        this.filterParticipant = ""
        this.applyFilters()
      },


      cleanFiltersClicked() {
        this.filterName = ''
        this.filterManager = ''
        this.filterParticipant = ''
        this.filterStatus = null
        this.filterLocation = null
        this.filterDateCreatedStart = null
        this.filterDateCreatedEnd = null
        this.filterTypeIssue = null
        this.supplyTypeId = null
        this.filterClassIssue = null
        this.filterDisciplineIssue = null
        this.filterOriginIssue = null
        this.filterDocumentId = null
        this.filterProviderId = null
        this.filterProjectId = null
        this.page = 1
        this.$refs.filterTags.resetTags();
        this.tags = [];
        this.applyFilters(1)
      },

      async updPlace(place) {
        this.place.latitude = place.latitude
        this.place.longitude = place.longitude
      },

      calculateElapsedTime(row, returnValor=false) {

        let createDate = moment(row.created_at, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
        // var createDate = dateMomentObject.toDate();
        let currentDate = moment()

        if( !row.is_open && row.closed_at)
          currentDate = moment(row.closed_at, "DD/MM/YYYY")

        let totalDays = currentDate.diff(createDate, 'days');

        if( returnValor )
          return totalDays

        return totalDays + ' dia(s) abiert' + (!row.is_open ? 'o' : 'a')
      },

      sortElapsedTime(a, b, isAsc) {
        let valorA = this.calculateElapsedTime(a)
        let valorB = this.calculateElapsedTime(b)

        return isAsc ? (valorA > valorB) : (valorA < valorB)
      },

      showModalReport() {
        this.listOrder = this.$refs.table_issues.newData
        this.showModal = true;
      },

      // carga la primera pagina del pdf en un etiqueta html canvas donde permita clicar la ubicacion de la incidencia
      async loadDrawing(drawing, scale=1) {

        this.drawingDocument = drawing // guardamos para saber cual se seleccionó

        PdfCanvas.setAssetUrl(this.asset(''))

        // nivel de zoom. Nunca menos de 0.4 ni mas de 2.6
        if (scale < 0.4) {
          scale = 0.4
        }
        if (scale > 2.6) {
          scale = 2.6
        }
        this.scale = scale.toFixed(2)

        this.documentTask = await PdfCanvas.loadDocument(drawing, {scale: this.scale})

        this.drawIssues(drawing)

        // y ademas, el listado de incidencias, filtrarlo por este documento
        this.filterDocumentId = drawing.document_id
        this.applyFilters(1)

      },

      drawIssues(drawing) {

        let issuesList = []
        // de todas las issues, nos quedamos con las vinculadas con el documento seleccionado
        this.listUnfiltered.filter(function(issue) {
          return issue.drawing && issue.drawing.document_id == drawing.document_id
        }).forEach(issue => {

          issue.metadata.markers.forEach(marker => {
            issuesList.push({
              id: issue.id,
              title: issue.code + ' ' + issue.name,
              x: marker.document_x,
              y: marker.document_y
            }) // y pasamos solo los datos necesarios
          })

        })

        PdfCanvas.drawMarker(this.documentTask, issuesList)
      },


    },


  };
  </script>

  <style scoped>

  .vertical-center {
    vertical-align: inherit !important;
  }

  .filters-collapse {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .filters-trigger:hover {
    background-color: #f8fafc;
  }
  </style>
