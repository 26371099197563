<template>
  <div>
    <div v-if="isLoading" class="text-center my-4">
      <scale-loader></scale-loader>
    </div>
    <div v-else>
      <div class="panel" v-if="ready">

        <label v-if="creatingNew">Añadir usuario en <b>{{ partner.name }}</b></label>
        <label v-else>Editar usuario de <b>{{ partner.name }}</b></label>

        <form @submit.prevent="updateUser">


          <div class="flex items-center mb-4">
            <div class="w-1/4 mr-4">
              <label for="name">{{ $t('name')}}</label>
              <v-input
                title="name"
                id="name"
                v-model="formChangeUser.name"
                name="name"
                :placeholder="$t('name')"
              />
            </div>

            <div class="w-1/4 mr-4">
              <label for="surname">{{ $t('surname')}}</label>
              <v-input
                title="surname"
                id="surname"
                v-model="formChangeUser.surname"
                name="surname"
                :placeholder="$t('surname')"
              />
            </div>

          </div>
          <div class="flex items-center mb-4">

            <div class="w-1/4 mr-4">
              <label for="dni">{{ $t('idcard')}}</label>
              <v-input
                title="dni"
                id="dni"
                v-model="formChangeUser.dni"
                name="dni"
                :placeholder="$t('idcard')"
              />
            </div>

            <div class="w-1/4 mr-4">
              <label for="phone">{{ $t('phone')}}</label>
              <v-input
                title="phone"
                id="phone"
                v-model="formChangeUser.phone"
                name="phone"
                :placeholder="$t('phone')"
              />
            </div>

          </div>
          <div class="flex items-center mb-4">

            <div class="w-1/4 mr-4">
              <label for="reference_code">{{ $t('reference_code')}}</label>
              <v-input
                title="reference_code"
                id="reference_code"
                v-model="formChangeUser.reference_code"
                name="reference_code"
                :placeholder="$t('reference_code')"
              />
            </div>

            <div class="w-1/4 mr-4">
            </div>

          </div>
          <div class="flex items-center mb-4">

            <div class="w-1/4 mr-4">
              <label for="email">{{ $t('email')}}</label>
              <v-input
                title="email"
                id="email"
                v-model="formChangeUser.email"
                name="email"
                placeholder="email"
              />
            </div>

            <div class="w-1/4 mr-4">
              <label for="password">{{ $t('password')}}</label>
              <v-input
                title="password"
                id="password"
                type="password"
                v-model="formChangeUser.password"
                name="password"
                placeholder="password"
              />
            </div>

          </div>

          <div class="flex items-center mb-4" v-show="this.user.areas.can_admin">

            <div class="w-1/4 mr-4">

              <v-switch
                :label="$t('partner_administrator')"
                :color="formChangeUser.isPartnerAdmin? 'green' : 'red'"
                :value="formChangeUser.isPartnerAdmin"
                v-model="formChangeUser.isPartnerAdmin"
                v-on:checked="toggleAdmin"
                style="margin-left:20px; margin-bottom:25px "
              />

            </div>

          </div>

          <div class="flex items-center mb-4">

            <div class="flex flex-row mt-4">
                <button class="btn btn-blue" nativeType="submit"
                :disabled="formChangeUser.name && formChangeUser.reference_code && formChangeUser.email && formChangeUser.password ? false : true">
                  {{$t('save')}}
                </button>

                <a @click="$router.go(-1)" class="btn btn-light ml-2">
                  {{$t('cancel')}}
                </a>
            </div>

          </div>

        </form>
      </div>

      <div class="flex items-center mb-4">


      </div>

    </div>
  </div>
</template>

<script>
  import Form from 'vform'
  import axios from 'axios'
  import store from '~/store'
  import { mapGetters } from "vuex";
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

  export default {
    middleware: 'auth',
    // title: 'userform_page_title',
    // subtitle: 'userform_page_subtitle',
    // menuOption: '0-2',

    components: {
      ScaleLoader
    },

    data: () => ({
      formChangeUser: new Form({
        name: '',
        surname: '',
        password: '',
        email: '',
        dni: null,
        reference_code: null,
        area_id: 0,
        locale: '',
        phone: '',
        avatar: null,
        active: true,
        reminders: false,
        isPartnerAdmin: false,
      }),
      partner: {},
      ready: false,
      partnerId: 0,
      userId: 0,
      creatingNew: false,
      isLoading: false,
      preIsPartnerAdmin: false, // contiene si es rol admin, al entrar a editar, para ver si cambia al pulsar guardar
    }),

    computed: {
      ...mapGetters({
        user: "auth/user",
      })
    },

    mounted() {

      // Capturamos los IDs de la url (/admin/partner/:id/user/:userId?) id y userId
      this.partnerId = store.state.route.params.id;
      this.userId = store.state.route.params.userId;
      this.creatingNew = store.state.route.params.userId ? false: true; // creando o editando?

      this.getPartner()

      if (this.userId) { //editar
        this.userId = parseInt(this.userId)
        this.getUser()
      } else { // crear nuevo
        this.ready = true
      }
    },

    created(){
    },

    methods: {

      toggleAdmin() {
        this.formChangeUser.isPartnerAdmin = !this.formChangeUser.isPartnerAdmin
      },

      updateType(value) {
        this.formChangeUser.type = parseInt(value)
      },

      updateArea(value) {
        this.formChangeUser.area_id = parseInt(value)
      },

      getPartner() {
        let self = this;
        axios.get('/api/v2/partner/' + self.partnerId)
          .then(function (response) {
            //  Object.assign(self.partner, response.data.data); bug, no va bien
            self.partner = Object.assign({}, response.data.data);
            self.formChangeUser.area_id = self.partner.area_id
          })
          .catch(function (error) {
            self.$notify.error('error_retrieving_data')
          });
      },

      getUser() {
        let self = this;

        // axios.get('/api/v2/user/' + this.userId) // esta llamada no devuelve el rol
        axios.get('/api/v2/partner/' + self.partnerId + '/user/' + self.userId)
          .then(function (response) {

            self.formChangeUser = Object.assign({}, response.data.user);
            self.preIsPartnerAdmin = self.formChangeUser.isPartnerAdmin // controlar si cambia de rol al guardar
            self.ready = true
          })
          .catch(function (error) {
            self.$notify.error('error_retrieving_data')
          });
      },

      async updateUser() {

        let formData = new FormData();
        formData.append('name', this.formChangeUser.name);
        formData.append('surname', this.formChangeUser.surname);
        formData.append('dni', this.formChangeUser.dni);
        formData.append('phone', this.formChangeUser.phone);
        formData.append('email', this.formChangeUser.email);
        if (this.formChangeUser.password !== '' && this.formChangeUser.password !== undefined &&
          this.formChangeUser.password !== null) {
          formData.append('password', this.formChangeUser.password );
        }
        formData.append('area_id', this.formChangeUser.area_id);
        formData.append('collaborator', 1);
        formData.append('locale', store.state.lang.locale);
        formData.append('active', 1);
        formData.append('reminders', 0);
        if (this.formChangeUser.reference_code !== '' && this.formChangeUser.reference_code !== null )
          formData.append('reference_code', this.formChangeUser.reference_code);
        else
          formData.append('reference_code', null); //tratarlo en backend, llega como string "null"

        var url = this.userId ? '/api/v2/user/' + this.userId : '/api/v2/user'

        let self = this
        this.isLoading = true

        await axios.post(url, formData)
          .then(function (response) {
            self.isLoading = false

            if (response.data.success == true) {

              if (self.creatingNew){
                // tras crear user en el sistema, asignarlo a la empresa
                // funcion cobertora para no redirigir al listado hasta completar (puede ser lenta) y no mostraria el usuario recien creado
                let addUser = async () => {
                    await self.assignUser(response.data.data.id, self.formChangeUser.isPartnerAdmin)
                }
                addUser().then(function (response) {
                    self.$notify.success('collaborator_edited')
                    self.$router.push({name: 'partner.users'})
                })

              } else {
                // cambio de rol al editar
                if (self.preIsPartnerAdmin != self.formChangeUser.isPartnerAdmin) {
                  // funcion cobertora para no redirigir al listado hasta completar (puede ser lenta) y no mostraria el usuario recien editado (por hacerle unassign)
                  let changeUser = async () => {
                    await self.changeRole(response.data.data.id, self.preIsPartnerAdmin)
                  }
                  changeUser().then(function (response) {
                    self.$notify.success('collaborator_edited')
                    self.$router.push({name: 'partner.users'})
                  });

                } else { // no hay cambio rol, volver al listado
                  self.$notify.success('collaborator_edited')
                  self.$router.push({name: 'partner.users'})
                }
              }

            }
          })
          .catch(error => {
            self.isLoading = false
            // Indicamos de forma explícita que el email ya ha sido utilizado
            if (error.response.data && error.response.data.errors.email && error.response.data.errors.email[0] === 'unique_user_email') {
              self.$notify.error('unique_user_email')
            } else if (error.response.data && error.response.data.errors.area_id && error.response.data.errors.area_id[0] === 'required_area') {
              self.$notify.error('required_area')
            } else {
              self.$notify.error('error_saving_collaborator')
            }
          });

      },

      async assignUser(userId, isAdmin) {

        let url = '/api/v2/partner/' + this.partnerId + '/users/add'
        let role = isAdmin ? 'partner_admin' : 'participant'

        let userData = {
          'user_id': userId,
          'role_name': role
        }

        const { data } = await axios.post(url, userData)
        if (!data || !data.success) {
          this.$notify.error(data.error ? data.error : 'error_user_added_to_list')
        }
      },

      // unassign, then assign
      async changeRole(userId, isAdmin) {

        let url = '/api/v2/partner/' + this.partnerId + '/users/remove'
        let role = isAdmin ? 'partner_admin' : 'participant'

        let userData = {
          'user_id': userId,
          'role_name': role
        }
        let self = this

        const { data } = await axios.post(url, userData)
        if (!data || !data.success) {
          this.$notify.error(data.error ? data.error : 'error_unassigning_user')
        } else {
          // solo cuando ha sido quitado el rol previo, podemos meter otro (si no, no mete otro rol)
          self.assignUser(userId, !isAdmin)
        }
      }


    },
  };
</script>
