<template>
<div>

  <div class="flex items-center mb-4">
    <v-button icon="arrow-left" @click="goToPage('edit-activity')" color="grey">{{ $t('activity') }}</v-button>
    <div class="ml-auto">
      <v-button icon="plus" @click="addStreet = true" color="green">{{ $t('add_street') }}</v-button>
    </div>
  </div>

  <!-- Formulario asociar calle -->
  <div class="border border-blue-lighter rounded bg-blue-lightest p-2" v-if="addStreet">
    <div class="flex items-center mb-4">
      <span class="flex-grow text-xl font-thin uppercase text-blue-light">{{ $t('add_street') }}</span>
      <v-button-icon-round
        icon="times"
        tooltip="close"
        tooltip-position="left"
        color="red"
        @click="addStreet=false; form={}"
      />
    </div>
    <div class="w-full">
      <div class="grid grid-cols-5 gap-5">
        <div class="pr-4">
          <label>{{ $t('conservation_area') }}</label>
          <b-select v-model="form.conservation_area" :placeholder="$t('select')" expanded>
              <option v-for="option in conservationAreas" :value="option.id" :key="'type_'+option.id">
                {{ option.name }}
              </option>
          </b-select>

        </div>

        <div class="pr-4">
          <label>{{ $t('province') }}</label>
          <b-select v-model="form.province"
            @input="getCities"
            :placeholder="$t('select')" expanded>
              <option v-for="option in provinces" :value="option.id" :key="'type_'+option.id">
                {{ option.name }}
              </option>
          </b-select>
        </div>

        <div class="pr-4">
          <label>{{ $t('city') }}</label>
          <b-autocomplete
                v-model="cityName"
                placeholder="Ej. Madrid"
                :keep-first="keepFirst"
                :open-on-focus="openOnFocus"
                :data="filteredCitiesObj"
                field="name"
                icon-pack="fas"
                @select="option => { if(option) { form.city = option.id; this.loadDataCities() } else { streets = [], streetName = '', form.city = null, form.street = null } }"
                :clearable="clearable"
            >
            </b-autocomplete>
        </div>

        <div class="pr-4">
          <label>{{ $t('district') }}</label>
           <b-select v-model="form.district" :placeholder="$t('select')" expanded>
              <option v-for="option in districts" :value="option.id" :key="'type_'+option.id">
                {{ option.name }}
              </option>
          </b-select>
        </div>

        <div class="pr-4">
          <label>{{ $t('residential') }}</label>
          <b-select v-model="form.residential" :placeholder="$t('select')" expanded>
              <option v-for="option in residentials" :value="option.id" :key="'type_'+option.id">
                {{ option.name }}
              </option>
          </b-select>
        </div>

        <div class="pr-4">
          <label>{{ $t('street') }}</label>
          <b-autocomplete
                v-model="streetName"
                placeholder="Ej. Azcona"
                :keep-first="keepFirst"
                :open-on-focus="openOnFocus"
                :loading="isFetching"
                :data="filteredDataObj"
                field="name"
                @select="option => { if(option) { form.street = option.id } else { streetName = '', form.street = null } }"
                icon-pack="fas"
                :clearable="clearable"
            >
            </b-autocomplete>
        </div>

        <div class="pr-4">
          <label>&nbsp;</label>
          <button class="btn btn-green" type="button" @click="associateStreet()">
            <i class="fas fa-save mr-2"></i> {{ $t('associate_street') }}
          </button>
        </div>
      </div>
    </div>

  </div>

  <div class="panel" v-if="!addStreet">
    <div v-if="isLoading" class="my-4 text-center">
      <scale-loader></scale-loader>
    </div>
    <div v-else>
      <div class="flex flex-wrap w-full mt-4">
        <section class="w-full mb-6" v-if="activityStreetsInRedaction.length > 0">
          <div class="flex items-center mb-4">
            <span class="flex-grow text-xl font-thin uppercase text-blue-light">{{ $t('approved_streets') }}</span>
          </div>
          <b-table
            icon-pack="fas"
            :data="activityStreetsInRedaction"
            :paginated="isPaginated"
            :per-page="perPage"
            :bordered="true"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :default-sort-direction="defaultSortDirection"
            v-if="!isLoading">
            <template>
              <b-table-column field="conservation_area" sortable>
                <template v-slot:header="">{{ $t('conservation_area') }}</template>
                <template v-slot="props">{{ getDescription(props.row.pivot.conservation_area) }}</template>
              </b-table-column>

              <b-table-column field="province" sortable>
                <template v-slot:header="">{{ $t('province') }}</template>
                <template v-slot="props">{{ props.row.city.province.name }}</template>
              </b-table-column>

              <b-table-column field="district" sortable>
                <template v-slot:header="">{{ $t('district') }}</template>
                <template v-slot="props">{{ getDescription(props.row.pivot.district) }}</template>
              </b-table-column>

              <b-table-column field="residential" sortable>
                <template v-slot:header="">{{ $t('residential') }}</template>
                <template v-slot="props">{{ getDescription(props.row.pivot.residential) }}</template>
              </b-table-column>

              <b-table-column field="street" sortable>
                <template v-slot:header="">{{ $t('street') }}</template>
                <template v-slot="props">{{ props.row.name }}</template>
              </b-table-column>

              <b-table-column field="actions" width=100 sortable>
                <template v-slot:header="">{{ $t('actions') }}</template>
                <template v-slot="">
                  <div class="flex items-center justify-around w-full h-full">
                  </div>
                </template>
              </b-table-column>
            </template>

            <template slot="bottom-left">
                <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                <b>{{ $t("total") }}</b>: {{ this.activityStreets.length }}
            </template>

            <template #empty>
                <div class="has-text-centered">{{ $t('no_results') }}</div>
            </template>

          </b-table>
        </section>

        <section class="w-full mb-6">
          <div class="flex items-center mb-4">
            <span class="flex-grow text-xl font-thin uppercase text-blue-light">{{ $t('running_streets') }}</span>
          </div>
          <b-table
            icon-pack="fas"
            :data="activityStreets"
            :paginated="isPaginated"
            :per-page="perPage"
            :bordered="true"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :default-sort-direction="defaultSortDirection"
            v-if="!isLoading">
            <template>
              <b-table-column field="conservation_area" sortable>
                <template v-slot:header="">{{ $t('conservation_area') }}</template>
                <template v-slot="props">{{ getDescription(props.row.pivot.conservation_area) }}</template>
              </b-table-column>

              <b-table-column field="province" sortable>
                <template v-slot:header="">{{ $t('province') }}</template>
                <template v-slot="props">{{ props.row.city.province.name }}</template>
              </b-table-column>

              <b-table-column field="district" sortable>
                <template v-slot:header="">{{ $t('district') }}</template>
                <template v-slot="props">{{ getDescription(props.row.pivot.district) }}</template>
              </b-table-column>

              <b-table-column field="residential" sortable>
                <template v-slot:header="">{{ $t('residential') }}</template>
                <template v-slot="props">{{ getDescription(props.row.pivot.residential) }}</template>
              </b-table-column>

              <b-table-column field="street" sortable>
                <template v-slot:header="">{{ $t('street') }}</template>
                <template v-slot="props">{{ props.row.name }}</template>
              </b-table-column>

              <b-table-column field="actions" width=100 sortable>
                <template v-slot:header="">{{ $t('actions') }}</template>
                <template v-slot="props">
                  <div class="flex items-center justify-around w-full h-full">
                    <b-tooltip :label="$t('delete')" position="is-left" type="is-danger">
                        <div @click="removeStreet(props.row)"
                            class="flex cursor-pointer text-red">
                          <b-icon pack="fas" icon="trash-alt"/>
                        </div>
                    </b-tooltip>
                  </div>
                </template>
              </b-table-column>
            </template>

            <template slot="bottom-left">
                <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                <b>{{ $t("total") }}</b>: {{ this.activityStreets.length }}
            </template>

            <template #empty>
                <div class="has-text-centered">{{ $t('no_results') }}</div>
            </template>

          </b-table>
        </section>
      </div>
    </div>
  </div>

  <form @submit.prevent="finishWriting" @keydown="form.onKeydown($event)"
    class="w-full flex items-center justify-end mb-8" v-if="activityStreetsInRedaction.length == 0">
    <v-button :disabled="disableSaveButton" type="submit" icon="thumbs-up" icon-pack="far" class="mr-4">{{ $t('finish_writing') }}</v-button>
    <!-- <v-button color="grey" icon="undo-alt" @click="$router.go(-1)">{{ $t('cancel') }}</v-button> -->
  </form>



</div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import Form from 'vform'
// import moment from 'moment'

// Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'activity_edit_page_title',
  subtitle: 'activity_edit_page_subtitle',
  menuOption: '2-2',

  components: {
    ScaleLoader,
  },

  data: () => ({
    isLoading: true,
    page: 1,
    perPage: 10,
    total: 0,
    orderField: "reference_code",
    orderDir: "asc",
    currentPage: 1,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    isPaginationSimple: false,
    isPaginated: true,


    activity: {},
    activityStreets: [],
    activityStreetsInRedaction: [],

    activityId: null,
    subactivityId: null,
    isSubactivity: false,
    form: new Form({
      // TODO
    }),


    disableSaveButton: false,

    addStreet: false,
    conservationAreas: [],
    provinces: [],
    province: null,
    cities: [],
    city: null,
    districts: [],
    residentials: [],
    streets: [],

    // auto completar
    keepFirst: true,
    openOnFocus: true,
    cityName: '',
    streetName: '',
    clearable: true,
    isFetching: false
  }),


  created() {

    // por el momento, streets son solo para actividades padre
    this.activityId = this.$route.params.activity_id
    this.subactivityId = this.$route.params.subactivity_id || null
    this.isSubactivity = this.$route.name === 'subactivity.streets.edit' // falso, no existe
  },


  mounted() {
    // TODO get organizationareas, get provincias para form de añadir calle
    // NOTICE. Distritos solo seleccionable para la ciudad "Madrid", el resto tienen distrito null
    this.getActivityStreets()

    this.getConservationAreas()

    this.getProvinces()
  },

  computed: {
    filteredDataObj() {
      let self = this
      return this.streets.filter(option => {
        return (
            option.name
              .toString()
              .toLowerCase()
              .indexOf(self.streetName.toLowerCase()) >= 0
        )
      })
    },

    filteredCitiesObj() {
      let self = this
      return this.cities.filter(option => {
        return (
            option.name
              .toString()
              .toLowerCase()
              .indexOf(self.cityName.toLowerCase()) >= 0
        )
      })
    },
  },


  methods: {

    async getActivityStreets() {
      let self = this
      let id = this.isSubactivity ? this.subactivityId : this.activityId
      const { data } = await axios.get('/api/v2/activity/' + id + '/streets')

      if (data && data.success) {

        self.activityStreets = data.data.streets.filter((item) => {
          return item.pivot.in_redaction === 0
        });

        self.activityStreetsInRedaction = data.data.streets.filter((item) => {
          return item.pivot.in_redaction === 1
        });

      } else {
        this.$notify.error('error_loading_activity_data')
      }

      this.isLoading = false;
    },

    async getConservationAreas() {
      let self = this

      const { data } = await axios.get('/api/v2/conservation-areas')

      if (data && data.success) {
        self.conservationAreas = data.data
      } else {
        this.$notify.error('error_loading_activity_data')
      }
    },

    async getProvinces() {
      let self = this
      const { data } = await axios.get('/api/v2/provinces')
      if (data && data.success) {
        self.provinces = data.data
      } else {
        this.$notify.error('error_loading_provinces_data')
      }
    },

    async getCities() {
      let self = this
      let url = '/api/v2/province/' + self.form.province + '/cities'

      const { data } = await axios.get(url)
      if (data && data.success) {
        self.cities = data.data
      } else {
        this.$notify.error('error_loading_cities_data')
      }
    },

    loadDataCities() {
      this.getPlaces()
    },

    async getPlaces() {
      let self = this
      let url = '/api/v2/city/' + self.form.city + '/places'
      this.isFetching = true
      const { data } = await axios.get(url)
      if (data && data.success) {
        self.districts = data.data.districts
        self.residentials = data.data.residentials
        self.streets = data.data.streets
        this.isFetching = false
      } else {
        this.$notify.error('error_loading_districts_data')
        this.isFetching = false
      }
    },

    async associateStreet() {
      let id = this.isSubactivity ? this.subactivityId : this.activityId
      let url = '/api/v2/activity/' + id + '/connect-street' // add url

      try{
        const { data } = await axios.post(url, this.form)
        if (data && data.success) {

          this.$notify.success('success_editing')
          this.newForm = { }
          this.addStreet = false;
          this.getActivityStreets()

        } else if(data && data.msg){

          this.$notify.error('no_access_permissions')
          this.$router.push({ name: 'project.home', params: {} })

        } else {
          this.$notify.error('error_saving')
        }
      }
      catch(error){
        //Se produjo un error
        this.isLoading = false;
        if (error.response && error.response.status === 422) {
          //extraemos el primer error de validación
          error.error_msg =
            error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
              ][0];
        }

        // Mostramos el error
        this.$toast.open({
          message: error.error_msg || this.$t("assignment_update_error"),
          type: "is-danger",
          position: "is-top-right"
        });
      };
    },

    getEntityId() {
      return this.isSubactivity ? parseInt(this.subactivityId) : parseInt(this.activityId)
    },


    disableButton() {
      this.disableSaveButton = true
      let self = this
      setTimeout(function() {
        self.disableSaveButton = false
      }, 2000);
    },


    goToPage(destiny = 'extrafields') {

      switch (destiny) {

        case 'edit-activity':
            if (this.isSubactivity) {
              this.$router.push({ name: 'subactivity.edit', params: {
                subactivity_id: this.subactivityId, activity_id: this.activityId
              } })
            } else {
              this.$router.push({ name: 'activity.edit', params: {
                activity_id: this.activityId
              } })
            }
            break;

        case 'streets':
            break;
      }

    },

    async finishWriting() {
      let id = this.isSubactivity ? this.subactivityId : this.activityId
      let url = '/api/v2/activity/' + id + '/finishWriting' // add url

      const { data } = await axios.post(url, {})
      if (data && data.success) {

        this.$notify.success('success_editing')
        this.getActivityStreets()
        this.newForm = { }

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')
        this.$router.push({ name: 'project.home', params: {} })

      } else {
        this.$notify.error('error_saving')
      }
    },

    getDescription(row){
      if (row == null)
        return '-'
      return row.name
    },

    async removeStreet(item){
      let url = '/api/v2/activitystreet/' + item.pivot.id + '/delete'

      const { data } = await axios.post(url)
      if (data && data.success) {

        this.$notify.success('removed_successfully')
        this.getActivityStreets()
        this.newForm = { }

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')
        this.$router.push({ name: 'project.home', params: {} })

      } else {
        this.$notify.error('remove_error')
      }
    },
  },
};
</script>
