<template>
  <div>
    <div class="flex items-center mb-4">
      <v-button icon="arrow-left" @click="goToActivity()" color="grey">{{ $t('activity') }}</v-button>
      <div class="ml-auto">
        <v-button
          @click="() => { showAdd = !showAdd }"
          icon="plus"
        >{{ $t('assign_interventions') }}</v-button>
      </div>
    </div>
    <div>
      <div class="flex">
        <div :class="showAdd ? 'panel w-7/12 mr-2':'panel w-full'">

          <b-table
            show-empty
            :data="data"
            :loading="isLoading"
            paginated
            striped
            bordered
            icon-pack="fas"
          >
            <template v-slot:empty="">
              <h4>{{ $t('no_rows') }}</h4>
            </template>

            <template>
              <b-table-column field="code" >
                <template v-slot:header="">
                  {{ $t('code') }}
                </template>
                <template v-slot="props">
                  {{ props.row.intervention.code }}
                </template>
              </b-table-column>

              <b-table-column field="type" >
                <template v-slot:header="">
                  {{ $t('type') }}
                </template>
                <template v-slot="props">
                  {{ props.row.intervention.type }}
                </template>
              </b-table-column>

              <b-table-column field="value" >
                <template v-slot:header="">
                  {{ $t('value') }}
                </template>
                <template v-slot="props">
                  {{ props.row.intervention.value }}
                </template>
              </b-table-column>

              <b-table-column field="street" >
                <template v-slot:header="">
                  {{ $t('street') }}
                </template>
                <template v-slot="props">
                  {{ props.row.street }}
                </template>
              </b-table-column>

              <b-table-column field="actions"  centered width="80">
                <template v-slot:header="">
                  {{ $t('actions') }}
                </template>
                <template v-slot="props">

                  <div class="w-full h-full flex items-center justify-around">
                      <div>
                        <b-tooltip
                          :label="$t('remove')"
                          type="is-danger"
                          position="is-left"
                        >
                          <div @click="remove(props.row)" class="cursor-pointer flex text-red">
                            <b-icon pack="fas" icon="trash-alt"/>
                          </div>
                        </b-tooltip>
                      </div>

                  </div>
                </template>
              </b-table-column>
            </template>
          </b-table>

        </div>
        <div class="panel w-5/12" v-if="showAdd">
              <form @submit.prevent="create" class="w-full">
                <div>
                  <span class="font-bold text-md">{{$t('assign_interventions')}}</span>
                </div>
                <div class="w-full mt-2">
                  <label for="spec_type" class="w-full">{{ $t('select_intervention') }}</label>
                  <!--<b-select ref="input"
                    v-model="selectedIntervention"
                    :placeholder="$t('select_intervention')"
                    class="w-full"
                    required
                    expanded
                  >
                    <option v-for="option in interventions" :value="option.id" :key="option.id">
                      {{ option.code }}
                    </option>
                  </b-select> -->
                  <b-autocomplete
                    v-model="selectedInterventionCode"
                    :placeholder="$t('select_or_type_to_search')"
                    :keep-first="true"
                    open-on-focus
                    :data="interventions"
                    :loading="isLoading"
                    @input="handleInputSearch"
                    field="code"
                    clearable
                    @select="option => { if (option) selectedIntervention = option.id; else selectedIntervention = null; }"
                    >
                  </b-autocomplete>
                </div>

                <div class="w-full mt-2">
                  <label for="street" class="w-full">{{ $t('street') }}</label>
                  <v-input
                    v-model="street"
                    placeholder="street"
                    class="w-full"
                    required
                  />
                </div>

                <div class="w-full mt-2">
                  <v-button
                    native-type="submit"
                    :disabled="!selectedIntervention || !street"
                  >{{ $t('save') }}
                  </v-button>
                </div>
              </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue"
  import axios from 'axios'
  import { mapGetters } from "vuex";

  export default {
    middleware: 'auth',
    title: 'assignment_interventions',
    subtitle: 'activity_assignment_interventions_subtitle',
    menuOption: '2-2',

    components: {
    },

    data: () => ({
      data: [],
      interventions: [],
      activityId: null,
      isLoading: true,
      showAdd: false,
      selectedIntervention: null,
      selectedInterventionCode: null,
      street: null
    }),

    
    computed: {
      ...mapGetters({
        project: 'app/project',
      }),
    },

    created(){
      this.debouncedSearch = this.debounce(this.getInterventions, 750)
    },
    
    mounted() {
      this.activityId = this.$route.params.subactivity_id || this.$route.params.activity_id
      this.isSubactivity = !!this.$route.params.subactivity_id
      this.getData();
      this.getInterventions();
      this.isLoading = false;
    },

    methods: {
      async getData() {
        let {data} = await axios.get('/api/v2/activity-interventions', {params: {activity_id: this.activityId}});

        if (data.success) {
          this.data = data.data
        } else {
          this.$notify.error("error_loading_data");
        }
      },

      handleInputSearch() {
        if (this.selectedInterventionCode.length > 0){
          this.debouncedSearch()
        } else {
          this.getInterventions()
          
        }
      },

      // funcion que espera a que se deje de escribir para enviar la peticion
      debounce(func, wait) {
        let timeout;
        return function (...args) {
          const context = this;
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            func.apply(context, args);
          }, wait);
        };
      },

      async getInterventions() {
        let params = {
          p: this.project.id,
          code: this.selectedInterventionCode
        }
        let {data} = await axios.get('/api/v2/assignment-interventions', {params: params})

        if (data.success) {
          this.street = null
          this.interventions = data.data
        } else {
          this.$notify.error("error_loading_data");
        }
      },

      reload() {
        this.isLoading = true;
        this.getData();
        this.getInterventions();
        this.isLoading = false;
      },

      async create() {
        this.isLoading = true;
        let {data} = await axios.post('/api/v2/activity-intervention', {
          activity_id: this.activityId,
          assignment_intervention_id: this.selectedIntervention,
          street: this.street
        })
        if (data.success) {
          this.$notify.success('save_successful')
          this.reload();
        } else {
          this.$notify.error('error_saving')
        }
        this.isLoading = false;
      },

      async remove(item) {
        this.$dialog.confirm({
          title: this.$t("delete"),
          message: this.$t("delete_confirm_text", ['La actuación', item.intervention.code]),
          confirmText: this.$t("delete"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            this.isLoading = true;
            let url = '/api/v2/activity-intervention/'+item.id;
            const { data } = await axios.delete(url);

            if (data.success) {
              this.$toast.open({
                message: this.$t("removed_successfully"),
                type: "is-success",
                position: "is-top-right"
              });

              this.reload();
            } else {
              // Error
              this.$toast.open({
                message: this.$t("remove_error"),
                type: "is-danger",
                position: "is-top-right"
              });
            }
            this.isLoading = false;
          }
        });
      },

      goToActivity() {
        if (this.isSubactivity) {
          this.$router.push({ name: 'subactivity.home', params: {
            activity_id: this.activityId
          } })
        } else {
          this.$router.push({ name: 'activity.home', params: {
            activity_id: this.activityId
          } })
        }
      }
    }
  };
</script>
