<template>
  <div class="page-with-top-bar">
    <project-template-admin-quick-buttons hiddenOption="assignments"/>
    <section>
      <div class="mt-4">
        <b-tabs type="is-boxed" v-model="mainActiveTab">

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-alt"></b-icon>
              <span> {{ $t('measurements') }} </span>
            </template>
            <div>
              <template-manage-measurements :projectId="projectId"/>
            </div>
          </b-tab-item>

          <!-- <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-import"></b-icon>
              <span>{{ $t('import_assignments') }}</span>
            </template>
            <div>
              <import-entities
                :can-overwrite="true"
                :project-id="projectId"
                entity-type="assignment"
              >
              </import-entities>
            </div>
          </b-tab-item> -->

        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import ImportEntities from "../../../components/import-entities";
  import ProjectTemplateAdminQuickButtons from '../../../components/project-template-admin-quick-buttons'
  import TemplateManageMeasurements from '../../../components/templates/project/template-manage-measurements.vue'

  export default {
    middleware: "auth",
    title: "assignments_page_title",
    menuOption: "0-6",

    components: {
      ImportEntities,
      ProjectTemplateAdminQuickButtons,
      TemplateManageMeasurements,
    },

    computed: {
      ...mapGetters({
        project: "template/project",
      }),
      projectId() {
        return parseInt(this.$route.params.template_id);
      }
    },

    data: () => ({
      mainActiveTab: 0
    }),

    watch: {
      mainActiveTab(value) {
        if (value == 0) {
          // no aplicable
          //this.$refs.project_assignments_manual.reload();
        }
      }
    },

    created() {
      let projectName = this.project.name
        ? this.project.name.toUpperCase()
        : "";
      this.$options.subtitle = ["project_actions_page_subtitle", [projectName]];
    },

    methods: {
      measurementUnitsImportSuccess() {
        this.$refs.project_measurements_manual.reload();
      },

      elementsImportSuccess() {
        this.$refs.project_elements_manual.reload();
      }
    }
  };
</script>
