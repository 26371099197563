  <template>
  <div>
    <div class="top-bar flex justify-end">
      <v-button
        @click="() => { showFormImportEntities = true }"
        icon="file-import"
        class="ml-4"
        :disabled="showFormData"
      >{{ $t('import_materials') }}</v-button>

      <v-button
        @click="openMaterialModal(null)"
        icon="plus"
        class="ml-4"
        :disabled="showFormImportEntities"
      >{{ $t('add_material') }}</v-button>

      <material-edit
        ref="material_modal"
        :projectId="projectId"
        :extrafields="extrafields"
        @reload-materials="handleSuccess"
      />

    </div>

    <div>
      <materials-filters ref="filtersRef"
        @filters-updated="filtersUpdated"
      />
    </div>

    <div class="flex">
      <div :class="['w-full', 'mr-2']">
        <materials ref="materials_list"
          :projectId="projectId"
          @epc-edit="handleEditEntity"
          :emitParentList="true"
        />
      </div>

      <transition name="fade">
        <!-- <div class="w-5/12 ml-2" v-if="showFormData">
          <material-modal
            :companyId="company.id"
            :provider="provider"
            @epc-close="handleOnCloseEdit"
            @epc-success-creating="handleSuccess"
          />
        </div> -->

        <div class="w-5/12 ml-2" v-if="showFormImportEntities">
          <materials-import
            :projectId="projectId"
            :entity-type="'store-products'"
            @epc-close="(event) => { showFormImportEntities = false }"
            @epc-success-import="() => { showFormImportEntities = false ; $refs.materials_list.reload(); }"
          />
        </div>

      </transition>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import {mapGetters} from "vuex";
import Materials from './materials.vue';
import MaterialEdit from './material-modal.vue';
// import MaterialForm from './material-form.vue';
import MaterialsImport from './materials-import.vue';
import MaterialsFilters from './materials-filters.vue';

export default {
  name: "manage-materials",

  components: {
    Materials,
    MaterialEdit,
    MaterialsImport,
    // MaterialForm,
    MaterialsFilters,
  },

  data: () => ({
    material: null, // objeto que se crea
    showFormData: false,
    showFormImportEntities: false,
    showFormLink: false,

    extrafields: [],
  }),

  props: {
    projectId: { type: Number, required: true }
  },

  computed: {
    ...mapGetters({
      company: "app/company",
    }),
  },

  created() {
    this.getExtrafields()
  },

  mounted() {
  },

  methods: {
    async getExtrafields() {
      let self = this
      let id = self.projectId

      this.isLoading = true
      const { data } = await axios.get('/api/v2/project/' + this.projectId + '/store-extrafields')
      this.isLoading = false

      if (data && data.success) {
        this.extrafields = data.extrafields;
      } else {
        this.$notify.error('error_loading_activity_data')
      }
    },

    addProvider() {
      this.material = null
      this.showFormData = true;
    },


    handleOnCloseEdit(event) {
      this.showFormData = !event
    },

    handleOnCloseLinkGroup(event) {
      this.showFormLink = !event
    },

    handleSuccess() { // recibe la entidad editada
      this.$refs.materials_list.reload()
    },

    handleEditEntity(entity) {
      this.openMaterialModal(entity)
    },

    handleEditChapter(material) {
      this.material = material
      this.showFormData = true;
    },

    // Botones de acción
    openMaterialModal(material) {
      if(material == null)
      {
        material = {
          id: 0,
          unit_id: null,
          code: "",
          presto_code: "",
          name: "",
          description: "",
          purpose: "",
        };
      }
      this.$refs.material_modal.show(material);

      // this.$buefy.modal.open({
      //   parent: this,
      //   component: MaterialForm,
      //   props: {
      //     projectId: this.projectId,
      //     extrafields: this.extrafields,
      //     product: material
      //   }
      // })
    },

    filtersUpdated() {
      this.applyFilters(1);
    },

    applyFilters(page) {
      let generatedFilters = this.customizeFilters();
      generatedFilters.page = 1;

      this.$refs.materials_list.reload(generatedFilters)
    },

    customizeFilters() {
      let filters = this.$refs.filtersRef.generateFilters();
      return filters;
    },
  }

};
</script>

<style scoped>

</style>
