<template>
  <div class="flex items-center justify-center px-2 w-25">
    <div class="flex flex-row items-center text-grey-light mt-1">

      <div>
        <b-tooltip :label="$t('mentions')" position="is-top">
          <b-icon icon="at" pack="fas" size="is-small" class="mr-1" v-bind:class="[current_mentions > 0 ? 'text-blue' : '']"/>
        </b-tooltip>
        <div class="-mt-2 text-center" v-bind:class="[current_mentions > 0 ? 'text-blue' : '']">{{ current_mentions }}</div>
      </div>

      <div v-if="enabledChangedStatus">
        <b-tooltip :label="$t('status_changes')" position="is-top">
          <b-icon icon="exchange-alt" pack="fas" size="is-small" class="mr-1" v-bind:class="[current_change_status  > 0 ? 'text-blue' : '']"/>
        </b-tooltip>
        <div class="-mt-2 text-center" v-bind:class="[current_change_status > 0 ? 'text-blue' : '']">{{ current_change_status }}</div>
      </div>

      <div>
        <b-tooltip :label="$t('comments')" position="is-top">
          <b-icon icon="envelope" pack="fas" size="is-small" class="mr-1" v-bind:class="[current_comments  > 0 ? 'text-blue' : '']"/>
        </b-tooltip>
        <div class="-mt-2 text-center" v-bind:class="[current_comments > 0 ? 'text-blue' : '']">{{ current_comments }}</div>
      </div>

      <div>
        <b-tooltip :label="$t('documents')" position="is-top">
          <b-icon icon="paperclip" pack="fas" size="is-small" class="mr-1" v-bind:class="[current_documents > 0 ? 'text-blue' : '']" />
        </b-tooltip>
        <div class="-mt-2 text-center" v-bind:class="[current_documents > 0 ? 'text-blue' : '']">{{ current_documents }}</div>
      </div>

      <div>
        <b-tooltip :label="$t('assignments_warnings')" position="is-top">
          <b-icon icon="tasks" pack="fas" size="is-small" class="mr-1" v-bind:class="[assignments_warnings > 0 ? 'text-blue' : '']" />
        </b-tooltip>
        <div class="-mt-2 text-center" v-bind:class="[assignments_warnings > 0 ? 'text-blue' : '']">{{ assignments_warnings }}</div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import EventBus from '~/plugins/bus'

export default {
  name: 'entity-notifications',

  props: {
    entityId: { type: Number, default: 0 },
    entityType: { type: String, required: true },
    mentions: { type: Number, default: 0 },
    change_status: { type: Number, default: 0 },
    comments: { type: Number, default: 0 },
    documents: { type: Number, default: 0 },
    enabledChangedStatus: { type: Boolean, default: false },
    assignments_warnings: { type: Number, default: 0 },
  },

  data: () => ({
    current_mentions: 0,
    current_change_status: 0,
    current_comments: 0,
    current_documents: 0,
    current_assignments_warnings: 0,
  }),

  created(){
    let self = this;

    this.current_mentions = this.mentions;
    this.current_change_status = this.change_status;
    this.current_comments = this.comments;
    this.current_documents = this.documents;
    this.current_assignments_warnings = this.assignments_warnings;

    // Si recibimos el evento refresh, refrescamos el componente
    EventBus.$on('refreshNotifications', function(e){
      self.loadEntityData(self.entityId);
    });
  },
  beforeDestroy(){
    EventBus.$off("refreshNotifications");
  },
  watch: {
    // Si cambia la entidad reseteamos los valores a los adecuados
    entityId(value) {
      this.restart()
    },
  },

  methods: {

   async loadEntityData(entityId) {
      const { data } = await axios.get('/api/v2/'+ this.entityType +'/' + entityId + '?h=1&nots=1')
      if (data && data.success) {

        this.current_mentions = data.data.notifications.mentions ? data.data.notifications.mentions : this.current_mentions;
        this.current_change_status = data.data.notifications.change_status ? data.data.notifications.change_status : this.current_change_status;
        this.current_comments = data.data.notifications.comments ? data.data.notifications.comments : this.current_comments;
        this.current_documents = data.data.notifications.documents ? data.data.notifications.documents : this.current_documents;
      }
    },

    restart() {
      this.current_mentions = this.mentions;
      this.current_change_status = this.change_status;
      this.current_comments = this.comments;
      this.current_documents = this.documents;
    }
  },

};
</script>
