<template>
    <div class="relative w-full">
          <select ref="simpleSelect"
            @change="onChange($event.target.value)"
            :name="selectName"
            :id="selectId"
            v-if="selectName"
            class="rounded w-full h-8 form-control simple-select">
              <option v-for="option in data" 
                :value="option.id" 
                :selected="selectedValue == option.id ? true : false"
                >{{ $t(option.name) }}</option>
          </select>
    </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'v-select',

  components: {

  },

  props: {
    data: { type: Array, required: true },
    selectName: { type: String, required: true },
    selectId: { type: String, required: true },
    selectedValue: { type: Number, required: false, default: 1},
  },

  data: () => ({
  }),

  methods: {
    onChange(id){
      this.$emit("updateValue",id)
    }

  },
  mounted(){
  }

}
</script>
