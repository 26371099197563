<template>
<div>
  <div class="panel p-2">
    <div class="flex items-center justify-between w-full mb-5">
      <div><label><b>{{ $t('import_work_orders') }}</b></label></div>
    </div>

    <div class="w-full pb-3">
      <import-entities
        :can-overwrite="false"
        :project-id="projectId"
        :withHistory="false"
        :entity-type="entityType"
        @success="$emit('epc-success-import')"
      />
    </div>
  </div>
</div>
</template>

<script>
import importEntities from '../../components/import-entities'

export default {
  name: "work-orders-import",

  components: {
    importEntities
  },

  data: () => ({

  }),

  props: {
    projectId: { type: Number, required: true },
    entityType: { type: String, default: 'work-orders' },
  },

  watch: {
  },

  computed: {

  },

  mounted() {
  },

  methods: {
    onCloseClicked() {
      this.$emit('epc-close', true)
    },

  },
};
</script>

