<template>
    <div>
        <vue-tags-input
          v-model="tag"
          :tags="tags"
          :placeholder="$t('add_tag')"
          :allow-edit-tags="allowEdit"
          :add-only-from-autocomplete="addFromList"
          :autocomplete-items="filteredParentTags"
          :autocomplete-always-open="autocompleteOpen"
          :autocomplete-min-length="parseInt(0)"
          :disabled="disabled"
          @focus="autocompleteOpen = filteredParentTags.length > 0"
          @blur="autocompleteOpen = false"
          @tags-changed="changedTags"
          @before-adding-tag="addTag"
          @before-deleting-tag="removeTag"
        />
    </div>
</template>

<script>
  import VueTagsInput from '@johmun/vue-tags-input';
  import axios from 'axios'
  import { mapGetters } from 'vuex'

  export default {
    name: "model-tags",

    components: {
      VueTagsInput,
    },

    props: {
      modelId: {type: Number, required: true},
      modelType: {type: String, required: true},
      allowEdit: {type: Boolean, default: false},
      addFromList: {type: Boolean, default: false},
      parentModelId: {type: Number, required: false},
      parentModelType: {type: String, required: false},
      hooksEnabled: {type: Boolean, default: false},
      disabled: {type: Boolean, default: false},
      initialTags: {type: Array, function () { return [] }},
      initialParentTags: {type: Array, function () { return [] }} // when defined, do NOT load parent tags
    },

    data: () => ({
      tag: '',
      tags: [],
      parentTags: [],
      autocompleteOpen: false,
    }),

    computed: {
      filteredParentTags: function () {
        return this.parentTags.filter(i => {
          return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
        })
      },
      ...mapGetters({
        storeProject: 'app/project',
        storeTags: 'app/tags',
      }),
    },

    created() {

      // Si procede, cargamos la lista de etiquetas del proyecto, si no se proporcionan unas initialParentTags
      if (this.initialParentTags === undefined
        && this.parentModelId && this.parentModelId === this.storeProject.id) {
        this.parentTags = this.storeTags.map(function(tag) {
          return {text: tag.name}
        })
      }

      // Inicializamos las tags
      this.initTags(this.initialTags)
    },

    mounted() {
      // solo cuando initialparentTags no sea vacio
      if( this.initialParentTags && this.initialParentTags.length )
      // solo cuando no se id de projecto = 0 y un initialparentTags no sea vacio
      // if( !this.parentModelId && this.initialParentTags && this.initialParentTags.length )
        this.parentTags = this.initialParentTags.map(function(tag) {
          return {text: tag.name}
        })
    },

    methods: {

      // Realiza una petición a la api para almacenar una tag
      addTag(obj) {
        if (! this.isDuplicate(this.tags, this.tag.trim())) {
          // Lanzamos la petición a la api correspondiente y actuamos según la respuesta
          if (this.hooksEnabled) {
            let self = this
            axios.post("/api/v2/" + this.modelType + "/" + this.modelId + "/tags/add", {tag: obj.tag})
              .then(function (response) {
                if (response.data.success) {
                  obj.addTag()

                  // En caso de estar modificando las tags del proyecto actual, almacenar el cambio en el store
                  if ((self.modelType === 'project' || self.modelType === 'project/templates') && self.storeProject && self.storeProject.id == self.modelId) {
                    self.updateStoreTags(self.modelType === 'project/templates' ? 'template': 'app', response.data.tags)
                  }

                  self.$notify.success('added_tag')
                } else {
                  throw 'error_adding_tags';
                }
              })
              .catch(function (error) {
                self.$notify.error('error_adding_tags')
              })

          } else {
            obj.addTag()
          }
        }
      },

      // Realiza una petición a la api para eliminar una tag
      removeTag(obj) {
        // Lanzamos la petición a la api correspondiente y actuamos según la respuesta
        if (this.hooksEnabled) {
          let self = this
          axios.post("/api/v2/"+this.modelType+"/" + this.modelId + "/tags/remove", { tag: obj.tag })
            .then(function(response) {
              if (response.data.success) {
                obj.deleteTag()

                // En caso de estar modificando las tags del proyecto actual, almacenar el cambio en el store
                if ((self.modelType === 'project' || self.modelType === 'project/templates') && self.storeProject && self.storeProject.id == self.modelId) {
                  self.updateStoreTags(self.modelType === 'project/templates' ? 'template': 'app', response.data.tags)
                }

                self.$notify.success('removed_tag')
              } else {
                throw 'error_removing_tags';
              }
            })
            .catch(function (error) {
              self.$notify.error('error_removing_tags')
            })

        } else {
          obj.deleteTag()
        }
      },

      isDuplicate(tags, tag) {
        return tags.map(t => t.text).indexOf(tag) > -1;
      },

      // Sincroniza todas las tags con el modelo
      syncTagsToModel(id) {
        let self = this
        axios.post("/api/v2/"+this.modelType+"/" + id + "/tags/sync", { tags: this.tags })
          .catch(function (error) {
            self.$notify.error('error_adding_tags')
          })
      },

      // Devuelve un json con el texto de las tags
      getJsonTags() {
        return JSON.stringify(this.tags.map(function(tag) {
          return tag.text
        }))
      },

      // Inicializamos las tags
      initTags(initialTags) {
        if (initialTags && initialTags.length > 0) {
          this.tags = initialTags.map(function(tag) {
            return {text: tag.name, id: tag.id}
          })
        }
      },

      // Actualizamos el store de las tags
      async updateStoreTags(module, tags) {
        await this.$store.dispatch( module + "/setTags", { tags })
      },

      // async updateStoreTags(tags) {
      //   await this.$store.dispatch("app/setTags", { tags })
      // },

      changedTags(newTags) { // se modifico por si algun componente padre necesita recibir los tags seleccionados
        this.tags = newTags
        this.$emit('epc-tags-changed', newTags)
      },
    },

  }
</script>

<style scoped>

    .vue-tags-input {
        max-width: 100% !important;
    }

    /*li.ti-tag {*/
    /*background: blue;*/
    /*color: green !important;*/
    /*}*/

    /*li.ti-tag {*/
        /*background: blue;*/
        /*color: green !important;*/
    /*}*/

    /*.vue-tags-input .ti-valid {*/
        /*background: blue;*/
        /*color: green !important;*/
    /*}*/

    /*.vue-tags-input .ti-input {*/
        /*padding: 4px 10px;*/
        /*transition: border-bottom 200ms ease;*/
        /*color: green;*/
    /*}*/
</style>
