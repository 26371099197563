var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-group',{staticClass:"list-reset",staticStyle:{"min-height":"3rem"},attrs:{"name":"custom-classes-transition","enter-active-class":_vm.show ? 'animated zoomIn' : null,"leave-active-class":_vm.show ? 'animated zoomOut' : null,"tag":"ul"}},_vm._l((_vm.usersList),function(user,index){return _c('li',{key:user.user_id,staticClass:"float-left pb-2",class:{
      'w-full': _vm.boxFullWidth,
      'w-full lg:w-1/2': !_vm.boxFullWidth,
      'lg:pr-1': !_vm.boxFullWidth && (index % 2 === 0),
      'lg:pl-1': !_vm.boxFullWidth && (index % 2 !== 0),
      }},[_c('div',{staticClass:"w-full border border-blue-lighter rounded flex items-center px-2",class:[
        (_vm.authUser.id === user.user_id) ? 'bg-blue-lightest' : 'bg-grey-lightest',
        _vm.isSuperiorHierarchyUser(user.user_id) ? 'sup-user' : ''
      ],staticStyle:{"height":"38px","line-height":"33px"}},[_c('img',{staticClass:"rounded-full overflow-hidden",staticStyle:{"width":"28px","height":"28px"},attrs:{"src":user.avatar}}),_vm._v(" "),_c('span',{staticClass:"flex-grow text-xs font-semibold text-blue-light truncate px-2"},[_vm._v("\n        "+_vm._s(user.fullname)+"\n      ")]),_vm._v(" "),_c('user-replacement',{directives:[{name:"show",rawName:"v-show",value:(! _vm.disabled && _vm.canReplace),expression:"! disabled && canReplace"}],attrs:{"userListIndex":index},on:{"replaceButtonClicked":function($event){return _vm.onClickReplaceButton($event)}}}),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRemoveButton(index) && ! _vm.isSuperiorHierarchyUser(user.user_id)),expression:"showRemoveButton(index) && ! isSuperiorHierarchyUser(user.user_id)"}],staticClass:"flex items-center cursor-pointer",on:{"click":function($event){return _vm.onClickRemoveButton(index)},"mouseover":function($event){_vm.iconRemoveHovered = index},"mouseleave":function($event){_vm.iconRemoveHovered = null}}},[_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isHoverRemoveIcon(index)),expression:"!isHoverRemoveIcon(index)"}],staticClass:"text-grey float w-full h-full",attrs:{"pack":"far","icon":"trash-alt","size":"is-small"}}),_vm._v(" "),_c('b-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.isHoverRemoveIcon(index)),expression:"isHoverRemoveIcon(index)"}],staticClass:"text-grey float w-full h-full",attrs:{"pack":"fas","icon":"trash-alt","size":"is-small"}})],1)],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }