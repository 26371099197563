import { Text } from 'pixi.js';

export class PicLabel extends Text {

  constructor(el, offset, index) {
    super();

    if (el.width === undefined) {
      el.width = 0
      el.height = 0

      el.y += 50
    }

    this.structureVertical = el.width < el.height

    this.picId = 'text_' + el.id;
    this.picText = this._GetNameEntity(el, index);
    this.picX = Math.abs( el.x + offset.x );
    this.picY = Math.abs( el.y - offset.y ) + (this.structureVertical ? el.height : 0);

    this.picWidth = this.structureVertical ? el.height : el.width;
    this.picHeight = this.structureVertical ? el.width : el.height;
    this.picFontSize = el.radius ? 15 : 2.5;

    this.picAlign = 'center',
    this.picVerticalAlign = 'middle',
    this.picRotation = this.structureVertical ? - Math.PI / 2 : 0

    this.init()
  }

  init() {
    this.text = this.picText;
    this.anchor = 0.5;
    this.resolution = (window.devicePixelRatio || 1) * 10;

    if (this.structureVertical) {
      this.x = this.picX + this.picHeight / 2;
      this.y = this.picY - this.picWidth / 2;
    } else {
      this.x = this.picX + this.picWidth / 2;
      this.y = this.picY + this.picHeight / 2;
    }


    // this.width = this.picWidth;
    // this.height = this.picHeight;
    this.rotation = this.picRotation

    this.style = {
      fontSize: this.picFontSize,
      fontweight: 'bold',
      fill: 0x000000,
      align: this.picAlign,
      textBaseline: this.picVerticalAlign,
      // padding: 5,
      wordWrap: true,
      wordWrapWidth: this.picWidth
    };
  }

  setText(text) {
    this.text = text;
  }

  setX(x) {
    this.x = x;
  }

  _GetNameEntity(entity, index) {
    if ( entity.name != null )
      return entity.name

    return 'E.' + entity.type.charAt(0).toUpperCase() + '.' + (index + 1)

  }
}
