<template>
  <div>

    <div class="panel">
      <div class="flex">

        <!-- Nombre -->
        <div class="w-1/3 pr-4">
          <l-form-input ref="name" :form="form" field="name" label="name" required="true"/>
        </div>

        <!-- Alias -->
        <div class="w-1/3 pr-4">
          <label for="alias" class="label">{{$t('alias')}} *
            <b-tooltip :label="'Todo minuscula, sin tildes ni espacios (para bucket S3 etc...)'" position="is-right" :multilined="false">
              <b-icon icon="info" pack="fas" size="is-small" class="ml-1 float-right"/>
            </b-tooltip>
          </label>
          <l-form-input :form="form" field="alias"  required="true"
            placeholder="alias" :maxlength="20" />


        </div>

        <!-- Campo json configuraciones propias del entorno -->
        <div class="w-1/3">
          <!-- TODO transformarlo en un componente vue-json-editor, o en pares key: value ya que esta casteado a array-->
          <label for="configs" class="label">{{$t('configurations')}}
            <b-tooltip :label="'Insertar un texto en json (o nada)'" position="is-right" :multilined="false">
              <b-icon icon="info" pack="fas" size="is-small" class="mr-1 float-right"/>
            </b-tooltip>
          </label>
          <!-- <l-form-input :form="form" field="configs" /> -->
          <div>
            <vue-json-editor v-model="form.configs" :show-btns="false" :mode="'code'"></vue-json-editor>
          </div>
        </div>
      </div>

      <!-- Imagen de la compania -->
      <div class="w-2/3 pr-4">
        <b-field class="file is-primary" :class="{'has-name': !!this.image}" v-if="!this.createNew" >
          <div class="w-1/2 pr-4">
            <label class="label">{{$t('company_image')}}</label>
            <img :src="this.image" class="w-full" v-if="this.image" style="box-shadow: 1px 1px 3px #d5d5d5">
            <div v-else><scale-loader></scale-loader></div>
          </div>
          <div class="w-1/2 pr-4">
            <label class="label">{{$t('change_image')}}</label>
            <b-upload  class="file-label" @input="uploadImage" accept=".jpg">
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload" pack="fas"></b-icon>
                <span class="file-label">{{$t('upload_image')}}</span>
              </span>
            </b-upload>
          </div>
        </b-field>
      </div>


      <label class="label mt-4">Usuarios externos invitados a esta empresa</label>

      <b-table
        :data="form.invitations"
        :loading="isLoading"
        icon-pack="fas"

        class="w-full my-4"
        striped
        bordered
      >

        <template slot="empty">
          <div v-if="! isLoading" class="m-4 text-lg text-dark-grey">
            <span>{{ $t('no_results') }}</span>
          </div>
        </template>

        <template>

          <b-table-column field="name" cell-class="vertical-center" centered sortable>
            <template v-slot:header="">
              {{ $t('name') }}
            </template>
            <template v-slot="props">
              {{ props.row.user.name }} {{ props.row.user.surname }}
            </template>
          </b-table-column>

          <b-table-column field="name" cell-class="vertical-center" centered sortable>
            <template v-slot:header="">
              {{ $t('email') }}
            </template>
            <template v-slot="props">
              {{ props.row.user.email }}
            </template>
          </b-table-column>

          <b-table-column field="name" cell-class="vertical-center" centered sortable>
            <template v-slot:header="">
              {{ $t('company_name') }}
            </template>
            <template v-slot="props">
              {{ props.row.user.area.company.name }}
            </template>
          </b-table-column>

          <b-table-column field="name" cell-class="vertical-center" centered sortable>
            <template v-slot:header="">
              {{ $t('accepted') }}
            </template>
            <template v-slot="props">
              <b-icon v-if="props.row.accepted" icon="check-circle" pack="fas" size="is-small" class="text-green"/>
              <b-icon v-else icon="times-circle" pack="fas" size="is-small" class="text-red"/>
            </template>
          </b-table-column>

          <b-table-column field="name" cell-class="vertical-center" centered sortable>
            <template v-slot:header="">
              {{ $t('actions') }}
            </template>
            <template v-slot="props">

              <b-tooltip :label="$t('unlink')" position="is-left">
                  <div @click="uninviteUser(props.row.user_id, props.row.user.name + ' ' + props.row.user.surname)" class="">
                    <b-icon icon="trash-alt" pack="fas" size="is-small" class="text-red"/>
                  </div>
              </b-tooltip>

            </template>
          </b-table-column>

        </template>

      </b-table>

      <!-- recuadro invitar usuario -->
      <div class="w-full border border-blue-lighter rounded items-center p-2 bg-blue-lightest mb-1">
        <div class="flex flex-row w-full">
          <div class="flex flex-col p-1">
            <label class="label p-2">Invitar usuario</label>
          </div>
          <div class="w-2/5 flex flex-col p-1">
            <input v-model="emailToSearch" type="text" :placeholder="$t('email')" class="input">
          </div>
          <div class="flex flex-col p-1">
            <button class="btn btn-green items-center h-full"  type="button" @click="searchEmail()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>

        <div class="flex-col my-4">
          <div v-for="user in foundUsers" :key="'user_'+user.id" class="">
            <b-tooltip :label="$t('invite')" position="is-left">
              <div @click="inviteUser(user)" class="flex cursor-pointer text-blue">
                <b-icon pack="fas" icon="sign-in-alt"/>
              </div>
            </b-tooltip>
            {{ user.name }} <i>&lt;{{ user.email }}&gt;</i> - <b>{{ user.company }}</b>
          </div>
          <div v-if="zeroResults" class="text-red">No results</div>
        </div>
      </div>



      <div v-if="createNew">
        * Se creara automáticamente: <br>
        - Area raiz <b>"Corporativo"</b><br>
        - Usuario administrador <b>admin.{{ form.alias }}@epc-tracker.com</b> (pass: <i>admin</i>)<br><br>
        <label class="text-xs">Tras crearlo, podrás editarlo y subir un logo <i>company_logo.jpg</i></label>
      </div>
      <div v-else>
        * Si editas alias, mueve todo el bucket <i>epc-tracker-storage/viejoalias</i> <b>a</b> <i>epc-tracker-storage/nuevoalias</i>
      </div>
    </div>

    <form
      @submit.prevent="sendCompanyData"
      @keydown="form.onKeydown($event)"
      class="w-full flex items-center justify-end mb-8"
    >
      <v-button type="submit" icon="thumbs-up" icon-pack="far" class="mr-4">{{$t('save')}}</v-button>
      <v-button color="grey" icon="undo-alt" @click="$router.go(-1)">{{$t('cancel')}}</v-button>
    </form>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import axios from "axios";
  import Form from "vform";
  import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
  import vueJsonEditor from 'vue-json-editor';

  export default {
    middleware: "auth",
    title: "company_edit_page_title",
    subtitle: "company_edit_page_subtitle",
    menuOption: "0-2",

    components: {
      ScaleLoader,
      vueJsonEditor
    },

    data: () => ({
      isLoading: true,
      companyId: null,
      createNew: false,
      image: '',
      form: new Form({
        name: '',
        alias: '',
        configs: null,
        invitations: [],
      }),
      apiUrl: "/api/v2/company",
      emailToSearch: '',
      foundUsers: [],
      zeroResults: false,
    }),

    computed: {
      ...mapGetters({
        user: "auth/userBasicInfo",
        navData: "app/navData",
        project: "app/project"
      }),

    },

    created() {
      // Crear nuevo proyecto
      if (this.$route.name === "company.create") {
        this.createNew = true;
        // this.$options.title = "company_create_page_title";
      } else {
        this.companyId = parseInt(this.$route.params.company_id);
      }
    },

    mounted() {
      if (this.companyId) { // editando
        this.getCompanyData();
      }
      else // creando nuevo
        this.isLoading = false;
    },

    methods: {
      async sendCompanyData() {

        var url = this.apiUrl;
        if (!this.createNew) { // is edit
          url += "/" + this.companyId;
        }

        // tratar el borrado total de configs, pasando un json vacio "{}" que será transformado a null
        if (this.form.configs != null
          && 
          Object.keys(this.form.configs).length === 0 && this.form.configs.constructor === Object)
          this.form.configs = null;

        if (this.form.configs == '')
          this.form.configs = null;

        // Enviamos el form
        const {data} = await this.form.post(url);

        if (data && data.success) {
          this.companyId = this.companyId ? this.companyId : data.company_id;

          // Success y nos vamos
          this.$notify.success('success_editing');
          this.$router.push({name: 'panel.companies'})
        } else {
          this.$notify.error('error_saving');
        }
      },

      async getCompanyData() {
        let params = { invitations: 1};
        const {data} = await axios.get("/api/v2/company/" + this.companyId,{params: params});
        if (data) {
          this.form.name = data.name;
          this.form.alias = data.alias;
          this.form.configs = data.configs;
          this.form.invitations = data.invitations;
          this.image = data.image;
        } else {
          this.$notify.error("error_retrieving_data");
        }

        this.isLoading = false;
      },

      async uploadImage(file) {
        let url = "/api/v2/company/" + this.companyId + "/image";

        let self = this
        self.image=''; // reset image, show 'uploading...'

        const formData = new FormData();
        formData.append('image', file);

        await axios.post(url, formData)
          .then(function (response) {
            if (response.data.success && response.data.image) {
                self.image = response.data.image;
            }
            else
              console.log('Error uploading image',response);
          })
      },

      async searchEmail() {

        let params = { email: this.emailToSearch};

        const { data } = await axios.get("/api/v2/users/search",{params: params});

        if (data && data.success) {
          this.foundUsers = data.data;
          this.zeroResults = this.foundUsers.length == 0;
        } else {
          this.$notify.error("error_retrieving_data");
        }
      },


      async inviteUser(user) {
        let url = "/api/v2/company/" + this.companyId + "/inviteuser"
        const {data} = await axios.post(url, { user_id: user.id, accepted: true });

        if (data && data.success) {

          this.getCompanyData();
          this.$notify.success('success_editing');

        } else {
          this.$notify.error( data.data || 'error_saving');
        }
      },


      async uninviteUser(userId, userName) {

        this.$dialog.confirm({
          title: this.$t('delete'),
          message: this.$t('delete_generic', [userName]),
          confirmText: this.$t('delete'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {

            let url = "/api/v2/company/" + this.companyId + "/uninviteuser"
            const { data } = await axios.post(url, { user_id: userId })
            if (data && data.success) {

              // reload
              this.getCompanyData()
              this.$notify.success('success_deleting_generic')

            } else if(data && data.msg){

              this.$notify.error('no_access_permissions')

            } else {
              this.$notify.error('error_saving')
            }
          }
        })
      },

    }
  };
</script>
