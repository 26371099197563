<template>
  <div class="relative">
    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>

    <div class="panel">
      <div class="flex">
        <!-- Nombre -->
        <div class="w-1/3 pr-4">
          <l-form-input
            ref="projectName"
            :form="form"
            field="name"
            label="project_name"
            placeholder="project_name_placeholder"
          />
        </div>
        <!-- Referencia -->
        <div class="w-1/3 pr-4">
          <l-form-input
            :form="form"
            field="reference"
            label="project_reference"
            placeholder="project_reference_placeholder"
            :maxlength="45"
          />
        </div>
        <!-- Área al que pertenece el proyecto -->
        <div class="w-1/3">
          <l-form-select
            :form="form"
            field="area_id"
            :options="areas"
            label="area"
            icon="sitemap"
            full
            @input="addManagers"
          />
        </div>
      </div>
      <!-- Fechas -->
      <div class="flex mt-4">
        <div class="w-1/3 mr-4">
          <l-form-double-date
            :form="form"
            startField="raw_scheduled_start_date"
            endField="raw_scheduled_end_date"
            label="scheduled_date"
          />
        </div>
        <div class="w-1/3 mr-4">
          <l-form-double-date
            :form="form"
            startField="raw_current_start_date"
            endField="raw_current_end_date"
            label="estimated_date"
            :disabled="this.createNew"
          />
        </div>
        <div class="w-1/3">
          <l-form-double-date
            :form="form"
            startField="raw_real_start_date"
            endField="raw_real_end_date"
            label="real_date"
            :disabled="this.createNew"
          />
        </div>
      </div>
      <!-- Descripción -->
      <div class="flex items-center mt-4">
        <l-form-input
          :form="form"
          field="description"
          label="project_description"
          input-type="textarea"
          :maxlength="2000"
          class="w-full"
        />
      </div>
      <div class="flex">
        <!-- Imagen del proyecto -->
        <div class="w-2/3 pr-4" v-if="!this.createNew">
          <b-field class="file is-primary" :class="{'has-name': !!this.image}">
            <div class="w-1/2 pr-4">
              <label class="label">{{$t('project_image')}}</label>
              <img :src="this.image" class="w-full" v-if="this.image" style="box-shadow: 1px 1px 3px #d5d5d5">
              <div v-else><scale-loader></scale-loader></div>
            </div>
            <div class="w-1/2 pr-4">
              <label class="label">{{$t('change_image')}}</label>
              <b-upload  class="file-label" @input="uploadImage">
                <span class="file-cta">
                  <b-icon class="file-icon" icon="upload" pack="fas"></b-icon>
                  <span class="file-label">{{$t('upload_image')}}</span>
                </span>
              </b-upload>
            </div>
          </b-field>
        </div>

        <div class="w-full">
          <div class="w-full flex flex-wrap mb-4">
            <div class="flex-grow">
              <label for="address_id" class="w-full">{{ $t('project_location') }}</label>
              <v-input
                v-model="place.address"
                title="address_title"
                id="address_id"
                name="address_name"
                placeholder="address_placeholder"
                class="w-full"
              />
            </div>

            <div class="flex-grow-0 pr-3 inline-flex self-end">
              <div @click="changeNewPlace" class="btn"><b-icon icon="search" type="is-primary" pack="fas"/></div>
            </div>
          </div>

          <googlemaps ref="locationMap"
            name="project-location"
            :datamaps="datamaps"
            :draggable="draggable"
            @updPlace="updPlace"
            :newPlace="newPlace"
            inputAutocomplete="address_id" />

        </div>
      </div>
      <!-- Mapa de Regiones DEPRECATED -->
      <!-- <div class="mb-8" v-if="!isLoading">
        <label class="label">{{$t('regions')}}</label>
        <g-map-markers-loader ref="regionsMap" :mapConfig="mapConfig" :points="points"/>
      </div> -->


      <!-- Gestión de almacenes -->
      <div class="flex mt-4">
        <div class="w-1/3">
          <b-field :label="$t('project_has_warehouse_title')">
            <b-switch
              v-model="form.hasWarehouse"
              :disabled="true"
            >{{ $t(form.hasWarehouse ? 'yes' : 'no') }}
            </b-switch>
          </b-field>
        </div>
        <!-- Presupuesto -->
        <div class="w-1/3">
          <l-form-input
            :form="form"
            field="budget"
            label="budget"
            placeholder="budget_placeholder"
          />
        </div>

        <div class="w-1/3">
          <b-field :label="$t('select_currency')">
            <b-select :placeholder="$t('select_currency')" v-model="form.currency" required>
              <option value="€">€</option>
              <option value="$">$</option>
            </b-select>
          </b-field>
        </div>
      </div>

      <div class="w-full items-center mt-4">
        <span class="label">{{ $t('project_tags') }}</span>
        <model-tags
          :model-id="projectId ? projectId : 0"
          model-type="project"
          :allow-edit="false"
          :hooks-enabled="! createNew"
          ref="model_tags"
        />
      </div>
      <!-- Usuarios -->
      <div class="flex mt-8">
        <!-- Jefes de proyecto -->
        <userlist
          ref="managers"
          title="project_managers"
          icon="cog"
          :parent-entity-id="createNew ? null : parseInt(projectId)"
          :api-url="apiUrl"
          :users-permission-type="['admin']"
          :allow-create-users="!createNew"
          :local-mode="createNew"
          :allow-zero-users="false"
          :only-internal-users="true"
          class="w-1/2 pr-3"
          roleName="project_admin"
        />
        <!-- Visualizadores -->
        <userlist
          ref="viewers"
          title="viewers_users"
          icon="eye"
          :parent-entity-id="createNew ? null : parseInt(projectId)"
          :api-url="apiUrl"
          :users-permission-type="['view']"
          :allow-create-users="!createNew"
          :local-mode="createNew"
          @user-added="viewerAdded($event)"
          :allow-zero-users="true"
          :only-internal-users="true"
          class="w-1/2 pl-3"
          roleName="viewer"
        />
      </div>
      <div class="flex mt-8">
        <!-- Usuarios del proyecto -->
        <userlist
          ref="participants"
          title="project_users"
          :parent-entity-id="createNew ? null : parseInt(projectId)"
          icon="users"
          :api-url="apiUrl"
          :users-permission-type="['participate']"
          :allow-create-users="!createNew"
          :local-mode="createNew"
          @user-added="participantAdded($event)"
          :allow-zero-users="true"
          :only-internal-users="true"
          class="w-full md:w-1/2 pr-3"
          roleName="participant"
        />
        <!-- Colaboradores -->
        <userlist
          ref="collaborators"
          title="collaborator_users"
          icon="address-book"
          :parent-entity-id="createNew ? null : parseInt(projectId)"
          :api-url="apiUrl"
          :users-permission-type="['collaborate']"
          :allow-create-users="!createNew"
          :local-mode="createNew"
          :allow-zero-users="true"
          :allow-external-users="true"
          :only-external-users="true"
          :is-project="parseInt(projectId)"
          class="w-full md:w-1/2 pl-3"
          roleName="collaborator"
        />
      </div>
      <div class="flex mt-8">
        <!-- Certificadores de mediciones -->
        <userlist
          ref="certifiers"
          title="certifiers_users"
          :parent-entity-id="createNew ? null : parseInt(projectId)"
          icon="thumbs-up"
          :api-url="apiUrl"
          :users-permission-type="['certificate']"
          :allow-create-users="!createNew"
          :local-mode="createNew"

          :allow-zero-users="true"
          :only-internal-users="true"
          class="w-full md:w-1/2 pr-3"
          roleName="certifier"
        />
        <!-- Participantes de incidencias -->
        <userlist
          ref="issue_users"
          title="issues_users"
          :parent-entity-id="createNew ? null : parseInt(projectId)"
          icon="exclamation-triangle"
          :api-url="apiUrl"
          :users-permission-type="['participate']"
          :allow-create-users="!createNew"
          :local-mode="createNew"

          :allow-zero-users="true"
          :only-internal-users="true"
          class="w-full md:w-1/2 pr-3"
          roleName="issue_user"
        />

        <!-- Participantes de flujos de validaciones -->
        <userlist
          ref="validation_users"
          title="validation_users"
          :parent-entity-id="createNew ? null : parseInt(projectId)"
          icon="project-diagram"
          :api-url="apiUrl"
          :users-permission-type="['participate']"
          :allow-create-users="!createNew"
          :local-mode="createNew"

          :allow-zero-users="true"
          :only-internal-users="true"
          class="w-full md:w-1/2 pr-3"
          roleName="validation_user"
        />
      </div>
    </div>

    <form v-if="!template"
      @submit.prevent="sendProjectData"
      @keydown="form.onKeydown($event)"
      class="w-full flex items-center justify-end mb-8"
    >
      <v-button :disabled="disableSaveButton" type="submit" icon="thumbs-up" icon-pack="far" class="mr-4">Guardar</v-button>
      <v-button color="grey" icon="undo-alt" @click="$router.go(-1)">Cancelar</v-button>
    </form>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import axios from "axios";
  import api from "~/api";
  import Form from "vform";
  import Userlist from "~/components/UserBox/UserList.vue";
  import Googlemaps from '~/components/GoogleMaps.vue'
  import moment from "moment";
  import ModelTags from "../../../components/model-tags";
  import GMapMarkersLoader from "../../../../../../front-submodule/resources/assets/js/components/Map/GMapMarkersLoader";
  import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
  import EventBus from '~/plugins/bus'

  export default {
    components: {
      GMapMarkersLoader,
      ModelTags,
      Userlist,
      ScaleLoader,
      Googlemaps, // para agragar el PROJECT_LOCATION (localizacion del projecto)
    },

    props: {
      projectId: {type: Number, default: null},
      template: { type: Object, default: null },
    },

    data: () => ({
      isLoading: true,
      createNew: false,

      center: {lat: 36.68631, lng: -6.14958},
      points: [],
      image: '',
      form: new Form({
        area_id: null,
        budget: null,
        currency: "€",
        name: "",
        hasWarehouse: false,
        reference: "",
        organization_chart_type: 0,
        scheduled_start_date: null,
        scheduled_end_date: null,
        current_start_date: null,
        current_end_date: null,
        real_start_date: null,
        real_end_date: null,
        raw_scheduled_start_date: null,
        raw_scheduled_end_date: null,
        raw_current_start_date: null,
        raw_current_end_date: null,
        raw_real_start_date: null,
        raw_real_end_date: null,
        description: "",
        create_location_in_activity: false,
        has_daily_report_module: false,
        has_planning_module: false,
        add_company_users: false,
        managers: null,
        viewers: null,
        participants: null,
        collaborators: null,
        regions: [],
        //ubication of project
        latitude: '',
        longitude: '',
      }),
      organizationCharTypes: [
        {id: 0, name: "oct_strict"}
        //17-07-2018 Sólo habilitamos el organigrama rígido
      ],
      areas: [],
      apiUrl: "/api/v2/project",
      scheduled_start_date: null,
      scheduled_end_date: null,
      current_start_date: null,
      current_end_date: null,
      real_start_date: null,
      real_end_date: null,
      disableSaveButton: false,

      // mapa
      datamaps: [],
      newPlace: '',
      place: {
        id: 0,
        reference_code: 'PROJECT_LOCATION',
        address: '',
        latitude: '',
        longitude: '',
      },
      draggable: false,
    }),

    computed: {
      ...mapGetters({
        user: "auth/userBasicInfo",
        navData: "app/navData",
        project: "app/project"
      }),

      mapConfig() {
        return {
          center: this.mapCenter,
          zoom: 19
        };
      },

      mapCenter() {
        return this.center
      }
    },

    watch: {
      template: function()  { this.getTemplateData(); }
    },

    created() {
      // Datos del área padre
      this.form.hasWarehouse = false;
      this.form.area_id = this.$route.params.area_id;

      // Cargamos los áreas existentes
      this.loadAreas();
      // Crear nuevo proyecto
      if (!this.projectId) {
        this.createNew = true;
      }

    },

    mounted() {
      if (this.projectId) {
        this.getProjectData();
      }
      else if(this.template)
        this.getTemplateData();
      else
        this.isLoading = false;

      // this.$refs.locationMap.reload()

    },

    methods: {
      async sendProjectData() {
        this.disableButton();

        this.prepareForm()

        // Edit
        var url = this.apiUrl;
        if (!this.createNew) {
          url += "/" + this.projectId;
        }
        // Enviamos el form
        const {data} = await this.form.post(url);

        if (data && data.success) {
          this.projectId = this.projectId ? this.projectId : data.project_id;

          // Añadimos las etiquetas si estamos creando proyectos
          if (this.createNew) {
            this.$refs.model_tags.syncTagsToModel(this.projectId);
          }

          // TODO
          // Actualizamos los datos del store si acabamos de modificar el proyecto que esté seleccionado
          // if (! this.createNew && project.id == this.projectId) {
          //   await this.$store.dispatch("app/setProject", { data.project });
          // }

          // Success y nos vamos
          this.$notify.success(
            this.createNew
              ? "success_creating_project"
              : "success_editing_project"
          );
          this.$router.go(-1);
        } else {
          this.$notify.error(
            this.createNew ? "error_creating_project" : "error_editing_project"
          );
        }
      },
      // Añadimos los managers al cambiar el área si estamos creando el proyecto
      addManagers() {
        if (this.createNew) {
          // Añadir los responsables del área como gestores de proyecto
          let self = this;
          api.fetchArea(this.form.area_id).then(function (value) {
            self.$refs.managers.usersList =
              value.managers.length > 0 ? value.managers : [self.user];
          });
        }
      },

      async getProjectData() {
        let params = {};
        const {data} = await axios.get("/api/v2/project/" + this.projectId, {params: params});
        if (data) {
          this.$refs.model_tags.initTags(data.tags);
          this.form.area_id = data.area_id;
          this.form.budget = data.budget;
          this.form.currency = data.currency;
          this.form.name = data.name;
          this.form.reference = data.reference;
          this.form.description = data.description;
          this.form.organization_chart_type = data.organization_chart_type;
          this.form.raw_scheduled_start_date = new Date(
            data.scheduled_start_date
          );
          this.form.raw_scheduled_end_date = new Date(data.scheduled_end_date);
          this.form.raw_current_start_date = new Date(data.current_start_date);
          this.form.raw_current_end_date = new Date(data.current_end_date);
          this.form.raw_real_start_date = new Date(data.real_start_date);
          this.form.raw_real_end_date = new Date(data.real_end_date);
          this.form.create_location_in_activity =
            data.create_location_in_activity === 1;
          this.form.has_daily_report_module = data.has_daily_report_module === 1;
          this.form.has_planning_module = data.has_planning_module === 1;
          this.form.add_company_users = data.add_company_users === 1;
          this.image = data.image;
        } else {
          this.$notify.error("error_loading_project_data");
        }

        this.isLoading = false;
      },

      async getTemplateData() {
        let params = {};
        const {data} = await axios.get("/api/v2/project/templates/" + this.template.id, {params: params});
        if (data) {
          this.$refs.model_tags.initTags(data.tags);

          EventBus.$emit('load-locations', []);

          this.form.name = data.name;
          this.form.reference = data.reference;
          this.form.description = data.description;

        } else {
          this.$notify.error("error_loading_project_data");
        }

        this.isLoading = false;
      },

      async viewerAdded(user) {
        //Comprobamos que no está ni como participante
        if (this.isUserInList(user, this.$refs.participants)) {
          this.removeUserFromList(user, this.$refs.viewers, ["view"]);
          this.$notify.error("error_user_in_use_project_participant");
        }
      },

      async participantAdded(user) {
        //Comprobamos que no está ni como visualizador
        if (this.isUserInList(user, this.$refs.viewers)) {
          this.removeUserFromList(user, this.$refs.participants, ["participate"]);
          this.$notify.error("error_user_in_use_project_viewer");
        }
      },

      isUserInList(user, list) {
        var exist = false;
        for (var j = list.usersList.length - 1; j >= 0; j--) {
          if (list.usersList[j].user_id == user.user_id) {
            exist = true;
            break;
          }
        }
        return exist;
      },

      async removeUserFromList(user, list, permission) {
        for (var j = list.usersList.length - 1; j >= 0; j--) {
          if (list.usersList[j].user_id == user.user_id) {
            list.usersList.splice(j, 1);
            break;
          }
        }
        let url = "/api/v2/project/" + this.projectId + "/users/remove";
        const {data} = await axios.post(url, {
          user_id: user.user_id,
          permission_type: permission
        });
      },

      loadAreas() {
        let self = this;
        api
          .fetchUserAreas({sort_field: "name", sort_dir: "asc"})
          .then(function (data) {
            if (data.areas.length > 0) {
              self.areas = data.areas.map(function (area) {
                return {
                  id: area.id,
                  name: area.name + " (" + area.reference_code + ")"
                };
              });
            }
          });
      },

      async uploadImage(file) {
        let url = "/api/v2/project/" + this.projectId + "/image";

        let self = this
        self.image=''; // reset image, show 'uploading...'

        const formData = new FormData();
        formData.append('image', file);

        await axios.post(url, formData)
          .then(function (response) {
            if (response.data.success && response.data.image) {
                self.image = response.data.image;
            }
            else
              console.log('Error uploading image',response);
          })
      },

      disableButton() {
        this.disableSaveButton = true
        let self = this
        setTimeout(function() {
          self.disableSaveButton = false
        }, 5000);
      },

      prepareForm() {
        // Añadimos listas de usuarios
        this.form.managers = this.$refs.managers.getUsersIds();
        this.form.viewers = this.$refs.viewers.getUsersIds();
        this.form.participants = this.$refs.participants.getUsersIds();
        this.form.collaborators = this.$refs.collaborators.getUsersIds();
        this.form.collaborators = this.$refs.collaborators.getUsersIds();
        this.form.certifiers = this.$refs.certifiers.getUsersIds();
        this.form.issue_users = this.$refs.issue_users.getUsersIds();

        this.form.code_location = this.place.reference_code
        this.form.latitude = this.place.latitude
        this.form.longitude = this.place.longitude

        //Tratamos las fechas del form con moment para evitar problemas de zonas horarias
        this.form.scheduled_start_date = moment(
          this.form.raw_scheduled_start_date
        ).format("YYYY-MM-DD");
        this.form.scheduled_end_date = moment(
          this.form.raw_scheduled_end_date
        ).format("YYYY-MM-DD");
        this.form.current_start_date = moment(
          this.form.raw_current_start_date
        ).format("YYYY-MM-DD");
        this.form.current_end_date = moment(
          this.form.raw_current_end_date
        ).format("YYYY-MM-DD");
        this.form.real_start_date = moment(this.form.raw_real_start_date).format(
          "YYYY-MM-DD"
        );
        this.form.real_end_date = moment(this.form.raw_real_end_date).format(
          "YYYY-MM-DD"
        );
      },

      // Para funcion para obtener los datos desde el parent
      async getData() {
        this.isLoading = true;

        this.prepareForm();

        if( !this.form.reference ){
          this.$notify.error('El campo referencia del projecto es obigatorio');
        } else if( !this.form.area_id ){
          this.$notify.error('El campo área es obigatorio');
        } else if( !this.form.latitude || !this.form.longitude){
          this.$notify.error('El campo ubicación del proyecto es obligatorio');
        } else if( !this.form.managers.length ){
          this.$notify.error('Debe añadir por lo menos un gestor de projecto');
        }else {
          this.form.template_id = this.template.id
          // this.$notify.success('Projecto configurado correctamente')
          this.$emit('epc-send-data', this.form)
        }

        this.isLoading = false
      },

      // para gestionar el mapa
      async changeNewPlace() {
        this.newPlace = document.getElementById('address_id').value
      },

      async updPlace(place) {
        this.place.latitude = place.latitude
        this.place.longitude = place.longitude
      },
    }
  };
</script>
