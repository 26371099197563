<template>
  <div class="page-with-top-bar">

    <div class="top-bar flex justify-end">
      <div class="flex items-center text-white">
      </div>
    </div>

    <section>
      <div class="mt-4">
        <b-tabs type="is-boxed" v-model="mainActiveTab">
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="tools"></b-icon>
              <span> {{ $t('workers') }} </span>
            </template>
            <div>
              <users-manual
                ref="users_manual"
              />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-import"></b-icon>
              <span> {{ $t('import_workers') }} </span>
            </template>
            <div>
              <import-entities
                :can-overwrite="true"
                :partner-id="parseInt(partnerId)"
                entity-type="collaborators"
              />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-export"></b-icon>
              <span> {{ $t('export_workers') }} </span>
            </template>
            <div>
              <export-collaborators
                :partner-id="parseInt(partnerId)"
              />
            </div>
          </b-tab-item>

        </b-tabs>
      </div>
    </section>

  </div>
</template>

<script>
import store from '~/store'
import ImportEntities from "·/components/import-entities";
import UsersManual from '·/pages/admin/partners/usersManual'
import ExportCollaborators from '../../../components/export-collaborators.vue';

export default {
  middleware: 'auth',
  title: 'partners_page_title',
  subtitle: 'partners_page_subtitle',
  menuOption: '0-4',
  components: {
    ImportEntities,
    UsersManual,
    ExportCollaborators,
  },

  data: () => ({
    mainActiveTab: 0,
    partnerId: store.state.route.params.id,
  }),


};
</script>
