<template>
  <div>
    <div class="panel">
      <div>
        <p class="font-bold">¿Seguro que desea generar el proyecto?</p>
      </div>
      <div>
        <ul class="pl-5 mt-2 space-y-1 list-inside">
          <li>
            <div>
              <p>
                <b-icon pack="fas"
                  icon="hand-point-right"
                  :type="process.PROJECT.action == currentProcess.action ? 'is-success' : 'is-warning'"
                  size="is-medium"></b-icon>
                <span>Crear proyecto</span>
              </p>
              <p class="pl-10" v-if="process.PROJECT.status == statuses.IN_PROCESS">
                <span>Creando proyecto</span> <b-icon pack="fas" icon="spinner" custom-class="animate-spin" size="is-medium"> </b-icon>
              </p>
              <p class="pl-10" v-if="process.PROJECT.status == statuses.SUCCESS"> <span>Proyecto creado</span> <b-icon pack="fas" icon="check" size="is-medium" type="is-success"> </b-icon> </p>
              <p class="pl-10" v-if="process.PROJECT.status == statuses.WITH_ERROR"> <span>Error al crear proyecto</span> <b-icon pack="fas" icon="times-circle" size="is-medium" type="is-danger"> </b-icon> </p>
            </div>
          </li>
          <li>
            <div>
              <p>
                <b-icon pack="fas"
                  icon="hand-point-right"
                  :type="process.ACTIVITIES.action == currentProcess.action ? 'is-success' : 'is-warning'"
                  size="is-medium"></b-icon>
                <span>Asignar actividades, subactividades y mediciones (este proceso puede tardar varios minutos)</span>
              </p>
              <p class="pl-10" v-if="process.ACTIVITIES.status == statuses.IN_PROCESS"> <span>Asignando actividades, subactividades y mediciones</span> <b-icon pack="fas" icon="spinner" custom-class="animate-spin" size="is-medium"> </b-icon> </p>
              <p class="pl-10" v-if="process.ACTIVITIES.status == statuses.SUCCESS"> <span>Actividades de subactividades y mediciones asignadas correctamente</span> <b-icon pack="fas" icon="check" size="is-medium" type="is-success"> </b-icon> </p>
              <p class="pl-10" v-if="process.ACTIVITIES.status == statuses.WITH_ERROR"> <span>Error al asignar actividades, subactividades y mediciones</span> <b-icon pack="fas" icon="times-circle" size="is-medium" type="is-danger"> </b-icon> </p>
            </div>
          </li>
          <li v-if="false">
            <div>
              <p>
                <b-icon pack="fas"
                  icon="hand-point-right"
                  :type="process.SUBACTIVITIES.action == currentProcess.action ? 'is-success' : 'is-warning'"
                  size="is-medium"> </b-icon>
                <span>Asignar subactividades</span>
              </p>
              <p class="pl-10" v-if="process.SUBACTIVITIES.status == statuses.IN_PROCESS"> <span>Asignando subactividad</span> <b-icon pack="fas" icon="spinner" custom-class="animate-spin" size="is-medium"> </b-icon> </p>
              <p class="pl-10" v-if="process.SUBACTIVITIES.status == statuses.SUCCESS"> <span>Subactividades asignadas correctamente</span> <b-icon pack="fas" icon="check" size="is-medium" type="is-success"> </b-icon> </p>
              <p class="pl-10" v-if="process.SUBACTIVITIES.status == statuses.WITH_ERROR"> <span>Error al asignar subactividades</span> <b-icon pack="fas" icon="times-circle" size="is-medium" type="is-danger"> </b-icon> </p>
            </div>
          </li>
          <li v-if="false">
            <div>
              <p>
                <b-icon pack="fas"
                icon="hand-point-right"
                :type="process.MEASUREMENTS.action == currentProcess.action ? 'is-success' : 'is-warning'"
                size="is-medium"> </b-icon>
                <span>Asignar mediciones</span>
              </p>
              <p class="pl-10" v-if="process.MEASUREMENTS.status == statuses.IN_PROCESS"> <span>Asignando mediciones</span> <b-icon pack="fas" icon="spinner" custom-class="animate-spin" size="is-medium"> </b-icon> </p>
              <p class="pl-10" v-if="process.MEASUREMENTS.status == statuses.SUCCESS"> <span>Mediciones asignadas correctamente</span> <b-icon pack="fas" icon="check" size="is-medium" type="is-success"> </b-icon> </p>
              <p class="pl-10" v-if="process.MEASUREMENTS.status == statuses.WITH_ERROR"> <span>Error al asignar mediciones</span> <b-icon pack="fas" icon="times-circle" size="is-medium" type="is-danger"> </b-icon> </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="mt-8" v-if="process.ACTIVITIES.status == statuses.SUCCESS">
        <p class="font-bold">Proyecto generado correctamente</p>
      </div>


    </div>
  </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import Form from 'vform'
import moment from 'moment'
// import entityList from './utils/entity-list.vue';

Vue.use(moment)

export default {
  name: 'wizard-template-complete',

  components: {
    // entityList
  },

  props: {
    // projectId: { type: Number, default: 0 },
    template: { type: Object, required: true },
    project: { type: Object, required: true },
    activities: { type: Array, required: true },
  },

  data: () => ({
    projectGenerateId: 0,
    form: new Form({ }),
    boxFullWidth: false,

    statuses: {
      PENDING: 0,
      IN_PROCESS: 1,
      SUCCESS: 2,
      WITH_ERROR: 3

    },

    currentProcess: {
      action: 'none', // nombre del proceso actual
      status: 0, // estado del proceso actual
    },

    process: {
      PROJECT: {action: 'create_poject', status: 0},
      ACTIVITIES: {action: 'link_activities', status: 0},
      SUBACTIVITIES: {action: 'link_subactivities', status: 0},
      MEASUREMENTS: {action: 'link_measurement', status: 0},
    }
  }),

  mounted() {
  },

  created() {

  },

  watch: {

  },

  computed: {
  },

  methods: {
    prepareForm() {


    },

    async generate() {
      this.initProcess('PROJECT')

      await this.generateProject()
        .then((response) => {
          this.projectGenerateId = response.data.project_id
          // console.log('ip project generate: ', this.projectGenerateId)
          this.currentProcess.status = this.statuses.SUCCESS
          this.process.PROJECT.status = this.statuses.SUCCESS
          // this.$notify.success('Projecto generado correctamente.')
        })
        .catch((error) => {
          this.currentProcess.status = this.statuses.WITH_ERROR
          this.process.PROJECT.status = this.statuses.WITH_ERROR
          // console.log('Error save project', error)
        })

        if (this.currentProcess.status == this.statuses.SUCCESS) {
          this.initProcess('ACTIVITIES')
          await this.linkActivitites().then((response) => {
            this.currentProcess.status = this.statuses.SUCCESS
            this.process.ACTIVITIES.status = this.statuses.SUCCESS
          }).catch((error) => {
            console.log('%cEPC-TACKER: '+ '%c ERROR: ', 'background: #5577BB; color: #fff', 'color: #000', error)
            this.currentProcess.status = this.statuses.WITH_ERROR
            this.process.ACTIVITIES.status = this.statuses.WITH_ERROR
          })
        }
    },

    async generateProject() {
      this.prepareProject()
      let url = `/api/v2/project/templates/generate`
      // añadimos el id del template para identificar de que project template se ha creado
      this.project.from_template = this.template.id
      // Enviamos el form
      return await this.project.post(url)
        .then(this.assertServerError)
        .catch(this.rejectServerError)
    },

    async linkActivitites() {
      this.prepareActivities();
      // this.projectGenerateId = 404
      let url = `/api/v2/project/templates/generate/${this.projectGenerateId}/link-activities`

      let params = {
        activities : this.activities
      }

      return await axios.post(url, params)
    },

    initProcess(process) {
      this.currentProcess.action = this.process[process].action;
      this.currentProcess.status = this.statuses.IN_PROCESS
      this.process[process].status = this.statuses.IN_PROCESS
    },

    // Customize
    assertServerError (res) {
      if (res && res.error) {
        const error = new Error(res.message)
        Object.assign(error, res.error)
        throw error
      }
      return res
    },

    rejectServerError (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // La solicitud fue hecha y el servidor respondió con un código de estado diferente a 2xx
        // console.error('Código de estado:', error.response.status);
        // console.error('Mensaje de error:', error.response.data);
        // console.log('Cabezera del error', error.response.headers);
        let errors = error.response.data.errors

        // console.log('errors: ', errors);

      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // La solicitud fue hecha pero no se recibió respuesta
        console.log('No se recibió respuesta del servidor', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        // Se produjo un error al configurar la solicitud
        console.log('Error al configurar la solicitud:', error.message);
      }

      this.$notify.error('Ha ocurrido un error al generar el proyecto')

      return Promise.reject(error)
    },

    prepareProject() {
      // this.project.
    },

    prepareActivities() {
      for(var key in this.activities) {
        let activity = this.activities[key]
        activity.start_date = moment( activity.start_date).format("YYYY-MM-DD");
        activity.end_date = moment( activity.end_date).format("YYYY-MM-DD");
        if( typeof activity.subactivities !== 'undefined' && activity.subactivities.length ){
          for(let subKey in activity.subactivities) {
            let subactivity = activity.subactivities[subKey]
            subactivity.start_date = moment( subactivity.start_date).format("YYYY-MM-DD");
            subactivity.end_date = moment( subactivity.end_date).format("YYYY-MM-DD");
          }
        }
      }
    }

  },
}

</script>
