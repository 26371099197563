<template>
  <div>
    <div>
      <multiselect
        v-model="selected"
        :options="usersToSelect"
        :multiple="true"
        :hide-selected="true"
        track-by="email"
        :customLabel="selectedLabel"
        :show-labels="false"
        :placeholder="$t(placeholder)"
        :disabled="disabled"
        >
        <template slot="option" slot-scope="users">
          <div class="flex flex-row space-x-2">
            <img
              style="width: 28px; height: 28px;"
              class="rounded-full overflow-hidden"
              :src="users.option.basic.avatar">
            <span class="text-sm font-semibold truncate">{{ users.option.basic.fullname }}</span>
          </div>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import Multiselect from 'vue-multiselect'
import EventBus from '~/plugins/bus'

export default {
  name: "select-users",

  components: {
    Multiselect,
    EventBus
  },

  props: {
    entityId: { type: Number, required: true },
    entityType: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    roles: { type: String, required: true },
    placeholder: { type: String, default: 'select_users' },
  },

  data: () => ({
    selected: [],
    usersToSelect: []
  }),

  created() {

    var self = this

    axios.get("/api/v2/"+this.entityType+"/" + this.entityId + "/users?r=" + this.roles + '&basic=0&array=1')
      .then(function (response) {
        if (response.data && response.data.success) {
          self.usersToSelect = response.data.users_list
        }
      })
  },

  methods: {

    getSelectedIds() {
      return this.selected.map((item) => {
        return item.id
      })
    },

    selectedLabel(user) {
      return user.basic.fullname
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
  .multiselect__option--highlight {
    background-color:cornflowerblue !important;
  }
  .multiselect__tag {
    background-color:cornflowerblue !important;
  }
  .multiselect__tag-icon::after {
    color: whitesmoke !important;
  }
  .multiselect__tag-icon:hover {
    background-color:#385da5 !important;
  }
</style>
