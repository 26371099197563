<template>
<div class="flex-col">

  <div class="w-full flex justify-between below-top-bar">

    <div class="flex">
      <router-link :to="{ name: 'project.dashboard.red' }" :class="['btn', 'btn-blue', 'px-4', 'rounded', 'py-2']">&lt; {{ $t('back' )}} a dashboard general </router-link>
    </div>

    <b-tooltip :label="txtTooltipExport || this.$t('export')" position="is-left">
      <div class="rounded-full bg-blue w-8 h-8 flex items-center justify-center text-grey-lightest cursor-pointer" @click="exportReport">
        <b-icon icon="download" pack="fas" size="is-small" v-if="! isLoadingExport"/>
        <b-icon pack="fas" icon="sync-alt" custom-class="fa-spin" v-if="isLoadingExport"></b-icon>
      </div>
    </b-tooltip>
  </div>



  <!-- Resumen campos extra -->
  <div class="panel">
    <div id="page-title" class="bg-white top-20 mb-4 z-10">
      <h1 class="m-0 p-0 text-xl font-raleway font-bold uppercase text-blue-light ">{{ activity.name }}</h1>
      <p class="m-0 text-sm font-bold font-raleway text-grey-dark">{{ activity.planning_code }}</p>
    </div>

    <div class="flex flex-wrap">

      <div v-for="field in extrafields" :key="field.extrafield_id" class="w-1/2 pr-4">

        <div v-if="field.field.code != 'ajuste'"> <!-- show all fields except 'ajuste' -->
          <label class="text-blue-light font-bold">{{ field.field.name }}</label>
          <p
            :class="[ field.field.name === 'Fecha fin contractual ejecución' ? 'text-blue-950 font-bold underline': 'text-grey-dark']">
            {{ getValue(field) }}
          </p>
        </div>

      </div>

    </div>

  </div>


  <!-- Panelitos -->
  <div class="flex flex-row">

    <!-- Panel 1 -->
    <div class="panel flex flex-col w-1/3">
      <label class="text-blue font-bold">Avance del presupuesto</label>
      <!-- <label class="text-blue italic">PEC del DSO:</label><br> {{ panel1.pecTotal }}<br> Canal desea mostrar el pecRedactado -->
      <label class="text-blue italic">Baja:</label> {{ panel1.baja100 }}<br>
      <label class="text-blue italic">PEC inicial del DSO:</label> {{ panel1.pecRedactado }}<br>
      <label class="text-blue italic">Presupuesto de adjudicación inicial del DSO:</label> {{ panel1.presAdjudicacionInicial }}<br>
      <!-- <label class="text-blue italic">PEC del DSO:</label> {{ panel1.pecRedactado }}<br> -->
      <label class="text-blue italic">PEC vigente del DSO:</label> {{ panel1.pecTotal }}<br>
      <label class="text-blue italic">Presupuesto de adjudicación vigente del DSO:</label> {{ panel1.presAdjudicacion }}<br>
      <!-- <label class="text-blue italic">PEC redactado:</label><br> {{ panel1.pecRedactado }}<br> -->
      <label class="text-blue italic">PEC del DSO ejecutado actualmente:</label> {{ panel1.avancePec }}<br>
      <label class="text-blue italic">Obra ejecutada:</label> <span v-if="panel1.avancePec">{{ obraEjecutada(panel1.avancePecRaw, panel1.baja) }}</span><br>
      <label class="text-blue italic">Porcentaje de avance:</label> {{ panel1.avancePecPercent }}%<br>
      <label class="text-blue italic">Acreditado: <b-tooltip :label="'90% Obra ejecutada + dev. retenciones + ajuste'" position="is-right"><b-icon pack="fas" icon="circle-info" /></b-tooltip></label> <span v-if="panel1.avancePec">{{ facturado(panel1.avancePecRaw, panel1.baja) }}</span><br>
    </div>

    <!-- Panel 2 -->
    <div class="panel flex flex-col w-1/3 ml-2">
      <label class="text-blue font-bold">Metros de tubería</label>
      <label class="text-blue italic">Total metros redactados en DSO:</label> {{ this.panel2.totalRedactado }}<br>
      <label class="text-blue italic">Total metros actualizados en DSO:</label> {{ this.panel2.total }}<br>
      <label class="text-blue italic">Ejecutado:</label> {{ this.panel2.totalTuberiasEjecutadas }}<br>
      <label class="text-blue italic">Porcentaje de avance:</label> {{ panel2.percentage }}%
    </div>

    <!-- Panel 3 -->
    <div class="panel flex flex-col w-1/3 ml-2">
      <label class="text-blue font-bold">Número de acometidas</label>
      <label class="text-blue italic">Total acometidas redactadas en DSO:</label> {{ this.panel3.totalRedactado }}<br>
      <label class="text-blue italic">Total acometidas actualizadas en DSO:</label> {{ this.panel3.total }}<br>
      <label class="text-blue italic">Ejecutado:</label> {{ this.panel3.totalAcometidasEjecutadas }}<br>
      <label class="text-blue italic">Porcentaje de avance:</label> {{ panel3.percentage }}%
    </div>
  </div>


  <!-- Panel 4 DSO -->
  <div class="w-full mt-8">
    <div class="flex flex-row w-full text-blue font-bold">% Avance PEC</div>
    <div class="flex flex-row w-full"><line-chart :min="0" :max="100" :suffix="'%'" :data="panel4" :colors="['#d12411']" /></div>
  </div>

  <!-- Panel 5 DSO -->
  <div class="w-full mt-8">
    <div class="flex flex-row w-full text-blue font-bold">Avance PEC</div>
    <div class="flex flex-row w-full"><line-chart :data="panel5" :colors="['#d12411']" /></div>
  </div>

  <!-- Panel 6 DSO -->
  <div class="w-full mt-8">
    <div class="flex flex-row w-full text-blue font-bold">ml de tuberias certificados</div>
    <div class="flex flex-row w-full"><line-chart :suffix="'m'" :data="panel6" :colors="['#d12411']" /></div>
  </div>


  <div class="flex flex-row mt-8">
    <!-- Panel 7 DSO -->
    <div class="flex flex-col w-1/2">
      <label class="flex flex-row w-full text-blue font-bold">Nº acometidas certificado</label>
      <pie-chart suffix="" :data="panel7" />

      <table>
        <tbody>
          <tr class="panel" v-for="(line, key, index) in panel7" :key="'7_'+index">
            <td align="center" class="justify-start py-2 w-2/12 bg-grey-lightest">
              <div v-if="piechartColors[index]" :style="'width: 20px; border-radius: 1em; height: 20px; background:' + piechartColors[index]"></div>
            </td>
            <td align="center" class="justify-start py-2 w-5/12 bg-indigo-lightest">
              <span class="text-sm font-semibold text-blue">{{ key }}</span>
            </td>
            <td align="center" class="justify-start py-2 w-5/12 bg-grey-lightest">
              <span class="text-sm font-semibold">{{ line }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Panel 8 DSO -->
    <div class="flex flex-col w-1/2 ml-2">
      <label class="flex flex-row w-full text-blue font-bold">Ml tuberias certificado</label>
      <pie-chart suffix="" :data="panel8" />

      <table>
        <tbody>
          <tr class="panel" v-for="(line, key, index) in panel8" :key="'8_'+index">
            <td align="center" class="justify-start py-2 w-2/12 bg-grey-lightest">
              <div v-if="piechartColors[index]" :style="'width: 20px; border-radius: 1em; height: 20px; background:' + piechartColors[index]"></div>
            </td>
            <td align="center" class="justify-start py-2 w-5/12 bg-indigo-lightest">
              <span class="text-sm font-semibold text-blue">{{ key }}</span>
            </td>
            <td align="center" class="justify-start py-2 w-5/12 bg-grey-lightest">
              <span class="text-sm font-semibold">{{ line }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import VueChartkick from 'vue-chartkick'
import Chart from 'chart.js'
import moment from 'moment'

Vue.use(VueChartkick, {adapter: Chart})
Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'project_home_page_title',
  subtitle: 'project_home_page_subtitle',
  menuOption: '2-1',

  components: {
    ScaleLoader,
  },

  data: () => ({
    extrafields: {},
    activity: {},
    activityId: 0,
    panel1: {}, // parcialmente cargado en getPanel45
    panel2: { total: null, totalTuberiasEjecutadas: 0, percentage: null },
    panel3: { total: null, totalAcometidasEjecutadas: 0, percentage: null },
    panel4: null,
    panel5: null,
    panel6: null,
    panel7: null,
    panel8: null,
    factorCanal: 0.9, // algunas cifras se multiplican por este factor fijo

    isLoadingExport: false,
    txtTooltipExport: null,
    piechartColors: ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#3b3eac', '#0099c6', '#dd4477', '#66aa00', '#b82e2e']
  }),

  computed: {
    ...mapGetters({
      project: 'app/project',
      user: 'auth/user',
    }),
  },

  mounted() {

    this.activityId = this.$route.params.activity_id
    this.getDSOInfo();

    let pageInfo = {
      title: this.project.name,
      subtitle: this.$t('project_home_page_subtitle'),
      menuOption: '2-1',
    }

    this.$store.dispatch('app/updatePageInfo', {info: pageInfo})

  },

  methods: {


    async getDSOInfo() {
      // get extrafield & activity info
      this.getActivityInfo()

      // get statistics panels info
      await this.getPanel123(); // await!. Carga en caché tuberias/acometidas usadas en panel78
      this.getPanel45();
      this.getPanel6();
      this.getPanel78(); // usa caché cargada en panel23
    },


    async getActivityInfo() {
      let url = '/api/v2/activity/' + this.activityId + '/extrafields-assigned';
      const {data} = await axios.get(url, {params: this.filters});
      if (data && data.success) {
        this.activity = data.data
        this.extrafields = this.activity.extrafields
      } else {
        this.$notify.error('error_loading_activities')
      }
    },

    getValue(field) {
      if(field) {
        let valor = null;
        switch (field.field.field_type) {
          case 0:
            valor = '-'
            let options = field.field.values
            let option = options.filter( o => o.id == field.value_id )
            if( option && option[0])
              valor = option[0].name
            break;

          default:
            valor = field.value_free
            break;
        }
        return valor;
      }
    },


    async getPanel123() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/dsopanel123';
      await axios.get(url, {params: {activity_id: this.activityId}})
        .then(response => {
          self.panel1.pecTotal = response.data.data.panel1.pecTotal
          self.panel1.pecRedactado = response.data.data.panel1.pecRedactado
          self.panel1.baja = response.data.data.panel1.baja
          self.panel1.baja100 = response.data.data.panel1.baja100
          self.panel1.presAdjudicacionInicial = response.data.data.panel1.presAdjudicacionInicial
          self.panel1.presAdjudicacion = response.data.data.panel1.presAdjudicacion
          self.panel1.retenciones = response.data.data.panel1.retenciones // resto datos en dsopanel45
          self.panel2 = response.data.data.tuberias
          self.panel3 = response.data.data.acometidas
          // se completan datos en getPanel78
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel23")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },


    async getPanel45() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/dsopanel45';
      await axios.get(url, {params: {activity_id: this.activityId}})
        .then(response => {
          self.panel4 = response.data.data.panel4
          self.panel5 = response.data.data.panel5

          // del array de datos del panel 4, el ultimo dato es el porcentaje presupuesto ejecutado/completado del dso (panel 1)
          self.panel1.avancePecPercent = self.panel4[0].data[Object.keys(self.panel4[0].data)[Object.keys(self.panel4[0].data).length - 1]]
          // del array de datos del panel 5, el ultimo dato es el presupuesto ejecutado/completado del dso (panel 1)
          self.panel1.avancePec = self.panel5[0].data[Object.keys(self.panel5[0].data)[Object.keys(self.panel5[0].data).length - 1]]
          self.panel1.avancePecRaw = self.panel1.avancePec // para ciertas operaciones, mantenemos el float
          self.panel1.avancePec = Intl.NumberFormat(self.user.locale, {style: "currency", currency: "EUR"} ).format(self.panel1.avancePec)

        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel45")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },


    async getPanel6() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/dsopanel6';
      await axios.get(url, {params: {activity_id: this.activityId}})
        .then(response => {
          self.panel6 = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel6")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },


    async getPanel78() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/dsopanel78';
      await axios.get(url, {params: {activity_id: this.activityId}})
        .then(response => {
          self.panel7 = response.data.data.panel7
          self.panel8 = response.data.data.panel8

          // para panel 2 y 3, se obtiene de aqui para no duplicar calculos
          self.panel3.totalAcometidasEjecutadas = Object.values(self.panel7).reduce((accumulator, value) => {
            return accumulator + parseFloat(value);
          }, 0)
          self.panel2.totalTuberiasEjecutadas = Object.values(self.panel8).reduce((accumulator, value) => {
            return accumulator + parseFloat(value);
          }, 0)

          // artisan converter es-ES number to float
          let panel2TotalNumber = self.panel2.total.replace('.', '').replace(',', '.') // 20.345,78 => 20345.78
          let panel3TotalNumber = self.panel3.total.replace('.', '').replace(',', '.') // 20.345,78 => 20345.78
          self.panel2.percentage = ((self.panel2.totalTuberiasEjecutadas * 100) / panel2TotalNumber).toFixed(2)
          self.panel3.percentage = ((self.panel3.totalAcometidasEjecutadas * 100) / panel3TotalNumber).toFixed(2)

          self.panel2.totalTuberiasEjecutadas = Intl.NumberFormat(self.user.locale).format(self.panel2.totalTuberiasEjecutadas)
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel78")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async exportReport() {
      if( !this.isLoadingExport ){
        this.txtTooltipExport = this.$t('generating');

        this.$notify.success('generating_report');
        this.isLoadingExport = true;

        let self = this
        let url = '/api/v2/project/' + this.project.id + '/dashboard/export';
        await axios.get(url, {params: {activity_id: this.activityId}})
          .then(response => {
            window.open(response.data.data);

            self.$notify.success('report_generated')
            self.isLoadingExport = false;
            self.txtTooltipExport = this.$t('export')
          })
          .catch((error) => {
            //Se produjo un error
            console.log("Error loading exportación")

            self.$notify.error(error.error_msg || 'error_retrieving_data')
            self.isLoadingExport = false
            self.txtTooltipExport = this.$t('export')
          });
      }
    },

    obraEjecutada(pecEjecutado, baja) {
      let res = pecEjecutado * (1 - baja)
      return Intl.NumberFormat(this.user.locale, {style: "currency", currency: "EUR"} ).format(res)
    },

    // facturado o acreditado. Si el extrafield 'ajuste' existe, añadir su valor a la suma
    facturado(pecEjecutado, baja) {
      let obraEjecutada = pecEjecutado * (1 - baja)
      let facturado = parseFloat(obraEjecutada * this.factorCanal) + parseFloat(this.panel1.retenciones)

      let extrafieldAjuste = this.extrafields.find(e => e.field.code=='ajuste')
      if (extrafieldAjuste && extrafieldAjuste.value_free) {
        facturado += parseFloat(extrafieldAjuste.value_free)
      }

      return Intl.NumberFormat(this.user.locale, {style: "currency", currency: "EUR"} ).format(facturado)
    },


  },

};
</script>

<style scoped>
.column-scroll {
  width: 50%;
  height: calc(100vh - 150px);
  overflow-x: hidden;
  overflow-y: auto;
}
.column-scroll:not(:last-child) {
  margin-right: 0.5rem;
}
.column-scroll:last-child {
  margin-left: 0.5rem;
}
</style>
