<template>
  <div>

    <div :class="'w-full flex ' + bgColor">

      <div class="w-1/5">
        <div
          @click="showPreview"
          class="cursor-pointer entity-document-thumbail">

          <div class="absolute w-full h-full" style="padding:1px">
            <img v-if="thumbnail" :src="thumbnail" class="w-full h-full" @error="imgError"/>
            <div v-else
                 :style="{ 'background-color': backgroundColor }"
                 class="flex items-center justify-center w-full h-full text-2xl font-semibold text-white">
              {{ fileExtension() }}
            </div>
            <div class="absolute inset-0 flex items-center justify-center text-black bg-white overlay">
              <b-icon pack="fas" icon="search-plus" size="is-large" />
            </div>
          </div>
        </div>
      </div>

      <div class="w-2/5 mt-1 ml-2" style="font-size: 12px">
        <div class="flex w-full mb-2">
          <b-tooltip :label="lastUser.fullname + ' (' + lastCreated + ')'" position="is-right" multilined>
            <div class="w-8 h-8 mr-1 overflow-hidden border rounded-full border-grey">
              <img :src="lastUser.avatar" :alt="lastUser.fullname">
            </div>
          </b-tooltip>
          <v-button-icon-round
                  @click="downloadDoc"
                  icon="download"
                  :tooltip="documentDownloadTooltip('download_document')"
                  tooltip-position="right"
                  :disabled="!isOk()"
                  class="mr-1"
          />
          <v-button-icon-round
                  v-if="(showVersions && entityType != 'validation' && document.related_validation==null && document.related_validation_task==null) || isAttached"
                  @click="openUploadModal"
                  icon="upload"
                  :tooltip="documentDownloadTooltip('upload_version')"
                  tooltip-position="right"
                  :disabled="!isOk()"
                  class="mr-1"
          />
          <v-button-icon-round
                  v-if="showVersions"
                  @click="openVersionsModal"
                  icon="history"
                  tooltip="display_versions"
                  tooltip-position="right"
                  class="mr-1"
                  :disabled="document.versions.length <= 0 || !isOk()"
          />
          <span class="dot" v-if="document.versions.length > 0">
            {{ document.versions.length }}
          </span>
        </div>
        <div class="w-full">
          <span class="font-bold"> {{ document.name }}</span>
          <span class="italic" v-if="document.desc_name"><br>{{ $t('descriptive_name')}}: {{ document.desc_name }}</span>
          <span class="text-xs" v-if="document.related_validation"><br>({{ document.related_validation.validation_name }})</span>
          <span class="text-xs" v-if="document.related_validation_task"><br>({{ document.related_validation_task.validation_task_name }})</span>        </div>
        </div>

      <div class="w-2/5 ml-2" style="font-size: 12px">
        <!-- Tags -->
        <div class="w-full" v-if="document.tags.length > 0">
          <visualize-tags
              :tags="document.tags"
              :bg-color="bgColor"
          />
        </div>
      </div>

      <!-- Modal para PREVIEW -->
      <entity-document-preview
          :entity-id="parentId"
          :entity-type="entityType"
          :document-id="document.document_id"
          ref="previewModal"
          :can-delete="canDelete"
          :showVersions="showVersions"
          :isAttached="isAttached"
          @reload-documents-list="() => $emit('reload-documents-list')"
      />

      <!-- Modal para subir versión -->
      <upload-document
              ref="upload_modal"
              :upload-endpoint="'/api/v2/document/' + document.document_id + '/add_version/' + entityType + '/' + parentId"
              :entity-id="parentId"
              :entity-type="entityType"
              :notify-users="true"
              @success="reloadList"
      />

      <!-- Modal para las versiones -->
      <document-versions
              ref="versions_modal"
              :document="document"
              :entityId="parentId"
              :entityType="entityType"
              @reload-documents-list="reloadList"
      />
    </div>
    <div class="pb-3 bg-white">
    </div>
  </div>
</template>

<script>
import EventBus from "~/plugins/bus";
import axios from "axios";
import entityDocumentPreview from "./entity-document-preview";
import VisualizeTags from "·/components/visualize-tags";
import uploadDocument from "·/components/upload-document";
import documentVersions from "·/components/document-versions";
import moment from 'moment'

export default {
  name: "document-item-list",

  components: {
    entityDocumentPreview,
    VisualizeTags,
    uploadDocument,
    documentVersions
  },

  props: {
    parentId: { type: Number, required: true },
    document: { type: Object, required: true },
    canDelete: { type: Boolean, default: false },
    entityType: { type: String, required: true },
    bgColor: { type: String, default: 'bg-white' },
    showVersions: { type: Boolean, default: true },
    isAttached: { type: Boolean, default: false },
  },

  data: () => ({
    thumbnail: null,
    backgroundColor: null
  }),

  mounted() {
    this.thumbnail = this.document.file.thumbnail
  },

  computed: {

    //si hay versiones del documento, pues el que subio la ultima version
    lastUser() {
      return this.document.versions.length
        ? this.document.versions[0].user.basic
        : this.document.user
    },

    lastCreated() {
      return this.document.versions.length
        ? moment(this.document.versions[0].created_at).format('DD-MM-YYYY H:mm:ss')
        : this.document.created_at
    }

  },

  methods: {
    async downloadThumbnail() {
      let url = "/api/v2/document/" + this.document.document_id + "/200";
      const { headers, data } = await axios.get(url, {
        responseType: "arraybuffer"
      });
      if (data && data.byteLength > 0) {
        let mime = headers["content-type"];
        this.thumbnail =
          "data:" +
          mime +
          ";base64," +
          new Buffer(data, "binary").toString("base64");
      } else {
        this.colorBackground();
      }
    },

    imgError() {
      this.thumbnail = null;
      this.colorBackground();
    },

    showPreview() {
      this.$refs.previewModal.show(this.document);
      if (this.document.exif) {
        EventBus.$emit("load-locations", this.document.exif);
      }
    },

    fileExtension() {
      let index = this.document.name.lastIndexOf(".");
      if (index) {
        let ext = this.document.name.substr(index + 1);
        if (ext) {
          return ext.toUpperCase();
        }
      }
      return "";
    },

    colorBackground() {
      var codes = [0, 0, 0];
      let ext = this.fileExtension();
      for (var i = 0; i < ext.length; i++) {
        codes[i] = ext.charCodeAt(i);
        if (i == 2) {
          break;
        }
      }
      var rgb = (codes[2] + 100) | ((codes[1] + 50) << 8) | (codes[0] << 16);
      this.backgroundColor = "#" + (0x1000000 + rgb).toString(16).slice(1);
      // console.log('aquí', hexColor)
    },

    async downloadDoc() {
        this.$notify.success("starting_download_document");
        let url = "/api/v2/document/" + this.document.document_id;

        const {data} = await axios.get(url);

        if (data) {

          window.open(data.data);

          // const link = document.createElement("a");
          // link.href = window.URL.href(data.data);
          // link.setAttribute("download", this.document.name);
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);

        } else {
          this.$notify.error("error_download_document");
        }
      },

    openUploadModal() {
      this.$refs.upload_modal.show();
    },

    reloadList() {
      this.downloadThumbnail();
      this.$emit('reload-documents-list')
    },

    openVersionsModal() {
      this.$refs.versions_modal.show();
    },

    isOk(){
      return this.document.status == 1;
    },

    documentDownloadTooltip(tooltip)
    {
      if(this.isOk())
        return tooltip;

      return 'document_still_processing';
    }
  }
};
</script>

<style scoped>
.entity-document-thumbail {
  /*opacity: 1;*/
  position: relative;
  /*  display: block;
  width: 100%;
  height: auto;*/
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.entity-document-thumbail:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.entity-document-thumbail .overlay {
  transition: 0.5s ease;
  opacity: 0;
  /*  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;*/
}
/*.entity-document-thumbail:hover .overlay {
  opacity: 0.3;
}*/
.entity-document-thumbail:hover .overlay {
  opacity: 0.5;
}

.dot {
  height: 21px;
  width: 21px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  color: white;
  margin-left: -4%;
  text-align: center;
  vertical-align: middle;
  padding: 2px 0;
}

</style>
