<template>
  <li class="menu-header">
    <b-icon v-if="icon" :icon="icon" pack="fas" class="menu-item-icon" />
    <span>
      <slot />
    </span>
  </li>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: null }
  },
};
</script>
