<template>
  <div>
    <label class="font-bold">Acción masiva: {{ action.title }}</label>

    <div class="panel">
      <p v-if="action.name === 'assign_group'">La acción asignará {{ linkedEntities.length }} grupo(s) a {{ validations.length }} flujo(s) seleccionado(s)</p>
      <p v-else-if="action.name === 'assign_group_users'">La acción asignará {{ linkedUsers.length }} usuario(s) a {{ linkedEntities.length }} grupo(s) de {{validations.length}} flujo(s) seleccionado(s)</p>
      <p v-else-if="action.name === 'remove_users'">La acción eliminará a {{ linkedUsers.length }} usuario(s) de {{ validations.length }} flujo(s) seleccionado(s)</p>


      <div class="flex flex-row mt-3 space-x-4">

        <div :class="action.name == 'assign_group_users' ? 'w-1/3':'w-1/2'">
          <p class="mb-2">Flujos(s) afectado(s)</p>
          <entity-list
              :entities="validations"
              entityKey="id"
              :withImage="false"
              :show="true"
              :showRemoveButton="false"
              :componentName="['name']"
            />
        </div>

        <div v-if="action.name != 'remove_users'" :class="action.name == 'assign_group_users' ? 'w-1/3':'w-1/2'">
          <p class="mb-2">Grupo(s) seleccionado(s)</p>
          <entity-list
            :entities="linkedEntities"
            entityKey="id"
            :withImage="false"
            :show="true"
            :showRemoveButton="false"
            :componentName="['name']"
          />
        </div>

        <div v-if="action.name != 'assign_group'" :class="action.name == 'assign_group_users' ? 'w-1/3':'w-1/2'">
          <p class="mb-2">Usuario(s) seleccionado(s)</p>
          <entity-list
            :entities="linkedUsers"
            entityKey="id"
            :withImage="true"
            :show="true"
            :showRemoveButton="false"
            :componentName="['name', 'surname']"
          />
        </div>

      </div>
      <div class="mt-8">
        <p class="font-bold">¿Seguro que desea continuar?</p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import Form from 'vform'
import moment from 'moment'
import entityList from '../utils/entity-list.vue';

Vue.use(moment)

export default {
  name: 'wizard-bulk-complete',

  components: {
    entityList
  },

  props: {
    action: { type: Object, required: true },
    validations: { type: Array, required: true },
    linkedEntities: { type: Array, required: true },
    linkedUsers: { type: Array, required: true },
  },

  data: () => ({
  }),

  mounted() {
  },

  created() {
  },

  watch: {

  },

  computed: {
  },

  methods: {
    async sendForm() {
      let url = `/api/v2/validations/templates/bulk-actions`

      // Enviamos el form
      let body = {
        action: this.action.type,
        validations: this.validations.map(v => v.id),
        entities: this.linkedEntities.map(g => g.code),
        users: this.linkedUsers.map(u => u.id),
        extra_info: null
      }
      const { data } = await axios.post(url, body)
      if (data && data.success) {
        this.$notify.success('bulk_action_added_process')
      } else {
        this.$notify.error('error_bulk_action')
      }
    },
  },
}
</script>
