<template>
  <div>
    <div class="panel p-2 relative">
      <div v-if="loadingAssigned" class="my-4 text-center">
          <scale-loader></scale-loader>
        </div>

        <div v-if="!loadingAssigned">
          <div class="flex items-center justify-between w-full mb-5">
            <div><label><b><span>{{ $t('pictogram') + ' ' + entity.name }} - </span> {{ $t('assigned_to') }}</b></label></div>
            <div>
              <b-tooltip :label="$t('close')" position="is-left">
                <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest"
                  @click="onCloseClicked">
                  <b-icon pack="fas" icon="times" size="is-small" />
                </div>
              </b-tooltip>
            </div>
          </div>

          <!-- recuadro de valores (documentSelectables) -->
          <div class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-blue-lightest mb-1"
            v-for="(value) in linkedEntities" :key="value.id">
            <div>
              <!-- delete -->
              <b-tooltip :label="$t('unlink')" position="is-left" type="is-danger">
                  <!-- @click="unlinkEntity(entity.id, value.documentable.id, entity.name, value.documentable.name, 'validation-task')" -->
                  <div class="flex cursor-pointer text-red"
                    @click="unlinkEntity(value)">
                    <b-icon pack="fas" icon="unlink"/>
                  </div>
              </b-tooltip>

              <span v-if="_ValidateType(value, 'ValidationFlowTemplate')" class="border rounded text-sm px-2 text-white bg-blue border-blue" >{{$t('flow')}}</span>
              <span v-else-if="_ValidateType(value, 'ValidationTask')" class="border rounded text-sm px-2 text-white bg-red border-red">{{$t('task')}}</span>
              <span v-else-if="_ValidateType(value, 'Activity')" class="border rounded text-sm px-2 text-white bg-green border-green">{{$t('activity')}}</span>
              <span v-else-if="_ValidateType(value, 'SubActivity')" class="border rounded text-sm px-2 text-white bg-orange border-orange">{{$t('subactivity')}}</span>

              <!-- save -->
              <span class="ml-2">{{ _GetNameEntity(value) }}</span>
            </div>
          </div>

          <!-- <div v-if="linkedEntities.length == 0" class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-gray-200 mb-1 has-text-centered">{{ $t('no_results') }}</div> -->


          <div class="flex items-center justify-end w-full mt-5" v-if="linkedEntities.length > 0 && showButtonUnlinkAll">
            <button v-if="!processingData"
              type="button"
              class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-orange hover:bg-orange-light transition ease-in-out duration-150"
              @click="unlinkEntity({ id: -1 }, 'all')"
              >
              <i class="fas fa-unlink mr-3"></i>
              {{ $t('unlink_documents') }}
            </button>

            <v-button-processing v-if="processingData" />
          </div>

          <div class="mt-3" v-if="linkedEntities.length == 0">
            <div class="flex items-center bg-blue-light text-white text-sm font-bold px-4 py-3 rounded" role="alert">
              <svg class="fill-current w-8 h-8 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
              <p>El pictograma no se encuentra asignado, seleccione la actividad o subactividad a la cual se vilculara.</p>
            </div>
          </div>

          <ActivitySelector v-if="linkedEntities.length === 0"
            ref="activity_selector"
            :projectId="projectId"
            :icon-button="'link'"
            :title-button="'link'"
            :action-button="linkEntity"
            @epc-selected-entities="(e) => { selectedEntities = e }"
          />
        </div>
    </div>
  </div>
  </template>

  <script>
  import axios from "axios";
  import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
  import ActivitySelector from "./components/activity-selector.vue";


  export default {
    name: "linked-entities",

    components: {
    ScaleLoader,
    ActivitySelector
},

    props: {
      projectId: { type: Number, required: true },
      entity: { type: Object, required: true }, // objeto con los datos de la entidad que se esta vinculando
      entityType: { type: String, required: true }, // el tipo entidad del objeto que se vincula, pictograma, documento, etc
      showButtonUnlinkAll: { type: Boolean, required: false, default: false }
    },

    data() {
      return {
        disableSaveButton: false,
        processingData: false,
        loadingAssigned: false,
        linkedEntities: [], // entidades vinculadas con la entidad

        selectedEntities: [] // entidades a ser vinculadas
      }
    },

    computed: {

    },

    watch: {
    },

    mounted() {
      this.getLinkedEntities();
    },

    methods: {
      async getLinkedEntities(params=null) {
        this.loadingAssigned = true;
        const { data } = await axios.get(this.getUrl(),
          { params: params }
        );

        if (data && data.success) {
          this.linkedEntities = data.linked_entities;
        } else {
          this.$notify.error("error_retrieving_data");
        }

        this.loadingAssigned = false;
      },

      getUrl() {
        let url = null;
        switch (this.entityType) {
          case 'pictogram':
            url = "/api/v2/pictogram/" + this.entity.id + '/linked-entities'
            break;

          default:
            break;
        }

        return url
      },

      async linkEntity() {
        if( this.selectedEntities.length == 0){
          this.$notify.error(this.$t('error_select_document'))
          return
        }

        this.processingData = true;

        let fields = [];
        this.selectedEntities.forEach(item => {
          fields.push(item);
        });

        let self = this;

        await axios.post("/api/v2/pictogram/assign", {
          entityId: this.entity.id,
          entityType: this.entityType,
          fields: fields
        })
        .then(response => {
          let data = response.data
          this.processingData = false;

          if (data && data.success) {
            self.linkedEntities = data.linked_entities;
            self.selectedEntities = [];

            self.$notify.success('success_editing')

          } else if(data && data.msg) {
            self.$notify.error('no_access_permissions')
          } else {
            self.$notify.error('error_saving')
          }
        })
        .catch(error => {
          self.processingData = false;
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
            error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("assignment_insert_error"),
            type: "is-danger",
            position: "is-top-right"
          });
        });
      },

      async unlinkEntity(linkedEntity) {
        let params = { };
        params.entityId = linkedEntity.id;
        params.entityType = this._GetEntityType(linkedEntity);

        let valueName = linkedEntity.id === -1 ? 'todas las asignaciones' : this._GetNameEntity(linkedEntity);
        let self = this;
        this.$dialog.confirm({
          title: this.$t('unlink'),
          message: this.$t('unassign_question_document', [this.entity.name, valueName]),
          confirmText: this.$t('unlink'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {

            const { data } = await axios.post(this._GetUrlUnlink(linkedEntity), params)
            if (data && data.success) {

              // reload
              this.getLinkedEntities()
              this.$notify.success('success_deleting_generic')

            } else if(data && data.msg){

              this.$notify.error('no_access_permissions')

            } else {
              this.$notify.error('error_saving')
            }
          }
        })
      },

      _GetUrlUnlink(entity) {
        let url = null
        if( entity.documentable_type ) {
          url = "/api/v2/document/" + this.entity.id + "/unassign"
        } else {
          if (this.entityType === 'pictogram')
            url = "/api/v2/pictogram/" + this.entity.id + "/unlink"
        }

        return url
      },

      _GetEntityType(entity) {
        // TODO: ajustar esta logica para otros casos si existiera
        let type = 'activity'  // por defecto asumimos que el modelo(entity) es de tipo actividad

        if( entity.documentable_type ) {

          if ( entity.documentable_type.includes('ValidationFlowTemplate'))
            type = 'validation-template'

          else if (entity.documentable_type.includes('ValidationTask'))
            type = 'validation-task'

        }

        return type
      },

      _ValidateType(entity, type) {
        // Verificamos primero si existe la propiedad documentable_type si existe procedemos a la verificación con el tipo enviado
        // si no consideramos que todas las entidades son actividades o subactividades
        // TODO: ajustar esta logica para otros casos si existiera
        let valid = false

        if( entity.documentable_type ) {
          switch (type.toLowerCase()) {
            case 'activity':
              valid = entity.documentable_type.includes(type) && !entity.documentable.parent_id
              break;

            case 'subactivity':
              valid = entity.documentable_type.includes('Activity') && entity.documentable.parent_id
              break;

            default:
              valid = entity.documentable_type.includes(type)
              break;
          }

        } else {
          if (type.toLowerCase() === 'activity') {
            valid = !entity.parent_id
          } else if (type.toLowerCase() === 'subactivity') {
            valid = entity.parent_id
          }
        }

        return valid
      },

      _GetNameEntity(entity) {
        if (entity.documentable) {
          return entity.documentable.name
        } else {
          return entity.name
        }
      },

      onCloseClicked() {
        this.$emit('epc-close', true)
      },
    },
  };
  </script>



