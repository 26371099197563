<template>
  <div class="w-1/3 viewer-right-panel">
    <div class="header relative w-full">
      <span class="m-3 block">Comentarios</span>
      <div class="viewer-panel__close-btn" @click="() => { $emit('epc-close', true) }">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="ga-left-panel-close"><g data-name="<Group>"><path d="M7.31 17.5a.5.5 0 0 1-.31-.85l10-10a.5.5 0 0 1 .71.71l-10 10a.5.5 0 0 1-.4.14z"></path><path d="M17.31 17.5a.5.5 0 0 1-.31-.15l-10-10a.5.5 0 0 1 .71-.71l10 10a.5.5 0 0 1-.4.86z"></path></g></svg>
      </div>
    </div>
    <div class="h-3" style="background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);"></div>
    <div class="bg-white">
      <span class="m-3 block">Opción para seleccionar layout o element</span>

      <div class="form-comment-container">
        <div>
          <div class="screenshot-container">
            <img :src="screenshot" alt="" class="img_screenshot">
          </div>
          <div class="fields-container">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "ViewerPanelViews",

    props: {
      views: { type: Array, default: [] },
      screenshot: { type: String, default: null },
      workLayer: { type: String, default: null }
    },

    watch: {
    },

    data() {
      return {

      }
    },

    methods: {
      _GetIcon(layer) {
        return layer.icon ?? 'grip-horizontal' // 'images'
      },

      _HandleClick(lyr) {
        if ( !this.workLayer )
          this.$emit('epc-view-selected', lyr.code)
      }
    }
  }

</script>

<style scoped>

  .active {
    border: 1px solid #5577bb;
    color: #5577bb;
  }
  .viewer-right-panel {
    border-left: 1px solid #d4dbe1;
    border-top: 1px solid #d4dbe1;
    background: linear-gradient(to top, #eeeeee 0, #f7f7f7 8px, #fafafa 12px, #fff 20px);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.3);
  }
  .viewer-panel__close-btn {
      position: absolute;
      top: 0px;
      right: 5px;
      float: none;
      fill: #939ca5;
      cursor: pointer;
      display: block;
      z-index: 1;
      /* width: 40px; */
      /* height: 40px; */
  }

  .screenshot-container {
    width: 100%;
    border: 1px solid #d4dbe1;
    text-align: center;
    margin-bottom: 15px;
    border-radius: 4px;
    min-height: 50px;
    position: relative;
  }
  .img_screenshot {
    max-height: 150px;
    max-width: 100%;
    width: auto;
    border: 0;
    margin: 0;
    border-radius: 0;
  }

</style>
