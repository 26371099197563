<template>
<b-field
  :label="label ? $t(label) : null"
  :type="form.errors.hasAny(startField, endField) ? 'is-danger' : ''"
  :message="form.errors.hasAny(startField, endField) ? form.errors.only(startField, endField) : null">
  <div class="flex items-center">
    <!--:max-date="form[endField] !== null ? form[endField] : null"-->
    <b-datepicker ref="start"
      v-model="form[startField]"
      :placeholder="$t('start_date')"
      :readonly="false"
      :min-date="form[minStartField] != null ? form[minStartField] : null"
      :max-date="form[maxEndField] !== null ? form[maxEndField] : null"
      :month-names="$t('months_names')"
      :day-names="$t('days_names')"
      :first-day-of-week="parseInt($t('first_day_of_week'))"
      class="w-1/2 mr-1"
      icon-pack="fas"
      :disabled="disabled"
      @input="checkStartDate"
    />
    <b-datepicker ref="end"
      v-model="form[endField]"
      :placeholder="$t('delivery_date')"
      :readonly="false"
      :min-date="form[startField] != null ? form[startField] : null"
      :max-date="form[maxEndField] !== null ? form[maxEndField] : null"
      :month-names="$t('months_names')"
      :day-names="$t('days_names')"
      :first-day-of-week="parseInt($t('first_day_of_week'))"
      class="w-1/2"
      icon-pack="fas"
      :disabled="disabled"
    />
  </div>
</b-field>
</template>

<script>
export default {
  name: 'l-form-double-date',

  props: {
    // The vform object
    form: { type: Object, required: true },
    // The Start Date field name
    startField: { type: String, required: true },
    // The End Date field name
    endField: { type: String, required: true },
    minStartField: { type: String, required: false, default: null },
    maxEndField: { type: String, required: false, default: null },
    // Key for the label text
    label: { type: String, default: null },
    //Para ediciones de actividades por parte de responsable
    disabled: { type: Boolean, default: false, required: false },
  },

  computed: {
  },

  mounted() {
    // Valores iniciales para la fecha
    if (this.form[this.startField] === null) {
      this.form[this.startField] = new Date()
    }
    if (this.form[this.endField] === null) {
      this.form[this.endField] = new Date()
    }
  },

  methods: {

    setFocus() {
      this.$refs.start.focus()
    },

    checkStartDate(date) {
      if (date > this.form[this.endField]) {
        this.form[this.endField] = date
      }
    }
  },

};
</script>
