<template>
<div>
  <menu-views></menu-views>

  <!-- Filtros -->
  <folders-filters v-if="false"
      @foldersfilters-updated="filtersUpdated"
      :locationsList="locationsList"
      ref="foldersFiltersRef"/>

  <!-- Botonera, sólo Jefes de departamento -->
  <div class="w-full h-12" v-if="false">
      <div class="float-right mt-1 w-full">

      <!-- sólo Jefes de departamento -->
      <template v-if="project && project.user_department.can_admin">

          <!-- Botón para ver Kanban-->
          <div class="rounded-sm mr-2 float-right">
          <v-button icon="columns" class="mr-4" @click="displayKanban()">{{$t('display_kanban')}}</v-button>
          </div>

          <!-- <div class="rounded-sm mr-2 float-right">
          <v-button icon="plus" class="mr-4" @click="onCreateActivityClicked()">{{$t('add_activity')}}</v-button>
          </div> -->

      </template>

      <div class="rounded-sm mr-2 float-right">
          <v-button icon="table" class="mr-4" @click="displayTable()">{{$t('display_table')}}</v-button>
      </div>


      <div class="rounded-sm mr-2 float-right" v-if="canAdd">
          <v-button icon="plus" class="mr-4" @click="onAddClicked()">{{$t('add_document')}}</v-button>
      </div>

      <!-- Modal -->
      <upload-document
          ref="modal"
          :upload-endpoint="'/api/v2/' + entityType + '/' + currentActivity.id + '/document'"
          :allow-multiple-files="true"
          @success="successfullyUploaded"
          v-if="canAdd"
          :entityId="currentActivity.id"
          :entityType="entityType"
          :selected-folder="currentFolder"
          :upload-folders="true"
          :notify-users="true"
          :documents="documents"
      />

      </div>
  </div>

  <!-- Breadcrumb -->
  <breadcrumb ref="ref_breadcrumb"></breadcrumb>

  <div class="flex w-full mt-4 relative">
      <!-- BLOCK TREE FOLDER -->
      <div v-show="showTreeFolder"
        class="mr-3 p-3 absolute lg:relative top-0 z-40 lg:z-0 bg-white w-60 lg:w-80- lg:w-1/4"
        style="border: 2px solid #adc2ea; border-radius: .4em;">
        <div class="mb-2">
          <div class="mb-2 text-right">
            <button class="rounded-full inline-block text-center w-9 h-9"
              style="background-color: rgba(85, 119, 187, 0.133);"
              @click="() => { showTreeFolder = false }">
              <b-icon icon="times" pack="fas"></b-icon>
            </button>
          </div>
          <b-field>
              <b-input :placeholder="$t('name')"
                  v-model="qNameFolder"
                  icon="search"
                  icon-pack="fas"
                  icon-right="sliders-h"
                  icon-right-clickable
                  @icon-right-click="openFilterAvancesFolderClick"
                  @input="_HandleInputFilterFolderSearch(qNameFolder)">
              </b-input>
          </b-field>
        </div>
        <div class="folder_tree">
            <FolderTree v-for="(folder, index) in folders" :indx="[index]" :key="folder.id" :node="folder"/>
        </div>

        <folders-filters-modal ref="refModalFilterFolders"
          :locations-list="locationsList"
          :with-button="false"
          @epc-apply-filters="_HandleApplyFilters"/>
      </div>


      <div class="flex flex-col w-full"
        :class="{ 'lg:w-3/4': showTreeFolder }">
        <div class="flex w-full mb-2">
          <b-field class="w-full mb-0">
              <b-input :placeholder="$t('name')"
                  v-model="qNameFilename"
                  icon="search"
                  icon-pack="fas"
                  icon-right="sliders-h"
                  icon-right-clickable
                  class="w-full"
                  @icon-right-click="openFilterAvancesFileClick"
                  @input="_HandleInputFilterFileSearch(qNameFilename)">
              </b-input>
          </b-field>

          <files-filters-modal ref="refModalFilterFiles"
            :type-list="typeList"
            :status-list="statusList"
            :discipline-list="disciplineList"
            :with-button="false"
            @epc-apply-filters="_HandleApplyFiltersFiles"
          />
        </div>

        <div class="flex w-full mb-2">
          <span v-if="!showTreeFolder"
            class="cursor-pointer flex items-center rounded-lg mr-1"
            style="background: #57b2; color: #358"
            onmouseover="this.style.background='#57b3'"
            onmouseout="this.style.background='#57b2'"
            @click="() => { showTreeFolder = true }"> <!-- v-if="canAdd" -->
            <span class="hidden lg:inline" style="display: none;"></span>
            <svg xmlns="http://www.w3.org/2000/svg"
              version="1.0" class="w-9 h-9"
              viewBox="0 0 752.000000 752.000000" preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,752.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path d="M2240 6112 c-19 -9 -45 -32 -57 -51 -22 -33 -23 -41 -23 -281 0 -240 1 -248 23 -281 43 -64 64 -69 325 -69 209 0 237 2 270 19 69 36 72 48 72 331 0 286 -2 295 -75 330 -38 18 -63 20 -271 20 -198 0 -234 -2 -264 -18z"/>
                <path d="M2390 5130 c-20 -20 -20 -33 -20 -1859 l0 -1840 26 -20 c22 -17 41 -21 105 -21 97 0 123 10 138 51 8 23 11 429 11 1455 l0 1424 223 0 c273 0 257 -8 257 137 0 144 12 137 -261 141 l-218 3 -3 254 c-4 312 4 295 -140 295 -85 0 -101 -3 -118 -20z"/>
                <path d="M3477 4782 c-15 -9 -36 -35 -47 -58 -18 -36 -20 -62 -20 -270 0 -256 5 -278 69 -321 33 -22 41 -23 281 -23 240 0 248 1 281 23 64 43 69 65 69 321 0 208 -2 233 -20 271 -35 73 -44 75 -332 75 -222 0 -256 -3 -281 -18z"/>
                <path d="M3645 3805 l-25 -24 0 -928 c0 -1035 -4 -971 69 -1020 33 -23 38 -23 341 -23 380 0 355 -10 355 140 0 146 11 140 -266 140 l-219 0 0 485 0 485 210 0 c281 0 280 -1 280 151 0 62 -4 81 -21 103 l-20 26 -225 0 -224 0 0 220 0 221 -25 24 c-22 23 -32 25 -115 25 -83 0 -93 -2 -115 -25z"/>
                <path d="M4743 3535 c-18 -8 -42 -29 -53 -47 -19 -31 -20 -50 -20 -292 l0 -258 38 -38 c48 -48 80 -53 347 -48 222 3 248 10 285 71 18 29 20 52 20 277 0 363 12 350 -345 350 -183 -1 -248 -4 -272 -15z"/>
                <path d="M4745 2280 c-73 -36 -75 -45 -75 -330 0 -363 -13 -350 342 -350 261 0 282 5 325 69 22 33 23 41 23 281 0 240 -1 248 -23 281 -43 64 -64 69 -322 69 -210 0 -234 -2 -270 -20z"/>
              </g>
            </svg>
          </span>
          <span
            class="p-2 cursor-pointer flex items-center rounded-lg"
            style="background: #57b2; color: #358"
            onmouseover="this.style.background='#57b3'"
            onmouseout="this.style.background='#57b2'"
            @click="onAddClicked()"> <!-- v-if="canAdd" -->
            <span class="hidden lg:inline" style="display: none;"></span> <b-icon pack="fas" icon="file-upload" />
          </span>

          <!-- Modal -->
          <upload-document
              ref="modal"
              :upload-endpoint="'/api/v2/' + entityType + '/' + currentActivity.id + '/document'"
              :allow-multiple-files="true"
              @success="reloadFolders"
              :entityId="currentActivity.id"
              :entityType="entityType"
              :selected-folder="currentFolder"
              :upload-folders="true"
              :notify-users="true"
              :documents="documents"
          /> <!-- v-if="canAdd" -->
        </div>



        <div class="documents">
          <b-table
              :data="documents"
              :loading="isLoading"
              :default-sort="orderField"
              :default-sort-direction="orderDir"
              @sort="onSort"

              class="w-full"
              striped
              bordered
              icon-pack="fas"
              :mobile-cards="false"
          >

            <template slot="empty">
              <div v-if="! isLoading" class="text-lg text-dark-grey">
                <span v-if="currentActivity.id === undefined">Seleccione una carpeta</span>
                <span v-else>No hay elementos que mostrar</span>
              </div>
            </template>

            <template>

              <b-table-column field="id" :label="$t('id')" sortable v-slot="props">
                  {{ props.row.document_id }}
              </b-table-column>

              <b-table-column field="file" :label="$t('file')" sortable v-slot="props">
                  <span class="cursor-pointer" @click="showPreview(props.row)">
                    {{ props.row.stored_filename }}
                  </span>
              </b-table-column>

              <b-table-column field="name" :label="$t('name')" sortable v-slot="props">
                  <span class="cursor-pointer" @click="showPreview(props.row)">
                    {{ props.row.name }}
                  </span>
              </b-table-column>

              <b-table-column field="type" :label="$t('type')" sortable v-slot="props">
                  {{ props.row.document_type? props.row.document_type.name : '' }}
              </b-table-column>

              <b-table-column field="created_at" :label="$t('created_at')" sortable v-slot="props">
                  {{ props.row.created_at.substring(0,10) }}
              </b-table-column>

              <b-table-column field="updated_at" :label="$t('updated')" sortable v-slot="props">
                  {{ props.row.updated_at.substring(0,10) }}
              </b-table-column>

              <b-table-column field="review_code" :label="$t('review_code')" sortable v-slot="props">
                  {{ props.row.versions.length + 1 }}
              </b-table-column>

              <b-table-column field="flow_status" :label="$t('flow_status')" sortable v-slot="props">
                  <validation-task-status-label v-if="props.row.related_validation_task" :status="props.row.related_validation_task.status" class="w-full" style="vertical-align: middle"/>
                  <validation-status-label v-else-if="props.row.related_validation" :status="props.row.related_validation.status" class="w-full" style="vertical-align: middle"/>
              </b-table-column>

              <b-table-column field="document_status" :label="$t('document_status')" sortable v-slot="props">
                  {{ props.row.document_status? props.row.document_status.name : '' }}
              </b-table-column>

              <b-table-column field="author" :label="$t('author')" sortable v-slot="props">
                  {{ props.row.user.fullname }}
              </b-table-column>

              <b-table-column field="tags" :label="$t('tags')" sortable v-slot="props">
                  {{ props.row.tags.join(', ') }}
              </b-table-column>

              <b-table-column field="attached" :label="$t('attached')" sortable v-slot="props">
                  <span v-for="attached in props.row.attached_documents" class="label_attached cursor-pointer" @click="showPreview(attached)">
                      {{attached.name}}
                  </span>
              </b-table-column>

              <b-table-column field="document_discipline" :label="$t('discipline')" sortable v-slot="props">
                  {{ props.row.document_discipline? props.row.document_discipline.name : '' }}
              </b-table-column>

              <b-table-column :label="$t('actions')" field="actions" centered width="150" v-slot="props">
                  <div class="w-full h-full flex items-center justify-around">
                    <b-dropdown
                        :mobile-modal="false"
                        position="is-bottom-left"
                        aria-role="list"
                      >
                        <template #trigger>
                          <b-icon icon="ellipsis-v" pack="fas"></b-icon>
                        </template>

                        <!--Restaurar documento, solo en carpeta papelera-->
                        <b-dropdown-item
                          v-if="currentFolder.id == -1 && canManageDocuments"
                          aria-role="listitem" @click="undelete(props.row)" >
                          <b-icon pack="fas" icon="trash-arrow-up" />
                          <span class="text-xs">{{ $t('restore') }}</span>
                        </b-dropdown-item>


                        <!--eliminar-->
                        <b-dropdown-item v-if="currentFolder.id != -1" aria-role="listitem" @click="onDelete(props.row)" >
                            <b-icon pack="fas" icon="trash-alt" />
                          <span class="text-xs">{{ $t('delete') }}</span>
                        </b-dropdown-item>

                        <!--Descargar documento-->
                        <b-dropdown-item
                          v-if="currentFolder.id != -1"
                          aria-role="listitem" @click="downloadDoc(props.row)" >
                          <b-icon pack="fas" icon="download" />
                          <span class="text-xs">{{ $t(documentDownloadTooltip('download_document', props.row))}}</span>
                        </b-dropdown-item>

                        <!--Subir versión-->
                        <b-dropdown-item
                          v-if="currentFolder.id != -1 && showVersions && entityType != 'validation' && props.row.related_validation==null && canManageDocuments"
                          aria-role="listitem" :disabled="!isOk(props.row)" @click="openUploadModal(props.row.document_id)" >
                          <b-icon pack="fas" icon="upload" />
                          <span class="text-xs">{{ $t(documentDownloadTooltip('upload_version', props.row))}}</span>
                        </b-dropdown-item>

                        <!--Open versión modal-->
                        <b-dropdown-item
                            v-if="currentFolder.id != -1 && showVersions && props.row.versions.length > 0"
                            aria-role="listitem"
                            :disabled="props.row.versions.length <= 0 || !isOk(props.row)"
                            @click="openVersionsModal(props.row.document_id)"  >
                          <b-icon pack="fas" icon="history" />
                          <span class="text-xs">{{ $t('display_versions')}}</span>
                          <span v-if="props.row.versions.length > 0"
                              class="ml-1 text-xs text-white font-medium px-2 rounded-full"
                              style="background-color: #5577bb;">{{ props.row.versions.length }}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                    <!-- Modal para subir versión -->
                    <upload-document
                        v-if="currentFolder.id != -1"
                        :ref="'upload_modal_' + props.row.document_id"
                        :upload-endpoint="'/api/v2/document/' + props.row.document_id + '/add_version/' + entityType + '/' + selectedEntityId"
                        :entity-id="selectedEntityId"
                        :entity-type="entityType"
                        :notify-users="true"
                        @success="() => reloadFolders()"
                    />

                    <!-- Modal para las versiones -->
                    <document-versions
                        v-if="currentFolder.id != -1"
                        :ref="'versions_modal_' + props.row.document_id"
                        :document="props.row"
                        :entityId="currentActivity.id"
                        :entityType="'activity'"
                        @reload-documents-list="() => reloadFolders()"
                    />
                  </div>
              </b-table-column>


            </template>
          </b-table>
        </div>
      </div>
    </div>

    <entity-document-preview
      :entity-id="currentActivity.id ? currentActivity.id : 0"
      :entity-type="'activity'"
      :document-id="documentId"
      ref="previewModal"
      :can-delete="canDeleteDocument"
      :showVersions="showVersions"
      @reload-documents-list="() => reloadFolders()"
    />
</div>

</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import EventBus from '~/plugins/bus'
  import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
  import FolderTree from "./folder-tree.vue";
  import foldersFilters from "·/components/documents-folders/folders-filters";
  import uploadDocument from "../../../components/upload-document";
  import validationStatusLabel from '·/components/validations/validation-status-label';
  import validationTaskStatusLabel from '·/components/validations/validation-task-status-label';
  import MenuViews from './components/menu-views.vue'
  import Breadcrumb from './components/breadcrumb.vue'
  import FoldersFiltersModal from '../../../components/documents-folders/folders-filters-modal.vue'
  import FilesFiltersModal from '../../../components/documents-folders/files-filters-modal.vue'

  import entityDocumentPreview from "·/components/entity-document-preview";
  import documentVersions from "·/components/document-versions";

  export default {
    middleware: 'auth',
    title: 'activities_page_title',
    subtitle: 'activities_page_subtitle',
    menuOption: '2-3',

    components: {
      ScaleLoader,
      FolderTree,
      foldersFilters,
      uploadDocument,
      validationStatusLabel,
      validationTaskStatusLabel,
      MenuViews,
      Breadcrumb,
      FoldersFiltersModal,
      FilesFiltersModal,
      entityDocumentPreview,
      documentVersions,
    },

    computed: {
      ...mapGetters({
        user: "auth/user",
        project: 'app/project',
        mobile: 'device/mobile'
      })
    },

    data: () => ({
      isLoading: true,
      documents: [],
      allDocuments: [],
      folders: [],
      foldersSub: [],
      currentActivity: {},
      currentFolder: {},
      currentIndex: [],
      lastfolder: {},
      loadSubactivities: false,
      canAdd: false,
      canManageFolders: false,
      selectedEntityId: 0,
      entityType: 'activity',
      canManageFolders: false,
      isProjectManager: false,
      isProjectParticipant: false,
      isProjectCollaborator: false,
      canManageDocuments: false,
      locationsList: [],
      orderField: 'id',
      orderDir: 'asc',
      showFilters: false,
      typeList: [],
      statusList: [],
      disciplineList: [],
      filters: {
        type: null,
        status: null,
        discipline: null,
        name: null,
        tag: null,
        created: null,
      },

      qNameFolder: null,
      qNameFilename: null,
      showTreeFolder: false,

      documentId: null,
      canDeleteDocument: false,
      showVersions: true,
      filesToDelete: []
    }),

    watch: {
        allDocuments() {
            this.filterDocuments({})
        },

        mobile() {
          this.showTreeFolder = !this.mobile.on_mobile
        }
    },

    mounted() {
      this.getActivitiesList()
      this.loadDocumentFilters()
    },

    created() {
        this.showTreeFolder = !this.mobile.on_mobile
        EventBus.$on('openFolder', (params) => {
            this.openFolder(params.index, params.folder)
        });
    },

    beforeDestroy(){
        EventBus.$off("openFolder");
    },

    methods: {

      async getActivitiesList() {

        let args = {}

        // if (this.$refs.foldersFiltersRef !== undefined) {
        //   args = this.$refs.foldersFiltersRef.generateFilters();
        // }

        if (this.$refs.refModalFilterFolders !== undefined) {
          args = this.$refs.refModalFilterFolders.getFilters();
        }

        if ( this.qNameFolder != null ) {
          args.n = this.qNameFolder
        }

        args.p = this.project.id
        args.with_location = 1
        args.pg = 1

        this.isLoading = true

        const {data} = await axios.get('/api/v2/activities', {params: args})

        if (data && data.activities) {
          this.folders = data.activities.map(function(activity) {
            return {
                id: activity.id,
                name: activity.planning_code + ' - ' + activity.name,
                code: null,
                is_virtual: true,
                is_current: false,
                location: activity.location,
                children: []
            }
          })
          this.allDocuments = []
          this.locationsList = this.getLocationsList(this.folders)

        } else {
          this.$notify.error('error_loading_activities')
        }

        this.isLoading = false
      },


      // devuelve listado subactividades para pintarlas como carpetas virtuales de la actividad
      async getSubactivities(activityId) {
        let url = '/api/v2/activity/' + activityId + '/subactivities'
        let self = this
        await axios.get(url)
          .then(function (response) {

            if (response.data && response.data.subactivities) {
              self.foldersSub = response.data.subactivities.map(function(activity) {
                return {
                  id: activity.id,
                  name: activity.planning_code + ' - ' + activity.name,
                  code: null,
                  is_virtual: true,
                  is_current: false,
                  children: []
                }
              })

            }
          })
      },


      // puede ser carpeta virtual (una actividad) o carpeta de documentos dentro de la actividad
      openFolder(index, folder, loadSubactivities = false) {
        this.$refs.ref_breadcrumb.setTree(folder, index)
        // caso base, abrir carpeta raiz raiz de todo
        if (folder.id === null && folder.is_virtual) {
          this.canManageFolders = false
          this.canAdd = false
          this.getActivitiesList()
          return
        }

        this.loadSubactivities = loadSubactivities

        if (folder.is_virtual) {
          this.currentActivity.id = folder.id // carpeta ficticia actividad, abre documentFolders que cuelgan del raiz
          this.currentFolder.id = null // empezamos por la raiz
          // carga subcarpetas virtuales (las subactividades)
          this.loadSubactivities = true
          this.getActivityFolder(this.currentActivity.id)
        } else {
          this.currentFolder.id = folder.id //carpeta 'de verdad' documentFolder, abre lo que cuelga de este id
          this.currentFolder.is_virtual = false
        }
        this.currentIndex = index

        // carga documentos y carpetas que cuelgan de esta actividad/documentFolder
        this.loadDocumentsList(index, this.currentActivity.id, 'activity', this.currentFolder.id, this.loadSubactivities)

      },

      async loadDocumentsList(index, entityId, entityType, documentFolder=null, loadSubactivities=false) {

        this.isLoading = true
        let self = this

        let params = {
          per_page: self.perPage,
          page: self.page,
          order_field: self.order_field,
          order_dir: self.order_dir,
          with_folders: 1, // ordenado en carpetas
          folder_id: documentFolder, // docs y carpetas que cuelgan de esta carpeta (del raiz si es null),
          with_attachments: 1
        }

        await axios.get("/api/v2/"+ entityType +"/" + entityId + "/documents?-=1",
          { params: params })
          .then(function(response) {
            let findFolder = self.findObjectByIndex(self.folders, index);
            findFolder.children = []
            if (loadSubactivities) {

                self.getSubactivities(entityId).then(function(response) {

                    if (findFolder) {
                        findFolder.children.push(...self.foldersSub)
                        self.foldersSub = []
                    }
                })

            }

            if (response.data && response.data.documents) {
              self.allDocuments = response.data.documents
              let folders = response.data.folders.map(function(folder) {
                  return {
                    id: folder.id,
                    name: folder.name,
                    code: folder.code,
                    is_virtual: false,
                    is_current: false,
                    icon: folder.icon,
                    documents_count: folder.documents_count,
                    children: []
                  }
                })

              self.currentFolder = response.data.currentFolder || {}

              if (findFolder) {
                findFolder.children.push(...folders)
                findFolder.is_current = true
                self.lastfolder.is_current = false
                self.lastfolder = findFolder
              }
            }
          })
          .catch(function(error) {
            console.log(error);
          })
          .finally(function () {
            self.isLoading = false;
          });
      },

      findObjectByIndex(tree, indexArray) {
        if (indexArray.length === 0) {
            return null; // No se encontró ningún objeto con los índices dados
        }

        const currentIndex = indexArray[0];
        if (currentIndex < 0 || currentIndex >= tree.length) {
            return null; // Índice fuera de rango
        }

        const currentObject = tree[currentIndex];

        if (indexArray.length === 1) {
            return currentObject; // Este es el objeto que estábamos buscando
        }

        // Recursivamente busca en el subárbol
        return this.findObjectByIndex(currentObject?.children || [], indexArray.slice(1));
      },

      getActivityFolder(entityId) {
        let self = this;

        axios.get("/api/v2/activity/" + entityId + "/", { params: {with_folders: 1} })
            .then(function(response) {
              self.selectedEntityId = entityId
              self.canAdd = (response.data.data.is_creator || response.data.data.is_manager || response.data.data.is_participant || response.data.data.is_collaborator) && !(response.data.data.status == 6) && (self.currentActivity.id && self.currentActivity.id > 0)
              self.canManageFolders = (response.data.data.is_project_manager || response.data.data.is_manager) && ( self.currentActivity.id && self.currentActivity.id > 0)
              self.isProjectManager = response.data.data.is_project_manager
              self.isProjectParticipant = response.data.data.is_project_participant
              self.isProjectCollaborator = response.data.data.is_project_collaborator
              self.canManageDocuments = (response.data.data.is_creator || response.data.data.is_manager || response.data.data.is_participant || response.data.data.is_collaborator)
            })
      },

      // a partir de la lista de carpetas con sus localizaciones devuelve array unico de localizaciones para filtros
      getLocationsList(folders) {
        let locations = folders.map(folder => folder.location)
        let locationsUnique = [] // ids de locations procesadas

        // devuelve las no repetidas
        return locations.filter(function(location){
          if (! locationsUnique.includes(location.id)) {
            locationsUnique.push(location.id)
            return true;
          }
        })
      },

      filtersUpdated() {
        this.folders = []
        this.getActivitiesList();
      },

      async loadDocumentFilters() {
        let types = await axios.get('/api/v2/document-types', {params: {p: this.project.id}});

        if (types.data.success) {
            this.typeList = types.data.data
        }

        let statuses = await axios.get('/api/v2/document-statuses', {params: {p: this.project.id}});

        if (statuses.data.success) {
            this.statusList = statuses.data.data
        }

        let disciplines = await axios.get('/api/v2/document-disciplines', {params: {p: this.project.id}});

        if (disciplines.data.success) {
            this.disciplineList = disciplines.data.data
        }
      },

      filterDocuments(filters) {
        console.log('Filtrar documents: ', filters)

        this.documents = this.allDocuments.filter(doc => {
            let ok = true
            if (filters.type) {
                ok = ok && filters.type == doc.document_type?.id
            }
            if (filters.status) {
                ok = ok && filters.status == doc.document_status?.id
            }
            if (filters.discipline) {
                ok = ok && filters.discipline == doc.document_discipline?.id
            }
            if (filters.name) {
                ok = ok && doc.name.toLowerCase().includes(filters.name)
            }
            if (filters.tag) {
                ok = ok && doc.tags.join(', ').toLowerCase().includes(filters.tag)
            }
            if (filters.created) {
                ok = ok && filters.created.toISOString().substring(0,10).split('-').reverse().join('/') == doc.created_at.substring(0,10)
            }

            return ok
        })
      },

      cleanFiltersClicked() {
        this.filters = {
            type: null,
            status: null,
            discipline: null,
            name: null,
            tag: null,
            created: null,
        }
        this.applyFilters()
      },

      onSort(field, order) {
        this.orderField = field
        this.orderDir = order
      },

      onAddClicked() {
        this.$refs.modal.show();
      },

      successfullyUploaded()
      {
        this.openFolder(this.currentIndex, this.currentFolder, true)
      },

      reloadFolders()
      {
        this.openFolder(this.currentIndex, this.currentFolder, true)
      },

      showPreview(document) {
        this.documentId = document.document_id
        this.$refs.previewModal.show(document);
        if (document.exif) {
          EventBus.$emit("load-locations", document.exif);
        }
      },

      openUploadModal(docId) {
        this.$refs['upload_modal_' + docId].show();
      },

      async undelete(document) {
        let self = this;
        const { data } = await axios.post("/api/v2/document/" + document.document_id + "/undelete", {})

        if (data && data.success) {
          this.$notify.success('document_restored')
          self.openFolder(this.currentIndex, this.currentFolder, true)

        } else if (data && data.msg) {
          this.$notify.error(data.msg)

        } else {
          this.$notify.error('error_retrieving_data')
        }
      },

      openVersionsModal(docId) {
        this.$refs['versions_modal_' + docId].show();
      },


      isOk(document){
        return document.status == 1;
      },

      documentDownloadTooltip(tooltip, document) {
        if(this.isOk(document))
          return tooltip;

        return 'document_still_processing';
      },

      async downloadDoc(document) {
        this.$notify.success("starting_download_document");
        let url = "/api/v2/document/" + document.document_id;

        const {data} = await axios.get(url);

        if (data) {

          window.open(data.data);

        } else {
          this.$notify.error("error_download_document");
        }
      },

      onDelete(document) {
        this.$dialog.confirm({
          title: this.$t("delete_document"),
          message: this.$t("delete_document_confirm_text"),
          confirmText: this.$t("delete_document"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            this.filesToDelete.push( document.document_id )
            let url = "/api/v2/" + this.entityType + "/" + this.selectedEntityId + "/document/" + document.document_id;
            const {data} = await axios.post(url);
            if (data && data.success) {
              this.$notify.success("success_deleting_document");

              this.openFolder(this.currentIndex, this.currentFolder, true)
            } else {
              this.$notify.error("error_deleting_document");
            }

            // quitamos el id del documento si existe en el filestodelete se haya o no eliminado
            let index = this.filesToDelete.findIndex( id => id == document.document_id )
            if ( index != -1 )
              this.filesToDelete.splice(index, 1)
          }
        });
      },

      isBeingEliminated(document) {
        return this.filesToDelete.findIndex( id => id == document.document_id ) != -1
      },

        /**
       * Cambia la ventana a la tabla de actividades normal
       */
      displayTable() {
        this.$router.push({name: 'project.activities'});
      },


      /**
       * Cambia la ventana a la vista kanban
       */
      displayKanban() {
        this.$router.push({name: 'project.activities.kanban'});
      },

      openFilterAvancesFolderClick() {
        this.$refs.refModalFilterFolders.show()
      },

      openFilterAvancesFileClick() {
        this.$refs.refModalFilterFiles.show()
      },

      _HandleInputFilterFolderSearch(filter) {
        if (filter.length >= 3 || filter.length == 0) {
          this.folders = []
          this.getActivitiesList();
        }
      },

      _HandleApplyFilters(args) {
        this.folders = []
        this.getActivitiesList();
      },

      _HandleApplyFiltersFiles(args) {
        this.filterDocuments(args)
      }
    },
  };
</script>

<style scoped>
.folder_tree {
    font-size: .8em;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    white-space: nowrap;
}
.documents {
    font-size: .8em;
    /* width: 75%; */
    height: 100%;
    /* overflow-x: scroll; */
    overflow-y: auto;
    white-space: nowrap;
}
.label_attached {
    background: #ccc;
    padding: 3px;
    border-radius: .8em;
    margin-right: 2px;
}

@media (min-width: 1024px) {
  .lg\:inline {
    display: inline !important;
  }
}
</style>
