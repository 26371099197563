<template>
  <b-modal :active.sync="isModalActive" :width="640" scroll="keep">
    <div class="flex flex-col border border-blue-light rounded shadow bg-white p-4">

      <div class="flex flex-col w-full">
        <div class="uppercase font-semibold text-blue">EDITAR TAREA</div>
        <div class="text-blue">Datos sobre la tarea</div>
      </div>

      <form @submit.prevent="submitForm">

        <div class="flex flex-col w-full mt-4">

          <div class="flex flex-row">

          <div class="flex flex-col w-2/4 mr-2">
            <div class="text-xs text-blue font-medium">{{ $t("code") }}</div>
            <div class="flex flex-row w-full">
              <input
                v-model="form.code"
                title="code"
                required="true"
                name="code"
                class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
              />
            </div>
          </div>

          <div class="flex flex-col w-2/4 mr-2">
            <div class="text-xs text-blue font-medium">{{ $t("weighting") }}</div>
            <div class="flex flex-row w-full">
              <input
                v-model="form.weight"
                title="weighting"
                required="true"
                name="weight"
                class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
              />
            </div>
          </div>



          </div>


        <div class="flex flex-col mt-2">

          <div class="flex flex-col w-full">
            <div class="text-xs text-blue font-medium">{{ $t("name") }}</div>
            <div class="flex flex-row w-full">
              <input
                v-model="form.name"
                title="name"
                required="true"
                name="name"
                class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
              />
            </div>
          </div>

          <div class="flex flex-col w-full mt-2">
            <div class="text-xs text-blue font-medium">{{ $t("description") }}</div>
            <div class="flex flex-row w-full">
              <textarea
                v-model="form.description"
                title="description"
                required="false"
                name="description"
                class="w-full h-16 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
              />
            </div>
          </div>



        </div>

      </div>

      <div class="flex flex-row justify-start w-full mt-6 items-center">
        <button class="btn btn-blue" type="submit">{{$t('save')}}</button>
        <span class="text-red text-xs ml-2 cursor-pointer"
              v-if="isAdmin()"
              @click.prevent="deleteTask">{{$t('delete')}}</span>
      </div>
      </form>

    </div>
  </b-modal>
</template>

<script>
  import axios from "axios";
  import {mapGetters} from "vuex";

  export default {
    name: "task-update-modal",

    components: {},

    props: {
      entityId: {type: Number, required: true},
      entityType: {type: String, required: true},
      canManage: {type: Boolean, default: false}
    },

    data: () => ({
      isModalActive: false,
      id: null,
      data: {},
      unitsList: [],
      form: {}
    }),

    computed: {
      ...mapGetters({
        project: "app/project",
        user: "auth/user"
      })
    },

    methods: {
      /**
       * Envía el formulario para editar y/o añadir una linea
       */
      async submitForm() {
        let data = {
          code: this.form.code,
          name: this.form.name,
          weight: this.form.weight,
          description: this.form.description,
          completed: false
        };

        let url =
          "/api/v2/assignments/" +
          this.entityType +
          "/" +
          this.entityId +
          "/" +
          this.id;
        axios({
          method: "post",
          url: url,
          data: data
        })
          .then(response => {
            this.$toast.open({
              message: this.$t("assignment_update_success"),
              type: "is-success",
              position: "is-top-right"
            });

            this.$emit("reload", {id: this.id, entityId: this.entityId}); //pide a su componente padre que recargue
            this.hide();
          })
          .catch(error => {
            //Se produjo un error
            this.isLoading = false;
            if (error.response && error.response.status === 422) {
              //extraemos el primer error de validación
              error.error_msg =
                error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                  ][0];
            }

            // Mostramos el error
            this.$toast.open({
              message: error.error_msg || this.$t("assignment_update_error"),
              type: "is-danger",
              position: "is-top-right"
            });
          });
      },

      /**
       * Confirma y borra la medición abierta en el modal
       */
      async deleteTask() {
        let self = this;

        this.$dialog.confirm({
          title: this.$t("delete_task"),
          message: this.$t("delete_task_confirm_text", [this.data.name]),
          confirmText: this.$t("delete_task"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            this.isLoading = true;
            let url = "/api/v2/assignment/" + self.id + "/delete";
            const {data} = await axios.post(url);

            if (data.success) {
              this.$toast.open({
                message: this.$t("task_remove_success"),
                type: "is-success",
                position: "is-top-right"
              });
              self.$emit("reload"); //pide a su componente padre que recargue
              self.hide();
            } else {
              this.isLoading = false;
              // Error
              this.$toast.open({
                message: this.$t("task_remove_error"),
                type: "is-danger",
                position: "is-top-right"
              });
            }
          }
        });
      },



      /**
       * Comprueba si el usuario puede administrar la asignación
       */
      isAdmin() {
        return this.data.user_id == (this.user && this.user.id) || this.canManage;
      },


      /**
       * Muestra este modal
       */
      show(task) {
        this.id = task.id;
        this.getAssignmentData();
        this.isModalActive = true;
      },

      /**
       * Toma los datos de esta medición de la API
       */
      getAssignmentData() {
        var self = this;
        const {data} = axios
          .get("/api/v2/assignment/" + this.id)
          .then(response => {
            if (response.data && response.data.success) {
              // console.log(response);
              self.data = response.data.assignment;
              self.form = {
                code: self.data.code,
                name: self.data.name,
                weight: self.data.weight == null ? null : String(self.data.weight),
                description: self.data.description,
                completed: false

              };
            } else {
              this.$notify.error("task_loading_error");
            }
          });
      },


      /**
       * valores por defecto del formulario
       */
      defaultFormValues() {
        return {
          name: "",
          weight: "",
          code: "",
          description: "",
          completed: 0
        };
      },

      /**
       * Oculta este modal
       */
      hide() {
        this.id = null;
        this.data = [];
        this.isModalActive = false;
        this.form = this.defaultFormValues();
      }
    }
  };
</script>
<style scoped>
  /* Oculta los botones para aumentar los inputs numéricos */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
</style>
