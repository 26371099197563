<template>
  <b-modal :active.sync="isModalActive" scroll="clip">
    <div v-if="false">
      <scale-loader></scale-loader>
    </div>

    <div v-if=" document ">
      <document-editor :ref="'pdf_' + nameEditorRef"
        :entity-id="entityId"
        :entity-type="entityType"
        :enableMeasurement="enableMeasurement"
        :enableBtnSave="enableBtnSave"
        :nameViewerRef="nameEditorRef"
        @reload-documents-list="() => { hide(); $emit('reload-documents-list') }"
      />
    </div>
  </b-modal>
</template>

<script>
  import DocumentEditor from "./assignments/document-editor.vue"
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

  export default {
    name: "entity-document-preview",

    components: {
      DocumentEditor,
      ScaleLoader
    },

    props: {
      entityId: {type: Number, required: true},
      entityType: {type: String, required: true},
      enableMeasurement: { type: Boolean, default: false },
      enableBtnSave: { type: Boolean, default: true },
      nameEditorRef: { type: String, default: 'editor' }
    },

    data: () => ({
      isModalActive: false,
      document: null,
      isLoading: false,
    }),

    mounted() {

    },

    computed: {
    },


    methods: {
      /**
       * Muestra el modal
       */
      show(document) {
        this.isModalActive = true;
        // this.isLoading = true
        this.document = document

        this.$nextTick(() => {
          setTimeout(() => {
            // Simulate asynchronous task completion
            this.isLoading = false
            // this.$refs.pdf_editor.show( document )
          }, 500);
        });

        setTimeout(() => { // espera a que se cargue el componente y este disponible
          // this.isLoading = false
          this.$refs['pdf_' + this.nameEditorRef].show( document )
        }, 1000);

      },

      hide() {
        this.isModalActive = false;
        this.isLoading = false;
      },


    }
  };
</script>
