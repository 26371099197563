<template>
  <div>
    <!-- Título y botón añadir -->
    <div class="flex items-center mb-4">
      <span class="flex-grow text-xl font-thin uppercase text-blue-light">{{ title }}</span>
      <v-button-icon-round
        icon="plus"
        tooltip="add_comment"
        tooltip-position="left"
        @click="addComment"
        v-if="canAdd"
      />
    </div>

    <!-- Caja de nuevo comentario -->
    <entity-comment-add
      ref="newComment"
      :entity-id="entityId"
      :entity-type="entityType"
      :documents-list="documentsList"
      @comment-saved="loadComments(entityId)"
    />

    <!-- ETIQUETAS -->
    <div>
      <filter-tags
        @filterList="filterComments($event)"
        ref="filterTags"
      />
    </div>

    <!-- Comentarios -->
    <div v-if="isLoading" class="my-4 text-center">
      <scale-loader></scale-loader>
    </div>

    <entity-comment
      v-for="(comment) in comments"
      :key="comment.id"
      :comment="comment"
      :documents-list="documentsList"
      :entityId="entityId"
      :isSubactivity="isSubactivity"
      :can-add="canAdd"
      :entityType="entityType"
      @reload="loadComments(entityId)"
    />

  </div>
</template>

<script>
  import Vue from "vue"
  import axios from 'axios'
  import EntityCommentAdd from '·/components/entity-comment-add'
  import EventBus from '~/plugins/bus'
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
  import store from "~/store";
  import moment from 'moment'
  import {mapGetters} from 'vuex'
  import FilterTags from "·/components/filter-tags";
  import VisualizeTags from "·/components/visualize-tags";
  import EntityComment from "./entity-comment";

  Vue.prototype.moment = moment

  export default {
    name: 'entity-comments',

    components: {
      EntityComment,
      EntityCommentAdd,
      ScaleLoader,
      FilterTags,
      VisualizeTags,
    },

    props: {
      entityId: {type: Number, required: true},
      isSubactivity: {type: Boolean, default: false},
      canAdd: {type: Boolean, default: false},
      entityType: {type: String, required: true},
    },

    data: () => ({
      user: [],
      comments: [],
      isLoading: true,
      canEdit: false,
      commentId: 0,
      title: '',
      tags: [],
      documentsList:[],
    }),

    watch: {
      entityId(id) {
        if (id > 0) {
          this.loadComments(id)
        }
      }
    },

    computed: {
      ...mapGetters({
        project: 'app/project',
      }),
    },

    created() {
      if (this.entityType === 'validation') {
        this.title = this.$t('validation_diary')
      }
      else {
        this.title = this.entityType === 'issue' ?
          this.$t('issue_diary') :
          this.$t(this.isSubactivity ?
            'subactivity_diary' :
            'activity_diary')
      }

      this.loadDocuments();
      this.loadComments(this.entityId);

      // Si recibimos el evento refresh, refrescamos el componente
      let self = this;
      EventBus.$on('refreshNotifications', function (e) {
        self.loadComments(self.entityId);
      });

      EventBus.$on('refreshComments', function (e) {
        if (self.entityType == e.entity) { //componente usado en activity/issue/validation. que solo recargue cuando es su tipo
          self.loadComments(self.entityId);
        }
      });

      if (this.entityType === 'activity') {
        // Si recibimos el evento refresh, refrescamos el componente
        EventBus.$on("refreshActivityDocumentsNotifications", function(e) {
          self.loadDocuments();
        });
      } else {
        // Si recibimos el evento refresh, refrescamos el componente
        EventBus.$on("refreshIssueDocumentsNotifications", function(e) {
          self.loadDocuments();
        });
      }

    },

    beforeDestroy(){
      EventBus.$off("refreshNotifications");
      EventBus.$off("refreshComments");
      EventBus.$off("refreshActivityDocumentsNotifications");
      EventBus.$off("refreshIssueDocumentsNotifications");
    },

    methods: {
      async loadComments(entityId) {
        const {data} = await axios.get(
          '/api/v2/' + this.entityType + '/' + entityId + '/comments',
          {params: {tags: this.tags}}
        )
        if (data && data.comments) {
          this.comments = data.comments
          this.isLoading = false
        }
      },

      async loadDocuments() {
        var self = this;
        let params = {'as_tags': 1};
        const {data} = await axios.get("/api/v2/" + this.entityType + "/" + this.entityId + "/documents", {params: params});
        if (data && data.success) {

          self.documentsList = data.documents;
        }
      },

      addComment() {
        this.$refs.newComment.new()
      },
      editComment(commentId) {
        this.$refs.commentModal.edit(commentId)
      },

      deleteComment(commentId) {
        this.$dialog.confirm({
          title: this.$t('delete_comment'),
          message: this.$t('delete_comment_confirm_text'),
          confirmText: this.$t('delete_comment'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            let self = this;
            let url = '/api/v2/' + self.entityType + '/comment/' + commentId + '/delete'
            const {data} = await axios.post(url)
            if (data && data.success) {
              self.$notify.success('success_deleting_comment')
              self.loadComments(self.entityId);
            } else {
              self.$notify.error(data.error)
            }
          }
        })
      },

      canUserEdit(commentAuthor) {
        return commentAuthor == store.getters["auth/user"].id
      },
      answerComment(parentComment) {
        this.$refs.commentModal.answer(parentComment)
      },

      // Carga las tags recibidas y aplica el filtro
      filterComments(tags) {
        this.isLoading = true
        this.tags = tags
        this.loadComments(this.entityId)
      }
    },

  };
</script>

<style>
  .comment-lists {
    overflow-wrap: break-word;
  }

  .comment-lists ul {
    list-style: disc !important;
    padding-left: 1.5em;
  }

  .comment-lists ol {
    padding-left: 1.5em;
  }
</style>
