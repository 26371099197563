<template>
  <div class="w-1/3 absolute top-1 right-2 bg-gray-50 p-4 rounded-md">
    <div v-if="datasetProject">
      <div class="font-bold text-blue-light mb-4">Avance del proyecto <span class="text-green">{{ totalProgress }}%</span></div>
      <div class="mb-4" v-if="progressData">
        <div class="font-bold text-blue-light" v-for="(pic, index) in progressData.pictograms"
          :key="'datapic_' + index">
          {{ pic.name }}: <span class="text-green">{{ pic.progress }}%</span>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="font-bold text-blue-light">{{ progressData ? progressData.name : 'CT' }}</div>
      <div class="font-bold text-blue-light mb-4">Avance del CT <span class="text-green">{{ progressData ? progressData.total_progress : 0 }}%</span></div>
      <div class="mb-4">
        <div class="font-bold text-blue-light">Hincados: <span class="text-green">{{ progressData ? progressData.progress_piling : 0 }}%</span></div>
        <div class="font-bold text-blue-light">Estructuras: <span class="text-green">{{ progressData ? progressData.progress_structures : 0 }}%</span></div>
        <div class="font-bold text-blue-light">Módulos: <span class="text-green">{{ progressData ? progressData.progress_modules : 0 }}%</span></div>
      </div>
    </div>

    <div>
      <div class="font-bold text-blue-light">Incidencias generadas: <span class="text-grey-darker">{{ progressData ? progressData.total_issues : 0 }}</span></div>
      <div class="font-semibold text-blue-lighter">Incidencias resueltas: <span class="text-grey-darker">{{ progressData ? progressData.total_issues_complete : 0 }}</span></div>
    </div>
  </div>
</template>

<script>
  import axios from "axios"

  export default {
    name: "ViewerProgress",

    props: {
      projectId: { type: Number, required: true },
      pictogramId: { type: Number, default: 0 },
      datasetProject: { type: Boolean, default: true }
    },

    watch: {
      pictogramId: function(newVal, oldVal) {
        if (! this.datasetProject && newVal > 0)
          this.getProgressPictogram()
      },

      datasetProject: function(newVal, oldVal) {
        if (newVal)
          this.getProgressPowerPlant()

      }
    },

    data() {
      return {
        progressData: null,
        totalProgress: 0
      }
    },

    mounted() {
      if (this.datasetProject)
        this.getProgressPowerPlant()
      else
        this.getProgressPictogram()
    },

    methods: {
      async getProgressPictogram() {
        this.$emit('epc-pictogram-project-dataset', false)
        this.datasetProject = false

        let self = this
        let url = '/api/v2/pictograms/' + this.pictogramId + '/progress';

        await axios.get(url, {params: {}})
          .then(response => {
            self.progressData = response.data.data

            let progress = []
            progress.push({
              name: 'pilings',
              total: response.data.data.total_piling,
              executed: response.data.data.executed_piling,
              data: {
                'Progreso total': response.data.data.progress_piling
              }
            })

            progress.push({
              name: 'structures',
              total: response.data.data.total_structures,
              executed: response.data.data.executed_structures,
              data: {
                'Progreso total': response.data.data.progress_structures
              }
            })

            progress.push({
              name: 'modules',
              total: response.data.data.total_modules,
              executed: response.data.data.executed_modules,
              data: {
                'Progreso total': response.data.data.progress_modules
              }
            })

            self.$emit('epc-pictogram-data-progress', progress)
          })
          .catch((error) => {
            //Se produjo un error
            console.log("Error loading progress pictogram ", pictogramId)
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });
      },

      async getProgressPowerPlant() {

        let self = this
        let url = '/api/v2/project/' + this.projectId + '/power-plant-progress';

        await axios.get(url, {params: {}})
          .then(response => {
            self.progressData = response.data.data

            // calculamos el progreso total con un promedio
            let tot = response.data.data.pictograms.length;
            response.data.data.pictograms.forEach(function(el) {
              self.totalProgress += el.progress
            });

            self.totalProgress = self.totalProgress / (tot == 0 ? 1 : tot)
          })
          .catch((error) => {
            //Se produjo un error
            console.log("Error loading progress pictogram ", this.projectId)
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });
      },
    }
  }

</script>

<style scoped>

</style>
