<template>
<div>

  <div class="flex flex-wrap">
    <v-button icon="arrow-left" @click="goToPage('edit-project')">{{ $t('project') }}</v-button>
  </div>

  <div class="mt-4">
    <b-tabs type="is-boxed" v-model="mainActiveTab">
      <b-tab-item>
        <template slot="header">
          <span> Paneles de actividad </span>
        </template>
        <div class="panel">
          <div v-if="isLoading" class="my-4 text-center">
            <scale-loader />
          </div>
          <div v-else >
              <div class="mb-3">
                <b-switch v-model="ignore_delayed_state"
                  :rounded="true"
                  :outlined="false"
                  :size="'is-medium'"
                  :type="'is-success'"
                  :left-label='true'
                  @input="saveIgnoreDelayedState()">Ignorar estado con retraso</b-switch>
              </div>
              <p class="mb-2">
                <b-icon pack="fas" icon="info-circle"></b-icon>
                Arrastre u oculte los paneles para configurar como se visualizarán en la vista general de cada actividad.
              </p>
              <div class="flex flex-wrap justify-center">
                <div style="height: 650px" class="flex w-3/4 text-white">
                  <div style="background: #333" class="w-1/2 h-full m-1 flex justify-center items-center text-2xl">
                      <b>DIARIO DE LA ACTIVIDAD</b>
                  </div>
                  <div class="w-1/2 h-full m-1 text-xl">
                      <draggable
                        :list="list"
                        class="h-full"
                        ghost-class="ghost"
                        @start="dragging = true"
                        @end="dragging = false"
                        @change="saveActivityPanels()"
                      >
                        <div
                          class="pb-2 text-lg"
                          style="height: 14.2%"
                          v-for="element, index in list"
                          :key="element.name"
                        >
                          <div
                            :style="element.show ? 'background: #38c172': 'background: #d94a58'"
                            class="h-1/4 flex justify-center items-center cursor-pointer rounded-t-lg"
                            @click="saveActivityPanels(index)"
                          >
                            <b-icon pack="fa" size="is-small" :icon="element.show ? 'eye': 'eye-slash'"></b-icon>
                          </div>
                          <div class="h-3/4 bg-blue flex justify-center items-center cursor-move rounded-b-lg">
                            <b>{{$t(element.name)}}</b>
                          </div>
                        </div>
                      </draggable>
                  </div>
                </div>
              </div>
          </div>

        </div>
      </b-tab-item>

      <b-tab-item>
        <template slot="header">
          <span> Lista de actividades </span>
        </template>
        <div class="panel">
          <div v-if="isLoading" class="my-4 text-center">
            <scale-loader />
          </div>
          <div v-else >
              <p class="mb-2">
                <b-icon pack="fas" icon="info-circle"></b-icon>
                Configure que columnas estarán visibles en la lista de actividades.
              </p>
              <div v-for="(column, index) in columns" :key="index" class="mb-2">
                <b-field>
                  <b-switch v-model="columns[index].visible"
                  type="is-success"
                  @input="saveColumns()">
                      {{$t(column.title)}}
                  </b-switch>
                </b-field>
              </div>
          </div>

        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import Vue from "vue"
import axios from 'axios'
import Form from 'vform'
import moment from 'moment'
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import draggable from "vuedraggable";

Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'activity_edit_page_title',
  subtitle: 'activity_edit_page_subtitle',
  menuOption: '0-2',
  components: {
    ScaleLoader,
    draggable
  },
  data: () => ({
    projectId: null,
    entity: 'project',
    isLoading: false,
    mainActiveTab: 0,
    list: [
      { name: "panel_progress", show: true },
      { name: "panel_subactivities", show: true },
      { name: "panel_measurements", show: true },
      { name: "panel_validations", show: true },
      { name: "panel_issues", show: true },
      { name: "panel_documents", show: true },
      { name: "panel_materials", show: true },
    ],
    columns: {
      in_planning: {title: 'in_planning', visible: true},
      user: {title: 'manager', visible: true},
      planning_code: {title: 'code', visible: true},
      name: {title: 'name', visible: true},
      location: {title: 'location', visible: true},
      type: {title: 'type', visible: true},
      progress: {title: 'progress', visible: true},
      current_start_date: {title: 'start_date', visible: true},
      current_end_date: {title: 'end_date', visible: true},
      status: {title: 'status', visible: true},
      status_id: {title: 'custom_status', visible: true},
      notifications: {title: 'notifications', visible: true},
    },

    ignore_delayed_state: false,

    dragging: false
  }),


  created() {

    this.projectId = this.$route.params.project_id || null

    if( this.projectId )
    {
      this.$options.title = 'project_edit_page_title'
      this.$options.subtitle = [
        "project_page_subtitle",
        [this.navData.area_name.toUpperCase()]
      ];
    }

  },

  computed: {
    ...mapGetters({
      navData: "app/navData",
      visualConfig: "app/visualConfig",
      project: "app/project",
    }),
  },

  mounted() {
    this.getVisualConfig()
  },


  methods: {

    async getVisualConfig() {
      this.isLoading = true;
      let {data} = await axios.get("/api/v2/project/"+this.projectId+"/configs");
      if (data && data.success) {
        if (data.data?.activities_panels) {
          // mapeamos la configuración que tenian previamente
          let activitiesPanels = data.data.activities_panels
          console.log( data )

          let visiblePanels = activitiesPanels.map(p => this.list.find(l => p.name == l.name))

          // obtenemos los q no estaban en la configuracion y los agregamos
          let hiddenPanels = []
          for (let panel of this.list) {
            if (!visiblePanels.some(vPanel => vPanel.name == panel.name)) {
              panel.show = false
              hiddenPanels.push(panel)
            }
          }
          this.list = visiblePanels.concat(hiddenPanels)
        }
        if (data.data?.activities_columns) {
          for (let column in this.columns) {
            if (data.data.activities_columns[column]) {
              this.columns[column] = data.data.activities_columns[column]
            }
          }
        }

        if (data.data?.ignore_delayed_state) {
          this.ignore_delayed_state = data.data.ignore_delayed_state
        }
      }
      this.isLoading = false;
    },

    async saveActivityPanels(index = null) {
      if (index || index == 0) {
        this.changeShow(index)
      }

      let data = {
        activities_panels: []
      }

      for (let e of this.list) {
        if (e.show) {
          data.activities_panels.push({
            name: e.name
          })
        }
      }

      // Enviamos el form
      await axios.post("/api/v2/project/"+this.projectId+"/configs", data);

      // actualizamos en el storage si estamos en el mismo proyecto
      if (this.project.id == this.projectId) {
        this.$store.dispatch('app/setVisualConfig', { config: {activities_panels: data.activities_panels} })
      }
    },

    changeShow(index) {
      this.list[index].show = !this.list[index].show
    },

    async saveColumns() {
      let data = {
        activities_columns: this.columns
      }

      // Enviamos el form
      await axios.post("/api/v2/project/"+this.projectId+"/configs", data);

      // actualizamos en el storage si estamos en el mismo proyecto
      if (this.project.id == this.projectId) {
        this.$store.dispatch('app/setVisualConfig', { config: {activities_columns: data.activities_columns} })
      }
    },

    async saveIgnoreDelayedState() {
      let data = {
        ignore_delayed_state: this.ignore_delayed_state
      }

      // Enviamos el form
      await axios.post("/api/v2/project/"+this.projectId+"/configs", data);

      // actualizamos en el storage si estamos en el mismo proyecto
      if (this.project.id == this.projectId) {
        this.$store.dispatch('app/setVisualConfig', { config: {ignore_delayed_state: data.ignore_delayed_state} })
      }
    },

    goToPage(destiny) {

      switch (destiny) {

        case 'edit-project':
            this.$router.push({ name: 'project.edit', params: {
              project_id: this.projectId
            } })
            break;
      }

    },

  },
};
</script>
<style scoped>
.ghost {
  opacity: 0.8;
}
</style>
