<template>
  <b-modal :active.sync="isModalActive" :width="680" :can-cancel="canCancel" @close="() => { hide() }">
    <div v-if="isLoading">
      <scale-loader></scale-loader>
    </div>

    <div class="border border-blue-light rounded shadow bg-white" v-if="!isLoading">
      <header class="w-full p-4 text-xl font-thin uppercase-unset text-blue-light">
        Información de <span class="font-bold">{{ layer.name }}</span>
      </header>
      <div class="w-full px-4 pb-0 pt-0">

        <b-tabs type="is-toggle-rounded" expanded>
            <b-tab-item label="Progreso" icon="chart-bar" icon-pack="fas">
              <div class="flex items-center justify-center w-full text-gray-800 bg-gray-200-unset">
                <!-- Component Start -->
                <div class="grid lg:grid-cols-1 md:grid-cols-1 gap-2 w-full max-w-6xl">

                    <!-- Tile 1 -->
                    <div class="flex items-center p-2 bg-gray-100 rounded">
                        <div class="flex flex-shrink-0 items-center justify-center bg-green-200 h-16 w-16 rounded">
                            <b-icon icon="check" pack="fas"></b-icon>
                        </div>
                        <div class="flex-grow flex flex-col ml-4">
                            <span class="text-xl font-bold">{{ totalExecute }} {{ _GetEvacuationLineData() }}</span>
                            <div class="flex items-center justify-between">
                                <span class="text-gray-500">Total a ejecutar</span>
                                <span class="text-green-500 text-sm font-semibold ml-2">100%</span>
                            </div>
                        </div>
                    </div>

                    <!-- Tile 2 -->
                    <div class="flex items-center p-2 bg-gray-100 rounded">
                        <div class="flex flex-shrink-0 items-center justify-center bg-red-200 h-16 w-16 rounded">
                          <b-icon icon="arrow-down" pack="fas"></b-icon>
                        </div>
                        <div class="flex-grow flex flex-col ml-4">
                            <p class="text-xl font-bold">{{ totalExecute - totalExecuted }} {{ _GetEvacuationLineEvance('pendings') }}
                              <span v-if="progress" class="text-ms text-gray-800"> | Re-calculado: {{ _GetProgressRecalculate('pendings') }} {{ _GetEvacuationLineRecalculateEvance('pendings') }}</span>
                            </p>
                            <div class="flex items-center justify-between">
                                <span class="text-gray-500">Pendiente de ejecutar</span>
                                <span class="text-red-500 text-sm font-semibold ml-2">{{ _GetPercentageProgress('pendings') }}%</span>
                            </div>
                        </div>
                    </div>

                    <!-- Tile 3 -->
                    <div class="flex items-center p-2 bg-gray-100 rounded">
                        <div class="flex flex-shrink-0 items-center justify-center bg-green-200 h-16 w-16 rounded">
                          <b-icon icon="arrow-up" pack="fas"></b-icon>
                        </div>
                        <div class="flex-grow flex flex-col ml-4">
                            <p class="text-xl font-bold">{{ totalExecuted }} {{ _GetEvacuationLineEvance('executed') }}
                              <span v-if="progress" class="text-ms text-gray-800"> | Re-calculado: {{ _GetProgressRecalculate('executed') }} {{ _GetEvacuationLineRecalculateEvance('executed') }}</span>
                            </p>

                            <div class="flex items-center justify-between">
                                <span class="text-gray-500">Total ejecutado</span>
                                <span class="text-green-500 text-sm font-semibold ml-2">{{ _GetPercentageProgress('executed') }}%</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

              <div class="mt-4 mb-4 text-xl text-blue-light w-full">Total ejecutado por estado</div>
              <div v-if="layer.statuses.length"
                v-for="status in layer.statuses" :key="'picstatus_' + status.id"
                class="mb-1">
                <span class="w-4 h-4 rounded-full inline-block" :style="{ backgroundColor: status.color }"></span>
                <span class="border-b border-dotted">{{ status.name }}</span>
                <b-icon icon="arrow-right" pack="fas"></b-icon>
                <span>{{ _GetProgressStatus(status) }} </span>
                <span v-if="progress" class="text-ms text-gray-800"> | Re-calculado: {{ _GetProgressRecalculateStatuses(status) }} {{ _GetEvacuationLineRecalculateEvance('status_evance', status) }}</span>
              </div>
              <div v-else>
                <span>No tiene definidos estados</span>
              </div>

              <div v-if="progress && layer.code === 'modules'">
                <div class="mt-4 mb-4 text-xl text-blue-light w-full">Total ejecutado por capas de diseño</div>
                <div v-if="progress.totalLayerDesingExecuted"
                  v-for="(value, key, index) in progress.totalLayerDesingExecuted" :key="'laystatus_' + index"
                  class="mb-1">
                  <span class="border-b border-dotted">{{ key }}</span>
                  <b-icon icon="arrow-right" pack="fas"></b-icon>
                  <span>{{ value }} </span>
                </div>
              </div>

              <div class="w-full flex items-center justify-between mt-5">
                <v-button v-if="!processingData && progress==null"
                  type="button"
                  icon="calculator"
                  icon-pack="fas"
                  class="mr-4"
                  @click="_RecalculateProgress()">{{ $t('recalculate_progress') }}</v-button>

                <v-button v-if="!processingData && progress!=null"
                  color="green"
                  icon="save"
                  icon-pack="fas"
                  @click="_SaveRecalculateProgress()">{{ $t('update_progress') }}</v-button>

                <v-button v-if="!processingData && progress!=null"
                  color="red"
                  icon="times"
                  icon-pack="fas"
                  @click="() => { progress = null }">{{ $t('cancel') }}</v-button>

                <v-button-processing v-if="processingData"
                  :text="$t(progress == null ? 'recalculating_progress' : 'updating_progress') + '...'"
                />
              </div>

            </b-tab-item>

            <b-tab-item label="General" icon="cog" icon-pack="fas">
              <div class="mt-4 mb-4 text-xl text-blue-light w-full">Información por capas de diseño</div>

              <div class="flex items-center justify-center w-full text-gray-800 bg-gray-200-unset">
                <!-- Component Start -->
                <div class="grid lg:grid-cols-1 md:grid-cols-1 gap-2 w-full max-w-6xl">

                  <div v-for="(value, name, index) in cadDayersDesign"
                    class="flex items-center p-2 bg-gray-100 rounded" :key="'cadlayerdesign' + index">
                    <div class="flex-grow flex flex-col ml-4">
                        <span class="text-xl font-bold">{{ name === 'default' ? 'Por defecto' : name.replaceAll('_', ' ') }}</span>
                        <div v-for="(val, key, i) in value" :key="'valuesLayer' + i"
                          class="flex flex-col mt-1">
                            <div v-if="_ShowDataLayer(key)" class="text-gray-500">
                              {{ _GetNameLayerDesign(key) }} <b-icon icon="arrow-right" pack="fas"></b-icon> {{ val }}
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

            </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import Form from 'vform'
  import axios from "axios"
  import {mapGetters} from "vuex";
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import { toDate } from 'date-fns';
  // import Color from 'buefy/src/utils/color'

  export default {
    name: "info-modal",

    components: {
      ScaleLoader,
    },

    props: {

    },

    data: () => ({
      isModalActive: false,
      isOpen: false,
      isLoading: true,

      processingData: false,

      canCancel: ['escape', 'x', 'outside'],

      pictogramId: null,
      pictogramType: null,
      layer: null,
      statusesProgress: null,
      totalExecute: 0,
      totalExecuted: 0,
      cadDayersDesign: null,

      evacuationLineData: null,
      totalSections: 1,

      disableSaveButton: false,
      progress: null
    }),

    mounted() {

    },

    computed: {
      ...mapGetters({
        project: 'app/project',
        user: 'auth/user',
      }),
    },

    watch: {
      registerOverDrawingMode(newValue, oldValue) {
        this.$nextTick(() => {
          this.modalWidth = (newValue ? '90%' : '30%')
        });
      },
    },

    methods: {
      show(data, type) {
        this.isLoading = true
        this.isModalActive = true;
        this.canCancel = ['escape', 'x', 'outside']

        this.pictogramId = data.id
        this.pictogramType = data.type
        this.layer = data.layers.find(l => l.code == type)
        this.statusesProgress = data.metadata.statuses_progress[type] ?? null
        this.totalExecute = data.metadata['total_' + type]
        this.totalExecuted = data.metadata.total_progress[type] ?? 0
        this.evacuationLineData = data.metadata.evacuation_line_data ?? null
        this.totalSections = data.metadata.total_sections
        this.cadDayersDesign = data.metadata.cad_layers_design
        this.$nextTick(() => {
          setTimeout(() => {
            // Simulate asynchronous task completion
            this.isLoading = false
          }, 1000);
        });
      },

      hide() {
        this.isModalActive = false;
      },

      _GetProgressStatus(status) {
        if( this.statusesProgress && this.statusesProgress[status.id])
          return this.statusesProgress[status.id]

        return '0'
      },

      _GetEvacuationLineData() {
        return this.evacuationLineData
          ? '(' + this.evacuationLineData.line_length + ' ' + this.evacuationLineData.unit + ')'
          : ''
      },

      _GetEvacuationLineEvance(type) {

        if (!this.evacuationLineData)
          return ''

        let minUnit = this.evacuationLineData.line_length / this.totalSections
        if ('pendings'==type) {
          return '(' + ( minUnit * (this.totalExecute - this.totalExecuted) ).toFixed(2) + ' ' + this.evacuationLineData.unit + ')'
        } else { // executed
          return '(' + ( minUnit * this.totalExecuted ).toFixed(2) + ' ' + this.evacuationLineData.unit + ')'
        }
      },

      _GetEvacuationLineRecalculateEvance(type, status=0) { // status en realidad es el id del estado
        if ( !this.evacuationLineData || this.progress == null)
          return ''


        let minUnit = this.evacuationLineData.line_length / this.totalSections

        if ('status_evance' == type) {
          return '(' + ( minUnit * this._GetProgressRecalculateStatuses(status) ).toFixed(2) + ' ' + this.evacuationLineData.unit + ')'
        }
        if ('pendings'==type ) {
          return '(' + ( minUnit * this._GetProgressRecalculate(type) ).toFixed(2) + ' ' + this.evacuationLineData.unit + ')'
        } else { // executed
          return '(' + ( minUnit * this._GetProgressRecalculate(type) ).toFixed(2) + ' ' + this.evacuationLineData.unit + ')'
        }
      },

      _GetPercentageProgress(value) {
        if (value === 'executed')
          return ((this.totalExecuted * 100) / this.totalExecute).toFixed(2)

        if (value === 'pendings')
          return (((this.totalExecute - this.totalExecuted) * 100) / this.totalExecute).toFixed(2)

        return 0;
      },

      _ShowDataLayer(key) {
        if(this.layer.code === 'pilings' && key === 'total_pilings')
          return true

        if(this.layer.code === 'structures' && key === 'total_axles')
          return true

        if(this.layer.code === 'modules' && (key === 'total_modules' || key === 'total_panels') )
          return true

        return false
      },

      _GetNameLayerDesign(key) {
        if(
          (key === 'total_pilings' || key === 'total_modules' || key === 'total_structures') &&
          this.layer.code !== this.layer.name
        )
          return 'Total ' + this.layer.name.toLowerCase();

        return this.$t(key);
      },

      async _RecalculateProgress() {
        this.processingData = true
        this.progress = null

        let form = new FormData();
        form.append('work_layer', this.layer.code)

        let url = `/api/v2/pictogram/${this.pictogramId}/calculate-progress`

        // Enviamos el form
        return await axios.post(url, form)
          .then((response) => {
            let data = response.data
            this.processingData = false

            if (data && data.success) {

              this.progress = data.data

            } else if(data && data.msg){

              this.$notify.error('no_access_permissions')

            } else {
              this.$notify.error('error_saving')
            }
          })
          .catch(error => {
            // Manejar errores aquí
            if (error.response) {
              // La solicitud fue hecha y el servidor respondió con un estado de error
              this.$notify.error(error.response.data.message)
              // console.log('Error de respuesta:', error.response.status, error.response.data);
              console.error('%cEPC-TACKER: '+ '%c Error de respuesta:', 'background: #5577BB; color: #fff', 'color: #000', error.response.status, error.response.data)
            } else if (error.request) {
              // La solicitud se realizó pero no se recibió respuesta del servidor
              this.$notify.error('No se recibió respuesta del servidor (E500)')
              console.error('%cEPC-TACKER: '+ '%c No se recibió respuesta del servidor', 'background: #5577BB; color: #fff', 'color: #000')
            } else {
              // Ocurrió un error antes de la solicitud, como una configuración incorrecta de Axios
              this.$notify.error('Error de configuración')
              console.error('%cEPC-TACKER: '+ '%c Error de configuración:', 'background: #5577BB; color: #fff', 'color: #000', error.message)
            }

            return null;
          })
          // .finally(() => {
          //   this.$emit('epc-saving', { loading: false, text: null })
          // })
      },

      async _SaveRecalculateProgress() {
        this.processingData = true
        let form = new FormData();
        form.append('work_layer', this.layer.code)
        form.append('total_executed', this.progress[this.layer.code])
        form.append('statuses_progress', JSON.stringify(this.progress.statuses))
        form.append('total_executed_panels', this.progress.statusCompletionPanels ?? 0)

        let url = `/api/v2/pictogram/${this.pictogramId}/force-update-progress`

        // Enviamob n el form
        return await axios.post(url, form)
          .then((response) => {
            this.processingData = false

            let data = response.data

            if (data && data.success) {
              this.progress = null

              this.$toast.open({
                message: 'Datos actualizados correctamente.',
                type: "is-success",
                position: "is-top-right",
              });

            } else if(data && data.msg){

              this.$notify.error('no_access_permissions')

            } else {
              this.$notify.error('error_saving')
            }
          })
          .catch(error => {
            // Manejar errores aquí
            if (error.response) {
              // La solicitud fue hecha y el servidor respondió con un estado de error
              this.$notify.error(error.response.data.message)
              // console.log('Error de respuesta:', error.response.status, error.response.data);
              console.error('%cEPC-TACKER: '+ '%c Error de respuesta:', 'background: #5577BB; color: #fff', 'color: #000', error.response.status, error.response.data)
            } else if (error.request) {
              // La solicitud se realizó pero no se recibió respuesta del servidor
              this.$notify.error('No se recibió respuesta del servidor (E500)')
              console.error('%cEPC-TACKER: '+ '%c No se recibió respuesta del servidor', 'background: #5577BB; color: #fff', 'color: #000')
            } else {
              // Ocurrió un error antes de la solicitud, como una configuración incorrecta de Axios
              this.$notify.error('Error de configuración')
              console.error('%cEPC-TACKER: '+ '%c Error de configuración:', 'background: #5577BB; color: #fff', 'color: #000', error.message)
            }

            return null;
          })
          // .finally(() => {
          //   this.$emit('epc-saving', { loading: false, text: null })
          // })
      },

      _GetProgressRecalculateStatuses(status) {
        return this.pictogramType === 'photovoltaic_park'
          ? this.progress.statuses[status.id].total ?? 'error'
          : this.progress.statuses[status.id].cumulative_total ?? 'error'
      },

      _GetProgressRecalculate(param) {
        let executed = 0
        if(this.layer.code === 'modules')
          executed = this.progress['statusCompletionPanels']
        else
          executed = this.progress[this.layer.code]

        if (param === 'executed')
          return executed

        // caso contrario devuelve progreso pendiente
        return this.totalExecute - executed
      }
    }
  };
</script>

<style scoped>
::v-deep.max-width-custom .modal-content {
  max-width: 90% !important;
}
</style>
