<template>
  <b-modal :active.sync="isModalActive" @after-leave="hide()" scroll="keep" :can-cancel="canCancel">
    <div class="p-4 bg-white border rounded shadow-lg border-blue-light">

      <header class="w-full text-xl font-semibold uppercase text-blue-light text-center">
        {{$t('reopen_task')}}
      </header>
      <div>

            <div class="w-full">
              <form @submit.prevent="submitForm" class="w-full">
                <div>

                  <div class="flex flex-row items-center mt-4">
                    <div class="font-semibold text-black">{{ task.name }}</div>
                  </div>

                  <div class="w-full pb-3 mt-4" >
                    <div class="text-xs font-medium text-blue">{{ $t("observations") }}</div>
                    <b-input
                      v-model="form.observation"
                      name="observation"
                      :placeholder="$t('observations')"
                      class="w-full"
                      maxlength="2000"
                      type="textarea"
                    ></b-input>

                    <div class="w-full border text-center mt-4 pt-4 bg-blue-lightest" style="border: 1px solid #abd5f5; border-radius:.4em">
                      <b class="">{{$t('select_reopen_group')}}</b> <br/>
                      <span class="inline-flex mt-5" v-for="(group, index) in task.validation_groups" :key="group.id">
                        <div @click="form.selectedGroup = group.order_column" v-if="group.status != 0" class="h-10 p-2 mr-1 font-bold text-xs text-center text-white btn"
                            :class="form.selectedGroup == group.order_column ? 'btn-green': 'btn-gray'">
                                {{ group.group.name }}
                        </div>
                      </span> <br/>
                      <div class="inline-flex mb-3 mt-5">
                        <button class="btn btn-blue" type="button" @click="submitStatus(6)" :disabled="isLoading || form.selectedGroup == null ">
                          {{$t('confirm_reopen')}}
                        </button>
                      </div>
                    </div>


                    <div class="w-full pb-3 mt-4" v-if="!canCancel">
                      <div class="text-red-700 px-4 py-3 rounded relative" style="border: 1px solid rgb(250, 204, 21); border-radius: 6px; padding: 0.25em; background-color: rgb(254, 248, 195);" role="alert">
                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <strong class="font-bold">{{$t('processing_request')}}</strong>
                        <span class="block sm:inline">{{$t('processing_request_info')}}</span>
                      </div>
                    </div>

                  </div>
                </div>


              </form>
            </div>

      </div>

    </div>
  </b-modal>
</template>

<script>
import Vue from 'vue';
import axios from "axios";
import { mapGetters } from "vuex";
import EventBus from "~/plugins/bus";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import moment from 'moment'

export default {
  name: "validation-reopen-modal",

  components: {
    ScaleLoader
  },

  props: {
    flowId: { type: Number, required: true },
    validableName: { type: String, required: true },
  },

  data: () => ({
    conditionals: {},
    isModalActive: false,
    openCollapse: false,
    canCancel: ['escape', 'x', 'outside'],
    id: null,
    isLoading: false,
    showUpload: true, // ya siempre desplegado

    task: {}, // los datos de la task
    taskLineId: null, // los datos de la task

    statuses: ['started', 'pending', 'pending_validation', 'refused', 'completed'],

    form: {
      observation: null,
      status: 0,
      selectedGroup: null
    }
  }),

  computed: {
    ...mapGetters({
      company: 'app/company',
      user: "auth/user",
    }),
  },

  methods: {

    submitStatus(status) {
      this.form.status = status
      this.submitForm()
    },

    /**
     * Envía el formulario de validacion de tarea
     */
    async submitForm() {

      if (!this.task.id) {
        return;
      }
      this.canCancel = false
      let url = "/api/v2/validations/task/" + this.task.id + "/status"

      this.isLoading = true;

      await axios({
        method: "post",
        url: url,
        data: this.form,
        })
        .then((response) => {
          this.taskLineId = response.data[0].id;
          this.$toast.open({
            message: response.data.error || this.$t("success_editing"),
            type: response.data.error ? "is-danger" : "is-success",
            position: "is-top-right",
          });

          EventBus.$emit("refreshValidationTasks");
        })
        .catch((error) => {
          //Se produjo un error
          this.isLoading = false;
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("update_error"),
            type: "is-danger",
            position: "is-top-right",
          });
        });


      this.isLoading = false;
      this.hide();
      // this.$emit("reload");
    },


    /**
     * Muestra este modal
     */
    show(task, isFirstGroup, isLastGroup) {
      this.task = task;
      this.isModalActive = true;
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    /**
     * valores por defecto del formulario
     */
    defaultFormValues() {
      return {
        observation: null,
        status: 0,
        selectedGroup: null
      };
    },

    /**
     * Oculta este modal
     */
    hide() {
      this.task = {};
      this.canCancel = ['escape', 'x', 'outside']
      this.isModalActive = false;
      this.form = this.defaultFormValues();
    }
  },
};
</script>
<style scoped>
/* Oculta los botones para aumentar los inputs numéricos */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
