import Cookies from 'js-cookie'
import * as types from '../mutation-types'

var cookies = Cookies.withConverter({
  read: function (value, name) {
    try {
      return atob(value);
    } catch (err) {
      // Usa lectura por defecto si no es base64
      return Cookies.converter.read(value, name);
    }
  },
  write: function (value, name) {
    return btoa(value);
  }
});

const { locale, locales } = window.config;

// state
export const state = {
  locale: cookies.get('locale') || locale,
  locales: locales
};

// getters
export const getters = {
  locale: state => state.locale,
  locales: state => state.locales
};

// mutations
export const mutations = {
  [types.SET_LOCALE] (state, { locale }) {
    state.locale = locale
  }
};

// actions
export const actions = {
  setLocale ({ commit },  locale ) {
    commit(types.SET_LOCALE, { locale });
    cookies.set('locale', locale, { expires: 365, sameSite: 'None', secure: true })
  }
};
