<template>
<div class="flex items-center">
  <b-tooltip :label="$t('activity_time_status_danger')" class="flex items-center mr-6">
    <span class="rounded-full w-4 h-4 bg-red mr-2"></span>
    <span class="text-bold">{{ danger }}</span>
  </b-tooltip>
  <b-tooltip :label="$t('activity_time_status_warning')" class="flex items-center mr-6">
    <span class="rounded-full w-4 h-4 bg-orange mr-2"></span>
    <span class="text-bold">{{ warning }}</span>
  </b-tooltip>
  <b-tooltip :label="$t('activity_time_status_ok')" class="flex items-center">
    <span class="rounded-full w-4 h-4 bg-green mr-2"></span>
    <span class="text-bold">{{ ok }}</span>
  </b-tooltip>
</div>

</template>

<script>
export default {
  name: 'activity-time-status-counters',

  props: {
    ok: { type: Number, default: 0 },
    warning: { type: Number, default: 0 },
    danger: { type: Number, default: 0 },
  },

};
</script>
