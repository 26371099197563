<template>
  <div class="page-with-top-bar">
    <section>
      <div class="mt-4">
        <b-tabs type="is-toggle" v-model="currentActiveTab" expanded size="is-medium">
          <b-tab-item :disabled="sendProcessData">
            <template slot="header">
              <b-icon pack="fas" icon="tasks"></b-icon>
              <span> {{ $t('step') + ' 1' }} </span>
            </template>

            <list-validations ref="list_validations"
              :clearSelectItems="validations.length == 0"
              @selected-items="onSelectedItems"
            />
          </b-tab-item>

          <!-- Acción a realizar sobre la lista previamente seleccionada -->
          <b-tab-item :disabled="(currentActiveTab != 1 && !selectedAction) || sendProcessData">
            <template slot="header">
              <b-icon pack="fas" icon="bolt"></b-icon>
              <span>{{ $t('step') + ' 2' }}</span>
            </template>
            <div v-if="selectedAction">

              <label class="font-bold">Acción masiva: {{ selectedAction.title }}</label>
              <div v-if="selectedAction.name == 'assign_project_rules'">
                <p>Seleccione la regla para realizar la acción seleccionada.</p>
                <div class="flex">
                  <list-rules ref="list_rules"
                    :action="selectedAction"
                    :projectId="projectId"
                    @selected-linked-entities="linkEntities"
                    class="w-1/2 mr-3"
                  />
                  <div class="flex flex-row space-x-4 w-1/2">
                    <div class="panel">

                      <div class="text-sm w-full">
                        <rule-form ref="rule_form" v-if="linkedEntities.length" :projectRule="linkedEntities[0]" :projectId="projectId" :templates="validations"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </b-tab-item>

          <!-- Muestra resumen de la acción y opcion para procesar la acción o cancelar -->
          <b-tab-item :disabled="currentActiveTab != 2 || !canComplete()">
            <template slot="header">
              <b-icon pack="fas" icon="hourglass-end"></b-icon>
              <span>{{ $t('step') + ' 3' }}</span>
            </template>

            <WizardBulkComplete 
              :projectId="projectId"
              :validations="validations"
              :action="selectedAction"
              :linkedEntities="linkedEntities"
              :extraInfo="extraInfo"
              v-if="canComplete()"
              ref="wizard_complete"
            />
          </b-tab-item>

        </b-tabs>

        <div class="sticky bottom-0 ml-4 mr-4">
          <div class="panel mt-0 mb-0">
            <BulkActionsButtons 
              title="Flujos seleccionados"
              :show="validations.length > 0 && currentActiveTab == 0"
              :clearSelect="!selectedAction"
              :set-actions="actions"
              @select-action="onSelectAction"
            />
            <WizardBulkButtons :step="currentActiveTab"
              :complete="canComplete()"
              :processingData="sendProcessData"
              @wizard-previus="prevTab"
              @wizard-next="nexTab"
            />
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
  import axios from "axios";
  import { mapGetters } from 'vuex'
  import ListValidations from "·/components/bulk-actions/validations/validations-list.vue";
  import ListRules from '·/components/bulk-actions/project-validations/rules-list';
  import WizardBulkButtons from '·/components/bulk-actions/wizard-bulk-buttons';
  import WizardBulkComplete from '·/components/bulk-actions/project-validations/wizard-bulk-complete';
  import BulkActionsButtons from '·/components/bulk-actions/bulk-actions-buttons';
  import RuleForm from  '·/components/bulk-actions/project-validations/rule-form';

  export default {
    middleware: "auth",
    title: "bulk_actions_page_title",
    menuOption: "0-2",

    components: {
      ListValidations,
      ListRules,
      WizardBulkButtons,
      BulkActionsButtons,
      WizardBulkComplete,
      RuleForm
    },

    data: () => ({
      currentActiveTab: 0,
      validations: [],
      linkedEntities: [],
      selectedAction: null,
      sendProcessData: false,
      project: null,
      actions: [
        {type: 13, name: 'assign_project_rules', text: 'asignación de reglas de proyecto', title: 'asignación de reglas de proyecto'}
      ],
      extraInfo: null
    }),

    computed: {
      ...mapGetters({
        navData: "app/navData"
      }),
      projectId() {
        return parseInt(this.navData.project_id);
      }
    },

    methods: {

      prevTab () {
        if (this.currentActiveTab === 0 ) return
        const newTabIndex = this.currentActiveTab - 1
        const oldTabIndex = this.currentActiveTab
        this.changeTab(newTabIndex)
      },

      nexTab() {
        if (this.currentActiveTab === 2) {
          this.completeWizard()
          return
        }

        if ( this.currentActiveTab == 0 && !this.validStep1() ) return
        if ( this.currentActiveTab == 1 && !this.validStep2() ) return

        const newTabIndex = this.currentActiveTab + 1
        const oldTabIndex = this.currentActiveTab
        this.changeTab(newTabIndex)
      },

      async completeWizard() {
        this.extraInfo = this.$refs.rule_form.getForm()
        this.sendProcessData = true
        await this.$refs.wizard_complete.sendForm(this.extraInfo);
        this.sendProcessData = false

        // limpiamos datos seleccionados
        this.linkedEntities = []
        this.selectedAction = null
        this.validations = []
        this.changeTab(0)
      },

      async changeTab (newTabIndex) {
        this.currentActiveTab = newTabIndex

        await this.$nextTick()
      },

      validStep1() {
        if( this.selectedAction && this.validations.length > 0)
          return true

        // Mostramos el error
        this.$toast.open({
          message: 'Debe seleccionar al menos un flujo y una acción',
          type: "is-danger",
          position: "is-top-right",
        });

        return false
      },

      validStep2() {
        let errorMessage = null
        if(this.selectedAction.name == 'assign_project_rules' && (this.linkedEntities.length == 0 || !this.$refs.rule_form?.isCompleted())) {
          errorMessage = 'Debe seleccionar una regla de proyecto y configurarla'
        }

        if (!errorMessage) {
          return true
        }

        // Mostramos el error
        this.$toast.open({
          'message': errorMessage,
          'position': 'is-top-right',
          'type': 'is-danger'
        })

        return false
      },

      async onSelectAction(action) {
        this.selectedAction = action;
        this.linkedEntities = [];
      },

      onSelectedItems(items) {
        this.validations = items

        if( !items.length )
          this.selectedAction = null

        if ( this.selectedAction && this.selectedAction.name == 'assign_project_rules') {
          this.linkedEntities = [];
          this.$refs.list_rules.selected = null
          this.$refs.rule_form.reloadForm()
        }
      },

      linkEntities(items) {
        this.linkedEntities = items
      },

      canComplete() {
        if (!this.selectedAction) {
          return false
        }
        if(this.selectedAction.name == 'assign_project_rules' && (this.linkedEntities.length == 0 || !this.$refs.rule_form?.isCompleted())) {
          return false
        }
        return true
      }
    }
  };
</script>
