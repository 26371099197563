import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)
const at = new Vuex.Store({
  state: {
    atUsers: []
  },
  mutations: {
    increment (state, payload) {
      state.atUsers = payload
    }
   
  }
})
export default at