<template>

    <b-collapse class="card filters-collapse" :open.sync="showFilters">

    <div slot="trigger" class="card-header filters-trigger">
      <p class="card-header-title">
        {{ $t('filters') }}
      </p>
      <a class="card-header-icon">
        <b-icon pack="fa" v-show="! showFilters" icon="plus-circle"/>
        <b-icon pack="fa" v-show="showFilters" icon="minus-circle"/>
      </a>
    </div>
    <div class="panel" style="border-width: 0">


      <div class="w-full flex-inline items-center">

        <div class="w-full">
          <b-field :label="$t('search_by_user')">
            <b-select
                      :loading="isLoadingUsers"
                      multiple
                      native-size="8"
                      ref="input"
                      v-model="filterUsers"
                      :placeholder="$t('department')"
                      icon="users"
                      icon-pack="fas"
                      @input="applyFilters()"
                      expanded
            >
              <option v-for="option in usersList"
                      :value="option['basic']['user_id']"
                      :key="option['basic']['user_id']"
              >
                {{ option['basic']['fullname'] }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="w-full mt-4">
          <b-field :label="$t('scheduled_start_date')">
            <b-datepicker
              v-model="filterDateStart"
              :placeholder="$t('scheduled_start_date')"
              :month-names="$t('months_names')"
              :day-names="$t('days_names')"
              :first-day-of-week="parseInt($t('first_day_of_week'))"
              :readonly="false"
              @input="applyFilters()"
            >
            </b-datepicker>
          </b-field>
        </div>
        <div class="w-full mt-4">
          <b-field :label="$t('scheduled_end_date')">
            <b-datepicker
              v-model="filterDateEnd"
              :placeholder="$t('scheduled_end_date')"
              :month-names="$t('months_names')"
              :day-names="$t('days_names')"
              :first-day-of-week="parseInt($t('first_day_of_week'))"
              :readonly="false"
              @input="applyFilters()"
            >
            </b-datepicker>
          </b-field>
        </div>
      </div>


      <div class="flex items-center mt-4 mb-4">
      <button class="button is-primary btn-blue" @click="cleanFiltersClicked()">{{$t('clean_filters')}}</button>
      </div>

    </div>
  </b-collapse>
</template>

<script>
  import moment from "moment";
  import axios from "axios";

  export default {
    name: 'livemap-filters',

    props: {},

    data: () => ({
      showFilters: true,
      usersList: [],
      filterUsers: [],
      filterDateStart: null,
      filterDateEnd: null,
      isLoadingMap: false,
      isLoadingUsers: true,
      showUserExposures: false,

    }),

    watch: {},

    mounted() {
      this.getUsersList();
    },

    methods: {
      generateFilters() {

        let params = {};

        //Filtro por usuario
        if (this.filterUsers.length > 0) {
          params.users = this.filterUsers
          if(this.filterUsers.length == 1){
            params.userName = this.usersList[this.filterUsers[0] - 1];
          }
        }
        //Filtro por fecha inicio
        if (this.filterDateStart !== null) {
          params.filter_date_start = moment(this.filterDateStart).format('YYYY-MM-DD')
        }
        //Filtro por fecha fin
        if (this.filterDateEnd !== null) {
          params.filter_date_end = moment(this.filterDateEnd).format('YYYY-MM-DD')
        }

        return params
      },

      async getUsersList() {
        let url = '/api/v2/points/users';
        const {data} = await axios.get(url);
        if (data && data.success) {
          this.usersList = data.data
          this.isLoadingUsers = false;

        } else {
          this.isLoadingUsers = false;
          this.$notify.error('error_loading_users_positions')
        }
      },

      applyFilters() {
        let args = this.generateFilters();
        this.$emit('filters-updated', args);
      },
      cleanFiltersClicked() {
        this.filterUsers = [];
        this.filterDateStart = null;
        this.filterDateEnd = null;
        this.isLoadingMap = true;
        this.applyFilters();
      },
    },

  };
</script>

<style>
  .filters-collapse {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .filters-trigger:hover {
    background-color: #f8fafc;
  }
</style>
