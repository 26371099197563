<template>
  <div>
    <b-table
      show-empty
      :data="data"
      :loading="isLoading"
      paginated
      striped
      bordered
      icon-pack="fas"
    >
      <template v-slot:empty="">
        <h4>{{ $t('no_rows') }}</h4>
      </template>

      <template>
        <b-table-column field="code" >
          <template v-slot:header="">
            {{ $t('code') }}
          </template>
          <template v-slot="props">
            {{ props.row.code }}
          </template>
        </b-table-column>

        <b-table-column field="description" >
          <template v-slot:header="">
            {{ $t('description') }}
          </template>
          <template v-slot="props">
            {{ props.row.description }}
          </template>
        </b-table-column>

        <b-table-column field="partner" >
          <template v-slot:header="">
            {{ $t('partner') }}
          </template>
          <template v-slot="props">
            {{ getNamePartner(props.row) }}
          </template>
        </b-table-column>

        <b-table-column field="actions"  centered width="80">
          <template v-slot:header="">
            {{ $t('actions') }}
          </template>
          <template v-slot="props">

            <div class="w-full h-full flex items-center justify-around">
                <div>
                  <b-tooltip :label="$t('edit')" position="is-left">
                    <div @click="edit(props.row)" class="cursor-pointer flex text-blue">
                      <b-icon pack="fas" icon="pencil-alt"/>
                    </div>
                  </b-tooltip>
                  <b-tooltip
                    :label="$t('remove')"
                    type="is-danger"
                    position="is-left"
                  >
                    <div @click="remove(props.row)" class="cursor-pointer flex text-red">
                      <b-icon pack="fas" icon="trash-alt"/>
                    </div>
                  </b-tooltip>
                </div>

            </div>
          </template>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'work-orders-list',

    components: {
    },

    data: () => ({
      data: [],
      isLoading: true,
    }),

    props: {
      projectId: {type: Number, required: true},
      entityType: {type: String, default: 'project'}
    },

    mounted() {
      this.getData();
      this.isLoading = false;
    },

    methods: {
      async getData() {
        let {data} = await axios.get('/api/v2/work-orders', {params: {
          project_id: this.projectId,
          with_partners: 1
        }});

        if (data.success) {
          this.data = data.data
        } else {
          this.$notify.error("error_loading_data");
        }
      },

      reload() {
        this.isLoading = true;
        this.getData();
        this.isLoading = false;
      },

      edit(order) {
        this.$emit('epc-work-orders-edit', order)
      },

      async remove(workOrder) {

        this.$dialog.confirm({
          title: this.$t("delete"),
          message: this.$t("delete_confirm_text", ['la orden de trabajo', workOrder.code]),
          confirmText: this.$t("delete"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            this.isLoading = true;
            let url = `/api/v2/work-orders/${workOrder.id}`;
            const { data } = await axios.delete(url);

            if (data.success) {
              this.$toast.open({
                message: this.$t("removed_successfully"),
                type: "is-success",
                position: "is-top-right"
              });

              this.getData();
            } else {
              // Error
              this.$toast.open({
                message: this.$t("remove_error"),
                type: "is-danger",
                position: "is-top-right"
              });
            }
            this.isLoading = false;
          }
        });
      },

      getNamePartner(order) {
        if (!order.partners || order.partners.length == 0 ) return null
        let partners = order.partners.map(p => p.name )
        return partners.join(',')
      }
    }
  };
</script>
