<template>
  <div class="flex flex-wrap w-full mt-4">
    <section class="w-full mb-6">
      <b-table
          icon-pack="fas"
          :data="documents"
          :paginated="isPaginated"
          :per-page="perPage"
          :bordered="true"
          :mobile-cards="false"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :default-sort-direction="defaultSortDirection">
          <template>
            <b-table-column field="thumbnail" cell-class="w-6 vertical-center" sortable>
              <template v-slot:header="">{{ $t('thumbnail') }}</template>
              <template v-slot="props">
                <div class="flex flex-wrap justify-center">
                  <img
                    :src="props.row.file.thumbnail"
                    class="p-1 bg-white border rounded"
                    :alt="props.row.description"
                    onerror="this.src='https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/thumb.jpeg'"
                  />
                </div>
              </template>
            </b-table-column>

            <b-table-column field="code" sortable>
              <template v-slot:header="">{{ $t('code') }}</template>
              <template v-slot="props">{{ props.row.code }}</template>
            </b-table-column>

            <b-table-column field="name" sortable>
              <template v-slot:header="">{{ $t('name') }}</template>
              <template v-slot="props">{{ props.row.description }}</template>
            </b-table-column>

            <b-table-column field="total" width=100 sortable>
              <template v-slot:header="">{{ $t('bindings') }}</template>
              <template v-slot="props">
                <div class="flex items-center justify-around w-full h-full">
                  <b-tooltip :label="props.row.activities_count + ' ' + (props.row.activities_count == 1 ? 'actividad vinculada' : 'actividades vinculadas')" position="is-left">
                      <div
                          class="flex text-sky-300">
                        <b-icon pack="fas" icon="link"/>
                        {{ props.row.activities_count }}
                      </div>
                  </b-tooltip>
                </div>
              </template>
            </b-table-column>

            <b-table-column field="actions" width=100 sortable>
              <template v-slot:header="">{{ $t('actions') }}</template>
              <template v-slot="props">
                <div class="flex items-center justify-around w-full h-full">
                  <b-tooltip :label="$t('view_document')" position="is-left" v-if="props.row.mime_type === 'application/pdf'">
                      <div @click="onShowViewerPdfClicked(props.row)"
                          class="flex cursor-pointer text-sky-300">
                        <b-icon pack="fas" icon="eye"/>
                      </div>
                  </b-tooltip>
                  <b-tooltip :label="$t('download_document')" position="is-left">
                      <div @click="downloadDoc(props.row.document_id)"
                          class="flex cursor-pointer text-sky-300">
                        <b-icon pack="fas" icon="download"/>
                      </div>
                  </b-tooltip>
                </div>
              </template>
            </b-table-column>
          </template>

          <template slot="bottom-left">
              <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
              <b>{{ $t("total") }}</b>: {{ this.documents.length }}
          </template>

          <template #empty>
              <div class="has-text-centered">{{ $t('no_results') }}</div>
          </template>
      </b-table>
    </section>
  </div>
</template>

  <script>
  import {mapGetters} from "vuex";
  import axios from 'axios'

  export default {

    components: {
    },

    props: {
      documents: { type: Array, required: false, default() { return [] } }
    },

    data: () => ({
      page: 1,
      perPage: 10,
      total: 0,
      orderField: "reference_code",
      orderDir: "asc",
      currentPage: 1,
      isPaginationSimple: false,
      defaultSortDirection: 'asc',
      isPaginationSimple: false,
      isPaginated: true,
    }),

    computed: {
      ...mapGetters({
        user: "auth/user",
        project: 'app/project',
      }),
    },

    created() {
    },

    mounted() {
    },

    methods: {

      disableButton() {
        this.disableSaveButton = true
        let self = this
        setTimeout(function() {
          self.disableSaveButton = false
        }, 2000);
      },

      async downloadDoc(document_id) {
        this.$notify.success("starting_download_document");
        let url = "/api/v2/document/" + document_id;

        const {data} = await axios.get(url);

        if (data) {
          window.open(data.data);
        } else {
          this.$notify.error("error_download_document");
        }
      },

      onShowViewerPdfClicked(document) {
        this.$emit('epc-show-viewer', document)
      },
    },
  };
  </script>
