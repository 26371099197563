//Manage recent chats. Se considera reciente al abrirlo
//import * as types from '../mutation-types'

// state
export const state = {
  recents: [],
  numChats: 5 //numero maximo de chats recientes a almacenar
};


// getters
export const getters = {
  recents: state => state.recents,
};

// mutations
export const mutations = {
  insertRecentChat (state, chat) {
    var tmpType = chat.entityType;
    
    let sameTypeChatsIds = state.recents
      .filter( (item) => item.entityType==tmpType ) //chats del mismo tipo 'activity / issue'
      .map( chat => chat.entityId ) //tomar solo sus ids

    //si no insertado previamente
    if (! sameTypeChatsIds.includes(chat.entityId) )
    {
      if (state.recents.length >= state.numChats){
        state.recents.shift();
      }
      state.recents.push(chat);
    }
    
  }
};

// actions
export const actions = {
  insertRecentChat ({ commit },  payLoad ) {
    commit('insertRecentChat', payLoad );
  }
};
