<template>
  <div>
    <!-- Filtros -->
     <div class="w-full flex justify-between mt-4 below-top-bar">
        <div class="flex">
        </div>
    </div>

    <!-- Tabla de proyectos -->
    <div class="panel">

      <b-table
        :data="providers"
        :loading="isLoadingTable"

        paginated
        backend-pagination
        :per-page="perPage"
        :total="total"
        @page-change="onPageChange"

        backend-sorting
        :default-sort="orderField"
        :default-sort-direction="orderDir"
        @sort="onSort"

        class="w-full"
        striped
        bordered
        icon-pack="fas"
      >

        <template>

          <b-table-column field="code" :label="$t('code')" sortable v-slot="props">
            {{ props.row.code }}
          </b-table-column>

          <b-table-column field="name" :label="$t('name')" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column field="cif" :label="$t('cif')" sortable v-slot="props">
            {{ props.row.CIF }}
          </b-table-column>

          <b-table-column :label="$t('actions')" field="actions" centered width="150" v-slot="props">
            <div class="w-full h-full flex items-center justify-around">
              <b-tooltip :label="$t('edit')" position="is-left">
                <div @click="editProviderButtonClicked(props.row)"
                     class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="pencil-alt"/>
                </div>
              </b-tooltip>
              <!-- <b-tooltip :label="$t('archive_project')" position="is-left">
                <div @click="archiveProjectButtonClicked(props.row.id, props.row.name, props.row.closed_at)"
                     :class=archiveClass(props.row.closed_at)>
                  <b-icon pack="fas" icon="folder-open"/>
                </div>
              </b-tooltip> -->
              <b-tooltip :label="$t('remove')" position="is-left" type="is-danger">
                <div @click="deleteProviderButtonClicked(props.row.id, props.row.name)"
                     class="cursor-pointer flex text-red">
                  <b-icon pack="fas" icon="trash-alt"/>
                </div>
              </b-tooltip>
            </div>
          </b-table-column>

        </template>

        <template #empty>
          <div class="has-text-centered">{{ $t('no_results') }}</div>
        </template>
      </b-table>

    </div>

  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import axios from 'axios'
  import MaterialModal from './material-modal.vue'

  export default {

    components: {
      MaterialModal
    },

    props: {
      projectId: { type: Number, required: true },
      emitParentList: { type: Boolean, default: false }
    },

    computed: {
      filters() {
        let filters = {
          'project_id': this.projectId,
          'order_field': this.orderField,
          'order_dir': this.orderDir,
        }

        return filters
      }
    },

    data: function() {
      return {
        isLoadingTable: true,
        providers: [],
        perPage: 20,
        orderField: 'name',
        orderDir: 'asc',
        page: 1,
        total: 0,
      }
    },

    created() {
      this.getProviders()
    },

    methods: {

      async getProviders() {
        this.isLoadingTable = true
        this.providers = []

        const {data} = await axios.get(`/api/v2/project/${this.projectId}/providers`, {params: this.filters})
        if (data && data.success) {
          this.providers = data.providers
          this.total = data.providers.length
          this.isLoadingTable = false

          // hacemos que el padre envie un evento con la lista de los primeros 25 productos
          if( this.emitParentList && this.page == 1 )
            this.$parent.$emit('epc-store-providers', this.providers)
        }
      },

      onSort(field, order) {
        this.projectId = this.projectId
        this.orderField = field
        this.orderDir = order
        this.getProjects()
      },

      onPageChange(page) {
        this.page = page
        this.getProjects()
      },

      cleanFiltersClicked() {
        this.filterTemplateName = ''
        this.getProviders()
      },

      reload() {
        this.getProviders();
      },

      /**
       * Coloca los datos necesarios en NavData para cambiar entre opciones de proyectos
       * @param project
       */
      setQuickButtonsNavData(project)
      {
        this.$store.dispatch('app/updateNavData', {
          area_name: project.area.name,
          project_id: project.id,
          project_name: project.name
        });
      },

      async editProviderButtonClicked(row) {
        let provider = {}
        provider.id = row.id
        provider.code = row.code
        provider.name = row.name
        provider.cif = row.CIF
        this.$emit('epc-edit', provider)
      },

      deleteProviderButtonClicked(providerId, templateName) {
        this.$dialog.confirm({
          title: this.$t('delete'),
          message: this.$t('delete_confirm_text', ['el proveedor', templateName]),
          confirmText: this.$t('delete') + ' ' + this.$t('provider'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            let url = '/api/v2/providers/' + providerId + '/delete'
            const {data} = await axios.post(url)
            if (data && data.success) {
              this.$notify.success('success_deleting_generic')
              this.getProviders()
            } else {
              this.$notify.error(data.error)
            }
          }
        })
      },

      archiveProjectButtonClicked(templateId, templateName, closedAt) {
        let confirmText = closedAt ? 'archive_project_undone_text' : 'archive_project_confirm_text';
        let confirmButtonText = closedAt ? 'undone_project_archive' : 'archive';
        this.$dialog.confirm({
          title: this.$t('archive_project'),
          message: this.$t(confirmText),
          confirmText: this.$t(confirmButtonText),
          type: 'is-warning',
          hasIcon: true,
          onConfirm: async () => {
            let url = '/api/v2/project-templates/' + templateId + '/toggle_archive'
            const {data} = await axios.post(url)
            if (data && data.success) {
              console.log(data)
              this.$notify.success(data.msg)
              this.getStorehouse()
            } else {
              this.$notify.error(data.error)
            }
          }
        })
      },

      archiveClass(value) {
        return value ? "cursor-pointer flex text-yellow-dark" : "cursor-pointer flex text-blue"
      },

      handleSelect(newRow, oldRow) {
        this.$emit('update:template', newRow);
      },

      clearSelected() {
        this.rowSelected = null
      }
    },

  };
</script>
