<template>
  <div class="flex items-stretch items-baseline gap-x-2 w-full">
    <div class="flex flex-col w-full panel">
      <div class="mb-3">
        <div class="flex items-center bg-blue-light text-white text-sm font-bold px-4 py-3 rounded" role="alert">
          <svg class="fill-current w-8 h-8 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
          <div class="flex flex-col">
            <h2 class="mb-2 text-lg font-semibold text-white">Nota:</h2>
            <ul class="space-y-1 text-white list-disc list-inside">
              <li>Este apartado le permite asignar estados de forma masiva a todos los pictogramas seleccionados.</li>
              <li>Tomar en cuenta que no se elimina o sobreescriben los estados ya asignados a los pictogramas.</li>
              <li>Si desea eliminar o editar algún estado de algún pictograma, gestiónelo desde el apartado listado de pictogramas.</li>
            </ul>
          </div>
        </div>
      </div>

      <b-field :label="$t('pictograms')" class="w-full mb-3">
        <multiselect v-model="selectedPictograms" :options="pictogramsLocal"
          :custom-label="nameWithCode"
          :multiple="true"
          :placeholder="$t('select')"
          label="name"
          track-by="name"
          :show-labels="false"
          :maxHeight="200"
          @input="_HandleInputMultiselect"/>
      </b-field>

      <div class="flex gap-x-2 w-full mb-3" v-if="layers.length > 0">
        <div class="flex flex-col w-1/3 bg-gray-300 rounded-lg" v-for="(layer, index) of layers">
          <div class="flex flex-row items-center justify-between leading-tight p-2 md:p-4">
            <div class="flex">
              <p :style="{ color: 'hsl(0, 0%, 21%)'}">
                <b-icon pack="fas" :icon="_GetIcon(layer)"></b-icon>  {{ layer.name }}
              </p>
            </div>

            <div class="flex">
              <b-tooltip :label="$t('add')" position="is-left">
                <div class="flex items-center justify-center w-9 h-9 rounded-full text-grey-lightest cursor-pointer bg-green"
                  @click="_HandleClickedAddStatus( layer )">
                  <b-icon pack="fas" icon="plus" size="is-small" />
                </div>
              </b-tooltip>
            </div>
          </div>

          <div>
            <div>
              <div :class="{'p-3': currentLayer && currentLayer.code == layer.code}">
                <status-pictogram-form v-if="currentLayer && currentLayer.code == layer.code"
                  :ref="'refFormStatus' + layer.code"
                  :status="currentStatus"
                  @epc-pictogram-status-form-close="() => {  currentLayer = null; currentStatus = null }"
                  @epc-add-element="_HandleElement"
                />
              </div>

              <div>
                <pictogram-statuses-list :ref="'refStatuses' + layer.code"
                  :statuses="layer.statuses"
                  :status-type="layer.code"
                  @epc-pictogram-status-edit="_HandleEditStatus"
                  @epc-pictogram-status-delete="_HandleDeleteElement"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex gap-x-2">
        <b-button
          ref="refBtnSave"
          :label="$t('save')"
          type="is-success"
          icon-pack="fas"
          icon-left="save"
          class="w-full"
          @click="_HandleClickedBtnSaveStatuses" />

        <b-button v-if="selectedPictograms && selectedPictograms.length > 0"
          ref="refBtnCancel"
          :label="'Cancelar'"
          type="is-danger is-light"
          icon-pack="fas"
          icon-left="xmark"
          class="w-full"
          @click="() => { _reset() }" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from 'vue-multiselect'

import StatusPictogramForm from './components/status-pictogram-form.vue'
import PictogramStatusesList from './pictogram-statuses-list.vue'

export default {
  name: "manage-pictogram-statuses",

  components: {
    Multiselect,
    StatusPictogramForm,
    PictogramStatusesList

  },

  props: {
    projectId: { type: Number, required: true },
    pictograms: { type: Array, default() { return [] } },
    getPictogramsFromRemote: { type: Boolean, default: false },
    canActionsPictogram: { type:Object, default() { return {} } }, // acciones que se puede realizar sobre el pictograma por defecto ninguna
    permittedActions: { type:Object, default() { return {} } } // acciones que se mostraran en la columna acciones por defecto todas
  },

  data() {
    return {
      selectedPictograms: [],
      pictogramsLocal: this.pictograms,
      _canActionsPictogram: this.canActionsPictogram,
      layers: [], // capas q contienen los pictogrmas
      currentLayer: null, // Propiedad que identifica en que capa se esta trabajando actualmente
      currentStatus: null, // Estado con el que se esta trabajando
      processingData: false,
    }
  },

  computed: {
  },

  created() {
    this.getProjectPictograms()
  },

  mounted() {

  },

  destroyed() {

  },

  beforeDestroy() {

  },

  methods: {
    async getProjectPictograms() {
      this.isLoading = true;
      let params = { };

      const { data } = await axios.get(
        "/api/v2/project/" + this.projectId + "/pictograms",
        { params: params }
      );

      if (data && data.success) {
        this.pictogramsLocal = data.pictograms;
      } else {
        this.$notify.error("error_loading_documents");
      }

      this.isLoading = false;
    },

    nameWithCode ({ name }) {
      return `${name}`
    },

    _HandleInputMultiselect(value, id) {
      if (value.length > 0 && this.layers.length == 0) {
        let lyr = value[0].layers
        this.layers = lyr.map(l => { return { id: l.id, code: l.code, name: l.name, icon: l.icon, statuses: [] } })
      } else if (value.length == 0) {
        this.layers = []
      }
    },

    _GetIcon(layer) {
      return layer.icon ?? 'layer-group'
    },

    _HandleElement(status) {
      if ( status.id != null){
        this._EditElement(status, this.currentLayer.statuses)
      }
      else
        this._AddElement(status, this.currentLayer.statuses)

      this.currentLayer = null
      this.currentStatus = null
    },

    _HandleDeleteElement(obj) {
      let lyr = this.layers.find(l => l.code == obj.type)
      this._DeleteElement(obj.status, lyr.statuses)
    },

    _DeterminateId(statuses) {
      let id = 0
      if( statuses.length > 0 ) {
        const ids = statuses.map( (objeto) => { return Math.abs(objeto.id) });
        id = Math.abs(Math.max(...ids));  // obtenemos el absoluto por si el maximo es un negativo y no genere una resta posterior
      }

      // Multiplicamos por -1 para identificar desde el frontend y backend que son nuevas inserciones
      // lo ya insertados vendrán con signo positivo
      return (id + 1) * -1
    },

    _AddElement(status, statuses) {
      status.id = this._DeterminateId(statuses)
      statuses.push(status)
    },

    _EditElement(status, statuses) {
      let origin = statuses.find( (el) => el.id == status.id )

      if( origin ) {
        origin.code = status.code
        origin.name = status.name
        origin.color = status.color
        origin.is_completion = status.is_completion
      }
    },

    _DeleteElement(status, statuses) {
      const indice = statuses.findIndex(objeto => objeto.id === status.id);
      if( indice !== -1 ) {
        statuses.splice(indice, 1)
      }
    },

    _HandleClickedAddStatus(lyr) {
      this.currentLayer = lyr
    },

    _HandleEditStatus(obj) {
      this.currentLayer = this.layers.find(l => l.code == obj.type)
      this.currentStatus = obj.status
    },

    _HandleClickedBtnSaveStatuses() {
      // Verificamos si ha seleccionado por lo menos un pictograma
      if (this.selectedPictograms.length == 0) {
        this.$toast.open({
          'message': "Debe seleccionar por lo menos un pictograma",
          'position': 'is-top-right',
          'type': 'is-danger'
        })
        return
      }

      let error = []
      for (const lyr of this.layers) {
        if (lyr.statuses.length === 0)
          error.push('lyr_' + lyr.code)
      }

      if (error.length === this.layers.length) {
        this.$toast.open({
          'message': "Ninguna de las capas no tine estados añadidos",
          'position': 'is-top-right',
          'type': 'is-danger'
        })
        return
      }

      console.log('error: ', error)

      if (error.length > 0 && error.length < this.layers.length) {
        this.$buefy.dialog.confirm({
          title: 'Atención',
          message: 'Aun tiene capas sin estados definidos. Desea continuar?',
          type: 'is-warning',
          hasIcon: true,
          icon: 'triangle-exclamation',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
          confirmText: "Guardar estados",
          onConfirm: () => { this._save() }
        })
      } else
        this._save()
    },

    async _save() {
      this.processingData = true

      let data = {
        idpictograms: this.selectedPictograms.map( p => { return p.id } ),
        layers: this.layers.map(l => {  return { code_layer: l.code, statuses: l.statuses }})
      };

      let url =
        "/api/v2/pictograms/assign-statuses";

      await axios({
        method: "post",
        url: url,
        data: data,
      })
      .then((response) => {
        if( response.data.success ) {

          this.$toast.open({
            message: this.$t("assignment_update_success"),
            type: "is-success",
            position: "is-top-right",
          });

          this.$emit('epc-pictograms-reload')

          this._reset()

        } else {

          this.$toast.open({
            message: response.data.error,
            type: "is-danger",
            position: "is-top-right",
          });
        }

        // this.$emit("reload", { id: this.id, entityId: this.entityId }); //pide a su componente padre que recargue
      })
      .catch((error) => {
        //Se produjo un error
        this.isLoading = false;
        if (error.response && error.response.status === 422) {
          //extraemos el primer error de validación
          error.error_msg =
            error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ][0];
        }

        // Mostramos el error
        this.$toast.open({
          message: error.error_msg || this.$t("assignment_update_error"),
          type: "is-danger",
          position: "is-top-right",
        });
      });

      this.processingData = false
    },

    _reset() {
      this.selectedPictograms = null;
      this.layers = []
      this.currentLayer = null
      this.currentStatus = null
    }
  }

};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>

</style>
