<template>
  <div class="page-with-top-bar">
    <project-admin-quick-buttons hiddenOption="technical-specification"/>
    <section>
      <div class="mt-4">
        <b-tabs type="is-boxed" v-model="mainActiveTab">

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="tasks"></b-icon>
              <span> Tipo de especificaciones </span>
            </template>
            <div>
              <technical-specification-type
                ref="technical_specification_type"
                :project-id="projectId"
              />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="tasks"></b-icon>
              <span> Lista de especificaciones </span>
            </template>
            <div>
              <technical-specification
                ref="technical_specification"
                :project-id="projectId"
              />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-import"></b-icon>
              <span> Importar especificaciones </span>
            </template>
            <div>
              <technical-specification-import
                ref="technical_specification_import"
                :project-id="projectId"
              />
            </div>
          </b-tab-item>

        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import TechnicalSpecificationType from '../../components/technical-specification/technical-specification-type'
  import TechnicalSpecification from '../../components/technical-specification/technical-specification'
  import TechnicalSpecificationImport from '../../components/technical-specification/technical-specification-import'
  import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons'

  export default {
    middleware: 'auth',
    title: 'technical_specification',
    menuOption: '0-2',

    components: {
      TechnicalSpecificationType,
      TechnicalSpecification,
      TechnicalSpecificationImport,
      ProjectAdminQuickButtons
    },

    computed: {
      ...mapGetters({
        navData: "app/navData",
      }),
      projectId() {
        return parseInt(this.$route.params.project_id)
      },
    },

    data: () => ({
      mainActiveTab: 0
    }),

    watch: {
      mainActiveTab(value) {
        switch (value) {
          case 0: 
            this.$refs.technical_specification_type.reload(); break;
          case 1: 
            this.$refs.technical_specification.reload(); break;
        }
      }
    },

    created() {
      let projectName = this.navData.project_name
        ? this.navData.project_name.toUpperCase()
        : "";
      this.$options.subtitle = ["project_actions_page_subtitle", [projectName]];
    },

    methods: {}
  };
</script>
