// import { PublicClientApplication } from '@azure/msal-browser';

export const state = {
    // esta config queda cacheada en una cookie, si hay cambios recomendado borrar cookies del sitio para ver cambios inmediatamente
    msalConfig: {
      auth: {
        clientId: '362012f6-5410-4e90-b77b-b6f1d27bf90e',
        authority:
          // 'https://login.microsoftonline.com/65e002ee-e4b8-4b58-b7c6-55e0f7791dd0', // tenant id. Solo admitiria login de users en nuestro tenant
          'https://login.microsoftonline.com/organizations/',  // de esta forma admite usuarios en cualquier tenant (TSK quiere se logueen a EPC los emails que esten en su tenant propio)
        redirectUri: window.config.env == 'local'
          ? 'https://epc-client.test/api/v2/login/azure/callback'
          : ((window.config.env == 'staging') ? 'https://dev.epc-tracker.com/api/v2/login/azure/callback' : 'https://app.epc-tracker.com/api/v2/login/azure/callback') // tras login con exito, microsoft llama a esta uri, pero sin enviar nada, por tanto no nos sirve, la mantengo por compatibilidad
      },
    },
    // accessToken:""
    logged: false, // indica si se ha logueado por microsoft entra id
}

export const mutations = {
    saveAccessToken(state, token) {
      state.accessToken = token;
    },
    saveLogged(state, flag) {
      state.logged = flag;
    }
}

export const actions = {
    setAccessToken ({ commit }, payload) {
      commit('saveAccessToken', payload)
    },
    setLogged ({ commit }, payload) {
      commit('saveLogged', payload)
    },
    async logout ({ commit }, payload) {
      commit('saveLogged', false)

      // por el momento no hacemos logout de microsoft
      // let msalInstance = new PublicClientApplication(
      //   state.msalConfig,
      //   // this.$store.getters["ms-entra-id/msalConfig"]
      // ); 
      // al mostrar popup para seleccionar la cuenta que desloguea, da algunos problemas por los redireccionamientos que hay
      // msalInstance.initialize().then(async () => await msalInstance.logout() );
      // probar esta forma tambien, llegado el momento
      // var accounts = await this.$msalInstance.GetAccountsAsync();
      // while (accounts.Any())
      // {
      //   console.log("mssss removing ",accounts.First())
      //   await this.$msalInstance.RemoveAsync(accounts.First());
      //   accounts = await this.$msalInstance.GetAccountsAsync();
      // }
    },
}

export const getters = {
  msalConfig: state => state.msalConfig,
  accessToken: state => state.accessToken,
  logged: state => state.logged,
}
