<template>
  <div class="page-with-top-bar">
    <project-admin-quick-buttons hiddenOption="extrafields"/>
    <section>
      <div class="card p-2 m4">

      <div class="flex items-center justify-end w-full">
        <v-button icon="table"
          @click="kpiTablePage()"
          class="ml-4 mr-4">{{ $t('Ver KPI') }}
        </v-button>
        <v-button icon="calculator"
          @click="generateReport()"
          class="ml-4 mr-4">{{ $t('export') }}</v-button>
        <v-button
          @click="isAddGroup? isAddGroup = false : isAddGroup = true"
          icon="plus"
        >{{ $t('Crear Grupo') }}</v-button>
        <!-- Modal -->
      </div>

      <!-- form add group -->
      <div v-if="isAddGroup">
        <form @submit.prevent="addKpiGroup">
          <v-input
            class="mb-4 mt-4"
            v-model="groupName"
            label="Nombre"
            placeholder="Nombre del grupo"
            required
          ></v-input>
          <div class="flex flex-wrap w-full mt-4">
            <!-- filtros -->
            <div class="card p-2 m-2 w-full">
              <section class="w-full">
                {{ $t('filters') }}
                <b-autocomplete
                  class="w-full"
                  v-model="filterParentId"
                  :data="parents"
                  icon-pack="fas"
                  placeholder="Filtrar por padre"
                  open-on-focus
                  clerable
                  field="name"
                  clearable
                  @select="filterActivities"
                ></b-autocomplete>
              </section>  
            </div>
            <section class="w-full">
              <!-- activities con asignaciones pra generar kpi -->
               {{ $t('select_activities') }}
              <b-table
                icon-pack="fas"
                :data="activitiesFiltered"
                :paginated="isPaginated"
                :per-page="10"
                :loading = "loadingActivity"
                :bordered="true"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :default-sort-direction="defaultSortDirection"
                checkable
                checkbox-position="left"
                :checkbox-type="'is-success'"
                :checked-rows.sync="selectedActivities"
              >
              <template>
                <b-table-column field="code" sortable>
                  <template v-slot:header="">{{ $t('code') }}</template>
                  <template v-slot="props">{{ props.row.planning_code }}</template>
                </b-table-column>
                <b-table-column field="name" sortable>
                  <template v-slot:header="">{{ $t('name') }}</template>
                  <template v-slot="props">{{ props.row.name }}</template>
                </b-table-column>
                <b-table-column field="progress" sortable>
                  <template v-slot:header="">{{ $t('progress') }}</template>
                  <template v-slot="props">

                    <progress-bar
                      :step="props.row.progress"
                      color="blue-light"
                      class="w-full"
                    />
                  </template>
                </b-table-column>
                <b-table-column field="current_start_date" sortable>
                  <template v-slot:header="">{{ $t('start') }}</template>
                  <template v-slot="props">{{ props.row.current_start_date }}</template>
                </b-table-column>
                <b-table-column field="current_end_date" sortable>
                  <template v-slot:header="">{{ $t('end') }}</template>
                  <template v-slot="props">{{ props.row.current_end_date }}</template>
                </b-table-column>


              </template>

              <template slot="empty">
                <div v-if="loadingActivity" class="m-4 text-lg text-dark-grey">
                  <span>{{ $t('loading') }}</span>
                </div>
                <div v-else class="m-4 text-lg text-dark-grey">
                  <span>{{ $t('empty_activities_table_msg') }}</span>
                </div>
              </template>
              <template slot="bottom-left">
                  <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                  <b>{{ $t("total") }}</b>: {{ this.activitiesFiltered.length }}
              </template>
              </b-table>
            </section>
          </div>
          <v-button
           type="submit">
           {{ $t('save') }}
          </v-button>
        </form>
      </div>

      <!-- form add activity -->
      <div v-if="isAddActivity">
        <form @submit.prevent="addKpiActivity">
          <div class="flex flex-wrap w-full mt-4">
            <!-- filtros -->
            <div class="card p-2 m-2 w-full">
              <section class="w-full">
                {{ $t('filters') }}
                <b-autocomplete
                  class="w-full"
                  v-model="filterParentId"
                  :data="parents"
                  icon-pack="fas"
                  placeholder="Filtrar por padre"
                  open-on-focus
                  clerable
                  field="name"
                  clearable
                  @select="filterActivities"
                ></b-autocomplete>
              </section>  
            </div>
            <section class="w-full">
              <!-- activities con asignaciones pra generar kpi -->
               {{ $t('select_activities') }}
              <b-table
                icon-pack="fas"
                :data="activitiesFiltered"
                :paginated="isPaginated"
                :per-page="10"
                :loading = "loadingActivity"
                :bordered="true"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :default-sort-direction="defaultSortDirection"
                checkable
                checkbox-position="left"
                :checkbox-type="'is-success'"
                :checked-rows.sync="selectedActivities"
              >
              <template>
                <b-table-column field="code" sortable>
                  <template v-slot:header="">{{ $t('code') }}</template>
                  <template v-slot="props">{{ props.row.planning_code }}</template>
                </b-table-column>
                <b-table-column field="name" sortable>
                  <template v-slot:header="">{{ $t('name') }}</template>
                  <template v-slot="props">{{ props.row.name }}</template>
                </b-table-column>
                <b-table-column field="progress" sortable>
                  <template v-slot:header="">{{ $t('progress') }}</template>
                  <template v-slot="props">

                    <progress-bar
                      :step="props.row.progress"
                      color="blue-light"
                      class="w-full"
                    />
                  </template>
                </b-table-column>
                <b-table-column field="current_start_date" sortable>
                  <template v-slot:header="">{{ $t('start') }}</template>
                  <template v-slot="props">{{ props.row.current_start_date }}</template>
                </b-table-column>
                <b-table-column field="current_end_date" sortable>
                  <template v-slot:header="">{{ $t('end') }}</template>
                  <template v-slot="props">{{ props.row.current_end_date }}</template>
                </b-table-column>


              </template>

              <template slot="empty">
                <div v-if="loadingActivity" class="m-4 text-lg text-dark-grey">
                  <span>{{ $t('loading') }}</span>
                </div>
                <div v-else class="m-4 text-lg text-dark-grey">
                  <span>{{ $t('empty_activities_table_msg') }}</span>
                </div>
              </template>
              <template slot="bottom-left">
                  <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                  <b>{{ $t("total") }}</b>: {{ this.activitiesFiltered.length }}
              </template>
              </b-table>
            </section>
          </div>
          <v-button
           type="submit">
           {{ $t('save') }}
          </v-button>
        </form>
      </div>
    </div>
    <div class="card p-2 mt-4">
      <b-table
        :data="kpiGroups"
        :loading="loading"

        paginated
        :per-page="10"
        :pagination-simple="true"
        :total="kpiGroups.length"
        detailed
        detail-key="id"
        icon-pack="fas"
        class="text-xs border-blue-lighter"
        bordered
        narrowed
        striped
        >
        <template slot="empty">
            <div v-if="! loading" class="m-4 text-lg text-dark-grey">
              <span>{{ $t('empty_activities_table_msg') }}</span>
            </div>
        </template>
        <template>
          <!-- name, subactividades, mediciones relacionadas, unidades de medida relacionadas -->
          <b-table-column field="name" label="Nombre" width="40%" class="text-left" v-slot="group">
            <template >
              <div class="flex items-center">
                <span>{{ group.row.name }}</span>
              </div>
            </template>
          </b-table-column>
          <b-table-column field="subactivities" label="Subactividades" width="20%" class="text-left" v-slot="group">
            <template >
              <div class="flex items
              -center">
                <span>{{ group.row.kpi_activities.length }}</span>
              </div>
            </template>
          </b-table-column>
          <b-table-column field="measurements" label="Mediciones" width="20%" class="text-left" v-slot="group">
            <template >
              <div class="flex items-center">
                <span>{{ group.row.total_assignment }}</span>
              </div>
            </template>
          </b-table-column>

          <b-table-column field="units" label="Unidades" width="20%" class="text-left" v-slot="group">
            <template >
              <div class="flex items-center">
                <span>{{ group.row.units }}
                 </span>
              </div>
            </template>
          </b-table-column>

          <!-- add activity-->
          <b-table-column field="add_activity" :label="$t('actions')" width="10%" class="text-left" v-slot="group">
            <template >
              <div class="flex items-center">
                <i v-if="!group.row.isAdd"
                  @click="addButton(group.row)"
                  class="fas fa-plus text-blue cursor-pointer"
                ></i>
                <i v-else
                  @click="addButton(group.row)"
                  class="fas fa-minus text-red cursor-pointer"
                ></i>

                <i @click="deleteKpiGroup(group.row.id)"
                  class="fas fa-trash text-red cursor-pointer ml-4"
                ></i>

              </div>
            </template>
          </b-table-column>

        </template>
        <template slot="detail" slot-scope="group">
          <!--title-->
          <b-collapse
                class="card"
                animation="slide"
                aria-id="contentIdForA11y3">
                <template #trigger="props">
                    <div
                        class="card-header"
                        role="button"
                        aria-controls="contentIdForA11y3"
                        :aria-expanded="props.open">

                        <div class="card-header-title">
                          <h4>
                            <b>{{ $t('subactivities') }}</b>
                          </h4>
                        </div>

                        <a class="card-header-icon">
                            <b-icon icon-pack="fas"
                                :icon="props.open ? 'caret-down' : 'caret-up'">
                            </b-icon>
                        </a>
                    </div>
                </template>

                <div class="card-content p-0">
                    <div class="content">
                      <template>
                        <b-table
                          :data="group.row.kpi_activities"
                          :loading="loading"
                          paginated
                          :per-page="10"
                          :pagination-simple="true"
                          :total="group.row.kpi_activities.length"
                          detailed
                          icon-pack="fas"
                          class="text-xs border-blue-lighter"
                          bordered
                          narrowed
                          striped
                          >
                          <template slot="empty">
                              <div v-if="! loading" class="m-4 text-lg text-dark-grey">
                                <span>{{ $t('empty_activities_table_msg') }}</span>
                              </div>
                          </template>
                          <template>
                            <b-table-column field="name" label="Nombre" width="60%" class="text-left" v-slot="activity">
                              <template >
                                <div class="flex items-center">
                                  <span>{{ activity.row.activity.name }}</span>
                                </div>
                                </template>
                            </b-table-column>
                            <b-table-column field="measurements" label="Mediciones" width="20%" class="text-left" v-slot="activity">
                              <template >
                                <div class="flex items-center">
                                  <span>{{ activity.row.activity.assignments.length }}</span>
                                </div>
                              </template>
                            </b-table-column>
                            <b-table-column field="units" label="Unidades" width="20%" class="text-left" v-slot="activity">
                              <template >
                                <div class="flex items-center">
                                  <span>{{ activity.row.activity.assignments[0].unit_name }}</span>
                                </div>
                              </template>
                            </b-table-column>
                            <b-table-column field="actions" label="Acciones" width="5%" class="text-left" v-slot="activity">
                              <template >
                                <div class="flex items
                                -center">
                                  <i class="fas fa-trash text-red cursor-pointer"
                                    @click="deleteKpiActivity(activity.row.id)"
                                  ></i>
                                </div>
                              </template>
                            </b-table-column>
                          </template>
                          <template slot="detail" slot-scope="activity">
                            <b-collapse
                              class="card"
                              animation="slide"
                              aria-id="contentIdForA11y3">
                              <template #trigger="props">
                                  <div
                                      class="card-header"
                                      role="button"
                                      aria-controls="contentIdForA11y3"
                                      :aria-expanded="props.open">

                                      <div class="card-header-title">
                                        <h4>
                                          <b>{{ $t('measurements') }}</b>
                                        </h4>
                                      </div>

                                      <a class="card-header-icon">
                                          <b-icon icon-pack="fas"
                                              :icon="props.open ? 'caret-down' : 'caret-up'">
                                          </b-icon>
                                      </a>
                                  </div>
                              </template>

                              <div class="card-content p-0">
                                  <div class="content">
                                    <template>
                                      <b-table
                                        :data="activity.row.activity.assignments"
                                        :loading="loading"
                                        :total="activity.row.activity.assignments.length"
                                        icon-pack="fas"
                                        class="text-xs border-blue-lighter"
                                        bordered
                                        narrowed
                                        striped
                                        >
                                        <template slot="empty">
                                            <div v-if="! loading" class="m-4 text-lg text-dark-grey">
                                              <span>{{ $t('empty_activities_table_msg') }}</span>
                                            </div>
                                        </template>
                                        <template>
                                          <b-table-column field="name" label="Nombre" width="60%" class="text-left" v-slot="assignment">
                                            <template >
                                              <div class="flex items-center">
                                                <span>{{ assignment.row.label }}</span>
                                              </div>
                                            </template>
                                          </b-table-column>
                                          <b-table-column field="value" label="Unidad de medida" width="20%" class="text-left" v-slot="assignment">
                                            <template >
                                              <div class="flex items
                                              -center">
                                                <span>{{ assignment.row.unit_name }}</span>
                                              </div>
                                            </template>
                                          </b-table-column>
                                        </template>
                                      </b-table>
                                    </template>
                                  </div>
                              </div>
                            </b-collapse>
                          </template>
                        </b-table>
                      </template>
                    </div>
                </div>

          </b-collapse>

        </template>
      </b-table>
    </div>
    </section>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import axios from 'axios'
  import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons'


  export default {
    middleware: "auth",
    title: "KPIS",
    menuOption: "0-2",

    components: {
      ProjectAdminQuickButtons,
    },

    computed: {
      ...mapGetters({
        navData: "app/navData",
      }),
      projectId() {
        return parseInt(this.navData.project_id);
      }
    },

    data: () => ({
      mainActiveTab: 0,
      kpiGroups: [],
      activities: [],
      activitiesFiltered: [],
      parents: [],
      filterParentId: null,
      groupName: "",
      selectedActivities: [],

      activitySelected: null,
      loading: false,
      isAddGroup: false,
      isAddActivity: false,

      // activities table
      currentPage: 1,
      isPaginated: true,
      isPaginationSimple: true,
      defaultSortDirection: 'asc',
      loadingActivity: false,

      groupIdSelected: null,

    }),

    watch: {
      mainActiveTab(value) {
        if (value == 0) {
          // no aplicable
          //this.$refs.project_assignments_manual.reload();
        }
      },



    },

    created() {
      let projectName = this.navData.project_name
        ? this.navData.project_name.toUpperCase()
        : "";

      this.$options.subtitle = ["project_actions_page_subtitle", [projectName]];
      // this.projectId = this.$route.params.project_id || null;
      this.getProjectKpis()
      this.getProjectKpiActivities()
    },


    methods: {

      extraFieldsImportSuccess() {
        this.$refs.project_extra_fields_manual.reload();
      },

      async generateReport() {
        const { data } = await axios.get('/api/v2/project/'+this.projectId+'/kpi-report')
        if (data && data.success) {
          this.$notify.success('report_generated')
          window.open(data.data, '_blank')
        } else {
          this.$notify.error('error_loading_activity_data')
        }
      },

      addButton(row) {
        // buscar si ya esta agregando una actividad
        const activity = this.kpiGroups.find(group => group.isAdd)
        if (activity) {
          activity.isAdd = false
        }

        if (this.isAddActivity) {
          this.isAddActivity = false
          this.groupIdSelected = null
          row.isAdd = false
        } else {
          this.isAddActivity = true
          this.groupIdSelected = row.id
          row.isAdd = true
        }

      },

      filterActivities(selected) {
        if (!selected) {
          this.activitiesFiltered = this.activities
          return
        }
        this.activitiesFiltered = this.activities.filter(activity => {
          return activity.parent_id 
            ? activity.parent_id == selected.id 
            : activity.id == selected.id;
        });
      },

      kpiTablePage() {
        this.$router.push({ name: 'project.admin.kpi.table', params: {
          project_id: this.projectId
        } })
      },


      async getProjectKpis() {
        this.loading = true
        const { data } = await axios.get('/api/v2/project/'+this.projectId+'/kpi-group')
        if (data && data.success) {

          this.kpiGroups = data.data
        //  this.activities = data.activities
          this.loading = false
        } else {
          this.$notify.error('error_loading_activity_data')
        }
      },

      async getProjectKpiActivities() {
        this.loadingActivity = true
        const { data } = await axios.get('/api/v2/project/'+this.projectId+'/kpi-activities')
        if (data && data.success) {
          this.activities = data.data
          this.activitiesFiltered = data.data
          this.parents = data.parents
          this.loadingActivity = false
        } else {
          this.$notify.error('error_loading_activity_data')
        }
      },

      async addKpiGroup() {
        if (!this.groupName) {
          this.$notify.error('empty_group_name')
          return
        }
        if (this.selectedActivities.length == 0) {
          this.$notify.error('select_activities')
          return
        }
        const actitivtyIds = this.selectedActivities.map(activity => activity.id)
        const { data } = await axios.post('/api/v2/project/'+this.projectId+'/kpi-group', {
          name: this.groupName,
          data: actitivtyIds
        })
        if (data && data.success) {
          this.$notify.success('group_added')
          this.getProjectKpis()
          this.groupName = ""
          this.selectedActivities = []
          this.isAddGroup = false
        } else {
          this.$notify.error('error_adding_group')
        }
      },

      async addKpiActivity() {
        if (this.selectedActivities.length == 0) {
          this.$notify.error('select_activities')
          return
        }

        const actitivtyIds = this.selectedActivities.map(activity => activity.id)
        const { data } = await axios.post('/api/v2/kpi-group/'+this.groupIdSelected+'/activity', {
          activities: actitivtyIds
        })
        if (data && data.success) {
          this.$notify.success('group_added')
          this.getProjectKpis()
          this.groupName = ""
          this.selectedActivities = []
          this.isAddGroup = false
          this.isAddActivity = false
          this.groupIdSelected = null
        } else {
          this.$notify.error('error_adding_group')
        }
      },

      deleteKpiGroup(groupId) {
        this.$buefy.dialog.confirm({
          title: 'Eliminar Grupo',
          message: '¿Estás seguro de que deseas eliminar este grupo?',
          confirmText: 'Eliminar',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.deleteKpiGroupConfirmed(groupId)
          }
        })
      },
      deleteKpiActivity(activityId) {
        this.$buefy.dialog.confirm({
          title: 'Eliminar Actividad',
          message: '¿Estás seguro de que deseas eliminar esta actividad?',
          confirmText: 'Eliminar',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.deleteKpiActivityConfirmed(activityId)
          }
        })
      },

      async deleteKpiActivityConfirmed(activityId) {
        const { data } = await axios.delete('/api/v2/kpi-acitivity/'+activityId)
        if (data && data.success) {
          this.$notify.success('Actividad eliminada')
          this.getProjectKpis()
        } else {
          this.$notify.error('Error eliminando actividad')
        }
      },

      async deleteKpiGroupConfirmed(groupId) {

        const { data } = await axios.delete('/api/v2/project/'+this.projectId+'/kpi-group/'+groupId)
        if (data && data.success) {
          this.$notify.success('Grupo eliminado')
          this.getProjectKpis()
        } else {
          this.$notify.error('Error eliminando grupo')
        }
      }
    }
  };
</script>
