<template>
    <div class="cursor-pointer" @click="goToItem(notification, entityType)">
        <div v-if="notification.comment" class="bg-blue-lightest mb-4 p-2 rounded" :key="notification.comment.id">
            <!-- Top -->
            <div class="flex items-center p-1">
                <!-- Usuario -->
                <user-avatar :user="notification.comment.user.basic">
                    <l-timeago :since="notification.comment.created_at" :locale="locale" slot-scope="props" />
                </user-avatar>
            </div>
            <!-- Contenido -->
            <div v-html="notification.comment.comment" id="list-comments" class="text-sm p-1"></div>
        </div>
        <div v-else>
            <span>{{ $t('deleted_comment') }}</span>
        </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "notification-comment",

    props: {
      notification: { type: Object, required: true},
      entityType: { type: String, required: true}
    },

    computed: {
      ...mapGetters({
        locale: 'lang/locale',
      }),
    },

    methods: {

      goToItem(notification, type) {
        if (type === 'activity') {
          if (notification.activity.parent) {
            this.$router.push({ name: 'subactivity.home', params: { subactivity_id: notification.activity.id, activity_id: notification.activity.parent.id }})
          } else {
            this.$router.push({ name: 'activity.home', params: { activity_id: notification.activity.id } })
          }
        } else if (type === 'issue') {
          if (notification.activity.parent) {
            this.$router.push({ name: 'issue.home-sub', params: { issue_id: notification.issue.id, subactivity_id: notification.activity.id, activity_id: notification.activity.parent.id  } })
          } else {
            this.$router.push({name: 'issue.home', params: {issue_id: notification.issue.id, activity_id: notification.activity.id}})
          }
        } else if (type === 'validation') {
          this.$router.push({name: 'validation.home', params: {validation_id: notification.validation_flow.id}})
        }
      },
    }
  }
</script>

<style scoped>

</style>
