<template>
  <div class="">
    <div @click="handleClickShowModel"
      class="relative flex w-full max-w-[26rem] flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-lg mb-3 hover:text-white hover:bg-blue-light">
        <model-thumbnail
          :modelBim="modelBim"
          @status-translation="handleStatusTranslation"
        />
        <div class="p-2">
          <div class="mb-2">
            <p class="font-bold leading-snug tracking-normal antialiased">
              {{ modelBim.code }}
            </p>
            <p class="truncate" :title="modelBim.original_filename">
              {{ modelBim.original_filename }}
            </p>
            <p class="leading-snug tracking-normal antialiased">
              {{ modelBim.description }}
            </p>
          </div>
          <div v-if="statusTranslation && (statusTranslation.state === 'inprogress' || statusTranslation.state === 'pending')"
            class="text-xs"
          >
            <span>La traducción del diseño esta en proceso.</span>
            <div>Progreso: <span>{{ statusTranslation.progress  }}</span></div>
          </div>
        </div>
      </div>
  </div>
</template>

<script type="module">
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import ModelThumbnail from './model-thumbnail.vue'


export default {
  middleware: 'auth',

  components: {
    ScaleLoader,
    ModelThumbnail
  },

  props: {
    modelBim: { type: Object, required: true }
  },

  data() {
    return {
      isLoading: false,
      statusTranslation: null
    }
  },

  computed: {

  },

  created() {

  },

  mounted() {

  },


  methods: {
    handleClickShowModel(){
      if( this.statusTranslation && this.statusTranslation.state === 'complete' )
        this.$emit( 'change-view-model', this.modelBim.urn )
    },

    handleStatusTranslation(e) {
      this.statusTranslation = e
    }
  },
};
</script>
