<template>
<div class="flex-col">
  <!-- info box -->
  <div class="w-full mt-8">
    <div class="flex flex-row w-full text-blue font-bold">Información de proyecto</div>
    <div v-if="panel1" class="flex flex-wrap justify-center w-100">
      <div v-for="box in panel1" :style="'height: 160px'" class="w-1/4 mb-2">
        <div class="border-4 border-blue text-blue rounded flex items-center h-full justify-center flex-col mx-2">
          <b class="text-center">{{$t(box.name)}}</b>
          <b class="text-3xl">{{box.total}}</b>
        </div>
      </div>
    </div>
  </div>

  <!-- table users info -->
  <div class=" w-full mt-8">
    <div class="flex flex-row w-full text-blue font-bold">Información por usuario</div>
    <b-table
    icon-pack="fas"
    :data="panel2"
    :bordered="true"
    v-if="panel2"
    >
    <template>
      <b-table-column field="avatar" cell-class="w-10">
        <template v-slot:header=""><span class="text-xs">Avatar</span></template>
        <template v-slot="props">
          <div class="w-8 h-8 rounded-full">
              <img class="w-8 h-8 rounded-full" :src="props.row.avatar"/>
          </div>
        </template>
      </b-table-column>

      <b-table-column field="fullname" cell-class="w-5/12">
        <template v-slot:header=""><span class="text-xs">Usuario</span></template>
        <template v-slot="props">{{ props.row.fullname }}</template>
      </b-table-column>

      <b-table-column field="comments">
        <template v-slot:header=""><span class="text-xs">Comentarios</span></template>
        <template v-slot="props">{{ props.row.comments }}</template>
      </b-table-column>

      <b-table-column field="images">
        <template v-slot:header=""><span class="text-xs">Imágenes</span></template>
        <template v-slot="props">{{ props.row.images }}</template>
      </b-table-column>

      <b-table-column field="documents">
        <template v-slot:header=""><span class="text-xs">Documentos</span></template>
        <template v-slot="props">{{ props.row.documents }}</template>
      </b-table-column>

      <b-table-column field="create_issues">
        <template v-slot:header=""><span class="text-xs">Incidencias Creadas</span></template>
        <template v-slot="props">{{ props.row.create_issues }}</template>
      </b-table-column>

      <b-table-column field="issues">
        <template v-slot:header=""><span class="text-xs">Incidencias Participante</span></template>
        <template v-slot="props">{{ props.row.issues }}</template>
      </b-table-column>
    </template>

    <template #empty>
        <div class="has-text-centered">{{ $t('no_results') }}</div>
    </template>
  </b-table>
  </div>
</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import VueChartkick from 'vue-chartkick'
import Chart from 'chart.js'
import moment from 'moment'

Vue.use(VueChartkick, {adapter: Chart})
Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'project_home_page_title',
  subtitle: 'project_home_page_subtitle',
  menuOption: '2-1',

  components: {
    ScaleLoader,
  },

  data: () => ({
    filters: {
      location_id: 0,
      status_id: 1,
      tag_name: '',
    },
    panel1: [
      {
        "name": "ACTIVIDADES",
        "total": 0
      },
      {
        "name": "MEDICIONES CREADAS",
        "total": 0
      },
      {
        "name": "INCIDENCIAS",
        "total": 0
      },
      {
        "name": "FLUJOS CREADOS",
        "total": 0
      },
      {
        "name": "IMÁGENES CARGADAS",
        "total": 0
      },
      {
        "name": "DOCUMENTOS CARGADOS",
        "total": 0
      },
      {
        "name": "COMENTARIOS",
        "total": 0
      }
    ],
    panel2: []
  }),

  computed: {
    ...mapGetters({
      project: 'app/project',
      user: 'auth/user',
    }),
  },

  mounted() {

    if (this.project == null) {

      this.$router.push('/projects');

    } else {

      this.getPanelsInfo();

      let pageInfo = {
        title: this.project.name,
        subtitle: this.$t('project_home_page_subtitle'),
        menuOption: '2-1',
      }

      this.$store.dispatch('app/updatePageInfo', {info: pageInfo})
    }
  },

  methods: {

    async getPanelsInfo() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/sandopanel12';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel1 = response.data.data.panel1
          self.panel2 = response.data.data.panel2
        })
        .catch((error) => {
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    }
  },

};
</script>
