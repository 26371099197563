<template>

<div class="w-full">
  <div v-if="isLoading" class="text-center my-4">
    <scale-loader></scale-loader>
  </div>
  <div v-else>
    <div v-if="projectNotifications && projectNotifications.length > 0">
      <div v-for="item in projectNotifications" :key="item.id" class="">
        <div class="panel" v-if="notificationsComponents.includes(removeNamespace(item.type))">
          <component
                  :notification="item"
                  :is="'notification-' + removeNamespace(item.type)">
          </component>
        </div>
        <!--<div class="panel" v-else>-->
        <!--{{ removeNamespace(item.type) }}-->
        <!--Componente no creado aún (Borrar esta caja antes de subir a producción)-->
        <!--</div>-->
      </div>
      <infinite-loading @distance="1" @infinite="infiniteHandler">
        <div slot="no-more">{{ $t('no_more_results') }}</div>
        <div slot="no-results">{{ $t('no_more_results') }}</div>
      </infinite-loading>
    </div>
    <div v-else class="panel">
      <span>{{ $t('empty_project_notifications') }}</span>
    </div>
  </div>
</div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import { mapGetters } from 'vuex'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import InfiniteLoading from 'vue-infinite-loading'
import EventBus from '~/plugins/bus'

const req = require.context('./notifications/', false, /\.(js|vue)$/i);

var notificationsComponents = []

req.keys().forEach(key => {
  const name = key.replace('.vue', '').replace('./', '')
  notificationsComponents.push(name.replace('notification-', ''));
  return Vue.component(name, req(key).default)
})

export default {
  name: 'project-activity-notifications',

  components: {
    ScaleLoader,
    InfiniteLoading
  },

  data: () => ({
    activity: null,
    images: null,
    documents: null,
    comments: null,
    list: [],
    listActivities: [],
    listIssues: [],
    isLoading: true,
    projectNotifications: [],
    notificationsComponents: notificationsComponents,
    page: 1,
    lastPage: 1,
    filters: []
  }),

  computed: {
    ...mapGetters({
      project: 'app/project',
      locale: 'lang/locale',
    }),
    params() {
      return { 'page': this.page, 'filters': this.filters }
    }
  },

  mounted() {
    this.loadData()
  },

  created(){
    let self = this;

    EventBus.$on('refreshNotifications', function(e){
      self.updateFilters(self.filters); //recarga desde pagina 1 manteniendo filtros
    });
  },

  beforeDestroy(){
    EventBus.$off("refreshNotifications");
  },

  methods: {
    // Notificaciones del proyecto
    async loadData() {

      let dataNotifications = await axios.get('/api/v2/user/notifications/project/'+ this.project.id, { params: this.params })

      if (dataNotifications.data && dataNotifications.data.success) {
        this.projectNotifications = dataNotifications.data.notifications
        this.lastPage = dataNotifications.data.last
      }

      this.isLoading = false
    },

    removeNamespace(value) {
      let arrayValues = value.split("\\")
      return arrayValues[arrayValues.length - 1]
    },

    infiniteHandler(state) {
      let self = this;

      if (this.lastPage > this.page) {

        this.page += 1;

        axios.get('/api/v2/user/notifications/project/'+ this.project.id, { params: this.params })
          .then(function (response) {
            if (response.data && response.data.success) {
              response.data.notifications.forEach(function(element) {
                self.projectNotifications.push(element);
              });
              state.loaded();
            }
          })
      } else {
        state.complete();
      }
    },

    // Actualiza el filtro y recarga la lista de notificaciones
    updateFilters(filters) {
      let self = this;

      self.filters = filters
      self.page = 1
      self.isLoading = true
      self.projectNotifications = []
      self.loadData()
    }
  },

};
</script>
