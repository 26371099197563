<template>
  <div>
    <b-table
      :data="_list"
      icon-pack="fas"

      class="border-blue-lighter text-xs measurements"
      bordered
      narrowed
      striped
      :loading="loadingData"
    >
      <template>

        <b-table-column field="type" :label="$t('type')" sortable v-slot="items">
          {{ $t(items.row.type) }}
        </b-table-column>

        <b-table-column field="description" :label="$t('name')" sortable v-slot="items">
          <span v-if="items.row.status == 1">
            <b-tooltip :label="$t('pending_validation_quantity')" position="is-left">
              <b-icon class="text-orange" pack="fas" icon="question"></b-icon>
            </b-tooltip>
          </span>
          <span v-else-if="items.row.status == 2">
            <b-tooltip :label="$t('rejected')" position="is-left">
              <b-icon class="text-red" pack="fas" icon="exclamation"></b-icon>
            </b-tooltip>
          </span>
          <span @click="onClickEdit(items.row)">
            <i v-if="items.row.type === 'chapter' && items.row.level" :style="'margin-left: ' + items.row.marginLeft + 'px'" class="w-4 line-level"></i>
            {{ (items.row.type === 'chapter' ? (items.row.code + ' ') : '') + items.row.description }}
          </span>
        </b-table-column>

        <b-table-column field="unit_name" :label="$t('measurement_unit_short')" sortable v-slot="items">
          <span v-if="items.row.unit_name || items.row.type === 'chapter'">{{  items.row.type !== 'chapter' ? items.row.unit_name : project.currency  }}</span>
          <span v-else>---</span>
        </b-table-column>

        <b-table-column field="total" :label="$t('total_quantity')" centered sortable v-slot="items">
          <span v-if="!isTask(items.row)">{{ twoDecimals(items.row.total) }}</span>
          <span v-else>---</span>
          <b-icon v-if="items.row.type=='certifier' && entityBlockedAssignments"
              pack="fas"
              icon="check-double"
              type="is-success"
              size="is-small">
          </b-icon>
        </b-table-column>

        <b-table-column field="executed_quantity" :label="$t('executed_quantity')" centered sortable v-slot="items">
          <span v-if="!isTask(items.row)">{{ twoDecimals(items.row.executed_quantity) }}</span>
          <span v-else>---</span>
        </b-table-column>

        <b-table-column v-if="project.can_progress" field="actual_progress" :label="$t('actual_quantity')" centered sortable v-slot="items">
          <span v-if="!isTask(items.row)">{{ twoDecimals(items.row.actual_progress) }}</span>
          <span v-else>---</span>
        </b-table-column>

        <b-table-column field="pending_validation_quantity" :label="$t('pending_validation_quantity')" centered sortable v-slot="items">
          <span v-if="isResource(items.row)">
            {{ twoDecimals(items.row.pending_validation_quantity) }}
            <span v-if="items.row.pending_validation_quantity != 0 &&
                  !items.row.has_children && apiProject.can_validate"
                @click="onClickValidate(items.row)">
              <b-tooltip
                :label="$t('validate')"
                position="is-top"
              >
                  <b-icon pack="fas" icon="thumbs-up"></b-icon>
              </b-tooltip>
            </span>
          </span>
          <span v-else>---</span>
        </b-table-column>

        <b-table-column field="progress_label" :label="$t('progress_label')" centered sortable v-slot="items">
          <progress-bar
            v-if="!isTask(items.row) && items.row.total > 0"
            :step="parseFloat((items.row.executed_quantity * 100) / items.row.total)"
            color="blue-light"
            class="w-full"
          />
        </b-table-column>

        <b-table-column v-if="project.can_progress" field="progress_label" :label="$t('actual_progress_short')" centered sortable v-slot="items">
          <progress-bar
            v-if="!isTask(items.row) && items.row.total > 0"
            :step="parseFloat((items.row.actual_progress * 100) / items.row.total)"
            color="blue-light"
            class="w-full"
          />
        </b-table-column>

        <b-table-column field="completed" :label="$t('activity_status_done')" centered sortable v-slot="items">
          <b-tooltip
            :label="items.row.completed ? $t('completed'):$t('uncompleted')"
            position="is-top"
          >
            <div v-html="isCompleted(items.row)"></div>
          </b-tooltip>
        </b-table-column>
      </template>

    </b-table>

    <!-- Modal para Editar -->
    <assignment-update-modal
      ref="assignment_update_modal"
      entity-type="activity"
      :entity-id="entityId"
      @reload="reload"
    />

    <assignment-validate-quantity-modal
      ref="assignment_validate_quantity_modal"
      entity-type="activity"
      :entity-id="entityId"
    />
  </div>
</template>

<script>

  import axios from 'axios'
  import assignmentUpdateModal from "·/components/assignment-update-modal";
  import EventBus from "~/plugins/bus";
  import {mapGetters} from "vuex";
  import AssignmentValidateQuantityModal from '·/components/assignment-validate-quantity-modal';

  export default {
    name: "assignment-table",

    components: {
      assignmentUpdateModal,
      AssignmentValidateQuantityModal
    },

    props: {
      list: Array,
      entityId: {type: Number, required: true},
      entityBlockedAssignments: {type: Boolean, required: false, default: true},
      apiProject: { type: Object, required: false }
    },

    data: function() {
      return {
        loadingData: false,
        assignments: this.list,
      }
    },

    computed: {
      ...mapGetters({
        user: 'auth/user',
        project: 'app/project'
      }),
      _list() {
        // Añdimos propiedad level si solo es chapter contiene la profundidad en la que se encuentra el nodo
        // luego sera usado para identar en la vista
        // tambien añadimos un apropiedad code order para ordenar por el mismo y evitar que aparesca el siguiente orden 1, 10, 11, 2, 3
        // para ello en codeOrder añadimos un cero delante del numero si es menor a 10
        this.assignments.forEach(( item ) => {
          if( item.type === 'chapter' ) {
            let codes = item.code.split('.')
            item.level = codes.length - 1
            item.marginLeft = item.level * 5

            if( item.level > 1 )
              item.marginLeft += 5

            if( codes[0].length === 1 )
              item.sortCode = '0' + codes.join('.')
            else
              item.sortCode = codes.join('.')
          }
        })

        //primero ordenamos por tipo y luego ordenamos por codigo
        // TODO revisar y monitorear el comportamiento de momento funciona esta logica pero si hay otro tipo con un code puede fallar
        // buscar una manera para optimizar esta logica
        let orderList = this.assignments.sort((a, b) => { a.type.localeCompare(b.type) })
        // Aquí solo mandamos a ordenar los que son de tipo capítulo
        let listOrder = orderList.sort( function(a, b) {
          if( a.type === 'chapter' && b.type === 'chapter' ) {
            if (a.sortCode < b.sortCode) return -1;
            if (a.sortCode > b.sortCode) return 1;
          }

          return 0;
        });


        return listOrder
      }
    },

    watch: {},

    mounted() {
      let self = this;
      EventBus.$on("refreshAssignmentChangedNotifications", function (e) {
        if (self.entityId === e.activity_id) {
          self.reload();
        }
      });
      EventBus.$on("refreshAssignmentCreatedNotifications", function (e) {
        if (self.entityId === e.activity_id) {
          self.reload();
        }
      });
      EventBus.$on("refreshAssignmentDeletedNotifications", function (e) {
        if (self.entityId === e.activity_id) {
          self.reload();
        }
      });
    },

    beforeDestroy(){
      EventBus.$off("refreshAssignmentChangedNotifications");
  	  EventBus.$off("refreshAssignmentCreatedNotifications");
  	  EventBus.$off("refreshAssignmentDeletedNotifications");
    },

    methods: {
      /**
       * Abre el modal para editar una medición
       */
      onClickEdit(row) {

        return; // deshabilitado modal para q no se pueda editar aqui

        if (!this.canOpenEditModal(row))
          return;

        this.entityId = row.parentId;
        this.$refs.assignment_update_modal.show(row.id);
      },

      // Modal para confirmar cantidades ejecutadas
      onClickValidate(row) {

        // User es usuario certificador del proyecto
        if (!this.apiProject.can_validate)
          return;

        this.entityId = row.parentId;
        this.$refs.assignment_validate_quantity_modal.show(row.id);
      },

      isTask(row) {
        if (row.type === 'task')
          return true;
      },

      isResource(row) {
        if (row.type === 'resource')
          return true;
      },

      reload(data) {
        this.$emit("reload", data);
      },

      isCompleted(row) {
        if (row.completed)
          return '<i class="fas fa-check text-green"/>';

        return '<i  class="fas fa-times text-red"/>';
      },

      /**
       * Convierte el numero a dos decimales obligatoriamente
       * @param number
       * @returns {string}
       */
      twoDecimals(number) {
        // to fix numeber is null o undefined to cast cero
        return parseFloat(number??0).toFixed(2);
      },

      getStatusIcon(row) {
        switch (row.execution_status) {
          case 0:
            return 'fas fa-check text-green';
            break;
          case 1:
            return 'fas fa-chevron-up text-red';
            break;
          case -1:
            return 'fas fa-chevron-down text-blue';
            break;
        }
      },

      getSemaforoIcon(row) {
        return '<i class="' + this.getStatusIcon(row) + '"/>';
      },

      getStatusLabel(row) {
        switch (row.execution_status) {
          case 0:
            return 'assignment-completed';
            break;
          case 1:
            return 'assignment-over';
            break;
          case -1:
            return 'assignment-under';
            break;

        }
      },

      canOpenEditModal(row) {
        if (row.user_id != this.user.id && this.isTask(row)) return false;
        return true;
      },

      loadAssigments(id) {
        this.loadingData = true;
        let self = this;
        let url = '/api/v2/activity/' + id + '/assignments';
        axios.get(url, {params: {}}).then(function (response) {
          if (response.data && response.data.success) {
            self.assignments = response.data.data
          }
          self.loadingData = false;
        })
      }

    }
  };
</script>

<style lang="scss">
  .line-level {
    display: inline-block;
    border-left: 2px solid #607eb9;
    border-bottom: 2px solid #607eb9;
    padding-bottom: 10px;
  }
  .measurements {
    .table th {
      background-color: #679dbf;
    }

    &.b-table .table.is-bordered th.is-current-sort, &.b-table .table.is-bordered th.is-sortable:hover {
      background-color: #679dbf !important;
    }

  }

  .clickable {
    cursor: pointer;
  }
</style>
