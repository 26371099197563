<template>

<b-modal :active.sync="isModalActive">

  <div class="w-auto border border-blue-light rounded">
    <header class="w-full bg-grey-lightest px-4 py-2 text-xl font-thin uppercase text-blue-light">
      {{ document.name }}
    </header>

    <div class="p-6 bg-grey-lightest text-center">

      <div v-if="isLoading" class="mt-4">
        <scale-loader></scale-loader>
      </div>

      <div v-else-if="document.versions.length > 0">
        <b-table
                :data="document.versions"
                :paginated="true"
                pagination-size="is-small"
                :per-page="10"
                :striped="true"
                :bordered="true"
                icon-pack="fas"
                class="text-sm"
        >

          <template>
            <b-table-column field="user" :label="$t('creator')" width="40" cell-class="vertical-center" centered>
              <template v-slot:header="">
                <i class="fas fa-user"></i>
              </template>
              <template v-slot="props">
                <b-tooltip :label="props.row.user.basic.fullname" position="is-right" size="is-small" multilined >
                  <div class="rounded-full overflow-hidden border border-grey w-8 h-8" style="margin: auto">
                    <img :src="props.row.user.basic.avatar" :alt="props.row.user.basic.fullname">
                  </div>
                </b-tooltip>
              </template>
            </b-table-column>

            <b-table-column field="size" :label="$t('size')" width="92" cell-class="vertical-center" v-slot="props">
              {{ getReadableFileSizeString(props.row.size) }}
            </b-table-column>

            <b-table-column field="original_filename" :label="$t('type')" width="40" cell-class="vertical-center" v-slot="props">
              {{ props.row.original_filename.substr(props.row.original_filename.lastIndexOf('.') + 1).toUpperCase() }}
            </b-table-column>

            <b-table-column field="created_at" :label="$t('uploaded_at')" cell-class="vertical-center" v-slot="props">
              <!-- {{ formatDate(props.row.created_at) }} -->
              {{ formatDate(props.row.document_updated_at) }}
            </b-table-column>

            <b-table-column field="id" :label="$t('download')" width="40" style="margin: auto" cell-class="vertical-center" centered>
              <template v-slot:header="">
                <i class="fas fa-download"></i>
              </template>
              <template v-slot="props">
                <v-button-icon-round
                        @click="downloadVersion(props.row)"
                        icon="download"
                />
              </template>
            </b-table-column>
          </template>

        </b-table>

        <div v-if="entityType != 'validation' && !document.related_validation">
          <a class="button is-danger" @click="onClickRestore">
            {{ $t('restore_previous_version') }}
          </a>
        </div>

      </div>

    </div>
  </div>
</b-modal>
</template>

<script>
import axios from 'axios'
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  name: 'document-versions',

  components: {
    ScaleLoader
  },

  props: {
    document: { type: Object },
    entityId: { type: Number, required: true },
    entityType: { type: String, required: true }
  },

  data: () => ({
    isModalActive: false,
    isLoading: false,
  }),

  methods: {

    async downloadVersion(version) {

      const { data } = await axios.get(
        "/api/v2/document/version/" + version.id,
        // { responseType: "blob" }
      );

      if (data) {

        window.open(data.data);

        // const link = document.createElement("a");
        // link.href = window.URL.createObjectURL(data);
        // link.setAttribute("download", version.original_filename);
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);

      } else {
        this.$notify.error("error_download_document");
      }
    },

    getReadableFileSizeString(fileSizeInBytes) {
      var i = -1;
      var byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
      do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
      } while (fileSizeInBytes > 1024);
      return Math.max(fileSizeInBytes, 0.01).toFixed(2) + byteUnits[i];
    },

    show() {
      this.isModalActive = true
    },

    hide() {
      this.isModalActive = false
    },

    onClickRestore() {
      this.$dialog.confirm({
        title: this.$t('warning'),
        message: this.$t('restore_document_version_text'),
        confirmText: this.$t('restore'),
        cancelText: this.$t('cancel'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.restoreVersion()
      })
    },

    restoreVersion() {
      let self = this
      this.isLoading = true

      axios.post(
        "/api/v2/document/" + this.document.document_id + "/restore_version",
        { 'entity_type': this.entityType, 'entity_id': this.entityId }
      ).then(function (response) {
        if (response.data && response.data.success) {
          self.$notify.success("success_restoring_document");
          self.$emit('reload-documents-list')
        } else {
          self.$notify.error("error_restoring_document");
        }
      }).finally(function() {
        self.isLoading = false
        self.hide()
      })
    },

    // Formatea la fecha poniendo el día al principio y el año al final
    formatDate(dateTimeString) {
      let options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
      let _date = new Date(dateTimeString);
      return _date.toLocaleDateString('es-ES', options);
    }
  },

};
</script>
