<template>
  <div>
      <!-- Elements Attribute Value -->
      <div class="panel relative">

        <div v-if="loaded" class="my-4 text-center">
          <scale-loader></scale-loader>
        </div>

        <div v-if="extrafield && !loaded">
          <div class="flex items-center justify-between w-full mb-5">
            <div><label><b>{{ $t('values') }} <span v-if="extrafield && form.name"> - {{ $t('extra_field') + ' ' + form.name }}</span></b></label></div>
            <div>
              <b-tooltip :label="$t('close')" position="is-left">
                <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
                  <b-icon pack="fas" icon="times" size="is-small" />
                </div>
              </b-tooltip>
            </div>
          </div>

          <!-- recuadro de valores -->
          <div class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-blue-lightest mb-1"
            v-for="(value, taskKey) in form.values" :key="value.id">

            <div class="flex items-center">
              <!-- delete -->
              <b-tooltip :label="$t('remove')" position="is-left" type="is-danger">
                  <div @click="deleteValue(value.id, value.name)" class="flex cursor-pointer text-red">
                    <b-icon pack="fas" icon="trash-alt"/>
                  </div>
              </b-tooltip>
              <!-- save -->
              <b-tooltip v-if="valuesInEdition.includes(value.id)" :label="$t('save')" position="is-left">
                  <div @click="sendValueData(value.id, taskKey)" class="flex cursor-pointer text-blue">
                    <b-icon pack="fas" icon="download"/>
                  </div>
              </b-tooltip>
              <!-- edit -->
              <b-tooltip v-else :label="$t('edit')" position="is-left">
                  <div @click="editValue(value.id)" class="flex cursor-pointer text-blue">
                    <b-icon pack="fas" icon="pencil-alt"/>
                  </div>
              </b-tooltip>

              <span v-if="valuesInEdition.includes(value.id)" class="inline-flex ml-2">
                <input v-model="form.values[taskKey].code" type="text" class="mr-2 input">
                <input v-model="form.values[taskKey].name" type="text" class="mr-2 input">
              </span>
              <span v-else class="ml-2">
                <span v-if="value.code">{{ value.code }} - </span>
                {{ value.name }}
              </span>
            </div>
          </div>
          <div v-if="form.values.length == 0" class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-gray-200 mb-1 has-text-centered">{{ $t('no_results') }}</div>
          <!-- recuadro añadir values -->
          <div class="w-full border border-blue-lighter rounded flex items-center p-2 bg-blue-lightest mb-1" v-if="form.field_type == 0">

            <b-tooltip :label="$t('add_generic')" position="is-left">
                <button class="btn btn-green items-center mr-2"  type="button" @click="saveValue()">
                  <i class="fas fa-plus"></i>
                </button>
            </b-tooltip>

            <!-- <b-tooltip :label="$t('name')" position="is-left"> -->
              <span class="inline-flex ml-2">
                <input v-model="newValue.code" type="text" :placeholder="$t('code')" class="input mr-2">
                <input v-model="newValue.name" type="text" :placeholder="$t('name')" class="input mr-2">
              </span>
            <!-- </b-tooltip> -->
          </div>
        </div>

      </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    ScaleLoader,
    Treeselect,
  },

  props: {
    extrafield: { type: Object, required: true },
    apiEntity: { type: String, default: 'extrafield' }
  },

  data() {
    return {
      disableSaveButton: false,
      processingData: false,
      loaded: false,

      form: new Form({
        id: this.extrafield ? this.extrafield.id : null,
        code: this.extrafield ? this.extrafield.code : '',
        name: this.extrafield ? this.extrafield.name : '',
        field_type: this.extrafield ? this.extrafield.field_type : null,
        values: [],
      }),

      newValue: new Form({
        code: '',
        name: '',
      }),

      valuesInEdition: [], // valor que estan en modo edicion. Editor inline
    }
  },

  computed: {
  },

  watch: {
  },
  created() {
    this.readExtrafield()
  },
  mounted() {

  },

  methods: {
    async readExtrafield() {

      if(this.extrafield){

        this.loaded = true;
        let params = { with: 'values' };

        const { data } = await axios.get(
          `/api/v2/${this.apiEntity}/` + this.extrafield.id,
          { params: params }
        );

        if (data && data.success) {
          this.form.values = data.extrafield.values;
        } else {
          this.$notify.error("error_loading_measurement_units_data");
        }

        this.loaded = false;
      }

    },


    onCloseClicked() {
      this.value = null;

      this.$emit('epc-close', true)
    },

    // redirige a pantalla edicion
    editValue(valueId) {
      this.valuesInEdition.push(valueId) // edicion inline
    },

    // guarda cambios de la tarea y la saca del modo edicion
    async sendValueData(valueId, taskKey) {

      var url = `/api/v2/${this.apiEntity}/value/` + valueId;

      // Enviamos el form
      const { data } = await axios.post(url, this.form.values[taskKey])
      if (data && data.success) {

        // la sacamos del modo edicion
        this.valuesInEdition.splice(this.valuesInEdition.indexOf(valueId),1)

        this.$notify.success('success_editing')

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')

      } else {
        this.$notify.error('error_saving')
      }
    },

    // crea nueva tarea en esta plantilla
    async saveValue() {
      if (this.newValue.code == '') {
        this.$notify.error(this.$t('empty_field_required', ['código']))
        return
      }

      if (this.newValue.name == '') {
        this.$notify.error(this.$t('empty_field_required', ['nombre']))
        return
      }

      const { data } = await axios.post(`/api/v2/${this.apiEntity}/` + this.extrafield.id + "/value", {
        code: this.newValue.code,
        name: this.newValue.name,
        element_id: this.extrafield.id
      })

      if (data && data.success) {
        // reload
        this.readExtrafield()
        this.newValue = new Form({
          code: '',
          name: '',
        }),

        this.$notify.success('success_editing')

      } else if(data && data.msg) {
        this.$notify.error('no_access_permissions')
      } else {
        this.$notify.error('error_saving')
      }
    },

    // elimina el valor
    async deleteValue(valueId, valueName) {

      let self = this;
      this.$dialog.confirm({
        title: this.$t('delete'),
        message: this.$t('delete_generic', [valueName]),
        confirmText: this.$t('delete'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {

          const { data } = await axios.post(`/api/v2/${this.apiEntity}/value/` + valueId + "/delete", {})
          if (data && data.success) {

            // reload
            this.readExtrafield()
            this.$notify.success('success_deleting_generic')

          } else if(data && data.msg){

            this.$notify.error('no_access_permissions')

          } else {
            this.$toast.open({
              message: data.error || this.$t("error_saving"),
              type: "is-danger",
              position: "is-top-right"
            });

            // this.$notify.error('error_saving')
          }
        }
      })
    },
  },
};
</script>

