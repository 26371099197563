<template>
  <div class="page-with-top-bar">
    <project-admin-quick-buttons hiddenOption="validations"/>
    <section>
      <div class="mt-4">
  <div class="flex mt-5">
    <div class="w-full mr-2">
      <div class="flex items-center justify-end w-full">
        <v-button v-if="this.entityId" icon="tasks" @click="goToPage('bulk-actions')" class="mr-4">{{ $t('bulk_actions') }}</v-button>
        <v-button v-if="this.entityId" icon="circle-exclamation" @click="goToPage('rules')" class="mr-4">{{ $t('project_rules') }}</v-button>
      </div>
      <div class="panel">
        <div v-if="isLoading" class="my-4 text-center">
          <scale-loader></scale-loader>
        </div>
        <div v-else>
          <div class="flex flex-wrap w-full mt-4">
            {{ $t('check_the_flows') }}:
            <section class="w-full mt-2">
              <b-table
                icon-pack="fas"
                :data="validations"
                :paginated="isPaginated"
                :per-page="perPage"
                :bordered="true"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :default-sort-direction="defaultSortDirection"
                v-if="!isLoading"
                checkable
                checkbox-position="left"
                :checkbox-type="'is-success'"
                :checked-rows.sync="checkedRows"
                @check="handleCheck"
                @check-all="handleCheckAll"
                >
                <template>
                  <b-table-column field="code" cell-class="vertical-center" sortable>
                    <template v-slot:header="">{{ $t('code') }}</template>
                    <template v-slot="props">{{ props.row.code }}</template>
                  </b-table-column>

                  <b-table-column field="name" cell-class="vertical-center" sortable>
                    <template v-slot:header="">{{ $t('name') }}</template>
                    <template v-slot="props">{{ props.row.name }}</template>
                  </b-table-column>

                  <b-table-column field="actions" cell-class="vertical-center has-text-centered w-1/12">
                    <template v-slot:header="">{{ $t('actions') }}</template>
                    <template v-slot="props">
                      <b-tooltip :label="$t('config_rules')" position="is-left">
                        <div @click="loadRules(props.row)" class="cursor-pointer flex text-blue">
                          <b-icon pack="fas" icon="gear" />
                        </div>
                      </b-tooltip>
                    </template>
                  </b-table-column>
                </template>

                <template #empty>
                    <div class="has-text-centered">{{ $t('no_results') }}</div>
                </template>
              </b-table>
            </section>
          </div>

          <!-- modal reglas de projecto -->
          <entity-project-rules-modal
            ref="entity_project_rules_modal"
            :project-id="entityId"
          />
        </div>
      </div>
    </div>

  </div>

      </div>
    </section>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons';
  import EntityProjectRulesModal from './entity-project-rules-modal';
  import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
  import EventBus from '~/plugins/bus';
  import axios from "axios";

  export default {
    middleware: "auth",
    title: "validations",
    menuOption: "0-2",

    components: {
      ProjectAdminQuickButtons,
      ScaleLoader,
      EntityProjectRulesModal,
    },

    computed: {
      ...mapGetters({
        navData: "app/navData",
        company: 'app/company',
      }),
      projectId() {
        return parseInt(this.navData.project_id);
      }
    },

    data: () => ({
      isLoading: true,
      page: 1,
      perPage: 25,
      orderField: "code",
      orderDir: "asc",
      currentPage: 1,
      defaultSortDirection: 'asc',
      isPaginationSimple: false,
      isPaginated: true,

      validations: [],
      checkedRows: [],

      entityId: null
    }),

    created() {
      let projectName = this.navData.project_name
        ? this.navData.project_name.toUpperCase()
        : "";
      this.$options.subtitle = ["project_actions_page_subtitle", [projectName]];
      this.entityId = this.projectId;
      this.getValidations();
    },

    methods: {
      async getValidations() {
        this.isLoading = true;
        let params = {
          company_id: this.company.id
        }

        const { data } = await axios.get("/api/v2/validations/templateImportsList", { params });

        if (data && data.success) {
          this.validations = data.templates || [];
          await this.getProjectValidations()
        } else {
          this.$notify.error("error_loading_validations");
        }
        this.isLoading = false;
      },

      async getProjectValidations() {
        let {data} = await axios.get("/api/v2/project/"+this.entityId+"/validations-templates");
        if (data.success && data.templates) {
          for (let validation of this.validations) {
            if (data.templates.some(t => t.id == validation.id)) {
              this.checkedRows.push(validation)
            }
          }
        }
      },
      
      async saveProjectValidations(templates) {
        let body = {
          templates : templates.map(t => t.id)
        }
        let {data} = await axios.post("/api/v2/project/"+this.entityId+"/validations-templates/sync", body)
      },

      // maneja evento check fila individual
      handleCheck(checkedList, row) {
        
        // handleCheck tambien es disparado al chequear todo (@check-all). Asi que solo guardamos si recibimos la fila, para evitar doble guardado
        if (row) {
          this.saveProjectValidations(checkedList)
        }
      },

      // maneja evento check all
      handleCheckAll(checkedList) {
        this.saveProjectValidations(checkedList)
      },

      loadRules(template) {
        this.$refs.entity_project_rules_modal.show(template)
      },

      goToPage(destiny) {

        switch (destiny) {
          case 'rules':
              this.$router.push({ name: 'project.admin.rules', params: {
                project_id: this.entityId
              } })
              break;
          case 'bulk-actions':
              this.$router.push({ name: 'project.admin.project-bulk-actions', params: {
                project_id: this.entityId
              } })
              break;
        }

      }
    }
  };
</script>
