<template>
  <div>
    <button
      style="margin-right: 5px"
      @click="onClickReplaceButton(userListIndex)"
      @mouseover="iconReplaceHovered = true"
      @mouseleave="iconReplaceHovered = false"
      class="flex items-center cursor-pointer">
      <b-icon v-show="! iconReplaceHovered"
        pack="fa"
        icon="exchange-alt"
        class="text-grey float w-full h-full"
        size="is-small"/>
      <b-icon v-show="iconReplaceHovered"
        pack="fa"
        icon="exchange-alt"
        class="text-grey replace-hover float w-full h-full"
        size="is-small"/>
    </button>
  </div>
</template>

<script>
export default {
  name: "user-replacement",

  props: {
    userListIndex: { type: Number, required: true }
  },

  data: () => ({
    iconReplaceHovered: false,
    showSearchBox: false
  }),

  methods: {

    onClickReplaceButton(index) {
      this.$emit('replaceButtonClicked', index)
    },

  }
}
</script>

<style>
  .replace-hover {
    color: #57b !important;
  }
</style>
