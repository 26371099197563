<template>
  <div>
    <div class="top-bar flex justify-end" >
      <v-button
              v-if="isAdminLocations"
              @click="addLocation"
              icon="plus">
        {{ $t('add_location') }}
      </v-button>
    </div>
    <div>
      <!-- Mapa -->
      <div class="panel">
        <googlemaps ref="mapRef"
          :datamaps="datamaps"
          :draggable="draggable"
          @updPlace="updPlace"
          :newPlace="newPlace"
          inputAutocomplete="address_id" />
      </div>
      <!-- Tabla de localizaciones -->
      <transition
              name="custom-classes-transition"
              enter-active-class="animated fadeInUp"
              leave-active-class="animated fadeOutDown">
        <div class="panel">
          <div class="w-full flex flex-wrap" v-show="create!=false || place.reference_code">
            <div class="panel">
              <form @submit.prevent="changeLocation" class="w-full flex">
                <div class="flex-grow pr-3">
                  <label for="place_id" class="w-full">{{ $t('location_code') }}</label>
                  <v-input
                    v-model="place.reference_code"
                    title="place_title"
                    id="place_id"
                    name="place_name"
                    placeholder="place_placeholder"
                    class="w-full"
                    required
                  />
                </div>
                <div class="flex-grow">
                  <label for="address_id" class="w-full">{{ $t('place_address') }}</label>
                  <v-input
                    v-model="place.address"
                    title="address_title"
                    id="address_id"
                    name="address_name"
                    placeholder="address_placeholder"
                    class="w-full"
                    required
                  />
                </div>

                <div class="flex-grow-0 pr-3 inline-flex self-end">
                  <div @click="changeNewPlace" class="btn"><b-icon icon="search" type="is-primary" pack="fas"/></div>
                  <v-button
                          native-type='submit'
                          :disabled="!place.reference_code || !place.address || !place.latitude"
                  >{{ $t('save') }}</v-button>
                </div>
              </form>
            </div>
          </div>
          <section>
            <b-table
              :data="datamaps"
              :loading="isLoading"
              icon-pack="fas"

              paginated
              backend-pagination
              :per-page="perPage"
              :total="total"
              @page-change="onPageChange"

              backend-sorting
              :default-sort="orderField"
              :default-sort-direction="orderDir"
              @sort="onSort"

              striped
              bordered
              :mobile-cards="false"
            >
              <template>
                <b-table-column field="reference_code" sortable>
                  <template v-slot:header="">{{ $t('reference_code') }}</template>
                  <template v-slot="props">{{ props.row.reference_code }}</template>
                </b-table-column>

                <b-table-column field="coordinate">
                  <template v-slot:header="">{{ $t('coordinate') }}</template>
                  <template v-slot="props">{{ getCoordinate(props.row.latitude, props.row.longitude) }}</template>
                </b-table-column>

                <b-table-column field="user" class="w-12 vertical-center" centered>
                  <template v-slot:header="">{{ $t('creator') }}</template>
                  <template v-slot="props">
                    <b-tooltip v-if="props.row.user" :label="props.row.user.basic.fullname">
                      <img
                              :src="props.row.user.basic.avatar"
                              class="rounded-full overflow-hidden w-6 h-6">
                    </b-tooltip>
                  </template>
                </b-table-column>

                <b-table-column field="updated_at" sortable>
                  <template v-slot:header="">{{ $t('date') }}</template>
                  <template v-slot="props">{{ new Date(props.row.updated_at).toLocaleDateString() + ' ' + new Date(props.row.updated_at).toLocaleTimeString() }}</template>
                </b-table-column>
                <b-table-column field="actions" :visible="isAdminLocations" centered width="80">
                  <template v-slot:header="">{{ $t('actions') }}</template>
                  <template v-slot="props">
                    <div class="w-full h-full flex items-center justify-around">
                      <b-tooltip :label="$t('edit_location')" position="is-left">
                        <div @click="editPlace(props.row)" class="cursor-pointer flex text-blue">
                          <b-icon icon="pencil-alt" pack="fas"/>
                        </div>
                      </b-tooltip>
                      <b-tooltip v-if="canDelete(props.row)" :label="$t('location_has_activities')" position="is-left">
                        <div class="cursor-not-allowed flex text-grey">
                          <b-icon icon="trash-alt" pack="fas"/>
                        </div>
                      </b-tooltip>
                      <b-tooltip v-else :label=" props.row.activities_count == 0 ? $t('remove_location'): $t('remove_location_unavailable')" type="is-danger" position="is-left">
                        <div 
                        @click="props.row.activities_count == 0 ? removePlace(props.row) : null" 
                        :class="{ 'cursor-pointer': props.row.activities_count == 0, 'text-red': props.row.activities_count == 0 }"
                        :disabled="props.row.activities_count == 0"
                        class="flex">
                          <b-icon icon="trash-alt" pack="fas"/>
                        </div>
                      </b-tooltip>
                    </div>
                  </template>
                </b-table-column>
              </template>
            </b-table>
          </section>
        </div>
      </transition>
    </div>
    <!-- Modal para PREVIEW -->
    <location-modal
            ref="location_modal"
            @reload-locations="() => reload()"
    />
  </div>
</template>

<script>
  import Form from 'vform'
  import Googlemaps from '~/components/GoogleMaps.vue'
  import LocationModal from './location-modal'
  import axios from 'axios'
  import EventBus from '~/plugins/bus'

  export default {
    name: 'project-locations-manual',

    components: {
      Googlemaps,
      LocationModal
    },

    data: () => ({
      datamaps: [],
      datatable: null,
      place: {
        id: 0,
        reference_code: '',
        address: '',
        latitude: '',
        longitude: '',
      },
      formChangeLocation: new Form({
        name: '',
        address: '',
        latitude: '',
        longitude: '',
      }),
      newPlace: '',
      create: false,
      draggable: false,
      isLoading: true,
      autocomplete: null,
      page: 1,
      perPage: 15,
      total: 0,
      orderField: "reference_code",
      orderDir: "asc",
    }),

    props: {
      projectId: { type: Number, required: true },
      isAdminLocations: {type: Boolean, default: false}
    },

    mounted() {
      this.getProjectLocations()
    },

    methods: {

      //Juanjo - 24-07-2018 - parece que este método es innecesario
      async addLocation() {

        this.reload()
        this.getProjectLocations()
        this.create = true
      },

      async getProjectLocations() {
        this.isLoading = true
        let params = {
          'p': this.projectId,
          'order_dir': this.orderDir,
          'order_field': this.orderField,
          'all': true,
        }
        const { data } = await axios.get('/api/v2/locations', {params: params})
        if (data && data.success) {
          this.datamaps = data.locations
          this.total = data.locations ? data.locations.length : 0
          EventBus.$emit('load-locations', data.locations);
        } else {
          this.$notify.error('error_loading_locations_data')
        }
        this.isLoading = false
      },

      async updPlace(place) {
        this.place.latitude = place.latitude
        this.place.longitude = place.longitude
      },

      async changeNewPlace() {

        this.newPlace = document.getElementById('address_id').value
      },

      reload() {
        this.getProjectLocations()
        this.$refs.mapRef.reload()
      },

      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },

      onPageChange(page) {
        this.page = page
        this.reload()
      },

      onSort(field, order) {
        this.orderField = field
        this.orderDir = order
        this.reload()
      },

      editPlace(location) {
        this.$refs.location_modal.show(location)
      },

      async removePlace(location) {

        this.$dialog.confirm({
          title: this.$t('delete_location'),
          message: this.$t('delete_location_confirm_text', [location.reference_code]),
          confirmText: this.$t('delete_location'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {

            this.isLoading = true
            let url = '/api/v2/location/' + location.id + '/remove'
            const { data } = await axios.post(url)

            if (data.success) {
              this.$toast.open({
                message: this.$t('location_remove_success'),
                type: 'is-success',
                position: 'is-top-right'
              })
              await this.sleep(1000)
              this.reload()
            } else {
              this.isLoading = false
              // Error
              this.$toast.open({
                message: this.$t('location_remove_error'),
                type: 'is-danger',
                position: 'is-top-right'
              })
            }
          }
        })
      },

      async changeLocation () {

        this.isLoading = true

        let url = '/api/v2/location/' + this.projectId + '/addLocation'

        this.formChangeLocation.reference_code = this.place.reference_code
        this.formChangeLocation.latitude = this.place.latitude
        this.formChangeLocation.longitude = this.place.longitude

        const { data } = await this.formChangeLocation.post(url)

        if (data.success) {

          this.$toast.open({
            message: this.$t('location_change_success'),
            type: 'is-success',
            position: 'is-top-right'
          })

          this.create = false
          this.place.reference_code = ''
          this.place.address = ''
          this.place.id = 0
          this.reload()

        } else {

          this.isLoading = false
          let msg = data.error_msg ? data.error_msg : this.$t('location_change_fail')

          // Error
          this.$toast.open({
            message: msg,
            type: 'is-danger',
            position: 'is-top-right'
          })
        }
      },

      getCoordinate(latitude, longitude) {
        let coordinate = '';
        if (latitude) {
          coordinate = parseFloat(latitude).toFixed(4)
        }
        if (longitude) {
          coordinate = coordinate + ', ' + parseFloat(longitude).toFixed(4)
        }
        return coordinate
      },

      canDelete(location) {
        return (location.activities && location.activities.length > 0) ||
          (location.issues && location.issues.length > 0)
      }
    }
  };
</script>
