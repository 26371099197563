<template>
  <div v-show="existProcess" class="mr-4 relative rounded-full bg-slate-100 p-1">
    <a v-if="isPublic" slot="trigger" class="text-black hover:text-grey " @click="showSide">
      <b-icon icon="tasks" pack="fas" class="float-left"></b-icon>
      <span class="px-1 py-0 text-xs text-white rounded-full bg-red absolute -bottom-1_ -right-1">{{ listQueue.length }}</span>
    </a>
    <a v-else slot="trigger" class="text-black hover:text-grey" @click="showSide">
      <b-icon icon="tasks" pack="fas" size="is-small" class="float-left"></b-icon>
    </a>

    <div class="slider bg-grey-darkest rounded-tl-lg rounded-tr-lg" :class="sliderClass" style="z-index: 1000">
      <!-- Info actividad -->
      <div class="flex h-12 rounded-tl-lg rounded-tr-lg flex-row items-center">
        <div class="inline-block flex-1 truncate overflow-hidden pl-2">
          <span class="whitespace-nowrap" style="color: white;">Procesos en cola</span>
        </div>
        <div class="leading-normal">
          <div class="float-right h-auto ml-auto mr-3 p-0 ">
            <v-button-icon-round
              icon="times"
              color="red"
              tooltip-position="left"
              @click="showSide"/>
          </div>
        </div>
      </div>

      <div id="content" class="overflow-y-scroll h-full">
        <div v-for="queue in listQueue">
          <process-item :data="queue"></process-item>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '~/plugins/bus'
import axios from 'axios'
import { mapGetters } from 'vuex'

import ProcessItem from './ProcessItem.vue'

export default {
  name: 'process-queue',

  props: {
  },

  components: {
    ProcessItem
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),

    // metodo verifica si existe procesos pendientes o en proceso
    existProcess() {
      let process = this.listQueue.filter(obj => obj.status < 2)
      return process.length > 0
    },
  },

  data: () => ({
    isPublic: true,
    loading: false,

    sliderClass: '',
    sliderShown: false,
    logActions: undefined,

    listQueue: []
  }),

  created() {
    this.loadProcesslog()
    // Referenciamos
    var self = this;

    // Si recibimos el evento refresh, refrescamos el componente
    EventBus.$on('processQueueLog', function(e){
      let objEncontrado = self.listQueue.find( obj => obj.queue === e.data.queue );

      if (objEncontrado) {
        objEncontrado.status = e.data.status
        objEncontrado.description_status = e.data.description_status
        objEncontrado.progress = e.data.progress
      } else {
        self.listQueue.unshift( e.data )
      }
    });
  },

  beforeDestroy(){
    EventBus.$off("processQueueLog");
  },

  methods: {

    async loadProcesslog(){

      // Referenciamos
      var self = this;

      // Nos traemos usuarios de la actividad para menciones
      await axios.get("/api/v2/user/" + this.user.id + "/processlog")
        .then(function(response) {
          // Si encontramos usuarios los guarda en la store
          if (response.data && response.data.success) {
            self.listQueue = response.data.data
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    async showSide() {
      this.sliderClass = this.sliderShown ? 'slide-out' : 'slide-in'
      this.sliderShown = !this.sliderShown
    },
  },

};
</script>

<style scoped>

  .slider {
    position: fixed;
    top: 61px;
    right: 0;
    bottom: 0;
    width: 360px;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  .slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }
  .slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
  }
  @keyframes slide-in {
    100% { transform: translateX(0%); }
  }
  @-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
  }
  @keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(100%); }
  }
  @-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(100%); }
  }
</style>
