<template>
  <div>
    <div class="top-bar flex justify-end">
      <v-button
        @click="showCreate = !showCreate"
        icon="plus">
        Nuevo tipo
      </v-button>
    </div>
    <div>
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInUp"
        leave-active-class="animated fadeOutDown">
        <div class="panel">

          <div class="w-full flex flex-wrap" v-show="showCreate">
            <div class="panel">
              <form @submit.prevent="create" class="w-full flex">
                <div class="flex-grow pr-3">
                  <label for="code" class="w-full">{{ $t('code') }}</label>
                  <v-input
                    v-model="item.code"
                    title="code"
                    id="code"
                    name="code"
                    placeholder="code"
                    class="w-full"
                    required
                  />
                </div>

                <div class="flex-grow pr-3">
                  <label for="name" class="w-full">{{ $t('name') }}</label>
                  <v-input
                    v-model="item.name"
                    title="name"
                    id="name"
                    name="name"
                    placeholder="name"
                    class="w-full"
                    required
                  />
                </div>

                <div class="flex-grow-0 pr-3 inline-flex self-end">
                  <v-button
                    native-type="submit"
                    :disabled="!item.name || !item.code"
                  >{{ $t('save') }}
                  </v-button>
                </div>
              </form>
            </div>
          </div>

          <b-table
            show-empty
            :data="data"
            :loading="isLoading"
            paginated
            striped
            bordered
            icon-pack="fas"
          >
            <template v-slot:empty="">
              <h4>{{ $t('no_rows') }}</h4>
            </template>

            <template>
              <b-table-column field="code" >
                <template v-slot:header="">
                  {{ $t('code') }}
                </template>
                <template v-slot="props">
                  <div v-if="editItem && editItem.id == props.row.id">
                    <v-input
                      v-model="editItem.code"
                      title="code"
                      id="code"
                      name="code"
                      :value="props.row.code"
                      placeholder="code"
                      class="w-full"
                    />
                  </div>
                  <div v-else>
                    {{ props.row.code }}
                  </div>
                </template>
              </b-table-column>

              <b-table-column field="name" >
                <template v-slot:header="">
                  {{ $t('name') }}
                </template>
                <template v-slot="props">
                  <div v-if="editItem && editItem.id == props.row.id">
                    <v-input
                      v-model="editItem.name"
                      title="name"
                      id="name"
                      name="name"
                      :value="props.row.name"
                      placeholder="name"
                      class="w-full"
                    />
                  </div>
                  <div v-else>
                    {{ props.row.name }}
                  </div>
                </template>
              </b-table-column>

              <b-table-column field="actions"  centered width="80">
                <template v-slot:header="">
                  {{ $t('actions') }}
                </template>
                <template v-slot="props">

                  <div class="w-full h-full flex items-center justify-around">
                      <div v-if="editItem && editItem.id == props.row.id">
                        <b-tooltip :label="$t('save')" position="is-left">
                          <div @click="update()" class="cursor-pointer flex text-blue">
                            <b-icon pack="fas" icon="save" />
                          </div>
                        </b-tooltip>
                        <b-tooltip
                          :label="$t('cancel')"
                          type="is-danger"
                          position="is-left"
                        >
                          <div @click="() => { editItem = {} }" class="cursor-pointer flex text-red">
                            <b-icon pack="fas" icon="ban" />
                          </div>
                        </b-tooltip>
                      </div>

                      <div v-else>
                        <b-tooltip :label="$t('edit')" position="is-left">
                          <div @click="() => {editItem = props.row}" class="cursor-pointer flex text-blue">
                            <b-icon pack="fas" icon="pencil-alt"/>
                          </div>
                        </b-tooltip>
                        <b-tooltip
                          :label="$t('remove')"
                          type="is-danger"
                          position="is-left"
                        >
                          <div @click="remove(props.row)" class="cursor-pointer flex text-red">
                            <b-icon pack="fas" icon="trash-alt"/>
                          </div>
                        </b-tooltip>
                      </div>

                  </div>
                </template>
              </b-table-column>
            </template>
          </b-table>

        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'technical-specification-type',

    components: {
    },

    data: () => ({
      data: [],
      isLoading: true,
      showCreate: false,
      item: {
        project_id: null,
        code: "",
        name: ""
      },
      editItem: {}
    }),

    props: {
      projectId: {type: Number, required: true},
      entityType: {type: String, default: 'project'}
    },

    mounted() {
      this.getTypes();
      this.isLoading = false;
    },

    methods: {
      async getTypes() {
        let {data} = await axios.get('/api/v2/technical-specification-types', {params: {p: this.projectId}});

        if (data.success) {
          this.data = data.data
        } else {
          this.$notify.error("error_loading_data");
        }
      },

      reload() {
        this.isLoading = true;
        this.getTypes();
        this.isLoading = false;
      },

      async create() {
        this.item.project_id = this.projectId

        this.isLoading = true;
        let {data} = await axios.post('/api/v2/technical-specification-type', this.item)
        if (data.success) {
          this.$notify.success('save_successful')
          this.getTypes();
          this.item.code = null
          this.item.name = null
        } else {
          this.$notify.error('error_saving')
        }
        this.isLoading = false;
      },

      async update() {
        let body = {
          code: this.editItem.code,
          name: this.editItem.name
        }
        this.isLoading = true;
        let {data} = await axios.post(`/api/v2/technical-specification-type/${this.editItem.id}`, body)
        if (data.success) {
          this.$notify.success('success_editing')
          this.getTypes();
          this.editItem = {}
        } else {
          this.$notify.error('error_saving')
        }
        this.isLoading = false;
      },

      async remove(type) {
        
        this.$dialog.confirm({
          title: this.$t("delete"),
          message: this.$t("delete_confirm_text", ['el tipo de especificación técnica', type.name]),
          confirmText: this.$t("delete"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            this.isLoading = true;
            let url = `/api/v2/technical-specification-type/${type.id}`;
            const { data } = await axios.delete(url);

            if (data.success) {
              this.$toast.open({
                message: this.$t("removed_successfully"),
                type: "is-success",
                position: "is-top-right"
              });

              this.getTypes();
            } else {
              // Error
              this.$toast.open({
                message: this.$t("remove_error"),
                type: "is-danger",
                position: "is-top-right"
              });
            }
            this.isLoading = false;
          }
        });
      }
    }
  };
</script>
