<template>
  <div class="page-with-top-bar">

    <div class="flex justify-between w-full mt-4 below-top-bar">
    </div>

    <!-- Formulario añadir nuevo grupo -->
    <div class="border border-blue-lighter rounded bg-blue-lightest p-2">

      <div class="flex w-full">

      <div class="w-1/8 pr-4">
        <label>{{ $t('code') }}</label>
        <input v-model="newForm.code" type="text" name="" class="input" ref="codeField">
      </div>

      <div class="w-1/4 pr-4">
        <label>{{ $t('name') }}</label>
        <input v-model="newForm.name" type="text" name="" class="input">
      </div>

      <div class="w-2/4 pr-4">
        <label>{{ $t('description') }}</label>
        <input v-model="newForm.description" type="text" name="" class="input">
      </div>

      <div class="w-1/8 pr-4">
        <label>&nbsp;</label>
        <button v-if="newForm.group_id" class="btn btn-green" type="button" @click="saveGroup()">
          <i class="fas fa-download mr-1"></i> {{ $t('save') }}
        </button>
        <button v-else class="btn btn-green" type="button" @click="saveGroup()">
          <i class="fas fa-plus mr-1"></i> {{ $t('add_group') }}
        </button>
      </div>

      </div>

    </div>



    <div class="panel">
      <div v-if="!loaded" class="my-4 text-center">
        <scale-loader></scale-loader>
      </div>
      <div v-else>
        <div class="flex flex-wrap w-full mt-4">
        <section class="w-full">
          <b-table

          icon-pack="fas"
          :data="groups"
          :paginated="isPaginated"
          :per-page="perPage"
          :bordered="true"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :default-sort-direction="defaultSortDirection"
          v-if="loaded">
          <template>

            <b-table-column field="code" sortable>
              <template v-slot:header="">{{ $t('code') }}</template>
              <template v-slot="props">{{ props.row.code }}</template>
            </b-table-column>

            <b-table-column field="name" sortable>
              <template v-slot:header="">{{ $t('name') }}</template>
              <template v-slot="props">{{ props.row.name }}</template>
            </b-table-column>

            <b-table-column field="description" sortable>
              <template v-slot:header="">{{ $t('description') }}</template>
              <template v-slot="props">{{ props.row.description }}</template>
            </b-table-column>

            <b-table-column field="actions" width=100 sortable>
              <template v-slot:header="">{{ $t('actions') }}</template>
              <template v-slot="props">
                <div class="flex items-center justify-around w-full h-full">
                  <b-tooltip :label="$t('edit')" position="is-left">
                      <div @click="editGroup(props.row)"
                          class="flex cursor-pointer text-blue">
                        <b-icon pack="fas" icon="pencil-alt"/>
                      </div>
                  </b-tooltip>
                </div>
              </template>
            </b-table-column>
          </template>
          <template slot="bottom-left">
              <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
              <b>{{ $t("total") }}</b>: {{ this.groups.length }}
          </template>

          <template #empty>
              <div class="has-text-centered">{{ $t('no_results') }}</div>
          </template>

          </b-table>
        </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  middleware: 'auth',
  title: 'validations_admin_page_title',
  subtitle: 'validations_admin_page_subtitle',
  menuOption: '0-5',
  components: {
    ScaleLoader
  },

  data: () => ({
    groups: [],
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 20,
    loaded: false,
    newForm: { code: '', name: '', description: '' },
  }),

  computed: {
    ...mapGetters({
      company: 'app/company',
    }),
  },

  mounted () {
    this.getGroups()
  },

  methods: {

    async getGroups () {
      const { data } = await axios.get("/api/v2/validations/templategroups", {'params' : { company_id: this.company.id }});

      if (data.groups) {
        this.groups = data.groups;
        this.loaded = true
      } else {
        // Error
        this.$notify.error('error_retrieving_data')

      }
    },


    editGroup(group) {

      this.newForm = group
      this.newForm.group_id = group.id
      this.$refs.codeField.focus()

    },


    async saveGroup() {

      let url = "/api/v2/validations/templategroup" // add url

      if (this.newForm.group_id) {
        url += "/" + this.newForm.group_id // edit url
      }

      let postData = Object.assign( { company_id: this.company.id }, this.newForm) // le fusiona el company_id al form

      // Enviamos el form
      const { data } = await axios.post(url, postData)
      if (data && data.success) {

        this.$notify.success('success_editing')
        this.getGroups()
        this.newForm = { code: '', name: '', description: '' }

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')
        this.$router.push({ name: 'project.home', params: {} })

      } else {
        this.$notify.error('error_saving')
      }
    },

  },


};
</script>
