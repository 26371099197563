<template>
  <div>
    <div class="text-blue-light font-thin text-center uppercase mb-4">
      {{ $t("activities_economic_preview") }}
    </div>
    <div v-if="isLoading">
      <scale-loader></scale-loader>
    </div>
    <div v-else>
      <b-table
        :data="data"
        :loading="isLoading"
        :bordered="true"
        :show-detail-icon="false"
        ref="table"
        detailed
        :mobile-cards="false"
      >
        <template slot="empty">
          <div class="m-4 text-lg text-dark-grey text-center">
            <span>{{ $t('empty_economic_assignments_info') }}</span>
          </div>
        </template>

        <template>
          <b-table-column
            :label="$t('without_extra_cost')"
            class="w-4 type-column vertical-center"
            centered v-slot="data"
          >
            {{ data.row.activities_ok }}
          </b-table-column>

          <b-table-column
            :label="$t('with_extra_cost')"
            :td-attrs="(row, column) => ({class: (row.activities_nok > 0) ? 'text-red' : ''})"
            class="w-4 type-column vertical-center"
            centered v-slot="data"
          >
              {{ data.row.activities_nok }}
              <span @click="data.toggleDetails(data.row)" v-if="data.row.activities_nok > 0" class="cursor-pointer">
                <b-icon icon="eye" pack="fas" size="is-small" type="is-info"  />
              </span>
          </b-table-column>

          <b-table-column
            :label="$t('spent')"
            class="w-4 type-column vertical-center"
            :td-attrs="(row, column) => ({class: (row.spent > row.total) ? 'text-red' : ''})"
            centered v-slot="data"
          >
            {{ currencyFormat(data.row.spent) }}
          </b-table-column>

          <b-table-column
            :label="$t('total')"
            class="w-4 type-column vertical-center"
            centered v-slot="data"
          >
            {{ currencyFormat(data.row.total) }}
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <div>
              <b>{{ $t('activities') + ' ' + $t('with_extra_cost').toLowerCase() }}</b>
          </div>
          <b-table
            ref="badactivities"
            :data="props.row.activities_nok_details"
            default-sort="notifications"
            default-sort-direction="desc"
            bordered
            class="border-blue-lighter bg-grey-lighter text-xs subactivities"
            narrowed
            :mobile-cards="false"
            icon-pack="fas">

            <template>
              <b-table-column field="planning_code" sortable cell-class="vertical-center">
                <template v-slot:header="">
                  {{ $t('code') }}
                </template>
                <template v-slot="activity_info">
                  <div>{{ activity_info.row.planning_code }}</div>
                </template>
              </b-table-column>

              <b-table-column field="name" cell-class="vertical-center" sortable>
                <template v-slot:header="">
                  {{ $t('name') }}
                </template>
                <template v-slot="activity_info">
                  <div :class="activity_info.row.access ? 'cursor-pointer' : ''"
                      @click="onRowClicked(activity_info.row.is_subactivity, activity_info.row.parent_id, activity_info.row.id, activity_info.row.access)">
                    {{ activity_info.row.name }}
                  </div>
                </template>
              </b-table-column>
            </template>
          </b-table>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { mapGetters } from "vuex";
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

export default {
  name: "project-economic-preview",

  components: {
    ScaleLoader
  },

  computed: {
    ...mapGetters({
      project: 'app/project',
      user: 'auth/user',
    })
  },

  data: () => ({
    data: [],
    isLoading: true,
    currency:"",
  }),

  mounted() {
    this.getEconomicPreview();
  },

  methods: {
    async getEconomicPreview() {
      this.isLoading = true;

      const { data } = await axios.get("/api/v2/assignments/"+this.project.id+"/economic-preview");

      if (data && data.success) {
        this.data = this.hasEconomicInfo(data.data);
        this.isLoading = false;
      }
    },
    hasEconomicInfo(preFilterInfo){
      return (preFilterInfo.total == 0 && preFilterInfo.activities_nok == 0) ? [] : [preFilterInfo];
    },
    currencyFormat(quantity){
      if (this.project) {
        switch (this.project.currency) {
          case "€":
            this.currency = "EUR";
            break;

          case "$":
            this.currency = "USD";
            break;

          default:
            this.currency = "EUR";
            break;
        }

        return Intl.NumberFormat(this.user.locale, {style: "currency", currency: this.currency}).format(quantity)
      }

      return null
    },

    onRowClicked(isSubactivity, activityId, subactivityId, access = true) {
      if (access) {
        if (!isSubactivity) {
          this.$router.push({name: 'activity.home', params: {activity_id: activityId}})
        } else {
          this.$router.push({
            name: 'subactivity.home',
            params: {activity_id: activityId, subactivity_id: subactivityId}
          })
        }
      }
    },
  },

};
</script>

<style>
</style>
