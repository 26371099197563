<template>
<div :class="{'w-full': full}">
  <!-- Label -->
  <label class="inline-block mb-2 font-semibold text-base text-black">{{ label ? $t(label) : null }}</label>
  <!-- Input + Botón -->
  <div class="flex">
    <!-- Contenedor del input -->
    <div :class="{'w-full': full}" class="relative flex items-center">
      <!-- Input -->
      <input ref="input"
        v-model="form[field]"
        :id="inputId"
        :name="inputName"
        :type="inputType"
        :autocomplete="autocomplete"
        :placeholder="$t(_placeholder)"
        :disabled="disabled"
        :readonly="loading"
        class="inp w-full max-w-full rounded-r-none"
      />
      <!-- Botón -->
      <button
        :type="buttonType"
        :disabled="_disabledButton"
        @click="clickButton"
        class="btn h-full rounded-l-none"
        :class="{
          [`btn-${buttonColor}`]: true,
          'btn-loading': loading,
        }"
      >
        <b-icon v-if="buttonIcon"
          :icon="buttonIcon"
          :pack="buttonIconPack"
          size="is-small"
          class="mr-2"
        />
        <span>
          {{ $t(buttonTitle) }}
        </span>
      </button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'l-form-input-with-button',

  props: {
    form: { type: Object, required: true },
    field: { type: String, required: true },
    label: { type: String, default: '' },
    inputType: { type: String, default: 'text' },
    inputId: { type: String, default: null },
    inputName: { type: String, default: null },
    inputIcon: { type: String, default: null },
    inputIconPack: { type: String, default: 'fas' },
    placeholder: { type: String, default:null },
    buttonTitle: { type: String, default: 'ok' },
    buttonType: { type: String, default: 'button' },
    buttonColor: { type: String, default: 'blue' },
    buttonIcon: { type: String, default: null },
    buttonIconPack: { type: String, default: 'fas' },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    allowEmpty: { type: Boolean, default: false },
    autocomplete: { type: String, default: null,
      validator: value => {
        return ['on', 'off'].includes(value)
      }
    },
    full: { type: Boolean, default: false },
  },

  data: () => ({
    currentValue: null,
    animateError: false,
  }),

  computed: {
    _placeholder() {
      return this.placeholder ? this.placeholder : this.label
    },
    _disabledButton() {
      return this.disabled || this.loading || (!this.allowEmpty && this.form[this.field].length == 0)
    },
    hasError() {
      return this.form.errors.has(this.field)
    },
  },

  methods: {
    clickButton(event) {
      // Evita enviar el click si el input está vacío
      if (! this.allowEmpty) {
        if (! this.form[this.field]) {
          event.preventDefault()
          return
        }
      }

      this.$emit('click', event)
    },
  },
};
</script>
