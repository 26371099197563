<template>
  <span
    class="uppercase h-6 text-white font-black tracking-wide text-center p-1 rounded"
    :class="[`bg-${labels[status]}`]"
    :style="'font-size:' + fontSize + 'rem'"
  >
    {{ $t(labels[status]) }}
  </span>
</template>

<script>

export default {
  name: 'activity-status-label',

  props: {
    status: { type: Number, default: 0 },
    fontSize: {type: String, default: "0.4"}
  },

  data: () => ({
    labels: [
      'activity_status_assigned',
      'activity_status_running',
      'activity_status_paused',
      'activity_status_issue',
      'activity_status_done',
      'activity_status_reopened',
      'activity_status_closed',
      'activity_status_delayed_start'
    ],
  }),

};
</script>
<style scoped>
  .bg-activity_status_assigned {
    background-color: #B8C2CC;
  }
  .bg-activity_status_delayed_start {
    background-color: #fc8a18;
  }
  .bg-activity_status_running {
    background-color: #3490dc;
  }
  .bg-activity_status_paused {
    background-color: #F6993F;
  }
  .bg-activity_status_issue {
    background-color: #e3342f;
  }
  .bg-activity_status_done {
    background-color: #6574CD;
  }
  .bg-activity_status_reopened {
    background-color: #9561E2;
  }
  .bg-activity_status_closed {
    background-color: #38C172;
  }
</style>
