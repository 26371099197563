<template>
  <div class="z-20 overflow-y-scroll slider-chat-rooms bg-grey-darkest" :class="sliderClass">

    <div class="flex px-4 py-2 text-white bg-blue-dark">
      <div class="w-full mt-1 text-left flew-grow" >{{ title }}</div>
      <div class="mt-2 text-right cursor-pointer flew-no-grow" v-on:click="closeChatPanel">

        <b-icon
            pack="fas"
            icon="arrow-circle-left"
            size="is-small">
        </b-icon>

      </div>
    </div>

    <div v-if="recentChats.length > 0">
      <div class="w-full p-4 text-sm text-left text-white bg-blue">{{ $t('recent_chats') }}</div>
      <div v-for="(recentChat) in recentChats" :key="recentChat.entityId" v-on:click="goToChat(recentChat)" class="w-full p-4 text-left text-white cursor-pointer bg-grey-darkest hover:bg-grey-darker">
          <chatRecent :chat="recentChat" />
      </div>
    </div>
    
    <div class="w-full p-4 text-sm text-left text-white bg-blue">{{ $t('activities') }}</div>
    <div v-if="activities.chats || activities.fav_chats">
        <div v-for="activity in activities" v-show="activity.chats || activity.favourite" :key="activity.id" v-on:click="goToActivity(activity.id)" class="w-full p-4 text-left text-white cursor-pointer bg-grey-darkest hover:bg-grey-darker">
          <div class="flex items-center float-left px-2 mt-3 mr-3">

              <div>

                <b-tooltip label="Chats" position="is-top">
                    <b-icon icon="comments" size="is-small" v-bind:class="[ (activity.chats  > 0)  ? 'text-green mr-1 float-left' : 'mr-1 float-left' ]"/>
                </b-tooltip>

                <div class="float-right -mt-1 text-center" v-bind:class="[activity.chats > 0 ? 'text-green text-bold' : '']">{{ activity.chats }}</div>

              </div>

          </div>

          <div v-if="activity.favourite" class="flex items-center float-right px-2 mt-3 mr-3">
            <div>
              <b-tooltip label="favourite" position="is-top">
                  <b-icon icon="star" size="is-small" v-bind:class="[ (activity.chats  > 0)  ? 'text-green mr-1 float-left' : 'mr-1 float-left' ]"/>
              </b-tooltip>
            </div>
          </div>

          <div class="w-full font-bold">{{ activity.name}}</div>
          <div class="w-full text-xs text-grey-dark">{{ activity.project}}</div>
        </div>
    </div>
      
    <div v-else>
        <div class="w-full p-4 text-left text-white bg-grey-darkest">
           {{ $t('empty_chat_notifications') }}
        </div>
    </div>

    <div class="w-full p-4 text-sm text-left text-white bg-blue">{{ $t('subactivities') }}</div>
    <div v-if="subactivities.chats || subactivities.fav_chats">
        <div v-for="subactivity in subactivities" v-show="subactivity.chats || subactivity.favourite" :key="subactivity.id" v-on:click="goToActivity(subactivity.id, subactivity.parent_id)" class="w-full p-4 text-left text-white cursor-pointer bg-grey-darkest hover:bg-grey-darker">
            <div class="flex items-center float-left px-2 mt-6 mr-3">

              <div>

                <b-tooltip label="Chats" position="is-top">
                    <b-icon icon="comments" size="is-small" v-bind:class="[ (subactivity.chats  > 0)  ? 'text-green mr-1 float-left' : 'mr-1 float-left' ]"/>
                </b-tooltip>

                <div class="float-right -mt-1 text-center" v-bind:class="[subactivity.chats > 0 ? 'text-green text-bold' : '']">{{ subactivity.chats }}</div>

              </div>

            </div>

            <div v-if="subactivity.favourite" class="flex items-center float-right px-2 mt-3 mr-3">
              <div>
                <b-tooltip label="favourite" position="is-top">
                    <b-icon icon="star" size="is-small" v-bind:class="[ (subactivity.chats  > 0)  ? 'text-green mr-1 float-left' : 'mr-1 float-left' ]"/>
                </b-tooltip>
              </div>
            </div>

            <div class="w-full font-bold">{{ subactivity.name}}</div>
            <div class="w-full text-xs text-grey-dark">{{ subactivity.description}}</div>
            <div class="w-full text-xs text-grey-dark">{{ subactivity.project}}</div>
        </div>
    </div>

    <div v-else>
        <div class="w-full p-4 text-left text-white bg-grey-darkest">
            {{ $t('empty_chat_notifications') }}
        </div>
    </div>

    <div class="w-full p-4 text-sm text-left text-white bg-blue" >{{ $t('issues') }}</div>
    <div v-if="issues.chats || issues.fav_chats">
        <div v-for="issue in issues" v-show="issue.chats || issue.favourite" :key="issue.id" v-on:click="goToIssue(issue.activity_id, issue.subactivity_id, issue.id, issue.fromSub)" class="w-full p-4 text-left text-white cursor-pointer bg-grey-darkest hover:bg-grey-darker">
          <div class="flex items-center float-left px-2 mt-6 mr-3">

              <div>

                <b-tooltip label="Chats" position="is-top">
                    <b-icon icon="comments" size="is-small" v-bind:class="[ (issue.chats  > 0)  ? 'text-green mr-1 float-left' : 'mr-1 float-left' ]"/>
                </b-tooltip>

                <div class="float-right -mt-1 text-center" v-bind:class="[issue.chats > 0 ? 'text-green text-bold' : '']">{{ issue.chats }}</div>

              </div>

          </div>

          <div v-if="issue.favourite" class="flex items-center float-right px-2 mt-3 mr-3">
            <div>
              <b-tooltip label="favourite" position="is-top">
                  <b-icon icon="star" size="is-small" v-bind:class="[ (issue.chats  > 0)  ? 'text-green mr-1 float-left' : 'mr-1 float-left' ]"/>
              </b-tooltip>
            </div>
          </div>
          
          <div class="w-full font-bold">{{ issue.name}}</div>
          <div class="w-full text-xs text-grey-dark">{{ issue.description}}</div>
          <div class="w-full text-xs text-grey-dark">{{ issue.project}}</div>
        </div>
    </div>

    <div v-else>
        <div class="w-full p-4 text-left text-white bg-grey-darkest">
            {{ $t('empty_chat_notifications') }}
        </div>
    </div>

</div>

</template>

<script>
import { mapGetters } from 'vuex'
import Vue from "vue";
import EventBus from '~/plugins/bus'
import axios from "axios";
import store from "~/store";
import chatRecent from '~/components/Chat/ChatRecent';

export default {
  name: 'chatrooms',

  components: { chatRecent },

  data() {
    return {
      user: null,
      title: "",
      iterator: 1,
      project: null,
      sliderShown: false,
      sliderClass: 'invisible',
      activities: [],
      subactivities: [],
      issues: [],
      roomsPushed: [],
      total: 1,
      isOpen: false,
      recentChats: []
    };
  },
  methods: {

    openChatPanel() {
      this.sliderClass = this.sliderShown ? 'slide-in' : 'slide-out'
      this.sliderShown = !this.sliderShown
      this.iterator = this.iterator + 1
    },

    closeChatPanel(){
      this.sliderClass = this.sliderShown ? 'slide-in' : 'slide-out'
      this.sliderShown = !this.sliderShown
    },

    async pullChatRooms() {

      const { data } = await axios.get('/api/v2/projects/chats')
      if (data && data.success) {

        this.activities     = data.data.user_activities;
        this.subactivities  = data.data.user_subactivities;
        this.issues         = data.data.user_issues;
        this.total          = data.data.total_chats;

        EventBus.$emit('total-chats', this.total);  
      }
    },


    openChatRoom(activityId){

      // Bus global para intercomunicar componentes
      EventBus.$emit('open-chat-room', activityId);

    },

  async goToChat(chat) {

    if (chat.entityType === 'issue') {
      this.goToIssue(chat.activityId, chat.subactivityId, chat.issueId, (chat.subactivityId != 0) ? true : false)
    } else if (chat.entityType === 'activity' && chat.isSubEntity) {
      this.goToActivity(chat.subactivityId, chat.activityId)
    } else {
      this.goToActivity(chat.activityId, 0)
    }
  },

  async goToActivity(activityId, parentId = 0) {

    // Añadimos al array
    //this.roomsPushed.push(activityId);
  
    // Enviamos al chat el comando para refrescar la conversación
    //EventBus.$emit('refresh-chat', activityId, this.roomsPushed);

    if (parentId === 0) {
      this.$router.push({ name: 'activity.home', params: { activity_id: activityId, with_chat: true }})
    } else {
      this.$router.push( {name: 'subactivity.home', params: { subactivity_id: activityId, activity_id: parentId, with_chat: true }})
    }

    // Cerramos el panel
    this.closeChatPanel();
  },

  async goToIssue(activityId, subactivityId, issueId, isSub) {

    // Cambiamos la pagina
    if (! isSub){
      this.$router.push({ name: 'issue.home', params: { issue_id: issueId, activity_id: activityId, with_chat: true } })
    } else{
      this.$router.push({ name: 'issue.home-sub', params: { issue_id: issueId, subactivity_id: subactivityId, activity_id: activityId, with_chat: true } })
    }

    // Cerramos el panel
    this.closeChatPanel();
  }
  },


  created() {

    // Referenciamos
    var self = this;

    // Capturamos el proyecto actual
    this.project = store.getters["app/project"];

    // Capturamos la id del usuario
    this.user = store.getters["auth/user"];

    // Tomamos chats recientes
    this.recentChats = store.getters["chat/recents"];

    // Bus global para intercomunicar componentes
    EventBus.$on('open-chat-panel', function(id){
      self.openChatPanel();
    });

    EventBus.$on('refreshChatNotifications', function(e){
      self.pullChatRooms()
    });

    // Pull
    this.pullChatRooms();

     // Si presionamos ESC cerramos el chat.
    window.addEventListener('keyup', function(event) {
      if(event.code == "Escape" && self.iterator > 1){
        self.sliderClass = 'slide-in'
            self.sliderShown = (self.sliderClass == 'slide-in') ? false : true
            self.iterator = self.iterator + 1

          }

    });
    
    EventBus.$on('setFavourite', function(data){
      self.pullChatRooms()
    });
  },
}
</script>


<style scoped>

.slider-chat-rooms {
  position: fixed;
  top: 61px;
  left: -16.666667%;
  bottom: 0;
  width: 33.333333%;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}
.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}
.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}
@keyframes slide-in {
  100% { transform: translateX(0%); }
}
@-webkit-keyframes slide-in {
  100% { -webkit-transform: translateX(0%); }
}
@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(100%); }
}
@-webkit-keyframes slide-out {
  0% { -webkit-transform: translateX(0%); }
  100% { -webkit-transform: translateX(100%); }
}
</style>
