<template>
    <section>

        <b-table
            :data="data"
            ref="table"
            detailed
            hoverable
            detail-key="id"
            icon-pack="fas"
            class="text-sm"
            :has-detailed-visible="hasChildren"
            @details-open="openDetails">

            <template slot="empty">
                <div class="m-1">
                    <span>{{ $t('empty_activities_table_msg') }}</span>
                </div>
            </template>

            <b-table-column field="id" :visible="false" v-slot="props">
                {{ props.row.id }}
            </b-table-column>

            <b-table-column field="name" :label="name" v-slot="props">
                <div class="cursor-pointer"  @click="goToActivity(props.row)">{{ props.row.name }}</div>
            </b-table-column>

            <b-table-column field="progress" :label="$t('progress')" centered v-slot="props">
                {{ props.row.progress }}%
            </b-table-column>

            <b-table-column field="type" :label="$t('type')" centered v-slot="props">
                {{ props.row.pivot.link_type }}
            </b-table-column>

            <b-table-column field="days" :label="$t('days')" centered v-slot="props">
                {{ props.row.pivot.days }}
            </b-table-column>

            <template slot="detail" slot-scope="props">
                <scale-loader v-if="props.row.loading"/>
                <div v-else>
                  <pre-activity-table v-if="props.row[childrenField] && props.row[childrenField].length" :data="props.row[childrenField] || []" :name="name" :childrenField="childrenField"/>
                  <div v-else class="m-1">
                      <span>{{ $t('empty_activities_table_msg') }}</span>
                  </div>
                </div>
            </template>
        </b-table>

    </section>
</template>

<script>
import axios from 'axios'
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  name: 'pre-activity-table',
  props: {
    data: { type: Array, required: true },
    childrenField: { type: String, required: true},
    name: { type: String, required: true}
  },
  components: {
    ScaleLoader
  },

  data() {
    return {
    }
  },

  methods: {
    hasChildren(row) {
      return true
      return row[this.childrenField]?.length
    },

    goToActivity(activity) {
      if (activity.parent_id)
        this.$router.push({'name': 'subactivity.home', params: {activity_id: activity.parent_id, subactivity_id: activity.id}})
      else
        this.$router.push({'name': 'activity.home', params: {activity_id: activity.id}})
    },

    async openDetails(row) {
      let index = this.data.findIndex(item => item.id === row.id);
      if (index >= 0) {
        this.$set(this.data[index], 'loading', true);
        const { data } = await axios.get('/api/v2/activity/' + row.id + '/prelations')
        if (data && data.success) {
          this.$set(this.data[index], this.childrenField, data[this.childrenField]);
  
        }
        this.$set(this.data[index], 'loading', false);
      }
    }
  }
}
</script>

<style>
.detail-container {
    padding: 0 !important;
}
</style>