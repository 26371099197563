<template>
<div class="page-with-top-bar" style="padding:0!important">

  <div class="top-bar flex px-4 flex-col">
    <div class="flex w-full items-center justify-end text-white">

      <div class="p-2 text-gray-900 flex flex-row items-center bg-white rounded-lg shadow-lg- font-medium capitalize text-right">

        <!-- Ir a a la actividad principal -->
        <span v-if="subactivityId"
          class="px-1 mt-2 cursor-pointer hover:text-gray-700 lg:hover:bg-epc-50- xl:hover:bg-gray-200 text-sm xl:rounded xl:px-2 xl:py-2 lg:hover:text-white-"
          @click="$router.push({'name': 'activity.home', params: {id: activityId }})">
          <b-tooltip :label="$t('back_to_main_activity')" position="is-top">
            <i class="fas fa-arrow-left p-2 text-white- bg-gray-200 bg-epc-900- rounded-full lg:w-8">
            </i>
            <span class="mx-1 hidden xl:inline">{{ $t('back_to_main_activity') }}</span>
          </b-tooltip>
        </span>

        <!-- <button v-if="subactivityId"
          :disabled="! parentAccess"
          @click="$router.push({'name': 'activity.home', params: {id: activityId }})"
          :class="parentAccess ?
            'bg-green hover:bg-green-dark text-grey-lightest rounded border border-green-dark hover:border-green-darker px-4 py-2 flex items-center justify-center mr-8' :
            'disabled bg-grey-light text-grey-lightest rounded border px-4 py-2 flex items-center justify-center mr-8'"
        >
          <b-icon icon="arrow-left" pack="fas" size="is-small" />
          <span class="font-medium ml-2">{{ $t('back_to_main_activity') }}</span>
        </button> -->

        <!-- Modal validación -->
        <!-- <add-validation-flow  v-if="activity.is_project_manager"   -->
        <!-- De momento quitamos la restriccion esto lo hace al momento de abrir el modal-->
        <add-validation-flow
          :entity-id="subactivityId ? parseInt(subactivityId) : parseInt(activity.id)"
          entity-type="App\Models\Activity"
          :openModalWith="'button-icon'"/>
      </div>

      <div v-if="! isLoading && ! disabledStatusAll && (activity.is_manager || activity.is_project_manager)">
        <change-status-activity
          :activity="activity"
          :menu-dropdown="true"
          @epc-change-status="_HandleChangeStatusActivity"
        />
      </div>


      <!-- Abrir Chat -->
      <!-- <v-button-icon-round
        @click="openChat"
        icon="comment"
        iconPack="fas"
        tooltip="open_chat"
        tooltip-position="bottom"
        class="mr-1"
      /> chat desactivado -->

      <!-- Sólo RESPONSABLE -->
      <div v-if="activity.is_manager && !activityClosed">
        <!-- Cambiar Progreso -->
        <v-button-icon-round
          v-if="!activity.has_assignments"
          icon="chart-area"
          icon-pack="fas"
          tooltip="change_progress"
          tooltip-position="bottom"
          @click="onClickChangeProgress"
          class="mr-1"
        />
        <activity-change-progress ref="changeProgressModal"
          :activity-id="activity.id"
          :progress="activity.progress"
          @changed="refreshProgress($event)"
        />
        <!-- Cambiar Estado -->
        <!--<v-button-icon-round
          icon="undo-alt"
          tooltip="change_status"
          tooltip-position="bottom"
          class="mr-1"
        />-->
      </div>
      <!-- Sólo CREADOR o RESPONSABLE -->
      <!--<template v-if="activity.is_manager || activity.is_creator">-->
        <!-- Generar Informe -->
        <!--<v-button-icon-round
          icon="file-alt"
          tooltip="generate_report"
          tooltip-position="bottom"
          @click="generateReport"
          class="mr-1"
        />-->
      <!--</template>-->
      <!-- Agregar participantes a la actividad -->
      <!-- <v-button-icon-round
        icon="users"
        tooltip="add_participants"
        tooltip-position="bottom"
        class="mr-1"
      /> -->
      <!-- Invitar usuarios a la actividad -->
      <!-- <v-button-icon-round
        icon="address-book"
        tooltip="add_users"
        tooltip-position="bottom"
        class="mr-1"
      /> -->


      <!-- Editar -->
      <v-button-icon-round
        icon="shuffle"
        pack="fa"
        tooltip="see_links"
        tooltip-position="bottom"
        @click="$refs.prelations_modal.show()"
        class="mr-1"
      />

      <!-- Editar -->
      <v-button-icon-round
        icon="list"
        pack="fa"
        tooltip="assignment_interventions"
        tooltip-position="bottom"
        @click="onClickInterventions"
        class="mr-1"
         v-if="(activity.is_creator || activity.is_manager || activity.is_head_department || activity.is_project_manager) && !activityClosed"
      />

      <!-- Editar -->
      <v-button-icon-round
        icon="pencil-alt"
        pack="fa"
        tooltip="edit"
        tooltip-position="bottom"
        @click="onClickEdit"
        class="mr-1"
         v-if="(activity.is_creator || activity.is_manager || activity.is_head_department || activity.is_project_manager) && !activityClosed"
      />
      <!-- Eliminar -->
      <v-button-icon-round
        color="red"
        icon="trash-alt"
        icon-pack="fas"
        tooltip="remove"
        tooltip-position="bottom"
        @click="onClickDelete"
        v-if="activity.is_creator || activity.is_project_manager"
      />
      <!-- Info actividad -->
      <v-button-icon-round
        icon="arrow-right"
        icon-pack="fas"
        color="grey-darkest"
        tooltip="activity_info"
        tooltip-position="left"
        class="ml-1 sm:ml-6"
        @click="showSide"
      />
    </div>

    <div class="flex items-center text-white">
      <!-- <div v-if="! isLoading" class="vertical-center mr-2">
        <activity-status-label
          :status="activity.status"
          fontSize="0.75"
          class="w-full text-lg inline-flex"
          style="vertical-align: middle"
        />
      </div> -->
    </div>
  </div>
  <div class="top-bar flex w-full text-xs" style="height: 15px">
    <activity-status-label
          :status=" activity.status == 7 && ignore_delayed_state ? 1 : activity.status "
          fontSize="0.75"
          class="w-full"
          style="vertical-align: middle"
        />
  </div>

  <!-- Contenido -->
  <div class="flex px-4 pt-4 below-top-bar bg-grey-lightest flex-col lg:flex-row">
    <div class="w-full lg:hidden containt-tabs-menu">
      <b-tabs ref="refTabsMenu"
        type="is-toggle-rounded"
        v-model="tabSelectedMenu"
        expanded
        class="sticky top-0 mb-3"
        @input="_HandleChangeTab">

        <b-tab-item label="Comentarios"
          value="tab_menu_comments"
          icon="comments-alt"
          icon-pack="fas" class="hidden"></b-tab-item>

        <b-tab-item
          label="Modulos"
          value="tab_menu_modulos"
          icon="cogs"
          icon-pack="fas"></b-tab-item>

      </b-tabs>
    </div>

    <div class="w-full flex">
      <!-- Columna IZQUIERDA -->
      <div class="w-full lg:w-2/5 lg:mr-2" v-show="showPanel == 'left' || showPanel == 'both'">
        <div class="column-scroll w-full mb-24">
          <entity-description
            class="pr-4" v-if="activity.description"
            :title="$t('activity_description')" :content="activity.description"
          />
          <!-- <div class="mb-4" v-if="activity.description" v-html="activity.description"></div> -->
          <entity-comments
            v-if="activity.id !== 0"
            :entity-id="activity.id"
            entity-type="activity"
            :is-subactivity="subactivityId != undefined"
            :can-add="(activity.is_creator || activity.is_manager || activity.is_participant || activity.is_collaborator) && !activityClosed"
            class="lg:pr-4 pb-32 mt-4"
            :can-manage-folders="activity.is_project_manager"
            :is-project-manager="activity.is_project_manager"
            :is-project-participant="activity.is_project_participant"
            :can-upload-folders="true"
            :notify-users="true"
            :validable-parent-code="activity.parent_code || activity.planning_code"
          />
        </div>
      </div>

      <!-- DERECHA -->
      <div class="w-full lg:w-3/5 lg:ml-2" v-show="showPanel == 'right' || showPanel == 'both'">
        <div class="column-scroll w-full pb-24">

          <!-- Actuaciones siempre de primero (solo para proyectos específicos)-->
          <activity-interventions :activityId="entityId" v-if="[532, 533, 534, 536].includes(activity.project_id)"/>

          <div v-if="panels && panels.length" v-for="panel in panels" :key="'activity_panel_'+panel.name">
            <!-- Progreso -->
            <activity-progress
              v-if="panel.name == 'panel_progress' || panel.name == 'show_all_panels'"
              ref="activity_progress"
              :key="areaChartKey"
              :isLoading="isLoading"
              :percentage="activity.progress"
              :activityId="entityId"
              @reload="() => refreshActivity()"
            />
            <!-- Subactividades -->
            <activity-subactivities v-if="subactivityId == undefined && (panel.name == 'panel_subactivities' || panel.name == 'show_all_panels')"
              :activity-id="activity.id"
              :activity-name="activity.name"
              :can-add="(activity.is_creator || activity.is_manager || activity.is_head_department || activity.is_project_manager)  && !activityClosed"
            />
             <!-- Tareas/Recursos aquí mimso pasamos el id del pictograma asociado si esque hay -->
            <activity-assignments
              v-if="activity.id !== 0 && (panel.name == 'panel_measurements' || panel.name == 'show_all_panels')"
              :activity-id="activity.id"
              :activity-name="activity.name"
              :activity-planning-code="activity.planning_code"
              :pictogram-id="activity.pictogram_id"
              :subactivity-id="subactivityId == undefined ? 0 : parseInt(subactivityId)"
              :can-add="(activity.is_creator || activity.is_manager || activity.is_participant || activity.is_project_manager) && !activityClosed"
              :can-manage="activity.is_project_manager"
              :can-manage-certifiers="activity.is_project_certifier"
              :can-record-actualprogress="activity.is_project_progress"
              :current-start-date="activity.current_start_date"
              :current-end-date="activity.current_end_date"
              :end-date="activity.real_end_date"
              :blocked-assignments="activity.blocked_assignments"
              @reload="() => assignmentsReloadEvent()"
            />
            <!-- flujos de validacion -->
            <entity-validationflows
              v-if="panel.name == 'panel_validations' || panel.name == 'show_all_panels'"
              ref="entity_validationflows"
              :entity-id="entityId"
              entity-type="activity"
            />
            <!-- Incidencias -->
            <activity-issues
              v-if="activity.id !== 0 && (panel.name == 'panel_issues' || panel.name == 'show_all_panels')"
              :activity-id="activity.id"
              :activity-name="activity.name"
              :subactivity-id="subactivityId == undefined ? 0 : parseInt(subactivityId)"
              :can-add="(activity.is_creator || activity.is_manager || activity.is_participant || activity.is_collaborator) && !activityClosed"
            />
            <!-- Documentos -->
            <div v-if="! isLoading">
              <entity-documents
                v-if="activity.id !== 0 && (panel.name == 'panel_documents' || panel.name == 'show_all_panels')"
                :entity-id="activity.id"
                entity-type="activity"
                viewType="folder"
                :can-add="(activity.is_creator || activity.is_manager || activity.is_participant || activity.is_collaborator) && !activityClosed"
                :can-manage-folders="activity.is_project_manager"
                :is-project-manager="activity.is_project_manager"
                :is-project-participant="activity.is_project_participant"
                :can-upload-folders="true"
                :notify-users="true"
                :validable-parent-code="activity.parent_code || activity.planning_code"
                class="mt-4"
              />

              <store-outs
                v-if="activity.id !== 0 && (panel.name == 'panel_materials' || panel.name == 'show_all_panels')"
                :entity-id="activity.id"
                :projectId="activity.project_id"
              entity-type="activity"
                viewType="folder"
                :can-add="(activity.is_creator || activity.is_manager || activity.is_participant || activity.is_collaborator) && !activityClosed"
                :can-manage-folders="activity.is_project_manager || activity.is_manager"
                :is-project-manager="activity.is_project_manager"
                :is-project-participant="activity.is_project_participant"
                class="mt-4"
              />

              <!-- <entity-chat
                    v-if="activity.id !== 0"
                    :entity-id="entityId"
                    entity-type="activity"
                    :key="'activity.' + entityId"
                    :is-closed="activityClosed"
                    :is-sub-entity="subactivityId != undefined"
                    :auto-open="withChat"
              /> chat desactivado -->
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div>
      <activity-info-sidebar :activity="activity" ref="activity_info">
      </activity-info-sidebar>
  </div>

  <pre-activity-alert-modal ref="prelations_alert_modal" :prelations="prelations" :activityId="activity.id"/>
  <pre-activity-modal ref="prelations_modal" v-if="activity.pre_activities" :prelations="activity.pre_activities" :activityId="activity.id"/>

</div>
</template>

<script>
import Vue from "vue"
import Vuex from "vuex"
import at from "~/store/modules/at";
import EventBus from '~/plugins/bus'
import axios from 'axios'
import { mapGetters } from 'vuex'
import entityDescription from '·/components/entity-description'
import entityComments from '·/components/entity-comments'
import activityInfoSidebar from '·/components/activity-info-sidebar'
import activityProgress from '·/components/activity-progress'
import activitySubactivities from '·/components/activity-subactivities'
import activityIssues from '·/components/activity-issues'
import activityAssignments from '·/components/activity-assignments'
import entityDocuments from '·/components/entity-documents'
import activityChangeProgress from '·/components/activity-change-progress'
import addValidationFlow from '·/components/add-validation-flow';

// import entityChat from '·/components/entity-chat'
import activityStatusLabel from '·/components/activity-status-label'
import entityValidationflows from '·/components/entity-validationflows'
import moment from 'moment'
import BIcon from "buefy/src/components/icon/Icon";
import api from '~/api'

import StoreOuts from '../../components/storehouse/outs-entity.vue'
import ChangeStatusActivity from './components/change-status.vue'
import ActivityInterventions from '../../components/activity-interventions.vue'
import PreActivityAlertModal from './pre-activity-alert-modal'
import PreActivityModal from './pre-activity-modal'

Vue.use(moment)
Vue.use(Vuex)

export default {
  middleware: 'auth',
  title: '',
  subtitle: 'activity_home_page_subtitle',
  menuOption: '2-2',

  components: {
    BIcon,
    entityComments,
    activityProgress,
    activitySubactivities,
    activityAssignments,
    activityIssues,
    entityDocuments,
    activityChangeProgress,
    // entityChat,
    activityStatusLabel,
    activityInfoSidebar,
    entityDescription,
    entityValidationflows,
    addValidationFlow,
    StoreOuts,
    ChangeStatusActivity,
    ActivityInterventions,
    PreActivityAlertModal,
    PreActivityModal
  },

  data: () => ({
    activity: {
      id: 0,
      name: '',
      progress: 0,
      parent_id: 0,
    },
    atValues: [],
    sliderShown: false,
    sliderClass: '',
    activityId: undefined,
    subactivityId: undefined,
    logActions: undefined,
    activityStatus : 0,
    activityClosed : false,
    activityDepartmentId : 0,
    openIssues: false,
    isLoading:true,
    changeProgressModalKey: 1,
    areaChartKey: 1,
    parentAccess: false,
    panels: null,
    ignore_delayed_state: false,

    showPanelRight: true,
    showPanelLeft: true,
    prelations: [],

    tabSelectedMenu: -1 // tab seleccionado por defecto
}),

   computed: {
     ...mapGetters({
      user: 'auth/user',
      visualConfig: 'app/visualConfig',
      mobile: 'device/mobile'
     }),
     withChat() {
      return this.$route.params.with_chat
     },
     entityId: {
      get: function() {
        return this.$route.params.subactivity_id ?
         parseInt(this.$route.params.subactivity_id) :
         parseInt(this.$route.params.activity_id)
      },
      set: function(newVal) {
        this.entityId = newVal
      }
     },
     disabledStatusAssigned() {
       return ! this.activity.is_manager || this.openIssues || this.activityClosed;
     },
     disabledStatusRunning() {
       return ! this.activity.is_manager || this.openIssues || this.activityClosed;
     },
     disabledStatusPaused() {
       return ! this.activity.is_manager || this.openIssues || this.activityClosed;
     },
     disabledStatusDone() {
       return ! this.activity.is_manager || this.openIssues || this.activityClosed;
     },
     disabledStatusReopened() {
       return (this.activity.is_manager && this.activity.hierarchically_superior_users.length > 1) || this.openIssues || !this.activityClosed;
     },
     disabledStatusClosed() {
       return (this.activity.is_manager && this.activity.hierarchically_superior_users.length > 1) || this.openIssues;
     },
     disabledStatusDelayedStart() {
       return false;
     },
     disabledStatusAll() {
       return this.disabledStatusAssigned &&
         this.disabledStatusRunning &&
         this.disabledStatusPaused &&
         this.disabledStatusDone &&
         this.disabledStatusReopened &&
         this.disabledStatusClosed
     },

     showPanel: function() {
      if( this.mobile.on_mobile ) {

        if( this.showPanelLeft && this.showPanelRight ) {
          this.tabSelectedMenu = 1
          this.showPanelLeft = false
        }

        return this.showPanelRight ? 'right' : 'left'
      }

      this.showPanelRight = true
      this.showPanelLeft = true
      return 'both'
    }
   },

  watch: {
    '$route.params.activity_id': function() {
      // Reload params
      this.isLoading = true
      this.loadParams()
    },
    '$route.params.subactivity_id': function() {
      // Reload params
      this.isLoading = true
      this.loadParams()
    },
  },

  created() {

    this.loadParams()

    // Referenciamos
    var self = this;

    // Si recibimos el evento refresh, refrescamos el componente
    EventBus.$on('refreshStatus', function(e){

        self.activityStatus = e.status
        self.activityClosed = e.status == 6

        self.loadParams()
        self.activity.status = e.status
    });

    EventBus.$on('update:entityId', function(entityId) {
        this.entityId = entityId
    })
    // set panels
    self.panels = self.visualConfig?.activities_panels
    if (!self.panels) {
      self.panels = [{name: 'show_all_panels'}]
    }

    self.ignore_delayed_state = self.visualConfig?.ignore_delayed_state
  },

  beforeDestroy(){
    EventBus.$off("refreshStatus");
    EventBus.$off("update:entityId");
  },

  methods: {

    loadParams() {
      this.activityId = this.$route.params.activity_id
      this.subactivityId = this.$route.params.subactivity_id
      this.loadActivityData(this.entityId)
      this.loadActivityUsers(this.entityId)
    },

    async loadActivityUsers(id){

      // Referenciamos
      var self = this;

      // Nos traemos usuarios de la actividad para menciones
      await axios.get("/api/v2/activity/" + id + "/chat/users")
        .then(function(response) {
          // Si encontramos usuarios los guarda en la store
          if (response.data && response.data.success) {
            response.data.data.forEach(function(element) {
              self.atValues.push(element)
            });
          }
        })
        .catch(error => {
          console.log(error);
        });

      at.commit('increment', self.atValues)
    },

    loadActivityData(activityId) {

      var self = this

      api.fetchActivity(activityId, { h: 1, with_prelations: 1 })
        .then(function (data) {
          if (data && data.success) {

            // Cargamos los datos de la actividad
            self.activity = data.data
            // Actualizamos el progreso
            self.refreshProgress(data.data)

            // Seteamos el proyecto y las tags
            let project = self.activity.project
            self.$store.dispatch('app/setProject', { project })
            let tags = project.tags
            self.$store.dispatch("app/setTags", { tags });

            // En caso de ser subactividad, comprobamos si se tiene acceso a la actividad padre
            self.parentAccess = self.subactivityId ? self.activity.parent_access : false;

            // Parseamos las fechas
            self.activity.scheduled_start_date  = moment(self.activity.scheduled_start_date).format('DD/MM/YYYY')
            self.activity.scheduled_end_date    = moment(self.activity.scheduled_end_date).format('DD/MM/YYYY')
            self.activity.current_start_date    = moment(self.activity.current_start_date).format('DD/MM/YYYY')
            self.activity.current_end_date      = moment(self.activity.current_end_date).format('DD/MM/YYYY')
            self.activity.real_start_date       = moment(self.activity.real_start_date).format('DD/MM/YYYY')
            self.activity.real_end_date         = moment(self.activity.real_end_date).format('DD/MM/YYYY')
            self.activity.expected_end_date     = self.activity.expected_end_date ? moment(self.activity.expected_end_date).format('DD/MM/YYYY') : '-'

            self.activity.planning_code = data.data.planning_code

            self.activityStatus = data.data.status
            self.activityDepartmentId = data.data.department_id
            self.openIssues = data.data.open_issues
            self.activityClosed = self.activityStatus == 6

            
            self.handleActivityPrelation(data.data)

            // Actualizamos el título de la página
            let pageInfo = {
              title: self.activity.name,
              subtitle: self.subactivityId ? self.activity.parent_name : self.$t('activity_home_page_subtitle'),
              menuOption: self.$options.menuOption
            }
            self.$store.dispatch('app/updatePageInfo', { info: pageInfo })

            self.isLoading = false

          } else if(data && data.deny){
            self.$notify.error('no_access_permissions')
            self.$router.push({ name: 'project.home', params: {} })
          } else {
            self.$notify.error('error_loading_activity_data')
            self.$router.push({ name: 'project.home', params: {} })
          }
        })
        .catch(error => {
          console.log(error.response);
          self.$notify.error('error_loading_activity_data')
          self.$router.push({ name: 'project.home', params: {} })
        });

    },

    // Botones
    onClickChangeProgress() {
      this.$refs.changeProgressModal.show()
    },
    assignmentsReloadEvent() {
      this.loadActivityData(this.entityId)
    },
    refreshActivity(){
        this.isLoading = true;
        this.loadParams();
    },
    onClickInterventions() {
      if (this.subactivityId) {
        this.$router.push({
          name: 'subactivity.interventions',
          params: {
            activity_id: this.activityId,
            subactivity_id: this.subactivityId
          }
        })
      } else {
        this.$router.push({ name: 'activity.interventions', params: { activity_id: this.activity.id } })
      }
    },
    onClickEdit() {
      if (this.subactivityId) {
        this.$router.push({
          name: 'subactivity.edit',
          params: {
            activity_id: this.activityId,
            subactivity_id: this.subactivityId,
            name: this.activity.name
          }
        })
      } else {
        this.$router.push({ name: 'activity.edit', params: { activity_id: this.activity.id } })
      }
    },
    onClickDelete() {
      this.$dialog.confirm({
        title: this.$t('delete_activity'),
        message: this.$t('delete_activity_confirm_text', [this.activity.name]),
        confirmText: this.$t('delete_activity'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true
          let url = '/api/v2/activity/' + this.activity.id + '/delete'
          const { data } = await axios.post(url)
          if (data && data.success) {
            this.isLoading = false
            this.$notify.success('success_deleting_activity')
            this.$router.push({ name: 'project.activities' })
          } else {
            this.isLoading = false
            this.$notify.error(data.error)
          }
        }
      })
    },

    refreshProgress(data) {
      this.areaChartKey = this.areaChartKey + 1
      this.activity.progress = data.progress
      if (data.status) {
        this.activity.status = this.activityStatus = data.status
      }
    },

    showSide() {

      this.$refs.activity_info.showSide()
    },

    /**
     * @deprecated esta función ya no se usa y el endpoint que usaba ahora funciona diferente
     */
    generateReport() {

      var array_id = new Array();
      array_id.push(this.activity.id);

      this.$dialog.confirm({
        title: this.$t('generate_report'),
        message: this.$t('confirm_generate_report', [this.activity.name]),
        confirmText: this.$t('generate_report'),
        type: 'is-success',
        hasIcon: true,
        onConfirm: async () => {

          // Parámetros para generar el reporte.
          var params = {
              comments:       true,
              documents:      true,
              chat:           true,
              subactivities:  true,
              issues:         true,
              activities:     array_id,
              from_activity:  true
          }

          // Nos las actividades en las que podemos generar reportes
          const { data } = await axios.get('/api/v2/report/generate', {params: params, responseType: 'blob'})

          if (data) {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(data)
            link.setAttribute('download', this.activity.name + ".zip")
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          } else {
            this.$notify.error('error_download_report')
      }

        }
      })
    },

    openChat() {
      let keyId = this.subactivityId ? this.subactivityId : this.activityId
      EventBus.$emit('open-chat', 'activity.' + keyId.toString() )
    },

    async changeActivityStatus(){
      if (this.activityStatus != this.activity.status) {
        let self = this
        await axios.post('/api/v2/activity/' + this.activity.id + '/status', { status: this.activityStatus })
          .then(function(response) {
            if (response.data && response.data.success) {
              self.activity.status = self.activityStatus
              self.activityClosed = self.activityStatus == 6

              // Si se ha completado la actividad, marcamos su progreso a 100%
              if (self.activityStatus == 4) {
                self.refreshProgress({'progress': 100})
              }
              self.$notify.success('success_updating_status')
            } else {
              self.activityStatus = self.activity.status
            }
          })
          .catch(error => {
            self.activityStatus = self.activity.status
            console.log(error.response);
          });
      }
    },

    getNameStatus(){
      if(this.activityStatus == 0)
        return 'activity_status_assigned'
      else if(this.activityStatus == 1)
        return 'activity_status_running'
      else if(this.activityStatus == 2)
        return 'activity_status_paused'
      else if(this.activityStatus == 3)
        return 'activity_status_issue'
      else if(this.activityStatus == 4)
        return 'activity_status_done'
      else if(this.activityStatus == 5)
        return 'activity_status_reopened'
      else if(this.activityStatus == 6)
        return 'activity_status_closed'
    },

    _HandleChangeTab(value) {
      this.showPanelRight = value === 1
      this.showPanelLeft = value === 0
    },

    _HandleChangeStatusActivity(e) {
      if (e.success) {
        this.activity.status = e.activityStatus
        this.activityClosed = e.activityStatus == 6

        // Si se ha completado la actividad, marcamos su progreso a 100%
        if (this.activityStatus == 4) {
          this.refreshProgress({'progress': 100})
        }
        this.$notify.success('success_updating_status')
      } else {
        this.activityStatus = e.activityStatus
      }
    },

    handleActivityPrelation(activity) {
      // lanzamos alerta cuando no esta completa
      this.prelations = activity.uncompleted_activities
      if (this.prelations?.length) {
        this.$refs.prelations_alert_modal.show();
      }
    }
  },
};
</script>

<style scoped>
.containt-tabs-menu::v-deep .b-tabs section {
  display: none;
}
.column-scroll {
  height: calc(100vh - 150px);
  overflow-x: hidden;
  overflow-y: auto;
}
.column-scroll:not(:last-child) {
  /* margin-right: 0.5rem; */
  border-color: #adc2ea;
  border-right-width: 1px;
}
/* .column-scroll:last-child {
  margin-left: 0.5rem;
} */

</style>
