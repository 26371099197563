<template>
    <div class="panel m-4 p-8">
      <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('participants') }}</h2>

      <div>
        <userlist ref="create"
        title="report_create"
        :parent-entity-id="provider_id"
        users-permission-type="create"
        icon="clipboard"
        api-url="/api/v2/report"
        :allow-zero-users="true"
        class="w-full md:w-1/2 pr-3" >-----</userlist>
      </div>
    </div>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import Userlist from '~/components/UserBoxReport/UserList'


export default {
  middleware: 'auth',
  title: 'report_participants_page_title',
  subtitle: 'report_participants_page_subtitle',
  menuOption: '0-3',

  components:{
    Userlist
  },
  data: () => ({
    provider_id: 0,
  }),

  mounted () {
    this.provider_id = parseInt(this.$route.params.provider_id)
  },

  methods: {

   
    /*
     ALERTAS
     */
     success(message){
      this.$toast.open({
        message: message,
        type: 'is-success',
        position: 'is-top-right'
      })
    },
    error(message){
      this.$toast.open({
        message: message,
        type: 'is-danger',
        position: 'is-top-right'
      })
    }
  },
  watch:{

  }

};
</script>
