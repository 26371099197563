<template>
  <div id="mainContain" :class="{'page-with-top-bar': !workZone}">
    <project-admin-quick-buttons v-if="! workZone" hiddenOption="departments"/>
    <div :class="{'below-top-bar': !workZone} ">
      <div class="flex mt-2">
        <!-- Mostrar usuarios -->
        <div class="flex float-left">
          <v-switch
            :disabled="loadingParentDepartmentForm"
            :focusable="! loadingParentDepartmentForm"
            :label="$t('display_department_users')"
            :color="displayDepartmentUsers ? 'green' : 'red'"
            :value="displayDepartmentUsers"
            v-on:checked="() => displayDepartmentUsers = ! displayDepartmentUsers"
            style="margin-left: 20px; margin-top: -5px"
          />
        </div>
      </div>

      <!-- Org Chart -->
      <div class="panel">
        <orgchart ref="orgchart" v-if="projectId"
                  :key="orgchartKey"
                  :get-data-url="'/api/v2/departments?p='+projectId"
                  :withUsers="withUsers"
                  pan
                  chart-container-name="departments-chart-container"
                  :create-node-callback="createNode"
                  @selectedNodeChanged="orgchartSelectedNodeChanged"
                  class="invert-colors"/>
      </div>

      <!-- Mostrar modal con participantes del proyecto sin departamento -->
      <div class="flex float-left">
        <div>
          <v-button-icon-round
            @click="displayParticipantsWithoutDepartment = true"
            icon="users"
            tooltip="participants_without_department"
            tooltip-position="right"
          />
          <span class="text-xs bg-red rounded-full py-0 px-1 text-white" style="margin-left: -10%">
            {{ this.participantsWithoutDepartment.length }}
          </span>
        </div>
        <div>
          <b-modal :active.sync="displayParticipantsWithoutDepartment">
            <div class="w-auto border border-blue-light rounded">
              <header class="w-full bg-grey-lightest px-4 py-2 text-xl font-bold text-blue-light uppercase">
                {{ $t('participants_without_department') }}
              </header>
              <div class="w-full p-4 bg-grey-lightest text-center">
                <div v-if="participantsWithoutDepartment.length === 0"
                     class="menu-header uppercase mb-2 font-bold text-sm text-grey-darkest">
                  <span>{{ $t('no_participants_without_department') }}</span>
                </div>
                <ul>
                  <li v-for="participant in participantsWithoutDepartment" class="menu-header mb-2">
                    <div class="flex items-center info-data">
                      <div class="rounded-full overflow-hidden border border-grey w-8 h-8 mr-2">
                        <img :src="participant.basic.avatar" :alt="participant.basic.avatar">
                      </div>
                      <div>
                        <div class="font-bold text-grey-dark text-sm">
                          <span>{{ participant.basic.fullname }}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-modal>
        </div>
      </div>

      <!-- CAJA DE USUARIOS EN LA PARTE DE ADMIN -->
      <transition
        v-if="! workZone"
        name="custom-classes-transition"
        enter-active-class="animated fadeInUp"
        leave-active-class="animated fadeOutDown">
        <div>
          <div v-show="selectedDepartmentNodeData!=null" class="flex items-center justify-end mb-4">
            <v-button @click="deleteDepartment" color="red" icon="trash-alt" icon-pack="far">
              {{ $t('delete_department') }}
            </v-button>
          </div>
          <div class="panel" v-show="selectedDepartmentNodeData!=null">
            <span style="float:right">{{selectedDepartmentNodeData ? '#' + selectedDepartmentNodeData.id : ''}}</span>
            <div class="w-full flex flex-wrap mt-4">
              <!-- Nombre del departamento -->
              <div class="w-full md:w-1/2 pr-3">
                <form @submit.prevent="changeDepartmentName" @keydown="formChangeDepartmentName.onKeydown($event)">
                  <l-form-input-with-button
                    :form="formChangeDepartmentName"
                    field="department_name"
                    label="department_field_title"
                    input-id="selected-node"
                    input-name="department_name"
                    button-title="save"
                    button-type="submit"
                    button-icon="check"
                    :loading="formChangeDepartmentName.busy"
                    full
                  />
                </form>
              </div>
              <!-- Departamento hijo -->
              <div class="w-full md:w-1/2 pl-3">
                <form @submit.prevent="addChildDepartment" @keydown="formAddChildDepartment.onKeydown($event)">
                  <l-form-input-with-button
                    :form="formAddChildDepartment"
                    field="child_department_name"
                    label="child_department_field_title"
                    input-id="child_department_name"
                    input-name="child_department_name"
                    placeholder="child_department_name_placeholder"
                    button-title="add"
                    button-type="submit"
                    button-icon="plus"
                    :loading="formAddChildDepartment.busy"
                    full
                  />
                </form>
              </div>
              <!-- Departamento padre -->
              <div class="w-full md:w-1/2 pr-3" style="margin-top: 15px">
                <form @submit.prevent="confirmSubmitParentDepartmentForm">
                  <div class="w-full">
                    <label class="font-semibold text-base text-black">{{ $t('new_parent_department') }}</label>
                    <div class="flex">
                      <div class="relative flex items-center w-full">
                        <multiselect
                          v-model="newParentDepartment"
                          :options="parentDepartments"
                          :placeholder="$t('parent_department')"
                          :disabled="disabledNewParentDepartment"
                          label="name"
                          class="rounded-r-none"
                          searchable
                          allowEmpty
                          closeOnSelect
                          :showLabels="false"
                          :loading="loadingParentDepartmentForm"
                          track-by="id"
                          @select="selectParentOption"
                          @remove="removeParentOption"
                        >
                          <!--<span slot="placeholder" class="text-base">-->
                          <!--{{ $t('parent_department') }}-->
                          <!--</span>-->
                        </multiselect>
                        <button
                          type="submit"
                          :disabled="disabledNewParentButton || disabledNewParentDepartment"
                          class="btn h-full rounded-l-none btn-blue"
                        >
                          <b-icon
                            icon="check"
                            pack="fa"
                            size="is-small"
                            class="mr-2"/>
                          <span>
                          {{ $t('modify') }}
                        </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- Usuarios -->
            <div class="w-full flex flex-wrap mt-8 pt-4 mb-8">
              <!-- Jefe de departamento -->
              <userlist ref="managers"
                        title="department_managers"
                        icon="cog"
                        :parent-entity-id="selectedDepartmentNodeData ? selectedDepartmentNodeData.id : null"
                        api-url="/api/v2/department"
                        :users-permission-type="['admin']"
                        :only-one-user="true"
                        :allow-zero-users="true"
                        :only-internal-users="true"
                        :project-id="this.projectId"
                        :department-id="selectedDepartmentNodeData ? selectedDepartmentNodeData.id : null"
                        class="w-full md:w-1/2 pr-3"
                        :canReplace="true"
                        roleName="department_chief"/>
              <!-- Visualizadores -->
              <userlist ref="viewers"
                        title="supervisor_users"
                        icon="eye"
                        :parent-entity-id="selectedDepartmentNodeData ? selectedDepartmentNodeData.id : null"
                        api-url="/api/v2/department"
                        :users-permission-type="['view']"
                        :only-internal-users="true"
                        :allow-zero-users="true"
                        :project-id="this.projectId"
                        :department-id="selectedDepartmentNodeData ? selectedDepartmentNodeData.id : null"
                        class="w-full md:w-1/2 pr-3"
                        :canReplace="true"
                        roleName="department_user"/>
            </div>
          </div>
        </div>
      </transition>
      <!-- CAJA DE USUARIOS EN LA PARTE DE SOLO LECTURA -->
      <transition
        v-if="workZone && displayUserBoxes"
        name="custom-classes-transition"
        enter-active-class="animated fadeInUp"
        leave-active-class="animated fadeOutDown">
        <div class="panel">
          <!-- Usuarios -->
          <div class="w-full flex flex-wrap mt-8 pt-4 mb-8">
            <!-- Jefe de departamento -->
            <userlist ref="managers"
                      title="department_managers"
                      icon="cog"
                      :parent-entity-id="selectedDepartmentNodeData ? selectedDepartmentNodeData.id : null"
                      api-url="/api/v2/department"
                      :users-permission-type="['admin']"
                      :only-one-user="true"
                      :allow-zero-users="true"
                      :only-internal-users="true"
                      :project-id="this.projectId"
                      :department-id="selectedDepartmentNodeData ? selectedDepartmentNodeData.id : null"
                      class="w-full md:w-1/2 pr-3"
                      :disabled="true"
                      roleName="department_chief"/>
            <!-- Visualizadores -->
            <userlist ref="viewers"
                      title="supervisor_users"
                      icon="eye"
                      :parent-entity-id="selectedDepartmentNodeData ? selectedDepartmentNodeData.id : null"
                      api-url="/api/v2/department"
                      :users-permission-type="['view']"
                      :only-internal-users="true"
                      :allow-zero-users="true"
                      :project-id="this.projectId"
                      :department-id="selectedDepartmentNodeData ? selectedDepartmentNodeData.id : null"
                      class="w-full md:w-1/2 pr-3"
                      :disabled="true"
                      roleName="department_user"/>
          </div>
        </div>
      </transition>
      <div id="element"></div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import Form from 'vform'
  import Orgchart from '~/components/OrgChart/OrgChart.vue'
  import Userlist from '~/components/UserBox/UserList'
  import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons'
  import Multiselect from 'vue-multiselect'


  export default {
    middleware: 'auth',
    title: 'departments_page_title',
    subtitle: 'departments_page_subtitle',
    menuOption: '0-2',

    components: {
      Orgchart,
      Userlist,
      ProjectAdminQuickButtons,
      Multiselect,
    },

    data: () => ({
      projectId: null,
      projectName: '',
      workZone: false,
      orgchart: null,
      selectedDepartmentNode: null,
      selectedDepartmentNodeData: null,
      formChangeDepartmentName: new Form({
        department_name: '',
      }),
      formAddChildDepartment: new Form({
        parent_department_id: -1,
        child_department_name: '',
      }),
      formAddParentDepartment: new Form({
        parent_department_id: null,
        // department_id: null
      }),
      displayUserBoxes: false,
      displayDepartmentUsers: true,
      withUsers: 1,
      orgchartKey: 0,
      parentDepartments: [],
      newParentDepartment: null,
      disabledNewParentDepartment: false,
      loadingParentDepartmentForm: false,
      disabledNewParentButton: true,
      displayParticipantsWithoutDepartment: false,
      participantsWithoutDepartment: []
    }),

    computed: mapGetters({
      project: 'app/project',
      navData: 'app/navData'
    }),

    created() {

      this.projectId = parseInt(this.$route.params.project_id)
      if (this.projectId) {
        this.workZone = false
        this.projectName = this.navData.project_name
      } else {
        this.projectId = this.project.id
        this.projectName = this.project.name
        this.$options.menuOption = '2-10'
        this.workZone = true
      }
      this.$options.subtitle = ['departments_page_subtitle', [this.projectName.toUpperCase()]]

      // Obtenemos los participantes del proyecto que no pertenecen a ningún departamento
      this.loadParticipantsWithoutDepartments();

      this.$on('removeButtonClicked', function (roleName) {
        if (roleName === 'department_chief') {
          this.selectedDepartmentNodeData.manager.avatar = '/img/icon-user-default.png'
          this.selectedDepartmentNodeData.manager.email = ''
          this.selectedDepartmentNodeData.manager.fullname = 'UNDEFINED'
          this.selectedDepartmentNodeData.manager.user_id = 0
          this.selectedDepartmentNode.setAttribute('data-source', JSON.stringify(this.selectedDepartmentNodeData))
          this.selectedDepartmentNode.querySelector('.dept-manager').textContent = this.selectedDepartmentNodeData.manager.fullname
          this.selectedDepartmentNode.querySelector('.dept-avatar').src = this.selectedDepartmentNodeData.manager.avatar
        }
        // Si es un viewer y está activado el mostrar los participantes de departamento, recargar el árbol
        else {
          this.reloadOrganigram()
        }

        // Recargamos los participantes del proyecto que no pertenecen a ningún departamento
        this.loadParticipantsWithoutDepartments();
      });

      this.$on('addUserClicked', function (user, roleName, isReplacement = false) {
        if (roleName === 'department_chief' && !isReplacement) {
          if (user.avatar) {
            this.selectedDepartmentNodeData.manager.avatar = user.avatar
          } else {
            this.selectedDepartmentNodeData.manager.avatar = '/img/icon-user-default.png'
          }
          this.selectedDepartmentNodeData.manager.email = user.email
          this.selectedDepartmentNodeData.manager.fullname = this.truncateText(user.fullname)
          this.selectedDepartmentNodeData.manager.user_id = user.id
          this.selectedDepartmentNode.setAttribute('data-source', JSON.stringify(this.selectedDepartmentNodeData))
          this.selectedDepartmentNode.querySelector('.dept-manager').textContent = this.selectedDepartmentNodeData.manager.fullname
          this.selectedDepartmentNode.querySelector('.dept-avatar').src = this.selectedDepartmentNodeData.manager.avatar
        }
        // Si es un viewer y está activado el mostrar los participantes de departamento, recargar el árbol
        else {
          this.reloadOrganigram()
        }

        // Recargamos los participantes del proyecto que no pertenecen a ningún departamento
        this.loadParticipantsWithoutDepartments();
      });
    },

    mounted() {
      this.orgchart = this.$refs.orgchart
    },

    watch: {
      displayDepartmentUsers(value) {
        this.withUsers = value ? 1 : 0
        this.reloadOrganigram()
        // if (value) {
        //   this.scrollToEnd()
        // }
      },
    },

    methods: {
      // Nuevo nodo (departamento) seleccionado
      orgchartSelectedNodeChanged(node) {
        this.displayUserBoxes = true
        //console.log(node)
        this.loadParentDepartmentForm(node)
        if (node === null) {
          this.resetSelectedNode()
        } else if (node !== this.selectedDepartmentNode) {
          this.selectedDepartmentNode = node
          this.selectedDepartmentNodeData = JSON.parse(node.getAttribute('data-source'))
          //console.log(this.selectedDepartmentNodeData)

          if (this.selectedDepartmentNodeData.dept_user_node === true) {
            this.resetSelectedNode(true)
          } else {
            this.formChangeDepartmentName.department_name = this.selectedDepartmentNodeData.name
            this.formAddChildDepartment.parent_department_id = this.selectedDepartmentNodeData.id
          }
        } else {
          this.selectedDepartmentNode = node
          this.formChangeDepartmentName.department_name = ""
          this.formChangeDepartmentName.department_name = this.selectedDepartmentNodeData.name
        }
      },

      reloadOrganigram() {
        this.orgchartKey++
        this.resetSelectedNode(true)
      },

      // Cambia nombre del departamento
      async changeDepartmentName() {
        if (!this.selectedDepartmentNode) {
          return;
        }
        const {data} = await this.formChangeDepartmentName.post('/api/v2/department/' + this.selectedDepartmentNodeData.id)
        if (data && data.success) {
          console.log("changeDepartmentName")
          this.selectedDepartmentNodeData.name = this.formChangeDepartmentName.department_name
          this.selectedDepartmentNode.setAttribute('data-source', JSON.stringify(this.selectedDepartmentNodeData))
          this.selectedDepartmentNode.querySelector('.dept-name').textContent = this.selectedDepartmentNodeData.name
          this.$notify.success('department_change_name_success')
        } else {
          // Error
          this.$notify.error('department_change_name_error')
        }
      },

      // Añade departamento hijo
      async addChildDepartment() {
        if (!this.selectedDepartmentNode && !this.formAddChildDepartment.child_department_name) {
          return;
        }

        const {data} = await this.formAddChildDepartment.post('/api/v2/department/' + this.selectedDepartmentNodeData.id + '/add-child')
        if (data && data.success) {
          // this.orgchart.getDataFromUrl()
          this.orgchart.addNode(this.selectedDepartmentNode, data.child_department)
          this.formAddChildDepartment.reset()
        }
      },

      deleteDepartment() {
        this.$dialog.confirm({
          title: this.$t('delete_department'),
          message: this.$t('delete_department_confirm_text', [this.selectedDepartmentNodeData.name]),
          confirmText: this.$t('delete_department'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            let url = '/api/v2/department/' + this.selectedDepartmentNodeData.id + '/delete'
            const {data} = await axios.post(url)
            if (data && data.success) {
              this.$notify.success('success_deleting_department')
              this.orgchart.removeNode(this.selectedDepartmentNode)
              this.resetSelectedNode(true)
            } else {
              this.$notify.error(data.error)
            }
          }
        })
      },

      resetSelectedNode(hideUserBoxes = false) {
        this.selectedDepartmentNode = null
        this.selectedDepartmentNodeData = null
        this.formChangeDepartmentName.reset()
        this.formAddChildDepartment.reset()
        if (hideUserBoxes) {
          this.displayUserBoxes = false
        }
      },

      // Pinta los nodos del organigrama
      createNode(node, data) {
        let title = node.getElementsByClassName("title")[0]
        title.innerHTML = ''
        title.classList.toggle('py-1')

        let container = document.createElement('div')
        container.setAttribute('style', 'height:48px')
        container.setAttribute('class', 'w-full inset-0 block border border-white rounded')

        let extra_name_class = '';
        if (data.name.length > 20)
          extra_name_class = 'dept-name-long';
        let top = document.createElement('div')
        top.setAttribute('style', 'height:50%;padding-left:44px')
        top.setAttribute('class', 'dept-name ' + extra_name_class + ' flex items-center text-white font-semibold')
        top.innerHTML = data.name
        container.appendChild(top)

        let bottom = document.createElement('div')
        bottom.setAttribute('style', 'height:50%;padding-left:44px')
        bottom.setAttribute('class', 'dept-manager bg-white flex items-center text-blue font-semibold')
        bottom.innerHTML = this.truncateText(data.manager.fullname)
        container.appendChild(bottom)

        let avatar = document.createElement('div')
        avatar.setAttribute('class', 'w-full h-full absolute float-left inset-0 flex items-center')
        let img = document.createElement('img')
        img.setAttribute('src', data.manager.avatar)
        img.setAttribute('alt', data.manager.fullname)
        img.setAttribute('class', 'dept-avatar w-8 h-8 rounded-full border border-grey-dark ml-3')
        avatar.appendChild(img)
        container.appendChild(avatar)

        title.appendChild(container)
      },

      truncateText(text) {
        return text ? text.length > 24 ? text.slice(0, 24) + '...' : text : "UNDEFINED";
      },

      async loadParentDepartmentForm(node) {

        this.parentDepartments = []
        this.newParentDepartment = null
        this.disabledNewParentDepartment = false
        this.disabledNewParentButton = true

        if (node !== null) {
          this.loadingParentDepartmentForm = true

          // Obtenemos los departamentos que pueden ser posibles padres del departamento
          const {data} = await axios.get('/api/v2/department/' + node.id + '/list_parents')

          if (data && data.success) {
            this.parentDepartments = data.departments
            this.disabledNewParentDepartment = this.parentDepartments.length === 0
          }
        }

        this.loadingParentDepartmentForm = false
      },

      selectParentOption(selectedOption, id) {
        this.disabledNewParentButton = false
      },

      removeParentOption(removedOption, id) {
        this.disabledNewParentButton = true
      },

      confirmSubmitParentDepartmentForm() {
        if (this.newParentDepartment !== null) {
          this.$dialog.confirm({
            title: this.$t('new_parent_department'),
            message: this.$t('modify_orgranigram_confirmation'),
            confirmText: this.$t('modify_orgranigram'),
            cancelText: this.$t('cancel'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => this.submitParentDepartmentForm()
          })
        }
      },

      // Modifica el organigrama asignando un nuevo padre a un departamento
      async submitParentDepartmentForm() {
        if (!this.selectedDepartmentNode && this.newParentDepartment !== null) {
          return;
        }

        this.formAddParentDepartment.parent_department_id = this.newParentDepartment.id

        const {data} = await this.formAddParentDepartment.post('/api/v2/department/' + this.selectedDepartmentNodeData.id + '/update_parent')
        if (data && data.success) {
          this.$notify.success('success_organigram_update')
          this.reloadOrganigram()
        } else {
          this.$notify.error('error_organigram_update')
        }
      },

      loadParticipantsWithoutDepartments() {
        // Obtenemos los participantes del proyecto que no pertenecen a ningún departamento
        let url = '/api/v2/project/' + this.projectId + '/users_without_departments'
        self = this
        axios.get(url).then(function (response) {
          if (response.data && response.data.success) {
            self.participantsWithoutDepartment = response.data.users
          }
        })
      },

      goToImportExportActivities() {
        this.$store.dispatch('app/updateNavData', {
          project_id: this.projectId,
          project_name: this.projectName
        })
        this.$router.push({
          name: 'project.import-export-activities', params: {
            project_id: this.projectId
          }
        })
      },

      // scrollToEnd: function() {
      // // var container = this.$refs.mainContainer
      // var container = document.querySelector('#app')
      // // alert(container.scrollHeight)
      // container.scrollTop = container.scrollHeight
      // // alert(container.scrollTop)
      // // const el = document.scrollingElement || document.documentElement
      // // el.scrollTop = el.scrollHeight
      // var element = document.scrollingElement
      // alert(element.scrollHeight)
      // element.scrollTo(container)
      // // element.scrollTop = element.scrollHeight
      // alert(element)
      // // document.scrollingElement.scrollHeight
      // window.scrollTo(0,element.scrollHeight);
      // var cancelScroll = VueScrollTo.scrollTo(container)
      // someElement.scrollTop = someElement.scrollHeight;
      // var app = document.querySelector('#app')
      // app.scrollTop = app.scrollHeight


      // document.body.scrollTop = document.body.scrollHeight
      // var el = document.querySelector("#mainContainer")
      // console.log(el)
      // el.scrollTop = el.scrollHeight
      // alert(el.scrollHeight)
      // window.scrollTo(0,document.querySelector("#mainContainer").scrollHeight);
      // },
    }
  };
</script>

<style>
  #mainContain {
    min-height: 101%;
    overflow-y: scroll !important;
  }
</style>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
