<template>
  <section>
    <div>
      <!-- Modal -->
      <onboarding-video
              ref="modal"
              :show=showOnboarding
      />
    </div>

    <div v-if="isLoading" class="my-4 text-center">
      <scale-loader></scale-loader>
    </div>
    <div v-else>
      <div v-if="!this.projects || this.projects.length == 0" class="mt-16">
        <p class="text-3xl text-center text-grey">
          <b-icon pack="fas" icon="ban" size="is-large"/>
          {{ $t('no_projects_to_select') }}
        </p>
      </div>
      <template v-else>
        <div>

          <div @click="$router.push({ name: 'group.select' });" class="underline cursor-pointer">
            &lt;&lt; {{ $t('back_to')}} {{ $t('groups') }}
          </div>

          <div class="relative flex flex-col sm:flex-row w-full z-0">
            <div class="w-full sm:w-1/4 sm:mt-4 sm:ml-2">
              <b-input
                      v-model="search"
                      :placeholder="$t('search_project')"
                      type="search"
                      icon="search">
              </b-input>
            </div>
            <div class="w-full sm:w-1/4">
              <v-switch
                v-model="showArchive"
                ref="show_archive"
                :label="$t('show_archive')"
                :color="showArchive ? 'green' : 'red'"
                v-on:checked="(value) => showArchive = value"
                class="sm:mt-3 sm:ml-3"
              />
            </div>
          </div>


          <div v-if="group" class="mt-5 sm:ml-2 text-blue-light font-bold">{{ $t('projects_of_group', [group.name]) }}</div>

          <div class="flex w-full mt-3 sm:mt-6">
            <div class="flex flex-wrap justify-between- w-full">
              <!-- Proyecto -->
              <div
                v-for="project in filteredProjects()"
                :key="project.id"
                :class="{ 'bg-grey-lighter': !project.can_participate, 'badge-orange': project.notifications }"
                :data-badge="project.notifications"
                class="flex-initial mb-8 border rounded-sm shadow cursor-pointer border-blue-lighter hover:bg-grey-lightest badge epc-card"
                style="min-height:495px;"
              >
                <!-- Nombre del proyecto -->
                <div
                  :class="{ 'border-grey-light': !project.can_participate,
                        'border-grey-lighter': project.can_participate }"
                  class="flex flex-col justify-center w-full p-2 text-center uppercase border-b bg-blue-lightest h-36"
                >
                  <span class="text-lg font-semibold" @click="selectProject(project)">
                    <!-- Icono de visualizador -->
                    <b-icon
                      pack="fas"
                      v-show="project.can_view"
                      icon="eye"
                      class="float-left text-blue"/>
                    <!-- Icono de colaborador -->
                    <b-icon
                      pack="fas"
                      v-show="project.can_collaborate"
                      icon="address-book"
                      class="float-left text-blue"/>
                    {{ project.name }}
                  </span>
                  <!-- <div> ocultado area por no usarse el organigrama
                    <b-icon pack="fas" icon="globe-africa" size="is-small"></b-icon>
                    <span class="text-sm">{{ project.area_name }}</span>
                  </div> -->
                </div>
                <div>
                  <img :src="project.image" class="object-fill w-full h-64" v-if="project.image" @click="selectProject(project)">
                </div>
                <!-- Área del proyecto -->

                <!-- Presupuesto -->
                <div class="w-full pt-2 text-sm text-center">
                  <span class="font-bold">{{ $t('budget') }}:</span>
                  <template v-if="project.budget">
                    {{ formatPrice(project.budget.toFixed(2)) }} {{ project.currency }}
                  </template>
                  <template v-else>N/A</template>
                </div>
                <!-- Fecha Entrega -->
                <div class="flex items-center justify-center w-full p-2 text-sm text-grey">
                  {{ $t('delivery_date') }}:
                  {{ (new Date(project.current_final_date)).toLocaleDateString() }}
                </div>
                <div class="flex items-center justify-center w-full pb-4 text-sm text-grey">
                  <b-dropdown v-show="project.can_participate">
                    <button class="button is-small" type="button" slot="trigger">
                      <template
                        v-if="!project.user_notifications.project_notifications || project.user_notifications.project_notifications === 'all'"
                      >
                        <b-icon pack="far" icon="bell" class="float-left h-full text-grey"></b-icon>
                        <span class="text-grey">{{ $t('all_notifications') }}</span>
                      </template>
                      <template v-else>
                        <b-icon pack="far" icon="bell" class="float-left h-full text-grey"></b-icon>
                        <span class="text-grey">{{ $t('mentions_favorites') }}</span>
                      </template>
                      <b-icon pack="fas" class="text-grey" icon="angle-down"></b-icon>
                    </button>

                    <b-dropdown-item @click="setProjectNotifications(project, true)" value="all">
                      <div class="media">
                        <b-icon class="media-left" icon="globe-africa"></b-icon>
                        <div class="media-content">
                          <h3>{{ $t('all_notifications') }}</h3>
                          <small>{{ $t('all_notifications_text') }}</small>
                        </div>
                      </div>
                    </b-dropdown-item>

                    <b-dropdown-item
                      @click="setProjectNotifications(project, false)"
                      value="mentions_favorites"
                    >
                      <div class="media">
                        <b-icon class="media-left" icon="user-check"></b-icon>
                        <div class="media-content">
                          <h3>{{ $t('mentions_favorites') }}</h3>
                          <small>{{ $t('mentions_favorites_text') }}</small>
                        </div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
// import Vue from 'vue'
import store from "~/store";
import { mapGetters } from 'vuex'
import axios from "axios";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import OnboardingVideo from "../../components/onboarding-video";

export default {
  middleware: "auth",
  title: "select_project_page_title",
  subtitle: "select_project_page_subtitle",
  menuOption: "2-0",
  components: {
    OnboardingVideo,
    ScaleLoader
  },

  computed: {
    showOnboarding() {
      //return true
      return this.$route.params.showOnboarding && this.$route.params.showOnboarding === "1"
    },
    ...mapGetters({
      company: 'app/company',
    }),
  },

  watch: {
    showArchive(value) {
      this.isLoading = true
      this.getProjects()
    }
  },

  data: () => ({
    group: null,
    projects: null,
    isLoading: true,
    search: '',
    showArchive: 0,
  }),

  created() {
      this.group = store.getters["app/group"];
  },

  mounted() {

    this.getProjects();
  },

  methods: {
    async getProjects() {
      const { data } = await axios.get("/api/v2/user/projects", {
        'params' : { showArchive : this.showArchive, group: this.group ? this.group.id : null, companyId: this.company.id, withConfigs: 1 }
      });

      if (data.projects) {
        this.projects = data.projects;
        this.isLoading = false;
      }
    },

    async selectProject(project) {
      await this.$store.dispatch("app/setProject", { project });
      let tags = project.tags
      await this.$store.dispatch("app/setTags", { tags });
      await this.getVisualConfig(project)
      await this.$store.dispatch('app/setDashboardReport', {dashboardReport: {} })
      this.$router.push({ name: "project.home" });
    },

    async setProjectNotifications(project, value) {
      // params
      let params = {
        key: "project_notifications",
        value: value ? "all" : "mentions_favorite",
        project: project.id
      };

      // endpoint
      const { data } = await axios.post(
        "/api/v2/project/user/notifications",
        params
      );

      if (data.success) {
        // show success msg
        this.getProjects();
        this.$notify.success("success_change_user_project_notifications");
      } else {
        // show error msg
        this.$notify.error("error_change_user_project_notifications");
      }
    },

    filteredProjects() {
      return this.projects.filter(project => {
        return project.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },

      formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    async getVisualConfig(project) {
      if (project.configs) {
        await this.$store.dispatch("app/setVisualConfig", {config: project.configs});
      }
    }
  }
};
</script>

<style scoped>
.epc-card{
  box-sizing: border-box;
  margin: 1rem .25em;
  flex-basis: calc(100% - 0.5rem);
}

/* @media only screen and (min-width: 768px) and (max-width: 1024px){ */
@media only screen and (min-width: 480px) {
  .epc-card {
    flex-basis: calc(50% - 0.5rem);
  }

}

@media only screen and (min-width: 768px) {
  .epc-card {
    flex-basis: calc(33% - 0.5rem);
  }

}

@media only screen and (min-width: 1200px) {
  .epc-card {
    flex-basis: calc(25% - 0.5rem);
  }
}
</style>
