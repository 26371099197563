<template>
  <div v-if="!isLoading">
    <div class="flex w-full">
      <div class="w-full">
        <!--light mode - text and icons-->
        <div class="p-2 text-gray-900 bg-white rounded-lg shadow-lg- font-medium capitalize text-right">

          <!-- Botón para ver Kanban-->
          <span v-if="project.user_department.can_admin"
            class="px-1 w-8 mt-2 relative cursor-pointer hover:text-gray-700"
            @click="displayGantt()">
            <b-tooltip :label="$t('display_gantt')" position="is-top">
              <i class="w-8 fas fa-chart-gantt p-2 bg-gray-200 rounded-full"></i>
            </b-tooltip>
            <!-- <span class="absolute right-0 top-0 -mt-2 -mr-1 text-xs bg-red-500 text-white font-medium px-2 shadow-lg rounded-full">3</span> -->
          </span>

          <!-- Mostrar tabla -->
          <span v-if="project.user_department.can_admin"
            class="px-1 mt-2  hover:text-gray-700 cursor-pointer
              lg:px-2 text-sm xl:rounded xl:py-2 xl:hover:bg-gray-200"
            @click="displayTable()">
            <b-tooltip :label="$t('display_table')" position="is-top">
              <i class="lg:w-8 fas fa-table p-2 bg-gray-200 rounded-full"></i>
              <span class="mx-1 hidden xl:inline-">{{ $t('display_table') }}</span>
            </b-tooltip>
          </span>

          <!-- Crear actividad -->
          <span v-if="project.user_department.can_admin"
            class="px-1 mt-2  hover:text-gray-700 cursor-pointer
              lg:px-2 text-sm xl:rounded xl:py-2 xl:hover:bg-gray-200"
            @click="onCreateActivityClicked()">
            <b-tooltip :label="$t('add_activity')" position="is-left">
              <i class="lg:w-8 fas fa-plus p-2 bg-gray-200 rounded-full"></i>
              <span class="mx-1 hidden xl:inline-">{{ $t('add_activity') }}</span>
            </b-tooltip>
          </span>
        </div>
      </div>
    </div>

    <div class="w-full h-8">
      <!-- Filtro por subactividades -->
      <div class="float-left mt-1 w-full">
        <v-switch
          :disabled="isLoading"
          :focusable="! isLoading"
          :label="$t('filter_by_subactivities')"
          :color="filterSubactivities ? 'green' : 'red'"
          :value="filterSubactivities"
          v-on:checked="checkFilterSubactivities"
          style="margin-left: 20px; float:left;"
        />

        <!-- Botón para abrir diagrama de gantt-->
        <!-- <div class="rounded-sm mr-2 float-right">
          <v-button icon="project-diagram" class="mr-4" @click="displayGantt()">{{$t('display_gantt')}}</v-button>
        </div>

        <div class="rounded-sm mr-2 float-right">
          <v-button icon="table" class="mr-4" @click="displayTable()">{{$t('display_table')}}</v-button>
        </div>

        <div class="rounded-sm mr-2 float-right">
          <v-button icon="plus" class="mr-4" @click="onCreateActivityClicked()">{{$t('add_activity')}}</v-button>
        </div> -->
      </div>
    </div>
    <br>
    <template v-if="isBlocked">
      <p class="text-red font-bold">Estados bloqueados, necesita estados propios para poder modificar los bloques en kanban.</p>
    </template>
      <kanban-board :stages="stages" :blocks="activities" @update-block="updateBlock" :config="config">
      <!-- Si se aplica, punto de color rojo/verde -->
      <div v-for="activity in activities" :slot="activity.id" :key="activity.id" @click="openActivity(activity)">
        <activity-time-status :date="activity.current_end_date" size="3" class="mr-2 reduce activity-time-status"
                              v-if="activity._status != 4 && activity._status != 6"/>

        <!-- Nombre de la actividad -->
        <strong>{{ activity.name }} </strong>

        <!-- Fecha de finalización -->
        <div class="text-xs">{{activity.current_end_date}}</div>

        <!-- Contenedor del avatar -->
        <div class="w-full">
          <div class="rounded-full overflow-hidden border border-grey w-8 h-8 mr-2 kanban-avatar">
            <img :src="activity.manager.avatar" :alt="activity.manager.fullname" :title="activity.manager.fullname">
          </div>
        </div>

      </div>
      </kanban-board>
  </div>
</template>

<script>
  import Vue from "vue"
  import axios from 'axios'
  import EventBus from "~/plugins/bus";
  import moment from 'moment'
  import {mapGetters} from 'vuex'
  import activityTimeStatus from '·/components/activity-time-status'
  import vueKanban from 'vue-kanban'

  Vue.use(vueKanban);
  Vue.use(moment);

  export default {
    middleware: 'auth',
    title: 'activities_page_title',
    subtitle: 'activities_page_subtitle',
    menuOption: '2-2',

    components: {
      activityTimeStatus
    },

    computed: {
      ...mapGetters({
        project: 'app/project',
      }),
    },

    data: () => ({
      isLoading: true,
      filterSubactivities: false,
      stages: [],
      statuses: [],
      statusesByName: [],
      activities: [],
      isBlocked: false,
      config: {},
    }),

    mounted() {
      this.fetchData();

      let self = this;
      EventBus.$on("kanbanRefresh", function (e) {
        if (self.project.id === e.project_id) {
          self.fetchData();
        }
      });
    },

    beforeDestroy(){
      EventBus.$off("kanbanRefresh");
    },

    methods: {
      /**
       * Recoge Datos necesarios de la API para dibujar el kanban
       */
      fetchData() {
        this.loadStatuses();
        this.fetchActivities();
      },

      /**
       * Cargar solo actividades padres
       */
      fetchActivities() {
        return this.loadActivities({p: this.project.id, pg: 1, parent_access: 1, kanban: true});
      },

      /**
       * Cargar solo actividades hijas
       */
      fetchSubactivities() {
        return this.loadActivities({
          p: this.project.id,
          pg: 1,
          filter_subactivities: 1,
          parent_access: 1,
          kanban: true
        });
      },

      /**
       * Abre actividad en pestaña al hacerle clic
       */
      openActivity(activity) {
        if (!this.filterSubactivities) //ACTIVIDADES PADRE
          this.$router.push({'name': 'activity.home', params: {activity_id: activity.id}});
        else //ACTIVIDADES HIJAS
          this.$router.push({
            'name': 'subactivity.home',
            params: {activity_id: activity.parent_id, subactivity_id: activity.id}
          });
      },

      /**
       * Cambia ventana al formulario de crear actividades
       */
      onCreateActivityClicked() {
        let url = '/api/v2/locations?all=1&p=' + this.project.id;
        self = this;
        axios.get(url)
          .then(function (response) {
            if (response.data && response.data.success && response.data.locations) {
              self.$router.push({name: 'activity.create'})
            } else {
              self.$notify.error('project_without_locations_create_activity')
            }
          });
      },

      /**
       * Cambia la ventana a la tabla de actividades normal
       */
      displayTable() {
        this.$router.push({name: 'project.activities'});
      },

      /**
       * Cambia la ventana a la vista del diagrama de gantt
       */
      displayGantt() {
        this.$router.push({name: 'project.activities.gantt'});
      },

      /**
       * Controla que hacer cuando el switch de subactividades es cambiado
       */
      checkFilterSubactivities() {
        this.filterSubactivities = !this.filterSubactivities;
        this.isLoading = true;

        if (this.filterSubactivities)
          this.fetchSubactivities();
        else
          this.fetchActivities();

        this.isLoading = false;
      },

      /**
       * Evento de tarjeta movida
       */
      updateBlock(id, status, index) {
        if (this.isBlocked){
          return; //evitamos mostrar errores de api al no existir statuses
        }

        id = Number(id);
        let activity = this.activities.find(b => b.id === id);

        this.updateStatus(activity, status, index);
      },

      /**
       * Actualiza la actividad por ID al status indicado
       * @param activity
       * @param status
       * @param index
       * @returns {Promise<void>}
       */
      async updateStatus(activity, status, index) {
        //nota: Cambiar alguna propiedad de "activity" fuerza su reordenado en la columna de destino.

        let url = '/api/v2/activity/' + activity.id + '/statuses';
        let body = {
          status: this.statusesByName[status].id,
          index: index
        };
        const {data} = await axios.post(url, body);

        if (data.success) {
          this.$toast.open({
            message: this.$t('status_change_success'),
            type: 'is-success',
            position: 'is-top-right'
          })
        } else {
          // Error
          this.$toast.open({
            message: this.$t('status_change_fail'),
            type: 'is-danger',
            position: 'is-top-right'
          })
        }
      },
      // Cargamos estados internos cuando no existen en el proyecto
      loadInternalStatuses(){
        this.isBlocked = true;
        // Añadimos config para que no se puedan mover los bloques
        this.config = {
          moves(){
            return false;
          }
        };

        return [{id: 0, name: this.$t('activity_status_assigned')},
                {id: 1, name: this.$t('activity_status_running')},
                {id: 2, name: this.$t('activity_status_paused')},
                {id: 3, name: this.$t('activity_status_issue')},
                {id: 4, name: this.$t('activity_status_done')},
                {id: 5, name: this.$t('activity_status_reopened')},
                {id: 6, name: this.$t('activity_status_closed')},
        ];

      },
      /**
       * Carga los estados personalizados
       * @returns {Promise<void>}
       */
      async loadStatuses() {
        let data = await axios.get('/api/v2/project/' + this.project.id + '/statuses', {params: this.params})

        if (data.data && data.data.success) {
          let statuses = data.data.statuses;

          // En caso de estado vacío usamos los internos
          if (statuses.length == 0) {
            // Cargamos los estados internos
            statuses = this.loadInternalStatuses();
          }

          this.stages = statuses.map(item => item.name);

          // insertamos un estado virtual para actividades todavia sin asignar
          this.stages.unshift(this.$t('no_status_kanban'))

          // Remapeado por id y nombre
          for (let status in statuses) {
            this.statuses[statuses[status].id] = statuses[status];
            this.statusesByName[statuses[status].name] = statuses[status];
          }
          this.isLoading = false;
        }
      },

      /**
       * Carga la lista de actividades para el proyecto actual
       * @returns {Promise<void>}
       */
      async loadActivities(args) {

        const {data} = await axios.get('/api/v2/activities', {params: args});

        if (data && data.activities) {
          this.activities = data.activities;

          this.activities.sort((a,b) => a.name - b.name); // ordenacion segun lo solicitado por aquambiente talvez se deba mejorar

          for (let activity in this.activities) {
            activity = this.activities[activity];

            if (activity.status_id == null && this._status == null) {
              activity.status_id = activity.status;
            }

            if (this.statuses[activity.status_id] == null){
              activity.status = this.stages[0];
            } else {
              activity.status = this.statuses[activity.status_id].name;
            }
          }

        } else {
          this.$notify.error('error_loading_activities')
        }
      },

    }
  };
</script>

<style lang="scss" scoped>
  #page-content-wrapper {
    overflow-y: hidden;
  }

</style>

<style lang="scss">
  @import 'node_modules/vue-kanban/src/assets/kanban.scss';

  div.drag-container {
    margin: 10px 0 0 0;
    width: auto;
    max-width: none;
    overflow-x: auto;
    height: auto;
  }

  li.drag-column {
    border-radius: 3px;
    min-width: 240px;
    max-width: 240px;

    background-color: #ebecf0;
  }

  span.drag-column-header {

    * {
      color: #172b4d;
    }
  }

  ul.drag-inner-list {
    overflow-y: auto;
    overflow-x: hidden;

    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateZ(0);

    @media screen and (min-height: 901px) {
      max-height: 730px;
    }

    @media screen and (max-height: 900px) {
      max-height: 550px;
    }

    &::-webkit-scrollbar {
      height: 8px;
      width: 8px
    }

    &::-webkit-scrollbar-button:end:increment, &::-webkit-scrollbar-button:start:decrement {
      background: transparent;
      display: none
    }

    &::-webkit-scrollbar-track-piece {
      background: rgba(9, 30, 66, .08)
    }

    &::-webkit-scrollbar-track-piece:vertical:start {
      border-radius: 4px 4px 0 0
    }

    &::-webkit-scrollbar-track-piece:vertical:end {
      border-radius: 0 0 4px 4px
    }

    &::-webkit-scrollbar-track-piece:horizontal:start {
      border-radius: 4px 0 0 4px
    }

    &::-webkit-scrollbar-track-piece:horizontal:end {
      border-radius: 0 4px 4px 0
    }

    &::-webkit-scrollbar-thumb:horizontal, &::-webkit-scrollbar-thumb:vertical {
      background: rgba(9, 30, 66, .13);
      border-radius: 4px;
      display: block;
      height: 48px
    }

  }

  li.drag-item {
    border: none;
    border-radius: 3px;
    max-width: 230px;
    height: auto;
    background-color: rgb(252, 252, 252);
    box-shadow: 0 1px 0 rgba(9, 30, 66, .25);


    &:hover {
      background-color: #f4f5f7;
      cursor: pointer;

      &.is-moving {
        cursor: grabbing;
      }
    }

    * {
      color: #172b4d
    }

  }

  .activity-time-status {
    margin-right: .25rem;
  }

  .kanban-avatar {
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 690px) {
    .drag-list {
      display: flex;
    }
  }
</style>
