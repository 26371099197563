<template>
<b-tooltip :active="tooltip !== null" :label="$t(tooltip)" :position="'is-' + tooltipPosition" :multilined="multilined">
  <button
    class="rounded-full w-8 h-8 btn p-0"
    :class="{
      [`btn-${color}`]: true,
      [`text-${iconColor}`]: true,
    }"
    @click="click"
    :disabled="disabled"
  >
    <b-icon :icon="icon" :pack="iconPack" size="is-small" />
  </button>
</b-tooltip>
</template>

<script>
export default {
  name: 'v-button-icon-round',

  props: {
    color: { type: String, default: 'blue' },
    icon: { type: String, default: null },
    iconPack: { type: String, default: 'fas' },
    iconColor: { type: String, default: 'white' },
    disabled: { type: Boolean, default: false },
    tooltip: { type: String, default: null },
    tooltipPosition: { type: String, default: 'top' },
  },

  computed: {
    multilined() {
      if (this.tooltip) {
        // Si el texto del tooltip es largo
        let text = this.$t(this.tooltip)
        return (text.length > 20)
      }
      return false
    }
  },

  methods: {
    click (event) {
      this.$emit('click', event)
    }
  },
};
</script>
