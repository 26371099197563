<template>
  <b-modal :active.sync="isModalActive" :can-cancel="canCancel">
    <div class="w-auto border rounded border-blue-light">
      <header
        class="w-full px-4 py-2 text-xl font-thin uppercase bg-grey-lightest text-blue-light"
      >
        {{ $t("add_document") }}
      </header>

      <div class="" v-if="canSetCodification && !onlyUploadFile">
        <b-tabs type="is-toggle-rounded" size="is-small" position="is-centered" class="bg-grey-lightest"
          v-model="codificationOption">
            <b-tab-item>
              <template slot="header">
                <b-icon pack="fas" icon="file"></b-icon>
                <span>{{ $t("without_coded_name") }}</span>
              </template>
              <div>
              </div>
            </b-tab-item>

            <b-tab-item>
              <template slot="header">
                <b-icon pack="fas" icon="file-signature"></b-icon>
                <span>{{ $t("with_coded_name") }}</span>
              </template>
              <div>
              </div>
            </b-tab-item>
        </b-tabs>
      </div>

      <div class="w-full p-8 text-center bg-grey-lightest">
        <div class="w-full flex">
          <b-upload v-model="files" :multiple="localMultipleFiles" drag-drop :accept="accept" :disabled="!canCancel" class="w-full justify-center">
            <div class="flex justify-center">
              <div class="p-8 text-center text-grey">
                <p class="m-4">
                  <b-icon pack="fas" icon="upload" size="is-large" />
                </p>
                <p>{{ $t("drop_files") }}</p>
              </div>
            </div>
          </b-upload>

          <b-upload v-model="files" v-if="codificationOption == 0 && uploadFolders" :multiple="localMultipleFiles" directory="" webkitdirectory="" mozdirectory="" :disabled="!canCancel" class="w-full">
            <div class="flex justify-center upload-draggable is-primary w-full">
              <div class="p-8 text-center text-grey">
                <p class="m-4">
                  <b-icon pack="fas" icon="folder" size="is-large" />
                </p>
                <p>{{ $t("upload_folders") }}</p>
              </div>
            </div>
          </b-upload>
        </div>
        <!-- Ficheros añadidos -->
        <div class="flex w-full p-8 text-left">
          <div
            v-if="!filesSelected"
            class="flex items-center justify-center w-full px-4 text-center text-grey-light"
          >
            <b-icon pack="fas" icon="ban" size="is-large" />
            {{ $t("no_files") }}
          </div>
          <div v-else class="w-full">

            <div class="w-full pb-3 mt-4" v-if="!canCancel">
              <div class="text-red-700 px-4 py-3 rounded relative" style="border: 1px solid rgb(250, 204, 21); border-radius: 6px; padding: 0.25em; background-color: rgb(254, 248, 195);" role="alert">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <strong class="font-bold">Procesando solicitud...</strong>
                <span class="block sm:inline">Mantenga activa esta ventana hasta que concluya el proceso.</span>
              </div>
            </div>

            <!-- subida de documentos generales, que NO son planos de proyecto y podrian tener formulario codificacion nombre -->
            <div v-if="localMultipleFiles && !onlyUploadFile">
              <span
                v-for="(file, index) in files"
                :key="index"
                class="inline-flex items-center px-2 py-1 mb-1 mr-1 text-xs rounded text-grey-lightest whitespace-nowrap"
                :class="{
                  'bg-grey-light': isSubmitingFiles,
                  'bg-blue': !isSubmitingFiles,
                }"
              >
                <input
                  v-model="files[index].custom_name"
                  :placeholder="getFileName(file)"
                  class="input"
                />
                <input
                  class="ml-1 input extension"
                  disabled
                  :value="getFileExtension(file)"
                />
                <button
                  v-if="!isSubmitingFiles"
                  type="button"
                  @click="removeFile(index)"
                  class="flex items-center ml-2 text-white rounded-full bg-blue-dark"
                  style="padding: 0.1rem"
                >
                  <b-icon pack="fas" icon="times" size="is-small" />
                </button>
              </span>
            </div>

            <!-- subida ficheros planos de proyecto, con campos 'code' y 'description' (no mostraría formulario codificacion nombre) -->
            <div v-if="localMultipleFiles && onlyUploadFile">
              <div
                v-for="(file, index) in files"
                :key="index"
                class="inline-flex items-center px-2 py-1 mb-1 mr-1 text-xs rounded text-grey-lightest whitespace-nowrap"
                :class="{
                  'bg-grey-light': isSubmitingFiles,
                  'bg-blue': !isSubmitingFiles,
                }"
              >
                <input
                  v-model="files[index].code"
                  :placeholder="$t('code')"
                  @input="validate = !validate;"
                  :class="[validate && files[index].code ? '' : 'required', 'input', 'code']"
                />
                <input
                  v-model="files[index].description"
                  :placeholder="$t('description')"
                  @input="validate = !validate;"
                  :class="['input', 'description', validate && files[index].description ? '' : 'required', 'ml-1']"
                />
                <input
                  v-model="files[index].custom_name"
                  :placeholder="getFileName(file)"
                  class="input filename ml-1"
                />
                <input
                  class="ml-1 input extension"
                  disabled
                  :value="getFileExtension(file)"
                />
                <button
                  v-if="!isSubmitingFiles"
                  type="button"
                  @click="removeFile(index)"
                  class="flex items-center ml-2 text-white rounded-full bg-blue-dark"
                  style="padding: 0.1rem"
                >
                  <b-icon pack="fas" icon="times" size="is-small" />
                </button>
              </div>
            </div>

            <div v-if="!localMultipleFiles">
              <span

                class="inline-flex items-center px-2 py-1 mb-1 mr-1 text-xs rounded text-grey-lightest whitespace-nowrap"
                :class="{
                  'bg-grey-light': isSubmitingFiles,
                  'bg-blue': !isSubmitingFiles,
                }"
              >
                {{ files.name }}
                <button
                  v-if="!isSubmitingFiles"
                  type="button"
                  @click="resetFile"
                  class="flex items-center ml-2 text-white rounded-full"
                  :class="{
                    'bg-blue-dark': !isSubmitingFiles,
                  }"
                  style="padding: 0.1rem"
                >
                  <b-icon pack="fas" icon="times" size="is-small" />
                </button>
              </span>

              <div v-if="canSetCodification && codificationOption == 1">
                <document-name-codification :document="files" :validableParentCode="validableParentCode" />
              </div>

            </div>
          </div>
        </div>

        <div v-if="entityId > 0 && (files.length > 0 || files.size > 0) && !onlyUploadFile">

          <b-checkbox v-if="notifyUsers" v-model="sendNotification" class="mb-2 text-left w-full">
              {{ $t('notify_participants_collaborators') }}
          </b-checkbox>

          <!-- Usuarios mencionados -->
          <select-users
            v-if="sendNotification"
            ref="select_users_new"
            :entityType="entityType"
            :entityId="entityId"
            roles="participant,collaborator,activity_viewer,activity_admin"
            placeholder="select_users_notify"
          />

          <!-- ETIQUETAS -->
          <div class="my-2 text-left">
            <model-tags
              :model-id="0"
              model-type="document"
              :allow-edit="false"
              :add-from-list="true"
              :parent-model-id="project.id"
              parent-model-type="project"
              ref="modelTags"
            />
          </div>
          <!-- Usuarios mencionados -->
          <mention-users
            ref="mention_users_new"
            :entityType="entityType"
            :entityId="entityId"
          />

          <b-select ref="input"
          v-model="selectedDocumentId"
          :placeholder="$t('is_attached_of') + ' (' + $t('optional') + ')'"
          class="mt-2"
          icon="cog"
          icon-pack="fas"
          expanded
          >
            <option :value="null">Sin seleccionar</option>
            <option v-for="document in documents" :value="document.document_id || document.id" :key="'attachdoc_'+document.document_id">
              {{ document.name }}
            </option>
          </b-select>


          <b-select ref="input"
          v-model="selectedType"
          :placeholder="$t('select_type')"
          class="mt-2"
          icon="cog"
          icon-pack="fas"
          expanded
          >
            <option v-for="type in docTypes" :value="type.id" :key="'type_'+type.id">
              {{ type.name }}
            </option>
          </b-select>

          <b-select ref="input"
          v-model="selectedStatus"
          :placeholder="$t('select_status')"
          class="mt-2"
          icon="cog"
          icon-pack="fas"
          expanded
          >
            <option v-for="status in docStatuses" :value="status.id" :key="'status_'+status.id">
              {{ status.name }}
            </option>
          </b-select>

          <b-select ref="input"
          v-model="selectedDiscipline"
          :placeholder="$t('select_discipline')"
          class="mt-2"
          icon="cog"
          icon-pack="fas"
          expanded
          >
            <option v-for="discipline in docDisciplines" :value="discipline.id" :key="'discipline_'+discipline.id">
              {{ discipline.name }}
            </option>
          </b-select>
        </div>

      </div>
      <footer
        class="flex items-center justify-end p-4 border-t bg-grey-lighter"
      >
        <template v-if="isSubmitingFiles">
          <!-- Progress bar -->
          <progress-bar
            :step="uploadPercentage"
            color="green"
            backgroundColor="grey-lightest"
            size="large"
            class="w-full mr-4"
          />
          <!-- Botón cancelar subida -->
          <button class="button is-warning" type="button" @click="cancelUpload">
            <b-icon pack="fas" icon="times" />
            <span>{{ $t("cancel") }}</span>
          </button>
        </template>
        <template v-else>
          <!-- Boton subir -->
          <button v-if="filesSelected && typeDocument !== 'models_bim'"
            class="mr-2 button is-primary"
            @click="
              localMultipleFiles ? onClickMultipleFiles() : onClickOneFile()
            "
          >
            <b-icon pack="fas" icon="upload" size="is-small" />
            <span>{{ $t("upload_documents") }}</span>
          </button>

          <button v-if="filesSelected && typeDocument === 'models_bim'"
            class="mr-2 button is-primary"
            @click="onClickUploadModel()"
          >
            <b-icon pack="fas" icon="upload" size="is-small" />
            <span>{{ $t("upload_documents") }}</span>
          </button>

          <!-- Botón cerrar -->
          <button class="button" type="button" @click="hide">
            {{ $t("close") }}
          </button>
        </template>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import MentionUsers from "·/components/mention-users";
import SelectUsers from "·/components/select-users";
import ModelTags from "./model-tags";
import { mapGetters } from "vuex";
import DocumentNameCodification from "·/components/document-name-codification";
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import store from "~/store";

export default {
  name: "upload-document",

  components: {
    MentionUsers,
    SelectUsers,
    ModelTags,
    DocumentNameCodification,
  },

  props: {
    uploadEndpoint: { type: String, required: true },
    allowMultipleFiles: { type: Boolean, default: false },
    entityId: { type: Number, default: 0 },
    entityType: { type: String, default: null },
    filterUploadType: {
      type: Object,
      default: () => ({ filter: false }),
      required: false,
    },
    selectedFolder: { type: Object, default: () => ({ }), required: false,},
    onlyUploadFile: { type: Boolean, default: false }, // para subir ficheros de planos, con campo 'code'
    typeDocument: { type: String, default: 'documents' },
    uploadFolders: { type: Boolean, default: false },
    notifyUsers: { type: Boolean, default: false },
    validableParentCode: { type: String, required: false, default: '' }, // para formato codificacion documentos "lean2" y "lean3" que van precedidos por codigo actividad padre
    isAttached: { type: Number, default: 0 },  // Documentos adjuntos (de momento solo para flujos)
    documents: { type: Array, default: () => ([]) }, // lista de documentos que se encuentran en la ruta actual (esto para cuando se relacione un documento a otro)
  },

  data: () => ({
    isModalActive: false,
    canCancel: ['escape', 'x', 'outside'],
    files: [],
    isSubmitingFiles: false,
    cancelSubmiting: false,
    uploadPercentage: 0,
    cancelRequest: null,
    types: {
      0: "application/*",
      1: "image/*",
      2: "audio/*",
      3: "video/*",
    },
    accept: "", // on created()
    codificationOption: 0, // opcion tab elegida (0 sin codi, 1 con codificacion nombre)
    localMultipleFiles: false,
    canSetCodification: false, // mostrar o no codificacion nombre fichero

    validate: false,
    messageError: null,
    additionalMessageError: '',

    uppy: null, // instancia de la libreria para subir archivos
    keyFileUploadCurrent: null, // key del archivo que se esta subiendo actualmente
    sendNotification: false,
    uploadedDocuments: [],
    docTypes: [],
    docStatuses: [],
    docDisciplines: [],
    selectedType: null,
    selectedStatus: null,
    selectedDiscipline: null,
    selectedDocumentId: null
  }),

  watch: {

    codificationOption(value) {
      this.files = []

      if (value == 0) {
        this.localMultipleFiles=true
      } else { // si vamos a codificar el nombre del fichero solo permitimos subir de 1 en 1
        this.localMultipleFiles=false
      }
    },

    validate(value) {
      for (var i = this.files.length - 1; i >= 0; i--) {
        if( (this.files[i].code && this.files[i].code != '') || (this.files[i].description && this.files[i].description != '') ) {
          this.validate = true;
        }else{
          this.validate = false;
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      project: "app/project",
      company: 'app/company',
    }),
    filesSelected() {
      return this.files.length > 0 || this.files.size > 0;
    },
  },

  created() {

    this.accept = this.$acceptedExtensions // extensiones aceptadas

    // asi trabajamos con una variable de este componente en lugar de una prop que no se deberia modificar
    this.localMultipleFiles = this.allowMultipleFiles
    // muestra form codificacion si el proyecto tiene algun tipo de formulario establecido
    this.canSetCodification = this.project.codification_form ? true : false

    // Establecemos los tipos de archivos que permitimos subir segun los parametros pasados
    if(this.filterUploadType.filter){
      this.accept = "";
      this.filterUploadType.types.forEach(type => {
        this.accept += this.types[type]+",";
      });
    }

    this.uppy = new Uppy({
        debug: process.env.NODE_ENV === 'development', // activamos debug para development
        autoProceed: false,
        allowMultipleUploads: false,
      })
      .use(AwsS3Multipart, {
        limit: 3,
        companionUrl: '/api/v2/',
        companionHeaders: {
          'Authorization': "Bearer " + store.getters["auth/token"]
        }
      })
      // .on('upload-success', (file, response) => { this.onUploadSuccess(file, response) } )
      .on('upload-success', (file, response) => { // callback deveulto cuando el file ha sido subido correctamente
        console.log('%cEPC-TACKER: '+ '%c file ' + file.data.name + ' subido correctamente', 'background: #5577BB; color: #fff', 'color: #000')
      })
      .on('upload-progress', (file, progesss) => { // callback devuelto con el progreso de subido del archivo
        this.uploadPercentage = parseInt(
          Math.round((progesss.bytesUploaded * 100) / progesss.bytesTotal)
        );
      })
      .on('upload-error', (file, error, response) => { // callback devuelto si ha ocurrido algun error en la subida
        console.error('%cEPC-TACKER: '+ '%c ha ocurrido un error al subir el archivo ' + file.data.name, 'background: #5577BB; color: #fff', 'color: #000', error)
      })
  },
  methods: {
    async onClickMultipleFiles() {

      if (this.files.length == 0) {
        return;
      }

      this.uploadedDocuments = []
      let files = this.files.slice();

      if( this.onlyUploadFile ) {
        let empty = false;

        for (var i = files.length - 1; i >= 0; i--) {
          if( !files[i].code && !files[i].description ) {
            empty = true;
            break;
          }
        }

        if( empty ) {
          this.$notify.error("required_fields");
          return
        }
      }

      this.isSubmitingFiles = true;
      this.cancelSubmiting = false;

      this.canCancel = false

      for (var i = files.length - 1; i >= 0; i--) {
        if (files[i].size / 1024 / 1024 > 4096) { // 4 GB por ahora. solo en galeria activity/issue
          this.$notify.warning("document_exceeded_max_size", {
            name: files[i].name,
          });
        } else if (files[i].size <= 0) { // menores o iguales a 0 por ahora consideramos que estan corruptos
          this.$notify.warning("document_corrupt_cero_size", {
            name: files[i].name,
          });
        } else {
          this.messageError = "upload-document_error"
          let ok = await this.submitFile(files[i]);

          // Si hemos pulsado cancelar
          if (this.cancelSubmiting) {
            this.$notify.warning("upload-document_cancelled");
            break;
          } else if (ok) {
            this.files.splice(i, 1);
            this.$notify.success("upload-document_success", {
              files: files[i].name,
            });
          } else {
            this.$notify.error(this.messageError);
            this.$buefy.dialog.alert({
              title: 'Error',
              message: 'El fichero ' + files[i].name + ' no se ha podido subir, por favor intentelo de nuevo <br><b>' 
                + this.$t(this.additionalMessageError)+'</b>',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            break;
          }
        }
      }
      this.cancelSubmiting = false;
      this.isSubmitingFiles = false;
      this.uploadPercentage = 0;
      // Todos los ficheros subidos
      if (this.files.length == 0) {
        if (this.uploadedDocuments.length) {
          // Enviamos notificaciones a participantes y colaboradores de la actividad
          await axios.post('/api/v2/activity/'+ this.entityId + '/notify-documents', {
            documents: this.uploadedDocuments,
            users: this.$refs.select_users_new.getSelectedIds()
          }, {})
          this.sendNotification = false
        }
        this.$notify.success("upload-document_success", {
          files: files.length,
        });
        this.$emit("success");
        this.hide();
      }
    },

    async onClickOneFile() {

      if (!this.filesSelected) {
        return;
      }

      this.uploadedDocuments = []
      this.isSubmitingFiles = true;
      this.cancelSubmiting = false;
      let file = this.files;
      if (file.size / 1024 / 1024 > 1024) {
        this.$notify.warning("document_exceeded_max_size", { name: file.name });
      } else {
        this.messageError = "upload-document_error"

        this.canCancel = false

        let ok = await this.submitFile(file);

        // Si hemos pulsado cancelar
        if (this.cancelSubmiting) {
          this.$notify.warning("upload-document_cancelled");
        } else if (ok) {
          this.resetFile();
          this.$notify.success("upload_version_success");
        } else {
          this.$notify.error(this.messageError);
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'El fichero no se ha podido subir, por favor intentelo de nuevo',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
      }
      this.cancelSubmiting = false;
      this.isSubmitingFiles = false;
      this.uploadPercentage = 0;
      // Todos los ficheros subidos
      if (!this.filesSelected) {
        if (this.uploadedDocuments.length) {
          // Enviamos notificaciones a participantes y colaboradores de la actividad
          await axios.post('/api/v2/activity/'+ this.entityId + '/notify-documents', {documents: this.uploadedDocuments}, {})
          this.sendNotification = false
        }
        this.$emit("success");
        this.hide();
      }
    },

    async submitFile(file) {

      this.uploadPercentage = 0;
      this.cancelRequest = axios.CancelToken.source();
      let form = new FormData();

      let fileName = file.desc_name || file.name
      fileName = fileName.trim()

      let customName = "";
      if (file.custom_name) customName = file.custom_name.trim();

      // Si el nombre personalizado después del trim tiene contenido usamos ese, y si no pasamos al del fichero
      form.append(
        "original_filename",
        customName != "" ? customName + this.getFileExtension(file) : fileName
      );

      // campo opcional en api, 'file_name' con nombre descriptivo
      if (file.desc_name) {
        form.append("file_name", fileName)
      }
      //else en backend se calcula este campo a partir de original_filename si no se introduce nada

      // campo opcional en api, 'file_path' con nombre descriptivo (esto hace referencia a si tiene ruta dentro de una carpeta subida)
      if (file.webkitRelativePath) {
        form.append("file_path", file.webkitRelativePath)
      }
      // campo opcional en api, 'file_code' con nombre descriptivo
      if (file.code) {
        form.append("file_code", file.code)
        form.append("is_drawing", this.typeDocument === 'drawings' ? 1 : 0)
      }

      // campo opcional en api, 'file_description' con nombre descriptivo
      if (file.description) {
        form.append("file_description", file.description)
      }

      // nombre generado por codificador
      if (file.coded_name == 1) {
        form.append("coded_name", 1)
      }

      // si hay carpeta seleccionada
      if (this.selectedFolder.id) {
        form.append("folder_id", this.selectedFolder.id)
      }

      // form.append("file", file);
      form.append("entity_type", this.entityType);
      form.append("entity_id", this.entityId);

      form.append("project_id", this.project.id)

      // Si existe el componente, enviamos los ids de las menciones realizadas
      if (this.$refs.hasOwnProperty("mention_users_new")) {
        form.append(
          "mentions_ids",
          this.$refs.mention_users_new.getMentionsIds()
        );
      }
      // Si existe el componente, enviamos las tags
      if (this.$refs.hasOwnProperty("modelTags")) {
        form.append("tags", this.$refs.modelTags.getJsonTags());
      }

      return this.store(file, {})
        .then(async (result) => { // result contiene dos matrices successFul & failed

          // successFul contiene toda la lista de archivos que se han subido
          // para nuestro caso solo consideramos siempre el primero por que enviamos de a uno
          if( result.successful.length > 0 ) {
            let response = result.successful[0].response

            let params = {
              // uuid: response.uuid,
              key: response.body.key,
              // bucket: response.bucket,
              // name: file.name,
              content_type: file.type,
              // url: response.url
            }

            form.append("aws_response", JSON.stringify(params));

            form.append('is_attached', this.isAttached || this.selectedDocumentId ? 1 : 0)
            form.append('entity_attached_type', this.selectedDocumentId ? 'document':'validation')
            form.append('entity_attached_id', this.selectedDocumentId || this.entityId)
            if (this.selectedType) {
              form.append('document_type', this.selectedType)
            }
            if (this.selectedStatus) {
              form.append('document_status', this.selectedStatus)
            }
            if (this.selectedDiscipline) {
              form.append('document_discipline', this.selectedDiscipline)
            }

            let resp = null
            resp = await axios.post(this.uploadEndpoint, form, {
                cancelToken: this.cancelRequest.token,
            })
            if (resp?.data?.document && this.sendNotification && this.entityType == 'activity') {
              this.uploadedDocuments.push(resp.data.document.id)
            }

            // para poder trasladar arriba los mensajes de error los metemos en additionalMessagesError
            if (resp && resp.data && resp.data.msg) {
              this.additionalMessageError = resp.data.msg
            }
            return resp && resp.data && resp.data.success ? resp.data.success : false
          }

          // failed contiene todos los archivos que tubieron fallo al subir
          if (result.failed.length > 0) {
            console.error('Errors:')
            result.failed.forEach((file) => {
              console.error(file.error)
            })

            return false
          }
        }).catch((error) => {
          console.log('fail #ff55b: ',error)
          return false;
        });
    },

    // CUSTOM VAPOR STORE METHOD
    async store(file, options = null) {
      // verificamos si existe algun archivo en la lista de carga de uppy
      // si existe lo eliminamos
      if( this.keyFileUploadCurrent ) {
        this.uppy.removeFile(this.keyFileUploadCurrent);
      }

      this.keyFileUploadCurrent = this.uppy.addFile({
        name: file.name, // file name
        type: file.type, // file type
        data: file, // file blob
        // meta: {
        //   // optional, store the directory path of a file so Uppy can tell identical files in different directories apart.
        //   relativePath: webkitFileSystemEntry.relativePath,
        // },
        source: 'Local',
        isRemote: false,
      });

      return this.uppy.upload()
    },

    async onUploadSuccess(file, response) {

    },

    cancelUpload() {
      this.cancelSubmiting = true;
      this.cancelRequest.cancel("cancelled");
      this.uppy.cancelAll() // cancelamos la subida de todos los archivos
      this.canCancel = ['escape', 'x', 'outside']
    },

    show() {
      this.isModalActive = true;
      this.getSelectables()
    },
    hide() {
      this.files = [];
      this.canCancel = ['escape', 'x', 'outside']
      this.isModalActive = false;
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },

    mentionLabel(user) {
      return user.fullname;
    },

    resetFile() {
      this.files = [];
    },

    getFileName(file) {
      return file.name ? file.name.replace(/\.[^/.]+$/, "") : '';
    },

    getFileExtension(file) {
      let re = /(?:\.([^.]+))?$/;
      return "." + re.exec(file.name)[1];
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    // Metodos exclusivos para tratamiento de subida de modelos bim
    async onClickUploadModel() {
      console.log("Upload model BIM")
      if (!this.filesSelected) {
        return;
      }

      if( this.onlyUploadFile ) {
        let empty = false;
        let files = this.files
        for (var i = files.length - 1; i >= 0; i--) {
          if( !files[i].code && !files[i].description ) {
            empty = true;
            break;
          }
        }

        if( empty ) {
          this.$notify.error("required_fields");
          return
        }
      }

      this.isSubmitingFiles = true;
      this.cancelSubmiting = false;
      let file = this.files;
      if (file.size / 1024 / 1024 > 2048) {
        this.$notify.warning("document_exceeded_max_size", { name: file.name });
      } else {
        this.messageError = "upload-document_error"

        this.canCancel = false

        let ok = await this.submitFileModel(file);

        // Si hemos pulsado cancelar
        if (this.cancelSubmiting) {
          this.$notify.warning("upload-document_cancelled");
        } else if (ok) {
          this.resetFile();
          this.$notify.success("upload_version_success");
        } else {
          this.$notify.error(this.messageError);
          this.$buefy.dialog.alert({
            title: 'Error',
            message: 'El fichero no se ha podido subir, por favor intentelo de nuevo',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
      }
      this.cancelSubmiting = false;
      this.isSubmitingFiles = false;
      this.uploadPercentage = 0;
      // Todos los ficheros subidos
      if (!this.filesSelected) {
        this.$emit("success");
        this.hide();
      }
    },

    async submitFileModel(file, signed) {
      this.uploadPercentage = 0;
      this.cancelRequest = axios.CancelToken.source();
      let form = new FormData();

      // form.append("file", file[0]);
      form.append("code", file[0].code);
      form.append("description", file[0].description);
      form.append("original_filename", file[0].name);

      return this.storeModel(file)
        .then(async (response) => {

          if( response !== false ) {

            form.append("urn", response.object_id);

            let resp = await axios.post('/api/v2/project/' + this.project.id + '/document-bim', form, {
                cancelToken: this.cancelRequest.token,
            })

            if( resp && resp.data && resp.data.success ) {
              let respTranslate = await this.translate(resp.data.data.id)

              // TODO controlar todos los posibles stados de la traducción del modelo si esque tubiera
              if ( respTranslate.result === 'success') {
                return true
              }
              this.$notify.error('Fallo la traducción del modelo')
            }

            return false
          }

          // se a producido un error
          return false

        }).catch((error) => {
          console.log('fail #ff55b: ',error)
          return false;
        });


    },

    async submitFileModelFromServer(file) {
      this.uploadPercentage = 0;
      this.cancelRequest = axios.CancelToken.source();
      let form = new FormData();

      // let fileName = file.desc_name || file.name
      // fileName = fileName.trim()

      form.append("project_id", this.project.id);
      form.append("model", file[0]);

      let resp = null

      resp = await axios.post('/api/v2/autodesk/forge/uploadModel', form, {
          cancelToken: this.cancelRequest.token,
      })

      return resp && resp.data && resp.data.success ? resp.data.success : false
    },

    async getSignedsUpload(file) {
      let params = {
        project_id: this.project.id,
        object_key: file[0].name
      }
      const {data} = await axios.get('/api/v2/autodesk/forge/getSigned3Upload', { params: params })
        if (data && data.success) {
          return data.data
        } else {
          this.$notify.error('Fallo signed3upload')
        }
    },

    async completeSignedsUpload(file, signed) {
      let params = {
        project_id: this.project.id,
        object_key: file[0].name,
        upload_key: signed.upload_key
      }
      const {data} = await axios.post('/api/v2/autodesk/forge/completeSigned3Upload', params)
        if (data && data.success) {
          return data.data
        } else {
          this.$notify.error('Fallo completesigneds3upload')
        }
    },

    async translate(id) {
      let params = {
        bim_id: id,
      }
      const {data} = await axios.post('/api/v2/autodesk/forge/translate-model', params)
        if (data && data.success) {
          console.log( 'translate model: ', data )
          return data.data
        } else {
          this.$notify.error('Fallo tanslate model')
        }
    },

    async storeModel(file) {
      let signed3 = await this.getSignedsUpload(file)

      var instance = axios.create();
      instance.defaults.headers.common = {};
      const uploadBucket = await instance.put(signed3.url, file[0], {
          headers: { "Content-Type": "application/octet-stream" },
          onUploadProgress: (progressEvent) => {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          }
      });

      if( uploadBucket.status === 200 ) {
        let completeSigned = await this.completeSignedsUpload(file, signed3)
        console.log('completeSigned: ',completeSigned)
        return completeSigned
      }

      return false
    },

    async futch(url, opts={}, onProgress) {
      return new Promise( (res, rej)=>{
          var xhr = new XMLHttpRequest();
          xhr.open(opts.method || 'get', url);
          for (var k in opts.headers||{})
              xhr.setRequestHeader(k, opts.headers[k]);
          xhr.onload = e => res(e.target.responseText);
          xhr.onerror = rej;
          if (xhr.upload && onProgress)
              xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
          xhr.send(opts.body);
      });
    },

    // fin metodos exclusivos metodos bim
    async getSelectables() {
      let types = await axios.get('/api/v2/document-types', {params: {p: this.project.id}});

      if (types.data.success) {
        this.docTypes = types.data.data
      }

      let statuses = await axios.get('/api/v2/document-statuses', {params: {p: this.project.id}});

      if (statuses.data.success) {
        this.docStatuses = statuses.data.data
      }

      let disciplines = await axios.get('/api/v2/document-disciplines', {params: {p: this.project.id}});

      if (disciplines.data.success) {
        this.docDisciplines = disciplines.data.data
      }
    },
  },
};
</script>

<style scoped lang="scss">
.input {
  &.extension {
    width: 4em;
  }

  &.required {
    --tw-border-opacity: 0.8;
    border-color: rgb(225 29 72/var(--tw-border-opacity));
    border-width: .14em;
    // border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='10' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}
</style>
