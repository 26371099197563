<template>
  <div
    :ref="'konva_tooltip'"
    :style="{
      position: 'absolute',
      top: position.y + 2 + 'px',
      left: position.x + 2 + 'px',
      padding: '5px',
      borderRadius: '3px',
      boxShadow: '0 0 5px grey',
      zIndex: 10,
      backgroundColor: '#5577BB',
      minWidth: '210px'
    }"
  >
    <div class="text-white font-bold p-1 mb-0 border-b border-solid border-slate-100 uppercase text-sm">
      {{ $t( dataTooltip.type && dataTooltip.type.endsWith('s') ? dataTooltip.type.slice(0, -1) : dataTooltip.type ) }} : {{ dataTooltip.name }}
    </div>
    <div class="text-white p-1 text-sm">
      <div v-if="dataTooltip.status">
          {{ $t('status') }}: <span :style="{ color: colorStatus }">
          <b-icon icon="traffic-light" pack="fas"></b-icon></span> {{ nameStatus }}</div>
      <div v-else>{{ $t('status') }}: <b-icon icon="traffic-light" pack="fas"></b-icon> Sin estado</div>
      <!-- <div>{{ $t('progress') }}: {{ dataTooltip.progress + '%' }} </div> -->
    </div>
  </div>
</template>

<script>


/** Events: all DxfViewer supported events (see DxfViewer.Subscribe()), prefixed with "dxf-". */
export default {
    name: "KonvaViewerTooltip",

    props: {
        position: { type: Object, required: true },
        statuses: { type: Array, required: true },
        dataTooltip: { type: Object, default() { return { } }},
        onClose: Function,
    },

    data() {
        return {
          nameStatus: null,
          colorStatus: '#ffffff',
        }
    },

    computed: {
    },

    watch: {
      dataTooltip(newVal, oldVal) {
        if( newVal.status ) {
          let id = typeof newVal.status === 'object' ? newVal.status.id : newVal.status;

          let status = this.statuses.find( (item) => { return item.id == id } )
          this.nameStatus = status ? status.name : 'undefined'
          this.colorStatus = status ? status.color : '#ffffff'
        }
      }
    },

    created() {

    },

    mounted() {
      window.addEventListener("click", this.onClick);
    },

    methods: {
      onClick(e) {
        if (!this.isClickedInside(e, this.$refs.konva_popup)) {
          this.onClose();
        }
      },

      isClickedInside(e, element) {
        let node = e.target;
        while (node) {
          if (node === element) {
            return true;
          }
          node = node.parentNode;
        }
        return false;
      }
    },

    destroyed() {
      window.removeEventListener("click", this.onClick);
    },
}
</script>

<style scoped>


</style>
