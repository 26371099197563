<template>
  <div>
    <div class="w-full flex justify-end">
      <div class="flex">
        <v-button @click="create = !create; 
          providerCreate.id = 0;
          providerCreate.name = '';
          providerCreate.cif = '';
          providerCreate.date = '';
          providerCreate.code = '';
          providerCreate.material_use = false;"
          class="btn btn-blue rounded" icon="plus">{{ $t('add_supplier') }}
      </v-button>
      </div>
    </div>
    <div class="panel" v-if="create || providerCreate.id">
      <div>

        <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('create_provider') }}</h2>
        <form @submit.prevent="createProvider"> 
        <div class="w-full justify inline-flex">
          <b-field :label="$t('name')" class="md:w-1/5 mr-4">
            <b-input v-model="providerCreate.name" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('cif')" class="md:w-1/5 mr-4">
            <b-input v-model="providerCreate.cif" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('code')" class="md:w-1/5 mr-4">
            <b-input v-model="providerCreate.code" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('date')" class="md:w-1/5 mr-4">
            <b-datepicker 
            v-model="providerCreate.date"
            :first-day-of-week="1"
            :placeholder="$t('click_to_select')">
            <button class="button is-primary"
                @click="providerCreate.date = new Date()">
                <b-icon icon="calendar"></b-icon>
                <span>{{ $t('today') }}</span>
            </button>
          </b-datepicker>
          </b-field>
          <b-field :label="$t('material_use')" class="md:w-1/5 mr-4">
            <b-switch v-model="providerCreate.material_use">
            </b-switch>
          </b-field>
          <b-field label="  " class="md:w-1/5 mr-4 mt-4"> 
            <v-button class="btn btn-primary" nativeType="submit" icon="save" :disabled="!providerCreate.name || !providerCreate.code || !providerCreate.cif">{{ $t('save') }}</v-button>
          </b-field>
        </div>
      </form>
      </div>
    </div>
    <div class="panel">
      <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('provider_list') }}</h2>
      <div class="panel">  
        <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('filter') }}</h2>
        <form @submit.prevent="filterProvider"> 
          <div class="w-full justify inline-flex">
            <b-field :label="$t('name')" class="md:w-1/5 mr-4">
              <b-input v-model="providerFilter.name" class="w-full"></b-input>
            </b-field>
            <b-field :label="$t('cif')" class="md:w-1/5 mr-4">
              <b-input v-model="providerFilter.cif" class="w-full"></b-input>
            </b-field>
            <b-field :label="$t('code')" class="md:w-1/5 mr-4">
              <b-input v-model="providerFilter.code" class="w-full"></b-input>
            </b-field>
            <b-field :label="$t('material_use')" class="md:w-1/5 mr-4">
              <b-input v-model="providerFilter.material_use" class="w-full"></b-input>
            </b-field>
            <b-field label="  " class="md:w-1/5 mr-4 mt-4"> 
              <v-button class="btn btn-primary" nativeType="submit"><b-icon icon="search"/></v-button>
            </b-field>
          </div>
        </form>
      </div>
      
      <div class="w-full flex flex-wrap mt-4">   
        <section class="w-full">
          <b-table
          :data="providers"
          :paginated="isPaginated"
          :per-page="perPage"
          :bordered="true"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :default-sort-direction="defaultSortDirection"
          v-if="loaded">
          <template slot-scope="props">
            <b-table-column field="name" :label="$t('name')" sortable width=100>
              {{ props.row.name }}
            </b-table-column>

            <b-table-column field="cif" :label="$t('cif')" sortable>
              {{ props.row.CIF }}
            </b-table-column>

            <b-table-column field="code" :label="$t('code')" sortable >
              {{ props.row.code }}
            </b-table-column>

            <b-table-column field="date" :label="$t('date')" sortable>
              {{ props.row.date }}
            </b-table-column>

            <b-table-column field="material_use" :label="$t('material_use')" sortable width=100>
              {{ props.row.material_use }}
            </b-table-column>

            <b-table-column field="actions" :label="$t('actions')" sortable width=100 >
              <div class="justify inline flex">
                <button @click="getProvider(props.row.id)"><b-icon icon="pencil-alt" type="is-primary" /></button>
              </div>
            </b-table-column>
          </template>
        </b-table>
      </section>
    </div>
  </div>
</div>
</template>

<script>
import Form from 'vform'
import axios from 'axios'

export default {
  middleware: 'auth',
  title: 'users_page_title',
  subtitle: 'users_page_subtitle',
  menuOption: '0-3',

  data: () => ({
    project_id: 0,
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 5,
    providers: [],
    loaded: false,
    projects: null,
    create: false,

    providerFilter: new Form ({
      name: '',
      code: '',
      material_use: '',
    }),
    providerCreate: new Form ({
      id: 0,
      name: '',
      date: '',
      code: '',
      material_use: '',
    }),
  }),

  mounted () {
    this.project_id = parseInt(this.$route.params.project_id)
    this.getProviders()
  },

  methods: {

    async filterProvider () {
      if (! this.providerFilter) {
        return;
      }
      console.log(this.providerFilter)
      const url = '/api/v2/report/provider/' + this.project_id + '/filter'
      const { data } = await this.providerFilter.post(url)
      if (data.data && data.success) {
        this.providers = data.data
      }
    },

    async createProvider () {
      if (! this.providerCreate) {
        return;
      }
      var url
      if(this.providerCreate.id){
        url = '/api/v2/report/provider/' + this.providerCreate.id 
      }else{
        url = '/api/v2/report/provider/' + this.project_id + '/add'
      }

      var d = new Date(this.providerCreate.date)
      var month =  d.getMonth() + 1
      var day =  d.getDate()
      var year = d.getFullYear()

      this.providerCreate.date = year + "-" + month + "-" + day
      const { data } = await this.providerCreate.post(url)
      if (data.data && data.success) {
        this.providers = data.data
        this.providerCreate.name = ''
        this.providerCreate.code = ''
        this.providerCreate.date = ''
        this.providerCreate.material_use = ''
        this.providerCreate.cif = ''
        if(this.providerCreate.id){
          this.getProviders()
          // Hago esto pq extiende de la clase padre y no me retorna la lista completa de nuevo.
        }
        this.providerCreate.id = 0
        this.success('provider_save')

      }else{
        this.error('error_creating_provider')

      }
    },

    async getProviders(){
      const url = '/api/v2/report/provider/' + this.project_id + '/list'
      const { data } = await axios.get(url)
      if (data.data && data.success) {
        this.providers = data.data
        this.loaded = true
      } else {
        this.error('error_retreiving_providers')

      }
    },

    async getProvider(id){
      const url = '/api/v2/report/provider/' + id
      const { data } = await axios.get(url)
      if (data) {
        //Object.assign(this.providerCreate,data.data)
        this.providerCreate.id = id
        this.providerCreate.name = data.name
        this.providerCreate.cif = data.CIF
        this.providerCreate.code = data.code

        this.providerCreate.date = new Date(data.date)
        this.providerCreate.material_use = !!data.material_use

      } else {
        this.error('error_retreiving_provider')
      }
    },

    /*
     ALERTAS
     */
     success(message){
      this.$toast.open({
        message: message,
        type: 'is-success',
        position: 'is-top-right'
      })
    },
    error(message){
      this.$toast.open({
        message: message,
        type: 'is-danger',
        position: 'is-top-right'
      })
    }
  },
  watch:{

  }

};
</script>
