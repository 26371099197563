<template>
  <div class="w-1/3 viewer-right-panel">
    <div class="header relative w-full">
      <span class="m-3 block">Captura de pantalla</span>
      <div class="viewer-panel__close-btn" @click="() => { $emit('epc-close', true) }">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="ga-left-panel-close"><g data-name="<Group>"><path d="M7.31 17.5a.5.5 0 0 1-.31-.85l10-10a.5.5 0 0 1 .71.71l-10 10a.5.5 0 0 1-.4.14z"></path><path d="M17.31 17.5a.5.5 0 0 1-.31-.15l-10-10a.5.5 0 0 1 .71-.71l10 10a.5.5 0 0 1-.4.86z"></path></g></svg>
      </div>
    </div>
    <div class="h-3" style="background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);"></div>
    <div class="bg-white">
      <div class="m-3">
        <b-icon icon="images" pack="fas"></b-icon>
        <span>Imagen del diseño</span>
      </div>

      <div class="m-3">
        <div>
          <div class="screenshot-container">
            <img :src="screenshot" alt="" class="img_screenshot">
          </div>
          <div class="fields-container text-right">
            <b-button size="is-small"
              @click="_downloadScreenshot">{{ $t('download') }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "ViewerPanelScreenshot",

    props: {
      views: { type: Array, default: [] },
      screenshot: { type: String, default: null },
      workLayer: { type: String, default: null }
    },

    watch: {
    },

    data() {
      return {

      }
    },

    methods: {
      getScreenShot() {
        if (this.screenshot && -1 === this.screenshot.indexOf("blob:"))
          return this.screenshot
        else {
          return this._getBase64Image().then(img)
        }
      },

      Base64ToBlob(base64String, contentType) {
        contentType = contentType || '';
        var sliceSize = 1024;
        var byteCharacters = atob(base64String);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);
          var byteNumbers = new Array(slice.length);

          for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          var byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
      },

      _getBase64Image() {
        const img = document.createElement("img");
        return new Promise((resolve, reject)=>{
          img.src = this.screenshot,
          img.addEventListener("load", ()=>{
              const canva = document.createElement("canvas")
              canva.width = img.width,
              canva.height = img.height;
              canva.getContext("2d").drawImage(img, 0, 0, img.width, img.height);
              resolve(canva.toDataURL("image/png"))
          })

          img.addEventListener("error", ()=>{
              reject()
          })
        })
      },

      _GetIcon(layer) {
        return layer.icon ?? 'grip-horizontal' // 'images'
      },

      _HandleClick(lyr) {
        if ( !this.workLayer )
          this.$emit('epc-view-selected', lyr.code)
      },

      _downloadScreenshot() {
        let dataImage = this.screenshot.replace(/^data:image\/[^;]*/, "data:application/octet-stream")
        const encoder = encodeURIComponent('Content-Disposition: attachment; filename="'.concat(this.workLayer+'.png', '"'));
        dataImage = dataImage.replace(/^data:application\/octet-stream/, "data:application/octet-stream;headers=".concat(encoder));

        let link = document.createElement("a");
        if ("Safari" === this._SaysWho()[0])
          link.target = "_blank"
        link.href = dataImage;
        link.download = this.workLayer+'.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },

      _SaysWho() {
        const ua = navigator.userAgent;
        let temp;
        if (ua.toLowerCase().indexOf("edge") > -1)
          return ["MSIE", "Edge"];

        let n = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(n[1])) {
          temp = /\brv[ :]+(\d+)/g.exec(e) || []
          return ["MSIE", temp[1] || ""]
        }

        if ("Chrome" === n[1]) {
          temp = ua.match(/\bOPR\/(\d+)/)
          if (temp != null)
            // return tem.slice(1).join(' ').replace('OPR', 'Opera')
            return ["Opera", temp[1]]
        }

        n = n[2] ? [n[1], n[2]] : [navigator.appName, navigator.appVersion, "-?"];
        temp = ua.match(/version\/(\d+)/i);

        if(temp != null)
          n.splice(1, 1, temp[1]);

        return n.join(' ')
      }

    }
  }

</script>

<style scoped>

  .active {
    border: 1px solid #5577bb;
    color: #5577bb;
  }
  .viewer-right-panel {
    border-left: 1px solid #d4dbe1;
    border-top: 1px solid #d4dbe1;
    background: linear-gradient(to top, #eeeeee 0, #f7f7f7 8px, #fafafa 12px, #fff 20px);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.3);
  }
  .viewer-panel__close-btn {
      position: absolute;
      top: 0px;
      right: 5px;
      float: none;
      fill: #939ca5;
      cursor: pointer;
      display: block;
      z-index: 1;
      /* width: 40px; */
      /* height: 40px; */
  }

  .screenshot-container {
    width: 100%;
    border: 1px solid #d4dbe1;
    text-align: center;
    margin-bottom: 15px;
    border-radius: 4px;
    min-height: 50px;
    position: relative;
  }
  .img_screenshot {
    max-height: 150px;
    max-width: 100%;
    width: auto;
    border: 0;
    margin: 0;
    border-radius: 0;
  }

</style>
