<template>
  <div>
    <div v-if="isLoading" class="text-center my-4">
      <scale-loader></scale-loader>
    </div>
    <div v-else>
      <div class="panel" v-if="ready">
        <form @submit.prevent="updateUser">
          <div class="w-full inline-flex">
            <div class="w-full md:w-1/4 p-8">
              <img :src="formChangeUser.avatar" class="w-full" v-if="formChangeUser.avatar">
              <input
                type="file"
                name="avatar"
                id="avatar_id"
                class="w-full"
                @change="selectFile"
              />
            </div>
            <div class="w-full md:w-3/4">
              <div class="w-full flex justify-end">
                <div class="flex">
                  <v-switch
                    :label="$t('active_account')"
                    :color="formChangeUser.active ? 'green' : 'red'"
                    :value="formChangeUser.active"
                    v-on:checked="updateActive"
                    v-if="userId !== user.id"
                  />
                </div>
              </div>
              <div class="w-full inline-flex mt-4 pb-4 border-grey-light border-b border-dashed">
                <div class="w-full md:w-1/2 inline-flex pl-4">
                  <label form="name_id" class="md:w-1/4">{{ $t('name')}}</label>
                  <v-input
                    title="name"
                    id="name_id"
                    required="true"
                    v-model="formChangeUser.name"
                    name="name"
                    placeholder="name"
                    class="md:w-3/4"
                  />
                </div>
                <div class="w-full md:w-1/2 inline-flex pl-4">
                  <label form="surname_id" class="md:w-1/4">{{ $t('surname')}}</label>
                  <v-input
                    title="surname"
                    id="surname_id"
                    required="true"
                    v-model="formChangeUser.surname"
                    name="surname"
                    placeholder="surname"
                    class="md:w-3/4"
                  />
                </div>

              </div>
              <div class="w-full inline-flex mt-4 pb-4 border-grey-light border-b border-dashed">
                <div class="w-full md:w-1/2 inline-flex pl-4">
                  <label form="password" class="md:w-1/4">{{ $t('password')}}</label>
                  <v-input
                    v-model="password"
                    title="password"
                    id="password_id"
                    type="password"
                    :required="!userId"
                    name="password"
                    placeholder="password"
                    class="md:w-3/4"
                  />
                </div>
                <div class="w-full md:w-1/2 inline-flex pl-4">
                  <label form="repeat_id" class="md:w-1/4">{{ $t('repeat_password')}}</label>
                  <v-input
                    v-model="repeat"
                    title="repeat"
                    id="repeat_id"
                    name="repeat"
                    type="password"
                    placeholder="repeat_password"
                    class="md:w-3/4"
                  />
                </div>
              </div>
              <div class="w-full inline-flex  mt-4 pb-4 border-grey-light border-b border-dashed">
                <div class="w-full md:w-1/2 inline-flex pl-4">
                  <label form="email_id" class="md:w-1/4">{{ $t('email')}}</label>
                  <v-input
                    v-model="formChangeUser.email"
                    title="email"
                    required="true"
                    type="email"
                    id="email_id"
                    name="email"
                    placeholder="email"
                    class="md:w-3/4"
                  />
                </div>

                <div class="w-full md:w-1/2 inline-flex pl-4">
                  <label form="phone_id" class="md:w-1/4">{{ $t('phone')}}</label>
                  <v-input
                    v-model="formChangeUser.phone"
                    title="phone"
                    type="number"
                    id="phone_id"
                    name="phone"
                    placeholder="phone"
                    class="md:w-3/4"
                  />
                </div>
              </div>
              <div class="w-full inline-flex  mt-4 pb-4 border-grey-light border-b border-dashed">

                <div class="w-full md:w-1/2 inline-flex pl-4">
                  <label form="area_id" class="md:w-1/4">{{ $t('area')}}</label>
                  <v-select
                    title="area_title"
                    select-name="area_name"
                    select-id="area_id"
                    :selectedValue="parseInt(formChangeUser.area_id)"
                    :data="areas"
                    v-on:updateValue="updateArea"
                    class="md:w-3/4"/>
                </div>

                <div class="w-full md:w-1/2 inline-flex pl-4">
                  <label form="user_locale" class="md:w-1/4">{{ $t('locale')}}</label>
                    <v-radio
                      id="es_radio"
                      class="px-2"
                      name="locale_radio"
                      value="es"
                      v-model="formChangeUser.locale"
                      :checked="formChangeUser.locale == 'es'"
                      >
                      {{ $t('spanish') }}
                    </v-radio>

                    <v-radio
                      id="en_radio"
                      name="locale_radio"
                      value="en"
                      v-model="formChangeUser.locale"
                      :checked="formChangeUser.locale == 'en'"
                      >
                      {{ $t('english') }}
                    </v-radio>
                </div>

              </div>
              <div class="w-full inline-flex  mt-4 pb-4 border-grey-light border-b border-dashed">

                <div class="w-full md:w-1/2 inline-flex pl-4">
                  <label form="daily_reminders" class="md:w-1/4">{{ $t('daily_reminders')}}</label>
                  <v-switch
                    ref="daily_reminders"
                    v-model="formChangeUser.reminders"
                    :label="$t('daily_reminders_msg')"
                    :color="formChangeUser.reminders ? 'green' : 'red'"
                    :value="formChangeUser.reminders"
                    v-on:checked="updateReminders"
                  />
                </div>
                <div class="flex">


                </div>

              </div>
              <div class="w-full inline-flex flex justify-end mt-16">
                <template v-if="password != repeat" class="text-red mr-2">
                  {{$t('password_not_match')}}
                </template>
                <button
                  v-if="userType == 'collaborator' && userId"
                  class="btn btn-red ml-2"
                  @click="removeCollaborator"
                  type="button"
                >
                  {{$t("convert_to_participant")}}
                </button>

                <button class="btn btn-blue ml-2"
                        nativeType="submit"
                        :disabled="password != repeat"
                >
                  {{$t('save')}}
                </button>
                <a @click="$router.go(-1)" class="btn btn-light ml-2">
                  {{$t('cancel')}}
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="panel" v-if="ready">
        <form @submit.prevent="saveConfiguration">
          <h1 class="font-bold">Notificaciones</h1>
          <div>
            <b>{{$t('company_name')}}: </b> {{company.name}}
          </div>
          <div class="w-full mt-2">
              <div
                v-for="(notifitem, index) in listNotifitems" 
                class="w-full mt-4 pb-4 border-grey-light border-b border-dashed">

                <div class="w-full inline-flex pl-4">
                  <label class="mr-3 w-1/4">{{$t(notifitem.name)}}</label>
                  <div v-if="notifitem.code == 'grvf'" class="w-3/4">
                    <div class="flex items-center">

                      <b-select v-model="templateCode" :placeholder="$t('select_validation_flow')" expanded class="mt-2 mr-2" style="width: 31%">
                        <option 
                          v-for="template in listTemplates" 
                          :value="template.code"
                        >
                          {{ template.name }}
                        </option>
                      </b-select>
                      
                      <b-select v-model="taskCode" :placeholder="$t('select_v_task')" expanded class="mt-2 mr-2" style="width: 31%">
                        <option 
                          v-for="task in listTasks" 
                          :value="task.code"
                        >
                          {{ task.name }}
                        </option>
                      </b-select>
                      
                      <b-select v-model="groupCode" :placeholder="$t('select_group_menu')" expanded class="mt-2 mr-2" style="width: 31%">
                        <option v-for="group in listGroups" :value="group.group.code">
                          {{ group.group.name }}
                        </option>
                      </b-select>
                      
                      <div class="rounded-full cursor-pointer flex" style="background: #beb" @click="addGroupConfiguration(index)">
                        <b-icon pack="fas" icon="plus" type="is-success" size="is-medium" />
                      </div>

                    </div>
                    <div class="w-full">
                      <div v-for="(groupConf, groupIndex) in notifitem.configs" class="mt-2 p-1 rounded-full flex justify-around" style="background: #eee;">
                        <small class="rounded-lg text-center inline-block" style="background: #fffa; width: 31%">
                           <b>{{$t('flow')}}: </b>{{groupConf.template_code}}
                        </small>
                        <small class="rounded-lg text-center inline-block" style="background: #fffa; width: 31%">
                           <b>{{$t('task')}}: </b>{{groupConf.task_code}}
                        </small>
                        <small class="rounded-lg text-center inline-block" style="background: #fffa; width: 31%">
                           <b>{{$t('group')}}: </b>{{groupConf.group_code}}
                        </small>
                        <div class="ml-1 rounded-full cursor-pointer inline-flex self-center" style="background: #ebb; padding: 2px" @click="deleteGroupConfiguration(groupIndex, index)">
                          <b-icon pack="fas" icon="times" type="is-danger" size="is-small" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="w-3/4">
                    <v-switch
                      v-model="notifitem.value"
                      :label="' '"
                      :color="notifitem.value ? 'green':'red'"
                      :value="notifitem.value"
                      v-on:checked="notifitem.value = !notifitem.value"
                    />
                  </div>
                </div>

              </div>
              <div class="w-full inline-flex flex justify-end mt-16">

                <button class="btn btn-blue ml-2"
                    nativeType="submit"
                >
                  {{$t('save')}}
                </button>
                <a @click="$router.go(-1)" class="btn btn-light ml-2">
                  {{$t('cancel')}}
                </a>
              </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from 'vform'
  import axios from 'axios'
  import store from '~/store'
  import api from '~/api'
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
  import {mapGetters} from 'vuex'

  export default {
    middleware: 'auth',
    // title: 'userform_page_title',
    // subtitle: 'userform_page_subtitle',
    menuOption: '0-2',

    components: {
      ScaleLoader
    },

    data: () => ({
      userType: '',
      areas: [],
      password: '',
      repeat: '',
      avatar: '',
      formChangeUser: new Form({
        name: '',
        surname: '',
        password: '',
        email: '',
        area_id: 0,
        locale: '',
        phone: '',
        avatar: null,
        active: true,
        reminders: false,
      }),
      ready: false,
      userId: 0,
      isLoading: false,
      listNotifitems: [],
      listTemplates: [],
      listTasks: [],
      listGroups: [],
      templateCode: null,
      taskCode: null,
      groupCode: null,
    }),

    computed: {
      ...mapGetters({
        user: 'auth/user',
        company: 'app/company'
      }),
    },

    watch: {
      templateCode: async function () {
        if (this.templateCode) {
          await this.changeTemplate(this.templateCode)
        }
      },

      taskCode: async function () {
        if (this.taskCode) {
          await this.changeTask(this.taskCode)
        }
      }
    },

    mounted() {
      let self = this

      // Capturamos la id del usuario si existe
      this.userId = store.state.route.params.id;

      // Obtenemos por la ruta si es usuario o colaborador
      let pathPieces = store.state.route.path.split('/');
      this.userType = pathPieces[pathPieces.length - 2]

      if (this.userType === 'collaborator') {
        this.formChangeUser.collaborator = true;
        this.$store.dispatch('app/updatePageInfo', {
          info: {
            title: this.$t('collaboratorform_page_title'),
            subtitle: this.$t('collaboratorform_page_subtitle'),
          }
        })
      } else {
        this.formChangeUser.collaborator = false;
        this.$store.dispatch('app/updatePageInfo', {
          info: {
            title: this.$t('userform_page_title'),
            subtitle: this.$t('userform_page_subtitle'),
          }
        })
      }
      api.fetchUserAreas({roles: ['area_admin']})
        .then(data => {
          self.areas = data.areas;
          self.areas.unshift({id: 0, name: self.$t('select')})
        })
        .catch(error => {
          self.$notify.error('error_retreving_areas')
        });

      this.loadTemplates()
      this.loadNotifitems()
      if (this.userId) {
        this.userId = parseInt(this.userId)
        this.getUser()
      } else {
        this.ready = true
      }
    },

    methods: {
      updateActive(value) {
        this.formChangeUser.active = value
      },
      updateReminders(value) {
        this.formChangeUser.reminders = value
      },
      updateArea(value) {
        this.formChangeUser.area_id = parseInt(value)
      },
      selectFile(e) {
        this.avatar = e.target.files[0]
      },

      getUser() {
        let self = this;
        axios.get('/api/v2/user/' + this.userId)
          .then(function (response) {
            Object.assign(self.formChangeUser, response.data.user);
            self.ready = true
          })
          .catch(function (error) {
            self.$notify.error('error_retreving_user_info')
          });
      },

      async updateUser() {

        const formData = new FormData();
        formData.append('avatar', this.avatar);
        formData.append('name', this.formChangeUser.name);
        formData.append('surname', this.formChangeUser.surname);
        formData.append('password', this.password);
        formData.append('email', this.formChangeUser.email);
        formData.append('area_id', this.formChangeUser.area_id);
        formData.append('locale', this.formChangeUser.locale);
        if (this.formChangeUser.phone) {
          formData.append('phone', this.formChangeUser.phone);
        }
        if (this.formChangeUser.active) {
          formData.append('active', 1);
        } else {
          formData.append('active', 0);
        }

        if (this.formChangeUser.collaborator) {
          formData.append('collaborator', 1);
        } else {
          formData.append('collaborator', 0);
        }

        if (this.formChangeUser.reminders) {
          formData.append('reminders', 1);
        } else {
          formData.append('reminders', 0);
        }

        var url = this.userId ? '/api/v2/user/' + this.userId : '/api/v2/user'

        let self = this
        this.isLoading = true

        await axios.post(url, formData)
          .then(function (response) {
            self.isLoading = false

            if (response.data.data && response.data.success) {
              // Actualizamos el avatar si procede
              self.formChangeUser.avatar = response.data.data.avatar
              // Vaciamos el password
              self.password = self.repeat = ''

              self.$notify.success(self.userType + '_edited')

              // Actualizamos los valores del usuario si un usuario ha modificado sus propios datos
              if (self.userId == self.user.id) {
                self.$store.dispatch('auth/fetchUser');
              }
              // Vamos a la lista de usuarios si el cambio lo ha realizado otro usuario (es decir, con permisos)
              else {
                self.$router.push({
                  name: 'admin.users'
                })
              }
            } else {
              self.$notify.error(response.data.msg || 'error_saving_' + self.userType)
            }
          })
          .catch(error => {
            self.isLoading = false
            console.log(error.response);
            // Indicamos de forma explícita que el email ya ha sido utilizado
            if (error.response.data && error.response.data.errors.email && error.response.data.errors.email[0] === 'unique_user_email') {
              self.$notify.error('unique_user_email')
            } else if (error.response.data && error.response.data.errors.area_id && error.response.data.errors.area_id[0] === 'required_area') {
              self.$notify.error('required_area')
            } else {
              self.$notify.error('error_saving_' + self.userType)
            }
          });

          // Si el idioma preferido ha cambiado
          if (this.formChangeUser.locale != this.$store.getters['lang/locale']){
            // Actualizamos locale
            this.$store.dispatch('lang/setLocale',this.formChangeUser.locale);
            //Refrescamos la página para que se traduzca
            this.$router.go('/admin/user/' + this.userId);
          }
      },

      /**
       * Pasa el usuario colaborador a normal/participante
       */
      removeCollaborator() {
        this.$dialog.confirm({
          title: this.$t("convert_to_participant"),
          message: this.$t("convert_to_participant_warning") + '<br><strong>' + this.$t('are_you_sure') + '</strong>',
          confirmText: this.$t("convert_to_participant"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            this.isLoading = true;
            let url = '/api/v2/user/' + this.userId + "/remove-collaboration";
            const {data} = await axios.get(url);

            if (data.success) {
              this.$toast.open({
                message: this.$t("convert_to_participant_success"),
                type: "is-success",
                position: "is-top-right"
              });
              this.$router.push({
                name: 'admin.users'
              });
            } else {
              this.isLoading = false;
              // Error
              this.$toast.open({
                message: data.error || this.$t("convert_to_participant_error"),
                type: "is-danger",
                position: "is-top-right"
              });
            }
          }
        });

      },

      async saveConfiguration() {
        this.isLoading = true
        await axios.post("/api/v2/user/notifitems", {configs: this.listNotifitems, company_id: this.company.id})
          .then(response => {
            if (response.data && response.data.success) {
              this.$notify.success('config_updated')

            } else {
              this.$notify.error('config_update_error')
            }
          })
          .catch(error => {
            this.$notify.error('config_update_error')
          })
        this.loadNotifitems()
      },

      async loadNotifitems() {
        this.isLoading = true
        let userNotifitems = await axios.get("/api/v2/user/notifitems/", {params: {company_id: this.company.id}})
        let notifitems = await axios.get('/api/v2/notifitems')
        this.listNotifitems = []
        for (let item of notifitems.data.data){
          let userNotif = userNotifitems.data.data.filter(un => un.notifitem_id == item.id)
          let result = {
            id: item.id,
            code: item.code,
            name: item.name,
            value: userNotif[0] ? true : false
          }
          if (item.code == 'grvf') {
            result['configs'] = userNotif.map(un => un.configs)
          }
          this.listNotifitems.push(result)
        }
        this.isLoading = false
      },

      async loadTemplates() {
        let {data} = await axios.get('/api/v2/validations/templates', {'params': { company_id: this.company.id }})
        this.listTemplates = data.templates
      },

      async changeTemplate(templateCode) {
        let template = this.listTemplates.find(t => t.code == templateCode)
        let {data} = await axios.get('/api/v2/validations/template/'+template.id)
        this.listTasks = []
        this.listGroups = []
        this.taskCode= null
        this.groupCode= null

        data.data.tasks.map(t => {
          this.listTasks.push(t)
          this.listTasks = this.listTasks.concat(t.children)
        })
      },

      async changeTask(taskCode) {
        let task = this.listTasks.find(t => t.code == taskCode)
        let {data} = await axios.get('/api/v2/validations/template/task/'+task.id)
        this.listGroups = data.data.groups
        this.groupCode= null
      },

      addGroupConfiguration(index) {
        let template_code = this.templateCode
        let task_code = this.taskCode
        let group_code = this.groupCode
        if (template_code && task_code && group_code) {
          if (this.listNotifitems[index].configs.some(c => c.template_code == template_code && c.task_code == task_code && c.group_code == group_code)) {
            // le notificamos que ya esta agregado ese grupo
            this.$notify.error('group_config_exists')
          } else {
            this.listNotifitems[index].configs.push({template_code, task_code, group_code})
            // reseteamos los seleccionables
            this.resetFields()
          }

        }
      },

      deleteGroupConfiguration(index, parentIndex) {
        this.listNotifitems[parentIndex].configs.splice(index, 1)
      },

      resetFields() {
        this.templateCode = null
        this.taskCode = null
        this.groupCode = null
        this.listTasks = []
        this.listGroups = []
      }
    },

  };
</script>
