<template>
  <div>
    <div class="text-right pr-2" v-if="menuDropdown">
      <b-dropdown
        v-model="activityStatus"
        :mobile-modal="true"
        position="is-bottom-left"
        aria-role="list"
        @change="changeActivityStatus"
      >
          <template #trigger>
            <span
              class="px-1 w-8 mt-2 cursor-pointer text-gray-700 hover:text-gray-700">
              <b-tooltip :label="$t('change_status')" position="is-top">
                <i class="fas fa-traffic-light px-3 py-2 text-white- bg-gray-200 bg-epc-900- rounded-full lg:w-8"></i>
              </b-tooltip>
            </span>
          </template>

          <b-dropdown-item aria-role="listitem" :value="parseInt('0')" :disabled="disabledStatusAssigned">
            {{$t('activity_status_assigned')}}
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" :value="parseInt('1')" :disabled="disabledStatusRunning">
            {{$t('activity_status_running')}}
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" :value="parseInt('2')" :disabled="disabledStatusPaused">
            {{$t('activity_status_paused')}}
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" :value="parseInt('3')" disabled>
            {{$t('activity_status_issue')}}
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" :value="parseInt('4')" :disabled="disabledStatusDone">
            {{$t('activity_status_done')}}
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" :value="parseInt('5')" :disabled="disabledStatusReopened">
            {{$t('activity_status_reopened')}}
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" :value="parseInt('6')" :disabled="disabledStatusClosed">
            {{$t('activity_status_closed')}}
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" :value="parseInt('7')" :disabled="disabledStatusDelayedStart">
            {{$t('activity_status_delayed_start')}}
          </b-dropdown-item>

      </b-dropdown>

    </div>

    <div v-else>
      <b-select ref="input"
        class="mr-1"
        v-model="activityStatus"
        :placeholder="$t('change_status')"
        @input="changeActivityStatus"
      >
        <option :value="parseInt('0')" :disabled="disabledStatusAssigned">{{$t('activity_status_assigned')}}</option>
        <option :value="parseInt('1')" :disabled="disabledStatusRunning">{{$t('activity_status_running')}}</option>
        <option :value="parseInt('2')" :disabled="disabledStatusPaused">{{$t('activity_status_paused')}}</option>
        <option :value="parseInt('3')" disabled>{{$t('activity_status_issue')}}</option>
        <option :value="parseInt('4')" :disabled="disabledStatusDone">{{$t('activity_status_done')}}</option>
        <option :value="parseInt('5')" :disabled="disabledStatusReopened">{{$t('activity_status_reopened')}}</option>
        <option :value="parseInt('6')" :disabled="disabledStatusClosed">{{$t('activity_status_closed')}}</option>
        <option :value="parseInt('7')" :disabled="disabledStatusDelayedStart">{{$t('activity_status_delayed_start')}}</option>
      </b-select>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    name: "menu-change-status-activity",

    components: {

    },

    props: {
      activity: {type: Object, required: true},
      menuDropdown: { type: Boolean, default: false }
    },

    computed: {
      disabledStatusAssigned() {
        return ! this.activity.is_manager || this.openIssues || this.activityClosed;
      },
      disabledStatusRunning() {
        return ! this.activity.is_manager || this.openIssues || this.activityClosed;
      },
      disabledStatusPaused() {
        return ! this.activity.is_manager || this.openIssues || this.activityClosed;
      },
      disabledStatusDone() {
        return ! this.activity.is_manager || this.openIssues || this.activityClosed;
      },
      disabledStatusReopened() {
        return (this.activity.is_manager && this.activity.hierarchically_superior_users.length > 1) || this.openIssues || !this.activityClosed;
      },
      disabledStatusClosed() {
        return (this.activity.is_manager && this.activity.hierarchically_superior_users.length > 1) || this.openIssues;
      },
      disabledStatusDelayedStart() {
        return false;
      },
      disabledStatusAll() {
        return this.disabledStatusAssigned &&
          this.disabledStatusRunning &&
          this.disabledStatusPaused &&
          this.disabledStatusDone &&
          this.disabledStatusReopened &&
          this.disabledStatusClosed
      },
    },

    data() {
      return {
        activityStatus: this.activity.status
      }
    },

    watch: {
      activity(newValue, oldValue) {
        this.activityStatus = newValue.status
      }
    },

    mounted() {

    },

    methods: {
      async changeActivityStatus(value){
        if (value != this.activity.status) {
          let self = this
          await axios.post('/api/v2/activity/' + this.activity.id + '/status', { status: value }) // usamos value porque this.activityStatus no trae el valor elegido cuando se muestra via dropdown
            .then(function(response) {
              if (response.data && response.data.success) {
                self.$emit('epc-change-status', { success: true, activityStatus: value })
              } else {
                self.$emit('epc-change-status', { success: false, activityStatus: self.activity.status })
              }
            })
            .catch(error => {
              self.$emit('epc-change-status', { success: false, activityStatus: self.activity.status })
              console.log(error.response);
            });
        }
      },
    }

  }
</script>
