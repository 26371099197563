<template>

<section>
  <div class="mt-4">
    <b-tabs type="is-boxed" v-model="mainActiveTab">
      <b-tab-item>
        <template slot="header">
          <b-icon pack="fas" icon="drafting-compass"></b-icon>
          <span> {{ $t('drawings') }} </span>
        </template>

        <project-documents
          ref="project_drawings"
          :type-document="'drawings'"
        ></project-documents>
      </b-tab-item>

      <b-tab-item>
        <template slot="header">
          <b-icon pack="fas" icon="table-cells"></b-icon>
          <span> {{ $t('pictograms') }} </span>
        </template>

        <manage-pictograms
          ref="manage-pictograms"
          :projectId="project.id"
          :permitted-actions="{ 'visualize': true }"
          :can-actions-pictogram="{}"
          :showDownloadFileOriginal="false"
        ></manage-pictograms>
      </b-tab-item>

    </b-tabs>

  </div>
</section>

</template>

<script>
import {mapGetters} from "vuex";
import ProjectDocuments from "../../components/documents/project-documents.vue"
import managePictograms from "../../components/pictograms/manage-pictograms.vue";

export default {
  middleware: 'auth',
  title: 'project_drawings_page_title',
  subtitle: 'project_drawings_page_subtitle',
  menuOption: '2-8',

  components: {
    ProjectDocuments,
    managePictograms
  },

  data: () => ({
    mainActiveTab: 0,
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
      project: 'app/project',
      mobile: 'device/mobile'
    }),

    stickyHeader() { return this.mobile.on_mobile}, // para fiar el header de la tabla cuando se esta mostrando en movil
  },

  created() {
  },

  mounted() {
  },

  beforeDestroy(){
  },

  methods: {

  },
};
</script>

<style scoped>
  .panel::v-deep .b-table .table-wrapper.has-sticky-header tr:first-child th,
  .panel::v-deep .b-table .table th.is-sticky {
    background: hsl(220, 43%, 53%);
  }

</style>
