<template>
  <div class="w-96 viewer-left-panel">
    <div class="header relative w-full">
      <span class="m-3 block">Pictogramas</span>
      <div class="viewer-panel__close-btn" @click="() => { $emit('epc-close', true) }">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="ga-left-panel-close">
          <g data-name="<Group>">
            <path d="M7.31 17.5a.5.5 0 0 1-.31-.85l10-10a.5.5 0 0 1 .71.71l-10 10a.5.5 0 0 1-.4.14z"></path>
            <path d="M17.31 17.5a.5.5 0 0 1-.31-.15l-10-10a.5.5 0 0 1 .71-.71l10 10a.5.5 0 0 1-.4.86z"></path>
          </g>
        </svg>
      </div>
    </div>
    <div class="h-3" style="background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);"></div>
    <div class="bg-white">
      <ul class="py-2 text-sm text-gray-700">
        <li>
          <div v-for="(pic, index) of pictograms" :key="pic.id ? pic.id : 'pic_' + index"
            class="px-4 py-2 flex hover:bg-gray-100 inactive">
              <div class="flex items-center h-5">
                <b-checkbox v-model="selectedPictogramsLocal"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  :native-value="pic"
                  :disabled="pic.screenshot == null"
                  @input="_HandleSelectPictogram">
                </b-checkbox>
              </div>
              <div class="ml-2 text-sm">
                  <label for="helper-checkbox"
                    class="font-medium text-gray-900">{{ pic.name }}</label>
                  <!-- <p id="helper-checkbox-text"
                    class="text-xs font-normal text-gray-500">For orders shipped from $25 in books or $29 in other categories</p> -->
              </div>

              <!-- <b-icon pack="fas" icon="solar-panel"></b-icon> {{ pic.name }} -->
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

  export default {
    name: "ViewerPanelPictograms",

    props: {
      pictograms: { type: Array, default: [] },
      selectedPictograms: { type: Array, default() { return [] } }
    },

    watch: {
    },

    data() {
      return {
        selectedPictogramsLocal: this.selectPictograms ? this.selectPictograms : []
      }
    },

    methods: {
      _GetIcon(layer) {
        return layer.icon ?? 'grip-horizontal' // 'images'
      },

      _HandleClick(lyr) {
        if ( !this.workLayer )
          this.$emit('epc-view-selected', lyr.code)
      },

      _HandleSelectPictogram(value) {
        this.$emit('epc-update:selected-pictograms', this.selectedPictogramsLocal)

        // let stage = self.$refs.stage.getStage()
        // delItem.forEach(el => {
        //   console.log('del item: ', el.item.id)
        //   let node = stage.findOne('#pic_' + el.item.id)
        //   if (node) {
        //     node.remove()
        //     stage.draw()
        //   }
        //   self.images.splice(el.pos, 1)
        // })

        // // Insertemos los que no estan insertados
        // // TODO: optimizar a futuro buscar una mejor forma
        // this.selectPictograms.forEach(element => {
        //   let obj = self.images.find( obj => obj.id === element.id)
        //   if ( null == obj  ) {
        //     const img = new Image()
        //     img.src = element.screenshot
        //     self.images.push({ id: element.id, image: img })
        //   }
        // });
      },
    }
  }

</script>

<style scoped>

  .active {
    border: 1px solid #5577bb;
    color: #5577bb;
  }
  .viewer-left-panel {
    border-right: 1px solid #d4dbe1;
    background: linear-gradient(to top, #eeeeee 0, #f7f7f7 8px, #fafafa 12px, #fff 20px);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.3);
  }
  .viewer-panel__close-btn {
      position: absolute;
      top: 0px;
      right: 5px;
      float: none;
      fill: #939ca5;
      cursor: pointer;
      display: block;
      z-index: 1;
      /* width: 40px; */
      /* height: 40px; */
  }

</style>
