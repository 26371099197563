<template>
<div>
  <div class="w-full h-8 flex items-center mb-2 overflow-visible">
    <b-icon :icon="icon" pack="fas" class="w-6 h-6 text-blue mr-2"/>
    <label class="font-semibold text-grey-darker">{{ $t(title) }}</label>
    <button ref="addButtom"
      v-show="showAddButton"
      @click="onClickAddButton"
      class="text-red ml-2">
      <b-icon icon="plus-circle" size="is-medium" pack="fas"/>
    </button>

    <b-modal :active.sync="showSearchBox" has-modal-card>
      <usersearchmodal
        v-if="showSearchBox"
        :base-url="getApiUrl()"
        :allow-external-users="allowExternalUsers"
        :allow-create-users="allowCreateUsers"
        :parent-element="$refs.addButtom"
        :only-internal-users="onlyInternalUsers"
        :only-external-users="onlyExternalUsers"
        :allow-company-users="allowCompanyUsers"
        :project-id="projectId"
        :role-name="_filterRoleName"
        :department-id="departmentId"
        :is-subactivity="isSubactivity"
        :activity-id="activityId"
        :is-replacement="isReplacement"
        :is-project="isProject"
        :validation-users="validationUsers"
        :is-issue="isIssue"
        @close="showSearchBox = false"
        @user-selected="addUser($event)"
        @create-user="createUser($event)" />
    </b-modal>

    <b-switch type="is-success" 
      v-if="withEmail"
      v-model="sendEmail"
    >
      {{ $t('send') }} {{ $t('email') }}
    </b-switch>
    
  </div>
  <transition
    name="custom-classes-transition"
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
    duration="200">
    <userboxes
      v-show="showUserBoxes"
      :show="showUserBoxes"
      ref="userbox"
      :usersList="usersList"
      :superiorHierarchicalUsers="superiorHierarchicalUsers"
      :disabled="disabled"
      :is-manager="isManager"
      :allow-zero-users="_allowZeroUsers"
      :canReplace="canReplace"
      @removeButtonClicked="onClickRemoveButton($event)"
      :boxFullWidth="boxFullWidth"
      class="w-full" />
  </transition>
</div>
</template>

<script>
import axios from 'axios'
import Userboxes from './UserBoxes.vue'
import Usersearchmodal from './UserSearchModal.vue'
import EventBus from '~/plugins/bus'

export default {
  components: {
    Userboxes,
    Usersearchmodal,
  },

  props: {
    title: { type: String, required: true },
    icon: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    withEmail: { type: Boolean, default: false },
    // La url base para llamadas a la API
    apiUrl: { type: String, required: true },
    // El id de la entidad padre del componente (p.e. área, proyecto, etc.)
    parentEntityId: { type: Number, default: null },
    // El tipo de permiso asociado al usuario
    usersPermissionType: { type: Array, required: true,
      validator: value => {
        var check = true
        value.forEach((item) => {
          ['admin', 'view', 'participate'].includes(item)
        })
        return check
      } },
    // El nombre del rol asociado al usuario
    roleName: { type: String },
    // Permitir filtar por rol
    allowFilterForRole: { type: Boolean, default: false },
    // Permitir añadir/crear/invitar usuarios colaboradores
    allowExternalUsers: { type: Boolean, default: false },
    // Presenta el botón para crear nuevos usuarios
    allowCreateUsers: { type: Boolean, default: true },
    // Permitir cero usuarios
    allowZeroUsers: { type: Boolean, default: false },
    // Sólo permite añadir 1 usuario (quita el botón de añadir cuando ya hemos añadido uno)
    isManager: { type: Boolean, default: false },
    onlyOneUser: { type: Boolean, default: false },
    onlyInternalUsers: { type: Boolean, default: false, required: false },
    onlyExternalUsers: { type: Boolean, default: false, required: false },
    allowCompanyUsers: { type: Boolean, default: false, required: false },
    projectId: { type: Number, default: null, required: false }, //En caso de actividades e incidencias, ID del proyecto en el que estamos para obtener su bolsa de usuarios
    isSubactivity: { type: Boolean, default: false, required: false },
    activityId: { type: Number, default: null, required: false },
    departmentId: { type: Number, default: null, required: false },
    canReplace: { type: Boolean, default: false},
    // Modo LOCAL: no envía operaciones al back (CREATE,DELETE), sólo almacena
    // en la lista del componente los usuarios
    localMode: { type: Boolean, default: false },
    superiorHierarchicalUsers: { type: Array, default: () => [] },
    isProject: { type: Number, default: 0, required: false }, //flag que dice si estamos editando un project (isProject=idproject) o otra cosa (isProject=0) (controlar colaboradores de subcontratas)
    validationUsers: { type: Boolean, default: false, required: false }, // flag para buscar usuarios de los grupos de validacion (que tendra cierta logica distinta)
    boxFullWidth: { type: Boolean, default: false, required: false},
    isIssue: { type: Boolean, default: false, required: false},
  },

  data: () => ({
    usersList: [],
    showAddButton: true,
    sendEmail: false,
    showUserBoxes: false,
    showSearchBox: false,
    defaultErrorMsg: 'error_user_added_to_list',
    isReplacement: false,
    indexUserToReplace: null
  }),

  computed: {
    _allowZeroUsers() {
      if (this.localMode) {
        // Forzamos esto porque cuando sólo permitimos 1 usuario
        // hemos de permitir a su vez eliminar el usuario que esté
        // para poder añadir uno nuevo (cambiar)
        return true
      } else {
        return this.allowZeroUsers
      }
    },
    _filterRoleName() {
      if( this.allowFilterForRole )
        return this.roleName

      return null
    }
  },

  watch: {
    parentEntityId (newId, oldId) {
      if (newId !== oldId) {
        this.refreshData()
      }
    },
    usersList (newList) {
      this.showUserBoxes = (newList.length > 0)
      if (this.onlyOneUser) {
        this.showAddButton = !this.disabled && (newList.length <= 0)
      }
    },
    disabled(value) {
      var showButton = !value
      // Si disabled acaba de ser desactivado y solo se permite añadir un usuario,
      // comprobamos el número de usuarios de la lista
      if (!value && this.onlyOneUser) {
        showButton = this.usersList.length <= 0
      }
      this.showAddButton = showButton
    },
    isReplacement(value) {
      if (! value) {
        this.indexUserToReplace = null
      }
    }
  },

  created() {
    // Referenciamos
    var self = this;

    this.$on('replaceButtonClicked', function(index) {
      this.onClickReplaceButton(index)
    });

    // Actualiza los participantes en el back si no es modo local (crear)
    // y el componente es de rol participante
    if (self.roleName === 'participant' && ! self.localMode) {

      // Bus global para intercomunicar componentes
      EventBus.$on('add-hierarchical-participant', function(user) {
        // console.log('Usuario a añadir como participante: ')
        // console.log(user)
        self.__addUserBackend({ 'user_id': user.user_id });
      });

      // Bus global para intercomunicar componentes: se quita lo de eliminar usuarios de la línea jerárquica
      // EventBus.$on('remove-hierarchical-participant', function(user) {
      //   console.log(user)
      //   self.__removeUserBackend({ 'user_id': user.user_id });
      // });
    }
  },

  mounted() {
    this.showAddButton = !this.disabled
    if (! this.localMode) {
      this.refreshData()
    }
  },

  methods: {
    // Carga inicial de la lista de usuarios desde el back
    async refreshData() {
      if (!this.parentEntityId) {
        return false;
      }
      this.usersList = []
      const { data } = await axios.get(this.getRefreshDataUrl())
      if (data && data.success) {
        this.usersList = data.users_list
      }
    },

    // Al pulsar el botón de añadir usuario
    onClickAddButton() {
      this.isReplacement = false
      this.$emit('addButtonClicked')
      this.showSearchBox = true
    },

    // Evento al pulsar enter o click en un usuario de la lista de búsqueda
    addUser(user) {
      this.showSearchBox = false

      // Evaluamos si tenemos que reemplazar o añadir
      if (this.isReplacement && this.indexUserToReplace !== null) {
        let indexUserToReplace = this.indexUserToReplace
        this.$dialog.confirm({
          title: this.$t('replace_dept_user_title'),
          message: this.$t('replace_dept_user_confirmation'),
          confirmText: this.$t('replace'),
          cancelText: this.$t('cancel'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => this.__replaceUser(user, indexUserToReplace)
        })
      } else {
        this.__addUser({ 'user_id': user.id })
      }

      this.isReplacement = false
    },

    // Evento para añadir y crear un nuevo usuario
    createUser(userData) {
      this.__addUser({
        'create': true,
        'name': userData.name,
        'surname': userData.surname,
        'email': userData.email,
      })
    },

    // Reemplazamos un usuario por otro
    async __replaceUser(replacingUser, replacedUserIndex) {

      let replacedUser = this.usersList[replacedUserIndex]

      // si es de issue solo se reemplaza ya que la incidencia aún no existe (usado solo para manager y solo al crear)
      if (this.isIssue) {
        this.usersList.splice(replacedUserIndex, 1, replacingUser.basic)
        return
      }

      let url = this.getBaseUrl() + '/users/replace'
      let params = { replacing_user_id: replacingUser.id, replaced_user_id: replacedUser.user_id}

      const { data } = await axios.post(url, params)
      if (data && data.success) {

        // Actualizamos el front y notificamos
        this.usersList.splice(replacedUserIndex, 1, replacingUser.basic)
        this.$notify.success('success_user_replacement')

        // Para actualizar el nombre del jefe del departamento en el organigrama
        this.$parent.$emit('addUserClicked', replacingUser.basic, this.roleName, true)

      } else {
        let error_msg = data.error && data.msg ? data.msg : 'error_user_replacement'
        this.$notify.error(error_msg)
      }
    },

    // Envía los datos del usuario a añadir a la lista
    __addUser(userData) {
      self = this

      // Local mode
      if (this.localMode) {
        let url = 'create' in userData ? '/api/v2/user/add' : '/api/v2/user/' + userData.user_id + '/?basic=1'
        axios.get(url, userData)
          .then(function(response) {
            if (response.data && response.data.success) {
              let user = response.data.user

              // Añadimos el usuario a la lista local (si no está ya)
              if (user !== null && ! self.__checkIfUserExists(user.user_id)) {
                self.__addUserToList(user)
              }
            }
          })

      } else {

        // Con la API del back
        let url = this.getBaseUrl() + '/users/add'
        userData['permission_type'] = this.usersPermissionType
        userData['role_name'] = this.roleName
        let data = null
        axios.post(url, userData)
          .then(function (response) {
            data = response.data
            if (data && data.success) {
              // Evitamos añadir un usuario que ya está en la lista
              if (! self.__checkIfUserExists(data.user.user_id)) {
                // Añadimos el usuario a la lista
                self.$notify.success('success_user_added_to_list')
                self.__addUserToList(data.user)
              }

              // Para actualizar el nombre del jefe del departamento en el organigrama
              self.$parent.$emit('addUserClicked', data.user, self.roleName)

            } else {
              // error
              self.__showErrorAddingUserToList(data.error || (data.msg || self.defaultErrorMsg))
            }
          })
          .catch(error => console.log(error))
      }
    },

    // Envía los datos del usuario a eliminar a la lista
    async __addUserBackend(userData) {
      let url = this.getBaseUrl() + '/users/add'
      userData['permission_type'] = this.usersPermissionType
      userData['role_name'] = this.roleName
      const { data } = await axios.post(url, userData)
      if (!data || !data.success) {
        this.__showErrorAddingUserToList(data.error ? data.error : this.defaultErrorMsg)
      }
    },

    // Envía los datos del usuario a eliminar a la lista
    async __removeUserBackend(userData) {
      // Llamamos a la API para remover el usuario
      let userId = userData.user_id
      let url = this.getBaseUrl() + '/users/remove'
      const { data } = await axios.post(url, {
        'user_id': userId,
        'permission_type': this.usersPermissionType,
        'role_name': this.roleName
      })
      if (!data || !data.success) {
        this.$toast.notify.error('error_remove_user_from_list')
      }
    },

    // Al pulsar el botón para quitar un usuario
    onClickRemoveButton(index) {

      // Comprobamos si existen restricciones de línea jerárquica para eliminar al usuario
      // El índice no se actualiza con la lista, habría que cambiarlo si hiciera falta
      // if (this.superiorHierarchicalUsers.length > 0 && this.superiorHierarchicalUsers.includes(this.usersList[index].user_id)) {
      //   this.$notify.error('error_getting_delete_participant_superior_user')
      // } else {

      // Mostrar un mensaje de advertencia cuando el roleName está relacionado con los departamentos
      if (this.roleName === 'department_chief' || this.roleName === 'department_user') {
        this.$dialog.confirm({
          title: this.$t('remove_dept_user_title'),
          message: this.$t('remove_dept_user_confirmation'),
          confirmText: this.$t('remove'),
          cancelText: this.$t('cancel'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => this.removeUserDispatch(index)
        })
      } else {
        this.removeUserDispatch(index)
      }
    },

    onClickReplaceButton(index) {
      this.isReplacement = true
      this.indexUserToReplace = index
      // this.$emit('replaceButtonClicked', index)
      // this.$parent.$emit('replaceButtonClicked')
      this.showSearchBox = true
    },

    removeUserDispatch(index) {
      this.$emit('removeButtonClicked', index)

      // Para actualizar el nombre del jefe del departamento en el organigrama
      this.$parent.$emit('removeButtonClicked', this.roleName)

      this.removeUser(index)
    },

    // Quitamos un usuario de la lista
    // async removeUser(index) { DUPLICADO!?

    //   // Local mode
    //   if (this.localMode) {
    //     this.__removeUserFromList(index)
    //     return
    //   }

    //   // Llamamos a la API para remover el usuario
    //   let userId = this.usersList[index].user_id
    //   let url = this.getBaseUrl() + '/users/remove'
    //   const { data } = await axios.post(url, {
    //     'user_id': userId,
    //     'permission_type': this.usersPermissionType,
    //     'role_name': this.roleName
    //   })
    //   if (data && data.success) {
    //     this.__removeUserFromList(index)
    //   } else {
    //     this.$toast.notify.error('error_remove_user_from_list')
    //   }
    // },

    // Reemplazamos a un usuario de la lista
    async removeUser(index) {
      // Local mode
      if (this.localMode) {
        this.__removeUserFromList(index)
        return
      }

      // Llamamos a la API para remover el usuario
      let userId = this.usersList[index].user_id
      let url = this.getBaseUrl() + '/users/remove'
      const { data } = await axios.post(url, {
        'user_id': userId,
        'permission_type': this.usersPermissionType,
        'role_name': this.roleName
      })
      if (data && data.success) {
        this.__removeUserFromList(index)
      } else {
        this.$notify.error(data.error || 'error_remove_user_from_list')
      }
    },

    // Devuelve la URL base para las peticiones a la API
    getBaseUrl() {
      return this.apiUrl + '/' + (this.parentEntityId ? this.parentEntityId : '')
    },

    // La URL para cargar la lista de usuarios según roles
    getRefreshDataUrl() {
      return this.getBaseUrl() + '/users?r=' + this.roleName
    },

    // Devuelve un array con los IDs de los usuarios en la lista
    getUsersIds() {
      return this.usersList.map((user) => {
        return user.user_id
      })
    },
    
    getUserEmails() {
      return this.usersList.map((user) => {
        return user.email
      })
    },

    // Añade un usuario a la lista local
    __addUserToList(user) {
      this.usersList.unshift(user)
      this.$emit('user-added', user)
    },

    // Quita un usuario de la lista local
    __removeUserFromList(index) {
      this.$emit('user-removed', this.usersList[index])
      this.usersList.splice(index, 1)
    },

    // Comprueba que el usuario no exista ya en la lista
    __checkIfUserExists(user_id) {
      for (var i = this.usersList.length - 1; i >= 0; i--) {
        if (this.usersList[i].user_id == user_id) {
          this.$notify.info('user_already_in_list')
          return true
        }
      }
      return false
    },

    // Muestra el error al añadir el usuario
    __showErrorAddingUserToList(msg = this.defaultErrorMsg) {
      this.$notify.error(msg)
    },

    // Marca un usuario de la lista como usuario de jerarquía superior
    markSuperiorHierarchicalUsers(superiorHierarchicalUsers) {
      this.$refs.userbox.markSuperiorHierarchicalUsers(superiorHierarchicalUsers)
    },

    // caso especial, al crear issue (en manager) se modifica la url para buscar los usuarios manager
    getApiUrl() {
      let url = this.apiUrl
      if (this.isIssue) {
        url = '/api/v2/activity/'+ this.activityId +'/issue/search-managers'
      }
      return url
    }

  },

};
</script>
