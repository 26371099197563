<template>
    <label :class="classObject">
        <span class="vue-switcher__label" v-if="shouldShowLabel">
            <span v-if="label" v-text="label"></span>
            <span v-if="!label && value" v-text="textEnabled"></span>
            <span v-if="!label && !value" v-text="textDisabled"></span>
        </span>

        <input type="checkbox" @change="trigger" :checked="switch_value">

        <div></div>
    </label>
</template>

<script>
export default {
    name: 'v-switch',
    props: {
        typeBold: {
            default: false
        },
        value: {
            default: false
        },
        disabled: {
            default: false
        },
        label: {
            default: ''
        },
        textEnabled: {
            default: 'On'
        },
        textDisabled: {
            default: 'Off'
        },
        color: {
            default: 'blue'
        },
        theme: {
            default: 'default'
        },
    },

    mounted(){
      this.switch_value = this.value
    },
    data: () => ({
      switch_value : false
    }),
    methods: {
        trigger (e) {
            this.switch_value = !this.switch_value
            if(this.switch_value)
            {
                this.$emit('checked', 1)
            }else{
                this.$emit('checked', 0)
            }
        }
    },
    computed: {
        classObject () {
            const { color, switch_value, theme, typeBold, disabled } = this;
            return {
                'vue-switcher' : true,
                ['vue-switcher--unchecked'] : !switch_value,
                ['vue-switcher--disabled'] : disabled,
                ['vue-switcher--bold']: typeBold,
                ['vue-switcher--bold--unchecked']: typeBold && !switch_value,
                [`vue-switcher-theme--${theme}`] : color,
                [`vue-switcher-color--${color}`] : color,
            };
        },
        shouldShowLabel () {
            return this.label !== '' || this.textEnabled !== '' || this.textDisabled !== '';
        }
    }
}
</script>

<style>
    .vue-switcher__label {
        /* original css is too small /node_modules/vue-switches/src/switches.scss */
        font-size: 16px;
    }
</style>