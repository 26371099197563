<template>
  <div>
    <div class="w-full flex justify-end">
      <div class="flex">
        <v-button @click="create = !create;
        imputationCreate.id = 0;
        imputationCreate.code = '';
        imputationCreate.description = '';
        imputationCreate.UGM= '';"
        class="btn btn-blue rounded" icon="plus">{{ $t('add_imputation') }}
      </v-button>
    </div>
  </div>
  <div class="panel" v-if="create || imputationCreate.id">
    <div>

      <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('create_imputation') }}</h2>
      <form @submit.prevent="createImputation">
        <div class="w-full justify inline-flex">
          <b-field :label="$t('code')" class="md:w-1/5 mr-4">
            <b-input v-model="imputationCreate.code" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('description')" class="md:w-1/5 mr-4">
            <b-input v-model="imputationCreate.description" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('UGM')" class="md:w-1/5 mr-4">
            <b-input v-model="imputationCreate.UGM" class="w-full"></b-input>
          </b-field>
          <b-field label="  " class="md:w-1/5 mr-4 mt-4">
            <v-button class="btn btn-primary" nativeType="submit" icon="save" :disabled="!imputationCreate.code || !imputationCreate.UGM">{{ $t('save') }}</v-button>
          </b-field>
        </div>
      </form>
    </div>
  </div>
  <div class="panel">
    <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('imputation_list') }}</h2>
    <div class="panel">
      <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('filter') }}</h2>
      <form @submit.prevent="filterImputation">
        <div class="w-full justify inline-flex">
          <b-field :label="$t('code')" class="md:w-1/5 mr-4">
            <b-input v-model="imputationFilter.code" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('UGM')" class="md:w-1/5 mr-4">
            <b-input v-model="imputationFilter.UGM" class="w-full"></b-input>
          </b-field>
          <b-field label="  " class="md:w-1/5 mr-4 mt-4">
            <v-button class="btn btn-primary" nativeType="submit"><b-icon pack="fas" icon="search"/></v-button>
          </b-field>
        </div>
      </form>
    </div>
  </div>

  <div class="w-full flex flex-wrap mt-4">
    <section class="w-full">
      <b-table
      :data="imputations"
      :paginated="isPaginated"
      :per-page="perPage"
      :bordered="true"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :default-sort-direction="defaultSortDirection"
      v-if="loaded">
      <template slot-scope="props">
        <b-table-column field="code" :label="$t('code')" sortable width=100>
          {{ props.row.code }}
        </b-table-column>

        <b-table-column field="description" :label="$t('description')" sortable>
          {{ props.row.description }}
        </b-table-column>

        <b-table-column field="UGM" label="UGM" sortable width=200>
          {{ props.row.UGM }}
        </b-table-column>

        <b-table-column field="actions" :label="$t('actions')" sortable width=100 >
          <div class="justify inline flex">
            <button @click="getImputation(props.row.id)"><b-icon pack="fas" icon="pencil-alt" type="is-primary" /></button>
          </div>
        </b-table-column>
      </template>
    </b-table>
  </section>
</div>
</div>
</div>
</template>

<script>
import Form from 'vform'
import axios from 'axios'

export default {
  middleware: 'auth',
  title: 'users_page_title',
  subtitle: 'users_page_subtitle',
  menuOption: '0-3',

  data: () => ({
    project_id: 0,
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 5,
    imputations: [],
    loaded: false,
    projects: null,
    create: false,

    imputationFilter: new Form ({
      code: '',
      UGM: '',
    }),
    imputationCreate: new Form ({
      id: 0,
      code: '',
      description: '',
      UGM: '',
    }),
  }),

  mounted () {
    this.project_id = parseInt(this.$route.params.project_id)
    this.getImputations()
  },

  methods: {

    async filterImputation () {
      if (! this.imputationFilter) {
        return;
      }
      console.log(this.imputationFilter)
      const url = '/api/v2/report/imputation/' + this.project_id + '/filter'
      const { data } = await this.imputationFilter.post(url)
      if (data.data && data.success) {
        this.imputations = data.data
      }
    },

    async createImputation () {
      if (! this.imputationCreate) {
        return;
      }
      var url
      if(this.imputationCreate.id){
        url = '/api/v2/report/imputation/' + this.imputationCreate.id
      }else{
        url = '/api/v2/report/imputation/' + this.project_id + '/add'
      }
      const { data } = await this.imputationCreate.post(url)
      if (data.data && data.success) {
        this.imputations = data.data
        this.imputationCreate.code = ''
        this.imputationCreate.description = ''
        this.imputationCreate.UGM = ''
        if(this.imputationCreate.id){
          this.getImputations()
          // Hago esto pq extiende de la clase padre y no me retorna la lista completa de nuevo.
        }
        this.imputationCreate.id = 0
        this.success('imputation_save')

      }else{
        this.error('error_creating_imputation')

      }
    },

    async getImputations(){
      const url = '/api/v2/report/imputation/' + this.project_id + '/list'
      const { data } = await axios.get(url)
      if (data.data && data.success) {
        this.imputations = data.data
        this.loaded = true
      } else {
        this.error('error_retreiving_imputations')

      }
    },

    async getImputation(id){
      const url = '/api/v2/report/imputation/' + id
      const { data } = await axios.get(url)
      if (data) {
        //Object.assign(this.imputationCreate,data.data)
        this.imputationCreate.id = id
        this.imputationCreate.code = data.code
        this.imputationCreate.description = data.description
        this.imputationCreate.UGM = data.UGM

      } else {
        this.error('error_retreiving_imputation')
      }
    },

    /*
     ALERTAS
     */
     success(message){
      this.$toast.open({
        message: message,
        type: 'is-success',
        position: 'is-top-right'
      })
    },
    error(message){
      this.$toast.open({
        message: message,
        type: 'is-danger',
        position: 'is-top-right'
      })
    }
  },
  watch:{

  }

};
</script>
