
export const state = {
    project: {},
    tags: [],
}

export const mutations = {
    saveProject (state, project) {
      // state.project = Object.assign({}, state.activities, filters)
      state.project = project
    },

    saveTags (state, tags) {
      // state.validations = Object.assign({}, state.validations, filters)
      state.tags = tags
    },
}

export const actions = {
    setProject ({ commit }, payload) {
      commit('saveProject', payload)
    },

    setTags ({ commit }, payload) {
      commit('saveTags', payload)
    },
}

export const getters = {
    project: state => state.project,
    tags: state => state.tags,
}
