<template>
<div class="modal-card" style="">
  <!-- Título -->
  <header class="modal-card-head">
    <p class="modal-card-title">{{ $t(creating ? 'create_new_user' : title) }}</p>
  </header>
  <!-- Contenido del modal -->
  <div class="modal-card-body">
    <!-- Template normal - cuando no se está invitando -->
    <template v-if="!creating">
      <!-- Input (typeahead) para buscar usuario -->
      <typeahead ref="input"
        :api-url="apiUrl"
        input-placeholder="search_user_placeholder"
        @esc-pressed="close"
        @item-selected="addUser($event)"
        :prepare-result-node="prepareResultNode"
        :select-first="true"
        class="w-full pb-4" />
      <!-- Boton al pie -->
      <v-button v-if="allowCreateUsers" full @click="startCreating(true)">
        {{ $t('create_new_user') }}
      </v-button>
    </template>
    <!-- Cuando se está invitando -->
    <template v-else>
      <form @submit.prevent="createUser" @keydown="createForm.onKeydown($event)" class="mt-4">
        <div class="flex w-full mb-4">
          <l-form-input ref="name"
            :form="createForm"
            field="name"
            placeholder="name"
            class="w-1/2 mr-1"
            @keydown.esc.native="close"
          />
          <l-form-input
            :form="createForm"
            field="surname"
            placeholder="surname"
            class="w-1/2 ml-1"
            @keydown.esc.native="close"
          />
        </div>
        <l-form-input
          :form="createForm"
          field="email"
          placeholder="email"
          inputType="email"
          class="w-full mb-4"
          @keydown.esc.native="close"
        />
        <div class="w-full flex">
          <v-button
            type="submit"
            class="w-1/2 mr-2"
            :class="{ shake: animateError, animated: animateError }"
          >
            {{ $t('send_user_invitation') }}
          </v-button>
          <v-button
            class="w-1/2 mr-2"
            color="grey"
            @click="startCreating(false)"
          >
            {{ $t('cancel') }}
          </v-button>
        </div>
      </form>
    </template>
  </div>
</div>
</template>

<script>
import Typeahead from './Typeahead'
import Form from 'vform'

export default {
  components: {
    Typeahead,
  },

  props: {
    title: { type: String, default: 'assign_user' },
    allowExternalUsers: { type: Boolean, default: false },
    allowCreateUsers: { type: Boolean, default: true },
    parentElement: { required: true },
  },

  data: () => ({
    creating: false,
    createForm: new Form({
      name: '',
      surname: '',
      email: '',
    }),
    fieldsWithErrors: null,
    animateError: false,
  }),

  computed: {
    apiUrl() {
      let baseUrl = "/api/v2/users/search"
      if (! this.allowExternalUsers) {
        return baseUrl + '?f=internal'
      }
      return baseUrl
    },
  },

  methods: {
    close() {
      // Cerramos el modal
      this.$emit('close')
    },
    prepareResultNode(item) {
      return '<span class="w-full block font-bold">' + item.name + ' ' + item.surname + '</span><span class="w-full block italic text-xs">(' + item.email + ')</span>'
    },
    addUser(user) {
      this.$emit('user-selected', user)
      this.close()
    },
    startCreating(start) {
      this.creating = start
      // Reposicionamos el modal
      // this.$refs.modal.updatePosition()
    },
    createUser() {
      // Validamos los datos
      this.fieldsWithErrors = null
      let errors = this.validateData()
      if (!errors) {
        this.$emit('create-user', this.createForm)
        this.close()
      } else {
        this.fieldsWithErrors = errors
        this.animateError = true
        this.$toast.open({
          'message': this.$t('fix_the_fields'),
          'position': 'is-top-right',
          'type': 'is-warning'
        })
        setTimeout(() => {
          this.animateError = false
        }, 1000)
      }
    },
    validateData() {
      var errors = []
      let name = this.createForm.name.trim()
      if (!name || name.length > 255) {
        errors.push('name')
      }
      let surname = this.createForm.surname.trim()
      if (!surname || surname.length > 255) {
        errors.push('surname')
      }
      let email = this.createForm.email.trim()
      if (!email || email.length > 255 || !this.__validateEmail) {
        errors.push('email')
      }
      return (errors.length > 0) ? errors : false
    },
    __validateEmail(email) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email.toLowerCase())
    }
  },

  mounted () {
    // this.$refs.modal.show()
    this.$nextTick(() => {
      // Foco al input de búsqueda
      this.$refs.input.setFocus()
    })
  },

};
</script>
