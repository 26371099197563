<template>
  <tr class="tr-detail">
    <td></td>
    <td colspan="4">
      <div class="detail-content">
					<ul>
						<details-item
              :node="node"
              :showingCertifiers="showingCertifiers"
            />
					</ul>
				</div>
    </td>
  </tr>
</template>

<script>

import DetailsItem from './details-item'

export default {
  components: {
    DetailsItem
  },

  props: {
    node: { type: Object, require: true },
    showingCertifiers: { type: Boolean, required: false, default: false }, // para titulo de la tabla 'capitulo' o 'certificadoras'
  }
}
</script>

<style>

.tr-detail > td {
  padding: 0 !important;
  padding-bottom: 10px !important;
  border-top: none !important;
}
.tr-detail > td:not(:first-child) {
  padding: 5px 5px 5px 5px;
}
.detail-content ul,
.detail-content li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.detail-content ul li {
  border: 2px dashed #337ab7;
  border-width: 0 0 0px 1px;
  display: flex;
}
.detail-content ul li .detail {
  margin: 0;
  padding: 0 10px 5px 0;
  margin-bottom: 10px;
  flex-grow: 1;
  flex-basis: 100%;
}
.detail-content ul li .detail fieldset {
  border: 1px solid #77acd9;
}
.detail-content ul li .detail fieldset legend {
  font-size: 90%;
  margin-left: 10px;
  width: auto;
  margin-bottom: 0;
  border: none;
}
/* .detail-content ul li .detail fieldset > div {
  margin-left: 5px;
  margin-right: 12px;
} */
.detail-content ul li .detail fieldset > div #testTree.table-condensed tbody > tr > td {
  padding: 2px;
}
.detail-content ul li .detail:first-child {
  border-top: 1px dashed #607eb9;
  flex-basis: 1%;
  background: #fff;
  margin-top: 25px;
}

/* .detail-content ul li:first-child {
  padding-top: 5px;
}
.detail-content ul li:last-child {
  margin-bottom: 20px;
} */

.detail-content ul li:last-child > .detail {
  margin-bottom: 0;
}
.detail-content ul li:last-child > .detail:first-child {
  margin-left: -1px;
}

</style>
