<template>
<div id="app" class="min-h-screen">
  <vue-progress-bar/>
<transition name="page" mode="out-in">
  <component v-if="layout" :is="layout"></component>
</transition>
</div>
</template>

<script>
// Load layout components dynamically.
const requireContext = require.context('~/layouts', false, /.*\.vue$/)

const layouts = requireContext.keys()
  .map(file =>
    [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
  )
  .reduce((components, [name, component]) => {
    components[name] = component.default // por actualizacion paquetes, ahora se usa con .default
    return components
  }, {})

export default {
  el: '#app',

  data: () => ({
    layout: null,
    defaultLayout: 'app'
  }),

  metaInfo () {
    const { appName } = window.config

    return {
      title: appName,
      titleTemplate: `%s - ${appName}`,
      noscript: [
        { innerHTML: 'This website requires JavaScript.' }
      ],
    }
  },

  methods: {
    setLayout (layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout
      }

      this.layout = layouts[layout]
    }
  },

      watch: {
    '$route.name': function() {
      if(this.$route.name === 'activity.home' ||
          this.$route.name === 'issue.home' || this.$route.name === 'issue.home-sub' ||
          this.$route.name === 'project.home' ||
          this.$route.name === 'validation.home'
      ){
        this.setLayout('appfix')
      }
    },
  },

};
</script>
