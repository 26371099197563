<template>
  <table id="testTree" class="w-full border-blue-lighter">
    <thead :class="{'bg-blue-lightest': showingCertifiers==false, 'bg-yellow-lightest': showingCertifiers==true}">
      <tr>
        <th class="p-2 text-sm font-semibold text-center uppercase text-blue-dark" width="36px"></th>
        <th class="p-2 text-sm font-semibold text-center uppercase text-blue-dark" width="80px"></th>
        <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ showingCertifiers ? $t('certifiers') : $t('chapter') }}</th>
        <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("progress") }}</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="row in listChapters">
        <!-- Recorremos las certificadoras -->
        <tr class="hover:bg-grey-lightest" :class="{'tr-selected': (row.id == selectedChapter) }" :key="row.id">
          <!-- Icono Detalles / Historia -->
          <td class="p-2 align-middle">

            <!-- desplegar subcapitulos -->
            <div class="cursor-pointer" v-show="row.children_count" @click="displayChapters(row)">
              <b-tooltip :label="$t('subchapters')" position="is-right" >
                <b-icon :icon="(row.id == selectedChapter) ? 'chevron-down' : 'chevron-right'" pack="fas" size="is-small" class="text-blue mr-1"></b-icon>
              </b-tooltip>
            </div>

            <!-- desplegar 'partidas' si tiene -->
            <div class="cursor-pointer" v-show="row.children_certifiers_count" @click="displayChapters(row, true)">
              <b-tooltip :label="'Partidas'" position="is-right" >
                <b-icon :icon="(row.id == selectedChapter) ? 'chevron-down' : 'chevron-right'" pack="fas" size="is-small" class="text-blue mr-1"></b-icon>
              </b-tooltip>
            </div>

          </td>
          <td class="justify-start w-auto h-full p-2 align-middle">

            <div class="flex text-center align-middle cursor-pointer" @click="displayHistory(row.id)">
              <b-tooltip
                :label="$t('display_history',[(row.lines ? row.lines.length : 0)])"
                position="is-right"
              >
                <span class="mr-1 text-xs font-semibold text-blue">{{ row.lines ? row.lines.length : 0 }}</span>
                <b-icon icon="history" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                <b-icon :icon=" (row.id == selectedHistory) ? 'chevron-up' : 'chevron-down'" pack="fas" size="is-small" class="text-blue"></b-icon>
              </b-tooltip>
            </div>
          </td>

          <!-- Chapter -->
          <td class="p-2 align-middle w-auto h-full justify-start" v-if="!row.completed">
            <div class="text-xs font-semibold">{{ row.code }} {{ row.name }}</div>
          </td>
          <td class="p-2" v-else>
            <div class="flex flex-row items-center">
              <div class="w-2 h-2 mr-1 rounded-full bg-green"></div>
              <div class="text-xs font-semibold">{{ row.name }}</div>
            </div>
          </td>
          <!-- Total unit -->
          <!-- <td class="p-2">
            <div class="flex flex-col">
              <div class="text-xs">{{ row.total_units }} / {{ row.unit.code }}</div>
            </div>
          </td> -->
          <!-- Pendiente / Ejecutado -->
          <td class="p-2">
            <div class="flex flex-col">
              <div class="text-xs">{{ row.total_units }} {{ row.unit_name || project.currency }} {{ $t("total").toLowerCase() }}</div>
              <div class="text-xs text-grey-dark">{{ calculateCertifierValues(row, 'pending') }} {{ row.unit_name || project.currency }} {{ $t('pendings') }}</div>
              <div class="text-xs font-medium text-blue">{{ calculateCertifierValues(row, 'assigned') }} {{ row.unit_name || project.currency }} {{ $t('certified').toLowerCase() }}</div>
            </div>
          </td>
        </tr>

        <details-row v-if="selectedChapterChildren.length"
          :key="'subchapter_' + row.id"
          :node="{...row, children: selectedChapterChildren}"
          :class="{'hidden': !(row.id == selectedChapter)}"
          :showingCertifiers="showingCertifiersSub"
        />

        <tr v-if="row.loading" :key="'certifier_history_loading_'+row.id">
          <td colspan="5" >
            <scale-loader/>
          </td>
        </tr>

        <tr :key="'certifier_history_'+row.id">
          <td colspan="5" :class="{'hidden': !(row.id == selectedHistory)}" >
              <table class="w-full">

                  <tr class="bg-indigo-lightest">

                      <td colspan="4" class="p-4">
                              <div class="flex justify-start w-auto h-full align-middle">
                              <b-icon icon="history" pack="fas" size="is-small" class="ml-1 text-blue"></b-icon>
                              <span class="ml-2 text-xs font-semibold text-blue">{{ $t('resource_history_header') }}</span>
                              </div>
                          </td>
                          </tr>

                          <tr class="bg-blue-lightest">
                              <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('date') }}</th>
                              <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('user') }}</th>
                              <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('action') }}</th>
                              <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('observations') }}</th>
                          </tr>

                  <tbody>
                      <template v-for="rowHistory in row.lines">
                        <tr :key="rowHistory.id" class="hover:bg-grey-lightest">

                            <td align="center" class="justify-start w-auto h-full p-4">
                                <div class="text-xs text-center">
                                  <!--
                                <l-timeago
                                    :since="rowHistory.created_at"
                                    :locale="locale"
                                    class="my-1 text-xs font-semibold text-blue-light"
                                />
                                  -->
                                  <div class="my-1 text-xs font-semibold text-blue-light">
                                    {{dateToText(rowHistory.created_at)}}
                                  </div>
                                </div>
                            </td>

                            <td class="p-2">

                                <div class="flex flex-row items-center justify-start w-auto h-full">
                                <div class="w-8 h-8 rounded-full">
                                    <img class="w-8 h-8 rounded-full" :src="rowHistory.user.avatar"/>
                                </div>
                                <div class="flex flex-col ml-2">
                                    <div class="text-xs">{{ rowHistory.user.fullname}}</div>
                                    <div class="text-xs text-grey-dark">{{ rowHistory.user.area}}</div>
                                </div>
                                </div>

                            </td>

                            <td align="center" class="h-full p-4">
                                    <div class="text-xs">{{ $t('execute') }} {{ rowHistory.executed_quantity}} {{ rowHistory.unit.name }}</div>
                            </td>

                            <td class="w-2/5 p-2">
                                <div class="flex flex-col">
                                <div class="text-xs" v-html="rowHistory.observation"></div>
                                <div class="flex flex-row">

                                  <template v-for="rowDocument in rowHistory.documents">
                                    <b-tooltip :label="rowDocument.name" position="is-top" :key="rowDocument.document_id">
                                      <div @click="showPreview(rowDocument)" class="w-12 h-12 m-1 rounded-lg shadow cursor-pointer bg-grey">
                                        <img class="w-12 h-12 rounded-lg" :src="rowDocument.file.thumbnail" />
                                      </div>
                                    </b-tooltip>
                                  </template>
                                </div>
                                </div>
                            </td>

                        </tr>
                      </template>
                  </tbody>
              </table>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>

import { mapGetters } from 'vuex'
import DetailsRow from './details-row'
import axios from "axios";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import { format } from 'date-fns'

export default {

  components: {
    DetailsRow,
    ScaleLoader
  },
  props: {
    listChapters: { type: Array, require: true },
    showingCertifiers: { type: Boolean, required: false, default: false }, // para titulo de la tabla 'capitulo' o 'certificadoras'
  },

  data () {
    return {
      selected: undefined,
      selectedHistory: undefined,
      selectedSubHistory: undefined,

      selectedChapter: undefined,
      selectedChapterChildren: [],
      showingCertifiersSub: false,
    }
  },

  computed: {
    ...mapGetters({
      locale: "lang/locale",
      project: 'app/project',
    }),
  },

  methods: {
    displayHistory(rowId){
      this.selectedChapter = undefined
      this.selectedHistory = (this.selectedHistory == rowId) ? undefined : rowId;
    },

    dateToText(date) {
      let dateObject = new Date(date);
      return format(dateObject, 'dd/MM/yyyy HH:mm')
    },

    async displayChapters(row, partidas = false){
      this.selectedHistory = undefined
      this.selectedChapter = (this.selectedChapter == row.id) ? undefined : row.id;

      if (this.selectedChapter == undefined) {
        return
      }
      row.loading = true
      this.selectedChapterChildren = []
      let args = {
        withLines: 1,
        where_type: partidas ? 'certifier' : 'certifier-head',
        parent_id: row.id
      };

      let url = "/api/v2/assignments/activity/" + row.assignable_id;
      let {data} = await axios.get(url, { params: args });
      this.selectedChapterChildren = data.assignments
      this.showingCertifiersSub = this.showingCertifiers

      // si lo que recibimos en este nivel son partidas (type 'certifier') cambiamos el titulo de la tabla que se abrirá debajo
      this.selectedChapterChildren.forEach( (element) => {
        if (element.type == 'certifier') {
          this.showingCertifiersSub = true;
        }
      })

      row.loading = false
    },

    calculateCertifierValues(item, type='pending'){

      // misma forma para capitulos y partidas. Desde backend se devuelven los mismos datos
      if (type == 'pending')
        return ((item.total_quantity - item.executed_quantity) == 0)
        ? (item.total_quantity - item.executed_quantity).toFixed()
        : (item.total_quantity - item.executed_quantity).toFixed(2)

      return (item.executed_quantity == null || item.executed_quantity == 0) ? 0 : (item.executed_quantity * 1).toFixed(2)

    },
  }
}
</script>

<style lang="scss" scoped>
table, tr {
  border: 1px solid #ADC2EA;
}

span.fa {
  transition: all 400ms;
}
span.fa:hover {
  cursor: pointer !important;
  color: #333;
}
.tr-selected > td:not(:first-child) {
  background-color: rgba(173, 194, 234, 0.174);
  // color: #fff;
}

</style>
