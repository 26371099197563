<template>
  <b-modal :active.sync="isModalActive" :width="640" scroll="keep">
    <div class="flex flex-col border border-blue-light rounded shadow bg-white p-4">

      <div class="flex flex-col w-full">
        <div class="uppercase font-semibold text-blue" v-if="!this.id">{{ $t('add_measurement_title') }}</div>
        <div class="uppercase font-semibold text-blue" v-else>{{ $t('add_subresource') }}</div>
        <div class="text-blue">Datos sobre el recurso</div>
      </div>

      <form @submit.prevent="submitForm">

        <div class="flex flex-col w-full mt-4">

          <div class="flex flex-row">
            <div class="flex flex-col w-1/3 mr-2">
              <div class="text-xs text-blue font-medium">{{ $t("code") }}</div>
              <div class="flex flex-row w-full">
                <input
                  v-model="form.code"
                  title="code"
                  required="true"
                  name="code"
                  class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
                />
              </div>
            </div>

            <div class="flex flex-col w-2/3 ml-2">
              <div class="text-xs text-blue font-medium">{{ $t("name") }}</div>
              <div class="flex flex-row w-full">
                <input
                  v-model="form.name"
                  title="name"
                  required="true"
                  name="name"
                  class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-row mt-4">

              <div class="w-1/4 flex flex-col">
                <div class="text-xs text-blue font-medium">{{ $t('measurement_unit')}}</div>
                <div class="flex flex-row w-full">
                  <select
                    v-model="form.measure_unit_id"
                    required="true"
                    :disabled="isMeasurementUnitsDisabled"
                    class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
                  >
                  <option
                    v-for="option in unitsList"
                    :value="option.id"
                    :key="option.id"
                  >{{ option.name }}
                  </option>
                </select>
                </div>
              </div>

              <div class="w-1/4 flex flex-col mx-4">
                <div class="text-xs text-blue font-medium capitalize">{{ $t("units") }}</div>
                <div class="flex flex-row w-full">
                  <input
                    v-model="form.total_units"
                    title="name"
                    required="true"
                    name="name"
                    class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
                  />
                </div>
              </div>

              <div class="w-1/4 flex flex-col mr-2">
                <div class="text-xs text-blue font-medium">{{ $t("unit_price") }}</div>
                <div class="flex flex-row w-full">
                  <input
                    v-model="form.unit_price"
                    title="name"
                    required="true"
                    name="name"
                    class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
                  />
                </div>
              </div>

              <div class="w-1/4 flex flex-col ml-2">
                <div class="text-xs text-blue font-medium">{{ $t("unit_cost") }}</div>
                <div class="flex flex-row w-full">
                  <input
                    v-model="form.unit_cost"
                    title="name"
                    required="true"
                    name="name"
                    class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
                  />
                </div>
              </div>

          </div>

          <div class="flex flex-row mt-4">

              <div class="w-1/3 flex flex-col">
                <div class="text-xs text-blue font-medium capitalize">{{ $t("length") }}</div>
                <div class="flex flex-row w-full">

                  <input
                    v-model="form.length"
                    title="length"
                    name="length"
                    step=".01"
                    type="number"
                    class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
                  />

                </div>
              </div>

              <div class="w-1/3 flex flex-col mx-4">
                <div class="text-xs text-blue font-medium capitalize">{{ $t("width") }}</div>
                <div class="flex flex-row w-full">
                  <input
                    v-model="form.width"
                    title="width"
                    name="width"
                    step=".01"
                    type="number"
                    class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
                  />
                </div>
              </div>

              <div class="w-1/3 flex flex-col">
                <div class="text-xs text-blue font-medium capitalize">{{ $t("height") }}</div>
                <div class="flex flex-row w-full">
                  <input
                    v-model="form.height"
                    title="height"
                    name="height"
                    step=".01"
                    type="number"
                    class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
                  />
                </div>
              </div>

          </div>

          <div class="flex flex-row mt-4">
            <div class="w-1/3 flex flex-col">
              <div class="text-xs text-blue font-medium">Vincular orden de pedido</div>
              <div class="flex flex-row w-full">
                <select
                  v-model="form.work_order_id"
                  class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
                >
                  <option
                    v-for="option in orders"
                    :value="option.id"
                    :key="'order_'+option.id"
                  >{{ option.code }}
                  </option>
                </select>
              </div>
            </div>

            <div class="w-1/3 flex flex-col mx-4">
              <div class="text-xs text-blue font-medium capitalize">{{ $t("position") }}</div>
              <div class="flex flex-row w-full">

                <input
                  v-model="form.position"
                  title="position"
                  name="position"
                  class="w-full h-8 bg-grey-lighter border border-grey-light rounded p-1 text-xs"
                />

              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row justify-start w-full mt-6 items-center">
          <button class="btn btn-blue" type="submit">{{$t('save')}}</button>
        </div>
      </form>

    </div>
  </b-modal>
</template>

<script>
  import axios from "axios";
  import {mapGetters} from "vuex";

  export default {
    name: "assignment-modal",

    components: {},

    props: {
      entityId: {type: Number, required: true},
      entityType: {type: String, required: true},
    },

    data: () => ({
      id: null,
      isModalActive: false,
      isMeasurementUnitsDisabled: false,
      unitsList: [],
      orders: [],
      form: {},
      assignmentTypes: ["resource", "task"]
    }),

    computed: {
      ...mapGetters({
        project: "app/project",
        company: "app/company"
      })
    },

    methods: {
      /**
       * Envia el "formulario" con una nueva medición
       */
      async submitForm() {

        // Comprobamos si viene con el padre (submedicion)
        if(this.id != null){
          this.form.parent_assignment_id = this.id;
        }

        let url = "/api/v2/assignments/" + this.entityType + "/" + this.entityId;
        axios({
          method: "post",
          url: url,
          data: this.form
        })
          .then(response => {
            this.$toast.open({
              message: this.$t("assignment_insert_success"),
              type: "is-success",
              position: "is-top-right"
            });

            this.$emit("reload");
            this.hide();
          })
          .catch(error => {
            //Se produjo un error
            this.isLoading = false;
            if (error.response && error.response.status === 422) {
              //extraemos el primer error de validación
              error.error_msg =
                error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                  ][0];
            }

            // Mostramos el error
            this.$toast.open({
              message: error.error_msg || this.$t("assignment_insert_error"),
              type: "is-danger",
              position: "is-top-right"
            });
          });
      },

      /**
       * Comprueba si los datos son de tipo recurso
       */
      isResource() {
        if (this.form.assignmentType == "resource") return true;

        return false;
      },

      /**
       *
       */
      isTask() {
      },

      /**
       * Pide la lista de unidades para el proyecto actual
       */
      getProjectUnits() {
        const {data} = axios
          // .get("/api/v2/project/" + this.project.id + "/measurement-units") // eliminar si tod ba bien
          .get("/api/v2/company/" + this.company.id + "/measurement-units")
          .then(response => {
            if (response.data && response.data.success) {
              this.unitsList = response.data.units;
            } else {
              this.$notify.error("error_loading_measurement_units_data");
            }
          });
      },

      async getWorkOrders() {
        let {data} = await axios.get('/api/v2/work-orders', {params: {
          project_id: this.project.id
        }});

        if (data.success) {
          this.orders = data.data
        } else {
          this.$notify.error("error_loading_data");
        }
      },

      /**
       * Abre este modal
       */
      show(assignment = null) {
        this.getProjectUnits();
        this.getWorkOrders();
        this.form = this.defaultFormValues();
        this.isModalActive = true;

        if(assignment){
          this.id = assignment.id;
          this.form.measure_unit_id = assignment.unit.id;
          this.form.work_order_id = assignment.order.id
          this.isMeasurementUnitsDisabled = true;
        }

      },

      /**
       * Valores por defecto pra el form
       */
      defaultFormValues() {
        return {
          code: "",
          name: "",
          weight: "",
          assignmentType: "resource",
          measure_unit_id: null,
          parent_assignment_id: null,
          total_units: null,
          description: "",
          width: "",
          height: "",
          length: ""
        };
      },

      /**
       * Oculta este formulario
       */
      hide() {
        this.id = null;
        this.isModalActive = false;
        this.form = this.defaultFormValues();
      }
    }
  };
</script>
<style scoped>
  /* Oculta los botones para aumentar los inputs numéricos */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
</style>
