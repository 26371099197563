<template>
<b-field
  :label="label ? $t(label) : null"
  :type="form.errors.has(field) ? 'is-danger' : ''"
  :message="form.errors.has(field) ? form.errors.get(field) : null">
  <b-select ref="input"
    v-model="form[field]"
    :placeholder="$t(_placeholder)"
    :size="size"
    :icon="icon"
    :icon-pack="iconPack"
    :expanded="full"
    :disabled="disabled"
    @input="input">
  >
    <option v-for="option in options"
      :value="option[optionIdField]"
      :key="option[optionIdField]"
    >
      {{ option[optionDisplayField] }}
    </option>
  </b-select>
</b-field>
</template>

<script>
export default {
  name: 'l-form-select',

  props: {
    // The vform object
    form: { type: Object, required: true },
    // The field name
    field: { type: String, required: true },
    // Options
    options: { type: Array, required: true },
    // Option ID field name (from the 'options' array)
    optionIdField: { type: String, default: 'id' },
    // Option display field name (from the 'options' array)
    optionDisplayField: { type: String, default: 'name' },
    // Key for the label text
    label: { type: String, default: null },
    // Key for the input placeholder text
    placeholder: { type: String, default: null },
    // Disabled select
    disabled: { type: Boolean, default: false },
    // Input size class
    size: { type: String, default: null },
    // Icon name
    icon: { type: String, default: null },
    // Icon pack (fa, fas, far, mdi)
    iconPack: { type: String, default: 'fas' },
    // Full width
    full: { type: Boolean, default: false },
    // Translate options
    translate: { type: Boolean, default: false },
  },

  computed: {
    _placeholder() {
      return this.placeholder ? this.placeholder : this.label
    }
  },

  mounted() {
    if (this.translate) {
      for (var i = this.options.length - 1; i >= 0; i--) {
        this.options[i][this.optionDisplayField] = this.$t(this.options[i][this.optionDisplayField])
      }
    }
  },

  methods: {
    setFocus() {
      this.$refs.input.focus()
    },
    input() {
      this.$emit('input');
    }
  },

};
</script>
