<template>
<div>
  <div class="w-full h-8 flex items-center mb-2 overflow-visible">
    <b-icon :icon="icon" class="w-6 h-6 text-blue mr-2"/>
    <label class="font-semibold text-grey-darker">{{ $t(title) }}</label>
    <button ref="addButtom"
      v-show="showAddButton"
      @click="onClickAddButton"
      class="text-red ml-2">
      <b-icon icon="plus-circle" size="is-large" />
    </button>
    <b-modal :active.sync="showSearchBox" has-modal-card>
      <usersearchmodal
        v-if="showSearchBox"
        :allow-external-users="allowExternalUsers"
        :allow-create-users="allowCreateUsers"
        :parent-element="$refs.addButtom"
        @close="showSearchBox = false"
        @user-selected="addUser($event)"
        @create-user="createUser($event)" />
      </b-modal>
  </div>
  <transition
    name="custom-classes-transition"
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
    duration="200">
    <userboxes
      v-show="showUserBoxes"
      :show="showUserBoxes"
      ref="userbox"
      :usersList="usersList"
      :disabled="disabled"
      :addSignatories="addSignatories"
      :allow-zero-users="_allowZeroUsers"
      @removeButtonClicked="onClickRemoveButton($event)"
      @removeSignatoryButtonClicked="onClickRemoveSignatoryButton($event)"
      @addSignatoryButtonClicked="onClickAddSignatoryButton($event)"
      class="w-full">
      <slot></slot>
    </userboxes>
  </transition>
</div>
</template>

<script>
import axios from 'axios'
import Userboxes from './UserBoxes.vue'
import Usersearchmodal from './UserSearchModal.vue'

export default {
  components: {
    Userboxes,
    Usersearchmodal,
  },

  props: {
    title: { type: String, required: true },
    icon: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    // La url base para llamadas a la API
    apiUrl: { type: String, required: true },
    // El id de la entidad padre del componente (p.e. área, proyecto, etc.)
    parentEntityId: { type: Number, default: null },
    // Id del proveedor necesario al agregar colaborador
    providerId: { type: Number, default: null },
    // El tipo de permiso asociado al usuario
    usersPermissionType: { type: String, required: true,
      validator: value => {
        return ['admin', 'create', 'collaborate'].includes(value)
      } },
    // Permitir añadir/crear/invitar usuarios colaboradores
    allowExternalUsers: { type: Boolean, default: false },
    // Presenta el botón para crear nuevos usuarios
    allowCreateUsers: { type: Boolean, default: true },
    // Permitir cero usuarios
    allowZeroUsers: { type: Boolean, default: false },
    // Sólo permite añadir 1 usuario (quita el botón de añadir cuando ya hemos añadido uno)
    onlyOneUser: { type: Boolean, default: false },
    // Modo LOCAL: no envía operaciones al back (CREATE,DELETE), sólo almacena
    // en la lista del componente los usuarios
    localMode: { type: Boolean, default: false },
    //Agrega el icono para poder asociar más firmantes
    addSignatories: { type: Boolean, default: false },
  },

  data: () => ({
    usersList: [],
    showAddButton: true,
    showUserBoxes: false,
    showSearchBox: false,
  }),

  computed: {
    _allowZeroUsers() {
      if (this.localMode) {
        // Forzamos esto porque cuando sólo permitimos 1 usuario
        // hemos de permitir a su vez eliminar el usuario que esté
        // para poder añadir uno nuevo (cambiar)
        return true
      } else {
        return this.allowZeroUsers
      }
    },
  },

  watch: {
    parentEntityId (newId, oldId) {
      if (newId !== oldId) {
        this.refreshData()
      }
    },
    usersList (newList) {
      this.showUserBoxes = (newList.length > 0)
      if (this.onlyOneUser) {
        this.showAddButton = !this.disabled && (newList.length == 0)
      }
    }
  },

  mounted() {
    this.showAddButton = !this.disabled
    if (! this.localMode) {
      this.refreshData()
    }
  },

  methods: {
    // Carga inicial de la lista de usuarios desde el back
    async refreshData() {
      if (!this.parentEntityId){
        return false;
      }
      this.usersList = []
      const { data } = await axios.get(this.getRefreshDataUrl())
      if (data && data.success) {
        this.usersList = data.users_list
      }
    },

    // Al pulsar el botón de añadir usuario
    onClickAddButton() {
      this.$emit('addButtonClicked')
      this.showSearchBox = true
    },

    // Evento al pulsar enter o click en un usuario de la lista
    // de búsqueda
    addUser(user) {
      this.showSearchBox = false
      console.log('off')
      this.__addUser({ 'user_id': user.id , 'provider': this.providerId})
    },

    // Evento para añadir y crear un nuevo usuario
    createUser(userData) {
      this.__addUser({
        'create': true,
        'name': userData.name,
        'surname': userData.surname,
        'email': userData.email,
      })
    },

    // Envía los datos del usuario a añadir a la lista
    async __addUser(userData) {
      // Local mode
      if (this.localMode) {
        var user = null
        if ('create' in userData) {
          const { data } = await axios.get('/api/v2/user/add', userData)
          if (data && data.success) {
            user = data.data
          }
        } else {
          const { data } = await axios.get('/api/v2/user/' + userData.user_id + '/?basic=1')
          if (data) {
            user = data
          }
        }
        if (user !== null) {
          // Añadimos el usuario a la lista local (si no está ya)
          if (! this.__checkIfUserExists(user.user_id)) {
            this.__addUserToList(user)
          }
        } else {
          // error
          this.__showErrorAddingUserToList()
        }
        return
      }

      // Con la API del back
      let url = this.getBaseUrl() + '/users/add'
      userData['permission_type'] = this.usersPermissionType
      const { data } = await axios.post(url, userData)
      if (data && data.success) {
        // Evitamos añadir un usuario que ya está en la lista
        if (! this.__checkIfUserExists(data.user.user_id)) {
        // Añadimos el usuario a la lista
          this.__addUserToList(data.user)
          this.$toast.open({
            'message': this.$t('success_user_added_to_list'),
            'position': 'is-top-right',
            'type': 'is-success'
          })
        }
      } else {
        // error
        this.__showErrorAddingUserToList()
      }
      //this.refreshData()
    },

    // Al pulsar el botón para quitar un usuario
    onClickRemoveButton(index) {
      this.$emit('removeButtonClicked', index)
      this.removeUser(index)
    },

    // Al pulsar el botón para quitar un usuario
    onClickRemoveSignatoryButton(index) {
      console.log(index,"+++")
      this.$emit('removeSignatoryButtonClicked', index)
      //this.removeUser(index)
    },

    // Al pulsar el botón para quitar un usuario
    onClickAddSignatoryButton(index) {
      console.log(index,"==!-")
      this.$emit('addSignatoryButtonClicked', index)
      //this.removeUser(index)
    },

    // Quitamos un usario de la lista
    async removeUser(index) {
      // Local mode
      if (this.localMode) {
        this.__removeUserFromList(index)
        return
      }

      // Llamamos a la API para remover el usuario
      let userId = this.usersList[index].user_id
      let url = this.getBaseUrl() + '/users/remove'
      const { data } = await axios.post(url, {
        'user_id': userId,
        'provider': this.providerId,
        'permission_type': this.usersPermissionType
      })
      if (data && data.success) {
        this.__removeUserFromList(index)
      } else {
        this.$toast.open({
          'message': this.$t('error_remove_user_from_list'),
          'position': 'is-top-right',
          'type': 'is-danger'
        })
      }
    },

    // Devuelve la URL base para las peticiones a la API
    getBaseUrl() {
      return this.apiUrl + '/' + (this.parentEntityId ? this.parentEntityId : '')
    },

    // La URL para cargar la lista de usuarios
    getRefreshDataUrl() {
      return this.getBaseUrl() + '/users?q=' + this.usersPermissionType + '&provider=' + this.providerId
    },

    // Añade un usuario a la lista local
    __addUserToList(user) {
      this.usersList.unshift(user)
    },

    // Quita un usuario de la lista local
    __removeUserFromList(index) {
      this.usersList.splice(index, 1)
    },

    // Comprueba que el usuario no exista ya en la lista
    __checkIfUserExists(user_id) {
      for (var i = this.usersList.length - 1; i >= 0; i--) {
        if (this.usersList[i].user_id == user_id) {
          this.$toast.open({
            'message': this.$t('user_already_in_list'),
            'position': 'is-top-right',
            'type': 'is-info'
          })
          return true
        }
      }
      return false
    },

    // Muestra el error al añadir el usuario
    __showErrorAddingUserToList() {
      this.$toast.open({
        'message': this.$t('error_user_added_to_list'),
        'position': 'is-top-right',
        'type': 'is-danger'
      })
    },

  },

};
</script>
