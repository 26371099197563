<template>
<b-tooltip :label="$t(tooltipLabel)" position="is-right">
  <span class="rounded-full" :class="[statusColor, 'w-'+size, 'h-'+size, 'inline-block']"></span>
</b-tooltip>
</template>

<script>
import Vue from "vue"
import moment from 'moment'
Vue.use(moment)

export default {
  name: 'activity-time-status',

  props: {
    date: { type: String, required: true },
    size: { type: String, default: "4" },
    status: { type: Number, default: -1 },
  },

  data: () => ({
    statusColor: 'bg-green',
    tooltipLabel: 'activity_time_status_ok'
  }),

  mounted () {
    this.setStatus()
  },

  watch: {
    date(value) {
      this.setStatus()
    },
  },

  methods: {
    setStatus() {
      let diff = this.diffDate()
      if (diff > 3) {
        // verde salvo actividad iniciada con retraso que la marcamos naranja
        this.statusColor = this.status == 7 ? 'bg-orange' : 'bg-green'
        this.tooltipLabel = 'activity_time_status_ok'
      } else if (diff <=3 && diff > 0) {
        this.statusColor = 'bg-orange'
        this.tooltipLabel = 'activity_time_status_warning'
      } else {
        this.statusColor = 'bg-red'
        this.tooltipLabel = 'activity_time_status_danger'
      }
    },
    diffDate() {
      let end = moment(this.date, "DD/MM/YYYY")
      let now = moment()
      let duration = moment.duration(end.diff(now));
      return duration.asDays()
    },
  },

};
</script>
