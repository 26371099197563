<template>
  <section>
    <div v-if="isLoading" class="my-4 text-center">
      <scale-loader></scale-loader>
    </div>
    <div v-else>
      <div v-if="!this.companies || this.companies.length == 0" class="mt-16">
        <p class="text-3xl text-center text-grey">
          <b-icon pack="fas" icon="ban" size="is-large"/>
          No tiene proyectos / actividades asignadas
        </p>
      </div>
      <template v-else>
        <div>
          <div class="relative flex flex-col w-full z-0 sm:flex-row">
            <div class="w-full sm:w-1/4 sm:mt-4 sm:ml-2">
              <b-input
                  v-model="search" :placeholder="'Buscar empresa'"
                  type="search" icon="search" icon-pack="fas">
              </b-input>
            </div>
            <div class="w-full sm:w-1/4">


            </div>
          </div>

          <div class="flex w-full mt-3 sm:mt-6">

              <div v-if="filteredCompanies().length > 0" class="w-full flex flex-wrap justify-between-">
                <div
                  v-for="company in filteredCompanies()"
                  :key="company.id"
                  class="flex-initial w-1/4 h-72 p-2 border rounded shadow cursor-pointer border-blue-lighter epc-card"
                >
                  <!-- <div class="items-center w-full h-64 p-4 border-b rounded border-grey-lighter bg-blue-lightest"> -->

                    <div class="text-center hover:bg-grey-lightest" @click="selectCompany(company)">
                      <span class="p-2 font-bold uppercase text text-blue">{{ company.name }}</span>
                    </div>
                    <div class="h-1/2 hover:bg-grey-lightest" @click="selectCompany(company)">
                      <img :src="'//' + s3bucket + '/' + company.alias + '/company_logo.jpg'"
                        class="flex-shrink object-cover w-full h-full" >
                    </div>
                    <div class="h-1/2 m-1 text-center">
                      <span class="p-2 mb-2 font-bold uppercase text text-blue">PROYECTOS</span>
                      <div v-if="company.projects.length > 0" class="overflow-y-auto h-20">
                        <small v-for="project in company.projects" @click="selectProject(project, company)" style="display:block" class="text-grey-darker hover:bg-grey-lightest">{{ project.name }}</small>
                      </div>
                      <div v-else class="h-20">
                        <small class="text-grey" >Sin proyectos...</small>
                      </div>
                    </div>
                  <!-- </div> -->
                </div>
              </div>
              <div v-else class="w-full text-center">
                No hay resultados con <strong>"{{ search }}"</strong>
              </div>
          </div>
        </div>

      </template>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  middleware: "auth",
  title: "select_group_page_title",
  subtitle: "select_group_page_subtitle",
  menuOption: "2-0",
  components: {
    ScaleLoader
  },

  data: () => ({
    s3bucket: window.config.s3bucket,
    companies: null,
    isLoading: true,
    search: '',
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  created() {
    this.getCompanies();
  },

  methods: {

    async getCompanies() {
      const { data } = await axios.get("/api/v2/user/companies", {'params' : { withProjects: 1 }});

      this.$store.dispatch("app/setCompanies", { companies: data.companies } );

      // siempre cargamos los projectos que contiene cada company
      this.companies = data.companies
      for (let i = 0; i < data.companies.length; i++) {
        data.companies[i]['projects'] = await this.getProjects(data.companies[i].id)
      }
      this.isLoading = false;

      if( this.companies.length == 1 ) {
        // si solo es una company lo seteamos por defecto y redirigimos para que pueda seleccionar proyectos
        this.$store.dispatch("app/setCompany", { company: data.companies[0] });
        this.$router.push({ name: "group.select" }); // si tiene varias company y se selecciona por defecto la del usuario poner afuera
      } else {
        // si se opta por seleccionar la company del usuario por defecto descomentar y comentar las lineas siguiente
        // this.$store.dispatch("app/setCompany", { company: this.user.company });

        // si tiene varias companias redirigimos a seleccionar solo si no se encuentra en la ruta correspondiente
        // el if evita que se genere error NavigationDuplicated Navigating to current location cuando se encuentra
        // en la ruta
        if( this.$router.history.current.name != 'company.select' )
          this.$router.push({ name: "company.select" });
      }


      // if (data.companies.length > 1) {
      //   this.companies = data.companies
      //   for (let i = 0; i < data.companies.length; i++) {
      //     data.companies[i]['projects'] = await this.getProjects(data.companies[i].id)
      //   }
      //   this.isLoading = false;
      // } else {
      //   this.$store.dispatch("app/setCompany", { company: data.companies[0] });
      //   this.$router.push({ name: "group.select" });
      // }
    },

    async selectCompany(company) {
      await this.$store.dispatch("app/setCompany", { company });
			await this.$store.dispatch("app/setGroup", { group: null });
      await this.$router.push({ name: "group.select" });
			await this.$store.dispatch("app/setProject", { project: null }); // primero el router, para q resetear el project no de undefined por ahi

    },

    filteredCompanies() {
      return this.companies.filter(company => {
        return company.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },

    async getProjects(id) {
      const { data } = await axios.get("/api/v2/user/projects", {'params' : { companyId: id, withConfigs: 1 }});
      return data.projects || []
    },

    async selectProject(project, company) {
      await this.$store.dispatch("app/setCompany", { company });
      await this.$store.dispatch("app/setProject", { project });
      let tags = project.tags
      await this.$store.dispatch("app/setTags", { tags });
      await this.getVisualConfig(project)
      this.$router.push({ name: "project.home" });
      this.setCurrentCompany(company);
    },

    async getVisualConfig(project) {
      let configs = {}
      if (project.configs) {
        configs = project.configs
      }
      await this.$store.dispatch("app/setVisualConfig", {config: configs});
    },

    // set current company in backend's session. Para que la ruta a los documentos sea la correcta al cambiar de company (se guarda en la sesión)
    async setCurrentCompany(company) {
      const { data } = await axios.post("/api/v2/user/set-company", { companyId: company.id  });

      if (!data.success) {
        // silent error
        console.log('Error setting current company in session', company)
      }
    },

  }
};
</script>

<style scoped>
.epc-card{
  box-sizing: border-box;
  margin: 1rem .25em;
  flex-basis: calc(100% - 0.5rem);
}

/* @media only screen and (min-width: 768px) and (max-width: 1024px){ */
@media only screen and (min-width: 480px) {
  .epc-card {
    flex-basis: calc(50% - 0.5rem);
  }

}

@media only screen and (min-width: 768px) {
  .epc-card {
    flex-basis: calc(33% - 0.5rem);
  }

}

@media only screen and (min-width: 1200px) {
  .epc-card {
    flex-basis: calc(25% - 0.5rem);
  }
}
</style>
