<template>
  <div class="relative mx-2 mt-2 rounded-xl_"
    :class="[ isLoading ? 'h-20' : '' ]">
    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
    <img v-if="!isLoading && thumbnailUrl != null"
      :src="thumbnailUrl"
      :alt="modelBim.original_filename"
    />
  </div>
</template>

<script type="module">

import axios from 'axios';

export default {
  middleware: 'auth',

  components: {

  },

  props: {
    modelBim: { type: Object, required: true }
  },

  data() {
    return {
      isLoading: false,
      thumbnailUrl: null,
      accessToken2:null,

      timerId: null,
    }
  },

  computed: {

  },

  created() {

  },

  mounted() {
    // this.retrieveDesignThumbnail();
    this.getStatus();
  },


  methods: {
    //checkTranslationStatus
    async getStatus() {
      this.isLoading = true;
      const apiUrl = `https://developer.api.autodesk.com/modelderivative/v2/designdata/${this.modelBim.urn}/manifest`;

      await this.getAccessToken()

      fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.accessToken2}`,
        },
      })
        .then((response) => {

          if( response.status === 200 ) {
            return response.json()
          }

          throw new Error('Error al obtener estado del translate.');
        })
        .then((data) => {

          if( data.status === 'success' ) {
            this.isLoading = false
            this.$emit('status-translation', { state: 'complete', progress: '100%' })
            this.retrieveDesignThumbnail();
          } else if( data.status === 'failed' ) {
            this.isLoading = false
            this.$emit('status-translation', { state: data.status, progress: data.progress })
            throw new Error('Translate failed.');
          } else {
            this.$emit('status-translation', { state: data.status, progress: data.progress })
            clearTimeout(this.timerId);

            this.timerId = setTimeout(() => {
              this.getStatus()
            }, 3000);
          }
        })
        .catch((error) => {
          this.isLoading = false
          console.error('Error retrieving design manifest:', error);
        });
    },

    async retrieveDesignThumbnail() {
      this.isLoading = true;
      const apiUrl = `https://developer.api.autodesk.com/modelderivative/v2/designdata/${this.modelBim.urn}/thumbnail`;

      await this.getAccessToken()

      fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${this.accessToken2}`,
        },
      })
        .then((response) => {
          this.isLoading = false
          if( response.status === 200 ) {
            return response.blob()
          }

          return null
        })
        .then(async (blob) => {
          this.thumbnailUrl = blob == null ? null : URL.createObjectURL(blob);
          // this.thumbnailUrl = blob == null ? null : await this.blobToBase64(blob);
        })
        .catch((error) => {
          this.isLoading = false
          console.error('Error retrieving design thumbnail:', error);
        });
    },

    getAccessToken: async function () {
      await axios.get('/api/v2/autodesk/forge/getToken')
        .then((response) => {
          // console.log('res: ', await data.json())
          let data = response.data
          if (! data.success) {
              throw new Error('Error al obtener token.');
          }

          this.accessToken2 = data.data.accessToken
        })
        .catch ((err) => {
          alert('Could not obtain access token. See the console for more details.');
          console.error(err);
        })
    },

    async blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    }
  },
};
</script>
