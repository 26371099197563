<template>
    <div class="page-with-top-bar">
        <div class="top-bar flex justify-end" >
            <!-- Volver -->
            <v-button
                    @click="$router.go(-1)"
                    color="grey"
                    size="small"
                    icon="arrow-left"
                    class="mr-8"
            >
                {{ $t('back') }}
            </v-button>
        </div>
        <section>
            <import-entities
                entity-type="users"
                :company-id="parseInt(this.company.id)"
            />
        </section>
    </div>
</template>

<script>
import ImportEntities from "../../components/import-entities";
import { mapGetters } from 'vuex';

export default {
  components: {
    ImportEntities,
  },
  middleware: "auth",
  title: "import_users",
  subtitle: 'import_users',
  menuOption: "2-0",

  data: () => ({
    importActiveTab: 0,
  }),

  methods: {
  },

  computed: {
    ...mapGetters({
      company: 'app/company',
    }),
  },
};

</script>
