<template>
<div class="modal-card" style="">
  <!-- Título -->
  <header class="modal-card-head">
    <p class="modal-card-title">{{ $t(creating ? 'create_new_user' : title) }}</p>
  </header>
  <!-- Contenido del modal -->
  <div class="modal-card-body">
    <!-- Template normal - cuando no se está invitando -->
    <template v-if="!creating">
      <!-- Input (typeahead) para buscar usuario -->
      <typeahead ref="input"
        :api-url="apiUrl"
        input-placeholder="search_user_placeholder"
        @esc-pressed="close"
        @item-selected="addUser($event)"
        :prepare-result-node="prepareResultNode"
        :select-first="true"
        class="w-full pb-4" />
      <!-- Boton al pie -->
      <!-- Juanjo 17/07/2018 - desactivada la opción de crear usuario -->
      <!--<v-button v-if="allowCreateUsers" full @click="startCreating(true)">
        {{ $t('create_new_user') }}
      </v-button>-->
    </template>
    <!-- Cuando se está invitando -->
    <template v-else>
      <form @submit.prevent="createUser" @keydown="createForm.onKeydown($event)" class="mt-4">
        <div class="flex w-full mb-4">
          <l-form-input ref="name"
            :form="createForm"
            field="name"
            placeholder="name"
            class="w-1/2 mr-1"
            @keydown.esc.native="close"
          />
          <l-form-input
            :form="createForm"
            field="surname"
            placeholder="surname"
            class="w-1/2 ml-1"
            @keydown.esc.native="close"
          />
        </div>
        <l-form-input
          :form="createForm"
          field="email"
          placeholder="email"
          inputType="email"
          class="w-full mb-4"
          @keydown.esc.native="close"
        />
        <div class="w-full flex">
          <v-button
            type="submit"
            class="w-1/2 mr-2"
            :class="{ shake: animateError, animated: animateError }"
          >
            {{ $t('send_user_invitation') }}
          </v-button>
          <v-button
            class="w-1/2 mr-2"
            color="grey"
            @click="startCreating(false)"
          >
            {{ $t('cancel') }}
          </v-button>
        </div>
      </form>
    </template>
  </div>
</div>
</template>

<script>
import Typeahead from './Typeahead'
import Form from 'vform'

export default {
  components: {
    Typeahead,
  },

  props: {
    baseUrl: { type: String, required: true },
    title: { type: String, default: 'assign_user' },
    allowExternalUsers: { type: Boolean, default: false },
    allowCreateUsers: { type: Boolean, default: true },
    onlyInternalUsers: { type: Boolean, default: false, required: false },
    onlyExternalUsers: { type: Boolean, default: false, required: false },
    allowCompanyUsers: { type: Boolean, default: false, required: false },
    projectId: { type: Number, default: null, required: false },
    roleName: { type: String, default: null, required: false },
    departmentId: { type: Number, default: null, required: false },
    activityId: { type: Number, default: null, required: false },
    isSubactivity: { type: Boolean, default: false, required: false },
    parentElement: { required: true },
    isReplacement: { type: Boolean, default: false, required: false },
    isProject: { type: Number, default: 0, required: false }, //flag que dice si estamos editando un project (isProject=idproject) o otra cosa (isProject=0)
    validationUsers: { type: Boolean, default: false, required: false }, // flag para buscar usuarios de los grupos de validacion (que tendra cierta logica distinta)
    isIssue: { type: Boolean, default: false, required: false },
  },

  data: () => ({
    creating: false,
    createForm: new Form({
      name: '',
      surname: '',
      email: '',
    }),
    fieldsWithErrors: null,
    animateError: false,
  }),

  computed: {
    apiUrl() {
      // is es issue ocupamos la ruta de actividad /issue/search-managers sino toma ruta por defecto
      let baseUrl = this.isIssue ? this.baseUrl : this.baseUrl + '/users/search'
      let urlParam = ''
      if (! this.allowExternalUsers) {
        baseUrl = baseUrl + '?f=internal'
      }

      if (this.onlyInternalUsers) {
        baseUrl = baseUrl + (baseUrl.indexOf("?") !== -1 ? '&only_internal=1' : '?only_internal=1')
      }

      if (this.onlyExternalUsers) {
        baseUrl = baseUrl + (baseUrl.indexOf("?") !== -1 ? '&only_external=1' : '?only_external=1')
      }

      if (this.allowCompanyUsers) {
        baseUrl = baseUrl + (baseUrl.indexOf("?") !== -1 ? '&allow_company_users=1' : '?allow_company_users=1')
      }

      // busca usuarios que pertenezcan a este proyecto (cualquier rol)
      if (this.projectId !== null) {
        urlParam = baseUrl.indexOf("?") !== -1 ? '&p=' : '?p='
        baseUrl = baseUrl + urlParam + this.projectId
      }

      // Busca usuarios que pertenezcan a este proyecto con un rol especifico
      // Por defecto filtrar por cualquier rol y si llega el parametro envia el rol por el cual filtrar
      if (this.projectId !== null && this.roleName !== null){
        urlParam = baseUrl.indexOf("?") !== -1 ? '&role=' : '?role='
        baseUrl = baseUrl + urlParam + this.roleName
      }

      // busca colaboradores que pertenezcan a algun partner de este proyecto (logica distinta, uso nombre parametro distinto)
      if (this.isProject !== 0){
        urlParam = baseUrl.indexOf("?") !== -1 ? '&editingProject=' : '?editingProject='
        baseUrl = baseUrl + urlParam + this.isProject
      }

      // Si estamos reemplazando usuarios, no filtramos por departamento
      if (this.departmentId !== null && ! this.isReplacement){
        urlParam = baseUrl.indexOf("?") !== -1 ? '&d=' : '?d='
        baseUrl = baseUrl + urlParam + this.departmentId
      }

      if (this.validationUsers) {
        baseUrl = baseUrl + (baseUrl.indexOf("?") !== -1 ? '&validationUsers=1' : '?validationUsers=1')
      }

      return baseUrl
    },
  },

  methods: {
    close() {
      // Cerramos el modal
      this.$emit('close')
    },
    prepareResultNode(item) {
      return '<span class="w-full block font-bold">' + item.name + ' ' + item.surname + '</span><span class="w-full block italic text-xs">(' + item.email + ')</span>'
    },
    addUser(user) {
      this.$emit('user-selected', user)
      this.close()
    },
    startCreating(start) {
      this.creating = start
      // Reposicionamos el modal
      // this.$refs.modal.updatePosition()
    },
    createUser() {
      // Validamos los datos
      this.fieldsWithErrors = null
      let errors = this.validateData()
      if (!errors) {
        this.$emit('create-user', this.createForm)
        this.close()
      } else {
        this.fieldsWithErrors = errors
        this.animateError = true
        this.$toast.open({
          'message': this.$t('fix_the_fields'),
          'position': 'is-top-right',
          'type': 'is-warning'
        })
        setTimeout(() => {
          this.animateError = false
        }, 1000)
      }
    },
    validateData() {
      var errors = []
      let name = this.createForm.name.trim()
      if (!name || name.length > 255) {
        errors.push('name')
      }
      let surname = this.createForm.surname.trim()
      if (!surname || surname.length > 255) {
        errors.push('surname')
      }
      let email = this.createForm.email.trim()
      if (!email || email.length > 255 || !this.__validateEmail) {
        errors.push('email')
      }
      return (errors.length > 0) ? errors : false
    },
    __validateEmail(email) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email.toLowerCase())
    }
  },

  mounted () {
    // this.$refs.modal.show()
    this.$nextTick(() => {
      // Foco al input de búsqueda
      this.$refs.input.setFocus()
    })
  },

};
</script>
