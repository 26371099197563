<template>
<div>
  <div class="flex">
    <!-- Tabla de Drawings -->
    <div class="panel">
      <div v-if="isLoading" class="my-4 text-center">
        <scale-loader></scale-loader>
      </div>
      <div v-else>
        <div class="flex flex-wrap w-full mt-4">
        <section class="w-full">
          <b-table
            icon-pack="fas"
            :data="documents"
            :paginated="isPaginated"
            :per-page="perPage"
            :bordered="true"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :default-sort-direction="defaultSortDirection"
            v-if="!isLoading"
            checkable
            checkbox-position="left"
            :checkbox-type="'is-success'"
            :checked-rows.sync="checkedRows"

            @check="handleCheck"
            @check-all="handleCheckAll"

            :row-class="assignRowClass"
          >
            <template>
              <b-table-column field="thumbnail" cell-class="w-6 vertical-center" sortable>
                <template v-slot:header="">{{ $t('thumbnail') }}</template>
                <template v-slot="props">
                  <div class="flex flex-wrap justify-center">
                    <img
                      :src="props.row.file.thumbnail"
                      class="p-1 bg-white border rounded"
                      :alt="props.row.description"
                      onerror="this.src='https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/thumb.jpeg'"
                    />
                  </div>
                </template>
              </b-table-column>

              <b-table-column field="code" cell-class="vertical-center" sortable>
                <template v-slot:header="">{{ $t('code') }}</template>
                <template v-slot="props">{{ props.row.code }}</template>
              </b-table-column>

              <b-table-column field="name" cell-class="vertical-center" sortable>
                <template v-slot:header="">{{ $t('name') }} / {{ $t('description') }}</template>
                <template v-slot="props">{{ props.row.name }}<br> {{ props.row.description }}</template>
              </b-table-column>

              <b-table-column field="file" cell-class="w-6 vertical-center" centered v-if="withActions">
                <template v-slot:header="">{{ $t('file') }}</template>
                <template v-slot="props">
                  <b-tooltip :label="$t('download_document')" position="is-top">
                    <div class="cursor-pointer text-blue-light">
                        <a class="button is-rounded is-link" @click="downloadDoc(props.row.document_id)">
                            <span class="icon">
                              <i class="fas fa-file"></i>
                            </span>
                        </a>
                    </div>
                  </b-tooltip>
                </template>
              </b-table-column>

              <b-table-column field="actions" cell-class="vertical-center" width=100 sortable v-if="withActions">
                <template v-slot:header="">{{ $t('actions') }}</template>
                <template v-slot="props">
                  <div class="flex items-center justify-around w-full h-full">

                    <b-tooltip :label="'editar'" position="is-right" v-if="withEditor && showPdfEditor">
                      <div class="w-8 h-8 overflow-hidden" @click="openEditor(props.row)">
                        <b-icon pack="fas" icon="pen" />
                      </div>
                    </b-tooltip>

                    <b-tooltip :label="$t('delete')" position="is-left" type="is-danger">
                        <div @click="removeDocument(props.row)"
                            class="flex cursor-pointer text-red">
                          <b-icon pack="fas" icon="trash-alt"/>
                        </div>
                    </b-tooltip>

                    <b-tooltip :label="$t('view_document')" position="is-left" v-if="props.row.mime_type === 'application/pdf'">
                        <!-- <div @click="onRowClicked(props.row)"
                            class="flex cursor-pointer text-sky-300">
                          <b-icon pack="fas" icon="eye"/>
                        </div> -->
                        <div @click="onShowViewerPdfClicked(props.row)"
                            class="flex cursor-pointer text-sky-300">
                          <b-icon pack="fas" icon="eye"/>
                        </div>
                    </b-tooltip>

                    <b-tooltip :label="$t('view_assignments')" position="is-left">
                        <div @click="assignedActivities(props.row)"
                            class="flex cursor-pointer text-sky-300">
                          <b-icon pack="fas" icon="link"/>
                        </div>
                    </b-tooltip>

                  </div>
                </template>
              </b-table-column>
            </template>

            <template slot="bottom-left">
                <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                <b>{{ $t("total") }}</b>: {{ this.documents.length }}
            </template>

            <template #empty>
                <div class="has-text-centered">{{ $t('no_results') }}</div>
            </template>

          </b-table>
        </section>
        </div>
      </div>
    </div>
  </div>
  <div v-if="withActions">
    <viewer-pdf ref="modalViewerPdf" :fileName="fileName"></viewer-pdf>

    <!-- Modal para EDITAR PDF -->
    <entity-document-editor v-if="withEditor"
        :entity-id="projectId"
        :entity-type="'project'"
        ref="editorModal"
        @reload-documents-list="() => $emit('reload-documents-list')"
    />
  </div>
</div>
</template>

<script>
import Form from "vform";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import ViewerPdf from "../../components/viewer-pdf.vue";
import axios from "axios";
import { mapGetters } from 'vuex';
import entityDocumentEditor from "·/components/entity-document-editor";

export default {
  name: "project-documents-manual",

  components: {
    ViewerPdf,
    ScaleLoader,
    entityDocumentEditor,
  },

  props: {
    projectId: { type: Number, required: true },
    documentType: { type: String, required: true },
    withEditor: { type: Boolean, default: true }, // show pdfEditor (if company has permission)
    withActions: { type: Boolean, default: true }, // show actions (download, edit remove, etc...)
    rowsSelected: { type: Array, default: () => [] }
  },

  data: () => ({
    isLoading: true,
    page: 1,
    perPage: 25,
    total: 0,
    orderField: "reference_code",
    orderDir: "asc",
    currentPage: 1,
    defaultSortDirection: 'asc',
    isPaginationSimple: false,
    isPaginated: true,

    documents: [],
    checkedRows: [],


    // values
    documentId: null,

    valuesInEdition: [], // valor que estan en modo edicion. Editor inline
    disableSaveButton: false,
    loaded: false,
    loadingAssigned: false,
    processingData: false,

    showPanel: 'none',

    fileName: '',
    errorFields: {},

    showPdfEditor: false, // determines if company has this module available
  }),


  watch: {
    rowsSelected: function(newVal, oldVal) { // watch it
      this.checkedRows = newVal
    }
  },

  mounted() {
    this.getProjectDocuments();

    this.path = window.location.origin + '/' + this.path;
    this.errorFields = {
      activity: 'DSO',
      validation: this.$t('validation_flow'),
      validationTask: this.$t('task')
    };

    // Check module pdfEditor availability for this company
    if (this.company.configs
      && this.company.configs.modules
      && this.company.configs.modules.includes('pdfEditor')) {
        this.showPdfEditor = true;
    } else {
        this.showPdfEditor = true; // always true for now...
    }
  },

  computed: {
    ...mapGetters({
      company: 'app/company',
    }),
  },

  methods: {
    async getProjectDocuments() {
      this.isLoading = true;
      let params = {
        text: '',
        with: this.documentType
      };

      const { data } = await axios.get(
        "/api/v2/project/" + this.projectId + "/documents",
        { params: params }
      );

      if (data && data.success) {
        this.documents = data.documents;
        this.total = data.documents ? data.documents.length : 0;
      } else {
        this.$notify.error("error_loading_documents");
      }
      this.isLoading = false;
    },

    reload() {
      this.getProjectDocuments();
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    onPageChange(page) {
      this.page = page;
      this.reload();
    },

    onSort(field, order) {
      this.orderField = field;
      this.orderDir = order;
      this.reload();
    },

    async removeDocument(field) {
      this.$dialog.confirm({
        title: this.$t("delete"),
        message: this.$t("delete_generic", [field.name]),
        confirmText: this.$t("delete"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          this.documentId = null

          this.isLoading = true;
          let url = "/api/v2/project/" + this.projectId + '/document/' + field.document_id;
          const { data } = await axios.post(url);

          if (data.success) {
            this.$toast.open({
              message: this.$t("success_deleting_generic"),
              type: "is-success",
              position: "is-top-right"
            });
            await this.sleep(1000);
            this.reload();
          } else {
            this.isLoading = false;
            // Error
            this.$toast.open({
              message: data.error || this.$t("error_saving"),
              type: "is-danger",
              position: "is-top-right"
            });
          }
        }
      });
    },

    onCloseClicked() {
        this.documentId = null;
        this.checkedRows = [];
    },

    async readDocument(params) {

      if(this.documentId){

        if( this.showPanel === 'assigned' )
          this.loadingAssigned = true;
        else
          this.loaded = true;

        const { data } = await axios.get(
          "/api/v2/document/" + this.documentId + '/selectable-list',
          { params: params }
        );

        if (data && data.success) {
          this.form = data.document;
        } else {
          this.$notify.error("error_retrieving_data");
        }

        if( this.showPanel === 'assigned' )
          this.loadingAssigned = false;
        else
          this.loaded = false;
      }

    },

    onRowClicked(field, access = true) {
      if (access) {
        this.documentId = field.document_id;
        this.showPanel = 'values';
        this.readDocument( { } );
      }
    },

    async assignedActivities(field) {
      this.documentId = field.document_id;
      this.showPanel = 'assigned';
      this.readDocument( { with: 'assigned' } );
    },

    // value
    // redirige a pantalla edicion
    editValue(valueId) {
      this.valuesInEdition.push(valueId) // edicion inline
    },

    async downloadDoc(document_id) {
      this.$notify.success("starting_download_document");
      let url = "/api/v2/document/" + document_id;

      const {data} = await axios.get(url);

      if (data) {
        window.open(data.data);
      } else {
        this.$notify.error("error_download_document");
      }
    },

    onShowViewerPdfClicked(document) {
      // adds timestamp to url to avoid showing cached previous versions
      let fileNameWithTimestamp = document.file.raw.includes('?')
        ? document.file.raw + '&' + Date.now()
        : document.file.raw + '?' + Date.now()
      this.fileName = fileNameWithTimestamp
      this.$refs.modalViewerPdf.show();
      this.$refs.modalViewerPdf.getPdf();
    },

    openEditor(document) {
      this.$refs.editorModal.show(document)
    },

    handleCheck(checkedList, row) {
      this.dispatchSelected(checkedList)
    },

    handleCheckAll(checkedList) {
      this.dispatchSelected(checkedList)
    },

    dispatchSelected(checkedList) {

      this.$emit('selected-documents', checkedList)
    },

    assignRowClass(row, index ) {
     if( this.withActions )
        return ''

      return this.checkedRows.find( r => r.document_id == row.document_id ) ? 'is-hidden': ''
      // (row, index) => row.id === 1 ? 'is-hidden' : ''
    },
  }
};
</script>

<style scoped>
  .b-table::v-deep .table .checkbox-cell {
    vertical-align: middle !important;
    text-align: center;
  }

  .b-table::v-deep .table .checkbox-cell .checkbox {
    background-color: white !important;
    padding: 0;
    margin: 0;
    border-radius: 5px;
    width: 20px;
  }

  .b-checkbox.checkbox:not(.button) {
    margin-right: 0 !important;
  }
</style>
