<template>
  <div class="page-with-top-bar">
    <project-admin-quick-buttons hiddenOption="drawings"/>
    <section>
      <div class="mt-4">
        <b-tabs type="is-boxed" v-model="mainActiveTab">
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file"></b-icon>
              <span> {{ $t('documents') }} </span>
            </template>

           <div class="flex items-center justify-end w-full">
              <v-button
                @click="onAddClicked('documents')"
                icon="plus"
              >{{ $t('add_document') }}</v-button>
            </div>

            <project-documents-manual
              ref="project_documents_manual"
              :projectId="projectId"
              :documentType="'documents'"
              :refModalName="'EditorDocument'"
            ></project-documents-manual>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-pdf"></b-icon>
              <span> {{ $t('drawings') }} </span>
            </template>

           <div class="flex items-center justify-end w-full">
              <v-button
                @click="onAddClicked('drawings')"
                icon="plus"
              >{{ $t('add_drawing') }}</v-button>
            </div>

            <project-documents-manual
              ref="project_drawings_manual"
              :projectId="projectId"
              :documentType="'drawings'"
              :withEditor="false"
              :refModalName="'EditorDrawing'"
            ></project-documents-manual>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="draw-polygon"></b-icon>
              <span> {{ $t('design_bim') }} </span>
            </template>

           <div class="flex items-center justify-end w-full">
              <v-button
                @click="onAddClicked('models_bim')"
                icon="plus"
              >{{ $t('add_design') }}</v-button>
            </div>

            <autodesk-models-list
              ref="autodesk_models_list"
              :documentType="'model'"
              :projectId="projectId"
            ></autodesk-models-list>
          </b-tab-item>
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="cog"></b-icon>
              <span> {{ $t('types') }} </span>
            </template>

            <document-type
              ref="document_types"
              :project-id="projectId"
            />
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="cog"></b-icon>
              <span> {{ $t('statuses') }} </span>
            </template>

            <document-status
              ref="document_statuses"
              :project-id="projectId"
            />
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="cog"></b-icon>
              <span> {{ $t('disciplines') }} </span>
            </template>

            <document-discipline
              ref="document_disciplines"
              :project-id="projectId"
            />
          </b-tab-item>

        </b-tabs>

        <!-- Modal -->
        <upload-document
          ref="modal"
          :upload-endpoint="'/api/v2/' + entityType + '/' + entityId + '/document'"
          :allow-multiple-files="true"
          @success="successfullyUploaded"
          :entityId="entityId"
          :entityType="entityType"
          :only-upload-file="true"
          :type-document="typeDocument"
        />

        <!-- Modal -->
        <upload-design
          ref="modalDesign"
          :upload-endpoint="'/api/v2/' + entityType + '/' + entityId + '/document'"
          :allow-multiple-files="true"
          @success="successfullyUploaded"
          :entityId="entityId"
          :entityType="entityType"
          :only-upload-file="true"
          :type-document="typeDocument"
          :project-id="projectId"
        />

      </div>
    </section>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import Form from "vform";
  import uploadDocument from "../../components/upload-document";
  import uploadDesign from "../../components/autodesk/upload-design.vue";
  import ProjectDocumentsManual from "../../components/project-documents-manual";
  import ImportEntities from "../../components/import-entities";
  import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons';
  import AutodeskModelsList from '../../components/autodesk/models-list.vue';
  import EventBus from '~/plugins/bus';
  import DocumentType from './document/document-type'
  import DocumentStatus from './document/document-status'
  import DocumentDiscipline from './document/document-discipline'

  export default {
    middleware: "auth",
    title: "documents_drawings_page_title",
    menuOption: "0-2",

    components: {
      ImportEntities,
      ProjectAdminQuickButtons,
      ProjectDocumentsManual,
      uploadDocument,
      uploadDesign,
      AutodeskModelsList,
      DocumentType,
      DocumentStatus,
      DocumentDiscipline,
    },

    computed: {
      ...mapGetters({
        navData: "app/navData",
        user: "auth/user",
      }),
      projectId() {
        return parseInt(this.navData.project_id);
      }
    },

    data: () => ({
      isLoading: false,
      form: new Form({
        projectId: null,
        partnerId: null,
        companyId: null,
        excelFile: null,
        description: "",
        overwrite: false,
        assignmentType: null,
        activityCode: null,
        actionType: "",
      }),

      entityType: 'project',
      entityId: null,

      errors: [],
      mainActiveTab: 0,
      typeDocument: 'documents',
    }),

    watch: {
      mainActiveTab(value) {
        if (value == 0) {
          // no aplicable
          //this.$refs.project_assignments_manual.reload();
        }
      }
    },

    created() {
      let projectName = this.navData.project_name
        ? this.navData.project_name.toUpperCase()
        : "";
      this.$options.subtitle = ["project_actions_page_subtitle", [projectName]];

      this.entityId = this.projectId;
    },

    mounted() {
      let self = this;
      // Si recibimos el evento refresh, refrescamos el componente
      EventBus.$on("refreshProjectDocumentsNotifications", function(e) {
        if( e.executor_user_id && e.executor_user_id != self.user.id ) {
          self.refreshProjectDocuments();
        } else {
        }
      });
    },

    beforeDestroy(){
      EventBus.$off("refreshProjectDocumentsNotifications");
    },

    methods: {

      onAddClicked(type='documents') {
        this.typeDocument = type

        if( type === 'models_bim' )
          this.$refs.modalDesign.show();
        else
          this.$refs.modal.show();
      },

      successfullyUploaded() {
        if( this.typeDocument === 'models_bim' )
          this.$refs.autodesk_models_list.reload();
        else if( this.typeDocument === 'drawings' )
          this.$refs.project_drawings_manual.reload();
        else
          this.$refs.project_documents_manual.reload();
      },

      refreshProjectDocuments() {
        this.$refs.project_drawings_manual.reload();
        this.$refs.project_documents_manual.reload();
      },
    }
  };
</script>
