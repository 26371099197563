<template>
  <div>
    <div class="w-full flex justify-end">
      <div class="flex">
        <v-button @click="create = !create;
          personalCreate.id = 0;
          personalCreate.name = '';
          personalCreate.code = '';
          personalCreate.type = 'individual';
          personalCreate.qty = '';
          personalCreate.measure_id = '';
          personalCreate.provider_id = '';
          personalCreate.description = '';"
          class="btn btn-blue rounded" icon="plus">{{ $t('add_personal') }}
      </v-button>
      </div>
    </div>
    <div class="panel" v-if="create || personalCreate.id">
      <div>

        <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('create_personal') }}</h2>
        <form @submit.prevent="createPersonal">
        <div class="w-full justify inline-flex">
          <b-field :label="$t('name')" class="md:w-1/5 mr-4">
            <b-input v-model="personalCreate.name" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('code')" class="md:w-1/5 mr-4">
            <b-input v-model="personalCreate.code" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('description')" class="md:w-1/5 mr-4">
            <b-input v-model="personalCreate.description" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('measure')" class="md:w-1/5 mr-4">
            <b-select :placeholder="$t('select_measure')" v-model="personalCreate.measure_id" required>
              <option value="number" v-for="measure in measures" :value="measure.id">{{ measure.name }}</option>
            </b-select>
          </b-field>
          <b-field :label="$t('type')" class="md:w-1/5 mr-4">
            <b-select :placeholder="$t('select_type')" v-model="personalCreate.type" required>
              <option value="individual">{{ $t('individual') }}</option>
              <option value="group">{{ $t('group') }}</option>
            </b-select>
          </b-field>
          <b-field :label="$t('qty')" class="md:w-1/5 mr-4">
            <b-input v-model="personalCreate.qty" class="w-full"></b-input>
          </b-field>
          <b-field label="  " class="md:w-1/5 mr-4 mt-4">
            <v-button class="btn btn-primary" nativeType="submit" icon="save" :disabled="!personalCreate.name || !personalCreate.code || !personalCreate.measure_id">{{ $t('save') }}</v-button>
          </b-field>
        </div>
      </form>
      </div>
    </div>
    <div class="panel">
      <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('personal_list') }}</h2>
      <div class="panel">
        <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('filter') }}</h2>
        <form @submit.prevent="filterPersonal">
          <div class="w-full justify inline-flex">
            <b-field :label="$t('name')" class="md:w-1/5 mr-4">
              <b-input v-model="personalFilter.name" class="w-full"></b-input>
            </b-field>
            <b-field :label="$t('code')" class="md:w-1/5 mr-4">
              <b-input v-model="personalFilter.code" class="w-full"></b-input>
            </b-field>
            <b-field label="  " class="md:w-1/5 mr-4 mt-4">
              <v-button class="btn btn-primary" nativeType="submit"><b-icon pack="fas" icon="search"/></v-button>
            </b-field>
          </div>
        </form>
      </div>

      <div class="w-full flex flex-wrap mt-4">
        <section class="w-full">
          <b-table
          :data="personals"
          :paginated="isPaginated"
          :per-page="perPage"
          :bordered="true"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :default-sort-direction="defaultSortDirection"
          v-if="loaded">
          <template slot-scope="props">
            <b-table-column field="name" :label="$t('name')" sortable width=100>
              {{ props.row.name }}
            </b-table-column>

            <b-table-column field="code" :label="$t('code')" sortable >
              {{ props.row.code }}
            </b-table-column>

            <b-table-column field="description" :label="$t('description')" sortable >
              {{ props.row.description }}
            </b-table-column>

            <b-table-column field="type" :label="$t('type')" sortable >
              {{ props.row.type }}
            </b-table-column>

            <b-table-column field="measure" :label="$t('measure')" v-if="props.row.measure != null" sortable>
              {{ props.row.measure.name }}
            </b-table-column>

            <b-table-column field="qty" :label="$t('qty')" sortable width=100>
              {{ props.row.qty }}
            </b-table-column>

            <b-table-column field="actions" :label="$t('actions')" sortable width=100 >
              <div class="justify inline flex">
                <button @click="getPersonal(props.row.id)"><b-icon pack="fas" icon="pencil-alt" type="is-primary" /></button>
              </div>
            </b-table-column>
          </template>
        </b-table>
      </section>
    </div>
  </div>
</div>
</template>

<script>
import Form from 'vform'
import axios from 'axios'

export default {
  middleware: 'auth',
  title: 'personal_page_title',
  subtitle: 'personal_page_subtitle',
  menuOption: '0-3',

  data: () => ({
    provider_id: 0,
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 5,
    personals: [],
    measures: [],
    loaded: false,
    projects: null,
    create: false,

    personalFilter: new Form ({
      name: '',
      code: '',
      measure_id: '',
    }),
    personalCreate: new Form ({
      id: 0,
      name: '',
      qty: 0,
      description: '',
      code: '',
      measure_id: '',
    }),
  }),

  mounted () {
    this.provider_id = parseInt(this.$route.params.provider_id)
    this.getMeasures()
    this.getPersonals()
  },

  methods: {

    async filterPersonal () {
      if (! this.personalFilter) {
        return;
      }
      console.log(this.personalFilter)
      const url = '/api/v2/report/personal/' + this.provider_id + '/filter'
      const { data } = await this.personalFilter.post(url)
      if (data.data && data.success) {
        this.personals = data.data
      }
    },

    async createPersonal () {
      if (! this.personalCreate) {
        return;
      }
      var url
      if(this.personalCreate.id){
        url = '/api/v2/report/personal/' + this.personalCreate.id
      }else{
        url = '/api/v2/report/personal/' + this.provider_id + '/add'
      }

      const { data } = await this.personalCreate.post(url)
      if (data.data && data.success) {
        this.personals = data.data
        this.personalCreate.name = ''
        this.personalCreate.code = ''
        this.personalCreate.qty = ''
        this.personalCreate.measure_id = ''
        this.personalCreate.description = ''
        if(this.personalCreate.id){
          this.getPersonals()
          // Hago esto pq extiende de la clase padre y no me retorna la lista completa de nuevo.
        }
        this.personalCreate.id = 0
        this.success('personal_save')

      }else{
        this.error('error_creating_personal')

      }
    },

    async getPersonals(){
      const url = '/api/v2/report/personal/' + this.provider_id + '/list'
      const { data } = await axios.get(url)
      if (data.data && data.success) {
        this.personals = data.data
        this.loaded = true
      } else {
        this.error('error_retreiving_personals')

      }
    },
    async getMeasures(){
      const url = '/api/v2/report/measure/' + this.provider_id + '/list'
      const { data } = await axios.get(url)
      if (data.data && data.success) {
        this.measures = data.data
        console.log(this.measures,"measures")
      } else {
        this.error('error_retreiving_personals')

      }
    },

    async getPersonal(id){
      const url = '/api/v2/report/personal/' + id
      const { data } = await axios.get(url)
      if (data) {
        //Object.assign(this.personalCreate,data.data)
        this.personalCreate.id = id
        this.personalCreate.name = data.name
        this.personalCreate.code = data.code
        this.personalCreate.measure_id = data.measure_id
        this.personalCreate.qty = data.qty
        this.personalCreate.description = data.description

      } else {
        this.error('error_retreiving_personal')
      }
    },

    /*
     ALERTAS
     */
     success(message){
      this.$toast.open({
        message: message,
        type: 'is-success',
        position: 'is-top-right'
      })
    },
    error(message){
      this.$toast.open({
        message: message,
        type: 'is-danger',
        position: 'is-top-right'
      })
    }
  },
  watch:{

  }

};
</script>
