<template>
  <div>
    <input id="pac-input" class="controls" type="text" :placeholder="$t('search')">
    <div
      class="google-map w-full"
      ref="googleMap"
      style="height: 400px;"
    ></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map"/>
    </template>
  </div>
</template>

<script>
  import GoogleMapsApiLoader from "google-maps-api-loader";
  import EventBus from "~/plugins/bus";

  export default {
    props: {
      mapConfig: Object,
      mapStyle: Object,
      points: {type: Array, default: () => []},
    },

    data() {
      return {
        google: null,
        map: null,
        markers: [],
      };
    },

    async created() {
      let libraries = ['places'];

      this.google = await GoogleMapsApiLoader({
        libraries: libraries,
        apiKey: process.env.MIX_GMAPS_API_KEY,
      });

      this.initializeMap();

      this.google.maps.event.addListener(this.map, 'click', (e) => {
        this.placeMarker(e.latLng);
      });

      this.loadMarkers();

      if (this.markers.length)
        this.centerMap(this.markers);
    },

    mounted() {
      EventBus.$on('center-map', (markers) => this.centerMap(markers));
    },

    methods: {
      initializeMap() {
        const mapContainer = this.$refs.googleMap;
        this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
        this.searchBar();
      },

      /**
       * Incluye lo necesario para tener una barra de busqueda
       * https://developers.google.com/maps/documentation/javascript/examples/places-searchbox?hl=es-419
       */
      searchBar() {
        var self = this;
        // Create the search box and link it to the UI element.
        let input = document.getElementById('pac-input');
        var searchBox = new this.google.maps.places.SearchBox(input);
        this.map.controls[this.google.maps.ControlPosition.TOP_LEFT].push(input);

        // Bias the SearchBox results towards current map's viewport.
        this.map.addListener('bounds_changed', () => {
          searchBox.setBounds(this.map.getBounds());
        });

        searchBox.addListener('places_changed', function () {
          var places = searchBox.getPlaces();

          if (places.length == 0) {
            return;
          }

          // For each place, get the icon, name and location.
          var bounds = new self.google.maps.LatLngBounds();
          places.forEach(function (place) {
            if (!place.geometry) {
              console.log("Returned place contains no geometry");
              return;
            }

            if (place.geometry.viewport) {
              // Only geocodes have viewport.
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          self.map.fitBounds(bounds);
        });
      },

      /**
       * Procesa el array points y los convierte a marcadores
       */
      loadMarkers() {
        this.points.forEach((item, index) => {
          //ejemplo: { lat: 36.68631, lng: -6.14958 }
          this.placeMarker(new this.google.maps.LatLng(item.location.coordinates[0], item.location.coordinates[1]), false);
        });
      },

      /**
       * Crea un marcador en la posición y le asigna sus eventos
       * @param position
       * @param autoCenter
       */
      placeMarker(position, autoCenter = true) {
        let marker = new google.maps.Marker({
          position: position,
          map: this.map
        });
        this.markers.push(marker);

        var self = this;
        marker.addListener("rightclick", function () {
          let index = self.markers.findIndex(x => x.getPosition().equals(this.getPosition()));
          self.markers.splice(index, 1);
          this.setMap(null);
        });

        if (autoCenter)
          this.map.panTo(position);
      },

      centerMap(markers) {

        // Creamos los limites
        let bounds = new this.google.maps.LatLngBounds();

        // Recorremos los marcadores y extendemos los limites
        markers.map(function (marker) {
          bounds.extend({lat: marker.getPosition().lat(), lng: marker.getPosition().lng()});
        });

        // Centramos el map
        this.map.fitBounds(bounds);

      },

      getMarkers() {
        let newMarkers = [];

        this.markers.forEach((item, index) => {
          newMarkers.push(item.getPosition());
        });

        return newMarkers;
      }
    },
  };
</script>

<style>
  #pac-input {
    background-color: #fff;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
    border: gray 1px solid;
  }

  #pac-input:focus {
    border-color: #4d90fe;
  }
</style>
