<template>

<b-tabs type="is-toggle-rounded" size="is-small" position="is-centered" v-model="activeTab">
  <b-tab-item>

    <template slot="header">
      <b-icon pack="fas" icon="file"></b-icon>
      <span>Autodesk</span>
    </template>

    <div class="flex">

      <div class="panel w-1/5 mr-3 p-0 mb-0"
        style="height: 70vh; background-color: #f6f7f9;">
        <div class="bg-blue-light rounded-t">
          <p class="text-center text-white">{{ $t('design_views') }}</p>
        </div>
        <div class="relative overflow-y-scroll overflow-x-hidden p-4" style="height: 65vh;">
          <ModelPreviewList
            :projectId="project.id"
            @change-model-view="handleChangeModel"
            @firts-urn-model-bim="handleChangeModel"
          />
        </div>
      </div>

      <div class="panel w-4/5 mb-0">
        <viewer-forge v-if="urn != null"
          ref="viewer"
          :get-access-token="handleAccessToken"
          :urn="urn"
          :options="options"
        />
      </div>

    </div>
  </b-tab-item>

  <b-tab-item v-if="company.alias == 'democanal' || company.alias == 'renovables'">
    <template slot="header">
      <b-icon pack="fas" icon="calendar"></b-icon>
      <span>IFC</span>
    </template>

    <bimdata-ifc v-if="activeTab === 1" />
  </b-tab-item>
</b-tabs>
</template>

<script type="module">

import myAwesomeExtension from '../../components/autodesk/viewer/extensions/myAwesomeExtension.js';
import myCustomToolbar from '../../components/autodesk/viewer/extensions/myCustomToolbar.js';

import {mapGetters} from 'vuex'
import ViewerForge from '../../components/autodesk/viewer/viewer-forge.vue'
import ModelPreviewList from '../../components/autodesk/model-preview-list.vue'

import axios from 'axios';
import BimdataIfc from '../../components/bimdata/bimdata-ifc.vue';


export default {
  middleware: 'auth',
  title: 'project_design_viewer_page_title',
  subtitle: 'project_design_viewer_page_subtitle',
  menuOption: '2-10',

  components: {
    ViewerForge,
    ModelPreviewList,
    BimdataIfc,
  },

  data() {
    return {
      activeTab: 0,

      // urn:'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6cHJ1ZWJhX215X2ZpcnN0X2J1Y2tldC9TdXNwZW5zaW9uLnppcA==',
      // urn:'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YnVja2V0X2VwY19wcm9qZWN0XzIyMy9UZWNobmljYWxfc2Nob29sLWN1cnJlbnRfbS5ydnQ',
      urn: null,

      options: {
        // env: 'AutodeskProduction2',
        // env: 'AutodeskProduction2',
        // api: 'streamingV2',  // for models uploaded to EMEA change this option to 'streamingV2_EU'
      }
    }
  },

  mounted() {
    this.getProjectModels()
    // this.$refs.viewer.addExtension(myAwesomeExtension);
    // this.$refs.viewer.addExtension(myCustomToolbar);
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      project: 'app/project',
      company: "app/company",
    }),
  },

  beforeCreate() {
    // const script = document.createElement('script');
    // script.src = 'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js';
    // script.async = true;
    // script.onload = this.getLoad;

    // document.head.appendChild(script);
  },

  created() {

  },

  mounted() {
  },


  methods: {

    handleAccessToken: async function (onSuccess) {
      await axios.get('/api/v2/autodesk/forge/getToken')
        .then((response) => {
          // console.log('res: ', await data.json())
          let data = response.data
          if (! data.success) {
              throw new Error('Error al obtener token.');
          }
          onSuccess(data.data.accessToken, data.data.expiry);
        })
        .catch ((err) => {
          alert('Could not obtain access token. See the console for more details.');
          console.error(err);
        })
    },

    handleChangeModel(urn) {
      this.urn = urn
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  },
};
</script>
