<template>
<div
  :class="['bg-'+backgroundColor, heightClass]"
  class="relative rounded overflow-hidden shadow">
  <!-- Barra de progreso -->
  <div
    :class="['bg-'+color]"
    class="float-left h-full"
    :style="{ width: progress + '%' }"
    style="-webkit-transition:width 1s ease-in-out;-moz-transition:width 1s ease-in-out;-o-transition:width 1s ease-in-out;transition:width 1s ease-in-out"
  ></div>
  <!-- Etiqueta -->
  <div v-if="showPercentage || showStep"
    class="float-left absolute inset-0 font-bold flex items-center justify-center"
    :class="{ 'text-grey-darkest': (progress <= 55), 'text-grey-lightest': (progress > 55) }"
    style="font-size:0.65rem;line-height:1rem"
  >
    {{ label }}
  </div>
</div>
</template>

<script>
export default {
  name: 'progress-bar',

  props: {
    step: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    showPercentage: { type: Boolean, default: true },
    showStep: { type: Boolean, default: false },
    color: { type: String, default: 'green' },
    backgroundColor: { type: String, default: 'grey-lighter' },
    size: { type: String, default: 'normal' }
  },

  data: () => ({
    progress: 0,
    label: '',
  }),

  computed: {
    heightClass() {
      if (this.size === 'normal') {
        return 'h-4'
      } else if (this.size === 'small') {
        return 'h-2'
      } else if (this.size === 'large') {
        return 'h-6'
      }
    },
  },

  watch: {
    step(value) {
      this.calculateProgress()
    },
    progress(value) {
      if (this.showPercentage) {
        this.label = value + '%'
      } else if (this.showStep) {
        this.label = value + '/' + this.max
      } else {
        this.label = ''
      }
    },
  },

  mounted() {
    this.calculateProgress()
  },

  methods: {
    calculateProgress() {
      this.progress = parseInt(Math.round((this.step * 100) / this.max))
    }
  },

};
</script>
