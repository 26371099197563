<template>
  <div class="page-with-top-bar">
    <project-admin-quick-buttons hiddenOption="extrafields"/>
    <section>
      <div class="card m-2">
        <div class="flex items-center justify-end w-full">
          <v-button icon="calculator"
            @click="generateReport()"
            class=" m-3">
            {{ $t('export') }}
          </v-button>

        </div>
      </div>
      <!-- form add group -->
      <div v-if="isAddGroup">
        <form @submit.prevent="addKpiGroup">
          <v-input
            v-model="newGroup.name"
            label="Nombre"
            placeholder="Nombre del grupo"
            required
          ></v-input>
          <v-button type="submit">{{ $t('save') }}</v-button>
        </form>
      </div>

      <!-- form add activity -->
      <div v-if="isAddActivity">
        <form @submit.prevent="addKpiActivity">
          <!-- selext -->
          <v-select
          v-model="activitySelected"
          label="Actividad"
          placeholder="Seleccione una actividad"
          :options="activities"
          required
          >

        </v-select>
          <v-button type="submit">{{ $t('save') }}</v-button>
        </form>
      </div>

      <!-- table -->
      <div
        class="card m-2"
      >
        <div class="mb-3">
          <multiselect v-model="columnsSelected"
            :options="_columns"
            :multiple="true"
            :deselect-label="$t('multiselect_deselect_label')"
            :select-label="$t('multiselect_select_label')"
            :selected-label="$t('multiselect_selected_label')"
            :show-labels="true"
            :hide-selected="false"
            :limit-text="count => `y ${count} más`"
            label="title"
            track-by="title"
            :close-on-select="true" :clear-on-select="false" :preserve-search="true"
            :max-height="150"
            :allow-empty="true"
            :limit="limitShowColumnsSelect"
            @select="_HandleSelectItem"
            @remove="_HandleRemoveItem">
          </multiselect>
        </div>
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th v-if="columns.group.visible">GRUPO</th>
                <th v-if="columns.subactivity.visible">SUBACTIVIDAD</th>
                <th v-if="columns.duration.visible">DURACIÓN DIAS</th>
                <th v-if="columns.days_elapsed.visible">DÍAS TRASCURRIDOS</th>
                <th v-if="columns.assignment.visible">MEDICIONES</th>
                <th v-if="columns.unit.visible">UNIDADES</th>
                <th v-if="columns.projected.visible">PROYECTADO</th>
                <th v-if="columns.executed.visible">Unidades ejecutadas</th>
                <th v-if="columns.progress.visible">AVANCE ACTUAL % POR MEDICIÓN</th>
                <th v-if="columns.executed_per_day.visible">CANTIDAD EJECUTADA POR DÍA  (REAL)</th>
                <th v-if="columns.ratio_programmed.visible">RATIO PROGRAMADO (LINEA BASE)</th>
                <th v-if="columns.ratio_actual_daily.visible">RATIO ACTUAL/DIARIO (REAL)</th>
                <th v-if="columns.ratio_actual_week.visible">Ratio ACTUAL / SEMANA (REAL)</th>
                <th v-if="columns.end_date_estimated.visible" :style="{ backgroundColor: '#C6EFCE', color: '#006100' } "
                >Fecha fin estimada</th>
                <th v-if="columns.ratio_objective_subactivity.visible" >RATIO OBJETIVO SUBACTIVIDAD	</th>
                <th v-if="columns.ratio_objective.visible" >RATIO OBJETIVO MEDICION</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="group in dataGroups2" >
                <template v-for="(subactivity, subIndex) in group.subactivities" >
                  <tr>
                    <td v-if="subIndex === 0 & columns.group.visible "  :rowspan="calculerowspanGroup(group)">{{ group.group }}</td>
                    <td v-if="columns.subactivity.visible" :rowspan="subactivity.assignments.length" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">  {{ subactivity.subactivity }}</td>
                    <td v-if="columns.duration.visible" :rowspan="subactivity.assignments.length" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">{{ subactivity.duration }}</td>
                    <td v-if="columns.days_elapsed.visible" :rowspan="subactivity.assignments.length" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">
                      {{ subactivity.days_elapsed }}
                    </td>
                    <td v-if="columns.assignment.visible" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">
                      {{ subactivity.assignments[0].assignment }}
                    </td>
                    <td v-if="columns.unit.visible" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">
                      {{ subactivity.assignments[0].unit }}
                    </td>
                    <td v-if="columns.projected.visible" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">
                      {{ subactivity.assignments[0].projected }}
                    </td>
                    <td v-if="columns.executed.visible" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : '#4EA72E' }">
                      {{ subactivity.assignments[0].executed }}
                    </td>
                    <td  v-if="columns.progress.visible" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">
                      {{ subactivity.assignments[0].progress }}
                    </td>
                    <td v-if="columns.executed_per_day.visible" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">
                      {{ subactivity.assignments[0].executed_per_day }}
                    </td>
                    <td  v-if="columns.ratio_programmed.visible" :rowspan="subactivity.assignments.length" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">
                      {{ subactivity.ratio_programmed }}
                    </td>
                    <td v-if="columns.ratio_actual_daily.visible" :rowspan="subactivity.assignments.length" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">
                      {{ subactivity.ratio_actual_daily }}</td>
                    <td v-if="columns.ratio_actual_week.visible" :rowspan="subactivity.assignments.length" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">
                      {{ subactivity.ratio_actual_week }}
                    </td>
                    <td v-if="columns.end_date_estimated.visible" :rowspan="subactivity.assignments.length" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">
                      {{ subactivity.end_date_estimated }}
                    </td>
                    <td v-if="columns.ratio_objective_subactivity.visible" :rowspan="subactivity.assignments.length" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">
                      {{ subactivity.ratio_objective_subactivity }}
                    </td>
                    <td v-if="columns.ratio_objective.visible" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }"
                     >
                      {{ subactivity.assignments[0].ratio_objective }}
                    </td>
                  </tr>
                  <tr v-for="(medicion, medIndex) in subactivity.assignments" :key="generateRandNumber('medicion' + medIndex)" v-if="medIndex > 0">
                    <td v-if="columns.assignment.visible" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">{{ medicion.assignment }}</td>
                    <td v-if="columns.unit.visible" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">{{ medicion.unit }}</td>
                    <td v-if="columns.projected.visible" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">{{ medicion.projected }}</td>
                    <td v-if="columns.executed.visible" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : '#4EA72E' }">{{ medicion.executed }}</td>
                    <td v-if="columns.progress.visible" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">{{ medicion.progress }}</td>
                    <td v-if="columns.executed_per_day.visible" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">{{ medicion.executed_per_day }}</td>
                    <td v-if="columns.ratio_objective.visible" :style="{ color: subactivity.subactivity === 'Total' ? '#FA7D00' : 'inherit', backgroundColor:subactivity.subactivity === 'Total' ? '#F2F2F2' : 'inherit' }">
                      {{ medicion.ratio_objective }}
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import axios from 'axios'
  import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons'
  import Multiselect from 'vue-multiselect'

  export default {
    middleware: "auth",
    title: "KPIS",
    menuOption: "0-2",

    components: {
      ProjectAdminQuickButtons,
      Multiselect,
    },

    computed: {
      ...mapGetters({
        navData: "app/navData",
        mobile: 'device/mobile'
      }),
      projectId() {
        return parseInt(this.navData.project_id);
      },

      groupedData() {
        const grouped = {};
        this.data.forEach(item => {
          if (!grouped[item.group]) {
            grouped[item.group] = { group: item.group, subactivities: [] };
          }
          grouped[item.group].subactivities.push(item);
        });
        return Object.values(grouped);
      },

      _columns: function() {
        // return Object.values(this.columns)
        // Object.keys(myObject).map(key => myObject[key]);
        return Object.entries(this.columns).map(([key, value]) => ({ key, title: this.$t(value.title), visible: value.visible }));
      },
      limitShowColumnsSelect() { // cantidad de columnas visibles seleccionadas a mostrar en el multiselect por defecto  8
        return this.mobile.on_mobile ? 3 : 8
      }
    },

    data: () => ({
      mainActiveTab: 0,
      kpiGroups: [],
      activities: [],
      newGroup: {
        name: "",
      },
      activitySelected: null,
      loading: false,
      isAddGroup: false,
      isAddActivity: false,
      dataGroups2:[],
      columns:{
        group: { title: "Grupo", visible: true },
        subactivity: { title: "Subactividad", visible: true },
        duration: { title: "Duración dias", visible: true },
        days_elapsed: { title: "Días transcurridos", visible: true },
        assignment: { title: "Mediciones", visible: true },
        unit: { title: "Unidades", visible: true },
        projected: { title: "Proyectado", visible: true },
        executed: { title: "Unidades ejecutadas", visible: true },
        progress: { title: "Avance actual % por medición", visible: true },
        executed_per_day: { title: "Cantidad ejecutada por día (real)", visible: true },
        ratio_programmed: { title: "Ratio programado (linea base)", visible: true },
        ratio_actual_daily: { title: "Ratio actual/diario (real)", visible: true },
        ratio_actual_week: { title: "Ratio actual/semana (real)", visible: true },
        end_date_estimated: { title: "Fecha fin estimada", visible: true },
        ratio_objective: { title: "Ratio objetivo medicion", visible: true },
        ratio_objective_subactivity: { title: "Ratio objetivo subactividad", visible: true },
      },
      columnsSelected: [],
    }),

    watch: {
      mainActiveTab(value) {
        if (value == 0) {
          // no aplicable
          //this.$refs.project_assignments_manual.reload();
        }
      }
    },

    created() {
      let projectName = this.navData.project_name
        ? this.navData.project_name.toUpperCase()
        : "";

      this.$options.subtitle = ["project_actions_page_subtitle", [projectName]];
      // this.projectId = this.$route.params.project_id || null;
      this.getProjectKpis()
      this.getProjectKpi()
      // computed data

    },

    mounted(){
      this.columnsSelected = this._columns.filter(column => column.visible == true)

    },

    methods: {

      extraFieldsImportSuccess() {
        this.$refs.project_extra_fields_manual.reload();
      },

      calculerowspanGroup(group) {
        return group.subactivities.map(subactivity => subactivity.assignments.length).reduce((a, b) => a + b, 0)
        // esto me da la longitud de la sumatoria de subactivities.mediciones.length
      },
      generateRandNumber(prefix = '') {
        const rand = Math.floor(Math.random() * 1000000);
        return `${prefix}${rand}`;
      },

      transformData(dataEntrada){
        // Agrupar datos por group y group_id
        const groupedData1 = dataEntrada.reduce((acc, item) => {
          const { group, group_id, subactivity } = item;

          if (!acc[group]) {
            acc[group] = {
              group: group,
              groupId: group_id,
              subactivities: {}
            };
          }

          if (!acc[group].subactivities[subactivity]) {
            acc[group].subactivities[subactivity] = {
              subactivity: subactivity,
              duration: item.duration,
              start_date: item.start_date,
              end_date: item.end_date,
              days_elapsed: item.days_elapsed,
              ratio_programmed: item.ratio_programmed,
              ratio_actual_daily: item.ratio_actual_daily,
              ratio_actual_week: item.ratio_actual_week,
              end_date_estimated: item.end_date_estimated,
              ratio_objective_subactivity: item.ratio_objective_subactivity,
              assignments: []
            };
          }

          acc[group].subactivities[subactivity].assignments.push({
            assignment: item.assignment,
            projected: item.projected,
            unit: item.unit,
            executed: item.executed,
            progress: item.progress,
            executed_per_day: item.executed_per_day,
            ratio_objective: item.ratio_objective,

          });

          return acc;
        }, {});

        // Convertir el objeto agrupado en un array con la estructura deseada
        const result = Object.keys(groupedData1).map(group => {
          const groupData = groupedData1[group];
          const subactivities = Object.keys(groupData.subactivities).map(subactivity => {
            return groupData.subactivities[subactivity];
          });

          return {
            group: groupData.group,
            groupId: groupData.groupId,
            subactivities: subactivities
          };
        });

        return result;
      },

      _HandleSelectItem(selectedOption, id) {
        this.columns[selectedOption.key].visible = true
      },

      _HandleRemoveItem(removedOption, id) {
        if (this.columns[removedOption.key])
          this.columns[removedOption.key].visible = false
      },

      async generateReport() {
        const { data } = await axios.get('/api/v2/project/'+this.projectId+'/kpi-report')
        if (data && data.success) {
          this.$notify.success('report_generated')
          window.open(data.data, '_blank')
        } else {
          this.$notify.error('error_loading_activity_data')
        }
      },

      async getProjectKpis() {
        this.loading = true
        const { data } = await axios.get('/api/v2/project/'+this.projectId+'/kpi-group')
        if (data && data.success) {

          this.kpiGroups = data.data
          this.activities = data.activities
          this.loading = false
        } else {
          this.$notify.error('error_loading_activity_data')
        }
      },


      async getProjectKpi(){
        this.loading = true
        const { data } = await axios.get('/api/v2/project/'+this.projectId+'/kpi-report-data')
        if (data && data.success) {
          //this.kpiGroups = data.data
          //this.activities = data.activities
          // console.log('kpi data:', data.data)
          this.dataGroups2 = this.transformData(data.data)
          this.loading = false
        } else {
          this.$notify.error('error_loading_activity_data')
        }
      },

    }
  };
</script>
<style scoped>
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
    vertical-align: middle;
    font-size: 12px;
  }

  th {
    background-color: #5577BB;
    color: white;

  }

  .table-container {
    overflow-x: auto;
  }
</style>
