// Plugin propio para manejo de pdfs, dibuja la pagina pasada en un elemento canvas
// Debe existir el elemento canvas en el componente padre

import * as pdfjsLib from "pdfjs-dist/build/pdf";

// pdfCanvas

export default {

  assetUrl: '',

  // para marker usamos una imagen de 40x65. Lo que significa que desde la posicion x,y deseada, hay que restar
  // la MITAD de la anchura y el total de la altura, para aparentar que la imagen apunta a la coordenada x,y
  markerImg: { src: 'public/marker-red.png', width: 20, height: 65 },

  // desde componente padre pasamos la url de assets
  setAssetUrl: function(url) {
    this.assetUrl = url
  },

  // extrae la pagina X del pdf y la pinta en un elemento canvas pasado
  // recibe un documento mapeado con un DocumentResource y unos parametros (pageNum y id elto canvas)
  loadDocument: async function(pdfDoc, params = { pageNum: 1, canvas: 'canvas', scale: 1}) {

    // carga la X pagina del pdf en un etiqueta html canvas

    let loadingTask = pdfjsLib.getDocument(pdfDoc.file.raw);
    let documentTask = await loadingTask.promise;
    // documentTask.numPages numero de paginas por si fuera necesario

    let pageNum = 1 // empieza en la 1
    if (params.pageNum) {
      pageNum = params.pageNum
    }

    let canvas = 'canvas'
    if (params.canvas) {
      canvas = params.canvas
    }

    let fixedCanvas = false
    if (params.fixedCanvas) {
      fixedCanvas = params.fixedCanvas
    }

    let scale = 1
    if (params.scale) {
      scale = params.scale
    }

    return documentTask.getPage(pageNum).then(page => {
      let elmCanvas = document.getElementById(canvas)
      let context = elmCanvas.getContext('2d')
      let viewport = page.getViewport({scale: scale})

      if (! fixedCanvas) {
        elmCanvas.width = viewport.width
        elmCanvas.height= viewport.height
      }

      return page.render({
          canvasContext: context,
          viewport: viewport,
        })
        .promise.then(function () {
          // console.log("pagina renderizada")
          return documentTask; // lo devuelve para que componente padre pueda llamar a otras funciones del modulo pasandole la pagina ya cargada
        })
    })

  },


  // dibuja en el canvas los markers de markersList en la coordenada (x, y) de cada uno
  async drawMarker(documentTask, markersList) {


    documentTask.getPage(1).then(page => {

      let canvas = document.getElementById('canvas')
      let context = canvas.getContext('2d');

      markersList.forEach(element => {
        // x e y estan guardados como porcentajes, transformarlo a pixeles segun nivel de zoom actual (tamaño canvas)
        let x = parseFloat( (canvas.width * element.x) / 100).toFixed(4)
        let y = parseFloat( (canvas.height * element.y) / 100).toFixed(4)

        const img = new Image();
        img.onload = () => {
          context.drawImage(img, x-this.markerImg.width, y-this.markerImg.height);
          context.font = '20px sans-serif'
          context.textAlign = 'center'
          context.fillText(element.title, x, y); // y+25 no hace nada!
        };

        img.src = this.assetUrl + this.markerImg.src
      });
      
    })

  },



  // Transforma una pagina del pdf en png y devuelve la ruta base64 de la imagen pdf: data:image/png;base64,iVB....
  // recibe un documento mapeado con un DocumentResource y unos parametros (pageNum)
  pdfToPng: async function(pdfDoc, params = { pageNum: 1, scale: 1}) {

    let loadingTask = pdfjsLib.getDocument(pdfDoc.file.raw);
    let documentTask = await loadingTask.promise;

    let pageNum = 1 // empieza en la 1
    if (params.pageNum) {
      pageNum = params.pageNum
    }

    let scale = 1
    if (params.scale) {
      scale = params.scale
    }


    return documentTask.getPage(pageNum).then(page => {

      // Crear un canvas temporal donde renderizar la página del PDF
      let tempCanvas = document.createElement('canvas');
      let tempContext = tempCanvas.getContext('2d');
      let viewport = page.getViewport({scale: scale})

      tempCanvas.width = viewport.width;
      tempCanvas.height = viewport.height;


      return page.render({
          canvasContext: tempContext,
          viewport: viewport,
          background: '#ffffff' // Color de fondo opcional
        }).promise.then(() => {

          // Renderiza el contenido del canvas en base64
          let imgSrc = tempCanvas.toDataURL();

          // Limpieza del canvas temporal
          tempCanvas = null;
          tempContext = null;

          return imgSrc;

        });

    })

  },



}
