<template>
<gantt />
</template>

<script>
  import gantt from '·/components/gantt'
  import Vue from "vue"
  import axios from 'axios'
  import {mapGetters} from 'vuex'
  import moment from 'moment'
  import MugenScroll from 'vue-mugen-scroll'
  import Buefy from 'buefy'

  Vue.use(moment);
  Vue.use(Buefy);

  export default {
    middleware: 'auth',
    title: 'activities_page_title',
    subtitle: 'activities_page_subtitle',
    menuOption: '2-2',

    components: {
      MugenScroll,
      gantt,
    },

    computed: {
      ...mapGetters({
        user: 'auth/user',
        project: 'app/project',
      }),


    },

    data: () => ({
      isLoadingTable: true,
    }),

    mounted() {
    },

    methods: {
      async getActivitiesList(args = []) {

        const {data} = await axios.get('/api/v2/activities?page=' + this.page, {params: args})

        // Last and total
        this.last = data.lastPage
        this.total = data.total

        if (data && data.activities) {
          this.list = data.activities
        } else {
          this.$notify.error('error_loading_activities')
        }

        this.isLoadingTable = false
      },


      fetchData() {
        if (this.page <= this.last) {
          this.isLoadingTable = true
          this.applyFilters(this.page)
          this.isLoadingTable = false
        }
      },


    },

  };
</script>

<style>
</style>


