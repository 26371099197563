import { Graphics } from "pixi.js";

export class PicNode extends Graphics {

  constructor(el, offset, layers, layerCAD, index) {
    super();

    this.picId = el.id.toString() // id del rectángulo
    this.picName = this._GetNameEntity(el, index) // nombre del rectángulo
    this.picX = parseFloat(Math.abs( el.x + offset.x )); // posición en x del rectángulo
    this.picY = parseFloat(Math.abs( el.y - offset.y )); // posición en y del rectángulo

    this.picRadius = el.radius; // radio del circulo
    this.picWidth = el.width; // ancho del rectángulo
    this.picHeight = el.height; // alto del rectángulo

    this.picFillColor = this._GetColorStatusOfLayout(el, layers) //?? '#000000';
    this.picLineColor = this._GetColorLayer(el.layer, layerCAD);
    this.picLineWidth = 0.5; //0.1 ancho de la línea del circulo

    // this.picAlpha = this.picFillColor === '#000000' ? 0.1 : el.status == null ? 1 : 0.7, // 0..1
    this.picAlpha = this.picFillColor === '#000000' ? 0.1 : 0.7, // 0..1
    this.picVisible = true // flag para saber si el rectángulo esta visible

    this.picType = el.type // el tipo de entidad si es modulo, hincado, estrucutura, etc
    this.picLayerName = el.layer, // nombre de la capa a la que pertenece el rectángulo

    this.picPreviouStatus = el.so, // estado anterior
    this.picStatus = el.status ? (typeof el.status === 'object' ? el.status.id : el.status ) :  null, // estado actual este contiene el id del estado
    this.picStatusColor = this.picFillColor,  // color del estado

    this.picColorSelected = '#fff7b3' // color mostrado cuando el mouse esta sobre el rectángulo
    this.picOpacitySelected = 0.3 // la opacidad que tiene el relloeno cuando el mouse esta sobre el rectángulo
    this.picSelected = false // flag para saber si el rectángulo esta seleccionado

    this.interactive = true;
    // this.cursor = 'pointer';

    // Dibujar el rectángulo con los parámetros personalizados
    this.drawCircle();
    this.addEvents();

    this._HandleMouseOver = this._HandleMouseOver.bind(this);
  }

  // Método para dibujar el rectángulo con los parámetros personalizados
  // Realizamos un fix para llenar el rectangulo si este no tiene estado el picFillColor es null y pixi asume o toma por defecto
  // el color blanco como fondo para evitar esto asumimos un negro y lo ponemos opacidad 0 para que sea transparente
  drawCircle() {
    this.circle(this.picX, this.picY, this.picRadius)

    if(this.picFillColor != null)
      this.fill(this.picFillColor)

    this.stroke({ width: this.picLineWidth, color: this.picLineColor })

    this.alpha = this.picAlpha;
  }

  addEvents() {
    this.on('click', this._HandleOnClick);

    this.on('mouseover', this._HandleMouseOver);

    this.on('mouseout', this._HandleMouseOut);
  }

  // Método para cambiar el tamaño del rectángulo
  resize(width, height) {
    this.width = width;
    this.height = height;
    this.clear(); // Limpiar el rectángulo anterior
    this.drawCircle(); // Dibujar el nuevo rectángulo
  }

  // Método para cambiar el color de relleno del rectángulo
  changeFillColor(color) {
    this.fillColor = color;
    this.clear(); // Limpiar el rectángulo anterior
    this.drawCircle(); // Dibujar el nuevo rectángulo
  }

  _GetNameEntity(entity, index) {
    if ( entity.name != null )
      return entity.name

    return 'E.' + entity.type.charAt(0).toUpperCase() + '.' + (index + 1)

  }

  _GetColorStatusOfLayout(entity, layers) {
    if( entity.status != null ) {
      let lyr = layers.find( item => { return item.code === entity.type })
      if (lyr) {
        let idStatus = typeof entity.status === 'object' ? entity.status.id : entity.status;

        let status = lyr.statuses.find(item => { return item.id == idStatus })
        if (status)
          return status.color
      }
    }
    return null
  }

  _GetColorLayer(nameLayer, layers) {
    if (layers === undefined)
      return '#000000'

    let layer = layers.find((l) => l.name === nameLayer)
    return this._GetCssColor(layer.color)
  }

  _GetCssColor(value) {
      let s = value.toString(16)
      while (s.length < 6) {
          s = "0" + s
      }
      return "#" + s
  }

  _HandleOnClick(e) {

    let el = e.target
    // if (! this.picSelected) {
    //   this.picSelected = true
    //   this.picFillColor = '#fff7b3'
    //   this.picAlpha = 0.6
    //   el.drawCircle();
    // } else {
    //   this.picSelected = false
    //   this.picFillColor = this.picStatusColor

    //   this.picAlpha = this.picStatus ? 0.7 : 1
    //   el.drawCircle();
    // }

    this.SelectPic(el)
    // console.log('Mensaje desde HanleOnLCick Se hizo clic en el rectángulo: ', this.picName);
  }

  _HandleMouseOver(e) {
    let el = e.target
    // this.clear()
    // console.log('event: ', e.target.fill(this.picColorSelected))
    el.fill(this.picColorSelected, this.picOpacitySelected)

    // console.log('Mensaje desde HandleMouseOver mouse esta dento en el rectángulo', this.picName);
  }

  _HandleMouseOut(e) {
    let el = e.target
    this.clear()
    el.drawCircle();
    // console.log('Mensaje desde HandleMouseOut mouse esta fuera del rectángulo', this.picFillColor);
  }

  SelectPic(el) {
    this.clear()
    if (! this.picSelected) {
      this.picSelected = true
      this.picFillColor = '#fff7b3'
      this.picAlpha = 0.6
      el.drawCircle();
    } else {
      this.picSelected = false
      this.picFillColor = this.picStatusColor

      this.picAlpha = this.picStatus ? 0.7 : 1
      el.drawCircle();
    }

    this._Emit('select', this)
  }

  _Emit(eventName, data = null) {
    document.dispatchEvent(new CustomEvent('__epc_pic_' + eventName, { detail: data }))
  }

  setPicStatus(status) {
    this.picStatus = status
  }

  setPicStatusColor(color) {
    this.picStatusColor = color
  }

  setPicPreviouStatus(status) {
    this.picPreviouStatus = status
  }
}
