// Plugin propio para manejo de subida de ficheros con laravel vapor

import axios from 'axios'
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import store from "~/store";

// File uploader

export default {


  // realiza la subida del fichero con los parametros necesarios
  // recibe un file (el File object de un b-upload o un Blob)
  // y params: entityType, entityId, uploadEndpoint
  submitFile: function(file, params) {

      this.uploadPercentage = 0;
      this.cancelRequest = axios.CancelToken.source();
      let form = new FormData();

      let fileName = file.desc_name || file.name
      fileName = fileName.trim()

      let customName = "";
      if (file.custom_name) customName = file.custom_name.trim();

      // Si el nombre personalizado después del trim tiene contenido usamos ese, y si no pasamos al del fichero
      form.append(
        "original_filename",
        customName != "" ? customName + this.getFileExtension(file) : fileName
      );

      // campo opcional en api, 'file_name' con nombre descriptivo
      if (file.desc_name) {
        form.append("file_name", fileName)
      }
      //else en backend se calcula este campo a partir de original_filename si no se introduce nada

      // campo opcional en api, 'file_code' con nombre descriptivo
      if (file.code) {
        form.append("file_code", file.code)
        // form.append("is_drawing", this.isDrawing ? 1 : 0)
      }

      // campo opcional en api, 'file_description' con nombre descriptivo
      if (file.description) {
        form.append("file_description", file.description)
      }

      // nombre generado por codificador
      if (file.coded_name == 1) {
        form.append("coded_name", 1)
      }

      // si hay carpeta seleccionada
      if (params.hasOwnProperty('selectedFolder') ) {
        form.append("folder_id", params.selectedFolder.id)
      }

      // form.append("file", file);
      form.append("entity_type", params.entityType);
      form.append("entity_id", params.entityId);

      form.append("project_id", params.projectId);

      // TODO. Tratar las menciones.
      // Si existe el componente, enviamos los ids de las menciones realizadas
      // if (this.$refs.hasOwnProperty("mention_users_new")) {
      //   form.append(
      //     "mentions_ids",
      //     this.$refs.mention_users_new.getMentionsIds()
      //   );
      // }
      // TODO. Tratar los tags
      // Si existe el componente, enviamos las tags
      // if (this.$refs.hasOwnProperty("modelTags")) {
      //   form.append("tags", this.$refs.modelTags.getJsonTags());
      // }

      return this.store(file, {})
        .then(async result => { // result contiene dos matrices successFul & failed

          // successFul contiene toda la lista de archivos que se han subido
          // para nuestro caso solo consideramos siempre el primero por que enviamos de a uno
          if( result.successful.length > 0 ) {
            let response = result.successful[0].response

            let tmpParams = {
                // uuid: response.uuid,
                key: response.body.key,
                // bucket: response.bucket,
                // name: file.name,
                content_type: file.type,
                // url: response.url
            }
            form.append("aws_response", JSON.stringify(tmpParams));

            // realizamos 3 intentos de subida de fichero
            let resp = null

            resp = await axios.post(params.uploadEndpoint, form, {
                cancelToken: this.cancelRequest.token,
            })

            return resp ? resp.data.success : false
          }

          // failed contiene todos los archivos que tubieron fallo al subir
          if (result.failed.length > 0) {
            console.error('Errors:')
            result.failed.forEach((file) => {
              console.error(file.error)
            })

            return false
          }

      }).catch((error) => {
        console.log('fail #ff55b: ',error)
        return false;
      });

  },


  // CUSTOM VAPOR STORE METHOD
  async store(file, options = null) {
    // TODO: monitorear si se llama desde un ciclo for se crear multiples instancias de uppy lo cual no seria lo optimo
    let uppy = new Uppy({
      debug: process.env.NODE_ENV === 'development', // activamos debug para development
      autoProceed: false,
      allowMultipleUploads: false,
    })
    .use(AwsS3Multipart, {
      limit: 3,
      companionUrl: '/api/v2/',
      companionHeaders: {
        'Authorization': "Bearer " + store.getters["auth/token"]
      }
    })
    // .on('upload-success', (file, response) => { this.onUploadSuccess(file, response) } )
    .on('upload-success', (file, response) => { // callback deveulto cuando el file ha sido subido correctamente
      console.log('%cEPC-TACKER: '+ '%c file ' + file.data.name + ' subido correctamente', 'background: #5577BB; color: #fff', 'color: #000')
    })
    .on('upload-progress', (file, progesss) => { // callback devuelto con el progreso de subido del archivo
      this.uploadPercentage = parseInt(
        Math.round((progesss.bytesUploaded * 100) / progesss.bytesTotal)
      );
    })
    .on('upload-error', (file, error, response) => { // callback devuelto si ha ocurrido algun error en la subida
      console.error('%cEPC-TACKER: '+ '%c a ocurrido un error al subir el archivo ' + file.data.name, 'background: #5577BB; color: #fff', 'color: #000', error)
    })

    uppy.addFile({
      name: file.name, // file name
      type: file.type, // file type
      data: file, // file blob
      // meta: {
      //   // optional, store the directory path of a file so Uppy can tell identical files in different directories apart.
      //   relativePath: webkitFileSystemEntry.relativePath,
      // },
      source: 'Local',
      isRemote: false,
    });

    if (typeof options.progress === 'undefined') {
        options.progress = () => {};
    }

    return uppy.upload()
  },


  getFileExtension: function(file) {
    let re = /(?:\.([^.]+))?$/;
    return "." + re.exec(file.name)[1];
  },


  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  },

}
