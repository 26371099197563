<template>
  <div class="flex items-stretch items-baseline gap-x-2 w-full">

    <div class="flex w-full panel">

      <div class="flex items-center w-full" v-if="scene == null">
        <div class="" style="width: 480px; padding: 30px 0 8px; margin: 0 auto; color: #0a131c">
          <h3 class="font-light text-center text-4xl">Linea de evacuación</h3>
          <p class="text-center text-sm mt-2.5 mx-0 mb-9">Seleccione el tipo de linea de evación que desea crear y <br /> complete el formulario para generar correctamente el diseño.</p>
          <div class="text-center mt-6" style="color: #76767D">
            <b-button size="is-large"
              icon-left="bezier-curve"
              icon-pack="fas"
              :type="'aerial_evacuation_line' == typeLine ? 'is-success' : 'is-normal'"
              @click="() => { if (null == typeLine) typeLine='aerial_evacuation_line' }"></b-button>
            <b-button size="is-large"
              icon-left="grip-lines"
              icon-pack="fas"
              :type="'buried_evacuation_line' == typeLine ? 'is-success' : 'is-normal'"
              @click="() => { if (null == typeLine) typeLine='buried_evacuation_line' }"></b-button>
          </div>
        </div>
      </div>

      <ViewerPageKonva v-if="scene != null"
        ref="refViewerPageKonva"
        :canActionsPictogram="_canActionsPictogram"
        :pictogram="scene"
        :typePictogram="typeLine"
        :work-layer="workLayer"
        @epc-reload="() => { $emit('epc-reload') }"></ViewerPageKonva>

    </div>
    <div v-if="typeLine != null"
      class="w-4/12 flex panel">
      <evacuation-line-data v-if="null == scene"
        :type-line="typeLine"
        @epc-evacuation-line-preview="_HandleEvacuationLinePreview"
        @epc-pictogram-data-close="() => { _Reset() }">
      </evacuation-line-data>

      <evacuation-line-save v-if="null != scene"
        ref="refSavePictogram"
        :entity-id="projectId"
        :input-file="file"
        :parse-dxf="parserDxf"
        :data="dataPictogram"
        :type-line="typeLine"
        @epc-pictogram-save="_HandlePictogramSave"
        @epc-pictograms-reload="_HandlePictogramsReload"
        @epc-pictogram-save-close="() => { _Reset() }"
        @epc-pictogram-show-loader="_HandleShowLoader">
      </evacuation-line-save>
    </div>
  </div>
</template>

<script>
import ViewerPageKonva from '../pictograms/viewer-konva/ViewerPageKonva.vue'
import EvacuationLineData from './components/evacuation-line-data.vue'
import EvacuationLineSave from './components/evacuation-line-save.vue'

export default {
  name: "manage-evacuation-line-add",

  components: {
    ViewerPageKonva,
    EvacuationLineData,
    EvacuationLineSave
  },

  props: {
    projectId: { type: Number, required: true },
    canActionsPictogram: { type:Object, default() { return {} } }, // acciones que se puede realizar sobre el pictograma por defecto ninguna
    permittedActions: { type:Object, default() { return {} } } // acciones que se mostraran en la columna acciones por defecto todas
  },

  data() {
    return {
      file: null, // Archivo dxf subido que contiene el diseño CAD
      parserDxf: null, // Objeto que contiene el dxf parseado
      scene: null, // Objeto que contiene la escena para ser visualizada por el viewer konva

      dataPictogram: null, // Objeto que contiene datos del pictograma
      workLayer: null, // capa donde  trabajará el usuario, solo permite trabajar en esa capa si null trabaja en todas

      _canActionsPictogram: this.canActionsPictogram,

      typeLine: null,
    }
  },

  computed: {
  },

  created() {

  },

  mounted() {

  },

  destroyed() {

  },

  beforeDestroy() {

  },

  methods: {
    _HandleEvacuationLinePreview(data) {
      // Desactivamos todas las acciones por que solo se visualizará el diseño
      this._canActionsPictogram = {
        'save': false,
        'select_entity': false
      }

      this.scene = {
        preview: true,
        scene: data.scene,
        layers: data.layers.filter((el) => { return el.create_entities })
      }

      this.dataPictogram = data
    },

    _Reset() {
      this.parserDxf = null
      this.typeLine = null  // usado para linea de evacuacion
      this.file = null;
      this.scene = null;
      this.dataPictogram = null
    },

    async _HandlePictogramSave() {
      this._HandleShowLoader({ loading: true, text: "Generando imagen" })

      this.$refs.refViewerPageKonva.ShowPanelScreenshot(true)
      // 1.- Obtenemos las capturas de las diferentes capas (screenshot en formato png)

      let screenshots = await this.$refs.refViewerPageKonva.GetScreenshot('general')

      this.$refs.refViewerPageKonva.ShowPanelScreenshot(false)
      // 2.- Guardamos los datos del pictograma
      this.$refs.refSavePictogram.SaveDataPictogram(screenshots)
    },

    _HandleShowLoader(data) {
      this.$refs.refViewerPageKonva.ShowLoader(data)
    },

    _HandlePictogramsReload() {
      this.$emit('epc-pictograms-reload')
    },
  }

};
</script>

<style scoped>

</style>
