<template>
    <div>
        <div class="text-blue-light font-thin text-center uppercase mb-4">{{ $t('notifications') }}</div>

        <div class="flex w-full">
            <!-- Filtro por tipo de actividad -->
            <b-field class="w-1/4">
                <b-select v-model="filters.notification_type" size="is-small" rounded icon="filter" @input="reloadNotifications">
                    <option value="all">{{ $t('all_notifications') }}</option>
                    <option value="mentions">{{ $t('mentions') }}</option>
                    <option value="unread">{{ $t('unread') }}</option>
                </b-select>
            </b-field>

            <div class="w-1/2 flex px-2">
                <b-field class="w-1/3">
                    <b-datepicker
                            v-model="filters.start_date"
                            :placeholder="$t('date_start')"
                            :month-names="$t('months_names')"
                            :day-names="$t('days_names')"
                            :first-day-of-week="parseInt($t('first_day_of_week'))"
                            :readonly="true"
                            size="is-small"
                            :max-date="maxDate"
                            @input="reloadNotifications"
                    />
                </b-field>
                <b-field class="w-1/3">
                    <b-datepicker
                            v-model="filters.end_date"
                            :placeholder="$t('date_end')"
                            :month-names="$t('months_names')"
                            :day-names="$t('days_names')"
                            :first-day-of-week="parseInt($t('first_day_of_week'))"
                            :readonly="true"
                            size="is-small"
                            :max-date="maxDate"
                            @input="reloadNotifications"
                    />
                </b-field>
            </div>

        </div>

        <div class="w-full mb-4">
            <filter-tags
                @filterList="filterNotifications($event)"
                ref="filterTags"
            />
        </div>

        <div class="w-full flex">
            <div class="w-1/4 flex justify-content mx-2">
                <button class="button is-primary btn-blue is-size-7" @click="cleanFilters()">{{$t('clean_filters')}}</button>
                <b-tooltip :label="$t('export_notifications')" position="is-right">
                    <button class="button is-primary btn-blue is-size-7 mx-2" @click="exportNotifications()">
                        <b-icon pack="fas" icon="file-export" size="is-small" />
                    </button>
                </b-tooltip>
            </div>
        </div>

    </div>

</template>

<script>
  import Vue from "vue"
  import axios from 'axios'
  import moment from 'moment'
  import FilterTags from "·/components/filter-tags"

  Vue.use(moment)

  export default {
    name: "project-notifications-filter",

    components: {
      FilterTags,
    },

    props: {
      projectId: { type: Number, required: true}
    },

    data: () => ({
      filters: {
        notification_type: 'all',
        start_date: null,
        end_date: null,
        tags: [],
      }
    }),

    computed: {
      maxDate() {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth(), today.getDate())
      }
    },

    methods: {
      cleanFilters() {
        this.filters = {
          'notification_type': 'all',
          'start_date': null,
          'end_date': null,
          'tags': [],
        }

        this.$refs.filterTags.resetTags()

        this.reloadNotifications()
      },

      // Carga las tags recibidas y aplica el filtro
      filterNotifications(tags) {
        this.filters.tags = tags
        this.reloadNotifications()
      },

      reloadNotifications() {
        this.$emit('reload-notifications', this.filters)
      },

      async exportNotifications() {

        let params = { 'filters': this.filters }
        let self = this

        await axios({
          method: 'get',
          url: '/api/v2/user/notifications/project/'+ this.projectId +'/export',
          responseType: 'blob',
          params: params
        })
          .then(function (response) {
            self.$notify.success('success_export');
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.download = self.$t('notifications') + '.xlsx';
            document.body.appendChild(link);
            link.click()
            document.body.removeChild(link);
          })
          .catch(error => {
            self.$notify.error('error_export')
            console.log(error)
          })
      }
    }
  }
</script>

<style scoped>

</style>
