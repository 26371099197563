<template>
  <div class="page-with-top-bar">
    <section>
      <div class="mt-4">
        <b-tabs type="is-boxed" v-model="mainActiveTab">

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="folder"></b-icon>
              <span> {{ $t('folders') }} </span>
            </template>
            <div>

              <folders-bim v-if="showBIM"
                ref="documents-folders-bim"
              />
              <folders v-else
                ref="documents-folders"
              />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="search"></b-icon>
              <span> {{ $t('document_search') }} </span>
            </template>
            <div>
              <folders-files
                ref="documents-folders-files"
              />
            </div>
          </b-tab-item>

        </b-tabs>
      </div>
    </section>

  </div>
</template>

<script>
import Folders from '·/pages/project/activities/folders'
import FoldersFiles from '·/pages/project/activities/folders-files'
import FoldersBim from '·/pages/project/activities/folders-bim'

export default {
  middleware: 'auth',
  title: 'activities_page_title',
  subtitle: 'activities_page_subtitle',
  menuOption: '2-3',
  components: {
    Folders,
    FoldersFiles,
    FoldersBim,
  },

  data: () => ({
    mainActiveTab: 0,
    showBIM: false,
  }),

  created() {
    let query = this.$route.query
    this.showBIM = query.view && query.view === 'containers'
  },

  beforeRouteUpdate(to, from, next) {
    // to: Objeto con información sobre la ruta actual
    // from: Objeto con información sobre la ruta anterior
    // next: Función que debe llamarse para continuar con la navegación

    // Accede al nuevo valor del parámetro de consulta
    this.showBIM = to.query.view && to.query.view === 'containers'

    // Realiza acciones basadas en el nuevo valor del parámetro de consulta

    // Llama a next() para continuar con la navegación
    next();
  }
};
</script>
