<template>
  <div class="slider bg-grey-darkest overflow-y-scroll" :class="sliderClass" style="z-index: 1000">
      <div class="info-entity">
      <div class="row-hide">
        <v-button-icon-round
                icon="arrow-right"
                color="blue"
                tooltip-position="left"
                class="ml-6 float-right"
                @click="showSide"/>
      </div>
      <b class="text-grey text-xs">{{$t('validation_flow')}}</b>
      <div class="text-white text-sm font-semibold">{{validation.template.name}}</div>

      <div class="more-info">
        <b class="text-grey text-xs">{{$t('validation_description')}}</b>
        <div class="info-data text-white text-xs">{{ validation.description? validation.description : 'Sin descripción' }}</div>
      </div>

      <div class="more-info">
        <b class="text-grey text-xs">{{$t('status')}}</b>
        <div class="info-data text-white text-xs">

          <validation-status-label :status="validation.status" class="w-full" />

        </div>
      </div>

      <div class="more-info">
        <b class="text-grey text-xs">{{$t('users')}}</b>
        <div v-if="validation.groups" v-for="group in validation.groups" :key="group.id">
          <div class="text-grey text-xs">{{group.name}}</div>
          <ul>
            <li v-for="user in group.users" :key="user.user_id" >
              <div class="flex items-center info-data" v-if="user.basic.avatar && user.basic.fullname">
                <div class="rounded-full overflow-hidden border border-grey w-8 h-8 mr-2">
                  <img :src="user.basic.avatar" :alt="user.basic.fullname">
                </div>
                <div>
                  <div class="text-blue-lighter text-sm">
                    {{ user.basic.fullname }}
                  </div>
                  <div class="text-grey text-xs" v-if="user.basic.area">
                    {{ user.basic.area }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import validationStatusLabel from '·/components/validations/validation-status-label'

export default {
  name: 'validation-info-sidebar',

  props: {
    validation: { type: Object, required: true }
  },

  components: {
    validationStatusLabel
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  data: () => ({
    sliderClass: '',
    sliderShown: false
  }),

  methods: {

    async showSide() {
      this.sliderClass = this.sliderShown ? 'slide-out' : 'slide-in'
      this.sliderShown = !this.sliderShown
    },
  },

};
</script>

<style scoped>

  .slider {
    position: fixed;
    top: 61px;
    right: 0;
    bottom: 0;
    width: 360px;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  .slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }
  .slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
  }
  @keyframes slide-in {
    100% { transform: translateX(0%); }
  }
  @-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
  }
  @keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(100%); }
  }
  @-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(100%); }
  }

  .info-entity{padding: 20px;}
  .info-entity .row-hide{float: right; width: 100%;}
  .info-entity .main-info{padding-right: 12px; margin-top: 20px; width: 50%;}
  .info-entity .main-info~.main-info{padding-right: 0;}
  .info-entity .info-data{margin-top: 10px; margin-bottom: 10px;}
  .info-entity .more-info{display: inline-block; width: 100%; margin-top: 20px;}
  .info-entity .info-data.progress{height: 58px;}
</style>
