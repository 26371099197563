<template>
<div class="navbarmenu">
	<!-- Avatar y nombre de usuario en la navbar -->
	<div ref="button" class="user p-2 rounded-full" @click="togleMenu(null)">
		<img class="mr-0 sm:mr-3" :src="userAvatar" />
		<div class="font-thin hidden sm:block">
			{{ userName }} <b-icon icon="angle-down" pack="fas" class="w-4 h-4 ml-2 text-grey-light"/>
		</div>

	</div>
	<!-- Menú desplegable -->
	<div v-show="menuShown" ref="dropdown"
		class="z-50 absolute top-14 right-1 p-3"
		> <!--:style="openStyle"-->

    <div class="card-user relative border-0 rounded-md bg-white">

      <div class="card-user-body pt-3 px-3 pb-3">
        <div class="flex w-full flex-col sm:flex-row h-24-">
          <div class="flex justify-center h-full relative">
            <img :src="userAvatar" class="w-24 h-24 rounded-full sm:rounded ">
            <div class="sm:hidden h-9 w-9 flex justify-center items-center absolute right-6 bottom-2 rounded-full text-white"
              style="background-color: rgb(125, 153, 212);"
              @click="togleMenu(false)">
              <router-link :to="'/admin/user/' + userId" class="text-white font-xs">
                <b-icon icon="user-edit" pack="fas"></b-icon>
              </router-link>
            </div>
          </div>
          <div class="flex flex-col justify-between h-full sm:ml-4">
            <div class="w-full">
              <h3 class="w-full truncate text-grey-dark text-center sm:text-left mt-2 sm:mt-0">{{ userName }}</h3>
              <div class="hidden w-full italic font-thin truncate text-grey sm:block">{{ userEmail }}</div>
            </div>
            <div class="hidden sm:block" @click="togleMenu(false)">
              <router-link :to="'/admin/user/' + userId" class="link-grey font-xs">
              {{ $t('edit_profile') }}
            </router-link>
            </div>

          </div>
        </div>
        <div class="overflow-auto max-h-40 mt-4">
          <div v-if="companies && companies.length > 1">
            <div class="text-sm font-thin">Cambiar empresa:</div>
            <ul class="" style="background-color:white;">
              <li v-for="company in companies" :key="company.id">
                <div class="border-b-2 border-gray-200 cursor-pointer"
                  @click="changeCompany(company)">
                  {{ company.name }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-2" v-if="user && user.root == 1">
          <div class="text-sm font-thin">Cambiar de entorno:</div>
          <ul>
            <li class="cursor-pointer p-1 hover:bg-gray-50" v-if="InEnvironment('dev')">
              <div @click="Redirect('app')">
                <b-icon icon="desktop" pack="fas"></b-icon>
                Entorno de producción
              </div>
            </li>
            <li class="cursor-pointer p-1 hover:bg-gray-50" v-if="InEnvironment('app')">
              <div @click="Redirect('dev')">
                <b-icon icon="bug" pack="fas"></b-icon>
                Entorno de desarrollo
              </div>
            </li>
          </ul>
        </div>
        <div class="flex flex-row w-full pt-4">
          <!-- Botón Logout -->
          <v-button
            @click="logout"
            full
            icon="power-off"
            color="red"
          >
            {{ $t('logout') }}
          </v-button>
        </div>
      </div>

    </div>

	</div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data: () => ({
		menuShown: false,
		openStyle: 'opacity:0',
	}),

	computed: {
		userName() {
			if (this.user) {
				return this.user.name + ' ' + this.user.surname
			}
			return ''
		},
		userEmail() {
			if (this.user) {
				return this.user.email
			}
			return ''
		},
		userId() {
			if (this.user) {
				return this.user.id
			}
			return ''
		},
		userAvatar() {
			if (this.user) {
				return this.user.avatar
			}
			return ''
		},
		...mapGetters({
			user: 'auth/user',
			companies: 'app/companies',
		}),
	},

	methods: {
		togleMenu(show) {
			if (this.menuShown == show) {
				return;
			}
			this.menuShown = (show == null) ? !this.menuShown : show
			if (this.menuShown) {

				// Animación de apertura del dropdown
				this.openStyle = 'opacity:1;max-height:220px;'
				setTimeout(() => {
					this.openStyle += 'overflow:visible'
					// Ponemos un listener para capturar los clicks fuera del dropdown
					// para cerrarlo
					document.body.addEventListener('click', this.clickOutsideEvent)
				}, 500)

			} else {
				// Animación de cierre del dropdown
				this.openStyle = 'overflow:hidden;max-height:0'
				setTimeout(() => {
					this.openStyle = 'opacity:0'
					// Quitamos el listener
					document.body.removeEventListener('click', this.clickOutsideEvent)
				}, 500)
			}
		},

		clickOutsideEvent(event) {
			// Miramos si el click ha sido fuera del dropdown para cerrarlo
			if (! (this.$refs.dropdown == event.target || this.$refs.dropdown.contains(event.target))) {
				event.stopPropagation()
        this.togleMenu(false)
      }
		},

		async logout () {
      // Log out the user.
			await this.$store.dispatch('app/logout')
			await this.$store.dispatch('auth/logout')
      
      // logout tambien de microsoft si fue logueado asi
      if (this.$store.getters["ms-entra-id/logged"]) {
        await this.$store.dispatch('ms-entra-id/logout');
      }

			// Redirect to login.
			this.$router.push({ name: 'login'})
		},

		async changeCompany(company) {
			await this.$store.dispatch("app/setCompany", { company });
			await this.$store.dispatch("app/setGroup", { group: null });
      		await this.$router.push({ name: "group.preselect" });
			await this.$store.dispatch("app/setProject", { project: null }); // primero el router, para q resetear el project no de undefined por ahi
		},

    Redirect(to) {
      const url = 'https://[TO].epc-tracker.com'
      window.location.replace( url.replace('[TO]', to) )
    },

    InEnvironment(to) {
      const hostname = window.location.hostname;
      return hostname.startsWith(to)
    }
	},

	beforeDestroy() {
		// Nos aseguramos de quitar el listener de clicks
		document.body.removeEventListener('click', this.clickOutsideEvent)
	}

};
</script>

<style scoped>

.card-user {
  border: 1px solid #cbd0dd;
}

.card-user:after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--phoenix-black);
  top: -10px;
  right: 0.9rem;
  left: auto;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: white;
  background: inherit;
  border-top-left-radius: 0.25rem;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #cbd0dd;
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,.075);
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,.075);
}

@media (min-width: 640px) {
  /* hack para evitar el hidden de pdf tron que tiene important */
  .sm\:block {
    display: block !important;
  }

  .sm\:mr-3 {
    margin-right: 0.75rem !important;
  }
}
</style>
