<template>
  <section>

    <div class="flex flex-col">

      <div class="flex flex-col mt-4">
        <b-field v-if="selectMsg.length" :label="selectMsg"></b-field>
        <b-field v-else :label="$t('select')"></b-field>
        <treeselect v-model="node_selected" :close-on-select="true" :options="mappedTree" />
      </div>

    </div>

  </section>
</template>

<script>

import EventBus from '~/plugins/bus'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  
export default {

  components: {
    Treeselect
  },


  props: {
    tree: { type: Array, required: true }, // array de carpetas en bruto
    preSelected: { type: Number, required: false },
    rootNode: { type: Boolean, default: false }, // añade un nodo raiz a la seleccion (representa carpeta virtual raiz de la actividad)
    type: { type: String, required: false }, // un string para identificar que tipo de select esta mostrando y disparar determinadas acciones
    selectMsg: { type: String, default: '' }, // string opcional para mensaje 'Selecciona...'
  },


  data() {
    return {
      node_selected: null,
      mappedTree: [], // el array recibido, mapeado como requiere el componente 'treeselect'
    }
  },


  watch: {
    tree: function() {
      this.mappedTree = this.mapTree(this.tree);
    },

    node_selected: function() {
      // segun el tipo del selector, disparará un evento un otro 
      if (this.type && this.type == 'activities') {

        EventBus.$emit('treeSelectorNodeSelected-ActivitySelected', this.node_selected) // para recargar carpetas

      } else if (this.type && this.type == 'otrostiposfuturos') {

      } else {
        // default event
        EventBus.$emit('treeSelectorNodeSelected', this.node_selected)
      }
    }
  },


  created(){
    this.mappedTree = this.mapTree(this.tree);

    // si pasamos opcion preseleccionada, marcarla
    if (this.preSelected)
      this.node_selected = this.preSelected
  },


  methods: {

    // devuelve las carpetas de la actividad tal como los necesita el componente treeselect (nodos {id, label, children})
    mapTree(tree) {

      var map = {}, node, roots = [], i;

      // insertamos una opcion root para poder mover documentos al raiz
      if (this.rootNode) {
        roots.push({id: 0, label: this.$t('root')})
      }

      for (i = 0; i < tree.length; i += 1) {
        map[tree[i].id] = i; // initialize the map
        tree[i].children = []; // initialize the children
      }
      
      for (i = 0; i < tree.length; i += 1) {
        node = tree[i];
        if (node.parent_id !== null) {
          tree[map[node.parent_id]].children.push({id: node.id, label: node.name, children: node.children});
        } else {
          roots.push({id: node.id, label: node.name, children: node.children});
        }
      }

      this.cleanEmptyChildrens(roots)

      return roots;
    },


    // limpia los nodos eliminando la key 'children' si está vacio
    cleanEmptyChildrens(tree) {

      for (let i = 0; i < tree.length; i += 1) {
        // Caso base 1. No tiene la key 'children', do nothing
        if (!tree[i].children)
          continue;
        
        // Caso base 2. Tiene la key vacia, eliminarla
        if (tree[i].children && tree[i].children.length == 0) {
          delete tree[i].children
          continue;

        // tiene childrens, pues llamar recursivamente
        } else {
          this.cleanEmptyChildrens(tree[i].children)
        }
      }
      return true

    },

  }
}
</script>
