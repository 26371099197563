<template>
<div>
  <!-- Organigrama -->
  <div class="panel">
    <orgchart ref="orgchart"
      get-data-url="/api/v2/areas"
      :key="orgchartKey"
      pan
      chart-container-name="areas-chart-container"
      @selectedNodeChanged="orgchartSelectedNodeChanged"
      class="title-align-left title-uppercase" />
  </div>
  <transition
    name="custom-classes-transition"
    enter-active-class="animated fadeInUp"
    leave-active-class="animated fadeOutDown">
    <div v-show="selectedAreaNode!=null">

      <div v-show="selectedAreaNodeData!=null && selectedAreaNodeData.can_admin" class="w-full flex items-center mb-4">
        <div v-if="selectedAreaNodeData" class="w-3/4">
          <span class="label">
            {{ $t('reference_code') }}
          </span>
          <span>
            {{ selectedAreaNodeData.reference_code }}
          </span>
        </div>

        <div class="w-1/4" style="display: flex; justify-content: flex-end">
          <v-button @click="deleteArea" color="red" icon="trash-alt" icon-pack="far">
            {{ $t('delete_area') }}
          </v-button>
        </div>
      </div>

      <div class="panel" >
        <div class="w-full flex flex-wrap mt-4">
          <!-- Nombre del área -->
          <div class="w-full md:w-1/2 pr-3">
            <form @submit.prevent="changeAreaName" @keydown="formChangeAreaName.onKeydown($event)">
              <l-form-input-with-button
                :form="formChangeAreaName"
                field="area_name"
                label="area_field_title"
                input-id="selected-node"
                input-name="area_name"
                placeholder="area_field_title"
                button-title="save"
                button-type="submit"
                button-icon="check"
                :loading="formChangeAreaName.busy"
                :disabled="selectedAreaNode && !selectedAreaNodeData.user_permissions['create_area']"
                full
              />
             </form>
          </div>
          <!-- Área hija -->
          <div class="w-full md:w-1/2 pl-3" >
            <form @submit.prevent="addChildArea" @keydown="formAddChildArea.onKeydown($event)">
              <l-form-input-with-button
                :form="formAddChildArea"
                field="child_area_name"
                label="child_area_field_title"
                input-id="child_area_name"
                input-name="child_area_name"
                placeholder="child_area_name_placeholder"
                button-title="add"
                button-type="submit"
                button-icon="plus"
                :loading="formAddChildArea.busy"
                :disabled="selectedAreaNode && !selectedAreaNodeData.user_permissions['create_area']"
                full
              />
             </form>
          </div>
        </div>

        <!-- Área padre -->
        <change-parent
                :node="selectedAreaNodeData"
                element-type="area"
                :disabled="selectedAreaNode && !selectedAreaNodeData.user_permissions['create_area']">
        </change-parent>

        <!-- Usuarios -->
        <div class="w-full flex flex-wrap mt-8 pt-4 mb-8">
          <!-- Administradores -->
          <userlist ref="managers"
            title="area_managers"
            icon="cog"
            :parent-entity-id="selectedAreaNodeData ? selectedAreaNodeData.id : null"
            api-url="/api/v2/area"
            :users-permission-type="['admin']"
            :only-internal-users="true"
            :disabled="selectedAreaNode && !selectedAreaNodeData.user_permissions['assign_area_admin']"
            class="w-full md:w-1/2 pr-3"
            roleName="area_admin"
          />
          <!-- Visualizadores -->
          <userlist ref="viewers"
            title="viewers_users"
            icon="eye"
            :parent-entity-id="selectedAreaNodeData ? selectedAreaNodeData.id : null"
            api-url="/api/v2/area"
            :users-permission-type="['view']"
            :allow-zero-users="true"
            :only-internal-users="true"
            :disabled="selectedAreaNode && !selectedAreaNodeData.user_permissions['assign_area_viewer']"
            class="w-full md:w-1/2 pl-3"
            roleName="viewer"
          />
        </div>
      </div>
    </div>
  </transition>
</div>
</template>

<script>
import axios from 'axios'
import Form from 'vform'
import Orgchart from '~/components/OrgChart/OrgChart.vue'
import Userlist from '~/components/UserBox/UserList'
import changeParent from '../../components/change-parent'

export default {
  middleware: 'auth',
  title: 'areas_page_title',
  subtitle: 'areas_page_subtitle',
  menuOption: '0-0',

  components:{
    Orgchart,
    Userlist,
    changeParent
  },

  data: () => ({
    orgchart: null,
    orgchartKey: 0,
    selectedAreaNode: null,
    selectedAreaNodeData: null,
    formChangeAreaName: new Form({
      area_name: '',
    }),
    formAddChildArea: new Form({
      parent_area_id: -1,
      child_area_name: '',
    }),
  }),

  mounted () {
    this.orgchart = this.$refs.orgchart
  },

  methods: {
    orgchartSelectedNodeChanged(node) {
      if (node === null) {
        this.resetSelectedNode()
      } else {
        this.selectedAreaNode = node
        this.selectedAreaNodeData = JSON.parse(node.getAttribute('data-source'))
        this.formChangeAreaName.area_name = this.selectedAreaNodeData.name
        this.formAddChildArea.parent_area_id = this.selectedAreaNodeData.id
      }
      console.log(this.selectedAreaNode && !this.selectedAreaNodeData.user_permissions['create_area'])
    },

    resetSelectedNode() {
      this.selectedAreaNode = null
      this.selectedAreaNodeData = null
      this.formChangeAreaName.reset()
      this.formAddChildArea.reset()
    },

    async changeAreaName() {
      // Si no hay un nodo seleccionado...
      if (! this.selectedAreaNode) {
        return;
      }

      const { data } = await this.formChangeAreaName.post('/api/v2/area/' + this.selectedAreaNodeData.id)
      if (data && data.success) {
        this.selectedAreaNodeData.name = this.formChangeAreaName.area_name
        this.selectedAreaNode.setAttribute('data-source', JSON.stringify(this.selectedAreaNodeData))
        this.selectedAreaNode.querySelector('.title').textContent = this.selectedAreaNodeData.name
        this.$notify.success('area_change_name_success')
      } else {
        // Error
        this.$notify.error('area_change_name_error')
      }
    },

    async addChildArea() {
      // Si no hay un nodo seleccionado...
      if (! this.selectedAreaNode && !this.formAddChildArea.child_area_name) {
        return;
      }

      const { data } = await this.formAddChildArea.post('/api/v2/area/' + this.selectedAreaNodeData.id + '/add-child')
      if (data && data.success) {
        this.orgchart.addNode(this.selectedAreaNode, data.child_area)
        this.formAddChildArea.reset()
      } else {
        this.$notify.error(data.error)
      }
    },

    deleteArea() {
      this.$dialog.confirm({
        title: this.$t('delete_area'),
        message: this.$t('delete_area_confirm_text', [this.selectedAreaNodeData.name]),
        confirmText: this.$t('delete_area'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          let url = '/api/v2/area/' + this.selectedAreaNodeData.id + '/delete'
          const { data } = await axios.post(url)
          if (data && data.success) {
            this.$notify.success('success_deleting_area')
            this.orgchart.removeNode(this.selectedAreaNode)
            this.resetSelectedNode()
          } else {
            this.$notify.error(data.error)
          }
        }
      })
    },

    reloadOrganigram() {
      this.orgchartKey++
      this.resetSelectedNode(true)
    },
  },
};
</script>
