<template>
  <section :class="{'inline-block': !openWithButton}">

    <span v-if="!openWithButton" class="px-1 w-8 mt-2 cursor-pointer hover:text-gray-700"
    @click="isModalActive = true">
      <b-tooltip :label="$t('project_report')" position="is-left">
        <i class="fas w-8 fa-book p-2 bg-gray-200 rounded-full"></i>
      </b-tooltip>
    </span>

    <div v-else class="mr-2 rounded-sm" @click="isModalActive = true">
      <v-button icon="book" class="mr-4">{{ $t('project_report') }}</v-button>
    </div>

    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="w-1/2 modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('generate_report') }}</p>
        </header>
        <section class="modal-card-body">
          <!-- Cuerpo del Modal-->
          <div class="w-1/2 mb-4 mr-4">
            <label class="md:w-1/3">{{ $t('periods_quantity')}}</label>
            <v-input
              v-model="totalPeriods"
              type="number"
              title="totalPeriods"
              required="true"
              class="md:w-2/3"
              step="1"
              max="100"
              min="1"
            />
          </div>
          <div class="w-1/2 mb-4 mr-4">
            <b-field :label="$t('start_date')">
              <b-datepicker
                v-model="periods[0]"
                :placeholder="$t('scheduled_start_date')"
                :month-names="$t('months_names')"
                :day-names="$t('days_names')"
                :first-day-of-week="parseInt($t('first_day_of_week'))"
                :readonly="false"
              >
              </b-datepicker>
            </b-field>
          </div>
          <div class="w-1/2 mb-4 mr-4" v-for="n in sizeOfList()">
            <b-field :label="$t('end_date') +' '+ n">
              <b-datepicker
                v-model="periods[n]"
                :placeholder="$t('scheduled_start_date')"
                :month-names="$t('months_names')"
                :day-names="$t('days_names')"
                :first-day-of-week="parseInt($t('first_day_of_week'))"
                :readonly="false"
              >
              </b-datepicker>
            </b-field>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" :disabled="isLoading" v-on:click="generateReport">{{ btnGenerateReport }}
          </button>
          <button class="button" size="is-small" type="button" @click="isModalActive = false">{{ $t('close') }}</button>
        </footer>
      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

    </b-modal>
  </section>
</template>

<script>

  import {mapGetters} from 'vuex'
  import axios from "axios";
  import moment from 'moment';

  export default {
    props: {
      openWithButton: {  type: Boolean, default: true }
    },

    data() {
      return {
        totalPeriods: "1",
        periods: [],
        btnGenerateReport: this.$t('generate_report'),
        isModalActive: false,
        isLoading: false
      }
    },

    computed: {
      ...mapGetters({
        project: 'app/project',
      }),
    },

    methods: {
      async generateReport() {

        let periods = [];
        for (let i in this.periods) {
          if (i > this.periods)
            break; //Por si el usuario añadió periodos pero luego los disminuyó
          periods.push(moment(this.periods[i]).format('YYYY-MM-DD'))
        }

        let params = {periods: periods};

        // Generando reporte, cerramos el modal
        this.$notify.success('generating_report');
        this.isModalActive = false;

        //Encargamos el reporte al servidor
        const {data} = await axios.get('/api/v2/project/' + this.project.id + '/assignments/generate',
          {params: params});

        //si nos llega un reporte hay que descargarlo
        if (data) {

          // Descarga
          window.open(data.data);

          // Loading
          this.isLoading = false;
          this.btnGenerateReport = this.$t('generate_report');
          this.isModalActive = false;

          // Reporte generado
          this.$notify.success('report_generated')

        } else {

          // Error
          this.$notify.error('error_loading_report')

          // Loading
          this.isLoading = false;
          this.btnGenerateReport = 'Generar Informe';

        }
      },
      sizeOfList() {
        return parseInt(this.totalPeriods);
      },

    }
  }
</script>

<style scoped lang="scss">
  .modal-card {
    width: 100%;
  }

  .modal-card-body {
    min-height: 480px;
    min-width: 560px;
  }
</style>
