<template>
  <div class="page-with-top-bar">
    <section>
      <div class="mt-4">
        <b-tabs type="is-boxed" v-model="mainActiveTab">
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="pencil-ruler"></b-icon>
              <span>{{ $t('measurement_units') }}</span>
            </template>
            <div>
              <!-- si se optimiza la manera de mejorar el manejo de las company en admin quitar el if -->
              <measurement-units-manual v-if="company"
                ref="measurement_units_manual"
                :company-id="companyId"
                :is-admin-measurements="true"
              />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-import"></b-icon>
              <span>{{ $t('import_measurement_units') }}</span>
            </template>
            <div>
              <!-- si se optimiza la manera de mejorar el manejo de las company en admin quitar el if -->
              <import-entities v-if="company"
                :can-overwrite="true"
                :project-id="companyId"
                entity-type="measurement_units"
                @success="measurementUnitsImportSuccess"
              />
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import MeasurementUnitsManual from "../../../components/measurement-units-manual";
  import ImportEntities from "../../../components/import-entities";

  export default {
    middleware: "auth",
    title: "measurement_units_page_title",
    subtitle: 'measurement_units',

    menuOption: "0-7",

    components: {
      ImportEntities,
      MeasurementUnitsManual,
    },

    computed: {
      ...mapGetters({
        company: "app/company",
      }),
      companyId() {
        return parseInt(this.company.id);
      }
    },

    data: () => ({
      mainActiveTab: 0
    }),

    watch: {
      mainActiveTab(value) {
        if (value == 0) {
          // no aplicable
          //this.$refs.project_assignments_manual.reload();
        }
      }
    },

    created() {
    },

    mounted() {
      // Redireccionamos si no ha seleccionado la company, esto no es del todo correcto ya que se esta trabajando
      // en el area de administración lo cual se deberīa cambiar esta logica a futuro para que la logica del area de
      // administración este separada la de trabajo
      if (!this.company) {
        this.$notify.info('Debe seleccionar una empresa')
        this.$router.push({ name: "company.select" });
      }
    },

    methods: {
      measurementUnitsImportSuccess() {
        this.$refs.measurement_units_manual.reload();
      },
    }
  };
</script>
