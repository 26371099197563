<template>
  <section :class="{'inline-block': !openWithButton}">
    <button-menu v-if="!openWithButton"
      :text-label="$t('generate_report') + ' csv'"
      :icon="'file-csv'"
      :active-tooltip="true"
      :positionTooltip="'is-left'"
      :redirect="null"
      :handle-on-click="() => { show() }"
    />

    <div  v-if="openWithButton" class="mr-2 rounded-sm" @click="show()">
      <v-button icon="file-csv" class="mr-4"></v-button>
    </div>
    <b-modal :active.sync="isModalActive" has-modal-card :on-cancel="() => { reports = [] }">
      <div class="w-1/2 modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('generate_report') }}</p>
        </header>
        <!-- Cuerpo del Modal-->
        <section class="modal-card-body">

          <b-tabs type="is-toggle-rounded" size="is-small" position="is-centered"
            v-model="reportType">
              <b-tab-item>
                <template slot="header">
                  <b-icon pack="fas" icon="file"></b-icon>
                  <span>Informe general</span>
                </template>
                <div>
                  Informe de todos los DSOs y sus campos adicionales
                  <div class="flex flex-wrap mt-4">
                    <div class="flex items-center my-4 w-full">
                      <b-loading :is-full-page="false" :active.sync="isLoadingReports" :can-cancel="false"></b-loading>
                      <table class="w-full" v-if="!isLoadingReports">
                        <thead class="bg-blue-lightest">
                          <tr>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">Descripción de reporte</th>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">Fecha</th>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">Accion</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="hover:bg-grey-lightest" v-for="(report, index) in reportGeneral" :key="'reportgeneral_'+index">
                            <td class="justify-start w-auto h-full p-4 align-middle">Informe general</td>
                            <td class="justify-start w-auto h-full p-4 align-middle">{{ formatDate(report.date) }}</td>
                            <td class="justify-start w-auto h-full p-4 align-middle text-xs">
                                <b-tooltip :label="$t('download_document')" position="is-left">
                                    <div @click="downloadDoc(report.url)"
                                        class="flex cursor-pointer text-sky-300">
                                      <b-icon pack="fas" icon="download"/>
                                    </div>
                                </b-tooltip>
                            </td>
                          </tr>
                          <tr class="text-center" v-if="!reportGeneral.length">
                            <td colspan="3" class="text-center w-auto h-full p-4 align-middle text-blue font-semibold">Sin reportes</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!-- <div class="w-1/2 mb-4 mr-4">
                  <b-field :label="$t('start_date')">
                    <b-datepicker
                      v-model="start_date"
                      :placeholder="$t('scheduled_start_date')"
                      :month-names="$t('months_names')"
                      :day-names="$t('days_names')"
                      :first-day-of-week="parseInt($t('first_day_of_week'))"
                      :readonly="false"
                    >
                    </b-datepicker>
                  </b-field>
                </div> -->
                <!-- <div class="w-1/2 mb-4 mr-4" >
                  <b-field :label="$t('end_date')">
                    <b-datepicker
                      v-model="end_date"
                      :placeholder="$t('scheduled_end_date')"
                      :month-names="$t('months_names')"
                      :day-names="$t('days_names')"
                      :first-day-of-week="parseInt($t('first_day_of_week'))"
                      :readonly="false"
                    >
                    </b-datepicker>
                  </b-field>
                </div> -->
                </div>
              </b-tab-item>

              <b-tab-item>
                <template slot="header">
                  <b-icon pack="fas" icon="road"></b-icon>
                  <span>Informe de calles</span>
                </template>
                <div>
                  Informe de todas las calles de cada DSO
                  <div class="flex flex-wrap mt-4">
                    <div class="flex items-center my-4 w-full">
                      <b-loading :is-full-page="false" :active.sync="isLoadingReports" :can-cancel="false"></b-loading>
                      <table class="w-full" v-if="!isLoadingReports">
                        <thead class="bg-blue-lightest">
                          <tr>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">Descripción de reporte</th>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">Fecha</th>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">Accion</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="hover:bg-grey-lightest" v-for="(report, index) in reportStreets" :key="'reportstreet_'+index">
                            <td class="justify-start w-auto h-full p-4 align-middle">Informe de calles</td>
                            <td class="justify-start w-auto h-full p-4 align-middle">{{ formatDate(report.date) }}</td>
                            <td class="justify-start w-auto h-full p-4 align-middle text-xs">
                               <b-tooltip :label="$t('download_document')" position="is-left">
                                  <div @click="downloadDoc(report.url)"
                                      class="flex cursor-pointer text-sky-300">
                                    <b-icon pack="fas" icon="download"/>
                                  </div>
                              </b-tooltip>
                            </td>
                          </tr>
                          <tr class="text-center" v-if="!reportStreets.length">
                            <td colspan="3" class="text-center w-auto h-full p-4 align-middle text-blue font-semibold">Sin reportes</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </b-tab-item>

              <b-tab-item>
                <template slot="header">
                  <b-icon pack="fas" icon="wrench"></b-icon>
                  <span>Elementos</span>
                </template>
                <div>
                  <div class="flex flex-wrap mt-4">
                    <div class="flex items-center my-4 w-full">
                      <b-loading :is-full-page="false" :active.sync="isLoadingReports" :can-cancel="false"></b-loading>
                      <table class="w-full" v-if="!isLoadingReports">
                        <thead class="bg-blue-lightest">
                          <tr>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">Descripción de reporte</th>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">Fecha</th>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">Accion</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="hover:bg-grey-lightest" v-for="(report, index) in reportElements" :key="'reportstreet_'+index">
                            <td class="justify-start w-auto h-full p-4 align-middle">Elementos</td>
                            <td class="justify-start w-auto h-full p-4 align-middle">{{ formatDate(report.date) }}</td>
                            <td class="justify-start w-auto h-full p-4 align-middle text-xs">
                               <b-tooltip :label="$t('download_document')" position="is-left">
                                  <div @click="downloadDoc(report.url)"
                                      class="flex cursor-pointer text-sky-300">
                                    <b-icon pack="fas" icon="download"/>
                                  </div>
                              </b-tooltip>
                            </td>
                          </tr>
                          <tr class="text-center" v-if="!reportElements.length">
                            <td colspan="3" class="text-center w-auto h-full p-4 align-middle text-blue font-semibold">Sin reportes</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </b-tab-item>

              <b-tab-item>
                <template slot="header">
                  <b-icon pack="fas" icon="file"></b-icon>
                  <span>{{ $t('pipes') }}</span>
                </template>
               <div>
                <div class="flex flex-wrap mt-4">
                  <div class="flex items-center my-4 w-full">
                    <b-loading :is-full-page="false" :active.sync="isLoadingReports" :can-cancel="false"></b-loading>
                    <table class="w-full" v-if="!isLoadingReports">
                      <thead class="bg-blue-lightest">
                        <tr>
                          <th class="p-2 text-sm font-semibold uppercase text-blue-dark">Descripción de reporte</th>
                          <th class="p-2 text-sm font-semibold uppercase text-blue-dark">Fecha</th>
                          <th class="p-2 text-sm font-semibold uppercase text-blue-dark">Accion</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="hover:bg-grey-lightest" v-for="(report, index) in reportDismissedPipes" :key="'reportsPipes_'+index">
                          <td class="justify-start w-auto h-full p-4 align-middle">{{ $t('pipes') }}</td>
                          <td class="justify-start w-auto h-full p-4 align-middle">{{ formatDate(report.date) }}</td>
                          <td class="justify-start w-auto h-full p-4 align-middle text-xs">
                             <b-tooltip :label="$t('download_document')" position="is-left">
                                <div @click="downloadDoc(report.url)"
                                    class="flex cursor-pointer text-sky-300">
                                  <b-icon pack="fas" icon="download"/>
                                </div>
                            </b-tooltip>
                          </td>
                        </tr>
                        <tr class="text-center" v-if="!reportDismissedPipes.length">
                          <td colspan="3" class="text-center w-auto h-full p-4 align-middle text-blue font-semibold">Sin reportes</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                  
               </div>
              </b-tab-item>

          </b-tabs>



        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" :disabled="isLoading" v-on:click="generateReport">{{ btnGenerateReport }}
          </button>
          <button class="button" size="is-small" type="button" @click="isModalActive = false">{{ $t('close') }}</button>
        </footer>
      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

    </b-modal>
  </section>
</template>

<script>

import {mapGetters} from 'vuex'
import axios from "axios";
import moment from 'moment';
import ButtonMenu from '../components/quick-button-menu/components/button-menu.vue'

  export default {
    components: {
      ButtonMenu
    },

    props: {
      openWithButton: {  type: Boolean, default: true }
    },

    data() {
      return {
        // totalPeriods: "1",
        // start_date: null,
        // end_date: null,
        reportType: 0, // opcion tab elegida (0 informe gral, 1 informe de calles, 2 informe de elementos, 3 informe de tuberias)
        btnGenerateReport: this.$t('generate_report'),
        isModalActive: false,
        isLoading: false,

        reports: [],
        isLoadingReports: false,
      }
    },

    computed: {
      ...mapGetters({
        project: 'app/project',
      }),

      reportGeneral() {
        return this.reports.filter( item => item.type === 'project' )
      },

      reportStreets() {
        return this.reports.filter( item => item.type === 'streets' )
      },

      reportElements() {
        return this.reports.filter( item => item.type === 'elements' )
      },

      reportDismissedPipes() {
        return this.reports.filter( item => item.type === 'dismissedPipes' )
      }
    },

    methods: {

      async generateReport() {
        let reportTypeMap = {
          0: 'general',
          1: 'streets',
          2: 'elements',
          3: 'dismissedPipes'
        };

        let params = {
          projectId: this.project.id,
          reportType: reportTypeMap[this.reportType],
          // start_date: moment(this.start_date).format('YYYY-MM-DD'),
          // end_date: moment(this.end_date).format('YYYY-MM-DD')
        };

        // Generando reporte, cerramos el modal
        this.$notify.success('generating_report');
        this.isModalActive = false;

        //Encargamos el reporte al servidor
        const {data} = await axios.get('/api/v2/report/generate_lote', {params: params});

        //si nos llega un reporte hay que descargarlo
        if (data) {

          // Descargamos
          window.open(data.data);

          // Loading
          this.isLoading = false;
          this.btnGenerateReport = this.$t('generate_report');
          this.isModalActive = false;

          // Reporte generado
          this.$notify.success('report_generated')

        } else {

          // Error
          this.$notify.error('error_loading_report')

          // Loading
          this.isLoading = false;
          this.btnGenerateReport = 'Generar Informe';

        }
      },

      async getReportsCsv() {
        this.isLoadingReports = true

        const { data } = await axios.get(
          "/api/v2/report/project/" + this.project.id + "/list")

        if (data && data.success) {
          this.isLoadingReports = false
          this.reports = data.reports
        }
      },

      async downloadDoc(url) {
          window.open(url);
      },

      show() {
        this.getReportsCsv();
        this.isModalActive = true;
      },

      formatDate(dateString){
        return moment(new Date(dateString)).format("DD/MM/YYYY")
      }
    }
  }
</script>

<style scoped lang="scss">
  .modal-card {
    width: 100%;
  }

  .modal-card-body {
    min-height: 280px;
    min-width: 560px;
  }
</style>
