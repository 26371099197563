<template>
  <div class="page-with-top-bar">
    <project-admin-quick-buttons hiddenOption="work-orders"/>
    <section>
      <div class="mt-4">
        <b-tabs type="is-boxed" v-model="mainActiveTab">

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="tasks"></b-icon>
              <span> {{ $t('work_orders') }} </span>
            </template>
            <div>
              <work-orders-manage
                ref="work_orders_manage"
                :project-id="projectId"
              />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-import"></b-icon>
              <span>{{ $t('import_orders') }}</span>
            </template>
            <div>
              <work-orders-import
                ref="refWorkOrdersImport"
                :project-id="projectId"
              />
            </div>
          </b-tab-item>

        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import WorkOrdersManage from '../../components/work-orders/work-orders-manage.vue'
  import WorkOrdersImport from '../../components/work-orders/work-orders-import'
  import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons'

  export default {
    middleware: 'auth',
    title: 'work_orders',
    menuOption: '0-2',

    components: {
      WorkOrdersManage,
      WorkOrdersImport,
      ProjectAdminQuickButtons
    },

    computed: {
      ...mapGetters({
        navData: "app/navData",
      }),
      projectId() {
        return parseInt(this.$route.params.project_id)
      },
    },

    data: () => ({
      mainActiveTab: 0
    }),

    watch: {
      mainActiveTab(value) {
        switch (value) {
          case 0:
            // this.$refs.technical_specification_type.reload(); break;
          case 1:
            // this.$refs.technical_specification.reload(); break;
        }
      }
    },

    created() {
      let projectName = this.navData.project_name
        ? this.navData.project_name.toUpperCase()
        : "";
      this.$options.subtitle = ["project_actions_page_subtitle", [projectName]];
    },

    methods: {}
  };
</script>
