<template>
<div>
  <div
    @click="showPreview"
    class="entity-document-thumbail cursor-pointer"
  >
    <!-- <b-tooltip :label="$t('document_tooltip', [document.name, document.created_at, document.user.fullname])" multilined class=""> -->
      <div class="absolute w-full h-full" style="padding:1px">
        <img v-if="thumbnail" :src="thumbnail" class="w-full h-full" @error="imgError">
        <div v-else
          :style="{ 'background-color': backgroundColor }"
          class="w-full h-full text-white text-2xl font-semibold flex items-center justify-center"
        >
          {{ fileExtension() }}
        </div>
        <div class="overlay absolute inset-0 bg-white text-black flex items-center justify-center">
          <b-icon pack="fas" icon="search-plus" size="is-large" />
        </div>
      </div>
    <!-- </b-tooltip> -->
  </div>
  <!-- Modal para PREVIEW -->
  <entity-document-preview ref="previewModal"
    :entity-id="parentId"
    :entity-type="entityType"
    :can-delete="canDelete"
    :showVersions="showVersions"
    @reload-documents-list="() => $emit('reload-documents-list')"
  />
</div>
</template>

<script>
import EventBus from "~/plugins/bus";
import axios from "axios";
import entityDocumentPreview from "./entity-document-preview";

export default {
  name: "entity-document-thumbnail",

  components: {
    entityDocumentPreview
  },

  props: {
    parentId: { type: Number, required: true },
    entityType: { type: String, required: true },
    document: { type: Object, required: true },
    canDelete: { type: Boolean, default: false },
    showVersions: { type: Boolean, default: true },
  },

  data: () => ({
    thumbnail: null,
    backgroundColor: null
  }),

  mounted() {
    this.thumbnail = this.document.file.thumbnail
  },

  methods: {
    // async downloadThumbnail() {
    //   let url = "/api/v2/document/" + this.document.document_id + "/200";
    //   const { headers, data } = await axios.get(url, {
    //     responseType: "arraybuffer"
    //   });
    //   if (data && data.byteLength > 0) {
    //     let mime = headers["content-type"];
    //     this.thumbnail =
    //       "data:" +
    //       mime +
    //       ";base64," +
    //       new Buffer(data, "binary").toString("base64");
    //   } else {
    //     this.colorBackground();
    //   }
    // },

    imgError() {
      this.thumbnail = null;
      this.colorBackground();
    },

    showPreview() {
      this.$refs.previewModal.show(this.document);
      if (this.document.exif) {
        EventBus.$emit("load-locations", this.document.exif);
      }
    },

    fileExtension() {
      let index = this.document.name.lastIndexOf(".");
      if (index) {
        let ext = this.document.name.substr(index + 1);
        if (ext) {
          return ext.toUpperCase();
        }
      }
      return "";
    },

    colorBackground() {
      var codes = [0, 0, 0];
      let ext = this.fileExtension();
      for (var i = 0; i < ext.length; i++) {
        codes[i] = ext.charCodeAt(i);
        if (i == 2) {
          break;
        }
      }
      var rgb = (codes[2] + 100) | ((codes[1] + 50) << 8) | (codes[0] << 16);
      this.backgroundColor = "#" + (0x1000000 + rgb).toString(16).slice(1);
      // console.log('aquí', hexColor)
    }
  }
};
</script>

<style scoped>
.entity-document-thumbail {
  /*opacity: 1;*/
  position: relative;
  /*  display: block;
  width: 100%;
  height: auto;*/
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.entity-document-thumbail:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.entity-document-thumbail .overlay {
  transition: 0.5s ease;
  opacity: 0;
  /*  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;*/
}
/*.entity-document-thumbail:hover .overlay {
  opacity: 0.3;
}*/
.entity-document-thumbail:hover .overlay {
  opacity: 0.5;
}
</style>
