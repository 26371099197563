<template>

<nav class="flex w-full h-8" aria-label="Breadcrumb">
  <ol class="inline-flex items-center space-x-1 md:space-x-3">
    <li class="inline-flex items-center">
      <span class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600"
        :class="{ 'cursor-pointer': emitClickRoot }"
        @click="_HandleClickRoot">
        <b-icon icon="folder" pack="fas" type="is-info"></b-icon>
        {{ $t('root') }}
      </span>
    </li>
    <li v-if="tree.length > 2">
      <div class="flex items-center">
        <svg class="w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <b-dropdown aria-role="list" class="z-50">
            <template #trigger="{ active }">
              <span class="rounded-full w[36px] h[36px] menu-hover cursor-pointer">
                <b-icon icon="ellipsis-h" pack="fas"></b-icon>
              </span>
            </template>

            <b-dropdown-item v-for="item, index in getFirstElementsTree()" :key="'bradcrumb_'+item.id"
              aria-role="listitem"
              @click="_OpenFolder(item)">
              <div >
                <b-icon icon="folder" pack="fas"></b-icon>
                {{ item.name }}
              </div>
            </b-dropdown-item>
        </b-dropdown>
      </div>
    </li>
    <li v-for="step, index in getLastElementsTree(2)" :key="'breadcrumb_'+step.id">
      <div class="flex items-center">
        <svg class="w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 cursor-pointer link_step"
          @click="_OpenFolder(step)">{{ step.name }}</span>
      </div>
    </li>
    <!-- <li>
      <div class="flex items-center">
        <svg class="w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <a href="#" class="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">Projects</a>
      </div>
    </li>
    <li aria-current="page">
      <div class="flex items-center">
        <svg class="w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Flowbite</span>
      </div>
    </li> -->
  </ol>
</nav>
</template>

  <script>
  import EventBus from "~/plugins/bus";

  export default {

    components: {

    },

    props: {
      emitClickRoot: { type: Boolean, default: false }
    },

    data: () => ({
      tree: [] //[{ id: null, name: this.$t('root'), is_virtual: true }]
    }),

    methods: {
      getLastElementsTree(n) {
        if (this.tree.length > 2)
          return this.tree.slice(Math.max(this.tree.length - n, 0));

        return this.tree
      },

      getFirstElementsTree() {
        if (this.tree.length > 2)
          return this.tree.slice(0, this.tree.length - 2);
        return this.tree
      },

      // Establece el Breadcrumb
      setTree(folder, levels=[]) {

        if (levels.length > 0) {
          // TODO: Este bloque dentro del if optimizar hay redundancia de codigo
          if (levels.length === 0  )
            this.tree.push(this._GetDataFolder(folder, levels))
          else if ( levels.length === this.tree.length ) {
            if ( folder.id != this.tree[this.tree.length - 1].id ) {
              this.tree.pop()
              this.tree.push(this._GetDataFolder(folder, levels))
            }
          } else if( levels.length <  this.tree.length ) {
            // si es menor eliminar los hijos
            let position = levels.length - 1
            this.tree = this.tree.filter( (value, index) => { return index <= position })

            if ( folder.id != this.tree[this.tree.length - 1].id ) {
              this.tree.pop()
              this.tree.push(this._GetDataFolder(folder, levels))
            }
          } else {
            this.tree.push(this._GetDataFolder(folder, levels))
          }

        } else {
          let alreadyOpened = this.tree.some(function(value) { return value.id == folder.id })

          // ha pulsado en una carpeta superior ya abierta, borramos las carpetas por debajo
          if (alreadyOpened) {
            let position = this.tree.findIndex(value => { return value.id == folder.id})
            this.tree = this.tree.filter( (value, index) => { return index <= position })
          } else {
            // la carpeta es nueva en la ruta, insertarla
            this.tree.push(this._GetDataFolder(folder, levels))
          }
        }
      },

      _GetDataFolder(folder, levels=[]) {
        let obj = {
          id: folder.id,
          name: folder.name,
          is_virtual: folder.is_virtual || false
        }

        if (levels.length > 0) {
          obj.levels = levels;
        }
        return obj;
      },

      _OpenFolder(folder) {
        console.log('folder: ', folder)
        let obj = {
          id: folder.id,
          name: folder.name,
          is_virtual: folder.is_virtual || false
        }

        if (folder.levels) {
          EventBus.$emit('openFolder', {index: folder.levels, folder: obj});
        } else {
          EventBus.$emit('openFolder', {folder: obj});
        }
      },

      _HandleClickRoot() {
        if (this.emitClickRoot) {
          this.$emit('epc-click', true)
        }
      }
    }
  };
  </script>

<style scoped>
.menu-hover:hover {
  background-color: #e3e3e3;
}

.link_step {
  box-sizing: border-box;
  overflow: hidden;
  /* padding-right: 1.25rem; */
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 18.75rem;
}
</style>
