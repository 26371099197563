<template>
  <b-modal :active.sync="isModalActive" :can-cancel="canCancel">
    <div v-if="isLoading">
      <scale-loader></scale-loader>
    </div>

    <div v-if="!isLoading" class="modal-card panel p-4">
      <div class="modal-card-header flex pb-4" style="border-bottom: 1px solid #e7e7e7;">
        <h3>Pictogramas cargados en el proyecto</h3>
      </div>
      <div class="modal-card-body">
        <div class="grid grid-cols-3 gap-4">
          <div v-for="item in pictograms">
            <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow">
              <div class="p-5">
                <div class="mb-5">
                  <img v-if="item.screenshot" class="rounded-t-lg" :src="item.screenshot" :alt="item.name" />
                  <p v-else>Sin vista previa</p>
                </div>
                <h5 class="mb-2 text-xl font-bold tracking-tight text-blue-light">{{ item.name }}</h5>
                <p v-if="item.activity_associated" class="mb-3 font-normal text-gray-700" >Actividad asociada: {{ item.activity_planning_code + ' - ' + item.activity_associated }}</p>
                <p v-else class="mb-3 font-normal text-gray-700" >Ninguna actividad asociada</p>
                <div class="h-10 p-2 font-bold text-xs text-center text-white btn"
                  @click="_HandleClickSelect(item.id)"
                  :class="selectedPictograms.includes(item.id) ? 'btn-green' : 'btn-gray'">
                  {{ selectedPictograms.includes(item.id) ? 'Incluido en el diseño' : 'Seleccionar' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-card-footer flex flex-row items-center justify-center w-full pt-4" style="border-top: 1px solid #e7e7e7;">
        <button
            @click.prevent="hide"
            :disabled="isLoading"
            class="mr-1 btn btn-red"
            type="button"
          >
            {{ $t("cancel") }}
          </button>
          <button class="btn btn-blue" type="button" @click="_HandleLoadPictogramSelectedInDesing" :disabled="isLoading">{{ $t("save") }}</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import {mapGetters} from "vuex";
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

  export default {
    name: "viewer-insert-pictograms-modal",

    components: {
      ScaleLoader,
    },

    props: {
     canActionsPictogram: { type:Object, default() { return {} } }, // acciones que se puede realizar sobre el pictograma por defecto ninguna
    },

    data: () => ({
      isModalActive: false,
      isOpen: false,
      isLoading: true,
      assignmentId: 0,

      processingData: false,

      canCancel: ['escape', 'x', 'outside'],

      registerOverDrawingMode: false, // indica si la medición se realizará sobre un plano

      // pictogram: null,
      pictograms: [],
      selectedPictograms: [],
      workLayer: null // capa donde  trabajará el usuario, solo permite trabajar en esa capa si null trabaja en todas
    }),

    mounted() {

    },

    computed: {
      ...mapGetters({
        project: 'app/project',
        user: 'auth/user',
      }),
    },

    watch: {
      registerOverDrawingMode(newValue, oldValue) {
        this.$nextTick(() => {
          this.modalWidth = (newValue ? '90%' : '30%')
        });
      },

      selectedDocument(newValue, oldValue) {
        if( newValue != null )
          this.showDocument(newValue)
      }
    },

    methods: {

      // handleDrawingLine(annotations) {
      handleDrawingLine(measure) {
        // let measure = annotations[0].uka
        // if( ! measure )
        //   measure = annotations[0].Aka
        // this.$refs.assignment_contribute.setUnits( measure.split(' ')[0].replace('"', '').replace(',', '') )
        this.$refs.assignment_contribute.setUnits( measure )
      },
      handleProcessingData(event) {
        this.processingData = event
        if( ! event ) {
          this.canCancel = ['escape', 'x', 'outside']
          this.hide()
        }
        else
          this.canCancel = !event
      },

      handleProcessingDataFinished(event) {
        this.processingData = false
        this.canCancel = ['escape', 'x', 'outside']

        this.hide()
      },

      showDocument(document) {
        this.$refs.pdf_editor.show(document)
      },

      show(pictograms, selectedPictograms) {
        this.isLoading = true
        this.isModalActive = true;
        this.canCancel = ['escape', 'x', 'outside']
        this.processingData = false

        this.pictograms = pictograms
        this.selectedPictograms = selectedPictograms


        this.$nextTick(() => {
          setTimeout(() => {
            // Simulate asynchronous task completion
            this.isLoading = false
          }, 1000);
        });
      },

      hide() {
        this.assignmentId = 0;
        this.processingData = false
        this.isModalActive = false;
        this.registerOverDrawingMode = false
        this.selectedPictograms = []
      },

      handleSaveDocument(data){
        if( this.registerOverDrawingMode )
          this.$refs.pdf_editor.clickButtonSavePdfTron(data)
      },

      _HandleClickSelect(id) {
        if (this.selectedPictograms.includes(id))
          this.selectedPictograms = this.selectedPictograms.filter(item => item != id)
        else
          this.selectedPictograms.push(id)
      },

      _HandleLoadPictogramSelectedInDesing() {
        this.$emit('epc-update:selected-pictograms', this.selectedPictograms)
        this.hide()
      }
    }
  };
</script>

<style scoped>
::v-deep.max-width-custom .modal-content {
  max-width: 90% !important;
}
</style>
