<template>
  <div class="border border-blue-light rounded shadow bg-white">
    <header class="w-full p-4 text-xl font-thin uppercase text-blue-light">
      {{ $t( form.id != null  ? 'edit_status' : 'add_status') }}
    </header>
    <div class="w-full px-4 pb-0 pt-0">
      <form @submit.prevent="updateStatus" class="w-full">
        <div class="mb-1">

          <!-- <div class="w-full pb-3">
            <div class="w-full">
              <label form="code" class="">{{ $t('code')}}</label>
              <v-input
                v-model="form.code"
                title="code"
                type="text"
                name="code"
                placeholder="code"
              />
            </div>
          </div> -->

          <div class="w-full pb-3">
            <div class="w-full">
              <label form="name" class="">{{ $t('name')}}</label>
              <v-input
                v-model="form.name"
                title="name"
                type="text"
                required="true"
                name="name"
                placeholder="name"
              />
            </div>
          </div>

          <div class="w-full pb-3">
            <div class="w-full">
              <b-field>
                <b-switch v-model="form.is_completion" :value="form.is_completion" type="is-success">Está completado</b-switch>
              </b-field>
            </div>
          </div>

          <div class="w-full pb-3">
            <label form="color">{{ $t('select_a_color') }}</label>
            <b-colorpicker v-model="form.color"
              :alpha="false"
              :inline="colorpickerInline"
              size="is-medium"
              :color-formatter="formatter" />
          </div>

          <div class="w-full flex flex-row justify-between gap-x-2 pb-3">
            <button
              class="btn btn-blue"
              nativeType="submit"
            >
              {{ $t( form.id != null ? 'edit' : 'add' ) }}
            </button>
            <button type="button"
              class="btn btn-default"
              @click="() => { $emit('epc-pictogram-status-form-close') }">
              Cancelar
            </button>
          </div>

        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import Form from 'vform'
  import {mapGetters} from "vuex";

  export default {
    name: "status-pictogram-form",

    components: {
    },

    props: {
      status: { type: Object, default: null }, //
      colorpickerInline: { type: Boolean, default: false } // Indica si el selector de color se mostrara en linea o haciendo click en un input
    },

    data() {
      return {
        isModalActive: false,
        isOpen: false,
        isLoading: true,
        assignmentId: 0,

        processingData: false,

        form: new Form({
          id: this.status ? this.status.id : null,
          code: this.status ? this.status.code : null,
          name: this.status ? this.status.name : null,
          is_completion: this.status ? this.status.is_completion : false,
          color: this.status ? this.status.color : null,
        }),

        formatColor: 'hex',
      }
    },

    mounted() {

    },

    computed: {
      ...mapGetters({
        project: 'app/project',
        user: 'auth/user',
      }),
    },

    watch: {
    },

    methods: {
      formatter (color) {
        return color.toString(this.formatColor)
      },

      /**
       * Actualiza el estado llamando a la API
       * NOTA:No implementado
       */
      async updateStatusRemote() {

        this.form.entity_type = this.entityType
        const {data} = await this.form.post('/api/v2/pictogram/status/' + this.status.id);

        if (data.success) {

          this.$toast.open({
            message: this.$t('status_change_success'),
            type: 'is-success',
            position: 'is-top-right'
          });

          this.reset();
          this.$emit("reload");

        } else {

          this.isLoading = false;
          let msg = data.error_msg ? data.error_msg : this.$t('status_change_fail');

          // Error
          this.$toast.open({
            message: msg,
            type: 'is-danger',
            position: 'is-top-right'
          })
        }
      },

      /**
       * Actualiza el estado localmente
       */
       async updateStatus() {
        // this.$toast.open({
        //   message: msg,
        //   type: 'is-danger',
        //   position: 'is-top-right'
        // })

        this.$emit('epc-add-element', {
          id: this.form.id,
          code: this.form.code,
          name: this.form.name,
          color:  this.form.color == null || this.formatter(this.form.color) === '#000000' ? null : this.formatter(this.form.color),
          is_completion: this.form.is_completion,
        })

        this.reset()
      },

      reset() {

        this.form.id = null
        this.form.code = null
        this.form.name = null
        this.form.color = null
        this.form.is_completion = false

        this.processingData = false
      },
    }
  };
</script>

<style scoped>
</style>
