<template>
  <div>
    <div class="top-bar flex ">
      <div class="w-1/2 flex">
        <b-field>
          <b-switch :value="partner_see_their_orders"
            type="is-success"
            @input="_HandleInputSwitch">
              {{ $t('contractors_only_see_their_assignments') }}
          </b-switch>
        </b-field>
      </div>

      <div class="w-1/2 flex justify-end">
        <v-button class="mr-2"
          @click="_HandleWorkOrdersLink"
          icon="link">
          {{ $t('link_work_orders') }}
        </v-button>

        <v-button
          @click="_HandleWorkOrdersAdd"
          icon="plus">
          {{ $t('new_work_order') }}
        </v-button>
      </div>
    </div>
    <div>
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInUp"
        leave-active-class="animated fadeOutDown">
        <div class="panel">

          <work-orders-list ref="refWorkOrdersList"
            :project-id="projectId"
            @epc-work-orders-edit="_HandleWorkOrdersEdit"
          />

        </div>
      </transition>

      <work-orders-edit-modal
        ref="refWorkOrdersEditModal"
        :project-id="projectId"
        @reload="() => _HandleRefreshWorkOrders()"
      />

      <work-orders-link-modal
        ref="refWorkOrdersLinkModal"
        :project-id="projectId"
        @reload="() => _HandleRefreshWorkOrders()"
      />
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import WorkOrdersList from './work-orders-list.vue';
  import WorkOrdersForm from './work-orders-form.vue'
  import WorkOrdersEditModal from './work-orders-edit-modal.vue'
  import WorkOrdersLinkModal from './work-orders-link-modal.vue'

  export default {
    name: 'work-orders-manage',

    components: {
      WorkOrdersList,
      WorkOrdersForm,
      WorkOrdersEditModal,
      WorkOrdersLinkModal
    },

    data: () => ({
      partner_see_their_orders: false
    }),

    props: {
      projectId: {type: Number, required: true},
      entityType: {type: String, default: 'project'}
    },

    mounted() {
      this.getVisualConfig()
    },

    methods: {
      _HandleRefreshWorkOrders() {
        this.$refs.refWorkOrdersList.reload();
      },

      _HandleWorkOrdersEdit(order) {
        this.$refs.refWorkOrdersEditModal.show(order)
      },

      _HandleWorkOrdersAdd() {
        this.$refs.refWorkOrdersEditModal.show({
          id: null,
          code: '',
          description: ''
        })
      },

      _HandleWorkOrdersLink() {
        this.$refs.refWorkOrdersLinkModal.show({})
      },

      async getVisualConfig() {
        this.isLoading = true;
        let {data} = await axios.get("/api/v2/project/"+this.projectId+"/configs");

        if (data && data.success) {
          this.partner_see_their_orders = data.data.partner_see_their_orders
        }
        this.isLoading = false;
      },

      async _HandleInputSwitch(value) {
        let data = {
          partner_see_their_orders: value
        }

        // Enviamos el form
        await axios.post("/api/v2/project/"+this.projectId+"/configs", data);
      }
    }
  };
</script>
