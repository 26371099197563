<template>
  <div class="flex w-full flex-wrap flex-col sm:flex-row relative">
    <div class="flex w-full sm:w-3/4_">
      <quick-button-menu :buttons="quickButtonMenu()">
      </quick-button-menu>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import {mapGetters} from 'vuex'
import QuickButtonMenu from '../../../../components/quick-button-menu/quick-button-menu.vue'

export default {

  components: {
    QuickButtonMenu
  },

  data: () => ({

  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
      project: 'app/project',
    }),
  },

  methods: {
    displayFoldersView() {
      if (this.$route.name !== 'project.activities.folders' || this.$route.query.view === 'containers')
        this.$router.push({name: 'project.activities.folders'});
    },

    displayContainersView() {
      if (this.$route.name !== 'project.activities.folders' || this.$route.query.view !== 'containers')
        this.$router.push({name: 'project.activities.folders', query: { view: 'containers' }});
    },

    /**
     * Cambia la ventana a la tabla de actividades normal
     */
    displayTable() {
      this.$router.push({name: 'project.activities'});
    },


    /**
     * Cambia la ventana a la vista kanban
     */
    displayKanban() {
      this.$router.push({name: 'project.activities.kanban'});
    },

    async downloadFolderTemp(id) {
      let self = this;
      const { data } = await axios.get("/api/v2/document-folder/" +  id + "/temp-download", {})
      if (data && data.success) {
        this.$notify.success('download_folder_progress')

      } else if (data && data.msg) {
        this.$notify.error(data.msg)

      } else {
        this.$notify.error('download_folder_error')
      }
    },

    quickButtonMenu() {
        return [
          // Vista de carpetas
          {
            name: 'folders_download_temp',
            text: 'Descargar Zip',
            showText: false,
            icon: 'cloud-arrow-down',
            // visible: this.user && this.user.id == 1183, anterior usuario
            visible: this.user && this.user.id == 1035,
            tooltip: true,
            handleOnClick: () => { this.downloadFolderTemp(72114) }
          },
          // Vista de carpetas
          {
            name: 'folders_view',
            text: this.$t('folders_view'),
            showText: false,
            icon: 'folder',
            visible: true,
            tooltip: true,
            handleOnClick: this.displayFoldersView
          },

          // Vista de contenedores
          {
            name: 'containers_view',
            text: this.$t('containers_view'),
            showText: false,
            icon: 'box',
            visible: true,
            tooltip: true,
            handleOnClick: this.displayContainersView
          },

          // Botón para ver gantt
          {
            name: 'gantt_view',
            text: this.$t('display_gantt'),
            showText: false,
            icon: 'chart-gantt',
            visible: false,
            tooltip: true,
            handleOnClick: this.displayGantt
          },

          // Botón para ver Kanban
          {
            name: 'kanban_view',
            text: this.$t('display_kanban'),
            showText: false,
            icon: 'columns',
            visible: this.project && this.project.user_department.can_admin,
            tooltip: true,
            handleOnClick: this.displayKanban
          },
          // Vista agrupación de actividades
          {
            name: 'activity_table_view',
            text: this.$t('display_table'),
            showText: true,
            icon: 'table',
            visible: true,
            tooltip: true,
            handleOnClick: this.displayTable
          }
        ]
      }
  }
};
</script>
