<template>
  <section>
    <b-modal :active.sync="isModalActive" has-modal-card @after-enter="getActivitiesTree">
      <div class="w-1/2 modal-card">

        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('move_document') }}</p>
        </header>

        <section class="modal-card-body">

          <div class="flex flex-col">
            <tree-selector :tree="treeActivities" :preSelected="entityId" type='activities' :selectMsg="$t('select_activity')"/>
          </div>

          <div class="flex flex-col">
            <tree-selector :tree="tree" :rootNode="true" :selectMsg="$t('select_folder')"/>
          </div>

        </section>

        <div class="modal-card-foot">
          <button class="button is-success" :disabled="selectedFolder==null" v-on:click="moveDocument">{{ $t('move') }}</button>
        </div>

      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

    </b-modal>
  </section>
</template>

<script>

  import {mapGetters} from 'vuex'
  import axios from "axios";
  import EventBus from '~/plugins/bus'
  import TreeSelector from '·/components/tree-selector'
  
  export default {

    components: {
      TreeSelector
    },

    props: {
      tree: { type: Array, required: true },
      entityId: { type: Number, required: true }, // la actividad a la que pertenece el documento a mover
      entityType: { type: String, required: false }
    },

    computed: {
      ...mapGetters({
        project: 'app/project',
      }),
    },

    data() {
      return {
        treeActivities: [], // listado de actividades con subactividades para elegir como destino de la accion mover
        documentId: null, // el id de documento a mover
        selectedFolder: null,
        isModalActive: false,
        isLoading: false
      }
    },


    created(){

      let self = this;
      EventBus.$on('treeSelectorNodeSelected', function (node) {
        self.selectedFolder = node;
      });

    },


    beforeDestroy(){
      EventBus.$off("treeSelectorNodeSelected");
    },


    methods: {

      show(document) {
        this.documentId = document.document_id
        this.selectedFolder = null
        this.isModalActive = true;
      },


      async moveDocument() {
        let self = this;

        let params = {
          folder_id: this.selectedFolder,
          entity_id: this.entityId
        }

        await axios.post('/api/v2/document/' + this.documentId + '/move-folder', params)
          .then(function(response) {

            if (response.data.success) {

              EventBus.$emit("refreshFolderDocuments")
              self.$notify.success('document_moved')
              self.isModalActive = false;

            } else {

              self.$notify.error(response.data.msg)

            }
          });

      },


      async getActivitiesTree() {

        let args = {
          p: this.project.id,
          s: 1, // con subactividades
          pg: 1,
          light: 1,
        }

        this.isLoading = true

        const {data} = await axios.get('/api/v2/activities', {params: args})

        if (data && data.activities) {

          // actividades padre
          this.treeActivities = data.activities.map(function(activity) {
            return {
              id: activity.id,
              parent_id: activity.parent_id == activity.id ? null : activity.parent_id,
              name: activity.planning_code + ' - ' + activity.name,
            }
          })

          // subactividades, las concatenamos al final, que es como lo necesita el componente tree-selector
          let subActivities = data.activities
            .map(act => act['subactivities'])  // vamos extrayendo las subactivities
            .flat() // aplanamos el array de arrays como un solo array
            .map(sub => { // devolvemos solo los campos necesarios
              return {
                id: sub.id,
                parent_id: sub.parent_id,
                name: sub.planning_code + ' - ' + sub.name,
              }
            } )

          // array final, con actividades y subactividades concatenadas al final
          this.treeActivities = this.treeActivities.concat(subActivities)
        } else {
          this.$notify.error('error_loading_activities')
        }

        this.isLoading = false
      },


  }
}
</script>

<style scoped lang="scss">
  .modal-card {
    width: 100%;
  }

  .modal-card-body {
    min-height: 360px;
    min-width: 360px;
  }

  .modal-card-foot {
    justify-content: flex-end !important;
  }
</style>
