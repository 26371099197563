<template>
  <div v-if="showCalendar">
    <FullCalendar ref="fullCalendar" :options="calendarOptions" />
    <prompt-with-select ref="prompt_with_select"></prompt-with-select>
  </div>
  <div v-else-if="isLoading" class="text-center">
    <scale-loader></scale-loader>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from 'vuex'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import PromptWithSelect from './prompt-with-select.vue';


export default {
  name: 'assigned-staff-calendar',

  components: {
    FullCalendar,
    ScaleLoader,
    PromptWithSelect,
  },

  props: {
  },

  computed: {
    ...mapGetters({
      project: 'app/project',
    }),
  },

  data: () => ({
    showCalendar: false, // to show the calendar
    isLoading: false, 
    entityType: '',
    entityId: 0,
    partners: [], // list of partners linked with this project
    selectedDay: null, // guarda el día seleccionado en el calendario

    calendarOptions: {
      plugins: [
        dayGridPlugin,
        interactionPlugin, // needed for dateClick
      ],
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      weekends: true,
      select: null,
      eventClick: null,
      locale: 'es',
      firstDay: 1,
      events: [],
    }
  
  }),

  created() {
    this.getProjectPartners();

    // Ahora que el componente está creado, podemos asignar las funciones
    this.calendarOptions.select = this.handleDateSelect;
    this.calendarOptions.eventClick = this.handleEventClick;
  },


  methods: {
    async show(entityType, entityId) {
      this.entityType = entityType
      this.entityId = entityId

      this.reloadEvents()
    },

    async reloadEvents() {
      this.showCalendar = false
      this.isLoading = true
      let {data} = await axios.get('/api/v2/' + this.entityType + '/' + this.entityId + '/assigned-staff');

      if (data.success) {
        this.calendarOptions.events = data.data
        this.isLoading = false
        this.showCalendar = true
      } else {
        this.$notify.error("error_loading_data");
      }
    },

    // Cuando solo se pedía un valor (sin partner), valía con un simple prompt
    // handleDateSelectOLD (selectInfo) {
    //   let calendarApi = selectInfo.view.calendar;
    //   calendarApi.unselect();

    //   if (this.calendarOptions.events.some(event => event.date == selectInfo.startStr)) {
    //     return
    //   }

    //   this.$dialog.prompt({
    //     title: "Introduce un valor",
    //     onConfirm: async (prompt) => {
    //       if (prompt) {
    //         let body = {
    //           staff_num: prompt,
    //           date_day: selectInfo.startStr
    //         }
    //         let {data} = await axios.post('/api/v2/' + this.entityType + '/' + this.entityId + '/assigned-staff', body);

    //         if (data.success) {
    //           this.calendarOptions.events.push({
    //             id: data.data.id,
    //             title: prompt,
    //             date: selectInfo.startStr
    //           })
    //         } 
    //       }
    //     }
    //   })
    // },

    handleDateSelect(selectInfo) {
      let calendarApi = selectInfo.view.calendar;
      calendarApi.unselect();

      this.selectedDay = selectInfo

      // Anulado, ahora sí permitimos varios eventos en el mismo día
      // if (this.calendarOptions.events.some(event => event.date == selectInfo.startStr)) {
      //   return
      // }

      this.$refs.prompt_with_select.show(this.partners)

    },

    handleEventClick(clickInfo) {
      this.$dialog.confirm({
        title: this.$t('delete'),
        message: this.$t('delete_generic', ['el registro '+clickInfo.event.title ]),
        confirmText: this.$t('delete'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          let {data} = await axios.delete('/api/v2/' + this.entityType + '/' + this.entityId + '/assigned-staff/'+clickInfo.event.id);

          if (data.success) {
            this.calendarOptions.events = this.calendarOptions.events.filter(event => event.id != clickInfo.event.id)
          }
        }
      })
    },

    // callback llamado desde el componente PromptWithSelect al introducir un valor
    async handleSavePrompt(promptValues) {

      let body = {
        staff_num: promptValues.inputValue,
        partner_id: promptValues.selectedOption,
        date_day: this.selectedDay.startStr
      }

      let {data} = await axios.post('/api/v2/' + this.entityType + '/' + this.entityId + '/assigned-staff', body);

      if (data.success) {
        this.calendarOptions.events.push({
          id: data.data.id,
          title: promptValues.inputValue,
          date: this.selectedDay.startStr
        })
      } else {
        this.$notify.error(data.msg || "error_saving_data");
      }
    },

    getProjectPartners() {
      axios.get('/api/v2/project/'+this.project.id+'/partners')
        .then(response => {
          this.partners = response.data.data.map(partner => {
            return {
              id: partner.id,
              name: partner.name,
            }
          })
        })
        .catch(error => {
          console.error('Could not retrieve partners:', error)
        })
    }

  },

};
</script>

<style >
/* some overridings to library's style */
/* show events (staff_num) big and centered */
.fc-event-title-container {
  text-align: center;
  /* font-size: 1.5em; */
  font-weight: bold;
}

.fc-toolbar-title {
  font-size: 1.3em !important;
  font-weight: bold;
  color: #57b;
  text-transform: uppercase;
}
</style>
