<template>
  <div class="epc" :style="{borderColor: (expanded ? model.color : '#ADC2EA') + ' !important'}"
    :class="{ 'expanded': expanded }">
    <div
      v-if="model.name !== 'root'"
      :id="model.id"
      class="epc-node w-full flex items-center"
      :class="{ 'epc-leaf-node': model.isLeaf, 'epc-tree-node': !model.isLeaf }"
      :style="{backgroundColor: expanded ? model.color : 'initial'}"
    >
      <div
        :class="treeNodeClass"
        :draggable="false"
        @dragstart="dragStart"
        @dragover="dragOver"
        @dragenter="dragEnter"
        @dragleave="dragLeave"
        @drop="drop"
        @dragend="dragEnd"
        @mouseover="mouseOver"
        @mouseout="mouseOut"
        @click.stop="click"
      >
        <!-- <span class="epc-caret epc-is-small pl-2 pr-2" v-if="model.children && model.children.length > 0">
          <i class="fas" :class="caretClass" @click.prevent.stop="toggle"></i>
        </span> -->


        <div class="epc-node-content w-full flex flex-col" @click.prevent.stop="toggle">
          <div class="flex flex-row">
            <span v-if="model.isLeaf">
              <slot name="leafNodeIcon" :expanded="expanded" :model="model" :root="rootNode">
                <i class="epc-icon epc-menu-icon epc-icon-file"></i>
              </slot>
            </span>
            <span v-else>
              <slot name="treeNodeIcon" :expanded="expanded" :model="model" :root="rootNode">
                <i class="epc-icon epc-menu-icon epc-icon-folder"></i>
              </slot>
            </span>
            <slot name="leafNameDisplay" :expanded="expanded" :model="model" :root="rootNode">
              {{ model.name }}
            </slot>
          </div>
          <div v-if="expanded" class="mt-5">
            <activities :filtersProps="rootNode.filters" v-if="model.has_activities" />
          </div>
        </div>
      </div>

      <div
        v-if="model.children && model.children.length > 0 && expanded"
        class="epc-border epc-bottom"
        :class="{ 'epc-active': isDragEnterBottom }"
        @drop="dropAfter"
        @dragenter="dragEnterBottom"
        @dragover="dragOverBottom"
        @dragleave="dragLeaveBottom"
      ></div>
    </div>

    <div
      :class="{ 'epc-tree-margin_': model.name !== 'root' }"
      v-show="model.name === 'root' || expanded"
      v-if="isFolder"
    >
      <item
        v-for="model in model.children"
        :default-tree-node-name="defaultTreeNodeName"
        :default-leaf-node-name="defaultLeafNodeName"
        :default-expanded="defaultExpanded"
        :model="model"
        :key="model.id"
      >
        <template v-slot:leafNameDisplay="slotProps">
          <slot name="leafNameDisplay" v-bind="slotProps" />
        </template>
        <template v-slot:addTreeNodeIcon="slotProps">
          <slot name="addTreeNodeIcon" v-bind="slotProps"/>
        </template>
        <template v-slot:addLeafNodeIcon="slotProps">
          <slot name="addLeafNodeIcon" v-bind="slotProps"/>
        </template>
        <template v-slot:editNodeIcon="slotProps">
          <slot name="editNodeIcon" v-bind="slotProps" />
        </template>
        <template v-slot:linkNodeIcon="slotProps">
          <slot name="linkNodeIcon" v-bind="slotProps" />
        </template>
        <template v-slot:delNodeIcon="slotProps">
          <slot name="delNodeIcon" v-bind="slotProps" />
        </template>
        <template v-slot:leafNodeIcon="slotProps">
          <slot name="leafNodeIcon" v-bind="slotProps" />
        </template>
        <template v-slot:treeNodeIcon="slotProps">
          <slot name="treeNodeIcon" v-bind="slotProps" />
        </template>
      </item>
    </div>
  </div>
</template>

<script>
import { TreeNode } from './Tree.js'
import { removeHandler } from './tools.js'
import Activities from '../../../../pages/activity/groups/activities.vue';

let compInOperation = null

export default {
  name: 'vue-tree-list-custom',
  components: {
    Activities
  },

  data: function() {
    return {
      isHover: false,
      isDragEnterUp: false,
      isDragEnterBottom: false,
      isDragEnterNode: false,
      expanded: this.defaultExpanded
    }
  },
  props: {
    model: {
      type: Object
    },
    defaultLeafNodeName: {
      type: String,
      default: 'Leaf Node'
    },
    defaultTreeNodeName: {
      type: String,
      default: 'Tree Node'
    },
    defaultAddTreeNodeTitle: {
      type: String,
      default: 'Add Tree Node'
    },
    defaultAddLeafNodeTitle: {
      type: String,
      default: 'Add Leaf Node'
    },
    defaultExpanded: {
      type: Boolean,
      default: true
    },
    disableActionMenu: {
      type: Boolean,
      default: false
    },
    depthMax: {
      type: Number,
      default: 0
    },

    filters: {
      type: Object, require: true
    }
  },

  computed: {
    rootNode() {
      var node = this.$parent
      while (node._props.model.name !== 'root') {
        node = node.$parent
      }
      return node
    },

    caretClass() {
      // return this.expanded ? 'epc-icon-caret-down' : 'epc-icon-caret-right'
      return this.expanded ? 'fa-caret-down' : 'fa-caret-right'
    },

    isFolder() {
      return this.model.children && this.model.children.length
    },

    treeNodeClass() {
      const {
        model: { dragDisabled, disabled },
        isDragEnterNode
      } = this

      return {
        'epc-node-main': true,
        'epc-active': isDragEnterNode,
        'epc-drag-disabled': dragDisabled,
        'epc-disabled': disabled
      }
    }
  },

  beforeCreate() {
    this.$options.components.item = require('./VueTreeListCustom').default
  },
  beforeDestroy() {
    removeHandler(window, 'keyup')
  },
  methods: {
    updateName(e) {
      var oldName = this.model.name
      this.model.changeName(e.target.value)
      // eslint-disable-next-line no-console
      console.log('update Name')
      this.rootNode.$emit('change-name', {
        id: this.model.id,
        oldName: oldName,
        newName: e.target.value,
        node: this.model
      })
    },

    delNode() {
      this.rootNode.$emit('delete-node', this.model)
    },

    toggle() {
      // if (this.isFolder) {
        this.expanded = !this.expanded
        this.rootNode.filters.group_id = this.model.id
      // }
    },

    mouseOver() {
      if (this.model.disabled || this.rootNode.disableActionMenu) return
      this.isHover = true
    },

    mouseOut() {
      this.isHover = false
    },

    click() {
      this.rootNode.$emit('click', {
        toggle: this.toggle,
        ...this.model
      })
    },

    addChild(isLeaf) {
      // const name = isLeaf ? this.defaultLeafNodeName : this.defaultTreeNodeName
      // this.expanded = true
      // var node = new TreeNode({ name, isLeaf })
      // this.model.addChildren(node, true)
      // this.rootNode.$emit('add-node', node)
      // enviamos el model de momento
      this.rootNode.$emit('add-node', this.model)
    },

    linkNode() {
      this.rootNode.$emit('link-node', this.model)
    },

    dragStart(e) {
      if (!(this.model.dragDisabled || this.model.disabled)) {
        compInOperation = this
        // for firefox
        e.dataTransfer.setData('data', 'data')
        e.dataTransfer.effectAllowed = 'move'
        return true
      }
      return false
    },
    dragEnd() {
      compInOperation = null
    },
    dragOver(e) {
      e.preventDefault()
      return true
    },
    dragEnter() {
      if (!compInOperation) return
      if (compInOperation.model.id === this.model.id || !compInOperation || this.model.isLeaf)
        return
      this.isDragEnterNode = true
    },
    dragLeave() {
      this.isDragEnterNode = false
    },
    drop() {
      if (!compInOperation) return
      const oldParent = compInOperation.model.parent
      compInOperation.model.moveInto(this.model)
      this.isDragEnterNode = false
      this.rootNode.$emit('drop', {
        target: this.model,
        node: compInOperation.model,
        src: oldParent
      })
    },

    dragEnterUp() {
      if (!compInOperation) return
      this.isDragEnterUp = true
    },
    dragOverUp(e) {
      e.preventDefault()
      return true
    },
    dragLeaveUp() {
      if (!compInOperation) return
      this.isDragEnterUp = false
    },
    dropBefore() {
      if (!compInOperation) return
      const oldParent = compInOperation.model.parent
      compInOperation.model.insertBefore(this.model)
      this.isDragEnterUp = false
      this.rootNode.$emit('drop-before', {
        target: this.model,
        node: compInOperation.model,
        src: oldParent
      })
    },

    dragEnterBottom() {
      if (!compInOperation) return
      this.isDragEnterBottom = true
    },
    dragOverBottom(e) {
      e.preventDefault()
      return true
    },
    dragLeaveBottom() {
      if (!compInOperation) return
      this.isDragEnterBottom = false
    },
    dropAfter() {
      if (!compInOperation) return
      const oldParent = compInOperation.model.parent
      compInOperation.model.insertAfter(this.model)
      this.isDragEnterBottom = false
      this.rootNode.$emit('drop-after', {
        target: this.model,
        node: compInOperation.model,
        src: oldParent
      })
    },
  }
}
</script>

<style scoped>
font-face {
	 font-family: 'icomoon';
	 src: url('./fonts/icomoon.eot?ui1hbx');
	 src: url('./fonts/icomoon.eot?ui1hbx#iefix') format('embedded-opentype'), url('./fonts/icomoon.ttf?ui1hbx') format('truetype'), url('./fonts/icomoon.woff?ui1hbx') format('woff'), url('./fonts/icomoon.svg?ui1hbx#icomoon') format('svg');
	 font-weight: normal;
	 font-style: normal;
}

.epc {
  border: 1px solid #ADC2EA;
}

.expanded {
  border-width: 4px;
}

.epc-icon {
/* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
/* speak: none;
  */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
/* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.epc-icon.epc-menu-icon {
	 margin-right: 4px;
}
 .epc-icon.epc-menu-icon:hover {
	 color: inherit;
}
 .epc-icon:hover {
	 color: blue;
}
 .epc-icon-file:before {
	 content: '\e906';
}
 .epc-icon-folder:before {
	 content: '\e907';
}
 .epc-icon-caret-down:before {
	 content: '\e901';
}
 .epc-icon-caret-right:before {
	 content: '\e900';
}
 .epc-icon-edit:before {
	 content: '\e902';
}
 .epc-icon-folder-plus-e:before {
	 content: '\e903';
}
 .epc-icon-plus:before {
	 content: '\e904';
}
 .epc-icon-trash:before {
	 content: '\e905';
}
 .epc-border {
	 height: 5px;
}
 .epc-border.epc-up {
	 margin-top: -5px;
	 background-color: transparent;
}
 .epc-border.epc-bottom {
	 background-color: transparent;
}
 .epc-border.epc-active {
	 border-bottom: 3px dashed blue;
	/*background-color: blue;
	*/
}
 .epc-node-main {
	 display: flex;
	 align-items: center;
	 padding: 5px;
   width: 100%;
}
 .epc-node-main .epc-input {
	 border: none;
	 max-width: 150px;
	 border-bottom: 1px solid blue;
}
 .epc-node-main:hover {
	 background-color: #f0f0f0;
}
 .epc-node-main.epc-active {
	 outline: 2px dashed pink;
}
 .epc-node-main .epc-caret {
	 margin-left: -1rem;
}
 .epc-node-main .epc-operation {
	 margin-left: 2rem;
	 letter-spacing: 1px;
}
 .epc-item {
	 cursor: pointer;
}
 .epc-tree-margin {
	 margin-left: 0em;
}

</style>
