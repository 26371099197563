<template>
  <div class="dropzone w-full">
    <b-upload v-model="inputFile"
      :multiple="false"
      drag-drop
      @input="_OnFileSelected"
      expanded>
      <div class="flex flex-col items-center justify-center pt-5 pb-6">
        <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
        </svg>
        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click para seleccionar</span> o arrastrar y soltar </p>
        <p>el archivo que contiene el diseño de las estructuras de la planta fotovoltaica en formato <span class="font-bold">dxf</span></p>
        <p class="text-xs text-gray-500 dark:text-gray-400">DXF</p>
      </div>
    </b-upload>
  </div>
</template>

<script>
import {DxfWorker} from "../viewer/src/DxfWorker"

export default {
  components: {
  },

  props: {
  },

  data () {
    return {
      inputFile: null,
      accept: "",
      urlFile: null,
      isLocalFile: false,
    }
  },

  methods: {

    _OnFileSelected(file) {
      if (!file) {
        this._OnFileCleared()
        return
      }

      if (this.urlFile && this.isLocalFile) {
        URL.revokeObjectURL(this.urlFile)
      }

      this.isLocalFile = true
      this.inputFile = file
      this.urlFile = URL.createObjectURL(file)

      this._LoadParserDxf()

    },

    _OnFileCleared() {
      if (this.inputFile) {
          this.inputFile = null
          URL.revokeObjectURL(this.urlFile)
          this.urlFile = null
          this.$notify.info("Archivo limpiado")
      }
    },

    async _LoadParserDxf() {
      let worker = new DxfWorker(null)
      const { dxf } = await worker._ConvertDxfToJson(this.urlFile, 'utf-8', null) // en null asignar el progress bar si es necesario
      await worker.Destroy()
      worker = null
      this.$emit('epc-pictogram-design', { inputFile: this.inputFile, parserDxf: dxf });
    },
  }
}
</script>
