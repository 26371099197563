<template>
  <section :class="{'inline-block': !openWithButton}">
    <button-menu v-if="!openWithButton"
      :text-label="$t('generate_report')"
      :icon="'file-excel'"
      :active-tooltip="true"
      :positionTooltip="'is-left'"
      :redirect="null"
      :handle-on-click="() => { isModalActive = true }"
    />
    <div v-if="openWithButton" class="mr-2 rounded-sm" @click="isModalActive = true">
      <v-button icon="file-excel" class="mr-4"></v-button>
    </div>
    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="modal-card">

        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('generate_report') }}</p>
        </header>

        <section class="modal-card-body">

          <!-- Cuerpo del Modal-->
          <div class="p-8" style="display: grid; align-items: center; justify-content: center; font-size: larger;">
            <strong>Se generará un informe excel agrupado por grupo de actividades.</strong>
            Pulse "Generar informe" para descargar el fichero.
          </div>

        </section>

        <footer class="modal-card-foot">
          <button class="button is-success" :disabled="isLoading" v-on:click="generateReport">{{ btnGenerateReport }}
          </button>
          <button class="button" size="is-small" type="button" @click="isModalActive = false">{{ $t('close') }}</button>
        </footer>

      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

    </b-modal>
  </section>
</template>

<script>

  import {mapGetters} from 'vuex'
  import axios from "axios";
  import ButtonMenu from '../quick-button-menu/components/button-menu.vue'

  export default {
    components: {
      ButtonMenu
    },

    props: {
      openWithButton: {  type: Boolean, default: true }
    },

    data() {
      return {
        btnGenerateReport: this.$t('generate_report'),
        isModalActive: false,
        isLoading: false,
      }
    },

    computed: {
      ...mapGetters({
        project: 'app/project',
        company: 'app/company',
      }),
    },


    methods: {

      async generateReport() {

        let params = {
          project_id: this.project.id,
        };

        // Generando reporte, cerramos el modal
        this.$notify.success('generating_report');
        this.isModalActive = false;

        //Encargamos el reporte al servidor
        const {data} = await axios.get('/api/v2/report/excel-by-activity-groups', {params: params});

        //si nos llega un reporte hay que descargarlo
        if (data) {

          // Descargamos
          window.open(data.data);

          // Loading
          this.isLoading = false;
          this.btnGenerateReport = this.$t('generate_report');
          this.isModalActive = false;

          // Reporte generado
          this.$notify.success('report_generated')

        } else {

          // Error
          this.$notify.error('error_loading_report')

          // Loading
          this.isLoading = false;
          this.btnGenerateReport = 'Generar Informe';

        }
      },

    }
  }
</script>

<style scoped lang="scss">
  .modal-card {
    width: 100%;
  }

  .modal-card-body {
    /*min-height: 480px;*/
    /*min-width: 560px;*/
  }
</style>
