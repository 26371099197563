<template>
  <span>
  <table id="testTree" class="w-full border-blue-lighter">
    <thead :class="{'bg-blue-lightest': listType=='chapter', 'bg-yellow-lightest': listType=='certifiers'}">
      <tr>
        <!-- <th class="p-2 text-sm font-semibold text-center uppercase text-blue-dark" width="36px"></th> -->
        <th class="p-2 text-sm font-semibold text-center uppercase text-blue-dark" width="80px"></th>
        <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ columnTitle }}</th>
        <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("progress") }}</th>
        <th class="p-2 text-sm font-semibold uppercase text-blue-dark"></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="row in listLayers">
        <!-- Recorremos las certificadoras -->
        <tr class="hover:bg-grey-lightest" :class="{'tr-selected': (row.id == selectedChapter) }" :key="'pic_' + row.id">
          <!-- Icono Detalles / Historia -->
          <!-- <td class="p-2 align-middle">
            <div class="cursor-pointer" v-show="row.children.length" @click="displayChapters(row.id)">
              <b-tooltip
                :label="$t('subchapters')"
                position="is-right"
              >
                <b-icon :icon="(row.id == selectedChapter) ? 'chevron-down' : 'chevron-right'" pack="fas" size="is-small" class="text-blue mr-1"></b-icon>
              </b-tooltip>
            </div>
          </td> -->
          <td class="justify-start w-auto h-full p-2 align-middle">

            <div class="flex text-center align-middle cursor-pointer" @click="displayHistory(row.id)">
              <b-tooltip
                :label="$t('display_history',[(row.lines ? row.lines.length : 0)])"
                position="is-right"
              >
                <span class="mr-1 text-xs font-semibold text-blue">{{ row.lines ? row.lines.length : 0 }}</span>
                <b-icon icon="history" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                <b-icon :icon=" (row.id == selectedHistory) ? 'chevron-up' : 'chevron-down'" pack="fas" size="is-small" class="text-blue"></b-icon>
              </b-tooltip>
            </div>
          </td>

          <!-- Chapter -->
          <td v-if="row.completed" class="p-2 align-middle w-auto h-full justify-start">
            <div class="text-xs font-semibold">{{ row.name }}</div>
          </td>
          <td  v-else class="p-2">
            <div class="flex flex-row items-center">
              <div class="w-2 h-2 mr-1 rounded-full bg-green"></div>
              <div class="text-xs font-semibold">{{ row.name }}</div>
            </div>
            <div class="text-xs text-blue"><span class="font-semibold">{{ $t('resource_weight_msg', [row.weight.toFixed(2)]) }}</span></div>
          </td>
          <!-- Total unit -->
          <!-- <td class="p-2">
            <div class="flex flex-col">
              <div class="text-xs">{{ row.total_units }} / {{ row.unit.code }}</div>
            </div>
          </td> -->
          <!-- Pendiente / Ejecutado -->
          <td class="p-2">
            <div class="flex flex-col">
              <div class="text-xs">{{ row.total_execute }} {{ _GetUnit(row) }} {{ $t("total").toLowerCase() }} {{ _GetEvacuationLineData() }}</div>
              <div class="text-xs text-grey-dark">
                {{ row.total_execute - row.progress }} {{ _GetUnit(row) }} {{ $t('pendings') }} {{ _GetEvacuationLineEvance(row, 'pendings') }}
              </div>
              <div class="text-xs font-medium text-blue">
                {{ row.progress }} {{ _GetUnit(row) }} {{ 'total '+ $t('executed').toLowerCase() }} {{ _GetEvacuationLineEvance(row, 'executed') }}
                <b-tooltip position="is-top" multilined>
                  <b-icon icon="traffic-light" pack="fas" size="small"></b-icon>
                  <template v-slot:content>
                    <div class="text-left">
                      <div>Total ejecutado por estado</div>
                      <div v-if="row.statuses.length"
                        v-for="status in row.statuses" :key="'picstatus_' + status.id"
                        class="mb-1">
                        <span class="w-3 h-3 rounded-full inline-block" :style="{ backgroundColor: status.color }"></span>
                        <span class="border-b border-dotted">{{ status.name }}</span>
                        <span>{{ _GetProgressStatus(row, status) }}</span>
                      </div>
                      <div v-else><span>No tiene definidos estados</span></div>
                    </div>
                  </template>
                </b-tooltip>
              </div>
            </div>
          </td>

          <!-- Opciones -->
          <td class="justify-center w-auto h-full p-4 align-middle">
            <div class="text-center cursor-pointer">
              <b-dropdown aria-role="list" position="is-bottom-left">

                <template #trigger="{ active }">
                    <b-icon :icon="active ? 'ellipsis-h' : 'ellipsis-v'" pack="fas" size="is-small" class="text-blue"></b-icon>
                </template>
                <!-- <b-dropdown-item aria-role="listitem" @click="onNameClick(row)" v-if="row.children.length == 0 && !row.completed" >
                  <span class="text-xs">{{ $t('register_progress') }}</span>
                </b-dropdown-item> -->
                <b-dropdown-item v-if="row.children.length == 0 && !row.completed"
                  aria-role="listitem"
                  @click="onClickOpenModal(row)" >
                  <span class="text-xs">{{ $t('register_progress') }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  @click="onClickShowCalendarStaff(row)" >
                  <span class="text-xs">{{ $t('assigned_staff') }}</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item v-if="row.lines == null" aria-role="listitem" @click="addSubResource(row)" :disabled="!canEditAssignments()">
                  <span class="text-xs">{{ $t('add_subresource') }}</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="!row.completed" aria-role="listitem" @click="onClickEdit(row)" :disabled="!canEditAssignments()">
                  <span class="text-xs">{{ $t('edit') }}</span>
                </b-dropdown-item> -->
                <b-dropdown-item separator v-if="!row.completed && !row.children.length > 0"></b-dropdown-item>
                <!-- <b-dropdown-item aria-role="listitem" @click="onCompleteChanged(row)" v-if="row.children.length == 0">
                  <span v-if="!row.completed" class="text-xs font-medium text-green">{{ $t('complete_resource') }}</span>
                  <span v-else class="text-xs font-medium text-red">{{ $t('uncomplete') }}</span>
                </b-dropdown-item> -->
              </b-dropdown>
            </div>
          </td>
        </tr>

        <details-row v-if="row.children.length"
          :key="'sub_' + row.id"
          :node="row"
          :class="{'hidden': !(row.id == selectedChapter)}"
        />

        <tr  :key="'pictogram_history_'+row.id">
          <td colspan="5" :class="{'hidden': !(row.id == selectedHistory)}" >
              <table class="w-full">

                  <tr class="bg-indigo-lightest">

                      <td colspan="4" class="p-4">
                              <div class="flex justify-start w-auto h-full align-middle">
                              <b-icon icon="history" pack="fas" size="is-small" class="ml-1 text-blue"></b-icon>
                              <span class="ml-2 text-xs font-semibold text-blue">{{ $t('resource_history_header') }}</span>
                              </div>
                          </td>
                          </tr>

                          <tr class="bg-blue-lightest">
                              <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('date') }}</th>
                              <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('user') }}</th>
                              <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('action') }}</th>
                              <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('observations') }}</th>
                          </tr>

                  <tbody>
                      <!-- <template v-if="false" v-for="rowHistory in row.lines"> -->
                      <template  v-for="rowHistory in row.lines">
                        <tr :key="rowHistory.id" class="hover:bg-grey-lightest">

                            <td align="center" class="justify-start w-auto h-full p-4">
                                <div class="text-xs text-center">
                                <!--
                                <l-timeago
                                    :since="rowHistory.created_at"
                                    :locale="locale"
                                    class="my-1 text-xs font-semibold text-blue-light"
                                />
                                -->
                              <div class="my-1 text-xs font-semibold text-blue-light">
                                {{dateToText(rowHistory.created_at)}}
                              </div>

                                </div>
                            </td>

                            <td class="p-2">

                              <div class="flex flex-row items-center justify-start w-auto h-full">
                              <div class="w-8 h-8 rounded-full">
                                  <img class="w-8 h-8 rounded-full" :src="rowHistory.user.avatar"/>
                              </div>
                              <div class="flex flex-col ml-2">
                                  <div class="text-xs">{{ rowHistory.user.fullname}}</div>
                                  <div class="text-xs text-grey-dark">{{ rowHistory.user.area}}</div>
                              </div>
                              </div>

                            </td>

                            <td align="center" class="h-full p-4">
                              <div class="text-xs">{{ $t('execute') }} {{ rowHistory.executed_quantity}} </div>
                              <div class="text-xs">
                                <span class="w-3 h-3 rounded-full inline-block" :style="{ backgroundColor: _GetStatusProperty(rowHistory, 'color') }"></span>
                                <span>{{ _GetStatusProperty(rowHistory, 'name') }}</span>
                              </div>
                            </td>

                            <td class="w-2/5 p-2">
                                <div class="flex flex-col">
                                <div class="text-xs" v-html="rowHistory.observation"></div>
                                <div class="flex flex-row">

                                  <template v-for="rowDocument in rowHistory.documents">
                                    <b-tooltip :label="rowDocument.name" position="is-top" :key="rowDocument.document_id">
                                      <div @click="showPreview(rowDocument)" class="w-12 h-12 m-1 rounded-lg shadow cursor-pointer bg-grey">
                                        <img class="w-12 h-12 rounded-lg" :src="rowDocument.file.thumbnail" />
                                      </div>
                                    </b-tooltip>
                                  </template>
                                </div>
                                </div>
                            </td>

                        </tr>
                      </template>
                  </tbody>
              </table>
          </td>
        </tr>
      </template>
    </tbody>
  </table>

  <div class="mt-5">
    <assigned-staff-calendar ref="assigned_staff_calendar" />
  </div>
  </span>
</template>

<script>

import { mapGetters } from 'vuex'
import DetailsRow from './details-row'
import { format } from 'date-fns'
import AssignedStaffCalendar from '·/components/assigned-staff-calendar.vue'
import { roundedShapeArc } from 'pixi.js'

export default {

  components: {
    DetailsRow,
    AssignedStaffCalendar,
  },
  props: {
    listLayers: { type: Array, require: true },
    statuses: { type: Array, require: true },
    pictogram: { type: Object, require: true },
    columnTitle: { type: String, default: 'Capa' },
    listType: { type: String, default: 'pictogram' },
    activityId: { type: Number, default: null },
    subactivityId: { type: Number, default: null }
  },

  data () {
    return {
      selected: undefined,
      selectedHistory: undefined,
      selectedSubHistory: undefined,

      selectedChapter: undefined,
    }
  },

  computed: {
    ...mapGetters({
      locale: "lang/locale",
      project: 'app/project',
    }),
  },

  mounted() {
    // console.log('listLayers: ', this.listLayers)
  },

  methods: {
    displayHistory(rowId){
      this.selectedChapter = undefined
      this.selectedHistory = (this.selectedHistory == rowId) ? undefined : rowId;
    },

    displayChapters(rowId){
      this.selectedHistory = undefined
      this.selectedChapter = (this.selectedChapter == rowId) ? undefined : rowId;
    },

    dateToText(date) {
      let dateObject = new Date(date);
      return format(dateObject, 'dd/MM/yyyy HH:mm')
    },

    /**
     * Comprueba si puede añadir o editar mediciones
     */
     canEditAssignments() {
      return true
      // return !this.assignmentsLocked || (this.assignmentsLocked && this.canManageCertifiers)
    },

    calculateCertifierValues(item, type='pending'){
      // Para pictogramas de momento no se saca de lines
      if (item.progress) {
        if (type == 'pending')
          return item.progress["-1"]?? 0
        else {
          // sino sumamos avance por estado
          let totalPartial = 0
          item.statuses.forEach(status => {
            if (item.progress[status.id])
              totalPartial += item.progress[status.id]
          });
          return totalPartial
        }

      }

      return 0

      // if (item.lines) {
      //   if (type == 'pending')

      //     return ((item.total_quantity - item.executed_quantity) == 0)
      //     ? (item.total_quantity - item.executed_quantity).toFixed()
      //     : (item.total_quantity - item.executed_quantity).toFixed(2)

      //   return (item.executed_quantity == null || item.executed_quantity == 0) ? 0 : (item.executed_quantity * 1).toFixed(2)

      // }

      // return type == 'pending' ? item.total_units : 0;

    },

    onClickOpenModal(row) {
      // this.$emit('epc-open-modal', row)

      this.$router.push({ name: 'progress.pictograms', params: {
        pictogramId: row.pictogram_id,
        wl: row.code,
        activity: this.activityId,
        subactivity: this.subactivityId
       } })
    },

    onClickShowCalendarStaff(row) {
      this.$refs.assigned_staff_calendar.show('pictogram-layer', row.id)
    },

    onCompleteChanged(row) {
      // this.$refs.assignment_completed_modal.show(assignment.id, assignment.completed);
    },

    _GetUnit(row) {
      if (!this.with_unit) return ''

      return row.unit_name || this.project.currency
    },

    _GetProgressStatus(item, status) {
      if( item.statuses_progress && item.statuses_progress[status.id])
        return item.statuses_progress[status.id]

      return '0'
    },

    _GetStatusProperty(row, property) {
      let layer = this.listLayers.find(l => { return l.id == row.layer_id });
      if (layer) {
        let status = layer.statuses.find(e => { return e.id == row.status_id })
        if (status)
          return status[property]
      }
      return null
    },

    _GetStatusColor(row) {
      this.
      console.log('statusjdflasdj: ', row)
      return 'aqui va algo'
    },

    _GetEvacuationLineData() {
      return this.pictogram.metadata.evacuation_line_data
        ? '(' + this.pictogram.metadata.evacuation_line_data.line_length + ' ' + this.pictogram.metadata.evacuation_line_data.unit + ')'
        : ''
    },

    _GetEvacuationLineEvance(row, type) {

      if (!this.pictogram.metadata.evacuation_line_data)
        return ''

      let minUnit = this.pictogram.metadata.evacuation_line_data.line_length / this.pictogram.metadata.total_sections
      if ('pendings'==type) {
        return '(' + ( minUnit * (row.total_execute - row.progress) ) + ' ' + this.pictogram.metadata.evacuation_line_data.unit + ')'
      } else { // executed
        return '(' + ( minUnit * row.progress ) + ' ' + this.pictogram.metadata.evacuation_line_data.unit + ')'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
table, tr {
  border: 1px solid #ADC2EA;
}

span.fa {
  transition: all 400ms;
}
span.fa:hover {
  cursor: pointer !important;
  color: #333;
}
.tr-selected > td:not(:first-child) {
  background-color: rgba(173, 194, 234, 0.174);
  // color: #fff;
}

</style>
