<template>
<div>
  <div class="panel">
      <div class="w-full flex flex-wrap pt-4 pb-8 mb-8">
        <userlist ref="managers"
          title="report_managers"
          icon="cog"
          :parent-entity-id="selectedAreaNodeData ? selectedAreaNodeData.id : null"
          api-url="/api/v2/area"
          users-permission-type="admin"
          :disabled="selectedAreaNode && !selectedAreaNodeData.can_admin"
          class="w-full md:w-1/2 pr-3" />
      </div>
    </div>
  </transition>
</div>
</template>

<script>
import Form from 'vform'
import Userlist from '~/components/UserBox/UserList'

export default {
  middleware: 'auth',
  title: 'report_page_title',
  subtitle: 'report_page_subtitle',
  menuOption: '0-4',

  components:{
    Userlist,
  },

  data: () => ({
  }),

  mounted () {
  },

  methods: {

  },
};
</script>
