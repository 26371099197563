<template>
  <div>
    <!-- Título y botón añadir -->
    <div class="flex items-center mb-4">
      <span class="flex-grow text-blue-light font-thin text-xl uppercase">{{ title }}</span>
    </div>

    <div class="panel mb-4 comment-lists">
      <!-- Top -->
      <div class="flex items-center mb-4"></div>
      <!-- Contenido -->
      <div v-html="content" class="text-sm mb-4"></div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'entity-description',

    props: {
      title: {type: String, required: true},
      content: {type: String, required: true},
    }

  };
</script>

<style>
  .comment-lists {
    overflow-wrap: break-word;
  }

  .comment-lists ul {
    list-style: disc !important;
    padding-left: 1.5em;
  }

  .comment-lists ol {
    padding-left: 1.5em;
  }
</style>
