<template>
    <div>
        <div class="panel p-2">
            <div class="flex items-center justify-between w-full mb-5">
                <div><label><b>{{ $t('add_assignment') }}</b></label></div>
                <div>
                    <b-tooltip :label="$t('close')" position="is-left">
                        <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest"
                            @click="onCloseClicked">
                            <b-icon pack="fas" icon="times" size="is-small" />
                        </div>
                    </b-tooltip>

                </div>
            </div>

            <div class="w-full pb-3">
                <form class="w-full" @submit.prevent="updateQuantity">
                    <div class="flex flex-wrap mb-3">
                        <div class="w-full px-4 mb-3">
                            <b-field :label="$t('quantity')">
                                <b-input v-model="formAssignment.quantity" title="quantity" required name="quantity"
                                    :placeholder="$t('quantity')" maxlength="100" type="number" />
                            </b-field>
                        </div>
                    </div>
                    <div class="flex flex-wrap mb-3">
                        <div class="w-full px-4 mb-3">
                            <b-field :label="$t('assignments')" :message="assignmentError"
                                :type="assignmentError ? 'is-danger' : ''">
                                <b-select class="w-full" v-model="selectedAssignment"
                                    :placeholder="$t('select_assignment')">
                                    <option v-for="assignment in assignments" :key="assignment.id" :value="assignment">
                                        {{ assignment.name }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                    <!-- <b-loading :is-full-page="false" v-model="processingData" :can-cancel="false"></b-loading> -->
                    <div class="flex flex-wrap mx-4 mb-3 justify-end">
                        <v-button type="submit" class="px-4">{{ $t('add') }}</v-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {

    name: "billings-assigment-cost-budget",
    components: {

    },
    data() {
        return {
            assignments: [],
            selectedAssignment: null,
            assignmentError: null,
            formAssignment: {
                quantity: null,
            },
            invoiceItemId: null,
            productId: null,

        }
    },

    methods: {
        onCloseClicked() {
            this.$emit('epc-close', true)
        },
        show(resource, invoiceId) {
            this.resetData();
            this.invoiceItemId = invoiceId;
            this.productId = resource.product_id;
            if (resource) {
                this.loadAssignments(resource.assignments_cost_budget, resource.product_id);
            }
        },
        async loadAssignments(assignments_cost_budget, productId) {
            try {

                const response = await axios.post(`/api/v2/invoice/cost-budgets/${productId}/assignments`, {
                    assignments: assignments_cost_budget,
                });
                this.assignments = response.data.assignments;
            } catch (error) {
                console.error('Error loading assignments:', error);
            }
        },
        async updateQuantity() {
            try {
                const response = await axios.put(`/api/v2/invoice/assignment-cost-budget/${this.selectedAssignment.id}/quantity`, {
                    quantity: this.formAssignment.quantity,
                    costBudgetId: this.selectedAssignment.cost_budget.id,
                    productId: this.productId,
                    invoiceItemId: this.invoiceItemId
                });
                if (response.data.success) {
                    this.resetData();
                    this.$emit('success-assignment');
                }
            } catch (error) {
                this.$notify.error("La cantidad es mayor a la que se tiene en el Descompuesto");
                console.error('Error updating quantity:', error);
            }
        },
        resetData() {
            this.selectedAssignment = null;
            this.costBudgetId = null;
            this.formAssignment.quantity = null;
        }

    },
}
</script>

<style lang="scss" scoped></style>