import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Cookies from 'js-cookie'

var cookies = Cookies.withConverter({
  read: function (value, name) {
    try {
      return atob(value);
    } catch (err) {
      // Usa lectura por defecto si no es base64
      return Cookies.converter.read(value, name);
    }
  },
  write: function (value, name) {
    return btoa(value);
  }
});

Vue.use(Vuex);

// Load store modules dynamically.
const requireContext = require.context('./modules', false, /.*\.js$/);

const modules = requireContext.keys()
  .map(file =>
    [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
  )
  .reduce((modules, [name, module]) => {
    if (module.namespaced === undefined) {
      module.namespaced = true
    }

    return {...modules, [name]: module}
  }, {});

// Vuex-persist
const vuexCookie = new VuexPersistence({
  key: 'epc-client',
  restoreState: (key, storage) => cookies.getJSON(key),
  saveState: (key, state, storage) => cookies.set(key, state, {
    expires: 3,
    sameSite: 'None',
    secure: true
  }),
  modules: ['auth'],
});
const vuexLocal = new VuexPersistence({
  key: 'epc-client',
  storage: window.localStorage,
  // modules: ['app', 'locale', 'route', 'chat'] // con 'route' da warning: [vuex] state field "route" was overridden by a module with the same name at "route", lo quitamos
  modules: ['app', 'locale', 'searchFilters', 'template', 'device', 'ms-entra-id']
});

export default new Vuex.Store({
  modules,
  plugins: [vuexCookie.plugin, vuexLocal.plugin],
})
