<template>
  <component :is="element" role="navigation" class="navbar"
    :class="{
      'has-shadow': shadow,
      'is-fixed-top': top,
      [`is-${color}`]: true,
    }"
  >
    <div class="container">
      <div class="navbar-brand">
        <slot
          v-if="$scopedSlots.brand"
          name="brand"
        />
        <a v-else href="/" class="navbar-item">
          {{ appName }}
        </a>
        <span class="navbar-burger burger"
          @click="toggleMenu"
          :class="{'is-active': navIsActive}"
          data-target="main-nav"
        >
          <span></span>
          <span></span>
          <span></span>
        </span>
      </div>
      <div id="main-nav"
        class="navbar-menu"
        :class="{'is-active': navIsActive}">
        <div class="navbar-end">
          <slot v-if="$scopedSlots.default"/>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'navbar',

  props: {
    element: { type: String, default: 'nav' },
    shadow: { type: Boolean, default: true },
    top: { type: Boolean, default: true },
    color: { type: String, default: 'light' },
  },

  data: () => ({
    appName: window.config.appName,
    navIsActive: false,
  }),

  methods: {
    toggleMenu() {
      this.navIsActive = !this.navIsActive
    },
  }
};
</script>
