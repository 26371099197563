  <template>
  <div v-if="action">
    <div>
      <label class="font-bold">Acción masiva: {{ action.title }}</label>
      <p>Seleccione el grupo al que se vincularán las actividades seleccionadas</p>
    </div>

    <div class="flex flex-row space-x-4">
      <div class="panel w-4/6">
        <activity-groups-list ref="list_groups"
          :entity-type="entityType"
          :entity-id="projectId"
          :disabled-menu-actions="true"
          :return-selected="true"
          @selected-group="handleSelectedGroup"
        ></activity-groups-list>
      </div>

      <div class="panel w-2/6">
        <div>
          <h6 class="mb-3">Grupo selecionado</h6>

          <entity-list
            :entities="selectedGroup"
            entityKey="id"
            :withImage="false"
            :show="true"
            :boxFullWidth="true"
            :componentName="['name']"
            @removeButtonClicked="onClickRemoveButton($event)"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import ActivityGroupsList from '../activity-groups/activity-groups-list.vue';
import entityList from './utils/entity-list.vue';

export default {
  name: "bulk-assign-group",

  components: {
    ActivityGroupsList,
    entityList
  },

  data: () => ({
    groups: [], // grupos del proyecto
    selectedGroup: [],
  }),

  props: {
    projectId: { type: Number, required: true },
    entityType: { type: String, required: true },
    action: { type: Object, default: null }
  },

  computed: {
  },

  created() {
    this.getGroups()
  },

  mounted() {
  },

  methods: {
    async getGroups() {
      this.groups = []

      const {data} = await axios.get('/api/v2/project/'+ this.projectId + '/activity/groups', {params: {}})
      if (data && data.success) {
        this.groups = data.groups
      }
      // else
      //   error_retreving_group_info
    },

    handleSelectedGroup(params) {
      this.selectedGroup = []
      this.selectedGroup.push(params)
    },

    onClickRemoveButton(event) {
      this.selectedGroup = []
    }

  }

};
</script>

<style scoped>

</style>
