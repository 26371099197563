<template>
  <div v-if="show">
    <div class="mb-2"><p><span>Elija la acción que desea realizar: </span> {{ title }}</p></div>
    <div class="flex flex-row w-full space-x-2 space-y-2 flex-wrap">
      <b-button v-for="action in actions"
        icon="list"
        size="is-small"
        @click="onClick(action)"
        :type="actionSelected && actionSelected.type == action.type ? 'is-info' : ''"
        class="" :key="action.type">{{ $t(action.text) }}</b-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'bulk-actions-buttons',

  components: {
  },

  props: {
    title: { type: String, default: 'actividades seleccionadas' },
    show: { type: Boolean, default: false },
    clearSelect: { type: Boolean, default: false },
    setActions: { type: Array, default: null }
  },

  data: () => ({
    actionSelected: null,
    actions: [
      {type: 1, name: 'assign_manager', text: 'assign_manager', title: 'asignación de responsable'},
      {type: 2, name: 'assign_participants', text: 'assign_participants', title: 'asignación de participantes'},
      {type: 3, name: 'assign_collaborators', text: 'assign_collaborators', title: 'asignación de colaboradores'},
      {type: 9, name: 'assign_tracking_user', text: 'assign_tracking_user', title: 'asignación de usuario de seguimiento'},
      {type: 4, name: 'change_data', text: 'modify_date_start_end', title: 'cambio de fechas de inicio/fin'},
      {type: 5, name: 'link_documents', text: 'link_documents', title: 'vinculación de documentos'},
      {type: 6, name: 'link_validations', text: 'add_validation_flow', title: 'asignación de fluo de validaciones'},
      {type: 7, name: 'link_tags', text: 'tags', title: 'asignación de etiquetas'},
      {type: 8, name: 'assign_group', text: 'assign_group', title: 'asignación de grupo'},
      // {type: 10, text: 'geolocation', title: 'asignación de geolocalización'},
      // type 10 al 14 estan en bulk actions validations
      // {type: 14, name: 'remove_manager', text: 'remove_manager', title: 'eliminar responsable'}, // Lo dejamos comentado por que una actividad no puede estas sin responsable
      {type: 15, name: 'remove_participants', text: 'remove_participants', title: 'eliminar participantes'},
      {type: 16, name: 'remove_collaborators', text: 'remove_collaborators', title: 'eliminar colaboradores'},
      {type: 17, name: 'remove_tracking_user', text: 'remove_tracking_user', title: 'eliminar usuario de seguimiento'},
      {type: 18, name: 'remove_activities', text: 'remove_activities', title: 'eliminar actividades o subactividades'},
    ]
  }),
// assign activity viewer user
  mounted() {
    if (this.setActions) {
      this.actions = this.setActions
    }
  },

  created() {

  },

  watch: {
    clearSelect(newVal, oldVal) {
      if (newVal)
        this.actionSelected = null
    }
  },

  methods: {
    onClick(action) {
      this.actionSelected = action
      this.$emit('select-action', action);
    },
  },
}
</script>
