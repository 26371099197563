<template>
  <div>
    <!-- Botonera, sólo Jefes de departamento -->
    <div class="flex w-full">
      <div class="w-full">
        <!--light mode - text and icons-->
        <div class="p-2 text-gray-900 bg-white rounded-lg shadow-lg- font-medium capitalize text-right">

          <!-- Mostrar tabla -->
          <span v-if="project.user_department.can_admin"
            class="px-1 mt-2  hover:text-gray-700 cursor-pointer
              lg:px-2 text-sm xl:rounded xl:py-2 xl:hover:bg-gray-200"
            @click="displayTable()">
            <b-tooltip :label="$t('display_table')" position="is-top">
              <i class="lg:w-8 fas fa-table p-2 bg-gray-200 rounded-full"></i>
              <span class="mx-1 hidden xl:inline-">{{ $t('display_table') }}</span>
            </b-tooltip>
          </span>

          <!-- Crear actividad -->
          <span v-if="project.user_department.can_admin"
            class="px-1 mt-2  hover:text-gray-700 cursor-pointer
              lg:px-2 text-sm xl:rounded xl:py-2 xl:hover:bg-gray-200"
            @click="onCreateActivityClicked()">
            <b-tooltip :label="$t('add_activity')" position="is-top">
              <i class="lg:w-8 fas fa-plus p-2 bg-gray-200 rounded-full"></i>
              <span class="mx-1 hidden xl:inline-">{{ $t('add_activity') }}</span>
            </b-tooltip>
          </span>

          <!-- Botón para ver Kanban-->
          <span v-if="project.user_department.can_admin"
            class="px-1 w-8 mt-2 relative cursor-pointer hover:text-gray-700"
            @click="displayKanban()">
            <b-tooltip :label="$t('display_kanban')" position="is-left">
              <i class="w-8 fas fa-columns p-2 bg-gray-200 rounded-full"></i>
            </b-tooltip>
            <!-- <span class="absolute right-0 top-0 -mt-2 -mr-1 text-xs bg-red-500 text-white font-medium px-2 shadow-lg rounded-full">3</span> -->
          </span>

        </div>
      </div>
    </div>

    <!-- Filtros -->
    <activity-filters @filters-updated="filtersUpdated" :projectId="project.id" ref="filters"/>

    <!-- Botonera, sólo Jefes de departamento -->
    <div class="w-full h-8" v-if="false">
      <div class="float-left mt-1 w-full">

        <!-- sólo Jefes de departamento -->
        <template v-if="project.user_department.can_admin">

          <!-- Botón para ver Kanban-->
          <div class="rounded-sm mr-2 float-right">
            <v-button icon="columns" class="mr-4" @click="displayKanban()">{{$t('display_kanban')}}</v-button>
          </div>

          <div class="rounded-sm mr-2 float-right">
            <v-button icon="plus" class="mr-4" @click="onCreateActivityClicked()">{{$t('add_activity')}}</v-button>
          </div>

        </template>

        <div class="rounded-sm mr-2 float-right">
          <v-button icon="table" class="mr-4" @click="displayTable()">{{$t('display_table')}}</v-button>
        </div>

      </div>
    </div>


    <br>

    <!-- spinner -->
    <div v-if="isLoading" class="text-center my-4">
      <scale-loader/>
    </div>

    <div v-if="!isFetchingData">
      <gantt-elastic
        v-if="tasks.length > 0"
        :options="options"
        :tasks="tasks"
        @tasks-changed="tasksUpdate"
        @options-changed="optionsUpdate"
        @dynamic-style-changed="styleUpdate"
        @hook:updated="ganttMounted"
        ref="ganttElastic"
      >
        <activity-gantt-header slot="header" :options="options"/>
      </gantt-elastic>

      <div v-else class="m-4 text-lg text-dark-grey">
        <span>{{ noResults() }}</span>
      </div>

    </div>
  </div>
</template>

<script>
  import GanttElastic from 'gantt-elastic';
  import EventBus from "~/plugins/bus";
  import ActivityGanttHeader from '·/components/activity-gantt-header'
  import ActivityFilters from '·/components/activity-filters'
  import axios from "axios";
  import {mapGetters} from "vuex";
  import moment from 'moment';
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
  import Vue from "vue";

  Vue.use(moment);

  const statusColors = [
    //assigned 0
    "#B8C2CC",
    //running 1
    "#3490dc",
    //paused 2
    "#F6993F",
    //issue 3
    "#e3342f",
    //done 4
    "#6574CD",
    //reopened 6
    "#9561E2",
    //closed 6
    "#38C172"
  ];

  let ganttVueObject = null;

  export default {
    name: "Gantt",

    components: {
      GanttElastic,
      ActivityFilters,
      ActivityGanttHeader,
      ScaleLoader
    },

    data() {
      return {
        completedText: this.$t('activity_status_done'),
        isLoading: true,
        options: {},
        isFetchingData: true,
        tasks: [],
        dynamicStyle: {},
      };
    },

    computed: {
      ...mapGetters({
        project: 'app/project'
      }),
    },

    mounted() {
      this.getTasks();
      ganttVueObject = this;

      let self = this;
      EventBus.$on("refreshAssignmentChangedNotifications", function (e) {
        if (self.project.id === e.project_id) {
          //actualizamos solo la medición afectada
          let assignment = self.tasks.findIndex(x => x.id === 't' + e.assignment_id);
          self.reloadAssigment(assignment);
        }
      });
      EventBus.$on("refreshAssignmentCreatedNotifications", function (e) {
        if (self.project.id === e.project_id) {
          //recarga completa
          self.getTasks();
        }
      });
      EventBus.$on("refreshAssignmentDeletedNotifications", function (e) {
        if (self.project.id === e.project_id) {
          //recarga completa
          self.getTasks();
        }
      });
    },

    beforeDestroy(){
      EventBus.$off("refreshAssignmentChangedNotifications");
  	  EventBus.$off("refreshAssignmentCreatedNotifications");
  	  EventBus.$off("refreshAssignmentDeletedNotifications");
    },

    methods: {
      async getActivitiesList(args = []) {
        this.isLoading = true;
        this.isFetchingData = true;

        if (args.length === 0)
          args = this.$refs.filters.generateFilters();
          args.light = 1
          args.vs_gantt = 1

        const {data} = await axios.get('/api/v2/activities', {params: this.customizeFilters(args)});

        if (data && data.activities) {
          this.tasks = this.mapActivities(data.activities);
          this.isFetchingData = false;
        } else {
          this.$notify.error('error_loading_activities')
        }
      },

      /**
       * Filtros obligatorios para el diagraa de gantt
       */
      customizeFilters(filters) {
        // filters.with_assignments = 0;
        filters.pg = 1;
        filters.s = 1;
        return filters;
      },

      async reloadAssigment(index) {
        let id = (this.tasks[index].id).substring(1);
        let url = '/api/v2/assignment/' + id;
        const {data} = await axios.get(url);

        if (data && data.assignment) {
          this.updateAssignment(index, data.assignment);
          this.isFetchingData = false;
        } else {
          this.$notify.error('error_loading_activities')
        }

        // console.log(this.tasks[index]);

      },

      updateAssignment(index, data) {
        console.log(this.tasks[index]);
        let percent = parseFloat(this.calculatePercentage(data).toFixed(2));
        this.tasks[index].percent = percent;
        this.tasks[index].label = percent + '% ' + this.completedText + ' - ' + data.name;
        //this.tasks[index].description = data.name; // el icono debe añadirse también
      },

      calculatePercentage(assignment) {
        if (assignment.total_quantity != null) {
          return (100 / assignment.total_quantity) * assignment.executed_quantity;
        } else
          return (assignment.completed) ? 100 : 0;
      },

      /**
       * Tras la llamada a la API la actividad debe procesarse
       */
      mapActivities(activities) {
        let clean = [];

        for (let i in activities) {
          let activity = activities[i];

          clean.push(this.mapActivity(activity));

          for (let j in activity.subactivities) {
            clean.push(this.mapActivity(activity.subactivities[j]));
          }

        }

        for (let i in clean) {
          let activity = clean[i];

          if (activity._assignments) {
            for (let j in activity._assignments) {
              clean.push(this.mapAssignment(activity._assignments[j], activity));
            }

            delete activity._assignments;
          }
        }

        return clean;
      },

      /**
       * Procesa los datos de una actividad, de las que fueron conseguidas via API
       */
      mapActivity(activity) {
        let clean = {};

        if (activity.assignments)
          clean._assignments = activity.assignments;//Luego es extraído y borrado;

        //otorga un color liso según el status
        clean.style = {
          base: {
            fill: statusColors[activity.status],
            stroke: statusColors[activity.status]
          }
        };

        clean.id = activity.id;
        clean.planning_code = activity.planning_code;
        clean.parentId = (activity.is_subactivity) ? activity.parent_id : null;
        clean.user = activity.manager;

        let start = moment(activity.current_start_date, 'DD-MM-YYYY');
        let end = moment(activity.current_end_date, 'DD-MM-YYYY');
        clean.start = Number(start.format("x"));
        //tiempo en milisegundos hasta el final del día
        clean.duration = Number(end.diff(start) + 86399000);

        //en el plugin gantt project se muestra lisa con picos y milestone es hexagonal
        clean.type = (activity.is_subactivity) ? 'milestone' : 'project';
        clean.collapsed = true;
        clean.description = activity.name;
        clean.percent = parseFloat(activity.progress.toFixed(2));
        clean.label = activity.progress + '% ' + this.completedText + ' - ' + activity.name;

        return clean;
      },

      /**
       * Procesa los datos de una asignaciones que fueron conseguidas via API
       */
      mapAssignment(assignment, activity) {
        assignment.start = activity.start;
        assignment.duration = 86399000 * 7;
        assignment.id = 't' + assignment.id;
        assignment.percent = parseFloat(this.calculatePercentage(assignment).toFixed(2));
        //En el plugin gantt project se muestra 'task' como un rectángulo sin más
        assignment.type = 'task';
        assignment.label = assignment.percent + '% ' + this.completedText + ' - ' + assignment.description;
        assignment.style = {
          base: {
            fill: '#679dbf',
            stroke: '#679dbf'
          }
        };

        return assignment;
      },

      /**
       * Toma las actividades del proyecto activo
       * @returns {Promise<void>}
       */
      async getTasks() {
        this.getActivitiesList();

        this.options = this.getOptions();
      },

      /**
       * Se dispara cuando la caja de filtros se actualiza
       */
      filtersUpdated(filters) {
        //filters.page = (page <= this.last) ? page : 1 // omitido, no usamos paginación para el diagrama de gantt
        this.getActivitiesList(filters);
      },

      /**
       * cambia de ventana a la actividad o subactividad
       */
      goToActivity(item) {
        if (item.parentId == null) {
          this.$router.push({name: 'activity.home', params: {activity_id: item.id}});
        } else if (item.type == 'task') {
          return this.goToActivity(this.tasks.find(x => x.id === item.parentId));
        } else {
          this.$router.push({name: 'subactivity.home', params: {subactivity_id: item.id, id: item.parent}});
        }

      },

      /**
       * Cambia ventana al formulario de crear actividades
       */
      onCreateActivityClicked() {
        let url = '/api/v2/locations?p=' + this.project.id;
        let self = this;
        axios.get(url)
          .then(function (response) {
            if (response.data && response.data.success && response.data.locations) {
              self.$router.push({name: 'activity.create'})
            } else {
              self.$notify.error('project_without_locations_create_activity')
            }
          });
      },

      /**
       * Cambia la ventana a la tabla de actividades normal
       */
      displayTable() {
        this.$router.push({name: 'project.activities'});
      },

      /**
       * Cambia la ventana a la vista kanban
       */
      displayKanban() {
        this.$router.push({name: 'project.activities.kanban'});
      },

      /**
       * Muestra mensaje para falta de resultados y anula el spinner
       */
      noResults() {
        this.isLoading = false;
        return this.$t('no_more_results');
      },

      /**
       * Avisa cuando el componente del diagrama acaba de cargar, quita el spinner
       */
      ganttMounted() {
        this.isLoading = false;
      },

      /**
       * Evento del Componente gantt-elastic para sincronizar "tareas"
       */
      tasksUpdate(tasks) {
        this.tasks = tasks;
      },

      /**
       * Evento del Componente gantt-elastic para sincronizar las opciones
       */
      optionsUpdate(options) {
        this.options = options;
      },

      /**
       * Evento del Componente gantt-elastic para sincronizar estilos
       */
      styleUpdate(style) {
        this.dynamicStyle = style;
      },

      /**
       * Lista de opciones usada por el diagrama de gantt
       * @returns {{calendar: {hour: {display: boolean}}, maxRows: number, maxHeight: number, taskList: {columns: ({width: number, id: number, label: VueI18n.TranslateResult, value: string}|{width: number, html: boolean, id: number, label: VueI18n.TranslateResult, expander: boolean, value: string, events: {click({data: *, column: *}): void}}|{width: number, html: boolean, id: number, label: VueI18n.TranslateResult, value: string}|{width: number, id: number, label: VueI18n.TranslateResult, value: (function(*): string)}|{width: number, id: number, label: VueI18n.TranslateResult, value: (function(*): string)})[], expander: {straight: boolean}}, row: {height: number}, taskMapping: {progress: string}, title: {html: boolean, label: string}, locale: {"Y-Scale": VueI18n.TranslateResult, months: *, "Task list width": VueI18n.TranslateResult, "Before/After": VueI18n.TranslateResult, weekdays: *, Now: VueI18n.TranslateResult, name: VueI18n.TranslateResult, monthsShort: *, "X-Scale": VueI18n.TranslateResult, weekdaysShort: *, "Display task list": VueI18n.TranslateResult, weekdaysMin: *}, chart: {progress: {bar: boolean}, expander: {display: boolean}}}}
       */
      getOptions() {
        return {
          taskMapping: {
            progress: "percent"
          },
          maxRows: 100,
          maxHeight: this.calculateHeight(),
          title: {
            label: "",
            html: false
          },
          row: {
            height: 24
          },
          calendar: {
            hour: {
              display: false
            }
          },
          chart: {
            progress: {
              bar: false
            },
            expander: {
              display: true
            }
          },
          taskList: {
            expander: {
              straight: true
            },
            columns: [
              {
                id: 1,
                label: this.$t('code'),
                value: "planning_code",
                width: 108
              },
              {
                id: 2,
                label: this.$t('manager'),
                value: task => {
                  return '<div class="gantt-avatar"><div class="rounded-full overflow-hidden border border-grey w-8 h-8 mr-1">' +
                    '<img src="' + task.user.avatar + '" alt="' + task.user.fullname + '" title="' + task.user.fullname + '"></div></div>';

                },
                width: 46,
                html: true
              },
              {
                id: 3,
                value: task => {
                  return '<a><i class="fas fa-link"></i></a>'
                },
                width: 27,
                html: true,
                events: {
                  click({data}) {
                    ganttVueObject.goToActivity(data);
                  }
                }
              },
              {
                id: 4,
                label: this.$t('description'),
                value: task => {

                  let type = '';

                  switch (task.type) {
                    case 'task':
                      type = '<i class="fas fa-tasks task"></i>';
                      break;

                    case 'milestone':
                      type = '<i class="fas fa-dot-circle milestone"></i>';
                      break;

                  }

                  return '<span class="gantt-description">' + type + ' ' + task.description + '</span>';
                },
                width: 200,
                expander: true,
                html: true,
                events: {
                  //click({data, column})
                  click({data}) {
                    ganttVueObject.$refs.ganttElastic.scrollToTime(data.start);
                  }
                }
              },
              {
                id: 5,
                label: '%',
                value: "percent",
                width: 50,
                style: {
                  "task-list-header-label": {
                    "text-align": "center",
                    width: "100%"
                  },
                  "task-list-item-value-container": {
                    "text-align": "center",
                    width: "100%"
                  }
                }
              }
            ]
          },
          scope: {
            before: 6,// padding de tiempo a la izquierda
            after: 12 // padding de tiempo a la derecha
          },
          times: this.calculateTimeSizes(),
          locale: {
            name: this.$t("gantt_locale_name"),
            Now: this.$t("go_to_present"),
            "X-Scale": this.$t("x-scale"),
            "Y-Scale": this.$t("y-scale"),
            "Task list width": this.$t("task_list_width"),
            "Before/After": this.$t("expand"),
            "Display task list": this.$t("hide_list_data"),

            weekdays: this.$t("weekdays").split('_'),
            weekdaysShort: this.$t("weekdaysShort").split('_'),
            weekdaysMin: this.$t("weekdaysMin").split('_'),
            months: this.$t("gantt_months_list").split('_'),
            monthsShort: this.$t("monthsShort").split('_')
          }
        };
      },

      /**
       * Calcula la altura disponible para la tabla gantt
       * @returns {number}
       */
      calculateHeight() {
        // 381px es el alto actual del encabezado de la página + barra de herramientas + filtro + scroll + etc
        return window.innerHeight - 381;
      },

      /**
       * Genera el sector times de las opciones del plugin de gantt
       * @returns {{timeZoom: number, firstTime: null, lastTime: null, totalViewDurationMs: number, timeScale: number, timePerPixel: number, firstTaskTime: number, totalViewDurationPx: number, stepDuration: string, lastTaskTime: number, steps: []}}
       */
      calculateTimeSizes() {
        // Valores por defecto
        let timeScale = 16000;
        let stepDuration = 'month';
        /*
        // De volver a usar esto, en el propio fetch sería óptimo de ejecutar.
        let smallest = this.tasks.reduce(function (prev, curr) {
          return prev.duration < curr.duration ? prev : curr;
        }, 0).duration;

        if (smallest > 86399000 * 30) {
          let timeScale = 8000;
          let stepDuration = 'week'

        } else if (smallest > 86399000 * 7) {
          let timeScale = 2000;
          let stepDuration = 'week'
        }
        */
        return {
          timeScale: 60 * timeScale,
          timeZoom: 20,
          timePerPixel: 0,
          firstTime: null,
          lastTime: null,
          firstTaskTime: 0,
          lastTaskTime: 0,
          totalViewDurationMs: 0,
          totalViewDurationPx: 2000,
          stepDuration: stepDuration,
          steps: []
        }
      }

    }

  };
</script>

<style lang="scss">
  .gantt-description {
    cursor: pointer;

    .svg-inline--fa {
      display: inline;
    }
  }

  .gantt-elastic__task-list-item-value {
    a {
      display: inline-block;
      margin-right: 0.25rem;
    }
  }

  .gantt-elastic__task-list-header-column, .gantt-elastic__calendar {
    background-color: #385DA5 !important;

    * {
      color: white !important;
    }

  }

  .gantt-description {
    .task {
      color: #679dbf;
    }

    .milestone {
      color: #89A4D6;
    }
  }
</style>
