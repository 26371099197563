<template>
<transition-group
  name="custom-classes-transition"
  :enter-active-class="show ? 'animated zoomIn' : null"
  :leave-active-class="show ? 'animated zoomOut' : null"
  tag="ul"
  class="flex-wrap"
  style="min-height:3rem">
  <li
    v-for="(user, index) in usersList"
    :key="user.user_id"
    class=" w-1/4  pb-2">
    <div
      :class="[(authUser.id === user.user_id) ? 'bg-blue-lightest' : 'bg-grey-lightest']"
      class="w-full border border-blue-lighter rounded flex items-center px-2" style="height:38px;line-height:33px">
      <img
        :src="user.avatar"
        class="rounded-full overflow-hidden"
        style="width:28px;height:28px">
      <span class="flex-grow text-xs font-semibold text-blue-light truncate px-2">
        {{ user.fullname }}
      </span>
      <b-modal :active.sync="showSearchBox" has-modal-card>
      <usersearchmodal
        v-if="showSearchBox"
        :parent-element="$refs.addButtom"
        :userSelected="userSelected"
        @close="showSearchBox = false"
        @user-selected="addUser({userSelected,$event})" />
      </b-modal>
      <button
        @click="onClickAddSignatoryButton(user.user_id)"
        v-if="addSignatories"
        @mouseover="isHoverAddIcon = true"
        @mouseleave="isHoverAddIcon = false"
        class="flex items-center mr-2">
        <b-icon v-show="!isHoverAddIcon"
          pack="far"
          icon="edit"
          class="text-grey float w-full h-full"
          size="is-small"/>
        <b-icon v-show="isHoverAddIcon"
          pack="fas"
          icon="edit"
          class="text-grey float w-full h-full"
          size="is-small"/>
      </button>
      <button
        v-show="showRemoveButton"
        @click="onClickRemoveButton(index)"
        @mouseover="isHoverIcon = true"
        @mouseleave="isHoverIcon = false"
        class="flex items-center">
        <b-icon v-show="!isHoverIcon"
          pack="far"
          icon="trash-alt"
          class="text-grey float w-full h-full"
          size="is-small"/>
        <b-icon v-show="isHoverIcon"
          pack="fas"
          icon="trash-alt"
          class="text-grey float w-full h-full"
          size="is-small"/>
      </button>
    </div>
    <template v-for="signatory in user.signatories">
      <div class="w-full px-4">
        <div class="w-full border border-grey-lighter rounded flex items-center px-4" style="height:38px;line-height:33px">
           <img
            :src="signatory.avatar"
            class="rounded-full overflow-hidden"
            style="width:28px;height:28px">
          <span class="flex-grow text-xs font-semibold text-blue-light truncate px-2">
            {{ signatory.name }} {{ signatory.surname }}
          </span>
          <button
            v-show="showRemoveButton"
            @click="onClickRemoveSignatoryButton({user,signatory})"
            @mouseover="isHoverIcon = true"
            @mouseleave="isHoverIcon = false"
            class="flex items-center">
            <b-icon v-show="!isHoverIcon"
              pack="far"
              icon="trash-alt"
              class="text-grey float w-full h-full"
              size="is-small"/>
            <b-icon v-show="isHoverIcon"
              pack="fas"
              icon="trash-alt"
              class="text-grey float w-full h-full"
              size="is-small"/>
          </button>
        </div>  
      </div>
    </template>
  </li>
</transition-group>
</template>

<script>
import { mapGetters } from 'vuex'
import Usersearchmodal from './UserSearchModal.vue'


export default {
  components:{
    Usersearchmodal,
  },
  props: {
    usersList: { type: Array, default: [] },
    disabled: { type: Boolean, default: false },
    allowZeroUsers: { type: Boolean, default: false },
    show: { type: Boolean, default: false },
    addSignatories: { type: Boolean, default: false },
  },

  data: () => ({
    isHoverIcon: false,
    isHoverAddIcon: false,
    showSearchBox:false,
    userSelected: 0,
  }),

  computed: {
    ...mapGetters({
      authUser: 'auth/user',
    }),
    showRemoveButton() {
      if (this.disabled) {
        return false
      }
      return this.allowZeroUsers || (this.usersList && (this.usersList.length > 1))
    }
  },

  methods: {
    addUser(index){
      console.log(index)
      this.$emit('addSignatoryButtonClicked', index)

    },
    onClickRemoveButton(index) {
      this.$emit('removeButtonClicked', index)
    },
    onClickRemoveSignatoryButton(index) {
      this.$emit('removeSignatoryButtonClicked', index)
    },
    onClickAddSignatoryButton(id) {
      this.userSelected = id
      this.showSearchBox = true
    },
  },
};
</script>
