<template>

<main class="flex flex-row max-h-screen bg-white overflow-y-hidden">

  <!-- Menú lateral -->
  <sidebar class="transition-transform lg:translate-x-0 -translate-x-full"
    :class="{ '-translate-x-full': !showSidebar, 'transform-none': showSidebar}"
    @epc-show-sidebar="_HandleShowSidebar"/>

  <!-- Contenedor de las páginas -->
  <div class="flex flex-col w-full lg:w-5/6">

    <!-- Menú superior -->
    <navbar @epc-show-sidebar="(e) => { showSidebar = e }"/>

    <div class="mt-20">
    <!-- Página -->
      <!-- Chatrooms Panel -->
      <!-- <chatrooms /> chat desactivado -->

      <!-- Título/Subtítulo de la página -->
      <div id="page-title" class="sticky p-4 bg-white top-20 z-10" style="border-bottom: 1px solid rgb(173, 194, 234);">
        <h1 :class="'m-0 p-0 text-xl font-raleway font-bold uppercase' + titleCssClass">{{ pageInfo.title }}</h1>
        <p class="m-0 text-sm font-bold font-raleway text-grey-dark">{{ pageInfo.subtitle }}</p>
      </div>

      <!-- Componente que renderiza la página en sí -->
      <child class="p-4" style="border-top:1px solid #adc2ea;" />

    </div>
  </div>
  <div v-show="showSidebar" id="drawer-backdrop"
    class="bg-gray-900 bg-opacity-50 fixed inset-0 lg:hidden"
    style="z-index: 55;"
    @click="() => { showSidebar = false }"></div>
</main>

</template>

<script>
import { mapGetters } from 'vuex'
import Sidebar from '~/components/Sidebar/Sidebar'
import Navbar from '~/components/Navbar/Navbar'
import Chatrooms from '~/components/Chat/ChatRooms'
import Vue from 'vue'

// DM - Para poder acceder al objeto metaInfo de cada página
Vue.mixin({
  mounted() {
    let title = this.$options.title,
        subtitle = this.$options.subtitle,
        menuOption = this.$options.menuOption

    // De momento consideramos 'páginas' los componentes de Vue que tengan
    // una propiedad 'title' definida
    if (title !== undefined) {
      // Si el título es un array es que trae parámetros
      var _title = ''
      if (title instanceof Array) {
        var titleClone = title.slice(0)
        let key = titleClone.shift()
        _title = this.$t(key, titleClone)
      } else {
        _title = this.$t(title)
      }
      // Lo mismo para el subtítulo
      var _subtitle = ''
      if (subtitle instanceof Array) {
        var subtitleClone = subtitle.slice(0)
        let key = subtitleClone.shift()
        _subtitle = this.$t(key, subtitleClone)
      } else {
        _subtitle = this.$t(subtitle)
      }
      // Guardamos la info en el store
      let pageInfo = {
        title: _title,
        subtitle: _subtitle,
        menuOption: menuOption
      }
      this.$store.dispatch('app/updatePageInfo', { info: pageInfo })

      this.$options.metaInfo = (() => {
        return { title: pageInfo.title }
      })
    }
  },

  // GLOBAL METHODS
  methods: {
    asset(path) { // bucket de nuestros assets estaticos
      return process.env.MIX_ASSETS_URL + path;
    },
    assetVapor(path) { // bucket dinamico de vapor donde sube todo el raiz
      let base = window.config.assetUrl || '' // vapor o local?
      return base + '/' + path;
    },
    clientHost() {
      let currentUrl = window.location.host;
      let pieces = currentUrl.toLowerCase().split('.');
      return pieces[0];
    },
  }
})

export default {
  name: 'AppFixLayout',

  components: {
    Sidebar,
    Navbar
  },


  data: () => ({
    version: window.config.version,

    showSidebar: false,
    windowWidth: null,
    isMobile: false,

    scrollNav: false, // no usado de momento
  }),


  computed: {
    ...mapGetters({
      pageInfo: 'app/pageInfo',
    }),
    titleCssClass() {
      return this.$route.name === 'issue.home' || this.$route.name === 'issue.home-sub' ?
        ' text-red-light ' :
        ' text-blue-light '
    },

  },

  created() {
    window.addEventListener('resize', this._CheckScreen)
    this._CheckScreen()
  },

  destroy() {
    window.removeEventListener('resize', this._CheckScreen)
  },

  mounted() {
    window.addEventListener('scroll', this._UpdateScroll)
  },

  methods: {
    _ToogleSidebar() {
      this.showSidebar = !this.showSidebar
    },

    _UpdateScroll() {
      const scrollPosition = window.scrollY
      if(scrollPosition > 50) {
        this.scrollNav = true
        return
      }
      this.scrollNav = false
    },

    async _CheckScreen() {
      this.windowWidth = window.innerWidth
      // if( this.windowWidth <= 640 ) { 768
      if( this.windowWidth <= 1024 ) {
        this.showSidebar = false
        this.isMobile = true
        await this.$store.dispatch("device/setMobile", { on_mobile: true });
        return
      // } else if (this.windowWidth <= 768) {
        // await this.$store.dispatch("responsive/setTablet", { isTablet: true });
        // return
      }

      this.showSidebar = true
      this.isMobile = false

      // await this.$store.dispatch("responsive/setTablet", { isTablet: false });
      await this.$store.dispatch("device/setMobile", { on_mobile: false });
    },

    async _HandleShowSidebar(e) {
      if (this.isMobile)
        this.showSidebar = e
    }
  }

};
</script>

<style scoped>
  .-translate-x-full {
    transform: translate(-100%, 0)
      rotate(0)
      skewX(0)
      skewY(0)
      scaleX(1)
      scaleY(1);
  }

  .transition-transform {
    transition-duration: .15s;
    transition-property: transform;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
  }

  .transform-none {
    transform: none;
  }

  .scrolled-nav {
    background-color: #000;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.6);
  }

  .scrolled-nav.nav {
    padding: 8px 0;
  }
</style>
