<template>
  <div>
      <!-- Tabla de Extra Fields -->
      <div class="panel">
          <div class="flex flex-wrap w-full mt-4">
            <section class="w-full">
              <b-table
              :loading="isLoading"
                icon-pack="fas"
                :data="extrafields"
                :paginated="isPaginated"
                :per-page="perPage"
                :bordered="true"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :default-sort-direction="defaultSortDirection"
                checkable
                checkbox-position="left"
                :checkbox-type="'is-success'"
                :checked-rows.sync="checkedRows"
                @check="checkedExtrafields">
                <template>
                  <b-table-column field="code" sortable>
                    <template v-slot:header="">{{ $t('code') }}</template>
                    <template v-slot="props">{{ props.row.code }}</template>
                  </b-table-column>

                  <b-table-column field="name" sortable>
                    <template v-slot:header="">{{ $t('name') }}</template>
                    <template v-slot="props">{{ props.row.name }}</template>
                  </b-table-column>

                  <b-table-column field="type" sortable>
                    <template v-slot:header="">{{ $t('type') }}</template>
                    <template v-slot="props">{{ fieldTypes[props.row.field_type] }}</template>
                  </b-table-column>

                  <b-table-column field="order" sortable>
                    <template v-slot:header="">{{ $t('order') }}</template>
                    <template v-slot="props">{{ props.row.order_column }}</template>
                  </b-table-column>

                  <b-table-column field="actions" width=100 sortable>
                    <template v-slot:header="">{{ $t('actions') }}</template>
                    <template v-slot="props">
                      <div class="flex items-center justify-around w-full h-full">
                        <b-tooltip :label="$t('edit')" position="is-left">
                            <div @click="openExtrafieldEdit(props.row)"
                                class="flex cursor-pointer text-blue">
                              <b-icon pack="fas" icon="pencil-alt"/>
                            </div>
                        </b-tooltip>
                        <b-tooltip :label="$t('delete')" position="is-left" type="is-danger">
                            <div @click="removeExtrafield(props.row)"
                                class="flex cursor-pointer text-red">
                              <b-icon pack="fas" icon="trash-alt"/>
                            </div>
                        </b-tooltip>
                        <b-tooltip :label="$t('view_values')" position="is-left">
                            <div @click="showValues(props.row)"
                                class="flex cursor-pointer text-sky-300">
                              <b-icon pack="fas" icon="eye"/>
                            </div>
                        </b-tooltip>
                        <b-tooltip :label="$t('view_assignments')" position="is-left">
                            <div @click="showAssignments(props.row)"
                                class="flex cursor-pointer text-sky-300">
                              <b-icon pack="fas" icon="paperclip"/>
                            </div>
                        </b-tooltip>
                      </div>
                    </template>
                  </b-table-column>
                </template>

                <template slot="bottom-left">
                    <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                    <b>{{ $t("total") }}</b>: {{ this.extrafields.length }}
                </template>

                <template #empty>
                    <div class="has-text-centered">{{ $t('no_results') }}</div>
                </template>

              </b-table>
            </section>
          </div>
      </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {

    components: {
    },

    props: {
      projectId: { type: Number, required: true },
      apiEntity: { type: String, default: 'extrafield' } // Api Entity hace referencia a la api de la entidad relacionada con project
    },

    computed: {
      filters() {
        let filters = {
          'project_id': this.projectId,
          'order_field': this.orderField,
          'order_dir': this.orderDir,
        }

        return filters
      }
    },

    data: function() {
      return {
        isLoading: true,
        extrafields: [],

        page: 1,
        perPage: 25,
        total: 0,
        orderField: "reference_code",
        orderDir: "asc",
        currentPage: 1,
        isPaginationSimple: false,
        defaultSortDirection: 'asc',
        isPaginationSimple: false,
        isPaginated: true,

        checkedRows: [],

        fieldTypes: [
          'select',
          'input',
          'calendar',
          'checkbox',
        ],

      }
    },

    created() {
      this.getProjectExtrafields()
    },

    methods: {

      async getProjectExtrafields() {
        this.isLoading = true;
        let params = {
          p: this.projectId,
          order_dir: this.orderDir,
          order_field: this.orderField
        };

        const { data } = await axios.get(
          "/api/v2/project/" + this.projectId + "/" + this.apiEntity + 's',
          { params: params }
        );

        if (data && data.success) {
          this.extrafields = data.extrafields;
          this.total = data.extrafields ? data.extrafields.length : 0;
        } else {
          this.$notify.error("error_loading_extra_fields_data");
        }
        this.isLoading = false;
      },

      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },

      checkedExtrafields(checkedList, row) {
        this.$emit('epc-checked-rows', checkedList)
      },

      onSort(field, order) {
        this.projectId = this.projectId
        this.orderField = field
        this.orderDir = order
        this.getProjectExtrafields()
      },

      onPageChange(page) {
        this.page = page
        this.getProjectExtrafields()
      },

      cleanFiltersClicked() {
        this.filterTemplateName = ''
        this.getProjectExtrafields()
      },

      reload() {
        this.getProjectExtrafields();
      },

      async openExtrafieldEdit(row) {
        let extrafield = {}
        extrafield.id = row.id
        extrafield.code = row.code
        extrafield.name = row.name
        extrafield.field_type = row.field_type
        extrafield.order_column = row.order_column
        this.$emit('epc-edit', extrafield)
      },

      removeExtrafield(field) {
        this.$dialog.confirm({
          title: this.$t("delete"),
          message: this.$t("delete_generic", [field.name]),
          confirmText: this.$t("delete"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            this.isLoading = true;
            let url = `/api/v2/${this.apiEntity}/` + field.id + "/delete";
            const { data } = await axios.post(url);

            if (data.success) {
              this.$toast.open({
                message: this.$t("success_deleting_generic"),
                type: "is-success",
                position: "is-top-right"
              });
              await this.sleep(1000);
              this.reload();
            } else {
              this.isLoading = false;
              // Error
              this.$toast.open({
                message: data.error || this.$t("error_saving"),
                type: "is-danger",
                position: "is-top-right"
              });
            }
          }
        });
      },

      showValues(field, access = true) {
        if (access) {
          this.$emit('epc-show-values', field)
        }
      },

      showAssignments(field, access = true) {
        if (access) {
          this.$emit('epc-show-assignments', field)
        }
      },

      archiveClass(value) {
        return value ? "cursor-pointer flex text-yellow-dark" : "cursor-pointer flex text-blue"
      },

      handleSelect(newRow, oldRow) {
        this.$emit('update:template', newRow);
      },

      clearSelected() {
        this.rowSelected = null
      }
    },

  };
</script>
