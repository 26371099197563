<template>
  <div>
    <b-table
      :data="list"
      icon-pack="fas"

      class="border-blue-lighter text-xs measurements"
      bordered
      narrowed
      striped

      checkable
      :checked-rows.sync="checkedRows"
      @check="handCheck"
      @check-all="handCheckAll"
    >
      <template>

        <b-table-column field="type" :label="$t('type')" sortable v-slot="items">
          {{ $t(items.row.type) }}
        </b-table-column>

        <b-table-column field="code" :label="$t('code')" sortable v-slot="items">
            {{ items.row.code }}
        </b-table-column>

        <b-table-column field="description" :label="$t('name')" sortable v-slot="items">
            {{ items.row.description }}
        </b-table-column>

        <b-table-column field="unit_name" :label="$t('measurement_unit')" sortable v-slot="items">
          <span v-if="items.row.unit_name">{{ items.row.unit_name }}</span>
          <span v-else>---</span>
        </b-table-column>

        <b-table-column field="quantity" :label="$t('quantity')" centered sortable v-slot="items" width="100px">
          <div>
            <b-input
              v-model="items.row['quantity']"
              :type="'text'"
              :size="'is-small'"
              :placeholder="'0'"
              icon-pack="fas"
              :disabled="items.row.selected === undefined ? true : !items.row.selected"
            />
          </div>
        </b-table-column>

        <b-table-column field="weight" :label="$t('weighting')" centered sortable v-slot="items" width="100px">
          <div>
            <b-input
              v-model="items.row.weight"
              :type="'text'"
              :size="'is-small'"
              :placeholder="'0'"
              icon-pack="fas"
              :disabled="items.row.selected === undefined ? true : !items.row.selected"
            />
          </div>
        </b-table-column>

      </template>
    </b-table>
  </div>
</template>

<script>

  import {mapGetters} from "vuex";
  import axios from "axios";

  export default {
    name: "template-list-measurements",

    components: {
    },

    props: {
      list: Array,
      entityId: {type: Number, required: true},
      hideActions: { type: Boolean, default: false },
    },

    data() {
      return {
        checkedRows: [...this.list],
      }
    },

    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },

    watch: { },

    mounted() { },

    beforeDestroy(){ },

    methods: {

      /**
       * Abre el modal para editar una medición
       */
      editEntity(row) {
        this.$emit('epc-edit-measurement', row)
      },

      isTask(row) {
        if (row.type === 'task')
          return true;
      },

      isResource(row) {
        if (row.type === 'resource')
          return true;
      },

      reload(data) {
        this.$emit("reload", data);
      },

      canOpenEditModal(row) {
        if (row.user_id != this.user.id && this.isTask(row)) return false;
        return true;
      },

      handCheck(checkedList, row) {
        if( typeof row !== 'undefined' ) {
          const index = this.indexOf(this.list, row)
          // si se encuentra marcamos como seleccionado o deseleccionada la actividas y por defecto las subactividades asumen la del padre
          if (index >= 0) {
            this.list[index].selected = !this.list[index].selected
            // let parentSelected = this.list[index].selected
            // this.list[index].subactivities.forEach((sub) => {
            //   sub.selected = parentSelected
            // })
          }
        }

        // this.dispatchSelected(checkedList)
      },

      handCheckAll(checkedList) {
        let allUnselected = checkedList.length === 0

        this.list.forEach((measurement) => {
          measurement.selected = !allUnselected
        })
        // this.dispatchSelected(checkedList)
      },

      dispatchSelected(checkedList) {
        this.$emit('selected-items', checkedList)
      },

      indexOf(array, obj) {
      if (!array) return -1
        return array.indexOf(obj)
      },
    }
  };
</script>

<style lang="scss">
  .measurements {
    .table th {
      background-color: #679dbf !important;
    }

    &.b-table .table.is-bordered th.is-current-sort, &.b-table .table.is-bordered th.is-sortable:hover {
      background-color: #679dbf !important;
    }

  }

  .clickable {
    cursor: pointer;
  }
</style>
