<template>
  <b-modal
    :active.sync="isModalActive"
    :width="640"
    scroll="keep"
    :on-cancel="cancelSubmit"
  >
    <div class="flex flex-col p-4 bg-white border rounded shadow border-blue-light">

      <div class="flex flex-col w-full">
        <div class="font-semibold uppercase text-blue">{{ $t(modalTitle) }}</div>
        <div class="text-blue">{{ $t(modalBody) }}</div>
      </div>

      <form @submit.prevent="submitForm">
      <div class="flex flex-col w-full mt-4">
        <div class="flex flex-col">
            <div class="text-xs font-medium text-blue">{{ $t("observations") }}</div>
            <div class="flex flex-row w-full">
              <textarea
                v-model="form.observation"
                title="observation"
                required="true"
                name="observation"
                maxlength="2000"
                :placeholder="$t('observations')"
                class="w-full h-32 p-2 text-xs border rounded bg-grey-lighter border-grey-light"
              />
            </div>
        </div>
        <div class="flex flex-col mt-4">

            <b-field class="file is-primary">
              <b-upload
                v-model="files"
                class="file-label"
                :multiple="true"
                :accept="accept"
              >
                <span class="file-cta">
                  <span class="mr-2 file-label">{{
                    $t("upload_documents")
                  }}</span>
                  <b-icon class="file-icon" icon="upload" pack="fas"></b-icon>
                </span>
              </b-upload>
            </b-field>

              <div class="mt-2 tags">
                <span
                  v-if="filesSelected"
                  v-for="(file, index) in files"
                  :key="index"
                  class="tag is-primary"
                >
                  {{ file.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="removeFile(index)"
                  ></button>
                </span>
              </div>
              <div v-if="isLoading" class="my-4 text-center">
                <scale-loader />
              </div>

        </div>

      </div>

      <div class="flex flex-row items-center justify-start w-full mt-6">
        <button
              @click.prevent="cancelSubmit"
              :disabled="isLoading"
              class="mr-1 btn btn-red"
              type="button"
            >
              {{ $t("cancel") }}
            </button>
            <button class="btn btn-blue" type="submit" :disabled="isLoading">{{ $t("save") }}</button>
      </div>
      </form>

    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import uploadDocument from "./upload-document";
import EventBus from "~/plugins/bus";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import store from "~/store";

export default {
  name: "assignment-completed-modal",

  components: {
    uploadDocument,
    ScaleLoader,
  },

  props: {
    entityId: { type: Number, required: true },
    entityType: { type: String, required: true },
    filterUploadType: {
      type: Object,
      default: () => ({ filter: false }),
      required: false,
    },
  },

  data: () => ({
    isModalActive: false,
    modalTitle: "",
    modalBody: "",
    id: null,
    completed: null,
    isLoading: false,
    data: {},
    form: {},
    files: [],
        cancelRequest: null,
    types: {
      0: "application/*",
      1: "image/*",
      2: "audio/*",
      3: "video/*",
    },
    accept: "", // on created()
    uppy: null, // instancia de la libreria para subir archivos
    keyFileUploadCurrent: null, // key del archivo que se esta subiendo actualmente
    uploadPercentage: 0,
  }),

   created(){

    this.accept = this.$acceptedExtensions // extensiones aceptadas
    /**
     * Establecemos los tipos de archivos que permitimos subir
     * segun los parametros pasados
     */
    if(this.filterUploadType.filter){
      this.accept = "";
      this.filterUploadType.types.forEach(type => {
        this.accept += this.types[type]+",";
      });
    }

    this.uppy = new Uppy({
        debug: process.env.NODE_ENV === 'development', // activamos debug para development
        autoProceed: false,
        allowMultipleUploads: false,
      })
      .use(AwsS3Multipart, {
        limit: 3,
        companionUrl: '/api/v2/',
        companionHeaders: {
          'Authorization': "Bearer " + store.getters["auth/token"]
        }
      })
      // .on('upload-success', (file, response) => { this.onUploadSuccess(file, response) } )
      .on('upload-success', (file, response) => { // callback deveulto cuando el file ha sido subido correctamente
        console.log('%cEPC-TACKER: '+ '%c file ' + file.data.name + ' subido correctamente', 'background: #5577BB; color: #fff', 'color: #000')
      })
      .on('upload-progress', (file, progesss) => { // callback devuelto con el progreso de subido del archivo
        this.uploadPercentage = parseInt(
          Math.round((progesss.bytesUploaded * 100) / progesss.bytesTotal)
        );
      })
      .on('upload-error', (file, error, response) => { // callback devuelto si ha ocurrido algun error en la subida
        console.error('%cEPC-TACKER: '+ '%c a ocurrido un error al subir el archivo ' + file.data.name, 'background: #5577BB; color: #fff', 'color: #000', error)
      })
  },


  computed: {
    ...mapGetters({
      project: "app/project",
    }),
    filesSelected() {
      return this.files.length > 0 || this.files.size > 0;
    },
  },
  methods: {
    onAddClicked() {
      this.$refs.modal.show();
    },
    /**
     * Cancelamos el envio del formulario y refrescamos la lista
     * para quitar check
     */
    cancelSubmit() {
      this.$emit("reload");
      this.hide();
    },
    /**
     * Envía el formulario para completarlo
     */
    async submitForm() {
      let url = "/api/v2/assignment/" + this.id + "/completion";
      let observation = null
      if (this.completed) {
        observation = this.form.observation ? this.form.observation : null
      } else {
        // al descompletar forzamos un comentario para que en la linea de trazabilidad se sepa que pasó, ya que aparecería "ejecutó 0"
        observation = this.form.observation
          ? this.form.observation + ' (descompletar)'
          : '(descompletar)'
      }

      let data = {
        observation: observation,
        completed: this.completed,
      };

      self = this;
      axios
        .post(url, data)
        .then(function (response) {
          if (response.data.error) {
            self.$notify.error(response.data.error);
            this.cancelSubmit();
          } else {
            self.$notify.success("updated_successfully");
            self.$emit("reload");
            EventBus.$emit("refreshActivityDocumentsNotifications", true);
          }
        })
        .catch((error) => {
          self.$notify.error("update_error");
          this.cancelSubmit();
        });

      if (this.filesSelected) {
        await this.uploadFiles();
      }
      this.hide();
      this.$emit("reload");
    },

    removeFile(index) {
      this.files.splice(index, 1);
    },

    async uploadFiles() {
      if (this.files.length == 0) {
        return;
      }

      this.isLoading = true;
      let files = this.files.slice();
      for (var i = files.length - 1; i >= 0; i--) {
        if (files[i].size / 1024 / 1024 > 1024) {
          this.$notify.warning("document_exceeded_max_size", {
            name: files[i].name,
          });
        } else {
          let ok = await this.submitFile(files[i]);
          if (ok) {
            this.files.splice(i, 1);
            this.$notify.success("upload-document_success", {
              files: files[i].name,
            });
          } else {
            this.isLoading = false;
            this.$notify.error("upload-document_error");
            return false;
          }
        }
      }

      // Todos los ficheros subidos
      if (this.files.length == 0) {
        this.isLoading = false;
        this.$notify.success("upload-document_success", {
          files: files.length,
        });
        this.$emit("success");
        return true;
      }
    },

    async submitFile(file) {

      this.uploadPercentage = 0
      this.cancelRequest = axios.CancelToken.source();

      let form = new FormData();

      let customName = "";
      if (file.custom_name) customName = file.custom_name.trim();

      // Si el nombre personalizado después del trim tiene contenido usamos ese, y si no pasamos al del fichero
      form.append(
        "original_filename",
        customName != "" ? customName + this.getFileExtension(file) : file.name
      );
      form.append("entity_type", "assignment");
      form.append("entity_id", this.id);

      form.append("project_id", this.project.id)

      // const { data } = await axios
      //   .post(
      //     "/api/v2/" + this.entityType + "/" + this.entityId + "/document",
      //     form,
      //     {
      //       headers: { "Content-Type": "multipart/form-data" },
      //     }
      //   )
      //   .catch((error) => {
      //     return false;
      //   });
      // return data && data.success;

      return this.store(file, {})
      .then(async result => {

          // successFul contiene toda la lista de archivos que se han subido
          // para nuestro caso solo consideramos siempre el primero por que enviamos de a uno
          if( result.successful.length > 0 ) {
            let response = result.successful[0].response

            let params = {
              // uuid: response.uuid,
              key: response.body.key,
              // bucket: response.bucket,
              name: file.name,
              content_type: file.type,
              // url: response.url
            }

            form.append("aws_response", JSON.stringify(params));

            let resp = null
            resp = axios.post("/api/v2/" + this.entityType + "/" + this.entityId + "/document", form, {
               cancelToken: this.cancelRequest.token,
            });

            return resp && resp.data && resp.data.success ? resp.data.success : false
          }

          // failed contiene todos los archivos que tubieron fallo al subir
          if (result.failed.length > 0) {
            console.error('Errors:')
            result.failed.forEach((file) => {
              console.error(file.error)
            })

            return false
          }
      }).catch((error) => {
        console.log(error);
        return false
      });

    },

    // CUSTOM VAPOR STORE METHOD
    async store(file, options = null) {

      // verificamos si existe algun archivo en la lista de carga de uppy
      // si existe lo eliminamos
      if( this.keyFileUploadCurrent ) {
        this.uppy.removeFile(this.keyFileUploadCurrent);
      }

      if (typeof options.progress === 'undefined') {
          options.progress = () => {};
      }

      this.keyFileUploadCurrent = this.uppy.addFile({
        name: file.name, // file name
        type: file.type, // file type
        data: file, // file blob
        // meta: {
        //   // optional, store the directory path of a file so Uppy can tell identical files in different directories apart.
        //   relativePath: webkitFileSystemEntry.relativePath,
        // },
        source: 'Local',
        isRemote: false,
      });

      // response.data.extension = file.name.split('.').pop()
      return this.uppy.upload()
    },

    /**
     * Muestra este modal
     */
    show(assignment_id, completed) {
      this.id = assignment_id;
      this.completed = completed;
      this.isModalActive = true;

      // Cambiamos texto según estado
      if (completed) {
        this.modalTitle = "decomplete_measurement_title";
        this.modalBody = "decomplete_measurement";
        this.completed = false;
      } else {
        this.modalTitle = "complete_measurement_title";
        this.modalBody = "complete_measurement";
        this.completed = true;

      }
    },

    /**
     * valores por defecto del formulario
     */
    defaultFormValues() {
      return {
        observation: "",
      };
    },

    /**
     * Oculta este modal
     */
    hide() {
      this.id = null;
      this.data = [];
      this.isModalActive = false;
      this.form = this.defaultFormValues();
      //resetamos archivos
      this.files = [];
    },
  },
};
</script>
<style scoped>
/* Oculta los botones para aumentar los inputs numéricos */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
