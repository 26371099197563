<template>
  <div>
    <label class="font-bold">Acción masiva: {{ action.title }}</label>

    <div class="panel">
      <p v-if="action.name === 'assign_project_rules'">La acción asignará {{ linkedEntities.length }} regla de proyecto en {{ validations.length }} flujo(s) seleccionado(s)</p>


      <div class="flex flex-row mt-3 space-x-4">

        <div class="w-1/2">
          <p class="mb-2">Flujos(s) afectado(s)</p>
          <entity-list
              :entities="validations"
              entityKey="id"
              :withImage="false"
              :show="true"
              :showRemoveButton="false"
              :componentName="['name']"
            />
        </div>

        <div v-if="action.name == 'assign_project_rules'" class="w-1/2">
          <p class="mb-2">Regla Seleccionada</p>
          <entity-list
              :entities="linkedEntities"
              entityKey="id"
              :withImage="false"
              :show="true"
              :showRemoveButton="false"
              :componentName="['name']"
            />
        </div>

      </div>
      <div class="mt-8">
        <p class="font-bold">¿Seguro que desea continuar?</p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import Form from 'vform'
import moment from 'moment'
import entityList from '../utils/entity-list.vue';

Vue.use(moment)

export default {
  name: 'wizard-bulk-complete',

  components: {
    entityList
  },

  props: {
    projectId: { type: Number, required: true },
    action: { type: Object, required: true },
    validations: { type: Array, required: true },
    linkedEntities: { type: Array, required: true }
  },

  data: () => ({
  }),

  mounted() {
  },

  created() {
  },

  watch: {

  },

  computed: {
  },

  methods: {
    async sendForm(extraInfo) {
      let url = `/api/v2/validations/templates/bulk-actions`

      // Enviamos el form
      let body = {
        action: this.action.type,
        validations: this.validations.map(v => v.id),
        entities: this.linkedEntities.map(e => e.id),
        extra_info: extraInfo,
        users: []
      }
      const { data } = await axios.post(url, body)
      if (data && data.success) {
        this.$notify.success('bulk_action_added_process')
      } else {
        this.$notify.error('error_bulk_action')
      }
    },
  },
}
</script>
