<template>
  <div class="forge-viewer-container" style="height:65vh">
    <div class="forge-viewer-display relative h-full" :id="id" @resize="handleResize" />
    <slot />
  </div>
</template>

<script>
import { ViewerService } from "./services/ForgeViewerService.js";

export default {
  name: 'viewer-forge',

  props: {
    id: {
      type: String,
      default: function() {
        return "forge-vuer";
      }
    },
    getAccessToken: {
      type: Function,
      required: true
    },
    urn: {
      type: String
    },
    options: {
      type: Object,
      default: () => {}
    },
    headless: {
      type: Boolean,
      default: false
    },
    extensions: {
      type: Object
    }
  },

  watch: {
    urn: function() {
      this.viewerService.LoadDocument(this.urn);
    },
    headless: function() {
      this.viewerService.SetHeadless(this.headless);
    }
  },

  data() {
    return {
      viewerService: null
    }
  },



  mounted: async function() {
    if( !window.Autodesk ) {
      throw new Error(
        'Forge Viewer js missing. Make sure you add it on the HTML header'
      )
    }

    if (typeof this.getAccessToken !== "function")
      throw new Error(
        `The 'getAccessToken' prop needs to be a function implementing a callback passing in the generated token and expire timeout in seconds.`
      );

    this.viewerService = new ViewerService(window.Autodesk, this);
    if (this.extensions && Object.keys(this.extensions).length > 0)
      this.viewerService.SetCustomExtensions(this.extensions);
    // Creating a new instance of the ViewerService
    await this.viewerService.LaunchViewer(
      this.id,
      this.getAccessToken,
      this.options,
      this.headless
    );
    // If a urn is supplied, load it to viewer;
    if (typeof this.urn === "string" && this.urn.trim().length > 0)
      this.viewerService.LoadDocument(this.urn);
  },

  methods: {
    handleResize: function() {
      if (this.viewerService.Viewer3D != null)
        this.viewerService.Viewer3D.resize();
    }
  }
}
</script>

<style>
.forge-viewer-display .adsk-viewing-viewer {
  position: inherit !important;
}
</style>
