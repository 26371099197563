
export const state = {
    mobile: {},
    tablet: {},
}

export const mutations = {
    saveMobile (state, flag) {
      // state.project = Object.assign({}, state.activities, filters)
      state.mobile = flag
    },

    saveTablet (state, flag) {
      // state.validations = Object.assign({}, state.validations, filters)
      state.tablet = flag
    },
}

export const actions = {
    setMobile ({ commit }, payload) {
      commit('saveMobile', payload)
    },

    setTablet ({ commit }, payload) {
      commit('saveTablet', payload)
    },
}

export const getters = {
    mobile: state => state.mobile,
    tablet: state => state.tablet,
}
