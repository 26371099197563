<template>
  <div>
    <div v-if="! this.isIE && ! this.isEdge">
      <form @submit.prevent="login" @keydown="form.onKeydown($event)">
        <l-form-input :form="form" field="email" placeholder="email" inputType="email" size="is-medium" icon="envelope" autocomplete="on" />
        <l-form-input :form="form" field="password" placeholder="password" inputType="password" size="is-medium" icon="unlock" />
        <!--<b-field>-->
          <!--<router-link :to="{ name: 'password.request' }" class="link-white">-->
            <!--{{ $t('forgot_password') }}-->
          <!--</router-link>-->
        <!--</b-field>-->
        <b-field>
          <b-checkbox v-model="remember">{{ $t('remember_me') }}</b-checkbox>
        </b-field>
        <b-field>
          <v-button full :loading="form.busy" native-type="submit">{{ $t('login') }}</v-button>
        </b-field>
        <!--<div class="has-text-centered">-->
        <!--<span class="has-text-grey-light">{{ $t('without_account') }}</span>-->
        <!--<router-link :to="{ name: 'register' }" class="link-white has-text-weight-semibold">-->
        <!--{{ $t('create_an_account') }}-->
        <!--</router-link>-->
        <!--</div>-->
        <b-field>
          <div @click="goToMSEntraID()" class="cursor-pointer">Login con Microsoft Entra ID <i class="fas fa-right-to-bracket"></i></div>
        </b-field>
        <b-field style="text-align:center;">
          <router-link
            :to="{ name: 'password.request' }"
            class="link-white"
          >{{ $t('forgot_password') }}</router-link>
        </b-field>
      </form>
    </div>
    <div v-else>
      <span class="font-bold">{{ $t('internet_explorer_msg') }}</span>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import FirebasePlugin from "~/plugins/firebase";

import { PublicClientApplication } from '@azure/msal-browser';

export default {
  middleware: "guest",
  layout: "login",
  title: "login",

  created () {

    // Internet Explorer 6-11
    this.isIE = /*@cc_on!@*/ false || !!document.documentMode;
    // Edge 20+
    this.isEdge = !this.isIE && !!window.StyleMedia;
  },

  // data: () => ({
  data() {
    return {
      form: new Form({
        email: '',
        password: '',
      }),
      formMSEntraId: new Form({
        email: '',
        hashtoken: null,
      }),
      remember: true,
      isIE: false,
      isEdge: false,
    }
  },

  methods: {

    async login() {
      // Submit the form.
      let data = null

      await this.form.post('/api/v2/login')
        .then(response => (data = response.data))
        .catch(error => console.log('login component: ', error)); // error.data.data

      //const { data } = await this.form.post('api/v2/login')

      if (data) {
        this.afterLogin(data);
      }
    },


    async afterLogin(data) {

      // Save the token.
      this.$store.dispatch("auth/saveToken", {
        token: data.token,
        remember: this.remember
      });

      // Set the user's favorite locale
      this.$store.dispatch('lang/setLocale', data.locale);
      
      // Fetch the user.
      await this.$store.dispatch("auth/fetchUser");

      // Solo entornos demo y usuarios que no hayan visitado el onboarding DEPRECATED
      // let showOnboarding = process.env.MIX_MULTI_CLIENT == "true" && data.user.onboarding == null ? "1" : null

      // Actualiza (si precisa) el token fcm push del dispositivo web (navegador)
      FirebasePlugin.registerFCMWebToken(data);

      // Redirect home.
      //this.$router.push({ name: "project.select", params: { showOnboarding: showOnboarding } });
      this.$router.push({ name: "company.select" });
    },



    async goToMSEntraID() {

      let self = this

      // de microsoft no necesitamos un token, ya que el token de EPC lo debe generar el backend
      // tan solo necesitamos que nos diga que el email introducido existe en un tenant de un cliente
      // y en ese caso buscamos ese email en epctracker y si existe lo logueamos

      this.$msalInstance = new PublicClientApplication(
        this.$store.getters["ms-entra-id/msalConfig"]
      );

      await this.$msalInstance.initialize();
      // let loginRequest = {
      //   scopes: ['openid', 'profile', 'User.Read'],
      // };

      await this.$msalInstance
        .loginPopup({
          // loginRequest // no hace falta
        })
        .then(async (resp) => {

          // si hiciera falta almacenar el token que devuelve microsoft. Quitado porque no nos sirve de nada
          // self.$store.dispatch('ms-entra-id/setAccessToken', resp.accessToken);

          const myAccounts = this.$msalInstance.getAllAccounts();
          if (myAccounts.length == 0) {
            console.log("no ms accounts detected")
            return;
          }
          let account = myAccounts[0];

          // console.log("LOGIN SUCCESSFULL respuesta de msei", account)

          // ok es valido y tengo todos los datos, pues fuerzo su login en epc
          self.formMSEntraId.email = account.username
          // formamos el hash. Todo este codio equivale a: sha256(txt)
          self.formMSEntraId.hashtoken = await crypto.subtle.digest('SHA-256', new TextEncoder('utf-8')
              .encode(self.formMSEntraId.email + 'epct'))
              .then((hashBuffer) => {
                const hashArray = Array.from(new Uint8Array(hashBuffer));
                const hashHex = hashArray
                  .map((bytes) => bytes.toString(16).padStart(2, '0'))
                  .join('');
                return hashHex;
              })

          self.formMSEntraId.post('/api/v2/login-ms')
            .then(async function(response) {

              self.$store.dispatch('ms-entra-id/setLogged', true);
              self.afterLogin(response.data);

            })
            .catch(error => console.log('login component: ', error)); // error.data.data

        })
        .catch(error => {
          console.error(`error during authentication mssss: ${error}`);
        });

    },

  }
};
</script>
