<template>
  <div>
    <div
      :style="'height: ' + height"
      class="google-map"
      :id="mapName">
    </div>
  </div>
</template>
<script>
import EventBus from "~/plugins/bus";

export default {
  name: "google-map",
  props: {
    datamaps: { type: Array, default: null },
    name: { type: String, default: "location" },
    draggable: { type: Boolean, default: true },
    newPlace: { type: String },
    height: { type: String, default: '300px' },
    inputAutocomplete: { type: String, default: "address_id" }
  },
  data: function() {
    return {
      mapName: this.name + "-map",
      map: null,
      place: {
        id: 0,
        name: "",
        address: "",
        latitude: "",
        longitude: ""
      },
      markers: [],
      autocomplete: null,
      tmpMarker: null
    };
  },
  mounted: function() {
    // instanciamos
    var self = this;

    // Recibimos por bus las localizaciones
    EventBus.$on("load-locations", function(locations) {
      self.load();
      self.otherMarkers(locations);
    });

    this.infoWindow = new window.google.maps.InfoWindow({
        content: ""
    });
  },
  methods: {
    load() {
      const element = document.getElementById(this.mapName);
      const options = {
        center: new window.google.maps.LatLng(0, 0),
        zoom: 10,
        mapId: this.mapName,
      };
      this.map = new window.google.maps.Map(element, options);

      //Asociamos el input para autocompletar localizaciones
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById(this.inputAutocomplete),
        {
          types: ["geocode"]
        }
      );
    },

    reload() {
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      if (this.tmpMarker) {
        this.tmpMarker.setMap(null);
      }
      this.markers = [];
      if (this.datamaps) {
        this.otherMarkers(this.datamaps);
      }
    },
    editLocation(index) {
      //Localizamos el marcador que vamos a editar

      this.markers.forEach(marker => {
        if (marker.id == index) {
          marker.setDraggable(true);
          marker.setZIndex(900);

          marker.setIcon("http://ludus.org.es/markers/marker_lgreen.png");
          this.tmpMarker = marker;
        } else {
          marker.setDraggable(false);
          marker.setZIndex(1);

          marker.setIcon();
        }
      });
    },

    otherMarkers(allMarkers) {
      //Creamos límites
      var bounds2 = new google.maps.LatLngBounds();
      var marker, i;
      for (i = 0; i < allMarkers.length; i++) {
        marker = new google.maps.marker.AdvancedMarkerElement({
          position: new google.maps.LatLng(
            allMarkers[i].latitude,
            allMarkers[i].longitude
          ),
          map: this.map,
          gmpDraggable: allMarkers[i].draggable || false,
          id: allMarkers[i].id,
        });

        ///////////////////////////////////
        //Capturamos los eventos de edición
        self = this;
        marker.addListener("dragend", function(e) {
          self.place.latitude = e.latLng.lat();
          self.place.longitude = e.latLng.lng();
          self.$emit("updPlace", self.place);
        });
        ///////////////////////////////////

        // definimos on contenedor para capturar el evento click y puede ser usado
        let el = document.createElement('div')
        el.setAttribute("id", 'epc-content-info')
        el.dataset.projectId = allMarkers[i].project_id

        // contenedor emite evento para ser tratado en el componente padre
        el.addEventListener('click', (ev) => {
          let elDiv = ev.target.closest('#epc-content-info')
          if( elDiv ) {
            this.$emit("onClickPlace", elDiv.dataset.projectId);
          }
        });

        marker.addListener("click", function(e) {
          if (typeof this.name !== 'undefined') {
            self.infoWindow.open(self.map, this);

            let tmpContent = '<strong>'+this.name+'</strong>';
            if (typeof this.location_name !== 'undefined' && this.name != this.location_name) {
              tmpContent += '<br>'+this.location_name;
            }
            if(typeof this.image !== 'undefined' && this.image) {
              tmpContent += '<div style="display: flex; align-items: center; justify-content: center"><img src="'+this.image+'" alt="'+this.name+'" width="200" style="margin: 5px 0px;" ></div>'
            }
            el.innerHTML  = tmpContent

            // el.innerHTML = `<div style='float:left'><img src='http://i.stack.imgur.com/g672i.png'></div><div style='float:right; padding: 10px;'><b>Title</b><br/>123 Address<br/> City,Country</div>`

            self.infoWindow.setContent(el);
          }
        });

        this.markers.push(marker);
        bounds2.extend(
          new window.google.maps.LatLng(
            allMarkers[i].latitude,
            allMarkers[i].longitude
          )
        );
      }
      //Aplicamos Límites tomando en cuenta los marcadores
      this.map.fitBounds(bounds2);
    },

    searchLocation() {
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: this.newPlace }, (results, status) => {
        if (status === "OK") {
          const positionActual = new window.google.maps.LatLng(
            results[0].geometry.location.lat(),
            results[0].geometry.location.lng()
          );
          this.place.latitude = results[0].geometry.location.lat();
          this.place.longitude = results[0].geometry.location.lng();
          this.$emit("updPlace", this.place);

          self = this;
          console.log(this.tmpMarker, "----");
          if (this.tmpMarker && this.tmpMarker.map != null) {
            this.tmpMarker.setPosition(positionActual);
          } else {
            this.tmpMarker = new window.google.maps.Marker({
              position: positionActual,
              map: self.map,
              draggable: true,
              icon: "http://ludus.org.es/markers/marker_lgreen.png"
            });
            this.tmpMarker.addListener("dragend", function(e) {
              self.place.latitude = e.latLng.lat();
              self.place.longitude = e.latLng.lng();
              self.$emit("updPlace", self.place);
            });
          }
          this.map.setZoom(5);
          this.map.panTo(positionActual);
        }
      });
    }
  },
  watch: {
    newPlace: function() {
      this.searchLocation();
    }
  }
};
</script>


<style scoped>
  .google-map {
    width: 100%;
    /* height: 300px; */
    margin: 0 auto;
    background: gray;
  }
</style>

