<template>
  <div>
    <div
      class="google-map w-full"
      ref="googleMap"
      style="height: 400px;"
    ></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot :google="google" :map="map"/>
    </template>
  </div>
</template>

<script>
  import GoogleMapsApiLoader from "google-maps-api-loader";
  import EventBus from "~/plugins/bus";


  export default {
    props: {
      mapConfig: Object,
      mapStyle: Object,
    },

    data() {
      return {
        google: null,
        map: null,
      };
    },

    async created() {
      const googleMapApi = await GoogleMapsApiLoader({
        libraries: ['drawing', 'marker'],
        apiKey: process.env.MIX_GMAPS_API_KEY,
      });
      this.google = googleMapApi;
      this.initializeMap();

      let drawingMapper = new this.google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: true,
        drawingControlOptions: {
          position: this.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ['polygon']
        },
        polygonOptions: {
          fillColor: '#FF0000',
          fillOpacity: 0.5,
          strokeWeight: 2,
          clickable: true,
          editable: true,
          zIndex: 1
        }
      });

      drawingMapper.setMap(this.map);

      this.google.maps.event.addListener(drawingMapper, 'polygoncomplete', function (polygon) {
        EventBus.$emit('polygoncomplete', polygon.getPath());
      });

    },

    mounted() {

      EventBus.$on('center-map', (markers) => this.centerMap(markers));

    },

    methods: {
      initializeMap() {
        const mapContainer = this.$refs.googleMap;
        this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
      },
      centerMap(markers) {

        // Creamos los limites
        let bounds = new this.google.maps.LatLngBounds();

        // Recorremos los marcadores y extendemos los limites
        markers.map(function (marker) {
          bounds.extend({lat: marker.properties.coords.latitude, lng: marker.properties.coords.longitude});
        });

        // Centramos el map
        this.map.fitBounds(bounds);

      }
    },
  };
</script>
