import Vue from "vue";
import store from "~/store";
import router from "~/router";
import i18n from "~/plugins/i18n";
import App from "~/components/App";

import "~/plugins";
import "~/components";


Vue.config.productionTip = false;

new Vue({
  i18n,
  store,
  router,
  ...App,
});

// Global Events Hub
Vue.prototype.$eventHub = new Vue();
// Para todos los b-upload, extensiones soportadas
Vue.prototype.$acceptedExtensions = ".7z, .zip, .txt, .rar, .doc, .docx, .xls, .xlsx, .pdf, .eml, .dwg, .msg, .bc3, .mpp, .presto, .prestoobra, application/*, image/*, audio/*, video/*, .ifc, .rvt, .nwd, .nwf, .nwc, .xml, .dcf, .dcfx, .ppt, .pptx, .mdb, .accdb, .mpp, .mpt, .mov, .ipt"
