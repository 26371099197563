<template>
  <div id="viewer-spinner" class="viewer-spinner" :style="{ display: isLoading ? 'flex' : 'none' }">
    <div class="viewer-spinner__overlay"></div>

    <div class="w-full" style="display: none;">
        <div class="w-full text-center upload__title"></div>
    </div>

    <div class="upload__spinner-row">
        <div class="viewer__loader">
            <svg class="loader__track" viewBox="25 25 50 50">
                <circle class="loader__moving" cx="50" cy="50" r="20"
                  fill="none"
                  stroke-width="0.6"
                  stroke-miterlimit="10"
                  stroke="#5577bb"></circle>
            </svg>

            <div class="loader__content loader__loading">
              {{ loaderText }}
              <span v-show="withDot" class="dot-one"> .</span>
              <span v-show="withDot" class="dot-two"> .</span>
              <span v-show="withDot" class="dot-three"> .</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "ViewerSpinnerLoader",

    props: {
      isLoading: { type: Boolean, default: false },
      customLoaderText: { type: String, default: null },
      withDot: { type: Boolean, default: false }
    },

    watch: {
    },

    data() {
      return {
        // animation_rotate: `${this.$style["rotate"]} 4s linear infinite`,
        loaderText: this.customLoaderText ? this.customLoaderText : this.$t('loading')
      }
    },

    watch: {
      customLoaderText(newValue, oldValue) {
        this.loaderText = newValue
      }
    },

    mounted() {

    },

    methods: {
      SetTextMessage(text) {
        this.loaderText = text
      }
    }
  }

</script>

<style scoped>
  #viewer-spinner {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    align-items: center;
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    z-index: 29;
  }

  .viewer-spinner__overlay {
    /* height: 100%;
    width: 100%; */
    background: #fff;
    opacity: .9;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .upload__title {
    margin: 70px;
    height: 84px;
  }

  .upload__spinner-row {
    height: 280px;
    position: relative;
  }
  @media only screen and (max-width: 767px) {
    .upload__spinner-row {
        height: 180px;
    }
  }
  .viewer__loader {
    width: 280px;
    height: 280px;
    border: solid 5px rgba(0,0,0,.1);
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  @media only screen and (max-width: 767px) {
    .loader {
        width: 180px;
        height: 180px;
    }
  }
  svg:not(:root) {
    overflow: hidden;
  }
  .loader__track {
    height: 344px;
    width: 344px;
    transform-origin: center center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -172px 0 0 -172px;
    animation: rotate 4s linear infinite;
  }
  @media only screen and (max-width: 767px) {
    .loader__track {
        height: 220px;
        width: 220px;
        margin: -110px 0 0 -110px;
    }
  }
  @keyframes rotate {
    100% { transform: rotate(360deg) }
  }
  .loader__moving {
    stroke-dasharray: 1,400;
    stroke-dashoffset: 0;
    animation: dash 3s ease-in-out infinite;
    stroke-linecap: round;
  }
  @media only screen and (max-width: 767px) {
    .loader__moving {
        stroke-width: 1;
    }
  }
  @keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
  }
  .loader__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .loader__loading {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #0a131c;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  span[class^="dot-"]{
  opacity: 0;
}
.dot-one{
  animation: dot-one 2s infinite linear
}
.dot-two{
  animation: dot-two 2s infinite linear
}
.dot-three{
  animation: dot-three 2s infinite linear
}
@keyframes dot-one{
  0%{
    opacity: 0;
  }
  15%{
    opacity: 0;
  }
  25%{
    opacity: 1;
  }
  100%{
    opacity: 1;
  }
}

@keyframes dot-two{
  0%{
    opacity: 0;
  }
  25%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 1;
  }
}

@keyframes dot-three{
  0%{
    opacity: 0;
  }
  50%{
    opacity: 0;
  }
  75%{
    opacity: 1;
  }
  100%{
    opacity: 1;
  }
}


</style>
