<template>
  <b-modal :active.sync="isDialogActive" has-modal-card aria-role="dialog" aria-modal>
    <div class="modal-card w-auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Ratio</p>
      </header>

      <section class="modal-card-body">

        <b-input v-model="inputValue" :placeholder="$t('budget_placeholder')"></b-input>

        <label for="customSelect">{{ $t('select') }}:</label>
        <b-select v-model="selectedOption" placeholder="partners" expanded>
          <option v-for="option in options" :key="option.id" :value="option.id">
            {{ option.name }}
          </option>
        </b-select>

      </section>

      <footer class="modal-card-foot">
        <b-button type="is-primary" icon-pack="fas" icon="check" :disabled="!inputValue || !selectedOption" @click="confirmDialog">Guardar</b-button>
        <b-button icon-pack="fas" icon="xmark" @click="cancelDialog">Cancelar</b-button>
      </footer>

    </div>
  </b-modal>
</template>

<script>
export default {

  data() {
    return {
      isDialogActive: false,
      inputValue: '',
      selectedOption: null,
      options: [],
    };
  },

  methods: {

    show(options) {
      this.options = options;
      this.isDialogActive = true;
    },

    confirmDialog() {
      this.isDialogActive = false;

      // callback to parent
      this.$parent.handleSavePrompt({inputValue: this.inputValue, selectedOption: this.selectedOption})

    },

    cancelDialog() {
      this.isDialogActive = false;
    },
  },
};
</script>
