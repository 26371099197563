<template>
<div class="flex-col">

  <!-- Panel 1 -->
  <div class="w-full mt-8 panel">
    <div class="flex flex-row w-full text-blue font-bold">Avance proyectado/ejecutado</div>
    <div class="flex flex-row w-full"><line-chart :data="panel1" /></div>
  </div>

  <!-- Panel 2 -->
  <div class="w-full mt-8 panel">
    <div class="flex flex-row w-full text-blue font-bold">Capítulos</div>
    <div class="flex flex-row w-full"><column-chart :data="panel2" :library="{ tooltips: { enabled: true, mode: 'single', callbacks: { label: tooltipCallback } } }" height="500px"/></div>
  </div>
</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import VueChartkick from 'vue-chartkick'
import Chart from 'chart.js'
import moment from 'moment'

Vue.use(VueChartkick, {adapter: Chart})
Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'project_home_page_title',
  subtitle: 'project_home_page_subtitle',
  menuOption: '2-1',

  components: {
    ScaleLoader,
  },

  data: () => ({
    filters: {
      location_id: 0,
      status_id: 1,
      tag_name: '',
    },
    panel1: null,
    panel2: null
  }),

  computed: {
    ...mapGetters({
      project: 'app/project',
      user: 'auth/user',
    }),
  },

  mounted() {

    if (this.project == null) {

      this.$router.push('/projects');

    } else {
      this.getPanel1();
      this.getPanel2();

      let pageInfo = {
        title: this.project.name,
        subtitle: this.$t('project_home_page_subtitle'),
        menuOption: '2-1',
      }

      this.$store.dispatch('app/updatePageInfo', {info: pageInfo})
    }
  },

  methods: {
    async getPanel1() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/etapspanel1';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel1 = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async getPanel2() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/etapspanel2';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel2 = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    tooltipCallback(tooltipItem) {
      let item = this.panel2[tooltipItem.datasetIndex].data[tooltipItem.index]
      let list = [`TOTAL: ${item[1]}`]
      if (item[2].length) {
        list.push(' ')
        item[2].map(child => {
          list.push(`${child.name}: ${child.value}`)
        });
      }

      return list
    }
  },

};
</script>
