import { render, staticRenderFns } from "./activity-groups-import.vue?vue&type=template&id=a3dd396e"
import script from "./activity-groups-import.vue?vue&type=script&lang=js"
export * from "./activity-groups-import.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports