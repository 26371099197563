<template>
    <div>
        <b-collapse class="card filters-collapse" :open.sync="showFilters">
            <div slot="trigger" class="card-header filters-trigger">
                <p class="card-header-title">
                    {{ $t('filters') }}
                </p>
                <a class="card-header-icon">
                    <b-icon pack="fa" v-show="!showFilters" icon="plus-circle" />
                    <b-icon pack="fa" v-show="showFilters" icon="minus-circle" />
                </a>
            </div>
            <div class="panel" style="border-width: 0">
                <div class="flex items-center mb-4">
                    <div class="w-1/4 mr-4">
                        <b-field :label="$t('search_by_code')">
                            <b-input v-model="filterCode" :placeholder="$t('code')" type="search" icon="search"
                                icon-pack="fas" @input="checkInputTextFilter">
                            </b-input>
                        </b-field>
                    </div>
                    <div class="w-1/4 mr-4">
                        <b-field :label="$t('search_by_name')">
                            <b-input v-model="filterName" :placeholder="$t('name')" type="search" icon="search"
                                icon-pack="fas" @input="checkInputTextFilter">
                            </b-input>
                        </b-field>
                    </div>
                    <div class="w-1/4 mr-4">
                        <b-field :label="$t('search_by_provider')">
                            <b-select v-model="selectedProvider" :placeholder="$t('select_provider')" icon="store"
                                icon-pack="fas" expanded @input="selectProvider">
                                <option v-for="(provider) in providers" :value="provider.id" :key="provider.id">
                                    {{ provider.name }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                </div>

                <button class="button is-primary btn-blue" @click="cleanFiltersClicked">{{ $t('clean_filters')
                    }}</button>
            </div>
        </b-collapse>
    </div>
</template>

<script>
export default {
    name: 'billing-filters',

    props: {
        projectId: { type: Number, required: true },
        providers: { type: Array, required: true }
    },
    data() {
        return {
            showFilters: false,
            filterCode: null,
            filterPrestoCode: null,
            filterName: null,
            selectedProvider: null,
            ajaxTimerProducts: null
        };
    },
    methods: {
        generateFilters() {
            const params = {};
            if (this.filterCode) params.code = this.filterCode;
            if (this.filterPrestoCode) params.presto_code = this.filterPrestoCode;
            if (this.filterName) params.name = this.filterName;
            if (this.selectedProvider) params.provider = this.selectedProvider;
            return params;
        },

        applyFilters() {
            const args = this.generateFilters();
            this.$emit('filters-updated', args);
        },

        cleanFiltersClicked() {
            this.filterCode = null;
            this.filterPrestoCode = null;
            this.filterName = null;
            this.selectedProvider = null;
            this.applyFilters();
        },

        checkInputTextFilter() {
            clearTimeout(this.ajaxTimerProducts);
            this.ajaxTimerProducts = setTimeout(() => {
                this.applyFilters();
            }, 500);
        },

        selectProvider() {
            this.applyFilters();
        }
    },
    watch: {
        filterCode() {
            this.checkInputTextFilter();
        },
        filterPrestoCode() {
            this.checkInputTextFilter();
        },
        filterName() {
            this.checkInputTextFilter();
        },
        selectedProvider() {
            this.applyFilters();
        }
    }
};
</script>

<style>
.filters-collapse {
    margin-top: 15px;
    margin-bottom: 15px;
}

.filters-trigger:hover {
    background-color: #f8fafc;
}
</style>
