<template>
  <div>
    <b-table
      :data="list"
      icon-pack="fas"

      class="border-blue-lighter text-xs measurements"
      bordered
      narrowed
      striped
    >
      <template>

        <b-table-column field="type" :label="$t('type')" sortable v-slot="items">
          {{ $t(items.row.type) }}
        </b-table-column>

        <b-table-column field="code" :label="$t('code')" sortable v-slot="items">
            {{ items.row.code }}
        </b-table-column>

        <b-table-column field="description" :label="$t('name')" sortable v-slot="items">
            {{ items.row.description }}
        </b-table-column>

        <b-table-column field="unit_name" :label="$t('measurement_unit')" sortable v-slot="items">
          <span v-if="items.row.unit_name">{{ items.row.unit_name }}</span>
          <span v-else>---</span>
        </b-table-column>

        <b-table-column field="weight" :label="$t('weighting')" centered sortable v-slot="items">
          {{ items.row.weight }}
        </b-table-column>

        <b-table-column
          field="actions"
          :label="$t('actions')"
          centered
          width="80" v-slot="props"
          :visible="hideActions"
        >
          <div class="w-full h-full flex items-center justify-around">
            <b-tooltip :label="$t('edit')" position="is-left">
              <div @click="editEntity(props.row)" class="cursor-pointer flex text-blue">
                <b-icon pack="fas" icon="pencil-alt" />
              </div>
            </b-tooltip>

            <b-tooltip
              :label="$t('delete')"
              type="is-danger"
              position="is-left"
            >
              <div @click="removeEntity(props.row)" class="cursor-pointer flex text-red">
                <b-icon pack="fas" icon="trash-alt" />
              </div>
            </b-tooltip>
          </div>
        </b-table-column>

      </template>

    </b-table>
  </div>
</template>

<script>

  import {mapGetters} from "vuex";
  import axios from "axios";

  export default {
    name: "template-list-measurements",

    components: {
    },

    props: {
      list: Array,
      entityId: {type: Number, required: true},
      hideActions: { type: Boolean, default: false },
    },

    data: () => ({

    }),

    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },

    watch: { },

    mounted() { },

    beforeDestroy(){ },

    methods: {

      /**
       * Abre el modal para editar una medición
       */
      editEntity(row) {
        this.$emit('epc-edit-measurement', row)
      },

      async removeEntity(measurement) {
        this.$dialog.confirm({
          title: this.$t("delete_measurement"),
          message: this.$t("delete_measurement_confirm_text", [measurement.description]),
          confirmText: this.$t("delete_measurement"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            this.isLoading = true;
            let url = "/api/v2/measurement/templates/" + measurement.id + "/delete";
            const { data } = await axios.post(url);

            if (data.success) {
              this.$toast.open({
                message: this.$t("removed_successfully"),
                type: "is-success",
                position: "is-top-right"
              });

              // TODO: Cuando se trabaja de manera concurrente no es eficiente, mejor  usar reaload
              let index = this.list.findIndex( (item) => { return item.id == measurement.id } );
              if( index > -1 )
                this.list.splice(index, 1);
              this.isLoading = false;

              // await this.sleep(1000);
              // this.reload(); //Descomentar si se quiere volver a recargar todo haciendo llamada desde el servidor
            } else {
              this.isLoading = false;
              // Error
              this.$toast.open({
                message: this.$t("remove_error"),
                type: "is-danger",
                position: "is-top-right"
              });
            }
          }
        });
      },

      isTask(row) {
        if (row.type === 'task')
          return true;
      },

      isResource(row) {
        if (row.type === 'resource')
          return true;
      },

      reload(data) {
        this.$emit("reload", data);
      },

      canOpenEditModal(row) {
        if (row.user_id != this.user.id && this.isTask(row)) return false;
        return true;
      }

    }
  };
</script>

<style lang="scss">
  .measurements {
    .table th {
      background-color: #679dbf;
    }

    &.b-table .table.is-bordered th.is-current-sort, &.b-table .table.is-bordered th.is-sortable:hover {
      background-color: #679dbf !important;
    }

  }

  .clickable {
    cursor: pointer;
  }
</style>
