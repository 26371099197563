<template>
    <section>
        <div class="panel">
            <div class="w-full mb-4">
                <div class="flex w-full">
                    <p class="content">
                        <b>{{ $t('order_field') }}</b>
                    </p>
                </div>
                <div class="flex w-full">
                    <b-radio v-model="orderField" native-value="reference_code">
                        {{ $t('reference_code') }}
                    </b-radio>
                    <b-radio v-model="orderField" native-value="updated_at">
                        {{ $t('date') }}
                    </b-radio>
                </div>
            </div>
            <div class="w-full mb-4">
                <div class="flex w-full">
                    <p class="content">
                        <b>{{ $t('order_dir') }}</b>
                    </p>
                </div>
                <div class="flex w-full">
                    <b-radio v-model="orderDir" native-value="asc">
                        {{ $t('ascending') }}
                    </b-radio>
                    <b-radio v-model="orderDir" native-value="desc">
                        {{ $t('descending') }}
                    </b-radio>
                </div>
            </div>
            <div class="flex w-full">
                <v-button type="button" color="green" icon="file-export" @click="exportMachineries">{{ $t('export') }}</v-button>
            </div>
        </div>
    </section>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import moment from 'moment'

Vue.use(moment)

export default {
  name: 'export-collaborators',

  props: {
    partnerId: { type: Number, required: true },
  },

  data: () => ({
    orderField: 'reference_code',
    orderDir: 'asc'
  }),

  methods: {
    exportMachineries() {

      let params = {
        partnerId: this.partnerId,
        orderDir: this.orderDir,
        orderField: this.orderField,
      }

      var self = this

      axios({
        method: 'get',
        url: '/api/v2/export/collaborators',
        responseType: 'blob',
        params: params
        })
        .then(function (response) {
          self.$notify.success('success_export');
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = self.$t('collaborators') + '.xlsx';
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          self.$notify.error('error_export')
          console.log(error)
        })
    },

  },
};

</script>