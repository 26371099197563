<template>
  <div class="page-with-top-bar">
    <project-admin-quick-buttons hiddenOption="extrafields"/>
    <section>
      <div class="mt-4">
        <b-tabs type="is-boxed" v-model="mainActiveTab">
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="wrench"></b-icon>
              <span> {{ $t('extrafields') }} </span>
            </template>
            <project-extrafields-manual
              ref="project_extra_fields_manual"
              :projectId="projectId"
            ></project-extrafields-manual>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-import"></b-icon>
              <span>{{ $t('import_extrafields') }}</span>
            </template>
            <div>
              <import-entities
                :can-overwrite="true"
                :project-id="projectId"
                entity-type="extrafields"
                @success="extraFieldsImportSuccess"
              />
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import ExportAssignments from "../../components/export-assignments";
  import ProjectExtrafieldsManual from "../../components/project-extrafields-manual";
  import ImportEntities from "../../components/import-entities";
  import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons'

  export default {
    middleware: "auth",
    title: "extrafields_page_title",
    menuOption: "0-2",

    components: {
      ImportEntities,
      ProjectAdminQuickButtons,
      ProjectExtrafieldsManual,
      ExportAssignments
    },

    computed: {
      ...mapGetters({
        navData: "app/navData",
      }),
      projectId() {
        return parseInt(this.navData.project_id);
      }
    },

    data: () => ({
      mainActiveTab: 0
    }),

    watch: {
      mainActiveTab(value) {
        if (value == 0) {
          // no aplicable
          //this.$refs.project_assignments_manual.reload();
        }
      }
    },

    created() {
      let projectName = this.navData.project_name
        ? this.navData.project_name.toUpperCase()
        : "";
      this.$options.subtitle = ["project_actions_page_subtitle", [projectName]];
    },

    methods: {
      extraFieldsImportSuccess() {
        this.$refs.project_extra_fields_manual.reload();
      }
    }
  };
</script>
