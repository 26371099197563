<template>
  <b-modal
    :active.sync="isModalActive"
    :width="640"
    scroll="keep"
    :on-cancel="cancelSubmit"
  >
    <div class="flex flex-col p-4 bg-white border rounded shadow border-blue-light">

      <div class="flex flex-col w-full">
        <div class="font-semibold uppercase text-blue">{{ $t('define_scale_of_drawing') }}</div>
      </div>

      <form @submit.prevent="submitForm">
      <div class="flex flex-col w-full mt-4">

        <div class="flex flex-col">
          <b-radio v-model="form.type_scale" native-value="custom_scale">
            <div class="text-xs- font-medium text-blue">{{ $t('scale') }}</div>
          </b-radio>

          <div class="flex flex-row justify-between w-full mt-4" v-show="form.type_scale === 'custom_scale'">
            <!-- campo unidades -->
            <!-- esta linea la editamos de momento para que permita ingresar progreso -->
            <!-- :disabled="data.completed || !data.total_units || (existsLastLine && !lastLine.units)" -->
            <div class="flex flex-col w-1/3">
              <div class="flex flex-row items-center">
                <b-numberinput
                  v-model="form.scale_drawing"
                  size="is-small"
                  :step="parseFloat(form.precision)"
                  icon-pack="fas"
                  controls-position="compact"></b-numberinput>
              </div>
            </div>

            <!-- campo largo -->
            <!-- esta linea la editamos de momento para que permita ingresar progreso -->
            <!-- :disabled="data.completed || !data.length  || (existsLastLine && !lastLine.length)" -->
            <div class="flex flex-col w-1/3 ml-2">
              <b-field class="mr-2">
                <b-select v-model="form.unit_drawing" size="is-small" icon="sort">
                  <option value="in">in</option>
                  <option value="mm">mm</option>
                  <option value="cm">cm</option>
                  <option value="pt">pt</option>
                </b-select>
              </b-field>
            </div>

            <!-- campo ancho -->
            <!-- esta linea la editamos de momento para que permita ingresar progreso -->
            <!-- :disabled="data.completed || !data.width  || (existsLastLine && !lastLine.width)" -->
            <div class="flex flex-col w-1/3">
              <div class="flex flex-row items-center">
                <b-numberinput
                  v-model="form.scale_real"
                  :step=" parseFloat(form.precision) "
                  size="is-small"
                  controls-position="compact"
                  icon-pack="fas"></b-numberinput>
              </div>
            </div>

            <!-- campo alto -->
            <!-- esta linea la editamos de momento para que permita ingresar progreso -->
            <!-- :disabled="data.completed || !data.height || (existsLastLine && !lastLine.height)" -->
            <div class="flex flex-col w-1/3 ml-2">
              <b-field class="mr-2">
                <b-select v-model="form.unit_real" size="is-small" icon="sort">
                  <option value="in">in</option>
                  <option value="mm">mm</option>
                  <option value="cm">cm</option>
                  <option value="pt">pt</option>
                  <option value="ft">ft</option>
                  <option value="ft-in">ft-in</option>
                  <option value="m">m</option>
                  <option value="yd">yd</option>
                  <option value="km">km</option>
                  <option value="mi">mi</option>
                </b-select>
              </b-field>
            </div>
          </div>

          <div class="mt-4" v-if="false">
            <b-radio v-model="form.type_scale" native-value="preset_scale">
              <div class="text-xs font-medium text-blue">Preestablecido</div>
            </b-radio>
          </div>

          <div class="flex flex-row justify-between w-full mt-4"  v-if="false" v-show="form.type_scale === 'preset_scale'">
            <b-field>
              <b-select v-model="form.pre" size="is-small" rounded icon="file">>
                <option value="1:10">1:10</option>
                <option value="1:20">1:20</option>
                <option value="1:50">1:50</option>
                <option value="1:100">1:100</option>
                <option value="1:200">1:200</option>
                <option value="1:500">1:500</option>
                <option value="1:1000">1:1000</option>
              </b-select>
            </b-field>
          </div>

          <div class="flex flex-row justify-between w-full mt-4">
            <b-field :label="$t('precision')">
              <b-select v-model="form.precision" size="is-small" icon="file">>
                <option value="0.1">0.1</option>
                <option value="0.01">0.01</option>
                <option value="0.001">0.001</option>
                <option value="0.0001">0.0001</option>
              </b-select>
            </b-field>
          </div>
        </div>

      </div>

      <div class="flex flex-row items-center justify-end w-full mt-6">
        <button
              @click.prevent="cancelSubmit"
              :disabled="isLoading"
              class="mr-1 btn btn-red"
              type="button"
            >
              {{ $t("cancel") }}
            </button>
            <button class="btn btn-blue" type="submit" :disabled="isLoading">{{ $t("save") }}</button>
      </div>
      </form>

    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import EventBus from "~/plugins/bus";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  name: "measure-scale-modal",

  components: {
    ScaleLoader,
  },

  props: {
    projectId: { type: Number, required: true }
  },

  data: () => ({
    isModalActive: false,
    id: null,
    isLoading: false,
    document: null,
    form: { },
  }),

  created(){
  },


  computed: {
  },

  methods: {
    /**
     * Cancelamos el envio del formulario y refrescamos la lista
     * para quitar check
     */
    cancelSubmit() {
      this.$emit("reload");
      this.hide();
    },
    /**
     * Envía el formulario para completarlo
     */
    async submitForm() {
      let url = "/api/v2/project/"+ this.projectId +"/document/" + this.id + "/drawing-scale";
      let data = {
        scale: {
          Type: this.form.type_scale,
          Scale: [[this.form.scale_drawing, this.form.unit_drawing], [this.form.scale_real, this.form.unit_real]],
          Precision: this.form.precision
        },
      };

      self = this;
      axios
        .post(url, data)
        .then(function (response) {
          if (response.data.error) {
            self.$notify.error(response.data.error);
            this.cancelSubmit();
          } else {
            self.$notify.success("updated_successfully");
            self.$emit("reload");
          }
        })
        .catch((error) => {
          self.$notify.error("update_error");
          this.cancelSubmit();
        });

      this.hide();
      this.$emit("reload");
    },

    /**
     * Muestra este modal
     */
    show(document) {
      this.id = document.document_id;
      this.document = document

      if( document.scale != null ) {
        this.form = {
          type_scale: 'custom_scale',
          scale_drawing: document.scale.Scale[0][0],
          unit_drawing: document.scale.Scale[0][1],
          scale_real: document.scale.Scale[1][0],
          unit_real: document.scale.Scale[1][1],
          precision: document.scale.Precision
        };
      } else {
        this.form = this.defaultFormValues()
      }

      this.isModalActive = true;
    },

    /**
     * valores por defecto del formulario
     */
    defaultFormValues() {
      return {
        type_scale: 'custom_scale',
        scale_drawing: 1.0,
        unit_drawing: 'cm',
        scale_real: 1.0,
        unit_real: 'm',
        precision: 0.1
      };
    },

    /**
     * Oculta este modal
     */
    hide() {
      this.id = null;
      this.data = [];
      this.isModalActive = false;
      this.form = this.defaultFormValues();
    },
  },
};
</script>
<style scoped>
/* Oculta los botones para aumentar los inputs numéricos */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
