<template>
  <section>
    
  </section>
</template>

<script>
import axios from "axios";

export default {
  middleware: "auth",
  title: "select_group_page_title",
  subtitle: "select_group_page_subtitle",
  menuOption: "2-0",

  data: () => ({
    companies: null,
    isLoading: true,
    search: '',
  }),

  created() {
    // componente cuyo unico proposito es redirigir a 'group.select' 
    // para evitar el "Avoided redundant navigation to current location"
    // si ya estamos en esta misma ruta 'group.select', pues apuntamos a group.preselect
    this.$router.push({ name: "group.select" });
  },

  methods: {
  }
};
</script>
