<template>
<div>
  <div class="panel p-2">
    <div class="flex items-center justify-between w-full mb-5">
      <div><label><b>{{ entity && entity.id ? 'Editar' : 'Crear' }} actividad: </b></label></div>
      <div>
        <b-tooltip :label="$t('close')" position="is-left">
          <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
            <b-icon pack="fas" icon="times" size="is-small" />
          </div>
        </b-tooltip>
      </div>
    </div>

    <div class="w-full pb-3">
      <div class="mt-4">
        <l-form-input ref="activityCode"
          :form="form"
          field="code"
          label="code"
        />
      </div>

      <div class="mt-4">
        <l-form-input ref="activityName"
          :form="form"
          field="name"
          label="activity_name"
        />
      </div>

      <div class="mt-4">
        <label for="weight" class="label">
          {{ $t('weight') }}
          <span class="text-red text-xs font-normal" v-show="!isSubactivity">{{ $t('only_subactivities') }}</span>
        </label>
        <l-form-input
          v-model="form.weight"
          ref="weight"
          :form="form"
          field="weight"
          :disabled="!isSubactivity"
        />
      </div>

      <!-- Descripción -->
        <div class="flex items-center mt-4">
          <l-form-input
            :form="form"
            field="description"
            label="activity_description"
            input-type="textarea"
            :maxlength="2000"
            class="w-full"
          />
        </div>

        <!-- ETIQUETAS -->
        <div class="w-full items-center">
          <span class="label">{{ $t('activity_tags') }}</span>
          <model-tags
            :model-id="isSubactivity ? parseInt(subactivityId) : parseInt(activityId)"
            model-type="activity/templates"
            :allow-edit="false"
            :add-from-list="true"
            :parent-model-id="projectId"
            parent-model-type="project"
            :initialParentTags="storeTags"
            ref="model_tags"
          />
        </div>
    </div>

    <div class="flex items-center justify-end w-full">
      <form @submit.prevent="sendActivityData" @keydown="form.onKeydown($event)" v-if="!processingData"
        class="w-full flex items-center justify-end mb-2 mt-2">
        <v-button :disabled="disableSaveButton" type="submit" icon="thumbs-up" icon-pack="far" class="mr-4">{{ $t('save') }}</v-button>
        <v-button color="grey" icon="undo-alt" @click="onCloseClicked">{{ $t('cancel') }}</v-button>
      </form>

      <v-button-processing v-if="processingData" />
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import Form from "vform";
import axios from "axios";
import ModelTags from "../../../components/model-tags";

export default {
  name: "template-activity-edit",

  components: {
    ModelTags
  },

  data: () => ({
    activityId: null,
    subactivityId: null,
    disableSaveButton: false,
    processingData: false,

    form: new Form({  // data activity or subactivity of template project
      id: null,
      parent_id: null,
      project_id: null,
      code: '',
      name: '',
      description: '',
      tags: '',
      weight: null,
    }),

  }),

  props: {
    projectId: { type: Number, required: true },
    entity: { type: Object, default: null },
  },

  watch: {
  },

  computed: {
    ...mapGetters({
      storeProject: 'template/project',
      storeTags: 'template/tags',
    }),

    isSubactivity(){
      return this.entity && this.entity.parent_id
    },
  },

  mounted() {
    if( this.entity ) {
      this.form.id = this.entity.id
      this.form.parent_id = this.entity.parent_id
      this.form.project_id = this.entity.project_id
      this.form.code = this.entity.code
      this.form.name = this.entity.name
      this.form.description = this.entity.description
      this.form.weight = this.entity.weight

      this.$refs.model_tags.initTags(this.entity.tagged.map( function(tag) { return { name: tag.tag_name } }))

    }
  },

  methods: {
    async getActivityData() {
      let id = this.isSubactivity ? this.subactivityId : this.activityId
      const { data } = await axios.get('/api/v2/activity/templates/' + id, {params: { with_statuses: 1 }})
      if (data && data.success) {
        this.$refs.model_tags.initTags(data.data.tags)
        this.form.name = data.data.name
        this.form.description = data.data.description
        this.form.weight = data.data.weight

      } else {
        this.$notify.error('error_loading_activity_data')
      }
    },

    async sendActivityData() {
      this.processingData = true
      var url = '/api/v2/activity/templates';
      let isCreate = ! this.form.id // si id es null es crear la actividad/subactividad

      if( isCreate )
        this.form.project_id = this.projectId

      url += (! isCreate ? ('/' + this.form.id) : '') // si id de actividad nos es null editamos

      // Enviamos el form
      const { data } = await this.form.post(url)
      if (data && data.success) {

        await this.$refs.model_tags.syncTagsToModel(isCreate ? data.activity_id : data.activity.id)

        if (this.form.parent_id) {
          this.$notify.success(isCreate ? 'success_creating_subactivity' : 'success_editing_subactivity')
        } else {
          this.$notify.success(isCreate ? 'success_creating_activity' : 'success_editing_activity')
        }

        if( data.warning )
          this.$notify.warning(data.warning)

        this.processingData = false
        this.$emit('epc-success-creating', this.entity)

      } else {
        this.processingData = false
        if( data && data.message )
          this.$notify.error(data.message)
        else
          this.$notify.error(this.form.parent_id ? 'error_creating_subactivity' : 'error_creating_activity')

      }
    },

    onCloseClicked() {
      this.entity = null;

      this.$emit('epc-close', true)
    },

    handleEditEntity(entity) {

    }
  },
};
</script>

