<template>
  <div :class="{'w-full': styleLabel==='classic' }">
    <span v-if="styleLabel==='classic'"
      class="uppercase h-6 text-white font-black tracking-wide text-center p-1 rounded w-full"
      :class="[`bg-${labels[status]}`]"
      :style="'font-size:' + fontSize + 'rem'"
    >
      {{ $t(labels[status]) }}
    </span>

    <b-tooltip v-if="styleLabel==='circle'"
      :label="$t(labels[status])"
      :active="true"
      :position="'is-left'">
      <span
        class="uppercase h-8 w-8 text-white font-black tracking-wide text-center p-1 rounded-full"
        :class="[`bg-${labels[status]}`]"
        :style="'font-size:' + fontSize + 'rem'"
      >
        {{ firstLetters($t(labels[status])) }}
      </span>
    </b-tooltip>
  </div>

</template>

<script>

export default {
  name: 'validation-status-label',

  props: {
    status: { type: Number, default: 0 },
    fontSize: {type: String, default: "0.6"},
    styleLabel: { type: String, default: 'classic' }
  },

  data: () => ({
    labels: [
      'validation_status_pending',
      'validation_status_pending_validation',
      'validation_status_completed',
      'validation_status_rejected',
      'validation_status_pending_answer',
      'validation_status_reopened',
      'validation_status_closed',
    ],
  }),

  methods: {
    firstLetters(texto) {
      // Dividir por espacios en blanco
      let words = texto.split(/\s+/);
      // solo consideramos las palabras con longitud mayor a dos
      words = words.filter( word => word.length > 2 )
      // solo consideramos las primeras dos palabras
      words = words.length > 2 ? words.slice(0, 2) : words;
      // Obtener la primera letra de cada palabra
      const firstletters = words.map(word => word.charAt(0));

      return firstletters.join(''); // Unir las primeras letras en una cadena
    }
  }

};
</script>
<style scoped>
  .bg-validation_status_pending {
    background-color: #3490dc;
  }
  .bg-validation_status_pending_validation {
    background-color: #F6993F;
  }
  .bg-validation_status_pending_answer {
    background-color: #7243bd;
  }
  .bg-validation_status_rejected {
    background-color: #e3342f;
  }
  .bg-validation_status_completed {
    background-color: #38C172;
  }
  .bg-validation_status_reopened {
    background-color: #e058da;
  }
  .bg-validation_status_closed {
    background-color: #41a66b;
  }
</style>
