<template>
  <section :class="{'inline-block': !openWithButton}">
    <span v-if="!openWithButton && !onlyModal" class="px-1 w-8 mt-2 cursor-pointer hover:text-gray-700"
    @click="isModalActive = true">
      <b-tooltip :label="$t('issues_report')" position="is-left">
        <i class="fas w-8 fa-book p-2 bg-gray-200 rounded-full"></i>
      </b-tooltip>
    </span>

    <b-modal :active.sync="isModalActive" has-modal-card @close="closeModal">
      <div class="modal-card w-1/2">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('generate_report') }}</p>

        </header>
        <section class="modal-card-body">

          Se generará un informe del listado actual

        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" :disabled="isLoading" v-on:click="generateReport">{{ btnGenerateReport }}
          </button>
          <button class="button" size="is-small" type="button" @click="isModalActive = false; $emit('update:show', !show)">{{ $t('close') }}</button>
        </footer>
      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

    </b-modal>
  </section>
</template>

<script>

  import Vue from "vue";
  import {mapGetters} from 'vuex'
  import axios from "axios";
  import store from "~/store";

  export default {
    data() {
      return {
        btnGenerateReport: this.$t('generate_report'),
        isModalActive: false,
        isLoading: false
      }
    },

    computed: {
      ...mapGetters({
        user: 'auth/user',
        project: 'app/project',
      }),
    },

    props: {
      list: {type: Array, required: true},
      onlyModal: { type: Boolean, default: false },
      show: { type: Boolean, default: false },
      openWithButton: {  type: Boolean, default: true }
    },

    watch: {
      isModalActive: function (val) {
      },

      show(newVal, oldVal) {
        this.isModalActive = newVal
      }
    },

    methods: {

      async generateReport() {

        // Loading
        this.isLoading = true;
        this.btnGenerateReport = this.$t('generating');
        // Parámetros para generar el reporte.
        // pasamos la lista completa porque no tienen Id y para evitar voler a hacer la lógica de buscar y filtrar las especificaciones tecnicas
        var params = {
          project_id: this.project.id,
          list: this.list
        };

        // Generando reporte, cerramos el modal
        this.$notify.success('generating_report');
        this.isModalActive = false;

        // Llamada generar informe
        const {data} = await axios.post('/api/v2/report/technical-specifications/generate', params);

        // Si hay resultado, forzamos descarga del fichero generado
        if (data && data.success) {

          // Loading
          this.isLoading = false;
          this.btnGenerateReport = this.$t('generate_report');
          this.$emit('update:show', !this.show)
          this.isModalActive = false;

          if (data.data) {
            window.open(data.data);
            // Reporte generado
            this.$notify.success('report_generated')
          }

        } else {

          // Error
          this.$notify.error('error_loading_report');

          // Loading
          this.isLoading = false;
          this.btnGenerateReport = 'Generar Informe';

        }

      },

      closeModal() {
        this.$emit('update:show', !this.show)
      }

    },
  }
</script>

<style lang="scss">
  .modal-card {
    width: 100%;
  }
</style>
