<template>
  <div>

    <div>
      <!-- Filtros -->
      <div class="panel">

        <div class="w-full mr-4 mb-2">
          <div class="flex mr-4">
            <b-field class="mr-4">
              <b-select
                v-model="filterGroupId"
                :placeholder="$t('groups')"
                icon="sitemap"
                icon-pack="fas"
                @input="getProjects"
              >
                <option :value="null" :key="0">{{ $t('all_the_groups') }}</option>
                <option v-for="group in groups"
                        :value="group.id" :key="group.id"
                >
                  {{ group.name + ' (' + group.description + ')' }}
                </option>
              </b-select>
            </b-field>
            <!-- determinado cliente pidio filtrado por tag pero por ultimo se desidio realizarlo por grupos -->
            <!-- los b-radios no se visualizan por esa razon -->
            <b-radio v-model="filterTag"
                v-if="false"
                name="name"
                size="is-medium"
                native-value="ESTE">
                Este
            </b-radio>
            <b-radio v-model="filterTag"
                v-if="false"
                name="name"
                size="is-medium"
                native-value="OESTE">
                Oeste
            </b-radio>

            <button class="button is-primary btn-blue" @click="cleanFiltersClicked">{{$t('clean_filters')}}</button>
          </div>
        </div>

      </div>

      <!-- Mapa -->
      <div class="panel">
        <googlemaps ref="mapRef"
                    :datamaps="datamaps"
                    :draggable="draggable"
                    @updPlace="updPlace"
                    @onClickPlace="onClickPlace"
                    :newPlace="newPlace"
                    height='600px'
                    inputAutocomplete="address_id" />
      </div>

      <!-- Tabla de localizaciones -->
      <transition
              name="custom-classes-transition"
              enter-active-class="animated fadeInUp"
              leave-active-class="animated fadeOutDown">

        <div class="panel">

          <v-input v-show="false"
            v-model="place.address"
            title="address_title"
            id="address_id"
            name="address_name"
            placeholder="address_placeholder"
            class="w-full"
            required
          />
          <section>
            <b-table
                    :data="projects"
                    :loading="isLoading"
                    icon-pack="fas"

                    paginated
                    backend-pagination
                    :per-page="perPage"
                    :total="total"
                    @page-change="onPageChange"

                    backend-sorting
                    :default-sort="orderField"
                    :default-sort-direction="orderDir"
                    @sort="onSort"

                    striped
                    bordered
            >
              <template>
                <b-table-column field="area.name" :label="$t('area')" sortable v-slot="props">
                  {{ props.row.area_name }}
                </b-table-column>

                <b-table-column field="name" :label="$t('name')" sortable v-slot="props">
                  <div class="cursor-pointer"
                    @click="onRowClicked(props.row)">
                    <b-tooltip position="is-right"
                              :label="$t('go_to_project')">
                      {{ props.row.name }}
                    </b-tooltip>
                  </div>
                </b-table-column>

                <b-table-column field="reference" :label="$t('reference')" sortable v-slot="props">
                  {{ props.row.reference }}
                </b-table-column>

                <b-table-column field="current_end_date" :label="$t('end_date')" class="w-25 vertical-center" sortable centered v-slot="props">
                  {{ new Date(props.row.current_final_date).toLocaleDateString() }}
                </b-table-column>

                <b-table-column field="actions" centered width="80">
                  <template v-slot:header="">{{ $t('actions') }}</template>
                  <template v-slot="props">
                    <div class="w-full h-full flex items-center justify-around">

                      <b-tooltip :label="$t('go_to_project')" position="is-left">
                        <div @click="onRowClicked(props.row)" class="cursor-pointer flex text-blue">
                          <b-icon icon="eye" pack="fas"/>
                        </div>
                      </b-tooltip>

                    </div>
                  </template>
                </b-table-column>
              </template>

              <template #empty>
                <div class="has-text-centered">{{ $t('no_results') }}</div>
              </template>

            </b-table>
          </section>
        </div>
      </transition>
    </div>

  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Form from 'vform'
  import Googlemaps from '~/components/GoogleMaps.vue'
  import LocationModal from './location-modal'
  import axios from 'axios'
  import EventBus from '~/plugins/bus'

  export default {
    name: 'project-map-manual',

    components: {
      Googlemaps,
      LocationModal
    },

    data: () => ({
      datamaps: [],
      projects: [],
      groups: [],
      datatable: null,
      place: {
        id: 0,
        reference_code: '',
        address: '',
        latitude: '',
        longitude: '',
      },
      newPlace: '',
      create: false,
      draggable: false,
      isLoading: true,
      autocomplete: null,
      page: 1,
      perPage: 15,
      total: 0,
      orderField: "reference_code",
      orderDir: "asc",

      filterGroupId: null,
      showArchive: false,
      filterTag: null,
    }),

    mounted() {
      this.getProjects()
    },

    watch: {
      filterTag(value) {
        this.isLoading = true
        if(this.filterGroupId)
          this.getProjects()
      }
    },

    computed: {
      ...mapGetters({
        company: 'app/company',
      }),
    },

    methods: {
      async getProjects() {
        this.isLoading = true;
        const { data } = await axios.get("/api/v2/user/projects", {
          'params' : { showArchive : this.showArchive, group: this.filterGroupId, companyId: this.company.id, filterTag: this.filterTag }
        });

        if (data.projects) {
          this.projects = data.projects;
          this.loadPointMarkMap()
          this.isLoading = false;
        }
      },

      async updPlace(place) {
        this.place.latitude = place.latitude
        this.place.longitude = place.longitude
      },

      reload() {
        this.$refs.mapRef.reload()
      },

      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },

      onPageChange(page) {
        this.page = page
        this.reload()
      },

      onSort(field, order) {
        this.orderField = field
        this.orderDir = order
        this.reload()
      },

      loadPointMarkMap() {
        let self = this
        self.datamaps = []
        this.projects.forEach(project => {
          // cargamos grupos
          if( project.groups && project.groups[0] ) {
            let group = project.groups[0]
            if (self.groups.find(g => g.id == group.id) === undefined )
              self.groups.push(group)
          }

          // locations
          if( project.location ) {
            project.location.name = 'Proyecto ' + project.name // cambiamos el nombre programaticamente por que location duplica code en name al guardar
            project.location.image = project.image // añadimos a location la imagen del projecto
            self.datamaps.push(project.location)
          }
        });

        EventBus.$emit('load-locations', self.datamaps);
      },

      cleanFiltersClicked(){
        this.filterGroupId = null
        this.filterTag = null
        // this.getGroups()
        this.getProjects()
      },

      onClickPlace(id) {
        let project = this.projects.filter( p => p.id == id)
        this.onRowClicked(project[0])
      },

      async onRowClicked(project) {
        await this.$store.dispatch("app/setProject", { project });
        let tags = project.tags
        await this.$store.dispatch("app/setTags", { tags });
        this.$router.push({ name: "project.home" });
      },

    }
  };
</script>
