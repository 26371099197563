<template>
<div>
  <div class="flex items-center justify-end w-full">
    <v-button icon="calculator"
      @click="calculateActivitiesFields()"
      class="ml-4 mr-4">{{ $t('calculate_fields') }}</v-button>
    <v-button
      @click="openExtrafieldModal(null)"
      icon="plus"
    >{{ $t('add_extrafield') }}</v-button>
    <!-- Modal -->
    <extra-field-modal
      ref="extra_field_modal"
      @reload-extra-fields="() => reload()"
    />
  </div>

  <div class="flex mt-5">
    <!-- Columna Izquierda - Campos Extras -->
    <div :class="[( extraFieldId || checkedRows.length > 0 ? 'w-8/12' : 'w-full'), 'mr-2']">
      <!-- Tabla de Extra Fields -->
      <div class="panel">
        <div v-if="isLoading" class="my-4 text-center">
          <scale-loader></scale-loader>
        </div>
        <div v-else>
          <div class="flex flex-wrap w-full mt-4">
          <section class="w-full">
            <b-table
              icon-pack="fas"
              :data="extrafields"
              :paginated="isPaginated"
              :per-page="perPage"
              :bordered="true"
              :current-page.sync="currentPage"
              :pagination-simple="isPaginationSimple"
              :default-sort-direction="defaultSortDirection"
              v-if="!isLoading"
              checkable
              checkbox-position="left"
              :checkbox-type="'is-success'"
              :checked-rows.sync="checkedRows">
              <template>
                <b-table-column field="code" sortable>
                  <template v-slot:header="">{{ $t('code') }}</template>
                  <template v-slot="props">{{ props.row.code }}</template>
                </b-table-column>

                <b-table-column field="name" sortable>
                  <template v-slot:header="">{{ $t('name') }}</template>
                  <template v-slot="props">{{ props.row.name }}</template>
                </b-table-column>

                <b-table-column field="type" sortable>
                  <template v-slot:header="">{{ $t('type') }}</template>
                  <template v-slot="props">{{ fieldTypes[props.row.field_type] }}</template>
                </b-table-column>

                <b-table-column field="order" sortable>
                  <template v-slot:header="">{{ $t('order') }}</template>
                  <template v-slot="props">{{ props.row.order_column }}</template>
                </b-table-column>

                <b-table-column field="actions" width=100 sortable>
                  <template v-slot:header="">{{ $t('actions') }}</template>
                  <template v-slot="props">
                    <div class="flex items-center justify-around w-full h-full">
                      <b-tooltip :label="$t('edit')" position="is-left">
                          <div @click="openExtrafieldModal(props.row)"
                              class="flex cursor-pointer text-blue">
                            <b-icon pack="fas" icon="pencil-alt"/>
                          </div>
                      </b-tooltip>
                      <b-tooltip :label="$t('delete')" position="is-left" type="is-danger">
                          <div @click="removeExtrafield(props.row)"
                              class="flex cursor-pointer text-red">
                            <b-icon pack="fas" icon="trash-alt"/>
                          </div>
                      </b-tooltip>
                      <b-tooltip :label="$t('view_values')" position="is-left">
                          <div @click="onRowClicked(props.row)"
                              class="flex cursor-pointer text-sky-300">
                            <b-icon pack="fas" icon="eye"/>
                          </div>
                      </b-tooltip>
                      <b-tooltip :label="$t('view_assignments')" position="is-left">
                          <div @click="assignedActivities(props.row)"
                              class="flex cursor-pointer text-sky-300">
                            <b-icon pack="fas" icon="paperclip"/>
                          </div>
                      </b-tooltip>
                    </div>
                  </template>
                </b-table-column>
              </template>

              <template slot="bottom-left">
                  <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                  <b>{{ $t("total") }}</b>: {{ this.extrafields.length }}
              </template>

              <template #empty>
                  <div class="has-text-centered">{{ $t('no_results') }}</div>
              </template>

            </b-table>
          </section>
          </div>
        </div>
      </div>
    </div>

    <!-- Columna derecha - Values -->
    <div v-if="showPanelValues" class="w-4/12 ml-2">
      <!-- Elements Attribute Value -->
      <div class="panel">

        <div v-if="loaded" class="my-4 text-center">
          <scale-loader></scale-loader>
        </div>

        <div v-if="extraFieldId && !loaded">
          <div class="flex items-center justify-between w-full mb-5">
            <div><label><b>{{ $t('values') }} <span v-if="extraFieldId && form.name"> - {{ $t('extra_field') + ' ' + form.name }}</span></b></label></div>
            <div>
              <b-tooltip :label="$t('close')" position="is-left">
                <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
                  <b-icon pack="fas" icon="times" size="is-small" />
                </div>
              </b-tooltip>
            </div>
          </div>

          <!-- recuadro de valores -->
          <div class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-blue-lightest mb-1"
            v-for="(value, taskKey) in form.values" :key="value.id">

            <div class="flex items-center">
              <!-- delete -->
              <b-tooltip :label="$t('remove')" position="is-left" type="is-danger">
                  <div @click="deleteValue(value.id, value.name)" class="flex cursor-pointer text-red">
                    <b-icon pack="fas" icon="trash-alt"/>
                  </div>
              </b-tooltip>
              <!-- save -->
              <b-tooltip v-if="valuesInEdition.includes(value.id)" :label="$t('save')" position="is-left">
                  <div @click="sendValueData(value.id, taskKey)" class="flex cursor-pointer text-blue">
                    <b-icon pack="fas" icon="download"/>
                  </div>
              </b-tooltip>
              <!-- edit -->
              <b-tooltip v-else :label="$t('edit')" position="is-left">
                  <div @click="editValue(value.id)" class="flex cursor-pointer text-blue">
                    <b-icon pack="fas" icon="pencil-alt"/>
                  </div>
              </b-tooltip>

              <span v-if="valuesInEdition.includes(value.id)" class="inline-flex ml-2">
                <input v-model="form.values[taskKey].code" type="text" class="mr-2 input">
                <input v-model="form.values[taskKey].name" type="text" class="mr-2 input">
              </span>
              <span v-else class="ml-2">
                <span v-if="value.code">{{ value.code }} - </span>
                {{ value.name }}
              </span>
            </div>
          </div>
          <div v-if="form.values.length == 0" class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-gray-200 mb-1 has-text-centered">{{ $t('no_results') }}</div>
          <!-- recuadro añadir values -->
          <div class="w-full border border-blue-lighter rounded flex items-center p-2 bg-blue-lightest mb-1" v-if="form.field_type == 0">

            <b-tooltip :label="$t('add_generic')" position="is-left">
                <button class="btn btn-green items-center mr-2"  type="button" @click="saveValue()">
                  <i class="fas fa-plus"></i>
                </button>
            </b-tooltip>

            <!-- <b-tooltip :label="$t('name')" position="is-left"> -->
              <span class="inline-flex ml-2">
                <input v-model="newValue.code" type="text" :placeholder="$t('code')" class="input mr-2">
                <input v-model="newValue.name" type="text" :placeholder="$t('name')" class="input mr-2">
              </span>
            <!-- </b-tooltip> -->
          </div>
        </div>

      </div>
    </div>

    <div v-if="showPanelLink" class="w-4/12 ml-2">
      <!-- <b-field :label="$t('select_dso') + ' (' + $t('optional') + ')'"> -->
      <div class="panel">
        <div class="flex items-center justify-between w-full mb-5">
          <div><label><b>Cantidad de campos extras a vincular: {{ this.checkedRows.length }}</b></label></div>
          <div>
            <b-tooltip :label="$t('close')" position="is-left">
              <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
                <b-icon pack="fas" icon="times" size="is-small" />
              </div>
            </b-tooltip>
          </div>
        </div>

        <div class="panel">
          <div><label><b>{{ $t('select_dso') }}</b></label></div>
          <div class="w-full inline-flex pb-3">
            <b-select ref="input3"
              v-model="activity"
              :placeholder="$t('select_dso')"
              icon="clipboard-list"
              icon-pack="fas"
              expanded
            >
              <optgroup label="Proyecto">
                <option v-bind:value="{ id: projectId, name: 'nombre proyecto', type: 'project', folders: null }"
                :key="'project_'+ projectId">
                  {{ $t('assign_to_project') }}
                </option>
              </optgroup>

              <optgroup label="DSOs">
                <option v-for="(option, indexActivities) in activities"
                v-bind:value="{ id: option['id'], name: option['name'], type: 'activity', folders: option['folders'] }"
                :key="'activity_'+ indexActivities">
                  <span v-if="option['is_subactivity']">&nbsp;&nbsp;</span>
                  {{ option['name'] }}
                </option>
              </optgroup>
            </b-select>
          </div>

          <div class="flex items-center justify-end w-full">
            <v-button v-if="!processingData"
              @click="assignExtrafieldsActivity()"
              icon="plus"
            >{{ $t('assign_extrafields') }}</v-button>

            <button v-if="processingData" type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-blue hover:bg-blue-light transition ease-in-out duration-150 cursor-not-allowed" disabled="">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {{ $t('assign_extrafields') }}
            </button>
          </div>
        </div>

        <div class="panel">
          <div><label><b>{{ $t('statuses_list') }}</b></label></div>
          <div class="w-full inline-flex pb-3">
            <multiselect
                v-model="statusesSelected"
                :options="statuses"
                :placeholder="$t('statuses_list')"
                :disabled="disabled"
                :multiple="true"
                label="name"
                class="rounded-r-none"
                searchable
                allowEmpty
                :closeOnSelect="false"
                :showLabels="false"
                :loading="processingData"
                track-by="id"
            >
                <!--<span slot="placeholder" class="text-base">-->
                <!--{{ $t('parent_department') }}-->
                <!--</span>-->
                <!-- <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template> -->
            </multiselect>

          </div>

          <div class="flex items-center justify-end w-full">
            <v-button v-if="!processingData"
              @click="linkStatuses()"
              icon="plus"
            >{{ $t('assign_extrafields') }}</v-button>

            <button v-if="processingData" type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-blue hover:bg-blue-light transition ease-in-out duration-150 cursor-not-allowed" disabled="">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {{ $t('assign_extrafields') }}
            </button>
          </div>
        </div>
      </div>

    </div>

    <div v-if="showPanelAssigned" class="w-4/12 ml-2">
      <div class="panel">
        <div v-if="loadingAssigned" class="my-4 text-center">
          <scale-loader></scale-loader>
        </div>

        <div v-if="extraFieldId && !loadingAssigned">
          <div class="flex items-center justify-between w-full mb-5">
            <div><label><b><span v-if="extraFieldId && form.name"> {{ $t('extra_field') + ' - ' + form.name }}</span></b></label></div>
            <div>
              <b-tooltip :label="$t('close')" position="is-left">
                <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
                  <b-icon pack="fas" icon="times" size="is-small" />
                </div>
              </b-tooltip>
            </div>
          </div>

          <b-tabs type="is-boxed" v-model="linkActiveTab">
            <b-tab-item>
              <template slot="header">
                <b-icon pack="fas" icon="file-excel"></b-icon>
                <span> {{ $t('linked_activities') }} </span>
              </template>

              <!-- recuadro de valores -->
              <div
                v-for="(value) in form.assigned_activities" :key="value.id">
                <div v-if="value.fieldable" class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-blue-lightest mb-1">
                  <!-- delete -->
                  <b-tooltip :label="$t('unlink')" position="is-left" type="is-danger">
                      <div @click="unassignExtrafields(value.extrafield_id, value.id, form.name, value.fieldable.name, 'actitivty')"
                        class="flex cursor-pointer text-red">
                        <b-icon pack="fas" icon="trash-alt"/>
                      </div>
                  </b-tooltip>
                  <!-- save -->
                  <span class="ml-2">{{ value.fieldable.name }}</span>
                </div>
              </div>
              <div v-if="form.assigned_activities.length == 0" class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-gray-200 mb-1 has-text-centered">{{ $t('no_results') }}</div>

              <div class="flex items-center justify-end w-full mt-5" v-if="form.assigned_activities.length > 0">
                <button v-if="!processingData"
                  type="button"
                  class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-orange hover:bg-orange-light transition ease-in-out duration-150"
                  @click="unassignExtrafields(form.id, -1, form.name, 'all', 'activity')"
                  >
                  <i class="fas fa-trash-alt mr-3"></i>
                  {{ $t('unlink_activities') }}
                </button>

                <button v-if="processingData" type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-orange hover:bg-orange-light transition ease-in-out duration-150 cursor-not-allowed" disabled="">
                  <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  {{ $t('unlink_activities') }}
                </button>
              </div>

            </b-tab-item>

            <b-tab-item>
              <template slot="header">
                <b-icon pack="fas" icon="traffic-light"></b-icon>
                <span>{{ $t('linked_statuses') }}</span>
              </template>

              <div class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-blue-lightest mb-1"
                v-for="(value) in form.statuses" :key="value.id">
                <div>
                  <!-- delete -->
                  <b-tooltip :label="$t('unlink')" position="is-left" type="is-danger">
                      <div @click="unassignExtrafields(value.pivot.extrafield_id, value.id, form.name, value.name, 'status')" class="flex cursor-pointer text-red">
                        <b-icon pack="fas" icon="trash-alt"/>
                      </div>
                  </b-tooltip>
                  <!-- save -->
                  <span class="ml-2">{{ value.name }}</span>
                </div>
              </div>
              <div v-if="form.statuses.length == 0" class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-gray-200 mb-1 has-text-centered">{{ $t('no_results') }}</div>

              <div class="flex items-center justify-end w-full mt-5" v-if="form.statuses.length > 0">
                <button v-if="!processingData"
                  type="button"
                  class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-orange hover:bg-orange-light transition ease-in-out duration-150"
                  @click="unassignExtrafields(form.id, -1, form.name, 'all', 'status')"
                  >
                  <i class="fas fa-trash-alt mr-3"></i>
                  {{ $t('unlink_statuses') }}
                </button>

                <button v-if="processingData" type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-orange hover:bg-orange-light transition ease-in-out duration-150 cursor-not-allowed" disabled="">
                  <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  {{ $t('unlink_statuses') }}
                </button>
              </div>
            </b-tab-item>
          </b-tabs>




        </div>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import Form from "vform";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import Multiselect from 'vue-multiselect'

// import MeasurementUnitModal from "./measurement-unit-modal";
import axios from "axios";
import ExtraFieldModal from './extra-field-modal.vue';

export default {
  name: "project-extrafields-manual",

  components: {
    // MeasurementUnitModal,
    ScaleLoader,
    ExtraFieldModal,
    Multiselect,
  },

  props: {
    projectId: { type: Number, required: true },
    // isAdminMeasurements: { type: Boolean, default: false }
  },

  data: () => ({
    isLoading: true,
    page: 1,
    perPage: 25,
    total: 0,
    orderField: "reference_code",
    orderDir: "asc",
    currentPage: 1,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    isPaginationSimple: false,
    isPaginated: true,

    extrafields: [],
    checkedRows: [],

    activities: [],
    activity: {},

    // values
    extraFieldId: null,
    form: new Form({
      code: '',
      name: '',
      values: [],
    }),

    newValue: new Form({
      code: '',
      name: '',
    }),

    valuesInEdition: [], // valor que estan en modo edicion. Editor inline
    disableSaveButton: false,
    loaded: false,
    loadingAssigned: false,
    processingData: false,

    fieldTypes: [
      'select',
      'input',
      'calendar',
      'checkbox',
    ],

    showPanel: 'none',
    statuses: [],
    statusesSelected: [],
    disabled: false,
    linkActiveTab: 0,
  }),

  mounted() {
    this.getProjectExtrafields();
    this.getActivities();
    this.getStatuses();
  },

  computed: {
    showPanelValues: function() {
      return this.extraFieldId && this.showPanel === 'values' && this.checkedRows.length == 0;
    },
    showPanelLink: function() {
      if( this.checkedRows.length > 0 )
        this.extraFieldId = null;
      return !this.extraFieldId && this.checkedRows.length > 0 && this.projectId > 0;
    },
    showPanelAssigned: function() {
      return this.extraFieldId && this.showPanel === 'assigned' && this.checkedRows.length == 0;
    },
  },

  methods: {
    async getProjectExtrafields() {
      this.isLoading = true;
      let params = {
        p: this.projectId,
        order_dir: this.orderDir,
        order_field: this.orderField
      };

      const { data } = await axios.get(
        "/api/v2/project/" + this.projectId + "/extrafields",
        { params: params }
      );

      if (data && data.success) {
        this.extrafields = data.extrafields;
        this.total = data.extrafields ? data.extrafields.length : 0;
      } else {
        this.$notify.error("error_loading_extra_fields_data");
      }
      this.isLoading = false;
    },

    async getActivities(){
      const { data } = await axios.get("/api/v2/activities", {'params' : { p : this.projectId, with_folders: 1, per_page: 999}});
      this.activities = data.activities;

      // para activar la actividad actual en la que se encuentra el flujo
      if(this.projectId > 0){
        let self = this;
        this.activities.forEach(item => {
          if( self.entityId == item.id ){
            self.activity = { id: item.id, name: item.name, type: 'activity', folders: item.folders }
          }
        });
      }
    },

    async getStatuses(){
      const { data } = await axios.get("/api/v2/project/"+this.projectId+"/statuses");
      this.statuses = data.statuses;

      // para activar la actividad actual en la que se encuentra el flujo
      // if(this.projectId > 0){
      //   let self = this;
      //   this.activities.forEach(item => {
      //     if( self.entityId == item.id ){
      //       self.activity = { id: item.id, name: item.name, type: 'activity', folders: item.folders }
      //     }
      //   });
      // }
    },

    reload() {
      this.getProjectExtrafields();
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    onPageChange(page) {
      this.page = page;
      this.reload();
    },

    onSort(field, order) {
      this.orderField = field;
      this.orderDir = order;
      this.reload();
    },

    async removeExtrafield(field) {
      this.$dialog.confirm({
        title: this.$t("delete"),
        message: this.$t("delete_generic", [field.name]),
        confirmText: this.$t("delete"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          this.extraFieldId = null

          this.isLoading = true;
          let url = "/api/v2/extrafields/" + field.id + "/delete";
          const { data } = await axios.post(url);

          if (data.success) {
            this.$toast.open({
              message: this.$t("success_deleting_generic"),
              type: "is-success",
              position: "is-top-right"
            });
            await this.sleep(1000);
            this.reload();
          } else {
            this.isLoading = false;
            // Error
            this.$toast.open({
              message: data.error || this.$t("error_saving"),
              type: "is-danger",
              position: "is-top-right"
            });
          }
        }
      });
    },

    openExtrafieldModal(dataField) {
      if(dataField == null)
      {
        dataField = {
          project_id: this.projectId,
          id: 0,
          code: "",
          name: "",
          type: "",
          order_column: 0
        };
      }
      this.$refs.extra_field_modal.show(dataField);
    },

    onCloseClicked() {
        this.extraFieldId = null;
        this.checkedRows = [];
    },

    async readExtrafield(params) {

      if(this.extraFieldId){

        if( this.showPanel === 'assigned' )
          this.loadingAssigned = true;
        else
          this.loaded = true;

        const { data } = await axios.get(
          "/api/v2/extrafields/" + this.extraFieldId,
          { params: params }
        );

        if (data && data.success) {
          this.form = data.extrafield;
        } else {
          this.$notify.error("error_loading_measurement_units_data");
        }

        if( this.showPanel === 'assigned' )
          this.loadingAssigned = false;
        else
          this.loaded = false;
      }

    },

    onRowClicked(field, access = true) {
      if (access) {
        this.extraFieldId = field.id;
        this.showPanel = 'values';
        this.readExtrafield( { } );
      }
    },

    async assignedActivities(field) {
      this.extraFieldId = field.id;
      this.showPanel = 'assigned';
      this.readExtrafield( { with: 'assigned' } );
    },

    // value
    // redirige a pantalla edicion
    editValue(valueId) {
      this.valuesInEdition.push(valueId) // edicion inline
    },

    // guarda cambios de la tarea y la saca del modo edicion
    async sendValueData(valueId, taskKey) {

      var url = '/api/v2/extrafields-values/' + valueId;

      // Enviamos el form
      const { data } = await axios.post(url, this.form.values[taskKey])
      if (data && data.success) {

        // la sacamos del modo edicion
        this.valuesInEdition.splice(this.valuesInEdition.indexOf(valueId),1)

        this.$notify.success('success_editing')

      } else if(data && data.msg){

        this.$notify.error('no_access_permissions')

      } else {
        this.$notify.error('error_saving')
      }
    },

    // crea nueva tarea en esta plantilla
    async saveValue() {
      if (this.newValue.code == '') {
        this.$notify.error(this.$t('empty_field_required', ['código']))
        return
      }

      if (this.newValue.name == '') {
        this.$notify.error(this.$t('empty_field_required', ['nombre']))
        return
      }

      const { data } = await axios.post("/api/v2/extrafields/" + this.extraFieldId + "/value", {
        code: this.newValue.code,
        name: this.newValue.name,
        element_id: this.extraFieldId
      })

      if (data && data.success) {
        // reload
        this.readExtrafield()
        this.newValue = new Form({
          code: '',
          name: '',
        }),

        this.$notify.success('success_editing')

      } else if(data && data.msg) {
        this.$notify.error('no_access_permissions')
      } else {
        this.$notify.error('error_saving')
      }
    },

    // elimina el valor
    async deleteValue(valueId, valueName) {

      let self = this;
      this.$dialog.confirm({
        title: this.$t('delete'),
        message: this.$t('delete_generic', [valueName]),
        confirmText: this.$t('delete'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {

          const { data } = await axios.post("/api/v2/extrafields-values/" + valueId + "/delete", {})
          if (data && data.success) {

            // reload
            this.readExtrafield()
            this.$notify.success('success_deleting_generic')

          } else if(data && data.msg){

            this.$notify.error('no_access_permissions')

          } else {
            this.$toast.open({
              message: data.error || this.$t("error_saving"),
              type: "is-danger",
              position: "is-top-right"
            });

            // this.$notify.error('error_saving')
          }
        }
      })
    },

    async assignExtrafieldsActivity() {
      if (!this.activity.id) {
        this.$notify.error(this.$t('empty_field_required', ['DSO']))
        return
      }

      if( this.checkedRows.length == 0){
        this.$notify.error(this.$t('error_select_extrafield'))
        return
      }

      if (!this.activity.id) {
        this.$notify.error(this.$t('empty_field_required', ['DSO']))
        return
      }

      this.processingData = true;
      let fields = [];
      this.checkedRows.forEach(field => {
        fields.push(field.id);
      });

      let self = this;

      await axios.post("/api/v2/extrafields/assign", {
        entityId: this.activity.id,
        entity: this.activity.type,
        fields: fields
      })
      .then(response => {
        let data = response.data
        this.processingData = false;

        if (data && data.success) {
          // reload
          self.activity = {};
          self.checkedRows = [];

          self.$notify.success('success_editing')

        } else if(data && data.msg) {
          self.$notify.error('no_access_permissions')
        } else {
          self.$notify.error('error_saving')
        }
      })
      .catch(error => {
          self.processingData = false;
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
                ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("assignment_insert_error"),
            type: "is-danger",
            position: "is-top-right"
          });
      });
    },

    async unassignExtrafields(fieldId, valueId, fieldName, valueName, entity='activity') {
      let params = { };
      params.entity = entity;
      params.assignment = valueId;
      valueName = valueName === 'all' ? 'todas las asignaciones' : valueName;
      let self = this;
      this.$dialog.confirm({
        title: this.$t('unassign'),
        message: this.$t('unassign_question_extrafield', [fieldName, valueName]),
        confirmText: this.$t('unassign'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {

          const { data } = await axios.post("/api/v2/extrafields/" + fieldId + "/unassign", params)
          if (data && data.success) {

            // reload
            this.readExtrafield( { with: 'assigned' } )
            this.$notify.success('success_deleting_generic')

          } else if(data && data.msg){

            this.$notify.error('no_access_permissions')

          } else {
            this.$notify.error('error_saving')
          }
        }
      })
    },

    async calculateActivitiesFields() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      const { data } = await axios.get('/api/v2/project/' + this.projectId + '/extrafields-calculate')

      loadingComponent.close()

      if (data && data.success) {

        this.$notify.success('fields_recalculated')

      } else {
        this.$notify.error('error_loading_activity_data')
      }

    },

    async linkStatuses() {
      if (!this.statusesSelected) {
        this.$notify.error(this.$t('empty_field_required', ['DSO']))
        return
      }

      this.processingData = true;
      let fields = [];
      this.checkedRows.forEach(field => {
        fields.push(field.id);
      });

      let statuses = [];
      this.statusesSelected.forEach(status => {
        statuses.push(status.id);
      });

      let self = this;

      await axios.post("/api/v2/extrafields/link-statuses", {
        fields: fields,
        statuses: statuses,
      })
      .then(response => {
        let data = response.data
        this.processingData = false;

        if (data && data.success) {
          // reload
          self.activity = {};
          self.checkedRows = [];
          self.statusesSelected = [];

          self.$notify.success('success_editing')

        } else if(data && data.msg) {
          self.$notify.error('no_access_permissions')
        } else {
          self.$notify.error('error_saving')
        }
      })
      .catch(error => {
          self.processingData = false;
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
                ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("assignment_insert_error"),
            type: "is-danger",
            position: "is-top-right"
          });
      });
    },
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
  .b-table .table .checkbox-cell .checkbox {
    background-color: white !important;
    padding: 0;
    margin: 0;
    border-radius: 5px;
  }

  .b-checkbox.checkbox:not(.button) {
    margin-right: 0 !important;
  }
</style>
