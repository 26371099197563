<template>
  <div class="page-with-top-bar">

    <div class="w-full flex justify-between mt-4 below-top-bar">
    
      <div class="flex">
        <router-link :to="{ name: 'partner.edit', params: { id: partnerId } }">&lt; {{$t('back')}}</router-link>
      </div>

      <div class="flex">
          <router-link
            :to="'/admin/partner/' + partnerId + '/machinery'"
            class="btn btn-blue rounded"><b-icon icon="plus" pack="fas"/>
            {{ $t('add_machinery') }}
          </router-link>
      </div>

    </div>

    <div class="panel">
      <div v-if="!loaded" class="text-center my-4">
        <scale-loader></scale-loader>
      </div>
      <div v-else>
        <div class="w-full flex flex-wrap mt-4">
        <section class="w-full">
          <b-table
          :data="machineries"
          :paginated="isPaginated"
          :per-page="perPage"
          :bordered="true"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :default-sort-direction="defaultSortDirection"
          v-if="loaded">
          <template>

            <b-table-column field="name" sortable>
              <template v-slot:header="">{{ $t('name') }}</template>
              <template v-slot="props">{{ props.row.name }}</template>
            </b-table-column>

            <b-table-column field="number_plate" sortable>
              <template v-slot:header="">{{ $t('number_plate') }}</template>
              <template v-slot="props">{{ props.row.number_plate }}</template>
            </b-table-column>

            <b-table-column field="type" sortable>
              <template v-slot:header="">{{ $t('type') }}</template>
              <template v-slot="props">{{ $t(types[props.row.type]) }}</template>
            </b-table-column>

            <b-table-column field="status" sortable>
              <template v-slot:header="">{{ $t('status') }}</template>
              <template v-slot="props">{{ $t(statuses[props.row.status]) }}</template>
            </b-table-column>
          

            <b-table-column field="actions" width=100 sortable>
              <template v-slot:header="">{{ $t('actions') }}</template>
              <template v-slot="props">
                <div class="w-full h-full flex items-center justify-around">
                    <router-link :to="'/admin/partner/' + partnerId + '/machinery/' + props.row.id" class="flex pr-0">
                      <b-tooltip :label="$t('edit')" type="is-primary" position="is-left">
                        <div class="cursor-pointer flex text-blue">
                          <b-icon icon="pencil-alt" pack="fas" />
                        </div>
                      </b-tooltip>
                  </router-link>

                  <b-tooltip :label="$t('remove')" position="is-left" type="is-danger">
                      <div @click="deleteMachineryButtonClicked(props.row.id, props.row.name)"
                          class="cursor-pointer flex text-red">
                        <b-icon pack="fas" icon="trash-alt"/>
                      </div>
                  </b-tooltip>
                </div>
              </template>
            </b-table-column>
          </template>
          <template slot="bottom-left">
              <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
              <b>{{ $t("total") }}</b>: {{ this.machineries.length }}      
          </template>

          <template #empty>
              <div class="has-text-centered">{{ $t('no_results') }}</div>
          </template>

          </b-table>
        </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import store from '~/store'
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  middleware: 'auth',
  title: 'partners_page_title',
  subtitle: 'partners_page_subtitle',
  menuOption: '0-4',
  components: {
    ScaleLoader
  },

  data: () => ({
    machineries: [],
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 20,
    loaded: false,
    partnerId: 0,
    types: ['machinery_type_type1', 'machinery_type_type2', 'machinery_type_type3'],
    statuses: ['machinery_status_available', 'machinery_status_notavailable', '2', '3', '4', '5']
  }),

  mounted () {
    this.partnerId = store.state.route.params.id;
    this.getMachineries()
  },

  methods: {

    async getMachineries () {
      const { data } = await axios.get("/api/v2/partner/" + this.partnerId + "/machineries", {'params' : { }});

      if (data.machineries) {
        this.machineries = data.machineries;
        this.loaded = true
      }
    },

    deleteMachineryButtonClicked(machineryId, machineryName) {

      let self = this;
      this.$dialog.confirm({
        title: this.$t('delete'),
        message: this.$t('delete_generic', [machineryName]),
        confirmText: this.$t('delete'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          let url = '/api/v2/partner/' + this.partnerId + '/machinery/' + machineryId + '/delete'
          const {data} = await axios.post(url)
          if (data && data.success) {
            this.loaded = false
            this.$notify.success('removed_successfully')
            self.getMachineries()
          } else {
            this.$notify.error(data.error)
          }
        }
      })
    },
  
  },


};
</script>
