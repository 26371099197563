<template>
  <div class="flex flex-col w-full">
    <!-- <form @submit.prevent="submitForm" class="w-full"> -->
      <div class="flex flex-col w-full">
        <div class="flex items-center justify-between w-full">
          <div class="font-semibold text-black">Nuevo pictograma</div>
          <div>
            <b-tooltip :label="$t('close')" position="is-left" :active="! processingData">
              <div class="flex items-center justify-center w-8 h-8 rounded-full text-grey-lightest"
                :class="{ 'cursor-pointer bg-red': !processingData, 'bg-gray-300': processingData}"
                @click="_HandleCloseClicked">
                <b-icon pack="fas" icon="times" size="is-small" />
              </div>
            </b-tooltip>
          </div>
        </div>

        <div class="mt-4">

          <div class="mt-4">
            <!-- <div class="mb-2">
              <div class="text-xs font-medium capitalize text-blue">{{ $t("code") }}</div>
              <div class="flex flex-row items-center">
                <input
                  v-model="form.code"
                  title="code"
                  :required="true"
                  name="code"
                  type="text"
                  class="w-full h-8_ p-1 border rounded bg-grey-lighter border-grey-light"
                  @focus="$event.target.select()" />
              </div>
            </div> -->

            <div class="mb-2">
              <div class="text-xs font-medium capitalize text-blue">{{ $t("name") }}</div>
              <div class="flex flex-row items-center">
                <input
                  v-model="form.name"
                  title="code"
                  :required="true"
                  :disabled="processingData"
                  name="name"
                  type="text"
                  class="w-full h-8_ p-1 border rounded bg-grey-lighter border-grey-light"
                  @focus="$event.target.select()" />
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="flex flex-col w-full pb-3 mt-5">
        <v-button-processing v-if="processingData" />

        <div v-else class="w-full flex flex-col lg:flex-row">
          <!-- <b-button
            :label="$t('edit_design')"
            type="is-info"
            icon-pack="fas"
            icon-left="pen-square"
            class="mr-2 w-full mb-2"
            @click="_EditDesign" /> -->

            <b-button
            ref="refBtnSave"
            :label="$t('save')"
            type="is-success"
            icon-pack="fas"
            icon-left="save"
            expanded
            class="w-full"
            @click="_HandleClickBtnSavePictogram" />
            <!-- native-type="submit" />  -->

        </div>
      </div>
    <!-- </form> -->
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import FieldsDesignForm from "./fields-design-form.vue"
import LayersDesignList from './layers-design-list.vue';

import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import store from "~/store";

export default {
  components: {
    FieldsDesignForm,
    LayersDesignList
  },

  props: {
    entityId: { type: Number, required: true },
    entityType: { type: String, required: false, default: 'project' },
    inputFile: { type: File, require: true }, // Archivo que contiene el diseño CAD extension dxf
    parserDxf: { type: Object, require: true }, // Diseño CAD parseado
    // data = Contiene informaciôn del pictograma como code, nombre, total de estructuras, capas manejadas por nosotros,
    // capas del diseño CAD, el diseño para ser visualizado por konva (scene).
    data: { type: Object, require: true },
  },

  data () {
    return {
      uppy: null, // instancia de la libreria para subir archivos
      form: {
        id: 0,
        code: null,
        name: null
      },

      processingData: false, // bandera que se utiliza para indicar que se esta realizando algo

      // Layers del diseño
      screenshotDesign: [] // array contiene las capturas de los diseños de las capas controlados por nosotros, son usados en el dasboard
    }
  },

  computed: {
    ...mapGetters({
      companies: "app/companies",
    }),
  },

  created() {
    this.uppy = new Uppy({
      debug: process.env.NODE_ENV === 'development', // activamos debug para development
      autoProceed: false,
      allowMultipleUploads: false,
    })
    .use(AwsS3Multipart, {
      limit: 3,
      companionUrl: '/api/v2/',
      companionHeaders: {
        'Authorization': "Bearer " + store.getters["auth/token"]
      }
    })
    // .on('upload-success', (file, response) => { this.onUploadSuccess(file, response) } )
    .on('upload-success', (file, response) => { // callback deveulto cuando el file ha sido subido correctamente
      console.log('%cEPC-TACKER: '+ '%c file ' + file.data.name + ' subido correctamente', 'background: #5577BB; color: #fff', 'color: #000')
    })
    .on('upload-progress', (file, progesss) => { // callback devuelto con el progreso de subido del archivo
      this.uploadPercentage = parseInt(
        Math.round((progesss.bytesUploaded * 100) / progesss.bytesTotal)
      );
    })
    .on('upload-error', (file, error, response) => { // callback devuelto si ha ocurrido algun error en la subida
      console.error('%cEPC-TACKER: '+ '%c a ocurrido un error al subir el archivo ' + file.data.name, 'background: #5577BB; color: #fff', 'color: #000', error)
    })
  },

  mounted() {
  },

  methods: {
    SaveDataPictogram(screenshots=[]) {
      this.screenshotDesign = screenshots
      this.submitForm()
    },

    _ShowLoader(loading=false, text=null) {
      this.$emit('epc-pictogram-show-loader', { loading, text })
    },

    _HandleCloseClicked() {
      if (! this.processingData)
        this.$emit('epc-pictogram-save-close')
    },

    async _EditDesign() {
      // no realiza ninguna de las acciones de abajo se debe ajustar
      // this.generatedDesign = false
      // await this._LoadParserDxf(this.dxfUrl)
    },

    _HandleClickBtnSavePictogram() {
      if( !this._ValidateDataForm() )
        return false;

      this.processingData = true
      this.$emit('epc-pictogram-save')
    },

    _ValidateDataForm() {
      if( !this.form.name || this.form.name.trim().length === 0 ) {
        this.$toast.open({
          'message': 'El campo nombre es obligatorio',
          'position': 'is-top-right',
          'type': 'is-danger'
        })
        return false
      }

      return true
    },

    async submitForm() {
      this.data.scene.entities = this._FlattenEntities();
      // console.log('this.scene: ', this.scene.entities)
      // return
      let aliasCompany = this._GetCompany('alias')

      if (aliasCompany) {
        this.processingData = true
        let document = null

        if ( this.inputFile ) {
          this._ShowLoader(true, "Guardando diseño CAD")
          document = await this.uploadFiles(); // Subimos el archivo que contiene el diseño CAD
        }

        if (document) {
          this._ShowLoader(true, "Guardando pictograma")
          const aws_response = await this.submitScenePictogram(document.filename.split('.').slice(0, -1).join('.') + '.json')
          if( aws_response ) {

            this._ShowLoader(true, "Guardando capturas")
            let arrAwsResponse = []
            for (const item of this.screenshotDesign) {
              const awsr = await this.submitResource(item)
              arrAwsResponse.push(awsr)
            }

            this._ShowLoader(true, "Guardando datos del pictograma")
            await this.submitDataPictogram(document, aws_response, arrAwsResponse)
          }
          // else
          // mostrar mensaje si fallo la subida TODO: tratar en caso de generase error por alguna razon conciderar los casos de eliminicion del archivo
          // subido previamente
        }

        // this._ShowLoader(true, "")
        this.$toast.open({
          message: 'Datos guardados correctamente.',
          type: "is-success",
          position: "is-top-right",
        });

        await this.sleep(1000)
        this.processingData = false

        this.$emit("epc-pictograms-reload");
        this._ShowLoader(false, null)
        this._HandleCloseClicked();
      } else {
        this.$toast.open({
          message: 'El proyecto no esta asignado a ninguna empresa.',
          type: "is-danger",
          position: "is-top-right",
        });
        this._ShowLoader(false, null)
      }

    },

    // Realizamos un aplastado para guardar en un array cuyo indice es el id de de la entidad para facilitar
    // en el servidor la busqueda
    _FlattenEntities() {
      let o = {}
      for (const entity of this._GetEntities2()) {
        o[entity.id] = entity
      }
      return o
    },

    *_GetEntities2() {
      for ( const entity of this.data.scene.entities) {
        yield entity
      }
    },

    /** Metodo devuelve la compania si attr=null, o algun valor de algun atributo de la compania si attr!=null
     * Valores retornados si la compania no existe retorna null, si el atributo no existe retorna undefined
    */
    _GetCompany(attr=null) {
      let company = this.companies.find((c) => { return c.projects.findIndex((p) => { return p.id == this.projectId }) !== 1 })

      if(!company) // si no existe la company mandamos null
        return null

      return attr ? company[attr] : company
    },

    async uploadFiles() {
      if (this.inputFile == null) {
        return;
      }

      this.isLoading = true;
      let files = [ this.inputFile ];
      let respuesta = null

      for (var i = files.length - 1; i >= 0; i--) {
        if (files[i].size / 1024 / 1024 > 1024) {
          this.$notify.warning("document_exceeded_max_size", {
            name: files[i].name,
          });
        } else {
          let ok = await this.submitFile(files[i]);
          if (ok) {
            // this.files.splice(i, 1);
            this.$notify.success("upload-document_success", {
              files: files[i].name,
            });

            return ok
          } else {
            this.isLoading = false;
            this.$notify.error("upload-document_error");
            return false;
          }
        }
      }
    },

    async submitFile(file) {

      this.uploadPercentage = 0;
      this.cancelRequest = axios.CancelToken.source();


      let form = new FormData();

      let customName = "";
      if (file.custom_name) customName = file.custom_name.trim();

      // Si el nombre personalizado después del trim tiene contenido usamos ese, y si no pasamos al del fichero
      form.append(
        "original_filename",
        customName != "" ? customName + this.getFileExtension(file) : file.name
      );
      form.append("entity_type", "pictogram");
      form.append("entity_id", this.id);

      form.append("project_id", this.entityId);

      return this.store(file, {})
      .then(async result => {
            // successFul contiene toda la lista de archivos que se han subido
            // para nuestro caso solo consideramos siempre el primero por que enviamos de a uno
            if( result.successful.length > 0 ) {
              let response = result.successful[0].response

              let params = {
                // uuid: response.uuid,
                key: response.body.key,
                // bucket: response.bucket,
                name: file.name,
                content_type: file.type,
                // url: response.url
              }

              form.append("aws_response", JSON.stringify(params));

              // let resp = null
              let { data } = await axios.post("/api/v2/" + this.entityType + "/" + this.entityId + "/document", form, {
                cancelToken: this.cancelRequest.token,
              });

              return data && data.success ? data.document : false
            }

            // failed contiene todos los archivos que tubieron fallo al subir
            if (result.failed.length > 0) {
              console.error('Errors:')
              result.failed.forEach((file) => {
                console.error(file.error)
              })

              return false
            }
      }).catch((error) => {
        console.log('error: ', error)
        return false
      });

    },

    // CUSTOM VAPOR STORE METHOD
    async store(file, options = null) {
      // verificamos si existe algun archivo en la lista de carga de uppy
      // si existe lo eliminamos
      if( this.keyFileUploadCurrent ) {
        this.uppy.removeFile(this.keyFileUploadCurrent);
      }

      if (typeof options.progress === 'undefined') {
          options.progress = () => {};
      }

      this.keyFileUploadCurrent = this.uppy.addFile({
        name: file.name, // file name
        type: file.type, // file type
        data: file, // file blob
        // meta: {
        //   // optional, store the directory path of a file so Uppy can tell identical files in different directories apart.
        //   relativePath: webkitFileSystemEntry.relativePath,
        // },
        source: 'Local',
        isRemote: false,
      });

      // response.data.extension = file.name.split('.').pop()
      return this.uppy.upload()
    },

    async submitScenePictogram(name) {
      const blob = new Blob([JSON.stringify(this.data.scene)], { type: 'application/json' });
      // blob.name = name
      return await this.submitResource({ src: blob, name: name })
      // return this.store(blob, {})
      //   .then(async result => {
      //     // successFul contiene toda la lista de archivos que se han subido
      //     // para nuestro caso solo consideramos siempre el primero por que enviamos de a uno
      //     if( result.successful.length > 0 ) {
      //       let response = result.successful[0].response

      //       let params = {
      //         // uuid: response.uuid,
      //         key: response.body.key,
      //         // bucket: response.bucket,
      //         name: blob.name,
      //         content_type: blob.type,
      //         // url: response.url
      //       }
      //       return params
      //       // form.append("aws_response", JSON.stringify(params));
      //     }

      //     // failed contiene todos los archivos que tubieron fallo al subir
      //     if (result.failed.length > 0) {
      //       console.error('Errors:')
      //       result.failed.forEach((file) => {
      //         console.error(file.error)
      //       })

      //       return null
      //     }
      //   }).catch((error) => {
      //     console.log('error: ', error)
      //     return null
      //   });
    },

    async submitResource(resource) {
      const blob = resource.src;
      blob.name = resource.name; // TODO: al nombre del recurso añadir marca de tiempo y name company o project who prefix
      return this.store(blob, {})
        .then(async result => {
          // successFul contiene toda la lista de archivos que se han subido
          // para nuestro caso solo consideramos siempre el primero por que enviamos de a uno
          if( result.successful.length > 0 ) {
            let response = result.successful[0].response

            let params = {
              // uuid: response.uuid,
              key: response.body.key,
              // bucket: response.bucket,
              name: blob.name,
              content_type: blob.type,
              // url: response.url
            }
            return params
            // form.append("aws_response", JSON.stringify(params));
          }

          // failed contiene todos los archivos que tubieron fallo al subir
          if (result.failed.length > 0) {
            console.error('Errors:')
            result.failed.forEach((file) => {
              console.error(file.error)
            })

            return null
          }
        }).catch((error) => {
          console.log('error: ', error)
          return null
        });
    },

    async submitDataPictogram(document, aws_response, aws_images) {
      const form =  new FormData()
      form.append('document_id', document.id)
      form.append('code', this.form.code)
      form.append('name', this.form.name)

      // this._GetLayers().forEach((item) => {
      //   form.append('layers[]', JSON.stringify(item))
      // })
      form.append('layers', JSON.stringify(this.data.layers))
      form.append('cad_layers', JSON.stringify(this.data.cadLayeredDesign))
      form.append('aws_images', JSON.stringify(aws_images))
      // dxf_parser => este contiene el parser del dxf a json original no lo usamos por lo que no mandamos de momento
      // si se envia considerar en mandar directo al buket o mandar con parametro form multipart
      // dxf_parser: this.parserDxf,
      // scene => sucede lo mismo que con el anterior para optimizar el envio, este es usado por konva para visualizacion de las
      // form.append('scene', JSON.stringify(this.scene)) // ya no lo enviamos optimizado guardando directo en el bucket
      form.append('aws_response', JSON.stringify(aws_response))

      let url = "/api/v2/project/" + this.entityId + "/pictogram";

      this.processingData = true

      await axios.post(url, form)
      .then((response) => {
        if( response.data.success ) {

          this.$toast.open({
            message: this.$t("assignment_update_success"),
            type: "is-success",
            position: "is-top-right",
          });

          this.id = response.data.pictogram.id

        } else {

          this.$toast.open({
            message: response.data.error,
            type: "is-danger",
            position: "is-top-right",
          });

        }
      })
      .catch((error) => {
        //Se produjo un error
        this.isLoading = false;
        if (error.response && error.response.status === 422) {
          //extraemos el primer error de validación
          error.error_msg =
            error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ][0];
        }

        // Mostramos el error
        this.$toast.open({
          message: error.error_msg || this.$t("assignment_update_error"),
          type: "is-danger",
          position: "is-top-right",
        });
      });
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  }
}
</script>
