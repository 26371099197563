<template>
<div>
  <div class="panel p-2">
    <div class="flex items-center justify-between w-full mb-5">
      <div class="flex flex-col">
        <div><label><b>{{ chapter && chapter.id ? 'Editar' : 'Crear' }} {{ chapter && chapter.parent_id ? 'subchapter' : 'chapter' }} </b></label></div>
        <div></div>
      </div>
      <div>
        <b-tooltip :label="$t('close')" position="is-left">
          <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
            <b-icon pack="fas" icon="times" size="is-small" />
          </div>
        </b-tooltip>
      </div>
    </div>

    <div class="w-full pb-3">
      <div class="mt-4">
        <b-field :label="$t('chapters') + ' padre'">
          <treeselect v-model="chapterParent"
            :multiple="false"
            :options="chapters"
            :disabled="!form.id && !!form.parent_id"
          />
        </b-field>
      </div>

      <div class="mt-4">
        <l-form-input
          v-model="form.code"
          ref="code"
          :form="form"
          field="code"
          label="code"
        />
      </div>

      <div class="mt-4">
        <l-form-input ref="name"
          :form="form"
          field="name"
          label="name"
        />
      </div>
    </div>

    <div class="flex items-center justify-end w-full">
      <form @submit.prevent="sendData" @keydown="form.onKeydown($event)" v-if="!processingData"
        class="w-full flex items-center justify-end mb-2 mt-2">
        <v-button :disabled="disableSaveButton" type="submit" icon="thumbs-up" icon-pack="far" class="mr-4">{{ $t('save') }}</v-button>
        <v-button color="grey" icon="undo-alt" @click="onCloseClicked">{{ $t('cancel') }}</v-button>
      </form>

      <v-button-processing v-if="processingData" />
    </div>
  </div>
</div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "chapter-edit",

  components: {
    Treeselect
  },

  props: {
    entityType: { type: String, required: true },
    entityId: { type: Number, required: true },
    chapter: { type: Object, default: null },
  },

  data() {
    return {
      disableSaveButton: false,
      processingData: false,

      form: new Form({  // data activity or subactivity of template project
        id: this.chapter ? this.chapter.id : null,
        entity_type: this.entityType,
        parent_id: this.chapter ? this.chapter.parent_id : null,
        code: this.chapter ? this.chapter.code : '',
        name: this.chapter ? this.chapter.name : ''
      }),

      chapters: [], // grupos de actividades
      chapterParent: this.chapter ? this.chapter.parent_id : null, // grupo de actividad seleccionada

    }
  },

  computed: {
    hasAlpha() {
      return /a\s*$/i.test(this.format)
    }
  },

  watch: {
  },
  created() {
    this.getChapters()
  },
  mounted() {

  },

  methods: {
    async getChapters() {
      let params = {
        template: 'select',
        entity_type: this.entityType
      };

      const { data } = await axios.get(
        "/api/v2/project/" + this.entityId + "/chapters",
        { params: params }
      );

      if (data && data.success) {
        this.chapters = data.chapters;
      } else {
        this.$notify.error("error_loading_extra_fields_data");
      }
    },

    async getChapterData() {
      const { data } = await axios.get('/api/v2/chapters/' + this.form.id, {params: { with_statuses: 1 }})
      if (data && data.success) {
        this.form.id = data.data.id
        this.form.parent_measurement_id = data.data.parent_measurement_id
        this.form.unit_id = data.data.unit_id
        this.form.code = data.data.code
        this.form.name = data.data.name
      } else {
        this.$notify.error('error_loading_measurement_data')
      }
    },

    async sendData() {
      this.processingData = true

      var url = `/api/v2/project/${this.entityId}/chapters`;
      let isCreate = ! this.form.id // si id es null o cero es crear el grupo
      if( !isCreate )
        url = `/api/v2/chapters/${this.form.id}`; // se edita el grupo

      this.form.parent_id = typeof this.chapterParent === 'undefined' ? null : this.chapterParent

      const { data } = await this.form.post(url).catch((error) => { this.processingData = false })

      if (data && data.success) {

        this.$notify.success('success_createing_group')

        if( data.warning )
          this.$notify.warning(data.warning)

        this.processingData = false
        this.$emit('epc-success-creating', this.entity)

      } else {
        this.processingData = false
        if( data && data.message )
          this.$notify.error(data.message)
        else
          this.$notify.error('error_creating_group')
      }
    },

    onCloseClicked() {
      this.measurement = null;

      this.$emit('epc-close', true)
    },

    handleEditEntity(entity) {

    },
  },
};
</script>

