import axios from "axios";
import store from "~/store";
import router from "~/router";
import i18n from "~/plugins/i18n";

// Vue.prototype.$http = axios.create({
//   baseURL: window.config.apiBaseUrl,
// });

// timeout a 5 minutos (para subidas de fichero gordas)
axios.defaults.timeout = 300000

// Request interceptor
axios.interceptors.request.use(request => {
  const token = store.getters["auth/token"];
  if (token) {
    request.headers.common["Authorization"] = `Bearer ${token}`;
  }

  const locale = store.getters["lang/locale"];
  if (locale) {
    request.headers.common["Accept-Language"] = locale;
  }

  // request.headers['X-Socket-Id'] = Echo.socketId()

  return request;
});

// Response interceptor
axios.interceptors.response.use(
  // Save the token if present in headers
  response => {
    if (response.headers.authorization) {
      let token = response.headers.authorization.split(" ")[1];
      store.dispatch("auth/saveToken", { token: token });
    }
    return response;
  },

  error => {

    let status = 0
    let statusText = ''
    // Se muestran errores en base a una variable de entorno incluida en el .env
    let env = process.env.MIX_FRONT_ERRORS_LEVEL

    if (error.response) {
      status = error.response.status;
      statusText = error.response.statusText;
    }

    // Unauthorized - Avoid infinite re-logout after a logout
    if (status === 401 && !error.response.config.url.includes("logout")) {
      store.dispatch("app/logout");
      store.dispatch("auth/logout");
      router.push({ name: 'login'})
    }
    // Laravel validation error
    else if (status === 422) {
      return Promise.reject(error);
    }
    // Error when laravel is down
    else if (status === 503) {
      store.dispatch("app/logout");
      store.dispatch("auth/logout");
      router.push({ name: 'login'})
    }
    else if (status > 401) {
      // Laravel validation error
      if (status === 418) {
        router.app.$toast.open({
          type: "is-danger",
          message: i18n.t("error_login"),
          position: "is-top-right"
        });
      } else if (env === 'error') {
        router.app.$toast.open({
          type: "is-danger",
          message:
            i18n.t("error_alert_text") +
            " <i>(" +
            status +
            "-" +
            statusText +
            ")</i>",
          position: "is-top-right"
        });
      }
    }

    return Promise.reject(error.response);
  }
);
