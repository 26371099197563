<template>
  <div>
    <div class="w-full flex justify-end">
      <div class="flex">
        <v-button @click="create = !create;
        planificationCreate.start_date = '';
        planificationCreate.end_date = '';
        planificationCreate.file = '';
        planificationCreate.description= '';"
        class="btn btn-blue rounded" icon="plus">{{ $t('add_planification') }}
      </v-button>
    </div>
  </div>
  <div class="panel" v-if="create">
    <div>

      <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('create_planning') }}</h2>
      <form @submit.prevent="createPlanification">
        <div class="w-full justify inline-flex">
          <b-field :label="$t('file')" class="md:w-1/5 mr-4">
            <input
            type="file"
            name="file"
            id="file_id"
            @change="selectFile"
            required
            />
          </b-field>
          <b-field :label="$t('start_date')" class="md:w-1/5 mr-4">
            <b-datepicker
            v-model="planificationCreate.start_date"
            :first-day-of-week="1"
            :month-names="months"
            :placeholder="$t('click_to_select')"
            required>
            <button class="button is-primary"
                @click="planificationCreate.start_date = new Date()">
                <b-icon icon="calendar"></b-icon>
                <span>{{ $t('Hoy') }}</span>
            </button>
            </b-datepicker>
          </b-field>
          <b-field :label="$t('end_date')" class="md:w-1/5 mr-4">
            <b-datepicker
            v-model="planificationCreate.end_date"
            :first-day-of-week="1"
            :month-names="months"
            :min-date="planificationCreate.start_date"
            :placeholder="$t('click_to_select')"
            required>
            <button class="button is-primary"
                @click="planificationCreate.end_date = new Date()">
                <b-icon icon="calendar"></b-icon>
                <span>{{ $t('Hoy') }}</span>
            </button>
            </b-datepicker>
          </b-field>
          <b-field :label="$t('description')" class="md:w-1/5 mr-4">
            <b-input v-model="planificationCreate.description" class="w-full"></b-input>
          </b-field>
          <b-field label="  " class="md:w-1/5 mr-4 mt-4">
            <v-button class="btn btn-primary" nativeType="submit" icon="save" :disabled="!planificationCreate.start_date || !planificationCreate.end_date ">{{ $t('save') }}</v-button>
          </b-field>
        </div>
      </form>
    </div>
  </div>

  <div class="w-full flex flex-wrap mt-4">
    <section class="w-full">
      <b-table
      :data="planifications"
      :paginated="isPaginated"
      :per-page="perPage"
      :bordered="true"
      :current-page.sync="currentPage"
      :pagination-simple="isPaginationSimple"
      :default-sort-direction="defaultSortDirection"
      v-if="loaded">
      <template slot-scope="props">
        <b-table-column field="start_date" :label="$t('start_date')" sortable width=200>
          {{ props.row.start_date }}
        </b-table-column>
        <b-table-column field="end_date" :label="$t('end_date')" sortable width=200>
          {{ props.row.end_date }}
        </b-table-column>
        <!--<b-table-column field="period" :label="$t('period')" sortable width=200>
          {{ props.row.period }}
        </b-table-column>-->
        <b-table-column field="processed" :label="$t('processed')" sortable width=200>
          {{ $t(props.row.processed) }}
        </b-table-column>

        <b-table-column field="description" :label="$t('description')" sortable>
          {{ props.row.description }}
        </b-table-column>
      </template>
    </b-table>
  </section>
</div>
</div>
</div>
</template>

<script>
import Form from 'vform'
import axios from 'axios'

export default {
  middleware: 'auth',
  title: 'planification_page_title',
  subtitle: 'planification_page_subtitle',
  menuOption: '0-2',

  data: () => ({
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    project_id: 0,
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 5,
    planifications: [],
    loaded: false,
    projects: null,
    create: false,
    file: null,
    planificationCreate: new Form ({
      id: 0,
      start_date: '',
      end_date: '',
      description: '',
    }),
  }),

  mounted () {
    this.project_id = parseInt(this.$route.params.project_id)
    this.getPlanifications()
  },

  methods: {
    formatDate(date){
      var d = new Date(date)
      var month =  d.getMonth() + 1
      var day =  d.getDate()
      var year = d.getFullYear()
      return year + "-" + month + "-" + day
    },
    async createPlanification () {
      if (! this.planificationCreate) {
        return;
      }
      var url = '/api/v2/planification/' + this.project_id + '/add'

      this.planificationCreate.start_date = this.formatDate(this.planificationCreate.start_date)
      this.planificationCreate.end_date = this.formatDate(this.planificationCreate.end_date)

      const formData = new FormData();
      formData.append('file', this.file );
      formData.append('start_date', this.planificationCreate.start_date);
      formData.append('end_date', this.planificationCreate.end_date);
      //formData.append('period', this.planificationCreate.period);
      formData.append('description', this.planificationCreate.description);

      const { data } = await axios.post(url,formData)
      if (data.data && data.success) {
        this.planificationCreate.file = ''
        this.planificationCreate.start_date = ''
        this.planificationCreate.end_date = ''
        this.planificationCreate.description = ''

        this.getPlanifications()
          // Hago esto pq extiende de la clase padre y no me retorna la lista completa de nuevo.

        this.planificationCreate.id = 0
        this.success(this.$t(data.msg))

      }else{
        this.error(this.$t(data.msg))
        this.getPlanifications()
      }
    },
    selectFile (e) {
      this.file = e.target.files[0]
    },

    async getPlanifications(){
      const url = '/api/v2/planification/' + this.project_id + '/list'
      const { data } = await axios.get(url)
      if (data.data && data.success) {
        this.planifications = data.data
        this.loaded = true
      } else {
        this.error(this.$t(data.msg))

      }
    },

    /*
     ALERTAS
     */
     success(message){
      this.$toast.open({
        message: message,
        type: 'is-success',
        position: 'is-top-right'
      })
    },
    error(message){
      this.$toast.open({
        message: message,
        type: 'is-danger',
        position: 'is-top-right'
      })
    }
  },
  watch:{

  }

};
</script>
