import { Graphics } from "pixi.js";

export class PicComplete extends Graphics {
  constructor(el, offset, layers, index) {
    super();

    const colorLine = this._GetColoStatusEntityOnlyComplete(el, layers)
    this.picId = 'sc_' + el.id;
    this.picX = Math.abs( el.x + offset.x );
    this.picY = Math.abs( el.y - offset.y );
    this.picWidth = el.width;
    this.picHeight = el.height;
    this.picOpacity = 0.7;
    this.picStroke = colorLine
    this.picStrokeWidth = 1.2;

    this.drawBackground();
  }

  drawBackground() {
    this.rect(this.picX, this.picY, this.picWidth, this.picHeight)
      .fill(this.picFillColor)
      .stroke({ width: this.picStrokeWidth, color: this.picStroke });

    this.alpha = this.picOpacity;

    this.visible = false;
  }

  _GetColoStatusEntityOnlyComplete(entity, layers) {
    if( entity.status != null ) {
      let lyr = layers.find( item => { return item.code === entity.type })
      if (lyr) {
        let idStatus = typeof entity.status === 'object' ? entity.status.id : entity.status;

        let status = lyr.statuses.find(item => { return item.id == idStatus && item.is_completion == 1})
        if (status)
          return status.color
      }
    }

   return '#ffffff'
 }
}
