<template>

  <div class="flex w-full flex-col items-center mb-4">

    <!-- por nombre -->
    <div class="w-full">
      <b-field :label="$t('search_by_name')">
        <b-input
          v-model="filterName"
          :placeholder="$t('name')"
          type="search"
          icon="search"
          icon-pack="fas"
          @input="checkInputTextFilter(filterName)">
        </b-input>
      </b-field>
    </div>

    <!-- por emplazamiento -->
    <div class="w-full">
      <b-field :label="$t('search_by_location')">
        <b-select ref="input"
          v-model="filterLocation"
          :placeholder="$t('select')"
          icon="search"
          icon-pack="fas"
          @input="applyFilters()"
          expanded
        >
          <option :value="null">{{$t('select')}}</option>
          <option v-for="flowOption in locationsList" :value="flowOption.id" :key="'location_'+flowOption.id">
            {{ flowOption.name }}
          </option>
        </b-select>
      </b-field>
    </div>

    <!-- mostrar actividades cerradas -->
    <div class="w-full">
      <v-switch
        :label="$t('show_closed_activites')"
        :color="filterShowClosed ? 'green' : 'red'"
        v-model="filterShowClosed"
        v-on:checked="(value) => filterShowClosed = value"
        />
    </div>

  </div>
</template>

<script>
  export default {

    components: {

    },

    props: {
      locationsList: { type: Array, required: true },
    },

    data: () => ({
      filterName: '',
      filterLocation: null,
      filterShowClosed: false, // show closed activities
    }),

    methods: {
      generateFilters() {

        let params = {};

        // Filtro por nombre
        if (this.filterName.length >= 3) {
          params.n = this.filterName
        }
        //Filtro por creador
        if (this.filterLocation !== null) {
          params.location_id = this.filterLocation
        }
        //Filtro mostrar cerradas
        if (this.filterShowClosed) {
          params.filterShowClosed = this.filterShowClosed
        }

        return params

      },

      applyFilters() {
        let args = this.generateFilters();
        // this.$emit('foldersfilters-updated', args);
      },

      cleanFilters() {
        this.filterName = '';
        this.filterLocation = null;
        this.filterShowClosed = false;
        this.page = 1; //TODO no deberíamos paginar a esta altura
        this.applyFilters();
      },

      checkInputTextFilter(filter) {
        if (filter.length >= 3 || filter.length == 0) {
          this.applyFilters()
        }
      },
    }
  };
</script>

<style scoped>
</style>
