<template>
  <div>
    <div v-if="isLoading" class="text-center my-4">
      <scale-loader></scale-loader>
    </div>
    <div v-else>
      <div class="panel" v-if="ready">
        <form @submit.prevent="updateGroup">


          <div class="w-1/3 flex-col">

            <div class="flex-col">
              <label form="name_id">{{ $t('name')}}</label>
              <v-input
                title="name"
                id="name_id"
                required="true"
                v-model="formChangeGroup.name"
                name="name"
                placeholder="name"
              />
            </div>

            <div class="flex-col mt-4">
              <label form="description_id">{{ $t('description')}}</label>
              <v-input
                title="description"
                id="description_id"
                required="true"
                v-model="formChangeGroup.description"
                name="description"
                placeholder="description"
              />
            </div>

            <!-- Imagen del grupo -->
            <div class="flex-col mt-4">
              <b-field class="file is-primary" :class="{'has-name': !!this.image}" v-if="this.groupId">
                <div class="w-1/2 pr-4">
                  <label class="label">{{$t('group_image')}}</label>
                  <img :src="this.image" class="w-full" v-if="this.image" style="box-shadow: 1px 1px 3px #d5d5d5">
                  <div v-else><scale-loader></scale-loader></div>
                </div>
                <div class="w-1/2 pr-4">
                  <label class="label">{{$t('change_image')}}</label>
                  <b-upload  class="file-label" @input="uploadImage">
                    <span class="file-cta">
                      <b-icon class="file-icon" icon="upload" pack="fas"></b-icon>
                      <span class="file-label">{{$t('upload_image')}}</span>
                    </span>
                  </b-upload>
                </div>
              </b-field>
            </div>

            <div class="flex-col mt-4">
              <label form="projects_id">{{ $t('projects_associated')}}</label>
              <b-taginput
                v-model="formChangeGroup.projects"
                :data="filteredProjects"
                autocomplete
                field="name"
                :placeholder="$t('select_project_menu')"
                @typing="getFilteredProjects">
                <template v-slot="props">
                    {{props.option.name}}
                </template>
                <template #empty>
                    {{ $t('empty_projects') }}
                </template>
            </b-taginput>
            </div>

            <div class="flex flex-row mt-4">
                <button class="btn btn-blue" nativeType="submit">
                  {{$t('save')}}
                </button>

                <a @click="$router.go(-1)" class="btn btn-light ml-2">
                  {{$t('cancel')}}
                </a>
            </div>

          </div>

        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import Form from 'vform'
  import axios from 'axios'
  import store from '~/store'
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

  export default {
    middleware: 'auth',
    // title: 'userform_page_title',
    // subtitle: 'userform_page_subtitle',
    menuOption: '0-2',

    components: {
      ScaleLoader
    },

    data: () => ({
      formChangeGroup: new Form({
        name: '',
        description: '',
        projects: null
      }),
      ready: false,
      groupId: 0,
      isLoading: false,
      isSelectOnly: false,
      projects: [],
      filteredProjects: [],

      image: '',
    }),

    created(){
      this.getProjects();

    },
    mounted() {
      let self = this

      // Capturamos la id del usuario si existe
      this.groupId = store.state.route.params.id;


      if (this.groupId) {
        this.groupId = parseInt(this.groupId)
        this.getGroup()
      } else {
        this.ready = true
      }
    },

    methods: {
      async getProjects(){
        const { data } = await axios.get("/api/v2/user/projects", {'params' : { showArchive : 1, group: null, mappedForSelect: true }});
        this.filteredProjects = data.projects;
        this.projects = data.projects;
      },

      getFilteredProjects(text) {
        this.filteredProjects = this.projects.filter((option) => {
            return option.name
                .toString()
                .toLowerCase()
                .indexOf(text.toLowerCase()) >= 0
        })
      },

      getGroup() {
        let self = this;
        axios.get('/api/v2/group/' + this.groupId)
          .then(function (response) {

            self.formChangeGroup.name = response.data.data.name;
            self.formChangeGroup.description = response.data.data.description;
            self.formChangeGroup.projects = response.data.data.projects;
            self.image = response.data.data.image;
            self.ready = true
          })
          .catch(function (error) {
            self.$notify.error('error_retreving_group_info')
          });
      },

      async updateGroup() {

        let formData = new FormData();
        formData.append('name', this.formChangeGroup.name);
        formData.append('description', this.formChangeGroup.description);
        formData.append('projects', JSON.stringify(this.formChangeGroup.projects));


        var url = this.groupId ? '/api/v2/group/' + this.groupId : '/api/v2/group'

        let self = this
        this.isLoading = true

        await axios.post(url, formData)
          .then(function (response) {
            self.isLoading = false
            if (response.data.success == true) {
              self.$notify.success('group_edited')

              if (url.endsWith('/' + response.data.group.id)) {
                self.$router.push({
                  name: 'admin.groups'
                })
              } else {
                self.image = response.data.group.image
                self.groupId = response.data.group.id
              }

            }
          })
          .catch(error => {
            self.isLoading = false
            console.log(error.response);
            self.$notify.error(error.response);

          });

      },

      async uploadImage(file) {
        let url = "/api/v2/group/" + this.groupId + "/image";

        let self = this
        self.image=''; // reset image, show 'uploading...'

        const formData = new FormData();
        formData.append('image', file);

        await axios.post(url, formData)
          .then(function (response) {
            if (response.data.success && response.data.image) {
                self.image = response.data.image;
            }
            else
              console.log('Error uploading image',response);
          })
      },
    },
  };
</script>
