<template>
  <div>
    <div v-if="isLoading" class="text-center my-4">
      <scale-loader></scale-loader>
    </div>
    <div v-else>

      <div class="flex">
        <router-link :to="{ name: 'admin.partners' }">&lt; {{$t('back')}}</router-link>
      </div>

      <div class="panel" v-if="ready">

        <form @submit.prevent="updatePartner">


          <div class="flex items-center mb-4">

            <div class="w-1/4 mr-4">
              <label for="name_id">{{ $t('name')}}</label>
              <v-input
                title="name"
                id="name_id"
                required="true"
                v-model="formChangePartner.name"
                name="name"
                placeholder="name"
              />
            </div>

            <div class="w-1/4 mr-4">
              <label for="cif_id">{{ $t('cif')}}</label>
              <v-input
                title="cif"
                id="cif_id"
                v-model="formChangePartner.cif"
                name="cif"
                placeholder="cif"
              />
            </div>

            <div class="w-1/4 mr-4">
              <label for="code_id">{{ $t('code')}}</label>
              <v-input
                title="code"
                id="code_id"
                v-model="formChangePartner.code"
                name="code"
                placeholder="code"
              />
            </div>

            <div class="w-1/4 mr-4">
              <label for="email_id">{{ $t('email')}}</label>
              <v-input
                title="email"
                id="email_id"
                required="true"
                v-model="formChangePartner.email"
                name="email"
                placeholder="email"
              />
            </div>


          </div>

          <div class="flex items-center mb-4">

            <div class="w-1/4 mr-4">
              <label for="type_id">{{ $t('type')}}</label>
              <v-select
                title="type"
                select-name="type_name"
                select-id="type_id"
                :selectedValue="parseInt(formChangePartner.type)"
                :data="partnerType"
                v-on:updateValue="updateType"/>
            </div>

            <div class="w-1/4 mr-4">
              <label for="area_id">{{ $t('area')}}</label>
              <v-select
                title="area"
                select-name="area_name"
                select-id="area_id"
                required="true"
                :selectedValue="parseInt(formChangePartner.area_id)"
                :data="areas"
                v-on:updateValue="updateArea"/>
            </div>
            <!-- <b-tooltip :label="$t('area_partner_help')" :position="is-left" :multilined="true"> -->
            <b-tooltip :label="'Los usuarios creados en esta empresa perteneceran a este area'" position="is-left" :multilined="true">
              <b-icon icon="info" size="is-small" class="mr-1 float-left"/>
            </b-tooltip>
          </div>

          <div class="flex items-center mb-4">

            <div class="flex flex-row mt-4">
                <button class="btn btn-blue" nativeType="submit">
                  {{$t('save')}}
                </button>

                <a @click="$router.go(-1)" class="btn btn-light ml-2">
                  {{$t('cancel')}}
                </a>
            </div>

          </div>

        </form>
      </div>

      <div v-if="ready && !creatingNew"
        class="bg-blue-lightest border-t border-b border-blue text-blue-dark px-4 py-3" role="alert">
        <label class="font-bold">Usuarios y maquinaria</label>
        <table>
        <tr><td>Total trabajadores</td><td class="font-bold pl-2"> {{ totalWorkers }}</td></tr>
        <tr><td>Trabajadores asignados a actividades</td><td class="font-bold pl-2"> {{ totalWorkersAssigned }}</td></tr>
        <tr><td>Total maquinaria</td><td class="font-bold pl-2"> {{ totalMachineries }}</td></tr>
        </table>
      </div>

      <div class="flex items-center mb-4" v-if="!creatingNew">

        <div class="flex flex-row mt-4">

            <router-link
              :to="'/admin/partner/'+ partnerId +'/users'"
              class="btn btn-blue rounded"><b-icon icon="users" pack="fas"/>
              &nbsp; {{ $t('manage_workers') }}
            </router-link>
            <router-link
              :to="'/admin/partner/'+ partnerId +'/machineries'"
              class="btn btn-blue rounded ml-4"><b-icon icon="tools" pack="fas"/>
              &nbsp; {{ $t('manage_machinery') }}
            </router-link>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
  import Form from 'vform'
  import axios from 'axios'
  import store from '~/store'
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

  export default {
    middleware: 'auth',
    // title: 'userform_page_title',
    // subtitle: 'userform_page_subtitle',
    // menuOption: '0-2',

    components: {
      ScaleLoader
    },

    data: () => ({
      formChangePartner: new Form({
        name: '',
        cif: '',
        code: '',
        email: '',
        type: 0,
        area_id: 0,
      }),
      totalWorkers: 0,
      totalWorkersAssigned: 0,
      totalMachineries: 0,
      ready: false,
      partnerId: 0,
      creatingNew: false,
      isLoading: false,
      partnerType: [{id: 0, name: 'UTE'}, {id: 1, name: 'Cliente'}, {id: 2, name: 'Subcontrata'}, {id:3, name:'Proveedor'}],
      areas: []
    }),

    mounted() {

      // Capturamos el ID partner si existe
      this.partnerId = store.state.route.params.id;
      this.creatingNew = store.state.route.params.id ? false: true; // creando o editando?


      if (this.partnerId) { //editar
        this.partnerId = parseInt(this.partnerId)
        this.getPartner()
      } else { // crear nuevo
        this.ready = true
      }
    },

    created(){
      this.getAreas();
    },

    methods: {

      async getAreas(){
        const { data } = await axios.get("/api/v2/user/areas");
        this.areas = data.areas;
        this.areas.unshift({id: 0, name: this.$t('select')})
      },

      updateType(value) {
        this.formChangePartner.type = parseInt(value)
      },

      updateArea(value) {
        this.formChangePartner.area_id = parseInt(value)
      },

      getPartner() {
        let self = this;
        axios.get('/api/v2/partner/' + this.partnerId)
          .then(function (response) {

            self.formChangePartner.name = response.data.data.name;
            self.formChangePartner.cif = response.data.data.cif;
            self.formChangePartner.code = response.data.data.code;
            self.formChangePartner.email = response.data.data.email;
            self.formChangePartner.type = response.data.data.type;
            self.formChangePartner.area_id = response.data.data.area_id;
            self.totalWorkers = response.data.data.users_count;
            self.totalWorkersAssigned = response.data.data.users_assigned_count;
            self.totalMachineries = response.data.data.machineries_count;
            self.ready = true
          })
          .catch(function (error) {
            self.$notify.error('error_retrieving_data')
          });
      },

      async updatePartner() {

        if(this.formChangePartner.area_id == 0) {
          this.$notify.error('Select area')
          return false;
        }

        let formData = new FormData();

        formData.append('name', this.formChangePartner.name);
        formData.append('cif', this.formChangePartner.cif);
        formData.append('code', this.formChangePartner.code);
        formData.append('email', this.formChangePartner.email);
        formData.append('type', this.formChangePartner.type);
        formData.append('area_id', this.formChangePartner.area_id);

        var url = this.partnerId ? '/api/v2/partner/' + this.partnerId : '/api/v2/partner'

        let self = this
        this.isLoading = true

        await axios.post(url, formData)
          .then(function (response) {
            self.isLoading = false

            if (response.data.success == true) {
              // now create admin user
              if(self.creatingNew) {
                // self.createAdmin(response.data.data.id);
              }

              self.$notify.success('success_editing')
              self.$router.push({
                name: 'admin.partners'
              })
            }
          })
          .catch(error => {
            self.isLoading = false
            self.$notify.error(error.response);
          });

      },

    },
  };
</script>
