<template>
<div>

  <div class="flex flex-wrap">
    <v-button icon="arrow-left" @click="goToPage('edit-' + entity)">{{ $t(entity) }}</v-button>
    <v-button icon="calculator" @click="calculateAutomaticFields()" class="ml-4" v-if="extrafields.length">{{ $t('recalculate_fields') }}</v-button>
  </div>

  <div class="panel">
    <div v-if="isLoading" class="my-4 text-center">
      <scale-loader />
    </div>
    <div v-else class="flex flex-wrap">
        <div v-if="fieldsRequired" class="w-full mb-5">
          <div class="border-t-4 rounded-b px-4 py-3 shadow-md" style="background-color: rgba(255,245,245,1); border-color:rgba(245,101,101,1);color: rgba(197,48,48,1);" role="alert">
            <div class="flex">
              <div class="py-1">
                <svg class="fill-current h-6 w-6 mr-4" style="color:rgba(252,129,129,1)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                </svg>
              </div>
              <div>
                <p class="font-bold">Campos extras</p>
                <p class="text-sm">La actividad se encuentra en el estado <b>{{ statusName }}</b>, los siguientes campos marcados son obligatorios.</p>
              </div>
            </div>
          </div>
        </div>

        <div v-for="field in extrafields" :key="field.extrafield_id" class="w-1/2 pr-4 pb-4 flex">

          <div :class="'w-5/6'">

            <!-- tipo select -->
            <l-form-select v-if="field.field && field.field.field_type == 0"
              :form="form"
              :field="field.extrafield_id.toString()"
              :options="field.field.values"
              :label="labelName(field)"
              optionDisplayField="name"
            />

            <!-- tipo input -->
            <l-form-input v-if="field.field && field.field.field_type == 1"
              ref="activityName"
              :form="form"
              :field="field.extrafield_id.toString()"
              :label="labelName(field)"
              :disabled="field.field.is_automatic && field.is_automatic && field.value_free ? true : false"
            />

            <!-- tipo calendar -->
            <b-field v-if="field.field && field.field.field_type == 2"
              :label="labelName(field)"
              :type="form.errors.has(field.extrafield_id.toString()) ? 'is-danger' : ''"
              :message="form.errors.has(field.extrafield_id.toString()) ? form.errors.get(field.extrafield_id.toString()) : null">
              <b-datepicker
                  v-model="form[field.extrafield_id.toString()]"
                  :label="field.field.name"
                  :placeholder="field.field.name"
                  :month-names="$t('months_names')"
                  :day-names="$t('days_names')"
                  :first-day-of-week="parseInt($t('first_day_of_week'))"
                  :readonly="false"
                  icon="calendar"
                  icon-pack="fas"
                  :disabled="field.field.is_automatic && field.is_automatic && field.value_free ? true : false"
                  editable
                >
                </b-datepicker>
            </b-field>

            <!-- tipo checkbox -->
            <b-field v-if="field.field && field.field.field_type == 3"
              :label="labelName(field)">
              <v-switch
                :label="' '"
                :color="form[field.extrafield_id.toString()] ? 'green' : 'red'"
                v-model="form[field.extrafield_id.toString()]"
                v-on:checked="form[field.extrafield_id.toString()] = !form[field.extrafield_id.toString()]"
                style="margin-left:15px; margin-top:3px "
                :disabled="field.field.is_automatic && field.is_automatic && field.value_free ? true : false"
              />
            </b-field>

          </div>

          <div class="w-1/6">
            <!-- si el campo raiz es automatico, permitir aqui a este campo concreto establecer automatico/manual -->
            <v-switch
              v-if="field.field.is_automatic"
              :label="form.automaticList[field.extrafield_id.toString()] ? 'Automatico' : 'Manual'"
              :ref="'switch'+field.extrafield_id.toString()"
              v-model="form.automaticList[field.extrafield_id.toString()]"
              v-on:checked="(value) => setAutomatic(field, value)"
              style="margin-left:15px; margin-top:15px "
            />
          </div>

        </div>


        <div v-if="!extrafields.length" class="w-full">
          <div class="border-t-4 rounded-b px-4 py-3 shadow-md" style="background-color: rgba(235,248,255,1); border-color:rgba(66,153,225,1);color: rgba(30,58,138,1);" role="alert">
            <div class="flex">
              <div class="py-1">
                <svg class="fill-current h-6 w-6 mr-4" style="color:rgba(66,153,225,1)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                </svg>
              </div>
              <div>
                <p class="font-bold">Campos extras</p>
                <p class="text-sm">La actividad no tiene asignado ningun campo extra.</p>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="w-full">
          <font >⚙️ Campos calculados automaticamente por el sistema</font>
        </div>

    </div>

  </div>
  <form @submit.prevent="sendActivityData" @keydown="form.onKeydown($event)" v-if="extrafields.length"
    class="w-full flex items-center justify-end mb-8">
    <v-button :disabled="disableSaveButton" type="submit" icon="thumbs-up" icon-pack="far" class="mr-4">{{ $t('save') }}</v-button>
    <v-button color="grey" icon="undo-alt" @click="$router.go(-1)">{{ $t('cancel') }}</v-button>
  </form>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import Vue from "vue"
import axios from 'axios'
import Form from 'vform'
import moment from 'moment'
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'activity_edit_page_title',
  subtitle: 'activity_edit_page_subtitle',
  menuOption: '2-2',
  components: {
    ScaleLoader,
  },
  data: () => ({
    project: {},
    activity: {},
    extrafields: [],

    projectId: null, // for mplichy
    activityId: null,
    subactivityId: null,
    isSubactivity: false,
    entity: null, // for mplichy
    form: new Form({
      'automaticList': [] // listado checkboxes campo automatico/manual. Declarado dinamicamente
      // seran declarados dinamicamente
    }),
    disableSaveButton: false,
    isLoading: false,
    fieldsRequired: 0,
    statusName: '',
  }),


  created() {

    this.projectId = this.$route.params.project_id || null
    this.activityId = this.$route.params.activity_id || null
    this.subactivityId = this.$route.params.subactivity_id || null
    this.isSubactivity = this.$route.name === 'subactivity.extrafields.edit'
    this.entity = this.projectId ? 'project' : 'activity'

    if( this.projectId )
    {
      this.$options.title = 'project_edit_page_title'
      this.$options.subtitle = [
        "project_page_subtitle",
        [this.navData.area_name.toUpperCase()]
      ];
      this.$options.menuOption = "0-2"
    }

  },

  computed: {
    ...mapGetters({
      navData: "app/navData",
    }),
  },

  mounted() {
    this.getEntityData()
  },


  methods: {

    async getEntityData() {
      let self = this
      let id = self.projectId
      if( self.entity === 'activity' )
        id = this.isSubactivity ? this.subactivityId : this.activityId
      this.isLoading = true
      const { data } = await axios.get('/api/v2/' + self.entity + '/' + id + '/extrafields-assigned')
      this.isLoading = false

      if (data && data.success) {
        this.parserData(data)

        // self.extrafields = data.data.extrafields

        // self.extrafields.forEach(function(field){
        //   if (field.field && field.field.field_type == 2) { // calendar
        //     // let fieldValue = field.value_free ? moment(field.value_free).format('YYYY-MM-DD') : null; // asi si almacenara fecha iso8601
        //     // fieldValue = fieldValue ? new Date(fieldValue) : fieldValue
        //     let fieldValue = field.value_free ? new Date(field.value_free) : null;
        //     self.form[field.extrafield_id.toString()] = fieldValue
        //   } else if (field.field && field.field.field_type == 3) {
        //     self.form[field.extrafield_id.toString()] = Boolean(parseInt(field.value_free)) || false
        //   } else {
        //     self.form[field.extrafield_id.toString()] = field.value_id || field.value_free || null
        //   }
        // })
      } else {
        this.$notify.error('error_loading_activity_data')
      }
    },


    async sendActivityData(showConfirm=true) {
      var url = '/api/v2/' + this.entity + '/' + this.getEntityId() + '/extrafields-update';

      let self=this
      // a cada atributo del form
      Object.keys(this.form).forEach(function(value) {
        // a los de tipo calendar (almacenan una fecha) nos quedamos solo con la fecha absoluta (sin hora ni timezone)
        if (self.form[value] instanceof Date) {
          self.form[value] = moment(self.form[value]).format('YYYY/MM/DD')
        }
      });

      // Enviamos el form
      const { data } = await this.form.post(url, this.form)
      if (data && data.success) {

        this.parserData(data);

        if (showConfirm) {
          let txt = this.isSubactivity ? 'success_editing_subactivity' : 'success_editing_activity'
          this.$notify.success(txt)
          // this.$router.push({ name: 'subactivity.home', params: {
          //   subactivity_id: this.subactivityId, activity_id: this.activityId
          // } })
        }
      } else {
        this.$notify.error('error_saving')
      }
    },


    async calculateAutomaticFields() {

      // Guardar lo que hay por si usuario hace cambios
      await this.sendActivityData(false)

      let self = this
      let id = self.projectId
      if( self.entity === 'activity' )
        id = this.isSubactivity ? this.subactivityId : this.activityId

      const { data } = await axios.get('/api/v2/' + self.entity + '/' + id + '/extrafields-calculate')

      if (data && data.success) {

        this.parserData(data);

        this.$notify.success('fields_recalculated')

      } else {
        this.$notify.error('error_loading_activity_data')
      }
    },


    getEntityId() {
      if( this.projectId )
        return this.projectId
      return this.isSubactivity ? parseInt(this.subactivityId) : parseInt(this.activityId)
    },


    goToPage(destiny = 'extrafields') {

      switch (destiny) {

        case 'edit-activity':
            if (this.isSubactivity) {
              this.$router.push({ name: 'subactivity.edit', params: {
                subactivity_id: this.subactivityId, activity_id: this.activityId
              } })
            } else {
              this.$router.push({ name: 'activity.edit', params: {
                activity_id: this.activityId
              } })
            }
            break;
        case 'edit-project':
            this.$router.push({ name: 'project.edit', params: {
              project_id: this.projectId
            } })
            break;

        case 'streets':
            break;
      }

    },

    parserData(data) {
        let self = this;
        self.extrafields = data.data.extrafields
        let statusId = data.data.status_id //the status activity
        self.fieldsRequired = 0

        self.extrafields.forEach(function(field) {

          if (field.field && field.field.field_type == 2) { // calendar
            // let fieldValue = field.value_free ? moment(field.value_free).format('YYYY-MM-DD') : null; // asi si almacenara fecha iso8601
            // fieldValue = fieldValue ? new Date(fieldValue) : fieldValue
            let fieldValue = field.value_free ? new Date(field.value_free) : null;
            self.form[field.extrafield_id.toString()] = fieldValue
          } else if (field.field && field.field.field_type == 3) {
            self.form[field.extrafield_id.toString()] = Boolean(parseInt(field.value_free)) || false
          } else {
            self.form[field.extrafield_id.toString()] = field.value_id || field.value_free || null
          }

          if (field.field.statuses) {
            let indexStatus = field.field.statuses.findIndex( o => o.id == statusId )

            if (indexStatus != -1 ){
              if(self.statusName == '')
                self.statusName = field.field.statuses[indexStatus].name
              // if( !field.value_id && !field.value_free) {
              if( field.value_id === null && field.value_free === null) {
                ++self.fieldsRequired
                self.form.errors.set(field.extrafield_id.toString(), "Campo requerido en este estado")
              }
            }
          }

          // tratamiento del atributo is_automatic
          // field.field.is_automatic (tabla extrafield) el flag 'general' del extrafield que indica que es un campo de calculo automatico
          // field.is_automatic (tabla extrafield_fieldables) el flag 'particular' de este campo que indica que, dentro de ser un campo automatico, se puede desactivar este comportamiento y hacerlo manual
          if (field.field.is_automatic) {
            self.form.automaticList[field.extrafield_id.toString()] = field.is_automatic
          }

        })
    },


    labelName(field)
    {
      return field.field.is_automatic 
        ? field.field.name + ' ⚙️'
        : field.field.name
    },


    setAutomatic(field, value)
    {
      this.form.automaticList[field.extrafield_id.toString()] = value;
      // este cambio no es detectado por el elto v-switch en su :label
      // asi que lo forzamos a lo bruto a cambiar el texto de la etiqueta.
      // arroja un warning en consola que podemos ignorar
      let txt = value ? 'Automatico' : 'Manual'
      this.$refs['switch'+field.extrafield_id.toString()][0]['_props']['label'] = txt
    }

  },
};
</script>
