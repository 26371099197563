<template>
  <div class="page-with-top-bar">

    <project-admin-quick-buttons hiddenOption="partners"/>

    <div class="panel">
      <div v-if="!loaded" class="text-center my-4">
        <scale-loader></scale-loader>
      </div>
      <div v-else>
        <div class="w-full flex flex-wrap mt-4">

          <section class="w-full mt-4">
            <label class="font-bold">Ordenes de pedido vinculados con la empresa: </label> {{ partner.name }}<br>
            <label class="font-bold">en el proyecto '{{ navData.project_name }}'</label>
            <p><br></p>

            <div class="top-bar flex justify-end">
              <!-- <v-button class="mr-2"
                @click="_HandleWorkOrdersLink"
                icon="link">
                Vincular pedidos
              </v-button> -->

              <v-button
                @click="_HandleWorkOrdersAdd"
                icon="plus">
                Nueva orden de pedido
              </v-button>
            </div>

            <b-table
              :data="partnerOrders"
              :paginated="isPaginated"
              :per-page="perPage"
              :bordered="true"
              :current-page.sync="currentPage"
              :pagination-simple="isPaginationSimple"
              :default-sort-direction="defaultSortDirection"
              icon-pack="fas"
              v-if="loaded">
            <template>

              <b-table-column field="code" sortable>
                <template v-slot:header="">{{ $t('code') }}</template>
                <template v-slot="props">{{ props.row.code }}</template>
              </b-table-column>

              <b-table-column field="description" sortable>
                <template v-slot:header="">{{ $t('description') }}</template>
                <template v-slot="props">{{ props.row.description }}</template>
              </b-table-column>

              <b-table-column field="actions" width=100 sortable>
                <template v-slot:header="">{{ $t('actions') }}</template>
                <template v-slot="props">
                  <div class="w-full h-full flex items-center justify-around">

                    <b-tooltip :label="$t('purchase_order')" position="is-left" type="is-info">
                      <div class="cursor-pointer text-blue" @click="downloadDoc(props.row.document_id)">
                        <b-icon pack="fas" icon="file-lines"/>
                      </div>
                    </b-tooltip>

                    <b-tooltip :label="$t('edit')" position="is-left">
                      <div @click="_HandleWorkOrdersEdit(props.row)" class="cursor-pointer flex text-blue">
                        <b-icon pack="fas" icon="pencil-alt"/>
                      </div>
                    </b-tooltip>

                    <b-tooltip :label="$t('remove')" position="is-left" type="is-danger">
                      <div @click="unAssignPartner(props.row.id, props.row.name)"
                          class="cursor-pointer flex text-red">
                        <b-icon pack="fas" icon="trash-alt"/>
                      </div>
                    </b-tooltip>

                  </div>
                </template>
              </b-table-column>
            </template>

            <template slot="bottom-left">
                <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                <b>{{ $t("total") }}</b>: {{ this.partnerOrders.length }}
            </template>

            <template #empty>
                <div class="has-text-centered">{{ $t('no_results') }}</div>
            </template>

            </b-table>
          </section>
        </div>

        <work-orders-edit-modal
          ref="refWorkOrdersEditModal"
          :project-id="projectId"
          @reload="() => _HandleRefreshWorkOrders()"
        />

        <work-orders-link-modal
          ref="refWorkOrdersLinkModal"
          :project-id="projectId"
          :partner="partner"
          @reload="() => _HandleRefreshWorkOrders()"
        />
      </div>
      </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import axios from 'axios'
  import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons'
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
  import WorkOrdersEditModal from '../../components/work-orders/work-orders-edit-modal.vue'
  import WorkOrdersLinkModal from '../../components/work-orders/work-orders-link-modal.vue'

  export default {
    middleware: 'auth',
    title: 'partner_work_orders_page_title',
    menuOption: '0-2',

    components: {
      ProjectAdminQuickButtons,
      ScaleLoader,
      WorkOrdersEditModal,
      WorkOrdersLinkModal
    },

    computed: {
      ...mapGetters({
        navData: "app/navData",
      }),
      projectId() {
        return parseInt(this.navData.project_id)
      },
    },

    data: () => ({
      partnerOrders: [],
      isPaginated: true,
      isPaginationSimple: false,
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 20,
      loaded: false,
      allPartners: [],
      filteredPartners: [],
      partnersToAdd: [],

      partnerId: null,
      partner: null
    }),

    created() {
      let projectName = this.navData.project_name
        ? this.navData.project_name.toUpperCase()
        : "";
      this.$options.subtitle = ["project_actions_page_subtitle", [projectName]];
      this.partnerId = this.$route.params.partner_id;

      this.getPartnerOrders();
    },

    methods: {

      // partners de este proyecto
      async getPartnerOrders() {

        const { data } = await axios.get("/api/v2/partner/"+this.partnerId, {'params' : {
          with_orders: 1
        }});

        if (data.data) {
          this.partner = data.data
          this.partnerOrders = data.data.orders;
          this.loaded = true
        }

      },

      // de todos, filtra los que cumplen string de busqueda (y no estaban ya vinculados)
      getFilteredPartners(text) {
        this.filteredPartners = this.allPartners.filter((option) => {
          return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
            &&
            !this.alreadyInserted(option)
        })
      },

      // comprueba si un partner está ya vinculado al proyecto
      alreadyInserted(partner) {
        return this.partnerOrders.some((option) => option.id === partner.id )
      },

      async assignPartners() {

        let formData = new FormData();
        formData.append('partners', JSON.stringify(this.partnersToAdd));

        let self = this
        this.loaded = false
        let url = '/api/v2/project/' + this.projectId + '/partners/add'

        const { data } = await axios.post(url, formData)

        if (data.success) {
          this.$toast.open({
            message: 'Ok',
            type: 'is-success',
            position: 'is-top-right'
          })
          self.partnersToAdd = []
          self.getpartnerOrders()
        } else {
          self.loaded = true
          // Error
          self.$toast.open({
            message: data.error, //self.$t('import_generic_error_msg'),
            type: 'is-danger',
            position: 'is-top-right'
          })
        }
      },

      unAssignPartner(partnerId, partnerName) {

        let self = this;
        this.$dialog.confirm({
          title: this.$t('unlink'),
          message: this.$t('unlink_partner_confirm_text', [partnerName]),
          confirmText: this.$t('delete'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            let url = '/api/v2/project/' + this.projectId + '/partners/remove'
            const {data} = await axios.post(url, { partnerId: partnerId })
            if (data && data.success) {
              this.loaded = false
              this.$notify.success('success_deleting_partner')
              self.getpartnerOrders()
            } else {
              this.$notify.error(data.error)
            }
          }
        })
      },




      _HandleRefreshWorkOrders() {
        // this.$refs.refWorkOrdersList.reload();
        this.getPartnerOrders()
      },

      _HandleWorkOrdersEdit(order) {
        this.$refs.refWorkOrdersEditModal.show(order)
      },

      _HandleWorkOrdersAdd() {
        this.$refs.refWorkOrdersEditModal.show({
          id: null,
          partner_id: this.partnerId,
          code: '',
          description: ''
        })
      },

      _HandleWorkOrdersLink() {
        this.$refs.refWorkOrdersLinkModal.show({})
      },

      async downloadDoc(document_id) {
        this.$notify.success("starting_download_document");
        let url = "/api/v2/document/" + document_id;

        const {data} = await axios.get(url);

        if (data) {
          window.open(data.data);
        } else {
          this.$notify.error("error_download_document");
        }
      },

    }
  };
</script>
