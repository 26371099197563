<template>
  <div>
    <div class="top-bar flex justify-end">
      <v-button
        @click="() => { openFormImportEntities = true }"
        icon="file-import"
        :disabled="openFormDataActivity"
      >{{ $t('import_activities') }}</v-button>

      <v-button
        @click="addEntity"
        icon="plus"
        class="ml-4"
        :disabled="openFormImportEntities"
      >{{ $t('add_activity') }}</v-button>
    </div>
    <div class="flex">
      <div :class="[( openFormDataActivity || openFormImportEntities ? 'w-8/12' : 'w-full'), 'mr-2']">
        <template-list-activities ref="table_list"
          :projectId="projectId"
          :hideActions="!(openFormDataActivity || openFormImportEntities)"
          @epc-edit-entity="handleEditEntity"
          @epc-add-sub-entity="handleAddSubEntity"
        />
      </div>

      <transition name="fade">
        <div class="w-4/12 ml-2" v-if="openFormDataActivity">
          <template-activity-edit :projectId="projectId"
            :entity="entity"
            @epc-close="handleOnCloseEdit"
            @epc-success-creating="handleSuccess"
          />
        </div>

        <div class="w-4/12 ml-2" v-if="openFormImportEntities">
          <template-entity-import :projectId="projectId"
            @epc-close="(event) => { openFormImportEntities = false }"
            @epc-success-import="() => { openFormImportEntities = false; $refs.table_list.reload() }"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import TemplateListActivities from './template-list-activities.vue';
import TemplateActivityEdit from './template-activity-edit.vue';
import TemplateEntityImport from './template-entity-import.vue';

export default {
  name: "template-manage-activities",

  components: {
    TemplateListActivities,
    TemplateActivityEdit,
    TemplateEntityImport,
  },

  data: () => ({
    entity: null, // objeto que se crea
    openFormDataActivity: false,
    openFormImportEntities: false,
  }),

  props: {
    projectId: { type: Number, required: true },
  },

  mounted() {
  },

  methods: {
    addEntity() {
      this.entity = null
      this.openFormDataActivity = true;
    },

    handleOnCloseEdit(event) {
      this.openFormDataActivity = !event
    },

    handleSuccess(entity) { // recibe la entididad editada
      this.openFormDataActivity = false
      this.$refs.table_list.reload()
    },

    handleEditEntity(entity) {
      this.entity = entity
      this.openFormDataActivity = true;
    },

    handleAddSubEntity(parent) {
      this.entity = {}
      this.entity.project_id = parent.project_id
      this.entity.parent_id = parent.id
      this.entity.code = ''
      this.entity.name = ''
      this.entity.description = ''
      this.entity.weight = null
      this.entity.tags = ''
      this.entity.tagged = []
      this.openFormDataActivity = true;
    },
  }

};
</script>

<style scoped>

</style>
