<template>
<div>
  <div class="flex items-center justify-end w-full">
    <v-button
        @click="() => { showFormImportEntities = true }"
        icon="file-import"
        :disabled="showFormData"
        class="mr-2"
      >{{ $t('import_extrafields') }}</v-button>

    <v-button
      @click="addExtrafield(null)"
      icon="plus"
      :disabled="showFormImportEntities"
    >{{ $t('add_extrafield') }}</v-button>
  </div>

  <div class="flex mt-5">
    <!-- Columna Izquierda - Campos Extras -->
    <div :class="[( showPanel ? 'w-8/12' : 'w-full'), 'mr-2']">
      <extrafields ref="list_extrafields"
        :projectId="projectId"
        :apiEntity="'store-extrafield'"
        @epc-edit="onEdit"
        @epc-show-values="onShowValues"
        @epc-show-assignments="onShowAssignments"
        @epc-checked-rows="onCheckedRows"
      />
    </div>

    <!-- Columna derecha -->
    <transition name="fade">

      <div v-if="showFormData" class="w-4/12 ml-2">
        <!-- Extrafields -->
        <extrafield
          :projectId="projectId"
          :extrafield="extrafield"
          api-entity="store-extrafield"
          @epc-close="handleOnCloseEdit"
          @epc-success-creating="handleSuccess"
        />
      </div>

      <div v-if="showPanelValues" class="w-4/12 ml-2">
        <!-- Elements Attribute Value -->
        <extrafield-values
          :extrafield="extrafield"
          :apiEntity="'store-extrafield'"
          @epc-close="handleOnCloseValues"
        />

      </div>

      <div v-if="showPanelLink" class="w-4/12 ml-2">
        <extrafield-assign
          :extrafields="checkedRows"
          :apiEntity="'store-extrafield'"
          @epc-close="handleOnCloseAssign"
        />
      </div>

      <div v-if="showPanelAssignments" class="w-4/12 ml-2">
        <extrafield-assignments
          :extrafields="extrafield"
          :apiEntity="'store-extrafield'"
          @epc-close="handleOnCloseAssigned"
        />
      </div>

      <div class="w-5/12 ml-2" v-if="showFormImportEntities">
        <extrafields-import
          :projectId="projectId"
          :entity-type="'store-extrafields'"
          @epc-close="(event) => { showFormImportEntities = false }"
          @epc-success-import="() => { showFormImportEntities = false ; $refs.list_extrafields.reload() }"
        />
      </div>

    </transition>

  </div>
</div>
</template>

<script>
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import Extrafields from '../extrafields/extrafields.vue';
import Extrafield from '../extrafields/extrafield.vue';
import ExtrafieldValues from '../extrafields/extrafield-values.vue';
import ExtrafieldAssign from '../extrafields/extrafield-assign.vue';
import ExtrafieldAssignments from '../extrafields/extrafield-assignments.vue';
import ExtrafieldsImport from '../extrafields/extrafields-import.vue';

export default {
  name: "manage-extrafields",

  components: {
    ScaleLoader,
    Extrafields,
    Extrafield,
    ExtrafieldValues,
    ExtrafieldAssign,
    ExtrafieldAssignments,
    ExtrafieldsImport,
  },

  props: {
    projectId: { type: Number, required: true },
  },

  data: () => ({
    extrafield: null,
    extraFieldId: null,

    loadingAssigned: false,
    processingData: false,

    disabled: false,
    linkActiveTab: 0,
    checkedRows: [],

    showFormImportEntities: false,
    showFormData: false,
    showPanelValues: false,
    showPanelLink: false,
    showPanelAssignments: false,
  }),

  computed: {
    showPanel: function() {
      return this.checkedRows.length > 0
        || this.showFormImportEntities || this.showFormData
        || this.showPanelValues || this.showPanelLink
        || this.showPanelAssignments ;
    },
  },

  mounted() {
  },

  methods: {
    addExtrafield() {
      this.extrafield = null
      this.showFormData = true;
    },

    handleOnCloseEdit(event) {
      this.showFormData = !event
    },

    handleOnCloseValues(event) {
      this.showPanelValues = !event
    },

    handleOnCloseAssign(event) {
      this.showPanelLink = !event
    },

    handleOnCloseAssignments(event) {
      this.showPanelAssignments = !event
    },

    handleSuccess(entity) { // recibe la entidad editada
      this.showFormData = false
      this.$refs.list_extrafields.reload()
    },

    onEdit(entity) {
      this.extrafield = entity
      this.showFormData = true;
    },

    onShowValues(entity) {
      this.extrafield = entity
      this.showPanelValues = true;
    },

    async onShowAssignments(entity) {
      this.extrafield = entity
      this.showPanelAssignments = true;
    },

    reload() {
      this.getProjectExtrafields();
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    onCloseClicked() {
      this.checkedRows = [];
    },

    onCheckedRows(checkedList) {
      this.checkedRows = checkedList
      this.showPanelLink = true;
    },
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
  .b-table .table .checkbox-cell .checkbox {
    background-color: white !important;
    padding: 0;
    margin: 0;
    border-radius: 5px;
  }

  .b-checkbox.checkbox:not(.button) {
    margin-right: 0 !important;
  }
</style>
