<template>
  <div class="page-with-top-bar">

    <div class="flex justify-between w-full mt-4 below-top-bar">
    </div>

    <div class="border border-blue-lighter rounded bg-blue-lightest p-2">

      <div class="flex w-full">

      <div class="w-1/6 pr-4">
        <label>{{ $t('code') }}</label>
        <input v-model="newForm.code" type="text" name="" class="input" ref="codeField">
      </div>

      <div class="w-1/6 pr-4">
        <label>{{ $t('name') }}</label>
        <input v-model="newForm.name" type="text" name="" class="input">
      </div>

      <div class="w-1/6 pr-4">
        <label>{{ $t('select_type') }}</label>
        <b-select ref="input"
          v-model="newForm.flow_type"
          :placeholder="$t('select_type')"
          id="flow_type"
          name="flow_type"
          class="w-full"
          required
          expanded
        >
          <option v-for="(type, index) in templateTypes" :value="index" :key="index">
            {{ type }}
          </option>
        </b-select>
      </div>

      <div class="w-2/6 pr-4">
        <label>{{ $t('description') }}</label>
        <input v-model="newForm.description" type="text" name="" class="input">
      </div>

      <div class="w-1/6 pr-4">
        <label>&nbsp;</label>
        <button class="btn btn-green" type="button" @click="saveValidation()">
          <i class="fas fa-plus mr-1"></i> {{ $t('add_validation_flow') }}
        </button>
      </div>

      </div>

    </div>

    <div class="panel">
      <div v-if="!loaded" class="my-4 text-center">
        <scale-loader></scale-loader>
      </div>
      <div v-else>
        <div class="flex flex-wrap w-full mt-4">
        <section class="w-full">
          <b-table

          icon-pack="fas"
          :data="templates"
          :paginated="isPaginated"
          :per-page="perPage"
          :bordered="true"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :default-sort-direction="defaultSortDirection"
          v-if="loaded">
          <template>

            <b-table-column field="code" sortable>
              <template v-slot:header="">{{ $t('code') }}</template>
              <template v-slot="props">{{ props.row.code }}</template>
            </b-table-column>

            <b-table-column field="name" sortable>
              <template v-slot:header="">{{ $t('name') }}</template>
              <template v-slot="props">{{ props.row.name }}</template>
            </b-table-column>

            <b-table-column field="description" sortable>
              <template v-slot:header="">{{ $t('description') }}</template>
              <template v-slot="props">{{ props.row.description }}</template>
            </b-table-column>

            <b-table-column field="actions" width=100 sortable>
              <template v-slot:header="">{{ $t('actions') }}</template>
              <template v-slot="props">
                <div class="flex items-center justify-around w-full h-full">
                  <b-tooltip :label="$t('edit')" position="is-left">
                      <div @click="editTemplate(props.row.id)"
                          class="flex cursor-pointer text-blue">
                        <b-icon pack="fas" icon="pencil-alt"/>
                      </div>
                  </b-tooltip>
                  <b-tooltip :label="$t('remove')" position="is-left" type="is-danger">
                      <div @click="deleteTemplate(props.row.id, props.row.name)"
                          class="flex cursor-pointer text-red">
                        <b-icon pack="fas" icon="trash-alt"/>
                      </div>
                  </b-tooltip>
                </div>
              </template>
            </b-table-column>
          </template>
          <template slot="bottom-left">
              <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
              <b>{{ $t("total") }}</b>: {{ this.templates.length }}
          </template>

          <template #empty>
              <div class="has-text-centered">{{ $t('no_results') }}</div>
          </template>

          </b-table>
        </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  middleware: 'auth',
  title: 'validations_admin_page_title',
  subtitle: 'validations_admin_page_subtitle',
  menuOption: '0-5',
  components: {
    ScaleLoader
  },

  data: () => ({
    templates: [],
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 20,
    loaded: false,

    newForm: { code: '', name: '', description: '', flow_type: 0 },
    templateTypes: ['Estándar', 'Especificaciones Técnicas', 'Incidencias'],
  }),

  computed: {
    ...mapGetters({
      company: 'app/company',
    }),
  },

  mounted () {
    // this.partnerId = store.state.route.params.id;
    this.getTemplates()
  },

  methods: {

    async getTemplates () {
      const { data } = await axios.get("/api/v2/validations/templateImportsList", {'params' : { company_id: this.company.id }});

      if (data.templates) {
        this.templates = data.templates;
      } else {
        // Error
        console.log('error validation: ', data);
        this.$notify.error(data.msg || 'error_retrieving_data')
      }
      this.loaded = true
    },


    editTemplate(validationId) {
        this.$router.push({
          name: 'validation-template.edit', params: { validation_id: validationId, }
        })
      },


    deleteTemplate(templateId, templateName) {

      let self = this;
      this.$dialog.confirm({
        title: this.$t('delete'),
        message: this.$t('delete_generic', [templateName]),
        confirmText: this.$t('delete'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          // TODO si permitimos borrar, meter la api...
          let url = "/api/v2/validations/templates/" + templateId + "/delete";
          const { data } = await axios.post(url);

          if (data.success) {
            this.$toast.open({
              message: this.$t("success_deleting_generic"),
              type: "is-success",
              position: "is-top-right"
            });
            await this.sleep(1000);
            this.getTemplates();
          } else {
            // this.isLoading = false;
            // Error
            this.$toast.open({
              message: data.error || this.$t("error_saving"),
              type: "is-danger",
              position: "is-top-right"
            });
          }
        }
      })
    },

    async saveValidation() {

      let url = "/api/v2/validations/templates"

      let postData = Object.assign( { company_id: this.company.id }, this.newForm) // le fusiona el company_id al form

      // Enviamos el form
      try {
        const { data } = await axios.post(url, postData)

        if (data && data.success) {

          this.$notify.success('success_editing')
          this.getTemplates()
          this.newForm = { code: '', name: '', description: '' }

        } else if(data && data.msg){

          this.$notify.error('no_access_permissions')
          this.$router.push({ name: 'project.home', params: {} })

        } else {
          this.$notify.error('error_saving')
        }
      } catch(error){
        if (error.response && error.response.status === 422) {
          //extraemos el primer error de validación
          error.error_msg =
            error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ][0];
        }

        // Mostramos el error
        this.$toast.open({
          message: error.error_msg || this.$t("assignment_update_error"),
          type: "is-danger",
          position: "is-top-right",
        });
      }
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

  },


};
</script>
