<template>
    <b-modal :active.sync="isModalActive" :width="1028" scroll="keep" :can-cancel="canCancel" @close="closeModal">
        <div class="border border-blue-light rounded shadow bg-white p-4">
            <form @submit.prevent="saveBilling" class="w-full">
                <header class="font-thin uppercase text-blue-light modal-card-head_ mb-4">
                    <div class="flex justify-between">
                        <div>
                            Añadir / editar {{ $t('breakdown_name') }}
                        </div>
                        <div class="field">
                            <b-switch v-model="onlyPrice" type="is-info">{{ $t('Precio Coste') }}</b-switch>
                        </div>
                    </div>
                </header>
                <div class="modal-card-body_ relative">
                    <div class="flex flex-wrap mx-4 mb-3">
                        <div class="w-1/2 px-4 mb-3">
                            <b-field :label="$t('breakdown_name')" :message="resourceError"
                                :type="resourceError ? 'is-danger' : ''">
                                <multiselect v-model="selectedResource" :options="filteredResources"
                                    :placeholder="$t('select') + ' escribe lo que estas buscando'" :multiple="false"
                                    label="name" track-by="name" :show-labels="false" :searchable="true"
                                    :loading="isLoadingResources" noOptions="La lista esta vacía"
                                    @search-change="handleSearchChangeResources" :maxHeight="200"
                                    :class="{ 'is-danger': resourceError }" @input="handleResourceChange" />
                            </b-field>
                        </div>
                        <div class="w-1/2 px-4 mb-3">
                            <b-field :label="onlyPrice ? $t('cost_price') : $t('unit_price')">
                                <b-input v-model.number="formInvoiceItem.unit_price" title="unit_price" required
                                    name="unit_price" :placeholder="$t('unit_price')" maxlength="100" type="number"
                                    step="0.01" />
                            </b-field>
                        </div>
                    </div>
                    <!-- Condicionalmente muestra el campo de cantidad -->
                    <div v-if="!onlyPrice" class="flex flex-wrap mx-4 mb-3">
                        <div class="w-1/2 px-4 mb-3">
                            <b-field :label="$t('quantity')">
                                <b-input v-model.number="formInvoiceItem.quantity" title="quantity" required
                                    name="quantity" :placeholder="$t('quantity')" maxlength="100" type="number" />
                            </b-field>
                        </div>
                    </div>
                    <b-loading :is-full-page="false" v-model="processingData" :can-cancel="false"></b-loading>
                    <div class="flex flex-wrap mx-4 mb-3 justify-end">
                        <v-button type="submit" class="px-4">{{ $t('Crear') }}</v-button>
                    </div>
                </div>
            </form>
        </div>
    </b-modal>
</template>

<script>
import axios from "axios";
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect
    },
    props: {
        resources: {
            type: Array,
            required: true
        },
        projectId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            isModalActive: false,
            processingData: false,
            canCancel: ['escape', 'x', 'outside'],
            onlyPrice: false,
            formInvoice: {
                provider_id: null,
                code: '',
                total_amount: null,
            },
            formInvoiceItem: {
                id: 0,
                invoice_id: null,
                product_id: null,
                project_id: null,
                unit_price: null,
                quantity: null,
            },
            selectedResource: null,
            filteredResources: [],
            isLoadingResources: false,
            ajaxTimer: null,
            selectedProviderData: {},
            invoiceCode: '',
            billingDate: Date,
            dueDate: Date,
            totalInvoiced: null,
            invoiceId: null,
            resourceError: null,
            assignments: [],
            selectedAssignment: null,
            assignmentError: null
        }
    },
    watch: {
        resources: {
            immediate: true,
            handler(newResources) {
                this.filteredResources = newResources;
            }
        }
    },
    methods: {
        show({ resource = null, selectedProvider, invoiceCode, billingDate, dueDate, totalInvoiced }) {
            this.selectedProviderData = selectedProvider || {};
            this.invoiceCode = invoiceCode || '';
            this.billingDate = billingDate || '';
            this.dueDate = dueDate || '';
            this.totalInvoiced = totalInvoiced || null;
            if (resource) {
                this.selectedAssignment = resource.assignment_id;
                this.formInvoiceItem = {
                    id: resource.id,
                    invoice_id: resource.invoice_id,
                    product_id: resource.product_id,
                    unit_price: resource.unit_price,
                    quantity: resource.quantity
                };
                this.selectedResource = this.resources.find(res => res.id === resource.product_id) || null;
                this.loadAssignments(this.selectedResource.id);
            } else {
                this.formInvoiceItem = {
                    id: null,
                    invoice_id: null,
                    product_id: null,
                    unit_price: null,
                    quantity: null
                };
                this.selectedResource = null;
                this.assignments = [];
                this.selectedAssignment = null;
            }
            this.isModalActive = true;
        },
        handleSearchChangeResources(search) {
            if (search.length < 3) {
                this.filteredResources = this.resources;
            } else {
                this.filteredResources = this.resources.filter(option =>
                    option.name.toLowerCase().includes(search.toLowerCase())
                );
            }
        },
        handleResourceChange() {
            if (this.selectedResource) {
                this.loadAssignments(this.selectedResource.id);
            } else {
                this.assignments = [];
                this.selectedAssignment = null;
            }
            this.clearResourceError();
        },
        async loadAssignments(resourceId) {
            try {
                const response = await axios.get(`/api/v2/invoice/cost-budgets/${resourceId}/assignments`);
                this.assignments = response.data.assignment;
            } catch (error) {
                console.error('Error loading assignments:', error);
            }
        },
        async saveBilling() {
            try {
                if (!this.selectedResource) {
                    this.resourceError = this.$t('resource_required_error');
                    return;
                }

                // Limpiar los mensajes de error si se seleccionó un recurso y un assignment
                this.resourceError = null;
                this.assignmentError = null;
                this.processingData = true;
                const formatDate = (date) => {
                    const d = new Date(date);
                    const month = `${d.getMonth() + 1}`.padStart(2, '0');
                    const day = `${d.getDate()}`.padStart(2, '0');
                    const year = d.getFullYear();
                    return `${year}-${month}-${day}`;
                };
                // Preparar los datos para el invoiceItem
                const invoiceItemData = {
                    product_id: this.selectedResource.id,
                    unit_price: this.formInvoiceItem.unit_price,
                    quantity: this.onlyPrice ? 0 : this.formInvoiceItem.quantity,
                    only_price: this.onlyPrice,
                    assignment_id: null
                };

                let endpoint, method;
                if (this.formInvoiceItem.id) {
                    endpoint = `/api/v2/invoice-items/${this.formInvoiceItem.id}`;
                    method = 'put';
                    invoiceItemData.invoice_id = this.formInvoiceItem.invoice_id;
                    this.closeModal();
                } else {
                    endpoint = '/api/v2/invoices';
                    method = 'post';
                    invoiceItemData.provider_id = this.selectedProviderData.id;
                    invoiceItemData.code = this.invoiceCode;
                    invoiceItemData.project_id = this.projectId;
                    invoiceItemData.billing_date = formatDate(this.billingDate);
                    invoiceItemData.due_date = formatDate(this.dueDate);
                }

                const response = await axios[method](endpoint, invoiceItemData);
                // Mostrar mensaje de éxito
                this.$toast.open({
                    message: this.$t('save_successful'),
                    type: 'is-success',
                    position: 'is-top-right'
                });
                this.resetForm();
                this.processingData = false;
                this.$emit('total_amount', response.data.total_amount);
                this.$emit('reload-list');
                if (this.formInvoiceItem.id) {
                    this.closeModal();
                }
            } catch (error) {
                console.error('Error saving billing:', error);
                // Manejar errores y mostrar mensajes de error
                this.processingData = false;
                let errorMessage = this.$t('save_error');
                if (error.response && error.response.data && error.response.data.errors) {
                    errorMessage = error.response.data.message;
                }
                this.$toast.open({
                    message: errorMessage,
                    type: 'is-danger',
                    position: 'is-top-right'
                });
            }
        },
        closeModal() {
            this.isModalActive = false;
        },
        resetForm() {
            this.formInvoiceItem = {
                id: null,
                invoice_id: null,
                product_id: null,
                unit_price: null,
                quantity: null
            };
            this.selectedResource = null;
            this.selectedAssignment = null;
            this.assignments = [];
            this.totalInvoiced = null;
        },
        clearResourceError() {
            this.resourceError = null;
        },
        clearAssignmentError() {
            this.assignmentError = null;
        },

    }
};
</script>
