<template>
  <div v-if="action">

    <div class="flex flex-row space-x-4">
      <div class="panel">

        <div class="text-sm w-full">

          <b-table
            icon-pack="fas"
            :data="rules"
            :paginated="isPaginated"
            :per-page="perPage"
            :bordered="true"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :default-sort-direction="defaultSortDirection"
            class="text-xs border-blue-lighter"
            :loading="isLoadingTable"
            :selected.sync="selected"
            :is-row-selectable="(row) => { return action.name == 'assign_project_rules' }"
            @select="handleSelect"
          >
            <template>

              <b-table-column field="name" :label="$t('name')" sortable v-slot="props">
                {{ props.row.name }}
              </b-table-column>

              <b-table-column field="description" :label="$t('description')" sortable v-slot="props">
                {{ props.row.description }}
              </b-table-column>

            </template>

            <template slot="empty">No results</template>

          </b-table>
        </div>
      </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  props: {
    action: {type: Object, default: null },
    projectId: {type: Number}
  },


  data: () => ({
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 20,
    isLoadingTable: false,
    rules: [],
    selected: null
  }),

  mounted () {
    this.getProjectRules();
  },

  watch:{
    action(newAction, oldAction) {
      this.selected = null
    }
  },

  methods: {

    async getProjectRules() {
      let {data} = await axios.get('/api/v2/project-rules', {params: {p: this.projectId}});

      if (data.success) {
        this.rules = data.data
      } else {
        this.$notify.error("error_loading_data");
      }
    },

    handleSelect(row, oldRow) {
      this.dispatchSelected([row])
    },
  
    dispatchSelected(checkedList) {
      this.$emit('selected-linked-entities', checkedList)
    }
  },

};
</script>
