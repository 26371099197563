<template>
  <div v-if="action">
    <div>
      <label class="font-bold">Acción masiva: {{ action.title }}</label>
      <p>Seleccione el o los documentos que se le vincularan a las actividades seleccionadas</p>
    </div>
    <!-- <div v-else>
      <label class="font-bold">Asignación masiva de participantes</label>
      <p>Seleccione los usuario que se asignara como participantes de las actividades seleccionadas</p>
    </div> -->

    <div class="flex flex-row space-x-4">

      <div class="panel">
        <div class="text-sm w-full">
          <b-tabs type="is-boxed" v-model="mainActiveTab">
            <b-tab-item>
              <template slot="header">
                <b-icon pack="fas" icon="file"></b-icon>
                <span> {{ $t('documents') }} </span>
              </template>

              <documents-list-manual
                ref="project_documents_manual"
                :projectId="projectId"
                :documentType="'documents'"
                :withActions="false"
                :rowsSelected="documentsSelected"
                @selected-documents="dispatchDocuments"
              ></documents-list-manual>
            </b-tab-item>

            <b-tab-item>
              <template slot="header">
                <b-icon pack="fas" icon="file-pdf"></b-icon>
                <span> {{ $t('drawings') }} </span>
              </template>

              <documents-list-manual
                ref="project_drawings_manual"
                :projectId="projectId"
                :documentType="'drawings'"
                :withActions="false"
                @selected-documents="dispatchDocuments"
              ></documents-list-manual>
            </b-tab-item>

          </b-tabs>
        </div>
      </div>

      <div class="panel">
        <div>
          <h6 class="mb-3">Documentos seleccionados</h6>

          <entity-list
            :entities="documentsSelected"
            entityKey="document_id"
            :withImage="false"
            :show="true"
            :componentName="['name']"
            @removeButtonClicked="onClickRemoveButton($event)"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import DocumentsListManual from "../../components/bulk-actions/documents-list-manual";
import entityList from './utils/entity-list.vue';


export default {

  components: {
    DocumentsListManual,
    entityList
  },

  props: {
    projectId: { type: Number, require:true },
    action: {type: Object, default: null }
  },

  data: () => ({
    mainActiveTab: 0,

    selectUsers: [],
    selected: null,

    documentsSelected: [],

    boxFullWidth: false,
    iconRemoveHovered: null,
  }),

  mounted () {
  },

  watch:{

  },

  methods: {

    isHoverRemoveIcon(index) {
      return this.iconRemoveHovered === index
    },
    onClickRemoveButton(index) {
      this.documentsSelected.splice(index, 1)
      console.log('onClickRemoveButton', index)
      this.$emit('removeButtonClicked', index)

    },

    dispatchDocuments(items) {
      this.documentsSelected = items
      this.$emit('selected-linked-entities', items)
    },
  },

};
</script>
