<template>
  <div v-if="parts.length > 0">
    <viewer-chartset-part v-for="(part, index) in parts"
      :key="'charset-pict-' + part"
      :pictogramId="part"/>
  </div>
</template>

<script>
import ViewerChartsetPart from "./ViewerCharsetPart.vue";

  export default {
    name: "ViewerChartsets",
    components: {
      ViewerChartsetPart
    },

    props: {
      parts: { type: Array, default: [] }
    },

    watch: {
    },

    data() {
      return {
        panel1: null,
        panel2: null,
        panel3: null,
      }
    },

    methods: {

    }
  }

</script>

<style scoped>

</style>
