<template>
<header class="fixed top-0 z-50 w-full h-20 border-b border-solid shadow-lg bg-blue-lightest lg:w-5/6">
  <nav class="h-full p-2">
    <div class="flex flex-wrap lg:h-full ">
      <!-- Grupo izquierda -->
      <div class="flex items-center w-1/2 lg:w-1/3 justify-left">
        <button class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" type="button" @click="(e) => $emit('epc-show-sidebar', true)">
          <span class="sr-only">Open sidebar</span>
          <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
          </svg>
        </button>

        <notifications class="inline-flex ml-0"></notifications>
        <!-- <a v-on:click="openChatPanel" class="mt-6 ml-2 mr-2 text-black hover:text-grey">
            <b-icon icon="comments" pack="fas" size="is-small" class="float-left"></b-icon>
            <span v-if="totalChats > 0 && totalChats <=99" class="px-1 py-0 text-xs text-white rounded-full bg-red">{{ totalChats }}</span>
            <span v-else-if="totalChats > 99" class="px-1 py-0 text-xs text-white rounded-full bg-red">+99</span>
        </a> chat desactivado -->
      </div>
      <!-- Centro - Cambio de Proyecto -->
      <div class="w-1/2 hidden lg:flex lg:flex-row lg:justify-center lg:w-1/3">
        <div class="relative flex justify-center">
          <b-tooltip :label="$t('change_company')" position="is-bottom">
            <router-link to="/select-company">
              <img v-if="selectedCompanyAlias"
                class="inline align-middle w-40" style="max-width: 250px; max-height: 60px;"
                :src="'//' + s3bucket + '/' + selectedCompanyAlias + '/company_logo.jpg'" />
              <b-icon icon="exchange-alt" pack="fas" size="is-small"
                class="text-blue right-1 top-1"
                :class="{ absolute: selectedCompanyAlias != null }"/>
            </router-link>
          </b-tooltip>
        </div>
        <!-- <ul id="navbar-item" class="navbaritems">
          <li v-show="company != null"> -->
            <div class="relative lg:flex lg:justify-center lg:mt-2" v-show="company != null">
              <b-tooltip :label="$t('change_project')" position="is-bottom">
                <router-link to="/select-project" class="flex items-center text-blue hover:text-orange" style="max-width:450px;">
                  <span class="float-left uppercase truncate text-blue font-extralight">{{ project_name }}</span>
                  <b-icon icon="th" pack="fas" class="ml-2 navbar-item-icon text-blue" />
                </router-link>
              </b-tooltip>
            </div>
          <!-- </li>
        </ul> -->
      </div>
      <!-- Derecha -->
      <div class="flex justify-end w-1/2 lg:w-1/3">
        <div class="flex flex-wrap">
          <!-- icon notification progress process -->
          <process-queue class="inline-flex items-center p-2"></process-queue>

          <!-- Iconos -->
          <div class="inline-flex items-center p-2" v-on:click="changeLang(isEs ? 'en' : 'es')">
            <img :src="asset('public/flags/' + (isEs ? 'en' : 'es') + '.png')"/>
          </div>
          <!-- <div v-if="isEs" v-on:click="changeLang('en')"><img class="mt-2" :src="asset('public/flags/en.png')"/></div>
          <div v-else v-on:click="changeLang('es')"><img class="mt-2" :src="asset('public/flags/es.png')"/></div> -->
          <!-- Menú usuario -->
          <navbarmenu class="flex ml-0"></navbarmenu>
        </div>
      </div>
    </div>
    <div class="lg:hidden">
      <p class="text-center">
        <b-tooltip :label="$t('change_project')" position="is-bottom">
          <router-link to="/select-project" class="flex items-center text-blue hover:text-orange" style="max-width:450px;">
            <span class="float-left uppercase truncate text-blue font-extralight">{{ project_name }}</span>
            <b-icon icon="th" pack="fas" class="ml-2 navbar-item-icon text-blue" />
          </router-link>
        </b-tooltip>
      </p>
    </div>
  </nav>
</header>
</template>

<script>
import { mapGetters } from 'vuex'
import Navitem from './NavBarItem'
import Navbarmenu from './NavBarMenu'
import Notifications from '../Notifications/Notifications'
import store from '~/store'
import EventBus from '~/plugins/bus'
import ProcessQueue from '../ProcessQueue/ProcessQueue'

export default {
  components: {
    Navitem,
    Navbarmenu,
    Notifications,
    EventBus,
    ProcessQueue
  },

  data: () => ({
    s3bucket: window.config.s3bucket,
    isEs: true,
    project_name: '',
    projectId: 0,
    totalChats: 0,
    hasProject: false,
    activity: null //eliminar luego de hacer pruebas
  }),

  computed: {
    selectedCompanyAlias: function() {
      // company seleccionada o la original del usuario mientras no selecciona
      // return this.company ? this.company.alias : this.user.company.alias

      // retornamos el alias de la company solo si se ha seleccionado una compania caso contrario devolvemos null
      return this.company ? this.company.alias : null
    },

    ...mapGetters({
      user: "auth/user",
      project: 'app/project',
      company: 'app/company',
    }),
  },

  watch: {
    project(newValue) {
      this.setProjectName()
    }
  },

  mounted() {

    // Referenciamos internamente
    var self = this;

    //Comprobamos si estamos en español o inglés
    this.isEs = store.state.lang.locale === 'es';

    this.setProjectName();

    EventBus.$on('total-chats', function(e){
        self.totalChats = e
    });

  },

  methods: {

    openChatPanel(){
      EventBus.$emit('open-chat-panel', this.projectId);
    },

    setProjectName() {
      this.projectId = this.project == null ? '' : this.project.id;
      this.project_name = this.project == null ? '' : this.project.name;
    },

    changeLang(lang){
        this.isEs = lang === 'es';
        this.$store.dispatch('lang/setLocale',lang);
        //Debido a un bug en vueJS 2, se genera un error al redirigir sin callback
        this.$router.push('/', () => {});
    },
  },

};
</script>

<style scoped>
  .sr-only {
    clip: rect(0,0,0,0);
    border-width: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

@media (min-width: 1024px) {
  .lg\:flex {
    display: flex !important;
  }
}
</style>
