<template>
<div class="flex-col">

  <!-- Resumen campos extra -->
  <div class="panel">
    <div class="flex flex-wrap">

      <div v-for="field in extrafields" :key="field.extrafield_id" class="w-1/2 pr-4">

        <div>
          <label class="text-blue-light font-bold">{{ field.field.name }}</label>
          <p class="text-grey-dark">{{ getValue(field) }}</p>
        </div>

      </div>

    </div>

  </div>
  <!-- Panel 1 -->
  <div class="w-full mt-8 panel">
    <div class="flex flex-row w-full text-blue font-bold">Avance proyectado/ejecutado</div>
    <div class="flex flex-row w-full"><line-chart :data="panel1" /></div>
  </div>

  <!-- Panel 2 -->
  <div class="w-full mt-8 panel">
    <div class="flex flex-row w-full text-blue font-bold">Capítulos</div>
    <div class="flex flex-row w-full"><column-chart :data="panel2" :library="{ tooltips: { enabled: true, mode: 'single', callbacks: { label: tooltipCallback } } }" height="500px"/></div>
  </div>
</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import VueChartkick from 'vue-chartkick'
import Chart from 'chart.js'
import moment from 'moment'

Vue.use(VueChartkick, {adapter: Chart})
Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'project_home_page_title',
  subtitle: 'project_home_page_subtitle',
  menuOption: '2-1',

  components: {
    ScaleLoader,
  },

  data: () => ({
    filters: {
      location_id: 0,
      status_id: 1,
      tag_name: '',
    },
    extrafields: {},
    panel1: null,
    panel2: null
  }),

  computed: {
    ...mapGetters({
      project: 'app/project',
      user: 'auth/user',
    }),
  },

  mounted() {

    if (this.project == null) {

      this.$router.push('/projects');

    } else {

      this.getProjectExtrafields();
      this.getPanel1();
      this.getPanel2();

      let pageInfo = {
        title: this.project.name,
        subtitle: this.$t('project_home_page_subtitle'),
        menuOption: '2-1',
      }

      this.$store.dispatch('app/updatePageInfo', {info: pageInfo})
    }
  },

  methods: {
    async getPanel1() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/aytomadridpanel1';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel1 = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async getPanel2() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/aytomadridpanel2';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel2 = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async getProjectExtrafields() {
      let url = '/api/v2/project/' + this.project.id + '/extrafields-assigned';
      const {data} = await axios.get(url);
      if (data && data.success) {
        // excluimos algún campo q no se deba mostrar
        this.extrafields = data.data.extrafields.filter(field => field.field.code != 'dashboard_execution_activity')
      } else {
        this.$notify.error('error_retrieving_data')
      }
    },

    tooltipCallback(tooltipItem) {
      let item = this.panel2[tooltipItem.datasetIndex].data[tooltipItem.index]
      let list = [`TOTAL: ${item[1]}`]
      if (item[2].length) {
        list.push(' ')
        item[2].map(child => {
          list.push(`${child.name}: ${child.value}`)
        });
      }

      return list
    },

    getValue(field) {
      if(field) {
        let valor = null;
        switch (field.field.field_type) {
          case 0:
            valor = '-'
            let options = field.field.values
            let option = options.filter( o => o.id == field.value_id )
            if( option && option[0])
              valor = option[0].name
            break;

          default:
            valor = field.value_free
            break;
        }
        return valor;
      }
    }
  },

};
</script>
