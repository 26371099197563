<template>

<div class="flex-col">

  <!-- Filters -->
  <div class="panel" style="border-width: 0">
    <div class="flex flex-col lg:flex-row items-center mb-4">
      <div class="w-full lg:w-1/4 mr-4">

        <b-field :label="$t('select_month')">
          <b-datepicker v-model="filters.month" type="month" placeholder="select_month" v-if="filters.month" icon-pack="fas"/>
        </b-field>

      </div>
      
      <div class="w-full lg:w-1/4 mr-4">

        <b-field :label="$t('select_activity')">
          <b-select v-model="filters.activity_id" placeholder="select_activity" >
            <option v-for="(activity, index) in activities" :value="activity.id" :key="index">
              {{ activity.name }}
            </option>
          </b-select>
        </b-field>

      </div>
    </div>
  </div>

  <div class="w-full" v-if="!filtersReady">
    <div class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
      <p class="font-bold"><i class="fa fa-triangle-exclamation" /></p>
      <p>{{ $t('select_activity_and_month') }}</p>
    </div>
  </div>

  <!-- Panel 1 -->
  <div class="w-full mt-8" v-if="filtersReady">
    <div class="flex flex-row w-full text-blue font-bold">{{$t('pilings')}} Ejecutado / Maquinas</div>
    <div class="flex flex-row w-full"><column-chart :suffix="''" :data="panelPilings" :colors="['#2b8f8f', '#15487a']" /></div>
  </div>

  <!-- Panel 2 -->
  <div class="w-full mt-8" v-if="filtersReady">
    <div class="flex flex-row w-full text-blue font-bold">{{$t('structures')}} Ejecutado / Maquinas</div>
    <div class="flex flex-row w-full"><column-chart :suffix="''" :data="panelStructures" :colors="['#f78e3e', '#f0e367']" /></div>
  </div>

  <!-- Panel 3 -->
  <div class="w-full mt-8" v-if="filtersReady">
    <div class="flex flex-row w-full text-blue font-bold">{{$t('modules')}} Ejecutado / Maquinas</div>
    <div class="flex flex-row w-full"><column-chart :suffix="''" :data="panelModules" :colors="['#2b8f8f', '#15487a']" /></div>
  </div>

</div>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import VueChartkick from 'vue-chartkick'
import Chart from 'chart.js'
import moment from 'moment'

Vue.use(VueChartkick, {adapter: Chart})
Vue.use(moment)

export default {
  name: 'dashboard-ignis-staff',

  components: {
    ScaleLoader,
  },

  data: () => ({
    viewType: 0,
    activities: [], // List of activities with pictogram
    filters: {
      month: 0,
      activity_id: 0,
    },
    panelPilings: null,
    panelStructures: null,
    panelModules: null,
  }),

  computed: {
    ...mapGetters({
      project: 'app/project',
    }),

    filtersReady() {
      return this.filters.activity_id > 0 && this.filters.month;
    },
  },

  mounted() {

    this.filters.month = new Date();
    this.filters.month.setDate(5); // Set day to 5th of the month to avoid timezone issues

    if (this.project == null) {

      this.$router.push('/projects');

    } else {

      this.getActivitiesWithPictogram();

    }
  },

  watch: {
    filters: {
      handler: function (val) {
        this.filters.month.setDate(5); // Set day to 5th of the month to avoid timezone issues
        if (this.filters.activity_id > 0 && this.filters.month > 0) {
          this.getPanelsInfo();
        }
      },
      deep: true
    },
  },

  methods: {

    // Get only activities with pictogram linked
    getActivitiesWithPictogram() {
      let self = this
      // let url = '/api/v2/activities?p=' + this.project.id + '&has_pictogram=1&light=1&pg=1'; // no da subactividades. quitado
      let url = '/api/v2/project/' + this.project.id + '/activities-pictograms';
      axios.get(url)
        .then(response => {
          self.activities = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading activities")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

    async getPanelsInfo() {
      this.getIgnisPanelStaff123();
      // future panels
    },

    async getIgnisPanelStaff123() {

      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/ignispanelstaff123';

      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panelPilings = response.data.data.pilings
          self.panelStructures = response.data.data.structures
          self.panelModules = response.data.data.modules
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading ignispanelstaff123")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },

  },

};
</script>
