<template>
  <div class="panel">
    <div class="w-full uppercase text-blue-light font-thin text-lg">
      {{ title }}
      <!-- <span class="font-semibold">{{ currentPercentage }}%</span> -->
    </div>
    <div
      v-if="showLoading"
      class="w-full my-8 text-center text-green font-semibold"
      style="font-size:6rem"
    >
      <scale-loader></scale-loader>
    </div>
    <div v-else class="w-full my-8 text-center text-green font-semibold" style="font-size:6rem">
      <template v-if="chartType=='line'">
        <line-chart :min="min" :max="max" :suffix="suffix" :data="dataset" />
      </template>
      <template v-if="chartType=='column'">
        <column-chart :min="min" :max="max" :suffix="suffix" :data="dataset" />
      </template>
      <template v-if="chartType=='bar'">
        <bar-chart :min="min" :max="max" suffix="%" :data="dataset" />
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import VueChartkick from "vue-chartkick";
import Chart from "chart.js";
import moment from "moment";
import axios from "axios";
Vue.use(VueChartkick, { adapter: Chart });
Vue.use(moment);

export default {
  name: "project-charts",

  components: {
    ScaleLoader
  },
  props: {
    title : { type: String, default: 'Gráfica'},
    chartKey: { type: String, default: 'progress'},
    chartType: { type: String, required: false, default: 'line' },
    isLoading: { type: Boolean, required: true },
    project: { type: Object, required: true }
    //percentage: { type: Number, required: true },
  },

  data: () => ({
    //currentPercentage: 0,
    showLoading: false,
    dataset: [],
    min: 0,
    max: 100,
    suffix: ' €'
  }),

  created() {
    this.showLoading = this.isLoading
    // Referenciamos
    var self = this;
    //this.currentPercentage = this.percentage;
    // Cargamos datos
    this.getDataset();

    // Si recibimos el evento refresh, refrescamos el componente
    // EventBus.$on("refreshProgress", function() {
    //   self.getDataset();
    // });
  },

  methods: {
    async getDataset() {

      const { data } = await axios.get(
        "/api/v2/project/" + this.project.id + "/chart/" +  this.chartKey)

      if (data && data.success) {
        this.showLoading = false
        this.dataset = data.dataset
        this.min = data.min || this.min
        this.max = data.max || this.max
        this.suffix = data.suffix || this.suffix
        if( this.chartKey == 'progress')
          this.suffix = '%'
        this.$emit("projectChartCalculationDone",
          Object.assign(
            {'chartKey': this.chartKey, 'suffix': this.suffix}, this.dataset
          )
        )
      }
      // this.dataset = this.fakeCall(this.chartKey); //data.data

      // this.isLoading = false;, aqui no, en el padre
      // this.$emit("reload");
    },

    fakeCall(chartKey) {

      if (chartKey == 'progress')
        return [
          {name:'Estimado', data: {'Enero':10,'Febrero':25,'Marzo':30,'Abril':44,'Mayo':60,'Junio':80}},
          {name:'Real', data: {'Enero':5,'Febrero':22,'Marzo':35,'Abril':39,'Mayo':55,'Junio':60}}
        ]
      else if (chartKey == 'costs')
        return [
          {name:'Valor ganado estimado', data: {'Enero':0.6,'Febrero':1.8,'Marzo':2.3,'Abril':2.6,'Mayo':3,'Junio':3.2}},
          {name:'Valor ganado real', data: {'Enero':0.8,'Febrero':1.9,'Marzo':2.1,'Abril':2.4,'Mayo':3.2,'Junio':3.4}}
        ]
    }

  }
};
</script>
