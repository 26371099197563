<template>
    <div class="relative w-full" ref="viewer_page">
        <!-- <div class="panel relative w-4/5" style="height: 100vh;"> -->
        <ViewerHeader v-if="_AllowedAction('show_header')"
          @epc-open-panel="_HandleOpenPanel"
          @epc-in-design-mode="_HandleInDesignMode"/>
        <div v-if="designMode"
          class="p-2 text-sm text-gray-500 bg-white">
          <div class="h-9">
            Vista en modo diseño
          </div>
        </div>
        <div class="relative w-full flex">
          <ViewerPanelPictograms v-if="showPanelViews"
              :pictograms="pictograms"
              :selected-pictograms="selectedPictograms"
              @epc-update:selected-pictograms="_HandleUpdateSelectedPictograms"
              @epc-view-selected="_OnClickDisplayLayerPictogram"
              @epc-close="(e) => { showPanelViews = !e }" />
          <div class="relative w-full flex" :style="{height: heightContainerKonva + 'vh'}">
            <ViewerDesign ref="viewer"
              :pictograms="selectedPictograms"
              :project-id="projectId"
              :workLayer="workLayer"
              :canActionsPictogram="canActionsPictogram"
              :show-layer-completed="showLayerCompleted"
              :design-mode="designMode"
              :pictogram-type="pictogramType"
              :show-areas="showAreas"
              :is-drawing="isDrawing"
              :selected-tool="selectedTool"
              @epc-loaded="_OnLoaded"
              @epc-mounted="_OnMounted"
              @epc-cleared="_OnCleared"
              @epc-message="_OnMessage"
              @epc-pointerdown="_onPointerDown"
              @epc-changestatuses="_OnShowChangeStatuses"
              @epc-open-split="_OnShowFormSplit"
              @epc-isloading="(e) => { isLoading = e }"
              @epc-reload="() => { $emit('epc-reload') }"
              @epc-saving="(e) => { isLoading = e.loading; customLoaderText = e.text }"
              @epc-screenshot="(e) => { screenshot = e }"
              @epc-pictogram-dataset="_HandlePictogramDataset"
              @epc-viewer-design-mode-drawing="(e) => { isDrawing = e }"
              @epc-viewer-design-selected-tool="(e) => { selectedTool = e }"
            />
          </div>
        </div>

        <div v-if="showMsgWithoutDesign"
          class="absolute flex items-center w-full" style="top: 50%; left: 50%; transform: translate(-50%, -50%);">
          <div class="" style="width: 480px; padding: 30px 0 8px; margin: 0 auto; color: #0a131c">
            <h3 class="font-light text-center text-4xl">Sin parque fotovoltaico</h3>
            <p class="text-center text-sm mt-2.5 mx-0 mb-9">El projecto tiene pictogramas asociados a actividades pero no cuenta con el diseño..</p>
            <div class="text-center mt-6" style="color: #76767D">
              <b-icon icon="ban" pack="fas" custom-size="fa-5x"></b-icon>
            </div>
          </div>
        </div>

        <div v-if="!showMsgWithoutDesign"
          class="epc-toolbar">
          <div id="settingsTools" class="epc-control-group">
            <div id="toolbar-panTool" class="epc-button inactive"
              :class="{ 'active' : isDraggable }" @click="_OnClickPan()">
              <b-icon pack="fas" icon="hand-paper"></b-icon>
            </div>
            <div id="toolbar-fitTools" class="epc-button inactive" @click="_OnFitContent()">
              <b-icon pack="fas" icon="arrows-to-circle"></b-icon>
            </div>
            <div id="toolbar-zoomInTools" class="epc-button inactive" @click="_OnClickZoom(true)">
              <b-icon pack="fas" icon="search-plus"></b-icon>
            </div>
            <div id="toolbar-zoomOutTools" class="epc-button inactive" @click="_OnClickZoom(false)">
              <b-icon pack="fas" icon="search-minus"></b-icon>
            </div>

            <div id="toolbar-progressTools" class="epc-button inactive" @click="() => { datasetProject = true, $emit('epc-pictogram-project-dataset', true) }">
              <b-icon pack="fas" icon="bars-progress"></b-icon>
            </div>

            <div id="toolbar-fullscreenTools" class="epc-button inactive" @click="_OnClickFullScreen()">
              <b-icon pack="fas" icon="expand"></b-icon>
            </div>
          </div>

          <div id="settingsTools" class="epc-control-group" v-if="_AllowedAction('save') && designMode">
            <div id="toolbar-saveTools" class="epc-button inactive" @click="_HandleClickSave()">
              <b-icon pack="fas" icon="save"></b-icon>
            </div>
          </div>
        </div>

        <div class="docking-panel layers-panel" style="display: none;">
          <div class="docking-panel-title docking-panel-delimiter-shadow">Capas</div>
          <div class="docking-panel-close"></div>
          <div class="docking-panel-footer">
            <div class="docking-panel-footer-resizer"></div>
          </div>
        </div>

        <LayersListKonva v-show="showLayerPanel"
          :layers="filterLayers"
          @toggleLayer="_OnToggleLayer"
          @toggleAll="_OnToggleAll"
          @epc-close="(close) => showLayerPanel = !close"/>

        <ViewerSpinnerLoader ref="refSpinnerLoader" :is-loading="isLoading" :custom-loader-text="customLoaderText"/>

        <ViewerInsertPictogramsModal ref="refModalInsertPictograms"
          @epc-update:selected-pictograms="_HandleUpdateSelectedPictograms"
        />
      </div>
</template>

<script>
import axios from "axios"
import ViewerDesign from "./ViewerDesign"
import Vue from "vue"
import LayersListKonva from "../LayersListKonva"
import LayersToolStatuses from "../LayersToolStatuses.vue"
import LayersToolSplit from "../LayersToolSplit.vue"
import ViewerHeader from "./ViewerHeaderDesign.vue"
import ViewerPanelPictograms from "./ViewerPanelPictograms.vue"
import ViewerPanelComments from "../ViewerPanelComments.vue"
import ViewerSpinnerLoader from "../ViewerSpinnerLoader.vue"
import ViewerInsertPictogramsModal from "./ViewerInsertPictogramsModal.vue"

export default {
    name: "ViewerPageDesign",

    components: {
    ViewerDesign,
    LayersListKonva,
    LayersToolStatuses,
    LayersToolSplit,
    ViewerHeader,
    ViewerPanelPictograms,
    ViewerPanelComments,
    ViewerSpinnerLoader,
    ViewerInsertPictogramsModal
},

    props: {
      pictograms: { type: Array, default() { return [] } },
      projectId: { type: Number, require: true },
      workLayer: { type: String, default: null },
      canActionsPictogram: { type:Object, default() { return {} } } // acciones que se puede realizar sobre el pictograma por defecto ninguna
    },

    data() {
      return {
        selectedPictograms: [],


        layers: null,
        filterLayers: null,
        showLayerPanel: false,
        displayPictogramLayer: '', // capa actual del pictograma que se está visualizando
        displayDefaultPictogramLayer: null, // capa por defecto del pictograma a visualizar
        heightContainerKonva: '100',
        fullscreenEnabled: false, // si api navegador permite full screen
        isDraggable: true,
        isDrawing: false, // Esta bandera indica si se está graficando algún shape
        selectedTool: null, // Variable que ayuda a identificar que tipo de herramiente se ha seleccionado, (Tipo String)

        showLayerToolStatuses: false,
        selectedEntities: [], // entidades seleccionadas para cambiar de estado enviado desde konvaviewer.vue
        statuses: [], // estados que se aplican en una capa enviado desde konvaviewer.vue

        showLayerToolSplit: false,

        showPanelViews: false,
        showLayerCompleted: false, // visualiza una capa representando las estructuras que tienen estado completado por defecto false


        isLoading: false, // Indica si esta visible el loader
        customLoaderText: null, // texto mostrado en el loader

        designMode: false, // indica si se esta en modo diseño o en modo visualización de datos
        datasetProject: true,
        pictogramIdSelected: 0,
        showProgress: false,
        showMsgWithoutDesign: false, // bandera que permite mostrar un mensaje si no existe el diseño

        pictogramsInPowerPlant: [], // Esta variable contiene solo los Id de los pictogramas que estan en el diseño (power plant) es obtenido cuando se carga la data del power plant y los que se van añadiendo o quitando
        pictogramType: 'structures', // Bandera que determina el tipo de pictogramas que se deben cargar, por defecto son las structures
        showAreas: false // bandera para mostrar las areas marcadas
      }
    },

    computed: {

    },

    watch: {
      displayPictogramLayer(newVal, oldVal) {
        this.showLayerCompleted = newVal == 'modules'
      }
    },

    mounted() {

    },

    methods: {
      _HandlePictogramDataset(pictogramId) {
        this.$emit('epc-pictogram-project-dataset', false)
        this.datasetProject = false
        this.pictogramIdSelected = parseInt(pictogramId)
      },

      _HandleInDesignMode(e) {
        this.designMode = e
        if (!e)
          this.showPanelViews = false
        this.$emit('epc-in-design-mode', e)
      },

      ShowLoader(data) {
        this.isLoading = data.loading;
        this.customLoaderText = data.text
      },

      _OnLoaded(e) {
          const layers = this.$refs.viewer.GetLayers()
          layers.forEach(lyr => Vue.set(lyr, "isVisible", true))
          this.layers = layers
          this.showProgress = e.length > 0
          this.showMsgWithoutDesign = e.length == 0
          this.pictogramsInPowerPlant = e

          this.$emit('epc-photovoltaic-park-loaded', e)
      },

      _OnMounted() {
        // this._FilterLayer()
        // this._OnClickDisplayLayerPictogram(this.displayPictogramLayer)
      },

      _FilterLayer() {
        // Primero filtramos a queyos que en el name layer tiene el prifijo el codigo del layer a visualizar
        // luego verificamos si esto devuelve un resultado, caso contrario se filtrar a aquellos que no contengan
        // EPC en el nombre
        let filterType = this.displayPictogramLayer.toUpperCase()
        this.filterLayers = this.layers.filter( (l) => l.name.indexOf(filterType) !== -1 )

        if( this.filterLayers.length == 0 )
          this.filterLayers = this.layers.filter( (l) => l.name.indexOf('EPC') === -1 )
      },

      _OnCleared() {
          this.layers = null
      },

      _OnToggleLayer(layer, newState) {
          layer.isVisible = newState
          this.$refs.viewer.ShowLayer(layer.name, newState)
      },

      _OnToggleAll(newState) {
          if (this.layers) {
              for (const layer of this.layers) {
                  if (layer.isVisible !== newState) {
                      this._OnToggleLayer(layer, newState)
                  }
              }
          }
      },

      _OnMessage(e) {
          let type = "info"
          // switch (e.detail.level) {
          // case _DxfViewer.MessageLevel.WARN:
          //     type = "warning"
          //     break
          // case _DxfViewer.MessageLevel.ERROR:
          //     type = "negative"
          //     break
          // }
          // this.$q.notify({ type, message: e.detail.message })
      },

      _OnClickDisplayLayerPictogram(type) {
        if (type === this.displayPictogramLayer)
          this.displayPictogramLayer = this.displayDefaultPictogramLayer
        else
          this.displayPictogramLayer = type

        // mandamos a renderizar los layers filtrados por tipo
        this._FilterLayer()
        // mandamos a renderizar
        this.$refs.viewer.Render(this.displayPictogramLayer)
      },

      _onPointerDown(e) {
        console.log('_onPointerDown up: ', e.detail)
        this.$refs.viewer.GetViewer().ResaltarObject(e.detail.domEvent)
      },

      _OnClickZoom(zoomIn) {
        if( zoomIn )
          this.$refs.viewer.ZoomIn()
        else
          this.$refs.viewer.ZoomOut()
      },

      // toggleFullscreen() {
      _OnClickFullScreen() {
        const element = this.$refs.viewer_page; // El elemento que deseas mostrar en pantalla completa
        // const element = this.$refs.viewer.$el; // El elemento que deseas mostrar en pantalla completa

        if (this.isFullscreen()) {
          this.heightContainerKonva = '75'
          // Salir del modo de pantalla completa
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        } else {
          this.heightContainerKonva = '100'
          // Ingresar al modo de pantalla completa
          if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
          } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
          } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
          }
        }
      },

      _OnFitContent() {
        this.$refs.viewer.FitToScreen()
      },

      async _HandleClickSave() {
        this.$refs.viewer.Save()
      },

      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },

      isFullscreen() {
        return (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement
        );
      },

      _IsEnabledFullScreen() {
        if (
          document.fullscreenEnabled ||
          document.webkitFullscreenEnabled ||
          document.mozFullScreenEnabled ||
          document.msFullscreenEnabled
        ) {
          this.fullscreenEnabled = true;
        } else {
          this.fullscreenEnabled = false;
        }
      },

      _OnClickPan() {
        this.isDraggable = !this.isDraggable
        this.$refs.viewer.EnableDraggable(this.isDraggable)
      },

      _OnShowChangeStatuses(e) {
        this.showLayerToolStatuses = e.show
        this.selectedEntities = e.selected_entities
        this.statuses = e.statuses
      },

      _OnShowFormSplit(e) {
        this.showLayerToolSplit = e.show
        this.selectedEntities = e.selected_entities
      },

      _HandleChangeStatus(e) {
        // de momento una vez que cambia de estado solo completamos la acción pasando los datos a la variable que
        // contiene todos los elementos que han cambiado de estado (refierase a la estructura general no a la propiedad)
        this.$refs.viewer.CompleteAction()
      },

      handleSplitEntities(e) {
        this.$refs.viewer.SplitEntities(e)
      },

      _HandleOpenPanel(namePanel) {
        if ( namePanel === 'panel-views' )
          this.$refs.refModalInsertPictograms.show(this.pictograms, this.pictogramsInPowerPlant)

        if ( namePanel === 'panel-drawing' ) {
          this.isDrawing = true
        }

        if ( namePanel === 'view-pilings' || namePanel === 'view-structures' || namePanel === 'view-modules' ) {
          this.pictogramType = namePanel.replace('view-', '');
          this.showAreas = false
        }

        if ( namePanel === 'view-areas' ) {
          // this.pictogramType = namePanel.replace('view-', '');
          this.showAreas = !this.showAreas
        }

        if (namePanel === 'tool-pencil') {
          this.selectedTool = 'pencil'
        }

        // if (namePanel === 'panel-views')
        //   this.showPanelViews = true

        // if (namePanel === 'panel-layers')
        //   this.showLayerPanel = true
      },

      _AllowedAction(action) {
        if (Object.keys(this.canActionsPictogram).length === 0)
          return false;

        return this.canActionsPictogram[action] === 'undefined' ? false : this.canActionsPictogram[action]
      },

      _HandleUpdateSelectedPictograms(e) {
        // La variable e es un array que tiene los id de los pictogramas a incluir en el diseño
        this.showMsgWithoutDesign = e.length == 0

        let newSelected = []
        this.pictograms.forEach(item => {
          if (e.includes(item.id)) {
            // let exist = this.selectedPictograms.find(p => p.id == item.id)
            // if (! exist) {
              newSelected.push(item)
            // }
          }
        })

        this.selectedPictograms = newSelected
      }
    },

    created() {

      // Verificar si la API de pantalla completa es compatible en el navegador
      this._IsEnabledFullScreen()
    },
}
</script>

<style scoped>

.epc-toolbar {
  /* bottom: 10px; */
  top: 45px;
  left: 10px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  width: calc(100% - 20px);
  z-index: 5;
}
.epc-toolbar .epc-control-group {
  background-color: rgba(34,34,34,.94);
  box-shadow: 1px 3px 10px 0 rgba(0,0,0,.5);
  color: #f4f4f4;

  border-radius: 5px;
  display: inline-block;
  margin: 0 5px;
  pointer-events: all;
}

.epc-toolbar .epc-control-group .layerTools{
  border-radius: 3px;
  box-shadow: inset 0 2px 2px 0 #000;
  color: #00bfff;
  outline: none;
}

.epc-button{
  border: 1px solid rgba(34,34,34,0);
  border-radius: 4px;
  cursor: pointer;
  float: left;
  /* height: 28px; */
  margin: 4px;
  padding: 6px;
  position: relative;
  /* width: 28px; */
}

.epc-button:hover,
.epc-button.active {
  border: 1px solid #00bfff;
  color: #00bfff;
}

.epc-button.active,
.epc-button:focus {
  border-radius: 3px;
  box-shadow: inset 0 2px 2px 0 #000;
  color: #00bfff;
  outline: none;
}


.docking-panel {
  box-shadow: 1px 3px 10px 0 rgba(0,0,0,.4);
  -webkit-touch-callout: none;
  background: transparent;
  border: none;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 2;
}

.docking-panel-title {
  background-color: rgba(34,34,34,.94);
  border-bottom: 1px solid rgba(34,34,34,.8);
  color: #fff;

  border: none;
  border-radius: 5px 5px 0 0;
  cursor: move;
  font-size: 20px;
  font-weight: 300;
  line-height: 27px;
  overflow: hidden;
  padding: 11px 0 11px 14px;
  position: relative;
  resize: none;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  z-index: 1;
}

.docking-panel-delimiter-shadow {
  box-shadow: 0 3px 5px 0 rgba(0,0,0,.1);
}

.docking-panel-close {
  background-position: 0 19px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  z-index: 1;
}

.docking-panel-close {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNLjQ1NSAxMC45OTdhLjUuNSAwIDAgMS0uMzEtLjg1bDEwLTEwYS41MDIuNTAyIDAgMCAxIC43MS43MWwtMTAgMTBhLjUuNSAwIDAgMS0uNC4xNFoiIGZpbGw9IiM5NDk0OUYiLz48cGF0aCBkPSJNMTAuNDU3IDExLjAwN2EuNS41IDAgMCAxLS4zMS0uMTVsLTEwLTEwYS41MDIuNTAyIDAgMCAxIC43MS0uNzFsMTAgMTBhLjUuNSAwIDAgMS0uNC44NloiIGZpbGw9IiM5MzlDQTUiLz48L2c+PC9zdmc+);
}

.docking-panel-footer {
  background-color: #222;
}

.docking-panel-footer {
  bottom: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

.docking-panel-footer-resizer {
  background-position-x: 9px;
  background-position-y: 8px;
  background-repeat: no-repeat;
  bottom: 0;
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 0;
  width: 20px;
}

.docking-panel-footer-resizer {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI3IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSI+PHBhdGggZD0ibS41IDYuNSA2LTZNNC41IDYuNWwxLjUzNi0xLjUzNiIgc3Ryb2tlPSIjMDAwIi8+PHBhdGggZD0ibTEuNSA2LjUgNi02TTUuNSA2LjVsMS41MzYtMS41MzYiIHN0cm9rZT0iI0JFQzhEMiIvPjwvZz48L3N2Zz4=);
  background-position-x: 9px;
  background-position-y: 8px;
  background-repeat: no-repeat;
}

.layers-panel {
  border-color: transparent;
  height: 250px;
  left: 220px;
  min-height: 100px;
  min-width: 250px;
  top: 180px;
  width: 300px;
}

.pressed {
  background-color: rgb(51, 85, 136);
  color: white;
}

.not-pressed {
  background-color: rgba(85, 119, 187, 0.133);
  color: rgb(51, 85, 136);
}

</style>
