<template>
  <b-modal :active.sync="isModalActive" :width="1028" scroll="keep" :can-cancel="canCancel" @close="closeModal">

    <div class="border border-blue-light rounded shadow bg-white p-4">
      <form @submit.prevent="saveProduct" class="w-full">
        <header class="font-thin uppercase text-blue-light modal-card-head_ mb-4"
        >Añadir / editar material</header>

        <div class="modal-card-body_ relative">
            <div class="border-grey-light border-b border-dashed mb-3">

              <div class="w-full flex flex-row mb-3">
                <div class="w-1/2 mr-4">
                  <b-field :label="$t('reference_code')">
                      <v-input
                        v-model="form.code"
                        title="reference_code"
                        required="true"
                        name="code"
                        placeholder="reference_code"
                        maxlength="100"
                        :type="form.errors.has('code') ? 'is-danger' : ''"
                        :message="form.errors.has('code') ? form.errors.get('code') : null"
                      />
                  </b-field>
                </div>

                <div class="w-1/2 mr-4">
                  <b-field :label="$t('presto_code')">
                    <v-input
                      v-model="form.presto_code"
                      title="presto_code"
                      required="true"
                      name="presto_code"
                      placeholder="presto_code"
                      maxlength="100"
                    />
                  </b-field>
                </div>



                <!-- <div class="w-2/5 ml-4">
                  <b-field :label="$t('measurement_unit')">
                    <b-select v-model="form.unit_id" :placeholder="$t('select')" expanded>
                      <option v-for="unit in units" :value="unit.id" :key="'unit_' + unit.id">
                        {{ unit.name }}
                      </option>
                    </b-select>
                  </b-field>
                </div> -->
              </div>

              <div class="w-full flex flex-row mb-3">
                <div class="w-full mr-4">
                  <b-field :label="$t('name')">
                    <v-input
                      v-model="form.name"
                      title="name"
                      required="true"
                      name="name"
                      placeholder="name"
                      maxlength="100"
                    />
                  </b-field>
                </div>
              </div>

              <div class="w-full">
                <div class="w-full">
                  <b-field :label="$t('description')">
                    <b-input
                      v-model="form.description"
                      title="description"
                      name="description"
                      :placeholder="$t('description')"
                      maxlength="250"
                      type="textarea"
                    />
                  </b-field>
                </div>
              </div>

              <hr class="m-0">


              <div class="w-full flex flex-wrap flex-row mt-4 mb-3">
                <div v-for="field in extrafields" :key="field.id" class="w-1/2 pr-4 pb-4">
                  <!-- tipo select -->
                  <l-form-select v-if="field && field.field_type == 0"
                    :form="form"
                    :field="field.id.toString()"
                    :options="field.values"
                    :label="labelName(field)"
                    :full="true"
                    optionDisplayField="name"
                  />

                  <!-- tipo input -->
                  <l-form-input v-if="field && field.field_type == 1"
                    :form="form"
                    :field="field.id.toString()"
                    :label="labelName(field)"
                    :disabled="field.is_automatic && field.is_automatic && field.value_free ? true : false"
                  />

                  <!-- tipo calendar -->
                  <b-field v-if="field && field.field_type == 2"
                    :label="labelName(field)"
                    :type="form.errors.has(field.extrafield_id.toString()) ? 'is-danger' : ''"
                    :message="form.errors.has(field.extrafield_id.toString()) ? form.errors.get(field.extrafield_id.toString()) : null">
                    <b-datepicker
                        v-model="field.fieldValue"
                        :label="field.name"
                        :placeholder="field.name"
                        :month-names="$t('months_names')"
                        :day-names="$t('days_names')"
                        :first-day-of-week="parseInt($t('first_day_of_week'))"
                        :readonly="false"
                        icon="calendar"
                        icon-pack="fas"
                        :disabled="field.is_automatic && field.is_automatic && field.value_free ? true : false"
                        editable
                      >
                      </b-datepicker>
                  </b-field>

                  <!-- tipo checkbox -->
                  <b-field v-if="field && field.field_type == 3"
                    :label="labelName(field)">
                    <v-switch
                      :label="' '"
                      :color="form[field.id.toString()] ? 'green' : 'red'"
                      v-model="field.fieldValue"
                      v-on:checked="form[field.id.toString()] = !form[field.id.toString()]"
                      style="margin-left:15px; margin-top:3px "
                      :disabled="field.is_automatic && field.is_automatic && field.value_free ? true : false"
                    />
                  </b-field>
                </div>
              </div>

              <b-loading :is-full-page="false" v-model="processingData" :can-cancel="false"></b-loading>
            </div>
        </div>
        <footer class="modal-card-foot_">
          <div class="w-full inline-flex justify-end">
            <!-- <v-button color="grey" icon="undo-alt" @click="isModalActive = false;">{{ $t('cancel') }}</v-button> -->
            <!-- <button class="button" size="is-small" type="button" @click="isModalActive = false; /*$emit('update:show', !show)*/">{{ $t('cancel') }}</button> -->
            <button class="btn btn-blue" nativeType="submit" v-if="!processingData">{{$t('save')}}</button>
            <v-button-processing v-if="processingData" />
          </div>
        </footer>
      </form>
    </div>

  </b-modal>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Form from "vform";

export default {
  name: "material-modal",

  components: {},

  props: {
    projectId: { type: Number, required: true },
    extrafields: { type: Array, required: true }
  },

  data: () => {
    return {
      isModalActive: false,
      processingData: false,
      canCancel: ['escape', 'x', 'outside'],
      data: null,
      form: new Form ({
        id: null,
        unit_id: null,
        code: "",
        presto_code: "",
        name: "",
        description: "",
      }),
      options: [
        { value: 0, text: 'Sin categoría' }
      ],

      units: [],
      providers: []
    }
  },

  computed: {
      ...mapGetters({
        company: "app/company",
      }),
  },

  created() {
    // this.getUnitsCompany() // comentamos de momento ya que este campo estará oculto no se usara

  },

  methods: {

    async getDataProduct() {
      const {data} = await axios.get('/api/v2/store-product/'+this.form.id)
      if (data && data.success) {
        this.parserData(data.product)
      } else {
        this.$notify.error('error_retreiving_measures')

      }
    },

    async getUnitsCompany() {
      this.units = []

      const {data} = await axios.get('/api/v2/company/'+this.company.id+'/measurement-units')
      if (data && data.success) {
        this.units = data.units
      } else {
        this.$notify.error('error_retreiving_measures')

      }
    },

    async saveProduct() {
      let url = "/api/v2/project/"+this.projectId+"/store-product";

      if( this.form.id > 0 )
        url = "/api/v2/store-product/" + this.form.id;

      this.canCancel = [];
      this.processingData = true

      // Enviamos el form
      const { data } = await this.form.post(url, this.form)
        .catch( error => {
           //Se produjo un error
          this.isLoading = false;
          this.processingData = false
          this.canCancel = ['escape', 'x', 'outside']
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("save_error"),
            type: "is-danger",
            position: "is-top-right"
          });
        })

      if (data && data.success) {

        // this.parserData(data);

        // if (showConfirm) {
          this.$notify.success('save_successful')

          this.processingData = false
          this.canCancel = ['escape', 'x', 'outside']

          this.hide();
          this.$emit("reload-materials");
        // }
      } else {
        this.isLoading = false;
        this.processingData = false
        this.canCancel = ['escape', 'x', 'outside']
        this.$notify.error('error_saving')
      }
    },

    async show(data) {
      // this.data = data;
      this.form.id = data.id;
      this.form.unit_id = data.unit_id;
      this.form.code = data.code;
      this.form.presto_code = data.presto_code;
      this.form.name = data.name;
      this.form.description = data.description;

      if( this.form.id ) {
        await this.getDataProduct();
      }

      this.$nextTick( () => {
        this.isModalActive = true;
      } );
    },

    hide() {
      this.data = null;
      this.isModalActive = false;

      this.form.id = null;
      this.form.unit_id = null;
      this.form.code = "";
      this.form.presto_code = "";
      this.form.name = "";
      this.form.description = "";

      this.form.clear()
      this.form.reset()
    },

    closeModal() {
      this.$emit('update:show', !this.show)
    },

    labelName(field)
    {
      return field.is_automatic
        ? field.name + ' ⚙️'
        : field.name
    },

    parserData(data) {
        let self = this;

        data.extrafields.forEach(function(field) {
          if (field.field && field.field.field_type == 2) { // calendar
            // let fieldValue = field.value_free ? moment(field.value_free).format('YYYY-MM-DD') : null; // asi si almacenara fecha iso8601
            // fieldValue = fieldValue ? new Date(fieldValue) : fieldValue
            let fieldValue = field.value_free ? new Date(field.value_free) : null;
            self.form[field.extrafield_id.toString()] = fieldValue
          } else if (field.field && field.field.field_type == 3) {
            self.form[field.extrafield_id.toString()] = Boolean(parseInt(field.value_free)) || false
          } else {
            self.form[field.extrafield_id.toString()] = field.value_id || field.value_free || null
          }
        })
    },

  }
};
</script>
<style scoped>
/* Oculta los botones para aumentar los inputs numéricos */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
