<template>
  <b-modal :active.sync="isModalActive" @after-leave="hide()" scroll="keep" :can-cancel="canCancel">
    <div class="bg-white border rounded shadow-lg border-blue-light">

      <header class="modal-card-head">
        <p class="modal-card-title">{{$t('config_tasks')}}</p>
      </header>

      <section class="modal-card-body">
        <b-collapse
          class="card"
          animation="slide"
          v-for="(task, index) of tasks"
          :key="index"
          :open="isOpen == index"
          @open="isOpen = index"
          :aria-id="'contentIdForA11y5-' + index">
          <template #trigger="props">
              <div
                class="card-header"
                role="button"
                :aria-controls="'contentIdForA11y5-' + index"
                :aria-expanded="props.open"
                :style="tasks[index].activity_id ? 'background: #cec': 'background: #ecc'"
              >
                  <p class="card-header-title">
                      <span v-if="task.parent_name" class="opacity-60">
                        {{ task.parent_name }}<b-icon pack="fa" icon="angle-right"></b-icon>
                      </span>
                      {{ task.name }}
                  </p>
                  <a class="card-header-icon">
                      <b-icon pack="fa" :icon="props.open ? 'caret-down' : 'caret-up'">
                      </b-icon>
                  </a>
              </div>
          </template>
          <div class="card-content">
              <div class="content">
                <b-field :label="$t('select_activity')">
                  <b-select
                    v-model="tasks[index].activity_id"
                    :placeholder="$t('select_activity')"
                    icon="clipboard-list"
                    icon-pack="fas"
                    expanded
                  >
                    <option v-for="(option, indexActivities) in activities" 
                      :value="option['id']" 
                      :key="'activity_'+ indexActivities" 
                    >
                      {{ option['name'] }}
                    </option>

                  </b-select>
                </b-field>

                <b-field :label="$t('select_subactivity') + ' (' + $t('optional') + ')'">
                  <b-select
                    v-model="tasks[index].subactivity_id"
                    :reloadSelect="reloadSelect"
                    :placeholder="$t('select_subactivity')"
                    icon="clipboard-list"
                    icon-pack="fas"
                    expanded
                  >
                    <option
                      v-for="(option, indexActivities) in subactivities[`_${index}`]" 
                      :value="option['id']" 
                      :key="'subactivity_'+ indexActivities"
                    >
                      {{ option['name'] }}
                    </option>

                  </b-select>
                </b-field>

                <div class="flex flex-col mt-4">
                  <b-field :label="$t('select_folder') + ' (' + $t('optional') + ')'"></b-field>
                  <treeselect 
                    v-model="tasks[index].folder_id" 
                    :reloadSelect="reloadSelect" 
                    :close-on-select="true" 
                    :options="folders[`_${index}`] || []" 
                  />
                </div>
              </div>
          </div>
        </b-collapse>
      </section>
      
      <div class="px-4 pb-4">
        <button class="button is-success" v-on:click="hide()">{{ $t('save') }}</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Vue from 'vue';
import axios from "axios";
import { mapGetters } from "vuex";
import EventBus from "~/plugins/bus";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import moment from 'moment'
import Treeselect from '@riophae/vue-treeselect'

export default {
  name: "validationtask-config-activities-modal",

  components: {
    ScaleLoader,
    Treeselect
  },

  props: {
    activities: {type: Array, required: true}
  },

  data: () => ({
    isModalActive: false,
    canCancel: ['escape', 'x', 'outside'],
    templateId: null,
    subactivities: {},
    folders: {},
    tasks: [],
    isOpen: null,
    reloadSelect: 0,
    watchers: {}
  }),

  computed: {
    ...mapGetters({
      company: 'app/company',
    })
  },

  methods: {

    /**
     * Muestra este modal
     */
    async show(templateId) {
      this.templateId = templateId;
      if (!this.tasks.length) {
        this.subactivities = {}
        this.folders = {}
        this.isOpen = null
        let {data} = await axios.get('/api/v2/validations/template/' + this.templateId)
        data.data.tasks.map((task, index) => {
          this.tasks.push({
            id: task.id,
            name: task.name,
            parent_name: null,
            activity_id: null,
            subactivity_id: null,
            folder_id: null
          })
          task.children.map((child, index) => {
            this.tasks.push({
              id: child.id,
              name: child.name,
              parent_name: task.name,
              activity_id: null,
              subactivity_id: null,
              folder_id: null
            })
          })
        })
      }
      this.watchers = {}
      for (let index in this.tasks) {
        this.watchers[`a_${index}`] = this.$watch(() => this.tasks[index].activity_id, function(value) {
          this.getsubActivities(value, index)
        });
        this.watchers[`s_${index}`] = this.$watch(() => this.tasks[index].subactivity_id, function(value) {
          this.getSubactivityFolders(value, index)
        });
      }

      this.isModalActive = true;
    },

    /**
     * Oculta este modal
     */
    hide() {
      this.canCancel = ['escape', 'x', 'outside']
      for (let index in this.tasks) {
        this.watchers[`a_${index}`]()
        this.watchers[`s_${index}`]()
      }
      this.isModalActive = false;
    },

    async getsubActivities(activityId, index){
      const { data } = await axios.get("/api/v2/activity/" + activityId + "/subactivities", {'params' : { with_folders: 1 }});
      this.tasks[index].subactivity_id= null;
      this.subactivities[`_${index}`] = data.subactivities;
      this.folders[`_${index}`] = this.getFolders(this.activities.find(a => a.id == activityId)?.folders || [])
      this.reloadSelect++
    },

    async getSubactivityFolders(subActivityId, index) {
      this.folders[`_${index}`] = this.getFolders(this.subactivities[`_${index}`].find(a => a.id == subActivityId)?.folders || [])
      this.reloadSelect++
    },

    // devuelve las carpetas de la actividad tal como los necesita el componente treeselect (nodos {id, label, children})
    getFolders(folders) {

      var map = {}, node, roots = [], i;

      for (i = 0; i < folders.length; i += 1) {
        map[folders[i].id] = i; // initialize the map
        folders[i].children = []; // initialize the children
      }

      for (i = 0; i < folders.length; i += 1) {
        node = folders[i];
        if (node.parent_id !== null) {
          folders[map[node.parent_id]].children.push({id: node.id, label: node.name, children: node.children});
        } else {
          roots.push({id: node.id, label: node.name, children: node.children});
        }
      }

      this.cleanEmptyChildrens(roots)

      return roots;
    },

    // limpia los nodos eliminando la key 'children' si está vacio
    cleanEmptyChildrens(tree) {

      for (let i = 0; i < tree.length; i += 1) {

        // Caso base 1. No tiene la key 'children', do nothing
        if (!tree[i].children)
          return true

        // Caso base 2. Tiene la key vacia, eliminarla
        if (tree[i].children && tree[i].children.length == 0) {
          delete tree[i].children
          return true;

        // tiene childrens, pues llamar recursivamente
        } else {
          this.cleanEmptyChildrens(tree[i].children)
        }
      }
      return true

    },
  },
};
</script>
