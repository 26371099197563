<template>
  <div class="page-with-top-bar">

    <div class="w-full flex justify-between mt-4 below-top-bar">
    
      <div class="flex">
        <router-link :to="{ name: 'partner.edit', params: { id: partnerId } }">&lt; {{$t('back')}}</router-link>
      </div>

      <div class="flex">
          <router-link
            :to="'/admin/partner/' + partnerId + '/user'"
            class="btn btn-blue rounded"><b-icon icon="plus" pack="fas"/>
            {{ $t('add_user') }}
          </router-link>
      </div>

    </div>

    <div class="panel">
      <div v-if="!loaded" class="text-center my-4">
        <scale-loader></scale-loader>
      </div>
      <div v-else>
        <div class="w-full flex flex-wrap mt-4">
        <section class="w-full">
          <b-table
          :data="users"
          :paginated="isPaginated"
          :per-page="perPage"
          :bordered="true"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :default-sort-direction="defaultSortDirection"
          v-if="loaded">
          <template>

            <b-table-column field="name" sortable>
              <template v-slot:header="">{{ $t('name') }}</template>
              <template v-slot="props">{{ props.row.name }}</template>
            </b-table-column>

            <b-table-column field="surname" sortable>
              <template v-slot:header="">{{ $t('surname') }}</template>
              <template v-slot="props">{{ props.row.surname }}</template>
            </b-table-column>

            <b-table-column field="dni" sortable>
              <template v-slot:header="">{{ $t('dni') }}</template>
              <template v-slot="props">{{ props.row.dni }}</template>
            </b-table-column>


            <b-table-column field="email" sortable>
              <template v-slot:header="">{{ $t('email') }}</template>
              <template v-slot="props">{{ props.row.email }}</template>
            </b-table-column>

            <b-table-column field="phone" sortable>
              <template v-slot:header="">{{ $t('phone') }}</template>
              <template v-slot="props">{{ props.row.phone }}</template>
            </b-table-column>

          

            <b-table-column field="actions" :label="$t('actions')" width=100 sortable>
              <template v-slot:header="">{{ $t('actions') }}</template>
              <template v-slot="props">
                <div class="w-full h-full flex items-center justify-around">
                  <router-link :to="'/admin/partner/' + partnerId + '/user/' + props.row.id" class="flex pr-0">
                    <b-tooltip :label="$t('edit')" type="is-primary" position="is-left">
                      <div class="cursor-pointer flex text-blue">
                        <b-icon icon="pencil-alt" pack="fas" />
                      </div>
                    </b-tooltip>
                  </router-link>

                  <b-tooltip :label="$t('remove')" position="is-left" type="is-danger">
                      <div @click="deleteUserButtonClicked(props.row.id, props.row.name)"
                          class="cursor-pointer flex text-red">
                        <b-icon pack="fas" icon="trash-alt"/>
                      </div>
                  </b-tooltip>
                </div>
              </template>
            </b-table-column>
          </template>
          <template slot="bottom-left">
              <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
              <b>{{ $t("total") }}</b>: {{ this.users.length }}      
          </template>

          <template #empty>
              <div class="has-text-centered">{{ $t('no_results') }}</div>
          </template>

          </b-table>
        </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import store from '~/store'
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  middleware: 'auth',
  title: 'partners_page_title',
  subtitle: 'partners_page_subtitle',
  menuOption: '0-4',
  components: {
    ScaleLoader
  },

  data: () => ({
    users: [],
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 20,
    loaded: false,
    partnerId: store.state.route.params.id || 0,
  }),

  mounted () {
    this.getUsers()
  },

  methods: {

    async getUsers () {
      let params = { 
        'r' : 'partner_admin,participant',
        'basic' : 0
      }
      const { data } = await axios.get("/api/v2/partner/" + this.partnerId + "/users", {'params' : params});

      if (data.users_list) {
        this.users = data.users_list;
        this.loaded = true
      }

    },

    deleteUserButtonClicked(userId, userName) {

      let self = this;
      this.$dialog.confirm({
        title: this.$t('delete'),
        message: this.$t('delete_generic', [userName]),
        confirmText: this.$t('delete'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          let url = '/api/v2/partner/' + self.partnerId + '/user/' + userId + '/delete'
          const {data} = await axios.post(url)
          if (data && data.success) {
            this.loaded = false
            this.$notify.success('success_deleting_user')
            self.getUsers()
          } else {
            this.$notify.error(data.error)
          }
        }
      })
    },
  
  },


};
</script>
