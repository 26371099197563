<template>
  <div class="page-with-top-bar">
    <section>
      <div class="mt-4">
        <b-tabs type="is-toggle" v-model="currentActiveTab" expanded size="is-medium">
          <b-tab-item :disabled="sendProcessData">
            <template slot="header">
              <b-icon pack="fas" icon="tasks"></b-icon>
              <span> {{ $t('step') + ' 1' }} </span>
            </template>
            <div>
              <label> <strong>{{ $t('select_template') }}</strong> </label>
              <templates-list-project ref="list_template"
                :withActions="false"
                :template.sync="template"
              />
            </div>
          </b-tab-item>

          <!-- custom project seleccionado -->
          <b-tab-item :disabled="(currentActiveTab != 1 && !template) || sendProcessData">
            <template slot="header">
              <b-icon pack="fas" icon="bolt"></b-icon>
              <span>{{ $t('step') + ' 2' }}</span>
            </template>
            <div>
              <project-edit v-if="template" ref="form_data_project"
                :template="template"
                @epc-send-data="handleDataProject"
              />
            </div>
          </b-tab-item>

          <!-- custon activities seleccionada -->
          <b-tab-item :disabled="(currentActiveTab != 2 && !project) || sendProcessData">
            <template slot="header">
              <b-icon pack="fas" icon="bolt"></b-icon>
              <span>{{ $t('step') + ' 3' }}</span>
            </template>
            <div>
              <template-project-activities ref="list_activities" key="select_activities" v-if="project"
                :project-id="template.id"
                :project="project"
                @selected-items="handleSelectedActivities"
              />
            </div>
          </b-tab-item>

          <!-- custon mediciones seleccionada -->
          <b-tab-item :disabled="(currentActiveTab != 3 && emptyDataActivities) || sendProcessData">
            <template slot="header">
              <b-icon pack="fas" icon="bolt"></b-icon>
              <span>{{ $t('step') + ' 4' }}</span>
            </template>
            <div>
              <template-project-activities ref="list_select_measurements" key="select_measurements" v-if="!emptyDataActivities"
                :project-id="template.id"
                :list-items="activities"
                :load-measurements="true"
                @selected-items="(items) => { measurements = items }"
              />
            </div>
          </b-tab-item>

          <!-- Muestra resumen de la acción y opcion para procesar la acción o cancelar -->
          <b-tab-item :disabled="currentActiveTab != 4">
            <template slot="header">
              <b-icon pack="fas" icon="hourglass-end"></b-icon>
              <span>{{ $t('step') + ' 5' }}</span>
            </template>

            <WizardTemplateComplete v-if="currentActiveTab == 4"
              :template="template"
              :project="project"
              :activities="activities"
              :projectId="projectId"
              ref="wizard_complete"
            />
          </b-tab-item>

        </b-tabs>

        <div class="sticky bottom-0 ml-4 mr-4">
          <div class="panel mt-0 mb-0">
            <WizardButtons :step="currentActiveTab"
              :complete="currentActiveTab === 4 && !showBtnfinish"
              :processingData="sendProcessData"
              :showBtnFinish="showBtnfinish"
              @wizard-previus="prevTab"
              @wizard-next="nexTab"
              @wizard-finish="finishWizard"
            />
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
  import axios from "axios";

  import TemplatesListProject from '../../components/templates/project/template-projects-list.vue'
  import WizardButtons from '../../components/templates/project/wizard-buttons.vue';
  import WizardTemplateComplete from '../../components/templates/project/wizard-template-complete';
  import ProjectEdit from '../../components/templates/project/project-edit'
  import TemplateProjectActivities from '../../components/templates/project/template-project-activities'

  export default {
    middleware: "auth",
    title: "project_create_from_template_page_title",
    menuOption: "0-2",

    components: {
      TemplatesListProject,
      WizardButtons,
      WizardTemplateComplete,
      ProjectEdit,
      TemplateProjectActivities
    },

    computed: {
      projectId() {
        // console.log(this.$route.params.)
        return parseInt(this.$route.params.project_id);
      },

      emptyDataActivities() {
        if( !this.activities.length )
          return true

        let indexEmptyData = this.activities.findIndex((item) => {
          return (!item.start_date || !item.end_date);
        })

        // si los los datos de las actividades selecionas estan completas pasamos a verificar si las sub actividades estan con datos completos
        if (indexEmptyData === -1 ) {

          //  this.activities.forEach((item) => {
          //   indexEmptyData = item.subactivities_selected.findIndex((subactivity) => {
          //     return !subactivity.start_date || !subactivity.end_date
          //   })

          //   if( indexEmptyData > -1 )
          //     return
          // })

          // let weightSuccess = true
          // let sumWeight = 0

          // for(var key in this.activities) {
          //   let activity = this.activities[key]

          //   if( typeof activity.subactivities_selected !== 'undefined' && activity.subactivities_selected.length ){
          //     sumWeight = 0
          //     activity.subactivities_selected.forEach((subitem) => {
          //       if( typeof subitem.selected !== 'undefined' && subitem.selected ){
          //         sumWeight += parseInt(subitem.weight)
          //       }
          //     })
          //   }

          //   weightSuccess = sumWeight == 100;
          //   if( !weightSuccess )
          //     break
          // }

          // indexEmptyData = weightSuccess ? -1 : 99

        }

        // let vilidActivities = this.activities.findIndex((item) => { item.start_date == null && item.end_date == null })
        return indexEmptyData > -1
      }
    },

    data: () => ({
      currentActiveTab: 0,
      template: null, // Project template select
      project: null, // datos del proyecto a crear
      activities: [], // datos de las actividades ha asociar al projecto
      measurements: [], // dostos de las mediciones a asociar a las actividades

      linkedEntities: [],
      sendProcessData: false,

      validWeight: true,
      showBtnfinish: false,
    }),

    watch: {
      currentActiveTab(value) {
        if (value == 0) {
          // no aplicable
          //this.$refs.project_assignments_manual.reload();
        }
      }
    },

    mounted() {
    },

    created() {
    },

    methods: {
      prevTab () {
        if (this.currentActiveTab === 0 ) return
        const newTabIndex = this.currentActiveTab - 1
        const oldTabIndex = this.currentActiveTab
        // emit('change', newTabIndex, oldTabIndex)
        // await props.beforeChange()
        this.changeTab(newTabIndex)
      },

      async nexTab() {
        if (this.currentActiveTab === 4) {
          this.completeWizard()
          return
        }

        if ( this.currentActiveTab == 0 && !this.validStep1() ) return
        if ( this.currentActiveTab == 1 ) {
          await this.$refs.form_data_project.getData()

          if ( !this.validStep2() ) return
        }
        if ( this.currentActiveTab == 2 && !this.validStep3() ) return
        if ( this.currentActiveTab == 3 && !this.validStep4() ) return

        const newTabIndex = this.currentActiveTab + 1
        const oldTabIndex = this.currentActiveTab
        // emit('change', newTabIndex, oldTabIndex)
        // await props.beforeChange()
        this.changeTab(newTabIndex)
      },

      async completeWizard() {
        this.sendProcessData = true
        await this.$refs.wizard_complete.generate();
        this.showBtnfinish = true

      },

      finishWizard() {
        this.sendProcessData = false

        // limpiamos datos seleccionados
        this.template = null
        this.project = null
        this.activities = []
        this.measurements = []
        this.linkedEntities = []
        this.showBtnfinish = false

        this.$refs.list_template.clearSelected()
        this.changeTab(0)
      },

      async changeTab (newTabIndex) {
        this.currentActiveTab = newTabIndex

        this.setActiveIndex()
        await this.$nextTick()
        // await props.beforeMount()
      },

      setActiveIndex()  {
        // TODO: No Hace nada ver para que puede ser usado
        // const currentTabs: any = tabs.value.entries()
        // for (const [index, tab] of currentTabs) {
        //     if (index === maxTabIndex.value + 1) {
        //         return
        //     }
        //     tab.checked = index < currentTabIndex.value
        //     tab.active = index === currentTabIndex.value
        // }
        // emit('updated:tabs', tabs, currentTabIndex)
      },

      validStep1() {
        if( this.template )
          return true

        // Mostramos el error
        this.$toast.open({
          message: 'Debe seleccionar una plantilla de proyecto',
          type: "is-danger",
          position: "is-top-right",
        });

        return false
      },

      validStep2() {
        if( this.project)
          return true

        // Mostramos el error
        // this.$toast.open({
        //   'message': this.$t('Debe debe guardar los datos del projecto'),
        //   'position': 'is-top-right',
        //   'type': 'is-danger'
        // })

        return false
      },

      validStep3() {
        // obtenemos los datos selecionados
        this.activities = this.$refs.list_activities.getActivitiesSelected()

        if( this.activities.length)
        {
          if( this.validateDataActivities() && this.validateDataActivities(true)) {
            if( this.validateWeightActivities() ) {
              return true
            }
          } else {

            // Mostramos el error
            this.$toast.open({
              'message': this.$t('Los campos fecha de inicio y finalizacion de las actividades/subactividades seleccionadas son obligatorios'),
              'position': 'is-top-right',
              'type': 'is-danger'
            })
          }

          this.activities = [] //seteamos array vacio si no cumple
          return false
        }

        // Mostramos el error
        this.$toast.open({
          'message': this.$t('Debe debe seleccionar al menos una actividad'),
          'position': 'is-top-right',
          'type': 'is-danger'
        })

        return false
      },

      validStep4() {
        // if( this.measurements.length)
        if( !this.activities.length)
        {
          // Mostramos el error
          this.$toast.open({
            'message': this.$t('Debe debe almenos una medición'),
            'position': 'is-top-right',
            'type': 'is-danger'
          })

          return false
        }

        // primero validamos si tiene mediciones
        if( !this.validateMeasurement() ){
          // this.$toast.open({
          //   'message': this.$t('Una actividad/subactividad no tiene asignado una medición.'),
          //   'position': 'is-top-right',
          //   'type': 'is-danger'
          // })

          return false
        }

        if( !this.validateMeasurement(true) ){
          // this.$toast.open({
          //   'message': this.$t('La suma de las ponderacion de una actividad/subactividad no suman 100%.'),
          //   'position': 'is-top-right',
          //   'type': 'is-danger'
          // })

          return false
        }

        return true
      },

      validateDataActivities(validSub=false) {
        // valida que datos actividad no esten vacios
        let indexEmpty = -1;

        if( !validSub ) {
          indexEmpty = this.activities.findIndex((item) => {
            return !item.start_date || !item.end_date
          })
        }
        else {
          // valida que datos subactividad no esten vacios
          this.activities.forEach((item) => {
            indexEmpty = item.subactivities.findIndex((subactivity) => {
              return !subactivity.start_date || !subactivity.end_date
            })

            if( indexEmpty > -1 )
              return
          })
        }

        return indexEmpty === -1
      },

      validateWeightActivities() {
        let nameActivity = null
        let weightSuccess = true
        let sumWeight = 0

        for(var key in this.activities) {
          sumWeight = 100
          let activity = this.activities[key]
          nameActivity = activity.name

          if( typeof activity.subactivities !== 'undefined' && activity.subactivities.length ){
            sumWeight = 0
            activity.subactivities.forEach((subitem) => {
              if( typeof subitem.selected !== 'undefined' && subitem.selected ){
                sumWeight += parseFloat(subitem.weight)
              }
            })
          }

          // Lo ponemos de esta forma por sumas de valor que no dan 100 exacto sino estan desplazados por algunos millonesimas (temas de float y javascript)
          // toFixed consideramos 4 cifras significativas despues del punto (indica cuan cerca esta el valor de 100)
          // hacemos doble parseFloat por si las moscas igualmente deberia funcionar con el parseFloat luego del toFixed
          weightSuccess = parseFloat(parseFloat(sumWeight).toFixed(4)) === 100;

          if( !weightSuccess )
            break
        }

        if( !weightSuccess ) {

          this.$dialog.alert({
            title: "Ponderación de la actividad",
            message: "La suma de la ponderación de las subactividades de la actividad <b>" + nameActivity + "</b> esta fuera del parametro requerido (100%), corrija las ponderaciones de las subactividades y vuelva a intentar.",
            confirmText: "Aceptar",
            type: "is-danger",
            icon: 'times-circle',
            iconPack: 'fas',
            hasIcon: true,
          });
        }

        return weightSuccess
      },

      handleSelectRow(row) {
        this.project = null
        this.activities = []
        this.measurements = []
        this.template = row
      },

      handleDataProject(data) {
        this.project = data
      },

      handleSelectedActivities(items) {
        // this.activities = [items]
        this.activities = [] // cada vez que aya cambios seteamos activities a un array vacio
      },

      // Metodos para validar las mediciones de una actividad/subactividad
      // por defecto valida si se ha selccionado
      validateMeasurement(validateWeight = false) {
        let hasSelectMeasurement = true
        let nameActivity = null

        for(var key in this.activities) {
          let activity = this.activities[key]
          nameActivity = activity.name

          if( typeof activity.subactivities !== 'undefined' && activity.subactivities.length ){
            for(let subKey in activity.subactivities) {
              let subactivity = activity.subactivities[subKey]
              nameActivity = subactivity.name
              if( validateWeight ){
                hasSelectMeasurement = this.sumWeightMeasurement(subactivity.assignments)
              } else {
                hasSelectMeasurement = this.hasMeasurement(subactivity.assignments)
              }
              if( !hasSelectMeasurement )
                break
            }
          } else {
            if( validateWeight )
              hasSelectMeasurement = this.sumWeightMeasurement(activity.assignments)
            else
              hasSelectMeasurement = this.hasMeasurement(activity.assignments)
          }

          if( !hasSelectMeasurement )
            break
        }


        if( !hasSelectMeasurement ) {

          this.$dialog.alert({
            title: (validateWeight ? "Ponderación" : "Mediciones"),
            message: ( validateWeight ? "La suma de la ponderación de las medicion de la actividad <b>" + nameActivity + "</b> esta fuera del parametro requerido (100%), corrija las ponderaciones de las mediciones y vuelva a intentar." : "La actividad <b>"+ nameActivity + "</b> no tiene mediciones asignadas"),
            confirmText: "Aceptar",
            type: "is-danger",
            icon: 'times-circle',
            iconPack: 'fas',
            hasIcon: true,
          });
        }

        return hasSelectMeasurement
      },

      hasMeasurement(measurements) {
        measurements = measurements || []
        // SI no tiene mediciones ya sea activity or subactivity permite la creacion
        if( (typeof measurements === 'undefined' || measurements === undefined) || !measurements.length )
          return true

        // si la actividad o subactividad tiene mediciones debe seleccionar al menos una
        let totalMeasurements = 0
        measurements.forEach((measurement) => {
          if( typeof measurement.selected !== 'undefined' && measurement.selected )
            totalMeasurements += 1
        })

        return totalMeasurements > 0
      },

      sumWeightMeasurement(measurements) {
        measurements = measurements || []
        // SI no tiene mediciones ya sea activity or subactivity permite la creacion
        if( (typeof measurements === 'undefined' || measurements === undefined) || !measurements.length )
          return true
        // de lo contrario determinamos la suma de la ponderacion de la mediciones
        let sumWeight = 0
        measurements.forEach((measurement) => {
          if( typeof measurement.selected !== 'undefined' && measurement.selected )
            sumWeight += parseFloat( typeof measurement.weight === 'string' ? measurement.weight.replace(',', '.') : measurement.weight)
        })
        return sumWeight == 100
      },
    }
  };
</script>
