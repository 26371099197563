<template>

<div class="root epc-panel layer-panel" style="top: 0; bottom: initial; right: 0;">
  <div class="epc-panel-title">Cambiar estado</div>
  <div class="epc-panel-close" @click="(e) => $emit('epc-close', true)">
    <b-icon
      :icon="'times'"
      pack="fas"
      size="is-small">
    </b-icon>
  </div>

  <div class="epc-panel-footer"><div class="epc-panel-footer-resizer"></div></div>
  <!-- <div class="visibility" title="Show/hide all layers" @click="_ToggleAll">
      <b-icon
        :icon=" showAll ? 'eye' : 'eye-slash'"
        pack="fas"
        size="is-small">
      </b-icon>
  </div> -->

  <div id="LayersPanel-Filterbox" class="adsk-control adsk-filterbox empty">
    <!-- <input class="filter-box docking-panel-delimiter-shadow"
      type="search" placeholder="Introdusca capa"
      data-i18n="Enter filter term" incremental="">
    <div class="filter-box-icon"></div>
    <div class="filter-box-close"></div> -->
  </div>

  <div class="epc-panel-scroll epc-panel-container-solid-color-a right" id="ViewerLayersPanel-scroll-container" style="height: calc(100% - 104px);">
    <div class="epc-panel-container-gradient text-xs" style="width: 100%; height: 100%;">
      <div class="card-header font-semibold px-3 py-1 mb-0 border-b border-solid border-slate-100 uppercase">
        <div>Objetos seleccionados: <span>{{ selectedEntities.length }}</span> </div>
      </div>
      <div class="card-body px-3 py-2">
        <!-- campo total de paneles por estructura, es introducido manualmente -->
        <div class="mb-2">
          <div class="text-xs font-medium capitalize text-blue">{{ $t("total_pilings") }}</div>
          <div class="flex flex-row items-center">
            <b-field class="mb-0 w-1/2"
              :type="{ 'is-danger' : errors.pilings } "
              :message="{ 'La cantidad es invalida' : errors.pilings  }">
              <b-numberinput
                v-model="form.total_pilings"
                controls-position="compact"
                :disabled="generatedDesign"
                size="is-small"
                icon-pack="fas"
                @input="(value) => { errors.pilings = value <= 0 }" />
            </b-field>

            <span class="ml-1 text-xs text-black">(Incados (pilotes) por estructura)</span>
          </div>
        </div>

        <!-- campo total de hincados (pilotes), es introducido manualmente -->
        <div class="mb-2">
          <div class="text-xs font-medium capitalize text-blue">{{ $t("total_modules") }}</div>

          <div class="flex flex-row items-center">
            <b-field class="mb-0 w-1/2"
              :type="{ 'is-danger' : errors.modules } "
              :message="{ 'La cantidad es invalida' : errors.modules  }">
              <b-numberinput
                v-model="form.total_modules"
                controls-position="compact"
                :disabled="generatedDesign"
                size="is-small"
                icon-pack="fas"
                @input="(value) => { errors.modules = value <= 0 }" />
            </b-field>
            <span class="ml-1 text-xs text-black">(Modulos por estructura)</span>
          </div>
        </div>

        <!-- campo total de paneles, es introducido manualmente -->
        <div class="mb-2">
          <div class="text-xs font-medium capitalize text-blue">{{ $t("total_panels") }}</div>

          <div class="flex flex-row items-center">
            <b-field class="mb-0 w-1/2"
              :type="{ 'is-danger' : errors.panels } "
              :message="{ 'La cantidad es invalida' : errors.panels  }">
              <b-numberinput
                v-model="form.total_panels"
                controls-position="compact"
                :disabled="generatedDesign"
                size="is-small"
                icon-pack="fas"
                @input="(value) => { errors.panels = value <= 0 }" />
            </b-field>
            <span class="ml-1 text-xs text-black">(Paneles por modulo)</span>
          </div>
        </div>

        <b-button type="is-primary" expanded @click="handleSplit" class="mt-4">Volver a dividir</b-button>
      </div>
    </div>
  </div>
</div>

</template>

<script>

export default {
    name: "LayersToolSplit",

    props: {
        selectedEntities: {
          type: Array, default() { return [] }
        },
        statuses: {
          type: Array, default() { return [] }
        }
    },

    watch: {
    },

    data() {
      return {
        form: {
          // id: 0,
          // code: null,
          // name: null,
          // total_structures: null,
          total_pilings: null,
          total_modules: null,
          total_panels: null,
        },
        errors: {
          pilings: false,
          modules: false,
          panels: false,
        },

        generatedDesign: false,
      }
    },

    methods: {
        _GetCssColor(value) {
            let s = value.toString(16)
            while (s.length < 6) {
                s = "0" + s
            }
            return "#" + s
        },

        handleSplit() {

          // this.$emit('epc-update-list', this.dataTooltip)
          this.$emit('epc-konva-split', this.form)
        }
    }
}

</script>

<style scoped>

.root {
    height: 100%;
    max-height: 100%;
    width: 300px;
}

.epc-panel {
  -webkit-touch-callout: none;
  background: transparent;
  border: none;
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 2;

  box-shadow: 1px 3px 10px 0 rgba(0,0,0,.4);
}

.epc-panel-title {
  background-color: #fff;
  border-bottom: 1px solid rgba(0,0,0,.2);
  color: #0a131c;

  border: none;
  border-radius: 5px 5px 0 0;
  cursor: move;
  font-size: 20px;
  font-weight: 300;
  line-height: 27px;
  overflow: hidden;
  padding: 11px 0 11px 14px;
  position: relative;
  resize: none;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  z-index: 1;
}

.epc-panel-close {
  background-position: 0 19px;
  background-repeat: no-repeat;
  cursor: pointer;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  z-index: 1;
}

.epc-panel-footer {
  bottom: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;

  background-color: #fff;
    border-top: 1px solid rgba(0,0,0,.2);
}

.epc-panel-footer-resizer {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI3IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSI+PHBhdGggZD0ibS41IDYuNSA2LTZNNC41IDYuNWwxLjUzNi0xLjUzNiIgc3Ryb2tlPSIjQkVDOEQyIi8+PHBhdGggZD0ibTEuNSA2LjUgNi02TTUuNSA2LjVsMS41MzYtMS41MzYiIHN0cm9rZT0iIzkzOUNBNSIvPjwvZz48L3N2Zz4=);
    background-position-x: 9px;
    background-position-y: 8px;
    background-repeat: no-repeat;
}

.epc-panel-scroll {
    height: 100%;
    overflow-x: auto;
    overflow-y: auto;
    position: relative;
    resize: none;
    width: 100%;
}

.epc-panel-scroll.right {
    direction: ltr;
    overflow-x: hidden;
}

.epc-panel-container-solid-color-a {
    background-color: hsla(0,0%,100%,.94);
}

.epc-panel-container-gradient {
    background: repeating-linear-gradient(rgba(242,247,250,.7),rgba(242,247,250,.7) 36px,rgba(242,247,250,0) 0,rgba(242,247,250,0) 72px);
}

.layer-panel {
  /* left: 0px;
  top: 0px; */
  right: 0px;
  bottom: 0px;
  min-width: 320px;
  min-height: 100px;
  height: calc(100% - 300px);
  width: 350px;
}

.layer-panel .visibility {
  right: 30px;
  top: 0px;
  width: 25px;
  height: 50px;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  background-position: 2px 19px;
  background-repeat: no-repeat;
  position: absolute;
  cursor: pointer;
  z-index: 1;
}

.adsk-filterbox {
    position: relative;
}

.adsk-filterbox input.filter-box {
    box-sizing: border-box;
    direction: ltr;
    font-size: 12px;
    height: 34px;
    line-height: normal;
    margin: 0;
    outline: none;
    padding: 7px 12px 8px 40px;
    position: relative;
    width: 100%;
    z-index: 1;

    background: #fff;
    border: 1px solid transparent;
    color: #0a131c;
}

.adsk-filterbox .filter-box-icon {
    height: 7px;
    left: 22px;
    position: absolute;
    top: 12px;
    width: 10px;
    z-index: 1;
}

.adsk-filterbox .filter-box-icon {
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBzdHJva2U9IiM5Nzk3OTciIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSI+PHBhdGggZD0iTTcuNSA2LjVoLTVNOC41IDMuNWgtN005LjUuNWgtOSIvPjwvZz48L3N2Zz4=);
}

.adsk-filterbox.empty>.filter-box-close {
    display: none;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48Y2lyY2xlIGZpbGw9IiNCRUM4RDIiIGN4PSI3IiBjeT0iNyIgcj0iNyIvPjxwYXRoIGZpbGw9IiNGRkYiIGQ9Ik00LjUxIDEwIDQgOS40OSA5LjQ5IDRsLjUxLjUxeiIvPjxwYXRoIGZpbGw9IiNGRkYiIGQ9Im0xMCA5LjQ5LS41MS41MUw0IDQuNTEgNC41MSA0eiIvPjwvZz48L3N2Zz4=);
}

.adsk-filterbox .filter-box-close {
    cursor: pointer;
    height: 14px;
    position: absolute;
    right: 13px;
    top: 10px;
    width: 14px;
    z-index: 1;
}


</style>
