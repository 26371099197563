export const parseQuantity = function (quantity) {
  if (typeof quantity === "undefined" || quantity === null) return 0;

  let parts = quantity.toString().split(".");
  if (parseFloat(parts[1]) > 0) return quantity;
  else return parts[0];
};

export const parseDateToDDMMYYYY = function (date, delimiter = "/") {
  let parts = date.toString().split("-");

  return parts.reverse().join(delimiter);
};

export const formatCurrency = function (mount, currency = null) {
  let parser = Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR"
    // useGrouping: true,
    // maximumSignificantDigits: 2
  });

  return parser.format(mount);
};
