<template>
  <div>
    <div class="flex items-center float-left mt-3 mr-3 px-2">

        <div>

          <b-tooltip label="Chats" position="is-top">
              <b-icon icon="clock" size="is-small" v-bind:class="[ 'mr-1 float-left' ]"/>
          </b-tooltip>

        </div>

    </div>

    <div class="w-full font-bold">{{ chat.entityName }}</div>
    <div class="w-full text-xs text-grey-dark">{{ chat.project.name }}</div>
  </div>
</template>

<script>
import axios from 'axios'
import api from '~/api'
import store from "~/store";
//import EventBus from '~/plugins/bus'

export default {
  name: 'chatRecent',

  props: {
    chat: {},
    index: 0,
  },

  data: () => ({
    //chats: 0,
  }),

  created(){

    // Referenciamos
    var self = this;

  },

};
</script>
