<template>
  <div class="max-w-3xl mx-auto px-4 mt-3">
    <div class="flex justify-between">
      <div class="w-1/2">
        <b-button
          icon-left="chevron-left"
          icon-pack="fas"
          @click="onClickPrevius()"
          class="mr-4"
          :disabled="step==0 || processingData">{{ $t('previus') }}</b-button>
      </div>
      <div class="w-1/2 text-right" v-if="!showBtnFinish">
        <button v-if="processingData" type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-blue hover:bg-blue-light transition ease-in-out duration-150 cursor-not-allowed" disabled="">
          <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          {{ $t('processing_request') }}
        </button>

        <b-button v-else
          icon-right="chevron-right"
          icon-pack="fas"
          :type="complete ? 'is-success' : 'is-info' "
          @click="onClickNext()"
          class="mr-4" >{{ $t(complete ? 'process' : 'next') }}</b-button>
      </div>
      <div v-else>
        <b-button
          icon-right="check"
          icon-pack="fas"
          :type="'is-success'"
          @click="onClickFinish()"
          class="mr-4" >{{ $t('finish') }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'wizard-bulk-buttons',

  components: {
  },

  props: {
    step: { type: Number, required: true },
    complete: { type: Boolean, default: false },
    showBtnFinish: { type: Boolean, default: false },
    processingData: { type: Boolean, default: false },
  },

  data: () => ({

  }),

  mounted() {
  },

  created() {

  },

  methods: {
    onClickPrevius() {
      this.$emit('wizard-previus')
    },

    onClickNext() {
      this.$emit('wizard-next')
    },

    onClickFinish() {
      this.$emit('wizard-finish')
    },
  },
}
</script>
