<template>
<div>
    {{ $t('descriptive_name') }}
    <input v-model="desc_name" :placeholder="''" class="input" />

    <!-- nuevo fichero, mostrar todos los select para codificar nombre -->
    <div v-if="isNewFile">

        <div class="flex flex-row w-full pb-3 mt-4">

            <div class="flex flex-col w-1/5 pb-3 mt-4">
                {{ $t('year') }}<br>
                <b-select v-model="myForm.tyear" :placeholder="$t('select')" expanded>
                    <option v-for="i in yearValues" :value="i.value" :key="'tyear_'+i.value">
                        {{ i.name }}
                    </option>
                </b-select>
            </div>

        </div>


        <div class="flex flex-col w-2/5 pb-3 mt-4">
            {{$t('select')}}<br>
            <b-select v-model="myForm.t0" :placeholder="$t('select')" expanded
                @input="getSelectOptions(1, myForm.t0.id)">
                <option v-for="option in options.t0" :value="option" :key="'t0_'+option.id">
                    {{ option.description }}
                </option>
            </b-select>
        </div>

        <div class="flex flex-col w-2/5 pb-3 mt-4">
            {{$t('select')}}<br>
            <b-select v-model="myForm.t1" :placeholder="$t('select')" expanded
                @input="getSelectOptions(2, myForm.t1.id)">
                <option v-for="option in options.t1" :value="option" :key="'t1_'+option.id">
                    {{ option.description }}
                </option>
            </b-select>
        </div>

        <div class="flex flex-col w-2/5 pb-3 mt-4" v-if="!myForm.t1.code">
            {{$t('select')}}<br>
            <b-select v-model="myForm.t2" :placeholder="$t('select')" expanded
                @input="getSelectOptions(3, myForm.t2.id)">
                <option v-for="option in options.t2" :value="option" :key="'t2_'+option.id">
                    {{ option.description }}
                </option>
            </b-select>
        </div>

        <div class="flex flex-col w-2/5 pb-3 mt-4" v-if="!myForm.t1.code">
            {{$t('select')}}<br>
            <b-select v-model="myForm.t3" :placeholder="$t('select')" expanded
                @input="getSelectOptions(4, myForm.t3.id)">
                <option v-for="option in options.t3" :value="option" :key="'t3_'+option.id">
                    {{ option.description }}
                </option>
            </b-select>
        </div>

    </div>

    <!-- nueva version. Mostrar solo el select de numero version si es detectado en el nombre -->
    <div v-if="!isNewFile && hasVersionNumber()" class="flex flex-row w-full pb-3 mt-4">
        Version:
        <div class="flex flex-col p-2 font-bold">R</div>
        <b-select v-model="version.cliente" :placeholder="'version cliente'" class="flex flex-col"
            :disabled="false && versionType!='cliente'"> <!-- forzado, nunca deshabilitado, siempre editable -->
            <option v-for="letter in abecedary" :value="letter" :key="'tvc_'+letter">
                {{ letter }}
            </option>
        </b-select>
        <b-select v-model="version.interna" :placeholder="'version interna'" class="flex flex-col"
            :disabled="false && versionType!='interna'"> <!-- forzado, nunca deshabilitado, siempre editable -->
            <option v-for="number in versionValues" :value="number" :key="'tvi_'+number">
                {{ number }}
            </option>
        </b-select>
    </div>



    <div class="cursor-default tag is-info" type="button" v-if="codeAvailable" 
        @click="generateName">
        {{$t('generate_filename')}} <b-icon pack="fas" icon="file-alt" />
    </div>
    <div class="cursor-default tag is-warning" type="button" v-else >
        {{$t('generate_filename')}} <b-icon pack="fas" icon="file-alt" />
    </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from 'moment'
import axios from 'axios';

export default {
  name: "codification-dragados",

  components: {
  },


  props: {
    form: {type: Object, required: false},
    isNewFile: {type: Boolean, required: false, default: true}, // flag is new file (true), or is version (false)
    versionFileName: {type: String, required: false, default: ''}, // nombre del fichero al que subir version (para buscar patron en RXX en su nombre)
  },


  data: () => ({
    yearValues: [],
    abecedary: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    versionValues: [],
    version: { cliente: 'A', interna: '1'}, // versionado: RA1, RA2, RB4....
    versionType: 'cliente', // establece cual de los 2 se permite modificar (la letra de 'cliente' o la cifra de 'interna')
    desc_name: '',
    regex: /-R[A-Z]\d+/, // expresion regular que buscara si el versionFileName contiene el numero de version RXX que luego fue sustituido por "-R{letra}{digito}"

    options: {
      t0: [],
      t1: [],
      t2: [],
      t3: [],
    },

    myForm: {
        tyear: moment().format('YY'),
        t0: { id: null, code: ''},
        t1: { id: null, code: ''},
        t2: { id: null, code: ''},
        t3: { id: null, code: ''},
        tdate: moment().format('YYMMDD'),
    }

  }),


  created() {
    this.yearValues = this.getYearValues()    
    this.versionValues = [...Array(100).keys()] // [ 0, 1, 2, 3, 4... 99]
    // this.versionValues.shift() // saca el 0

    this.resetSelects(0)
    this.getSelectOptions(0, 0)

    // al subir version detectamos patron de numero version, y bloqueamos la cifra o el digito segun el proyecto
    // proyecto 'redaccion' sube el numero. proyecto 'ejecucion' sube la letra
    if (! this.isNewFile) {

        if (this.hasVersionNumber()) {
            let versionNumber = this.versionFileName.match(this.regex)[0]  // [0] la primera ocurrencia que encuentra y en teoria unica (-RA1)
            versionNumber = versionNumber.replace('-R', '') // A1
            this.version.cliente = versionNumber.substring(0,1) // A
            this.version.interna = versionNumber.substring(1) // 1
        }

        // Distincion de proyecto por ID. Por campo extra es posible pero más dificil de traer aqui el dato.
        // Para las pocas veces que ocurrirá meter aqui ids de proyectos que precisen mover la cifra
        if (this.project.id == 421) {
            this.versionType = 'interna'
        }

    }
  },


  computed: {

    ...mapGetters({
        project: 'app/project',
    }),

    // codigo del fichero generado
    codedName() {

        // en subidas de version, devolver el nombre original tal cual antes de subir version
        if (! this.isNewFile) {
            return this.versionFileName.replace(/\.[^/.]+$/, "") // sin extension
        }

        // si no ha puesto T0 es que no va codificado
        if (this.myForm.t0.code == '')
            return '';
        let name = this.myForm.tyear.toString()
        if (this.myForm.t1.code)
            name += '-' + this.myForm.t1.code
        if (this.myForm.t2.code)
            name += '-' + this.myForm.t2.code
        if (this.myForm.t3.code)
            name += '-' + this.myForm.t3.code

        // sin extension, se le añade en el componente que lo llame

        return name
    },

    codeAvailable() {

        // en subidas de version, no aparece los selectores, siempre true
        if (! this.isNewFile) {
            return true
        }

        // esta codificacion solo genera codigos en el 2º o 4º select
        return this.myForm.t1.code || this.myForm.t3.code
    }
  },


  methods: {

    getYearValues() {
        // rellenar yearValues con año actual +2 y -2
        let options = [];

        [-2, -1, 0, 1, 2].forEach(function(index) {
            let year = moment().add(index, 'year')
            options.push({value: year.format('YY'), name: year.format('YYYY')})
        })

        return options
    },


    // carga desde Api las opciones del select del nivel n (t1, t2..)
    async getSelectOptions(level, parent_id) {

        this.resetSelects(level)

        await axios.get("/api/v2/documentcodification?project_id=" + this.project.id + "&parent_id=" + parent_id)
            .then((response) => {
                this.options[`t${level}`] = response.data.data
            })
            .catch((error) => {
                this.$notify.error("error obteniendo codificaciones");
            });
    },


    // resetea los siguientes selects
    resetSelects(n) {
        while(n < 4) {
            this.myForm[`t${n}`]['code'] = '';
            n++
        }
    },


    // aplica el codigo de nombre generado al campo custom_name
    generateName() {

        // emit al componente padre validationtask-completion-modal con lo generado
        let customName = this.codedName // codedName dificil de setear, trabajamos con copia

        // Procesar numero de version de fichero
        if (this.isNewFile) {
            // buscar el patron RXX para poner numero de version. No todos los nombre generados lo tienen.
            if (customName.includes('RXX')) {
                // nuevo fichero, comenzar por RA1
                customName = customName.replace('RXX', 'RA1')
            }

        } else {
            // version. buscar el patron RXX ya relleno: -RA1, -RB8, -RB44......
            if (this.hasVersionNumber()) {
                customName = customName.replace(
                    customName.match(this.regex)[0],  // [0] la primera ocurrencia que encuentra y en teoria unica
                    '-R' + this.version.cliente + this.version.interna)
            }
        }

        this.$emit('generatedCodename', {
            desc_name: this.desc_name || '',
            custom_name: customName,
            coded_name: customName ? 1 : 0,
        })

    },


    // detecta si el fichero al que vamos a subir version tenia la info de version en su nombre, es decir el original RXX que luego fue sustituido por "-R{letra}{digito}"
    hasVersionNumber() {
        return this.regex.test(this.versionFileName);
    }


  }
}
</script>