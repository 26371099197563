<template>
<div>
  <div class="top-bar flex justify-end px-4">
    <div class="flex items-center text-white">
    </div>
  </div>

  <div class="panel">

    <!-- Nombre flujo (no editable) -->
    <div class="flex">
      <div class="w-1/2 pr-4">
        <l-form-input ref="validationName"
          :form="form"
          field="name"
          label="validation_name"
          :disabled="true"
        />
      </div>
    </div>

    <!-- Descripción -->
    <div class="flex items-center mt-8">
      <l-form-input
        ref="validationDescription"
        :form="form"
        field="description"
        label="validation_description"
        input-type="textarea"
        :maxlength="2000"
        class="w-full"
        :disabled="false"
      />
    </div>

    <!-- Cerrar flujo -->
    <div v-if="form.status != 6" class="w-full flex items-center">
      <b-field :label="'Cerrar flujo'">
        <b-switch v-model="is_closed"
          type="is-success"
        >
          {{ $t(is_closed ? 'cerrado' : 'no cerrado') }}
        </b-switch>
      </b-field>
    </div>
    <div v-else>
      <validation-status-label
          :status="form.status"
          fontSize="0.75"
          class="w-full"
          style="vertical-align: middle"
        />
    </div>
    
  </div>

  <form @submit.prevent="sendValidationData" @keydown="form.onKeydown($event)"
    class="w-full flex items-center justify-end mb-8">
    <v-button :disabled="disableSaveButton" type="submit" icon="thumbs-up" icon-pack="far" class="mr-4">{{ $t('save') }}</v-button>
    <v-button color="grey" icon="undo-alt" @click="$router.go(-1)">{{ $t('cancel') }}</v-button>
  </form>

</div>
</template>

<script>
import axios from 'axios'
import Form from 'vform'
import validationStatusLabel from '·/components/validations/validation-status-label';

export default {
  middleware: 'auth',
  title: 'validation_edit_page_title',
  subtitle: 'validation_edit_page_subtitle',
  menuOption: '2-4',


  components: {
    validationStatusLabel,
  },


  data: () => ({
    validationId: null,
    form: new Form({
      validation_id: null,
      name: '',
      description: '',
      status: null, // enviar status=6 para cerrar
    }),
    is_closed: false, // flag para marcar el flujo como cerrado
    disableSaveButton: false,
  }),


  created() {
    this.validationId = this.$route.params.validation_id
  },


  mounted() {
    this.getValidationData()    
    this.$refs.validationDescription.setFocus()
  },


  methods: {

    async getValidationData() {

      const { data } = await axios.get('/api/v2/validation/' + this.validationId)

      if (data && data.success) {
        this.form.name = data.data.template.name
        this.form.description = data.data.description
        this.form.status = data.data.status
        this.is_closed = data.data.status == 6 ? true : false
      } else {
        this.$notify.error('error_loading_data')
      }
    },


    async sendValidationData() {

      var url = '/api/v2/validation/' + this.validationId;
      this.disableButton();

      this.form.validation_id = this.validationId
      if (this.is_closed) {
        this.form.status = 6 // set status to closed
      } else {
        delete this.form.status
      }

      // Enviamos el form
      const { data } = await this.form.post(url)
      if (data && data.success) {

        this.$notify.success('success_editing')
        this.$router.push({ name: 'validation.home', params: {
          validation_id: this.createNew ? data.validation_id : this.validationId
        } })

      } else {
        this.$notify.error('error_saving')
      }
    },



    disableButton() {
      this.disableSaveButton = true
      let self = this
      setTimeout(function() {
        self.disableSaveButton = false
      }, 2000);
    },


  },
};
</script>
