<template>

  <div class="w-full">

    <div class="bg-grey-lightest">


      <div v-if="document.size > 0">

        <!-- etiqueta previsualización del nombre -->
        <span v-if="label.custom_name || label.desc_name" @click="toggleCodification" 
          class="inline-flex items-center p-1 rounded border-2 border-blue text-blue bg-white">
          <span>
            <span class="font-bold">{{ label.custom_name }}</span>
            <br>
            <span class="italic">{{ label.desc_name }}</span>
          </span>
          <b-icon v-if="!showCodification" pack="fas" icon="angle-double-down" size="is-small" class="ml-2 text-black" />
          <b-icon v-else pack="fas" icon="angle-double-up" size="is-small" class="ml-2 text-black" />
        </span>


        <div v-if="showCodification">

          <!-- lean, lean2, lean3 -->
          <codification-lean v-if="project.codification_form.startsWith('lean')"
            :form="{ isLastGroup: false }"
            :prefixCode="project.codification_form == 'lean2' || project.codification_form == 'lean3'
              ? validableParentCode
              : ''"
            @generatedCodename="(obj) => applyFileName(obj)"/>

          <codification-dragados v-if="project.codification_form=='dragados'"
            @generatedCodename="(obj) => applyFileName(obj)"/>

        </div>


      </div>

    </div>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import CodificationLean from '·/components/documents-codifications/codification-lean.vue';
import CodificationDragados from '·/components/documents-codifications/codification-dragados.vue';

export default {
  name: 'document-name-codification',

  components: {
    CodificationLean,
    CodificationDragados,
  },

  props: {
    document: { type: File, required: true },
    validableParentCode: { type: String, required: false, default: '' }, // para formato codificacion documentos "lean2" y "lean3" que van precedidos por codigo actividad padre
  },

  data: () => ({

    // muestra como quedará el nombre codificado
    label: {
      desc_name: '',
      custom_name: '',
    },

    showCodification: true,
  
  }),

  computed: {
    ...mapGetters({
      project: 'app/project',
    }),
  },

  methods: {


    // aplica al campo custom_name el codigo de nombre generado desde componente hijo de codificacion 
    applyFileName(obj) {

      this.document.custom_name = obj.custom_name
      this.document.desc_name = obj.desc_name
      this.document.coded_name = obj.coded_name

      // para etiqueta previsualización (aqui con extensión)
      this.label.custom_name = obj.custom_name + this.getFileExtension(this.document)
      this.label.desc_name = obj.desc_name

      this.toggleCodification(); // y oculta la codificacion tras acabar

    },


    toggleCodification() {
      this.showCodification = !this.showCodification
    },


    getFileExtension(file) {
      let re = /(?:\.([^.]+))?$/;
      return "." + re.exec(file.name)[1];
    },


  },

};
</script>