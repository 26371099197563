<template>
<div>

  <div v-if="homeType == 2" >
      <project-home-type2 />
  </div>
  <div v-else >
      <project-home-type1 />
  </div>

</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProjectHomeType1 from '·/pages/project/home-type1'
import ProjectHomeType2 from '·/pages/project/home-type2'

export default {
  middleware: 'auth',
  title: 'project_home_page_title',
  subtitle: 'project_home_page_subtitle',
  menuOption: '2-1',

  components: {
    ProjectHomeType1,
    ProjectHomeType2,
  },

  data: () => ({
    homeType: 1,
  }),

  computed: {
    ...mapGetters({
      company: 'app/company',
      project: 'app/project',
    }),
  },
  mounted() {

    // Carga el tipo de Home para esta company (campo configs de tabla companies, almacenado en la store al loguear)
    this.homeType = this.company.configs && this.company.configs.projectHome
      ? parseInt(this.company.configs.projectHome)
      : 1;

  },

  methods: {

  },

};
</script>

<style scoped>
.column-scroll {
  width: 50%;
  height: calc(100vh - 150px);
  overflow-x: hidden;
  overflow-y: auto;
}
.column-scroll:not(:last-child) {
  margin-right: 0.5rem;
}
.column-scroll:last-child {
  margin-left: 0.5rem;
}
</style>
