<template>
  <div v-if="display" class="w-auto p-4 mb-4 bg-white border rounded-lg shadow border-blue-light">
    <div class="w-full p-4 bg-white">
      <quill-editor v-model="content" :options="editorOption"></quill-editor>
    </div>
    <!-- ETIQUETAS -->
    <div style="max-width: 95%; margin: 0 auto">
      <b-field :label="$t('tags')">
      <model-tags
        v-if="! parentCommentId"
        :model-id="newComment ? 0 : commentId"
        :model-type="entityType + '/comment'"
        :allow-edit="false"
        :add-from-list="true"
        :parent-model-id="project.id"
        parent-model-type="project"
        ref="modelTags"
      />
      </b-field>
      <b-field :label="$t('documents')">
        <b-taginput
          v-model="documentsSelected"
          :data="documentsSearchResult"
          autocomplete
          ref="taginput"
          icon="label"
          :placeholder="$t('documents')"
          field="name"
          @typing="filterDataArray"
        >
          <template slot-scope="props">
            {{props.option.name}}
          </template>

          <template slot="empty">
            There are no items
          </template>

        </b-taginput>
      </b-field>
    </div>
    <div class="flex items-center justify-end p-4 bg-white border-t">
      <!-- Botón Upload -->
      <button class="mr-2 button is-primary" @click="saveComment" :disabled="processing">
        <b-icon pack="fas" icon="check" size="is-small"/>
        <span>{{ $t(commentButtonTitle) }}</span>
      </button>
      <!-- Botón cerrar -->
      <button class="button" type="button" @click="hide">{{ $t('close') }}</button>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import at from "~/store/modules/at";
  import ModelTags from "·/components/model-tags";
  import {mapGetters} from 'vuex'

  // require styles
  import "quill/dist/quill.core.css";
  import "quill/dist/quill.snow.css";
  import "quill/dist/quill.bubble.css";
  import "quill-emoji/dist/quill-emoji.css";
  import {quillEditor} from "vue-quill-editor";
  import Quill from "quill";
  import emoji from "quill-emoji/dist/quill-emoji";
  import EventBus from "~/plugins/bus";

  Quill.register("modules/quill-emoji", emoji);
  import Mention from "quill-mention";

  import VueTribute from "vue-tribute";

  export default {
    name: "entity-comment-add",

    components: {
      quillEditor,
      VueTribute,
      ModelTags
    },

    computed: {
      ...mapGetters({
        project: 'app/project',
      }),

      /**
       * Filtra la lista del auto completado a partir de las coincidencias en los strings
       * @returns {*[]}
       */
      filteredDataArray(text) {
        return [];
        // if (text)
        //   return this.documentsList;
        // let search = text.toLowerCase();
        // return this.documentsList.filter((option) => {
        //   return option.name
        //     .toLowerCase()
        //     .indexOf(search) >= 0
        // });
      }
    },

    props: {
      entityId: {type: Number, required: true},
      entityType: {type: String, required: true},
      documentsList: {type: Array, required: true}
    },

    data: () => ({
      display: false,
      modalTitle: "",
      newComment: true,
      editComment: false,
      answerComment: false,
      commentId: null,
      parentCommentId: null,
      content: null,
      processing: false,
      customToolbar: [
        [["emoji"], ["bold", "italic", "underline", "strike"]],
        [({align: []}, "blockquote")],
        [{indent: "-1"}, {indent: "+1"}],
        [{header: 1}, {header: 2}],
        [{list: "ordered"}, {list: "bullet"}],
        [{color: []}, {background: []}]
      ],
      commentButtonTitle: "",
      editorOption: {
        placeholder: "",
        modules: {
          toolbar: {
            container: [
              ["emoji"],
              ["bold", "italic", "underline", "strike"],
              [{list: "ordered"}, {list: "bullet"}],
              ["link", "clean"]
            ],
            handlers: {
              emoji: function () {
              }
            }
          },
          "emoji-toolbar": true,
          "emoji-textarea": false,
          "emoji-shortname": true,
          mention: {
            offsetTop: 2,
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@", "#"],
            source: function (searchTerm, renderList, mentionChar) {
              let values;
              let i = 0;
              let self = this;
              if (mentionChar === "@") {
                values = at.state.atUsers;
              }

              if (searchTerm.length === 0) {
                renderList(values, searchTerm);
              } else {
                const matches = [];
                if (values) {
                  for (i = 0; i < values.length; i++) {
                    if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
                  }
                }
                renderList(matches, searchTerm);
              }
            }
          }
        },
        formats: [
          'mention',
          'background',
          'bold',
          'color',
          'font',
          'code',
          'italic',
          'link',
          'size',
          'strike',
          'script',
          'underline',
          'blockquote',
          'header',
          'indent',
          'list',
          'align',
          'direction',
          'code-block',
          'formula'
          // 'image'
          // 'video'
        ]
      },
      documentsSearchResult: [],
      documentsSelected: [],
    }),

    methods: {
      async saveComment() {
        let self = this;

        self.processing = true;

        let baseUrl = this.newComment ?
          "/api/v2/" + this.entityType + "/" + this.entityId + "/comment" :
          "/api/v2/" + this.entityType + "/" + this.entityId + "/comment/" + this.commentId + "/edit";

        var postData = {
          comment: self.content,
          documents: this.documentsSelected.map((doc) => doc.id)
        };

        // Añadir 'parent_comment_id' para respuestas
        if (this.parentCommentId) {
          postData["parent_comment_id"] = this.parentCommentId;
        }
        const {data} = await axios.post(baseUrl, postData);
        if (data && data.success) {

          // Sincronizamos las tags si existe el componente
          if (this.$refs.modelTags) {
            this.$refs.modelTags.syncTagsToModel(data.comment.id)
          }

          let title = this.entityType === 'issue' ? this.modalTitle + '_issue' : this.modalTitle;
          this.$notify.success("success_" + title);
          this.$emit("comment-saved", data.comment);
          this.hide();
          self.processing = false;
        } else {
          this.$notify.error("error_add_comment");
          self.processing = false;
        }
      },

      async loadComment(commentId) {
        // Referenciamos
        var self = this;

        // Nos traemos el historial de mensajes
        const {data} = await axios.get("/api/v2/" + this.entityType + "/comment/" + commentId);

        // Si encontramos mensajes los pasamos
        if (data && data.success) {
          self.content = data.comment.comment;
          self.parentCommentId = data.comment.parent_comment_id;
          self.documentsSelected = data.comment.documents.map(doc => {
            return {id: doc.id, name: doc.name}
          });
          // Inicializamos las tags si existe el componente
          if (this.$refs.modelTags) {
            this.$refs.modelTags.initTags(data.comment.tags)
          }
        }
      },

      filterDataArray(text) {
        let search = text.toLowerCase();
        this.documentsSearchResult = this.documentsList.filter((option) => {
          return option.name
            .toLowerCase()
            .indexOf(search) >= 0
        });
      },

      new() {
        this.newComment = true;
        this.editComment = false;
        this.content = "";
        this.modalTitle = "add_comment";
        this.commentButtonTitle = "comment";
        this.display = true;
      },
      answer(parentCommentId) {
        this.parentCommentId = parentCommentId;
        this.newComment = true;
        this.editComment = false;
        this.content = "";
        this.modalTitle = "answer_comment";
        this.commentButtonTitle = "answer";
        this.display = true;
      },
      edit(commentId) {
        this.commentId = commentId;
        this.editComment = true;
        this.newComment = false;
        this.loadComment(commentId);
        this.modalTitle = "edit_comment";
        this.commentButtonTitle = "edit";
        this.display = true;
      },
      hide() {
        this.parentCommentId = null;
        this.display = false;
        this.$emit("editor-hided");
      }
    }
  };
</script>
<style>
  #emoji-palette {
    max-width: 320px !important;
  }

  #tab-panel {
    padding: 10px !important;
  }

  .bem {
    margin: 5px !important;
  }

  .ql-editor {
    height: 300px;
  }

  .ql-mention-list-container {
    max-width: 85%;
    width: auto;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
    z-index: 1000;
  }

  .ql-mention-list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .ql-mention-list-item {
    cursor: pointer;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    padding: 0 20px;
    vertical-align: middle;
  }

  .ql-mention-list-item.selected {
    background-color: #385da5;
    color: #fff;
    text-decoration: none;
  }

  .mention {
    height: 24px;
    width: 65px;
    border-radius: 6px;
    color: #3490dc;
    padding: 3px 0;
    background: none;
  }

  .mention > span {
    margin: 0 3px;
  }
</style>
