<template>
  <div>
    <div class="flex flex-col w-full">
      <div class="panel">
        <viewer-page-design ref="refViewerPageDesign"
          :pictograms="pictograms"
          :project-id="projectId"
          :can-actions-pictogram="canActionsPictogram"
          @epc-in-design-mode="(e) => { designMode = e }"
          @epc-photovoltaic-park-loaded="(e) => { partsPark = e }"
          @epc-pictogram-project-dataset="(e) => { datasetProject = e }"
          @epc-pictogram-data-progress="(e) => { progressPictogram = e }"/>

          <!-- <viewer-progress v-if="! designMode && showProgress"
            :project-id="projectId"
            :pictogram-id="pictogramIdSelected"
            :dataset-project="datasetProject"
            @epc-pictogram-data-progress="(e) => { $emit('epc-pictogram-data-progress', e) }"
          /> -->
      </div>

      <div v-if="! designMode">
        <viewer-chartsets v-if="datasetProject"
          :parts="partsPark"
        />

        <div v-if="!datasetProject && progressPictogram">
          <div v-for="(progress, index) in progressPictogram" :key="'chartprogress_' + index">
            <viewer-chartset-pictogram
              :dataset="progress"
            />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";
import ViewerPageDesign from "./viewer-konva/design-konva/ViewerPageDesign.vue";
import ViewerChartsets from "./viewer-konva/design-konva/ViewerCharsets.vue";
import ViewerChartsetPictogram from "./viewer-konva/design-konva/ViewerCharsetPictogram.vue";
import ViewerProgress from "./viewer-konva/design-konva/ViewerProgress.vue"

import VueChartkick from 'vue-chartkick'
import Chart from 'chart.js'
import moment from 'moment'

Vue.use(VueChartkick, {adapter: Chart})
Vue.use(moment)

export default {
  name: "manage-photovoltaic-park",

  components: {
    ViewerPageDesign,
    ViewerChartsets,
    ViewerChartsetPictogram,
    ViewerProgress
  },

  data: () => ({
    pictograms: [], // contiene la lista de pictogramas existentes en el proyecto

    designMode: false, // bandera que indica si se esta en modo diseño
    partsPark: [], // arreglo que contiene las partes que component el paque fotovoltaico
    datasetProject: true,
    progressPictogram: null
  }),

  props: {
    projectId: { type: Number, required: true },
    showDownloadFileOriginal: { type: Boolean, default: true },
    permittedActions: { type:Object, default() { return {} } }, // acciones que se mostraran en la columna acciones por defecto todas
    canActionsPictogram: { type:Object, default() { return {} } } // acciones que se puede realizar sobre el pictograma por defecto ninguna
  },

  computed: {
  },

  created() {
    this.getProjectPictograms();
  },

  mounted() {
  },

  destroyed() {
  },

  beforeDestroy() {

    },

  methods: {
    async getProjectPictograms() {
      this.isLoading = true;
      let params = {
        with_screenshot: 1,
        with_associated_activity: 1,
      };

      const { data } = await axios.get(
        "/api/v2/project/" + this.projectId + "/pictograms",
        { params: params }
      );

      if (data && data.success) {
        this.pictograms = data.pictograms;
        this.total = data.pictograms ? data.pictograms.length : 0;
      } else {
        this.$notify.error("error_loading_documents");
      }
      this.isLoading = false;
    },

    FullScreenHack() {
      this.$refs.refViewerPageDesign.ForceRezise()
    }
  }

};
</script>

<style scoped>
</style>
