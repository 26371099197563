<template>
  <div>
    <div class="panel">
      <div class="flex items-center justify-between w-full mb-5">
        <div><label><b>Cantidad de campos extras a vincular: {{ this.extrafields.length }}</b></label></div>
        <div>
          <b-tooltip :label="$t('close')" position="is-left">
            <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
              <b-icon pack="fas" icon="times" size="is-small" />
            </div>
          </b-tooltip>
        </div>
      </div>

      <div class="panel">
        <div><label><b>{{ $t('statuses_list') }}</b></label></div>
        <div class="w-full inline-flex pb-3">
          <multiselect
              v-model="statusesSelected"
              :options="statuses"
              :placeholder="$t('statuses_list')"
              :disabled="disabled"
              :multiple="true"
              label="name"
              class="rounded-r-none"
              searchable
              allowEmpty
              :closeOnSelect="false"
              :showLabels="false"
              :loading="processingData"
              track-by="id"
          >
              <!--<span slot="placeholder" class="text-base">-->
              <!--{{ $t('parent_department') }}-->
              <!--</span>-->
              <!-- <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template> -->
          </multiselect>

        </div>

        <div class="flex items-center justify-end w-full">
          <v-button v-if="!processingData"
            @click="linkStatuses()"
            icon="plus"
          >{{ $t('assign_extrafields') }}</v-button>

          <v-button-processing v-if="processingData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import Multiselect from 'vue-multiselect'
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  components: {
    ScaleLoader,
    Multiselect,
  },

  props: {
    extrafields: { type: Array, required: true },
    apiEntity: { type: String, default: 'extrafield' }
  },

  data() {
    return {
      disableSaveButton: false,
      processingData: false,
      loaded: false,
      disabled: false,

      statuses: [],
      statusesSelected: [],
    }
  },

  computed: {
  },

  watch: {
  },
  created() {
    this.getProducts()
  },
  mounted() {

  },

  methods: {
    async getProducts() {
    },


    onCloseClicked() {
      this.value = null;
      this.$emit('epc-close', true)
    },

    async assignExtrafieldsActivity() {
      if (!this.activity.id) {
        this.$notify.error(this.$t('empty_field_required', ['DSO']))
        return
      }

      if( this.checkedRows.length == 0){
        this.$notify.error(this.$t('error_select_extrafield'))
        return
      }

      if (!this.activity.id) {
        this.$notify.error(this.$t('empty_field_required', ['DSO']))
        return
      }

      this.processingData = true;
      let fields = [];
      this.checkedRows.forEach(field => {
        fields.push(field.id);
      });

      let self = this;

      await axios.post("/api/v2/extrafields/assign", {
        entityId: this.activity.id,
        entity: this.activity.type,
        fields: fields
      })
      .then(response => {
        let data = response.data
        this.processingData = false;

        if (data && data.success) {
          // reload
          self.activity = {};
          self.checkedRows = [];

          self.$notify.success('success_editing')

        } else if(data && data.msg) {
          self.$notify.error('no_access_permissions')
        } else {
          self.$notify.error('error_saving')
        }
      })
      .catch(error => {
          self.processingData = false;
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
                ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("assignment_insert_error"),
            type: "is-danger",
            position: "is-top-right"
          });
      });
    },

    async unassignExtrafields(fieldId, valueId, fieldName, valueName, entity='activity') {
      let params = { };
      params.entity = entity;
      params.assignment = valueId;
      valueName = valueName === 'all' ? 'todas las asignaciones' : valueName;
      let self = this;
      this.$dialog.confirm({
        title: this.$t('unassign'),
        message: this.$t('unassign_question_extrafield', [fieldName, valueName]),
        confirmText: this.$t('unassign'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {

          const { data } = await axios.post("/api/v2/extrafields/" + fieldId + "/unassign", params)
          if (data && data.success) {

            // reload
            this.readExtrafield( { with: 'assigned' } )
            this.$notify.success('success_deleting_generic')

          } else if(data && data.msg){

            this.$notify.error('no_access_permissions')

          } else {
            this.$notify.error('error_saving')
          }
        }
      })
    },
  },
};
</script>

