<template>
  <b-modal :active.sync="isModalActive" :can-cancel="canCancel">
    <div v-if="isLoading">
      <scale-loader></scale-loader>
    </div>

    <div v-if="!isLoading" class="modal-card">
      <div class="modal-card-body panel p-0">
        <ViewerPageKonva
          :canActionsPictogram="canActionsPictogram"
          :pictogram="pictogram"
          :type-pictogram="pictogram.type"
          :work-layer="workLayer"
          @epc-reload="() => { $emit('epc-reload') }"
          @epc-screenshot="(e) => { $emit('epc-screenshot', e) }"></ViewerPageKonva>
      </div>
      <div></div>
    </div>
  </b-modal>
</template>

<script>
  import {mapGetters} from "vuex";
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
  import ViewerPageKonva from './ViewerPageKonva.vue'

  export default {
    name: "konva-preview-modal",

    components: {
      ScaleLoader,
      ViewerPageKonva
    },

    props: {
     canActionsPictogram: { type:Object, default() { return {} } } // acciones que se puede realizar sobre el pictograma por defecto ninguna
    },

    data: () => ({
      isModalActive: false,
      isOpen: false,
      isLoading: true,
      assignmentId: 0,

      processingData: false,

      canCancel: ['escape', 'x', 'outside'],
      selectedDocument: null,

      registerOverDrawingMode: false, // indica si la medición se realizará sobre un plano

      pictogram: null,
      workLayer: null // capa donde  trabajará el usuario, solo permite trabajar en esa capa si null trabaja en todas
    }),

    mounted() {

    },

    computed: {
      ...mapGetters({
        project: 'app/project',
        user: 'auth/user',
      }),
    },

    watch: {
      registerOverDrawingMode(newValue, oldValue) {
        this.$nextTick(() => {
          this.modalWidth = (newValue ? '90%' : '30%')
        });
      },

      selectedDocument(newValue, oldValue) {
        if( newValue != null )
          this.showDocument(newValue)
      }
    },

    methods: {

      // handleDrawingLine(annotations) {
      handleDrawingLine(measure) {
        // let measure = annotations[0].uka
        // if( ! measure )
        //   measure = annotations[0].Aka
        // this.$refs.assignment_contribute.setUnits( measure.split(' ')[0].replace('"', '').replace(',', '') )
        this.$refs.assignment_contribute.setUnits( measure )
      },
      handleProcessingData(event) {
        this.processingData = event
        if( ! event ) {
          this.canCancel = ['escape', 'x', 'outside']
          this.hide()
        }
        else
          this.canCancel = !event
      },

      handleProcessingDataFinished(event) {
        this.processingData = false
        this.canCancel = ['escape', 'x', 'outside']

        this.hide()
      },

      showDocument(document) {
        this.$refs.pdf_editor.show(document)
      },

      show(pictogram, workLayer=null) {
        this.isLoading = true
        this.isModalActive = true;
        this.canCancel = ['escape', 'x', 'outside']
        this.selectedDocument = null
        this.processingData = false
        this.pictogram = pictogram
        this.workLayer = workLayer

        this.$nextTick(() => {
          setTimeout(() => {
            // Simulate asynchronous task completion
            this.isLoading = false
          }, 1000);
        });
      },

      hide() {
        this.assignmentId = 0;
        this.selectedDocument = null
        this.processingData = false
        this.isModalActive = false;
        this.registerOverDrawingMode = false
      },

      handleSaveDocument(data){
        if( this.registerOverDrawingMode )
          this.$refs.pdf_editor.clickButtonSavePdfTron(data)
      },
    }
  };
</script>

<style scoped>
::v-deep.max-width-custom .modal-content {
  max-width: 90% !important;
}
</style>
