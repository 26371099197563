<template>
  <div>
    <div class="flex flex-wrap justify-between">
      <v-button icon="arrow-left" @click="goBack">{{ $t('back') }}</v-button>
      <div class="flex">
        <span class="px-1 hover:text-gray-700 cursor-pointer lg:px-2 text-sm xl:rounded xl:py-2 xl:hover:bg-gray-200"
            @click="exportReport">
          <b-tooltip label="Exportar PDF" position="is-left">
            <i class="lg:w-8 fas fa-book p-2 bg-gray-200 rounded-full"></i>
            <span class="mx-1 hidden xl:inline-">Exportar PDF</span>
          </b-tooltip>
        </span>
      </div>
    </div>

        <!-- Filtros (de momento solo para un proyecto)--> 
    <b-collapse v-if="project.id == 528" class="card filters-collapse" :open.sync="showFilters">
      <div slot="trigger" class="card-header filters-trigger">
        <p class="card-header-title">
          {{ $t('filters') }}
        </p>
        <a class="card-header-icon">
          <b-icon pack="fa" v-show="! showFilters" icon="plus-circle"></b-icon>
          <b-icon pack="fa" v-show="showFilters" icon="minus-circle"></b-icon>
        </a>
      </div>

      <div class="panel" style="border-width: 0px">
        <div class="flex flex-col lg:flex-row mb-4">

          <div class="w-full lg:w-1/3 pr-4">
            <b-field :label="$t('search_by_code')">
              <b-select
                v-model="filters.code"
                :placeholder="$t('code')"
                icon="search" icon-pack="fas"
                @input="applyFilters(1)"
                expanded
              >
                <option :value="null">{{ $t('all') }}</option>
                <option v-for="code in codes"
                        :value="code.code" :key="'code_'+code.code"
                >
                  {{ code.name }}
                </option>
              </b-select>
            </b-field>
          </div>

        </div>
        <button class="button is-primary" @click="cleanFiltersClicked()">{{ $t('clean_filters') }}</button>
      </div>
    </b-collapse>

    <div v-if="isLoading" class="my-4 text-center">
      <scale-loader />
    </div>
    <div v-else>
      <div class="text-sm overflow-x-auto mt-4">
        <table class="w-full">  
          <tr class="bg-blue-lightest uppercase">
            <th rowspan="2" class="border border-blue-lighter has-text-centered p-2 text-blue-dark vertical-center" >{{$t('equipment')}}</th>
            <th rowspan="2" class="border border-blue-lighter has-text-centered p-2 text-blue-dark vertical-center">{{$t('technical_specification')}}</th>
            <th colspan="2" class="border border-blue-lighter has-text-centered p-2 text-blue-dark vertical-center">{{$t('et_approved')}}</th>
            <th colspan="2" class="border border-blue-lighter has-text-centered p-2 text-blue-dark vertical-center">{{$t('et_approved_gti')}}</th>
            <th colspan="2" class="border border-blue-lighter has-text-centered p-2 text-blue-dark vertical-center">{{$t('et_pending')}}</th>
            <th colspan="2" class="border border-blue-lighter has-text-centered p-2 text-blue-dark vertical-center">{{$t('et_not_submitted')}}</th>
          </tr>
          <tr class="bg-blue-lightest uppercase">
            <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark vertical-center" >{{$t('number')}}</th>
            <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark vertical-center" >%</th>
            <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark vertical-center" >{{$t('number')}}</th>
            <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark vertical-center" >%</th>
            <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark vertical-center" >{{$t('number')}}</th>
            <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark vertical-center" >%</th>
            <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark vertical-center" >{{$t('number')}}</th>
            <th class="border border-blue-lighter has-text-centered p-2 text-blue-dark vertical-center" >%</th>
          </tr>
          <tr class="" v-for="(techspec, index) in data">
            <th class="border border-blue-lighter p-4 has-text-centered text-blue-dark bg-blue-lightest">{{index}}</th>
            <td class="border border-blue-lighter p-4 has-text-centered">{{techspec.total}}</td>
            <td class="border border-blue-lighter p-4 has-text-centered">{{techspec.approved}}</td>
            <td class="border border-blue-lighter p-4 has-text-centered">{{techspec.approved_porc}}%</td>
            <td class="border border-blue-lighter p-4 has-text-centered">{{techspec.approved_gti}}</td>
            <td class="border border-blue-lighter p-4 has-text-centered">{{techspec.approved_gti_porc}}%</td>
            <td class="border border-blue-lighter p-4 has-text-centered">{{techspec.pending}}</td>
            <td class="border border-blue-lighter p-4 has-text-centered">{{techspec.pending_porc}}%</td>
            <td class="border border-blue-lighter p-4 has-text-centered">{{techspec.not_submitted}}</td>
            <td class="border border-blue-lighter p-4 has-text-centered">{{techspec.not_submitted_porc}}%</td>
          </tr>
          <tr class="bg-blue-lightest">
            <th class="border border-blue-lighter p-4 has-text-centered text-blue-dark uppercase">{{$t('et_total')}}</th>
            <td class="border border-blue-lighter p-4 has-text-centered text-blue-dark">{{total.total}}</td>
            <td class="border border-blue-lighter p-4 has-text-centered text-blue-dark">{{total.approved}}</td>
            <td class="border border-blue-lighter p-4 has-text-centered text-blue-dark">{{total.approved_porc}}%</td>
            <td class="border border-blue-lighter p-4 has-text-centered text-blue-dark">{{total.approved_gti}}</td>
            <td class="border border-blue-lighter p-4 has-text-centered text-blue-dark">{{total.approved_gti_porc}}%</td>
            <td class="border border-blue-lighter p-4 has-text-centered text-blue-dark">{{total.pending}}</td>
            <td class="border border-blue-lighter p-4 has-text-centered text-blue-dark">{{total.pending_porc}}%</td>
            <td class="border border-blue-lighter p-4 has-text-centered text-blue-dark">{{total.not_submitted}}</td>
            <td class="border border-blue-lighter p-4 has-text-centered text-blue-dark">{{total.not_submitted_porc}}%</td>
          </tr>
        </table>
      </div>

      <div class="w-full mt-8 panel">
        <div class="flex flex-row w-full"><bar-chart :data="bars" height="500px"/></div>
      </div>

      <div class="w-full mt-8 panel flex flex-wrap justify-evenly">
        <div class="mb-5">
          <div class="text-center text-blue font-bold mb-2">{{$t('et_approval')}}</div>
          <pie-chart :data="pieTotal" width="350px" height="350px"/>
        </div>
        <div class="mb-5">
          <div class="text-center text-blue font-bold mb-2">{{$t('et_approval_gti')}}</div>
          <pie-chart :data="pieGTI" width="350px" height="350px"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import {mapGetters} from 'vuex'
import moment from 'moment'
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'technical_specifications_page_title',
  subtitle: 'technical_specifications_page_subtitle',
  menuOption: '2-5',

  components: {
    ScaleLoader
  },

  props: {
  },

  data: () => ({
    isLoading: true,
    showFilters: false,
    data: {},
    total: {},
    bars:  [],
    pieTotal: [],
    pieGTI: [],
    filters: {
      p: null,
      code: null
    },
    codes: [
      {code: "ET-FVDG", name: "PLANTA SOLAR FOTOVOLTAICA DEL DEPÓSITO DE GETAFE"},
      {code: "ET-FVDR", name: "PLANTA SOLAR FOTOVOLTAICA DEL DEPÓSITO DE RETAMARES"},
      {code: "ET-FVEAV", name: "PLANTA SOLAR FOTOVOLTAICA DE LA EDAR ARROYO VALENOSO"}
    ]
  }),

  computed: {
    ...mapGetters({
      project: 'app/project',
    }),
  },

  mounted() {
    this.filters.p = this.project.id
    this.getDataDashboard(true)
  },

  methods: {

    async getDataDashboard(isFirst = false) {
        this.isLoading = true
        let {data} = await axios.get('/api/v2/technical-specifications/dashboard', {params: this.filters});

        if (data.success) {
            if (isFirst) {
              // this.codes = data.codes
            }
            this.data = data.data
            this.total = data.total
            this.mapBars()
            this.mapPie()
        } else {
            this.$notify.error("error_loading_data");
        }
        this.isLoading = false
    },

    async exportReport() {
      if( !this.isLoadingExport ){
        this.$notify.success('generating_report');

        let self = this
        let url = '/api/v2/export/technical-specifications/dashboard';
        await axios.get(url, {params: this.filters})
          .then(response => {
            window.open(response.data);
            self.$notify.success('report_generated')
          })
          .catch((error) => {
            //Se produjo un error
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });
      }
    },

    async mapBars() {
      let approved = []
      let pending = []
      let not_submitted = []

      for (let techspec in this.data) {
        approved.push([techspec, this.data[techspec].approved])
        pending.push([techspec, this.data[techspec].pending])
        not_submitted.push([techspec, this.data[techspec].not_submitted])
      }

      this.bars = [
        {name: this.$t('et_approved'), data: approved}, 
        {name: this.$t('et_pending'), data: pending}, 
        {name: this.$t('et_not_submitted'), data: not_submitted}
      ]
    },

    async mapPie() {
      this.pieTotal = [
        [this.$t('et_approved'), this.total.approved],
        [this.$t('et_pending'), this.total.pending],
        [this.$t('et_not_submitted'), this.total.not_submitted]
      ]

      let approvedGTI = 0
      this.pieGTI = []
      for (let techspec in this.data) {
        this.pieGTI.push([techspec, this.data[techspec].approved_gti])
        approvedGTI += this.data[techspec].approved_gti
      }

      this.pieGTI.push([this.$t('not_approved'), this.total.total_gti - approvedGTI])
    },

    cleanFiltersClicked() {
      this.filters.code = null
      this.applyFilters(1)
    },

    applyFilters(page) {
      this.getDataDashboard()
    },

    goBack() {
      this.$router.push({'name': 'project.technical-specifications'})
    },
  },

};
</script>

<style scoped>

.vertical-center {
  vertical-align: inherit !important;
}
</style>
