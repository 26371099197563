<template>
  <div class="w-full">
    <div v-if="statuses.length > 0">
      <div
        v-for="status in statuses" :key="statusType+'_'+status.id"
        class="flex flex-row mb-1 bg-grey-light border hover:border-blue rounded p-1">
        <div class="flex items-stretch">
          <b-icon
            :icon="status.is_completion ? 'check-circle' : 'square-full'"
            pack="fas"
            :style="{ color: status.color }"
            style="height: 1.5rem !important; width: 1.5rem !important;"
            size="is-medium">
          </b-icon>
          <!-- <span v-else class="rounded inline-block" style="width: 25px; height: 25px;"
            :style="{ backgroundColor: status.color }"></span> -->
        </div>
        <div class="flex-grow text-md mr-1 ml-1"><p>{{ status.name }}</p></div>
        <div>
          <span @click="_OnClickEdit(status)">
            <b-icon
              icon="pencil-alt"
              pack="fas"
              type="is-info"
              size="is-small">
            </b-icon>
          </span>
          <span @click="_OnClickDelete(status)">
            <b-icon
              icon="trash-alt"
              pack="fas"
              type="is-danger"
              size="is-small">
            </b-icon>
          </span>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-row bg-grey-light border hover:border-blue rounded">
      <p class="text-center w-full">No tiene estados definidos</p>
    </div>
  </div>
</template>

<script>

export default {
name: "pictogram-statuses-list",

components: {
},

props: {
  statuses: { type: Array, required: true },
  statusType: { type: String, required: true },
},

data: () => ({
  isLoading: false,
  data: {},
}),

created() {
},

computed: {
},

methods: {
  /**
   * Confirma y borra la medición abierta en el modal
   */
  async deleteAssignment() {
    let self = this;

    this.$dialog.confirm({
      title: this.$t("delete_measurement"),
      message: this.$t("delete_measurement_confirm_text", [this.data.name]),
      confirmText: this.$t("delete_measurement"),
      type: "is-danger",
      hasIcon: true,
      onConfirm: async () => {
        this.isLoading = true;
        let url = "/api/v2/assignment/" + self.id + "/delete";
        const { data } = await axios.post(url);

        if (data.success) {
          self.data = data;

          this.$toast.open({
            message: this.$t("measurement_remove_success"),
            type: "is-success",
            position: "is-top-right",
          });
          self.$emit("reload"); //pide a su componente padre que recargue
          self.hide();
        } else {
          this.isLoading = false;
          // Error
          this.$toast.open({
            message: this.$t("measurement_remove_error"),
            type: "is-danger",
            position: "is-top-right",
          });
        }
      },
    });
  },

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  },

  _OnClickEdit(status) {
    this.$emit('epc-pictogram-status-edit', { type: this.statusType, status })
  },

  _OnClickDelete(status) {
    this.$emit('epc-pictogram-status-delete', { type: this.statusType, status })
  }
},
};
</script>
