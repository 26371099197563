<template>
    <div class="page-with-top-bar">
      <project-template-admin-quick-buttons hiddenOption="import_export_activities"/>
      <section>
          <div class="mt-4">
              <b-tabs type="is-boxed" v-model="mainActiveTab">
                <b-tab-item>
                  <template slot="header">
                    <b-icon pack="fas" icon="file-alt"></b-icon>
                    <span> {{ $t('activities') }} </span>
                  </template>
                  <div>
                    <template-manage-activities :projectId="project_id"/>
                  </div>
                </b-tab-item>

                <!-- <b-tab-item>
                  <template slot="header">
                    <b-icon pack="fas" icon="file-import"></b-icon>
                    <span> {{ $t('import_activities') }} </span>
                  </template>
                  <div>
                    <import-entities
                      :can-overwrite="true"
                      :project-id="project_id"
                      :withHistory="false"
                      entity-type="activity/templates"
                    />
                  </div>
                </b-tab-item> -->
              </b-tabs>
          </div>
      </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import importEntities from '../../../components/import-entities'
import ProjectTemplateAdminQuickButtons from '../../../components/project-template-admin-quick-buttons'
import TemplateManageActivities from '../../../components/templates/project/template-manage-activities.vue'

export default {
  components: {
    importEntities,
    ProjectTemplateAdminQuickButtons,
    TemplateManageActivities
  },
  middleware: "auth",
  title: "activities",
  menuOption: "0-6",

  computed: {
    ...mapGetters({
      project: 'template/project',
    }),
  },

  data: () => ({
    project_id: null,
    mainActiveTab: 0
  }),

  created() {
    this.$options.subtitle = ['project_actions_page_subtitle', [this.project.name.toUpperCase()]]
    this.project_id =  parseInt(this.$route.params.template_id)
  },

  methods: {

  },
};

</script>
