<template>
  <div class="subheader">
    <div class="actions-group" id="file-actions">
      <div class="float-left">

        <!-- <div v-if="designMode" action-name="views" action-category="left-panel" class="subheader__item c-subheader__item--views ga-model-views"
          @click="() => { $emit('epc-open-panel', 'panel-views') }">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="subheader__item-icon"><path d="M18 6H6a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1zm-1 2v5H7V8z" fill="#0a131c"></path></svg>
          <span class="subheader__item-txt ">
            <span class="subheader__item-txt-number"></span>
            <span class="subheader__item-txt-string">{{ $t('pictograms') }}</span>
          </span>
        </div> -->

        <div class="subheader__item c-subheader__item--views ga-model-views">
          <b-dropdown aria-role="list">
            <template #trigger>
                <p role="button">
                    <b-icon icon="solar-panel" pack="fas"></b-icon> {{ $t(currentView)}} <b-icon icon="chevron-down" pack="fas"></b-icon>
                </p>
            </template>

            <b-dropdown-item aria-role="listitem"
              @click="() => { currentView='pilings'; $emit('epc-open-panel', 'view-pilings') }">
              <b-icon icon="band-aid" pack="fas"></b-icon> {{ $t('pilings') }}</b-dropdown-item>
            <b-dropdown-item aria-role="listitem"
              @click="() => { currentView='structures'; $emit('epc-open-panel', 'view-structures') }">
              <b-icon icon="stream" pack="fas"></b-icon> {{ $t('structures') }}</b-dropdown-item>
            <b-dropdown-item aria-role="listitem"
              @click="() => { currentView='modules'; $emit('epc-open-panel', 'view-modules') }">
              <b-icon icon="solar-panel" pack="fas"></b-icon> {{ $t('modules') }}</b-dropdown-item>
          </b-dropdown>
        </div>

        <!-- <div v-if="currentView==='structures'" class="subheader__item c-subheader__item--views ga-model-views">
          <b-dropdown aria-role="list">
            <template #trigger>
                <p role="button">
                    <b-icon icon="eye" pack="fas"></b-icon> {{ $t('show')}} <b-icon icon="chevron-down" pack="fas"></b-icon>
                </p>
            </template>
            <b-dropdown-item aria-role="listitem"
              @click="() => { $emit('epc-open-panel', 'view-areas') }">
              <b-icon icon="clone" pack="fas"></b-icon> {{ $t('areas') }}</b-dropdown-item>
            </b-dropdown>
        </div> -->

        <div v-if="currentView==='structures'" action-name="showareas"
          action-category="viewer-panel-lp"
          class="subheader__item c-subheader__item--layers ga-model-layers"
          :style="{ background: showAreas ? 'rgb(85, 119, 187)' : 'initial', color: showAreas ? '#fff' : 'initial' }"
          @click="() => { showAreas = !showAreas;  $emit('epc-open-panel', 'view-areas') }">
          <b-icon icon="eye" pack="fas" class="subheader__item-icon w-6 h-6"></b-icon>
          <span class="subheader__item-txt ">
            <span class="subheader__item-txt-number"></span>
            <span class="subheader__item-txt-string"
              :style="{ color: showAreas ? '#fff' : 'initial' }"
              >{{ 'Mostrar Areas' }}</span>
          </span>
        </div>

        <div v-if="designMode" class="subheader__item c-subheader__item--views ga-model-views">
          <b-dropdown aria-role="list">
            <template #trigger>
                <p
                    role="button">
                    <b-icon icon="plus" pack="fas"></b-icon> Insertar <b-icon icon="chevron-down" pack="fas"></b-icon>
                </p>
            </template>

            <b-dropdown-item aria-role="listitem"
              @click="() => { $emit('epc-open-panel', 'panel-views') }">
              <b-icon icon="solar-panel" pack="fas"></b-icon> Pictogramas</b-dropdown-item>
            <b-dropdown-item v-if="currentView==='structures' && showAreas"
              aria-role="listitem"
              @click="() => { $emit('epc-open-panel', 'panel-drawing') }">
              <b-icon icon="pen-nib" pack="fas"></b-icon> Vector</b-dropdown-item>
            <b-dropdown-item v-if="currentView==='structures' && showAreas"
              aria-role="listitem"
              @click="() => { $emit('epc-open-panel', 'tool-pencil') }">
              <b-icon icon="font" pack="fas"></b-icon> Texto</b-dropdown-item>
          </b-dropdown>
        </div>


      </div>

      <div class="float-right">
        <div action-name="designmode"
          action-category="viewer-panel-lp"
          class="subheader__item c-subheader__item--layers ga-model-layers"
          :style="{ background: designMode ? 'rgb(85, 119, 187)' : 'initial', color: designMode ? '#fff' : 'initial' }"
          @click="() => { designMode = !designMode;  $emit('epc-in-design-mode', designMode) }">
          <b-icon icon="object-ungroup" pack="fas" class="subheader__item-icon w-6 h-6"></b-icon>
          <span class="subheader__item-txt ">
            <span class="subheader__item-txt-number"></span>
            <span class="subheader__item-txt-string"
              :style="{ color: designMode ? '#fff' : 'initial' }"
              >{{ 'Modo diseño' }}</span>
          </span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

  export default {
    name: "ViewerHeaderDesign",

    props: {

    },

    watch: {
    },

    data() {
      return {
        designMode: false,
        showAreas: false,
        currentView: 'structures'
      }
    },

    methods: {

    }
  }

</script>

<style scoped>
  .subheader {
    height: 45px;
    background: #fff;
    padding: 11px 15px 10px;
    line-height: 24px;
    font-size: 11px;
    border-bottom: 1px solid #d4dbe1;
  }
  .subheader__item{
    display: inline-block;
    border-radius: 14px;
    padding: 0 10px 0 6px;
    cursor: pointer;
    font-weight: bold;
    transition: box-shadow .25s ease-out;
  }

  svg {
    display: inline;
  }
  svg:not(:root) {
    overflow-clip-margin: content-box;
    overflow: hidden;
  }
  .subheader__item-txt{
    color: #4a555b;
    transition: color .25s ease-out;
  }

  .subheader__item+.subheader__item {
    margin-left: 6px;
  }

  .subheader__item-icon {
    vertical-align: middle;
    transition: fill .25s ease-out;
    position: relative;
    top: -1px;
  }

  @media screen and (min-width: 768px) {
    .subheader__item:hover {
        box-shadow: 0 1px 1px 0 rgba(0,0,0,.3);
    }
    .subheader__item:hover .subheader__item-txt {
      color: #0a131c;
    }
  }
</style>
