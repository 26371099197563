<template>
  <li>
    <router-link :to="url" >
      <b-icon :icon="icon" pack="fas" class="navbar-item-icon" />
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    url: { type: String, default: null },
    icon: { type: String, required: true },
  },
};
</script>
