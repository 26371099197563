<template>
  <div>
    <ViewerPageKonva v-if="pictogram"
      :canActionsPictogram="canActionsPictogram"
      :pictogram="pictogram"
      :work-layer="workLayer"
      @epc-reload="() => { $emit('epc-reload') }"
      @epc-screenshot="(e) => { $emit('epc-screenshot', e) }"></ViewerPageKonva>
  </div>
</template>

<script>

import axios from 'axios'
import ViewerPageKonva from '../../components/pictograms/viewer-konva/ViewerPageKonva.vue'


export default {
  middleware: "guest",
  layout: "pictogram",
  title: "pictogram",

  components: {
    ViewerPageKonva
  },

  created () {

  },

  mounted() {
    this.workLayer = this.$route.params.wl // wl = worklayer
    this.readPictogram(this.$route.params.pictogramId);

    console.log('this.$route Authorization: ', this.$route.meta.Authorization)

    this.$store.dispatch("auth/saveToken", {
      token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2VwYy1jbGllbnQudGVzdC9hcGkvdjIvbG9naW4iLCJpYXQiOjE3MDU0MDU4NDEsImV4cCI6MTcwNjcwMTg0MSwibmJmIjoxNzA1NDA1ODQxLCJqdGkiOiJFNWlsUUVaY0xwNWkxSWkzIiwic3ViIjoiODgiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.p10g2JFAWGy2Mz1fbu5-FmJrXozIMnqwg1TCB7W9Ga0',
      remember: true //this.remember
    });
  },

  props: {

  },

  // data: () => ({
  data() {
    return {
      pictogram: null,
      workLayer: null, // capa donde  trabajará el usuario, solo permite trabajar en esa capa si null trabaja en todas
      canActionsPictogram: {
        'save': true,
        'select_entity': true
      }
    }
  },

  methods: {

    async readPictogram(pictogramId) {
      // Submit the form.
      let data = null

      await axios.get('/api/v2/pictogram/' + pictogramId)
        .then(response => (data = response.data))
        .catch(error => console.log(error));

      //const { data } = await this.form.post('api/v2/login')

      if (data && data.success) {
        this.pictogram = data.pictogram
      }
    }

  }
};
</script>
