<template>
<b-field
  :label="label ? $t(label) : null"
  :type="form.errors.has(field) ? 'is-danger' : ''"
  :message="form.errors.has(field) ? form.errors.get(field) : null">
  <b-autocomplete ref="input"
    v-model="form[field]"
    :data="options"
    :field="optionFieldName"
    :placeholder="$t(_placeholder)"
    :size="size"
    :icon="icon"
    :icon-pack="iconPack"
    :keep-first="true"
    :open-on-focus="true"
  />
</b-field>
</template>

<script>
export default {
  name: 'l-form-auto-select',

  props: {
    // The vform object
    form: { type: Object, required: true },
    // The field name
    field: { type: String, required: true },
    // Options
    options: { type: Array, required: true },
    // Option field name (from the 'options' array)
    optionFieldName: { type: String, default: 'name' },
    // Key for the label text
    label: { type: String, default: null },
    // Key for the input placeholder text
    placeholder: { type: String, default: null },
    // Input size class
    size: { type: String, default: null },
    // Icon name
    icon: { type: String, default: null },
    // Icon pack (fa, fas, far, mdi)
    iconPack: { type: String, default: 'fas' },
  },

  computed: {
    _placeholder() {
      return this.placeholder ? this.placeholder : this.label
    }
  },

  methods: {
    setFocus() {
      this.$refs.input.focus()
    },
    setSelected(selected) {
      this.$refs.input.setSelected(selected)
    }
  },

};
</script>
