<template>
  <div>
    <!-- Filtros -->
    <activity-filters :projectId="project.id"
      @filters-updated="filtersUpdated"
      ref="filtersRef"/>

    <div class="w-full h-8">
      <!-- Botones, sólo Jefes de departamento -->
      <div class="flex float-right">
          <div class="rounded-sm mr-2 float-right">
            <v-button icon="table" class="mr-4" @click="displayTable()">{{$t('display_table')}}</v-button>
          </div>

          <div class="mr-2 rounded-sm">
            <v-button icon="file-pdf" class="mr-4" @click="goToPage('drawings')">{{ $t('drawings') }}</v-button>
          </div>

          <div class="mr-2 rounded-sm" v-if="project.user_department.can_admin">
            <v-button icon="plus" class="mr-4" @click="onCreateActivityClicked()">{{ $t('add_activity') }}</v-button>
          </div>

          <!-- Botón para abrir diagrama de gantt-->
          <div class="mr-2 rounded-sm">
            <b-tooltip :label="$t('display_gantt')" position="is-top">
              <v-button icon="project-diagram" class="" @click="displayGantt()"></v-button>
            </b-tooltip>
          </div>

          <!-- Botón para ver Kanban-->
          <div class="mr-2 rounded-sm" v-if="project.user_department.can_admin">
            <b-tooltip :label="$t('display_kanban')" position="is-top">
              <v-button icon="columns" class="" @click="displayKanban()"></v-button>
            </b-tooltip>
          </div>

          <!-- Botón para ver Carpetas documentos -->
          <div class="mr-2 rounded-sm">
            <b-tooltip :label="$t('display_folders')" position="is-top">
              <v-button icon="folder-plus" class="" @click="$router.push({name: 'project.activities.folders'});"></v-button>
            </b-tooltip>
          </div>

          <template v-if="project.user_department.can_admin">
            <project-report v-if="!isLoadingTable" :filters="this.generatedFilters"/>
            <project-report-excel v-if="showExcelReport"/>
          </template>

          <!-- Botón Lotes canal exportar CSV proyecto -->
          <project-report-lotes v-if="showLotesReport" />

          <!--<div class="p-1 rounded-sm bg-blue text-grey-lightest">
            <b-icon pack="fas" icon="check-circle" size="is-medium" />
          </div>-->
      </div>

    </div>

    <div class="panel relative">
      <section>
        <div style="border: 1px solid #ADC2EA">
          <b-loading :is-full-page="false" v-model="isLoadingTable" :can-cancel="false"></b-loading>
          <div class="flex">
            <div class="w-full">
              <div class="" style="background-color: #5577BB; color: #fff; font-weight:600">
                <div class="flex items-center flex-row text-xs">
                  <div class="flex-none w-10 text-center p-1" style="border: 1px solid #ADC2EA"><span><i class="fas fa-star"></i></span></div>
                  <div class="flex-none w-10 text-center p-1" style="border: 1px solid #ADC2EA"><span><i class="fas fa-road"></i></span></div>
                  <div class="flex-none w-10 text-center p-1" style="border: 1px solid #ADC2EA"><span><i class="fas fa-mobile-alt"></i></span></div>
                  <div class="flex-none w-10 text-center p-1" style="border: 1px solid #ADC2EA"><span><i class="fas fa-layer-group"></i></span></div>
                  <div class="flex-none w-10 text-center p-1" style="border: 1px solid #ADC2EA"><span><i class="fas fa-user"></i></span></div>
                  <div class="w-3/12 text-left p-1" style="border: 1px solid #ADC2EA">{{ $t('code') }}</div>
                  <div class="w-5/12 text-left p-1" style="border: 1px solid #ADC2EA">{{ $t('name') }}</div>
                  <div class="w-4/12 text-center p-1" style="border: 1px solid #ADC2EA"><span class="is-relative">{{ $t('progress_label') }}</span></div>
                  <div class="w-4/12 text-center p-1" style="border: 1px solid #ADC2EA"><span class="is-relative">{{ $t('start') }}</span></div>
                  <div class="w-4/12 text-center p-1" style="border: 1px solid #ADC2EA"><span class="is-relative">{{ $t('end') }}</span></div>
                  <div class="w-4/12 text-center p-1" style="border: 1px solid #ADC2EA"><span class="is-relative">{{ $t('status') }}</span></div>
                  <div class="w-4/12 text-center p-1" style="border: 1px solid #ADC2EA"><span><i class="fas fa-bell"></i></span></div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="flex w-full">
            <div class="flex items-center flex-row w-full">
              <div class="w-1/6">
                <b-skeleton :animated="true"></b-skeleton>
              </div>
              <div class="w-5/6 pl-2">
                <b-skeleton :animated="true"></b-skeleton>
              </div>
            </div>
          </div> -->
          <vue-tree-list-custom v-if="hasGroups"
            @click="onClick"
            :model="list"
            key="lista_group"
            default-tree-node-name="new node"
            default-leaf-node-name="new leaf"
            v-bind:default-expanded="false"
            v-bind:disable-action-menu="true"
            :depth-max="2"
            :filters="filters"
          >
            <template v-slot:leafNameDisplay="slotProps">
              <div class="flex items-center flex-row w-full">
                <!-- <div class="w-60 pr-5">{{ slotProps.model.code }}</div> -->
                <div class="w-full text-black text-center uppercase text-sm">
                  {{ slotProps.model.name }}<br>
                  <span style="font-size:11px"><b>{{$t('weight')}}:</b>{{slotProps.model.weight}}% - </span>
                  <span style="font-size:11px"><b>{{$t('progress_label')}}:</b>
                  {{slotProps.model.group_progress.progress}}% | {{slotProps.model.group_progress.progress_real}}%
                  </span>
                  <span class="muted" v-if="slotProps.model.subgroups_num">({{ slotProps.model.children.length }})</span>
                </div>
              </div>
            </template>
            <span class="icon" slot="addTreeNodeIcon"><b-icon pack="fas" icon="folder-plus" size="is-small" /></span>
            <span class="icon" slot="addLeafNodeIcon"><b-icon pack="fas" icon="plus-circle" size="is-small" /></span>
            <span class="icon" slot="editNodeIcon"><b-icon pack="fas" icon="pencil-alt" size="is-small" /></span>
            <span class="icon" slot="linkNodeIcon"><b-icon pack="fas" icon="link" size="is-small" /></span>
            <span class="icon" slot="delNodeIcon"><b-icon pack="fas" icon="trash-alt" size="is-small" /></span>
            <span class="icon" slot="leafNodeIcon"><b-icon pack="fas" icon="file-alt" size="is-small" /></span>
            <!-- <span class="icon" slot="treeNodeIcon"><b-icon pack="fas" icon="layer-group" size="is-small" /></span> -->
            <template v-slot:treeNodeIcon="slotProps">
              <span class="icon rounded-full mr-2"
                :style="{ 'background-color' : slotProps.model.color ? slotProps.model.color : 'initial', 'color' : slotProps.model.color ? 'white' : 'initial' }">
                <b-icon pack="fas" icon="layer-group" size="is-small" />
              </span>
            </template>
          </vue-tree-list-custom>

          <div class="flex w-full" v-else>
            <div class="has-text-centered w-full">{{ $t('no_results') }}</div>
          </div>

        <!-- <button @click="getNewTree">Get new tree</button>
        <pre>
          {{newTree}}
        </pre> -->

      </div>
    </section>
  </div>
  </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import {mapGetters} from 'vuex'
import Form from 'vform'
import activityTimeStatus from '·/components/activity-time-status'
import activityStatusLabel from '·/components/activity-status-label'
import assignmentTable from '·/components/assignment-table'
import projectReport from '·/components/project-report'
import projectReportExcel from '·/components/project-report-excel'
import projectReportLotes from '·/components/project-report-lotes'
import moment from 'moment'
import entityNotifications from '·/components/entity-notifications'
import MugenScroll from 'vue-mugen-scroll'
import Buefy from 'buefy'
import BIcon from "buefy/src/components/icon/Icon";
import EventBus from '~/plugins/bus'
import ActivityFilters from '·/components/activity-filters';

import Activities from '../activity/groups/activities.vue';
import VueTreeListCustom from '../../components/activity-groups/components/vue-tree-list/VueTreeListCustom'
import { Tree, TreeNode } from '../../components/activity-groups/components/vue-tree-list/Tree.js'

(moment);
Vue.use(Buefy);

export default {
  middleware: 'auth',
  title: 'activities_page_title',
  subtitle: 'activities_page_subtitle',
  menuOption: '2-2',

  components: {
    BIcon,
    activityTimeStatus,
    activityStatusLabel,
    entityNotifications,
    projectReport,
    projectReportExcel,
    projectReportLotes,
    assignmentTable,
    MugenScroll,
    ActivityFilters,

    Activities,
    VueTreeListCustom
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      project: 'app/project',
      company: 'app/company',
    }),

    filters() {
      return this.$refs.filtersRef.generateFilters()
    }
  },

  props: {

  },

  data: () => ({
    form: new Form({
      creator: 'Todos',
      fecha: '1 Enero 2018 - 31 Diciembre 2018',
    }),
    hasGroups: false,
    // paginationRows: 10,
    isLoadingTable: true,
    page: 1,
    last: 1,
    perPage: 25,
    currentPage: 1,
    total: 0,
    orderField: "planning_code",
    orderDir: "asc",
    list: new Tree([]),
    filterSubactivities: false,
    showSubs: false,
    openedSubactivitiesId: 0,
    subactivities: [],
    showFilters: false,
    generatedFilters: {}, // objeto con los filtros generados por el componente 'activity-filters'
    columns: {
      'in_planning': {title: 'in_planning', visible: true},
      'user': {title: 'manager', visible: true},
      'planning_code': {title: 'code', visible: true},
      'name': {title: 'name', visible: true},
      'progress': {title: 'progress', visible: true},
      'current_start_date': {title: 'start_date', visible: true},
      'current_end_date': {title: 'end_date', visible: true},
      'status': {title: 'status', visible: true},
      'status_id': {title: 'custom_status', visible: true},
      'notifications': {title: 'notifications', visible: true},
    },
    showExcelReport: false,
    showLotesReport: false,
    childStatusList: [], // lista status de componente activity-filters
    apiProject: {}, // algunas caracteristicas del proyecto actual devueltas por api (si user es certificador)
  }),

  mounted() {

    // recupera de la localstore la pagina que estamos (util al volver de una validacion al listado)
    this.page = this.$store.getters["searchFilters/activities"].page || 1
    this.last = this.$store.getters["searchFilters/activities"].last || 1

    this.applyFilters(this.page);
    let self = this;

    // Muestra columna "mis estados" cuando el componente hijo 'activity-filters' recibe por api lista statusList
    EventBus.$on("statusListReceived", function (statusList) {
      self.childStatusList = statusList
    });

    // Si recibimos el evento refresh, refrescamos el componente
    EventBus.$on('refreshActivitiesNotifications', function (e) {
      self.isLoading = true;
      self.applyFilters(self.page);
    });

    // Si recibimos el evento refresh, refrescamos el componente
    EventBus.$on('refreshSubactivitiesNotifications', function (e) {
      self.isLoading = true;
      self.applyFilters(self.page);
    });
  },

  beforeDestroy(){
    EventBus.$off("statusListReceived");
    EventBus.$off("refreshActivitiesNotifications");
    EventBus.$off("refreshSubactivitiesNotifications");
  },

  created() {
    this.showExcelReport = (window.location.hostname.includes('test') ||
      window.location.hostname.includes('ignis') ||
      this.user.company.id == 16)
      ? true : false

    this.showLotesReport = this.company.configs && this.company.configs.loteCanal
  },

  methods: {
    async getActivityGroupsList(args = []) {

      const {data} = await axios.get(`/api/v2/project/${this.project.id}/activity/groups`, {params: args});
      // Last and total
      this.last = data.lastPage;
      this.total = data.total;
      this.apiProject = data.project;
      // guarda en la localstore la ultima pagina
      this.$store.dispatch('searchFilters/setActivitiesFilters', { last: this.last });

      if (data && data.groups) {
        //deducimos si no tiene status kanban aplicados y los ocultamos
        if (!this.hasStatusList())
          delete this.columns.status_id;

        this.hasGroups = data.groups.length > 0
        this.list = new Tree(data.groups);
      } else {
        this.$notify.error('error_loading_activities')
      }

      this.isLoadingTable = false
    },

    filtersUpdated() {
      this.isLoadingTable = true;
      this.applyFilters(1);
    },

    customizeFilters() {
      let filters = this.$refs.filtersRef.generateFilters();
      filters.with_assignments = 1;
      filters.order_field = this.orderField;
      filters.order_dir = this.orderDir;
      filters.per_page = this.perPage;
      //filters.s = 1; //con subactividades. Como se piden de todas formas al desplegar, se quitan de aqui para aligerar carga

      if (this.filterSubactivities) {
        filters.filter_subactivities = 1
      }

      return filters;
    },

    onCreateActivityClicked() {
      let url = '/api/v2/locations?all=1&p=' + this.project.id;
      self = this;
      axios.get(url)
        .then(function (response) {
          if (response.data && response.data.success && response.data.locations) {
            self.$router.push({name: 'activity.create'})
          } else {
            self.$notify.error('project_without_locations_create_activity')
          }
        });
    },

    displayKanban() {
      this.$router.push({name: 'project.activities.kanban'});
    },

    displayGantt() {
      this.$router.push({name: 'project.activities.gantt'});
    },

    async createProjectDossier() {
      //TO-DO: realizar llamada al generador de informes para el proyecto this.project.id
      return true
    },

    applyFilters(page) {
      this.generatedFilters = this.customizeFilters();
      this.generatedFilters.page = (page <= this.last) ? page : 1;
      // actualiza en localstore la pagina actual
      // this.$store.dispatch('searchFilters/setActivitiesFilters', { page: this.generatedFilters.page });
      this.currentPage = this.generatedFilters.page;
      this.generatedFilters.with_groups = 1

      // this.getActivitiesList(this.generatedFilters)
      this.getActivityGroupsList(this.generatedFilters)
    },

    fetchData() {
      if (this.page <= this.last) {
        this.isLoadingTable = true;
        this.applyFilters(this.page);
        this.isLoadingTable = false
      }
    },

    hasStatusList() {
      return this.childStatusList.length > 0;
      // return this.$refs.filtersRef.hasStatusList(); // da error por componente todavia no montado (ahora usamos emit-on)
    },

    goToPage(destiny = 'drawings') {

      switch (destiny) {

        case 'drawings':
            this.$router.push({ name: 'project.drawings' })
            break;
      }

    },

    colorGroup(group){
      let defaultColor = "#F29B57"
      let configs = JSON.parse(group.configs)

      return configs && configs.color ? configs.color : defaultColor
    },

    nameGroup(group){
      return group.name.substring(0, 2).toUpperCase()
    },
    toggle(row) {
      this.$refs.tbl_groups.toggleDetails(row)
    },

    onClick(params) {
      // console.log(params)
    },

    /**
     * Cambia la ventana a la tabla de actividades normal
     */
    displayTable() {
      this.$router.push({name: 'project.activities'});
    },
  },

};
</script>

<style lang="scss">
.disabled-subactivity {
  background: #F8FAFC !important;
  color: #B8C2CA !important;
}

.reduce {
  transform: scale(0.80)
}

.vertical-center {
  vertical-align: inherit !important;
}

/* Se le debería de poder cambiar el icono al botón de details, no probado */
.sub-notifications > td.chevron-cell {
  background-color: #F8E0E0 !important;
}

.sub-notifications > td.chevron-cell svg {
  color: #e3342f !important;
}

.subactivities {
  .table th {
    background-color: #89a4d6;
  }

  &.b-table .table.is-bordered th.is-current-sort, &.b-table .table.is-bordered th.is-sortable:hover {
    background-color: #89a4d6 !important;
  }
}
</style>


