<template>

<div class="w-full">

    IFC viewer
    <div style="height: 100vh;">
      <div id="viewer"></div>
    </div>
</div>

</template>

<script type="module">

// import {mapGetters} from 'vuex'
import makeBIMDataViewer from "@bimdata/viewer";
// import makeBIMDataViewer from "../../../../../public/bimdataviewer/bimdataViewer.esm.min.js"; // intentos de cargar el viewer localmente ya que falla el versioning en webpack solo con este paquete (pero tambien falla)

export default {
  name: 'bimdata-ifc',

  components: {
  },

  data() {
    return {

      bimdata: {
        client_id: "e4388f07-8540-4c75-9b86-34bc7bb618d2",
        client_secret: "b672c6ec710539a06f9b2445f325f57110e2a794e0bbeba17e33aa28",
        access_token: null,
      },

      activeTab: 0,

    }
  },

  computed: {
    // ...mapGetters({
    //   user: "auth/user",
    //   project: 'app/project',
    // }),
  },

  created() {
    this.getAccessToken();
  },

  watch: {
    // una vez tenemos access_token, podemos llamar al viewer
    'bimdata.access_token': function (newVal, oldVal) {
      if (newVal) {
        this.createViewer()
      }
    }
  },


  methods: {

    getAccessToken() {

      // TODO. Llamar a una ruta interna nuestra que haga el post para evitar exponer aqui client_id y client_secret

      const params = new URLSearchParams();
        params.append('grant_type', 'client_credentials');
        params.append('client_id', this.bimdata.client_id);
        params.append('client_secret', this.bimdata.client_secret);

      // con axios da problemas de CORS. Usamos fetch
      fetch('https://iam.bimdata.io/auth/realms/bimdata/protocol/openid-connect/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: params
        })
        .then(response => response.json())
        .then(data => {
          this.bimdata.access_token = data.access_token
        })
        .catch ((err) => {
          console.error("Error fetching access_token: ", err);
        })

    },


    createViewer() {
      // ids de modelo, project... hardcodeados para pruebas. Se pueden ver en https://platform.bimdata.io/
      const bimdataViewer = makeBIMDataViewer({
        locale: 'es',
        api: {
          modelIds: [1190760],
          cloudId: 26610,
          projectId: 848570,
          accessToken: this.bimdata.access_token,
        },
      });

      bimdataViewer.mount("#viewer");
    },

  },
};
</script>
