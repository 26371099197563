<template>
  <b-collapse class="card filters-collapse" :open.sync="showFilters">
    <div slot="trigger" class="card-header filters-trigger">
      <p class="card-header-title">
        {{ $t('filters') }}
      </p>
      <a class="card-header-icon">
        <b-icon pack="fa" v-show="! showFilters" icon="plus-circle"/>
        <b-icon pack="fa" v-show="showFilters" icon="minus-circle"/>
      </a>
    </div>
    <div class="panel" style="border-width: 0">
      <div class="flex items-center mb-4">

        <!-- por nombre -->
        <div class="w-1/3 mr-4">
          <b-field :label="$t('search_by_name')">
            <b-input
              v-model="filterName"
              :placeholder="$t('name')"
              type="search"
              icon="search"
              icon-pack="fas"
              @input="checkInputTextFilter(filterName)">
            </b-input>
          </b-field>
        </div>

        <!-- por emplazamiento -->
        <div class="w-1/3 mr-4">
          <b-field :label="$t('search_by_location')">
            <b-select ref="input"
                      v-model="filterLocation"
                      :placeholder="$t('select')"
                      icon="search"
                      icon-pack="fas"
                      @input="applyFilters()"
                      expanded
            >
              <option :value="null">{{$t('select')}}</option>
              <option v-for="flowOption in locationsList" :value="flowOption.id" :key="'location_'+flowOption.id">
                        {{ flowOption.name }}
                      </option>
            </b-select>
          </b-field>
        </div>

      </div>

      <div class="flex items-center mb-4">
        <button class="button is-primary btn-blue" @click="cleanFiltersClicked()">{{$t('clean_filters')}}</button>
      </div>
    </div>
  </b-collapse>
</template>

<script>
  // import {mapGetters} from "vuex";

  export default {
    name: 'validation-filters',

    props: {
      locationsList: { type: Array, required: true },
    },

    data: () => ({
      showFilters: false,
      filterName: '',
      filterLocation: null,
    }),

    methods: {
      generateFilters() {

        let params = {};

        // Filtro por nombre
        if (this.filterName.length >= 3) {
          params.n = this.filterName
        }
        //Filtro por creador
        if (this.filterLocation !== null) {
          params.location_id = this.filterLocation
        }

        return params
      },

      applyFilters() {
        let args = this.generateFilters();
        this.$emit('foldersfilters-updated', args);
      },

      cleanFiltersClicked() {
        this.filterName = '';
        this.filterLocation = null;
        this.page = 1; //TODO no deberíamos paginar a esta altura
        this.applyFilters();
      },

      checkInputTextFilter(filter) {
        if (filter.length >= 3 || filter.length == 0) {
          this.applyFilters()
        }
      },

    },

  };
</script>

<style>
  .filters-collapse {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .filters-trigger:hover {
    background-color: #f8fafc;
  }
</style>
