import Vue from "vue";
import "vue-googlemaps/dist/vue-googlemaps.css";
import VueGoogleMaps from "vue-googlemaps";

Vue.use(VueGoogleMaps, {
  load: {
    apiKey: "AIzaSyCjGeFCMl6ekU3r09EO0JxFzq1-FusbOc0",
    libraries: ["places", "marker"],
    useBetaRenderer: true,
  },
});
