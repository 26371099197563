<template>
  <div>
    <div>
      <!-- Tabla de chapters-->
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInUp"
        leave-active-class="animated fadeOutDown"
      >
        <div class="panel relative">
          <section>
            <div>
              <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
              <div class="flex">
                <div class="w-full">
                  <div class="" style="background-color: #5577BB; color: #fff; font-weight:600">
                    <div class="flex items-center flex-row pt-2 pb-2">
                      <div class="ml-12 w-60 pr-5">{{ $t('code') }}</div>
                      <div>{{ $t('name') }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <vue-tree-list v-if="hasChapters"
                @click="onClick"
                @change-name="onChangeName"
                @add-node="onAddNode"
                @edit-node="onEditNode"
                @link-node="onLinkNode"
                @delete-node="onDel"
                @drop="onDrop"
                @drop-after="onDropAfter"
                @drop-before="onDropBefore"
                :model="data"
                default-tree-node-name="new node"
                default-leaf-node-name="new leaf"
                v-bind:default-expanded="false"
                v-bind:disable-action-menu="disabledMenuActions"
                :depth-max="2"
              >
                <template v-slot:leafNameDisplay="slotProps">
                  <div class="flex items-center flex-row">
                    <div class="w-60 pr-5">{{ slotProps.model.code }}</div>
                    <div class="w-96">
                      {{ slotProps.model.name }}
                      <span class="muted" v-if="slotProps.model.subgroups_num">({{ slotProps.model.children.length }})</span>
                    </div>
                  </div>
                </template>
                <span class="icon" slot="addTreeNodeIcon"><b-icon pack="fas" icon="folder-plus" size="is-small" /></span>
                <span class="icon" slot="addLeafNodeIcon"><b-icon pack="fas" icon="plus-circle" size="is-small" /></span>
                <span class="icon" slot="editNodeIcon"><b-icon pack="fas" icon="pencil-alt" size="is-small" /></span>
                <!-- <span class="icon" slot="linkNodeIcon"><b-icon pack="fas" icon="link" size="is-small" /></span> -->
                <span class="icon" slot="delNodeIcon"><b-icon pack="fas" icon="trash-alt" size="is-small" /></span>
                <span class="icon" slot="leafNodeIcon"><b-icon pack="fas" icon="file-alt" size="is-small" /></span>
                <!-- <span class="icon" slot="treeNodeIcon"><b-icon pack="fas" icon="layer-group" size="is-small" /></span> -->
                <template v-slot:treeNodeIcon="slotProps">
                  <span class="icon rounded-full mr-2"
                    :style="{ 'background-color' : slotProps.model.color ? slotProps.model.color : 'initial', 'color' : slotProps.model.color ? 'white' : 'initial' }">
                    <b-icon pack="fas" icon="stream" size="is-small" />
                  </span>
                </template>
              </vue-tree-list>

              <div class="flex w-full" v-else>
                <div class="has-text-centered w-full">{{ $t('no_results') }}</div>
              </div>

            </div>
          </section>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import VueTreeList from '../activity-groups/components/vue-tree-list/VueTreeList'
import { Tree, TreeNode } from '../activity-groups/components/vue-tree-list/Tree.js'

export default {
  name: "chapters-list",

  components: {
    VueTreeList
  },

  props: {
    entityId: { type: Number, required: true },
    entityType: { type: String, required: true },
    disabledMenuActions: { type: Boolean, default: false },
    returnSelected: { type: Boolean, default: false },
  },

  data() {
    return {
      newTree: {},
      hasChapters: false,
      isLoading: true,
      data: new Tree([])
    }

  },

  mounted() {
    this.getChapters();
  },

  methods: {
    async getChapters() {
      this.isLoading = true;
      let params = {
        entity_type: this.entityType
      };
      const { data } = await axios.get(
        `/api/v2/project/${this.entityId}/chapters`,
        { params: params }
      );
      if (data && data.success) {
        this.data = new Tree(data.chapters);
        this.hasChapters = data.chapters.length > 0
      } else {
        this.$notify.error("error_loading_data");
      }
      this.isLoading = false;
    },

    reload() {
      this.getChapters();
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    async onDel(node) {
      this.$dialog.confirm({
        title: this.$t("delete_group"),
        message: this.$t("delete_group_confirm_text", [node.name]),
        confirmText: this.$t("delete_group"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true;
          let url = "/api/v2/chapters/" + node.id + "/delete";
          const { data } = await axios.post(url);

          if (data.success) {
            // node.remove()
            this.$toast.open({
              message: this.$t("removed_successfully"),
              type: "is-success",
              position: "is-top-right"
            });

          //   // TODO: Cuando se trabaja de manera concurrente no es eficiente, mejor  usar reaload
          //   let index = this.data.findIndex( (item) => { return item.id == activity.id } );
          //   if( index > -1 )
          //     this.data.splice(index, 1);
          //   this.isLoading = false;

            await this.sleep(1000);
            this.reload(); //Descomentar si se quiere volver a recargar todo haciendo llamada desde el servidor
          } else {
            this.isLoading = false;
            // Error
            this.$toast.open({
              message: this.$t("remove_error"),
              type: "is-danger",
              position: "is-top-right"
            });
          }
        }
      });

    },

    reloadActivities() {
      this.reload()
    },

    onChangeName(params) {
      // console.log(params)
    },

    onAddNode(params) {
      let chapter = {}
      chapter.id = null
      chapter.parent_id = params.id,
      chapter.code = ''
      chapter.name = ''
      chapter.weight = null
      chapter.color = null
      this.$emit('add-chapter', chapter)
    },

    onEditNode(params) {
      let chapter = {}
      chapter.id = params.id
      chapter.parent_id = params.parent_id,
      chapter.code = params.code
      chapter.name = params.name
      this.$emit('edit-chapter', chapter)
    },

    onLinkNode(params) {
      let chapter = {}
      chapter.id = params.id
      chapter.parent_id = params.parent_id,
      chapter.code = params.code
      chapter.name = params.name
      this.$emit('link-chapter', chapter)
    },

    onClick(params) {
      if( this.returnSelected ) {
        let chapter = {}
        chapter.id = params.id
        chapter.parent_id = params.parent_id,
        chapter.code = params.code
        chapter.name = params.name
        this.$emit('selected-chapter', chapter)
      }
    },

    addNode() {
      // var node = new TreeNode({ name: 'new node', isLeaf: false })
      // if (!this.data.children) this.data.children = []
      // this.data.addChildren(node)
    },

    onDrop(params){
      // params contiene tres objetos node, src, target (nodo actual, padre actrual, target= del nodo que se cambio padre al que se mobio)
      // cambimos el parent del que cuelga el nodo
      let chapter = {}
      chapter.id = params.node.id
      chapter.parent_id = params.target.id,
      chapter.code = params.node.code
      chapter.name = params.node.name
      this.$emit('drop-chapter', chapter)
    },

    onDropAfter(params){ // de momento anulado
      // console.log('Dop after: ', params)
    },

    onDropBefore(params){ // de momento anulado
      // console.log('Dop before: ', params)
    },
  }
};
</script>
<style scoped>
  .vtl .vtl-drag-disabled {
	 background-color: #d0cfcf;
}
 .vtl .vtl-drag-disabled:hover {
	 background-color: #d0cfcf;
}
 .vtl .vtl-disabled {
	 background-color: #d0cfcf;
}

</style>

<style scoped>
 .icon:hover {
	 cursor: pointer;
}
 .muted {
	 color: gray;
	 font-size: 80%;
}

</style>
