<template>
<div class="flex-col">

  <!-- Filtros -->
  <div class="flex flex-row">
    <!-- <div class="flex items-center mb-4"> -->

      <!-- Emplazamientos -->
      <div class="w-1/4 mr-4">
        <b-field :label="$t('select_location')">
          <b-select ref="input"
                    v-model="filters.location_id"
                    :placeholder="$t('select_location')"
                    icon="map-marker-alt"
                    icon-pack="fas"
                    @input="getPanelsInfo()"
                    expanded
          >
            <option :value="0" :key="'location_0'">{{ $t('all') }}</option>
            <option v-for="option in locationsList"
                    :value="option['id']" :key="'location_'+option['id']"
            >
              {{ option['name'] }}
            </option>
          </b-select>
        </b-field>
      </div>

      <!-- Estado -->
      <div class="w-1/4 mr-4">
        <b-field :label="$t('dsos_status')">
          <b-select ref="input"
                    v-model="filters.status_id"
                    :placeholder="$t('select_status')"
                    icon="search"
                    icon-pack="fas"
                    @input="getPanelsInfo()"
                    expanded
          >
            <option :value="0" :key="'status_0'">En redacción (E03)</option>
            <option :value="1" :key="'status_1'">Redactados (E04 - E10)</option>
            <option :value="2" :key="'status_2'" selected>En ejecución / completados (E07 - E10)</option>
            <option :value="3" :key="'status_3'">Completados (E08 - E09)</option>

          </b-select>
        </b-field>
      </div>

      <!-- select DSO -->
      <div class="w-2/4">
        <div class="w-1/2 float-right">
          <b-field :label="'Detalle DSO'">
          <b-select ref="input"
                    v-model="selectedDso"
                    :placeholder="$t('select_location')"
                    icon="search"
                    icon-pack="fas"
                    @input="goToDSODashboard(selectedDso)"
                    expanded
            >
              <option v-for="option in activitiesList"
                      :value="option['id']" :key="'activity_'+option['id']"
              >
                {{ option['name'] }}
              </option>
          </b-select>
          </b-field>
        </div>
      </div>

      <b-tooltip :label="txtTooltipExport || this.$t('export')" position="is-left">
        <div class="rounded-full bg-blue w-8 h-8 flex items-center justify-center text-grey-lightest cursor-pointer" @click="exportReport">
          <b-icon icon="download" pack="fas" size="is-small" v-if="! isLoadingExport" />
          <b-icon
            pack="fas"
            icon="sync-alt"
            custom-class="fa-spin"
            v-if="isLoadingExport"
            >
          </b-icon>
        </div>
      </b-tooltip>
    <!-- </div> -->
  </div>


  <!-- Panelitos -->
  <div class="flex flex-row">

    <!-- Panel 1 -->
    <div class="panel flex flex-col w-1/3" v-if="filters.status_id > 0">
      <label class="text-blue font-bold mb-4">Avance del presupuesto</label>
      <!-- layout filtro 1 Redactados -->
      <span v-if="filters.status_id == 1">
        <label class="text-blue italic">PEC total en DSO's {{ statusName }}:</label><br> {{ panel1.pecRedactado }}<br>
      </span>
      <span v-if="filters.status_id == 1">
        <label class="text-blue italic">Baja:</label> {{ panel1.baja100 }}<br>
      </span>
      <span v-if="filters.status_id == 1">
        <label class="text-blue italic">Presupuesto de Adjudicación en DSO's {{ statusName }}:</label> {{ panel1.presAdjudicacionRedac }}<br>
      </span>
      <!-- fin filtro 1 -->

      <!-- layout filtro 2 y 3 En ejecucion y completados / Completados -->
      <span v-if="filters.status_id > 1">
        <label class="text-blue italic">PEC del LOTE segun pliego:</label> {{ panel1.pecPliego }}<br>
      </span>
      <span v-if="filters.status_id > 1">
        <label class="text-blue italic">Baja:</label> {{ panel1.baja100 }}<br>
      </span>
      <span v-if="filters.status_id > 1">
        <label class="text-blue italic">Presupuesto de Adjudicación del LOTE:</label> {{ panel1.presAdjudicacionLote }}<br>
      </span>
      <span v-if="filters.status_id > 1">
        <label class="text-blue italic">PEC total en DSO's {{ statusName }}:</label> <span v-if="pectotal">{{pectotal}}</span><span v-else>-</span><br>
      </span>
      <span v-if="filters.status_id > 1">
        <label class="text-blue italic">Presupuesto de Adjudicación en DSO's {{ statusName }}:</label> <span v-if="pectotal">{{ presAdjudicacion(pectotalRaw, panel1.baja) }}</span><span v-else>-</span><br>
      </span>
      <span v-if="filters.status_id > 1">
        <label class="text-blue italic">Obra ejecutada:</label> <span >{{ panel1.obraEjecutada }}</span><br>
      </span>
      <span v-if="filters.status_id > 1 && filters.location_id == 0">
        <label class="text-blue italic">Porcentaje de avance {{ statusName }}:</label> {{ panel1.avancePerCent }}
      </span>
      <span v-if="filters.status_id > 1">
        <label class="text-blue italic">Acreditado:</label> {{ panel1.facturado }}
      </span>

      <!-- <span v-if="filters.status_id != 1">
        <label class="text-blue italic">PEC ejecutado:</label> {{ panel1.pecEjecutado }}<br>
        <label class="text-blue italic">Avance líquido:</label> {{ panel1.avance }}<br>
      </span>
       -->
    </div>

    <!-- Panel 2 -->
    <div class="panel flex flex-col w-1/3 ml-2">
      <label class="text-blue font-bold mb-4">Metros de tubería</label>

      <template v-if="filters.status_id == 0">
        <span>
          <label class="text-blue italic">Total en DSO's {{ statusName }}:</label> {{ this.panel2.total }}<br>
        </span>
      </template>
      <template v-else>
        <span>
          <label class="text-blue italic">ML de pliego:</label> {{ this.panel2.pliego }} <br>
          <label class="text-blue italic">Total en DSO's {{ statusName }}:</label> {{ this.panel2.total }}<br>
        </span>
        <span v-if="filters.status_id != 1">
          <label class="text-blue italic">Ejecutado:</label> {{ panel2.ejecutado }}<br>
        </span>
        <span v-if="filters.location_id == 0">
          <label class="text-blue italic">Porcentaje de avance {{ statusName }}:</label> {{ filters.status_id != 1 ? panel2.percentage : panel2.percentage2 }}%
        </span>
      </template>
    </div>

    <!-- Panel 3 -->
    <div class="panel flex flex-col w-1/3 ml-2" v-if="filters.status_id > 0">
      <label class="text-blue font-bold mb-4">Número de acometidas</label>

      <span>
        <label class="text-blue italic">Total en DSO's {{ statusName }}:</label> {{ this.panel3.total }}<br>
      </span>
      <span v-if="filters.status_id != 1">
        <label class="text-blue italic">Ejecutado:</label> {{ panel3.ejecutado }}<br>
        <span v-if="filters.location_id == 0">
          <label class="text-blue italic">Porcentaje de avance {{ statusName }}:</label> {{ panel3.percentage }}%
        </span>
      </span>
    </div>
  </div>

  <!-- Panel 13 -->
  <div class="w-full mt-8" v-if="filters.status_id == 2 && filters.location_id == 0">
    <div class="flex flex-row w-full text-blue font-bold">Avance ml tubería planificado / real</div>
    <div class="flex flex-row w-full"><line-chart :suffix="'ml'" :data="panel13" /></div>
  </div>

  <!-- Panel 4 -->
  <div class="w-full mt-8" v-if="filters.status_id > 0">
    <div class="flex flex-row w-full text-blue font-bold">PEC por DSO</div>
    <div class="flex flex-row w-full"><column-chart :suffix="'€'" :data="panel4" /></div>
  </div>

  <!-- Panel 5 -->
  <div class="w-full mt-8" v-if="filters.status_id > 1">
    <div class="flex flex-row w-full text-blue font-bold">% de Avance del Presupuesto por DSO</div>
    <div class="flex flex-row w-full"><column-chart :min="0" :max="100" :suffix="'%'" :data="panel5" :colors="['#eb8c7d']" /></div>
  </div>

  <!-- Panel 6 -->
  <div class="w-full mt-8" v-if="filters.status_id > 1">
    <div class="flex flex-row w-full text-blue font-bold">PEC ejecutado por meses</div>
    <div class="flex flex-row w-full"><line-chart :suffix="'€'" :data="panel6" :colors="['#d12411']" /></div>
  </div>

  <!-- Panel 7 anulado por redundancia en panel 13 -->
  <!-- <div class="w-full mt-8">
    <div class="flex flex-row w-full text-blue font-bold">ml de tubería certificados del Lote</div>
    <div class="flex flex-row w-full"><line-chart :suffix="'m'" :data="panel7" :colors="['#d12411']" /></div>
  </div> -->

  <div class="flex flex-row" v-if="filters.status_id > 0">

    <!-- Panel 8 -->
    <div class="panel flex flex-col w-1/2">
      <label class="text-blue font-bold">Nº acometidas {{ filters.status_id==1 ? 'redactados' : 'certificado' }}</label>
      <pie-chart suffix="" :data="panel8"/>

      <table class="mx-5 mt-4">
        <tbody>
          <tr class="panel" v-for="(line, key, index) in panel8" :key="'8_'+index">
            <td align="center" class="justify-start py-2 w-2/12 bg-grey-lightest">
              <div v-if="piechartColors[index]" :style="'width: 20px; border-radius: 1em; height: 20px; background:' + piechartColors[index]"></div>
            </td>
            <td align="center" class="justify-start py-2 w-5/12 bg-indigo-lightest">
              <span class="text-sm font-semibold text-blue">{{ key }}</span>
            </td>
            <td align="center" class="justify-start py-2 w-5/12 bg-grey-lightest">
              <span class="text-sm font-semibold">{{ line }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Panel 9 -->
    <div class="panel flex flex-col w-1/2 ml-2">
      <label class="text-blue font-bold">Ml tuberias {{ filters.status_id==1 ? 'redactados' : 'certificado' }}</label>
      <pie-chart suffix="" :data="panel9" />

      <table class="mx-5 mt-4">
        <tbody>
          <tr class="panel" v-for="(line, key, index) in panel9" :key="'9_'+index">
            <td align="center" class="justify-start py-2 w-2/12 bg-grey-lightest">
              <div v-if="piechartColors[index]" :style="'width: 20px; border-radius: 1em; height: 20px; background:' + piechartColors[index]"></div>
            </td>
            <td align="center" class="justify-start py-2 w-5/12 bg-indigo-lightest">
              <span class="text-sm font-semibold text-blue">{{ key }}</span>
            </td>
            <td align="center" class="justify-start py-2 w-5/12 bg-grey-lightest">
              <span class="text-sm font-semibold">{{ line }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

  <!-- Panel 10 -->
  <div class="w-full mt-8">
    <div class="flex flex-row w-full text-blue font-bold">ml de Tubería por DSO</div>
    <div class="flex flex-row w-full"><column-chart :suffix="'ml'" :data="panel10" /></div>
  </div>

  <!-- Panel 11 -->
  <div class="w-full mt-8" v-if="filters.status_id > 1">
    <div class="flex flex-row w-full text-blue font-bold">% de avance ml de tubería por DSO</div>
    <div class="flex flex-row w-full"><column-chart :min="0" :max="100" :suffix="'%'" :data="panel11" :colors="['#d12411']" /></div>
  </div>

  <!-- Panel 12 -->
  <div class="w-full mt-8" v-if="filters.status_id > 0">
    <div class="flex flex-row w-full text-blue font-bold">Nº acometidas por DSO</div>
    <div class="flex flex-row w-full"><column-chart :suffix="'ud'" :data="panel12" /></div>
  </div>

  <!-- Panel 13 arriba -->


</div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import VueChartkick from 'vue-chartkick'
import Chart from 'chart.js'
import moment from 'moment'

Vue.use(VueChartkick, {adapter: Chart})
Vue.use(moment)

export default {
  middleware: 'auth',
  title: 'project_home_page_title',
  subtitle: 'project_home_page_subtitle',
  menuOption: '2-1',

  components: {
    ScaleLoader,
  },

  data: () => ({
    locationsList: [],
    activitiesList: [],
    selectedDso: null,
    filters: {
      location_id: 0,
      status_id: 2,
    },
    pectotal: null,
    pectotalRaw: null,
    panel1: {},
    panel2: {},
    panel3: {},
    panel4: [],
    panel5: [],
    panel6: null, // poniendo null sale 'loading' en la grafica
    panel7: null,
    panel8: null,
    panel9: null,
    panel10: null,
    panel11: null,
    panel12: null,
    panel13: null,

    isLoadingExport: false,
    txtTooltipExport: null,
    piechartColors: ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#3b3eac', '#0099c6', '#dd4477', '#66aa00', '#b82e2e']
  }),

  computed: {
    ...mapGetters({
      project: 'app/project',
      user: 'auth/user',
    }),

    statusName() {
      switch (this.filters.status_id) {
        case 0: return 'en redacción';
        case 1: return 'redactados';
        case 2: return 'en ejecución/completados';
        case 3: return 'completados';
        default: return '';
      }
    }
  },

  mounted() {

    if (this.project == null) {

      this.$router.push('/projects');

    } else {

      this.getLocationsList();
      this.getPanelsInfo();

      let pageInfo = {
        title: this.project.name,
        subtitle: this.$t('project_home_page_subtitle'),
        menuOption: '2-1',
      }

      this.$store.dispatch('app/updatePageInfo', {info: pageInfo})
    }
  },

  methods: {

    async getLocationsList() {
      let url = '/api/v2/locations?p=' + this.project.id;
      const {data} = await axios.get(url);
      if (data && data.success) {
        this.locationsList = data.locations
      } else {
        this.$notify.error('error_loading_locations')
      }
    },


    async getActivitiesList() {
      let url = '/api/v2/project/' + this.project.id + '/dashboard/activities-list';
      const {data} = await axios.get(url, {params: this.filters});
      if (data && data.success) {
        this.activitiesList = data.data
      } else {
        this.$notify.error('error_loading_activities')
      }
    },


    async getPanelsInfo() {

      // filtro 'en redaccion'
      if (this.filters.status_id == 0) {

        await this.getActivitiesList(); // esperar, asi carga en cache lista actividades usado por siguientes llamadas
        this.getPanel210ER(); // Paneles 2 y 10 especial para En Redaccion

      } else {

        // resto de filtros

        await this.getPanel1(); // await!. Carga en caché backend lista actividades
        this.getActivitiesList(); // puesto tras getPanel1 porque en backend se cachea este listado tras calcularlo en llamada panel1
        this.getPanel23(); // siguientes llamadas usan cache cargada antes y ya pueden ser simultaneas. Probado, asi es mas rapido.
        this.getPanel45();
        if (this.filters.status_id != 1) {
          this.getPanel6();
        }
        // this.getPanel7(); anulado por redundancia en panel 13
        this.getPanel89();
        this.getPanel101112();
        if (this.filters.status_id == 2 && this.filters.location_id == 0) {
          this.getPanel13(); // solo sin emplazamiento seleccionado y estado no sea redactado
        }

      }
    },


    // un solo metodo da conflicto con todas las llamadas simultaneas, las Promises se lian
    // async getPanel(n) {
    //   let url = '/api/v2/project/' + this.project.id + '/dashboard/panel' + n;
    //   await axios.get(url, {params: this.filters})
    //     .then(response => {
    //       console.log("datos panel "+n,response.data)
    //       this[panel+n] = response.data.data
    //     })
    //     .catch((error) => {
    //       //Se produjo un error
    //       this.isLoading = false
    //       console.log("ha fallado panel " + n, error.error_msg)
    //       this.$notify.error(error.error_msg || 'error_loading_locations')
    //     });
    // },


    async getPanel1() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/panel1';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel1 = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel1")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },


    async getPanel23() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/panel23';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel2 = response.data.data.tuberias
          self.panel3 = response.data.data.acometidas
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel2-3")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },


    // Especial filtro En Redaccion
    async getPanel210ER() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/panel210er';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel2 = response.data.data.panel2
          self.panel10 = response.data.data.panel10
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel210er")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },


    async getPanel45() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/panel45';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel4 = response.data.data.panel4
          self.panel5 = response.data.data.panel5
          // sumar las columnas proyectadas del panel 4 para PEC Total
          self.pectotal = Object.values(self.panel4[0].data).reduce((accumulator, value) => {
            return accumulator + parseFloat(value);
          }, 0)
          self.pectotalRaw = self.pectotal // para ciertas operaciones, mantenemos el float
          self.pectotal = Intl.NumberFormat(self.user.locale, {style: "currency", currency: "EUR"} ).format(self.pectotal)
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel4-5")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },


    async getPanel6() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/panel6';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel6 = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel6")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },


    async getPanel7() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/panel7';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel7 = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel7")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },


    async getPanel89() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/panel89';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel8 = response.data.data.panel8
          self.panel9 = response.data.data.panel9
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel89")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },


    async getPanel101112() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/panel101112';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel10 = response.data.data.panel10
          self.panel11 = response.data.data.panel11
          self.panel12 = response.data.data.panel12
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel10-11-12")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },


    async getPanel13() {
      let self = this
      let url = '/api/v2/project/' + this.project.id + '/dashboard/panel13';
      await axios.get(url, {params: this.filters})
        .then(response => {
          self.panel13 = response.data.data
        })
        .catch((error) => {
          //Se produjo un error
          self.isLoading = false
          console.log("Error loading panel13")
          self.$notify.error(error.error_msg || 'error_retrieving_data')
        });
    },


    goToDSODashboard(selectedDso) {
      this.$router.push({ name: 'project.dashboard.dso', params: { activity_id: selectedDso }})
    },


    async exportReport() {

      if( !this.isLoadingExport ){
        this.txtTooltipExport = this.$t('generating');

        this.$notify.success('generating_report');
        this.isLoadingExport = true;
        let self = this
        let url = '/api/v2/project/' + this.project.id + '/dashboard/export';
        await axios.get(url, {params: this.filters})
          .then(response => {
            window.open(response.data.data);

            self.$notify.success('report_generated')
            self.isLoadingExport = false;
            self.txtTooltipExport = this.$t('export')
          })
          .catch((error) => {
            //Se produjo un error
            console.log("Error loading exportación")
            self.$notify.error(error.error_msg || 'error_retrieving_data')
            self.isLoadingExport = false
            self.txtTooltipExport = this.$t('export')
          });
      }
    },


    presAdjudicacion(pectotal, baja) {
      let res = pectotal * (1 - baja)
      return Intl.NumberFormat(this.user.locale, {style: "currency", currency: "EUR"} ).format(res)
    },

  },

};
</script>

<style scoped>
.column-scroll {
  width: 50%;
  height: calc(100vh - 150px);
  overflow-x: hidden;
  overflow-y: auto;
}
.column-scroll:not(:last-child) {
  margin-right: 0.5rem;
}
.column-scroll:last-child {
  margin-left: 0.5rem;
}
</style>
