import Vue from 'vue'
// import store from '~/store'
import VueI18n from 'vue-i18n'
import Buefy from 'buefy'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  messages: {}
})

/**
 * @param {String} locale
 */
export async function loadMessages (locale) {
  if (Object.keys(i18n.getLocaleMessage(locale)).length === 0) {
    let _messages, appMessages;
    

      _messages = await import(`~/lang/${locale}`)
      appMessages = await import(`#/lang/${locale}.json`)
  


    const messages = Object.assign({}, _messages, appMessages)
    i18n.setLocaleMessage(locale, messages)
  }

  if (i18n.locale !== locale) {
    i18n.locale = locale
  }

  Vue.use(require('vue-faker'), {
    locale: i18n.locale
  });
}

export default i18n
