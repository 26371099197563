<template>
  <div class="panel">
    <div class="w-full flex items-center justify-between mb-8">
      <div class=" uppercase text-blue-light font-thin text-lg">
        {{ $t('assignment_interventions') }}
      </div>
    </div>

    <div>
      <b-table
        show-empty
        :data="data"
        :loading="isLoading"
        paginated
        striped
        bordered
        icon-pack="fas"
        :per-page="perPage"
      >
        <template v-slot:empty="">
          <h4>{{ $t('no_rows') }}</h4>
        </template>

        <template>
          <b-table-column field="code" :label="$t('code')" v-slot="props">
            {{ props.row.intervention.code }}
          </b-table-column>

          <b-table-column field="type" :label="$t('type')" v-slot="props">
            {{ props.row.intervention.type }}
          </b-table-column>

          <b-table-column field="value" :label="$t('value')" v-slot="props">
            {{ props.row.intervention.value }}
          </b-table-column>

          <b-table-column field="street" :label="$t('street')" v-slot="props">
            {{ props.row.street }}
          </b-table-column>
        </template>
      </b-table>
    </div>

  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import axios from 'axios'

  export default {

    components: {
    },

    props: {
      activityId: {type: Number, require: true },
    },

    data: () => ({
      data: [],
      isLoading: true,
      perPage: 5
    }),

    created() {
      this.getData()
    },

    methods: {
      async getData() {
        this.isLoading = true
        let {data} = await axios.get('/api/v2/activity-interventions', {params: {activity_id: this.activityId}});

        if (data.success) {
          this.data = data.data
        } else {
          this.$notify.error("error_loading_data");
        }
        this.isLoading = false
      }
    },

  };
</script>
