<template>
<div class="panel">
  <!-- Top -->
  <div class="w-full flex items-center justify-between mb-4">
    <!-- Título -->
    <div class="uppercase text-blue-light font-thin text-lg">{{ $t('subactivities') }}</div>



    <!-- Contadores actividades -->
    <activity-time-status-counters :ok="okCounter" :warning="warningCounter" :danger="dangerCounter" v-if="!isLoading"/>
    <!-- Botón añadir -->
    <b-tooltip :label="$t('add_subactivity')" position="is-left" v-if="canAdd">
      <div class="rounded-full bg-blue w-8 h-8 flex items-center justify-center text-grey-lightest cursor-pointer" @click="onAddClicked">
        <b-icon icon="plus" pack="fas" size="is-small" />
      </div>
    </b-tooltip>
  </div>

  <div class="w-full flex items-center mb-4" v-if="!isLoading && list.length > 0">
    <b-select ref="input"
              class="mr-1"
              v-model="orderBy"
              @input="refreshSubactivities">
      <option value="updated_at">{{ $t('updated_date') }}</option>
      <option value="name">{{ $t('name') }}</option>
      <option value="current_start_date">{{ $t('current_start_date') }}</option>
      <option value="current_end_date">{{ $t('current_end_date') }}</option>
    </b-select>
    <b-select ref="input"
              class="mr-1"
              v-model="orderDir"
              :placeholder="$t('order_direction')"
              @input="refreshSubactivities">
      <option value="desc">{{ $t('descending') }}</option>
      <option value="asc">{{ $t('ascending') }}</option>
    </b-select>
  </div>

  <div v-if="isLoading" class="text-center my-4"><scale-loader></scale-loader></div>
  <!-- Subactividades -->
  <b-collapse v-if="!isLoading && list.length > 0" :open.sync="isListOpen">
    <div slot="trigger" slot-scope="props"
      class="flex items-center font-semibold text-sm">
      {{ list.length + ' ' + $t('subactivities') }}
      <b-icon :icon="props.open ? 'caret-up' : 'caret-down'" class="text-blue ml-2" />
    </div>



    <!-- Subactividad -->
    <div
        v-for="subactivity in list"
        :class="(subactivity.access ? 'bg-grey-lighter cursor-pointer' : 'no_access') + ' my-1 p-2'"
        @click="onClickSubactivity(subactivity)"
        :key="subactivity.id"
    >
      <!-- Top -->
      <div class="flex items-center justify-start mb-4 float-left">
        <!-- Usuario -->
        <div class="flex items-center">
          <div class="rounded-full overflow-hidden border border-grey w-8 h-8 mr-2">
            <img :src="subactivity.manager.avatar" :alt="subactivity.manager.fullname">
          </div>
          <div>
            <div :class="(subactivity.access ? 'text-blue' : '') + ' font-medium flex items-center'">
              {{ subactivity.name }}
              <activity-time-status :date="subactivity.current_end_date" class="ml-2"
                v-if="![4, 6].includes(subactivity.status)" /> <!-- no show when completed (4) or closed (6) -->
            </div>
            <div :class="(subactivity.access ? 'text-blue-light' : '') + ' text-sm'">
              {{ subactivity.manager.fullname }}
            </div>
          </div>
        </div>
      </div>

        <!-- Status -->
        <div class="flex float-right mt-2">
          <activity-status-label :status="subactivity.status" />
        </div>


        <!-- Notifications -->
        <div class="flex float-right mt-2">
          <entity-notifications
                  :entityId="subactivity.id"
                  entityType="activity"
                  :mentions="subactivity.notifications.mentions"
                  :change_status="subactivity.notifications.change_status"
                  :comments="subactivity.notifications.comments"
                  :documents="subactivity.notifications.documents"
                  :chats="subactivity.notifications.chats"
                  :enabledChangedStatus="true"
          />
        </div>


      <!-- Fechas -->
      <div class="flex w-full flow-root">
        <span class="mr-4">{{ $t('current_start_date') }}: <strong :class="subactivity.access ? '' : 'no_access'">{{ subactivity.current_start_date }}</strong></span>
        <span>{{ $t('current_end_date') }}: <strong :class="subactivity.access ? '' : 'no_access'">{{ subactivity.current_end_date }}</strong></span>
      </div>
    </div>
  </b-collapse>
</div>
</template>

<script>
import axios from 'axios'
import activityTimeStatusCounters from '·/components/activity-time-status-counters'
import activityTimeStatus from '·/components/activity-time-status'
import activityStatusLabel from '·/components/activity-status-label'
import entityNotifications from '·/components/entity-notifications'
import EventBus from '~/plugins/bus'
import Vue from "vue"
import moment from 'moment'
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

Vue.use(moment)

export default {
  components: {
    activityTimeStatusCounters,
    activityTimeStatus,
    activityStatusLabel,
    entityNotifications,
    ScaleLoader
  },

  props: {
    activityId: { type: Number, required: true },
    activityName: { type: String, required: true },
    canAdd: { type: Boolean, default: false },
  },

  data: () => ({
    list: [],
    isListOpen: true,
    okCounter: 0,
    warningCounter: 0,
    dangerCounter: 0,
    isLoading: true,
    orderBy: 'planning_code',
    orderDir: 'asc',
  }),

  watch: {
    activityId(id) {
      if (id > 0) {
        this.getSubactivities()
      }
    }
  },

  created(){

    // Referenciamos
    let self = this;

    // Si recibimos el evento refresh, refrescamos el componente
    EventBus.$on('refreshSubactivitiesNotifications', function(e){
      self.getSubactivities();
    });
  },

  beforeDestroy(){
  	EventBus.$off("refreshSubactivitiesNotifications");
  },


  methods: {
    async getSubactivities() {
      let url = '/api/v2/activity/' + this.activityId + '/subactivities'
      const { data } = await axios.get(url, {params: { order_by: this.orderBy, order_dir: this.orderDir }})
      if (data && data.subactivities) {
        this.list = data.subactivities
        this.calculateCounters()
        this.isLoading = false
      }
    },
    onAddClicked() {
      this.$router.push({name: 'subactivity.create', params: {id: this.activityId, name: this.activityName}})
    },
    onClickSubactivity(subactivity) {
      if (subactivity.access) {
        this.$router.push({name: 'subactivity.home', params: {subactivity_id: subactivity.id, id: this.activityId}})
      }
    },
    calculateCounters() {
      let now = new Date()
      this.okCounter = 0
      this.warningCounter = 0
      this.dangerCounter = 0

      this.list.forEach((activity) => {
        let diff = this.diffDate(activity.current_end_date)
        if (diff > 3) {
          this.okCounter++
        } else if (diff <=3 && diff > 0) {
          this.warningCounter++
        } else {
          this.dangerCounter++
        }
      })
    },

    diffDate(date) {
      let end = moment(date, "DD/MM/YYYY")
      let now = moment()
      let duration = moment.duration(end.diff(now));
      return duration.asDays()
    },

    refreshSubactivities() {
      this.isLoading = true
      this.getSubactivities()
    }
  },

};
</script>

<style scoped>
  .no_access {
    color: #B8C2CB;
    background-color: #F8FAFC;
  }
</style>
