<template>
<div class="flex items-center">
  <div class="rounded-full overflow-hidden border border-grey" :class="imageClasses">
    <img :src="user.avatar" :alt="user.fullname">
  </div>
  <div>
    <div class="text-blue font-medium" :class="nameClasses">
      {{ user.fullname }}
    </div>
    <div  class="text-grey font-semibold" :class="secondLineClasses">
      <slot></slot>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'user-avatar',

  props: {
    user: { type: Object, required: true },
    size: { type: String, default: 'normal',
      validator: value => {
        return ['small', 'normal', 'large'].includes(value)
      }
    },
  },

  data: () => ({
    imageClasses: ['w-8', 'h-8', 'mr-1'],
    nameClasses: ['text-sm'],
    secondLineClasses: ['text-xs'],
  }),

  // computed: {
  //   ...mapGetters({
  //     user: 'authUser',
  //     authenticated: 'authCheck'
  //   }),
  // },

  mounted() {
  },

  methods: {
    setupSizeClasses() {

    }
  },

};
</script>
