<template>
<div>
  <!-- Resumen cuenta -->
  <div class="panel flex py-8 px-4 mt-8">
    <div class="w-1/4">
      <h3 class="text-header">{{ $t('account_state') }}</h3>
      <span class="flex items-center mt-2 font-semibold">
        <span class="bg-orange rounded-full w-3 h-3"></span>
        <span class="ml-2 flex-grow">ACTIVO (5 días para vencimiento)</span>
      </span>
    </div>
    <div class="w-1/4">
      <h3 class="text-header">{{ $t('contract_type') }}</h3>
      <span class="flex items-center mt-2 font-semibold">
        SEMESTRAL <span class="ml-2 text-green-light">5% DESCUENTO</span>
      </span>
    </div>
    <div class="w-1/4 pl-4">
      <h3 class="text-header">{{ $t('renew_date') }}</h3>
      <span class="flex items-center mt-2 font-semibold">
        25/04/2018
      </span>
    </div>
    <div class="w-1/4 pl-4 flex items-center">
      <v-button color="green" xl full>Pagar</v-button>
    </div>
  </div>
  <!-- Widget contadores -->
  <div class="flex justify-between mt-8">
    <widgetcounter
      :route="{ name: 'admin.areas' }"
      title="areas"
      subtitle="areas_handled"
      quantity="10/50"
      iconName="sitemap"
      color="orange"
      class="w-1/4 mr-4" />
    <widgetcounter
      :route="{ name: 'admin.projects' }"
      title="projects"
      subtitle="projects_handled"
      quantity="2,508"
      iconName="rocket"
      color="blue"
      class="w-1/4 mr-4" />
    <widgetcounter
      :route="{ name: 'admin.users' }"
      title="company_users"
      subtitle="active_company_users"
      quantity="30/100"
      iconName="user"
      color="green"
      class="w-1/4 mr-4" />
    <widgetcounter
      :route="{ name: 'admin.users' }"
      title="external_users"
      subtitle="external_users_subtitle"
      quantity="9/50"
      iconName="address-book"
      color="red"
      class="w-1/4" />
  </div>
</div>
</template>

<script>
import Widgetcounter from '~/components/WidgetCounter'

export default {
  middleware: 'auth',
  title: 'home_page_title',
  subtitle: 'home_page_subtitle',
  menuOption: '0-0',

  components: {
    Widgetcounter
  },

};
</script>
