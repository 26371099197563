<template>
  <div class="w-1/3 viewer-left-panel">
    <div class="header relative w-full">
      <span class="m-3 block">Views</span>
      <div class="viewer-panel__close-btn" @click="() => { $emit('epc-close', true) }">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="ga-left-panel-close"><g data-name="<Group>"><path d="M7.31 17.5a.5.5 0 0 1-.31-.85l10-10a.5.5 0 0 1 .71.71l-10 10a.5.5 0 0 1-.4.14z"></path><path d="M17.31 17.5a.5.5 0 0 1-.31-.15l-10-10a.5.5 0 0 1 .71-.71l10 10a.5.5 0 0 1-.4.86z"></path></g></svg>
      </div>
    </div>
    <div class="h-3" style="background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);"></div>
    <div class="bg-white">
      <ul class="py-2 text-sm text-gray-700">
        <li>
          <!-- <a href="#" class="block px-4 py-2 hover:bg-gray-100">Dashboard</a> -->
          <div v-for="(lyr, index) of views" :key="lyr.id ? lyr.id : 'lyr_' + index"
            class="px-4 py-2 hover:bg-gray-100 inactive"
            :id="'toolbar-' + lyr.code.toLowerCase() + 'Tools'"
            :class="{ 'active' : displayView === lyr.code, 'cursor-pointer': workLayer === null }"
            @click="_HandleClick(lyr)">
              <b-icon pack="fas" :icon="_GetIcon(lyr)"></b-icon> {{ lyr.name }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

  export default {
    name: "ViewerPanelViews",

    props: {
      views: { type: Array, default: [] },
      displayView: { type: String, default: null },
      workLayer: { type: String, default: null }
    },

    watch: {
    },

    data() {
      return {

      }
    },

    methods: {
      _GetIcon(layer) {
        return layer.icon ?? 'grip-horizontal' // 'images'
      },

      _HandleClick(lyr) {
        if ( !this.workLayer )
          this.$emit('epc-view-selected', lyr.code)
      }
    }
  }

</script>

<style scoped>

  .active {
    border: 1px solid #5577bb;
    color: #5577bb;
  }
  .viewer-left-panel {
    border-right: 1px solid #d4dbe1;
    background: linear-gradient(to top, #eeeeee 0, #f7f7f7 8px, #fafafa 12px, #fff 20px);
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.3);
  }
  .viewer-panel__close-btn {
      position: absolute;
      top: 0px;
      right: 5px;
      float: none;
      fill: #939ca5;
      cursor: pointer;
      display: block;
      z-index: 1;
      /* width: 40px; */
      /* height: 40px; */
  }

</style>
