<template>
  <div class="flex flex-col w-full">
    <div class="flex items-center justify-between w-full">
      <div class="font-semibold text-black">Nuevo pictograma</div>
      <div>
        <b-tooltip :label="$t('close')" position="is-left" :active="! processingData">
          <div class="flex items-center justify-center w-8 h-8 rounded-full text-grey-lightest"
            :class="{ 'cursor-pointer bg-red': !processingData, 'bg-gray-300': processingData }"
            @click="_HandleCloseClicked">
            <b-icon pack="fas" icon="times" size="is-small" />
          </div>
        </b-tooltip>
      </div>
    </div>

    <div class="mt-4">

      <!-- campo total de estructuras, se calcula a partir del diseño contenido en el archivo dxf -->
      <div class="mb-2">
        <div class="text-xs font-medium capitalize text-blue">{{ $t("total_structures") }}</div>
        <div class="flex flex-row items-center">
          <input
            v-model="total_structures"
            title="units"
            :disabled="true"
            :required="true"
            name="units"
            step="1"
            type="number"
            class="w-16 h-8 p-1 border rounded bg-grey-lighter border-grey-light"
            @focus="$event.target.select()" />
          <span class="ml-1 text-xs text-black">(Dato calculado desde el diseño)</span>
        </div>
      </div>

      <div>
        <b-field>
          <b-switch v-model="layeredDesign"
            :rounded="true"
            :outlined="false"
            :size="'is-small'"
            :type="'is-success'"
            :disabled="dxf == null || processingData"
            :left-label='true'>Crear diseño por capas</b-switch>
        </b-field>
      </div>

      <div class="mt-4">

        <fields-design-form ref="frm_default"
          :disable-fields="dxf == null || processingData"
        />

        <layers-design-list v-if="layeredDesign"
          ref="refDataCadLayersDesign"
          :layers="layersDesign"
          :disable-fields="dxf == null || processingData"
        />
      </div>

    </div>

    <div class="flex flex-col w-full mt-4">
      <v-button-processing v-if="processingData" />

      <div v-else class="w-full flex flex-col lg:flex-row">
        <b-button :label="$t('generate_design')"
          type="is-info"
          icon-pack="fas"
          icon-left="table-cells"
          class="w-full"

          @click="_HandleClickGenerateDesign" />
      </div>
    </div>
  </div>
</template>

<script>
import {DxfWorker} from "../viewer/src/DxfWorker"

import FieldsDesignForm from "./fields-design-form.vue"
import LayersDesignList from './layers-design-list.vue';

export default {
  components: {
    FieldsDesignForm,
    LayersDesignList
  },

  props: {
    dxf: { type: Object, require: true }
  },

  data () {
    return {
      total_structures: null,
      processingData: false, // bandera para indicar que se esta procesando algo

      // Layers del diseño
      layersDesign: [], // array contiene las capas del diseño
      layeredDesign: false, // bandera que indica si se añadira el diseño por capas de cad.
      totalEntitiesDesign: {}, // objeto que contiene el total de pilings modules y panels generados
      cadLayeredDesign: {}, // objeto que contiene los datos de diseño por capas
      screenshotDesign: [] // array contiene las capturas de los diseños de las capas controlados por nosotros, son usados en el dasboard
    }
  },

  mounted() {
    this.total_structures = this.dxf.entities.filter(item => item.type === 'POLYLINE' || item.type === 'LWPOLYLINE').length
    this.layersDesign = []
    Object.values(this.dxf.tables.layer.layers).forEach(value => {
      this.layersDesign.push(value);
    })
  },

  methods: {
    async _HandleClickGenerateDesign() {

      let defaultDesign = this.$refs.frm_default.getDataForm()

      if ( defaultDesign != null ) {
        this.processingData = true

        let cadLayersDesign = {}

        if ( this.layeredDesign ) {
          cadLayersDesign = this.$refs.refDataCadLayersDesign.getDataDesign()
        }

        this.cadLayeredDesign = Object.assign({}, {default: defaultDesign}, cadLayersDesign)

        let worker = new DxfWorker(null)

        this.totalEntitiesDesign = await worker.CreateEntities(this.dxf, this._GetLayers(), this.cadLayeredDesign)

        this.scene = await worker.CreateObject(this.dxf)

        await worker.Destroy()
        worker = null

        await this.sleep(1000)

        this.processingData = false

        // Mandamos a previsualizar el diseño generado
        let dataPictogram = {
          scene: this.scene,
          layers: this._GetLayers(),
          cadLayeredDesign: this.cadLayeredDesign,
        }
        this.$emit('epc-pictogram-preview', dataPictogram)
      }
    },

    /**
     * _GetLayers retorna los layers que hacen referencia a los layer manejador por nosotros, no a los que estan definidos en el
     * DXF de momento lo harcodeamos, porque son las que por defecto deben crearse y generarse, pero en el futoro hay que retocar
     * esta parte para adicionar otros dinamicamente
     * Propiedad create_entities indica si se van a crear las entidades al momento de generar el parser del DXF, ademas puede usarse
     * para identificar si se inserta en la tabla pictogram_layers (Si es muy ambiguo crear la propiedad adecuada y ajustar)
     * Propiedad in_design indica que viene en el diseño y no hay la necesidad de crear las entidades
     * Propiedad icon es el icono que representara el layer por defecto seria null
     */
     _GetLayers() {
      return [
        {
          code: 'pilings',
          name: 'Hincados',
          icon: 'band-aid',
          order: 0,
          quantity: 0,
          total_entities: this.totalEntitiesDesign.hasOwnProperty('pilings') ? this.totalEntitiesDesign['pilings'] : 0,
          create_entities: true,
          in_design: false
        },
        {
          code: 'structures',
          name: 'Estructuras',
          icon: 'stream',
          order: 1,
          quantity: this.total_structures,
          total_entities: this.total_structures,
          create_entities: true,
          in_design: true
        },
        {
          code: 'modules',
          name: 'Modulos',
          icon: 'solar-panel',
          order: 2,
          quantity: 0,
          total_entities: this.totalEntitiesDesign.hasOwnProperty('modules') ? this.totalEntitiesDesign['modules'] : 0,
          create_entities: true,
          in_design: false
        },
        {
          code: 'panels',
          name: 'Paneles',
          icon: null,
          order: 3,
          quantity: 0,
          total_entities: this.totalEntitiesDesign.hasOwnProperty('panels') ? this.totalEntitiesDesign['panels'] : 0,
          create_entities: false,
          in_design: false
        }
      ]
    },

    _HandleCloseClicked() {
      if (!this.processingData)
        this.$emit('epc-pictogram-data-close')
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  }
}
</script>
