<template>
  <div>
    <vue-tags-input
      v-model="tag"
      :tags="tags"
      :placeholder="$t('search_by_tag')"
      :add-only-from-autocomplete="true"
      :autocomplete-items="filteredParentTags"
      :autocomplete-always-open="autocompleteOpen"
      :autocomplete-min-length="parseInt(0)"
      @focus="autocompleteOpen = filteredParentTags.length > 0"
      @blur="autocompleteOpen = false"
      @tags-changed="syncTags"
      ref="tagInput"
    />
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import {mapGetters} from 'vuex'

export default {
  name: "filter-tags",

  components: {
    VueTagsInput,
  },

  props: {
    tagsArray: {type: Array, required: false}
  },

  data: () => ({
    tag: '',
    tags: [],
    parentTags: [],
    autocompleteOpen: false,
  }),

  computed: {
    filteredParentTags: function () {

      if (this.tagsArray && this.tagsArray.length !== 0) {
        return this.tagsArray.filter(i => {
          return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
        })
      } else {
        return this.parentTags.filter(i => {
          return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
        })
      }
    },
    ...mapGetters({
      storeTags: 'app/tags',
    }),
  },

  created() {
    // Cargamos la lista de de etiquetas del proyecto actual
    this.parentTags = this.storeTags.map(function (tag) {
      return {text: tag.name}
    })
  },

  methods: {

    // Emite un evento al padre
    syncTags(newTags) {
      this.tags = newTags
      this.$emit(
        'filterList',
        this.tags.map(function (tag) {
          return tag.text
        })
      )
    },

    isDuplicate(tags, tag) {
      return tags.map(t => t.text).indexOf(tag) > -1;
    },

    resetTags() {
      this.tags = []
    }
  },
}
</script>

<style scope>
.vue-tags-input {
  max-width: 100% !important;
}
</style>
