import axios from 'axios'

var api_version = '/api/v2/'

// Devuelve un promise que hay que resolver/rechazar
async function fetch(endpoint, params = {}) {
  try {
    return axios.get(api_version + endpoint, params)
      .then(function (response) {
        return response.data
      })
  } catch (error) {
    console.error(error)
  }
}

export default {

  fetchAreas(params = {}) {
    return fetch('areas_all', {params: params})
  },

  fetchArea(id, params = {}) {
    return this.fetchEntity('area', id, params)
  },

  fetchUserAreas(params = {}) {
    return fetch('user/areas', {params: params})
  },

  fetchActivity(id, params = {}) {
    return this.fetchEntity('activity', id, params)
  },

  fetchEntity(entity, id, params = {}) {
    return fetch(entity + '/' + id, {params: params})
  },
}

