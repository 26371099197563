<template>
  <div>
    <div>
      <multiselect
        v-model="mentions"
        :options="usersToMention"
        :multiple="true"
        :hide-selected="true"
        track-by="email"
        :customLabel="mentionLabel"
        :show-labels="false"
        :placeholder="$t('mention_users')"
        :disabled="disabled"
        >
        <template slot="option" slot-scope="users">
          <div>
            <img
              style="width: 28px; height: 28px;"
              class="rounded-full overflow-hidden"
              :src="users.option.basic.avatar">
            <span class="text-sm font-semibold truncate">{{ users.option.basic.fullname }}</span>
          </div>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import Multiselect from 'vue-multiselect'
import EventBus from '~/plugins/bus'

export default {
  name: "mention-users",

  components: {
    Multiselect,
    EventBus
  },

  props: {
    entityId: { type: Number, required: true },
    entityType: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    document: { type: Object, default: null},
  },

  data: () => ({
    mentions: [],
    usersToMention: []
  }),

  created() {

    var self = this

    axios.get("/api/v2/"+this.entityType+"/" + this.entityId + "/users?r=" + 'activity_admin,participant,collaborator' + '&basic=0&array=1')
      .then(function (response) {
        if (response.data && response.data.success) {
          self.usersToMention = response.data.users_list
        }
      })

    // Si hemos recibido un documento, cargamos su información de menciones
    if (this.document) {
      this.mentions = this.document.mentioned_users
    }
  },

  methods: {

    async uploadMentions() {

      let mentions = this.getMentionsIds()

      const { data } = await axios.post(
        "/api/v2/document/" + this.document.document_id + "/update/mentions", { mentions: mentions }
      );
      // if (data && data.success) {
      //   this.$notify.success('success_updating_mentions')
      // } else {
      //   this.$notify.error('error_updating_mentions')
      // }
    },

    getMentionsIds() {
      // Enviamos los ids de las menciones realizadas
      return this.mentions.map((item) => {
        return item.id
      })
    },

    mentionLabel(user) {
      return user.basic.fullname
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
  .multiselect__option--highlight {
    background-color:cornflowerblue !important;
  }
  .multiselect__tag {
    background-color:cornflowerblue !important;
  }
  .multiselect__tag-icon::after {
    color: whitesmoke !important;
  }
  .multiselect__tag-icon:hover {
    background-color:#385da5 !important;
  }
</style>
