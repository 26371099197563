<template>
    <section>
      <div class="mt-4">
         <!--
            <div class="flex mr-4 text-blue cursor-pointer" @click="downloadTemplate">
            <b-icon pack="fas" icon="file-excel"></b-icon>
            {{ $t('download_template') }}
            </div>
        -->
        <div class="panel">
          <form @submit.prevent="submitForm" :files="true">
            <div class="flex w-full">
              <div class="flex">
                <b-field class="flex mr-4">
                  <b-upload
                    v-model="form.excelFile"
                    @input="cleanErrors"
                    required
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  >
                    <a class="button is-primary">
                      <b-icon pack="fas" icon="upload"></b-icon>
                      <span>{{ $t('select_file') }}</span>
                    </a>
                  </b-upload>
                  <span class="file-name" v-if="form.excelFile">{{ form.excelFile.name }}</span>
                </b-field>
              </div>
            </div>
          
      
            <div class="flex w-full mt-4">
              <div class="flex w-full">
                <b-field class="w-full">
                  <b-input
                    required
                    :placeholder="$t('description')"
                    v-model="form.description"
                    maxlength="255"
                    expanded
                  ></b-input>
                </b-field>
              </div>
            </div>
            <div class="flex w-full">
              <v-button
                :loading="isLoading"
                type="submit"
                color="green"
                icon="file-import"
                icon-pack="fas"
              >{{ $t('import') }}</v-button>
            </div>
          </form>
        </div>
        <div>
          <b-tabs type="is-boxed" v-model="importActiveTab">
            <b-tab-item>
              <template slot="header">
                <b-icon pack="fas" icon="box-open"></b-icon>
                <span>{{ $t('imports_archive') }}</span>
              </template>
              <div>
                <entity-imports-archive
                  v-if="withHistory"
                  :project-id="projectId"
                  :partner-id="partnerId"
                  :company-id="companyId"
                  :entity-type="entityType"
                  :overwrite-field="false"
                  ref="entity_imports"
                ></entity-imports-archive>
              </div>
            </b-tab-item>
            <b-tab-item :disabled="errors.length < 1">
              <template slot="header">
                <b-icon pack="fas" icon="exclamation-circle"></b-icon>
                <span>
                  {{ $t('error_log') }}
                  <b-tag rounded>{{ errorsCount }}</b-tag>
                </span>
              </template>
              <div>
                <div class="flex items-center font-bold text-red-dark text-ml mb-4">
                  <span>{{ $t('error_import_title') }}</span>
                </div>
                <div v-for="(error, index) in errors" :key="'error_'+index">
                  <ul>
                    <li v-for="(msg, index) in error.messages" :key="'errorli_'+index" class="menu-header mb-2">
                      <div class="flex items-center">
                        <div class="text-red-dark text-ml">
                          <span>
                            {{ $t('import_row_title', [error.row]) }}.
                            {{ msg }}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import Form from "vform";
  import entityImportsArchive from "./entity-imports-archive";
  import axios from 'axios';
  import moment from "moment";
  
  export default {
    name: "import-pipes",
    components: {
      entityImportsArchive
    },
  
    props: {
      withHistory: { type: Boolean, default: true },
      projectId: { type: Number, default: null, required: false },
      partnerId: { type: Number, default: null, required: false },
      companyId: { type: Number, default: null, required: false },
      entityId: { type: String, default: '', required: false }, // mediciones certificadoras van sobre una actividad concreta
      entityType: { type: String, required: true },
      canOverwrite: { type: Boolean, default: false },
      actionType: { type: String, default: '' }, // distinciones a tener en cuenta por el importador
      morphType: { type: String, default: '' }, // usado para cuando se tenga que trabajar con importaciones polimorphicas
      assignmentType: { type: String, default: 'certifiers' }, // para cargar certificadoras se requiere que por defecto sea seleccionado
    },
  
    created() {
      // si pasamos un codigo actividad, para importar cantidades ejecutadas sobre una medicion (desde la modal)
      if (this.entityId !== '') {
        this.form.activityCode = this.entityId
        this.form.overwrite = 1 // para cargar cantidades ejecutadas se requiere esta opcion a true
        this.form.assignmentType = this.assignmentType
        this.disableActivityCode = true
      }
    },
  
    data: () => ({
      isLoading: false,
      form: new Form({
        projectId: null,
        partnerId: null,
        companyId: null,
        excelFile: null,
        description: "",
        overwrite: false,
        assignmentType: null,
        activityCode: null,
        actionType: "",
        workType: null,
        effectiveDate: "",
      }),
      effectiveDate: null,
      errors: [],
      importActiveTab: 0,
      errorsCount: 0,
      disableActivityCode: false,
      filteredActivities: [],
    }),
  
    computed: {
      templateName() {
        // TODO: de momento hacemos este hack para mantener funcionando (replace('/', '-')) lo ideal es quitar el replace y mantener una convencion para entitytype
        return this.assetVapor("template_import_" + this.entityType.replace('/', '-') + ".xlsx");
      },
      templateFileName() {
        // TODO: de momento hacemos este hack para mantener funcionando (replace('/', '-'))
        return this.$t(this.entityType.replace('/', '-') + "_import_template") + ".xlsx";
      },
      overwriteEnabled() {
        return this.canOverwrite;
      },
      previousMonth() {
        let today = new Date()
        return new Date(today.getFullYear(), today.getMonth()-1, today.getDate())
      }
    },
  
    methods: {
      submitForm() {
        this.isLoading = true;
        var self = this;
        this.cleanErrors();
  
        this.form.projectId = this.projectId;
        this.form.partnerId = this.partnerId;
        this.form.companyId = this.companyId;
        this.form.actionType= this.actionType;
        this.form.effectiveDate = this.effectiveDate
          ? moment(this.effectiveDate).format('YYYY-MM-DD')
          : "";
        if( this.morphType )
          this.form.morphType = this.morphType
  
        this.form
          .post("/api/v2/import/" + this.entityType)
          .then(function(response) {
  
            self.isLoading = false;
            if (response.data.success) {
  
              self.$notify.success("success_import");
              self.form.description = "";
              if (self.withHistory)
                self.$refs.entity_imports.reloadImports();
              self.$emit("success");
            } else {
  
              self.$notify.error("error_import");
  
              // Procesamos los errores para mostrarlos
              for (let key in response.data.errors) {
                let error_info = key.split(".");
                let row = parseInt(error_info[0]);
                let field = `[${error_info[1]}]`;//self.$t(error_info[1]);
  
                let messages = response.data.errors[key].map(function(msg) {
                  return self.$t(msg) !== msg
                    ? self.$t(msg, [field])
                    : msg; // prefiero el msg tal cual se devuelve en el importador
                    // : self.$t("import_generic_error_msg", [parseInt(field)+1]); // mensaje generico sin muchas pistas
                });
  
                self.errorsCount = self.errorsCount + messages.length;
  
                self.errors.push({ row: row, field: field, messages: messages });
              }
  
              self.errors = self.errors.sort(function(a, b) {
                return parseInt(a.row) - parseInt(b.row);
              });
  
              self.importActiveTab = 1;
            }
          })
          .catch(error => {
            self.isLoading = false;
            self.$notify.error("error_import");
          });
  
        this.form.excelFile = null;
      },
  
      cleanErrors() {
        this.errors = [];
        this.errorsCount = 0;
        this.importActiveTab = 0;
      },
  
      downloadTemplate() {
        let link = document.createElement("a");
        link.href = this.templateName;
        link.download = this.templateFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
  
      async getFilteredActivities(){
        if (this.form.activityCode && this.form.activityCode.length > 2){
          let getParams = {
            p: this.projectId,
            filter_planning_code: this.form.activityCode,
            light: 1
          }
          const { data } = await axios.get('/api/v2/activities', { params: getParams });
          this.filteredActivities = data.activities.map(activity => activity.planning_code);
        }
  
        else {
          this.filteredActivities = [];
        }
      },
    }
  };
  </script>
  