<template>
<div>
  <!-- optional indicators -->
  <b-icon icon="loading"
    class="relative float-left text-grey"
    style="top:24px;left:5px"
    v-if="loading" />
  <template v-else>
    <b-icon icon="search"
      class="relative float-left text-grey"
      style="top:24px;left:5px"
      v-show="isEmpty" />
    <b-icon icon="close"
      class="relative float-right text-grey cursor-pointer"
      style="top:24px;right:9px"
      v-show="isDirty"
      @click="reset" />
  </template>
  <!-- the input field -->
  <input ref="input"
    class="input w-full px-6"
    type="text"
    :placeholder="$t(inputPlaceholder)"
    autocomplete="off"
    autofocus
    v-model="query"
    @keydown.down="down"
    @keydown.up="up"
    @keydown.enter="hit"
    @keydown.esc="onEscPressed"
    @input="update"/>
    <!-- the list -->
    <transition-group v-show="hasItems"
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
      tag="ul"
      class="typeahead-results">
      <!-- <ul v-show="hasItems" class="list-reset text-center"> -->
        <li v-for="(item, index) in items"
          :class="[{ 'border-t': (index != 0) }, activeClass(index)]"
          :key="item.id"
          @mousedown="hit"
          @mousemove="setActive(index)">
          <div v-html="_prepareResultNode(item)"></div>
        </li>
      <!-- </ul> -->
    </transition-group>
  </div>
</template>

<script>
import axios from 'axios'
import { util } from 'vue'

export default {
  props: {
    apiUrl: { type: String, required: true },
    // Parámetro a concatenar a la url pasando al back la query
    queryParamName: { type: String, default: 'q' },
    // Límite de resultados a mostrar
    resultsLimit: { type: Number, default: 5 },
    // Caracteres mínimos para lanzar la primera consulta al back
    minCharsToQuery: { type: Number, default: 3 },
    inputPlaceholder: { type: String, default: '...' },
    // Callback para preparar el HTML del nodo de resultados
    prepareResultNode: { type: Function, default: null},
    selectFirst: { type: Boolean, default: false },
  },

  data: () => ({
    items: [],
    query: '',
    current: -1,
    loading: false,
  }),

  computed: {
    hasItems () {
      return this.items.length > 0
    },
    isEmpty () {
      return !this.query
    },
    isDirty () {
      return !!this.query
    }
  },

  methods: {
    update () {
      this.cancel()
      if (!this.query) {
        return this.reset()
      }
      if (this.minCharsToQuery && this.query.length < this.minCharsToQuery) {
        return
      }
      this.loading = true
      this.fetch().then((response) => {
        if (response && this.query) {
          let data = response.data
          data = this.prepareResponseData ? this.prepareResponseData(data) : data
          this.items = this.resultsLimit ? data.slice(0, this.resultsLimit) : data
          this.current = -1
          this.loading = false
          if (this.selectFirst) {
            this.down()
          }
        }
      })
    },

    fetch () {
      const src = this.queryParamName
        ? this.apiUrl
        : this.apiUrl + this.query

      const params = this.queryParamName
        ? Object.assign({ [this.queryParamName]: this.query }, this.data)
        : this.data

      let cancel = new Promise((resolve) => this.cancel = resolve)
      let request = axios.get(src, { params })

      return Promise.race([cancel, request])
    },

    _prepareResultNode(item) {
      if (this.prepareResultNode) {
        return this.prepareResultNode(item)
      } else {
        return item.name
      }
    },

    // Por si queremos cancelar las búsquedas previas
    cancel () {
    },

    reset () {
      this.items = []
      this.query = ''
      this.loading = false
    },

    setActive (index) {
      this.current = index
    },

    setFocus () {
      this.$refs.input.focus()
    },

    activeClass (index) {
      return { active: this.current === index }
    },

    hit () {
      if (this.current !== -1) {
        this.$emit('item-selected', this.items[this.current])
      }
    },

    up () {
      if (this.current > 0) {
        this.current--
      } else if (this.current === -1) {
        this.current = this.items.length - 1
      } else {
        this.current = -1
      }
    },

    down () {
      if (this.current < this.items.length - 1) {
        this.current++
      } else {
        this.current = -1
      }
    },

    onEscPressed () {
      this.reset()
      this.$emit('esc-pressed')
    },

  }
};
</script>
