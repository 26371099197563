<template>
  <div>
    <div class="top-bar flex justify-end">
      <v-button
        @click="addRow"
        icon="plus"
      >{{ $t('add_generic') }}</v-button>
    </div>
    <div>
      <!-- Tabla de clases / tipos / disciplinas / origenes -->
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInUp"
        leave-active-class="animated fadeOutDown"
      >
        <div class="panel">

          <!-- form create new -->
          <div class="w-full flex flex-wrap" v-show="create!=false || form.code">
            <div class="panel">
              <form @submit.prevent="changeRow" class="w-full flex">
                <div class="flex-grow pr-3">
                  <label for="code" class="w-full">{{ $t('code') }}</label>
                  <v-input
                    v-model="form.code"
                    title="code"
                    id="code"
                    name="code"
                    placeholder="place_placeholder"
                    class="w-full"
                    required
                  />
                </div>
                <div class="flex-grow pr-3">
                  <label for="name" class="w-full">{{ $t('name') }}</label>
                  <v-input
                    v-model="form.name"
                    title="name"
                    id="name"
                    name="name"
                    placeholder="name"
                    class="w-full"
                    required
                  />
                </div>
                <div class="flex-grow pr-3">
                  <label for="description" class="w-full">{{ $t('description') }}</label>
                  <v-input
                    v-model="form.description"
                    title="description"
                    id="description"
                    name="description"
                    placeholder="description"
                    class="w-full"
                  />
                </div>

                <div class="flex-grow-0 pr-3 inline-flex self-end">
                  <v-button
                    native-type="submit"
                    :disabled="!form.code && !form.name"
                  >{{ $t('save') }}</v-button>
                </div>
              </form>
            </div>
          </div>


          <section>
            <b-table
              show-empty
              :data="data"
              :loading="isLoading"
              paginated
              backend-pagination
              :per-page="perPage"
              :total="total"
              @page-change="onPageChange"
              backend-sorting
              :default-sort="orderField"
              :default-sort-direction="orderDir"
              @sort="onSort"
              striped
              bordered
              icon-pack="fas"
            >
              <template v-slot:empty="">
                <h4>{{ $t('no_rows') }}</h4>
              </template>

              <template>
                <b-table-column field="code" sortable width="200">
                  <template v-slot:header="">
                    {{ $t('code') }}
                  </template>
                  <template v-slot="props">
                    <div v-if="newData && newData.id == props.row.id">
                      <v-input
                        v-model="newData.code"
                        title="code"
                        id="code"
                        name="code"
                        :value="props.row.code"
                        placeholder="code"
                        class="w-full"
                        :ref="'fieldCode' + props.row.id"
                      />
                    </div>
                    <div v-else>
                      {{ props.row.code }}
                    </div>
                  </template>
                </b-table-column>

                <b-table-column field="name" >
                  <template v-slot:header="">
                    {{ $t('name') }}
                  </template>
                  <template v-slot="props">
                    <div v-if="newData && newData.id == props.row.id">
                      <v-input
                        v-model="newData.name"
                        title="name"
                        id="name"
                        name="name"
                        :value="props.row.name"
                        placeholder="name"
                        class="w-full"
                        :ref="'fieldName' + props.row.id"
                      />
                    </div>
                    <div v-else>
                      {{ props.row.name }}
                    </div>
                  </template>
                </b-table-column>

                <b-table-column field="description" >
                  <template v-slot:header="">
                    {{ $t('description') }}
                  </template>
                  <template v-slot="props">
                    <div v-if="newData && newData.id == props.row.id">
                      <v-input
                        v-model="newData.description"
                        title="description"
                        id="description"
                        name="description"
                        :value="props.row.description"
                        placeholder="description"
                        class="w-full"
                      />
                    </div>
                    <div v-else>
                      {{ props.row.description }}
                    </div>
                  </template>
                </b-table-column>

                <b-table-column field="actions" centered width="80">
                  <template v-slot:header="">
                    {{ $t('actions') }}
                  </template>
                  <template v-slot="props">
                    <div class="w-full h-full flex items-center justify-around">
                      <div v-if="newData && newData.id == props.row.id">
                        <b-tooltip :label="$t('save')" position="is-left">
                          <div @click="saveType()" class="cursor-pointer flex text-blue">
                            <b-icon pack="fas" icon="save" />
                          </div>
                        </b-tooltip>
                        <b-tooltip
                          :label="$t('cancel')"
                          type="is-danger"
                          position="is-left"
                        >
                          <div @click="() => { newData = {} }" class="cursor-pointer flex text-red">
                            <b-icon pack="fas" icon="ban" />
                          </div>
                        </b-tooltip>
                      </div>

                      <div v-else>
                        <b-tooltip :label="$t('edit')" position="is-left">
                          <div @click="editRow(props.row)" class="cursor-pointer flex text-blue">
                            <b-icon pack="fas" icon="pencil-alt" />
                          </div>
                        </b-tooltip>


                        <b-tooltip
                          v-if="canDelete(props.row)"
                          :label="$t('measurement_unit_has_usage')"
                          position="is-left"
                        >
                          <div class="cursor-not-allowed flex text-grey">
                            <b-icon pack="fas" icon="trash-alt" />
                          </div>
                        </b-tooltip>

                        <b-tooltip
                          v-else
                          :label="$t('delete')"
                          type="is-danger"
                          position="is-left"
                        >
                          <div @click="removeRow(props.row)" class="cursor-pointer flex text-red">
                            <b-icon pack="fas" icon="trash-alt" />
                          </div>
                        </b-tooltip>
                      </div>

                    </div>
                  </template>
                </b-table-column>
              </template>
            </b-table>
          </section>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// import Form from "vform";
import axios from "axios";

export default {
  name: "project-issue-c-t-d-o", // classes-types-disciplines-origins

  components: {
  },

  data: () => ({
    data: [],
    datatable: null,
    form: {
      code: "",
      name: "",
      description: ""
    },
    newData: {},
    create: false,
    draggable: false,
    isLoading: true,
    autocomplete: null,
    page: 1,
    perPage: 15,
    total: 0,
    orderField: "name",
    orderDir: "asc"
  }),

  props: {
    projectId: { type: Number, required: true },
    entity_type: { type: String, default: 'project' },
    option: { type: String, default: 'disciplines' }, // classes | types | disciplines | origins
  },

  mounted() {
    this.getProjectIssueCTDO();
  },

  methods: {
    async addRow() {
      // this.reload();
      // this.getProjectIssueCTDO();
      this.create = !this.create;
    },

    async getProjectIssueCTDO() {
      this.isLoading = true;
      let params = {
        // order_dir: this.orderDir,
        // order_field: this.orderField,
        entity_type: this.entity_type, // en este momento no usado en backend
      };

      let endpoint = 'issue-' + this.option // issue-classes | issue-types | issue-disciplines | issue-origins
      const { data } = await axios.get(
        "/api/v2/project/" + this.projectId + "/" + endpoint,
        { params: params }
      );
      if (data && data.success) {
        this.data = data.data;
        this.total = data.data ? data.data.length : 0;
      } else {
        this.$notify.error("error_loading_data");
      }
      this.isLoading = false;
    },

    reload() {
      this.getProjectIssueCTDO();
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    onPageChange(page) {
      this.page = page;
      this.reload();
    },

    onSort(field, order) {
      this.orderField = field;
      this.orderDir = order;
      this.reload();
    },

    editRow(row) {
      this.newData = row
      this.$nextTick(() => {
        let refName = `fieldName${row.id}`
        this.$refs[refName].$el.focus()
      });
    },

    async saveType() {
      if (this.newData.code == '') {
        this.$notify.error(this.$t('empty_field_required', ['code']))
        return
      }

      if (this.newData.name == '') {
        this.$notify.error(this.$t('empty_field_required', ['nombre']))
        return
      }

      let endpoint = 'issue-' + this.option.slice(0, -1) // issue-class | issue-type | issue-discipline | issue-origin (removes 's' for singular)
      endpoint = (endpoint == 'issue-classe') ? 'issue-class' : endpoint; // corregir 'issue-classe' a 'issue-class'
      const { data } = await axios.post("/api/v2/" + endpoint + "/" + this.newData.id, {
        code: this.newData.code,
        name: this.newData.name,
        description: this.newData.description,
        entity_type: this.entity_type,
      })

      if (data && data.success) {
        // this.getProjectIssueCTDO()
        let index = this.data.findIndex(x => x.id === this.newData.id);
        if( index !== -1 )
          this.data[index] = this.newData
        // else // en este caso se añadiria al array
        this.newData = {}

        this.$notify.success('success_editing')

      } else if(data && data.msg) {
        this.$notify.error('no_access_permissions')
      } else {
        this.$notify.error('error_saving')
      }
    },

    async removeRow(row) {
      this.$dialog.confirm({
        title: this.$t("delete_type"),
        message: this.$t("delete_confirm_text", ['('+row.code+')', row.name]),
        confirmText: this.$t("delete"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true;
          let endpoint = 'issue-' + this.option.slice(0, -1) // issue-class | issue-discipline | issue-origin (removes 's' for singular)
          endpoint = (endpoint == 'issue-classe') ? 'issue-class' : endpoint; // corregir 'issue-classe' a 'issue-class'
          let url = "/api/v2/project/" + this.projectId + "/" + endpoint + "/" + row.id;
          const { data } = await axios.delete(url);

          if (data.success) {
            this.$toast.open({
              message: this.$t("removed_successfully"),
              type: "is-success",
              position: "is-top-right"
            });

            await this.sleep(1000);
            this.isLoading = false;

            // let index = this.data.findIndex(x => x.id === this.newData.id);
            // if( index !== -1 )
            //   this.data.splice(index, 1);

            this.reload();
          } else {
            this.isLoading = false;
            // Error
            console.log(data);
            this.$toast.open({
              message: data.error || this.$t("remove_error"),
              type: "is-danger",
              position: "is-top-right"
            });
          }
        }
      });
    },

    async changeRow() {
      this.isLoading = true;

      let params = {
        code: this.form.code,
        name: this.form.name,
        description: this.form.description,
        entity_type: this.entity_type,
      };

      let endpoint = 'issue-' + this.option.slice(0, -1) // issue-class | issue-discipline | issue-origin (removes 's' for singular)
      endpoint = (endpoint == 'issue-classe') ? 'issue-class' : endpoint; // corregir 'issue-classe' a 'issue-class'
      let url = "/api/v2/project/" + this.projectId + "/" + endpoint;
      axios({
        method: "post",
        url: url,
        data: params
      })
        .then(response => {
          this.$toast.open({
            message: this.$t("save_successful"),
            type: "is-success",
            position: "is-top-right"
          });

          this.create = false;
          this.form.code = "";
          this.form.name = "";
          this.form.description = "";
          this.reload();
        })
        .catch(error => {
          //Se produjo un error
          this.isLoading = false;
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("measurement_unit_change_fail"),
            type: "is-danger",
            position: "is-top-right"
          });
        });
    },

    canDelete(data) {
      return (
        (data.activities && data.activities.length > 0) ||
        (data.issues && data.issues.length > 0)
      );
    }
  }
};
</script>
