// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'

// Info de navegación en Administración
export const ADMIN_NAVIGATION_DATA = 'ADMIN_NAVIGATION_DATA'

// Info de la página actual
export const UPDATE_PAGE_INFO = 'UPDATE_PAGE_INFO'

// Proyecto seleccionado en el área de trabajo
export const PROJECT_SELECTED = 'PROJECT_SELECTED'

// Grupo seleccionado en el área de trabajo
export const GROUP_SELECTED = 'GROUP_SELECTED'

// Empresa seleccionada en el área de trabajo
export const COMPANY_SELECTED = 'COMPANY_SELECTED'

// Empresas que tiene aceso el user
export const SET_COMPANIES = 'SET_COMPANIES'

// Tags actualizadas
export const TAGS_SELECTED = 'TAGS_SELECTED'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'

// Tipos de devices
export const DEVICE_TYPE_WEB = 'Web'

// Configuración visual para actividades
export const VISUAL_CONFIG = 'VISUAL_CONFIG'

// Configuración para exportador pdf dashboard
export const DASHBOARD_REPORT = 'DASHBOARD_REPORT'

// Data que se esta importando
export const IMPORT_ENTITIES = 'IMPORT_ENTITIES'