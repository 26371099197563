<template>
  <div v-if="action">
    <div v-if="showDescription">
      <label class="font-bold">Acción masiva: {{ action.title }}</label>
      <p>Seleccione el grupo que se asignará a los flujos seleccionados.</p>
    </div>

    <div class="flex flex-row space-x-4">
      <div class="panel">

        <div class="text-sm w-full">

          <b-table
            icon-pack="fas"
            :data="groups"
            :paginated="isPaginated"
            :per-page="perPage"
            :bordered="true"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :default-sort-direction="defaultSortDirection"
            class="text-xs border-blue-lighter"
            :loading="isLoadingTable"

            :focusable="action.name != 'assign_group'"
            :selected.sync="selected"
            :is-row-selectable="(row) => { return action.name != 'assign_group' }"
            @select="handleSelect"
            :checkable="action.name == 'assign_group'"
            :checked-rows.sync="checkedRows"
            @check="handleCheck"
            @check-all="handleCheckAll"
          >
            <template>

              <b-table-column field="code" :label="$t('code')" sortable v-slot="props">
                {{ props.row.code }}
              </b-table-column>

              <b-table-column field="name" :label="$t('name')" sortable v-slot="props">
                {{ props.row.name }}
              </b-table-column>

            </template>

            <template slot="empty">No results</template>

          </b-table>
        </div>
      </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  props: {
    action: {type: Object, default: null },
    showDescription: {type: Boolean, default: true }
  },


  data: () => ({
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 20,
    isLoadingTable: false,
    groups: [],
    selected: null,
    checkedRows: [],
  }),

  computed: {
    ...mapGetters({
      company: 'app/company',
    }),
  },

  mounted () {
    this.getGroups();
  },

  watch:{
    action(newAction, oldAction) {
      this.checkedRows = []
      this.selected = null
    }
  },

  methods: {

    async getGroups(){
      const { data } = await axios.get('/api/v2/validations/templategroups', {'params' : { company_id: this.company.id }})
      if (data.groups) {
        this.groups = data.groups
      }
    },

    handleSelect(row, oldRow) {
      this.dispatchSelected([row])
    },

    handleCheck(checkedList, row) {
      this.dispatchSelected(checkedList)
    },

    handleCheckAll(checkedList) {
      this.dispatchSelected(checkedList)
    },
  
    dispatchSelected(checkedList) {
      this.$emit('selected-linked-entities', checkedList)
    }
  },

};
</script>
