<template>
  <div class="space-y-4">
    <div class="flex-grow">
      <label for="code" class="w-full">{{ $t('code') }}</label>
      <v-input
        v-model="item.code"
        title="code"
        id="code"
        name="code"
        placeholder="code"
        class="w-full"
        required
      />
    </div>

    <div class="flex-grow">
      <label for="description" class="w-full">{{ $t('description') }}</label>
      <v-input
        v-model="item.description"
        title="description"
        id="description"
        name="description"
        placeholder="description"
        class="w-full"
      />
    </div>

    <div class="flex-grow w-full">
      <label for="upload-file" class="w-full">Documento de pedido</label>

      <b-field class="file is-success">
        <b-upload
          v-model="item.file"
          :accept="accept"
          :expanded="true"
        >
          <a class="button is-primary is-fullwidth">
            <b-icon class="file-icon" icon="upload" pack="fas"></b-icon>
            <span>{{ item.file ? item.file.name : $t("upload_document") }}</span>
          </a>
        </b-upload>
      </b-field>
    </div>

  </div>
</template>

<script>

  export default {
    name: 'work-orders-form',

    components: {
    },

    props: {
      data: {type: Object, required: true},
    },

    data: function() {
      return {
        item: {
          id: this.data.id,
          code: this.data.code,
          description: this.data.description,
          file: null
        },

        accept: "", // on created()
      }
    },

    created() {
      this.accept = this.$acceptedExtensions // extensiones aceptadas
    },

    mounted() {
    },

    methods: {
      getDataForm() {
        return this.item
      }
    }
  };
</script>
