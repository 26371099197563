<template>
  <div class="page-with-top-bar">
    <project-template-admin-quick-buttons hiddenOption="edit" v-if="! createNew"/>

    <div class="panel relative">
      <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
      <div class="flex">
        <!-- Nombre -->
        <div class="w-1/3 pr-4">
          <l-form-input
            ref="projectName"
            :form="form"
            field="name"
            label="project_name"
            placeholder="project_name_placeholder"
          />
        </div>
        <!-- Referencia -->
        <div class="w-1/3 pr-4">
          <l-form-input
            :form="form"
            field="code"
            label="project_reference"
            placeholder="project_reference_placeholder"
            :maxlength="45"
          />
        </div>
      </div>

      <!-- Descripción -->
      <div class="flex items-center mt-8">
        <l-form-input
          :form="form"
          field="description"
          label="project_description"
          input-type="textarea"
          :maxlength="2000"
          class="w-full"
        />
      </div>

      <div class="w-full items-center" v-if="! createNew">
        <span class="label">{{ $t('project_tags') }}</span>
        <model-tags
          :model-id="templateId ? templateId : 0"
          model-type="project/templates"
          :allow-edit="false"
          :hooks-enabled="! createNew"
          ref="model_tags"
        />
      </div>
    </div>

    <form
      @submit.prevent="sendProjectData"
      @keydown="form.onKeydown($event)"
      class="w-full flex items-center justify-end mb-8"
    >
      <v-button :disabled="disableSaveButton" type="submit" icon="thumbs-up" icon-pack="far" class="mr-4">Guardar</v-button>
      <v-button color="grey" icon="undo-alt" @click="$router.go(-1)">Cancelar</v-button>
    </form>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import axios from "axios";
  import api from "~/api";
  import Form from "vform";
  import ProjectTemplateAdminQuickButtons from '·/components/project-template-admin-quick-buttons'
  import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
  import ModelTags from "../../components/model-tags";

  export default {
    middleware: "auth",
    title: "project_template_edit_page_title",
    // subtitle: "project_edit_page_subtitle",
    menuOption: "0-6",

    components: {
      ProjectTemplateAdminQuickButtons,
      ScaleLoader,
      ModelTags
    },

    data: () => ({
      isLoading: true,
      templateId: null,
      createNew: false,
      form: new Form({
        company_id: 0,
        currency: "€",
        code: "",
        name: "",
        reference: "",
        description: "",
        // config: null,
      }),

      apiUrl: "/api/v2/project/templates",
      disableSaveButton: false
    }),

    computed: {
      ...mapGetters({
        company: "app/company",
        project: "template/project"
      }),
    },

    created() {
      // Crear nuevo proyecto
      if (this.$route.name === "project-template.create") {
        this.createNew = true;
        this.$options.title = "project_create_template_page_title";
      } else {
        this.$options.subtitle = [
          "project_page_subtitle",
          [this.project.name.toUpperCase()]
        ];

        this.templateId = parseInt(this.$route.params.template_id);
      }
    },

    mounted() {
      if (this.templateId) {
        this.getTemplateData();
      }
      else
        this.isLoading = false;
    },


    methods: {

      async sendProjectData() {
        this.isLoading = true;
        this.disableButton();

        // Edit
        var url = this.apiUrl;
        if (!this.createNew) {
          url += "/" + this.templateId;
        }
        this.form.company_id = this.company.id
        // Enviamos el form
        const {data} = await this.form.post(url);

        if (data && data.success) {
          this.templateId = this.templateId ? this.templateId : data.template_id;

          // Añadimos las etiquetas si estamos creando proyectos
          // if (this.createNew) {
          //   this.$refs.model_tags.syncTagsToModel(this.templateId);
          // }

          // Actualizamos los datos del store si acabamos de modificar el proyecto que esté seleccionado
          // if (! this.createNew && project.id == this.templateId) {
          //   await this.$store.dispatch("template/setProject", { data.project });
          // }

          // Success y nos vamos
          this.$notify.success(
            this.createNew
              ? "success_creating_project"
              : "success_editing_project"
          );

          if( this.createNew ) {
            this.$router.push({ path: '/admin/project-template/' + this.templateId })
          }

          // this.$router.go(-1); // activar si se quiere volver atras despues de añadir la plantilla
        } else {
          this.$notify.error(
            this.createNew ? "error_creating_project" : "error_editing_project"
          );
        }

        this.isLoading = false;
      },

      async getTemplateData() {
        let params = {};
        const {data} = await axios.get("/api/v2/project/templates/" + this.templateId, {params: params});
        if (data) {

          this.$store.dispatch('template/setTags', data.tags)

          this.$refs.model_tags.initTags(data.tags);
          // this.form.currency = data.currency;
          this.form.company_id = data.company_id;
          this.form.name = data.name;
          this.form.code = data.code;
          this.form.description = data.description;
          // this.config = data.config;

        } else {
          this.$notify.error("error_loading_project_data");
        }

        this.isLoading = false;
      },

      disableButton() {
        this.disableSaveButton = true
        let self = this
        setTimeout(function() {
          self.disableSaveButton = false
        }, 5000);
      },
    }
  };
</script>
