<template>
    <div>
        <vue-tags-input
                v-model="tag"
                :tags="styledTags"
                placeholder=""
                :disabled="true"
                class="visualize-ti-input"
                :style="styledBgColor"
        >
            <div slot="tag-actions" slot-scope="props"></div>
        </vue-tags-input>
    </div>
</template>

<script>
  import VueTagsInput from '@johmun/vue-tags-input';

  export default {
    name: "visualize-tags",

    components: {
      VueTagsInput,
    },

    props: {
      tags: {type: Array, required: true},
      bgColor: {type: String, default: 'bg-white'}
    },

    computed: {
      styledTags: function () {
        return this.tags.map(function(tag) {
          return { text: tag.name, classes: 'text-xs', style: 'color: #385DA5; background-color: transparent; border: 1px solid #385DA5' }
        })
      },

      styledBgColor: function() {
        let color = this.bgColor === 'bg-white' ? 'white' : '#F1F5F8';
        return {'background-color': color}
      }
    },

    data: () => ({
        tag: ''
    }),

  }
</script>

<style scoped>
    .vue-tags-input {
        max-width: 100% !important;
    }
</style>

<style>
    /* Lo ponemos sin el scope porque dentro no lo reconoce */
    .visualize-ti-input div {
        border: 0 ! important;
    }
</style>