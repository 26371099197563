<template>
<div>
  <div class="flex mt-5">
    <!-- Columna Izquierda - Drawings -->
    <div :class="[( documentId || checkedRows.length > 0 ? 'w-8/12' : 'w-full'), 'mr-2']">
      <!-- Tabla de Drawings -->
      <div class="panel">
        <div v-if="isLoading" class="my-4 text-center">
          <scale-loader></scale-loader>
        </div>
        <div v-else>
          <div class="flex flex-wrap w-full mt-4">
          <section class="w-full">
            <b-table
              icon-pack="fas"
              :data="documents"
              :paginated="isPaginated"
              :per-page="perPage"
              :bordered="true"
              :current-page.sync="currentPage"
              :pagination-simple="isPaginationSimple"
              :default-sort-direction="defaultSortDirection"
              v-if="!isLoading"
              checkable
              checkbox-position="left"
              :checkbox-type="'is-success'"
              :checked-rows.sync="checkedRows">
              <template>
                <b-table-column field="thumbnail" cell-class="w-6 vertical-center" sortable>
                  <template v-slot:header="">{{ $t('thumbnail') }}</template>
                  <template v-slot="props">
                    <div class="flex flex-wrap justify-center">
                      <img
                        :src="props.row.file.thumbnail"
                        class="p-1 bg-white border rounded"
                        :alt="props.row.description"
                        onerror="this.src='https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/thumb.jpeg'"
                      />
                    </div>
                  </template>
                </b-table-column>

                <b-table-column field="code" cell-class="vertical-center" sortable>
                  <template v-slot:header="">{{ $t('code') }}</template>
                  <template v-slot="props">{{ props.row.code }}</template>
                </b-table-column>

                <b-table-column field="name" cell-class="vertical-center" sortable>
                  <template v-slot:header="">{{ $t('name') }} / {{ $t('description') }}</template>
                  <template v-slot="props">{{ props.row.name }}<br> {{ props.row.description }}</template>
                </b-table-column>

                <b-table-column field="file" cell-class="w-6 vertical-center" centered>
                  <template v-slot:header="">{{ $t('file') }}</template>
                  <template v-slot="props">
                    <b-tooltip :label="$t('download_document')" position="is-top">
                      <div class="cursor-pointer text-blue-light">
                          <a class="button is-rounded is-link" @click="downloadDoc(props.row.document_id)">
                              <span class="icon">
                                <i class="fas fa-file"></i>
                              </span>
                          </a>
                      </div>
                    </b-tooltip>
                  </template>
                </b-table-column>

                <b-table-column field="actions" cell-class="vertical-center" width=100 sortable>
                  <template v-slot:header="">{{ $t('actions') }}</template>
                  <template v-slot="props">
                    <div class="flex items-center justify-around w-full h-full">

                      <b-tooltip :label="'editar'" position="is-right" v-if="withEditor && showPdfEditor">
                        <div class="w-8 h-8 overflow-hidden" @click="openEditor(props.row)">
                          <b-icon pack="fas" icon="pen" />
                        </div>
                      </b-tooltip>

                      <b-tooltip :label="'scale'" position="is-right" v-if="documentType === 'drawings'">
                        <div class="w-8 h-8 overflow-hidden" @click="openScale(props.row)">
                          <b-icon pack="fas" icon="ruler-combined" />
                        </div>
                      </b-tooltip>

                      <b-tooltip :label="$t('delete')" position="is-left" type="is-danger">
                          <div @click="removeDocument(props.row)"
                              class="flex cursor-pointer text-red">
                            <b-icon pack="fas" icon="trash-alt"/>
                          </div>
                      </b-tooltip>

                      <b-tooltip :label="$t('view_document')" position="is-left" v-if="props.row.mime_type === 'application/pdf'">
                          <!-- <div @click="onRowClicked(props.row)"
                              class="flex cursor-pointer text-sky-300">
                            <b-icon pack="fas" icon="eye"/>
                          </div> -->
                          <div @click="onShowViewerPdfClicked(props.row)"
                              class="flex cursor-pointer text-sky-300">
                            <b-icon pack="fas" icon="eye"/>
                          </div>
                      </b-tooltip>

                      <b-tooltip :label="$t('view_assignments')" position="is-left">
                          <div @click="assignedActivities(props.row)"
                              class="flex cursor-pointer text-sky-300">
                            <b-icon pack="fas" icon="link"/>
                          </div>
                      </b-tooltip>

                    </div>
                  </template>
                </b-table-column>
              </template>

              <template slot="bottom-left">
                  <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                  <b>{{ $t("total") }}</b>: {{ this.documents.length }}
              </template>

              <template #empty>
                  <div class="has-text-centered">{{ $t('no_results') }}</div>
              </template>

            </b-table>
          </section>
          </div>
        </div>
      </div>
    </div>

    <!-- Columna derecha - Values -->
    <div class="w-4/12 ml-2" v-if="showPanelValues">
      <!-- Elements Attribute Value -->
      <div class="panel">

        <div v-if="loaded" class="my-4 text-center">
          <scale-loader></scale-loader>
        </div>

        <div v-if="documentId && !loaded">
          <div class="flex items-center justify-between w-full mb-5">
            <div><label><b>{{ $t('values') }} <span v-if="documentId && form.name"> - {{ $t('document') + ' ' + form.name }}</span></b></label></div>
            <div>
              <b-tooltip :label="$t('close')" position="is-left">
                <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
                  <b-icon pack="fas" icon="times" size="is-small" />
                </div>
              </b-tooltip>
            </div>
          </div>

          <!-- recuadro de valores -->
          <div class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-blue-lightest mb-1"
            v-for="(value, taskKey) in form.values" :key="value.id">

            <div class="flex items-center">
              <!-- delete -->
              <b-tooltip :label="$t('unlink')" position="is-left" type="is-danger">
                  <div @click="deleteValue(value.id, value.name)" class="flex cursor-pointer text-red">
                    <b-icon pack="fas" icon="unlink"/>
                  </div>
              </b-tooltip>
              <!-- save -->
              <b-tooltip v-if="valuesInEdition.includes(value.id)" :label="$t('save')" position="is-left">
                  <div @click="sendValueData(value.id, taskKey)" class="flex cursor-pointer text-blue">
                    <b-icon pack="fas" icon="download"/>
                  </div>
              </b-tooltip>
              <!-- edit -->
              <b-tooltip v-else :label="$t('edit')" position="is-left">
                  <div @click="editValue(value.id)" class="flex cursor-pointer text-blue">
                    <b-icon pack="fas" icon="pencil-alt"/>
                  </div>
              </b-tooltip>

              <span v-if="valuesInEdition.includes(value.id)" class="inline-flex ml-2">
                <input v-model="form.values[taskKey].code" type="text" class="mr-2 input">
                <input v-model="form.values[taskKey].name" type="text" class="mr-2 input">
              </span>
              <span v-else class="ml-2">
                <span v-if="value.code">{{ value.code }} - </span>
                {{ value.name }}
              </span>
            </div>
          </div>
          <div v-if="form.values.length == 0" class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-gray-200 mb-1 has-text-centered">{{ $t('no_results') }}</div>
          <!-- recuadro añadir values -->
          <div class="w-full border border-blue-lighter rounded flex items-center p-2 bg-blue-lightest mb-1" v-if="form.field_type == 0">

            <b-tooltip :label="$t('add_generic')" position="is-left">
                <button class="btn btn-green items-center mr-2"  type="button" @click="saveValue()">
                  <i class="fas fa-plus"></i>
                </button>
            </b-tooltip>

            <!-- <b-tooltip :label="$t('name')" position="is-left"> -->
              <span class="inline-flex ml-2">
                <input v-model="newValue.code" type="text" :placeholder="$t('code')" class="input mr-2">
                <input v-model="newValue.name" type="text" :placeholder="$t('name')" class="input mr-2">
              </span>
            <!-- </b-tooltip> -->
          </div>
        </div>

      </div>
    </div>

    <div v-if="showPanelLink" class="w-4/12 ml-2">
      <div class="panel p-2">
        <div class="flex items-center justify-between w-full mb-5">
          <div><label><b>Cantidad de documentos a vincular: {{ this.checkedRows.length }}</b></label></div>
          <div>
            <b-tooltip :label="$t('close')" position="is-left">
              <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
                <b-icon pack="fas" icon="times" size="is-small" />
              </div>
            </b-tooltip>
          </div>
        </div>
        <div class="panel">

          <div><label><b>{{ $t('select_dso') }}</b></label></div>
          <div class="w-full inline-flex pb-3">
            <b-select ref="input3"
              v-model="activity"
              :placeholder="$t('select_dso')"
              icon="clipboard-list"
              icon-pack="fas"
              expanded
            >
              <optgroup label="DSOs">
                <option v-for="(option, indexActivities) in activities"
                v-bind:value="{ id: option['id'], name: option['name'], type: 'activity', folders: option['folders'] }"
                :key="'activity_'+ indexActivities">
                  <span v-if="option['is_subactivity']">&nbsp;&nbsp;</span>
                  {{ option['name'] }}
                </option>
              </optgroup>
            </b-select>
          </div>
           <div><label><b>{{ $t('select_subactivity') }} ({{$t('optional')}})</b></label></div>
          <div class="w-full pb-3">
            <b-select ref="input6"
              v-model="subactivity"
              :placeholder="$t('select_subactivity')"
              icon="clipboard-list"
              icon-pack="fas"
              expanded
            >
              <option v-for="(option, indexsubactivities) in subactivities"
              v-bind:value="{ id: option['id'], name: option['name'], type: 'activity'}"
              :key="'subactivity_'+ indexsubactivities">
                {{ option['name'] }}
              </option>
            </b-select>
          </div>
          <div class="flex items-center justify-end w-full">
            <v-button v-if="!processingData"
              @click="linkDocuments('activity')"
              icon="plus"
            >{{ $t('link_documents') }}</v-button>

            <button v-if="processingData" type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-blue hover:bg-blue-light transition ease-in-out duration-150 cursor-not-allowed" disabled="">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {{ $t('link_documents') }}
            </button>
          </div>
        </div>

        <div class="panel">

          <div><label><b>{{ $t('select_v_flow') }}</b></label></div>
          <div class="w-full pb-3">
            <b-select ref="input4"
              v-model="validation"
              :placeholder="$t('select_v_flow')"
              icon="clipboard-list"
              icon-pack="fas"
              expanded
            >
              <option v-for="(option, indexValidations) in validations"
              v-bind:value="{ id: option['id'], name: option['name'], type: 'validation-template'}"
              :key="'validation_'+ indexValidations">
                {{ option['name'] }}
              </option>
            </b-select>
          </div>

          <div class="flex items-center justify-end w-full">
            <v-button v-if="!processingData"
              @click="linkDocuments('validation')"
              icon="plus"
            >{{ $t('link_documents') }}</v-button>

            <button v-if="processingData" type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-blue hover:bg-blue-light transition ease-in-out duration-150 cursor-not-allowed" disabled="">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {{ $t('link_documents') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showPanelLinked" class="w-4/12 ml-2">
      <div class="panel">
        <div v-if="loadingAssigned" class="my-4 text-center">
          <scale-loader></scale-loader>
        </div>

        <div v-if="documentId && !loadingAssigned">
          <div class="flex items-center justify-between w-full mb-5">
            <div><label><b><span v-if="documentId && form.name">{{ $t('document') + ' ' + form.name }} - </span> {{ $t('assigned_to') }}</b></label></div>
            <div>
              <b-tooltip :label="$t('close')" position="is-left">
                <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
                  <b-icon pack="fas" icon="times" size="is-small" />
                </div>
              </b-tooltip>
            </div>
          </div>

          <!-- recuadro de valores (documentSelectables) -->
          <div class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-blue-lightest mb-1"
            v-for="(value) in form.entities_selectables" :key="value.id">
            <div>
              <!-- delete -->
              <b-tooltip :label="$t('unlink')" position="is-left" type="is-danger">
                  <div v-if="value.documentable_type.includes('ValidationFlowTemplate')" @click="unlinkDocuments(form.id, value.documentable.id, form.name, value.documentable.name, 'validation-template')" class="flex cursor-pointer text-red">
                    <b-icon pack="fas" icon="unlink"/>
                  </div>
                  <div v-else-if="value.documentable_type.includes('ValidationTask')" @click="unlinkDocuments(form.id, value.documentable.id, form.name, value.documentable.name, 'validation-task')" class="flex cursor-pointer text-red">
                    <b-icon pack="fas" icon="unlink"/>
                  </div>
                  <div v-else-if="value.documentable_type.includes('Activity')" @click="unlinkDocuments(form.id, value.documentable.id, form.name, value.documentable.name, 'activity')" class="flex cursor-pointer text-red">
                    <b-icon pack="fas" icon="unlink"/>
                  </div>
              </b-tooltip>

              <span v-if="value.documentable_type.includes('ValidationFlowTemplate')" class="border rounded text-sm px-2 text-white bg-blue border-blue" >{{$t('flow')}}</span>
              <span v-else-if="value.documentable_type.includes('ValidationTask')" class="border rounded text-sm px-2 text-white bg-red border-red">{{$t('task')}}</span>
              <span v-else-if="value.documentable_type.includes('Activity') && !value.documentable.parent_id" class="border rounded text-sm px-2 text-white bg-green border-green">{{$t('activity')}}</span>
              <span v-else-if="value.documentable_type.includes('Activity') && value.documentable.parent_id" class="border rounded text-sm px-2 text-white bg-orange border-orange">{{$t('subactivity')}}</span>

              <!-- save -->
              <span class="ml-2">{{ value.documentable.name }}</span>
            </div>
          </div>

          <div v-if="form.entities_selectables.length == 0" class="w-full border border-blue-lighter rounded flex-col items-center p-2 bg-gray-200 mb-1 has-text-centered">{{ $t('no_results') }}</div>


          <div class="flex items-center justify-end w-full mt-5" v-if="form.entities_selectables.length > 0 ">
            <button v-if="!processingData"
              type="button"
              class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-orange hover:bg-orange-light transition ease-in-out duration-150"
              @click="unlinkDocuments(form.id, -1, form.name, 'all')"
              >
              <i class="fas fa-unlink mr-3"></i>
              {{ $t('unlink_documents') }}
            </button>

            <button v-if="processingData" type="button" class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-orange hover:bg-orange-light transition ease-in-out duration-150 cursor-not-allowed" disabled="">
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {{ $t('unlink_documents') }}
            </button>
          </div>
        </div>

      </div>
    </div>

  </div>

  <viewer-pdf ref="modalViewerPdf" :fileName="fileName"></viewer-pdf>

  <!-- Modal para EDITAR PDF -->
  <entity-document-editor v-if="withEditor"
      :ref="'modal' + refModalName"
      :entity-id="projectId"
      :entity-type="'project'"
      :enableMeasurement="documentType === 'drawings'"
      :enableBtnSave="true"
      :nameEditorRef="refModalName"
      @reload-documents-list="() => $emit('reload-documents-list')"
  />

  <measure-scale-modal
    ref="measure_scale_modal"
    :project-id="projectId"
    @reload="() => getProjectDocuments()">

  </measure-scale-modal>

</div>
</template>

<script>
import Form from "vform";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import ViewerPdf from "./viewer-pdf.vue";
import axios from "axios";
import { mapGetters } from 'vuex';
import entityDocumentEditor from "·/components/entity-document-editor";
import MeasureScaleModal from "../components/assignments/measure-scale-modal.vue"

export default {
  name: "project-documents-manual",

  components: {
    ViewerPdf,
    ScaleLoader,
    entityDocumentEditor,
    MeasureScaleModal
  },

  props: {
    projectId: { type: Number, required: true },
    documentType: { type: String, required: true },
    withEditor: { type: Boolean, default: true }, // show pdfEditor (if company has permission)
    refModalName: { type: String, default: 'Editor' },
  },

  data: () => ({
    isLoading: true,
    page: 1,
    perPage: 25,
    total: 0,
    orderField: "reference_code",
    orderDir: "asc",
    currentPage: 1,
    defaultSortDirection: 'asc',
    isPaginationSimple: false,
    isPaginated: true,

    documents: [],
    checkedRows: [],

    activities: [],
    activity: {},
    validations: [],
    validation: {},
    subactivity: {},
    subactivities: [],

    // values
    documentId: null,
    form: new Form({
      code: '',
      name: '',
      values: [],
    }),

    newValue: new Form({
      code: '',
      name: '',
    }),

    valuesInEdition: [], // valor que estan en modo edicion. Editor inline
    disableSaveButton: false,
    loaded: false,
    loadingAssigned: false,
    processingData: false,

    showPanel: 'none',

    fileName: '',
    errorFields: {},

    showPdfEditor: false, // determines if company has this module available
  }),


  watch: {
    // Cargar subactividades, cuando seleccione actividad
    // evento @click de b-select no funciona en chrome, detectamos el cambio por watch
    activity: function() {
      if (this.activity) {
        this.getSubactivities(this.activity.id);
      }
    },
  },

  mounted() {
    this.getProjectDocuments();
    this.getActivities();
    this.getValidations();
    this.path = window.location.origin + '/' + this.path;
    this.errorFields = {
      activity: 'DSO',
      validation: this.$t('validation_flow'),
      validationTask: this.$t('task')
    };


    // Check module pdfEditor availability for this company
    if (this.company.configs
      && this.company.configs.modules
      && this.company.configs.modules.includes('pdfEditor')) {
        this.showPdfEditor = true;
    } else {
        this.showPdfEditor = true; // always true for now...
    }
  },

  computed: {
    ...mapGetters({
      company: 'app/company',
    }),
    showPanelValues: function() {
      return this.documentId && this.showPanel === 'values' && this.checkedRows.length == 0;
    },
    showPanelLink: function() {
      if( this.checkedRows.length > 0 )
        this.documentId = null;
      return !this.documentId && this.checkedRows.length > 0 && this.projectId > 0;
    },
    showPanelLinked: function() {
      return this.documentId && this.showPanel === 'assigned' && this.checkedRows.length == 0;
    },
  },

  methods: {
    async getProjectDocuments() {
      this.isLoading = true;
      let params = {
        text: '',
        with: this.documentType
      };

      const { data } = await axios.get(
        "/api/v2/project/" + this.projectId + "/documents",
        { params: params }
      );

      if (data && data.success) {
        this.documents = data.documents;
        this.total = data.documents ? data.documents.length : 0;
      } else {
        this.$notify.error("error_loading_documents");
      }
      this.isLoading = false;
    },

    async getActivities(){
      const { data } = await axios.get("/api/v2/activities", {'params' : { p : this.projectId, with_folders: 1, per_page: 999}});

      this.activities = data.activities;
      // para activar la actividad actual en la que se encuentra el flujo
      if(this.projectId > 0){
        let self = this;
        this.activities.forEach(item => {
          if( self.entityId == item.id ){
            self.activity = { id: item.id, name: item.name, type: 'activity', folders: item.folders }
          }
        });
      }
    },

    async getValidations() {
      const { data } = await axios.get("/api/v2/validations/templates", {'params': { company_id: this.company.id }});

      this.validations = data.templates;
    },

    async getSubactivities(activityId) {
      this.subactivity = {}
      const { data } = await axios.get('/api/v2/activity/' + activityId + '/subactivities', {params : {}});
      this.subactivities = data.subactivities || [];
    },

    reload() {
      this.getProjectDocuments();
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    onPageChange(page) {
      this.page = page;
      this.reload();
    },

    onSort(field, order) {
      this.orderField = field;
      this.orderDir = order;
      this.reload();
    },

    async removeDocument(field) {
      this.$dialog.confirm({
        title: this.$t("delete"),
        message: this.$t("delete_generic", [field.name]),
        confirmText: this.$t("delete"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          this.documentId = null

          this.isLoading = true;
          let url = "/api/v2/project/" + this.projectId + '/document/' + field.document_id;
          const { data } = await axios.post(url);

          if (data.success) {
            this.$toast.open({
              message: this.$t("success_deleting_generic"),
              type: "is-success",
              position: "is-top-right"
            });
            await this.sleep(1000);
            this.reload();
          } else {
            this.isLoading = false;
            // Error
            this.$toast.open({
              message: data.error || this.$t("error_saving"),
              type: "is-danger",
              position: "is-top-right"
            });
          }
        }
      });
    },

    onCloseClicked() {
        this.documentId = null;
        this.checkedRows = [];
    },

    async readDocument(params) {

      if(this.documentId){

        if( this.showPanel === 'assigned' )
          this.loadingAssigned = true;
        else
          this.loaded = true;

        const { data } = await axios.get(
          "/api/v2/document/" + this.documentId + '/selectable-list',
          { params: params }
        );

        if (data && data.success) {
          this.form = data.document;
        } else {
          this.$notify.error("error_retrieving_data");
        }

        if( this.showPanel === 'assigned' )
          this.loadingAssigned = false;
        else
          this.loaded = false;
      }

    },

    onRowClicked(field, access = true) {
      if (access) {
        this.documentId = field.document_id;
        this.showPanel = 'values';
        this.readDocument( { } );
      }
    },

    async assignedActivities(field) {
      this.documentId = field.document_id;
      this.showPanel = 'assigned';
      this.readDocument( { with: 'assigned' } );
    },

    // value
    // redirige a pantalla edicion
    editValue(valueId) {
      this.valuesInEdition.push(valueId) // edicion inline
    },

    async linkDocuments(model) {
      if (model == 'activity' && Object.keys(this.subactivity).length > 0) model = 'subactivity'
      if (!this[model].id) {
        this.$notify.error(this.$t('empty_field_required', [this.errorFields[model]]))
        return
      }

      if( this.checkedRows.length == 0){
        this.$notify.error(this.$t('error_select_document'))
        return
      }

      this.processingData = true;
      let fields = [];
      this.checkedRows.forEach(field => {
        fields.push(field.document_id);
      });

      let self = this;

      await axios.post("/api/v2/document/assign", {
        entityId: this[model].id,
        entityType: this[model].type,
        fields: fields
      })
      .then(response => {
        let data = response.data
        this.processingData = false;

        if (data && data.success) {
          // reload
          self[model] = {};
          self.checkedRows = [];

          self.$notify.success('success_editing')

        } else if(data && data.msg) {
          self.$notify.error('no_access_permissions')
        } else {
          self.$notify.error('error_saving')
        }
      })
      .catch(error => {
          self.processingData = false;
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
                ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("assignment_insert_error"),
            type: "is-danger",
            position: "is-top-right"
          });
      });
    },

    async unlinkDocuments(documentId, valueId, fieldName, valueName, entityType) {
      let params = { };
      params.entityId = valueId;
      params.entityType = entityType;
      valueName = valueName === 'all' ? 'todas las asignaciones' : valueName;
      let self = this;
      this.$dialog.confirm({
        title: this.$t('unlink'),
        message: this.$t('unassign_question_document', [fieldName, valueName]),
        confirmText: this.$t('unlink'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {

          const { data } = await axios.post("/api/v2/document/" + documentId + "/unassign", params)
          if (data && data.success) {

            // reload
            this.readDocument( { with: 'assigned' } )
            this.$notify.success('success_deleting_generic')

          } else if(data && data.msg){

            this.$notify.error('no_access_permissions')

          } else {
            this.$notify.error('error_saving')
          }
        }
      })
    },

    async downloadDoc(document_id) {
      this.$notify.success("starting_download_document");
      let url = "/api/v2/document/" + document_id;

      const {data} = await axios.get(url);

      if (data) {
        window.open(data.data);
      } else {
        this.$notify.error("error_download_document");
      }
    },

    onShowViewerPdfClicked(document) {
      // adds timestamp to url to avoid showing cached previous versions
      let fileNameWithTimestamp = document.file.raw.includes('?')
        ? document.file.raw + '&' + Date.now()
        : document.file.raw + '?' + Date.now()
      this.fileName = fileNameWithTimestamp
      this.$refs.modalViewerPdf.show();
      this.$refs.modalViewerPdf.getPdf();
    },

    openEditor(document) {
      this.$refs['modal' + this.refModalName].show(document)
    },

    openScale(document) {
      this.$refs.measure_scale_modal.show(document)
    }

  }
};
</script>

<style scoped>
  .b-table::v-deep .table .checkbox-cell {
    vertical-align: middle !important;
    text-align: center;
  }

  .b-table::v-deep .table .checkbox-cell .checkbox {
    background-color: white !important;
    padding: 0;
    margin: 0;
    border-radius: 5px;
    width: 20px;
  }

  .b-checkbox.checkbox:not(.button) {
    margin-right: 0 !important;
  }
</style>
