<template>
  <!-- Filtros -->
  <div>
    <div class="w-full inline-flex">
      <div class="w-1/6 mr-4">
        <livemap-filters @filters-updated="filtersUpdated" ref="filtersRef"/>
      </div>
      <div class="w-5/6">
        <div class="panel">
          <GMapLoader :mapConfig="mapConfig">
            <template slot-scope="{ google, map }">
              <GMarker
                v-for="marker in markers"
                :key="marker.id"
                :marker="marker"
                :google="google"
                :map="map"
              />
            </template>
          </GMapLoader>
        </div>

        <div v-if="isTableVisible" class="panel">
          <div id="page-title" class="mb-2">
            <h1 class="m-0 p-0 text-xl font-raleway font-bold uppercase text-blue-light ">Exposición SARS-CoV-2</h1>
            <p class="font-raleway m-0 font-bold text-grey-dark text-sm">Exposición de {{ userSelected }} con otros
              trabajadores.</p>
          </div>

          <b-table v-if="rows" :data="rows" :isNarrowed="true" :loading="isLoadingTable" :selected.sync="selected">

            <template slot-scope="props">


              <b-table-column field="timestamp" label="Date" centered sortable="">
                <span class="tag is-success">
                    {{ new Date(props.row.timestamp).toLocaleString() }}
                </span>
              </b-table-column>

              <b-table-column field="user.fullname" :label="$t('name')">
                <img :src="props.row.user.avatar" width="24">

                {{ props.row.user.fullname }}
              </b-table-column>

              <b-table-column field="user.phone" :label="$t('phone')">
                {{ props.row.user.phone }}
              </b-table-column>

              <b-table-column field="user.email" :label="$t('email')">
                {{ props.row.user.email }}
              </b-table-column>

              <b-table-column field="coords" :label="$t('coordinate')">
                {{ props.row.coords.latitude }} {{ props.row.coords.longitude }}
              </b-table-column>

            </template>


          </b-table>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
import axios from "axios";
import GMapLoader from "~/components/Map/GMapLoader.vue";
import GMarker from "~/components/Map/GMarker.vue";
import LivemapFilters from "./livemap-filters";
import EventBus from "~/plugins/bus";

export default {
  name: "LiveMap",
  middleware: "auth",
  title: "livemap_page_title",
  subtitle: "livemap_page_subtitle",
  menuOption: "0-4",
  components: {
    LivemapFilters,
    GMapLoader,
    GMarker
  },

  data: function () {
    return {
      args: null,
      center: {lat: 36.68631, lng: -6.14958},
      markers: null,
      isLoadingTable: false,
      isTableVisible: false,
      userSelected: "",
      rows: null,
      selected: null
    };
  },

  watch: {
    // whenever question changes, this function will run
    selected: function (newSelected, oldSelected) {

      EventBus.$emit('setMarker', newSelected);

    }
  },

  async mounted() {

    let self = this;
    EventBus.$on('polygoncomplete', function (polygon) {

      var bounds = [];
      for (var i = 0; i < polygon.length; i++) {
        var point = {
          lat: polygon.getAt(i).lat(),
          lng: polygon.getAt(i).lng()
        };
        bounds.push(point);
      }

      self.loadExposureTable(bounds);

    });

  },

  beforeDestroy(){
    EventBus.$off("polygoncomplete");
  },

  methods: {
    async loadMarkers(args) {

      // Emitimos por bus
      EventBus.$emit('remove-marker');
      //vaciamos los marcadores
      this.markers=[];
      this.isTableVisible = false;
      this.rows = null;


      // Obtenemos las notificaciones sin leer del usuario
      if (args.users) {
        await axios
          .get("/api/v2/user/points", {params: args})
          .then(response => {
            this.markers = response.data.data
            EventBus.$emit('center-map', this.markers);
          })
          .catch(error => {
            EventBus.$emit('center-map', this.markers);
            this.$notify.error('error_loading_user_points');
            EventBus.$emit('center-map', this.markers);
          });
      }
    },

    async loadExposureTable(bounds) {


      // Mostramos la tabla
      this.isTableVisible = true;
      this.isLoadingTable = true;
      // Bounds
      this.args.bounds = bounds;

      // Obtenemos las notificaciones sin leer del usuario
      await axios
        .get("/api/v2/user/exposure", {params: this.args})
        .then(response => (this.rows = response.data.data))
        .catch(error => {
          this.$notify.error('error_loading_user_exposure');
        });

      this.isLoadingTable = false;


    },

    customizeFilters() {
      return this.$refs.filtersRef.generateFilters();
    },

    applyFilters() {
      let args = this.customizeFilters();
      this.args = args;
      if (args.userName) {
        this.userSelected = args.userName["basic"]["fullname"];
      }
      this.loadMarkers(args)
    },

    filtersUpdated() {
      this.isLoadingTable = true;
      this.applyFilters();
      this.isLoadingTable = false
    },

  },

  created() {
    //this.loadMarkers();
  },

  computed: {
    mapConfig() {
      return {
        center: this.mapCenter,
        zoom: 19
      };
    },
    mapCenter() {
      return this.center
    }
  }
};
</script>
