<template>
  <section :class="{'inline-block': !openModalWith}">
    <span v-if="openModalWith === 'button-span'"
      class="p-2 cursor-pointer flex items-center"
      style="background: #57b2; color: #358"
      onmouseover="this.style.background='#57b3'"
      onmouseout="this.style.background='#57b2'"
      @click="show()">
      <span class="hidden lg:inline">{{ $t(textButton) }}</span> <b-icon pack="fas" icon="project-diagram" />
    </span>

    <span v-else-if ="openModalWith === 'button-icon'" class="px-1 w-8 mt-2 cursor-pointer hover:text-gray-700"
      @click="show()">
      <b-tooltip :label="$t(textButton)" position="is-left">
        <i class="fas w-8 fa-project-diagram p-2 bg-gray-200 rounded-full"></i>
      </b-tooltip>
    </span>

    <div v-else  class="mr-2 rounded-sm" @click="show()">
      <v-button icon="project-diagram" class="mr-4"><span class="hidden lg:inline">{{ $t(this.textButton) }}</span></v-button>
    </div>

    <b-modal :active.sync="isModalActive" has-modal-card @after-enter="getActivityFolders">
      <div class="w-1/2 modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('validations') }}</p>
        </header>
        <section class="modal-card-body">
          <!-- Cuerpo del Modal-->
          <div class="flex flex-col">

            <div class="mt-3 mb-3" v-if="!canCreateFlow && !validationFlowId">
              <div class="flex items-center bg-blue-light text-white text-sm font-bold px-4 py-3 rounded" role="alert">
                <svg class="fill-current w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/>
                </svg>
                <p>{{ $t('user_cannot_create_flows') }}</p>
              </div>
            </div>


            <div class="flex" v-if="entityType!='issue'">
              <b-field :label="$t('select_type')" class="w-full">
                <b-select
                  v-model="selectedFlowType"
                  :placeholder="$t('select_type')"
                  icon="clipboard-list"
                  icon-pack="fas"
                  expanded
                  :disabled="validationFlowId > 0"
                >
                  <option v-for="(type, index) in templateTypes" :value="index" :key="index">
                    {{ type }}
                  </option>
                </b-select>
              </b-field>

            </div>

            <div class="mt-4 bg-grey-lighter p-2 rounded" v-if="showTechnicalSpecifications">
              <div class="flex">
                <b-field :label="$t('select_scope')" class="w-1/2">
                  <b-select
                    v-model="selectedScope"
                    :placeholder="$t('select_scope')"
                    icon="clipboard-list"
                    icon-pack="fas"
                    expanded
                  >
                    <option :value="null">{{ $t('all') }}</option>
                    <option v-for="(scope, index) in scopeList" :value="scope" :key="index">
                      {{ scope }}
                    </option>
                  </b-select>
                </b-field>
                <b-field :label="$t('select_technical_specification_type')" class="w-1/2 pl-2">
                  <b-select
                    v-model="technicalSpecificationTypeId"
                    :placeholder="$t('select_technical_specification_type')"
                    icon="cogs"
                    icon-pack="fas"
                    expanded
                  >
                    <option :value="null">{{ $t('all') }}</option>
                    <option v-for="option in technicalSpecificationTypes" :value="option['id']" :key="'type_'+ option['id']">
                      <b>{{ option['code'] }}</b> - {{ option['name'] }}
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div class="flex mt-2">
                <b-field :label="$t('select_technical_specification')" class="w-full">
                  <b-select
                    v-model="technicalSpecificationId"
                    :placeholder="$t('select_technical_specification')"
                    icon="cogs"
                    icon-pack="fas"
                    expanded
                  >
                    <option v-for="option in technicalSpecifications" :value="option['id']" :key="'tech_spec_'+ option['id']">
                      <b>{{ option['code'] }}</b> - {{ option['name'] }} ({{ option['scope']}})
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>

            <div class="mt-4  p-2 rounded" v-if="entityType=='issue'">
              <div class="flex">
                <b-field :label="$t('issue')" class="w-full">
                  <b-input
                    v-model="issueName"
                    :placeholder="$t('issue')"
                    icon="clipboard-list"
                    icon-pack="fas"
                    expanded
                    readonly
                  ></b-input>
                </b-field>
              </div>
            </div>

            <!-- change parent -->
            <div v-if="validationFlowId">
              <b-field :label="validationTemplateName"></b-field>
            </div>
            <!-- create flow -->
            <div v-else class="flex flex-col mt-4">
              <label for="" class="font-bold mb-2 label">{{ $t('select_v_flow') }}
                <span v-if="isLoadingTemplates" class="font-normal text-blue-light"> <b-icon icon="spinner" class="animate-spin" pack="fas"></b-icon> {{ $t('loading') }}... </span></label>
              <!-- <b-field :label="$t('select_v_flow')"> -->
                <b-select ref="input1"
                          v-model="templateId"
                          :placeholder="$t('select_validation_flow')"
                          icon="project-diagram"
                          icon-pack="fas"
                          expanded
                          :disabled="validationFlowId > 0"
                          :loading="isLoadingTemplates"
                >

                  <option v-for="(option, indexTemplates) in templates"
                          :value="option['id']" :key="'template_'+ indexTemplates"
                  >
                    {{ option['name'] }}
                  </option>
                </b-select>
              <!-- </b-field> -->
              <label v-if="inputTemplateIsRequired" class="text-red text-xs">{{ $t('required_field') }}</label>
            </div>

            <!-- Ya no permitimos elegir proyecto, ya se trabaja sobre el proyecto actual.
              Y si no se elige actividad, pues se asigna el flujo al proyecto
              <div v-if="!this.entityId" class="flex flex-col mt-4">
              <b-field :label="$t('select_project')">
                <b-select ref="input2"
                          v-model="entity"
                          :placeholder="$t('select_project')"
                          icon="clipboard-list"
                          icon-pack="fas"
                          expanded
                >

                  <optgroup :label="$t('projects')">

                    <option v-for="(option, indexProjects) in projects"
                      v-bind:value="{ id: option['id'], name: option['name'], type: 'project' }"
                      :key="'project_'+ indexProjects">
                      {{ option['name'] }}
                    </option>

                  </optgroup>

                </b-select>
              </b-field>
            </div> -->

            <div v-if="!this.entityId && selectedFlowType != 2" class="flex flex-col mt-4">
              <b-field :label="$t('select_activity')">
                <b-select ref="inputActivity"
                          v-model="activity"
                          :placeholder="$t('select_activity')"
                          icon="clipboard-list"
                          icon-pack="fas"
                          expanded
                >

                  <optgroup :label="$t('activities')">

                    <option v-for="(option, indexActivities) in activities"
                    v-bind:value="{ id: option['id'], name: option['name'], type: 'activity', folders: option['folders'] }"
                    :key="'activity_'+ indexActivities">
                      <span v-if="option['is_subactivity']">&nbsp;&nbsp;</span>
                      {{ option['name'] }}
                    </option>

                  </optgroup>

                </b-select>
              </b-field>
              <label v-if="inputActivityIsRequired" class="text-red text-xs">{{ $t('required_field') }}</label>
            </div>


            <div v-if="!this.entityId && selectedFlowType != 2" class="flex flex-col mt-4">
              <b-field :label="$t('select_subactivity') + ' (' + $t('optional') + ')'">
                <b-select ref="input3"
                          v-model="subActivity"
                          :placeholder="$t('select_subactivity')"
                          icon="clipboard-list"
                          icon-pack="fas"
                          expanded
                >

                  <optgroup :label="$t('subactivities')">

                    <option v-for="(option, indexActivities) in subActivities"
                    v-bind:value="{ id: option['id'], name: option['name'], type: 'activity', folders: option['folders'] }"
                    :key="'subactivity_'+ indexActivities">
                      {{ option['name'] }}
                    </option>

                  </optgroup>

                </b-select>
              </b-field>
            </div>


            <div class="flex flex-col mt-4" v-if="selectedFlowType != 2">
              <b-field :label="$t('select_folder') + ' (' + $t('optional') + ')'"></b-field>
              <treeselect v-model="folderId" :close-on-select="true" :options="folderTree" />
            </div>

            <div class="flex flex-col mt-4">
              <b-field :label="$t('description')" class="w-full">
                <b-input
                  :placeholder="$t('description')"
                  v-model="description"
                  maxlength="250"
                  expanded
                ></b-input>
              </b-field>
            </div>

          </div>
          <div v-if="selectedFlowType != 2">
            <b-checkbox v-model="configTasks" :disabled="!templateId || isLoading" class="mb-2 text-left w-full text-black">
              {{ $t('associate_tasks_to_activities') }}
            </b-checkbox>
            <button class="button"
              :class="tasksConfigured() ? 'is-success': 'is-danger'"
              :disabled="!templateId || isLoading"
              v-if="configTasks"
              v-on:click="modalConfigTasks"
            >
              {{ $t('config_tasks') }}
            </button>
          </div>
        </section>
        <div class="modal-card-foot">
          <button class="button" :disabled="isLoading" v-on:click="isModalActive = false"><i class="fas fa-times"></i>&nbsp; {{ $t('cancel') }}</button>
          <button class="button is-success" :disabled="(configTasks && !tasksConfigured()) || isLoading" v-on:click="associateValidationFlowToEntity"><i class="fas fa-check"></i>&nbsp; {{ btnAssociateValidationFlow }}</button>
        </div>
      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

    </b-modal>
    <validationtask-config-activities-modal
      :activities="activities"
      ref="config_task_modal"
    />
  </section>
</template>

<script>

  import {mapGetters} from 'vuex'
  import axios from "axios";
  import EventBus from '~/plugins/bus'
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import validationtaskConfigActivitiesModal from './validations/validationtask-config-activities-modal'

  export default {

    components: {
      Treeselect,
      validationtaskConfigActivitiesModal
    },

    props: {
      textButton: { type: String, require: false, default: "add_validation_flow" },
      validationFlowId: { type: Number, require: false, default: 0 },
      validationTemplateId: { type: Number, require: false, default: 0 },
      validationTemplateName: { type: String, require: false, default: '' },
      entityId: { type: Number, required: false },
      entityType: { type: String, required: false },
      openModalWith: {  type: String, default: null }
    },

    data() {
      return {
        templateId: null,
        groups: [],
        activities: [],
        activity: null,
        subActivities: [],
        subActivity: null,
        projects: [],
        templates: [],
        entity: null, // será el proyecto seleccionado
        folderId: null,
        folderTree: [],
        btnAssociateValidationFlow: this.$t('associate_validation_flow'),
        isModalActive: false,
        isLoading: false,
        isLoadingTemplates: false, // usado para indicar que aun se esta cargando el listado de flujos
        description: '',
        inputTemplateIsRequired: false, // por defecto input template no requerido (ya tratamos abajo el true)
        inputActivityIsRequired: false, // por defecto input activity no requerido (ya tratamos abajo el true),
        configTasks: false,
        canCreateFlow: true, // De momento para poder mostrar el mensaje si el usuario no es participante de la primera tarea de algún flujo
        technicalSpecifications: [],
        technicalSpecificationTypes: [],
        showTechnicalSpecifications: false,
        showIssues: false,
        issues: [],
        issueId: null,
        issueName: null,
        templateTypes: ['Estándar', 'Especificaciones Técnicas'],
        scopeList: ['AT', 'GTI'],
        selectedFlowType: null,
        selectedScope: null,
        technicalSpecificationId: null,
        technicalSpecificationTypeId: null,
      }
    },

    watch: {

      templateId: function() {
        if (this.templateId) {
          this.inputTemplateIsRequired = false // quitar alerta de campo requerido
        }
      },

      validationFlowId: function(){
        this.templateId = this.validationTemplateId;
        this.inputTemplateIsRequired = false // quitar alerta de campo requerido
        this.btnAssociateValidationFlow = this.$t(this.textButton);
      },

      activity: function() {
        this.subActivity = null // reset subactivity
        this.folderId = null
        if (this.activity) {
          this.getsubActivities(this.activity.id);
          this.folderTree = this.getFolders(this.activity.folders || [])
          this.inputActivityIsRequired = false // quitar alerta de campo requerido
        }
      },

      subActivity: function() {
        this.folderId = null
        if (this.subActivity) {
          this.folderTree = this.getFolders(this.subActivity.folders || [])
        }
      },

      selectedFlowType: function() {
        this.selectedScope = null
        this.technicalSpecificationId = null
        this.technicalSpecificationTypeId = null

        this.showTechnicalSpecifications = this.selectedFlowType == 1;
        this.showIssues = this.selectedFlowType == 2;

        this.getTemplates(this.project.id);
      },

      selectedScope: function() {
        this.technicalSpecificationId = null
        this.getTechnicalSpecifications()
      },

      technicalSpecificationTypeId: function() {
        this.technicalSpecificationId = null
        this.getTechnicalSpecifications()
      },

      templateId: function() {
        this.$refs.config_task_modal.tasks = []
      },

      configTasks: function() {
        this.$refs.config_task_modal.tasks = []
      }
    },

    created(){

      // this.getActivityFolders()
      if (this.entityType == 'issue') {        
        this.getIssues(this.entityId)
        this.selectedFlowType = 2
      } else {
        this.selectedFlowType = 0
      }
      this.entity = { id: this.project.id, name: this.project.name, type: 'project' }

      // TODO. Mejor que en el created, será mejor cargar templates y actividades cuando la modal se active, que sera solo un 1% de las veces

      // en el caso de Cambiar actividad padre del flujo no hace falta cargar plantillas
      if (this.validationTemplateId == 0) {
        this.getTechnicalSpecificationTypes()
        this.getTechnicalSpecifications()
      }

      // cargar actividades solo cuando no es creacion de flujo directamente desde dentro de actividad
      // ahora cargamos siempre porq la ocupamos para el componente validationtaskConfigActivitiesModal
      // if (! this.entityId) {
        this.getActivities(this.project.id)
      // }
    },


    computed: {
      ...mapGetters({
        company: 'app/company',
        project: 'app/project',
      }),
    },

    methods: {
      show() {
        this.isModalActive = true

        // en el caso de Cambiar actividad padre del flujo no hace falta cargar plantillas tampoco si ya cargo la primera vez
        if (this.validationTemplateId == 0 && this.templates.length == 0) {
          this.getTemplates(this.project.id);
        }

        // cargar actividades solo cuando no es creacion de flujo directamente desde dentro de actividad
        // tampoco si cargo la primera vez
        if (! this.entityId && this.activities.length == 0) {
          this.getActivities(this.project.id)
        }
      },

      async getsubActivities(activityId){
        const { data } = await axios.get("/api/v2/activity/" + activityId + "/subactivities", {'params' : { with_folders: 1 }});
        this.subActivities = data.subactivities;
      },

      async getActivities(projectId){
        const { data } = await axios.get("/api/v2/activities", {'params' : { p : projectId, with_folders: 1, per_page: 999}});
        this.activities = data.activities;

        // para seleccionar por defecto la actividad actual en la que se encuentra el flujo. Creo que puede ser confuso que aparezca seleccionada. Lo quito
        // if(this.validationTemplateId > 0){
        //   let self = this;
        //   this.activities.forEach(item => {
        //     if( self.entityId == item.id ){
        //       self.activity = { id: item.id, name: item.name, type: 'activity', folders: item.folders }
        //     }
        //   });
        // }
      },

      async getIssues(entityId){
        // https://epc-client.test/api/v2/issues?activity_id=70355
        const params = { activity_id: entityId }
        
        const { data } = await axios.get('/api/v2/issue/' + entityId + '?h=1&with_doc=1');
        this.issueId = data.data.id
        this.issueName = data.data.code + ' - ' + data.data.name
        // this.issues = data.issues;
      },

      async getProjects(companyId){
        const { data } = await axios.get("/api/v2/user/projects", {'params' : { showArchive : 1, group: null, mappedForSelect: true, companyId: companyId }});
        this.projects = data.projects;
      },

      async getTemplates(projectId){
        this.isLoadingTemplates = true
        const { data } = await axios.get("/api/v2/project/"+projectId+"/validations-templates", {params: {type: this.selectedFlowType}});
        this.templates = data.templates;
        this.canCreateFlow = this.templates.length > 0
        this.isLoadingTemplates = false
      },

    async getTechnicalSpecificationTypes(){
      let {data} = await axios.get('/api/v2/technical-specification-types', {params: {p: this.project.id}});

      if (data.success) {
        this.technicalSpecificationTypes = data.data
      }
    },

    async getTechnicalSpecifications(){
      let params = {
        p: this.project.id,
        scope: this.selectedScope,
        type: this.technicalSpecificationTypeId,
      }
      let {data} = await axios.get('/api/v2/technical-specifications', {params});

      if (data.success) {
        this.technicalSpecifications = data.data
      }
    },

      // devuelve las carpetas de la actividad tal como los necesita el componente treeselect (nodos {id, label, children})
      getFolders(folders) {

        var map = {}, node, roots = [], i;

        for (i = 0; i < folders.length; i += 1) {
          map[folders[i].id] = i; // initialize the map
          folders[i].children = []; // initialize the children
        }

        for (i = 0; i < folders.length; i += 1) {
          node = folders[i];
          if (node.parent_id !== null) {
            folders[map[node.parent_id]].children.push({id: node.id, label: node.name, children: node.children});
          } else {
            roots.push({id: node.id, label: node.name, children: node.children});
          }
        }

        this.cleanEmptyChildrens(roots)

        return roots;
      },

      // limpia los nodos eliminando la key 'children' si está vacio
      cleanEmptyChildrens(tree) {

        for (let i = 0; i < tree.length; i += 1) {

          // Caso base 1. No tiene la key 'children', do nothing
          if (!tree[i].children)
            return true

          // Caso base 2. Tiene la key vacia, eliminarla
          if (tree[i].children && tree[i].children.length == 0) {
            delete tree[i].children
            return true;

          // tiene childrens, pues llamar recursivamente
          } else {
            this.cleanEmptyChildrens(tree[i].children)
          }
        }
        return true

      },

      async associateValidationFlowToEntity() {
        // Cargando
        this.isLoading = true;

        // Preparamos los parametros para asociar flujo
        let params = {
            template: null,
            entity_type: null,
            entity_id: null,
            description: null
        }

        // Cuando se muestra el select de flujo, debe ir relleno
        if (!this.templateId) {
          this.inputTemplateIsRequired = true;
          this.isLoading = false;
          return 1
        }

        // crear flujo desde actividad
        if (this.entityId && this.validationFlowId == 0) {

          params = {
            template: this.templateId,
            entity_type: this.entityType,
            entity_id: this.entityId,
            description: this.description,
            company_id: this.company.id,
          }

        } else {

          // Cuando se muestra el select de actividad, debe ir relleno
          if (this.selectedFlowType != 2) {
            if (!this.activity && !this.subActivity) {
              this.inputActivityIsRequired = true;
              this.isLoading = false;
              return 1
            } 
            params = {
              template: this.templateId,
              entity_type: this.activity ? this.activity.type : this.entity.type,
              entity_id: this.subActivity ? this.subActivity.id :
                (this.activity ? this.activity.id : this.entity.id),
              description: this.description,
              company_id: this.company.id,
            }
            // console.log('params nortmal', params)

          } else {
            // es tipo incidencia
            params = {
              template: this.templateId,
              entity_type: 'issue',
              entity_id: this.issueId,
              description: this.description,
              company_id: this.company.id,
            }
          }

        }

        this.$notify.info('generating_validation_flow');

      params.folder_id = this.folderId
      params.tasks = this.$refs.config_task_modal.tasks.map(task => {
        task.folder_id = task.folder_id || null
        return task
      })
      params.config_tasks = this.configTasks
      params.techspec_id = this.technicalSpecificationId

        // Endpoint segun sea cambio de padre del flujo o crear flujo nuevo
        let url = this.validationFlowId > 0
          ? '/api/v2/validation/'+this.validationFlowId+'/changeparent'
          : '/api/v2/validations/generate';

        await axios.post(url, params)
        .then(response => {
          let data = response.data
          if (data.success) {

            // Emitimos por el BUS
            // al crear nuevo, data.data.validation_flow_id contendrá el id del flujo creado
            EventBus.$emit("refreshValidations"); // no enviamos objeto junto con refreshValidation, para forzar siempre la recarga tras crear nuevo flujo

            // Loading
            this.isLoading = false;
            this.btnAssociateValidationFlow = this.$t('associate_validation_flow');
            this.isModalActive = false;

            // Flujo generado
            this.$notify.success('validation_flow_generated')

          } else {

            // Error
            this.$notify.error(data.error)

            // Loading
            this.isLoading = false;
            this.btnAssociateValidationFlow = this.$t('associate_validation_flow');

          }
        })
        .catch(error => {
          // Error
          this.$toast.open({
            message: error.response?.data?.message || this.$t("error_generating_validation_flow"),
            type: "is-danger",
            position: "is-top-right"
          });

          // Loading
          this.isLoading = false;
          this.btnAssociateValidationFlow = this.$t('associate_validation_flow');
        });
      },

      // carga las carpetas de la actividad, cuando abrimos el boton desde home de actividad, pasandole entityId
      getActivityFolders() {

        if (this.entityId && this.entityType=="App\\Models\\Activity") {
          let self = this
          axios.get("/api/v2/activity/" + this.entityId + "/", { params: {with_folders: 1} })
            .then(function(response) {
              self.folderTree = self.getFolders(response.data.data.folders || [])
            })
        }

    },

    // MODAL PARA ASOCIAR TAREAS A ACTIVIDADES
    modalConfigTasks() {
        this.$refs.config_task_modal.show(this.templateId);
    },

    tasksConfigured() {
      return this.$refs.config_task_modal.tasks.length && this.$refs.config_task_modal.tasks.every(t => t.activity_id)
    }
  }
}
</script>

<style scoped lang="scss">
  .modal-card {
    width: 100%;
  }

  .modal-card-body {
    min-height: 180px;
    min-width: 360px;
  }

  .modal-card-foot {
    justify-content: flex-end !important;
  }

  .vue-treeselect--open{
    color: #4a4a4a;
  }

  // @media (min-width: 640px) { // maneja talwind
  @media (min-width: 480px) { // personalizado
    .sm\:inline {
      display: inline !important;
    }
  }

  @media (min-width: 1080px) { // personalizado
    .lg\:inline {
      display: inline !important;
    }
  }

</style>
