<template>
<div class="sidebarmenu-container">
  <ul id="sidebar-menu" class="flex flex-col justify-center sidebarmenu">
    <template v-for="(section, sectionId) in data">
      <li v-if="section.sectionType == 'spacer'" class="h-4"></li>
      <menuheader v-else
        :icon="section.icon">
          {{ $t(section.sectionTitle) }}
      </menuheader>
      <template v-for="(item, itemId) in section.items">
        <item
          :itemId="sectionId+`-`+itemId"
          :url="(item.subitems!=null) ? null : item.url"
          :icon="item.icon"
          :hasSubmenu="item.subitems!=null"
          :class="{ opened: (pageInfo.menuOption === sectionId+`-`+itemId) }"
          v-if="item.url"
          @click="_HandleClickItem"
        >
          {{ $t(item.title) }}
        </item>
        <div
          v-if="item.subitems"
          :class="{ block: (pageInfo.menuOption !== sectionId+`-`+itemId),
                    hidden: (pageInfo.menuOption !== sectionId+`-`+itemId) }"
          class="submenu-container">
          <ul class="pl-8 m-0" style="list-style-type:disc">
            <li v-for="(subitem, subitemId) in item.subitems" class="relative" @click="_HandleClickItem">
              <subitem>{{ $t(subitem.title) }}</subitem>
            </li>
          </ul>
        </div>
      </template>
    </template>
  </ul>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Menuheader from './SidebarMenuHeader'
import Item from './SidebarMenuItem'
import Subitem from './SidebarSubmenuItem'
import { methods } from 'vue-mugen-scroll';

export default {
  components: {
    Menuheader,
    Item,
    Subitem
  },

  props: {
    data: { type: Array, required: true }
  },

  computed: mapGetters({
    pageInfo: 'app/pageInfo'
    // Esto de momento no hace falta hasta que necesitemos
    // restringir opciones de menú por permisos de usuario
    // user: 'authUser',
    // authenticated: 'authCheck'
  }),

  mounted() {
    // console.log(this.pageInfo)
  },

  methods: {
    _HandleClickItem() {
      this.$emit('epc-show-sidebar', false)
    }
  }

};
</script>
