<template>
    <!-- Panel -->
    <div v-if="pictogram" class="w-full mt-8">
      <div v-if="pictogram" class="flex flex-row w-full text-blue font-bold mt-3">{{ pictogram.name }}</div>
      <div v-if="pictogram" class="flex flex-row w-full text-blue font-bold">% de Avance {{ pictogram.activity_name }}</div>
      <div class="flex flex-row w-full"><line-chart :suffix="'%'" :data="dataset" /></div>
    </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: "ViewerChartsetPart",

    props: {
      pictogramId: { type: Number, required: true }
    },

    watch: {
    },

    data() {
      return {
        pictogram: null,
        dataset: null,
      }
    },

    mounted() {
      this.getPanel()
    },

    methods: {
      async getPanel() {
        let self = this
        let url = '/api/v2/pictogram/' + this.pictogramId + '/progress-dataset';

        await axios.get(url, {params: {}})
          .then(response => {
            if( response.data.success ) {
              self.pictogram = response.data.data.pictogram
              self.dataset = response.data.data.dataset
            } else {
              self.$notify.warning(response.data.msg)
            }
          })
          .catch((error) => {
            //Se produjo un error
            self.isLoading = false
            console.log("Error loading panel")
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });
      },
    }
  }

</script>

<style scoped>

</style>
