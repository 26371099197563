<template>
  <div>
    <div class="w-full flex justify-end">
      <div class="flex">
        <v-button @click="create = !create; 
          machineryCreate.id = 0;
          machineryCreate.name = '';
          machineryCreate.code = '';
          machineryCreate.measure_id = '';
          machineryCreate.provider_id = '';
          machineryCreate.description = '';"
          class="btn btn-blue rounded" icon="plus">{{ $t('add_machinery') }}
      </v-button>
      </div>
    </div>
    <div class="panel" v-if="create || machineryCreate.id">
      <div>

        <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('create_machinery') }}</h2>
        <form @submit.prevent="createMachinery"> 
        <div class="w-full justify inline-flex">
          <b-field :label="$t('name')" class="md:w-1/5 mr-4">
            <b-input v-model="machineryCreate.name" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('code')" class="md:w-1/5 mr-4">
            <b-input v-model="machineryCreate.code" class="w-full"></b-input>
          </b-field>
          <b-field :label="$t('description')" class="md:w-1/5 mr-4">
            <b-input v-model="machineryCreate.description" class="w-full"></b-input>
          </b-field>     
          <b-field :label="$t('measure')" class="md:w-1/5 mr-4">
            <b-select :placeholder="$t('select_measure')" v-model="machineryCreate.measure_id" required>
              <option value="number" v-for="measure in measures" :value="measure.id">{{ measure.name }}</option>
            </b-select>
          </b-field>
          <b-field label="  " class="md:w-1/5 mr-4 mt-4"> 
            <v-button class="btn btn-primary" nativeType="submit" icon="save" :disabled="!machineryCreate.name || !machineryCreate.code || !machineryCreate.measure_id">{{ $t('save') }}</v-button>
          </b-field>
        </div>
      </form>
      </div>
    </div>
    <div class="panel">
      <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('machinery_list') }}</h2>
      <div class="panel">  
        <h2 class="m-0 p-0 text-l font-raleway font-light text-blue-light uppercase mb-2">{{ $t('filter') }}</h2>
        <form @submit.prevent="filterMachinery"> 
          <div class="w-full justify inline-flex">
            <b-field :label="$t('name')" class="md:w-1/5 mr-4">
              <b-input v-model="machineryFilter.name" class="w-full"></b-input>
            </b-field>
            <b-field :label="$t('code')" class="md:w-1/5 mr-4">
              <b-input v-model="machineryFilter.code" class="w-full"></b-input>
            </b-field>
            <b-field :label="$t('measure')" class="md:w-1/5 mr-4">
              <b-select :placeholder="$t('select_measure')" v-model="machineryFilter.measure_id" required>
              <option value="number" v-for="measure in measures" :value="measure.id">{{ measure.name }}</option>
            </b-select>
            </b-field>
            <b-field :label="$t('description')" class="md:w-1/5 mr-4">
              <b-input v-model="machineryFilter.description" class="w-full"></b-input>
            </b-field>
            <b-field label="  " class="md:w-1/5 mr-4 mt-4"> 
              <v-button class="btn btn-primary" nativeType="submit"><b-icon icon="search"/></v-button>
            </b-field>
          </div>
        </form>
      </div>
      
      <div class="w-full flex flex-wrap mt-4">   
        <section class="w-full">
          <b-table
          :data="machineries"
          :paginated="isPaginated"
          :per-page="perPage"
          :bordered="true"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :default-sort-direction="defaultSortDirection"
          v-if="loaded">
          <template slot-scope="props">
            <b-table-column field="name" :label="$t('name')" sortable width=100>
              {{ props.row.name }}
            </b-table-column>
            
            <b-table-column field="code" :label="$t('code')" sortable >
              {{ props.row.code }}
            </b-table-column>

            <b-table-column field="description" :label="$t('description')" sortable >
              {{ props.row.description }}
            </b-table-column>

            <b-table-column field="measure" :label="$t('measure')" v-if="props.row.measure != null" sortable>
              {{ props.row.measure.name }}
            </b-table-column>

            <b-table-column field="actions" :label="$t('actions')" sortable width=100 >
              <div class="justify inline flex">
                <button @click="getMachinery(props.row.id)"><b-icon icon="pencil-alt" type="is-primary" /></button>
              </div>
            </b-table-column>
          </template>
        </b-table>
      </section>
    </div>
  </div>
</div>
</template>

<script>
import Form from 'vform'
import axios from 'axios'

export default {
  middleware: 'auth',
  title: 'machinery_page_title',
  subtitle: 'machinery_page_subtitle',
  menuOption: '0-3',

  data: () => ({
    provider_id: 0,
    isPaginated: true,
    isPaginationSimple: false,
    defaultSortDirection: 'asc',
    currentPage: 1,
    perPage: 5,
    machineries: [],
    measures: [],
    loaded: false,
    projects: null,
    create: false,

    machineryFilter: new Form ({
      name: '',
      code: '',
      measure_id: '',
    }),
    machineryCreate: new Form ({
      id: 0,
      name: '',
      description: '',
      code: '',
      measure_id: '',
    }),
  }),

  mounted () {
    this.provider_id = parseInt(this.$route.params.provider_id)
    this.getMeasures()
    this.getMachineries()
  },

  methods: {

    async filterMachinery () {
      if (! this.machineryFilter) {
        return;
      }
      console.log(this.machineryFilter)
      const url = '/api/v2/report/machinery/' + this.provider_id + '/filter'
      const { data } = await this.machineryFilter.post(url)
      if (data.data && data.success) {
        this.machineries = data.data
      }
    },

    async createMachinery () {
      if (! this.machineryCreate) {
        return;
      }
      var url
      if(this.machineryCreate.id){
        url = '/api/v2/report/machinery/' + this.machineryCreate.id 
      }else{
        url = '/api/v2/report/machinery/' + this.provider_id + '/add'
      }

      const { data } = await this.machineryCreate.post(url)
      if (data.data && data.success) {
        this.machineries = data.data
        this.machineryCreate.name = ''
        this.machineryCreate.code = ''
        this.machineryCreate.measure_id = ''
        this.machineryCreate.description = ''
        if(this.machineryCreate.id){
          this.getMachineries()
          // Hago esto pq extiende de la clase padre y no me retorna la lista completa de nuevo.
        }
        this.machineryCreate.id = 0
        this.success('machinery_save')

      }else{
        this.error('error_creating_machinery')

      }
    },

    async getMachineries(){
      const url = '/api/v2/report/machinery/' + this.provider_id + '/list'
      const { data } = await axios.get(url)
      if (data.data && data.success) {
        this.machineries = data.data
        this.loaded = true
      } else {
        this.error('error_retreiving_machineries')

      }
    },
    async getMeasures(){
      const url = '/api/v2/report/measure/' + this.provider_id + '/list'
      const { data } = await axios.get(url)
      if (data.data && data.success) {
        this.measures = data.data
        console.log(this.measures,"measures")
      } else {
        this.error('error_retreiving_machineries')

      }
    },

    async getMachinery(id){
      const url = '/api/v2/report/machinery/' + id
      const { data } = await axios.get(url)
      if (data) {
        //Object.assign(this.machineryCreate,data.data)
        this.machineryCreate.id = id
        this.machineryCreate.name = data.name
        this.machineryCreate.code = data.code
        this.machineryCreate.measure_id = data.measure_id
        this.machineryCreate.description = data.description

      } else {
        this.error('error_retreiving_machinery')
      }
    },

    /*
     ALERTAS
     */
     success(message){
      this.$toast.open({
        message: message,
        type: 'is-success',
        position: 'is-top-right'
      })
    },
    error(message){
      this.$toast.open({
        message: message,
        type: 'is-danger',
        position: 'is-top-right'
      })
    }
  },
  watch:{

  }

};
</script>
