<template>
  <div>
    <!-- Filtros -->
    <div class="panel">
      <div class="flex items-center mb-2">
        <div class="w-1/2 mr-4">
          <b-field>
            <b-input
              v-model="filterTemplateName"
              :placeholder="$t('project_name')"
              type="search"
              icon="search"
              icon-pack="fas"
              @keyup.enter.native="getProjects">
            </b-input>
          </b-field>
        </div>
      </div>

      <button class="button is-primary btn-blue" @click="cleanFiltersClicked">{{$t('clean_filters')}}</button>
    </div>


    <div class="w-full flex justify-between mt-4 below-top-bar" v-if="withActions">
        <div class="flex">
        </div>
        <!-- Botón añadir proyectos -->
        <div class="flex float-right">
          <div class="rounded-sm mr-4 mb-4 mt-2">
            <v-button icon="plus" @click="createTemplateButtonClicked()">{{$t('add_template')}}</v-button>
          </div>
        </div>
    </div>

    <!-- Tabla de proyectos -->
    <div class="panel">

      <b-table
        :data="templates"
        :loading="isLoadingTable"

        paginated
        backend-pagination
        :per-page="perPage"
        :total="total"
        @page-change="onPageChange"

        backend-sorting
        :default-sort="orderField"
        :default-sort-direction="orderDir"
        @sort="onSort"

        class="w-full"
        striped
        bordered
        icon-pack="fas"

        :selected.sync="rowSelected"
        @select="handleSelect"
        :is-row-selectable="() => { return !withActions }"
      >

        <template>

          <b-table-column field="code" :label="$t('code')" sortable v-slot="props">
            {{ props.row.code }}
          </b-table-column>

          <b-table-column field="name" :label="$t('name')" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column field="description" :label="$t('description')" sortable v-slot="props">
            {{ props.row.description }}
          </b-table-column>

          <b-table-column :label="$t('actions')" field="actions" centered width="150" v-slot="props" :visible="withActions">
            <div class="w-full h-full flex items-center justify-around">
              <b-tooltip :label="$t('edit_project')" position="is-left">
                <div @click="editProjectButtonClicked(props.row)"
                     class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="pencil-alt"/>
                </div>
              </b-tooltip>
              <!-- <b-tooltip :label="$t('archive_project')" position="is-left">
                <div @click="archiveProjectButtonClicked(props.row.id, props.row.name, props.row.closed_at)"
                     :class=archiveClass(props.row.closed_at)>
                  <b-icon pack="fas" icon="folder-open"/>
                </div>
              </b-tooltip> -->
              <b-tooltip :label="$t('remove_project')" position="is-left" type="is-danger">
                <div @click="deleteProjectButtonClicked(props.row.id, props.row.name)"
                     class="cursor-pointer flex text-red">
                  <b-icon pack="fas" icon="trash-alt"/>
                </div>
              </b-tooltip>
            </div>
          </b-table-column>

        </template>

        <template #empty>
          <div class="has-text-centered">{{ $t('no_results') }}</div>
        </template>
      </b-table>

    </div>

  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import axios from 'axios'

  export default {

    components: {},

    props: {
      withActions: {type: Boolean, default: true },
      template: Object,
    },

    computed: {
      ...mapGetters({
        company: "app/company",
      }),

      filters() {
        let filters = {
          'company_id': this.company.id,
          'order_field': this.orderField,
          'order_dir': this.orderDir,
        }

        if (this.filterTemplateName != '') {
          filters['template_name'] = this.filterTemplateName
        }

        return filters
      }
    },

    data: function() {
      return {
        isLoadingTable: true,
        templates: [],
        filterTemplateName: '',
        perPage: 20,
        orderField: 'name',
        orderDir: 'asc',
        page: 1,
        total: 0,

        rowSelected: this.template
      }
    },

    created() {
      // solo cargara las templates si se ha seleccionado una compania, caso contrario
      // en el mounted mostramos mensaje y redireccionamos al apartado correspondiente
      if (this.company)
        this.getProjectTemplates()
    },

    mounted() {
      // Redireccionamos si no ha seleccionado la company, esto no es del todo correcto ya que se esta trabajando
      // en el area de administración lo cual se deberīa cambiar esta logica a futuro para que la logica del area de
      // administración este separada la de trabajo
      if (!this.company) {
        this.$notify.info('Debe seleccionar una empresa')
        this.$router.push({ name: "company.select" });
      }
    },

    methods: {

      async getProjectTemplates() {
        this.isLoadingTable = true
        this.templates = []

        const {data} = await axios.get('/api/v2/project/templates', {params: this.filters})
        if (data && data.success) {
          this.templates = data.templates
          this.total = data.templates.length
          this.isLoadingTable = false
        }
      },

      onSort(field, order) {
        this.companyId = this.company.id
        this.orderField = field
        this.orderDir = order
        this.getProjects()
      },

      onPageChange(page) {
        this.page = page
        this.getProjects()
      },

      cleanFiltersClicked() {
        this.filterTemplateName = ''
        this.getProjectTemplates()
      },

      // Botones de acción
      createTemplateButtonClicked() {
        this.$router.push({name: 'project-template.create'})
      },

      /**
       * Coloca los datos necesarios en NavData para cambiar entre opciones de proyectos
       * @param project
       */
      setQuickButtonsNavData(project)
      {
        this.$store.dispatch('app/updateNavData', {
          area_name: project.area.name,
          project_id: project.id,
          project_name: project.name
        });
      },

      async editProjectButtonClicked(template) {
        // this.setQuickButtonsNavData(template);
        await this.$store.dispatch('template/setProject', template)

        this.$router.push({
          name: 'project-template.edit', params: {
            template_id: template.id,
          }
        })
      },

      deleteProjectButtonClicked(templateId, templateName) {
        this.$dialog.confirm({
          title: this.$t('delete_project'),
          message: this.$t('delete_project_confirm_text', [templateName]),
          confirmText: this.$t('delete_project'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            let url = '/api/v2/project/templates/' + templateId + '/delete'
            const {data} = await axios.post(url)
            if (data && data.success) {
              this.$notify.success('success_deleting_project')
              this.getProjectTemplates()
            } else {
              this.$notify.error(data.error)
            }
          }
        })
      },

      archiveProjectButtonClicked(templateId, templateName, closedAt) {
        let confirmText = closedAt ? 'archive_project_undone_text' : 'archive_project_confirm_text';
        let confirmButtonText = closedAt ? 'undone_project_archive' : 'archive';
        this.$dialog.confirm({
          title: this.$t('archive_project'),
          message: this.$t(confirmText),
          confirmText: this.$t(confirmButtonText),
          type: 'is-warning',
          hasIcon: true,
          onConfirm: async () => {
            let url = '/api/v2/project-templates/' + templateId + '/toggle_archive'
            const {data} = await axios.post(url)
            if (data && data.success) {
              console.log(data)
              this.$notify.success(data.msg)
              this.getProjectTemplates()
            } else {
              this.$notify.error(data.error)
            }
          }
        })
      },

      archiveClass(value) {
        return value ? "cursor-pointer flex text-yellow-dark" : "cursor-pointer flex text-blue"
      },

      handleSelect(newRow, oldRow) {
        this.$emit('update:template', newRow);
      },

      clearSelected() {
        this.rowSelected = null
      }
    },

  };
</script>
