<template>
  <div>
    <!-- Filtros -->
     <div class="w-full flex justify-between mt-4 below-top-bar" v-if="withPanelFilter">
        <div class="flex">
        </div>

    </div>

    <!-- Tabla de productos -->
    <div :class="{'panel': withClassWrapperTable }">

      <b-table
        :data="outs"
        :loading="isLoadingTable"

        paginated
        backend-pagination
        :per-page="perPage"
        :total="total"
        @page-change="onPageChange"

        backend-sorting
        :default-sort="orderField"
        :default-sort-direction="orderDir"
        @sort="onSort"

        class="w-full"
        striped
        bordered
        icon-pack="fas"
        :mobile-cards="false"
      >

        <template>

          <b-table-column field="code" :label="$t('code')" sortable v-slot="props" :visible="columnsVisible['code'].display">
            {{ props.row.inventory.product.code }}
          </b-table-column>

          <b-table-column field="product" :label="$t('material')" sortable v-slot="props" :visible="columnsVisible['material'].display">
            {{ props.row.inventory.product.name }}
          </b-table-column>

          <b-table-column field="provider" :label="$t('provider')" sortable v-slot="props" :visible="columnsVisible['provider'].display">
            {{ props.row.inventory.provider.name }}
          </b-table-column>

          <b-table-column field="date_out" :label="$t('date_out')" sortable v-slot="props" centered :visible="columnsVisible['date_out'].display" >
            {{ showDate(props.row.date_out) }}
          </b-table-column>

          <b-table-column field="qty" :label="$t('quantity')" sortable v-slot="props" centered :visible="columnsVisible['qty'].display" >
            {{ showQuantity(props.row.qty) }}
          </b-table-column>

          <b-table-column field="entity_id" :label="$t('activity')" sortable v-slot="props" :visible="columnsVisible['activity'].display">
            {{ props.row.storeable.name }}
          </b-table-column>

          <b-table-column field="user_id" :label="$t('user')" sortable v-slot="props" :visible="columnsVisible['user'].display">
            {{ props.row.user.name}}
          </b-table-column>

          <b-table-column :label="$t('actions')" field="actions" centered width="150" v-slot="props" :visible="columnsVisible['actions'].display">
            <div class="w-full h-full flex items-center justify-around">
              <!-- <b-tooltip :label="$t('edit_project')" position="is-left">
                <div @click="editButtonClicked(props.row)"
                     class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="pencil-alt"/>
                </div>
              </b-tooltip> -->
              <!-- <b-tooltip :label="$t('archive_project')" position="is-left">
                <div @click="archiveProjectButtonClicked(props.row.id, props.row.name, props.row.closed_at)"
                     :class=archiveClass(props.row.closed_at)>
                  <b-icon pack="fas" icon="folder-open"/>
                </div>
              </b-tooltip> -->
              <b-tooltip :label="$t('remove_project')" position="is-left" type="is-danger">
                <div @click="deleteButtonClicked(props.row.id, props.row.name)"
                     class="cursor-pointer flex text-red">
                  <b-icon pack="fas" icon="trash-alt"/>
                </div>
              </b-tooltip>
            </div>
          </b-table-column>

        </template>

        <template #empty>
          <div class="has-text-centered">{{ $t('no_results') }}</div>
        </template>
      </b-table>

    </div>

  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import axios from 'axios'
  import { parseQuantity, parseDateToDDMMYYYY } from './helpers.js'

  export default {

    components: {

    },

    props: {
      projectId: {type: Number, require: true },
      withPanelFilter: { type: Boolean, default: true },
      withClassWrapperTable: { type: Boolean, default: true },
      entityId: { type: Number, default: 0 },
      entityType: { type: String, default: null },
      propColumns: { type: Object, default: () => ( {} ) }
    },

    computed: {
      ...mapGetters({
        company: "app/company",
      }),

      filters() {
        let filters = {
          'order_field': this.orderField,
          'order_dir': this.orderDir,
          'perPage': this.perPage,
          'page': this.page,
        }

        if( this.entityId > 0 ) {
          filters['entity_id'] = this.entityId
          filters['entity_type'] = this.entityType
        }

        return filters
      },

      columnsVisible() {
        return {
          ...this.configColumns,
          ...this.propColumns
        }
      }
    },

    data: function() {
      return {
        isLoadingTable: true,
        outs: [],
        perPage: 25,
        orderField: 'name',
        orderDir: 'asc',
        page: 1,
        last: 1,
        total: 0,

        configColumns: {
          code: { title: 'code', display: true },
          material: { title: 'material', display: true },
          provider: { title: 'provider', display: true },
          date_out: { title: 'date_out', display: true },
          qty: { title: 'quantity', display: true },
          activity: { title: 'activity', display: true },
          user: { title: 'user', display: true },
          actions: { title: 'actions', display: true },
        }
      }
    },

    created() {
      this.getOuts()
    },

    methods: {

      async getOuts(filters=null) {
        this.isLoadingTable = true
        this.outs = []

        if( filters == null )
          filters = this.filter

        const {data} = await axios.get(`/api/v2/project/${this.projectId}/store-outs`, {params: filters})
        if (data && data.success) {
          this.outs = data.storeouts
          this.last = data.lastPage;
          this.total = data.total;
          this.isLoadingTable = false
        }
      },

      onSort(field, order) {
        this.projectId = this.projectId
        this.orderField = field
        this.orderDir = order
        this.getOuts()
      },

      onPageChange(page) {
        this.page = page
        this.getOuts()
      },

      reload(arg = {}) {
        let filters = { ...arg, ...this.filters }
        filters.page = 1
        this.getOuts();
      },

      async editButtonClicked(entity) {
        this.$emit('epc-edit', entity)
      },

      deleteButtonClicked(purchaseId, purchaseName) {
        this.$dialog.confirm({
          title: this.$t('delete'),
          message: this.$t('delete_confirm_text', ['la salida', purchaseName]),
          confirmText: this.$t('delete') + ' ' + this.$t('out'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            let url = '/api/v2/store-out/' + purchaseId + '/delete'
            const {data} = await axios.post(url)
            if (data && data.success) {
              this.$notify.success('success_deleting_generic')
              this.getOuts()
              this.$parent.$emit('epc-updated-list')
            } else {
              this.$notify.error(data.error)
            }
          }
        })
      },

      archiveClass(value) {
        return value ? "cursor-pointer flex text-yellow-dark" : "cursor-pointer flex text-blue"
      },

      handleSelect(newRow, oldRow) {
        this.$emit('update:template', newRow);
      },

      clearSelected() {
        this.rowSelected = null
      },

      showQuantity(qty) {
        return parseQuantity(qty)
      },

      showDate(date) {
        return parseDateToDDMMYYYY(date);
      }
    },

  };
</script>
