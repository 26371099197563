<template>
  <b-modal :active.sync="isModalActive" :width="640" @close="hide()" >
    <div class="border border-blue-light rounded shadow bg-white" v-if="document">
      <div v-if="image" class="w-full p-2 flex justify-center">
        <img :src="image" :alt="document.name">
      </div>
      <div v-else-if="video" class="w-full p-2 flex justify-center">
        <video :src="video" controls></video>
      </div>
      <div class="bg-grey-lighter p-4">
        <div class="flex items-center mb-4">
          <div class="flex items-center">
            <div class="rounded-full overflow-hidden border border-grey w-10 h-10 mr-2">
              <img :src="document.user.avatar" :alt="document.user.fullname">
            </div>
          </div>
          <div>
            <div class="text-blue font-medium">{{ document.user.fullname }}</div>
            <div class="text-grey text-sm">{{ document.user.email }}</div>
          </div>
          <div class="flex-grow flex justify-end">
            <v-button-icon-round
              v-if="isPdf"
              @click="loadPDFInNewTab"
              icon="eye"
              :tooltip="documentDownloadTooltip('view_document')"
              :disabled="!isOk()"
              tooltip-position="left"
              class="mr-1"
            />
            <v-button-icon-round
              @click="downloadDoc"
              icon="download"
              :tooltip="documentDownloadTooltip('download_document')"
              :disabled="!isOk()"
              tooltip-position="left"
              class="mr-1"
            />
            <v-button-icon-round
              @click="deleteDoc"
              icon="trash-alt"
              color="red"
              tooltip="delete_document"
              tooltip-position="left"
              v-if="canDelete"
            />
          </div>
        </div>
        <div class="content">
          {{ $t('file') + ': '}}<strong>{{ document.name }}</strong><br>
          {{ $t('size') + ': '}}<strong>{{ getReadableFileSizeString(document.size) }}</strong><br>
          {{ $t('type') + ': '}}<strong>{{ document.name.substr(document.name.lastIndexOf('.') + 1).toUpperCase()
          }}</strong><br>
          <small>{{ $t('uploaded_at') + ': '}}<strong>{{ moment(document.created_at).format('DD/MM/YYYY H:mm:ss') }}</strong></small>
          <small v-if="document.exif && document.exif.original_datetime">{{ $t('taken_at') + ': '}}<strong>{{
            moment(document.exif.original_datetime).format('DD/MM/YYYY H:mm:ss') }}</strong></small>
        </div>
        <b-field :label="$t('filename')">
          <b-input
            v-model="filename"
            :placeholder="$t('filename')"
            required
            icon-pack="fas"
          />
        </b-field>
        <div class="mb-2">

        </div>

        <!-- ETIQUETAS -->
        <div class="mb-2">
          <model-tags
            v-if="document"
            :model-id="document.document_id"
            :initial-tags="document.tags"
            model-type="document"
            :allow-edit="false"
            :add-from-list="true"
            :parent-model-id="project.id"
            parent-model-type="project"
            ref="modelTags"
            :disabled="!canDelete"
          />
        </div>

        <div style="margin-top: 10px">
          <!-- Botón Upload -->
          <button v-if="document !== null && canDelete"
                  class="button is-primary mr-2"
                  @click="updateDocument"
                  :disabled="filename.trim() == ''"
          >
            <b-icon pack="fas" icon="save" size="is-small"/>
            <span>{{ $t('save') }}</span>
          </button>
        </div>



        <!-- Geolocalización -->
        <b-collapse class="panel" :open.sync="isOpen" v-if="document.exif">
          <div slot="trigger" class="panel-heading">
            <strong>{{ $t('geolocation') }}</strong>
          </div>
          <div class="panel-block">
            <photo-map class="w-full p-2" v-if="document.exif"
                       :datamaps="datamaps"/>
          </div>
        </b-collapse>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import axios from "axios";
  import PhotoMap from "~/components/Map.vue";
  import EventBus from "~/plugins/bus";
  import MentionUsers from '·/components/mention-users'
  import ModelTags from "·/components/model-tags";
  import {mapGetters} from "vuex";

  export default {
    name: "assignment-document-preview",

    components: {
      PhotoMap,
      MentionUsers,
      ModelTags
    },

    props: {
      entityId: {type: Number, required: true},
      entityType: {type: String, required: false},
      canDelete: {type: Boolean, default: false},
    },

    data: () => ({
      filename: "",
      extension: "",
      isModalActive: false,
      document: null,
      image: null,
      video: null,
      datamaps: {},
      isOpen: false,
      isPdf: false,
    }),

    computed: {
      ...mapGetters({
        project: 'app/project'
      }),
    },

    beforeDestroy(){
      EventBus.$off("load-locations");
    },

    methods: {

      loadPDFInNewTab() {
          window.open(this.document.file.raw, '_blank');
      },
      async loadPreviewImage() {
        let url = "/api/v2/document/" + this.document.document_id + "/600";
        const {headers, data} = await axios.get(url, {
          responseType: "arraybuffer"
        });


        // Filtramos si es pdf
        if(this.document.type == 0){
          this.isPdf = true;
        }

        if (data && data.byteLength > 0) {
          let mime = headers["content-type"];
          this.image =
            "data:" +
            mime +
            ";base64," +
            new Buffer(data, "binary").toString("base64");
        } else {
          this.image = null;
        }

      },
      async loadVideoPlayer(){
       this.video = this.document.file.raw;
      },
      getReadableFileSizeString(fileSizeInBytes) {
        var i = -1;
        var byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
        do {
          fileSizeInBytes = fileSizeInBytes / 1024;
          i++;
        } while (fileSizeInBytes > 1024);
        return Math.max(fileSizeInBytes, 0.01).toFixed(2) + byteUnits[i];
      },
      async downloadDoc() {
        this.$notify.success("starting_download_document");
        let url = "/api/v2/document/" + this.document.document_id;

        const {data} = await axios.get(url);

        if (data) {

          window.open(data.data);

          // const link = document.createElement("a");
          // link.href = window.URL.href(data.data);
          // link.setAttribute("download", this.document.name);
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);

        } else {
          this.$notify.error("error_download_document");
        }
      },
      deleteDoc() {
        this.$dialog.confirm({
          title: this.$t("delete_document"),
          message: this.$t("delete_document_confirm_text"),
          confirmText: this.$t("delete_document"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            let url = "/api/v2/" + this.entityType + "/" + this.entityId + "/document/" + this.document.document_id;
            const {data} = await axios.post(url);
            if (data && data.success) {
              this.$notify.success("success_deleting_document");
              this.$emit("reload-documents-list");

              this.hide();
            } else {
              this.$notify.error("error_deleting_document");
            }
          }
        });
      },
      show(document) {

        console.log(document);
        // instanciamos
        var self = this;
        this.document = document;
        this.extension = this.getFileExtension(document);
        this.filename = document.name.replace(/\.[^/.]+$/, "");
        //Aqui

        if (this.document.type == 3) {
          this.loadVideoPlayer();
        } else {
          this.loadPreviewImage();
        }
        EventBus.$on("load-locations", function (locations) {

          let location = JSON.parse(locations);
          if (location) {
            self.datamaps = {
              id: 1,
              latitude: location.lat,
              longitude: location.lon
            };
          }
        });

        this.isModalActive = true;
      },

      getFileExtension(file) {
        let re = /(?:\.([^.]+))?$/;

        return '.' + re.exec(file.name)[1];
      },

      hide() {
        this.document = null;
        this.isModalActive = false;
        this.filename = "";
        this.extension = "";
        this.image = null;
      },

      // Actualizamos las etiquetas y las menciones
      updateDocument() {
        this.$refs.mention_users.uploadMentions();
        this.$refs.modelTags.syncTagsToModel(this.document.document_id);
        this.updateDocumentName();

        this.$notify.success("success_editing_document");
        this.$emit("reload-documents-list");
        this.hide();

        // Refrescamos los componentes dependiente de notificaciones para actualizar la lista de documentos
        // if (this.entityType === 'activity') {
        //   console.log("desde updateDocument")
        //   EventBus.$emit('refreshActivityDocumentsNotifications', true)
        // } else {
        //   EventBus.$emit('refreshIssueDocumentsNotifications', true)
        // }
      },

      async updateDocumentName() {
        let url = "/api/v2/document/" + this.document.document_id;
        let data = await axios.post(url, {original_name: this.filename.trim() + this.extension});
      },

      isOk(){
        return this.document.status == 1;
      },

      documentDownloadTooltip(tooltip)
      {
        if(this.isOk())
          return tooltip;

        return 'document_still_processing';
      }

    }
  };
</script>
