<template>
<div class="flex items-center">
  <span class="mr-2">{{ $t('show') }}</span>
    <b-select v-model="selected">
      <option v-for="option in options"
        :value="option"
        :key="option">
        {{ option }}
      </option>
    </b-select>
  <span class="ml-2">{{ $t('rows') }}</span>
</div>
</template>

<script>
export default {
  name: 'l-pagination-select',

  props: {
    // # of rows as options
    options: { type: Array, default: () => {
      return [ 10, 25, 50, 100 ]
    } },
    // Default selected option
    defaultOption: { type: Number, default: 10 },
    // v-model value
    value: {},
  },

  data: () => ({
    selected: null,
  }),

  watch: {
    selected(value) {
      this.$emit('input', value)
    }
  },

  mounted () {
    this.selected = this.value ? this.value : this.defaultOption
  },

  methods: {
  },

};
</script>
