import { Graphics, Text, TextStyle, Texture } from "pixi.js";

export class PicBackground extends Graphics {
  constructor(el, offset, index) {
    super();

    this.picId = 'bk_' + el.id;
    this.picX = Math.abs( el.x + offset.x );
    this.picY = Math.abs( el.y - offset.y );
    this.picWidth = el.width;
    this.picHeight = el.height;
    this.picFillColor = index % 2 === 0 ? '#f7f3f3' : null;

    this.drawBackground();
  }

  drawBackground() {
    this.rect(this.picX, this.picY, this.picWidth, this.picHeight)
      .fill(this.picFillColor)
  }
}
