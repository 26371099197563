<template>
  <section>
    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <!-- <div class="mx-auto flex h-12 w-12 flex-shrink-0- items-center justify-center rounded-full bg-blue-lighter sm:mx-0 sm:h-10 sm:w-10">
              <b-icon class="h-6 w-6 text-blue-dark" icon="file-pen" pack="fas"></b-icon>
            </div> -->
            <div class="mt-3 mb-3 text-center sm:mt-0 sm:text-left">
              <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Vincular ordenes de pedidos a subcontrata <span v-if="partner">: {{ partner.name }}</span></h3>
              <p class="text-sm text-gray-500">Seleccione las órdenes de pedidos que están vinculadas a la subcontrata. Si la orden ya está vinculada a la subcontrata, no se vuelven a vincular.</p>
            </div>
          </div>
          <div class="mt-2 space-y-4">
            <div class="flex-grow">
              <label for="work_orders" class="w-full">{{ $t('order_orders') }}</label>
              <multiselect v-model="selorders" :options="orders"
                :placeholder="'Elija ordes de pedidos'"
                label="code"
                track-by="id"
                :show-labels="false"
                :searchable="true"
                :hide-selected="true"
                :multiple="true"
                :maxHeight="200"/>
            </div>

            <div class="flex-grow mb-40" v-if="!partner">
              <label for="partner" class="w-full">{{ $t('partner') }}</label>
              <multiselect
                v-model="partnerSel"
                :options="partners"
                label="name"
                track-by="id"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                placeholder="Elija una subcontrata"></multiselect>

            </div>
          </div>
        </div>
        <div class="px-4 py-3 justify-center sm:flex sm:px-6">
          <button class="button is-success mr-2" :disabled="isLoading" v-on:click="save()"><i class="fas fa-floppy-disk"></i>&nbsp; Guardar</button>
          <button class="button mr-2" :disabled="isLoading" v-on:click="isModalActive = false"><i class="fas fa-times"></i>&nbsp; {{ $t('cancel') }}</button>
        </div>
      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

    </b-modal>
  </section>
</template>

<script>
  import axios from "axios";
  import Multiselect from 'vue-multiselect'

  export default {

    components: {
      Multiselect
    },

    props: {
      projectId: {type: Number, required: true},
      partner: {type: Object, default: null, required: false},
    },

    data() {
      return {
        isModalActive: false,
        isLoading: false,
        partnerSel: null,

        partners: [],
        orders: [],
        selorders: null
      }
    },

    watch: {
    },

    created(){
      this.getProjectPartners();
      this.getWorkOrders()
    },

    computed: {
    },

    methods: {
      show(workOrder) {
        this.workOrder = {
          id: workOrder.id,
          project_id: workOrder.project_id,
          code: workOrder.code,
          description: workOrder.description
        }

        if (workOrder.partner_id) {
          this.workOrder.partner_id = workOrder.partner_id
        }
        this.isModalActive = true
      },

      hide() {
        this.workOrder = {}
        this.isModalActive = false
        this.partnerSel = null
        this.selorders = null
      },

      async save() {
        let body = {
          project_id: this.projectId,
          orders: this.selorders.map((el) => { return el.id })
        }

        this.isLoading = true;
        let url = `/api/v2/work-orders/${ this.partnerSel ? this.partnerSel.id : this.partner.id }/link`;
        let {data} = await axios.post(url, body)

        if (data.success) {
          this.$notify.success('success_editing')
          this.$emit('reload')
          this.hide()
        } else {
          this.$notify.error('error_saving')
        }

        this.isLoading = false;
      },

      async getProjectPartners() {

        const { data } = await axios.get("/api/v2/project/" + this.projectId + "/partners", {'params' : { }});

        if (data.data) {
          this.partners = data.data;
          this.loaded = true
        }

      },

      async getWorkOrders() {
        let {data} = await axios.get('/api/v2/work-orders', {params: {
          project_id: this.projectId
        }});

        if (data.success) {
          this.orders = data.data
        } else {
          this.$notify.error("error_loading_data");
        }
      },
    }
}
</script>

<style scoped lang="scss">
  .modal-card-body {
    min-height: 180px;
    min-width: 340px;
  }

  .modal-card-foot {
    justify-content: flex-end !important;
  }

  .mb-40 {
    margin-bottom: 10rem !important;
  }
</style>
