var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:'konva_tooltip',style:({
    position: 'absolute',
    top: _vm.position.y + 2 + 'px',
    left: _vm.position.x + 2 + 'px',
    padding: '5px',
    borderRadius: '3px',
    boxShadow: '0 0 5px grey',
    zIndex: 10,
    backgroundColor: '#5577BB',
    minWidth: '210px'
  })},[_c('div',{staticClass:"text-white font-bold p-1 mb-0 border-b border-solid border-slate-100 uppercase text-sm"},[_vm._v("\n    "+_vm._s(_vm.$t( _vm.dataTooltip.type && _vm.dataTooltip.type.endsWith('s') ? _vm.dataTooltip.type.slice(0, -1) : _vm.dataTooltip.type ))+" : "+_vm._s(_vm.dataTooltip.name)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"text-white p-1 text-sm"},[(_vm.dataTooltip.status)?_c('div',[_vm._v("\n        "+_vm._s(_vm.$t('status'))+": "),_c('span',{style:({ color: _vm.colorStatus })},[_c('b-icon',{attrs:{"icon":"traffic-light","pack":"fas"}})],1),_vm._v(" "+_vm._s(_vm.nameStatus))]):_c('div',[_vm._v(_vm._s(_vm.$t('status'))+": "),_c('b-icon',{attrs:{"icon":"traffic-light","pack":"fas"}}),_vm._v(" Sin estado")],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }