import Vue from 'vue'
import i18n from '~/plugins/i18n'
import { loadMessages } from '~/plugins/i18n'
import store from '~/store'
import Buefy from 'buefy'
import { DialogProgrammatic as Dialog } from 'buefy'
import { ToastProgrammatic as Toast } from 'buefy'
import VueProgressBar from 'vue-progressbar'

;(async function () {
  // Cargamos las cadenas traducidas de la app para poder usarlas en Buefy
  await loadMessages(store.getters['lang/locale'])

  // see https://buefy.github.io/#/documentation/constructor-options
  Vue.use(Buefy, {
    defaultIconPack: 'fas',
    defaultDialogConfirmText: i18n.t('ok'),
    defaultDialogCancelText: i18n.t('cancel'),
    defaultToastDuration: 3500,
    defaultTooltipType: 'is-dark',
    defaultTooltipAnimated: true,
    defaultInputAutocomplete: 'off',
    defaultDateParser: (date) => {
      date = date.split('/')
      date = date[2] + '-' + date[1] + '-' + date[0] + ' 00:00:00'
      return new Date(date)
    },
    defaultDateFormatter: (date) => {
      if (date) {
        let d = date.getDate()
        let m = date.getMonth() + 1
        let y = date.getFullYear()
        return ('0' + d).slice(-2) + '/' +
               ('0' + m).slice(-2) + '/' + y
      } else {
        return ''
      }
    },
  })

  // actualizar buefy ahora implica hacer esta declaracion explicitamente
  Vue.prototype.$dialog = Dialog
  Vue.prototype.$toast = Toast

  // Notificaciones TOAST
  Vue.prototype.$notify = {
    _notify(msg, color, params) {
      Toast.open({
        message: i18n.t(msg, params),
        'position': 'is-top-right',
        'type': color
      })
    },
    success(msg, params = null) {
      this._notify(msg, 'is-success', params)
    },
    error(msg, params = null) {
      this._notify(msg, 'is-danger', params)
    },
    info(msg, params = null) {
      this._notify(msg, 'is-info', params)
    },
    warning(msg, params = null) {
      this._notify(msg, 'is-warning', params)
    },
  }
})()

// Loading Bar
Vue.use(VueProgressBar, {
  color: '#17AC56',
  failedColor: '#ff3860',
  thickness: '4px',
  transition: {
    speed: '0.2s',
    opacity: '0.1s'
  }
})
