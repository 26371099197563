<template>
<div class="activity-documents panel">
  <div class="flex items-center justify-between w-full">

    <div class="flex-col w-1/2">
      <!-- Título -->
      <div class="text-lg font-thin uppercase text-blue-light">{{ $t('documents') }}</div>
    </div>

    <div class="flex-col w-1/2">
      <div class="flex-row">

        <!-- Botón añadir -->
        <b-tooltip :label="$t('add_document')" position="is-left" class="float-right" v-if="canAdd">
          <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-blue text-grey-lightest" @click="onAddClicked">
            <b-icon pack="fas" icon="plus" size="is-small" />
          </div>
        </b-tooltip>
        <!-- Modal -->
        <upload-document v-if="canAdd"
          ref="modal"
          :upload-endpoint="'/api/v2/' + entityType + '/' + entityId + '/document'"
          :allow-multiple-files="true"
          @success="successfullyUploaded"
          :entityId="entityId"
          :entityType="entityType"
          :selected-folder="currentFolder"
          :upload-folders="canUploadFolders"
          :notify-users="notifyUsers"
          :validable-parent-code="validableParentCode"
        />

        <!-- Boton duplicar documento de proyecto -->
        <div class="float-right mr-2" v-if="canAdd && entityType == 'activity'">
          <copy-document-template
            :entityId="entityId"
            :entityType="entityType"
            />
        </div>

      </div>
    </div>

  </div>

  <div v-if="isLoading" class="mt-4 text-center">
        <div class="mt-4 text-center"><scale-loader></scale-loader></div>
  </div>

  <div v-else>
    <div v-if="! isLoading" class="flex flex-wrap mt-4">

      <div class="flex items-center w-full">

        <!-- Orden -->
        <b-field class="mr-2">
          <b-select v-model="orderField" size="is-small" rounded icon="sort">
            <option value="date">{{ $t('date') }}</option>
            <option value="name">{{ $t('name') }}</option>
          </b-select>
        </b-field>

        <!-- Tipo de documentos -->
        <b-field>
          <b-select v-model="fileType" size="is-small" rounded icon="file">>
            <option value="-">{{ $t('all') }}</option>
            <option value="i">{{ $t('images') }}</option>
            <option value="v">{{ $t('videos') }}</option>
            <!--
            <option value="a">{{ $t('audios') }}</option>
            -->
            <option value="d">{{ $t('documents') }}</option>
          </b-select>
        </b-field>

        <!-- Tipo de visualización SELECT -->
        <b-field>
          <b-select v-model="viewType" size="is-small" rounded icon="file">>
            <option value="list">Lista</option>
            <option value="thumbnail">Miniaturas</option>
            <option value="folder" v-if="entityType === 'activity'">Por carpetas</option>
          </b-select>
        </b-field>

        <!-- Filtro por menciones -->
        <v-switch
                :label="$t('filter_by_mentions')"
                :color="filterMentions ? 'green' : 'red'"
                :value="filterMentions"
                v-on:checked="checkFilterMentions"
                style="margin-left:20px; margin-bottom:25px"
        />
      </div>

      <!-- ETIQUETAS -->
      <div class="w-full mb-4">
        <filter-tags
            @filterList="filterDocuments($event)"
            ref="filterTags"
        />
      </div>

      <div v-if="viewType == 'thumbnail'" class="flex flex-wrap w-full">
        <entity-document-thumbnail
           v-for="doc in list"
           :key="'thumb_'+doc.document_id"
           :document="doc"
           :parent-id="entityId"
           :entityType="entityType"
           :can-delete="user.id == doc.user.user_id && entityType!='validation'"
           :showVersions="showVersions"
           class="w-1/5"
           @reload-documents-list="() => loadDocumentsList(entityId, entityType)"
        />
      </div>
      <div v-else-if="viewType == 'list'" class="w-full">
        <document-item-list
            v-for="(doc, index) in list"
            :key="doc.document_id"
            :document="doc"
            :parent-id="entityId"
            :can-delete="(user.id == doc.user.user_id || isProjectManager) && entityType!='validation'"
            :entity-type="entityType"
            :bg-color="index % 2 ? 'bg-white' : 'bg-grey-lighter'"
            :showVersions="showVersions"
            @reload-documents-list="() => loadDocumentsList(entityId, entityType)"
        />
      </div>
      <div v-else-if="viewType == 'folder'" class="w-full">
        <entity-document-folder
            :documents="list"
            :folders="folders"
            :selected-folder="currentFolder"
            :parent-id="entityId"
            :user="user"
            :entity-type="entityType"
            :showVersions="showVersions"
            :canManageFolders="canManageFolders"
            :isProjectManager="isProjectManager"
            :isProjectParticipant="isProjectParticipant"
            :projectId="project ? project.id : 0"
            @openFolder="(newFolder) => openFolder(newFolder)"
            @reload-documents-list="() => loadDocumentsList(entityId, entityType)"
            :validable-parent-code="validableParentCode"
        />
      </div>

    </div>

    <div v-if="this.total > this.perPage" class="mt-4">
      <b-pagination
          :total="total"
          :current.sync="page"
          size="is-small"
          iconPack="fas"
          order="is-centered"
          :per-page="perPage"
          @change="onPageChange">
      </b-pagination>
    </div>
  </div>

  <!-- Documentos Adjuntos -->
  <div v-if="withAttachments">
    <hr>
    <entity-attached-documents
      :entity-id="entityId"
      :entity-type="entityType"
      :validable-parent-code="validableParentCode"
    />
  </div>
</div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import uploadDocument from "./upload-document";
import entityDocumentThumbnail from "./entity-document-thumbnail";
import documentItemList from "./document-item-list";
import entityDocumentFolder from "./entity-document-folder";
import copyDocumentTemplate from "./copy-document-template";
import filterTags from "./filter-tags";
import EventBus from "~/plugins/bus";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import Buefy from 'buefy'
import entityAttachedDocuments from "./entity-attached-documents";

export default {
  name: "entity-documents",

  components: {
    uploadDocument,
    entityDocumentThumbnail,
    documentItemList,
    entityDocumentFolder,
    copyDocumentTemplate,
    filterTags,
    ScaleLoader,
    Buefy,
    entityAttachedDocuments
  },

  props: {
    entityId: { type: Number, required: true },
    entityType: { type: String, required: true },
    viewType: { type: String, default: 'list', required: false }, // thumbnail, folder
    canAdd: { type: Boolean, default: false },
    canManageFolders: { type: Boolean, default: false },
    isProjectManager: { type: Boolean, default: false },
    isProjectParticipant: { type: Boolean, default: false },
    canUploadFolders: { type: Boolean, default: false },
    notifyUsers: { type: Boolean, default: false },
    validableParentCode: { type: String, required: false, default: '' }, // para formato codificacion documentos "lean2" y "lean3" que van precedidos por codigo actividad padre
    withAttachments: { type: Boolean, default: false }, // Documentos adjuntos (de momento solo para flujos)
  },

  data: () => ({
    list: [],
    folders: [], // listado carpetas si selecciona vista por carpetas
    currentFolder: {}, // carpeta seleccionada viendo sus ficheros
    isLoading: true,
    filterMentions: false,
    fileType: '-',
    orderField: 'date',
    page: 1,
    perPage: 15,
    total: 0,
    tags: [],
    showVersions: true
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
      project: 'app/project'
    }),
  },

  watch: {
    entityId(id) {
      if (id > 0) {
        this.loadDocumentsList(id, this.entityType);
      }
    },
    fileType(value) {
      this.loadDocumentsList(this.entityId, this.entityType)
    },
    orderField(value) {
      this.loadDocumentsList(this.entityId, this.entityType)
    },
    viewType(newValue, oldValue) {
      // si pasamos de folder a otra cosa (o viceversa), recargar lista con/sin carpetas
      if (oldValue=='folder' || newValue=='folder')
        this.loadDocumentsList(this.entityId, this.entityType)
    }
  },

  created() {
    // Referenciamos
    var self = this;

    // Esta linea comentada, por doble llamada a la api que se realiza nuevamente dentro del watch en la primera carga
    this.loadDocumentsList(this.entityId, this.entityType);

    if (this.entityType === 'validation') {
      // Si recibimos el evento refresh, refrescamos el componente
      EventBus.$on("refreshValidationDocumentsNotifications", function(e) {
        self.loadDocumentsList(self.entityId, 'validation');
      });
    }
    else if (this.entityType === 'activity') {
      // Si recibimos el evento refresh, refrescamos el componente
      EventBus.$on("refreshActivityDocumentsNotifications", function(e) {

        if( e && typeof e == "object" ){
          if( self.currentFolder.id == e.folder_parent_id )
            self.loadDocumentsList(self.entityId, 'activity');
        }else
          self.loadDocumentsList(self.entityId, 'activity');

        if(e && typeof e == "boolean"){
          self.list = [];
        }

      });
    } else {
      // Si recibimos el evento refresh, refrescamos el componente
      EventBus.$on("refreshIssueDocumentsNotifications", function(e) {
        self.loadDocumentsList(self.entityId, 'issue');
      });
    }

    // entornos que almacenan ficheros fuera de epc, no manejamos versiones, ni permite ver online
    if (process.env.MIX_HOST_FILES !== undefined && process.env.MIX_HOST_FILES !== 'default')
      this.showVersions = false

  },
  beforeDestroy(){
    EventBus.$off("refreshValidationDocumentsNotifications");
    EventBus.$off("refreshActivityDocumentsNotifications");
    EventBus.$off("refreshIssueDocumentsNotifications");
  },
  methods: {
    async loadDocumentsList(entityId, entityType) {

      this.isLoading = true
      let self = this

      let params = {
        per_page: this.perPage,
        page: this.page,
        order_field: this.orderField,
        tags: this.tags,
        with_folders: this.viewType == 'folder' ? 1 : 0, // ordenado en carpetas
        folder_id: this.currentFolder.id || null, // docs y carpetas que cuelgan de esta carpeta (del raiz si es null)
      }

      if (this.filterMentions) {
        params.mention_user_id = this.user.id
      }
      self.isLoading = false;

      await axios.get("/api/v2/"+ entityType +"/" + entityId + "/documents?" + this.fileType + "=1",
        { params: params })
        .then(function(response) {

          if (response.data && response.data.documents) {
            self.list = response.data.documents;
            self.total = response.data.total
            self.folders = response.data.folders
            self.currentFolder = response.data.currentFolder || {}
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(function () {
          self.isLoading = false;
        });
    },

    openFolder(folder) {
        this.currentFolder.id = folder.id
        this.loadDocumentsList(this.entityId, this.entityType)
    },

    successfullyUploaded()
    {
      this.loadDocumentsList(this.entityId, this.entityType);

      let entity = this.entityType;
      entity = entity.charAt(0).toUpperCase() + entity.slice(1);
      //Evento : "refreshActivityDocumentsNotifications"

      EventBus.$emit('refresh'+entity+'DocumentsNotifications', true);
    },

    onPageChange(value) {
      this.page = value
      this.loadDocumentsList(this.entityId, this.entityType)
    },

    onAddClicked() {
      this.$refs.modal.show();
    },

    checkFilterMentions() {
      this.filterMentions = ! this.filterMentions
      this.page = 1
      this.loadDocumentsList(this.entityId, this.entityType)
    },

    // Carga las tags recibidas y aplica el filtro
    filterDocuments(tags) {
      this.isLoading = true
      this.tags = tags
      this.page = 1
      this.loadDocumentsList(this.entityId, this.entityType)
    }
  }
};
</script>
