<template>
  <div>
    <div class="text-blue-light font-thin text-center uppercase mb-4">
      {{ $t("assignment_preview") }}
    </div>
    <div v-if="isLoading">
      <scale-loader></scale-loader>
    </div>
    <div v-else>
      <b-table
        :data="data"
        :loading="isLoading"
        :bordered="true"
        ref="table"
      >
        <template slot="empty">
          <div class="m-4 text-lg text-dark-grey text-center">
            <span>{{ $t('empty_pending_validation_assignments_info') }}</span>
          </div>
        </template>

        <template>
          <b-table-column
            :label="$t('assignment_pending_validation')"
            class="w-4 type-column vertical-center"
            centered v-slot="data"
          >
              {{ data.row }}
              <span @click="goTo" v-if="data.row > 0" class="cursor-pointer">
                <b-icon icon="eye" pack="fas" size="is-small" type="is-info"  />
              </span>
          </b-table-column>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { mapGetters } from "vuex";
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

export default {
  name: "project-assignments-preview",

  components: {
    ScaleLoader
  },

  computed: {
    ...mapGetters({
      project: 'app/project',
      user: 'auth/user',
      navData: "app/navData",
    })
  },

  data: () => ({
    data: [],
    isLoading: true,
    totalPendingValidation: 0,
  }),

  created() {
    this.getPendingValidation();
  },

  methods: {
    async getPendingValidation() {
      let args = {};

      args.with_parent = 1;
      args.with_assignments = 1;
      args.where_pending_validation = 'pending';
      args.order_field = 'name';
      args.order_dir = 'desc';

      const {data} = await axios.get('/api/v2/project/' + this.project.id + '/assignments?nopaginate=1', {params: args});

      // Last and total
      this.last = data.lastPage;
      this.total = data.total;

      if (data) {
        this.totalPendingValidation = data.assignments;
        this.data.push(data.assignments);
      } else {
        this.$notify.error('error_loading_assignment_data');
      }

      this.isLoading = false;
    },

    goTo() {
      this.$router.push({name: 'project.measurements', query: {pending_validation: 1}})
    },
  },

};
</script>

<style>
</style>
