<template>
<div>

    <!-- nuevo fichero, do nothing -->
    <div v-if="isNewFile">

    </div>


    <!-- nueva version. Permitir cambiar nombre fich original y añadir un nombre descriptivo -->
    <div v-if="!isNewFile" class="px-4">

        <div class="flex flex-col w-full pb-3 mt-4">
            <label>{{$t('name')}}</label><br>
            <input v-model="custom_name" class="input" />
            <b-checkbox v-model="selectedValue">Usar nombre del nuevo fichero</b-checkbox>
        </div>

        <div class="flex flex-col w-full pb-3 mt-8">
            <label>{{$t('descriptive_name')}}</label><br>
            <input v-model="desc_name" :placeholder="''" class="input" />
        </div>

        <div class="cursor-default tag is-info" type="button"
            @click="generateName">
            {{$t('save')}} <b-icon pack="fas" icon="file-alt" />
        </div>

    </div>



</div>
</template>

<script>

export default {
  name: "codification-default",

  components: {
  },


  props: {
    isNewFile: {type: Boolean, required: false, default: true}, // flag is new file (true), or is version (false)
    versionFileNameCustom: {type: String, required: false, default: ''}, // nombre original del fichero al que subir version
    versionFileNameDesc: {type: String, required: false, default: ''}, // nombre descriptivo del fichero al que subir version
    versionFileNameNew: {type: String, required: false, default: ''}, // nombre del nuevo fichero seleccionado
  },


  data: () => ({

    custom_name: '',
    desc_name: '',
    new_name: '',

    previous_name: '', // guarda temporalmente el nombre que traia el fichero, para reestablecerlo
    selectedValue: false,

  }),


  created() {

    this.custom_name = this.versionFileNameCustom
    this.desc_name = this.versionFileNameDesc

  },


  watch: {
    selectedValue: function () {

        if (this.selectedValue) {
            // usar nombre del nuevo fichero a subir (guardamos el anterior por si deshace este cambio)
            this.previous_name = this.versionFileNameCustom
            this.custom_name = this.versionFileNameNew
        } else {
            // restaurar el nombre anterior
            this.custom_name = this.previous_name
        }
    }

  },


  methods: {



    // aplica el codigo de nombre generado al campo custom_name
    generateName() {

        // emit al componente padre validationtask-completion-modal con lo generado

        // if (this.isNewFile) {

        // } else {

        // }

        // quitamos la extension porque se pone fuera
        this.custom_name = this.custom_name.substring(0, this.custom_name.lastIndexOf("."))

        this.$emit('generatedCodename', {
            desc_name: this.desc_name || '',
            custom_name: this.custom_name,
            // coded_name: 0,
        })

    },



  }
}
</script>