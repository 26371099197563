<template>
  <b-modal :active.sync="isModalActive" :width="640" scroll="keep">
    <div class="border border-blue-light rounded shadow bg-white" v-if="data">
      <header
        class="w-full bg-grey-lightest px-4 text-xl font-thin uppercase text-blue-light"
      >{{ $t('edit_measurement_unit') }}</header>
      <div class="w-full px-8 pb-8 pt-6 bg-grey-lightest">
        <form @submit.prevent="updateUnit" class="w-full">
          <div class="border-grey-light border-b border-dashed mb-3">
            <div class="w-full inline-flex pb-3">
              <label form="reference_code" class="md:w-1/3">{{ $t('reference_code')}}</label>
              <v-input
                v-model="form.code"
                title="reference_code"
                required="true"
                name="code"
                placeholder="reference_code"
                class="md:w-2/3"
                maxlength="100"
              />
            </div>

            <div class="w-full inline-flex pb-3">
              <label form="name" class="md:w-1/3">{{ $t('name')}}</label>
              <v-input
                v-model="form.name"
                title="name"
                required="true"
                name="name"
                placeholder="name"
                class="md:w-2/3"
                maxlength="100"
              />
            </div>

            <!-- Descripción de la localización-->
            <div class="w-full pb-3">
              <textarea
                v-model="form.description"
                name="description"
                :placeholder="$t('description')"
                class="w-full"
              ></textarea>
            </div>

            <div class="w-full inline-flex justify-end pb-3">
              <button class="btn btn-blue" nativeType="submit">{{$t('edit')}}</button>
            </div>
          </div>
        </form>

        <div class="content">
          {{ $t('last_update') + ': '}}
          <strong>{{ new Date(data.updated_at).toLocaleDateString() + ' ' + new Date(data.updated_at).toLocaleTimeString() }}</strong>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Form from "vform";

export default {
  name: "measurement-unit-modal",

  components: {},

  data: () => ({
    isModalActive: false,
    data: null,
    form: {
      code: "",
      name: "",
      description: ""
    }
  }),

  methods: {
    async updateUnit() {
      let url = "/api/v2/measurement-units/" + this.data.id;
      axios({
        method: "post",
        url: url,
        data: this.form
      })
        .then(response => {
          this.$toast.open({
            message: this.$t("measurement_unit_change_success"),
            type: "is-success",
            position: "is-top-right"
          });

          this.hide();
          this.$emit("reload-units");
        })
        .catch(error => {
          //Se produjo un error
          this.isLoading = false;
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("measurement_unit_change_fail"),
            type: "is-danger",
            position: "is-top-right"
          });
        });
    },

    show(data) {
      this.data = data;
      this.form.code = data.code;
      this.form.name = data.name;
      this.form.description = data.description;
      this.isModalActive = true;
    },

    hide() {
      this.data = null;
      this.isModalActive = false;
      this.form.code = "";
      this.form.name = "";
      this.form.description = "";
    }
  }
};
</script>
<style scoped>
/* Oculta los botones para aumentar los inputs numéricos */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
