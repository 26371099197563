<template>
  <div>
    <project-map-manual />
  </div>
</template>

<script>
  import ProjectMapManual from '../../components/project-map-manual'

  export default {
    middleware: 'auth',
    title: 'select_group_page_title',
    subtitle: 'select_group_page_subtitle',
    // menuOption: '2-10',

    components: {
      ProjectMapManual
    },


    data: () => ({

    }),

    methods: {

    }
  };
</script>
