<template>
<b-modal :active.sync="isModalActive" :width="640" scroll="keep">
  <div class="w-auto border border-blue-light rounded">
    <header class="w-full bg-grey-lightest px-4 py-2 text-xl font-thin uppercase text-blue-light">
      {{ $t('change_progress') }}
    </header>
    <div class="w-full p-4 bg-grey-lightest">
      <div class="w-full flex items-baseline justify-center font-bold py-4">
        <div
          :class="{
            'text-green': newProgress > progress,
            'text-red': newProgress < progress,
            'text-grey-darker': newProgress == progress
          }"
          style="font-size:6rem"
          class="">
          {{ newProgress }}
        </div>
        <div class="text-grey-darker text-5xl">%</div>
      </div>
      <div>
        <div class="slidecontainer">
          <input
            type="range"
            min="0"
            max="100"
            :value="newProgress"
            class="slider"
            id="progressSlider"
            @input="changeProgress($event)">
        </div>
      </div>
      <!-- <div class="flex items-center justify-center">
        <button @click="addClicked"
          class="btn btn-green rounded-full w-12 h-12 mr-2"
          :disabled="newProgress == 100"
        >
          <b-icon pack="fas" icon="plus" size="is-medium" />
        </button>
        <button @click="substractClicked"
          class="btn btn-red rounded-full w-12 h-12 ml-2"
          :disabled="newProgress == 0"
        >
          <b-icon pack="fas" icon="minus" size="is-medium" />
        </button>
      </div> -->
      <h3 class="h-8 text-grey italic text-right">{{ (progress != newProgress) ? $t('previus_progress', [progress]) : '' }}</h3>
    </div>
    <footer class="border-t p-4 flex items-center justify-end bg-grey-lighter">
      <!-- Botón Guardar -->
      <v-button v-show="progress != newProgress"
        @click="save"
        icon="check"
        class="mr-4">
        {{ $t('save') }}
      </v-button>
      <!-- Botón cerrar -->
      <v-button
        @click="hide"
        color="grey"
        icon="undo-alt">
        {{ $t('close') }}
      </v-button>
    </footer>
  </div>
</b-modal>
</template>

<script>
// import Vue from 'vue'
import axios from 'axios'
import EventBus from '~/plugins/bus'

// import { mapGetters } from 'vuex'

export default {
  name: 'activity-change-progress',

  // components: {
  //   Component,
  // },

  props: {
    activityId: { type: Number, required: true },
    progress: { type: Number, required: true },
  },

  data: () => ({
    isModalActive: false,
    newProgress: 0,
  }),

  watch: {
    progress(value) {
      this.newProgress = value
    }
  },

  // computed: {
  //   ...mapGetters({
  //     user: 'authUser',
  //     authenticated: 'authCheck'
  //   }),
  // },

  mounted () {
    this.newProgress = this.progress
  },

  methods: {
    async save() {
      const { data } = await axios.post('/api/v2/activity/' + this.activityId + '/progress', { progress: this.newProgress })
      if (data && data.success) {
        this.$emit('changed', {'progress': parseInt(this.newProgress), 'status': data.activity.status})
        this.hide()
        this.$notify.success('success_updating_progress')
      } else {
        this.$notify.error('error_updating_progress')
      }
    },
    // addClicked() {
    //   this.newProgress++
    //   if (this.newProgress > 100) {
    //     this.newProgress = 100
    //   }
    // },
    // substractClicked() {
    //   this.newProgress--
    //   if (this.newProgress < 0) {
    //     this.newProgress = 0
    //   }
    // },
    show() {
      this.isModalActive = true;
    },
    hide() {
      this.isModalActive = false;
    },
    changeProgress(e) {
      this.newProgress = e.target.value
    },
  },

};
</script>

<style scoped>
.slidecontainer {
    width: 100%; /* Width of the outside container */
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
}

/* Mouse-over effects */
.slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
}
</style>
