<template>
  <div v-if="activity"> 

    <div class="panel">
      <div class="flex">
        <div class="mr-6">
          <b>{{$t('name')}}</b> {{activity.name}}
        </div>
        <div class="mr-6">
          <b>{{$t('start_date')}}</b> {{activity.current_start_date}}
        </div>
        <div class="mr-6">
          <b>{{$t('end_date')}}</b> {{activity.current_end_date}}
        </div>
      </div>

      <div class="mt-5">
        <b>{{$t('delay')}}:</b> {{activity.delayed_days}} <span class="lowercase">{{$t('days')}}</span>
      </div>
      
      <div class="flex mt-5">
        <div class="w-1/2 p-2">
          <pre-activity-table :data="predecessors" :name="$t('predecessors')" childrenField="pre_activities"/>
        </div>
        <div class="w-1/2 p-2">
          <pre-activity-table :data="successors" :name="$t('successors')"  childrenField="next_activities"/>
        </div>
      </div>

      <div class="w-full mt-5">
        <v-switch v-model="applyDelay" :label="$t('apply_delay')" :disabled="activity.delayed_days == 0"
          :color="applyDelay ? 'green' : 'red'" v-on:checked="(value) => applyDelay = value"
           />
      </div>

      <form @submit.prevent="sendForm"
        class="w-full flex sm:flex-row items-center justify-end mb-4 mt-4">
        <v-button type="submit" icon="thumbs-up" icon-pack="far" :disabled="activity.delayed_days == 0"
          class="mr-2">{{ $t('save') }}</v-button>
        <v-button color="grey" icon="undo-alt" @click="$router.go(-1)">{{$t('back')}}</v-button>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import PreActivityTable from './pre-activity-table'

export default {
  middleware: 'auth',
  title: 'linked_activities',
  subtitle: 'linked_activities',
  menuOption: '2-2',

  components: {
    PreActivityTable
  },

  data: () => ({
    activityId: null,
    activity: null,
    applyDelay: false,
    predecessors: [],
    successors: [],
  }),

  computed: {
  },

  created() {
    this.activityId = this.$route.params.activity_id
    this.getActivityData()
  },

  mounted() {

  },

  methods: {


    async getActivityData() {
        const { data } = await axios.get('/api/v2/activity/' + this.activityId + '?with_prelations=1')
        if (data && data.success) {
          this.activity = data.data

          this.successors = data.data.next_activities
          this.predecessors = data.data.pre_activities

          // this.getSuccessors(data.data.all_next_activities)
          // this.getPredecessors(data.data.all_pre_activities)

        } else {
          this.$notify.error('error_loading_activity_data')
        }
    },

    async sendForm() {
      if (this.applyDelay) {
        const { data } = await axios.post('/api/v2/activity/' + this.activityId + '/apply-delay', {})
        if (data && data.success) {
          this.$notify.success("delay_applied_correctly");
        }
      }
    },

    getSuccessors(data) {
      for (let suc of data) {
        this.successors.push(suc)
        this.getSuccessors(suc.all_next_activities)
      }
    },

    getPredecessors(data) { 
      for (let pre of data) {
        this.predecessors.push(pre)
        this.getPredecessors(pre.all_pre_activities)
      }
    },

    goToActivity(activity) {
      if (activity.parent_id)
        this.$router.push({'name': 'subactivity.home', params: {activity_id: activity.parent_id, subactivity_id: activity.id}})
      else
        this.$router.push({'name': 'activity.home', params: {activity_id: activity.id}})
    }
  }
};
</script>
