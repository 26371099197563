<template>
  <div>
    <div class="top-bar flex justify-end">
      <v-button
        @click="() => { openFormImportEntities = true }"
        icon="file-import"
        :disabled="openFormDataMeasurement"
      >{{ $t('import_measurement') }}</v-button>
    </div>
    <div class="flex">
      <div :class="[( openFormDataMeasurement || openFormImportEntities ? 'w-8/12' : 'w-full'), 'mr-2']">
        <template-list-activities ref="table_list"
          :projectId="projectId"
          :hideActions="!(openFormDataMeasurement || openFormImportEntities)"
          :loadMeasurements="true"
          @epc-add-measurement="handleAddMesurement"
          @epc-edit-measurement="handleEditMeasurement"
        />
      </div>

      <transition name="fade">
        <div class="w-4/12 ml-2" v-if="openFormDataMeasurement">
          <template-measurement-edit :projectId="projectId"
            :measurement="entityMeasurement"
            :optionsUnits="units"
            :entity="entity"
            :entity_id="entity_id"
            @epc-close="handleOnCloseEdit"
            @epc-success-creating="handleSuccess"
          />
        </div>

        <div class="w-4/12 ml-2" v-if="openFormImportEntities">
          <template-entity-import
            :projectId="projectId"
            :entity-type="'measurement/templates'"
            @epc-close="(event) => { openFormImportEntities = false }"
            @epc-success-import="() => { openFormImportEntities = false ; $refs.table_list.reload() }"
          />
        </div>

      </transition>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Form from "vform";
import axios from "axios";
import TemplateListActivities from './template-list-activities.vue';
import TemplateMeasurementEdit from './template-measurement-edit.vue';
import TemplateEntityImport from './template-entity-import.vue';

export default {
  name: "template-manage-measurements",

  components: {
    TemplateListActivities,
    TemplateMeasurementEdit,
    TemplateEntityImport,
  },

  data: () => ({
    entity: 'activity', // entidad a la cual esta asociada la medicion default activity
    entity_id: 0, // identificador de la entidad default cero
    entityMeasurement: null, // objeto que se crea
    openFormDataMeasurement: false,
    openFormImportEntities: false,

    units: [], // unidades de la compania
  }),

  props: {
    projectId: { type: Number, required: true },
  },

  computed: {
    ...mapGetters({
      company: "app/company",
    }),
  },

  created() {
    this.getUnitsCompany()
  },

  mounted() {
  },

  methods: {
    async getUnitsCompany() {
      this.units = []

      const {data} = await axios.get('/api/v2/company/'+this.company.id+'/measurement-units')
      if (data && data.success) {
        this.units = data.units
      }
    },

    addMeasurement() {
      this.entity = null
      this.openFormDataMeasurement = true;
    },

    handleOnCloseEdit(event) {
      this.openFormDataMeasurement = !event
    },

    handleSuccess(entity) { // recibe la entidad editada
      this.openFormDataMeasurement = false
      this.$refs.table_list.reload()
    },

    // handleEditEntity(entity) {
    //   this.entity = entity
    //   this.openFormDataMeasurement = true;
    // },

    handleAddMesurement(activity) {
      this.entityMeasurement = {}
      this.entityMeasurement.id = null
      this.entityMeasurement.parent_measurement_id = null,
      this.entityMeasurement.unit_id = null
      this.entityMeasurement.code = ''
      this.entityMeasurement.name = ''
      this.entityMeasurement.weight = null

      this.entity_id = activity.id

      this.openFormDataMeasurement = true;
    },

    handleEditMeasurement(measurement) {
      this.entityMeasurement = {}
      this.entityMeasurement.id = measurement.id
      this.entityMeasurement.parent_measurement_id = measurement.parent_measurement_id
      this.entityMeasurement.unit_id = measurement.unit_id
      this.entityMeasurement.code = measurement.code
      this.entityMeasurement.name = measurement.description
      this.entityMeasurement.weight = measurement.weight

      this.openFormDataMeasurement = true;
    },
  }

};
</script>

<style scoped>

</style>
