<template>
  <section :class="{'inline-block': !openWithButton}">
    <button-menu v-if="!openWithButton"
      :text-label="$t('project_report')"
      :icon="'book'"
      :active-tooltip="true"
      :positionTooltip="'is-top'"
      :redirect="null"
      :handle-on-click="() => { isModalActive = true }"
    />

    <div v-if="openWithButton" class="mr-2 rounded-sm" @click="isModalActive = true">
      <v-button icon="book" class="mr-4">{{ $t('project_report') }}</v-button>
    </div>
    <b-modal :active.sync="isModalActive" has-modal-card>
      <div class="w-1/2 modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t('generate_report') }}</p>
          <v-button icon="layer-group" @click="selectAll" class="mr-4">{{ $t('select_all') }}</v-button>

        </header>
        <section class="modal-card-body">
          <b-field :label="$t('activities_page_title')">
            <b-select
              multiple
              native-size="10"
              expanded
              v-model="selectedOptions">
              <option v-for="activity in list"
                      :value="activity['id']"
                      :key="activity['id']"
              >
                {{ activity['name'] }}
              </option>
            </b-select>
          </b-field>

          <b-field :label="$t('comments')" class="float-left mr-4">
            <div class="field">
              <b-switch v-model="withComments" type="is-success" size="is-small">{{ $t('include') }}</b-switch>
            </div>
          </b-field>

          <b-field :label="$t('documents')" class="float-left mr-4">
            <div class="field">
              <b-switch v-model="withDocuments" type="is-success" size="is-small">{{ $t('include') }}</b-switch>
            </div>
          </b-field>

          <!-- <b-field :label="$t('chat_history')" class="float-left mr-4">
            <div class="field">
              <b-switch v-model="withChat" type="is-success" size="is-small">{{ $t('include') }}</b-switch>
            </div>
          </b-field> -->

          <b-field :label="$t('subactivities')" class="float-left mr-4">
            <div class="field">
              <b-switch v-model="withSubactivities" type="is-success" size="is-small">{{ $t('include') }}</b-switch>
            </div>
          </b-field>

          <b-field :label="$t('issues')" class="float-left mr-4">
            <div class="field">
              <b-switch v-model="withIssues" type="is-success" size="is-small">{{ $t('include') }}</b-switch>
            </div>
          </b-field>


        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" :disabled="isLoading" v-on:click="generateReport">{{ btnGenerateReport }}
          </button>
          <button class="button" size="is-small" type="button" @click="isModalActive = false">{{ $t('close') }}</button>
        </footer>
      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

    </b-modal>
  </section>
</template>

<script>

  import Vue from "vue";
  import {mapGetters} from 'vuex'
  import axios from "axios";
  import store from "~/store";
  import ButtonMenu from '../components/quick-button-menu/components/button-menu.vue'

  export default {
    components: {
      ButtonMenu
    },

    data() {
      return {
        btnGenerateReport: this.$t('generate_report'),
        isModalActive: false,
        selectedOptions: [],
        list: [],
        withComments: true,
        withDocuments: false,
        withChat: true,
        withSubactivities: true,
        withIssues: true,
        isLoading: false

      }
    },

    computed: {
      ...mapGetters({
        user: 'auth/user',
        project: 'app/project',
        company: 'app/company',
      }),
    },

    props: {
      filters: {type: Object, required: true},
      openWithButton: {  type: Boolean, default: true }
    },

    watch: {
      isModalActive: function (val) {
        if (val === true) {
          // Cargamos las actividades siempre que abramos el modal para mantener la consistencia con los filtros
          this.loadActivitiesReport()
        }
      }
    },

    methods: {

      async loadActivitiesReport() {

        let params = this.filters;

        this.$delete(params, 'per_page');
        this.$delete(params, 'page');
        this.$delete(params, 's');
        this.$delete(params, 's_num');
        params.pg = 1;

        // Nos las actividades en las que podemos generar reportes
        const {data} = await axios.get('/api/v2/activities/report', {params: params});

        // Si encontramos actividades, las pasamos
        if (data && data.activities) {
          this.list = data.activities
        } else {
          this.$notify.error('error_loading_activities')
        }

      },

      /**
       * Al pulsar "seleccionar todo" marca todas, si todas están marcadas las desmarca
       */
      selectAll() {
        if (this.selectedOptions.length === this.list.length)
          this.selectedOptions = [];
        else
          this.selectedOptions = this.list.map(a => a.id);
      },

      async generateReport() {

        if (this.selectedOptions.length == 0) {

          // Error
          this.$notify.error('error_select_activity')

        } else {

          // Loading
          this.isLoading = true;
          this.btnGenerateReport = this.$t('generating');

          // Parámetros para generar el reporte.
          var params = {
            comments: this.withComments,
            documents: this.withDocuments,
            //chat: this.withChat,
            subactivities: this.withSubactivities,
            issues: this.withIssues,
            activities: this.selectedOptions
          };

          // Generando reporte, cerramos el modal
          // this.$notify.success('generating_report');
          this.isModalActive = false;


          // Nos las actividades en las que podemos generar reportes
          const {data} = await axios.get('/api/v2/report/generate/' + this.company.alias, {params: params});

          // Si encontramos actividades, las pasamos
          if (data) {

            this.$notify.success('generating_report');
            // // Download
            // window.open(data.data);

            // // Reporte generado
            // this.$notify.success('report_generated')

            // Loading
            this.isLoading = false;
            this.btnGenerateReport = this.$t('generate_report');
            this.isModalActive = false;


          } else {

            // Error
            this.$notify.error('error_loading_report');

            // Loading
            this.isLoading = false;
            this.btnGenerateReport = 'Generar Informe';

          }

        }

      }

    },
  }
</script>

<style lang="scss" scoped>
  .modal-card {
    width: 100%;
  }

  .hidden {
    display: none;
  }

  @media (min-width: 1280px) {
    .xl\:inline {
      display: inline !important;
    }
  }


</style>
