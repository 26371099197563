<template>
  <div class="page-with-top-bar">
    <project-admin-quick-buttons hiddenOption="pictograms"/>
    <section>
      <div class="mt-4">
        <b-tabs type="is-boxed" v-model="mainActiveTab">
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="solar-panel"></b-icon>
              <span> {{ $t('pictograms') }} </span>
            </template>

            <manage-pictograms
              ref="refManagePictograms"
              :projectId="projectId"
              :can-actions-pictogram="canActionsPictogram"
            ></manage-pictograms>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="table-cells"></b-icon>
              <span> {{ $t('photovoltaic_park') }} </span>
            </template>

            <!-- <manage-photovoltaic-park
              ref="refManagePhotovoltaicPark"
              :projectId="projectId"
              :can-actions-pictogram="canActionsPictogram"
            ></manage-photovoltaic-park> -->
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="traffic-light"></b-icon>
              <span> {{ $t('manage_statuses') }} </span>
            </template>

            <manage-pictogram-statuses
              ref="managePictogramStatuses"
              :projectId="projectId"
              :can-actions-pictogram="canActionsPictogram"
              @epc-pictograms-reload="_HandlePictogramsRefresh"
            ></manage-pictogram-statuses>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-circle-plus"></b-icon>
              <span> {{ $t('add_pictogram') }} </span>
            </template>

            <manage-pictogram-add
              ref="managePictogramAdd"
              :projectId="projectId"
              :can-actions-pictogram="canActionsPictogram"
              @epc-pictograms-reload="_HandlePictogramsRefresh"
            ></manage-pictogram-add>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="grip-lines"></b-icon>
              <span> {{ $t('add_evacuation_line') }} </span>
            </template>

            <manage-evacuation-line-add
              ref="manageEvacuationLineAdd"
              :projectId="projectId"
              :can-actions-pictogram="canActionsPictogram"
              @epc-pictograms-reload="_HandlePictogramsRefresh"
            ></manage-evacuation-line-add>
          </b-tab-item>

        </b-tabs>

      </div>
    </section>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import Form from "vform";
  import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons';
  import ManagePictograms from '../../components/pictograms/manage-pictograms.vue';
  import ManagePictogramAdd from '../../components/pictograms/manage-pictogram-add.vue';
  import ManagePictogramStatuses from '../../components/pictograms/manage-pictogram-statuses.vue';
  import ManagePhotovoltaicPark from '../../components/pictograms/manage-photovoltaic-park.vue'
  import ManageEvacuationLineAdd from '../../components/evacuation-line/manage-evacuation-line-add.vue';

  export default {
    middleware: "auth",
    title: "pictograms_drawings_page_title",
    menuOption: "0-2",

    components: {
      ProjectAdminQuickButtons,
      ManagePictograms,
      ManagePictogramStatuses,
      ManagePictogramAdd,
      ManagePhotovoltaicPark,
      ManageEvacuationLineAdd
    },

    computed: {
      ...mapGetters({
        navData: "app/navData",
        user: "auth/user",
      }),
      projectId() {
        return parseInt(this.navData.project_id);
      }
    },

    data: () => ({
      isLoading: false,
      form: new Form({
        projectId: null,
        partnerId: null,
        companyId: null,
        excelFile: null,
        description: "",
        overwrite: false,
        assignmentType: null,
        activityCode: null,
        actionType: "",
      }),

      entityType: 'project',
      entityId: null,

      errors: [],
      mainActiveTab: 0,
      addDrawings: false,

      canActionsPictogram: {
        'mode_dashboard': false,
        'show_header': true,
        'save': true,
        'select_entity': false
      }
    }),

    watch: {
      mainActiveTab(value) {
        if (value == 1) {
          // this.$refs.refManagePhotovoltaicPark.FullScreenHack()
          this.redirectToPowerPlant()
        }
      }
    },

    created() {
      let projectName = this.navData.project_name
        ? this.navData.project_name.toUpperCase()
        : "";
      this.$options.subtitle = ["project_actions_page_subtitle", [projectName]];

      this.entityId = this.projectId;
    },

    mounted() {

    },

    beforeDestroy(){
    },

    methods: {
      async getProjectPictograms() {
        this.isLoading = true;
        let params = { };

        const { data } = await axios.get(
          "/api/v2/project/" + this.entityId + "/pictograms",
          { params: params }
        );

        if (data && data.success) {
          this.pictograms = data.pictograms;
          this.total = data.pictograms ? data.pictograms.length : 0;
        } else {
          this.$notify.error("error_loading_documents");
        }
        this.isLoading = false;
      },

      _HandlePictogramsRefresh() {
        this.$refs.refManagePictograms.RefreshListPictograms()
        this.mainActiveTab = 0
      },

      redirectToPowerPlant() {
        this.$router.push({ name: "project.admin.powerplant", params: {  } });
      }
    }
  };
</script>
