<template>
  <child/>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PictogrmaContainer',

  data: () => ({
    appName: window.config.appName,
  }),

};
</script>
