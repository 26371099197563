<template>
  <b-modal :active.sync="isModalActive" width="60%" height="80vh">
    <div class="w-auto border rounded border-blue-light" style="height: 90vh">
      <!-- <header
        class="w-full px-4 py-2 text-xl font-thin uppercase bg-grey-lightest text-blue-light"
      >
        {{ $t("add_document") }}
      </header> -->

      <!-- <div style="height: 75vh;">
        <iframe src="" frameborder="0" width="100%" :src="`${getFilePath}`" style="height: inherit !important;"></iframe>
      </div> -->

      <div id="outerContainer">
        <div id="sidebarContainer">

        </div>  <!-- sidebarContainer -->

        <div id="mainContainer">
          <div class="toolbar">
            <div id="toolbarContainer">
              <div id="toolbarViewer">
                <div id="toolbarViewerLeft">
                  <div class="splitToolbarButton hiddenSmallView">
                    <button class="toolbarButton" title="Previous Page" id="previous" tabindex="13" data-l10n-id="previous" @click="prevPage($event)">
                      <span data-l10n-id="previous_label">Previous</span>
                    </button>
                    <div class="splitToolbarButtonSeparator"></div>
                    <button class="toolbarButton" title="Next Page" id="next" tabindex="14" data-l10n-id="next" @click="nextPage($event)">
                      <span data-l10n-id="next_label">Next</span>
                    </button>
                  </div>
                  <input type="number"
                    id="pageNumber"
                    class="toolbarField"
                    title="Page" value="1"
                    size="4" min="1" tabindex="15" data-l10n-id="page" autocomplete="off"
                    @focus="$event.target.select()"
                    @change="changePageNumber">
                  <span id="numPages" class="toolbarLabel"></span>
                </div>
                <div id="toolbarViewerRight">
                </div>
                <div id="toolbarViewerMiddle">
                  <span id="scaleSelectContainer" class="dropdownToolbarButton">
                    <select id="scaleSelect" title="Zoom" tabindex="23" data-l10n-id="zoom" @change="scaleChanged">
                      <option id="pageAutoOption" title="" value="auto" selected="selected" data-l10n-id="page_scale_auto">Automatic Zoom</option>
                      <option id="pageActualOption" title="" value="page-actual" data-l10n-id="page_scale_actual">Actual Size</option>
                      <option id="pageFitOption" title="" value="page-fit" data-l10n-id="page_scale_fit">Page Fit</option>
                      <option id="pageWidthOption" title="" value="page-width" data-l10n-id="page_scale_width">Page Width</option>
                      <option id="customScaleOption" title="" value="custom" disabled="disabled" hidden="true"></option>
                      <option title="" value="0.5" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 50 }'>50%</option>
                      <option title="" value="0.75" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 75 }'>75%</option>
                      <option title="" value="1" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 100 }'>100%</option>
                      <option title="" value="1.25" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 125 }'>125%</option>
                      <option title="" value="1.5" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 150 }'>150%</option>
                      <option title="" value="2" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 200 }'>200%</option>
                      <option title="" value="3" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 300 }'>300%</option>
                      <option title="" value="4" data-l10n-id="page_scale_percent" data-l10n-args='{ "scale": 400 }'>400%</option>
                    </select>
                  </span>
                </div>
              </div>
              <div id="loadingBar">
                <div class="progress">
                  <div class="glimmer">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="viewerContainer" ref="viewerContainer" tabindex="0">
            <div id="viewer" class="pdfViewer"></div>
          </div>

          <div id="errorWrapper" hidden='true'>
            <div id="errorMessageLeft">
              <span id="errorMessage"></span>
              <button id="errorShowMore" data-l10n-id="error_more_info">
                More Information
              </button>
              <button id="errorShowLess" data-l10n-id="error_less_info" hidden='true'>
                Less Information
              </button>
            </div>
            <div id="errorMessageRight">
              <button id="errorClose" data-l10n-id="error_close">
                Close
              </button>
            </div>
            <div id="errorSpacer"></div>
            <textarea id="errorMoreInfo" hidden='true' readonly="readonly"></textarea>
          </div>
        </div> <!-- mainContainer -->

        <div id="dialogContainer">

        </div>  <!-- dialogContainer -->

      </div> <!-- outerContainer -->
      <div id="printContainer"></div>

      <input type="file" id="fileInput" class="hidden">

      <!-- <footer
        class="flex items-center justify-end p-4 border-t bg-grey-lighter"
      >
        <template>
          <button class="button" type="button" @click="hide">
            {{ $t("close") }}
          </button>
        </template>
      </footer> -->
    </div>
  </b-modal>
</template>
<script>
// import pdfjsLib from 'pdfjs-dist/build/pdf'
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import { EventBus, PDFViewer } from 'pdfjs-dist/web/pdf_viewer'


// import 'pdfjs-dist/web/pdf_viewer.css'
import '../../sass/pdf_viewer.scss'

import worker from "pdfjs-dist/build/pdf.worker.entry";
pdfjsLib.GlobalWorkerOptions.workerSrc = worker;
// pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.15.349/build/pdf.worker.min.js";

export default {
  name: "viewer-pdf",
  props: {
    fileName: { type: String, required: true },
  },

  data: () => ({
    isModalActive: false,
    pdfViewer: null,
    eventBus: null,
    pdfDocument: null,
    pagesCount: 0,
  }),

  mounted() {
    this.eventBus = new EventBus;
  },

  computed: {

  },

  methods: {
    async getPdf() {
      await  this.$nextTick()
      let container = document.getElementById("viewerContainer"); // mainContainer
      // let container = this.$refs.containerViewerPdf;
      let viewer = document.getElementById("viewer") // viewerContainer

      const pageColors = {
        background: "Canvas",
        foreground: "CanvasText"
      }

      // console.log('service: ', new PDFViewer.PDFLinkService())

      this.pdfViewer = new PDFViewer({
        container,
        viewer,
        eventBus: this.eventBus,
        pageColors
      });

      // let loadingTask = pdfjsLib.getDocument("./pdf-sample.pdf");
      let loadingTask = pdfjsLib.getDocument(this.fileName);
      let pdf = await loadingTask.promise;
      this.pdfDocument = pdf
      this.pdfViewer.setDocument(this.pdfDocument);
      this.pagesCount =  this.pdfDocument ? this.pdfDocument.numPages : 0;
    },

    show() {
      this.isModalActive = true;
    },

    hide() {
      this.isModalActive = false;
    },

    // event
    nextPage(e) {
      console.log(e.target)
      let eventName = 'nextpage',
          details = e.target
      this.pdfViewer.nextPage()
      this.eventBus.dispatch(eventName, details);
    },

    prevPage(e) {
      console.log(e.target)
      let eventName = 'previouspage',
          details = e.target

      this.pdfViewer.previousPage()
      this.eventBus.dispatch(eventName, details);
    },

    changePageNumber(evt) {
      // const pdfViewer = PDFViewerApplication.pdfViewer;

      if (evt.target.value !== "") {
        this.goToPage(evt.target.value);
      }

      if (evt.value !== this.pdfViewer.currentPageNumber.toString() && evt.value !== this.pdfViewer.currentPageLabel) {
        // PDFViewerApplication.toolbar.setPageNumber(this.pdfViewer.currentPageNumber, this.pdfViewer.currentPageLabel);
      }
    },

    onZoomIn(evt) {
      this.zoomIn(1);
    },

    onZoomOut(evt) {
      this.zoomOut(evt.detail);
    },

    zoomIn(steps) {
      if (this.pdfViewer.isInPresentationMode) {
        return;
      }

      this.pdfViewer.increaseScale(steps);
    },

    zoomOut(steps) {
      if (this.pdfViewer.isInPresentationMode) {
        return;
      }

      this.pdfViewer.decreaseScale(steps);
    },

    zoomReset() {
      if (this.pdfViewer.isInPresentationMode) {
        return;
      }

      this.pdfViewer.currentScaleValue = _ui_utils.DEFAULT_SCALE_VALUE;
    },

    scaleChanged(evt) {
      this.pdfViewer.currentScaleValue = evt.target.value;
    },

    triggerPrinting() {
      // if (!this.supportsPrinting) {
      //   return;
      // }

      window.print();
    },

    async handleDownload() {
      // const url = this._downloadUrl,
      //       filename = this.filename.substring(url.lastIndexOf('/')+1);
      const url = this.fileName,
            filename = this.fileName.substring(url.lastIndexOf('/')+1);;

      try {
        this._ensureDownloadComplete();

        const data = await this.pdfDocument.getData();
        const blob = new Blob([data], {
          type: "application/pdf"
        });

        await this.download(blob, url, filename);
      } catch (reason) {
        await this.download(url, filename);

        // download(url + "#pdfjs.action=download", filename);
      }
    },

    download(blobUrl, filename) {
      const a = document.createElement("a");

      if (!a.click) {
        throw new Error('DownloadManager: "a.click()" is not supported.');
      }

      a.href = blobUrl;
      a.target = "_parent";

      if ("download" in a) {
        a.download = filename;
      }

      (document.body || document.documentElement).append(a);
      a.click();
      a.remove();
    },

    _ensureDownloadComplete() {
      if (this.pdfDocument && this.downloadComplete) {
        return;
      }

      throw new Error("PDF document not downloaded.");
    },


    goToPage(val) {
      if (!this.pdfDocument) {
        return;
      }

      const pageNumber = typeof val === "string" && this.pdfViewer.pageLabelToPageNumber(val) || val | 0;

      console.log('pageNumber: ', pageNumber);

      if (!(Number.isInteger(pageNumber) && pageNumber > 0 && pageNumber <= this.pagesCount)) {
        console.error(`PDFLinkService.goToPage: "${val}" is not a valid page.`);
        return;
      }

      if (this.pdfHistory) {
        this.pdfHistory.pushCurrentPosition();
        this.pdfHistory.pushPage(pageNumber);
      }

      this.pdfViewer.scrollPageIntoView({
        pageNumber
      });
    }


  }
}
</script>
<style scoped>
  #containerViewerPdf {
    position: absolute;
    margin: auto;
    width: 80%;
  }

  div.page {
    display: inline-block;
  }
</style>
