<template>
  <div class="slider bg-grey-darkest overflow-y-scroll" :class="sliderClass" style="z-index: 1000">
    <!-- Info actividad -->
    <div class="info-entity">
      <div class="row-hide">
        <v-button-icon-round
                icon="arrow-right"
                color="blue"
                tooltip-position="left"
                class="ml-6 float-right"
                @click="showSide"/>
      </div>
      <div class="text-grey text-xs">{{$t('activity_label')}}</div>
      <div class="text-white text-sm font-semibold">{{activity.name}}</div>
      <div class="main-info float-left">
        <div class="text-grey text-xs">{{$t('activity_manager')}}</div>
        <div class="flex items-center info-data">
          <div class="rounded-full overflow-hidden border border-grey w-8 h-8 mr-2" v-if="activity.manager && activity.manager['avatar']  && activity.manager['fullname']">
            <img :src="activity.manager['avatar']" :alt="activity.manager['fullname']">
          </div>
          <div>
            <div class="text-blue-lighter text-sm" v-if="activity.manager && activity.manager['fullname']">
              {{ activity.manager['fullname'] }}
            </div>
            <div class="text-grey text-xs" v-if="activity.manager && activity.manager['department']">
              {{ activity.manager['department']['name'] }}
            </div>
          </div>
        </div>
        <div class="text-grey text-xs">{{$t('current_start_date')}}</div>
        <div class="info-data text-white text-xs">{{activity.current_start_date}}</div>
        <div class="text-grey text-xs">{{$t('scheduled_start_date')}}</div>
        <div class="info-data text-white text-xs">{{activity.scheduled_start_date}}</div>
        <div class="text-grey text-xs">{{$t('real_start_date')}}</div>
        <div class="info-data text-white text-xs">{{activity.real_start_date}}</div>
        <div class="text-grey text-xs">{{$t('expected_end_date')}}</div>
        <div class="info-data text-white text-xs">{{activity.expected_end_date}}</div>
      </div>
      <div class="main-info float-left">
        <div class="text-grey text-xs">{{$t('progress_label')}}</div>
        <div class="progress text-center text-green text-2xl font-bold info-data">{{activity.progress}}%</div>
        <div class="text-grey text-xs">{{$t('current_end_date')}}</div>
        <div class="info-data text-white text-xs">{{activity.current_end_date}}</div>
        <div class="text-grey text-xs">{{$t('scheduled_end_date')}}</div>
        <div class="info-data text-white text-xs">{{activity.scheduled_end_date}}</div>
        <div class="text-grey text-xs">{{$t('real_end_date')}}</div>
        <div class="info-data text-white text-xs">{{activity.real_end_date}}</div>
      </div>
      <div class="more-info">
        <div class="text-grey text-xs">{{$t('activity_status')}}</div>
        <div class="info-data text-white text-xs">

          <activity-status-label :status="activity.status" class="w-full" />

        </div>
      </div>
      <div class="more-info">
        <div class="text-grey text-xs">{{$t('activity_description')}}</div>
        <div class="info-data text-white text-xs">{{ activity.description? activity.description : 'Sin descripción' }}</div>
      </div>

      <!-- Tags -->
      <div class="w-full mb-2 mt-2" v-if="activity.tags && activity.tags.length > 0">
        <div class="text-grey text-xs">{{$t('tags')}}</div>
        <visualize-tags
                :tags="activity.tags"
        />
      </div>

      <div class="more-info">
        <div>
          <div class="text-grey text-xs">{{$t('users_participants')}}</div>
          <ul id="participants">
            <li v-for="user in activity.participants" :key="user.user_id" >
              <div class="flex items-center info-data" v-if="user['avatar'] && user['fullname']">
                <div class="rounded-full overflow-hidden border border-grey w-8 h-8 mr-2">
                  <img :src="user['avatar']" :alt="user['fullname']">
                </div>
                <div>
                  <div class="text-blue-lighter text-sm">
                    {{ user['fullname'] }}
                  </div>
                  <div class="text-grey text-xs" v-if="user['area']">
                    {{ user['area'] }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="mt-6" v-if="activity.collaborators && activity.collaborators.length > 0">
          <div class="text-grey text-xs">{{$t('users_collaborators')}}</div>
          <ul id="collaborators">
            <li v-for="user in activity.collaborators" :key="user.user_id" >
              <div class="flex items-center info-data" v-if="user['avatar'] && user['fullname']">
                <div class="rounded-full overflow-hidden border border-grey w-8 h-8 mr-2">
                  <img :src="user['avatar']" :alt="user['fullname']">
                </div>
                <div>
                  <div class="text-blue-lighter text-sm">
                    {{ user['fullname'] }}
                  </div>
                  <div class="text-grey text-xs" v-if="user['area']">
                    {{ user['area'] }}
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="more-info">
        <div class="text-grey text-xs">{{$t('log')}}</div>
        <ul id="log-participants">
          <li v-for="action of logActions" class="p-2" :key="action.id">

            <div class="float-left w-full">

              <div class="float-left rounded-full overflow-hidden w-8 h-8">
                <img :src="action.author.avatar" :alt="action.author.fullname">
              </div>

              <div class="float-left rounded-full uppercase px-2 py-1 text-xs text-white ml-3" :class="action.entity_type_color">{{ action.entity_type }}</div>
              <div class="float-left rounded-full uppercase px-2 py-1 text-xs text-white ml-3" :class="action.action_type_color">{{ action.action_type }}</div>

            </div>

            <div class="float-left w-full text-grey mb-4">

              <div class="text-xs text-left" style="margin-left:50px;">{{ action.created_at }}</div>

            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import activityStatusLabel from '·/components/activity-status-label'
import VisualizeTags from "·/components/visualize-tags";

export default {
  name: 'activity-info-sidebar',

  props: {
    activity: { type: Object, required: true }
  },

  components: {
    activityStatusLabel,
    VisualizeTags
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },

  data: () => ({
    sliderClass: '',
    sliderShown: false,
    logActions: undefined,
  }),

  methods: {

    async showSide() {
      this.sliderClass = this.sliderShown ? 'slide-out' : 'slide-in'
      this.sliderShown = !this.sliderShown
      //Obtenemos el log de la actividad
      if(!this.logActions){
        const { data } = await axios.get('/api/v2/activity/' + this.activity.id + '/log?h=1')
        if (data && data.success) {
          this.logActions = data.data
        }

      }
    },
  },

};
</script>

<style scoped>

  .slider {
    position: fixed;
    top: 61px;
    right: 0;
    bottom: 0;
    width: 360px;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  .slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }
  .slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
  }
  @keyframes slide-in {
    100% { transform: translateX(0%); }
  }
  @-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
  }
  @keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(100%); }
  }
  @-webkit-keyframes slide-out {
    0% { -webkit-transform: translateX(0%); }
    100% { -webkit-transform: translateX(100%); }
  }

  .info-entity{padding: 20px;}
  .info-entity .row-hide{float: right; width: 100%;}
  .info-entity .main-info{padding-right: 12px; margin-top: 20px; width: 50%;}
  .info-entity .main-info~.main-info{padding-right: 0;}
  .info-entity .info-data{margin-top: 10px; margin-bottom: 10px;}
  .info-entity .more-info{display: inline-block; width: 100%; margin-top: 20px;}
  .info-entity .info-data.progress{height: 58px;}

  .timeline-box {
    position: relative;
    margin-top: 20px;
  }
  .timeline-box:before {
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    margin-left: 0;
    content: '';
    border-left: 1px solid transparent;
    border-color: #ffffff;
  }
  .timeline-box .tl-row {
    position: relative;
    padding: 10px 0;
  }
  .timeline-box .tl-row .tl-item {
    width: 50%;
  }
  .timeline-box .popover {
    min-width: 50px;
  }
  .timeline-box .tl-row .tl-item .popover {
    position: relative;
    display: block;
    margin: 0;
    box-shadow: 0 0 0 transparent;
  }
  .timeline-box .tl-row .tl-item .popover.left {
    float: right;
    margin-right: 35px;
  }
  .timeline-box .tl-row .tl-item.float-right .popover.right {
    margin-left: 35px;
    background: white;
  }
  .timeline-box .tl-row .tl-item .tl-panel {
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    margin-top: -8px;
    margin-left: 23px;
    text-align: left;
  }
  .timeline-box .tl-row .tl-item.float-right .tl-panel {
    right: 50%;
    left: auto;
    margin-right: 23px;
    margin-left: 0;
    text-align: right;
  }
  .timeline-box .tl-row .tl-item .tl-title {
    font-size: 15px;
    font-weight: bold;
    margin: 0 0 10px;
  }
  .timeline-box .tl-row .tl-item .tl-label {
    display: inline-block;
    margin-bottom: 10px;
    padding: 5px 8px;
  }
  .timeline-box .tl-row .tl-item .tl-content {
    font-size: 13px;
    margin: 0 0 10px;
    opacity: .80;
    -moz-opacity: .80;
    filter: alpha(opacity: 80);
  }
  .timeline-box .tl-row .tl-item .tl-time {
    font-style: italic;
    opacity: .40;
    -moz-opacity: .40;
    filter: alpha(opacity: 40);
  }
  .timeline-box .tl-row .tl-item .tl-icon,
  .timeline-box .tl-row .tl-item .tl-bullet {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 17px;
    height: 17px;
    margin-top: -9px;
    margin-left: -8px;
    border-radius: 100px;
  }
  .timeline-box .tl-row .tl-item .tl-bullet:before {
    display: block;
    width: 9px;
    height: 9px;
    margin: 4px 0 0 4px;
    content: '';
    border-radius: 100px;
    background: #fff;
  }
  .timeline-box .tl-row .tl-item .tl-icon {
    font-size: 18px;
    line-height: 34px;
    width: 34px;
    height: 34px;
    margin-top: -18px;
    margin-left: -17px;
    text-align: center;
  }
  /* Timeline Horizontal */

  .timeline-scroll {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .timeline-horizontal {
    height: 400px;
    padding: 10px 0;
  }
  .timeline-horizontal .tl-row {
    float: left;
    width: 400px;
    height: 173px;
  }
  .timeline-horizontal.timeline-box:before {
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    border: 0;
  }
  .timeline-horizontal.timeline-box .tl-row .tl-item {
    width: auto;
  }
  .timeline-horizontal.timeline-box .tl-row .tl-item .tl-panel {
    top: auto;
    bottom: -60px;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: center;
  }
  .timeline-horizontal.timeline-box .tl-row .tl-item .tl-bullet {
    margin-left: -9px;
  }
  .timeline-horizontal.timeline-box .tl-row .tl-item .tl-icon,
  .timeline-horizontal.timeline-box .tl-row .tl-item .tl-bullet {
    top: auto;
    bottom: -27px;
  }
  .timeline-horizontal.timeline-box .tl-row .tl-item .popover {
    position: absolute;
    top: auto;
    bottom: 10px;
    width: 100%;
  }
  .timeline-horizontal.timeline-box .tl-row .tl-item.float-right .tl-panel {
    bottom: 0;
  }
  .timeline-horizontal.timeline-box .tl-row .tl-item.float-right .popover {
    top: 220px;
    bottom: auto;
  }

  /* Timeline columns */

  .timeline-box-right.timeline-box .tl-row .tl-item,
  .timeline-box-left.timeline-box .tl-row .tl-item {
    width: auto;
    float: none !important;
  }

  .timeline-box-right.timeline-box .tl-row .tl-item .popover,
  .timeline-box-left.timeline-box .tl-row .tl-item .popover {
    float: none;
  }

  .timeline-box-right.timeline-box:before {
    left: auto;
    right: 17px;
  }

  .timeline-box-right.timeline-box .tl-row {
    padding-right: 20px;
  }

  .timeline-box-right.timeline-box .tl-row .tl-item .tl-icon,
  .timeline-box-right.timeline-box .tl-row .tl-item .tl-bullet {
    left: auto;
    right: 17px;
    margin-left: 0;
    margin-right: -17px;
  }

  .timeline-box-left.timeline-box:before {
    right: auto;
    left: 17px;
  }

  .timeline-box-left.timeline-box .tl-row {
    padding-left: 20px;
  }

  .timeline-box-left.timeline-box .tl-row .tl-item .tl-icon,
  .timeline-box-left.timeline-box .tl-row .tl-item .tl-bullet {
    right: auto;
    left: 17px;
    margin-right: 0;
    margin-left: -17px;
  }

  .popover-content{padding: 12px;}

  .popover.right > .arrow:after {
    bottom: -10px;
    left: 1px;
    content: " ";
    border-right-color: #fff;
    border-left-width: 0;
  }
  .popover.right .arrow:after {
    bottom: -10px;
    left: 1px;
    border-right-color: rgba(0, 0, 0, .2);
    border-left-width: 0;
  }

  body .popover.right .arrow:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent #ffffff transparent transparent;
    left: -15px !important;
    position: absolute;
    top: 43%;
  }
</style>
