<template>
<input ref="input"
  class="input"
  :type="type"
  :id="id"
  :name="name"
  :placeholder="$t(placeholder)"
  autocomplete="off"
  :autofocus="setAutofocus"
  :value="value"
  @input="updateValue($event.target.value)"
/>
</template>

<script>

export default {
  name: 'v-input',

  props: {
    value: { type: String, default: null },
    type: { type: String, default: 'text' },
    id: { type: String, default: null },
    name: { type: String, default: null },
    placeholder: { type: String, default: null },
    focus: { type: Boolean, default: false },
  },

  data: () => ({
    setAutofocus: null
  }),

  methods: {
    updateValue(value) {
      this.$emit('input', value)
    },
    setFocus() {
      this.$refs.input.focus()
    }
  },

  mounted() {
    if (this.focus) {
      this.setFocus()
      this.setAutofocus = true
    }
  },
};
</script>
