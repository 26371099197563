<template>
  <div>
    <div class="top-bar flex justify-end">
      <v-button
        @click="() => { showFormImportEntities = !showFormImportEntities }"
        icon="file-import"
      >{{ $t('import_assignment_intervention') }}</v-button>

      <v-button
        @click="showCreate = !showCreate"
        class="ml-4"
        icon="plus">
        {{ $t('new_f') }}
      </v-button>
    </div>
    <div>
      <div class="flex">
        <div :class="showFormImportEntities ? 'panel w-7/12 mr-2':'panel w-full'">

          <div class="w-full flex flex-wrap" v-show="showCreate">
            <div class="panel">
              <form @submit.prevent="create" class="w-full flex">
                <div class="flex-grow pr-3">
                  <label for="spec_type" class="w-full">{{ $t('select_type') }}</label>
                  <b-select ref="input"
                    v-model="item.type"
                    :placeholder="$t('select_type')"
                    id="spec_type"
                    name="spec_type"
                    class="w-full"
                    required
                    expanded
                  >
                    <option v-for="option in types" :value="option" :key="option">
                      {{ option }}
                    </option>
                  </b-select>
                </div>

                <div class="flex-grow pr-3">
                  <label for="code" class="w-full">{{ $t('code') }}</label>
                  <v-input
                    v-model="item.code"
                    title="code"
                    id="code"
                    name="code"
                    placeholder="code"
                    class="w-full"
                    required
                  />
                </div>

                <div class="flex-grow pr-3">
                  <label for="value" class="w-full">{{ $t('value') }}</label>
                  <v-input
                    v-model="item.value"
                    title="value"
                    id="value"
                    name="value"
                    placeholder="value"
                    class="w-full"
                    required
                    native-type="number"
                  />
                </div>

                <div class="flex-grow-0 pr-3 inline-flex self-end">
                  <v-button
                    native-type="submit"
                    :disabled="!item.value || !item.code || !item.type"
                  >{{ $t('save') }}
                  </v-button>
                </div>
              </form>
            </div>
          </div>

          <b-table
            show-empty
            :data="data"
            :loading="isLoading"
            paginated
            striped
            bordered
            icon-pack="fas"
          >
            <template v-slot:empty="">
              <h4>{{ $t('no_rows') }}</h4>
            </template>

            <template>
              <b-table-column field="code" >
                <template v-slot:header="">
                  {{ $t('code') }}
                </template>
                <template v-slot="props">
                  {{ props.row.code }}
                </template>
              </b-table-column>

              <b-table-column field="type" >
                <template v-slot:header="">
                  {{ $t('type') }}
                </template>
                <template v-slot="props">
                  {{ props.row.type }}
                </template>
              </b-table-column>

              <b-table-column field="value" >
                <template v-slot:header="">
                  {{ $t('value') }}
                </template>
                <template v-slot="props">
                  {{ props.row.value }}
                </template>
              </b-table-column>

              <b-table-column field="actions"  centered width="80">
                <template v-slot:header="">
                  {{ $t('actions') }}
                </template>
                <template v-slot="props">

                  <div class="w-full h-full flex items-center justify-around">
                      <div>
                        <b-tooltip :label="$t('edit')" position="is-left">
                          <div @click="edit(props.row)" class="cursor-pointer flex text-blue">
                            <b-icon pack="fas" icon="pencil-alt"/>
                          </div>
                        </b-tooltip>
                        <b-tooltip
                          :label="$t('remove')"
                          type="is-danger"
                          position="is-left"
                        >
                          <div @click="remove(props.row)" class="cursor-pointer flex text-red">
                            <b-icon pack="fas" icon="trash-alt"/>
                          </div>
                        </b-tooltip>
                      </div>

                  </div>
                </template>
              </b-table-column>
            </template>
          </b-table>

        </div>
        <div class="panel w-5/12" v-if="showFormImportEntities">
          <import-entities
            :can-overwrite="false"
            :project-id="projectId"
            :withHistory="false"
            entity-type='assignment-interventions'
            @success="() => getData()"
          />
        </div>
      </div>

      
      <assignment-intervention-edit-modal
        ref="assignment_intervention_edit_modal"
        :types="types"
        @reload="() => reload()"
      />
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import AssignmentInterventionEditModal from './assignment-intervention-edit-modal'
  import importEntities from '../../import-entities'   

  export default {
    name: 'assignment-intervention',

    components: {
      AssignmentInterventionEditModal,
      importEntities
    },

    data: () => ({
      data: [],
      types: ['ALC', 'SIS'],
      isLoading: true,
      showCreate: false,
      item: {
        type: 'ALC',
        code: "",
        value: null
      },
      showFormImportEntities: false
    }),

    props: {
      projectId: {type: Number, required: true}
    },

    mounted() {
      this.getData();
      this.isLoading = false;
    },

    methods: {
      async getData() {
        let {data} = await axios.get('/api/v2/assignment-interventions', {params: {p: this.projectId}});

        if (data.success) {
          this.data = data.data
        } else {
          this.$notify.error("error_loading_data");
        }
      },

      reload() {
        this.isLoading = true;
        this.getData();
        this.isLoading = false;
      },

      async create() {
        this.item.project_id = this.projectId

        this.isLoading = true;
        let {data} = await axios.post('/api/v2/assignment-intervention', this.item)
        if (data.success) {
          this.$notify.success('save_successful')
          this.getData();
          this.item.code = null
          this.item.value = null
        } else {
          this.$notify.error('error_saving')
        }
        this.isLoading = false;
      },

      async edit(item) {
        this.$refs.assignment_intervention_edit_modal.show(item)
      },

      async remove(item) {
        if (item.activity_interventions_count) {
          this.$toast.open({
            message: 'No se puede eliminar porque tiene actividades asociadas',
            type: "is-danger",
            position: "is-top-right"
          });
          return
        }
        
        this.$dialog.confirm({
          title: this.$t("delete"),
          message: this.$t("delete_confirm_text", ['La actuación', item.name]),
          confirmText: this.$t("delete"),
          type: "is-danger",
          hasIcon: true,
          onConfirm: async () => {
            this.isLoading = true;
            let url = `/api/v2/assignment-intervention/${item.id}`;
            const { data } = await axios.delete(url);

            if (data.success) {
              this.$toast.open({
                message: this.$t("removed_successfully"),
                type: "is-success",
                position: "is-top-right"
              });

              this.getData();
            } else {
              // Error
              this.$toast.open({
                message: this.$t("remove_error"),
                type: "is-danger",
                position: "is-top-right"
              });
            }
            this.isLoading = false;
          }
        });
      }
    }
  };
</script>
