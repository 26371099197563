<template>
  <div class="canvasContainer w-full h-full overflow-scroll-" ref="canvasContainer">
    <div id="viewer-pixi" ref="refCanvasPixi" style="width: 100%; height: 100%; position: relative;"> </div>
  </div>
</template>

<script>
import axios from "axios"
import { DataPictogram, PixiViewer } from './utils/index'

import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import store from "~/store";

export default {
    name: "KonvaViewer",

    components: {
    },

    props: {
        pictogram: {
          type: Object,
          default() { return {} },
        },

        workLayer: { type: String, default: null },

        options: {
            default() {
                return {
                    clearColor: "#fff",
                    autoResize: true,
                    colorCorrection: true,
                    sceneOptions: {
                        wireframeMesh: true
                    }
                }
            }
        },

      typeRegister: { type: String, default: 'progress' }, // bandera para indicar que tipo de registro se hace
      canActionsPictogram: { type:Object, default() { return {} } }, // acciones que se puede realizar sobre el pictograma por defecto ninguna
      showLayerCompleted: { type: Boolean, default: false } // visualiza o oculta la capa de estructuras completadas
    },

    data() {
        return {
            isLoading: false,
            progress: null,
            progressText: null,
            curProgressPhase: null,
            error: null,

            dataPictogram: null,

            pics: [], // contiene todas las entidades graficables
            filterPics: [], // solo contiene las entidades graficables filtradas segun tipo 'structure', 'piling', 'module'

            arrTexts: [], // lista de textos a visualizar en la capa respectiva
            arrGroups: [], // para simular una agrupación
            arrStrucComplete: [], // lista de estructuras con estado completado para mostrar como fondo cuando se visualizan los modulos

            popupPosition: { x: -999, y: 0 },
            popupData: { type: null, name: null, status: null, progress: 0 },
            popupXDistanceMin: 0,
            popupYDistanceMin: 0,

            popoverPosition: { x: -9999, y: 0 },
            popoverData: { type: null, name: null, status: null, progress: 0 },
            popoverXDistanceMin: 0,
            popoverYDistanceMin: 0,

            filterStatuses: [], // estados filtrados segun se este visualizando estructuras hincados o modulos
            modifiedEntities: [], // lista de objetos que se modificaron y se mandaran para actualizar el general
            selectedObjects: [], // contiene los objetos seleccionados sobre los cuales se han de realizar acciones o acción

            toggleDrawing: false,
            regions: [],
            isDrawing: false,
            idRegion: 0,

            startCoords: null,
            endCoords: null,
            rectSelect: null,
            isDraggable: true,

            // fechas en las que se ha realizado el avance
            start_date: null,
            end_date: null,
        }
    },

    computed: {
      showTooltip: function() {
        return true //this.popupData.type != null
      },

      showPopover: function() {
        return true //this.popoverData.type != null
      },
    },

    watch: {
      showLayerCompleted: function(newValue, oldValue) {
        this.ShowPicComplete(newValue)
      }
    },

    methods: {

      getRelativePointerPosition(node) {
        // the function will return pointer position relative to the passed node
        const transform = node.getAbsoluteTransform().copy();
        // to detect relative position we need to invert transform
        transform.invert();

        // get pointer (say mouse or touch) position
        const pos = node.getStage().getPointerPosition();

        // now we find relative point
        return transform.point(pos);
        // return pos;
      },

      _handleMouseDownStage(e) {
        if(this.isDraggable && !this._AllowedAction('select_entity') )
          return

        this.toggleDrawing = true;
        this.isDrawing = true
        const point = this.getRelativePointerPosition(e.target.getStage());

        this.startCoords = point

        this.rectSelect = {
          x: point.x,
          y: point.y,
          width: 0,
          height: 0,
          stroke: 'black',
          fill: "#0c5e95", //"#b3e5ea",
          strokeWidth: 0,
          opacity: 0.6
        }
      },

      _handleMouseMoveStage(e) {
        // if (!this.isDrawing) {
        //   return;
        // }
        // const point = this.getRelativePointerPosition(e.target.getStage());

        // this.endCoords = point
        // let currX = point.x

        // let direction = null
        // if (currX > this.startCoords.x) { // movimiento a la derecha
        //   this.rectSelect.fill = "#0c5e95"
        //   direction = 'right'
        // } else if (currX < this.startCoords.x) { /// movimiento a la izquierda
        //   this.rectSelect.fill = "#5e950c"
        //   direction = 'left'
        // }

        // this.rectSelect.width = this.endCoords.x - this.rectSelect.x
        // this.rectSelect.height = this.endCoords.y - this.rectSelect.y

        // this.$refs.regions.getNode().getLayer().draw()

        // // determinamos con quienes intersecta el area
        // let nodeLayer = this.$refs.layer.getNode().getLayer()
        // let objects = nodeLayer.getChildren().filter((el) => { return !isNaN(el.attrs.id)  })
        // let areaReact = this.rectSelect
        // let objetosDebajo = []

        // // objects.forEach(function(objeto) {
        // // })

        // for (const objeto of objects) {
        //   let indexObjectSelected = this._IndexObjetoSelected(objeto)

        //   // Verificamos si el objeto esta contenido en el rectangulo
        //   if ( this._inArea(direction, objeto, areaReact)) {

        //     // El objeto intersecta con el área, añadirlo al array
        //     if (indexObjectSelected === -1) {
        //       this.selectedObjects.push(objeto);
        //       objeto.fill('#fff7b3');
        //       objeto.opacity(0.6)
        //     }
        //   } else {
        //     // si no esta deseleccionamos
        //     if (indexObjectSelected > -1) {
        //       this.selectedObjects.splice(indexObjectSelected, 1);
        //       objeto.fill( objeto.attrs.status ? objeto.attrs.status.color : null );
        //       objeto.opacity(1)
        //     }
        //   }
        // }
      },

      _inArea(direction, objeto, areaReact) {

        if (direction == 'right') {

          return objeto.attrs.x < areaReact.x + areaReact.width &&
            objeto.attrs.x + objeto.attrs.width > areaReact.x &&
            objeto.attrs.y < areaReact.y + areaReact.height &&
            objeto.attrs.y + objeto.attrs.height > areaReact.y

        } else if (direction == 'left') {

          return objeto.attrs.x < areaReact.x
            && objeto.attrs.x > areaReact.x + areaReact.width
            && objeto.attrs.y > areaReact.y
            && objeto.attrs.y < areaReact.y + areaReact.height
        }
      },

      async Load() {
        this.isLoading = true
        this.$emit('epc-isloading', true)
        this.error = null

        try {
          if (!this.pictogram.preview)
            await this.dataPictogram.Load({ url: `/api/v2/pictogram/${this.pictogram.id}/json` })
          else {
            this.dataPictogram.SetDataPictogram(this.pictogram.scene)
            // this.konvaViewer.FitToScreen()
          }

          this.$emit('epc-loaded');
        } catch (error) {
            console.warn(error)
            this.error = error.toString()
        } finally {
            this.$emit('epc-isloading', false)
            this.isLoading = false
            this.progressText = null
            this.progress = null
            this.curProgressPhase = null
        }
      },


      async Render(type, typePictogram) {
        this.konvaViewer.Render(type, typePictogram)
        this.filterStatuses = await this._FilterStatuses( type )
      },

      GetLayers() {
        return this.konvaViewer.GetLayers()
      },

      EnableDraggable(enable) {
        this.isDraggable = enable
        this.konvaViewer.EnableDraggable(enable)
      },

      // Metodo para pasar los objetos seleccionas al array modifiedEntities que se guardará, deja en blanco el array de
      // objetos seleccionado
      CompleteAction(data) {
        // Pasamos todos los objetos seleccionados a modifiedEntities
        this.selectedObjects.forEach((item, i) => {
          let indexEntity = this.modifiedEntities.findIndex( (obj) => { return parseInt(obj.id) === parseInt(item.picId) } )

          if (indexEntity !== -1) {
            // no cambiamos propiedad so (estado anterior) por que no
            if (this.typeRegister === 'progress')
              this.modifiedEntities[indexEntity].status = typeof item.picStatus === 'object' ? item.picStatus.id : item.picStatus
            else // typeRegister === 'issue'
              this.modifiedEntities[indexEntity].withIssue = item.picWithIssue
          }
          else {
            let objEntity = {
              id: item.picId,
              name: item.picName,
              type: item.picType,
            }

            if (this.typeRegister === 'progress') {
              objEntity.previou_status = item.picPreviouStatus // estado anterior
              objEntity.status = (item.picStatus != null && typeof item.picStatus === 'object') ? item.picStatus.id : item.picStatus
            }
            else { //typeRegister === 'issue'
              objEntity.withIssue = item.picWithIssue
            }

            this.modifiedEntities.push(objEntity)
          }

        });

        this.start_date = data.start_date
        this.end_date = data.end_date
        this.selectedObjects = []
      },

      ShowLayer(name, show) {
        const layer = this.GetLayers().find(  (l) => l.name == name)
        if (!layer) {
            return
        }

        for (const obj of this.konvaViewer.GetEntitiesDesign(this.workLayer)) {
            if ( obj.picLayerName == name)
              obj.visible = show
        }
      },

      async Save() {
        this.$emit('epc-saving', { loading: true, text: "Generando imagenes" })

        // 1.- Obtenemos las capturas de las diferentes capas (screenshot en formato png)
        let screenshots = await this.GetScreenshotsLayers()

        // 2.- Guardamos los datos del pictograma
        this.$emit('epc-saving', { loading: true, text: 'Guardando pictograma' })
        // primero guardamos las entidades modificadas si todo va bien recien actualizamos datos meta del pictograma y registramos
        // progreso realizado por el usuario
        let response = await this._UpdateEntities()
        if ( response ) {
          // console.log('response: ', response)
          this.$emit('epc-saving', { loading: true, text: 'Guardando capturas' })
          let arrAwsResponse = []
          for (const item of screenshots) {
            const awsr = await this.submitResource(item)
            arrAwsResponse.push(awsr)
          }

          this.$emit('epc-saving', { loading: true, text: 'Guardando datos del pictograma' })
          response = await this._UpdateDataPictogram(response, arrAwsResponse);
        }

        if (response) this.$emit('epc-reload')

        this.$emit('epc-saving', { loading: false, text: null })
      },

      // Method que obtiene las capturas de las diferentes capas
      async GetScreenshotsLayers() {

        // TODO: para guardar las tres capturas en uno hacer que si worklayers es null or all tomar this.pictogram.layers
        // analizar
        let layers = [{ code: this.workLayer}]
        if(this.pictogram.type !== 'photovoltaic_park') {
          layers.push({code: 'general'}) // hacks para pictogramas de lineas de evacuacion para general la imagen general
        }
        let screenshots = []
        // console.log('layers: ' , layers)
        for (const lyr of layers) {

          // TODO: esta linea comentada activar cuando se guarde las 3 capturas
          // this._OnClickDisplayLayerPictogram(lyr.code)
          // await this.sleep(5000) // enves de realizar el fit pausamos 1 segundo

          await this.konvaViewer.FitToScreen()
          .then(async (result) => {
            let img = await this.konvaViewer.TakeScreenshot()
            screenshots.push({
              name: lyr.code + '.png',
              src: img
            })
          })

          await this.sleep(500) // Por si las moscas esperamos 1/2 seg entre captura
        }

        // TODO: Activar cuando se guarden las tres capturas (analizar antes)
        // this._OnClickDisplayLayerPictogram(this.displayPictogramLayer)
        // await this.sleep(3000)

        return screenshots
      },

      async _UpdateEntities() {
        let form = new FormData();
        form.append('modified_entities', JSON.stringify(this.modifiedEntities))
        form.append('work_layer', this.workLayer)

        let url = `/api/v2/pictogram/${this.pictogram.id}/update-entities`

        // Enviamos el form
        return await axios.post(url, form)
          .then((response) => {
            let data = response.data

            if (data && data.success) {

              console.log('%cEPC-TACKER: '+ '%c entities actualizado correctamente.', 'background: #5577BB; color: #fff', 'color: #000')

              return data.data

            } else if(data && data.msg){

              this.$notify.error('no_access_permissions')

            } else {
              this.$notify.error('error_saving')
            }
          })
          .catch(error => {
            // Manejar errores aquí
            if (error.response) {
              // La solicitud fue hecha y el servidor respondió con un estado de error
              this.$notify.error(error.response.data.message)
              // console.log('Error de respuesta:', error.response.status, error.response.data);
              console.error('%cEPC-TACKER: '+ '%c Error de respuesta:', 'background: #5577BB; color: #fff', 'color: #000', error.response.status, error.response.data)
            } else if (error.request) {
              // La solicitud se realizó pero no se recibió respuesta del servidor
              this.$notify.error('No se recibió respuesta del servidor (E500)')
              console.error('%cEPC-TACKER: '+ '%c No se recibió respuesta del servidor', 'background: #5577BB; color: #fff', 'color: #000')
            } else {
              // Ocurrió un error antes de la solicitud, como una configuración incorrecta de Axios
              this.$notify.error('Error de configuración')
              console.error('%cEPC-TACKER: '+ '%c Error de configuración:', 'background: #5577BB; color: #fff', 'color: #000', error.message)
            }

            return null;
          })
          // .finally(() => {
          //   this.$emit('epc-saving', { loading: false, text: null })
          // })
      },

      async _UpdateDataPictogram(partialProgress, awsResponseImages) {
        // 1. contabilizar primero total de entidades por typo y estado
        // TODO: Este paso ya no es necesario, por que esta devolviendo el progreso total anterior al momento de cargar
        // el archivo solo estamos realizando un proceso inecesario directo el calculo lo realizamos en el servidor
        // enviando solo el progreso realizado por el usuario, lo dejamos comentado de momento
        // let totalStatausProgress = this._CalulateProgress(this.filterPics) // total progreso ya es calculado apartir del partian en backend

        // 2. contabilizar total de entidades por tipo y estado modificadas por el usuario
        // let statusesProgress = this._CalulateProgress(this.modifiedEntities) // ahora ya no calculamos apartir de los modificados sino desde el diseño
        let statusesProgress = this._CalulateProgressInDesign()
        let statusesExecuted = this._CalulateExecuted()
        // 3. preparar para mandar al backend
        let form = new FormData();

        form.append('work_layer', this.workLayer)
        // form.append('total_progress', JSON.stringify(totalStatausProgress))
        form.append('partial_progress', JSON.stringify(partialProgress))
        form.append('statuses_progress', JSON.stringify(statusesProgress))
        form.append('statuses_executed', JSON.stringify(statusesExecuted))
        form.append('aws_images', JSON.stringify(awsResponseImages))
        form.append('start_date', this.start_date)
        form.append('end_date', this.end_date)

        let url = `/api/v2/pictogram/${this.pictogram.id}/update-progress` // add url

        // Enviamos el form
        return await axios.post(url, form)
          .then((response) => {
            let data = response.data

            if (data && data.success) {
              this.$notify.success('success_editing')
              console.log('%cEPC-TACKER: '+ '%c pictograma actualizado correctamente.', 'background: #5577BB; color: #fff', 'color: #000')

              return true;
            } else if(data && data.msg){
              this.$notify.error('no_access_permissions')
            } else {
              this.$notify.error('error_saving')
            }

            return false;
          })
          .catch(error => {
            // Manejar errores aquí
            if (error.response) {
              // La solicitud fue hecha y el servidor respondió con un estado de error
              this.$notify.error(error.response.data.message)
              // console.log('Error de respuesta:', error.response.status, error.response.data);
              console.error('%cEPC-TACKER: '+ '%c Error de respuesta:', 'background: #5577BB; color: #fff', 'color: #000', error.response.status, error.response.data)
            } else if (error.request) {
              // La solicitud se realizó pero no se recibió respuesta del servidor
              this.$notify.error('No se recibió respuesta del servidor (E500)')
              console.error('%cEPC-TACKER: '+ '%c No se recibió respuesta del servidor', 'background: #5577BB; color: #fff', 'color: #000')
            } else {
              // Ocurrió un error antes de la solicitud, como una configuración incorrecta de Axios
              this.$notify.error('Error de configuración')
              console.error('%cEPC-TACKER: '+ '%c Error de configuración:', 'background: #5577BB; color: #fff', 'color: #000', error.message)
            }

            return false;
          })
          // .finally(() => {
          //   this.$emit('epc-saving', { loading: false, text: null })
          // })
      },

      async submitResource(resource) {
        const blob = resource.src;
        blob.name = resource.name; // TODO: al nombre del recurso añadir marca de tiempo y name company o project who prefix
        return this.store(blob, {})
        .then(async result => {
          // successFul contiene toda la lista de archivos que se han subido
          // para nuestro caso solo consideramos siempre el primero por que enviamos de a uno
          if( result.successful.length > 0 ) {
            let response = result.successful[0].response

            let params = {
              // uuid: response.uuid,
              key: response.body.key,
              // bucket: response.bucket,
              name: blob.name,
              content_type: blob.type,
              // url: response.url
            }
            return params
            // form.append("aws_response", JSON.stringify(params));
          }

          // failed contiene todos los archivos que tubieron fallo al subir
          if (result.failed.length > 0) {
            console.error('Errors:')
            result.failed.forEach((file) => {
              console.error(file.error)
            })

            return null
          }
        }).catch((error) => {
          console.log('error: ', error)
          return null
        });
      },

      // CUSTOM VAPOR STORE METHOD
      async store(file, options = null) {
        // verificamos si existe algun archivo en la lista de carga de uppy
        // si existe lo eliminamos
        if( this.keyFileUploadCurrent ) {
          this.uppy.removeFile(this.keyFileUploadCurrent);
        }

        if (typeof options.progress === 'undefined') {
            options.progress = () => {};
        }

        this.keyFileUploadCurrent = this.uppy.addFile({
          name: file.name, // file name
          type: file.type, // file type
          data: file, // file blob
          // meta: {
          //   // optional, store the directory path of a file so Uppy can tell identical files in different directories apart.
          //   relativePath: webkitFileSystemEntry.relativePath,
          // },
          source: 'Local',
          isRemote: false,
        });

        // response.data.extension = file.name.split('.').pop()
        return this.uppy.upload()
      },

      _Filter(entities, filter) {
        return entities.filter( (p) => p.type === filter );
      },

      _FilterEntitiesDesign(filter) { //usado porpixi
        return this.konvaViewer.GetEntitiesDesign(filter);
      },

      _FilterStatuses(filter, onlyCompletion=false) {
        let layer = this.pictogram.layers.find((item) => { return item.code == filter })

        if( layer ) {
          return onlyCompletion ? layer.statuses.filter((item) => { return item.is_completion }) : layer.statuses
        }
        return []
      },

      _CalulateProgressInDesign() {

        let progress = { }

        if ('photovoltaic_park' === this.pictogram.type) {
          progress = {
            structures: {
              '-1': 0
            },
            pilings: {
              '-1': 0
            },
            modules: {
              '-1': 0
            }
          }
        } else {
          progress = {
            sections: {
              '-1': 0
            },
          }

          if ('aerial_evacuation_line' === this.pictogram.type) {
            progress.nodes = { '-1': 0 }
          }
        }

        let filterStatuses = this._FilterStatuses(this.workLayer)
        let filterEntities = this._FilterEntitiesDesign(this.workLayer)
        // determinamos primero los que no tiene status
        progress[this.workLayer]['-1'] = filterEntities.filter( (o) => o.picStatus == null ).length;

        // determinamos cantidad por status
        filterStatuses.forEach( (status) => {

          progress[this.workLayer][status.id] = filterEntities.filter( (o) => {
            if (o.picStatus != null) {
              // // si existe un estado anterior verificamos si ese estado no representa completado para evitar
              // // volver a contabilizar el mismo
              // if (o.previou_status != null) {
              //   if (filterStatuses.find(ps => { return ps.id ==  o.previou_status }) != null)
              //     return false
              // }

              let idStatus = typeof o.picStatus === 'object' ? o.picStatus.id : o.picStatus;
              return idStatus == status.id

            } else
              return false
          }).length;
        })

        return progress
      },

      _CalulateProgress(entities) {
        let progress = {
          structures: {
            '-1': 0
          },
          pilings: {
            '-1': 0
          },
          modules: {
            '-1': 0
          }
        }

        //TODO: Optimización solo manda los datos en la capa que se ha trabajadado (this.workLayer) no en todas, de momento dejamos comentado el codigo
        // this.pictogram.layers.forEach( (layer, index) => {
        //   let filterStatuses = this._FilterStatuses(layer.code)
        //   let filterEntities = this._Filter(entities, layer.code)

        //   // determinamos primero los que no tiene status
        //   progress[layer.code]['-1'] = filterEntities.filter( (o) => o.status == null ).length;

        //   // determinamos cantidad por status
        //   filterStatuses.forEach( (status) => {
        //     progress[layer.code][status.id] = filterEntities.filter( (o) => o.status != null && o.status.id == status.id ).length;
        //   })
        // })

        let filterStatuses = this._FilterStatuses(this.workLayer)
        let filterEntities = this._Filter(entities, this.workLayer)
        // determinamos primero los que no tiene status
        progress[this.workLayer]['-1'] = filterEntities.filter( (o) => o.status == null ).length;

        // determinamos cantidad por status
        filterStatuses.forEach( (status) => {
          progress[this.workLayer][status.id] = filterEntities.filter( (o) => {
            if (o.status != null) {
              // // si existe un estado anterior verificamos si ese estado no representa completado para evitar
              // // volver a contabilizar el mismo
              // if (o.previou_status != null) {
              //   if (filterStatuses.find(ps => { return ps.id ==  o.previou_status }) != null)
              //     return false
              // }

              let idStatus = typeof o.status === 'object' ? o.status.id : o.status;
              return idStatus == status.id

            } else
              return false
          }).length;
        })

        return progress
      },

      _CalulateExecuted() {
        let progress = { }

        let filterStatuses = this._FilterStatuses(this.workLayer)
        let filterEntities = this.modifiedEntities

        filterStatuses.forEach( (status) => {
          if (! progress.hasOwnProperty(this.workLayer)) {
            progress[this.workLayer] = {}
          }
          progress[this.workLayer][status.id] = filterEntities.filter( (o) => {
            if (o.status != null) {
              let idStatus = typeof o.status === 'object' ? o.status.id : o.status;
              return idStatus == status.id

            } else
              return false
          }).length;
        })

        return progress
      },

      _PrepareObjects() {
        this.konvaViewer.SetScene( this.dataPictogram.GetScene() )
        let objects = this.konvaViewer.GetEntities(this.pictogram.layers, this.typeRegister)

        this.texts = objects.labels
        this.arrTexts = this.texts // optimizar por que se filtrara
        this.arrGroups = objects.backgrounds // optimizar por que se filtrara
        this.arrStrucComplete = objects.completed
        this.pics = objects.entities

        this.$emit('epc-loaded-scene')
      },

      _PrepareObjectsEvacuationLine() {

        this.konvaViewer.SetScene( this.dataPictogram.GetScene() )
        let objects = this.konvaViewer.GetEntitiesEvacuationLine(this.pictogram.layers)

        this.texts = objects.labels
        this.arrTexts = this.texts // optimizar por que se filtrara
        this.arrGroups = objects.backgrounds // optimizar por que se filtrara
        this.arrStrucComplete = objects.completed
        this.pics = objects.entities

        this.$emit('epc-loaded-scene')
      },

      _IndexObjetoModified(obj) {
        return this.modifiedEntities.findIndex( (item) => { return item.attrs.id === obj.attrs.id } )
      },

      _IsObjetoSelectedOrModified(obj) {
        return ( _IndexObjetoSelected(obj) !== -1 ) || ( _IndexObjetoModified(obj) !== -1 )
      },

      handleWheel(e) {
        // stop default scrolling
        e.evt.preventDefault();
        this.konvaViewer.ZoomStagePoiter(e, 1.2) //1.01

        this._Screenshot();

        // console.log( 'canvasContainer: ', this.$refs.canvasContainer )
        // html2canvas(this.$refs.canvasContainer, {
        //   scale: 2,
        //   // width: 1280,
        //   // height: 1080
        // }).then(function(canvas) {
        //     console.log( 'canvas: ', canvas )
        //     document.body.appendChild(canvas);
        // });

      },

      handleClosePopover() {
        this.popoverPosition = { x: -9999, y: 0 }
      },

      handleModifiedEntity(entity) {
        // 1. Actualizamos datos del array de pictogramas original en este caso solo cambia el estado
        this.filterPics.find( (obj) =>  obj.id == entity.attrs.id).status = entity.status

        // 2. Actualizamos el array de entidades modificadas por el usuario
        let posIndex = this.modifiedEntities.findIndex( (obj) => obj.id == entity.attrs.id )
        if( posIndex === -1) {
          this.modifiedEntities.push( {
            id: entity.attrs.id,
            name: entity.attrs.name,
            type: entity.attrs.type,
            status: entity.status,
          } )
        } else if (posIndex > -1) {
          this.modifiedEntities[posIndex].status = entity.status
        }
      },

      async _Screenshot() {
        return new Promise((resolve, reject) => {
          // let timeOut = null;
          // const l = ()=>{
          //     timeOut && window.clearTimeout(timeOut);
          //     try {
          //       console.log('screenshot')\
              this.$emit('epc-screenshot', this.konvaViewer.Screenshot());
              resolve()
          //       timeOut = window.setTimeout(l, 1e3)
          //     } catch (e) {
          //       console.log(e)
          //     }
          // };
          // l()
        })
      },

      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },

      ZoomIn() {
        this.konvaViewer.ZoomStage(0.1)//1.2
      },

      ZoomOut() {
        this.konvaViewer.ZoomStage(-0.1)//-0.8
      },

      Selecting(value, workLayer) {
        this.konvaViewer.Selecting(value, workLayer)
      },

      Dragging(value) {
        this.konvaViewer.Dragging(value)
      },

      FitToScreen() {

        return new Promise((resolve, reject) => {
          this.konvaViewer.FitToScreen()
            .then(result => {
              // resolve(this._Screenshot());
            })
        })
      },

      // Función para manejar el evento de redimensionamiento
      handleResize() {
        this.konvaViewer.Resize()
      },

      _AllowedAction(action) {
        if (Object.keys(this.canActionsPictogram).length === 0)
          return false;

        return this.canActionsPictogram[action] === 'undefined' ? false : this.canActionsPictogram[action]
      },







      // TODO esta funsion se debe obtimizar lo ideal es que no exista arrStructuresCompleted sino que tolo lo maneje el  PixiViewer
      ShowPicComplete(visible) {
        this.arrStrucComplete.forEach( (obj) => {
          if(obj.picStroke !== '#ffffff')
            obj.visible = visible
        })
      },

      _IndexObjetoSelected(obj) {
        return this.selectedObjects.findIndex( (item) => { return item.picId === obj.picId } )
      },

      _HandleClickPic(e) {

        if( this._AllowedAction('select_entity') ) {

          var receivedObj = e.detail;

          let indexObjectSelected = this._IndexObjetoSelected(receivedObj)

          if (indexObjectSelected > -1) {
            this.selectedObjects.splice(indexObjectSelected, 1);
          } else {
            this.selectedObjects.push(receivedObj);
          }

          let emitData = {
            show: this.selectedObjects.length > 0,
            selected_entities: this.selectedObjects,
            statuses: this.filterStatuses
          }

          // estos dos de momento estan aquí luego iran en un menu contextual
          this.$emit('epc-changestatuses', emitData) // habilitar para abrir que se habra for changge state
          // this.$emit('epc-open-split', emitData)
        }
      },

      GetModifiedEntities() {
        return this.modifiedEntities
      },
    },

    created() {

      this.uppy = new Uppy({
        debug: process.env.NODE_ENV === 'development', // activamos debug para development
        autoProceed: false,
        allowMultipleUploads: false,
      })
      .use(AwsS3Multipart, {
        limit: 3,
        companionUrl: '/api/v2/',
        companionHeaders: {
          'Authorization': "Bearer " + store.getters["auth/token"]
        }
      })
      // .on('upload-success', (file, response) => { this.onUploadSuccess(file, response) } )
      .on('upload-success', (file, response) => { // callback deveulto cuando el file ha sido subido correctamente
        console.log('%cEPC-TACKER: '+ '%c file ' + file.data.name + ' subido correctamente', 'background: #5577BB; color: #fff', 'color: #000')
      })
      .on('upload-progress', (file, progesss) => { // callback devuelto con el progreso de subido del archivo
        this.uploadPercentage = parseInt(
          Math.round((progesss.bytesUploaded * 100) / progesss.bytesTotal)
        );
      })
      .on('upload-error', (file, error, response) => { // callback devuelto si ha ocurrido algun error en la subida
        console.error('%cEPC-TACKER: '+ '%c a ocurrido un error al subir el archivo ' + file.data.name, 'background: #5577BB; color: #fff', 'color: #000', error)
      })

      document.addEventListener('__epc_pic_select', this._HandleClickPic);

    },

    async mounted() {

      this.dataPictogram = new DataPictogram()

      this.konvaViewer = new PixiViewer(this.$refs.canvasContainer, this.$refs.refCanvasPixi)

      // this.popupXDistanceMin = this.$refs.ref_tooltip.$el.clientWidth
      // this.popupYDistanceMin = this.$refs.ref_tooltip.$el.clientHeight

      // this.popoverXDistanceMin = this.$refs.ref_popover.$el.clientWidth
      // this.popoverYDistanceMin = this.$refs.ref_popover.$el.clientHeight

      // Observa el evento de redimensionamiento de la ventana
      await this.Load()

      if (this.pictogram.type === 'photovoltaic_park')
        await this._PrepareObjects()
      else
        await this._PrepareObjectsEvacuationLine()
      // // this.konvaViewer.SetScaleStage()
      // this.konvaViewer.FitViewKonva()
      // window.addEventListener('resize', this.handleResize);
      // console.log( 'mounted primero' )
      // this.Render(this.workLayer ? this.workLayer : 'structures')

      this.$emit('epc-mounted')
    },

    destroyed() {
      // this.konvaViewer.Destroy()
      this.konvaViewer = null
      this.dataPictogram = null
    },

    beforeDestroy() {
      // Elimina el observador del evento de redimensionamiento antes de destruir el componente
      window.removeEventListener('resize', this.handleResize)

      document.removeEventListener('__epc_pic_select', this._HandleClickPic);
    }
}
</script>

<style scoped>

.canvasContainer {
    position: relative;
    width: 100%;
    height: 100vh;
    min-width: 100px;
    min-height: 100px;

    background-color: #fefefe;
}

</style>
