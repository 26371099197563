<template>
  <div class="page-with-top-bar">
    <project-admin-quick-buttons hiddenOption="pictograms"/>
    <section>
      <div class="mt-4">
        <manage-photovoltaic-park
          ref="refManagePhotovoltaicPark"
          :projectId="projectId"
          :can-actions-pictogram="canActionsPictogram"
        ></manage-photovoltaic-park>

      </div>
    </section>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import Form from "vform";
  import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons';
  import ManagePhotovoltaicPark from '../../components/pictograms/manage-photovoltaic-park.vue'

  export default {
    middleware: "auth",
    title: "power_plant_drawings_page_title",
    menuOption: "0-2",

    components: {
      ProjectAdminQuickButtons,
      ManagePhotovoltaicPark
    },

    computed: {
      ...mapGetters({
        navData: "app/navData",
        user: "auth/user",
      }),
      projectId() {
        return parseInt(this.navData.project_id);
      }
    },

    data: () => ({
      isLoading: false,
      form: new Form({
        projectId: null,
        partnerId: null,
        companyId: null,
        excelFile: null,
        description: "",
        overwrite: false,
        assignmentType: null,
        activityCode: null,
        actionType: "",
      }),

      entityType: 'project',
      entityId: null,

      errors: [],
      mainActiveTab: 0,
      addDrawings: false,

      canActionsPictogram: {
        'mode_dashboard': false,
        'show_header': true,
        'save': true,
        'select_entity': false
      }
    }),

    watch: {
      mainActiveTab(value) {
        if (value == 1) {
          this.$refs.refManagePhotovoltaicPark.FullScreenHack()
        }
      }
    },

    created() {
      let projectName = this.navData.project_name
        ? this.navData.project_name.toUpperCase()
        : "";
      this.$options.subtitle = ["project_actions_page_subtitle", [projectName]];

      this.entityId = this.projectId;
    },

    mounted() {

    },

    beforeDestroy(){
    },

    methods: {
      async getProjectPictograms() {
        this.isLoading = true;
        let params = { };

        const { data } = await axios.get(
          "/api/v2/project/" + this.entityId + "/pictograms",
          { params: params }
        );

        if (data && data.success) {
          this.pictograms = data.pictograms;
          this.total = data.pictograms ? data.pictograms.length : 0;
        } else {
          this.$notify.error("error_loading_documents");
        }
        this.isLoading = false;
      },

      _HandlePictogramsRefresh() {
        this.$refs.refManagePictograms.RefreshListPictograms()
        this.mainActiveTab = 0
      }
    }
  };
</script>
