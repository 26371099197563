<template>
  <b-collapse class="card filters-collapse" :open.sync="showFilters">
    <div slot="trigger" class="card-header filters-trigger">
      <p class="card-header-title">
        {{ $t('filters') }}
      </p>
      <a class="card-header-icon">
        <b-icon pack="fa" v-show="! showFilters" icon="plus-circle"/>
        <b-icon pack="fa" v-show="showFilters" icon="minus-circle"/>
      </a>
    </div>
    <div class="panel" style="border-width: 0">
      <div class="flex flex-col lg:flex-row items-center mb-4">
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_name')">
            <b-input
              v-model="filterName"
              :placeholder="$t('name')"
              type="search"
              icon="search"
              icon-pack="fas"
              @input="checkInputTextFilter(filterName)">
            </b-input>
          </b-field>
        </div>

        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_status')">
            <b-select ref="input"
                      :placeholder="$t('status')"
                      v-model="filterCompleted"
                      icon="search"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      expanded
            >
              <option value="">{{$t('all')}}</option>
              <option value="1">{{$t('completed')}}</option>
              <option value="0">{{$t('uncompleted')}}</option>
            </b-select>
          </b-field>
        </div>

        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_unit')">
            <b-select ref="input"
                      :placeholder="$t('measurement_unit')"
                      v-model="filterUnitId"
                      icon="search"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      expanded
            >
              <option value="">{{$t('all')}}</option>
              <option
                v-for="option in unitsList"
                :value="option.id"
                :key="option.id">
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_type')">
            <b-select ref="input"
                      v-model="filterType"
                      icon="search"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      expanded
            >
              <option value="">{{$t('all')}}</option>
              <option value="resource">{{$t('resource')}}</option>
              <option value="task">{{$t('task')}}</option>
            </b-select>
          </b-field>
        </div>

        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_pending_validation')">
            <b-select ref="input"
                      v-model="filterPendingValidation"
                      icon="search"
                      icon-pack="fas"
                      @input="applyFilters(1)"
                      expanded
            >
              <option value="">{{$t('all')}}</option>
              <option value="pending">{{$t('validation_status_pending_validation')}}</option>
              <option value="rejected">{{$t('rejected')}}</option>
            </b-select>
          </b-field>
        </div>

      </div>

      <div class="flex flex-col lg:flex-row items-center mb-4">
        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_partner')">
            <b-select ref="input"
              :placeholder="$t('partner')"
              v-model="filterPartnerId"
              icon="search"
              icon-pack="fas"
              @input="applyFilters(1)"
              expanded
            >
              <option value="">{{$t('all')}}</option>
              <option v-for="partner in partners" :key="'partner_'+partner.id" :value="partner.id">
                {{ getDataNamePartner(partner) }}
              </option>
            </b-select>
          </b-field>
        </div>

        <!-- <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_partner_code')">
            <b-input
              v-model="filterPartnerCode"
              :placeholder="$t('partner_code')"
              type="search"
              icon="search"
              icon-pack="fas"
              @input="checkInputTextFilter(filterName)">
            </b-input>
          </b-field>
        </div> -->

        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_order_number')">
            <b-select ref="input"
              :placeholder="$t('purchase_order')"
              v-model="filterOrderId"
              icon="search"
              icon-pack="fas"
              @input="_HandleInputSelectOrder"
              expanded
            >
              <option value="">{{$t('all')}}</option>
              <option
                v-for="item in ordersList"
                :value="item.id"
                :key="'order_' + item.id">
                {{ item.code }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="w-full lg:w-1/4 mr-4">
          <b-field :label="$t('search_by_position')">
            <b-select ref="input"
              :placeholder="$t('position')"
              v-model="filterPosition"
              icon="search"
              icon-pack="fas"
              @input="applyFilters(1)"
              expanded
              :loading="loadingPositions"
            >
              <option value="">{{$t('all')}}</option>
              <option v-for="item in positionsList" :key="'position_'+item" :value="item">{{ item }}</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <button class="button is-primary btn-blue" @click="cleanFiltersClicked()">{{$t('clean_filters')}}</button>
    </div>

  </b-collapse>
</template>

<script>
  import axios from "axios";
  import {mapGetters} from "vuex";

  export default {
    name: 'measurement-filters',

    computed: {
      ...mapGetters({
        project: 'app/project',
        company: 'app/company',
      })
    },

    components: {},

    props: {
    },

    data: () => ({
      showFilters: false,
      unitsList: [],
      partners: [],
      ordersList: [],
      positionsList: [],
      loadingPositions: false,

      filterName: '',
      filterCompleted: null,
      filterType: null,
      filterUnitId: null,
      filterPendingValidation: null,
      filterPartnerId: null,
      filterPartnerCode: null,
      filterOrderId: null,
      filterPosition: null
    }),

    created() {
      this.getMeasureUnits();
      this.getProjectPartners();
      this.getWorkOrders()
    },

    mounted() {

    },

    methods: {
      async getMeasureUnits() {
        // let data = await axios.get('/api/v2/project/' + this.project.id + '/measurement-units'); // eliminar si todo va bien
        let data = await axios.get('/api/v2/company/' + this.company.id + '/measurement-units');

        if (data.data && data.data.success) {
          this.unitsList = data.data.units;
        }
      },

      async getProjectPartners() {

        const { data } = await axios.get("/api/v2/project/" + this.project.id + "/partners", {'params' : {
          with_orders: 1
        }});

        if (data.data) {
          this.partners = data.data;

          this.loaded = true
        }

      },

      async getWorkOrders() {
        const { data } = await axios.get('/api/v2/work-orders', {params: {
          project_id: this.project.id,
          with_partners: 1
        }});

        if (data.success) {
          this.ordersList = data.data
          this.$emit('epc-filter-orders-loaded', this.ordersList)

        } else {
          this.$notify.error("error_loading_data");
        }
      },

      //Fabrica el objeto con los nuevos filtros
      generateFilters() {

        let params = {p: this.project.id, s_num: 1};

        // Filtro por nombre
        if (this.filterName.length >= 3) {
          params.like_name = this.filterName
        }

        //Filtro por completado o no
        if (this.filterCompleted != null) {
          params.where_completed = this.filterCompleted
        }

        //Filtro por unidad de medida
        if (this.filterUnitId != null) {
          params.where_unit_id = this.filterUnitId
        }


        //Filtro por tipo de asignación
        if (this.filterType != null) {
          params.where_type = this.filterType
        }

        if (this.filterPendingValidation != null) {
          params.where_pending_validation = this.filterPendingValidation
        }

        if (this.filterPartnerId != null) {
          params.where_partner_id = this.filterPartnerId
        }

        if (this.filterPartnerCode != null) {
          params.where_partner_code = this.filterPartnerCode
        }

        if (this.filterOrderId != null) {
          params.where_order_id = this.filterOrderId
        }

        if (this.filterPosition != null) {
          params.where_position = this.filterPosition
        }

        return params
      },

      applyFilters() {
        this.$emit('filters-updated', this.generateFilters());
      },

      cleanFiltersClicked() {
        this.filterName = '';
        this.filterCompleted = null;
        this.filterType = null;
        this.filterUnitId = null;
        this.filterPendingValidation = null;
        this.filterPartnerId = null;
        this.filterPartnerCode = null;
        this.filterOrderId = null;
        this.filterPosition = null;
        this.isLoadingTable = true;
        this.applyFilters();
      },

      checkInputTextFilter(filter) {
        if (filter.length >= 3 || filter.length == 0) {
          this.applyFilters(1)
        }
      },

      getDataNamePartner(data) {
        if (data.code)
          return data.code + '    |    ' + data.name

        return data.name
      },

      async _HandleInputSelectOrder(value) {
        this.positionsList = []

        this.applyFilters(1)

        this.loadingPositions = true
        const { data } = await axios.get(`/api/v2/work-orders/${value}/positions`, {params: {}});
        this.loadingPositions = false

        if (data.success) {
          this.positionsList = data.data
        } else {
          console.error('Error loading positions')
          this.$notify.error("error_loading_data");
        }

      }

    },

  };
</script>

<style scoped>
  .filters-collapse {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .filters-trigger:hover {
    background-color: #f8fafc;
  }

  /* FIX TEMPORAL. UN PAQUETE (PROBABLEMENTE @BIMDATA/VIEWER) ESTA SOBREESCRIBIENDO LA CLASE .flex-col */
  @media (min-width: 1024px) {
    .lg\:flex-row {
      flex-direction: row;
    }
  }
</style>
