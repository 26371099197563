<template>
<div class="page-with-top-bar" style="padding:0!important">

  <div class="flex justify-end px-4 top-bar">
    <div class="flex w-full items-center justify-end text-white">
      <div class="p-2 text-gray-900 bg-white rounded-lg shadow-lg- font-medium capitalize text-right">

        <!-- Ir a a la actividad -->
        <span v-if="validation.validable_type.toLowerCase().indexOf('activity') !== -1"
          class="px-1 mt-2 cursor-pointer hover:text-gray-700 lg:hover:bg-epc-50- xl:hover:bg-gray-200 text-sm xl:rounded xl:px-2 xl:py-2 lg:hover:text-white-"
          @click="goToActivity()">
          <b-tooltip :label="$t('go_to_activity')" position="is-top">
            <i class="fas fa-arrow-left p-2 text-white- bg-gray-200 bg-epc-900- rounded-full lg:w-8">
            </i>
            <span class="mx-1 hidden xl:inline">{{ $t('go_to_activity') }}</span>
          </b-tooltip>
        </span>

        <!-- <button v-if="validation.validable_type.toLowerCase().indexOf('activity') !== -1"
          @click="goToActivity()"
          :class="'bg-green hover:bg-green-dark text-grey-lightest rounded border border-green-dark hover:border-green-darker px-4 py-2 flex items-center justify-center mr-8'"
        >
          <b-icon icon="arrow-left" pack="fas" size="is-small" />
          <span class="font-medium ml-2">{{ $t('go_to_activity') }}</span>
        </button> -->



      </div>

      <!-- TODO. Sólo CREADOR o RESPONSABLE -->
        <!-- Change parent flow -->
        <add-validation-flow
          v-if="true"
          class="text-black"
          :validation-flow-id="parseInt(validation.id)"
          :validation-template-id="parseInt(validation.template_id)"
          :validation-template-name="validation.template.name"
          text-button="change_validation_flow"
          :open-modal-with="'button-icon'"
        />

      <!-- Editar -->
      <v-button-icon-round
          icon="pencil-alt"
          pack="fa"
          tooltip="edit"
          tooltip-position="bottom"
          @click="onClickEdit"
          class="mr-1"
          v-if="validation.project && (validation.project.can_admin || validation.project.can_validate)"
        />
        <!-- Eliminar -->
        <v-button-icon-round
          color="red"
          icon="trash-alt"
          icon-pack="fas"
          tooltip="remove"
          tooltip-position="bottom"
          @click="onClickDelete"
          v-if="false"
        />

        <!-- Info actividad -->
        <v-button-icon-round
          icon="info"
          icon-pack="fas"
          color="grey-darkest"
          tooltip="validation_info"
          tooltip-position="left"
          class="ml-2"
          @click="showSide"
        />
    </div>
  </div>
  <div v-if="! isLoading" class="top-bar flex w-full text-xs" style="height: 15px">
    <!-- <div v-if="! isLoading" class="mr-2 vertical-center"> -->
      <validation-status-label
          :status="validation.status"
          fontSize="0.75"
          class="flex"
          style="vertical-align: middle"
      />
    <!-- </div> -->
  </div>

  <!-- Contenido -->
  <div class="flex px-4 pt-4 below-top-bar bg-grey-lightest flex-col lg:flex-row">

    <div class="w-full lg:hidden containt-tabs-menu">
      <b-tabs ref="refTabsMenu"
        type="is-toggle-rounded"
        v-model="tabSelectedMenu"
        expanded
        class="sticky top-0 mb-3"
        @input="_HandleChangeTab">

        <b-tab-item label="Diario del flujo"
          value="tab_menu_diary"
          icon="book"
          icon-pack="fas" class="hidden"></b-tab-item>

        <b-tab-item
          label="Modulos"
          value="tab_menu_modulos"
          icon="cogs"
          icon-pack="fas"></b-tab-item>

      </b-tabs>
    </div>

    <div class="w-full flex">
      <!-- Columna IZQUIERDA -->
      <div class="w-full lg:w-2/5 lg:mr-2" v-show="showPanel == 'left' || showPanel == 'both'">
        <div class="w-full column-scroll pb-24">
          <entity-description
            class="lg:pr-4" v-if="validation.description"
            :title="$t('validation_description')" :content="validation.description"
          />
          <entity-comments
            v-if="validation.id !== 0"
            :entity-id="validation.id"
            entity-type="validation"
            :can-add="!this.validationClosed"
            class="lg:pr-4"
          />
        </div>
      </div>

      <!-- DERECHA -->
      <div class="w-full lg:w-3/5 lg:ml-2" v-show="showPanel == 'right' || showPanel == 'both'">
        <div class="cw-full column-scroll">

          <!-- Tareas -->
          <validation-tasks
            v-if="validation.id !== 0"
            :validation-id="validation.id"
            :validable-name="validation.validable.name"
            :validable-parent-code="validation.validable.parent ? validation.validable.parent.planning_code : validation.validable.planning_code"
            :validation-status="validation.status"
            :user-group-id="validation.user_group_id"
            :is-project-manager="validation.project.can_admin"
            :is-validating-user="validation.project.can_validate"
            class="last:mb"
          />

          <!-- Documentos -->
          <div v-if="! isLoading" class="last:mb">
            <entity-documents
              v-if="validation.id !== 0"
              :entity-id="validation.id"
              entity-type="validation"
              :can-add="false"
              :with-attachments="true"
              class="my-4"
              :validable-parent-code="validation.validable.parent ? validation.validable.parent.planning_code : validation.validable.planning_code"
            />
          </div>

        </div>
      </div>
    </div>
  </div>

  <div>
      <validation-info-sidebar :validation="validation" ref="validation_info">
      </validation-info-sidebar>
  </div>


</div>
</template>

<script>
import Vue from "vue"
import Vuex from "vuex"
import at from "~/store/modules/at";
import EventBus from '~/plugins/bus'
import axios from 'axios'
import { mapGetters } from 'vuex'
import entityDescription from '·/components/entity-description'
import entityComments from '·/components/entity-comments'
import validationTasks from '·/components/validations/validation-tasks'
import entityDocuments from '·/components/entity-documents'
import validationStatusLabel from '·/components/validations/validation-status-label'
import addValidationFlow from '·/components/add-validation-flow';
import validationInfoSidebar from './validation-info-sidebar'
import moment from 'moment'
import BIcon from "buefy/src/components/icon/Icon";
import api from '~/api'

Vue.use(moment)
Vue.use(Vuex)

export default {
  middleware: 'auth',
  title: '',
  subtitle: 'validation_home_page_subtitle',
  menuOption: '2-4',

  components: {
    BIcon,
    entityComments,
    validationTasks,
    entityDocuments,
    validationStatusLabel,
    entityDescription,
    addValidationFlow,
    validationInfoSidebar
  },

  data: () => ({
    validation: {
      id: 0,
      name: '',
      validable_type: '',
      validable_id: 0,
      template: {},
    },
    atValues: [],
    sliderShown: false,
    sliderClass: '',
    validationClosed : false,
    isLoading:true,
    changeProgressModalKey: 1,
    projectId: undefined,

    showPanelRight: true,
    showPanelLeft: true,

    tabSelectedMenu: -1 // tab seleccionado por defecto
}),

   computed: {
     ...mapGetters({
      user: 'auth/user',
      mobile: 'device/mobile'
     }),

     showPanel: function() {
      if( this.mobile.on_mobile ) {

        if( this.showPanelLeft && this.showPanelRight ) {
          this.tabSelectedMenu = 1
          this.showPanelLeft = false
        }

        return this.showPanelRight ? 'right' : 'left'
      }

      this.showPanelRight = true
      this.showPanelLeft = true
      return 'both'
    }
   },

  watch: {
    '$route.params.validation_id': function() {
      // Reload params
      this.isLoading = true
      this.loadParams()
    },
  },

  created() {

      this.loadParams()

      // Referenciamos
      var self = this;

      // Si recibimos este evento, refrescamos el componente
      EventBus.$on('refreshValidations', function(e){
        // actualiza el status de validation-status-label
        if (e && self.validationId == e.validation_flow_id) {
          self.loadValidationData()
        }
      });

  },

  beforeDestroy(){
  	EventBus.$off("refreshValidations")
  },

  methods: {

    loadParams() {
      this.validationId = this.$route.params.validation_id
      this.loadValidationData()
      this.loadValidationUsers()
    },

    loadValidationData() {

      var self = this

      api.fetchEntity('validation', self.validationId, {withGroups: 1})
        .then(function (data) {
          if (data && data.success) {

            // Cargamos los datos de la validacion
            self.validation = data.data

            // Seteamos el proyecto y las tags
            let project = self.validation.project
            self.projectId = project.id

            self.$store.dispatch('app/setProject', { project })
            let tags = project.tags
            self.$store.dispatch("app/setTags", { tags });

            // Parseamos las fechas
            self.validation.end_date = moment(self.validation.end_date).format('DD/MM/YYYY')

            self.validationClosed = data.data.status == 2

            // Actualizamos el título de la página
            let pageInfo = {
              title: self.validation.template.name,
              subtitle: self.validation.validable.name +
                ( self.validation.parent_activity ? ' (' + self.validation.parent_activity.name + ')' : '') +
                (self.validation.technical_specification ? ' / (Especificación técnica: ' + self.validation.technical_specification.name + ')' : ''),
              menuOption: self.$options.menuOption
            }
            self.$store.dispatch('app/updatePageInfo', { info: pageInfo })

            self.isLoading = false

          } else if(data && data.msg){
            self.$notify.error('no_access_permissions')
            self.$router.push({ name: 'project.home', params: {} })
          } else {
            self.$notify.error('error_loading_validations')
            self.$router.push({ name: 'project.home', params: {} })
          }
        })
        .catch(error => {
          console.log(error.response);
          self.$notify.error('error_loading_validations')
          self.$router.push({ name: 'project.home', params: {} })
        });

    },

    // lista de usuarios del flujo para menciones comentarios
    async loadValidationUsers(){

      // Referenciamos
      var self = this;

      // Nos traemos usuarios de la actividad para menciones
      await axios.get("/api/v2/validation/" + self.validationId + "/chat/users")
        .then(function(response) {
          // Si encontramos usuarios los guarda en la store
          if (response.data && response.data.success) {
            response.data.data.forEach(function(element) {
              self.atValues.push(element)
            });
          }
        })
        .catch(error => {
          console.log(error);
        });

      at.commit('increment', self.atValues)
    },

    // Botones

    onClickEdit() {
      this.$router.push({
          name: 'validation.edit',
          params: { activity_id: this.validation.id, name: this.validation.name }
        })
    },

    onClickDelete() {
      this.$dialog.confirm({
        title: this.$t('delete_validation'),
        message: this.$t('delete_generic', [this.validation.name]),
        confirmText: this.$t('delete_validation'),
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true
          let url = '/api/v2/validation/' + this.validation.id + '/delete'
          const { data } = await axios.post(url)
          if (data && data.success) {
            this.isLoading = false
            this.$notify.success('success_deleting_generic')
            this.$router.push({ name: 'project.validations' })
          } else {
            this.isLoading = false
            this.$notify.error(data.error)
          }
        }
      })
    },


    showSide() {
      this.$refs.validation_info.showSide()
    },


    goToActivity() {
      if (this.validation.validable.parent_id)
        this.$router.push({'name': 'subactivity.home', params: {activity_id: this.validation.validable.parent_id, subactivity_id: this.validation.validable_id}})
      else
        this.$router.push({'name': 'activity.home', params: {activity_id: this.validation.validable_id}})
    },

    _HandleChangeTab(value) {
      this.showPanelRight = value === 1
      this.showPanelLeft = value === 0
    }

  },
};
</script>

<style scoped>
.containt-tabs-menu::v-deep .b-tabs section {
  display: none;
}

.column-scroll {
  height: calc(100vh - 150px);
  overflow-x: hidden;
  overflow-y: auto;
}
.column-scroll:not(:last-child) {
  /* margin-right: 0.5rem; */
  border-color: #adc2ea;
  border-right-width: 1px;
}
/* .column-scroll:last-child {
  margin-left: 0.5rem;
} */

.last\:mb:last-child {
  margin-bottom: 15rem;
}
</style>
