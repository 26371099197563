<template>
    <div>
        <div class="top-bar flex justify-end">
            <v-button icon="plus" @click="goToPage('add-billing')" class="mr-4">{{
                $t('add_invoice') }}</v-button>
        </div>
        <!-- Filtros -->
        <div>
            <billings-filter ref="filtersRef" @filters-updated="handleFilter" :providers="providers" />
        </div>
        <!-- Tabla Billings -->
        <div class="flex">
            <div :class="[(showFormData || showFormImportEntities ? 'w-9/12' : 'w-full'), 'mr-2']">
                <billings ref="billings_list" :projectId="projectId" @add="handleAdd" />
            </div>
            <transition name="fade">
                <div class="w-3/12 ml-2" v-show="showFormImportEntities">
                    <billings-assigment-cost-budget ref="loading_cost_assignment" @epc-close="(event) => {
                        showFormImportEntities = false
                    }" @success-assignment="handleSuccessAssignment" />

                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Billings from "./billings.vue";
import BillingsFilter from "./billings-filter.vue";
import BillingsAssigmentCostBudget from './billings-assigment-cost-budget.vue';
import axios from 'axios';

export default {
    components: {
        Billings,
        BillingsFilter,
        BillingsAssigmentCostBudget,
    },
    props: {
        projectId: { type: Number, required: true },
    },
    data() {
        return {
            providers: [],
            showFormData: false,
            showFormImportEntities: false
        };
    },
    created() {
        this.getProviders();
    },
    methods: {
        goToPage(destiny) {
            switch (destiny) {
                case 'add-billing':
                    this.$router.push({
                        name: 'project.admin.cost-management.billing',
                        params: {
                            projectId: this.projectId,
                        }
                    });
                    break;
            }
        },
        handleFilter(args = {}) {
            this.$refs.billings_list.reload(args);
        },
        async getProviders() {
            try {
                const { data } = await axios.get(`/api/v2/project/${this.projectId}/providers`);
                if (data && data.success) {
                    this.providers = data.providers;
                }
            } catch (error) {
                console.error('Error fetching providers:', error);
            }
        },
        handleAdd(resource, invoiceId) {
            if (this.$refs.loading_cost_assignment) {
                this.$refs.loading_cost_assignment.show(resource, invoiceId);
                this.$refs.billings_list.loadAssignmentsQuantity(resource.product_id);
            }
            this.showFormImportEntities = true;
        },
        handleSuccessAssignment() {
            this.showFormImportEntities = false;
            this.$refs.billings_list.getInvoice();
            // $refs.budgets_table.reload();
        }
    }
};
</script>

<style lang="scss" scoped></style>
