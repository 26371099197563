<template>
  <div class="canvasContainer" ref="canvasContainer">

    <v-stage ref="stage" class="relative"
      :config="configKonva"
      @wheel="handleWheel"
      @touchMove="() => {}"
      @touchEnd="() => {}"
      @contextmenu="handleContextMenu"
      @mousedown="_handleMouseDownStage($event)"
      @mousemove="_handleMouseMoveStage($event)"
      @mouseup="_handleMouseUpStage($event)"
      @clicktap="handleClickTap">

      <v-layer ref="refLayerText">
        <v-rect v-for="bk in arrGroups " :key="bk.id"
          :config="bk"
        />
      </v-layer>

      <v-layer ref="refLayerCompleted"
        :visible="showLayerCompleted">
        <v-rect v-for="c in arrStrucComplete " :key="c.id"
          :config="c"
        />
      </v-layer>

      <v-layer ref="layer"
        :config="{ id: 'layerEntities', name: 'layerEntities' }">

        <v-rect v-for="pic in sections" :key="pic.id"
          :config="pic"

          @mousemove="handleMouseMove"
          @mouseout="handleMouseOut"
          @click="handleClick"
          @touchstart="handleClick"
        />

        <v-circle v-for="pic in nodes " :key="pic.id" :config="pic"
          @mousemove="handleMouseMove"
          @mouseout="handleMouseOut"
          @click="handleClick"
          @touchstart="handleClick"
        />

        <v-line v-for="line in borderLine" :key="line.id" :config="line"
        />

        <v-text v-for="obj in nameNodes" :key="obj.id"
          :config="obj"/>

        <v-text v-for="obj in arrTexts" :key="obj.id"
          :config="obj"/>

        <v-rect v-for="pic in filterPics " :key="pic.id"
          :config="pic"

          @mousemove="handleMouseMove"
          @mouseout="handleMouseOut"
          @click="handleClick"
          @touchstart="handleClick"
        />
      </v-layer>

      <v-layer ref="regions">
        <!-- <v-line v-for="r in regions" :key="r.id + Math.random()"
          name="region"
          :points="r.points.flatMap(p => [p.x, p.y])"
          :fill="r.color"
          closed
          :opacity="0.8"
        >
        </v-line> -->
        <v-rect v-if="rectSelect" ref="refRegionSelect"
          :config="rectSelect"
        />
      </v-layer>

    </v-stage>

      <!-- <b-loading v-model="isLoading" :is-full-page="false" :can-cancel="false" style="z-index: 10"/>
      <div v-if="progress !== null" class="progress">
          <b-progress type="is-info" :indeterminate="progress < 0" :value="progress" show-value format="percent"/>
          <div v-if="progressText !== null" class="progressText">{{progressText}}</div>
      </div>
      <div v-if="error !== null" class="error" :title="error">
          <q-icon name="warning" class="text-red" style="font-size: 4rem;" /> Error occurred: {{error}}
      </div> -->

    <KonvaViewerTooltip ref="ref_tooltip"
      v-show="showTooltip"
      :position="popupPosition"
      :dataTooltip="popupData"
      :statuses="filterStatuses ? filterStatuses : [] "
      :onClose="(e) => { popupData.type = null }"
    />

    <KonvaViewerPopover ref="ref_popover"
      v-show="showPopover"
      :position="popoverPosition"
      :dataTooltip="popoverData"
      :statuses="filterStatuses ? filterStatuses : []"
      @epc-close="handleClosePopover"
      @epc-update-list="handleModifiedEntity"
    />
  </div>
</template>

<script>
import axios from "axios"
import Vue from 'vue';
import VueKonva from 'vue-konva';
import {KonvaViewer} from './utils/index'
import KonvaViewerTooltip from "../components/KonvaViewerTooltip.vue";
import KonvaViewerPopover from "../components/KonvaViewerPopover.vue";

import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import store from "~/store";

import html2canvas from 'html2canvas';

Vue.use(VueKonva);

export default {
    name: "KonvaViewer",

    components: {
      KonvaViewerTooltip,
      KonvaViewerPopover
    },

    props: {
        pictogram: {
          type: Object,
          default() { return {} },
        },

        typePictogram: {
          type: String,
          default: 'photovoltaic_park'
        },
        workLayer: { type: String, default: null },

        options: {
            default() {
                return {
                    clearColor: "#fff",
                    autoResize: true,
                    colorCorrection: true,
                    sceneOptions: {
                        wireframeMesh: true
                    }
                }
            }
        },

      canActionsPictogram: { type:Object, default() { return {} } }, // acciones que se puede realizar sobre el pictograma por defecto ninguna
      showLayerCompleted: { type: Boolean, default: false } // visualiza o oculta la capa de estructuras completadas
    },

    data() {
        return {
            isLoading: false,
            progress: null,
            progressText: null,
            curProgressPhase: null,
            error: null,

            configKonva: {
              width: 50,
              height: 50,
              draggable: true,
              opacity: 1,
            },

            pics: [], // contiene todas las entidades graficables
            filterPics: [], // solo contiene las entidades graficables filtradas segun tipo 'structure', 'piling', 'module'

            arrTexts: [], // lista de textos a visualizar en la capa respectiva
            arrGroups: [], // para simular una agrupación
            arrStrucComplete: [], // lista de estructuras con estado completado para mostrar como fondo cuando se visualizan los modulos

            popupPosition: { x: -999, y: 0 },
            popupData: { type: null, name: null, status: null, progress: 0 },
            popupXDistanceMin: 0,
            popupYDistanceMin: 0,

            popoverPosition: { x: -9999, y: 0 },
            popoverData: { type: null, name: null, status: null, progress: 0 },
            popoverXDistanceMin: 0,
            popoverYDistanceMin: 0,

            filterStatuses: [], // estados filtrados segun se este visualizando estructuras hincados o modulos
            modifiedEntities: [], // lista de objetos que se modificaron y se mandaran para actualizar el general
            selectedObjects: [], // contiene los objetos seleccionados sobre los cuales se han de realizar acciones o acción

            toggleDrawing: false,
            regions: [],
            isDrawing: false,
            idRegion: 0,

            startCoords: null,
            endCoords: null,
            rectSelect: null,
            isDraggable: true,

            // para lineas de evacuacion
            nodes: [],
            sections: [],
            nameNodes: [],
            borderLine: [], // para mostrar borde cuando es linea de evacuacion enterrada
        }
    },

    computed: {
      showTooltip: function() {
        return true //this.popupData.type != null
      },

      showPopover: function() {
        return true //this.popoverData.type != null
      },
    },

    watch: {

    },

    methods: {

      getRelativePointerPosition(node) {
        // the function will return pointer position relative to the passed node
        const transform = node.getAbsoluteTransform().copy();
        // to detect relative position we need to invert transform
        transform.invert();

        // get pointer (say mouse or touch) position
        const pos = node.getStage().getPointerPosition();

        // now we find relative point
        return transform.point(pos);
        // return pos;
      },

      _handleMouseDownStage(e) {
        if(this.isDraggable && !this._AllowedAction('select_entity') )
          return

        this.toggleDrawing = true;
        this.isDrawing = true
        const point = this.getRelativePointerPosition(e.target.getStage());

        this.startCoords = point

        this.rectSelect = {
          x: point.x,
          y: point.y,
          width: 0,
          height: 0,
          stroke: 'black',
          fill: "#0c5e95", //"#b3e5ea",
          strokeWidth: 0,
          opacity: 0.6
        }
      },

      _handleMouseMoveStage(e) {
        // if (!this.isDrawing) {
        //   return;
        // }
        // const point = this.getRelativePointerPosition(e.target.getStage());

        // this.endCoords = point
        // let currX = point.x

        // let direction = null
        // if (currX > this.startCoords.x) { // movimiento a la derecha
        //   this.rectSelect.fill = "#0c5e95"
        //   direction = 'right'
        // } else if (currX < this.startCoords.x) { /// movimiento a la izquierda
        //   this.rectSelect.fill = "#5e950c"
        //   direction = 'left'
        // }

        // this.rectSelect.width = this.endCoords.x - this.rectSelect.x
        // this.rectSelect.height = this.endCoords.y - this.rectSelect.y

        // this.$refs.regions.getNode().getLayer().draw()

        // // determinamos con quienes intersecta el area
        // let nodeLayer = this.$refs.layer.getNode().getLayer()
        // let objects = nodeLayer.getChildren().filter((el) => { return !isNaN(el.attrs.id)  })
        // let areaReact = this.rectSelect
        // let objetosDebajo = []

        // // objects.forEach(function(objeto) {
        // // })

        // for (const objeto of objects) {
        //   let indexObjectSelected = this._IndexObjetoSelected(objeto)

        //   // Verificamos si el objeto esta contenido en el rectangulo
        //   if ( this._inArea(direction, objeto, areaReact)) {

        //     // El objeto intersecta con el área, añadirlo al array
        //     if (indexObjectSelected === -1) {
        //       this.selectedObjects.push(objeto);
        //       objeto.fill('#fff7b3');
        //       objeto.opacity(0.6)
        //     }
        //   } else {
        //     // si no esta deseleccionamos
        //     if (indexObjectSelected > -1) {
        //       this.selectedObjects.splice(indexObjectSelected, 1);
        //       objeto.fill( objeto.attrs.status ? objeto.attrs.status.color : null );
        //       objeto.opacity(1)
        //     }
        //   }
        // }
      },

      _inArea(direction, objeto, areaReact) {

        if (direction == 'right') {

          return objeto.attrs.x < areaReact.x + areaReact.width &&
            objeto.attrs.x + objeto.attrs.width > areaReact.x &&
            objeto.attrs.y < areaReact.y + areaReact.height &&
            objeto.attrs.y + objeto.attrs.height > areaReact.y

        } else if (direction == 'left') {

          return objeto.attrs.x < areaReact.x
            && objeto.attrs.x > areaReact.x + areaReact.width
            && objeto.attrs.y > areaReact.y
            && objeto.attrs.y < areaReact.y + areaReact.height
        }
      },

      _handleMouseUpStage(e) {
        if (!this.isDrawing && !this._AllowedAction('select_entity')) {
          return;
        }

        this.toggleDrawing = false;
        this.isDrawing = false;
        this.startCoords = null
        this.endCoords = null
        this.rectSelect = null

        let emitData = {
          show: this.selectedObjects.length > 0,
          selected_entities: this.selectedObjects,
          statuses: this.filterStatuses
        }

        // estos dos de momento estan aquí luego iran en un menu contextual
        this.$emit('epc-changestatuses', emitData) // habilitar para abrir que se habra for change state
        // this.$emit('epc-open-split', emitData)
      },

      async Load() {
        this.isLoading = true
        this.$emit('epc-isloading', true)
        this.error = null

        try {
          if (!this.pictogram.preview)
            await this.konvaViewer.Load({ url: `/api/v2/pictogram/${this.pictogram.id}/json` })
          else {
            if (this.typePictogram === 'photovoltaic_park') {
              this.konvaViewer.SetDataPictogram(this.pictogram.scene)
              this.konvaViewer.FitToScreen()
            } else {
              this.konvaViewer.SetDataEvacuation(this.pictogram.scene)
            }
          }

          this.$emit('epc-loaded');
        } catch (error) {
            console.warn(error)
            this.error = error.toString()
        } finally {
            this.$emit('epc-isloading', false)
            this.isLoading = false
            this.progressText = null
            this.progress = null
            this.curProgressPhase = null
        }
      },


      async Render(type) {
        this.filterPics = await this._Filter(this.pics, type)
        this.filterStatuses = await this._FilterStatuses( type )
        const stage = this.$refs.stage.getNode()
        stage.draw()
      },

      GetLayers() {
        return this.konvaViewer.GetLayers()
      },

      EnableDraggable(enable) {
        this.isDraggable = enable
        this.konvaViewer.EnableDraggable(enable)
      },

      // Metodo para pasar los objetos seleccionas al array modifiedEntities que se guardará, deja en blanco el array de
      // objetos seleccionado
      CompleteAction() {
        // Pasamos todos los objetos seleccionados a modifiedEntities
        this.selectedObjects.forEach((item, i) => {
          let indexEntity = this.modifiedEntities.findIndex( (obj) => { return obj.id === item.attrs.id } )
          if (indexEntity !== -1) {
            // no cambiamos propiedad so (estado anterior) por que no
            this.modifiedEntities[indexEntity].status = typeof item.attrs.status === 'object' ? item.attrs.status.id : item.attrs.status
          }
          else
            this.modifiedEntities.push({
              id: item.attrs.id,
              name: item.attrs.name,
              type: item.attrs.type,
              previou_status: item.attrs.previou_status, // estado anterior
              status: typeof item.attrs.status === 'object' ? item.attrs.status.id : item.attrs.status,
            })
        });

        this.selectedObjects = []
      },

      SplitEntities(dataSplit) {
        // console.log( 'SplitEntities konvaviewer dataSplit: ', dataSplit )
        console.log( 'SplitEntities konvaviewer: ', this.selectedObjects[0].attrs )

      },

      ShowLayer(name, show) {
        const layer = this.GetLayers().find(  (l) => l.name == name)
        if (!layer) {
            return
        }

        for (const obj of this.filterPics) {
            if ( obj.layerName == name)
              obj.visible = show
        }

        // this.Render()
        const stage = this.$refs.stage.getNode()
        stage.draw()
      },

      async Save(screenshots=[]) {
        this.$emit('epc-saving', { loading: true, text: 'Guardando pictograma' })

        // primero guardamos las entidades modificadas si todo va bien recien actualizamos datos meta del pictograma y registramos
        // progreso realizado por el usuario
        let response = await this._UpdateEntities()
        if ( response ) {
          this.$emit('epc-saving', { loading: true, text: 'Guardando capturas' })
          let arrAwsResponse = []
          for (const item of screenshots) {
            const awsr = await this.submitResource(item)
            arrAwsResponse.push(awsr)
          }

          this.$emit('epc-saving', { loading: true, text: 'Guardando datos del pictograma' })
          response = await this._UpdateDataPictogram(response, arrAwsResponse);
        }

        if (response) this.$emit('epc-reload')

        this.$emit('epc-saving', { loading: false, text: null })
      },

      async submitResource(resource) {
        const blob = resource.src;
        blob.name = resource.name; // TODO: al nombre del recurso añadir marca de tiempo y name company o project who prefix
        return this.store(blob, {})
        .then(async result => {
          // successFul contiene toda la lista de archivos que se han subido
          // para nuestro caso solo consideramos siempre el primero por que enviamos de a uno
          if( result.successful.length > 0 ) {
            let response = result.successful[0].response

            let params = {
              // uuid: response.uuid,
              key: response.body.key,
              // bucket: response.bucket,
              name: blob.name,
              content_type: blob.type,
              // url: response.url
            }
            return params
            // form.append("aws_response", JSON.stringify(params));
          }

          // failed contiene todos los archivos que tubieron fallo al subir
          if (result.failed.length > 0) {
            console.error('Errors:')
            result.failed.forEach((file) => {
              console.error(file.error)
            })

            return null
          }
        }).catch((error) => {
          console.log('error: ', error)
          return null
        });
      },

      // CUSTOM VAPOR STORE METHOD
      async store(file, options = null) {
        // verificamos si existe algun archivo en la lista de carga de uppy
        // si existe lo eliminamos
        if( this.keyFileUploadCurrent ) {
          this.uppy.removeFile(this.keyFileUploadCurrent);
        }

        if (typeof options.progress === 'undefined') {
            options.progress = () => {};
        }

        this.keyFileUploadCurrent = this.uppy.addFile({
          name: file.name, // file name
          type: file.type, // file type
          data: file, // file blob
          // meta: {
          //   // optional, store the directory path of a file so Uppy can tell identical files in different directories apart.
          //   relativePath: webkitFileSystemEntry.relativePath,
          // },
          source: 'Local',
          isRemote: false,
        });

        // response.data.extension = file.name.split('.').pop()
        return this.uppy.upload()
      },

      async _UpdateEntities() {
        let form = new FormData();
        form.append('modified_entities', JSON.stringify(this.modifiedEntities))
        form.append('work_layer', this.workLayer)

        let url = `/api/v2/pictogram/${this.pictogram.id}/update-entities`

        // Enviamos el form
        return await axios.post(url, form)
          .then((response) => {
            let data = response.data

            if (data && data.success) {

              console.log('%cEPC-TACKER: '+ '%c entities actualizado correctamente.', 'background: #5577BB; color: #fff', 'color: #000')

              return data.data

            } else if(data && data.msg){

              this.$notify.error('no_access_permissions')

            } else {
              this.$notify.error('error_saving')
            }
          })
          .catch(error => {
            // Manejar errores aquí
            if (error.response) {
              // La solicitud fue hecha y el servidor respondió con un estado de error
              this.$notify.error(error.response.data.message)
              // console.log('Error de respuesta:', error.response.status, error.response.data);
              console.error('%cEPC-TACKER: '+ '%c Error de respuesta:', 'background: #5577BB; color: #fff', 'color: #000', error.response.status, error.response.data)
            } else if (error.request) {
              // La solicitud se realizó pero no se recibió respuesta del servidor
              this.$notify.error('No se recibió respuesta del servidor (E500)')
              console.error('%cEPC-TACKER: '+ '%c No se recibió respuesta del servidor', 'background: #5577BB; color: #fff', 'color: #000')
            } else {
              // Ocurrió un error antes de la solicitud, como una configuración incorrecta de Axios
              this.$notify.error('Error de configuración')
              console.error('%cEPC-TACKER: '+ '%c Error de configuración:', 'background: #5577BB; color: #fff', 'color: #000', error.message)
            }

            return null;
          })
          // .finally(() => {
          //   this.$emit('epc-saving', { loading: false, text: null })
          // })
      },

      async _UpdateDataPictogram(partialProgress, awsResponseImages) {
        // 1. contabilizar primero total de entidades por typo y estado
        // TODO: Este paso ya no es necesario, por que esta devolviendo el progreso total anterior al momento de cargar
        // el archivo solo estamos realizando un proceso inecesario directo el calculo lo realizamos en el servidor
        // enviando solo el progreso realizado por el usuario, lo dejamos comentado de momento
        // let totalStatausProgress = this._CalulateProgress(this.filterPics) // total progreso ya es calculado apartir del partian en backend

        // 2. contabilizar total de entidades por tipo y estado modificadas por el usuario
        // let statusesProgress = this._CalulateProgress(this.modifiedEntities) // ahora ya no calculamos apartir de los modificados sino desde el diseño
        let statusesProgress = this._CalulateProgressInDesign()
        // 3. preparar para mandar al backend
        let form = new FormData();

        form.append('work_layer', this.workLayer)
        // form.append('total_progress', JSON.stringify(totalStatausProgress))
        form.append('partial_progress', JSON.stringify(partialProgress))
        form.append('statuses_progress', JSON.stringify(statusesProgress))
        form.append('aws_images', JSON.stringify(awsResponseImages))

        let url = `/api/v2/pictogram/${this.pictogram.id}/update-progress` // add url

        // Enviamos el form
        return await axios.post(url, form)
          .then((response) => {
            let data = response.data

            if (data && data.success) {
              this.$notify.success('success_editing')
              console.log('%cEPC-TACKER: '+ '%c pictograma actualizado correctamente.', 'background: #5577BB; color: #fff', 'color: #000')

              return true;
            } else if(data && data.msg){
              this.$notify.error('no_access_permissions')
            } else {
              this.$notify.error('error_saving')
            }

            return false;
          })
          .catch(error => {
            // Manejar errores aquí
            if (error.response) {
              // La solicitud fue hecha y el servidor respondió con un estado de error
              this.$notify.error(error.response.data.message)
              // console.log('Error de respuesta:', error.response.status, error.response.data);
              console.error('%cEPC-TACKER: '+ '%c Error de respuesta:', 'background: #5577BB; color: #fff', 'color: #000', error.response.status, error.response.data)
            } else if (error.request) {
              // La solicitud se realizó pero no se recibió respuesta del servidor
              this.$notify.error('No se recibió respuesta del servidor (E500)')
              console.error('%cEPC-TACKER: '+ '%c No se recibió respuesta del servidor', 'background: #5577BB; color: #fff', 'color: #000')
            } else {
              // Ocurrió un error antes de la solicitud, como una configuración incorrecta de Axios
              this.$notify.error('Error de configuración')
              console.error('%cEPC-TACKER: '+ '%c Error de configuración:', 'background: #5577BB; color: #fff', 'color: #000', error.message)
            }

            return false;
          })
          // .finally(() => {
          //   this.$emit('epc-saving', { loading: false, text: null })
          // })
      },

      _Filter(entities, filter) {
        return entities.filter( (p) => p.type === filter );
      },

      _FilterEntitiesDesign(filter) {
        let entities = this.$refs.layer.getNode().children
        return entities.filter( (p) => p.attrs.type === filter );
      },

      _FilterStatuses(filter, onlyCompletion=false) {
        let layer = this.pictogram.layers.find((item) => { return item.code == filter })

        if( layer ) {
          return onlyCompletion ? layer.statuses.filter((item) => { return item.is_completion }) : layer.statuses
        }
        return []
      },

      _CalulateProgressInDesign() {
        let progress = {
          structures: {
            '-1': 0
          },
          pilings: {
            '-1': 0
          },
          modules: {
            '-1': 0
          }
        }

        let filterStatuses = this._FilterStatuses(this.workLayer)
        let filterEntities = this._FilterEntitiesDesign(this.workLayer)
        // determinamos primero los que no tiene status
        progress[this.workLayer]['-1'] = filterEntities.filter( (o) => o.attrs.status == null ).length;

        // determinamos cantidad por status
        filterStatuses.forEach( (status) => {
          progress[this.workLayer][status.id] = filterEntities.filter( (o) => {
            if (o.attrs.status != null) {
              // // si existe un estado anterior verificamos si ese estado no representa completado para evitar
              // // volver a contabilizar el mismo
              // if (o.previou_status != null) {
              //   if (filterStatuses.find(ps => { return ps.id ==  o.previou_status }) != null)
              //     return false
              // }

              let idStatus = typeof o.attrs.status === 'object' ? o.attrs.status.id : o.attrs.status;
              return idStatus == status.id

            } else
              return false
          }).length;
        })

        return progress
      },

      _CalulateProgress(entities) {
        let progress = {
          structures: {
            '-1': 0
          },
          pilings: {
            '-1': 0
          },
          modules: {
            '-1': 0
          }
        }

        //TODO: Optimización solo manda los datos en la capa que se ha trabajadado (this.workLayer) no en todas, de momento dejamos comentado el codigo
        // this.pictogram.layers.forEach( (layer, index) => {
        //   let filterStatuses = this._FilterStatuses(layer.code)
        //   let filterEntities = this._Filter(entities, layer.code)

        //   // determinamos primero los que no tiene status
        //   progress[layer.code]['-1'] = filterEntities.filter( (o) => o.status == null ).length;

        //   // determinamos cantidad por status
        //   filterStatuses.forEach( (status) => {
        //     progress[layer.code][status.id] = filterEntities.filter( (o) => o.status != null && o.status.id == status.id ).length;
        //   })
        // })

        let filterStatuses = this._FilterStatuses(this.workLayer)
        let filterEntities = this._Filter(entities, this.workLayer)
        // determinamos primero los que no tiene status
        progress[this.workLayer]['-1'] = filterEntities.filter( (o) => o.status == null ).length;

        // determinamos cantidad por status
        filterStatuses.forEach( (status) => {
          progress[this.workLayer][status.id] = filterEntities.filter( (o) => {
            if (o.status != null) {
              // // si existe un estado anterior verificamos si ese estado no representa completado para evitar
              // // volver a contabilizar el mismo
              // if (o.previou_status != null) {
              //   if (filterStatuses.find(ps => { return ps.id ==  o.previou_status }) != null)
              //     return false
              // }

              let idStatus = typeof o.status === 'object' ? o.status.id : o.status;
              return idStatus == status.id

            } else
              return false
          }).length;
        })

        return progress
      },

      _OnProgress(phase, size, totalSize) {
          if (phase !== this.curProgressPhase) {
              switch(phase) {
              case "font":
                  this.progressText = "Fetching fonts..."
                  break
              case "fetch":
                  this.progressText = "Fetching file..."
                  break
              case "parse":
                  this.progressText = "Parsing file..."
                  break
              case "prepare":
                  this.progressText = "Preparing rendering data..."
                  break
              }
              this.curProgressPhase = phase
          }
          if (totalSize === null) {
              this.progress = -1
          } else {
              this.progress = size / totalSize
          }
      },

      _PrepareObjects() {
        let objects = this.konvaViewer.GetEntities(this.pictogram.layers)
        this.texts = objects.labels
        this.arrTexts = this.texts // optimizar por que se filtrara
        this.arrGroups = objects.backgrounds // optimizar por que se filtrara
        this.arrStrucComplete = objects.completed
        this.pics = objects.entities
      },

      _PrepareEntities() {
        let objects = this.konvaViewer.GetLineaEvacuation(this.typePictogram)

        this.sections = objects.sections
        this.nodes = objects.nodes
        this.nameNodes = objects.labels
        this.borderLine = objects.backgrounds
        // this.arrTexts = this.texts // optimizar por que se filtrara
        // this.arrGroups = objects.backgrounds // optimizar por que se filtrara
        // this.arrStrucComplete = objects.completed
        // this.pics = objects.entities
      },

      writeMessage(message) {
        // console.log( 'mouse hover: ', message )
      },

      handleMouseOut(event) {
        // this.writeMessage('Mouseout triangle');
        let obj = event.target
        let indexObjectSelected = this._IndexObjetoSelected(obj)
        if (indexObjectSelected === -1) {
          obj.fill(this._GetFillColor(obj))
          obj.opacity(0.7)
        } else {
          obj.opacity(0.6)
        }

        if ( this.workLayer == null ) {
          this.popupPosition.x = -999
          this.popupPosition.y = 0
        }
      },

      _GetFillColor(obj) {
        if (obj.attrs.status) {
          if (typeof obj.attrs.status === 'object')
            return obj.attrs.status.color
          else {
            let status = this.filterStatuses.find( item => { return item.id == obj.attrs.status } )
            return status.color
          }
        }

        if (obj.status)
          return obj.status.color

        return null
      },

      handleMouseMove(event) {
        // determinamos la posicion donde aparecera el popup
        const position = this.konvaViewer.GetPosition(this.popupXDistanceMin, this.popupYDistanceMin);

        let obj = event.target
        obj.fill('#fff7b3') // color para identificar que esta sobre ese objeto luego se debe reestablecer a su color original
        obj.opacity(0.3)

        if( this.workLayer == null ) {
          this.popupPosition.x = position.x
          this.popupPosition.y = position.y
          this.popupData = {
            type: obj.attrs.type,
            name: obj.attrs.name,
            status: obj.attrs.status,
            progress: 0,
          }
        }
      },

      handleClick(event) {
        event.evt.preventDefault()
        if( this._AllowedAction('select_entity') ) {
          // Solo tomamos en cuenta si se ha pulsado el click derecho del mouse
          if( event.evt.button === 0 ) {
            // determinamos la posicion donde aparecera el popover
            // const position = this.konvaViewer.GetPosition(this.popoverXDistanceMin, this.popoverYDistanceMin);

            let obj = event.target
            // obj.opacity(0.5)
            // this.popoverPosition.x = position.x
            // this.popoverPosition.y = position.y
            // this.popoverData = obj

            let indexObjectSelected = this._IndexObjetoSelected(obj)
            if (indexObjectSelected === -1) {
              this.selectedObjects.push(obj);
              obj.fill('#fff7b3');
              obj.opacity(0.6)
            } else {
              this.selectedObjects.splice(indexObjectSelected, 1);
              obj.fill( obj.attrs.status ? obj.attrs.status.color : null );
              obj.opacity(1)
            }
          }

          let emitData = {
            show: this.selectedObjects.length > 0,
            selected_entities: this.selectedObjects,
            statuses: this.filterStatuses
          }

          // estos dos de momento estan aquí luego iran en un menu contextual
          this.$emit('epc-changestatuses', emitData) // habilitar para abrir que se habra for changge state
          // this.$emit('epc-open-split', emitData)
        }
      },

      _IndexObjetoSelected(obj) {
        return this.selectedObjects.findIndex( (item) => { return item.attrs.id === obj.attrs.id } )
      },

      _IndexObjetoModified(obj) {
        return this.modifiedEntities.findIndex( (item) => { return item.attrs.id === obj.attrs.id } )
      },

      _IsObjetoSelectedOrModified(obj) {
        return ( _IndexObjetoSelected(obj) !== -1 ) || ( _IndexObjetoModified(obj) !== -1 )
      },

      handleWheel(e) {
        // stop default scrolling
        e.evt.preventDefault();
        this.konvaViewer.ZoomStagePoiter(e, 1.2) //1.01

        // this._Screenshot();

        // console.log( 'canvasContainer: ', this.$refs.canvasContainer )
        // html2canvas(this.$refs.canvasContainer, {
        //   scale: 2,
        //   // width: 1280,
        //   // height: 1080
        // }).then(function(canvas) {
        //     console.log( 'canvas: ', canvas )
        //     document.body.appendChild(canvas);
        // });

      },

      handleClosePopover() {
        this.popoverPosition = { x: -9999, y: 0 }
      },

      handleContextMenu(e) {
        // prevent default behavior
        console.log('handleContextMenu: ', e)
        e.evt.preventDefault();
        e.evt.stopPropagation()
        const stage = this.$refs.stage.getNode();

        if (e.target === stage) {
          // if we are on empty place of the stage we will do nothing
          return;
        }
        let currentShape = e.target;
        console.log('handleContextMenu: ', currentShape)
        // show menu
        // menuNode.style.display = 'initial';
        // var containerRect = stage.container().getBoundingClientRect();
        // menuNode.style.top =
        //   containerRect.top + stage.getPointerPosition().y + 4 + 'px';
        // menuNode.style.left =
        //   containerRect.left + stage.getPointerPosition().x + 4 + 'px';
      },

      handleModifiedEntity(entity) {
        // 1. Actualizamos datos del array de pictogramas original en este caso solo cambia el estado
        this.filterPics.find( (obj) =>  obj.id == entity.attrs.id).status = entity.status

        // 2. Actualizamos el array de entidades modificadas por el usuario
        let posIndex = this.modifiedEntities.findIndex( (obj) => obj.id == entity.attrs.id )
        if( posIndex === -1) {
          this.modifiedEntities.push( {
            id: entity.attrs.id,
            name: entity.attrs.name,
            type: entity.attrs.type,
            status: entity.status,
          } )
        } else if (posIndex > -1) {
          this.modifiedEntities[posIndex].status = entity.status
        }
      },

      async _Screenshot() {
        return new Promise((resolve, reject) => {
          // let timeOut = null;
          // const l = ()=>{
          //     timeOut && window.clearTimeout(timeOut);
          //     try {
          //       console.log('screenshot')\
              this.$emit('epc-screenshot', this.konvaViewer.Screenshot());
              resolve()
          //       timeOut = window.setTimeout(l, 1e3)
          //     } catch (e) {
          //       console.log(e)
          //     }
          // };
          // l()
        })
      },

      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },

      ZoomIn() {
        this.konvaViewer.ZoomStage(1.2)
      },

      ZoomOut() {
        this.konvaViewer.ZoomStage(0.8)
      },

      FitToScreen() {

        return new Promise((resolve, reject) => {
          this.konvaViewer.FitToScreen()
            .then(result => {
              // TODO: esto hay que reactivar por que se usa para guardar la captura inicial o poner un flag que solo al guardar se active
              resolve(this._Screenshot());
              // resolve()
            })
        })
      },

      // Función para manejar el evento de redimensionamiento
      handleResize() {
        this.konvaViewer.Resize()
      },

      handleClickTap(e) {
        console.log( 'cliack tap: ', e )
        // if (e.target === stage) {
        //   selectedObjects.forEach((obj) => {
        //     obj.fill(obj.originalFill);
        //   });
        //   selectedObjects.length = 0;
        //   layer.draw();
        // }
      },

      _AllowedAction(action) {
        if (Object.keys(this.canActionsPictogram).length === 0)
          return false;

        return this.canActionsPictogram[action] === 'undefined' ? false : this.canActionsPictogram[action]
      }
    },

    created() {
      this.uppy = new Uppy({
        debug: process.env.NODE_ENV === 'development', // activamos debug para development
        autoProceed: false,
        allowMultipleUploads: false,
      })
      .use(AwsS3Multipart, {
        limit: 3,
        companionUrl: '/api/v2/',
        companionHeaders: {
          'Authorization': "Bearer " + store.getters["auth/token"]
        }
      })
      // .on('upload-success', (file, response) => { this.onUploadSuccess(file, response) } )
      .on('upload-success', (file, response) => { // callback deveulto cuando el file ha sido subido correctamente
        console.log('%cEPC-TACKER: '+ '%c file ' + file.data.name + ' subido correctamente', 'background: #5577BB; color: #fff', 'color: #000')
      })
      .on('upload-progress', (file, progesss) => { // callback devuelto con el progreso de subido del archivo
        this.uploadPercentage = parseInt(
          Math.round((progesss.bytesUploaded * 100) / progesss.bytesTotal)
        );
      })
      .on('upload-error', (file, error, response) => { // callback devuelto si ha ocurrido algun error en la subida
        console.error('%cEPC-TACKER: '+ '%c a ocurrido un error al subir el archivo ' + file.data.name, 'background: #5577BB; color: #fff', 'color: #000', error)
      })
    },

    async mounted() {

      this.konvaViewer = new KonvaViewer(this.$refs.canvasContainer, this.$refs.stage.getStage())

      this.popupXDistanceMin = this.$refs.ref_tooltip.$el.clientWidth
      this.popupYDistanceMin = this.$refs.ref_tooltip.$el.clientHeight

      this.popoverXDistanceMin = this.$refs.ref_popover.$el.clientWidth
      this.popoverYDistanceMin = this.$refs.ref_popover.$el.clientHeight

      // Observa el evento de redimensionamiento de la ventana
      await this.Load()
      if ( this.typePictogram === 'photovoltaic_park' ) {
        await this._PrepareObjects()
        // this.konvaViewer.SetScaleStage()
        this.konvaViewer.FitViewKonva()
      } else {
        await this._PrepareEntities()
      }

      window.addEventListener('resize', this.handleResize);
      // console.log( 'mounted primero' )
      // this.Render(this.workLayer ? this.workLayer : 'structures')

      // // Obtener el elemento de Konva asociado al rectángulo
      // const rectElement = this.$refs.stage.getStage().find('Rect');
      // console.log( 'rectElement: ', rectElement )
      // // Adjuntar el evento touchstart al elemento de Konva
      // if (rectElement.length) {
      //   rectElement.forEach((el) => {
      //     console.log( el )
      //     // el.addEventListener('touchstart', (event) => {
      //     //   console.log('Touchstart en el rectángulo', event);
      //     //   // Tu lógica para manejar el evento touchstart aquí
      //     // });

      //     el.VueComponent.$el.addEventListener('touchstart', (event) => {
      //       console.log('Touchstart en el rectángulo', event);
      //       // Tu lógica para manejar el evento touchstart aquí
      //     });
      //   })
      // }

      this.$emit('epc-mounted')
    },

    destroyed() {
      this.konvaViewer.Destroy()
      this.konvaViewer = null
    },

    beforeDestroy() {
      // Elimina el observador del evento de redimensionamiento antes de destruir el componente
      window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>

.canvasContainer {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: 100px;
    min-height: 100px;

    background-color: #fefefe;
    .progress {
        position: absolute;
        z-index: 20;
        width: 90%;
        margin: 20px 5%;

        .progressText {
            margin: 10px 20px;
            font-size: 14px;
            color: #262d33;
            text-align: center;
        }
    }

    .error {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 20;
        padding: 30px;

        img {
            width: 24px;
            height: 24px;
            vertical-align: middle;
            margin: 4px;
        }
    }
}

</style>
