// Plugin propio para manejo de token fcm push

import axios from 'axios'
import firebase from "firebase/app";
import "firebase/messaging";

// FIREBASE WEB APP

export default {

  messaging: null, //futuro objeto messaging

  firebaseConfig: {
    apiKey: process.env.MIX_FIREBASE_WEBAPP_API_KEY || "AIzaSyAtDCP1j_eWwkkxWrWKpE0mGpRt5uTej1I",
    authDomain: process.env.MIX_FIREBASE_WEBAPP_AUTH_DOMAIN || "epc-tracker-mobile.firebaseapp.com",
    databaseURL: process.env.MIX_FIREBASE_WEBAPP_DATABASE_URL || "https://epc-tracker-mobile.firebaseio.com",
    projectId: process.env.MIX_FIREBASE_WEBAPP_PROJECT_ID || "epc-tracker-mobile",
    storageBucket: process.env.MIX_FIREBASE_WEBAPP_STORAGE_BUCKET ||  "epc-tracker-mobile.appspot.com",
    messagingSenderId: process.env.MIX_FIREBASE_WEBAPP_MESSAGING_SENDER_ID ||  "813152257413",
    appId: process.env.MIX_FIREBASE_WEBAPP_APP_ID ||  "1:813152257413:web:3df8475afa3d81685ff0ec",
    measurementId: process.env.MIX_FIREBASE_WEBAPP_MEASUREMENT_ID ||  "G-FW2MLJ6ZHN"
  },

  initialize: function() {

    if (this.messaging === null ) {
      // Initialize Firebase
      firebase.initializeApp(this.firebaseConfig); 
        
      this.messaging = firebase.messaging();
    }
  },

  // comprueba si navegador soporta firebase messaging (en este momento safari no)
  isSupported: function() {
    return firebase.messaging.isSupported()
  },

  // llamada tras loguear para registrar el token en Devices como dispositivo tipo web
  registerFCMWebToken: function(data) {

    if (! this.isSupported()) {
      return 0;
    }

    if (Notification.permission == 'granted') {
  
      this.initialize();
  
      this.messaging.getToken().then((token) => {
  
        // actualiza fcm token si ha cambiado con el q tiene el user en DB (devices)
        if (token != data.fcm_web_token) {
          
          // Nuevo token detectado
          let url = '/api/v2/device/register'
          let postData = {
              user_id: data.user.id,
              device_type: 'Web',
              fcm_token: token
              // device_model: vm.$browserDetect.meta.name, (chrome) TO-DO. plugin vue-browser-detect-plugin
              // device_version: vm.$browserDetect.meta.version (89)
          }

          axios.post(url, postData).then(function (response) {
            if (response.data.success !== 'true' && response.data.success !== true)
              console.log('Error guardando nuevo token fcm');
          })
          
        }
      })
    }
    else if (Notification.permission !== "denied") {
      // si no establecido, pedir permiso y empezamos de nuevo
      Notification.requestPermission().then( (permission) => this.registerFCMWebToken(data))
    }
  }
}
