<template>
<div class="w-full h-full inset-0">
  <b-loading :active.sync="isLoading"></b-loading>
  <div :id="chartContainerName" class="chart-container"></div>
</div>
</template>

<script>
import axios from 'axios'
import OrgChart from './orgchart'
import { mergeOptions, closest, bindEventHandler, getId, addNodes, deleteNodes, resetPanel, exportJSON } from './utils'

export default {
  name: 'orgchart',

  props: {
    getDataUrl: { type: String, default: null },
    chartContainerName: { type: String, default: 'chart-container' },
    createNodeCallback: { type: Function, default: null },
    withUsers : { type: Number, default: 0 },

    chartData: { type: Object, default () { return {} } },
    pan: { type: Boolean, default: true },
    zoom: { type: Boolean, default: true },
    direction: { type: String, default: 't2b' },
    verticalDepth: { type: Number },
    toggleSiblingsResp: { type: Boolean, default: false },
    ajaxURL: { type: Object },
    depth: { type: Number, default: 999 },
    nodeTitle: { type: String, default: 'name' },
    parentNodeSymbol: { type: String, default: '' },
    nodeContent: { type: String },
    nodeId: { type: String, default: 'id' },
    exportButton: { type: Boolean, default: false },
    exportFilename: { type: String },
    chartClass: { type: String, default: '' },
    //draggable: { type: Boolean, default: true },
    dropCriteria: { type: Function },
  },

  data: () => ({
    orgchart: null,
    defaultOptions: {
      data: {},
    },
    options: {},
    isLoading: false,
    zoomLevel: 0,
  }),

  mounted() {
    // Callback para pintar cada nodo
    this.defaultOptions.createNode = ((node, data) => {
      // Primero el interno
      this.__createNode(node, data)
      // Luego, si lo hay, el externo
      if (this.createNodeCallback) {
        this.createNodeCallback(node, data)
      }
    })

    // Si hay url, cargamos los datos de ella
    if (this.getDataUrl) {
      this.getDataFromUrl()
    } else {
      // Si no, cargamos los datos pasados en la propiedad
      this.initOrgChart()
    }
  },

  beforeDestroy() {
    let chartContainer = document.querySelector(this.options.chartContainer)
    if (chartContainer) {
      chartContainer.removeEventListener('click', this.clickNode)
    }
    document.querySelectorAll('.orgchart').forEach(element => {
      element.removeEventListener('click', this.clickChart)
    })
  },

  methods: {
    initOrgChart(data) {
      this.options = mergeOptions(this.defaultOptions, this.$props)
      this.options.chartContainer = '#' + this.chartContainerName
      this.options.data = data ? data[0] : this.chartData
      this.orgchart = new OrgChart(this.options)
      //this.orgchart.chart.style.transform = 'matrix(1, 0, 0, 1, 0, 0) scale(1, 1)'
      this.$nextTick(() => {
        bindEventHandler('.node', 'click', this.clickNode, this.options.chartContainer)
        bindEventHandler('.orgchart', 'click', this.clickChart)
        bindEventHandler('.orgchart', 'wheel', this.onWheeling)
      })


    },

    async getDataFromUrl() {
      this.isLoading = true
      var params = { with_users: this.withUsers}
      const { data } = await axios.get(this.getDataUrl, {params: params})
      if (data && data.success) {
        this.initOrgChart(data.nodes)
      } else {
        this.$notify.error('error_loading_orgchart_data');
      }
      this.isLoading = false
    },

    addNode (selectedNode, child) {
      let hasChild = selectedNode.parentNode.colSpan > 1
      child.relationship = hasChild ? '110' : '100'
      if (hasChild) {
        this.orgchart.addSiblings(closest(selectedNode, el => el.nodeName === 'TABLE').querySelector('.nodes').querySelector('.node'), { 'siblings': [child] })
      } else {
        this.orgchart.addChildren(selectedNode, { 'children': [child] })
      }
    },

    removeNode (selectedNode) {
      this.orgchart.removeNodes(selectedNode)
    },

    clickNode (event) {
      let sNode = closest(event.target, el => el.classList && el.classList.contains('node'))
      let sNodeInput = document.getElementById('selected-node')
      if (sNodeInput !== null) {
        sNodeInput.value = sNode.querySelector('.title').textContent
        sNodeInput.dataset.node = sNode.id
        sNodeInput.dispatchEvent(new Event('change'))
      }
      this.$emit('selectedNodeChanged', sNode)
    },

    clickChart (event) {
      if (!closest(event.target, el => el.classList && el.classList.contains('node'))) {
        let sNodeInput = document.getElementById('selected-node')
        if (sNodeInput !== null) {
          sNodeInput.value = ''
          sNodeInput.dispatchEvent(new Event('change'))
        }
        this.$emit('selectedNodeChanged', null)
      }
      // console.log('AQUI')
      // console.log(this.orgchart.chart.style.transform)
    },

    updateNode (node) {
      node.querySelector('.title').textContent = node.dataset.name
    },

    __createNode (node, data) {
      // Callback en el OrgChart para modificar los nodos
      // En este caso añadimos el 'ojo' para usuarios visualizadores
      let title = node.getElementsByClassName("title")[0]
      title.innerHTML = '<span class="flex-grow truncate">' + data.name + '</span>'
      title.setAttribute('class', 'title flex items-center w-full py-1')
      // Diferenciamos los nodos de usuario de departamento
      if (data.dept_user_node === true) {
        title.setAttribute('style', 'background-color:#D8D8D8')
        //node.setAttribute('draggable', false)
      }

      let icon = document.createElement('img')
      icon.setAttribute('src', '/img/eye.svg')
      icon.setAttribute('class', 'mx-1 opacity-0 truncate')
      icon.setAttribute('style', 'width:18px;height:18px')
      title.appendChild(icon)
    },

    // onWheeling (event) {
    //   event.preventDefault();
    //   let transform = this.orgchart.chart.style.transform
    //
    //   console.log(this.orgchart.chart)
    //   console.log(event)
    //   console.log(transform)
    //
    //   if (transform !== '' && transform.includes('matrix')) {
    //
    //     let initM = transform.indexOf("(")
    //     let endM = transform.indexOf(")")
    //     let matrix = transform.substr(initM + 1, endM - initM -1)
    //     let valuesM = matrix.split(',')
    //
    //     if (parseFloat(valuesM[0].replace(' ','')) < 0.60 && event.deltaY > 0) {
    //       event.stopImmediatePropagation()
    //     } else if (parseFloat(valuesM[0].replace(' ','')) > 1.30 && event.deltaY < 0) {
    //       event.stopImmediatePropagation()
    //     }
    //   }
    // }

  },

};
</script>

<style>
  .ghost-node {
    display:none;
  }
</style>
