<template>
  <div class="subheader flex flex-col">
    <div class="actions-group" id="file-actions">
      <div class="float-left">

        <div action-name="views" action-category="left-panel" class="subheader__item c-subheader__item--views ga-model-views"
          @click="() => { $emit('epc-open-panel', 'panel-views') }">
          <b-icon size="is-medium" icon="window-restore" pack="fas"></b-icon>
          <span class="subheader__item-txt ">
            <span class="subheader__item-txt-number"></span>
            <span class="subheader__item-txt-string">{{ $t('views') }}</span>
          </span>
        </div>

        <div action-name="layers" action-category="viewer-panel-lp" class="subheader__item c-subheader__item--layers ga-model-layers"
          @click="() => { $emit('epc-open-panel', 'panel-layers') }">
          <b-icon size="is-medium" icon="layer-group" pack="fas"></b-icon>
          <span class="subheader__item-txt ">
            <span class="subheader__item-txt-number"></span>
            <span class="subheader__item-txt-string">{{ $t('layers') }}</span>
          </span>
        </div>

        <!-- <div action-name="properties" action-category="viewer-panel-props" class="subheader__item c-subheader__item--properties ga-model-properties c-subheader__item--hide-mobile">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="subheader__item-icon"><path d="M17 5H7a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1zM8 17V7h8v10zm7-8a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1 1 1 0 0 1 1-1h4a1 1 0 0 1 1 1zm0 3a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1 1 1 0 0 1 1-1h4a1 1 0 0 1 1 1zm-2 3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1 1 1 0 0 1 1-1h2a1 1 0 0 1 1 1z"></path></svg>
          <span class="subheader__item-txt ">
            <span class="subheader__item-txt-number"></span>
            <span class="subheader__item-txt-string">Properties</span>
          </span>
        </div>

        <div action-name="settings" action-category="viewer-panel-settings" class="subheader__item c-subheader__item--settings ga-model-settings c-subheader__item--hide-mobile">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="subheader__item-icon"><path d="M18.45 11.18l-1.09-.4a5.65 5.65 0 0 0-.7-1.7L17.15 8a.89.89 0 0 0-.2-1 .86.86 0 0 0-1-.2l-1.05.49a5.38 5.38 0 0 0-1.71-.71L12.8 5.5a.9.9 0 0 0-1.66 0l-.39 1.08a5.38 5.38 0 0 0-1.71.71L8 6.86a.86.86 0 0 0-1 .2.89.89 0 0 0-.2 1l.49 1.05a5.65 5.65 0 0 0-.7 1.7l-1.09.4a.89.89 0 0 0 0 1.65l1.09.4a5.43 5.43 0 0 0 .71 1.7L6.85 16a.89.89 0 0 0 .2 1 .86.86 0 0 0 1 .2l1.06-.49a5.7 5.7 0 0 0 1.69.7l.4 1.1a.91.91 0 0 0 1.66 0l.4-1.1a5.7 5.7 0 0 0 1.69-.7l1.06.49a.86.86 0 0 0 1-.2.89.89 0 0 0 .2-1l-.5-1.05a5.43 5.43 0 0 0 .71-1.7l1.09-.4a.89.89 0 0 0 0-1.65zM12 14.5a2.5 2.5 0 1 1 2.5-2.5 2.5 2.5 0 0 1-2.5 2.5z" fill-rule="evenodd"></path></svg>
          <span class="subheader__item-txt ">
            <span class="subheader__item-txt-number"></span>
            <span class="subheader__item-txt-string">Settings</span>
          </span>
        </div> -->
      </div>

      <div class="float-right">
        <!-- <div action-name="comments" action-category="right-panel" class="subheader__item c-subheader__item--comments ga-model-comments">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="subheader__item-icon"><path d="M17 5H7a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h5.7l3.6 2.9a.4.4 0 0 0 .7-.3V16a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2zm-4.5 8h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1zm3-2h-7a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zm0-2h-7a.5.5 0 0 1 0-1h7a.5.5 0 1 1 0 1z"></path></svg>
          <span class="subheader__item-txt ">
            <span class="subheader__item-txt-number">0</span>
            <span class="subheader__item-txt-string">Comentarios</span>
          </span>
        </div> -->

        <!-- <div action-name="print" action-category="right-panel" class="subheader__item c-subheader__item--print ga-model-print c-subheader__item--hide-mobile">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="subheader__item-icon"><path d="M19 11v4a2 2 0 0 1-2 2h-1v-4H8v4H7a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2zM9 18a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4H9zm7-12a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v2h8z" fill-rule="evenodd"></path></svg>
          <span class="subheader__item-txt ">
            <span class="subheader__item-txt-number"></span>
            <span class="subheader__item-txt-string">Print</span>
          </span>
        </div> -->

        <!-- <div action-name="screenshot" action-category="right-panel" class="subheader__item c-subheader__item--screenshot ga-model-screenshot c-subheader__item--hide-mobile">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="subheader__item-icon"><path d="M18 8h-1.8l-.7-1.8A2 2 0 0 0 13.6 5h-3.2a2 2 0 0 0-1.9 1.3L7.8 8H6a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2zm-6 9a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm3-4a3 3 0 1 1-3-3 3 3 0 0 1 3 3z"></path></svg>

          <span class="subheader__item-txt ">
            <span class="subheader__item-txt-number"></span>
            <span class="subheader__item-txt-string">Captura de pantalla</span>
          </span>
        </div> -->

        <!-- <div action-name="share" action-category="right-panel" class="subheader__item c-subheader__item--share ga-model-share">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="subheader__item-icon"><path d="M15.3 12.7a4 4 0 0 1-1.2 2.8L12.7 17l-.7.7A4 4 0 0 1 6.3 12l1.4-1.4a5 5 0 0 0 .3 2.5l-.3.3a2 2 0 0 0 2.8 2.8l.7-.7 1.4-1.4a2 2 0 0 0 0-2.8l-.7-.7 1.4-1.4.7.7a4 4 0 0 1 1.3 2.8zm.6-1.8a5 5 0 0 1 .3 2.5l1.5-1.4A4 4 0 0 0 12 6.3l-1.4 1.5-.7.7a4 4 0 0 0 0 5.7l.7.7 1.4-1.5-.7-.7a2 2 0 0 1 0-2.8l.7-.7 1.4-1.4a2.1 2.1 0 0 1 2.8 0 2 2 0 0 1 0 2.8"></path></svg>
          <span class="subheader__item-txt ">
            <span class="subheader__item-txt-number"></span>
            <span class="subheader__item-txt-string">Share</span>
          </span>
        </div> -->
      </div>

    </div>
  </div>
</template>

<script>

  export default {
    name: "ViewerHeader",

    props: {

    },

    watch: {
    },

    data() {
      return {

      }
    },

    methods: {

    }
  }

</script>

<style scoped>
  .subheader {
    height: 45px;
    background: #fff;
    padding: 11px 15px 10px;
    line-height: 24px;
    font-size: 11px;
    border-bottom: 1px solid #d4dbe1;
  }
  .subheader__item{
    display: inline-block;
    border-radius: 14px;
    padding: 0 10px 0 6px;
    cursor: pointer;
    font-weight: bold;
    transition: box-shadow .25s ease-out;
  }

  svg {
    display: inline;
  }
  svg:not(:root) {
    overflow-clip-margin: content-box;
    overflow: hidden;
  }
  .subheader__item-txt{
    color: #4a555b;
    transition: color .25s ease-out;
  }

  .subheader__item+.subheader__item {
    margin-left: 6px;
  }

  .subheader__item-icon {
    vertical-align: middle;
    transition: fill .25s ease-out;
    position: relative;
    top: -1px;
  }

  @media screen and (min-width: 768px) {
    .subheader__item:hover {
        box-shadow: 0 1px 1px 0 rgba(0,0,0,.3);
    }
    .subheader__item:hover .subheader__item-txt {
      color: #0a131c;
    }
  }
</style>
