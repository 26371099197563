<template>
<div class="container">
  <h1 v-if="title" class="title">{{ $t(title) }}</h1>
  <h2 v-if="subtitle" class="subtitle">{{ $t(subtitle) }}</h2>
  <div class="row">
    <slot></slot>
  </div>
</div>
</template>

<script>
export default {
  name: 'page-wrapper',

  props: {
    title: { type: String },
    subtitle: { type: String },
  },
};
</script>
