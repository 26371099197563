  <template>
  <div>
    <div class="top-bar flex justify-end">
      <v-button
        @click="() => { showFormImportEntities = true }"
        icon="file-import"
        :disabled="showFormData || showFormLink"
      >{{ $t('import_chapters') }}</v-button>

      <v-button
        @click="addChapter"
        icon="plus"
        class="ml-4"
        :disabled="showFormImportEntities || showFormLink"
      >{{ $t('add_chapter') }}</v-button>
    </div>

    <div class="flex">
      <div :class="[( showFormData || showFormImportEntities || showFormLink ? 'w-7/12' : 'w-full'), 'mr-2']">
        <chapters-list ref="list_chapters"
          :entity-type="entityType"
          :entity-id="projectId"
          :disabled-menu-actions="showFormImportEntities || showFormData || showFormLink"
          @add-chapter="handleAddChapter"
          @edit-chapter="handleEditChapter"
          @link-chapter="handleLinkChapter"
          @drop-chapter="handleDropChapter"
        ></chapters-list>
      </div>

      <transition name="fade">
        <div class="w-5/12 ml-2" v-if="showFormData">
          <chapter-edit
            :entity-type="entityType"
            :entity-id="projectId"
            :chapter="chapter"
            @epc-close="handleOnCloseEdit"
            @epc-success-creating="handleSuccess"
          />
        </div>

        <div class="w-5/12 ml-2" v-if="showFormLink">
          <chapter-link
            :project-id="projectId"
            :chapter="chapter"
            :entity-type="entityType"
            @epc-close="handleOnCloseLinkGroup"
          />
        </div>

        <div class="w-5/12 ml-2" v-if="showFormImportEntities">
          <chapters-import
            :projectId="projectId"
            :entity-type="'chapters'"
            :morph-type="entityType"
            @epc-close="(event) => { showFormImportEntities = false }"
            @epc-success-import="() => { showFormImportEntities = false ; $refs.list_chapters.reload() }"
          />
        </div>

      </transition>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import ChaptersList from './chapters-list.vue';
import ChapterEdit from './chapter-edit.vue';
// import ChapterLink from './chapter-link.vue';
import ChaptersImport from './chapters-import.vue';

export default {
  name: "manage-chapters",

  components: {
    ChaptersList,
    ChapterEdit,
    // ChapterLink,
    ChaptersImport,
  },

  data: () => ({
    group: null, // objeto que se crea
    showFormData: false,
    showFormImportEntities: false,
    showFormLink: false,
  }),

  props: {
    projectId: { type: Number, required: true },
    entityType: { type: String, required: true }
  },

  computed: {
  },

  created() {
  },

  mounted() {
  },

  methods: {
    addChapter() {
      this.chapter = null
      this.showFormData = true;
    },


    handleOnCloseEdit(event) {
      this.showFormData = !event
    },

    handleOnCloseLinkGroup(event) {
      this.showFormLink = !event
    },

    handleSuccess(entity) { // recibe la entidad editada
      this.showFormData = false
      this.$refs.list_chapters.reload()
    },

    // handleEditEntity(entity) {
    //   this.entity = entity
    //   this.showFormData = true;
    // },

    handleAddChapter(chapter) {
      this.chapter = chapter
      this.showFormData = true;
    },

    handleEditChapter(chapter) {
      this.chapter = chapter
      this.showFormData = true;
    },

    handleLinkChapter(chapter) {
      this.chapter = chapter
      this.showFormLink = true
    },

    async handleDropChapter(chapter) {
      let form = new Form({
        id: chapter.id,
        entity_type: this.entityType,
        parent_id: chapter.parent_id,
        code: chapter.code,
        name: chapter.name,
      })

      let url = `/api/v2/chapters/${chapter.id}`;

      const { data } = await form.post(url).catch((error) => { console.log(error) })

      if (data && data.success) {

        this.$notify.success('save_successful')

        if( data.warning )
          this.$notify.warning(data.warning)

      } else {
        this.processingData = false
        if( data && data.message )
          this.$notify.error(data.message)
        else
          this.$notify.error('assignment_change_fail')
      }
    }
  }

};
</script>

<style scoped>

</style>
