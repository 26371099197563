<template>
  <div>
    <div>
      <!-- Tabla de Asignaciones-->
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInUp"
        leave-active-class="animated fadeOutDown"
      >
        <div class="panel">
          <section>
            <b-table
              show-empty
              :data="data"
              :loading="isLoading"

              paginated
              backend-pagination
              :per-page="perPage"
              :total="total"
              @page-change="onPageChange"

              detailed
              detail-key="id"
              :has-detailed-visible="displaySubactivitiesOrAssignments"
              :opened-detailed="[this.openedSubactivitiesId]"
              @details-open="openDetails"
              @details-close="(row, index) => this.openedSubactivitiesId = 0"

              striped
              bordered
              icon-pack="fas"
              :row-class="getRowClass"

              :checkable="!listItems.length"
              :checked-rows.sync="checkedRows"
              @check="handCheck"
              @check-all="handCheckAll"
            >
              <template>
                <b-table-column field="code" :label="$t('code')" v-slot="props">{{ props.row.code }}</b-table-column>

                <b-table-column field="name" :label="$t('name')" v-slot="props">{{ props.row.name}}</b-table-column>

                <b-table-column field="start_date" :label="$t('start_date')" v-slot="props" width="150px" :visible="!loadMeasurements">
                  <b-datepicker
                    v-model="props.row[startField]"
                    :placeholder="$t('start_date')"
                    :readonly="false"
                    :min-date="project_start_date != null ? project_start_date : null"
                    :max-date="project_end_date !== null ? project_end_date : null"
                    :month-names="$t('months_names')"
                    :day-names="$t('days_names')"
                    :first-day-of-week="parseInt($t('first_day_of_week'))"
                    icon-pack="fas"
                    size="is-small"
                    :disabled="props.row.selected === undefined ? true : !props.row.selected"
                    @input="checkStartDate"
                  />
                </b-table-column>

                <b-table-column field="end_date" :label="$t('end_date')" v-slot="props" width="150px" :visible="!loadMeasurements">
                  <b-datepicker ref="end"
                    v-model="props.row[endField]"
                    :placeholder="$t('delivery_date')"
                    :readonly="false"
                    :min-date="project_start_date != null ? project_start_date : null"
                    :max-date="project_end_date !== null ? project_end_date : null"
                    :month-names="$t('months_names')"
                    :day-names="$t('days_names')"
                    :first-day-of-week="parseInt($t('first_day_of_week'))"
                    icon-pack="fas"
                    size="is-small"
                    :disabled="props.row.selected === undefined ? true : !props.row.selected"
                  />
                </b-table-column>

                <b-table-column field="weight" :label="$t('weight')" v-slot="props">{{ props.row.weight }}</b-table-column>

              </template>

              <template slot="detail" slot-scope="activities">

                <!--Mediciones -->
                <div class="mb-1" v-if="loadMeasurements && !hasSubactivitiesSelect(activities.row)">
                  <div>
                    <h4>
                      <b>{{ $t('measurements') }}</b>
                    </h4>
                  </div>

                  <template-activity-measurements
                    :list="activities.row.assignments"
                    :entityId="activities.row.id"
                    :hideActions="hideActions"
                  />
                </div>

                <!-- Subactividades -->
                <div v-if="displaySubactivities(activities.row) && hasSubactivitiesSelect(activities.row)">
                  <div>
                    <h4>
                      <b>{{ activities.row.subactivities_num }} {{
                          activities.row.subactivities_num > 1 ?
                            $t('subactivities') : $t('subactivity')
                        }}</b>
                    </h4>
                  </div>

                  <template>
                    <b-table
                      ref="subactivities"
                      :data="activities.row.subactivities"
                      bordered
                      class="text-xs border-blue-lighter bg-grey-lighter subactivities"
                      narrowed

                      detailed
                      detail-key="id"
                      icon-pack="fas"
                      :has-detailed-visible="displaySubactivitiesOrAssignments"

                      :checkable="!listItems.length && activities.row.selected"
                      :checked-rows.sync="checkedRowsSub"
                      @check="handCheckSub"
                      @check-all="handCheckAllSub"
                    >

                      <template>
                        <b-table-column field="code" :label="$t('code')" v-slot="props">{{ props.row.code }}</b-table-column>

                        <b-table-column field="name" :label="$t('name')" v-slot="props" cell-class="vertical-center" > {{ props.row.name }} </b-table-column>

                        <b-table-column field="start_date" :label="$t('start_date')" v-slot="props" width="150px" :visible="!loadMeasurements">
                          <b-datepicker
                            v-model="props.row[startField]"
                            :placeholder="$t('start_date')"
                            :readonly="false"
                            :min-date="project_start_date != null ? project_start_date : null"
                            :max-date="project_end_date !== null ? project_end_date : null"
                            :month-names="$t('months_names')"
                            :day-names="$t('days_names')"
                            :first-day-of-week="parseInt($t('first_day_of_week'))"
                            icon-pack="fas"
                            size="is-small"
                            :disabled="props.row.selected === undefined ? true : !props.row.selected"
                            @input="checkStartDate"
                          />
                        </b-table-column>

                        <b-table-column field="end_date" :label="$t('end_date')" v-slot="props" width="150px" :visible="!loadMeasurements">
                          <b-datepicker ref="end"
                            v-model="props.row[endField]"
                            :placeholder="$t('delivery_date')"
                            :readonly="false"
                            :min-date="project_start_date != null ? project_start_date : null"
                            :max-date="project_end_date !== null ? project_end_date : null"
                            :month-names="$t('months_names')"
                            :day-names="$t('days_names')"
                            :first-day-of-week="parseInt($t('first_day_of_week'))"
                            icon-pack="fas"
                            size="is-small"
                            :disabled="props.row.selected === undefined ? true : !props.row.selected"
                          />
                        </b-table-column>

                        <b-table-column field="weight" :label="$t('weight')" v-slot="props" width="100px">
                          <b-input v-if="!loadMeasurements"
                            v-model="props.row.weight"
                            :type="'text'"
                            :size="'is-small'"
                            :placeholder="'0'"
                            icon-pack="fas"
                            :disabled="props.row.selected === undefined ? true : !props.row.selected"
                          />
                          <span v-else>{{ props.row.weight }}</span>
                        </b-table-column>

                      </template>

                      <template slot="detail" slot-scope="child">
                      <!--Mediciones -->
                      <div class="mb-1" v-if="loadMeasurements">
                        <div>
                          <h4>
                            <b>{{ $t('measurements') }}</b>
                          </h4>
                        </div>

                        <template-activity-measurements
                          :list="child.row.assignments" :entityId="child.row.id"
                          :entityBlockedAssignments="child.row.blocked_assignments == 1"
                          :hideActions="hideActions"
                        />
                      </div>
                    </template>
                    <template #empty>
                      <div class="has-text-centered">{{ $t('no_results') }}</div>
                    </template>
                    </b-table>
                  </template>
                </div>
              </template>

              <template #empty>
                <div class="has-text-centered">{{ $t('no_results') }}</div>
              </template>
            </b-table>
          </section>

        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import TemplateListMeasurements from './template-list-measurements.vue';
import TemplateActivityMeasurements from './template-activity-measurements.vue';

export default {
  name: "template-project-activities",

  components: {
    TemplateListMeasurements,
    TemplateActivityMeasurements
  },

  props: {
    projectId: { type: Number, required: true }, // identificador de la plantilla de projecto
    project: { type: Object, default: null }, // datos del nuevo projecto a generar para obtener fecha inicio y fecha finalizacion
    hideActions: { type: Boolean, default: true },
    loadMeasurements: { type: Boolean, default: false },
    listItems: { type:Array, default: function() { return [] } }
  },

  data: function() {
    return {
      data: [],
      isLoading: false,
      page: 1,
      perPage: 1000,
      total: 0,

      showSubs: false,
      openedSubactivitiesId: 0,
      subactivities: [],
      checkedRowsSub: [],

      checkedRows: [], // Actividades seleccionadas
      currentRow: null,

      // The Start Date field name
      startField: 'start_date',
      // The End Date field name
      endField: 'end_date',
      minStartField: 'start_date',
      maxEndField: 'end_date',

      // start and end date project, valores min y max de rango de fechas
      project_start_date: this.project ? this.project.raw_scheduled_start_date : null,
      project_end_date: this.project ? this.project.raw_scheduled_end_date: null,
    }
  },

  watch: {
    projectId: function() { this.getProjectActivities(); },
    listItems: function(newVal, oldVal) {
      this.data = [...newVal]
    },

    // project: {
    //   deep: true,
    //   handler: (newValue, oldValue) => {
    //     console.log('force updated component')
    //   },
    // }
    'project.raw_scheduled_end_date': function(newValue, oldValue) {
        this.project_end_date = newValue ? newValue : null
        this.setStartEndDateActivities()
    },

    'project.raw_scheduled_start_date': function(newValue, oldValue) {
        this.project_start_date = newValue ? newValue : null
        this.setStartEndDateActivities()
    },
  },

  mounted() {
    if(!this.listItems.length)
      this.getProjectActivities();
    else {
     this.data = this.listItems
    }
  },

  methods: {
    async getProjectActivities() {
      this.isLoading = true;
      let params = {
        p: this.projectId,
        order_dir: this.orderDir,
        order_field: this.orderField,
        add_data: 1
      };
      const { data } = await axios.get(
        "/api/v2/activity/templates",
        { params: params }
      );
      if (data && data.success) {
        // añadimos los campos start_date y end_date del projecto
        data.activities.forEach((item) => {
          item.start_date = this.project_start_date; item.end_date = this.project_end_date
          item.subactivities.forEach((item) => { item.start_date = this.project_start_date; item.end_date = this.project_end_date });
        });

        this.data = data.activities;
        this.total = data.activities ? data.activities.length : 0;
        this.checkedRows = this.data;
      } else {
        this.$notify.error("error_loading_activities");
      }
      this.isLoading = false;
    },

    reload() {
      this.getProjectActivities();
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    onPageChange(page) {
      this.page = page;
      this.reload();
    },

    setStartEndDateActivities() {
      this.isLoading = true;

      this.data.forEach((item) => {
        item.start_date = this.project_start_date;
        item.end_date = this.project_end_date

        item.subactivities.forEach((item) => {
          item.start_date = this.project_start_date;
          item.end_date = this.project_end_date
        });
      });

      this.isLoading = false;
    },

    displaySubactivities(row) {
      return !row.isSubactivity && row.subactivities_num > 0
    },

    displayAssignments(row) {
      return (row.assignments && row.assignments.length > 0);
    },

    displaySubactivitiesOrAssignments(row) {
      return !!(this.displayAssignments(row) || this.displaySubactivities(row));
    },

    openDetails(row, index) {
      this.checkedRowsSub.push(...row.subactivities)
      this.currentRow = row
    },

    getSubactivities(row) {
      if( this.listItems.length )
        return row.subactivities_selected

      return this.subactivities[row.id]
    },

    getRowClass(row, index) {
      let cssClass = '';
      // if (row.parent_access && !this.filterSubactivities) {
      //   cssClass = 'is-hidden'
      // } else if (row.subactivities_notifications) {
      //   cssClass = cssClass + ' sub-notifications'
      // }

      return cssClass
    },

    checkStartDate(date) {
      // if (date > this.form[this.endField]) {
      //   this.form[this.endField] = date
      // }
    },

    // checked activity
    handCheck(checkedList, row) {
      if( typeof row !== 'undefined' ){
        let index = this.data.findIndex((activity) => activity.id == row.id)
        // si se encuentra marcamos como seleccionado o deseleccionada la actividas y por defecto las subactividades
        // y mediciones asumen de la actividad padre
        if (index >= 0) {
          this.data[index].selected = !this.data[index].selected
          let parentSelected = this.data[index].selected
          this.data[index].subactivities.forEach((sub) => {
            sub.selected = parentSelected
             if( typeof sub.assignments !== 'undefined' )
              sub.assignments.forEach((assignment) => {
                assignment.selected = parentSelected
              })
          })

          if( typeof this.data[index].assignments !== 'undefined' )
            this.data[index].assignments.forEach((assignment) => {
              assignment.selected = parentSelected
            })
        }
      }

      // this.dispatchSelected(checkedList)
    },

    handCheckAll(checkedList) {
      let allActvitiesUnselected = checkedList.length === 0

      this.data.forEach((activity) => {
        activity.selected = !allActvitiesUnselected
        if( typeof activity.assignments !== 'undefined' )
          activity.assignments.forEach((assignment) => {
            assignment.selected = !allActvitiesUnselected
          })

        if( typeof activity.subactivities !== 'undefined' ) {
          activity.subactivities.forEach((subactivity) => {
            subactivity.selected = !allActvitiesUnselected
            if( typeof subactivity.assignments !== 'undefined' ){
              subactivity.assignments.forEach((assignment) => {
                assignment.selected = !allActvitiesUnselected
              })
            }
          })
        }
      })

      // this.dispatchSelected(checkedList)
    },

    dispatchSelected(checkedList) {
      this.$emit('selected-items', checkedList)
    },

    // checked subactivity
    handCheckSub(checkedList, row) {
      if( typeof row !== 'undefined' ) {
        let indexActivity = this.data.findIndex((activity) => activity.id == row.parent_id)
        // let indexActivity = this.data.map(activity => activity.id).indexOf(row.parent_id)

        if( indexActivity > -1 ) {
          let index = this.data[indexActivity].subactivities.findIndex((sub) => sub.id == row.id)

          if (index >= 0) {
            this.data[indexActivity].subactivities[index].selected = !this.data[indexActivity].subactivities[index].selected
            let selectedSubactivity = this.data[indexActivity].subactivities[index].selected
            if( typeof this.data[indexActivity].subactivities[index].assignments !== 'undefined' ){
              this.data[indexActivity].subactivities[index].assignments.forEach((assignment) => {
                assignment.selected = selectedSubactivity
              })
            }
          }
        }

        // this.dispatchSelected(this.checkedRows)
      }
    },

    handCheckAllSub(checkedList) {
      let allSubActvitiesUnselected = checkedList.length === 0
      if( this.currentRow )
      {
        let currentRow = this.currentRow
        let indexActivity = this.data.findIndex((activity) => activity.id == currentRow.id)

        console.log('%cEPC-TACKER: '+ '%c Ejecutando subactiviti handCheckAllSub: ', 'background: #5577BB; color: #fff', 'color: #000')

        if( indexActivity > -1 ) {
          this.data[indexActivity].subactivities.forEach((sub) => {
            sub.selected = !allSubActvitiesUnselected
            if( typeof sub.assignments !== 'undefined' ){
              sub.assignments.forEach((assignment) => {
                assignment.selected = !allSubActvitiesUnselected
              })
            }
          })
        }
      }
    },

    hasSubactivitiesSelect( row ) {
      if( !this.listItems.length )
        return true
      return row.subactivities.length > 0
    },

    indexOf(array, obj) {
      if (!array) return -1

      return array.indexOf(obj)
    },

    // Para optener todas las actividades y subactividades seleccionadas seleccionadas
    getActivitiesSelected() {
      // let _activities = [...this.data] // solo clona a primer nivel
      // let _activities = this.deepCopyFunction(this.data) // hace una copia profunda buscar otra manera si da problemas
      let _activities = JSON.parse(JSON.stringify(this.data)); // hace una copia profunda buscar otra manera si da problemas
      this.data.forEach((activity) => {
        // verificamos si la actividad padre no esta selecionada y la eliminamos
        if( !activity.selected ) {
          // let index = this.indexOf(_activities, activity) // NO funciona al parecer trabaja por referencia
          let index = _activities.findIndex((act) => act.id === activity.id)
          if (index >= 0) {
            _activities.splice(index, 1)
          }
        }else {
          // si la actividad padre esta seleccionada verificamos que subactividades no estan seleccionadas para eliminarlas
          activity.subactivities.forEach((subactivity) => {
            let indexActivity = _activities.findIndex((act) => act.id === subactivity.parent_id)
            if( indexActivity > -1 ) {
              // let indexsub = this.indexOf(_activities[indexActivity].subactivities, subactivity)
              let indexsub = _activities[indexActivity].subactivities.findIndex((sub) => sub.id === subactivity.id)

              if( !subactivity.selected ){
                if (indexsub >= 0) {
                  _activities[indexActivity].subactivities.splice(indexsub, 1)
                  // _activities[indexActivity].subactivities[indexsub].selected = _activities[indexActivity].subactivities[indexsub].selected
                }
              } else {
                //parceamos el el weight (reemplazamos la coma por un punto)
                if( indexsub >= 0 ) {
                  let weightSub =_activities[indexActivity].subactivities[indexsub].weight
                  _activities[indexActivity].subactivities[indexsub].weight = parseFloat(  typeof weightSub === "string" ? weightSub.replace(',', '.') : weightSub)
                }else {
                  console.log('%cEPC-TACKER: '+ '%c Index  ' + indexsub + ' subactivity no encontrado ', 'background: #5577BB; color: #fff', 'color: #000')
                }
              }
            }

          })
        }
      })

      return _activities
    },

    deepCopyFunction(inObject) {
      let outObject, value, key

      if (typeof inObject !== "object" || inObject === null) {
        return inObject // Return the value if inObject is not an object
      }

      // Create an array or object to hold the values
      outObject = Array.isArray(inObject) ? [] : {}

      for (key in inObject) {
        value = inObject[key]

        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = this.deepCopyFunction(value)
      }

      return outObject
    }
  }
};
</script>

<style lang="scss">
.disabled-subactivity {
  background: #F8FAFC !important;
  color: #B8C2CA !important;
}
</style>
