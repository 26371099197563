<template>
  <div class="page-with-top-bar">
    <project-admin-quick-buttons hiddenOption="assignments"/>
    <section>
      <div class="mt-4">
        <b-tabs type="is-boxed" v-model="mainActiveTab">
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-import"></b-icon>
              <span>{{ $t('import_assignments') }}</span>
            </template>
            <div>
              <import-entities
                :can-overwrite="true"
                :project-id="projectId"
                entity-type="assignment"
              >
                <template v-slot:descripcion>
                  <span class="text-orange-dark">{{$t('assignment_overwriting_warning')}}</span>
                </template>
              </import-entities>
              <!-- <import-entities-optimized
                :can-overwrite="true"
                :project-id="projectId"
                entity-type="assignment"
              >
                <template v-slot:descripcion>
                  <span class="text-orange-dark">{{$t('assignment_overwriting_warning')}}</span>
                </template>
              </import-entities-optimized> -->
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-export"></b-icon>
              <span> {{ $t('export_assignments') }} </span>
            </template>
            <div>
              <export-assignments :project_id="projectId"></export-assignments>
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="wrench"></b-icon>
              <span> {{ $t('elements_and_attributes') }} </span>
            </template>
            <project-elements-manual
              ref="project_elements_manual"
              :projectId="projectId"
            ></project-elements-manual>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-import"></b-icon>
              <span>{{ $t('import_elements') }}</span>
            </template>
            <div>
              <import-entities
                :can-overwrite="true"
                :project-id="projectId"
                entity-type="assignment_elements"
                @success="elementsImportSuccess"
              />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-import"></b-icon>
              <span>{{ $t('import_expected_executions') }}</span>
            </template>
            <div>
              <import-entities
                :with-history="false"
                :can-overwrite="true"
                :project-id="projectId"
                entity-type="expected_executions"
                @success="elementsImportSuccess"
              />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="stream"></b-icon>
              <span>{{ $t('chapters') }}</span>
            </template>
            <div>
              <manage-chapters
                ref="manage_chapters"
                :project-id="projectId"
                :entity-type="'project'"
              />
            </div>
          </b-tab-item>

          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="stream"></b-icon>
              <span>{{ $t('assignment_interventions') }}</span>
            </template>
            <div>
              <assignment-intervention
                ref="assignment_intervention"
                :project-id="projectId"
              />
            </div>
          </b-tab-item>
          
          <b-tab-item>
            <template slot="header">
              <b-icon pack="fas" icon="file-import"></b-icon>
              <span>{{ $t('pipes') }}</span>
            </template>
            <div>
              <import-pipes
                :can-overwrite="true"
                :project-id="projectId"
                entity-type="dismissed-pipes"
                @success="elementsImportSuccess"
              />
            </div>
          </b-tab-item>
          
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import ExportAssignments from "../../components/export-assignments";
  import ProjectElementsManual from "../../components/project-elements-manual";
  import ImportEntities from "../../components/import-entities";
  import ImportEntitiesOptimized from "../../components/import-entities-optimized";
  import importPipes from "../../components/import-pipes.vue";
  import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons'
  import ManageChapters from "../../components/chapters/manage-chapters";
  import AssignmentIntervention from "../../components/assignments/intervention/assignment-intervention";

  export default {
    middleware: "auth",
    title: "assignments_page_title",
    menuOption: "0-2",

    components: {
      ImportEntities,
      ImportEntitiesOptimized,
      importPipes,
      ProjectAdminQuickButtons,
      ProjectElementsManual,
      ExportAssignments,
      ManageChapters,
      AssignmentIntervention
    },

    computed: {
      ...mapGetters({
        navData: "app/navData",
      }),
      projectId() {
        return parseInt(this.navData.project_id);
      }
    },

    data: () => ({
      mainActiveTab: 0
    }),

    watch: {
      mainActiveTab(value) {
        if (value == 0) {
          // no aplicable
          //this.$refs.project_assignments_manual.reload();
        }
      }
    },

    created() {
      let projectName = this.navData.project_name
        ? this.navData.project_name.toUpperCase()
        : "";
      this.$options.subtitle = ["project_actions_page_subtitle", [projectName]];
    },

    methods: {
      // measurementUnitsImportSuccess() {
      //   this.$refs.project_measurements_manual.reload();
      // },

      elementsImportSuccess() {
        this.$refs.project_elements_manual.reload();
      }
    }
  };
</script>
