<template>
  <div class="mr-2 rounded-sm">
    <b-tooltip :label="textLabel"
      :active="activeTooltip"
      :position="positionTooltip">
      <v-button
        :icon="icon"
        class=""
        size="is-small"
        @click="handleClick">
        <span v-show="showText">{{ $t(textLabel) }}</span>
      </v-button>
    </b-tooltip>
  </div>
</template>

<script>

  export default {
    name: 'button-classic',

    computed: {

    },

    components: {},

    props: {
      textLabel: { type: String, required: true },
      showText: { type: Boolean, default: true },
      icon: { type: String, default: null },
      activeTooltip: { type: Boolean, default: false },
      positionTooltip: { type: String, default: 'is-top' },
      handleOnClick: { type: Function, required: true }
    },

    data: () => ({

    }),

    watch: {},

    mounted() {
    },

    methods: {
      handleClick() {
        this.handleOnClick()
      }
    },

  };
</script>
