<template>
  <div>
    <div class="text-blue-light font-thin text-center uppercase mb-4">
      {{ $t("activities_extrafields_preview") }}
    </div>
    <div v-if="isLoading">
      <scale-loader></scale-loader>
    </div>
    <div v-else>
      <b-table
        :data="data"
        :loading="isLoading"
        :paginated="true"
        :pagination-simple="false"
        :per-page="5"
        :bordered="true"
        :show-detail-icon="false"
        ref="table"
        icon-pack="fas"
      >
        <template slot="empty">
          <div class="m-4 text-lg text-dark-grey text-center">
            <span>{{ $t('empty_extrafields_activities') }}</span>
          </div>
        </template>

        <template>
          <b-table-column
            :label="$t('code')"
            class="w-4 type-column vertical-center"
            centered v-slot="data"
          >
            {{ data.row.planning_code }}
          </b-table-column>

          <b-table-column
            :label="$t('name')"
            class="w-4 type-column vertical-center"
            centered v-slot="data"
          >
              {{ data.row.name }}
          </b-table-column>

          <b-table-column
            :label="$t('custom_status')"
            class="w-4 type-column vertical-center"
            centered v-slot="data"
          >
            {{ data.row.status_name }}
          </b-table-column>

          <b-table-column
            :label="$t('extrafields_empty')"
            class="w-4 type-column vertical-center"
            centered v-slot="data"
          >
            <b-tooltip position="is-left"
              multilined>
                {{ data.row.extrafields_empty }}
                <template v-slot:content>
                  <b>Campos extras vacios</b> <br> <span v-html="Object.values(data.row.extrafields).join('<br>')"></span>
              </template>
            </b-tooltip>

            <span @click="onRowClicked(data.row.id)" v-if="data.row.extrafields_empty > 0" class="cursor-pointer">
              <b-icon icon="eye" pack="fas" size="is-small" type="is-info"  />
            </span>
          </b-table-column>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { mapGetters } from "vuex";
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

export default {
  name: "project-activities-preview",

  components: {
    ScaleLoader
  },

  computed: {
    ...mapGetters({
      project: 'app/project',
      user: 'auth/user',
    })
  },

  data: () => ({
    data: [],
    isLoading: true,
    currency:"",
  }),

  mounted() {
    this.getActivitiesPreview();
  },

  methods: {
    async getActivitiesPreview() {
      if (this.project) {
        this.isLoading = true;

        let params = {p: this.project.id, with_extrafields: 1 };

        const {data} = await axios.get('/api/v2/activities', {params: params});

        if (data && data.success) {
          this.data = data.activities;
          this.isLoading = false;
        }
      }
    },

    onRowClicked(activityId, access = true) {
      if (access) {
        // this.$router.push({path: '/activity/'+activityId+'/extrafields/edit'})
        this.$router.push({path: '/activity/'+activityId+'/edit'})
      }
    },
  },

};
</script>

<style>
</style>
